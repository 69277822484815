import { useContext } from 'react';

import feedType, {
  mainFeedTypes,
  footerFeedTypes,
} from '../../../../context/FeedContext/config/feedTypes';
import feedTypeConfig from '../../../../context/FeedContext/config/feedTypeConfig';
import FeedContext from '../../../../context/FeedContext';

const useFeedListConfig = () => {
  const { onFeedSelected, totalUnreadCount } = useContext(FeedContext);

  const feedListEntries = mainFeedTypes.map((type) => ({
    feedId: feedType[type],
    onClick: () => onFeedSelected(feedType[type]),
    icon: feedTypeConfig[feedType[type]].icon,
    unreadCount: totalUnreadCount[feedType[type]],
    title: feedTypeConfig[feedType[type]].title,
  }));

  const footerFeedListEntries = footerFeedTypes.map((type) => ({
    feedId: feedType[type],
    onClick: () => onFeedSelected(feedType[type]),
    icon: feedTypeConfig[feedType[type]].icon,
    unreadCount: totalUnreadCount[feedType[type]],
    title: feedTypeConfig[feedType[type]].title,
  }));

  return {
    feedListEntries,
    footerFeedListEntries,
  };
};

export default useFeedListConfig;
