import styled, { css } from 'styled-components';
import { Button } from '@mui/material';
import { ReactComponent as BillingIcon } from '../../../assets/icons/billing.svg';
import { ReactComponent as CheckinIcon } from '../../../assets/icons/check-in.svg';
import { ReactComponent as ForkIcon } from '../../../assets/icons/fork.svg';
import { ReactComponent as OtherIcon } from '../../../assets/icons/folder-icon.svg';
import OnCallActions from '../../../components/OnCallActions';
import Tabs from '../../components/Tabs';
import colors from '../../../styles/colors';

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.shades.gamma5};
`;

const UserInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 30px;
  position: relative;
`;

const ActionButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const TabHeaderRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledOnCallActions = styled(OnCallActions)`
  padding: 0 30px 10px 10px;
`;

const iconStyles = css`
  height: 15px;
  width: 15px;
  margin-right: 8px;
`;

const StyledTabs = styled(Tabs)`
  flex: 1;
  border-color: ${colors.base.primary};

  & .Mui-selected {
    background-color: ${colors.base.primary};
  }
`;

const StyledBillingIcon = styled(BillingIcon)`
  ${iconStyles};
`;

const StyledCheckinIcon = styled(CheckinIcon)`
  ${iconStyles};
`;

const StyledForkIcon = styled(ForkIcon)`
  ${iconStyles};
`;

const StyledOtherIcon = styled(OtherIcon)`
  ${iconStyles};
`;

const StyledButton = styled(Button)`
  border-radius: 5px;
  margin-top: 10px;
  background: ${colors.base.primary};
  color: ${colors.base.beta};
  height: fit-content;
  padding: 10px 14px;
  &.MuiButton-text {
    font-size: 14px;
    line-height: 16px;
  }
  &:hover {
    background: ${colors.shades.primary1};
  }
`;

export {
  HeaderContainer,
  UserInfoContainer,
  ActionButtonContainer,
  TabHeaderRow,
  StyledOnCallActions,
  StyledBillingIcon,
  StyledCheckinIcon,
  StyledForkIcon,
  StyledOtherIcon,
  StyledButton,
  iconStyles,
  StyledTabs,
};
