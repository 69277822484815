import styled, { css } from 'styled-components';

import { Heading1 } from '../../../../components/Typography';
import { mixins } from '../../../../styles/breakpoints';
import PreviousButton from '../../../../components/BackButton';
import colors from '../../../../styles/colors';
import { ReactComponent as BackgroundImageSvg } from '../../../../assets/signup_login_bg.svg';
import { ReactComponent as AppLogoSvg } from '../../../../assets/logo.svg';

const LoginContainer = styled.div`
  max-width: unset;
  padding: 0;
  min-height: 100%;

  ${mixins.tabletMedia(css`
    background-color: ${colors.base.gamma};
  `)}
`;

const LoginFormsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: ${colors.base.alpha};

  ${mixins.tabletMedia(css`
    background-color: ${colors.base.gamma};
    height: 100vh;
  `)}
`;

const BackButton = styled(PreviousButton)`
  position: fixed;
  left: 0;
  top: 0;
  margin: 25px 0 0 30px;
  width: 10px;
  height: 8px;
  z-index: 1;
`;

const FormWrapper = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;  
  padding-top: 25px;
  padding-bottom: 85px;
  max-width: 400px;
  background: transparent;
  box-shadow: none;

  ${mixins.tabletMedia(css`
    margin-top: 88px;
    box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.1);
    background: ${colors.base.alpha};
  `)}

  form {
    padding: 0 25px;
  }
`;

const FormHeading = styled(Heading1)`
  text-align: center;
  padding: 40px 0;
  margin: 20px 0 10px;
  font-size: 40px;
  font-weight: normal;
  text-transform: none;

  ${mixins.tabletMedia(css`
    padding: 0 0 22px 0;
    font-size: 28px;
  `)}
`;

const BackgroundImage = styled(BackgroundImageSvg)`
  position: fixed;
  display: none;

  ${mixins.tabletMedia(css`
    display: block;
  `)}
`;

const AppLogo = styled(AppLogoSvg)`
  position: fixed;
  display: none;
  top: 22px;

  ${mixins.tabletMedia(css`
    display: block;
  `)}
`;

export {
  LoginContainer,
  LoginFormsContainer,
  BackButton,
  BackgroundImage,
  AppLogo,
  FormWrapper,
  FormHeading,
};
