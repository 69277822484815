import styled from 'styled-components';

const ErrorMessageContainer = styled.div`
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  margin: 10px;
  padding: 10px;
`;

export {
  ErrorMessageContainer,
};
