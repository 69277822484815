import React, {
  useEffect,
  useContext,
  useState,
  useCallback,
} from 'react';
import { compose } from 'recompose';
import { autorun } from 'mobx';
import { observer } from 'mobx-react';

import { ReactComponent as ViewIcon } from '../../../../../../assets/icons/v2/arrow-right.svg';
import InternalAssignment from '../../../../../../Model/InternalAssignment';
import CoachNotificationView from '../../../../../../Model/CoachNotificationView';
import UserContext from '../../../../../../context/UserContext';
import useQuickSearch from '../../../../../../hooks/useQuickSearch';
import useComponentMounted from '../../../../../../hooks/useComponentMounted';
import { AssignmentRole } from '../../../../../../utils/internalAssignments';
import { PrimaryButton } from '../../../../../../components/Button/ActionButtons';
import GenericDataGrid from '../../../../../components/GenericDataGrid';
import QuickSearchToolbar from '../../../../../components/QuickSearchToolbar';

import getColumns from './columns';
import texts from './texts.json';

const CoachTable = () => {
  const [coachViewsCollection, setCoachViewsCollection] = useState({ docs: [] });
  const [assignmentsCollection, setAssignmentsCollection] = useState({ docs: [] });
  const [notificationViews, setNotificationViews] = useState([]);
  const [isCollectionsReady, setIsCollectionsReady] = useState(false);
  const [isTableReady, setIsTableReady] = useState(false);

  const { userId: user } = useContext(UserContext);

  const isComponentMountedRef = useComponentMounted();

  useEffect(() => {
    const init = async () => {
      setIsCollectionsReady(false);
      const coachNotificationViewsCol = await CoachNotificationView.getAllExternalCoachNotificationViews();
      const assignmentsCol = await InternalAssignment.getAllCurrentAssignments(user, AssignmentRole.ASSISTANT_COACH);

      if (isComponentMountedRef.current) {
        setCoachViewsCollection(coachNotificationViewsCol);
        setAssignmentsCollection(assignmentsCol);
        setIsCollectionsReady(true);
      }
    };

    init();
  }, [
    isComponentMountedRef,
    user,
  ]);

  useEffect(() => {
    if (isCollectionsReady) {
      const disposer = autorun(() => {
        setIsTableReady(false);
        const assignments = [...assignmentsCollection.docs];
        const views = [...coachViewsCollection.docs];

        const showAllViews = assignments.length === 0;

        let filteredViews = showAllViews
          ? views
          : views.filter((view) => assignments.some((assignment) => assignment.coach === view.id));

        // Sort the views alphabetically.
        filteredViews = filteredViews.sort((a, b) => (a.name.localeCompare(b.name)));

        if (isComponentMountedRef.current) {
          setNotificationViews(filteredViews);
          setIsTableReady(true);
        }
      });

      return disposer;
    }
    return null;
  }, [
    isCollectionsReady,
    isComponentMountedRef,
    assignmentsCollection.docs,
    coachViewsCollection.docs,
  ]);

  const renderActionCell = useCallback(({ row: { id: coachId } }) => (
    <PrimaryButton
      onClick={() => window.open(`/c/${coachId}`, '_blank')}
      icon={<ViewIcon />}
      variant="info"
      size="medium"
    >
      {texts.buttons.view}
    </PrimaryButton>
  ), []);

  const columns = getColumns({
    actionsRenderCell: renderActionCell,
  });

  const {
    filteredRows,
    toolbarProps,
  } = useQuickSearch(notificationViews, columns);

  return (
    <GenericDataGrid
      rows={filteredRows}
      columns={columns}
      components={{
        Toolbar: QuickSearchToolbar,
      }}
      loading={!isTableReady}
      componentsProps={{
        toolbar: {
          ...toolbarProps,
          placeholder: texts.toolbarPlaceholder,
        },
      }}
    />
  );
};

export default compose(
  observer,
)(CoachTable);
