import React from 'react';
import FeedList from './components/FeedList';
import Feed from './components/FeedContainer';
import FeedActionBar from './components/FeedActionBar';

import { ActivityFeedContainer } from './styles';

const ActivityFeed = () => (
  <ActivityFeedContainer>
    <FeedList />
    <Feed />
    <FeedActionBar />
  </ActivityFeedContainer>
);

export default ActivityFeed;
