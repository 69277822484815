import styled from 'styled-components';
import { Paper } from '@mui/material';

const StyledPaper = styled(Paper)`
  padding: 16px;
  min-width: 40%;
`;

const Container = styled.div`
  margin: 20px 0 10px;
  width: 100%;
`;

export {
  StyledPaper,
  Container,
};
