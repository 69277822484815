import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import {
  InputLabel,
  MenuItem,
  Paper,
  Select,
} from '@mui/material';

import format from 'string-template';
import useToast from '../../../../hooks/useToast';
import Lead, { SalesStage } from '../../../../Model/Lead';
import { ReactComponent as PersonRemoveIcon } from '../../../../../assets/icons/person-remove-icon.svg';

import {
  StyledInput,
  StyledDialogContent,
  Title,
  StyledDialog,
  StyledDialogActions,
  ConfirmButton,
  ConfirmMessage,
  CancelButton,
} from './styles';
import texts from './texts.json';
import { DisqualifyOptions } from './utils';

const DisqualifyModal = ({
  lead,
  userId,
  userName,
  onClose,
  showModal,
}) => {
  const [selectedCause, setSelectedCause] = useState('');
  const [reasonForDisqualify, setReasonForDisqualify] = useState('');

  const isReasonInputVisible = useMemo(() => selectedCause === texts.possibleCauses[DisqualifyOptions.OTHER], [
    selectedCause,
  ]);

  const { showToast } = useToast();

  const handleDisqualify = useCallback(async () => {
    if (isReasonInputVisible && !reasonForDisqualify) {
      showToast(texts.compulsoryFields, {
        options: {
          position: 'top-right',
          closeOnClick: true,
          draggable: true,
        },
        error: true,
      });
      return;
    }
    let reason = selectedCause;
    if (reasonForDisqualify) {
      reason += ` - ${reasonForDisqualify}`;
    }
    await lead.setSalesStage(SalesStage.UNQUALIFIED, { userId, userName, reason });
    showToast(texts.toastSuccess, {
      options: {
        position: 'top-right',
        closeOnClick: true,
        draggable: true,
      },
    });
    onClose();
  }, [
    lead,
    onClose,
    userId,
    reasonForDisqualify,
    isReasonInputVisible,
    selectedCause,
    userName,
    showToast,
  ]);

  const questionItemList = useMemo(() => (
    Object.values(texts.possibleCauses).map((question) => (
      <MenuItem
        key={question}
        value={question}
      >
        {question}
      </MenuItem>
    ))
  ), []);

  useEffect(() => {
    if (showModal) {
      setSelectedCause('');
    }
  }, [showModal]);

  useEffect(() => {
    setReasonForDisqualify('');
  }, [selectedCause]);

  return (
    <StyledDialog
      open={showModal}
      PaperComponent={Paper}
      onClose={onClose}
    >
      <Title>
        <PersonRemoveIcon />
        {texts.title}
      </Title>
      <StyledDialogContent>
        <InputLabel>
          {texts.causeOfDisqualification}
        </InputLabel>
        <Select
          value={selectedCause}
          onChange={(event) => setSelectedCause(event.target.value)}
          variant="outlined"
          fullWidth
        >
          {questionItemList}
        </Select>
        {isReasonInputVisible && (
          <>
            <InputLabel>
              {texts.inputReason}
            </InputLabel>
            <StyledInput
              variant="outlined"
              multiline
              rows={4}
              value={reasonForDisqualify}
              onChange={(evt) => setReasonForDisqualify(evt.target.value)}
            />
          </>
        )}
        <ConfirmMessage>
          {format(texts.confirmMessage, {
            leadName: lead.name,
          })}
        </ConfirmMessage>
      </StyledDialogContent>
      <StyledDialogActions>
        <ConfirmButton
          onClick={handleDisqualify}
          disabled={!selectedCause}
        >
          {texts.confirm}
        </ConfirmButton>
        <CancelButton onClick={onClose}>
          {texts.cancel}
        </CancelButton>
      </StyledDialogActions>
    </StyledDialog>
  );
};

DisqualifyModal.propTypes = {
  lead: PropTypes.instanceOf(Lead).isRequired,
  onClose: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  showModal: PropTypes.bool.isRequired,
};

export default compose(
  observer,
)(DisqualifyModal);
