import React from 'react';
import PropTypes from 'prop-types';

import {
  Next,
  Previous,
  LeftArrow,
  RightArrow,
} from './styles';

const ArrowTypes = {
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
};

const Arrow = ({
  type,
  ...props
}) => {
  if (type === ArrowTypes.LEFT) {
    return (
      <Previous {...props}>
        <LeftArrow />
      </Previous>
    );
  }

  if (type === ArrowTypes.RIGHT) {
    return (
      <Next {...props}>
        <RightArrow />
      </Next>
    );
  }

  return null;
};

Arrow.propTypes = {
  type: PropTypes.oneOf(Object.keys(ArrowTypes)).isRequired,
};

export default Arrow;
export {
  ArrowTypes,
};
