import styled, { css } from 'styled-components';

import {
  headline2,
  headline3,
} from '../../../../styles/text';
import colors from '../../../../styles/colors';
import { Row } from '../../../../styles/grid';

import { ReactComponent as ActionFailed } from '../../../../assets/icons/action_failed.svg';
import { ReactComponent as ActionSuccessful } from '../../../../assets/icons/action_succeeded.svg';
import { ReactComponent as PaymentConfirm } from '../../../../assets/icons/payment_confirm.svg';

import Button from '../../../../components/Button';

import SubscribeButton from './SubscribeButton';

const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${colors.shades.gamma1};
  opacity: 0.8;
  z-index: 2;
  cursor: pointer;
`;

const CardTitle = styled.div`
  ${headline2}
  display: flex;
  flex-direction: column;
  text-transform: none;
  margin-top: 50px;
`;

const NotificationCardTitle = styled(CardTitle)`
  text-align: center;
`;

const ActionButtonGroup = styled(Row)`
  ${headline3};
  margin: 30px 0 0px 0;
  padding: 10px 0;
  justify-content: center;
`;

const SvgBadgeStyle = css`
  margin: 40px auto;
  padding: 10px 0;
  display: flex;
  height: 100px;
`;
const StyledActionFailed = styled(ActionFailed)`
  ${SvgBadgeStyle};
`;

const StyledActionSuccessful = styled(ActionSuccessful)`
  ${SvgBadgeStyle};
`;

const StyledPaymentConfirm = styled(PaymentConfirm)`
  ${SvgBadgeStyle};
`;

const DescriptionBar = styled.div`
  ${headline2};
  text-transform: none;
  text-align: center;
`;

const CardGrid = styled.div`
  overflow: visible;
  margin: auto 16px;
  z-index: 10;
  position: relative;
  display: flex;
  justify-content: center;
`;

const ActionButton = styled(Button)`
  margin: 10px;
  cursor: pointer;
  font-size: 14px;
  color: ${colors.shades.secondary3};
`;

const RegisterNowButton = styled(SubscribeButton)`
  width: 90%;
  margin: auto;
  color: ${colors.base.secondary2};
`;

export {
  CardGrid,
  StyledActionSuccessful,
  NotificationCardTitle,
  Overlay,
  DescriptionBar,
  RegisterNowButton,
  ActionButtonGroup,
  StyledPaymentConfirm,
  ActionButton,
  StyledActionFailed,
};
