import styled from 'styled-components';

const Col = styled.div`
  display: flex;
  flex-direction: column;
  &:not(:first-child) {
    margin-left: 10px;
  }
`;

/*
  row which takes a spaceBetween parameter which is default false
  if true, it will add justify-content: space-between
  if false, it will add justify-content: flex-start
*/
const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${(props) => (props.$withoutMargin ? '0px' : '20px')};
  ${({ spaceBetween }) => spaceBetween && 'justify-content: space-between;'};
  ${({ fullWidth }) => fullWidth && 'width: 100%;'};
`;

export {
  Row,
  Col,
};
