import styled from 'styled-components';
import {
  ImageSearchOutlined,
} from '@mui/icons-material';

import FileSelectButton from '../../../../../components/FileSelectButton';
import LoadingPage from '../../../../../components/LoadingPage';
import colors from '../../../../../styles/colors';

const ImageContainer = styled.div`
  display: flex;
`;

const ImageDescriptionContainer = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: ${colors.shades.gamma9};
  margin-left: 20px;
  max-width: 370px;
`;

const ImageDescription = styled.div``;

const ImageTipsDescription = styled.div`
  margin-top: 16px;
`;

const ImageUploader = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
`;

const ImageWrapper = styled.div`
  width: 300px;
  aspect-ratio: 4/3;
  display: flex;
  background-color: ${colors.shades.gamma1};
  overflow: hidden;

  &:hover {
    background-color: ${colors.shades.gamma2};
  }
`;

const StyledPreview = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const StyledLoadingPage = styled(LoadingPage)`
  height: auto;
  color: ${colors.base.beta};
`;

const StyledEditIcon = styled.div`
  background-color: ${colors.base.alpha};
  color: ${colors.base.beta};
  border-radius: 50%;
  width: 35px;
  height: 35px;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items:  center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.25s;
`;

const StyledFileSelectButton = styled(FileSelectButton)`
  position: relative;
  width: 100%;

  &:hover > ${StyledEditIcon} {
    opacity: 1;
  }
`;

const NoPreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 10px;
  color: ${colors.base.beta};
`;

const StyledImageSearch = styled(ImageSearchOutlined)`
  color: ${colors.base.beta};
  width: 65px;
  height: auto;
`;

export {
  ImageContainer,
  ImageDescriptionContainer,
  ImageDescription,
  ImageTipsDescription,
  ImageUploader,
  ImageWrapper,
  StyledPreview,
  StyledLoadingPage,
  StyledEditIcon,
  StyledFileSelectButton,
  NoPreviewContainer,
  StyledImageSearch,
};
