import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  BarChart,
  XAxis,
  YAxis,
  ReferenceLine,
  Bar,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';

import colors from '../../../../styles/colors';
import {
  getMacroPercentages,
} from '../../../utils/meals';
import MacroStats from './MacroStats';
import {
  Container,
  TitleText,
  UnitLabel,
  ValueText,
  StatContainer,
  Stat,
  AverageStatContainer,
  DailyPopupContainer,
  StyledDivider,
  DailyStat,
} from './styles';
import texts from './texts.json';

// generates a bar chart to show the daily calorie intake of the check-in week
// with a reference line to show the daily calorie goal.
const CalorieIntakeChart = ({
  activity,
}) => {
  const {
    mealsSummary: {
      calories = 0,
      macros: {
        protein: dailyAverageProteinIntake = 0,
        carbs: dailyAverageCarbsIntake = 0,
        fat: dailyAverageFatIntake = 0,
      },
      dailyCalorieIntake = {},
      mealPlan: {
        calories: dailyCalorieGoal = 0,
      } = {},
    } = {},
    checkInDay = 0,
  } = activity;

  const dailyAverageMacroPercentages = useMemo(() => getMacroPercentages({
    protein: dailyAverageProteinIntake,
    carbs: dailyAverageCarbsIntake,
    fat: dailyAverageFatIntake,
  }), [
    dailyAverageProteinIntake,
    dailyAverageCarbsIntake,
    dailyAverageFatIntake,
  ]);

  // generate data for the bar chart
  // 'empty' bars are used to show a grey bar for days where no data was recorded
  const dailyCalorieData = useMemo(() => {
    const weekdays = moment.weekdays();
    // order the weekdays from check-in day to check-in day
    weekdays.push(...weekdays.splice(0, (checkInDay + 1)));
    return weekdays.map((dayName) => {
      const isDataRecorded = Object.keys(dailyCalorieIntake).includes(dayName);
      const dayData = dailyCalorieIntake[dayName];
      const dayCalories = dayData ? dayData.calorieIntake : 0;
      return {
        name: dayName?.toUpperCase().substring(0, 1),
        value: dayCalories?.toFixed(0),
        macrosPercentages: dayData?.macros ? getMacroPercentages(dayData?.macros) : '',
        macros: dayData?.macros,
        day: dayData?.day,
        empty: (!isDataRecorded ? (dailyCalorieGoal / 10) : 0),
      };
    });
  }, [
    dailyCalorieIntake,
    dailyCalorieGoal,
    checkInDay,
  ]);

  return (
    <Container>
      <ResponsiveContainer width="100%" aspect={2.5}>
        <BarChart
          data={dailyCalorieData}
          stackOffset="sign"
        >
          <XAxis
            fontSize={10}
            axisLine={false}
            tickLine={false}
            dataKey="name"
          />
          <YAxis
            width={40}
            fontSize={10}
            axisLine={false}
            tickLine={false}
            tickSize={10}
            min={dailyCalorieGoal}
            domain={calories === 0 ? [0, dailyCalorieGoal] : [0, 'dataMax']}
          />
          <Tooltip
            cursor={false}
            allowEscapeViewBox={{ x: true }}
            content={({ payload }) => (
              <DailyPopupContainer>
                {payload[0]?.payload?.value !== '0' ? (
                  <>
                    <DailyStat>
                      <TitleText>{payload[0]?.payload?.day}</TitleText>
                      <ValueText>{`${payload[0]?.value} kCal`}</ValueText>
                    </DailyStat>
                    <StyledDivider />
                    <MacroStats
                      macros={payload[0]?.payload?.macros}
                      macrosPercentages={payload[0]?.payload?.macrosPercentages}
                    />
                  </>
                ) : <TitleText>{texts.noData}</TitleText>}
              </DailyPopupContainer>
            )}
          />
          <ReferenceLine
            y={dailyCalorieGoal}
            stroke={colors.base.danger}
            strokeDasharray="3 3"
          />
          <Bar
            dataKey="value"
            stackId="stack"
            fill={colors.shades.primary1}
            barSize={10}
            radius={10}
          />
          <Bar
            dataKey="empty"
            stackId="stack"
            fill={colors.shades.gamma1}
            barSize={10}
            radius={10}
          />
        </BarChart>
      </ResponsiveContainer>
      {/* Show cards for daily goal and daily calorie intake average for the check-in week */}
      <AverageStatContainer>
        <StatContainer>
          <Stat>
            <TitleText>{texts.dailyGoal}</TitleText>
            <ValueText>{dailyCalorieGoal}</ValueText>
            <UnitLabel>{texts.calories}</UnitLabel>
          </Stat>
          <Stat>
            <TitleText>{texts.dailyAverage}</TitleText>
            <ValueText>{calories?.toFixed(0)}</ValueText>
            <UnitLabel>{texts.calories}</UnitLabel>
          </Stat>
        </StatContainer>
        <MacroStats
          macros={activity?.mealsSummary?.macros}
          macrosPercentages={dailyAverageMacroPercentages}
          shouldShowTitle
        />
      </AverageStatContainer>
    </Container>
  );
};

CalorieIntakeChart.propTypes = {
  activity: PropTypes.object,
};

CalorieIntakeChart.defaultProps = {
  activity: {},
};

export default CalorieIntakeChart;
