import styled, { css } from 'styled-components';
import {
  Typography,
  FormControl,
} from '@mui/material';

import colors from '../../../../styles/colors';
import { StyledSelect, StyledTextFieldContainer } from '../../../../components/Inputs';
import { ReactComponent as MealPlateIcon } from '../../../../assets/icons/v2/modal-meal-plate.svg';

const ContentSection = styled.div`
  display: flex;
  row-gap: 5px;
  flex-direction: column;
  padding: 21px 0 14px 51px;
`;

const Subtitle = styled(Typography)`
  font-size: 10px;
  line-height: 12px;
  font-weight: 500;
  color: ${colors.shades.secondary3};
  letter-spacing: 0.07em;
  text-align: left;
  text-transform: uppercase;
`;
const RowContainer = styled(FormControl)`
  display: flex;
  flex-direction: row;
  column-gap: 5px;
  width: 60%;

  label.MuiInputLabel-root {
    color: ${colors.shades.secondary3};
  }
`;

const flexItemStyles = css`
  flex-grow: 1;
  flex-basis: calc(100% / 3);
`;

const FilterLabel = styled.div`
  display: flex;
  align-items: center;
  font-style: normal;
  font-size: 11px;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0;
  color: ${colors.shades.secondary3};
  ${flexItemStyles};
`;

const ReStyledTextFieldContainer = styled(StyledTextFieldContainer)`
  ${flexItemStyles};
`;

const ReStyledSelect = styled(StyledSelect)`
  ${flexItemStyles};
`;

const StyledMealPlateIcon = styled(MealPlateIcon)`
  width: 27px;
  height: 29px;
  margin-bottom: 9px;
`;

export {
  ContentSection,
  Subtitle,
  RowContainer,
  FilterLabel,
  ReStyledSelect,
  ReStyledTextFieldContainer,
  StyledMealPlateIcon,
};
