/**
 * Converts a given playable/accessible vimeo video link to an embeddable link
 *
 * @param {string} url - URL of the video (vimeo or youtube link)
 * @returns an embeddable URL link
 */
const transformVideoUrl = (url) => {
  /* Extract video ID and hash parameter of vimeo link and generate embeddable vimeo link
  *  eg. https://vimeo.com/793124626/3c9e08ff0a -> https://player.vimeo.com/video/793124626?h=3c9e08ff0a
  */
  const vimeoHashRegex = /https:\/\/vimeo\.com\/(\d+)\/([a-zA-Z0-9_-]+)/i;
  const vimeoHashMatch = url.match(vimeoHashRegex);

  if (vimeoHashMatch && vimeoHashMatch[1] && vimeoHashMatch[2]) {
    return `https://player.vimeo.com/video/${vimeoHashMatch[1]}?h=${vimeoHashMatch[2]}`;
  }

  /* Extract video ID of vimeo link and generate embeddable vimeo link
  *  eg. https://vimeo.com/793124626 -> https://player.vimeo.com/video/793124626
  */
  const vimeoRegex = /https:\/\/vimeo\.com\/(\d+)/i;
  const vimeoMatch = url.match(vimeoRegex);

  if (vimeoMatch && vimeoMatch[1]) {
    return `https://player.vimeo.com/video/${vimeoMatch[1]}`;
  }

  return url;
};

export { transformVideoUrl };
