import React, {
  useState,
  useEffect,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { observer } from 'mobx-react';

import useComponentMounted from '../../../hooks/useComponentMounted';
import MsgEndpoint from '../../../Model/MsgEndpoint';
import MsgEndpointContext from './MsgEndpointContext';

const MsgEndpointContextProvider = ({
  children,
}) => {
  const [msgEndpointCollection, setMsgEndpointCollection] = useState({});
  const [loadingMsgEndpoints, setLoadingMsgEndpoints] = useState(false);

  const isComponentMountedRef = useComponentMounted();

  // Load the leads collection.
  useEffect(() => {
    const init = async () => {
      setLoadingMsgEndpoints(true);
      const endpointCollection = await MsgEndpoint.getAllEndpoints();

      if (isComponentMountedRef.current) {
        setLoadingMsgEndpoints(false);
        setMsgEndpointCollection(endpointCollection);
      }
    };
    init();
  }, [
    isComponentMountedRef,
  ]);

  const value = useMemo(() => ({
    msgEndpointCollection,
    loadingMsgEndpoints,
  }), [
    msgEndpointCollection,
    loadingMsgEndpoints,
  ]);

  return (
    <MsgEndpointContext.Provider value={value}>
      {children}
    </MsgEndpointContext.Provider>
  );
};

MsgEndpointContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default compose(
  observer,
)(MsgEndpointContextProvider);
