import styled from 'styled-components'; import {
  MailOutline as EmailIcon,
  PhoneInTalk as PhoneIcon,
  PersonPinCircle as LocationIcon,
} from '@mui/icons-material';

import colors from '../../../../styles/colors';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const InfoWrapper = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const ContactInfo = styled(InfoWrapper)`
  justify-content: space-between;
  color: ${colors.shades.gamma2};
  border-top: 1px solid ${colors.shades.gamma3};
  border-bottom: 1px solid ${colors.shades.gamma3};
`;

const Name = styled.div`
  font-weight: bold;
  font-size: 24px;
`;

const StyledEmailIcon = styled(EmailIcon)`
  height: 16px;
  width: 16px;
`;

const StyledPhoneIcon = styled(PhoneIcon)`
  height: 16px;
  width: 16px;
`;

const StyledLocationIcon = styled(LocationIcon)`
  height: 16px;
  width: 16px;
`;

export {
  Container,
  InfoContainer,
  InfoWrapper,
  Name,
  ContactInfo,
  StyledEmailIcon,
  StyledPhoneIcon,
  StyledLocationIcon,
};
