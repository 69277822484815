import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { compose } from 'recompose';

import { AssignmentRole } from '../../../../utils/internalAssignments';
import { withPayoutReconciliationContextProvider } from '../../../context/PayoutReconciliationContext';
import { PayoutConfig } from '../../../utils/payoutReconciliation';
import CommissionsTable from '../../../components/CommissionsTable';

const CrxCommissions = () => {
  const {
    params: {
      userId: crxId,
    },
  } = useRouteMatch();

  return (
    <CommissionsTable
      userId={crxId}
      payoutsConfig={PayoutConfig[AssignmentRole.CRX]}
    />
  );
};

export default compose(
  withPayoutReconciliationContextProvider,
)(CrxCommissions);
