import styled from 'styled-components';

import colors from '../../../../../../../../styles/colors';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  max-height: ${(props) => (props.$isModalView ? '320px' : 'auto')};
  overflow-y: auto;
`;

const LabelsRow = styled.div`
  display: flex;
  gap: 2px;
  padding: 26px 6px 6px 6px;
`;

const Label = styled.div`
  color: ${colors.shades.primary1};
  font-weight: 600;
  font-size: 8px;
  line-height: 9px;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  padding-left: 2px;
`;
const FirstLabel = styled(Label)`
  margin-left: 40px;
  width: 310px;
  margin: 22px 49px 0 51px;
`;
const SecondLabel = styled(Label)`
  width: 80px;
`;
const ThirdLabel = styled(Label)`
  margin-right: 40px;
  width: 80px;
`;

export {
  StyledContainer,
  LabelsRow,
  Label,
  FirstLabel,
  SecondLabel,
  ThirdLabel,
};
