import React, { useCallback } from 'react';
import {
  FieldArray,
  useFormikContext,
} from 'formik';

import { Checkbox } from '@mui/material';

import { RecipeTag } from '../../../../../utils/meals';
import { fieldName } from '../../formFields';
import { Label } from '../../styles';

import {
  Container,
  TagContainer,
  StyledFormControlLabel,
} from './styles';
import texts from './texts';

const TagsForm = () => {
  const { values } = useFormikContext();

  const handleTagChange = useCallback((event, push, remove) => {
    const {
      checked,
      name,
    } = event.target;
    const tags = values[fieldName.TAGS];
    if (checked) {
      push(name);
    } else {
      const removeIndex = tags.indexOf(name);
      remove(removeIndex);
    }
  }, [
    values,
  ]);

  return (
    <Container>
      <Label>{`${texts.title}:`}</Label>
      <TagContainer>
        <FieldArray
          name={fieldName.TAGS}
          render={({
            push,
            remove,
          }) => (
            <>
              {Object.keys(RecipeTag).map((tag) => {
                const checked = values[fieldName.TAGS].indexOf(RecipeTag[tag]) !== -1;
                return (
                  <StyledFormControlLabel
                    key={tag}
                    control={<Checkbox checked={checked} />}
                    label={texts[RecipeTag[tag]]}
                    name={RecipeTag[tag]}
                    onChange={(event) => handleTagChange(event, push, remove)}
                  />
                );
              })}
            </>
          )}
        />
      </TagContainer>
    </Container>
  );
};

export default TagsForm;
