import { ContractMinimumValue, ContractProperties } from './userContract';

const DefaultPackage = {
  name: '',
  [ContractProperties.CURRENCY]: '',
  [ContractProperties.INITIAL_PAYMENT]: '',
  [ContractProperties.INITIAL_TERM]: ContractMinimumValue.INITIAL_TERM,
  [ContractProperties.MIN_SUBSCRIPTION_MONTHS]: 3,
  [ContractProperties.MONTHLY_PAYMENT]: '',
  [ContractProperties.PRICE_ID]: '',
  [ContractProperties.MONTHLY_PRICE_ID]: '',
};

export {
  DefaultPackage,
};
