import React, {
  useMemo,
  useContext,
} from 'react';
import PropTypes from 'prop-types';

import UserContext from '../../../context/UserContext';
import config from '../../../config';

import Portal from '../Portal';

import { MobileView } from './styles';

const MealPortal = ({
  options: {
    recipeId,
    servings,
  },
}) => {
  const { userId } = useContext(UserContext);

  const mealUrl = useMemo(() => (
    `${config.mobileAppURL}/u/${userId}/mealPlan/recipe/${recipeId}?servings=${servings}&pageHeaderActions=false`
  ), [
    recipeId,
    servings,
    userId,
  ]);

  return (
    <MobileView>
      <Portal
        url={mealUrl}
      />
    </MobileView>
  );
};

MealPortal.propTypes = {
  options: PropTypes.shape({
    recipeId: PropTypes.string,
    servings: PropTypes.number,
  }).isRequired,
};

export default MealPortal;
