import React from 'react';

const initialValues = {
  isReady: false,
};

const UserContractsContext = React.createContext(initialValues);

export default UserContractsContext;
export {
  initialValues,
};
