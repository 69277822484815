import React from 'react';
import format from 'string-template';
import PropTypes from 'prop-types';

import {
  HabitText,
  ItemContainer,
  TextContainer,
  StatText,
  StyledHabitProgress,
  Container,
} from './styles';
import texts from './texts.json';

const DAYS_PER_WEEK = 7;

const HabitStat = ({
  activity,
}) => {
  const {
    habitData,
  } = activity;

  return (
    <Container>
      {Object.keys(habitData).map((habitId) => {
        const habitWeeklyCount = habitData[habitId].completedCount;

        return (
          <ItemContainer key={habitId}>
            <StyledHabitProgress
              type="determinate"
              value={habitWeeklyCount / DAYS_PER_WEEK}
            />
            <TextContainer>
              <HabitText>
                {habitData[habitId].text}
              </HabitText>
              <StatText>
                {format(texts.statText, {
                  count: habitWeeklyCount,
                })}
              </StatText>
            </TextContainer>
          </ItemContainer>
        );
      })}
    </Container>
  );
};

HabitStat.propTypes = {
  activity: PropTypes.object.isRequired,
};

export default HabitStat;
