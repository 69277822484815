import feedType from './feedTypes';

const texts = {
  [feedType.WORKOUT_COMPLETE]: 'Completed Workouts',
  [feedType.CUSTOM_WORKOUT]: 'Custom Workouts',
  [feedType.NEED_NEW_WORKOUTS]: 'Need new Workouts',
  [feedType.NEED_NEW_MEAL_PLAN]: 'Need new Meal plans',
  [feedType.TRACKED_ACTIVITY]: 'Tracked Activities',
  [feedType.DAILY_SUMMARY]: 'Daily Summary',
  [feedType.ARCHIVED]: 'Deleted Notifications',
  [feedType.CHECK_IN]: 'Check-in',
  [feedType.CLIENT_STATUS]: 'Client Status',
  [feedType.SPECIAL]: 'Special Reminders',
  [feedType.WORKOUT_FEEDBACK_REQUEST]: 'Feedback Requested',
};

export default texts;
