import React, {
  useContext,
  useState,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import { useRouteMatch } from 'react-router-dom';
import { Container as DraggableContainer } from 'react-smooth-dnd';
import uuid from 'uuid/v4';
import moment from 'moment';
import format from 'string-template';
import ReactHtmlParser from 'react-html-parser';

import { DateFormat } from '../../../../../utils/date';
import { removeSpaces } from '../../../../../utils/string';
import useLogger from '../../../../../hooks/useLogger';
import { CoachingActivity } from '../../../../../utils/log';
import EmptyView from '../../../../components/EmptyView';
import ConfirmDialog from '../../../../components/ConfirmDialog';
import AppCustomCheckin from '../../../../Model/AppCustomCheckin';
import User from '../../../../../Model/User';
import useComponentMounted from '../../../../../hooks/useComponentMounted';
import LoadingOverlay from '../../../../components/LoadingOverlay';
import CustomizationContext, { withCustomizationContextReady } from '../../../../context/CustomizationContext';
import useToast from '../../../../hooks/useToast';
import ExternalCoachContext from '../../../../context/ExternalCoachContext';
import CheckInItem from './components/CheckInItem';
import QuestionInputModal from './components/QuestionInputModal';

import texts from './texts.json';
import {
  Container,
  Title,
  Description,
  TitleContainer,
  StyledButton,
  StyledList,
  HeaderContainer,
  ConfigContainer,
  StyledQuestionIcon,
  SubTitle,
  CheckInDayText,
  CheckInDayLabel,
  CheckInDayContainer,
  CheckInDay,
  ClientCount,
  StyledStarIcon,
  ImageUploadOptionsContainer,
  StyledRadioButtonGroup,
} from './styles';
import { baseCheckInQuestionList, baseCheckInQuestionDefinition } from './utils';
import CheckInDayConfirmContent from './components/CheckInDayConfirmContent';

const CheckInCustomization = () => {
  const { params: { userId: coachId } } = useRouteMatch();
  const { customCheckInDoc, appCustomizationDoc, setCustomCheckInDoc } = useContext(CustomizationContext);
  const { showToast } = useToast();
  const [checkInQuestionList, setCheckInQuestionList] = useState([]);
  const [selectedCheckInDay, setSelectedCheckInDay] = useState();
  const [userCountByCheckInDay, setUserCountByCheckInDay] = useState({});
  const [checkInQuestionDefinition, setCheckInQuestionDefinition] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [activeUsersCollection, setActiveUsersCollection] = useState();
  const [newCheckInDay, setNewCheckInDay] = useState();
  const [isImageUploadRequired, setIsImageUploadRequired] = useState(false);
  const isComponentMountedRef = useComponentMounted();
  const { coachDoc: { name: coachName } } = useContext(ExternalCoachContext);
  const { logCoachingActivity } = useLogger();

  const customDocCheckInQuestionList = customCheckInDoc?.checkInQuestions;
  const customDocCheckInQuestionDefinition = customCheckInDoc?.checkInQuestionDefinition;
  // Default check in day is set to 0 here when a custom check in doc is not found.
  // There can be a scenario where there is no custom check in doc for a coach, if the
  // coach has not customized the check in questions or set a custom check-in day in the dashbord,
  const customCheckInDay = customCheckInDoc?.checkInDay || 0;
  const checkInDayUpdatedby = customCheckInDoc?.checkInDayUpdatedBy || '-';
  const checkInDayUpdatedAt = customCheckInDoc?.checkInDayUpdatedAt
    ? moment(customCheckInDoc?.checkInDayUpdatedAt.toDate()).format(DateFormat.TEXT_DATE_FORMAT)
    : '-';
  const isImageUploadCompulsory = customCheckInDoc?.isImageUploadCompulsory || false;

  useEffect(() => {
    if (customDocCheckInQuestionList && customDocCheckInQuestionDefinition) {
      setCheckInQuestionList(customDocCheckInQuestionList);
      setCheckInQuestionDefinition(customDocCheckInQuestionDefinition);
    } else {
      setCheckInQuestionList(baseCheckInQuestionList);
      setCheckInQuestionDefinition(baseCheckInQuestionDefinition);
    }
    setSelectedCheckInDay(customCheckInDay);
    setIsImageUploadRequired(isImageUploadCompulsory);
  }, [
    customCheckInDoc,
    customDocCheckInQuestionList,
    customDocCheckInQuestionDefinition,
    customCheckInDay,
    isImageUploadCompulsory,
  ]);

  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      const userCollection = await User.getActiveUsersByCoach(coachId);
      if (isComponentMountedRef.current) {
        setActiveUsersCollection(userCollection);
        setIsLoading(false);
      }
    };
    init();
  }, [
    isComponentMountedRef,
    coachId,
  ]);

  // calculate user count by check in day
  useEffect(() => {
    if (activeUsersCollection && activeUsersCollection.docs.length > 0) {
      const checkInDayUserCount = activeUsersCollection.docs.reduce((res, userDoc) => {
        const { checkInDay } = userDoc;
        if (!res[checkInDay]) {
          res[checkInDay] = 0;
        }
        res[checkInDay] += 1;
        return res;
      }, {});
      setUserCountByCheckInDay(checkInDayUserCount);
    }
  }, [
    activeUsersCollection,
  ]);

  const defaultCheckInQuestionList = useMemo(() => (
    checkInQuestionList.filter((checkInQuestion) => checkInQuestionDefinition
      && !!checkInQuestionDefinition[checkInQuestion]?.isDefault)
  ), [
    checkInQuestionList,
    checkInQuestionDefinition,
  ]);

  const customCheckInQuestionList = useMemo(() => (
    checkInQuestionList.filter((checkInQuestion) => checkInQuestionDefinition
      && !checkInQuestionDefinition[checkInQuestion]?.isDefault)
  ), [
    checkInQuestionList,
    checkInQuestionDefinition,
  ]);

  const updateCheckInDoc = useCallback(async (updatedCheckInQuestionList, updatedCheckInQuestionDefinition) => {
    setIsLoading(true);
    if (!customCheckInDoc) {
      const newCustomCheckInDoc = await AppCustomCheckin.addDoc({
        checkInQuestions: updatedCheckInQuestionList,
        createdAt: new Date(),
      });
      setCustomCheckInDoc(newCustomCheckInDoc);
      if (updatedCheckInQuestionDefinition) {
        newCustomCheckInDoc.setCheckinQuestionDefinition(updatedCheckInQuestionDefinition);
      }
      newCustomCheckInDoc.setCheckinQuestions(updatedCheckInQuestionList);
      appCustomizationDoc.setBaseCheckin(newCustomCheckInDoc.id);
    } else {
      if (updatedCheckInQuestionDefinition) {
        customCheckInDoc.setCheckinQuestionDefinition(updatedCheckInQuestionDefinition);
      }
      customCheckInDoc.setCheckinQuestions(updatedCheckInQuestionList);
    }
    logCoachingActivity(CoachingActivity.UPDATED_CHECKIN_SETTINGS);
    if (isComponentMountedRef.current) {
      setIsLoading(false);
    }
  }, [
    appCustomizationDoc,
    customCheckInDoc,
    setCustomCheckInDoc,
    isComponentMountedRef,
    logCoachingActivity,
  ]);

  const addNewQuestion = useCallback((question, prompt) => {
    setIsModalOpen(false);
    if (question && prompt) {
      let newCheckInQuestionList = null;
      let newCheckInQuestionDefinition = null;
      let newCheckInQuestionId = uuid();
      let oldCheckInQuestionObject = null;
      let isDuplicate = false;

      if (checkInQuestionDefinition) {
        Object.keys(checkInQuestionDefinition).forEach((checkInQuestion) => {
          if (removeSpaces(checkInQuestionDefinition[checkInQuestion].question) === removeSpaces(question)) {
            oldCheckInQuestionObject = checkInQuestionDefinition[checkInQuestion];
            // bring back the deleted question if there is any by using the old id
            newCheckInQuestionId = oldCheckInQuestionObject.id;

            if (checkInQuestionDefinition[checkInQuestion].active) {
              isDuplicate = true;
            }
          }
        });

        if (isDuplicate) {
          showToast(texts.duplicateCheckin, { warning: true });
          return;
        }
        newCheckInQuestionList = [...checkInQuestionList];
        if (!checkInQuestionList.includes(newCheckInQuestionId)) {
          newCheckInQuestionList.push(newCheckInQuestionId);
        }
      } else {
        newCheckInQuestionList = [newCheckInQuestionId];
      }

      // check new question in the question definition. if present change the active status
      if (checkInQuestionDefinition && oldCheckInQuestionObject) {
        checkInQuestionDefinition[oldCheckInQuestionObject.id].active = true;
        newCheckInQuestionDefinition = { ...checkInQuestionDefinition };
      } else {
        newCheckInQuestionDefinition = {
          ...checkInQuestionDefinition,
          [newCheckInQuestionId]: {
            question,
            prompt,
            id: newCheckInQuestionId,
            active: true,
            isDefault: false,
          },
        };
      }

      updateCheckInDoc(newCheckInQuestionList, newCheckInQuestionDefinition);
    }
  }, [
    updateCheckInDoc,
    checkInQuestionList,
    checkInQuestionDefinition,
    showToast,
  ]);

  // move position of array element after drag and drop
  const move = (array, from, to) => {
    array.splice(to, 0, array.splice(from, 1)[0]);
    return [...array];
  };

  const onDrop = useCallback(({ removedIndex, addedIndex }, isDefault = true) => {
    const updatedRemovedIndex = isDefault ? removedIndex : (removedIndex + defaultCheckInQuestionList.length);
    const updatedAddedIndex = isDefault ? addedIndex : (addedIndex + defaultCheckInQuestionList.length);
    if (checkInQuestionList) {
      const newArray = move([...checkInQuestionList], updatedRemovedIndex, updatedAddedIndex);
      updateCheckInDoc(newArray, checkInQuestionDefinition);
    }
  }, [
    updateCheckInDoc,
    checkInQuestionList,
    defaultCheckInQuestionList,
    checkInQuestionDefinition,
  ]);

  const handleDelete = useCallback((removedIndex) => {
    if (checkInQuestionList && checkInQuestionDefinition) {
      const newArray = [...checkInQuestionList];
      const removedCheckInQuestion = newArray.splice(removedIndex, 1)[0];

      const newDefinition = { ...checkInQuestionDefinition };
      delete newDefinition[removedCheckInQuestion];
      updateCheckInDoc(newArray, newDefinition);
    }
  }, [
    updateCheckInDoc,
    checkInQuestionList,
    checkInQuestionDefinition,
  ]);

  const handleEdit = useCallback((editedIndex, editedQuestion, editedPrompt) => {
    if (checkInQuestionList && checkInQuestionDefinition) {
      const editedCheckInQuestionId = checkInQuestionList[editedIndex];
      checkInQuestionDefinition[editedCheckInQuestionId].question = editedQuestion;
      checkInQuestionDefinition[editedCheckInQuestionId].prompt = editedPrompt;
      const newDefinition = { ...checkInQuestionDefinition };
      updateCheckInDoc(checkInQuestionList, newDefinition);
    }
  }, [
    updateCheckInDoc,
    checkInQuestionList,
    checkInQuestionDefinition,
  ]);

  const handleHide = useCallback((hiddenIndex) => {
    if (checkInQuestionList && checkInQuestionDefinition) {
      const hiddenCheckInQuestionId = checkInQuestionList[hiddenIndex];
      checkInQuestionDefinition[hiddenCheckInQuestionId].active = !checkInQuestionDefinition[hiddenCheckInQuestionId]
        .active;
      const newDefinition = { ...checkInQuestionDefinition };
      updateCheckInDoc(checkInQuestionList, newDefinition);
    }
  }, [
    updateCheckInDoc,
    checkInQuestionList,
    checkInQuestionDefinition,
  ]);

  const handleCheckInDayClick = useCallback(async () => {
    setIsLoading(true);
    if (!customCheckInDoc) {
      const newCustomCheckInDoc = await AppCustomCheckin.addDoc({
        createdAt: new Date(),
      });
      setCustomCheckInDoc(newCustomCheckInDoc);
      newCustomCheckInDoc.setCheckInDay(newCheckInDay);
      newCustomCheckInDoc.setCheckInDayUpdatedBy(coachName);
      appCustomizationDoc.setBaseCheckin(newCustomCheckInDoc.id);
    } else {
      customCheckInDoc.setCheckInDay(newCheckInDay);
      customCheckInDoc.setCheckInDayUpdatedBy(coachName);
    }
    if (isComponentMountedRef.current) {
      setIsLoading(false);
      setIsConfirmDialogOpen(false);
    }
  }, [
    customCheckInDoc,
    appCustomizationDoc,
    setCustomCheckInDoc,
    isComponentMountedRef,
    newCheckInDay,
    coachName,
  ]);

  const handleImageUploadRequirementClick = useCallback(async (isRequired) => {
    setIsLoading(true);
    if (!customCheckInDoc) {
      const newCustomCheckInDoc = await AppCustomCheckin.addDoc({
        createdAt: new Date(),
        isImageUploadCompulsory: isRequired,
        imageUploadCompulsoryUpdatedAt: new Date(),
      });
      if (isComponentMountedRef.current) {
        setCustomCheckInDoc(newCustomCheckInDoc);
      }
      appCustomizationDoc.setBaseCheckin(newCustomCheckInDoc.id);
    } else {
      customCheckInDoc.setImageUploadRequirement(isRequired);
    }
    if (isComponentMountedRef.current) {
      setIsLoading(false);
    }
  }, [
    customCheckInDoc,
    appCustomizationDoc,
    setCustomCheckInDoc,
    isComponentMountedRef,
  ]);

  return (
    <Container>
      <ConfigContainer>
        <HeaderContainer>
          <TitleContainer>
            <Title>{texts.checkInDay.title}</Title>
            <SubTitle>{texts.checkInDay.description}</SubTitle>
          </TitleContainer>
        </HeaderContainer>
        <CheckInDayContainer>
          {moment.weekdaysShort().map((day, index) => (
            <CheckInDay
              key={day}
              onClick={() => {
                setNewCheckInDay(index);
                setIsConfirmDialogOpen(true);
              }}
              isSelected={Object.keys(userCountByCheckInDay).includes(String(index))
                || (selectedCheckInDay === index)}
            >
              {day}
              {userCountByCheckInDay[index] && (
                <ClientCount>
                  {`${userCountByCheckInDay[index]} ${texts.clients}`}
                </ClientCount>
              )}
              {(selectedCheckInDay === index) && <StyledStarIcon />}
            </CheckInDay>
          ))}
        </CheckInDayContainer>
        <CheckInDayContainer>
          <CheckInDayLabel>
            {ReactHtmlParser(format(texts.checkInDayLabel,
              {
                checkInDay: moment.weekdays()[selectedCheckInDay],
                updatedBy: checkInDayUpdatedby,
                updatedAt: checkInDayUpdatedAt,
              }))}
          </CheckInDayLabel>
        </CheckInDayContainer>
      </ConfigContainer>
      <ConfigContainer>
        <HeaderContainer>
          <TitleContainer>
            <Title>{texts.checkInImages.title}</Title>
            <SubTitle>{texts.checkInImages.description}</SubTitle>
          </TitleContainer>
        </HeaderContainer>
        <ImageUploadOptionsContainer>
          <StyledRadioButtonGroup
            options={[
              {
                label: texts.mandatoryText,
                value: texts.mandatory,
              }, {
                label: texts.optionalText,
                value: texts.optional,
              },
            ]}
            selectedOption={isImageUploadRequired ? texts.mandatory : texts.optional}
            onOptionChange={(value) => handleImageUploadRequirementClick(value === texts.mandatory)}
          />
        </ImageUploadOptionsContainer>
        <CheckInDayContainer>
          <CheckInDayLabel>
            {texts.checkInImageUpload}
          </CheckInDayLabel>
          <CheckInDayText>
            {isImageUploadRequired ? texts.mandatory : texts.optional}
          </CheckInDayText>
        </CheckInDayContainer>
      </ConfigContainer>
      <ConfigContainer>
        <HeaderContainer>
          <TitleContainer>
            <Title>{texts.checkInQuestions.title}</Title>
            <SubTitle>{texts.checkInQuestions.description}</SubTitle>
          </TitleContainer>
        </HeaderContainer>
        <Description>{texts.defaultCheckins}</Description>
        <StyledList>
          <DraggableContainer dragHandleSelector=".drag-handle" lockAxis="y" onDrop={onDrop}>
            {defaultCheckInQuestionList.map((checkInQuestionId, index) => (
              <CheckInItem
                index={index}
                handleDelete={handleDelete}
                question={checkInQuestionDefinition && checkInQuestionDefinition[checkInQuestionId].question}
                prompt={checkInQuestionDefinition && checkInQuestionDefinition[checkInQuestionId].prompt}
                key={checkInQuestionId}
                isDefault
                handleEdit={handleEdit}
                handleHide={handleHide}
                isHidden={!(checkInQuestionDefinition && checkInQuestionDefinition[checkInQuestionId].active)}
              />
            ))}
          </DraggableContainer>
        </StyledList>
        <Description>{texts.customCheckins}</Description>
        <StyledList>
          <DraggableContainer dragHandleSelector=".drag-handle" lockAxis="y" onDrop={(data) => onDrop(data, false)}>
            {customCheckInQuestionList.map((checkInQuestionId, index) => (
              <CheckInItem
                // we only update the main check-in question list which includes both default
                // and custom check-in questions
                index={index + defaultCheckInQuestionList.length}
                handleDelete={handleDelete}
                question={checkInQuestionDefinition && checkInQuestionDefinition[checkInQuestionId]?.question}
                prompt={checkInQuestionDefinition && checkInQuestionDefinition[checkInQuestionId]?.prompt}
                key={checkInQuestionId}
                isDefault={false}
                handleEdit={handleEdit}
                handleHide={handleHide}
                isHidden={!(checkInQuestionDefinition && checkInQuestionDefinition[checkInQuestionId]?.active)}
              />
            ))}
            {(!customCheckInQuestionList.length) && <EmptyView content={texts.noCustomCheckInQuestions} />}
          </DraggableContainer>
        </StyledList>
        <StyledButton
          variant="contained"
          onClick={() => setIsModalOpen(true)}
        >
          <StyledQuestionIcon />
          {texts.addNewQuestion}
        </StyledButton>
        <QuestionInputModal
          isOpen={isModalOpen}
          dialogTexts={{
            title: texts.dialogTitle,
            content: texts.dialogContent,
          }}
          onCancel={() => setIsModalOpen(false)}
          onConfirm={addNewQuestion}
        />
        <ConfirmDialog
          isOpen={isConfirmDialogOpen}
          onConfirm={() => handleCheckInDayClick()}
          onCancel={() => setIsConfirmDialogOpen(false)}
          dialogTexts={{
            title: texts.confirmCheckInDayChange,
            content: <CheckInDayConfirmContent
              currentDay={moment.weekdays(selectedCheckInDay)}
              newDay={moment.weekdays(newCheckInDay)}
            />,
          }}
        />
      </ConfigContainer>
      <LoadingOverlay isLoading={isLoading} />
    </Container>
  );
};

export default compose(
  withCustomizationContextReady,
  observer,
)(CheckInCustomization);
