import React, {
  useContext,
  useMemo,
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import moment from 'moment';
import format from 'string-template';

import MealPlanContext, { withMealPlanContextReady } from '../../../../../context/MealPlanContext';
import useComponentMounted from '../../../../../../hooks/useComponentMounted';
import UserNutritionMacroGoals from '../../../../../Model/UserNutritionMacroGoals';
import { getMacroNutrientsFromCalories } from '../../../../../utils/meals';
import NutritionalSummary from '../../NutritionalSummary';

import colors from '../../../../../../styles/colors';
import BarChart from '../../../../../components/BarChart';
import { DateFormat } from '../../../../../../utils/date';

import {
  ChartContainer,
  ChartTitle,
  TablesWrapper,
} from './styles';
import texts from './texts.json';

const BarChartWidth = 400;

const NutritionalStats = ({ user, nutritionLogs }) => {
  const {
    mealPlanAssignments,
  } = useContext(MealPlanContext);

  const [macroGoalsDoc, setMacroGoalsDoc] = useState({});

  const isComponentMountedRef = useComponentMounted();

  useEffect(() => {
    const init = async () => {
      const doc = await UserNutritionMacroGoals.getById(user) || {};
      if (isComponentMountedRef.current) {
        setMacroGoalsDoc(doc);
      }
    };
    init();
  }, [
    user,
    isComponentMountedRef,
  ]);

  const userMealPlanAssignment = useMemo(() => (
    mealPlanAssignments?.find((assignment) => assignment.id === user) || {}
  ), [
    user,
    mealPlanAssignments,
  ]);

  const userMacros = useMemo(() => {
    // Get macros and percentages from macro goals doc
    const {
      macroAverages: {
        macros: macroGoalsMacros,
        percentages: macroGoalsPercentages,
      } = {},
      totalDailyCalories,
    } = macroGoalsDoc;

    // These are the overwritten user's goals
    if (!!macroGoalsMacros && !!macroGoalsPercentages) {
      return {
        protein: macroGoalsMacros.protein,
        proteinPercentage: macroGoalsPercentages.protein,
        fat: macroGoalsMacros.fat,
        fatPercentage: macroGoalsPercentages.fat,
        carbs: macroGoalsMacros.carbs,
        carbsPercentage: macroGoalsPercentages.carbs,
        calories: totalDailyCalories,
      };
    }

    // If no goals were found, get the meal plan percentages
    const {
      assignmentMacroAverages: {
        percentages: mealPlanPercentages = {},
      } = {},
    } = userMealPlanAssignment;

    // And then calculate macro nutrients using meal plan percentages
    const calculatedMacroNutrients = getMacroNutrientsFromCalories(totalDailyCalories, mealPlanPercentages);

    return {
      protein: calculatedMacroNutrients.protein || 0,
      proteinPercentage: mealPlanPercentages.protein || 0,
      fat: calculatedMacroNutrients.fat || 0,
      fatPercentage: mealPlanPercentages.fat || 0,
      carbs: calculatedMacroNutrients.carbs || 0,
      carbsPercentage: mealPlanPercentages.carbs || 0,
      calories: totalDailyCalories || 0,
    };
  }, [
    userMealPlanAssignment,
    macroGoalsDoc,
  ]);

  const mealsSummary = useMemo(() => {
    const summary = nutritionLogs.reduce((acc, { macros: logMacros }) => {
      acc.protein += logMacros.protein;
      acc.calories += logMacros.calories;
      acc.fat += logMacros.fat;
      acc.carbs += logMacros.carbs;
      return acc;
    }, {
      protein: 0,
      calories: 0,
      fat: 0,
      carbs: 0,
    });
    const nutritionLogsLength = nutritionLogs.length;
    return {
      protein: Math.round(summary.protein / nutritionLogsLength),
      calories: Math.round(summary.calories / nutritionLogsLength),
      fat: Math.round(summary.fat / nutritionLogsLength),
      carbs: Math.round(summary.carbs / nutritionLogsLength),
    };
  }, [nutritionLogs]);

  return (
    <TablesWrapper>
      <ChartContainer>
        <ChartTitle>
          {texts.nutritionalChart}
        </ChartTitle>
        <NutritionalSummary
          mealPlan={userMacros}
          mealsSummary={mealsSummary}
        />
      </ChartContainer>
      <ChartContainer>
        <ChartTitle>
          {texts.caloriesChart}
        </ChartTitle>
        <BarChart
          width={BarChartWidth}
          data={nutritionLogs.map((log) => ({
            Calories: Math.round(log.macros.calories || 0),
            name: moment(log.startTime.toDate()).format(DateFormat.DATE_MONTH_SHORT_DISPLAY_FORMAT),
          }))}
          toolTipFormatter={(value) => format(texts.kCal, { value })}
          keys={[{ name: 'Calories', color: colors.shades.secondary3 }]}
        />
      </ChartContainer>
      <ChartContainer>
        <ChartTitle>
          {texts.macrosChart}
        </ChartTitle>
        <BarChart
          width={BarChartWidth}
          data={nutritionLogs.map((log) => ({
            Protein: Math.round(log.macros.protein || 0),
            Carbohydrates: Math.round(log.macros.carbs || 0),
            Fat: Math.round(log.macros.fat || 0),
            name: moment(log.startTime.toDate()).format(DateFormat.DATE_MONTH_SHORT_DISPLAY_FORMAT),
          }))}
          toolTipFormatter={(value) => format(texts.grams, { value })}
          keys={[
            { name: 'Protein', color: colors.shades.warning3 },
            { name: 'Carbohydrates', color: colors.shades.secondary2 },
            { name: 'Fat', color: colors.shades.success1 },
          ]}
        />
      </ChartContainer>
      <ChartContainer>
        <ChartTitle>
          {texts.proteinChart}
        </ChartTitle>
        <BarChart
          width={BarChartWidth}
          data={nutritionLogs.map((log) => ({
            Protein: Math.round(log.macros.protein || 0),
            name: moment(log.startTime.toDate()).format(DateFormat.DATE_MONTH_SHORT_DISPLAY_FORMAT),
          }))}
          toolTipFormatter={(value) => format(texts.grams, { value })}
          keys={[{ name: 'Protein', color: colors.shades.warning3 }]}
        />
      </ChartContainer>
      <ChartContainer>
        <ChartTitle>
          {texts.carbsChart}
        </ChartTitle>
        <BarChart
          width={BarChartWidth}
          data={nutritionLogs.map((log) => ({
            Carbohydrates: Math.round(log.macros.carbs || 0),
            name: moment(log.startTime.toDate()).format(DateFormat.DATE_MONTH_SHORT_DISPLAY_FORMAT),
          }))}
          toolTipFormatter={(value) => format(texts.grams, { value })}
          keys={[{ name: 'Carbohydrates', color: colors.shades.secondary2 }]}
        />
      </ChartContainer>
      <ChartContainer>
        <ChartTitle>
          {texts.fatChart}
        </ChartTitle>
        <BarChart
          width={BarChartWidth}
          data={nutritionLogs.map((log) => ({
            Fat: Math.round(log.macros.fat || 0),
            name: moment(log.startTime.toDate()).format(DateFormat.DATE_MONTH_SHORT_DISPLAY_FORMAT),
          }))}
          toolTipFormatter={(value) => format(texts.grams, { value })}
          keys={[{ name: 'Fat', color: colors.shades.success1 }]}
        />
      </ChartContainer>
    </TablesWrapper>
  );
};

NutritionalStats.propTypes = {
  user: PropTypes.string.isRequired,
  nutritionLogs: PropTypes.array,
};

NutritionalStats.defaultProps = {
  nutritionLogs: [],
};

export default compose(
  withMealPlanContextReady,
  observer,
)(NutritionalStats);
