import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'mobx-react';
import * as Sentry from '@sentry/browser';
import { posthog } from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';

import App from './App';
import * as serviceWorker from './serviceWorker';
import sessionStore from './stores/sessionStore';
import { FirebaseContextProvider } from './context/FirebaseContext';
import config from './config';

const {
  posthog: { apiHost, apiKey },
  sentry: { projectId, organization },
} = config;

posthog.init(apiKey);

const {
  sentry: { dsn, environment },
} = config;

const sentryOptions = {
  dsn,
  environment,
  integrations: [new posthog.SentryIntegration(
    posthog,
    organization,
    projectId,
    ['error', 'info'],
  )],
};

Sentry.init(sentryOptions);

ReactDOM.render(
  <React.StrictMode>
    <PostHogProvider
      apiKey={apiKey}
      options={{ api_host: apiHost }}
    >
      <Provider sessionStore={sessionStore}>
        <FirebaseContextProvider>
          <Router>
            <App />
          </Router>
        </FirebaseContextProvider>
      </Provider>
    </PostHogProvider>

  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
