import styled from 'styled-components';

import colors from '../../../styles/colors';

const StyledHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  background-color: ${colors.shades.gamma5};
  position: relative;

  &:after {
    content: "";
    width: 100%;
    height: 50px;
    background-color: ${colors.shades.gamma5};
    position: absolute;
    top: 100%;
    left: 0;
  }
`;

export {
  StyledHeader,
};
