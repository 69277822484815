import { IconButton } from '@mui/material';
import styled from 'styled-components';
import colors from '../../../../../styles/colors';
import Portal from '../../../../components/Portal';

const paperStyles = {
  backgroundColor: 'transparent',
  boxShadow: 'none',
};

const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
  color: ${colors.base.alpha};
`;

const StyledIframe = styled(Portal)`
  border: none;
  box-shadow: 0 0 10px ${colors.shades.gamma11};
  border-radius: 20px;
  width: 380px;
  height: 650px;
  background: ${colors.base.alpha};
`;

export {
  paperStyles,
  StyledIconButton,
  StyledIframe,
};
