import React, {
  useState,
  useCallback,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import format from 'string-template';
import { Container as DraggableContainer } from 'react-smooth-dnd';

import {
  caloriesToProtein,
  caloriesToCarbs,
  caloriesToFat,
  getMealServings,
  getMealsMacrosAvgs,
} from '../../utils/meals';
import { MealPortal } from '../MealPlanPortal';
import { AddButton } from '../../../components/Button/ActionButtons';
import {
  InfoTag,
  OkTag,
  NoticeTag,
} from '../../../components/Tags';
import { ReactComponent as EmojiFrownIcon } from '../../../assets/icons/v2/emoji-frown.svg';

import MealItem from './components/MealItem';

import {
  Container,
  ContentContainer,
  BucketContainer,
  BucketTitle,
  StyledModal,
  ModalContentWrapper,
  BucketHeader,
  BucketHeaderCell,
  NoMealPlaceholder,
  PlaceholderLabel,
  AddButtonContainer,
} from './styles';

import texts from './texts.json';

const MealPlanView = ({
  className,
  mealTimes,
  handleDelete,
  allowEdit,
  totalDailyCalories,
  macrosPercentages,
  scaleMeals,
  moveMeal,
  onAddRecipe,
  onGenerateMeals,
  alwaysShowAddButton,
}) => {
  const [openPortalModal, setOpenPortalModal] = useState(false);
  const [portalOptions, setPortalOptions] = useState({});

  const handleViewRecipe = useCallback((recipeId, recipeServings) => {
    setPortalOptions({ recipeId, servings: recipeServings });
    setOpenPortalModal(true);
  }, []);

  const handlePortalClose = useCallback(() => {
    setOpenPortalModal(false);
    setPortalOptions({});
  }, []);

  // A bucket summary can be based off either the target macros or the content of the bucket
  const isTargetSummary = useMemo(() => (
    !!totalDailyCalories
    && !!macrosPercentages?.protein
    && !!macrosPercentages?.carbs
    && !!macrosPercentages?.fat
  ), [
    totalDailyCalories,
    macrosPercentages,
  ]);

  const bucketSummary = useMemo(() => {
    const macros = {};
    if (isTargetSummary) {
      mealTimes.forEach(({ name, caloricSplit }) => {
        const targetCalories = Math.round((caloricSplit / 100) * totalDailyCalories);
        macros[name] = {
          calories: targetCalories,
          protein: Math.round(caloriesToProtein(targetCalories * (macrosPercentages.protein / 100))),
          carbs: Math.round(caloriesToCarbs(targetCalories * (macrosPercentages.carbs / 100))),
          fat: Math.round(caloriesToFat(targetCalories * (macrosPercentages.fat / 100))),
        };
      });
    } else {
      mealTimes.forEach(({ name, meals }) => {
        const macrosAvgsPerBucket = getMealsMacrosAvgs(meals, true);
        macros[name] = macrosAvgsPerBucket;
      });
    }
    return macros;
  }, [
    mealTimes,
    totalDailyCalories,
    macrosPercentages,
    isTargetSummary,
  ]);

  const getMacroColumnHeader = useCallback((macroName) => {
    if (isTargetSummary) {
      return `${macroName} ${texts.target}`;
    }
    return macroName;
  }, [isTargetSummary]);

  return (
    <Container className={className}>
      <ContentContainer>
        {mealTimes.map((mealTime, bucketIndex) => {
          const {
            name: bucketName,
            meals,
            caloricSplit,
          } = mealTime;
          const isEmpty = !meals?.length;
          return (
            <BucketContainer key={bucketName}>
              <BucketHeader>
                <BucketTitle>
                  {bucketName}
                  <InfoTag $small>
                    {format(texts.caloricSplit, { caloricSplit })}
                  </InfoTag>
                  <NoticeTag $small>
                    {format(texts.numOfRecipes, { recipes: meals ? meals.length : 0 })}
                  </NoticeTag>
                </BucketTitle>
                <BucketHeaderCell>
                  {getMacroColumnHeader(texts.calories)}
                  {!!bucketSummary[bucketName].calories && (
                    <OkTag>
                      {`${bucketSummary[bucketName].calories} ${texts.caloriesUnit}`}
                    </OkTag>
                  )}
                </BucketHeaderCell>
                <BucketHeaderCell>
                  {getMacroColumnHeader(texts.protein)}
                  {!!bucketSummary[bucketName].protein && (
                    <OkTag>
                      {`${bucketSummary[bucketName].protein}G`}
                    </OkTag>
                  )}
                </BucketHeaderCell>
                <BucketHeaderCell>
                  {getMacroColumnHeader(texts.carb)}
                  {!!bucketSummary[bucketName].carbs && (
                    <OkTag>
                      {`${bucketSummary[bucketName].carbs}G`}
                    </OkTag>
                  )}
                </BucketHeaderCell>
                <BucketHeaderCell>
                  {getMacroColumnHeader(texts.fat)}
                  {!!bucketSummary[bucketName].fat && (
                    <OkTag>
                      {`${bucketSummary[bucketName].fat}G`}
                    </OkTag>
                  )}
                </BucketHeaderCell>
                <BucketHeaderCell>
                  {texts.allergens}
                </BucketHeaderCell>
                <BucketHeaderCell>
                  {texts.prepTime}
                </BucketHeaderCell>
                <BucketHeaderCell>
                  {texts.suitableFor}
                </BucketHeaderCell>
              </BucketHeader>
              {isEmpty && (
                <NoMealPlaceholder>
                  <EmojiFrownIcon />
                  <PlaceholderLabel>{texts.noMealPlaceholder}</PlaceholderLabel>
                </NoMealPlaceholder>
              )}
              {!isEmpty && (
                <DraggableContainer
                  groupName="mealBuckets"
                  dragHandleSelector=".drag-handle"
                  lockAxis="y"
                  getChildPayload={(mealIndex) => ({
                    meal: meals[mealIndex],
                    sourceBucketIndex: bucketIndex,
                  })}
                  onDrop={(dropResult) => {
                    const { removedIndex, addedIndex, payload } = dropResult;
                    const { meal, sourceBucketIndex } = payload;
                    const destinationBucketIndex = bucketIndex;

                    // Dropped in the same place
                    if (removedIndex === null && addedIndex === null) {
                      return;
                    }

                    moveMeal({
                      removedIndex,
                      addedIndex,
                      sourceBucketIndex,
                      destinationBucketIndex,
                      meal,
                    });
                  }}
                >
                  {meals.map((meal, idx) => {
                    const {
                      recipe,
                      recipe: {
                        isScalingDisabled = false,
                      },
                    } = meal;
                    const servings = (scaleMeals && !isScalingDisabled) ? getMealServings({
                      meal,
                      mealTimes,
                      bucket: bucketName,
                      totalDailyCalories,
                    }) : 1;
                    return (
                      <MealItem
                        key={`${bucketName}-${recipe.id}`}
                        recipe={recipe}
                        bucketSummary={bucketSummary[bucketName]}
                        bucketIndex={bucketIndex}
                        handleDelete={handleDelete}
                        handleView={handleViewRecipe}
                        allowDelete={allowEdit}
                        allowDrag={allowEdit && !!moveMeal}
                        servings={servings}
                        index={idx}
                        enableColorCoding={isTargetSummary}
                      />
                    );
                  })}
                </DraggableContainer>
              )}
              {(isEmpty || alwaysShowAddButton) && (
                <AddButtonContainer>
                  {(!!onGenerateMeals && isEmpty) && (
                    <>
                      <AddButton onClick={() => onGenerateMeals(bucketName)}>
                        {texts.addRecipesAutomatically}
                      </AddButton>
                      <PlaceholderLabel>{texts.or}</PlaceholderLabel>
                    </>
                  )}
                  <AddButton variant="muted" onClick={() => onAddRecipe(bucketName)}>
                    {texts.addRecipesManually}
                  </AddButton>
                </AddButtonContainer>
              )}
            </BucketContainer>
          );
        })}
      </ContentContainer>
      <StyledModal
        open={openPortalModal}
        onClose={handlePortalClose}
      >
        <ModalContentWrapper>
          <MealPortal
            options={portalOptions}
          />
        </ModalContentWrapper>
      </StyledModal>
    </Container>
  );
};

MealPlanView.propTypes = {
  className: PropTypes.string,
  mealTimes: PropTypes.array,
  handleDelete: PropTypes.func,
  allowEdit: PropTypes.bool,
  totalDailyCalories: PropTypes.number,
  scaleMeals: PropTypes.bool,
  moveMeal: PropTypes.func,
  macrosPercentages: PropTypes.object,
  onAddRecipe: PropTypes.func,
  onGenerateMeals: PropTypes.func,
  alwaysShowAddButton: PropTypes.bool,
};

MealPlanView.defaultProps = {
  className: '',
  mealTimes: [],
  handleDelete: () => {},
  onAddRecipe: () => {},
  allowEdit: false,
  totalDailyCalories: 0,
  scaleMeals: false,
  moveMeal: null,
  macrosPercentages: {},
  alwaysShowAddButton: false,
  onGenerateMeals: null,
};

export default MealPlanView;
