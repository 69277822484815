import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  Paper,
} from '@mui/material';
import format from 'string-template';

import {
  proteinToCalories,
  carbsToCalories,
  fatToCalories,
} from '../../../../utils/meals';
import { macroPercentage } from './utils';

import {
  MealsAchievement,
  StyledTableCell,
  StyledTableContainer,
} from './styles';
import texts from './texts.json';

const NutritionalSummary = ({ mealPlan, mealsSummary }) => {
  const mealsSummaryProteinPercentage = macroPercentage(
    proteinToCalories(mealsSummary.protein || 0),
    (mealsSummary.calories || 0),
  );
  const mealsSummaryCarbsPercentage = macroPercentage(
    carbsToCalories(mealsSummary.carbs || 0),
    (mealsSummary.calories || 0),
  );
  const mealsSummaryFatPercentage = macroPercentage(
    fatToCalories(mealsSummary.fat || 0),
    (mealsSummary.calories || 0),
  );

  return (
    <StyledTableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell />
            <StyledTableCell>{texts.goal}</StyledTableCell>
            <StyledTableCell>{texts.achieved}</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <StyledTableCell>
              {texts.avgCalories}
            </StyledTableCell>
            <StyledTableCell>
              {format(texts.kCal, {
                value: (mealPlan?.calories || 0),
              })}
            </StyledTableCell>
            <StyledTableCell>
              {format(texts.kCal, {
                value: Math.round(mealsSummary.calories || 0),
              })}
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell>
              {texts.protein}
            </StyledTableCell>
            <StyledTableCell>
              {format(texts.nutritionStat, {
                value: Math.round(mealPlan?.protein || 0),
                percentage: mealPlan?.proteinPercentage || 0,
              })}
            </StyledTableCell>
            <StyledTableCell>
              <MealsAchievement>
                {format(texts.nutritionStat, {
                  value: Math.round(mealsSummary.protein || 0),
                  percentage: mealsSummaryProteinPercentage,
                })}
              </MealsAchievement>
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell>
              {texts.carbs}
            </StyledTableCell>
            <StyledTableCell>
              {format(texts.nutritionStat, {
                value: Math.round(mealPlan?.carbs || 0),
                percentage: (mealPlan?.carbsPercentage || 0),
              })}
            </StyledTableCell>
            <StyledTableCell>
              <MealsAchievement>
                {format(texts.nutritionStat, {
                  value: Math.round(mealsSummary.carbs || 0),
                  percentage: mealsSummaryCarbsPercentage,
                })}
              </MealsAchievement>
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell>
              {texts.fat}
            </StyledTableCell>
            <StyledTableCell>
              {format(texts.nutritionStat, {
                value: Math.round(mealPlan?.fat || 0),
                percentage: (mealPlan?.fatPercentage || 0),
              })}
            </StyledTableCell>
            <StyledTableCell>
              <MealsAchievement>
                {format(texts.nutritionStat, {
                  value: Math.round(mealsSummary.fat || 0),
                  percentage: mealsSummaryFatPercentage,
                })}
              </MealsAchievement>
            </StyledTableCell>
          </TableRow>
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
};

NutritionalSummary.propTypes = {
  mealPlan: PropTypes.object,
  mealsSummary: PropTypes.object,
};

NutritionalSummary.defaultProps = {
  mealPlan: {},
  mealsSummary: {},
};

export default NutritionalSummary;
