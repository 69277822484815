import pages from '../../config/pages';
import WorkoutsManager from '../../pages/WorkoutsManager';
import {
  WorkoutsManagerTab,
  getTabPath,
} from './workoutsManagerPages';

const routes = Object.values(WorkoutsManagerTab).map((type) => ({
  pageId: pages.WORKOUTS_MANAGER,
  workoutsManagerPath: getTabPath(type),
  component: WorkoutsManager,
}));

export default routes;
