const REACT_APP_ROOT_ID = 'root';

/**
 * Creates an element to be used as a portal for ReactDOM.createPortal.
 * The element will be placed inside the element used as a root container for the react app
 * @param {string} id id of the element to be created
 * @returns an empty div HTML element, sibling to the react app
 */

const createPortalElement = (id) => {
  const existingPortal = document.getElementById(id);
  if (existingPortal) {
    return existingPortal;
  }
  const portalElement = document.createElement('div');
  portalElement.setAttribute('id', id);
  document.getElementById(REACT_APP_ROOT_ID).appendChild(portalElement);
  return portalElement;
};

export {
  createPortalElement,
};
