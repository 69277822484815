import styled from 'styled-components';
import { ErrorMessage as FormikErrorMessage } from 'formik';

import colors from '../../styles/colors';

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin: ${({ hasLabel }) => (hasLabel ? '15px 0' : '0')};

  input {
    padding: ${({ isSmall }) => (isSmall ? '5px 0' : '10px 5px')};
    font-size: 16px;
  }

  select {
    padding: ${({ isSmall }) => (isSmall ? '5px 0' : '18px 0')};
  }

  input, select {
    background: ${colors.base.alpha};
    border: none;
    border-radius: 0;
    border-bottom: 1px solid ${colors.base.secondary2};
    color: ${colors.base.secondary2};
    outline: none;
    box-shadow: unset;

    margin-bottom: ${({ hasLabel }) => (hasLabel ? '0' : '25px')};
    width: ${({ hasLabel }) => (hasLabel ? 'auto' : '100%')};

    line-height: 148%;

    &.is-invalid {
      background-image: none;
    }

    &::placeholder {
      opacity: 0.34;
    }
    
    &:placeholder-shown {
      border-bottom: 1px solid ${colors.shades.gamma3};
    }

    &:focus, &.is-invalid:focus {
      line-height: 148%;
      background: ${colors.base.alpha};
      border: none;
      border-bottom: 1px solid ${colors.base.secondary2};
      outline: none;
      box-shadow: unset;
    }
  }

  input[type="checkbox"] {
    border: 1px solid ${colors.base.secondary2};
    user-select: none;
    width: 18px;
    height: 18px;
    padding: 0;
    margin: 0;
    margin-bottom: 20px;
  }

  label {
    margin-left: 13px;
    margin-bottom: 20px;
  }
`;

const ErrorMessage = styled(FormikErrorMessage)`
  position: absolute;
  top: calc(100% - 25px);
  margin-top: 0;
  font-size: 14px;
  color: ${colors.base.danger};
`;

export default Container;
export { ErrorMessage };
