import React from 'react';
import PropTypes from 'prop-types';
import format from 'string-template';

import feedTypeConfig from '../../../../context/FeedContext/config/feedTypeConfig';

import texts from './texts.json';
import {
  Container,
  EmptyFeed,
} from './styles';

const FeedEmptyView = ({ activityType }) => (
  <Container>
    <EmptyFeed>
      {format(texts.noActivities, {
        activityType: feedTypeConfig[activityType].title,
      })}
    </EmptyFeed>
  </Container>
);

FeedEmptyView.propTypes = {
  activityType: PropTypes.string,
};

FeedEmptyView.defaultProps = {
  activityType: '',
};

export default FeedEmptyView;
