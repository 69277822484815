import {
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { autorun } from 'mobx';

import pages from '../config/pages';

import NavigationContext from '../context/NavigationContext';
import UserContext from '../../context/UserContext';
import useCurrentLoggedInUser from '../../hooks/useCurrentLoggedInUser';
import useSessionStore from '../../hooks/useSessionStore';

const useChat = () => {
  const {
    userDoc: userInRouteDoc,
  } = useContext(UserContext);
  const { isCurrentLoggedInUserInPath } = useCurrentLoggedInUser();
  const { isCoachAssistant } = useSessionStore();
  const [userDocForChat, setUserDocForChat] = useState(userInRouteDoc);

  const { activePage } = useContext(NavigationContext);

  useEffect(() => {
    autorun(() => {
      setUserDocForChat(userInRouteDoc);
    });
  }, [
    userInRouteDoc,
  ]);

  return useMemo(() => ({
    userDocForChat,
    multiChannelMode: activePage === pages.CHAT,
    readOnlyMode: !isCurrentLoggedInUserInPath && !isCoachAssistant,
  }), [
    userDocForChat,
    isCurrentLoggedInUserInPath,
    activePage,
    isCoachAssistant,
  ]);
};

export default useChat;
