import { Document } from 'firestorter';

class BaseDocument extends Document {
  constructor(path, opts) {
    super(path, opts);
    this.disableObserverRefCount = !!opts?.disableObserverRefCount;
  }

  /**
   * Initializes the document by making sure that after calling this
   * method is safe to start consuming data from this document.
   * @param {object} [opts] Options
   * @param {boolean} [opts.fetch=true] A flag indicating if this document needs
   *                                    to be explicitly fetched.
   */
  async init({ fetch } = { fetch: true }) {
    if (fetch) {
      await this.fetch();
    }
    await this.ready();
  }

  get exists() {
    return !!this.snapshot && this.snapshot.exists;
  }

  /**
   * Updates the fields of the doc passed as parameter.
   * If the document exists, it will just update its value.
   * If the document does not exists, it will create the document and populate
   * the corresponding fields.
   * Note: make sure to use this method with an already initialized document.
   * (meaning init method was called before). Only by doing that you can make
   * sure that the snapshot prop is actually available to be used.
   * @param {object} fields The fields to update.
   * @param {object} [opts] Additional options.
   * @param {boolean} [opts.merge=true] Whether the data being updated should be merged when using set.
   */
  updateFields = async (fields, { merge } = { merge: true }) => {
    if (this.exists) {
      await this.update({ ...fields });
    } else {
      await this.set({ ...fields }, { merge });
    }
  };

  /**
   * Fetches a collection after checking that the collection itself is not active or already
   * loading, otherwise, it ensured that the collection is ready.
   *
   * @param {object} The firestore collection to initialize
   * @returns {Promise<void>} A promise that gets solved once the collection is considered to be initialized.
   */
  static async initCollection(collection) {
    if (!collection.isLoading && !collection.isActive) {
      await collection.fetch();
    }
    await collection.ready();
  }

  /**
   * Define the default ref count for all documents.
   * This fixes a RangeError: Maximum call stack size exceeded error thrown when collections get unubserved.
   * Reference: https://github.com/IjzerenHein/firestorter/issues/58#issuecomment-679401214
   */
  defaultRefCount = 0;

  addObserverRef() {
    if (this.disableObserverRefCount) {
      return this.defaultRefCount;
    }

    return super.addObserverRef();
  }

  releaseObserverRef() {
    if (this.disableObserverRefCount) {
      return this.defaultRefCount;
    }
    return super.releaseObserverRef();
  }
}

export default BaseDocument;
