import React from 'react';
import ReactDOM from 'react-dom';
import { createPortalElement } from '../../utils/portal';

/**
 * The HOC uses a react portal to move the DOM element outside the root
 *
 * @param {Object} Component - The child component to be rendered.
 * @return {ReactComponent}
 */
const withPortal = (portalName) => (Component) => (props) => {
  const portalElement = createPortalElement(portalName);
  return ReactDOM.createPortal(
    <Component {...props} />,
    portalElement,
  );
};

export default withPortal;
