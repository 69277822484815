import { getFirestore } from 'firestorter';
import moment from 'moment';

import { DateFormat } from '../utils/date';
import { firestorePaths } from '../utils/paths';
import BaseDocument from './BaseDocument';

class Coupon extends BaseDocument {
  get coach() {
    return this.data.coach;
  }

  get code() {
    return this.data.code;
  }

  get expires() {
    return this.data.expires && moment(this.data.expires, DateFormat.DATE_FORMAT_TIME_MINUTES);
  }

  get percentOff() {
    return this.data.percentOff;
  }

  get planCodes() {
    return this.data.planCodes;
  }

  static async getCouponByCoachAndCode(coach, code) {
    const db = getFirestore();
    const couponQuerySnapshot = await db.collection(firestorePaths.COUPON)
      .where('coach', '==', coach)
      .where('code', '==', code)
      .limit(1)
      .get();

    if (!couponQuerySnapshot.empty) {
      const couponDoc = couponQuerySnapshot.docs[0];
      const coupon = new Coupon(couponDoc.ref);
      await coupon.init();
      return coupon;
    }

    return null;
  }
}

export default Coupon;
