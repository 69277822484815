import styled from 'styled-components';

import { subtitle1 } from '../../styles/text';

import Button from '../Button';

const Container = styled.div`
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Message = styled.div`
  ${subtitle1}
  text-align: center;
  margin: 30px 0;
`;

const ButtonsContainer = styled.div`
  ${Button} {
    &:not(:first-child) {
      margin-top: 25px;
    }
  }
`;

export {
  Container,
  Message,
  ButtonsContainer,
};
