import styled, { css } from 'styled-components';

import { LinkButton } from '../../../../../components/Button';
import OnboardingCard from '../../../../../components/OnboardingCard';
import ContractDetails from '../../../../../components/ContractDetails';
import {
  subtitle2,
  caption1,
  sansSerifHeadlineFontStyles,
} from '../../../../../styles/text';

import { mixins } from '../../../../../styles/breakpoints';
import colors from '../../../../../styles/colors';
import { ReactComponent as ClockIconBase } from '../../../../../assets/onboarding/clock.svg';
import { ReactComponent as BellCrossedBase } from '../../../../../assets/onboarding/bell-crossed.svg';

const StyledOnboardingCard = styled(OnboardingCard)`
  background: ${colors.base.alpha};
  padding: 0;
  margin: 0;
  ${mixins.tabletMedia(css`
    max-width: 600px;
  `)}
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  scroll-behavior: smooth;
  background-color: ${colors.base.alpha};
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${colors.base.gamma};
  border-bottom: 1px solid ${colors.shades.gamma4};
  padding: 30px;
  width: 100%;
  margin-bottom: 24px;

  ${mixins.upToTabletMedia(css`
    display: none;
    margin: 0;
  `)}
`;

const FreeTitle = styled.div`
  ${subtitle2}
  font-size: 18px;
  text-align: center;
  padding: 10px;
`;

const SignOutInfo = styled.div`
  ${sansSerifHeadlineFontStyles};
  font-size: 13px;
  letter-spacing: 0.04em;
  font-weight: 600;
  color: ${colors.shades.gamma2};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 5px;
  max-width: 70%;
`;

const SignOutLink = styled(LinkButton)`
  ${caption1}
  display: block;
  text-decoration: underline;
  opacity: 0.4;
  font-size: 13px;
  letter-spacing: 0.04em;
  cursor: pointer;
  padding: 0 5px;
`;

const InfoContainer = styled.div`
  ${sansSerifHeadlineFontStyles}
  background-color: ${colors.shades.secondary5};
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  border-radius: 10px;
  margin: 10px 0;
`;

const WarningContainer = styled(InfoContainer)`
  background-color: ${colors.shades.warning1};
`;

const IconProps = css`
  margin-right: 10px;
`;

const ClockIcon = styled(ClockIconBase)`
  ${IconProps}
`;

const BellCrossedIcon = styled(BellCrossedBase)`
  ${IconProps}
`;

const StepTitle = styled.div`
  ${sansSerifHeadlineFontStyles};
  font-size: 16px;
  letter-spacing: 0.04em;
  margin-bottom: 10px;
  font-weight: 600;
  color: ${colors.shades.primary1};
  > span {
    color: ${colors.base.beta};
  }
`;

const StepContainer = styled.div`
  display: flex;
  flex-direction: ${({ $direction }) => $direction || 'column'};
  justify-content: space-between;
  width: auto;
  margin: 24px 0;
  ${mixins.upToTabletMedia(css`
    margin: 24px 8px;
  `)}
`;

const AgreementContainer = styled.div`
  ${subtitle2};
  color: ${colors.base.beta};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 50px;
`;

const StyledContractDetails = styled(ContractDetails)`
  padding: 15px 0 30px;
  ${sansSerifHeadlineFontStyles};
`;

const CheckboxContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AgreementText = styled.div`
  font-weight: normal;
  font-size: 16px;
  line-height: 19.2px;
  margin: 10px 0;
  text-align: left;
`;

const NoPaymentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const CoachHero = styled.img`
  aspect-ratio: 4/3;
  width: auto;
  height: auto;
  max-width: 100%;
  object-fit: cover;
  margin-bottom: 24px;
`;

const CoachTitle = styled.div`
  ${sansSerifHeadlineFontStyles};
  font-weight: 600;
  font-size: 15px;
  letter-spacing: 0.04em;
  color: ${colors.shades.gamma2};
`;

const HeaderTitle = styled.div`
  ${sansSerifHeadlineFontStyles};
  font-weight: 800;
  font-size: 20px;
  color: ${colors.base.secondary2};
`;

export {
  Container,
  StyledOnboardingCard,
  FreeTitle,
  SignOutLink,
  InfoContainer,
  WarningContainer,
  ClockIcon,
  BellCrossedIcon,
  StepTitle,
  StepContainer,
  AgreementContainer,
  StyledContractDetails,
  CheckboxContainer,
  AgreementText,
  NoPaymentContainer,
  HeaderContainer,
  CoachTitle,
  HeaderTitle,
  CoachHero,
  SignOutInfo,
};
