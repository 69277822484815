import React from 'react';
import PropTypes from 'prop-types';
import copy from 'clipboard-copy';

import useToast from '../../../hooks/useToast';

import {
  Container,
  ActionButton,
} from './styles';

import texts from './texts.json';

const ActionContainer = ({
  row,
  navigateToForm,
  handleFormDuplicateClick,
}) => {
  const { showToast } = useToast();

  const copyFormEmbedCode = (coachId, formId) => {
    // Create the HTML string to be inserted to webflow
    const htmlString = `<div id="form" data-coachId="${coachId}" data-formId="${formId}"></div>`;
    copy(htmlString);
    showToast(texts.copied);
  };

  return (
    <Container>
      <ActionButton onClick={() => navigateToForm(row.id)}>
        {texts.edit}
      </ActionButton>
      <ActionButton onClick={() => handleFormDuplicateClick(row.id)}>
        {texts.duplicate}
      </ActionButton>
      <ActionButton onClick={() => copyFormEmbedCode(row.coachId, row.id)}>
        {texts.copy}
      </ActionButton>
    </Container>
  );
};

ActionContainer.propTypes = {
  row: PropTypes.object.isRequired,
  navigateToForm: PropTypes.func.isRequired,
  handleFormDuplicateClick: PropTypes.func.isRequired,
};

export default ActionContainer;
