import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
} from '@mui/material';

import { DateFormat } from '../../../utils/date';

import {
  StatLabel,
  StatValue,
  StyledStatCell,
  StyledTableRow,
} from './styles';
import texts from './texts.json';

import {
  checkInColumns,
  circumferenceMeasurements,
} from './utils';

// This will generate a table with the the circumference measurements
// for the intial, current and last check-in + the difference between
// the measurement values for the current and last check-in for each
// circumference measurement type
const CircumferenceStatTable = ({
  activity,
  renderDifferenceStat,
}) => (
  <TableContainer>
    <Table>
      <TableHead>
        {/* header row */}
        <TableRow>
          <StyledStatCell />
          {checkInColumns.map((column) => (
            <StyledStatCell key={column}>
              <StatLabel>
                {texts[column]}
              </StatLabel>
            </StyledStatCell>
          ))}
          <StyledStatCell>
            <StatLabel>
              {texts.difference}
            </StatLabel>
          </StyledStatCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {/* row to show the dates */}
        {activity.checkInData && (
          <StyledTableRow key={activity.checkInData.current.submittedAt}>
            <StyledStatCell>
              <StatValue>
                {texts.dateLabel}
              </StatValue>
            </StyledStatCell>
            {checkInColumns.map((column) => (
              <StyledStatCell key={`time_${activity.id}_${column}`}>
                <StatValue $current={column === checkInColumns[2]}>
                  {(activity.checkInData[column]
                    && moment(activity.checkInData[column].submittedAt?.toDate())
                      .format(DateFormat.MONTH_NAME_DATE_FORMAT))
                    || texts.emptyCell}
                </StatValue>
              </StyledStatCell>
            ))}
            <StyledStatCell />
          </StyledTableRow>
        )}
        {/* circumference measurement rows */}
        {activity.checkInData && circumferenceMeasurements.map((measurement) => (
          <StyledTableRow key={measurement}>
            <StyledStatCell>
              <StatValue>
                {activity.checkInData.current[measurement]?.label}
              </StatValue>
            </StyledStatCell>
            {checkInColumns.map((column) => (
              <StyledStatCell key={`circ_${activity.id}_${column}`}>
                <StatValue $current={column === checkInColumns[2]}>
                  {(activity.checkInData[column] && activity.checkInData[column][measurement]?.value
                    && `${activity.checkInData[column][measurement].value}
                              ${activity.checkInData[column][measurement].unit}`)
                    || texts.emptyCell}
                </StatValue>
              </StyledStatCell>
            ))}
            <StyledStatCell>
              {renderDifferenceStat(measurement)}
            </StyledStatCell>
          </StyledTableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

CircumferenceStatTable.propTypes = {
  activity: PropTypes.object,
  renderDifferenceStat: PropTypes.func.isRequired,
};

CircumferenceStatTable.defaultProps = {
  activity: {},
};

export default CircumferenceStatTable;
