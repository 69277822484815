import convert from 'convert-units';

const MeasurementUnit = {
  IMPERIAL: 'IMPERIAL',
  METRIC: 'METRIC',
};

const getFtInFromCentimeters = (cm) => {
  const totalInches = Math.round(convert(cm).from('cm').to('in'));

  // Decompose the total inches into feet and inches
  const ft = Math.floor(totalInches / 12);
  const inches = totalInches % 12;

  return ({ ft, inches });
};

const getCentimetersFromFtIn = ({ ft, inches }, rounded = false) => {
  const ftCentimeters = convert(ft).from('ft').to('cm');
  const inCentimeters = convert(inches).from('in').to('cm');
  const cm = ftCentimeters + inCentimeters;

  return rounded ? Math.round(cm) : cm;
};

const getLbsFromKg = (kg, rounded = false) => {
  const lb = convert(kg).from('kg').to('lb');
  return rounded ? Math.round(lb) : lb;
};

const getKgFromLbs = (lbs, rounded = false) => {
  const kg = convert(lbs).from('lb').to('kg');
  return rounded ? Math.round(kg) : kg;
};

export {
  MeasurementUnit,
  getFtInFromCentimeters,
  getCentimetersFromFtIn,
  getLbsFromKg,
  getKgFromLbs,
};
