import React, {
  useContext,
  useState,
  useCallback,
} from 'react';

import { ChatSetting } from '../../../../../../../Model/Coach';
import useComponentMounted from '../../../../../../../hooks/useComponentMounted';
import useLogger from '../../../../../../../hooks/useLogger';
import { CoachingActivity } from '../../../../../../../utils/log';
import ExternalCoachContext from '../../../../../../context/ExternalCoachContext';
import useToast from '../../../../../../hooks/useToast';
import RadioButtonGroup from '../../../../../../components/RadioButtonGroup';
import { SettingOption } from '../utils';
import {
  SettingContainer as Container,
  HeaderContainer,
  SettingsActionContainer,
  TitleContainer,
  Title,
  SubTitle,
  ActionLabel,
} from '../styles';
import texts from './texts';

const ChatSettings = () => {
  const {
    coachDoc,
    coachDoc: {
      notificationsConfig: chatBotConfig,
    },
  } = useContext(ExternalCoachContext);

  const { showToast } = useToast();
  const isComponentMountedRef = useComponentMounted();
  const { logCoachingActivity } = useLogger();

  const [chatSettings, setChatSettings] = useState({
    [ChatSetting.ENABLE_WORKOUT_NOTIFICATIONS]: chatBotConfig[ChatSetting.ENABLE_WORKOUT_NOTIFICATIONS] === false
      ? SettingOption.DISABLED : SettingOption.ENABLED,
    [ChatSetting.ENABLE_SUMMARY_NOTIFICATIONS]: chatBotConfig[ChatSetting.ENABLE_SUMMARY_NOTIFICATIONS] === false
      ? SettingOption.DISABLED : SettingOption.ENABLED,
    [ChatSetting.ENABLE_FEEDBACK_NOTIFICATIONS]: chatBotConfig[ChatSetting.ENABLE_FEEDBACK_NOTIFICATIONS] === false
      ? SettingOption.DISABLED : SettingOption.ENABLED,
  });

  const handleChatSettingsChange = useCallback(async (setting, value) => {
    try {
      setChatSettings((prevState) => ({
        ...prevState,
        [setting]: value,
      }));

      await coachDoc.updateNotificationsConfig(setting, value === SettingOption.ENABLED);
      logCoachingActivity(CoachingActivity.UPDATED_CHAT_SETTINGS);
      if (isComponentMountedRef.current) {
        showToast(texts.chatSettingSaveSuccessful);
      }
    } catch (error) {
      showToast(texts.chatSettingSaveFailed, { error: true });
    }
  }, [
    coachDoc,
    isComponentMountedRef,
    showToast,
    logCoachingActivity,
  ]);

  return (
    <Container>
      <HeaderContainer>
        <TitleContainer>
          <Title>{texts.chat}</Title>
          <SubTitle>{texts.chatDescription}</SubTitle>
        </TitleContainer>
      </HeaderContainer>
      {Object.values(ChatSetting).map((setting, index) => (
        <SettingsActionContainer key={setting} $top={index === 0}>
          <ActionLabel>{texts.chatSetting[setting]}</ActionLabel>
          <RadioButtonGroup
            options={[
              {
                label: texts.enabled,
                value: SettingOption.ENABLED,
              },
              {
                label: texts.disabled,
                value: SettingOption.DISABLED,
              },
            ]}
            selectedOption={chatSettings[setting]}
            onOptionChange={(value) => handleChatSettingsChange(setting, value)}
          />
        </SettingsActionContainer>
      ))}
    </Container>
  );
};

export default ChatSettings;
