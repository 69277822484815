import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Activity from '../../../../../../Model/Activity';
import InputLabel from '../../../../../../components/v2/InputLabel';
import DialogRoundedModal from '../../../../../../components/DialogRoundedModal';
import {
  TimeUnit,
  convertSeconds,
  getSecondsFrom,
} from '../../../../../../utils/time';
import { ReactComponent as PauseIcon } from '../../../../../../assets/icons/v2/pause.svg';
import { ReactComponent as CreateIcon } from '../../../../../../assets/icons/v2/creation-plus-circle.svg';
import { PrimaryButton } from '../../../../../../components/Button/ActionButtons';

import { EditorActionType } from '../../WorkoutEditor/utils';
import {
  ModalBody,
  InputContainer,
  RestInput,
  StyledSelect,
  ButtonContainer,
} from './styles';
import texts from './texts';

const restTimeOptions = Object.values(TimeUnit).map((unit) => ({
  value: unit,
  label: texts.timeUnits[unit] || '',
}));

const RestModal = ({
  isOpen,
  onClose,
  onSave,
  activity,
}) => {
  const [restTime, setRestTime] = useState(convertSeconds(activity?.duration, activity?.durationDisplayUnit) || '');
  const [restTimeDisplayUnit, setRestTimeDisplayUnit] = useState(activity?.durationDisplayUnit || TimeUnit.SECONDS);

  const onAddUpdateRest = (values) => {
    const newActivity = new Activity({
      type: EditorActionType.REST,
      duration: getSecondsFrom(Number(values.restTime), values.restTimeDisplayUnit),
      durationDisplayUnit: values.restTimeDisplayUnit,
    });
    onSave([newActivity]);
  };

  return (
    <DialogRoundedModal
      title={activity ? texts.title.edit : texts.title.add}
      IconComponent={<PauseIcon />}
      fullWidth
      open={isOpen}
      onClose={onClose}
    >
      <ModalBody>
        <InputLabel>{texts.labels.rest}</InputLabel>
        <InputContainer>
          <RestInput
            value={restTime}
            onChange={(event) => setRestTime(event.target.value)}
            type="number"
          />
          <StyledSelect
            options={restTimeOptions}
            onChange={(event) => setRestTimeDisplayUnit(event.target.value)}
            value={restTimeDisplayUnit}
          />
          <ButtonContainer>
            <PrimaryButton
              onClick={() => onAddUpdateRest({ restTime, restTimeDisplayUnit })}
              icon={<CreateIcon />}
              disabled={!restTime || (restTime <= 0)}
              size="medium"
            >
              {activity ? texts.actions.update : texts.actions.add}
            </PrimaryButton>
          </ButtonContainer>
        </InputContainer>
      </ModalBody>
    </DialogRoundedModal>
  );
};

RestModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  activity: PropTypes.object,
};

RestModal.defaultProps = {
  onClose: () => {},
  onSave: () => {},
  activity: {},
};

export default RestModal;
