import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import styled, { css } from 'styled-components';

import Button from '../../../../../components/Button';
import colors from '../../../../../styles/colors';

const Title = styled(DialogTitle)`
  font-size: 21px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  padding-left: 40px;
`;

const StyledInput = styled(TextField)`
  min-width: 350px;
  width: 100%;
  margin-top: 5px;
`;

const StyledDialogContent = styled(DialogContent)`
  background: white;
  padding: 40px;
`;

const buttonStyles = css`
  height: 46px;
  width: fit-content;
  font-size: 14px;
  line-height: 16px;
  border-radius: 5px;
  text-transform: none;
  padding: 10px 20px;
  cursor: pointer;
`;

const ConfirmButton = styled(Button)`
  ${buttonStyles};
  background: ${colors.base.primary};
  border: 1px solid ${colors.shades.primary1};
  color: ${colors.base.beta};
  &:hover {
    background: ${colors.base.primary};
  }
`;

const ConfirmMessage = styled.div`
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  margin-top: 12px;
`;

const CancelButton = styled(Button)`
  ${buttonStyles};
  background: ${colors.shades.gamma10};
  border: 1px solid ${colors.shades.gamma8};
  color: ${colors.base.alpha};
  &:hover {
    background: ${colors.shades.gamma10};
  }
`;

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    background: ${colors.shades.gamma5};
  }
`;

const StyledDialogActions = styled(DialogActions)`
  padding: 20px 40px;
  justify-content: flex-start;
`;

export {
  ConfirmButton,
  ConfirmMessage,
  CancelButton,
  Title,
  StyledInput,
  StyledDialog,
  StyledDialogContent,
  StyledDialogActions,
};
