import React from 'react';

import { CardsContainer } from '../../../../../components/v2/Card';
import StripeConfiguration from './StripeConfiguration';
import CurrencyConfiguration from './CurrencyConfiguration';

const PaymentConfiguration = () => (
  <CardsContainer>
    <StripeConfiguration />
    <CurrencyConfiguration />
  </CardsContainer>

);

export default PaymentConfiguration;
