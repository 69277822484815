import React, { useContext } from 'react';
import LoadingPage from '../../../components/LoadingPage';
import InternalAssignmentsContext from './InternalAssignmentsContext';
import InternalAssignmentsContextProvider from './InternalAssignmentsContextProvider';

/**
 * Inserts InternalAssignmentsContextProvider.
 *
 * @param {Object} Component The component that needs the context provider.
 * @returns {Object} The component to render wrapped with the provider.
 */
const withInternalAssignmentsContextProvider = (Component) => (props) => (
  <InternalAssignmentsContextProvider>
    <Component {...props} />
  </InternalAssignmentsContextProvider>
);

const withInternalAssignmentsContextReady = (Component) => (props) => {
  const { isReady } = useContext(InternalAssignmentsContext);

  return isReady ? <Component {...props} /> : <LoadingPage />;
};

export {
  withInternalAssignmentsContextProvider,
  withInternalAssignmentsContextReady,
};
