import config from '../config';

const CONSOLE_BASE_URL = 'https://console.firebase.google.com/project';

/**
 * Gets the URL to use for the firebase firestore console for the path segments provided.
 */
const openFirestoreDocument = (path) => {
  const { projectId } = config.firebase;
  const consoleUrl = `${CONSOLE_BASE_URL}/${projectId}/firestore/databases/-default-/data`;
  const formattedPath = path.replace(/\//g, '~2F');
  const redirectUrl = `${consoleUrl}/~2F${formattedPath}`;
  window.open(redirectUrl, '_blank');
};

export {
  openFirestoreDocument,
};
