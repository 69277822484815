import styled, { css } from 'styled-components';
import { TextField } from '@mui/material';

import { DateContainer } from '../styles';

import colors from '../../../../../styles/colors';

const Container = styled.div`
  border: 1px solid ${colors.shades.secondary7};
  border-radius: 5px;
  width: 100%;
  display: flex;
  flex-direction: column;
  > :not(:last-child) {
    border-bottom: 1px solid ${colors.shades.secondary7};
  }
  > :first-child {
    border-radius: 5px 5px 0 0;
  }
  > :last-child {
    border-radius: 0 0 5px 5px;
  }
`;

const TitleContainer = styled.div`
  background: ${colors.shades.secondary10};
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 1px;
  padding: 16px;
  color: ${colors.shades.primary1};
`;

const ContentContainer = styled.div`
  background: ${colors.base.alpha};
  padding: 16px;
  display: flex;
  flex-direction: row;
  column-gap: 25px;
  width: 100%;
  align-items: center;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;
`;

const Label = styled.div`
  font-size: 9px;
  font-weight: 500;
  line-height: 9px;
  letter-spacing: 0.07em;
  ${({ $isAllergens }) => $isAllergens && css`
    color: ${colors.shades.danger5};
  `}
`;

const StyledTextField = styled(TextField)`
  width: 100%;
  border-radius: 5px;
  background-color: ${colors.base.alpha};
  border: 1px solid ${colors.shades.secondary7};
  padding: 0;
  min-width: 365px;
  .MuiTextField-root {
    border: unset;
  }
  .MuiOutlinedInput-notchedOutline {
    border: unset;
  }
  .MuiInputBase-input {
    border: unset;
    padding: 0;
  }
  .MuiInputBase-root {
    border: unset;
    padding: 12px;
    font-size: 14px;
    &:hover {
      .MuiOutlinedInput-notchedOutline {
        border: unset;
      }
    }
  }
`;

const ItemList = styled.div`
  display: flex;
  flex-direction: row;
  padding: 8px 0;
  column-gap: 10px;
`;

const StyledDateContainer = styled(DateContainer)`
  align-self: flex-end;
  margin: 15px 0;
`;

const LoadingIndicatorContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  column-gap: 8px;
  border-radius: 29px;
  border: 1px solid ${colors.shades.primary2};
  background: ${colors.base.primary};
  padding: 10px;
  color: ${colors.base.secondary2};
  font-size: 14px;
  font-weight: 400;
`;

export {
  Container,
  TitleContainer,
  ContentContainer,
  ItemContainer,
  Label,
  StyledTextField,
  ItemList,
  StyledDateContainer,
  LoadingIndicatorContainer,
};
