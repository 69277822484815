import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Email is invalid')
    .required('Email is required'),
});

const initialValues = {
  email: '',
};

export { validationSchema, initialValues };
