import React, {
  useCallback,
  useState,
  useEffect,
} from 'react';
import format from 'string-template';
import PropTypes from 'prop-types';
import {
  EditOutlined,
} from '@mui/icons-material';

import useStorage from '../../../../../hooks/useStorage';
import useComponentMounted from '../../../../../hooks/useComponentMounted';
import useToast from '../../../../hooks/useToast';
import {
  ImageContainer,
  ImageDescription,
  ImageUploader,
  ImageTipsDescription,
  ImageDescriptionContainer,
  ImageWrapper,
  StyledPreview,
  StyledLoadingPage,
  StyledEditIcon,
  StyledFileSelectButton,
  StyledImageSearch,
  NoPreviewContainer,
} from './styles';
import texts from './texts.json';

const RECOMMENDED_IMAGE_WIDTH = 36;
const RECOMMENDED_IMAGE_HEIGHT = 30;

const WebIconConfiguration = ({
  webAppIconRef,
  imageUrl,
  setImagePreview,
  fileToUpload,
  setFileToUpload,
  isReadOnly,
}) => {
  const { getBlobUrl } = useStorage();
  const isComponentMountedRef = useComponentMounted();
  const { showToast } = useToast();

  const [isPreviewReady, setIsPreviewReady] = useState(false);

  useEffect(() => {
    const setWebIconUrl = async () => {
      const webIconUrl = webAppIconRef ? await getBlobUrl(webAppIconRef) : '';

      if (isComponentMountedRef.current) {
        setImagePreview(webIconUrl);
        setIsPreviewReady(true);
      }
    };

    setWebIconUrl();
  }, [
    isComponentMountedRef,
    getBlobUrl,
    webAppIconRef,
    setImagePreview,
  ]);

  const onImageSelected = useCallback(({ target }) => {
    const { files } = target;
    if (files.length) {
      const filesArray = [...files];
      // Revoke any previously created URL.
      if (imageUrl) {
        URL.revokeObjectURL(imageUrl);
      }

      const [file] = filesArray;
      const url = URL.createObjectURL(file);

      // Show warning if file type is not SVG
      if (file.type !== 'image/svg+xml') {
        showToast(texts.warnings.fileType, { warning: true, options: { autoClose: false } });
      }

      setImagePreview(url);
      setFileToUpload(file);
    }
  }, [
    imageUrl,
    showToast,
    setImagePreview,
    setFileToUpload,
  ]);

  /**
   * This gets executed every time an image is loaded in the preview component. We will use this to show a warning
   * if the file dimensions are not as expected
   */
  const imageOnLoad = useCallback((event) => {
    if (fileToUpload) {
      const imgElement = event.target;

      // Get image dimensions
      const width = imgElement.naturalWidth;
      const height = imgElement.naturalHeight;

      if (width !== RECOMMENDED_IMAGE_WIDTH || height !== RECOMMENDED_IMAGE_HEIGHT) {
        showToast(
          format(texts.warnings.dimensions, {
            recWidth: RECOMMENDED_IMAGE_WIDTH, recHeight: RECOMMENDED_IMAGE_HEIGHT, width, height,
          }),
          { warning: true, options: { autoClose: false } },
        );
      }
    }
  }, [
    fileToUpload,
    showToast,
  ]);

  const renderImagePreview = () => {
    if (!isPreviewReady) {
      return <StyledLoadingPage />;
    }

    if (!imageUrl && !fileToUpload) {
      return (
        <NoPreviewContainer>
          <StyledImageSearch />
          {texts.newUpload}
        </NoPreviewContainer>
      );
    }

    return (
      <>
        <StyledEditIcon>
          <EditOutlined />
        </StyledEditIcon>
        <StyledPreview src={imageUrl} onLoad={imageOnLoad} />
      </>
    );
  };

  return (
    <ImageContainer>
      <ImageUploader>
        <ImageWrapper>
          <StyledFileSelectButton
            onSelect={onImageSelected}
            multiple={false}
            extraInputProps={{ accept: ['image/svg+xml', 'image/png'] }}
            extraButtonProps={{ disabled: isReadOnly }}
          >
            {renderImagePreview()}
          </StyledFileSelectButton>
        </ImageWrapper>
      </ImageUploader>
      <ImageDescriptionContainer>
        <ImageDescription>{texts.imageDescription}</ImageDescription>
        <ImageTipsDescription>{texts.imageTipsDescription}</ImageTipsDescription>
        <ul>
          {texts.requirements.map((text) => (
            <li key={text}>{format(text, { width: RECOMMENDED_IMAGE_WIDTH, height: RECOMMENDED_IMAGE_HEIGHT })}</li>
          ))}
        </ul>
      </ImageDescriptionContainer>
    </ImageContainer>
  );
};

WebIconConfiguration.propTypes = {
  webAppIconRef: PropTypes.string,
  imageUrl: PropTypes.string.isRequired,
  setImagePreview: PropTypes.func.isRequired,
  fileToUpload: PropTypes.object,
  setFileToUpload: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool,
};

WebIconConfiguration.defaultProps = {
  webAppIconRef: '',
  fileToUpload: null,
  isReadOnly: false,
};

export default WebIconConfiguration;
