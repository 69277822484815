import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import Lead from '../../../../Model/Lead';
import PostPaymentFormAnswer from '../../../../Model/PostPaymentFormAnswer';
import { getFormAnswersFromData } from '../../../../utils/formAnswers';
import FormResponseModal from '../FormResponseModal';

import {
  StyledButton,
} from './styles';

const FormAnswers = ({
  formDoc,
  buttonText,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const responsesData = useMemo(() => getFormAnswersFromData(formDoc), [formDoc]);

  return (
    <>
      {!!responsesData && (
        <>
          <StyledButton onClick={() => setIsModalOpen((prev) => !prev)}>
            {buttonText}
          </StyledButton>
          <FormResponseModal
            onClose={() => setIsModalOpen(false)}
            showModal={isModalOpen}
            questionAnswers={responsesData}
          />
        </>
      )}
    </>
  );
};

FormAnswers.propTypes = {
  formDoc: PropTypes.oneOfType([
    PropTypes.instanceOf(PostPaymentFormAnswer),
    PropTypes.instanceOf(Lead),
  ]).isRequired,
  buttonText: PropTypes.string.isRequired,
};

export default FormAnswers;
