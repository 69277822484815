import React, {
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import format from 'string-template';
import moment from 'moment';

import {
  Tooltip,
} from '@mui/material';

import { ReactComponent as ReviewIcon } from '../../../../../../assets/icons/v2/arrow-right.svg';
import { ReactComponent as RefreshIcon } from '../../../../../../assets/icons/refresh-icon-2.svg';
import { ReactComponent as CheckIcon } from '../../../../../../assets/icons/v2/check-circle.svg';
import { DateFormat } from '../../../../../../utils/date';
import useQuickSearch from '../../../../../../hooks/useQuickSearch';
import { PrimaryButton } from '../../../../../../components/Button/ActionButtons';
import QuickSearchToolbar from '../../../../../components/QuickSearchToolbar';
import MealPlanContext, { withMealPlanContextReady } from '../../../../../context/MealPlanContext';
import useMealPlanNavigation from '../../../../../hooks/useMealPlanNavigation';
import { MealPlanAssignmentStatus } from '../../../../../utils/mealPlan';
import { DietaryRestrictionText } from '../../../../../Model/UserNutritionProfile';
import GenericDataGrid from '../../../../../components/GenericDataGrid';
import UserProfileCard from '../../../../../components/UserProfileCard';

import SelectFilter from '../SelectFilter';
import {
  DateContainer,
  RestrictionsList,
  NoMealPlansMessage,
  StyledCaloricTargetDateTypography,
  CaloricTargetContainerCell,
  CrossedCaloriesText,
  RestrictionItem,
  StyledPeopleIcon,
  StyledActionIcon,
  StyledCurvedArrowIcon,
  ActionCell,
} from './styles';
import {
  filterChecks,
  FilterOptionValues,
} from './utils';
import texts from './texts.json';
import getColumns from './columns';

const filterOptionIcons = {
  [FilterOptionValues.ALL]: StyledPeopleIcon,
  [FilterOptionValues.NEEDS_ACTION]: StyledActionIcon,
  [FilterOptionValues.NEEDS_REFRESH]: StyledCurvedArrowIcon,
  [FilterOptionValues.NEW_ASSIGNMENTS]: StyledCurvedArrowIcon,
};

const MealPlanAssignmentsTable = () => {
  const {
    navigateToRefreshPage,
    navigateToClientAssignmentPage,
    navigateToNewAssignmentPage,
  } = useMealPlanNavigation();

  const { mealPlanAssignments, mealPlansStatusCount } = useContext(MealPlanContext);

  const filterOptionTags = {
    [FilterOptionValues.NEEDS_ACTION]: (
      mealPlansStatusCount[MealPlanAssignmentStatus.NEEDS_REFRESH]
      + mealPlansStatusCount[MealPlanAssignmentStatus.PENDING]
    ),
    [FilterOptionValues.NEEDS_REFRESH]: mealPlansStatusCount[MealPlanAssignmentStatus.NEEDS_REFRESH],
    [FilterOptionValues.NEW_ASSIGNMENTS]: mealPlansStatusCount[MealPlanAssignmentStatus.PENDING],
  };

  // Default the selected option to `ALL` if there are no assignments that need any action
  const [activeFilter, setActiveFilter] = useState(
    filterOptionTags[FilterOptionValues.NEEDS_ACTION] > 0
      ? FilterOptionValues.NEEDS_ACTION
      : FilterOptionValues.ALL,
  );

  const tableRows = useMemo(() => {
    const rows = [];

    mealPlanAssignments.forEach(
      ({
        id,
        userId,
        userName,
        userAvatar,
        status,
        lastUpdated,
        assignmentName = '',
        productName = '',
        dietaryRestrictions: restrictions = [],
        calculatedDailyCalories,
        totalDailyCalories,
        updatedMacroAveragesAt,
      }) => {
        rows.push({
          id,
          user: userId,
          userName,
          userAvatar,
          status,
          lastUpdated: lastUpdated ? moment(lastUpdated.toDate()).format(DateFormat.DATE_FORMAT_COMMA) : '-',
          calculatedTarget: calculatedDailyCalories ? `${calculatedDailyCalories} ${texts.kcal}` : '-',
          currentTarget: totalDailyCalories ? `${totalDailyCalories} ${texts.kcal}` : '-',
          currentTargetUpdatedAt: updatedMacroAveragesAt
            ? moment(updatedMacroAveragesAt.toDate()).format(DateFormat.DATE_FORMAT_COMMA)
            : '',
          restrictions,
          productName,
          assignmentName,
        });
      },
    );

    return rows;
  }, [mealPlanAssignments]);

  const renderActionCell = useCallback(({ row: { status, user } = {} }) => {
    const handleViewClick = () => navigateToClientAssignmentPage(user);
    const handleRefreshClick = () => navigateToRefreshPage(user);
    const handleAssignClick = () => navigateToNewAssignmentPage(user);

    return (
      <ActionCell>
        {status === MealPlanAssignmentStatus.NEEDS_REFRESH && (
          <PrimaryButton
            onClick={handleRefreshClick}
            icon={<RefreshIcon />}
            size="medium"
          >
            {texts.refreshMealPlan}
          </PrimaryButton>
        )}
        {status === MealPlanAssignmentStatus.PENDING && (
          <PrimaryButton
            onClick={handleAssignClick}
            icon={<CheckIcon />}
            size="medium"
          >
            {texts.assignMealPlan}
          </PrimaryButton>
        )}
        <PrimaryButton
          onClick={handleViewClick}
          endIcon={<ReviewIcon />}
          variant="info"
          size="medium"
        >
          {texts.viewMeals}
        </PrimaryButton>
      </ActionCell>
    );
  }, [
    navigateToRefreshPage,
    navigateToClientAssignmentPage,
    navigateToNewAssignmentPage,
  ]);

  const renderLastUpdatedCell = useCallback(({ row: { status, lastUpdated } }) => {
    const mealPlanNeedRefresh = status === MealPlanAssignmentStatus.NEEDS_REFRESH;
    return (
      status === MealPlanAssignmentStatus.MANUALLY_REFRESHABLE
        ? texts.emptyCell
        : (
          <DateContainer expired={mealPlanNeedRefresh}>
            {lastUpdated}
          </DateContainer>
        )
    );
  }, []);

  const renderUserNameCell = useCallback(
    ({ row }) => (
      <UserProfileCard
        key={row.user}
        clientId={row.user}
        avatarSize="small"
        showButtonsOnHover
        dataRow={row}
      />
    ),
    [],
  );

  const renderCaloricGoalCell = useCallback(
    ({
      row: {
        calculatedTarget,
        currentTarget,
        currentTargetUpdatedAt,
      } = {},
    }) => {
      /*
        A caloric goal is overwritten when the calculated value is different than the
        current value. For this, there needs to be a calculated value and an update date.
      */
      const isOverwritten = !!calculatedTarget
        && !!currentTargetUpdatedAt
        && calculatedTarget !== currentTarget
        && calculatedTarget !== '-';

      return (
        <CaloricTargetContainerCell>
          {isOverwritten && (
            <CrossedCaloriesText>
              {calculatedTarget}
            </CrossedCaloriesText>
          )}
          {currentTarget}
          {isOverwritten && (
            <StyledCaloricTargetDateTypography>
              {`${texts.manualSet} ${currentTargetUpdatedAt}`}
            </StyledCaloricTargetDateTypography>
          )}
        </CaloricTargetContainerCell>
      );
    },
    [],
  );

  // Render the restrictions cell.
  const renderRestrictions = useCallback(({ row: { restrictions } = {} }) => {
    let restrictionsCell = texts.emptyCell;

    // If we have restrictions, then we render them with different styles.
    if (restrictions.length > 0) {
      // Show first 2 restriction as badges and the remaining restrictions as a tooltip.
      const firstRestrictions = restrictions.slice(0, 2);
      const remainingRestrictions = restrictions.slice(2, restrictions.length);

      const tooltipText = remainingRestrictions.map((rst) => DietaryRestrictionText[rst]).join(', ');

      restrictionsCell = (
        <RestrictionsList>
          {firstRestrictions.map((rst) => (
            <RestrictionItem key={rst}>{DietaryRestrictionText[rst]}</RestrictionItem>
          ))}
          {!!remainingRestrictions.length && (
            <Tooltip
              title={tooltipText}
              placement="top"
              arrow
            >
              <RestrictionItem>
                {format(texts.moreRestrictions, { amount: remainingRestrictions.length })}
              </RestrictionItem>
            </Tooltip>
          )}
        </RestrictionsList>
      );
    }

    return restrictionsCell;
  }, []);

  const columns = getColumns({
    actionsRenderCell: renderActionCell,
    restrictionsRenderCell: renderRestrictions,
    userNameRenderCell: renderUserNameCell,
    caloricGoalRenderCell: renderCaloricGoalCell,
    lastUpdatedRenderCell: renderLastUpdatedCell,
  });

  const {
    filteredRows: quickSearchRows,
    toolbarProps,
  } = useQuickSearch(tableRows, columns);

  const filteredRows = useMemo(
    () => quickSearchRows.filter((row) => filterChecks[activeFilter](row)),
    [
      quickSearchRows,
      activeFilter,
    ],
  );

  if (tableRows.length === 0) {
    return <NoMealPlansMessage>{texts.noMealPlanAssignments}</NoMealPlansMessage>;
  }

  return (
    <GenericDataGrid
      rows={filteredRows}
      columns={columns}
      components={{
        Toolbar: QuickSearchToolbar,
      }}
      componentsProps={{
        toolbar: {
          ...toolbarProps,
          placeholder: texts.toolbarPlaceholder,
          filterTools: [
            {
              Component: SelectFilter,
              id: 'meal-plans-select-filter',
              props: {
                options: FilterOptionValues,
                onSelect: setActiveFilter,
                optionTexts: texts.highLights,
                activeOption: activeFilter,
                optionIcons: filterOptionIcons,
                optionTags: filterOptionTags,
              },
            },
          ],
        },
      }}
    />
  );
};

export default compose(
  withMealPlanContextReady,
  observer,
)(MealPlanAssignmentsTable);
