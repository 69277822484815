import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import {
  Backdrop,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { Button } from 'react-chat-elements';

import useComponentMounted from '../../../../../hooks/useComponentMounted';
import LeadMsg from '../../../../Model/LeadMsg';
import { ContactProvider } from '../../../../Model/Lead';
import { sendMessage } from '../../../../utils/message';
import useToast from '../../../../hooks/useToast';
import {
  IMsgIcon,
  StyledButton,
  StyledInput,
  StyledMessageList,
  StyledPaper,
  WhatsAppIcon,
  StyledChip,
  PaddedDiv,
  EmailIcon,
} from './styles';
import texts from './texts.json';

let clearInput = () => { };

const ProviderIcon = {
  [ContactProvider.iMessage]: IMsgIcon,
  [ContactProvider.WhatsApp]: WhatsAppIcon,
  [ContactProvider.EMAIL]: EmailIcon,
  DEFAULT: IMsgIcon,
};

const ChatModal = ({
  onClose,
  open,
  contactNo,
  email,
  name,
  sendAsUserName,
  sendAsUserId,
  provider,
  onMoreSelected,
  msgEndpoints,
  onMsgSent,
  showInputField,
}) => {
  const [isLoadingMsgs, setIsLoadingMsgs] = useState(true);
  const [msgList, setMsgList] = useState([]);
  const [isSendingMsg, setIsSendingMsg] = useState(false);
  const [message, setMessage] = useState('');

  const isComponentMountedRef = useComponentMounted();
  const { showToast } = useToast();
  const inputRef = React.useRef();
  const messagesEndRef = React.useRef();

  useEffect(() => {
    const loadMsgs = async () => {
      setMsgList([]);
      if (contactNo) {
        setIsLoadingMsgs(true);
        const msgs = await LeadMsg.getLeadMsgs(contactNo);
        const emails = await LeadMsg.getLeadEmails(email);
        if (isComponentMountedRef.current) {
          const messageList = [...msgs.docs, ...emails.docs];
          const sortedMsgList = messageList.sort((first, second) => first.sentAt - second.sentAt);
          setMsgList(sortedMsgList);
          setIsLoadingMsgs(false);
          messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
      }
    };
    loadMsgs();
  }, [isComponentMountedRef, contactNo, email]);

  const sendMsg = useCallback(async () => {
    if (!message) {
      showToast(texts.emptyMsg, { error: true });
      return;
    }
    // if provider is not set, then it means the user has not sent any message yet
    // so we will use the msg modal to send the first message
    if (!provider || !sendAsUserId) {
      onMoreSelected(ContactProvider.iMessage);
      return;
    }
    setIsSendingMsg(true);
    let msgEndpoint = null;
    if (sendAsUserId) {
      msgEndpoint = msgEndpoints.find((p) => p.userId === sendAsUserId);
    }
    const { errMessage, successMessage } = await sendMessage(
      provider, contactNo, message, null, null, msgEndpoint,
    );
    setIsSendingMsg(false);
    if (errMessage) {
      showToast(errMessage, { error: true });
      return;
    }
    showToast(successMessage, { success: true });
    if (successMessage) {
      clearInput();
      setMessage('');
      onMsgSent({
        msgProvider: provider,
        contactNo,
        messageBody: message,
        ...(msgEndpoint && {
          sendAs: msgEndpoint.userId,
          sendAsUserName: msgEndpoint.userName,
        }),
      });
    }
  }, [provider, sendAsUserId, contactNo, message, showToast, onMoreSelected, msgEndpoints, onMsgSent]);

  const hasMsgs = !isLoadingMsgs && msgList.length > 0;

  return (
    <Dialog
      open={open}
      PaperComponent={StyledPaper}
    >
      <DialogTitle>{texts.chat}</DialogTitle>
      <DialogContent>
        {hasMsgs && (
          <StyledMessageList
            dataSource={msgList.map((msg) => ({
              avatar: ProviderIcon[msg.provider] || ProviderIcon.DEFAULT,
              title: msg.isFromMe ? msg.sentAsUsername : name,
              type: 'text',
              text: msg.body,
              date: msg.sentAt,
              position: msg.isFromMe ? 'right' : 'left',
            }))}
          />
        )}
        <div ref={messagesEndRef} />
        {!hasMsgs && !isLoadingMsgs && texts.noMsgs}
      </DialogContent>
      {showInputField && (
        <PaddedDiv>
          <StyledInput
            placeholder={texts.typeHere}
            multiline
            autofocus
            onChange={(e) => setMessage(e.target?.value)}
            clear={(clear) => { clearInput = clear; }}
            referance={inputRef}
            rightButtons={(
              <>
                <Button text={texts.send} onClick={sendMsg} />
                <Button text={texts.more} onClick={() => onMoreSelected(provider)} />
              </>
            )}
          />
          {!!sendAsUserName && <StyledChip label={`${texts.sendAs}: ${sendAsUserName}`} />}
          {!!provider && <StyledChip label={`${texts.provider}: ${provider}`} />}
          {!!contactNo && <StyledChip label={`${texts.contactNo}: ${contactNo}`} />}
        </PaddedDiv>
      )}
      <DialogActions>
        <StyledButton onClick={onClose}>{texts.close}</StyledButton>
      </DialogActions>
      <Backdrop open={isLoadingMsgs || isSendingMsg}>
        <CircularProgress />
      </Backdrop>
    </Dialog>
  );
};

ChatModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  contactNo: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  sendAsUserName: PropTypes.string,
  sendAsUserId: PropTypes.string,
  provider: PropTypes.string,
  onMoreSelected: PropTypes.func.isRequired,
  msgEndpoints: PropTypes.array.isRequired,
  onMsgSent: PropTypes.func.isRequired,
  showInputField: PropTypes.bool,
};

ChatModal.defaultProps = {
  sendAsUserName: '',
  provider: '',
  sendAsUserId: '',
  showInputField: true,
};

export default compose(
  observer,
)(ChatModal);
