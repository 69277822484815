import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import escapeStringRegexp from 'escape-string-regexp';

/**
 * Hook to use the QuickSearchToolbar in a DataGrid component
 * @param {Array} rows array of data, the rows of the table
 * @param {Array} columns array of columns. The columns to be used in the filter must have
 * the attribute quickSearch:true and, optionally, a stringifier function
 * @returns an object with the filteredRows and the toolbarProps, to be used in the
 * DataGrid component, in the componentsProps prop
 */
const useQuickSearch = (unfilteredRows, columns) => {
  const [searchText, setSearchText] = useState('');
  const [filteredRows, setFilteredRows] = useState(unfilteredRows);

  const columnsToSearch = useMemo(() => (
    columns.filter((column) => column.quickSearch)
  ), [columns]);

  const defaultStringifier = useCallback((value) => value?.toString(), []);

  const requestSearch = useCallback((searchValue = '') => {
    const searchRegex = new RegExp(escapeStringRegexp(searchValue), 'i');
    setSearchText(searchValue);

    const newFilteredRows = unfilteredRows.reduce((resultRows, row) => {
      const matchingColumns = columnsToSearch.filter((column) => {
        const stringifier = column.stringifier || defaultStringifier;
        return searchRegex.test(stringifier(row[column.field]));
      });

      if (matchingColumns.length > 0) {
        const rowWithFilteredColumns = matchingColumns.reduce((prevRow, column) => {
          // Custom Search function for each column
          if (column.cellSearchFunction) {
            const cellValue = column.cellSearchFunction(row, searchValue, searchRegex);
            return { ...prevRow, ...cellValue };
          }
          return prevRow;
        }, row);
        return [...resultRows, rowWithFilteredColumns];
      }

      return resultRows;
    }, []);

    setFilteredRows(newFilteredRows);
  }, [
    columnsToSearch,
    unfilteredRows,
    defaultStringifier,
  ]);

  // Note: the useEffect is used to update the filteredRows when the unfilteredRows change
  useEffect(() => {
    requestSearch(searchText);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unfilteredRows]);

  const onChange = useCallback(
    (event) => requestSearch(event.target.value),
    [requestSearch],
  );

  const clearSearch = useCallback(
    () => requestSearch(),
    [requestSearch],
  );

  const toolbarProps = useMemo(() => ({
    value: searchText,
    onChange,
    clearSearch,
  }), [
    searchText,
    onChange,
    clearSearch,
  ]);

  return useMemo(() => ({
    toolbarProps,
    filteredRows,
  }), [
    toolbarProps,
    filteredRows,
  ]);
};

export default useQuickSearch;
