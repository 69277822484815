import MobileApp from './MobileApp';
import SlackNotifications from './SlackNotifications';
import texts from './texts.json';
import {
  MobileAppIcon,
  SlackNotificationsIcon,
} from './styles';

const tabs = {
  MOBILE_APP: 'MOBILE_APP',
  SLACK_NOTIFICATIONS: 'SLACK_NOTIFICATIONS',
};

const tabsConfig = {
  [tabs.MOBILE_APP]: {
    label: texts.tabLabels[tabs.MOBILE_APP],
    icon: MobileAppIcon,
    component: MobileApp,
  },
  [tabs.SLACK_NOTIFICATIONS]: {
    label: texts.tabLabels[tabs.SLACK_NOTIFICATIONS],
    icon: SlackNotificationsIcon,
    component: SlackNotifications,
  },
};

export {
  tabs,
  tabsConfig,
};
