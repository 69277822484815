import React from 'react';
import PropTypes from 'prop-types';

import Button from './Button';
import { Spinner } from './styles';

const LoadingButton = ({
  children,
  isLoading,
  disabled,
  startIcon,
  ...otherProps
}) => (
  <Button
    disabled={isLoading || disabled}
    {...otherProps}
  >
    {startIcon}
    {children}
    {isLoading && <Spinner name="crescent" />}
  </Button>
);

LoadingButton.propTypes = {
  children: PropTypes.node.isRequired,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  startIcon: PropTypes.node,
};

LoadingButton.defaultProps = {
  isLoading: false,
  startIcon: null,
  disabled: false,
};

export default LoadingButton;
