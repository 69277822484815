import React from 'react';
import PropTypes from 'prop-types';

import DialogModal from '../../../components/DialogModal';
import WorkoutEditor from '../../pages/WorkoutsManager/Workouts/WorkoutEditor';

import {
  Wrapper,
} from './styles';

const WorkoutEditorModal = ({
  showModal,
  workout,
  workoutAssignment,
  editorAction,
  onSave,
  onClose,
}) => (
  <DialogModal
    open={showModal}
    onClose={onClose}
    fullWidth
    maxWidth="xl"
  >
    <Wrapper>
      <WorkoutEditor
        workout={workout}
        workoutAssignment={workoutAssignment}
        onSave={onSave}
        onClose={onClose}
        action={editorAction}
      />
    </Wrapper>
  </DialogModal>
);

WorkoutEditorModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  workout: PropTypes.object,
  workoutAssignment: PropTypes.object,
  editorAction: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

WorkoutEditorModal.defaultProps = {
  workout: null,
  workoutAssignment: null,
  onSave: () => {},
  onClose: () => {},
};

export default WorkoutEditorModal;
