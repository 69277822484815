import React, {
  useCallback,
  useMemo,
  useState,
} from 'react';
import PropTypes, { object } from 'prop-types';
import { Container as DraggableContainer } from 'react-smooth-dnd';
import format from 'string-template';

import Activity, { ActivityTypes } from '../../../../../../Model/Activity';
import { convertIndexToLetter } from '../../../../../../utils/text';
import {
  addItem,
  moveItem,
  removeItem,
  replaceItem,
} from '../../../../../../utils/array';
import { PrimaryButton } from '../../../../../../components/Button/ActionButtons';
import { ReactComponent as CreateIcon } from '../../../../../../assets/icons/v2/creation-plus-circle.svg';
import ActivityModal from '../../ActivityModal/ActivityModal';
import AddCircuitModal from '../../AddCircuitModal';
import RestModal from '../../components/RestModal';
import ConfirmDialog from '../../../../../components/ConfirmDialog';
import { getActivity, isCircuit } from '../../utils';

import { EditorActionType } from '../utils';
import {
  containerStyle,
  ActionButtonContainer,
} from './styles';
import texts from './texts.json';
import ActivityComp from './Activity';
import Circuit from './Circuit';

const Activities = ({
  activities,
  onActivitiesChange,
  onClickAddActivity,
  selectedExercise,
  showActivityModal,
  setShowActivityModal,
  setSelectedExercise,
}) => {
  const [selectedAction, setSelectedAction] = useState(null);
  const [selectedCircuitIndex, setSelectedCircuitIndex] = useState(null);
  const [selectedActivityIndex, setSelectedActivityIndex] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [shouldResetExerciseData, setShouldResetExerciseData] = useState(false);
  const [isExternalDrop, setIsExternalDrop] = useState(false);

  const ExerciseData = useMemo(() => {
    if (shouldResetExerciseData) {
      return selectedExercise;
    }
    if (isUpdate && selectedAction === EditorActionType.ACTIVITY) {
      if (selectedCircuitIndex !== null) {
        return {
          ...activities[selectedCircuitIndex]?.activities[selectedActivityIndex],
        };
      }
      return {
        ...activities[selectedActivityIndex]?.activities[0],
        rounds: activities[selectedActivityIndex]?.rounds,
      };
    }
    return selectedExercise;
  }, [
    activities,
    selectedCircuitIndex,
    selectedActivityIndex,
    selectedExercise,
    selectedAction,
    isUpdate,
    shouldResetExerciseData,
  ]);

  const onDropActivity = useCallback(
    (
      removedIndex,
      addedIndex,
      payload,
      activitiesToUpdate = activities,
      isDroppingToCircuit = false,
      circuitIndex = null,
    ) => {
      /*
       * If the drop was triggered from an item from the external exercises table,
       * we add a dummy placeholder activity in the dropped position and then open
       * the activity modal to allow the user to customize the activity
       */
      if (payload.isFromExerciseTable) {
        // Only consider the event fired for adding the activity
        if (addedIndex !== null && removedIndex === null) {
          addItem(activitiesToUpdate, new Activity(payload.exercise), addedIndex);
          setSelectedExercise(payload.exercise);
          setIsExternalDrop(true);
          setSelectedCircuitIndex(circuitIndex);
          setSelectedActivityIndex(addedIndex);
          setShowActivityModal(true);
          return;
        }
      }
      if (removedIndex !== null && addedIndex !== null) {
        moveItem(activitiesToUpdate, removedIndex, addedIndex);
      } else if (removedIndex !== null) {
        removeItem(activitiesToUpdate, removedIndex);
      } else if (addedIndex !== null) {
        let activityToAdd = payload;
        if (isDroppingToCircuit) {
          // If we are adding to a circuit, we need to add just the activity without the wrapping circuit
          activityToAdd = getActivity(payload);
        } else if (payload.type !== ActivityTypes.REST) {
          // If we are adding a activity to root, we need to wrap it in a circuit
          activityToAdd = new Activity({
            type: ActivityTypes.CIRCUIT,
            activities: [payload],
          });
        }
        addItem(activitiesToUpdate, activityToAdd, addedIndex);
      }
      onActivitiesChange(activities.slice());
    }, [
      activities,
      onActivitiesChange,
      setSelectedExercise,
      setShowActivityModal,
    ],
  );

  const onclickRemoveActivity = useCallback((activityIndex = null, circuitIndex = null) => {
    setSelectedActivityIndex(activityIndex);
    setSelectedCircuitIndex(circuitIndex);
    setShowConfirmDialog(true);
  }, []);

  const confirmationMessage = useMemo(() => {
    if (selectedActivityIndex !== null && selectedCircuitIndex !== null) {
      return format(texts.deleteCircuitActivityConfirmMsg,
        { activityName: activities[selectedCircuitIndex]?.activities[selectedActivityIndex]?.name });
    }
    return format(texts.deleteConfirmMsg,
      { activityName: activities[selectedActivityIndex]?.activities?.[0]?.name });
  }, [
    activities,
    selectedActivityIndex,
    selectedCircuitIndex,
  ]);

  const onRemoveActivity = useCallback(() => {
    const newActivities = activities.slice();
    // If activityIndex is null, remove the whole circuit
    if (selectedActivityIndex === null) {
      removeItem(newActivities, selectedCircuitIndex);
    } else {
      removeItem(selectedCircuitIndex !== null
        ? newActivities[selectedCircuitIndex].activities : newActivities, selectedActivityIndex);
    }
    onActivitiesChange(newActivities);
    setSelectedActivityIndex(null);
    setSelectedCircuitIndex(null);
    setShowConfirmDialog(false);
  }, [
    activities,
    onActivitiesChange,
    selectedActivityIndex,
    selectedCircuitIndex,
  ]);

  const onDuplicateActivity = useCallback((activityIndex = null, circuitIndex = null) => {
    const newActivities = activities.slice();
    // If activityIndex is null, duplicate the whole circuit
    if (activityIndex === null) {
      addItem(newActivities, activities[circuitIndex], circuitIndex);
    } else {
      const selectedActivities = circuitIndex !== null ? newActivities[circuitIndex].activities : newActivities;
      const activityToDuplicate = selectedActivities[activityIndex];
      addItem(selectedActivities, activityToDuplicate, activityIndex);
    }
    onActivitiesChange(newActivities);
  }, [activities, onActivitiesChange]);

  const onCloseModal = useCallback(() => {
    setShowActivityModal(false);
    setSelectedActivityIndex(null);
    if (isUpdate) {
      setSelectedCircuitIndex(null);
    }
    setIsUpdate(false);
    setSelectedAction(EditorActionType.ACTIVITY);
    setShouldResetExerciseData(false);
    setIsExternalDrop(false);
  }, [
    setShowActivityModal,
    isUpdate,
  ]);

  const onActivityModalClose = useCallback(() => {
    // If the activity modal was closed without saving, we remove the placeholder activity
    if (isExternalDrop) {
      onActivitiesChange(
        selectedCircuitIndex === null
          ? removeItem(activities, selectedActivityIndex)
          : activities.map((activity, index) => {
            if (index === selectedCircuitIndex) {
              return new Activity({
                ...activity,
                activities: removeItem(activity.activities, selectedActivityIndex),
              });
            }
            return activity;
          }),
      );
    }
    onCloseModal();
  }, [
    isExternalDrop,
    onCloseModal,
    onActivitiesChange,
    selectedCircuitIndex,
    selectedActivityIndex,
    activities,
  ]);

  const onAddUpdateActivity = useCallback((newActivities) => {
    /**
     * If we are adding new activity (Rest, Circuit and Activities not inside the circuit),
     * we add them to the end of the activity list.
     * If we are adding activities to a circuit, we add them to the end of the circuit's activity list.
     */
    if (!isUpdate) {
      if (selectedCircuitIndex === null) {
        onActivitiesChange([...activities, ...newActivities]);
      } else {
        onActivitiesChange(
          activities.map((activity, index) => {
            if (index === selectedCircuitIndex) {
              return new Activity({
                ...activity,
                activities: [...activity.activities, ...newActivities],
              });
            }
            return activity;
          }),
        );
      }
    }
    /**
     * If we are updating an activity (Rest, Circuit and Activities not inside the circuit),
     * we replace the old one with the new activities.
     * If we are updating activities inside a circuit,
     * we replace the old activity list of the circuit with the new activity list.
     *
     * Also, if this update is triggered by an external exercise drop
     * we need to replace that activity as we initially add a dummy on drop.
     */
    if (isUpdate || isExternalDrop) {
      if (selectedCircuitIndex === null) {
        onActivitiesChange(replaceItem(activities, selectedActivityIndex, newActivities));
      } else {
        onActivitiesChange(
          activities.map((activity, index) => {
            if (index === selectedCircuitIndex) {
              return new Activity({
                ...activity,
                activities: activity.activities.flatMap((subActivity, subIndex) => {
                  if (subIndex === selectedActivityIndex) {
                    return newActivities.map((activityToAdd) => new Activity(activityToAdd));
                  }
                  return subActivity;
                }),
              });
            }
            return activity;
          }),
        );
      }
    }
    onCloseModal();
  }, [
    activities,
    onActivitiesChange,
    isUpdate,
    isExternalDrop,
    onCloseModal,
    selectedCircuitIndex,
    selectedActivityIndex,
  ]);

  const onCloseConfirmationModal = () => {
    setShowConfirmDialog(false);
    setSelectedActivityIndex(null);
    setSelectedCircuitIndex(null);
  };

  const onClickAdd = (circuitIndex) => {
    setSelectedAction(EditorActionType.ACTIVITY);
    onClickAddActivity(true);
    setSelectedCircuitIndex(circuitIndex);
  };

  const onClickRest = () => {
    setSelectedAction(EditorActionType.REST);
    setSelectedActivityIndex(null);
    setSelectedCircuitIndex(null);
  };

  const onClickCircuit = () => {
    setSelectedAction(EditorActionType.CIRCUIT);
    setSelectedActivityIndex(null);
    setSelectedCircuitIndex(null);
  };

  const onClickEdit = (activityType, index, circuitIndex = null) => {
    if (activityType === EditorActionType.ACTIVITY) {
      setShowActivityModal(true);
    }
    setSelectedActivityIndex(index);
    setSelectedCircuitIndex(circuitIndex);
    setIsUpdate(true);
    setSelectedAction(activityType);
  };

  return (
    <>
      <DraggableContainer
        dragHandleSelector=".drag-handle"
        groupName="activities"
        lockAxis="y"
        onDrop={({ removedIndex, addedIndex, payload }) => onDropActivity(removedIndex, addedIndex, payload)}
        getChildPayload={(index) => activities[index]}
        style={containerStyle}
      >
        {activities.map((activity, index) => {
          const activityLetter = convertIndexToLetter(index);
          if (isCircuit(activity)) {
            const showRestWarning = index !== activities.length - 1 // this isn't the last activity of the workout
              && activity.activities.length > 0 // Check that this CIRCUIT has activities inside
              && !activity.activities[activity.activities.length - 1].restTime
              && activities[index + 1].type !== ActivityTypes.REST;

            return (
              <Circuit
                activity={activity}
                circuitTag={activityLetter}
                showRestWarning={showRestWarning}
                key={activityLetter}
                circuitIndex={index}
                onDropActivity={onDropActivity}
                onAddActivity={onClickAdd}
                onClickEdit={onClickEdit}
                onRemoveActivity={onclickRemoveActivity}
                onDuplicateActivity={onDuplicateActivity}
              />
            );
          }
          const showRestWarning = index !== activities.length - 1 // this isn't the last activity of the workout
            && activity?.activities
            && !activity.activities[0].restTime // this activity doesn't have a rest time
            && activities[index + 1].type !== ActivityTypes.REST; // the next activity isn't a rest activity
          return (
            <ActivityComp
              // we need to pass the unwrapped activity to the Activity component
              activity={getActivity(activity)}
              // pass the rounds explicitly as it's only used for display purposes for activities outside of circuits
              rounds={activity.rounds}
              tag={activityLetter}
              key={activityLetter}
              showRestWarning={showRestWarning}
              onRemove={() => onclickRemoveActivity(index)}
              onDuplicate={() => onDuplicateActivity(index)}
              onClickEdit={
                () => onClickEdit(activity.type === ActivityTypes.REST
                  ? EditorActionType.REST : EditorActionType.ACTIVITY, index)
              }
            />
          );
        })}
      </DraggableContainer>
      <ActionButtonContainer>
        <PrimaryButton icon={<CreateIcon />} onClick={() => onClickAdd(null)}>
          {texts.button.activity}
        </PrimaryButton>
        <PrimaryButton icon={<CreateIcon />} onClick={onClickCircuit}>
          {texts.button.circuit}
        </PrimaryButton>
        <PrimaryButton icon={<CreateIcon />} onClick={onClickRest}>
          {texts.button.rest}
        </PrimaryButton>
      </ActionButtonContainer>
      {(showActivityModal) && (
        <ActivityModal
          showModal={showActivityModal}
          onClose={onActivityModalClose}
          exercise={ExerciseData}
          onAddActivity={onAddUpdateActivity}
          isUpdate={isUpdate}
          isCircuitActivity={!!activities[selectedCircuitIndex]?.name}
          setSelectedExercise={setSelectedExercise}
          setShouldResetExerciseData={setShouldResetExerciseData}
        />
      )}
      {selectedAction === EditorActionType.CIRCUIT && (
        <AddCircuitModal
          onClose={onCloseModal}
          showModal={selectedAction === EditorActionType.CIRCUIT}
          onAddCircuit={onAddUpdateActivity}
          circuitData={activities[selectedActivityIndex] || null}
        />
      )}
      {selectedAction === EditorActionType.REST && (
        <RestModal
          onClose={onCloseModal}
          isOpen={selectedAction === EditorActionType.REST}
          onSave={onAddUpdateActivity}
          activity={activities[selectedActivityIndex] || null}
        />
      )}
      {showConfirmDialog && (
        <ConfirmDialog
          isOpen={showConfirmDialog}
          onConfirm={onRemoveActivity}
          onCancel={onCloseConfirmationModal}
          dialogTexts={{
            title: confirmationMessage,
          }}
        />
      )}
    </>
  );
};

Activities.propTypes = {
  activities: PropTypes.arrayOf(object).isRequired,
  selectedExercise: PropTypes.object,
  onActivitiesChange: PropTypes.func.isRequired,
  onClickAddActivity: PropTypes.func.isRequired,
  showActivityModal: PropTypes.bool.isRequired,
  setShowActivityModal: PropTypes.func.isRequired,
  setSelectedExercise: PropTypes.func.isRequired,
};

Activities.defaultProps = {
  selectedExercise: null,
};

export default Activities;
