import React, { useContext } from 'react';
import { Chat, ChatView } from '@fitmoola/system2-chat';
import 'stream-chat-react/dist/css/index.css';

import FirebaseContext from '../../../../../context/FirebaseContext';
import UserContext from '../../../../../context/UserContext';
import useSessionStore from '../../../../../hooks/useSessionStore';
import WebMediaRecorder from '../../WebMediaRecorder';

import {
  ChannelViewContainer,
} from './styles';

const ChatSideBar = () => {
  const {
    firebase,
  } = useContext(FirebaseContext);

  const {
    userId: chatUserId,
  } = useContext(UserContext);
  const sessionStore = useSessionStore();
  const webMediaRecorder = new WebMediaRecorder();

  return (
    <ChannelViewContainer>
      <Chat
        chatView={ChatView.SidebarView}
        firebase={firebase}
        sessionStore={sessionStore}
        chatUserId={chatUserId}
        mediaRecorder={webMediaRecorder}
      />
    </ChannelViewContainer>
  );
};

export default ChatSideBar;
