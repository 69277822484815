import React from 'react';

import Sidebar from '../components/Sidebar';
import useAssistantCoachSideBarConfig from './useAssistantCoachSidebarConfig';

const AssistantCoachSidebar = () => {
  const { entries } = useAssistantCoachSideBarConfig();
  return (
    <Sidebar entries={entries} />
  );
};

export default AssistantCoachSidebar;
