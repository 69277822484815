import styled from 'styled-components';
import { Button } from '@mui/material';

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  flex-direction: column;
  height: 100%;
  padding-top: 20px;
`;

const AddNewCoachButton = styled(Button)`
  margin: 20px;
`;

const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
`;

export {
  Container,
  AddNewCoachButton,
  ButtonsContainer,
};
