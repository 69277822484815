import styled from 'styled-components';

import colors from '../../../styles/colors';
import Filter from '../Filter';

const StyledFilter = styled(Filter)`
  background: ${colors.base.alpha};
`;

export {
  StyledFilter,
};
