import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import Product from '../../../../Model/Product';
import Select from '../../../../components/Select';
import FeaturesList from '../FeaturesList';
import {
  InputsContainer,
  Container,
  PlanSelectionFormControl,
  Title,
} from './styles';
import texts from './texts.json';

const ProductSelector = ({
  selectedProduct,
  onChange,
  isReadOnly,
  className,
  products,
  title,
}) => {
  const productOptions = useMemo(() => {
    const items = [];

    products.forEach((product) => {
      /*
        Only show products that are not hidden (e.g. The default free products that are used for testing). However,
        if the selected product is hidden, then we need to show it in the list so that the coach can see it in the
        contract modal.
      */
      if (!product.isHidden || selectedProduct?.id === product.id) {
        items.push({
          value: product.id,
          label: product.name || texts.unnamedProduct,
        });
      }
    });

    return items;
  }, [
    products,
    selectedProduct,
  ]);

  return (
    <Container className={className}>
      {!!title && (<Title>{title}</Title>)}
      <InputsContainer>
        <PlanSelectionFormControl>
          <Select
            value={selectedProduct?.id || ''}
            onChange={(e) => onChange(products.find((product) => product.id === e.target.value))}
            size="small"
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
            }}
            disabled={isReadOnly}
            options={productOptions}
            id="product-selector"
          />
        </PlanSelectionFormControl>
      </InputsContainer>
      {!!selectedProduct && (
        <FeaturesList productDoc={selectedProduct} />
      )}
    </Container>
  );
};

ProductSelector.propTypes = {
  selectedProduct: PropTypes.instanceOf(Product),
  onChange: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool,
  className: PropTypes.string,
  products: PropTypes.arrayOf(PropTypes.instanceOf(Product)),
  title: PropTypes.string,
};

ProductSelector.defaultProps = {
  selectedProduct: null,
  isReadOnly: false,
  className: '',
  products: [],
  title: '',
};

export default ProductSelector;
