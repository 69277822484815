import styled from 'styled-components';
import { OpenInNew } from '@mui/icons-material';
import { Accordion, Typography } from '@mui/material';

import colors from '../../../styles/colors';

const Text = styled(Typography)`
  margin-bottom: 5px;
`;

const Title = styled(Text)`
  font-weight: bold;
  font-size: 24px;
`;

const Description = styled(Text)`
  font-size: 16px;
  margin-bottom: 15px;
`;

const StyledOpenInNew = styled(OpenInNew)`
  margin-left: 5px;
  cursor: pointer;
  color: ${colors.shades.secondary2};
`;

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledAccordion = styled(Accordion)`
  margin-top: 15px;
`;

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 30px;
  }
`;

export {
  StyledOpenInNew,
  Container,
  StyledAccordion,
  Title,
  Description,
  HeaderRow,
};
