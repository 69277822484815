import React from 'react';
import PropTypes from 'prop-types';

import Select, {
  ListSubheader,
} from '../../../../../components/Select';
import { MealPlanType } from '../../../../Model/MealPlan';

import {
  StyledMenuItem,
  BadgesContainer,
  MealTimesTag,
  RecipesTag,
} from './styles';

import texts from './texts.json';

const MealPlanSelect = ({
  className,
  value,
  description,
  displayEmpty,
  mealPlanOptions,
  onChange,
}) => {
  const getMenuItemsFromMealPlans = (mealPlans) => (
    mealPlans.map(({
      id,
      name,
      numberOfMealTimes = 0,
      meals: numberOfMeals = 0,
      type,
    }) => (
      <StyledMenuItem key={id} value={id}>
        {name}
        <BadgesContainer>
          <MealTimesTag>{`${numberOfMealTimes} ${texts.mealTimes}`}</MealTimesTag>
          {type === MealPlanType.PREDEFINED && <RecipesTag>{`${numberOfMeals} ${texts.recipes}`}</RecipesTag>}
        </BadgesContainer>
      </StyledMenuItem>
    ))
  );

  const parseMealPlanOptions = (selectOptions = []) => {
    const liveTemplates = [];
    const predefinedTemplates = [];
    selectOptions.forEach((option) => {
      const { type } = option;
      if (type === MealPlanType.LIVE) {
        liveTemplates.push(option);
      } else {
        predefinedTemplates.push(option);
      }
    });

    let resultOptions = [];

    if (liveTemplates.length) {
      const liveTemplatesOptions = getMenuItemsFromMealPlans(liveTemplates);
      resultOptions = [
        <ListSubheader key="LIVE-TEMPLATE-SELECT-SUBHEADER">{texts.autoMealPlan}</ListSubheader>,
        ...liveTemplatesOptions,
      ];
    }

    if (predefinedTemplates.length) {
      const predefinedTemplatesOptions = getMenuItemsFromMealPlans(predefinedTemplates);
      resultOptions = [
        ...resultOptions,
        <ListSubheader key="PREDEFINED-TEMPLATE-SELECT-SUBHEADER">{texts.predefinedMealPlan}</ListSubheader>,
        ...predefinedTemplatesOptions,
      ];
    }

    return resultOptions;
  };

  const getNameFromId = (selectedValue) => mealPlanOptions.find(({ id }) => id === selectedValue)?.name || '';

  return (
    <Select
      className={className}
      value={value}
      description={description}
      displayEmpty={displayEmpty}
      options={mealPlanOptions}
      onChange={onChange}
      optionParserFunction={parseMealPlanOptions}
      renderValue={getNameFromId}
    />
  );
};

MealPlanSelect.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  description: PropTypes.string,
  displayEmpty: PropTypes.bool,
  mealPlanOptions: PropTypes.array,
  onChange: PropTypes.func,
};

MealPlanSelect.defaultProps = {
  className: '',
  value: '',
  description: '',
  displayEmpty: false,
  mealPlanOptions: [],
  onChange: () => {},
};

export default MealPlanSelect;
