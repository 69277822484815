import { NotificationAddOutlined } from '@mui/icons-material';
import styled from 'styled-components';

import {
  ReactComponent as SpecialRemindersIcon,
} from '../../../../assets/icons/menuIcons/feedMenuIcons/special-reminders.svg';
import Button from '../../../../components/Button';
import colors from '../../../../styles/colors';

const StyledAddIcon = styled(NotificationAddOutlined)`
  height: 18px;
  width: 18px;
  margin: 0 8px 0 0;
`;

const StyledContainer = styled.div`
  align-self: center;
`;

const CoachReminderButton = styled(Button)`
  font-size: 14px;
  width: auto;
  padding: 5px 15px;
  margin: 0 20px;
  font-weight: 500;
  border-radius: 36px;
  border: none;
  background-color: ${colors.shades.warning1};
`;

const StyledSpecialRemindersIcon = styled(SpecialRemindersIcon)`
  height: 16px;
  width: 16px;
  margin: 0 8px 0 0;
  > path {
    fill: ${colors.base.primary};
  }
`;

export {
  CoachReminderButton,
  StyledContainer,
  StyledAddIcon,
  StyledSpecialRemindersIcon,
};
