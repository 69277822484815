import styled from 'styled-components';

import colors from '../../../../styles/colors';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  display: flex;
  padding: 20px 0;
  justify-content: space-between;
`;
const TableContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 30px 30px 30px;
`;

const Title = styled.div`
  font-size: 24px;
  line-height: 28px;
  align-self: center;
  text-align: left;
  color: ${colors.shades.secondary8};
`;

export {
  Container,
  HeaderContainer,
  TableContainer,
  Title,
};
