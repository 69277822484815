import React from 'react';
import { PropTypes } from 'prop-types';

import variants from '../../../../components/Card/variants';
import Button from '../../../../components/Button/Button';
import OnboardingCard from '../../../../components/OnboardingCard';

import {
  CardGrid,
  StyledActionSuccessful,
  NotificationCardTitle,
} from './styles';

const SuccessfulPaymentNotificationCard = ({
  texts,
  onConfirmingFinalResult,
}) => (
  <CardGrid>
    <OnboardingCard variant={variants.PRIMARY}>
      <NotificationCardTitle>{texts.message}</NotificationCardTitle>
      <StyledActionSuccessful />
      <Button onClick={() => onConfirmingFinalResult(true)}>{texts.next}</Button>
    </OnboardingCard>
  </CardGrid>
);

SuccessfulPaymentNotificationCard.propTypes = {
  texts: PropTypes.shape({
    message: PropTypes.string,
    toAppointment: PropTypes.string,
    next: PropTypes.string,
  }).isRequired,
  onConfirmingFinalResult: PropTypes.func.isRequired,
};

export default SuccessfulPaymentNotificationCard;
