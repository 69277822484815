import React, { useContext } from 'react';

import LoadingPage from '../../../components/LoadingPage';
import ManageProgramContext from './ManageProgramContext';
import ManageProgramContextProvider from './ManageProgramContextProvider';

/**
 * Inserts ProgramManagementContextProvider.
 *
 * @param {Object} Component The component that needs the context provider.
 * @returns {Object} The component to render wrapped with the provider.
 */
const withManageProgramContextProvider = (Component) => (props) => (
  <ManageProgramContextProvider>
    <Component {...props} />
  </ManageProgramContextProvider>
);

const withManageProgramContextReady = (Component) => (props) => {
  const { isReady } = useContext(ManageProgramContext);

  return isReady ? <Component {...props} /> : <LoadingPage />;
};

export {
  withManageProgramContextProvider,
  withManageProgramContextReady,
};
