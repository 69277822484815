import React, {
  useMemo,
  useContext,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import useQuickSearch from '../../../hooks/useQuickSearch';
import { DateFormat } from '../../../utils/date';
import MonthSelect from '../MonthSelect';
import TableWithFilters from '../TableWithFilters';
import PayoutReconciliationContext from '../../context/PayoutReconciliationContext';
import texts from './texts.json';

const CommissionsTable = ({
  payoutsConfig,
  userId,
  title,
}) => {
  const {
    payoutReconciliationCollection,
    loadPayoutReconciliationData,
    clearData,
    isLoadingDocs,
  } = useContext(PayoutReconciliationContext);

  const onMonthChange = useCallback((date) => {
    if (date) {
      const { id: dateMonth } = date;
      const fromDate = moment.utc(dateMonth).startOf('month').format(DateFormat.DEFAULT_DATE_FORMAT);
      const toDate = moment.utc(dateMonth).endOf('month').format(DateFormat.DEFAULT_DATE_FORMAT);
      loadPayoutReconciliationData(payoutsConfig.payoutFunctionRef, userId, fromDate, toDate);
    } else {
      clearData();
    }
  }, [
    payoutsConfig,
    userId,
    loadPayoutReconciliationData,
    clearData,
  ]);

  const columns = useMemo(() => [
    {
      field: 'coach',
      quickSearch: true,
      headerName: texts.headers.coach,
      flex: 20,
    },
    {
      field: 'coachName',
      quickSearch: true,
      headerName: texts.headers.coachName,
      flex: 20,
    },
    {
      field: 'user',
      quickSearch: true,
      headerName: texts.headers.user,
      flex: 20,

    },
    {
      field: 'userName',
      quickSearch: true,
      headerName: texts.headers.userName,
      flex: 20,
    },
    {
      field: 'startDate',
      headerName: texts.headers.startDate,
      flex: 15,
    },
    {
      field: 'contractId',
      quickSearch: true,
      headerName: texts.headers.contractId,
      flex: 20,
    },
    {
      field: 'payoutDate',
      headerName: texts.headers.payoutDate,
      flex: 15,
    },
    {
      field: 'reportingCategory',
      headerName: texts.headers.reportingCategory,
      flex: 15,
    },
    {
      field: 'connectedAccountDirectChargeId',
      headerName: texts.headers.chargeId,
      flex: 15,
    },
    {
      field: 'netAmountWithCurrency',
      headerName: texts.headers.netAmount,
      flex: 15,
    },
    {
      field: 'feesConfigurationString',
      headerName: texts.headers.feesConfiguration,
      flex: 20,
    },
    {
      field: 'totalCommissionableFeePercentage',
      headerName: texts.headers.totalCommissionableFeePercentage,
      flex: 15,
    },
    {
      field: 'commissionableFeeAmount',
      headerName: texts.headers.commissionableFeeAmount,
      flex: 15,
    },
    {
      field: payoutsConfig.commissionNetAmount,
      headerName: texts.headers.commission,
    },
    {
      field: payoutsConfig.commissionNetAmountWithCurrency,
      headerName: texts.headers.commission,
      flex: 15,
    },
    {
      field: 'recurringSubscription',
      headerName: texts.headers.recurringSubscription,
      flex: 15,
    },
  ], [payoutsConfig]);

  // Get the first filtering stage which returns the rows that match the quick search input.
  const {
    filteredRows,
  } = useQuickSearch(payoutReconciliationCollection.docs, columns);

  return (
    <TableWithFilters
      title={title}
      rows={filteredRows}
      columns={columns}
      columnVisibilityModel={{
        // Hide columns
        coach: false,
        connectedAccountDirectChargeId: false,
        [payoutsConfig.commissionNetAmount]: false,
      }}
      isLoading={isLoadingDocs}
      csvHeaders={columns.map(({ field }) => field)}
      csvFileName={`payout_reconciliation_${userId}`}
      filters={[
        <MonthSelect
          onChange={onMonthChange}
          key="monthSelect"
        />,
      ]}
    />
  );
};

CommissionsTable.propTypes = {
  payoutsConfig: PropTypes.object.isRequired,
  userId: PropTypes.string.isRequired,
  title: PropTypes.string,
};

CommissionsTable.defaultProps = {
  title: texts.title,
};

export default CommissionsTable;
