import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import {
  Route,
  Redirect,
  useLocation,
} from 'react-router-dom';

import useSessionStore from '../../hooks/useSessionStore';

/**
 * A wrapper for <Route> that redirects to the login page if you're not yet authenticated, you're not an admin,
 * or the user id of the path doesn't corresponds to the already logged in user.
 */
const AdminRoute = ({
  component: Component,
  children,
  ...rest
}) => {
  const { isAdmin } = useSessionStore();
  const location = useLocation();
  const { search, state } = location;

  return (
    <Route
      {...rest}
      render={(props) => {
        /*
          We can't use route match here as the routes have not been rendered just yet.
          Instead, we do a simple check that the admin user is of the logged in user needs to be
          part of the path trying to be rendered.
          The assumption is that one admin can not see routes of another admin.
        */
        if (isAdmin) {
          return Component ? <Component {...props} /> : children;
        }

        return (
          <Redirect to={{
            pathname: '/login',
            search,
            state,
          }}
          />
        );
      }}
    />
  );
};

AdminRoute.propTypes = {
  component: PropTypes.elementType,
  children: PropTypes.node,
};

AdminRoute.defaultProps = {
  children: null,
  component: null,
};

export default compose(
  observer,
)(AdminRoute);
