import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  width: 100%;
  text-align: center;
  padding: 20px;
`;

const EmptyViewContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export {
  Container,
  EmptyViewContainer,
};
