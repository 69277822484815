import React, {
  useState,
  useContext,
  useMemo,
  useEffect,
  useCallback,
} from 'react';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';

import InternalAssignmentsContext,
{
  withInternalAssignmentsContextProvider,
  withInternalAssignmentsContextReady,
} from '../../../../../context/InternalAssignmentsContext';
import FirebaseContext from '../../../../../../context/FirebaseContext';
import { DateFormat } from '../../../../../../utils/date';
import useComponentMounted from '../../../../../../hooks/useComponentMounted';
import LoadingOverlay from '../../../../../components/LoadingOverlay';
import CoachesListContext from '../../../../../context/CoachesListContext';
import { getTimeSpentData, RemoteFunctions } from '../../../../../../utils/analytics';
import { formatDuration, TimeDurationFormat } from '../../../../../../utils/time';
import CoachActiveUsersAnalytics from '../../../../../Model/analytics/CoachActiveUsersAnalytics';
import AnalyticsGroup from '../../../components/AnalyticsGroup';
import ChartContainer from '../../../components/ChartContainer';
import BarChart from '../../../../../components/BarChart';
import AnalyticsTimeCard from '../AnalyticsTimeCard/AnalyticsTimeCard';
import texts from './texts.json';

const InternalUserView = ({
  selectedPeriod,
  userId,
}) => {
  const [totalTimeSpent, setTotalTimeSpent] = useState(0);
  const [featureTimeSpent, setFeatureTimeSpent] = useState([]);
  const [timeSpentGrpByCoach, setTimeSpentGrpByCoach] = useState([]);
  const [activeSubscriptions, setActiveSubscriptions] = useState(new Map());
  const [isLoading, setIsLoading] = useState(false);

  const { firebase: { remote } } = useContext(FirebaseContext);
  const { coachesCollection } = useContext(CoachesListContext);
  const isComponentMountedRef = useComponentMounted();

  const {
    internalAssignmentsCol: { docs: internalAssignments },
  } = useContext(InternalAssignmentsContext);

  const coachAssignments = useMemo(() => internalAssignments
    .filter((assignment) => assignment.userId === userId
      && coachesCollection.docs.find((coach) => coach.id === assignment.coach)),
  [internalAssignments, userId, coachesCollection.docs]);

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      const from = moment().utc().subtract(selectedPeriod, 'days').format(DateFormat.DATE_FORMAT_YYYYMMDD);
      const to = moment().utc().format(DateFormat.DATE_FORMAT_YYYYMMDD);
      const getTimeSpent = async () => getTimeSpentData(
        remote,
        RemoteFunctions.GET_TIME_SPENT_BY_USER,
        {
          from,
          to,
          userId,
        },
      );
      const getTimeSpentGrpByCoach = async () => getTimeSpentData(
        remote,
        RemoteFunctions.GET_TIME_SPENT_BY_USER,
        {
          from,
          to,
          userId,
          groupByCoach: true,
        },
      );
      const getFeatureTimeSpent = async () => getTimeSpentData(
        remote,
        RemoteFunctions.GET_FEATURE_TIME_SPENT,
        {
          from,
          to,
          userId,
        },
      );
      const timeSpentData = await getTimeSpent();
      const timeSpentGrpByCoachData = await getTimeSpentGrpByCoach();
      const featureTimeSpentData = await getFeatureTimeSpent();
      if (isComponentMountedRef.current) {
        setTotalTimeSpent(timeSpentData[0]?.timeSpent || 0);
        setTimeSpentGrpByCoach(timeSpentGrpByCoachData);
        setFeatureTimeSpent(featureTimeSpentData);
        setIsLoading(false);
      }
    };

    loadData();
  }, [
    userId,
    isComponentMountedRef,
    remote,
    selectedPeriod,
  ]);

  useEffect(() => {
    const loadActiveUserCount = async () => {
      const dateTxt = moment().subtract(1, 'days').format(DateFormat.DEFAULT_DATE_FORMAT);
      const coachActiveUsers = new Map();
      const promises = internalAssignments.map(async (assignment) => {
        const coachActiveUsersAnalytics = new CoachActiveUsersAnalytics(assignment.coach, dateTxt);
        await coachActiveUsersAnalytics.fetch();
        coachActiveUsers.set(assignment.coach, coachActiveUsersAnalytics.activeUsers);
      });
      await Promise.all(promises);
      if (isComponentMountedRef.current) {
        setActiveSubscriptions(coachActiveUsers);
      }
    };
    loadActiveUserCount();
  }, [internalAssignments, isComponentMountedRef]);

  const getTimeSpentPerClient = useCallback((coachId) => formatDuration(activeSubscriptions.get(coachId) > 0
    ? totalTimeSpent / activeSubscriptions.get(coachId) : 0, TimeDurationFormat.LONG_FORMAT),
  [activeSubscriptions, totalTimeSpent]);

  const activeClients = useMemo(() => Array.from(activeSubscriptions.values()).reduce((acc, value) => acc + value, 0),
    [activeSubscriptions]);

  return (
    <>
      <AnalyticsGroup>
        <AnalyticsTimeCard
          title={formatDuration(totalTimeSpent, TimeDurationFormat.LONG_FORMAT)}
          subTitle={texts.cardHeaders.totalTimeSpent}
        />
        <AnalyticsTimeCard
          title={activeClients}
          subTitle={texts.cardHeaders.activeClients}
        />
        <AnalyticsTimeCard
          title={formatDuration(activeClients > 0 ? totalTimeSpent / activeClients : 0, TimeDurationFormat.LONG_FORMAT)}
          subTitle={texts.cardHeaders.timeSpentPerClient}
        />
      </AnalyticsGroup>
      <ChartContainer title={texts.timeSpentByFeature}>
        <BarChart
          data={featureTimeSpent?.map((item) => ({
            name: item.feature,
            'Time Spent': item.timeSpent,
          }))}
          keys={['Time Spent']}
          toolTipFormatter={(value) => formatDuration(value, TimeDurationFormat.LONG_FORMAT)}
        />
      </ChartContainer>
      <AnalyticsGroup>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell variant="head">{texts.tableHeaders.coach}</TableCell>
              <TableCell variant="head">{texts.tableHeaders.timeSpent}</TableCell>
              <TableCell variant="head">{texts.tableHeaders.activeClients}</TableCell>
              <TableCell variant="head">{texts.tableHeaders.timeSpentPerClient}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {coachAssignments.map((assignment) => (
              <TableRow key={assignment.id}>
                <TableCell>{coachesCollection.docs.find((coach) => coach.id === assignment.coach)?.name}</TableCell>
                <TableCell>
                  {
                    formatDuration(
                      timeSpentGrpByCoach.find((item) => item.userId === assignment.coach)?.timeSpent || 0,
                      TimeDurationFormat.LONG_FORMAT,
                    )
                  }
                </TableCell>
                <TableCell>
                  {activeSubscriptions.get(assignment.coach) || 0}
                </TableCell>
                <TableCell>
                  {getTimeSpentPerClient(assignment.coach)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </AnalyticsGroup>
      <LoadingOverlay isLoading={isLoading} />
    </>
  );
};

InternalUserView.propTypes = {
  userId: PropTypes.string.isRequired,
  selectedPeriod: PropTypes.number.isRequired,
};

export default compose(
  withInternalAssignmentsContextProvider,
  withInternalAssignmentsContextReady,
  observer,
)(InternalUserView);
