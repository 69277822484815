import moment from 'moment';

import { DateFormat } from '../../utils/date';
import {
  ClientStatus,
  clientStatusLabel,
  filterChecks,
} from './statusFilter';

const getUserStatus = (user, dateFormat) => {
  let status;
  let associatedDateString;
  if (user.isActive) {
    status = user.subscriptionStatus?.toLowerCase() || clientStatusLabel[ClientStatus.ACTIVE];
    if (filterChecks[ClientStatus.ON_BREAK](user)) {
      status = clientStatusLabel[ClientStatus.ON_BREAK];
      if (dateFormat) {
        associatedDateString = `break ends on ${moment(user.breakEndDate).format(dateFormat)}`;
      } else {
        associatedDateString = `break ends ${moment(user.breakEndDate).fromNow()}`;
      }
    }
    if (filterChecks[ClientStatus.FUTURE_START](user)) {
      status = clientStatusLabel[ClientStatus.FUTURE_START];
      if (dateFormat) {
        associatedDateString = `${moment(user.serviceStartAt)
          .format(dateFormat)}`;
      } else {
        associatedDateString = `service starts ${moment(user.serviceStartAt).fromNow()}`;
      }
    }
    if (filterChecks[ClientStatus.FUTURE_CANCELLATION](user)) {
      status = clientStatusLabel[ClientStatus.FUTURE_CANCELLATION];
      if (dateFormat) {
        associatedDateString = `${moment.unix(user.cancelAt)
          .format(dateFormat)}`;
      } else {
        associatedDateString = `cancels ${moment.unix(user.cancelAt).fromNow()}`;
      }
    }
    if (filterChecks[ClientStatus.MONTHLY_START](user)) {
      status = clientStatusLabel[ClientStatus.MONTHLY_START];
      if (dateFormat) {
        associatedDateString = `${moment.unix(user.monthlyStartAt)
          .format(dateFormat)}`;
      } else {
        associatedDateString = `monthly starts ${moment.unix(user.monthlyStartAt).fromNow()}`;
      }
    }
  } else {
    status = user.subscriptionStatus?.toLowerCase().replace('_', ' ') || clientStatusLabel[ClientStatus.INACTIVE];
  }

  return {
    status,
    associatedDateString,
  };
};

const getUserStatusWithDate = (clientDoc) => {
  if (clientDoc) {
    const { status, associatedDateString } = getUserStatus(clientDoc, DateFormat.DEFAULT_DATE_FORMAT);
    let statusText = status;
    if (associatedDateString) {
      statusText += ` (${associatedDateString})`;
    }
    return statusText;
  }
  return '';
};

export {
  getUserStatus,
  getUserStatusWithDate,
};
