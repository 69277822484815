const ToggleOption = {
  ENABLE: 'ENABLE',
  DISABLE: 'DISABLE',
};

const ToggleSetting = {
  MACROS: 'macrosInfoDisabled',
  RECIPE: 'recipeNutritionInfoDisabled',
};

export {
  ToggleOption,
  ToggleSetting,
};
