import React from 'react';

const initialValues = {};

const MsgEndpointContext = React.createContext(initialValues);

export default MsgEndpointContext;
export {
  initialValues,
};
