import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';

import useAuthentication, {
  SignInMethod,
  AuthErrorCode,
} from '../../../../hooks/useAuthentication';

import { SuccessMessage, ErrorMessage, ActionButton } from '../../styles';

import ForgotPasswordContainer from './styles';

const ForgotPassword = ({
  sendAuthLink,
  email,
}) => {
  const {
    getSignInMethod,
    sendPasswordResetEmail,
    createAnonymousAccountWithEmail,
  } = useAuthentication();
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const onForgotPasswordClick = async () => {
    try {
      const authMethod = await getSignInMethod(email);

      switch (authMethod) {
        case SignInMethod.EMAIL_PASSWORD: {
          await sendPasswordResetEmail(email);
          setSuccessMessage(`Password reset email sent to ${email}`);
          setError(null);
          break;
        }
        case SignInMethod.EMAIL_LINK: {
          sendAuthLink();
          break;
        }
        case SignInMethod.NO_ACCOUNT:
        default: {
          try {
            const user = await createAnonymousAccountWithEmail(email);
            await user.delete();
            setSuccessMessage(null);
            setError({ message: 'No such user.' });
          } catch (err) {
            if (err.code === AuthErrorCode.EMAIL_ALREADY_IN_USE) {
              sendAuthLink();
            } else {
              Sentry.captureException(err);
            }
          }
        }
      }
    } catch (e) {
      setSuccessMessage(null);
      setError(e);
    }
  };

  return (
    <ForgotPasswordContainer>
      <ActionButton onClick={onForgotPasswordClick}>Forgot Password?</ActionButton>
      {error && <ErrorMessage>{error.message}</ErrorMessage>}
      {successMessage && <SuccessMessage>{successMessage}</SuccessMessage>}
    </ForgotPasswordContainer>
  );
};

ForgotPassword.propTypes = {
  email: PropTypes.string.isRequired,
  sendAuthLink: PropTypes.func.isRequired,
};

export default ForgotPassword;
