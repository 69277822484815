import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import DialogRoundedModal from '../../../../../../components/DialogRoundedModal';
import NutritionHistory from '../../../../../components/NutritionHistory';

import {
  ModalContent,
  StyledMealPlateIcon,
} from './styles';

import texts from './texts.json';

const NutritionGoalsHistoryModal = ({
  isOpen,
  onClose,
  history,
  currentGoals,
}) => {
  const fullHistory = useMemo(() => (
    [...history, currentGoals].reverse()
  ), [
    history,
    currentGoals,
  ]);

  return (
    <DialogRoundedModal
      open={isOpen}
      onClose={onClose}
      title={texts.title}
      description={texts.description}
      IconComponent={<StyledMealPlateIcon />}
    >
      <ModalContent>
        <NutritionHistory history={fullHistory} />
      </ModalContent>
    </DialogRoundedModal>
  );
};

NutritionGoalsHistoryModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  history: PropTypes.array,
  currentGoals: PropTypes.object,
};

NutritionGoalsHistoryModal.defaultProps = {
  history: [],
  currentGoals: {},
};

export default NutritionGoalsHistoryModal;
