import React, { useContext } from 'react';
import { CircularProgress, Divider } from '@mui/material';
import PropTypes from 'prop-types';

import FeedContext from '../../../../context/FeedContext';

import ActivityHeader from '../ActivityHeader';
import ActivityContent from '../ActivityContent';
import ActivityAction from '../ActivityAction';
import ActivityStatus from '../ActivityStatus';

import {
  FeedActivityContainer,
  StyledLoaderContainer,
} from './styles';

const FeedActivity = ({ activity, onActivityActionClick }) => {
  const { activityInProgress } = useContext(FeedContext);

  if (activityInProgress.includes(activity.id)) {
    return <StyledLoaderContainer><CircularProgress color="inherit" /></StyledLoaderContainer>;
  }

  return (
    <FeedActivityContainer>
      <ActivityHeader activity={activity} />
      <ActivityStatus activity={activity} />
      <ActivityContent activity={activity} />
      <Divider />
      <ActivityAction
        activity={activity}
        onActivityActionClick={onActivityActionClick}
      />
    </FeedActivityContainer>
  );
};

FeedActivity.propTypes = {
  activity: PropTypes.object,
  onActivityActionClick: PropTypes.func.isRequired,
};

FeedActivity.defaultProps = {
  activity: {},
};

export default FeedActivity;
