import styled from 'styled-components';
import { Box } from '@mui/material';

import Tabs from '../../components/Tabs';
import { StyledTab as Tab } from '../../components/Tabs/styles';

import colors from '../../../styles/colors';

const Container = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${colors.shades.gamma5};
  position: relative;
`;

const StyledTabs = styled(Tabs)`
  border-bottom: unset;
  min-height: unset;

  ${Tab} {
    border-radius: unset;
    min-width: unset;
    padding: 20px 5px;
    column-gap: 5px;
    background-color: ${colors.shades.gamma5};

    &:not(:first-child) {
      margin-left: 15px;
    }

    &.Mui-selected {
      color: unset;
      background-color: unset;
      box-shadow: inset 0 -3px 0 ${colors.shades.secondary3};
      &::before {
        content: none;
      }
    }
  }
`;

const TitledActionContainer = styled.div`
  border: 1px solid ${colors.shades.secondary7};
  border-radius: 5px;
  margin: 0 20px 20px 20px;
`;
const TitledActionContainerHeader = styled.header`
  border: none;
  border-radius: 5px 5px 0 0;
  color: ${colors.shades.secondary8};
  background: ${colors.shades.secondary10};
  text-transform: uppercase;
  padding: 15px 20px;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 1px;
`;
const TitledActionContainerSection = styled.section`
  border-top: 1px solid ${colors.shades.secondary7};
  border-bottom: 1px solid ${colors.shades.secondary7};
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;
const TitledActionContainerFooter = styled.footer`
  border: none;
  border-radius: 0 0 5px 5px;
  background: ${colors.shades.secondary10};
  padding: 15px 20px;
  gap: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const HeaderContainer = styled.div`
  display: flex;
  padding: 20px 0;
  justify-content: space-between;
`;
const Title = styled.div`
  font-size: 24px;
  line-height: 28px;
  align-self: center;
  text-align: left;
  color: ${colors.shades.secondary8};
`;

export {
  Container,
  Header,
  StyledTabs,
  TitledActionContainerFooter,
  TitledActionContainerSection,
  TitledActionContainerHeader,
  TitledActionContainer,
  Title,
  HeaderContainer,
};
