import fieldName, { questionFieldName } from './formFields';
import { HiddenFieldType } from './questionType';
import texts from './texts';

const isDefault = true;

const defaultFields = {
  [fieldName.HIDDEN_FIELDS]: [
    {
      [questionFieldName.TYPE]: HiddenFieldType.TEXT,
      [questionFieldName.ID]: texts.defaultFields.source,
      isDefault,
    },
  ],
  [fieldName.FIELDS]: [],
};

export default defaultFields;
