import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useContext,
} from 'react';
import { IonSpinner } from '@ionic/react';
import {
  ListItem,
  ListItemButton,
  ListItemAvatar,
  Avatar,
  Radio,
  TextField,
  InputAdornment,
} from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import format from 'string-template';
import { debounce } from 'throttle-debounce';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import { useRouteMatch, useHistory } from 'react-router-dom';

import Modal from '../../../../components/Modal';
import Button from '../../../../components/Button';
import useSessionStore from '../../../../hooks/useSessionStore';
import { isCoachRoute } from '../../../../utils/routes';
import ExternalCoachContext from '../../../context/ExternalCoachContext';
import CoachesListContext from '../../../context/CoachesListContext';
import useToolsNavigation from '../../../hooks/useToolsNavigation';

import InternalAssignment from '../../../../Model/InternalAssignment';
import useComponentMounted from '../../../../hooks/useComponentMounted';
import LabelCheckbox from '../../../components/LabelCheckbox';
import text from './text.json';
import {
  Container,
  StyledList,
  ViewAsRow,
  StyledListItemText,
} from './styles';

const SEARCH_DEBOUNCE_TIME = 350;

const CoachSelectorModal = (modalProps) => {
  const [coaches, setCoaches] = useState([]);
  const [showAllCoaches, setShowAllCoaches] = useState(false);
  const [hasShowAllCoachesCheckbox, setHasShowAllCoachesCheckbox] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [filteredList, setFilteredList] = useState([]);
  const { externalCoachDoc } = useContext(ExternalCoachContext);
  const {
    isReady,
    coachesCollection,
  } = useContext(CoachesListContext);

  const searchFieldRef = React.useRef(null);

  const {
    path,
    params: {
      userId: urlCoachId,
    },
  } = useRouteMatch();
  const history = useHistory();
  const isCoachUrl = isCoachRoute(path);
  const { navigateToCoachTools } = useToolsNavigation();
  const {
    authUser,
    isAdmin,
    isCoachAssistant,
    isInsideSales,
  } = useSessionStore();
  const isComponentMountedRef = useComponentMounted();

  useEffect(() => {
    const init = async () => {
      const assignmentsCollection = await InternalAssignment.getAllCurrentAssignmentsByUserId(authUser.uid);
      const assignments = assignmentsCollection.docs;
      if (!assignments.length) {
        setShowAllCoaches(true);
        setHasShowAllCoachesCheckbox(false);
      }

      let fullCoachesList = [...coachesCollection.docs];

      // Get the CRX user data from the coaches list, if available.
      const isCRX = !!fullCoachesList.find(({ id }) => authUser.uid === id);

      // If no CRX data has been found, then we add it to the list of coaches
      if (!isCRX) {
        const crxData = {
          id: authUser.uid,
          name: `${authUser.displayName} (CRX)`,
          email: authUser.email,
        };

        fullCoachesList = [...fullCoachesList, crxData];
      }

      let coachesData = fullCoachesList.filter((coach) => {
        // We should hide the currently loaded coach from the view-as list.
        if ((isCoachUrl && urlCoachId === coach.id)) {
          return false;
        }

        return showAllCoaches || !!assignments.some((assignment) => assignment.coach === coach.id);
      });

      // Sort the coaches list alphabetically.
      coachesData = coachesData.sort((a, b) => (a.name.localeCompare(b.name)));

      if (isComponentMountedRef.current) {
        setCoaches(coachesData);
        setFilteredList(coachesData);
      }
    };
    if (isReady) {
      init();
    }
  }, [
    authUser,
    isAdmin,
    isCoachAssistant,
    isInsideSales,
    isReady,
    isCoachUrl,
    urlCoachId,
    coachesCollection,
    coachesCollection.docs.length,
    showAllCoaches,
    isComponentMountedRef,
    externalCoachDoc,
  ]);

  const filterList = useMemo(() => debounce(SEARCH_DEBOUNCE_TIME, () => {
    const searchText = searchFieldRef.current.value.toLowerCase();

    if (searchText) {
      const list = coaches.filter(({ email = '', name = '' }) => (
        name.toLowerCase().includes(searchText) || email.toLowerCase().includes(searchText)
      ));
      setFilteredList(list);
    } else {
      setFilteredList(coaches);
    }
  }), [
    coaches,
    searchFieldRef,
  ]);

  const onViewAsClick = useCallback(() => {
    navigateToCoachTools(filteredList[selectedIndex].id);
    // Refresh the page as soon as we change the url for the new coach to reset the coach feed section.
    // TODO: Remove this when we have our own feed implementation.
    history.go(0);
  }, [
    filteredList,
    selectedIndex,
    navigateToCoachTools,
    history,
  ]);

  const onSearchChange = useCallback(() => {
    filterList();
    setSelectedIndex(-1);
  }, [
    filterList,
  ]);

  const coachListToRender = useMemo(() => (
    filteredList.map(({ name, avatar, id }, index) => (
      <ListItem
        key={id}
        divider={index !== filteredList.length - 1}
        disableGutters
        disablePadding
      >
        <ListItemButton
          onClick={() => setSelectedIndex(index)}
        >
          <ListItemAvatar>
            <Avatar
              src={avatar}
            />
          </ListItemAvatar>
          <StyledListItemText>{name}</StyledListItemText>
          <Radio checked={index === selectedIndex} />
        </ListItemButton>
      </ListItem>
    ))
  ), [
    filteredList,
    selectedIndex,
  ]);

  const modalContent = useMemo(() => {
    if (!isReady) {
      return <IonSpinner name="crescent" />;
    }

    const extraData = selectedIndex !== -1 ? `: ${filteredList[selectedIndex].name}` : '';
    const viewAsText = format(text.viewAsButton, { extraData });
    return (
      <>
        <ViewAsRow>
          <small>
            {format(text.loggedInAsUser, { username: authUser.displayName })}
          </small>
          <LabelCheckbox
            isChecked={showAllCoaches}
            onChange={(checked) => setShowAllCoaches(checked)}
            description={text.showAllCoaches}
            disabled={!hasShowAllCoachesCheckbox}
          />
        </ViewAsRow>
        <TextField
          label={text.searchCoach}
          variant="outlined"
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={onSearchChange}
          inputRef={searchFieldRef}
        />
        <StyledList>
          {coachListToRender}
        </StyledList>
        <Button
          onClick={onViewAsClick}
          disabled={selectedIndex === -1}
        >
          {viewAsText}
        </Button>
      </>
    );
  }, [
    authUser.displayName,
    hasShowAllCoachesCheckbox,
    isReady,
    coachListToRender,
    showAllCoaches,
    selectedIndex,
    filteredList,
    onSearchChange,
    onViewAsClick,
  ]);

  return (
    <Modal {...modalProps}>
      <Container>
        {modalContent}
      </Container>
    </Modal>
  );
};

export default compose(
  observer,
)(CoachSelectorModal);
