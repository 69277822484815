import React from 'react';
import PropTypes from 'prop-types';

import { Divider } from '@mui/material';
import useToolsNavigation from '../../hooks/useToolsNavigation';

import {
  StyledModal,
  ModalContentWrapper,
  AnnouncementSection,
  SettingsActionsSection,
  Announcement,
  Title,
  SubTitle,
  CrossButton,
  StyledButton,
  Cross,
  StyledList,
  StyledListElement,
  StyledTypography,
} from './styles';

import texts from './texts.json';

const CoachMissingSettingsModal = ({ isOpen, onClose, missingSettings }) => {
  const { navigateTo } = useToolsNavigation();

  const handleNavigation = (path) => {
    navigateTo(path);
    onClose();
  };

  return (
    <StyledModal open={isOpen} onClose={onClose}>
      <ModalContentWrapper>
        <CrossButton onClick={onClose}>
          <Cross />
        </CrossButton>
        <AnnouncementSection>
          <Announcement />
          <Title>{texts.title}</Title>
          <SubTitle>{texts.subTitle}</SubTitle>
        </AnnouncementSection>
        <Divider />
        <SettingsActionsSection>
          <StyledList>
            {missingSettings.map(({ path, buttonText, label }) => (
              <StyledListElement key={label}>
                <StyledTypography>{label}</StyledTypography>
                <StyledButton onClick={() => handleNavigation(path)} full={false}>
                  <StyledTypography>{buttonText}</StyledTypography>
                </StyledButton>
              </StyledListElement>
            ))}
          </StyledList>
        </SettingsActionsSection>
      </ModalContentWrapper>
    </StyledModal>
  );
};

CoachMissingSettingsModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  missingSettings: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      buttonText: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
};

CoachMissingSettingsModal.defaultProps = {
  isOpen: false,
  onClose: () => {},
  missingSettings: [],
};

export default CoachMissingSettingsModal;
