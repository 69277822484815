import React from 'react';
import PropTypes from 'prop-types';

import BaseContractModal from '../../BaseContractModal';
import ReonboardingContractModalContent from './ReonboardingContractModalContent';

const ReonboardingContractModal = ({
  user,
  coachId,
  showModal,
  onClose,
}) => (
  <BaseContractModal
    showModal={showModal}
    onClose={onClose}
  >
    <ReonboardingContractModalContent
      user={user}
      coachId={coachId}
      onClose={onClose}
    />
  </BaseContractModal>
);

ReonboardingContractModal.propTypes = {
  user: PropTypes.object.isRequired,
  coachId: PropTypes.string.isRequired,
  showModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ReonboardingContractModal;
