import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as DownloadIcon } from '../../../assets/icons/download-icon.svg';
import { downloadCsv } from '../../utils/csv';
import { StyledButton } from './styles';
import texts from './texts.json';

const DownloadCSVButton = ({
  className,
  csvHeaders,
  rows,
  fileName,
  disabled,
  onClick,
}) => (
  <StyledButton
    variant="contained"
    onClick={onClick || (() => downloadCsv(csvHeaders, rows, fileName))}
    className={className}
    disabled={disabled}
  >
    <DownloadIcon />
    {texts.cta}
  </StyledButton>
);

DownloadCSVButton.propTypes = {
  className: PropTypes.string,
  csvHeaders: PropTypes.array,
  rows: PropTypes.array,
  fileName: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

DownloadCSVButton.defaultProps = {
  className: '',
  disabled: false,
  onClick: null,
  csvHeaders: [],
  rows: [],
  fileName: '',
};

export default DownloadCSVButton;
