import React from 'react';
import PropTypes from 'prop-types';

import defaultAvatar from '../../assets/avatar.svg';
import AvatarSizeType from './AvatarSizes';

import {
  StyledAvatar,
  StyledImage,
  AvatarWrapper,
  StyledBadge,
} from './styles';
import texts from './texts.json';

const Avatar = ({
  image,
  className,
  name,
  showNewBadge,
  avatarSize,
}) => {
  const renderAvatar = (userName, userImage, cssClassName, size) => {
    if (userImage) {
      return <StyledImage className={cssClassName} src={userImage} alt="Avatar" $avatarSize={size} />;
    }
    if (userName) {
      return (
        <StyledAvatar className={cssClassName} $avatarSize={size}>
          {userName[0].toUpperCase()}
        </StyledAvatar>
      );
    }
    return <StyledImage className={cssClassName} src={defaultAvatar} alt="Avatar" $avatarSize={size} />;
  };

  return (
    <AvatarWrapper>
      <StyledBadge
        badgeContent={texts.new}
        invisible={!showNewBadge}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        $avatarSize={avatarSize}
        $showNewBadge={showNewBadge}
      >
        {renderAvatar(name, image, className, avatarSize)}
      </StyledBadge>
    </AvatarWrapper>
  );
};

Avatar.propTypes = {
  image: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  showNewBadge: PropTypes.bool,
  avatarSize: PropTypes.oneOf(AvatarSizeType),
};

Avatar.defaultProps = {
  image: '',
  className: '',
  name: '',
  showNewBadge: false,
  avatarSize: AvatarSizeType.MEDIUM,
};

export default Avatar;
