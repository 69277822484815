import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import CopyAllIcon from '@mui/icons-material/CopyAll';

import InputLabel from '../../../../../../components/v2/InputLabel';
import useToast from '../../../../../hooks/useToast';
import {
  PlaceholderButton,
  PlaceholdersContainer,
  PlaceholderTip,
} from '../../../../PlaceHolder/styles';

import {
  InputContainer,
  StyledTextField,
  TextContainer,
} from './styles';
import texts from './texts.json';

const MsgPlaceholder = {
  CLIENT_NAME: 'clientName',
  COACH_NAME: 'coachName',
  CONTACT_NUMBER: 'contactNo',
  PURCHASE_LINK: 'purchaseLink',
  LEAD_NAME: 'leadName',
};

const MsgBody = ({
  msgBody,
  onMsgBodyChange,
  showLabel,
  isReadOnly,
  placeHolders,
}) => {
  const { showToast } = useToast();

  const copyPlaceholderValue = useCallback(async (placeholderValue) => {
    await navigator.clipboard.writeText(`{${placeholderValue}}`);
    showToast(texts.placeholderCopied);
  }, [
    showToast,
  ]);

  return (

    <InputContainer spacing={4} direction="row">
      <TextContainer>
        {showLabel && (
          <InputLabel>{texts.body}</InputLabel>
        )}
        <StyledTextField
          variant="outlined"
          value={msgBody}
          multiline
          rows={15}
          onChange={(e) => onMsgBodyChange(e.target.value)}
          disabled={isReadOnly}
          placeholder={texts.inputPlaceholder}
        />
      </TextContainer>
      <PlaceholdersContainer>
        <PlaceholderTip>{texts.placeholderTitle}</PlaceholderTip>
        {placeHolders.map((placeholderValue) => (
          <PlaceholderButton
            key={placeholderValue}
            startIcon={<CopyAllIcon />}
            onClick={() => copyPlaceholderValue(placeholderValue)}
            disabled={isReadOnly}
          >
            {`{${placeholderValue}}`}
          </PlaceholderButton>
        ))}
        <PlaceholderTip>{texts.placeholderDescription}</PlaceholderTip>
      </PlaceholdersContainer>
    </InputContainer>
  );
};

MsgBody.propTypes = {
  msgBody: PropTypes.string,
  onMsgBodyChange: PropTypes.func.isRequired,
  showLabel: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  placeHolders: PropTypes.arrayOf(PropTypes.string).isRequired,
};

MsgBody.defaultProps = {
  msgBody: '',
  showLabel: true,
  isReadOnly: false,
};

export default MsgBody;

export {
  MsgPlaceholder,
};
