import React, {
  useCallback,
  useContext,
} from 'react';
import { useChannelStateContext } from 'stream-chat-react';
import PropTypes from 'prop-types';

import ChatContext from '../../../../context/ChatContext';
import useToolsNavigation from '../../../../hooks/useToolsNavigation';
import ClientQuickInfoButton from '../../../../components/ClientQuickInfoButton';
import useChannel from '../../hooks/useChannel';
import MessagingChannelProfilePic from '../MessagingChannelProfilePic';

import {
  SingleChannelNameContainer,
  MultipleChannelNameContainer,
  CloseButtonComponent,
  StyledLink,
  StyledButton,
  BackButton,
  StyledBackIcon,
} from './styles';
import texts from './texts.json';
import './MessagingChannelHeader.css';

const MessagingChannelHeader = ({
  emptyChat,
}) => {
  const { channel, loading } = useChannelStateContext();
  const { getChannelName } = useChannel(channel);

  const {
    isMultiChannelView,
    quickChatUser,
    onQuickChatUserSelected,
    onMarkChannelAsUnread,
    onMarkChannelAsRead,
    readOnlyMode,
    setChatListOpen,
    isChatModalOpen,
  } = useContext(ChatContext);
  const { navigateToClientPage } = useToolsNavigation();

  const isLoading = typeof loading === 'boolean' ? loading : true;

  const endUserId = channel.id;
  const isOnline = channel.state.members[endUserId]?.user.online || false;
  const endUser = channel.state.members[endUserId]?.user || {};
  const {
    image,
  } = endUser;

  const getTitle = useCallback(() => {
    if (emptyChat) {
      return texts.chat;
    }
    return isLoading ? texts.loadingChat : getChannelName();
  }, [
    emptyChat,
    isLoading,
    getChannelName,
  ]);

  const getChannelHeader = useCallback(() => {
    if (isMultiChannelView) {
      return (
        <MultipleChannelNameContainer>
          {isChatModalOpen && (
            <BackButton onClick={() => setChatListOpen(true)}>
              <StyledBackIcon />
            </BackButton>
          )}
          <MessagingChannelProfilePic image={image} isOnline={isOnline} />
          <StyledLink onClick={() => navigateToClientPage(channel.id)}>
            {getTitle()}
          </StyledLink>
          <ClientQuickInfoButton
            userId={endUserId}
          />
        </MultipleChannelNameContainer>
      );
    }
    return (
      <SingleChannelNameContainer>
        {isChatModalOpen && (
          <BackButton onClick={() => setChatListOpen(true)}>
            <StyledBackIcon />
          </BackButton>
        )}
        <MessagingChannelProfilePic image={image} isOnline={isOnline} />
        <StyledLink onClick={() => navigateToClientPage(channel.id)}>
          {getTitle()}
        </StyledLink>
        <ClientQuickInfoButton
          userId={endUserId}
        />
      </SingleChannelNameContainer>
    );
  }, [
    isMultiChannelView,
    getTitle,
    navigateToClientPage,
    channel.id,
    setChatListOpen,
    isChatModalOpen,
    image,
    isOnline,
    endUserId,
  ]);

  const closeQuickChat = useCallback(() => {
    onQuickChatUserSelected('');
  }, [
    onQuickChatUserSelected,
  ]);

  const handleClick = useCallback(() => {
    if (channel.data.markedAsUnread) {
      onMarkChannelAsRead(channel);
    } else {
      onMarkChannelAsUnread(channel);
    }
  }, [
    channel,
    onMarkChannelAsRead,
    onMarkChannelAsUnread,
  ]);

  const shouldRenderCloseButton = !isMultiChannelView && quickChatUser;

  return (
    <div className="messaging__channel-header">
      {getChannelHeader()}
      {!readOnlyMode && (
        <StyledButton
          onClick={handleClick}
        >
          {channel.data.markedAsUnread ? texts.markAsRead : texts.markAsUnread}
        </StyledButton>
      )}
      {shouldRenderCloseButton && <CloseButtonComponent onClick={closeQuickChat} />}
    </div>
  );
};

MessagingChannelHeader.propTypes = {
  emptyChat: PropTypes.bool,
};

MessagingChannelHeader.defaultProps = {
  emptyChat: false,
};

export default MessagingChannelHeader;
