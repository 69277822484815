import React, {
  useCallback,
  useMemo,
} from 'react';
import moment from 'moment';
import format from 'string-template';
import PropTypes from 'prop-types';

import { DateFormat } from '../../../../utils/date';
import WorkoutDayPanel from '../WorkoutDayPanel';
import { WorkoutFeedViewType } from '../utils';
import {
  Container,
  ItemContainer,
  StyledTitle,
  CountLabel,
  Description,
  StyledWorkoutProgress,
  DataContainer,
  WorkoutDetails,
} from './styles';
import texts from './texts.json';

const WorkoutStat = ({
  activity: {
    workoutData: workoutList = [],
    checkInDay = 0,
  },
}) => {
  const dayAfterCheckIn = checkInDay + 1;
  // this will calculate an object with the user workouts grouped by type
  const workoutsByType = useMemo(() => {
    const initialObject = Object.keys(WorkoutFeedViewType).reduce((prev, type) => {
      const newObject = { ...prev };
      newObject[WorkoutFeedViewType[type]] = [];
      return newObject;
    }, {});

    return workoutList.reduce((prev, curr) => {
      const previous = { ...prev };
      previous[curr.type].push(curr);
      return previous;
    }, initialObject);
  }, [
    workoutList,
  ]);

  // this will calculate an object with the user workouts grouped by day (which day of the week)
  // and type
  const workoutsByDayAndType = useMemo(() => {
    const initialObject = moment.weekdays().reduce((prev, current) => {
      const newObject = { ...prev };
      newObject[current] = {};
      Object.keys(WorkoutFeedViewType).forEach((type) => {
        newObject[current][WorkoutFeedViewType[type]] = [];
      });
      return newObject;
    }, {});

    return workoutList.reduce((prev, curr) => {
      const weekDay = moment(curr.startDate.toDate()).format(DateFormat.WEEK_DAY_NAME);
      const previous = { ...prev };
      previous[weekDay][curr.type].push(curr);
      return previous;
    }, initialObject);
  }, [
    workoutList,
  ]);

  const completedWorkoutCount = (workouts) => workouts
    .filter((workout) => !!workout.isCompleted)
    .length;

  const percentageCompleted = useMemo(() => (
    completedWorkoutCount(workoutsByType[WorkoutFeedViewType.COACH_ASSIGNED_WORKOUT])
    / workoutsByType[WorkoutFeedViewType.COACH_ASSIGNED_WORKOUT].length
  ), [
    workoutsByType,
  ]);

  const renderCompletedValue = useCallback((type) => {
    const count = completedWorkoutCount(workoutsByType[type]);
    let countLabel = count;
    if (count > 0 && count < 10) {
      countLabel = `0${count}`;
    }
    return countLabel;
  }, [
    workoutsByType,
  ]);

  return (
    <Container>
      <DataContainer>
        {/* coach asssigned workout summary */}
        <ItemContainer>
          <StyledTitle>{texts.workouts}</StyledTitle>
          <CountLabel>{renderCompletedValue(WorkoutFeedViewType.COACH_ASSIGNED_WORKOUT)}</CountLabel>
          <Description>
            {format(texts.goal, {
              goalCount: workoutsByType[WorkoutFeedViewType.COACH_ASSIGNED_WORKOUT].length,
            })}
          </Description>
          <StyledWorkoutProgress
            type="determinate"
            value={percentageCompleted || 0}
          />
        </ItemContainer>
        {/* custom workout summary */}
        <ItemContainer>
          <StyledTitle>{texts.customActivities}</StyledTitle>
          <CountLabel>{renderCompletedValue(WorkoutFeedViewType.CUSTOM_WORKOUT)}</CountLabel>
          <Description>{texts.logged}</Description>
        </ItemContainer>
      </DataContainer>
      {/* expanded workout details for the each day of the week */}
      <WorkoutDetails>
        {[...moment.weekdays().slice(dayAfterCheckIn), ...moment.weekdays().slice(0, dayAfterCheckIn)]
          .map((dayName) => (
            <WorkoutDayPanel
              key={dayName}
              dayName={dayName}
              workoutList={workoutsByDayAndType[dayName]}
            />
          ))}
      </WorkoutDetails>
    </Container>
  );
};

WorkoutStat.propTypes = {
  activity: PropTypes.object,
};

WorkoutStat.defaultProps = {
  activity: {},
};

export default WorkoutStat;
