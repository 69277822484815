import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import format from 'string-template';
import ReactHtmlParser from 'react-html-parser';

import { DateFormat } from '../../../../utils/date';
import {
  MacroNutrient,
  getMacroNutrientsFromCalories,
} from '../../../utils/meals';
import HistoryParam from './HistoryParam';

import texts from './texts.json';
import {
  HistoryItem,
  MealPlanName,
  Params,
  DateRow,
  Date,
  Current,
  Origin,
} from './styles';

const HistoryEntry = ({
  historyItem,
  historyItem: {
    calculatedDailyCalories,
    totalDailyCalories,
    isRecommended,
    previousMacroAverages,
    macroAverages: {
      percentages,
      macros,
    },
  },
  current,
}) => {
  const previousMacros = useMemo(() => {
    if (!isRecommended) {
      // Use previous macro averages (meal plan macro averages) if available, if not calculate them
      if (previousMacroAverages) {
        return previousMacroAverages.macros;
      } if (calculatedDailyCalories && percentages) {
        return getMacroNutrientsFromCalories(calculatedDailyCalories, percentages);
      }
    }
    return {};
  }, [
    isRecommended,
    previousMacroAverages,
    calculatedDailyCalories,
    percentages,
  ]);

  const beforeKcal = (!isRecommended && !!calculatedDailyCalories) && calculatedDailyCalories;

  return (
    <HistoryItem>
      <DateRow>
        <Date>
          {historyItem.updatedMacroAveragesAt
            ? moment(historyItem.updatedMacroAveragesAt.toDate()).format(DateFormat.DATE_FORMAT_COMMA_TH)
            : texts.unknownDate}
        </Date>
        {current && <Current>{texts.current}</Current>}
        <Origin>{isRecommended ? texts.recommended : texts.manuallySet}</Origin>
      </DateRow>
      <MealPlanName>
        {historyItem.mealPlanName
          ? ReactHtmlParser(format(texts.mealPlanName, { name: historyItem.mealPlanName }))
          : texts.noMealPlan}
      </MealPlanName>
      <Params>
        <HistoryParam
          title={texts.daily}
          kcal={totalDailyCalories}
          beforeKcal={beforeKcal}
        />
        {
          [MacroNutrient.PROTEIN, MacroNutrient.CARBS, MacroNutrient.FAT].map((param) => (
            <HistoryParam
              key={param}
              title={texts[param]}
              grams={macros?.[param]}
              percent={percentages?.[param]}
              beforeGrams={previousMacros[param]}
              beforePercent={previousMacroAverages?.percentages?.[param]}
            />
          ))
        }
      </Params>
    </HistoryItem>
  );
};

HistoryEntry.propTypes = {
  historyItem: PropTypes.object.isRequired,
  current: PropTypes.bool,
};

HistoryEntry.defaultProps = {
  current: false,
};

export default HistoryEntry;
