import {
  ToggleOption,
  ToggleSetting,
} from './utils';

const texts = {
  header: 'Client Calories & Macros',
  description: {
    [ToggleSetting.MACROS]: 'Option to show or hide Calories & Macros Goals for clients in the app',
    [ToggleSetting.RECIPE]: 'Option to enable or disable Calories & Macros from the recipes in the client’s meal plan',
  },
  button: {
    [ToggleSetting.MACROS]: {
      [ToggleOption.ENABLE]: 'Show Calories/Macros for Clients',
      [ToggleOption.DISABLE]: 'Hide Calories/Macros for Clients',
    },
    [ToggleSetting.RECIPE]: {
      [ToggleOption.ENABLE]: 'Show in Recipes',
      [ToggleOption.DISABLE]: 'Hide in Recipes',
    },
  },
  saved: 'Nutrition preference settings saved',
};

export default texts;
