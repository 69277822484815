import React from 'react';
import PropTypes from 'prop-types';

import ClearIcon from '@mui/icons-material/Clear';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { v4 as uuidv4 } from 'uuid';

import FileSelectButton from '../../../components/FileSelectButton';
import LoadingOverlay from '../LoadingOverlay';
import {
  FileUploadContainer,
  FileList,
  FileItem,
  FileName,
  StyledButton,
  StyledIconButton,
} from './styles';
import texts from './texts.json';

const AttachmentsContainer = ({
  fileAttachments,
  onFilesSelected,
  onFileRemoved,
  isUploading,
  className,
}) => {
  const openFileInNewTab = (url) => {
    if (url) {
      window.open(url, '_blank');
    }
  };
  return (
    <FileUploadContainer className={className}>
      <FileSelectButton
        ButtonComponent={StyledButton}
        onSelect={onFilesSelected}
        multiple
      >
        <PostAddIcon />
        {texts.attachFile}
      </FileSelectButton>
      <FileList>
        {fileAttachments.map((file, index) => {
          const name = file.name || file.file.name;
          const url = file.dataUrl || file.url;
          return (
            <FileItem key={`${name}-${uuidv4()}`}>
              <FileName
                onClick={() => openFileInNewTab(url)}
              >
                {name}
              </FileName>
              <StyledIconButton onClick={() => onFileRemoved(index)}>
                <ClearIcon fontSize="inherit" />
              </StyledIconButton>
            </FileItem>
          );
        })}
      </FileList>
      <LoadingOverlay isLoading={isUploading && fileAttachments.length !== 0} />
    </FileUploadContainer>
  );
};

AttachmentsContainer.propTypes = {
  fileAttachments: PropTypes.array.isRequired,
  onFilesSelected: PropTypes.func.isRequired,
  onFileRemoved: PropTypes.func.isRequired,
  isUploading: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

AttachmentsContainer.defaultProps = {
  className: '',
};

export default AttachmentsContainer;
