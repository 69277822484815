import React, { useContext } from 'react';
import { compose } from 'recompose';
import { observer } from 'mobx-react';

import { ConfigurationType } from '../../config/configurationPages';
import ConfigurationMenuContext, { withConfigurationMenuContext } from '../../context/ConfigurationMenuContext';

import ConfigurationList from './components/ConfigurationList';
import HabitCustomization from './components/HabitCustomization';
import CheckoutPageConfiguration from './components/CheckoutPageConfiguration';
import WelcomeMessageConfig from './components/WelcomeMessageConfig';
import CheckInCustomization from './components/CheckInCustomization';
import PaymentConfiguration from './components/PaymentConfiguration/PaymentConfiguration';
import ProductsConfig from './components/ProductsConfig';
import OtherSettings from './components/OtherSettings';
import { ConfigContainer } from './styles';

const Config = () => {
  const { selectedConfiguration } = useContext(ConfigurationMenuContext);

  return (
    <ConfigContainer>
      <ConfigurationList />
      {selectedConfiguration === ConfigurationType.HABITS && <HabitCustomization />}
      {selectedConfiguration === ConfigurationType.CHECK_IN && <CheckInCustomization />}
      {selectedConfiguration === ConfigurationType.PAYMENT && <PaymentConfiguration />}
      {selectedConfiguration === ConfigurationType.CHECKOUT_PAGE && <CheckoutPageConfiguration />}
      {selectedConfiguration === ConfigurationType.WELCOME_MESSAGE && <WelcomeMessageConfig />}
      {selectedConfiguration === ConfigurationType.PRODUCTS && <ProductsConfig />}
      {selectedConfiguration === ConfigurationType.OTHER_SETTINGS && <OtherSettings />}
    </ConfigContainer>
  );
};

export default compose(
  withConfigurationMenuContext,
  observer,
)(Config);
