import { Collection } from 'firestorter';
import CollectionName from '../../utils/collections';
import BaseDocument from '../../Model/BaseDocument';

const RefreshInterval = {
  WEEKLY: 'WEEKLY',
  BIWEEKLY: 'BIWEEKLY',
  MONTHLY: 'MONTHLY',
  QUARTERLY: 'QUARTERLY',
  NEVER: 'NEVER',
};

class MealPlanAssignment extends BaseDocument {
  constructor(id, opts) {
    super(`${CollectionName.MEAL_PLAN_ASSIGNMENT}/${id}`, opts);
  }

  get coach() {
    return this.data.coach;
  }

  get user() {
    return this.data.user;
  }

  get mealPlanId() {
    return this.data.mealPlanId;
  }

  get lastUpdated() {
    return this.data.lastUpdated;
  }

  get restrictions() {
    return this.data.restrictions || [];
  }

  get approvalDate() {
    return this.data.approvalDate;
  }

  get customMealPlanIntroMessage() {
    return this.data.customMealPlanIntroMessage || '';
  }

  get current() {
    return this.data.current;
  }

  get name() {
    return this.data.name || '';
  }

  get macroAverages() {
    return this.data.macroAverages;
  }

  get mealTimes() {
    return this.data.mealTimes || [];
  }

  get allergenTags() {
    return this.data.allergenTags || [];
  }

  get recipes() {
    return this.data.recipes || [];
  }

  async setCustomMealPlanIntroMessage(message) {
    return this.updateFields({
      customMealPlanIntroMessage: message || '',
    });
  }

  // Creates a new assignment and sets current as false for the previous one
  async updateMealPlan(newMealPlanData) {
    const currentData = this.data;

    const date = new Date();

    await this.updateFields({
      current: false,
      lastUpdated: date,
    });

    const {
      mealTimes,
      allergenTags,
      recipes,
      macroAverages,
      name,
      mealPlanId,
    } = newMealPlanData;

    const mealPlanAssignmentsCollection = new Collection(CollectionName.MEAL_PLAN_ASSIGNMENT);
    await mealPlanAssignmentsCollection.add({
      ...currentData,
      mealTimes,
      allergenTags,
      recipes,
      macroAverages,
      name,
      mealPlanId,
      current: true,
      lastUpdated: date,
      approvalDate: date,
    });
  }

  // Creates a new assignment and sets it as current
  static async addNewAssignment({
    user,
    mealPlanData,
    coach,
  }) {
    const {
      mealTimes,
      mealPlanId,
      name,
      allergenTags,
      macroAverages,
      recipes,
    } = mealPlanData;
    const date = new Date();

    const mealPlanAssignmentsCollection = new Collection(CollectionName.MEAL_PLAN_ASSIGNMENT);
    const { id } = await mealPlanAssignmentsCollection.add({
      current: true,
      user,
      coach,
      mealPlanId,
      mealTimes,
      name,
      allergenTags,
      macroAverages,
      recipes,
      approvalDate: date,
      lastUpdated: date,
    });

    const doc = new MealPlanAssignment(id);
    await doc.init();
    return doc;
  }

  // Gets current assignment for a given user
  static async getUserMealPlanAssignment(user) {
    const collection = new Collection(CollectionName.MEAL_PLAN_ASSIGNMENT, {
      createDocument: ({ id }, opts) => new MealPlanAssignment(id, opts),
      query: (ref) => ref
        .where('user', '==', user)
        .where('current', '==', true),
    });
    await collection.fetch();

    return collection.hasDocs ? collection.docs[0] : null;
  }

  static async getMealPlanAssignmentsByCoach(coach, currentPlans = true) {
    const collection = new Collection(CollectionName.MEAL_PLAN_ASSIGNMENT, {
      createDocument: ({ id }, opts) => new MealPlanAssignment(id, opts),
      query: (ref) => {
        const query = ref.where('coach', '==', coach);
        if (currentPlans) {
          return query.where('current', '==', true);
        }
        return query;
      },
    });
    await collection.fetch();
    return collection;
  }

  static async getAllMealPlanAssignments() {
    const collection = new Collection(CollectionName.MEAL_PLAN_ASSIGNMENT, {
      createDocument: ({ id }, opts) => new MealPlanAssignment(id, opts),
    });
    await collection.fetch();
    return collection;
  }

  // Gets all assignments for a given user, including previous ones
  static async getAssignmentsByUser(userId) {
    const collection = new Collection(CollectionName.MEAL_PLAN_ASSIGNMENT, {
      createDocument: ({ id }, opts) => new MealPlanAssignment(id, opts),
      query: (ref) => ref.where('user', '==', userId),
    });
    await collection.fetch();
    return collection.hasDocs ? collection.docs : [];
  }
}

export default MealPlanAssignment;
export { RefreshInterval };
