import styled from 'styled-components';
import { Box } from '@mui/material';
import colors from '../../../../../styles/colors';

const TemplateListContainer = styled(Box)``;

const TemplateListContainerStyle = {
  borderRight: `1px solid ${colors.shades.gamma1}`,
  minWidth: '350px',
  boxSizing: 'border-box',
};

export {
  TemplateListContainer,
  TemplateListContainerStyle,
};
