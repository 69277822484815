import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
} from 'react';
import { observer } from 'mobx-react';
import { compose } from 'recompose';

import CoachSelect from '../../../components/Leads/components/CoachSelect/CoachSelect';
import { AnalyticGroups } from '../../../../utils/analytics';
import InternalAssignmentsContext,
{
  withInternalAssignmentsContextProvider,
  withInternalAssignmentsContextReady,
} from '../../../context/InternalAssignmentsContext';
import TimePeriodToggles, { DaysOptions } from '../components/TimePeriodToggles';
import UserSelect from '../components/UserSelect/UserSelect';
import GroupSelect from '../components/GroupSelect/GroupSelect';
import {
  Container,
  FilterRow,
  PageContent,
  StyledAlert,
} from './styles';
import CoachView from './components/CoachView';
import InternalUserView from './components/InternalUserView';
import texts from './texts.json';

const TimeTrackingView = () => {
  const [selectedPeriod, setSelectedPeriod] = useState(DaysOptions[0]);
  const [selectedGroup, setSelectedGroup] = useState(AnalyticGroups.COACH);
  const [selectedUser, setSelectedUser] = useState(null);

  const {
    crxUsers,
    isUsers,
    acUsers,
  } = useContext(InternalAssignmentsContext);

  const userList = useMemo(() => {
    if (selectedGroup === AnalyticGroups.CRX) {
      return crxUsers;
    }
    if (selectedGroup === AnalyticGroups.INSIDE_SALES) {
      return isUsers;
    }
    if (selectedGroup === AnalyticGroups.ASSISTANT_COACH) {
      return acUsers;
    }
    return [];
  }, [
    selectedGroup,
    crxUsers,
    isUsers,
    acUsers,
  ]);

  useEffect(() => {
    setSelectedUser(null);
  }, [selectedGroup]);

  const errMsg = useMemo(() => {
    if (!selectedGroup) {
      return texts.errors.noGroupSelected;
    }
    if (!selectedUser) {
      return texts.errors.noUserSelected;
    }
    return '';
  }, [selectedGroup, selectedUser]);

  return (
    <Container>
      <PageContent>
        <FilterRow>
          <GroupSelect onChange={setSelectedGroup} selectedGroup={selectedGroup} />
          {selectedGroup === AnalyticGroups.COACH && (
            <CoachSelect
              onChange={setSelectedUser}
              loadNewCoachDataOnChange={false}
            />
          )}
          {!!selectedGroup && selectedGroup !== AnalyticGroups.COACH && (
            <UserSelect
              selectedUser={selectedUser}
              userList={userList}
              onChange={setSelectedUser}
            />
          )}
          <TimePeriodToggles
            selectedPeriod={selectedPeriod}
            onChange={setSelectedPeriod}
          />
        </FilterRow>
        {!!errMsg && (
          <StyledAlert severity="warning">
            {errMsg}
          </StyledAlert>
        )}
        {selectedGroup === AnalyticGroups.COACH && selectedUser && (
          <CoachView coachId={selectedUser.id} selectedPeriod={selectedPeriod} />
        )}
        {selectedGroup !== AnalyticGroups.COACH && selectedUser && (
          <InternalUserView
            userId={selectedUser.id}
            selectedPeriod={selectedPeriod}
          />
        )}
      </PageContent>
    </Container>
  );
};

export default compose(
  withInternalAssignmentsContextProvider,
  withInternalAssignmentsContextReady,
  observer,
)(TimeTrackingView);
