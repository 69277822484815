import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import ChartContainer from '../../ChartContainer';
import BarChart from '../../../../../components/BarChart';
import CoachLeadAnalytics from '../../../../../Model/analytics/CoachLeadAnalytics';
import { DateFormat } from '../../../../../../utils/date';
import texts from './texts.json';

const LeadsChart = ({
  leadAnalytics,
}) => (
  <ChartContainer title={texts.leads}>
    <BarChart
      data={leadAnalytics.map((leadAnalytic) => ({
        name: leadAnalytic.id,
        Male: leadAnalytic.male,
        Female: leadAnalytic.female,
        Other: leadAnalytic.total - leadAnalytic.male - leadAnalytic.female,
      }))}
      keys={[{ name: 'Male' }, { name: 'Female' }, { name: 'Other' }]}
      labelFormatter={(yearMonth) => moment(yearMonth, DateFormat.YEAR_MONTH_FORMAT)
        .format(DateFormat.YEAR_MONTH_SHORT_DISPLAY_FORMAT)}
    />
  </ChartContainer>
);

LeadsChart.propTypes = {
  leadAnalytics: PropTypes.arrayOf(PropTypes.instanceOf(CoachLeadAnalytics)).isRequired,
};

export default LeadsChart;
