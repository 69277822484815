import React, { useState } from 'react';
import PropTypes from 'prop-types';
import format from 'string-template';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InputAdornment from '@mui/material/InputAdornment';

import {
  ContractMinimumValue,
} from '../../../utils/userContract';

import {
  Container,
  StyledInput,
} from './styles';
import texts from './texts.json';

const FeeInput = ({
  label,
  value,
  name,
  onChange,
}) => {
  const [isOverridingFees, setIsOverridingFees] = useState(false);
  const [initialValue] = useState(value);

  const handleCheckboxChange = (event) => {
    setIsOverridingFees(event.target.checked);
    if (!event.target.checked) {
      // Reset the value to its initial value when checkbox is unchecked (not overriding fees)
      onChange({ target: { name, value: initialValue } });
    }
  };

  const error = value > initialValue || value < ContractMinimumValue.FEE_PERCENTAGE;
  const helperText = (error && value > initialValue)
    ? format(texts.wrongPercentage, { value: initialValue })
    : texts.positiveNumber;

  return (
    <Container>
      <FormControlLabel
        control={(
          <Checkbox
            checked={isOverridingFees}
            onChange={handleCheckboxChange}
          />
        )}
        label={label}
      />
      <StyledInput
        size="small"
        value={value}
        name={name}
        onChange={onChange}
        type="number"
        disabled={!isOverridingFees}
        error={error}
        helperText={error && helperText}
        InputProps={{
          inputProps: {
            min: ContractMinimumValue.FEE_PERCENTAGE,
            max: initialValue,
          },
          endAdornment: (
            <InputAdornment position="end">%</InputAdornment>
          ),
        }}
        fullWidth
      />
    </Container>
  );
};

FeeInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.number,
  name: PropTypes.string,
  onChange: PropTypes.func,
};

FeeInput.defaultProps = {
  label: '',
  value: 0,
  name: '',
  onChange: () => {},
};

export default FeeInput;
