import React from 'react';
import PropTypes from 'prop-types';

import {
  StyledOnboardingCard,
  StyledButton,
  StyledApplePayButton,
  CreditCard,
} from './styles';

import texts from './texts.json';

const CheckoutActions = ({
  isLoading,
  onCreditCardClicked,
  applePayPaymentRequest,
  creditCardButtonText,
  className,
  disableActions,
}) => (
  <StyledOnboardingCard className={className}>
    <StyledButton
      isLoading={isLoading}
      onClick={onCreditCardClicked}
      disabled={disableActions}
    >
      {
        creditCardButtonText || (
          <>
            {texts.checkoutWithCreditCard}
            <CreditCard />
          </>
        )
      }
    </StyledButton>
    {!!applePayPaymentRequest && (
      <StyledApplePayButton
        disabled={disableActions}
        paymentRequest={applePayPaymentRequest}
        logoOnly
      />
    )}
  </StyledOnboardingCard>
);

CheckoutActions.propTypes = {
  applePayPaymentRequest: PropTypes.object,
  onCreditCardClicked: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  creditCardButtonText: PropTypes.string,
  className: PropTypes.string,
  disableActions: PropTypes.bool,
};

CheckoutActions.defaultProps = {
  isLoading: false,
  applePayPaymentRequest: null,
  className: '',
  creditCardButtonText: null,
  disableActions: true,
};

export default CheckoutActions;
