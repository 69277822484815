import { DialogTitle } from '@mui/material';
import styled from 'styled-components';
import { sansSerifHeadlineFontStyles } from '../../../../../styles/text';

const Title = styled(DialogTitle)`
  ${sansSerifHeadlineFontStyles}
  font-size: 24px;
  font-weight: bold;
`;

const QuestionContainer = styled.div`
  margin: 0 0 15px;
  font-size: 18px;
`;

const Question = styled.h6`
  font-size: 1em;
  margin: 0 0 5px;
  font-weight: 600;
`;

const Answer = styled.p`
  font-size: 0.8em;
  margin: 0;
  white-space: pre-wrap;
`;

export {
  Title,
  QuestionContainer,
  Question,
  Answer,
};
