import styled, { css } from 'styled-components';
import {
  Select,
  MenuItem,
  ListSubheader,
} from '@mui/material';

import colors from '../../styles/colors';

const menuStyles = {
  '.MuiMenu-list': {
    padding: 0,
  },
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Description = styled.p`
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  margin-right: 14px;
`;

const selectStyles = css`
  border-radius: 5px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: ${colors.base.secondary2};
`;

const StyledSelect = styled(Select)`
  min-width: 265px;
  text-align: initial;

  .MuiSelect-select {
    ${selectStyles};
    background: ${colors.base.alpha};
    border: 1px solid ${colors.shades.secondary6};
    padding: 15px;
    min-height: unset;
  }

  fieldset {
    border: unset;
  }

  &:hover .MuiOutlinedInput-notchedOutline {
    border: unset;
  }

  .MuiSelect-icon.Mui-disabled {
    > path {
      stroke: ${colors.shades.gamma3};
    }
  }
`;

const StyledMenuItem = styled(MenuItem)`
  ${selectStyles};
  padding: 10px 15px;
  background: ${colors.shades.secondary5};
  border-radius: unset;
`;

const StyledListSubheader = styled(ListSubheader)`
  ${selectStyles};
  padding: 10px 15px;
  border-radius: unset;
  color: ${colors.shades.primary1};
  background: ${colors.shades.secondary5};
`;

export {
  Container,
  Description,
  StyledSelect,
  StyledMenuItem,
  StyledListSubheader,
  menuStyles,
};
