import React, {
  useState,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';

import CoachesListContext from '../../../context/CoachesListContext';
import CoachSelect from '../../../components/AutoComplete';
import DialogModal from '../../../../components/DialogModal';
import LoadingOverlay from '../../../components/LoadingOverlay';

import {
  Container,
  Header,
  StyledDropdownHeading,
  StyledButton,
} from './styles';
import texts from './texts.json';

const CoachSelectModal = ({
  showModal,
  onClose,
  onConfirm,
}) => {
  const [isProcessingData, setIsProcessingData] = useState(false);
  const [selectedCoach, setSelectedCoach] = useState('');

  const { coachesCollection } = useContext(CoachesListContext);

  const coaches = useMemo(() => (
    coachesCollection.docs.map(({ id, name }) => ({ id, label: name }))
  ), [coachesCollection]);

  const handleConfirmClick = useCallback(async () => {
    setIsProcessingData(true);
    const coach = coachesCollection.docs.find((doc) => doc.id === selectedCoach);
    await onConfirm(coach);
    setIsProcessingData(false);
  }, [
    coachesCollection,
    onConfirm,
    selectedCoach,
  ]);

  return (
    <DialogModal
      open={showModal}
      onClose={onClose}
      fullWidth
      maxWidth="xs"
    >
      <Container>
        <Header>
          <StyledDropdownHeading>
            {texts.coach}
          </StyledDropdownHeading>
          <CoachSelect
            options={coaches}
            onChange={(data) => {
              setSelectedCoach(data?.id);
            }}
          />
        </Header>
        <StyledButton
          variant="contained"
          disabled={!selectedCoach}
          onClick={() => handleConfirmClick()}
        >
          {texts.confirm}
        </StyledButton>
        <LoadingOverlay isLoading={isProcessingData} />
      </Container>
    </DialogModal>
  );
};

CoachSelectModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default CoachSelectModal;
