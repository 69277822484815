import format from 'string-template';
import { Collection } from 'firestorter';
import moment from 'moment';

import BaseDocument from '../../../Model/BaseDocument';
import {
  AnalyticFrequency,
  AnalyticType,
  AnalyticUserType,
} from '../../../utils/analytics';
import Collections from '../../../utils/collections';
import { DateFormat } from '../../../utils/date';

class CoachActiveUsersAnalytics extends BaseDocument {
  constructor(coachId, id, opts) {
    super(`${format(Collections.ANALYTICS, {
      userType: AnalyticUserType.COACH,
      analyticType: AnalyticType.ACTIVE_USERS,
      frequency: AnalyticFrequency.DAILY,
      userId: coachId,
    })}/${id}`, opts);
  }

  get date() {
    return this.data.date;
  }

  get activeUsers() {
    return this.data.activeUsers || 0;
  }

  static async getLastNMonthsAnalytics(coachId, noOfMonths) {
    const activeUserAnalyticsCollection = new Collection(format(Collections.ANALYTICS, {
      userType: AnalyticUserType.COACH,
      analyticType: AnalyticType.ACTIVE_USERS,
      frequency: AnalyticFrequency.DAILY,
      userId: coachId,
    }), {
      createDocument: (src, opts) => new CoachActiveUsersAnalytics(coachId, src.id, opts),
      query: (ref) => ref
        .where('date', '>=', moment().subtract(noOfMonths, 'months').format(DateFormat.DEFAULT_DATE_FORMAT))
        .where('date', '<=', moment().format(DateFormat.DEFAULT_DATE_FORMAT))
        .orderBy('date'),
    });
    await activeUserAnalyticsCollection.fetch();
    const collectionDocs = activeUserAnalyticsCollection.docs;

    // we might not have analytics for all days, so we need to fill in the blanks
    const analytics = [];
    const from = moment().subtract(noOfMonths, 'months');
    const to = moment().subtract(1, 'day');
    while (from.isSameOrBefore(to)) {
      const date = from.format(DateFormat.DEFAULT_DATE_FORMAT);
      if (collectionDocs[0]?.id === date) {
        analytics.push(collectionDocs.shift());
      } else {
        analytics.push(new CoachActiveUsersAnalytics(coachId, date));
      }
      from.add(1, 'days');
    }
    return analytics;
  }
}

export default CoachActiveUsersAnalytics;
