import styled, { css } from 'styled-components';
import { Button } from '@mui/material';

import { ReactComponent as RestrictionIcon } from '../../../assets/icons/v2/block-sign.svg';
import { ReactComponent as ClockIcon } from '../../../assets/icons/v2/clock.svg';
import colors from '../../../styles/colors';
import MultiSelectFilter from '../MultiSelectFilter';

import configs from './configs';

const Container = styled.div`
  display: flex;
  width: 100%;
  min-height: 440px;
  min-width: 920px;
  height: 62vh;
`;

const DataGridContainer = styled.div`
  flex-basis: 100%;
`;

const TagList = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 3px;
`;

const commonCellStyles = css`
  display: flex;
  width: 100%;
  align-self: center;
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: break-word;
  line-height: 18px;
`;

const CellContainer = styled.div`
  ${commonCellStyles};
`;

const ImageContainer = styled.div`
  ${commonCellStyles};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledImage = styled.img`
  width: 100%;
  height: auto;
  aspect-ratio: 1;
  object-fit: cover;
`;

const StyledFilter = styled(MultiSelectFilter)`
  &.${configs.classNames.filter} {
    width: 150px;
    
    .MuiSelect-select {
      border: 1px solid ${colors.shades.primary2};
    }
  }

  .MuiMenu-paper {
    width: 150px;
  }
`;

const StyledRestrictionIcon = styled(RestrictionIcon)`
  margin-right: 5px;
`;

const StyledClockIcon = styled(ClockIcon)`
  margin-right: 5px;
`;

const AdvancedFiltersButton = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${colors.shades.secondary8};
  background-color: ${colors.shades.primary7};
  border: 1px solid ${colors.shades.primary3};
  text-transform: none;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0;
  text-align: left;

  padding: 9px 15px;
  height: 40px;

  &:hover {
    background-color: ${colors.shades.primary3};
  }
`;

export {
  Container,
  DataGridContainer,
  TagList,
  ImageContainer,
  StyledImage,
  CellContainer,
  StyledFilter,
  StyledRestrictionIcon,
  StyledClockIcon,
  AdvancedFiltersButton,
};
