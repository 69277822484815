import styled from 'styled-components';
import { AddCircleOutline as AddIcon } from '@mui/icons-material';

import RoundedButton from '../../../../../../../components/v2/RoundedButton';
import colors from '../../../../../../../styles/colors';
import { ButtonIconStyle } from '../../../../../../../components/v2/Button';
import MonthInput from '../../../../../../../components/v2/MonthInput';
import CurrencySelector from '../../../../../../components/ContractModal/CurrencySelector';

const StyledButton = styled(RoundedButton)`
  background-color: ${colors.shades.beta2};
  color: ${colors.base.alpha};
`;

const ProductContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid ${colors.shades.beta2};
`;

const StyledAddIcon = styled(AddIcon)`
  ${ButtonIconStyle}
  color: ${colors.shades.primary1};
`;

const TermField = styled(MonthInput)`
  width: 206px;
`;

const StyledCurrencySelector = styled(CurrencySelector)`
  width: 206px;
`;

export {
  StyledButton,
  ProductContainer,
  StyledAddIcon,
  TermField,
  StyledCurrencySelector,
};
