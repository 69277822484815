import format from 'string-template';
import { Collection } from 'firestorter';

import moment from 'moment';
import { firestorePaths, pathPlaceholder } from '../utils/paths';
import { ClientStatus } from '../CoachTools/utils/statusFilter';
import BaseDocument from './BaseDocument';

/**
 * User roles.
 */
const UserRole = {
  CRX: 'CRX',
  IS: 'IS',
  SDR: 'SDR',
  SUPPORT: 'SUPPORT',
  AC: 'AC',
};

/**
 * User role flags.
 * This is used to query users by role.
 */
const UserRoleFlag = {
  [UserRole.CRX]: 'isCRX',
  [UserRole.IS]: 'isInsideSales',
  [UserRole.SDR]: 'isSDR',
  [UserRole.SUPPORT]: 'isSupport',
  [UserRole.AC]: 'isCoachAssistant',
};

/**
 * Class representing a User.
 *
 * @class User
 * @extends BaseDocument
 */
class User extends BaseDocument {
  /**
     * Get user's address.
     * @return {string}
     */
  get address() {
    return this.data.address;
  }

  /**
   * Get the id of user's assigned coach.
   * @return {string}
   */
  get assignedCoach() {
    return this.data.assignedCoach;
  }

  /**
     * Get user's avatar link.
     * @return {string}
     */
  get avatarUrl() {
    return this.data.avatarUrl;
  }

  /**
   * Get user's break end date.
   * @return {Date}
   */
  get breakEndDate() {
    return this.data?.breakEndDate?.toDate();
  }

  /**
   * Get user's birthdate.
   * @return {Date|void}
   */
  get birthdate() {
    return this.data.birthdate?.toDate();
  }

  /**
   * Get user's cancel at date in unix timestamp.
   * @return {number}
   */
  get cancelAt() {
    return this.data.cancelAt;
  }

  /**
   * Get user's check in day (day of the week).
   * @return {number}
   */
  get checkInDay() {
    return this.data.checkInDay;
  }

  /**
   * Get user's notes from the coach.
   * @return {string}
   */
  get coachNotes() {
    return this.data.coachNotes || '';
  }

  get customTags() {
    return this.data.customTags || [];
  }

  /**
   * Get user's commitment ending date.
   * @return {Date}
   */
  get commitmentEndingDate() {
    return this.data.commitmentEndingDate?.toDate();
  }

  /**
   * Get user's createdAt date.
   * @return {Date|void}
   */
  get createdAt() {
    return this.data.createdAt?.toDate();
  }

  /**
   * Get default meal plan id if the user is a coach.
   * @return {string}
   */
  get defaultMealPlan() {
    return this.data.defaultMealPlan || '';
  }

  /**
   * Get user's email.
   * @return {string}
   */
  get email() {
    return this.data.email;
  }

  /**
   * if the user sent a chat message and is expecting a reply.
   * @return {boolean}
   */
  get expectingReply() {
    return !!this.data.expectingReply;
  }

  /**
   * Get user's first name.
   * @return {string}
   */
  get firstName() {
    return this.data.firstName;
  }

  /**
   * Get user's flags.
   * @return {object} user flags.
   * @property {boolean} isActive - user's active status. This is true if user's subscription is trialing or active.
   * @property {boolean} dayZero - true until the user has logged in for the first time.
   * @property {boolean} delinquent - this maps to the Stripe customer delinquent field.
   * automatic payment failure or passing the invoice.due_date will set this field to true.
   * @property {string} subscriptionStatus - user's subscription status.
   * This maps to the Stripe subscription status (in uppercase).
   * @property {boolean} greetingMessageSent - reflects whether the greeting message has been sent to the user.
   * Defaults to false and is set to true after the greeting message is sent to the user.
   * @property {boolean} firstPaymentSuccessful - reflects whether the first invoice payment was successful
   */
  get flags() {
    return this.data.flags || {};
  }

  /**
   * Get user's gender.
   * @return {string}
   */
  get gender() {
    return this.data.gender;
  }

  /**
   * Get greeting message sent status.
   * This reflects whether the greeting message has been sent to the user.
   * Defaults to false and is set to true after the greeting message is sent to the user.
   * @return {boolean}
   */
  get greetingMessageSent() {
    return !!this.flags.greetingMessageSent;
  }

  /**
   * Get user's active status.
   * This is true if user's subscription is trialing or active.
   * @return {boolean}
   */
  get isActive() {
    return !!this.flags.isActive;
  }

  /**
   * If the user is an assistant coach.
   * @return {boolean}
   */
  get isCoachAssistant() {
    return !!this.data.isCoachAssistant;
  }

  /**
   * Get user's day zero status.
   * This is true until the user has logged in for the first time.
   * @return {boolean}
   */
  get isDayZero() {
    return !!this.flags.dayZero;
  }

  /**
   * Get user's delinquent status.
   * This maps to the Stripe customer delinquent field.
   * Automatic payment failure or passing the invoice.due_date will set this field to true.
   * @return {boolean}
   */
  get isDelinquent() {
    return !!this.flags.delinquent;
  }

  /**
   * If the user is an inside sales agent.
   * @return {boolean}
   */
  get isInsideSales() {
    return !!this.data.isInsideSales;
  }

  /**
   * Get user's workout notification resume date.
   * @return {Date|void}
   */
  get workoutNotificationsResumeDate() {
    return this.data.workoutNotificationsResumeDate?.toDate();
  }

  /**
   * Get user's meal plan notification resume date.
   * @return {Date|void}
   */
  get mealPlanNotificationsResumeDate() {
    return this.data.mealPlanNotificationsResumeDate?.toDate();
  }

  /**
   * Get user's onboarded status.
   * Defaults to false and is set to true after a subscription is created.
   * If coach cancels the subscription, this flag is set to false.
   * @return {boolean}
   */
  get isOnboarded() {
    return !!this.data.isOnboarded;
  }

  /**
   * If the user is a sales development representative.
   * @return {boolean}
   */
  get isSDR() {
    return !!this.data.isSDR;
  }

  /**
   * If the user is an on call engineer.
   * @return {boolean}
   */
  get isOnCall() {
    return !!this.data.isOnCall;
  }

  /**
   * If the user is a coach relations execute.
   * @return {boolean}
   */
  get isCRX() {
    return !!this.data.isCRX;
  }

  /**
   * If the user is a support agent.
   * @return {boolean}
   */
  get isSupport() {
    return !!this.data.isSupport;
  }

  /**
   * Get user's last check in date.
   * @return {Date|void}
   */
  get lastCheckIn() {
    return this.data.lastCheckIn?.toDate();
  }

  /**
   * Get user's last message date.
   * @return {Date|void}
   */
  get lastMessageAt() {
    return this.data.lastMessageAt?.toDate();
  }

  /**
   * Get user's last name.
   * @return {string}
   */
  get lastName() {
    return this.data.lastName;
  }

  /**
   * Get user's last workout completed date.
   * @return {Date}
   */
  get lastWorkoutCompletedAt() {
    return this.data.lastWorkoutCompletedAt?.toDate();
  }

  /**
   * Get user's monthly start date in unix timestamp.
   * If user has a PIF plan, this will be the date when the user's monthly subscription starts.
   * @return {number}
   */
  get monthlyStartAt() {
    return this.data.monthlyStartAt;
  }

  /**
   * Get user's openTok sessions.
   * @return {Array}
   * @property {string} sessionId - openTok session id.
   * @property {string} token - openTok token.
   * @property {string} expireTime - openTok token expire time.
   */
  get openTokSessions() {
    return this.data.openTokSessions;
  }

  /**
   * Get user's phone number.
   * @return {string}
   */
  get phoneNumber() {
    return this.data.phoneNumber;
  }

  /**
   * Get user's subscribed plan id.
   * @return {string}
   */
  get planId() {
    return this.data.planId;
  }

  /**
   * Get user's post payment form submitted date.
   * @return {Object}
   */
  get postPaymentFormSubmittedAt() {
    return this.data.postPaymentFormSubmittedAt;
  }

  /**
   * Get user's suscribed product id.
   * @return {string}
   */
  get product() {
    return this.data.product;
  }

  /**
   * Get user's service start date (when user's contract starts).
   * @return {Date}
   */
  get serviceStartAt() {
    return this.data?.serviceStartAt?.toDate();
  }

  /**
   * Get user's stream token.
   * This is used to authenticate the user with the chat server (stream).
   * @return {string}
   */
  get streamToken() {
    return this.data.streamToken;
  }

  /**
   * Get user's subscription status.
   * This maps to the Stripe subscription status (in uppercase).
   * @return {string}
   */
  get subscriptionStatus() {
    return this.flags.subscriptionStatus;
  }

  /**
   * Get user's tags.
   * @return {Array<String>}
   */
  get tags() {
    return this.data.tags || [];
  }

  /**
   * Get id of user who last interacted with the client
   * @return {string}
   */
  get lastInteractionBy() {
    return this.data.lastInteractionBy;
  }

  /**
   * Get name of user who last interacted with the client
   * @return {string}
   */
  get lastInteractionByUserName() {
    return this.data.lastInteractionByUserName;
  }

  // Computed properties

  /**
   * If the user is on break.
   * @return {boolean}
   */
  get isOnBreak() {
    return !!this.data.breakEndDate && moment(this.breakEndDate).isAfter(moment());
  }

  /**
   * Get user's age
   * return {number|void}
   */
  get age() {
    return this.birthdate && moment().diff(moment(this.birthdate), 'years');
  }

  /**
   * Get user's name (first name and last name).
   * If user has last name, it will be concatenated with first name.
   * If user doesn't have last name, only first name will be returned.
   * @return {string}
   */
  get name() {
    const firstName = (this.firstName || '').trim();
    const lastName = (this.lastName || '').trim();
    return [firstName, lastName].join(' ');
  }

  // Methods

  /**
   * Creates a new user entry in the database with the provided userId and userData
   * @param {string} userId The user id of the user record to be created
   * @param {object} userData The user data
   * @param {string} userData.email The user email
   * @param {string} [userData.firstName] The user firstName
   * @param {string} [userData.lastName] The user lastName
   * @param {string} [userData.phoneNumber] The user phoneNumber
   * @return {Promise<void>}
   */
  static async createUser(userId, userData) {
    const userDoc = new User(format(firestorePaths.USER_DOC, {
      [pathPlaceholder.USER_ID]: userId,
    }));
    await userDoc.set({
      ...userData,
      createdAt: new Date(),
    }, {
      merge: true,
    });
  }

  /**
   * Get all active users by coach id.
   * @param {string} coachId - The coach id.
   * @return {Promise<Collection>} The collection of active users.
   */
  static async getActiveUsersByCoach(coachId) {
    const userCollection = new Collection(firestorePaths.USER, {
      createDocument: (source, options) => new User(source, { ...options, disableObserverCountRef: true }),
      query: (query) => query
        .where('assignedCoach', '==', coachId)
        .where('flags.isActive', '==', true)
      ,
    });
    await User.initCollection(userCollection);
    return userCollection;
  }

  /**
   * Get all active users.
   * @return {Promise<Collection>} The collection of active users.
   */
  static async getAllActiveUsers() {
    const userCollection = new Collection(firestorePaths.USER, {
      createDocument: (source, options) => new User(source, { ...options, disableObserverCountRef: true }),
      query: (query) => query
        .where('flags.isActive', '==', true)
      ,
    });
    await User.initCollection(userCollection);
    return userCollection;
  }

  /**
   * Get user by id.
   * @param {string} id - The user id.
   * @return {Promise<User>} The user document.
   */
  static async getById(id) {
    const userDoc = new User(format(firestorePaths.USER_DOC, {
      [pathPlaceholder.USER_ID]: id,
    }));
    await userDoc.init();
    return userDoc.exists ? userDoc : null;
  }

  /**
   * Update user's notes from the coach.
   * @param {string} notes - The new coach notes.
   * @return {Promise<void>}
   */
  updateCoachNotes = async (notes) => {
    await this.updateFields({ coachNotes: notes });
  };

  /**
   * Update user's createdAt date if it's not already set.
   * @param {string} createdAt - The new createdAt date.
   * @return {Promise<void>}
   */
  updateCreatedAt = async (createdAt) => {
    /*
      Update the userDoc creation time, but only if it's not already set, in order to
      allow us to test overriding it to a different value.
    */
    if (!this.data.createdAt) {
      await this.set({ createdAt: new Date(createdAt) }, { merge: true });
    }
  };

  /**
   * Update user's meal plan notification resume date.
   * @param {string} date - The meal plan notification resume date.
   * @return {Promise<void>}
   */
  updateMealPlanNotificationsResumeDate = async (date) => {
    await this.set({ mealPlanNotificationsResumeDate: date }, { merge: true });
  };

  /**
   * Update user's workout notification resume date.
   * @param {string} date - The workout notification resume date.
   * @return {Promise<void>}
   */
  updateWorkoutNotificationsResumeDate = async (date) => {
    await this.set({ workoutNotificationsResumeDate: date }, { merge: true });
  };

  /**
   * activateUser
   * This is used to force the activation process on a user doc.
   *
   * @param {string} activatedBy - The user id of the user who activated the user.
   * @returns {Promise<void>}
   */
  activateUser = (activatedBy) => {
    const now = new Date();

    return this.updateFields({
      'flags.isActive': true,
      'flags.subscriptionStatus': ClientStatus.ACTIVE,
      /*
        Set the first payment successful flag to true to avoid showing a payment failure message in the mobile app,
        which blocks all other functions.
      */
      'flags.firstPaymentSuccessful': true,
      isOnboarded: true, // Cancelled users should be marked as onboarded once again.
      serviceStartAt: now, // Set the service start date to now so that the user can use the app right away.
      breakEndDate: now,
      // Workout and meal plan notifications should be resumed immediately if they were not manually paused before.
      ...(this.mealPlanNotificationsResumeDate === this.serviceStartAt && { mealPlanNotificationsResumeDate: now }),
      ...(this.workoutNotificationsResumeDate === this.serviceStartAt && { workoutNotificationsResumeDate: now }),
      activatedBy,
      activatedManuallyAt: now, // Save when we activated the user manually for traceability.
    });
  };

  /**
   * Get all users by coach id.
   * @param {string} coachId - The coach id.
   * @return {Promise<Collection>} The collection of users.
   */
  static async getUsersByCoach(coachId) {
    const userCollection = new Collection(firestorePaths.USER, {
      createDocument: (source, options) => new User(source, { ...options, disableObserverCountRef: true }),
      query: (query) => query
        .where('assignedCoach', '==', coachId)
      ,
    });
    await User.initCollection(userCollection);
    return userCollection;
  }

  /**
   * Get all users fro thr given role.
   * @param {string} role - The role of the users.
   * @return {Promise<Collection>} The collection of users.
   */
  static async getUsersByRole(role) {
    const userCollection = new Collection(firestorePaths.USER, {
      createDocument: (source, options) => new User(source, options),
      query: (query) => query
        .where(UserRoleFlag[role], '==', true)
      ,
    });
    await User.initCollection(userCollection);
    return userCollection;
  }

  /**
   * get user by email
   * @param {string} email - The email of the user.
   * @return {Promise<Collection>} The collection of users.
   */
  static async getUserByEmail(email) {
    const userCollection = new Collection(firestorePaths.USER, {
      createDocument: (source, options) => new User(source, options),
      query: (query) => query
        .where('email', '==', email)
      ,
    });
    await User.initCollection(userCollection);
    return userCollection;
  }
}

export default User;

export {
  UserRole,
  UserRoleFlag,
};
