import React, {
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import format from 'string-template';
import moment from 'moment';

import { ReactComponent as ContractIcon } from '../../../../../assets/icons/contract-icon.svg';
import { DateFormat } from '../../../../../utils/date';
import useToast from '../../../../hooks/useToast';

import ContractModalContext from '../../context/ContractModalContext';
import UserInfoSection from '../../UserInfoSection';

import {
  ContractState,
} from '../../utils/ContractTypes';
import ContractEditor from '../../ContractEditor';
import {
  Container,
  Header,
  Footer,
  SendContractButton,
  EditButton,
  ViewTermsButton,
  ContractSentText,
} from './styles';
import texts from './texts.json';

const ReonboardingContractModalContent = ({
  user,
  coachId,
  onClose,
}) => {
  const {
    contract,
    contractState,
    isSaving,
    isValidContract,
    isReadOnly,
    setCoachId,
    loadReonboardingContractByUser,
    generateContract,
    editContract,
    viewTerms,
  } = useContext(ContractModalContext);
  const { showToast } = useToast();

  useEffect(() => {
    if (coachId) {
      setCoachId(coachId);
    }
  }, [
    coachId,
    setCoachId,
  ]);

  useEffect(() => {
    loadReonboardingContractByUser(user);
  }, [
    user,
    loadReonboardingContractByUser,
  ]);

  const contractSentAt = useMemo(() => contract?.updatedAt?.toDate() || contract?.createdAt?.toDate(), [contract]);

  const contractSendButtonText = useMemo(() => {
    if (isSaving) {
      return texts.sendingContract;
    }

    if (contractSentAt) {
      return texts.sendUpdatedContract;
    }

    return texts.sendContract;
  }, [
    isSaving,
    contractSentAt,
  ]);

  const generateContractAndSendToClient = useCallback(async () => {
    const { isSuccessful } = await generateContract();
    if (isSuccessful) {
      showToast(texts.contractSent);
      onClose();
    }
  }, [
    generateContract,
    onClose,
    showToast,
  ]);

  /*
    If contract is in configuration state, we show the view terms button.
    If contract is in details state, we show the edit and send contract buttons.
  */
  const renderFooterContent = () => {
    if (contractState === ContractState.CONFIGURATION) {
      return (
        <>
          {!!contract && (
            <ViewTermsButton
              disabled={!isValidContract}
              onClick={viewTerms}
            >
              {texts.viewTerms}
            </ViewTermsButton>
          )}
        </>
      );
    }

    return (
      <>
        <EditButton onClick={editContract}>
          {texts.editTerms}
        </EditButton>
        <SendContractButton
          onClick={generateContractAndSendToClient}
          disabled={isSaving || !isValidContract}
        >
          <ContractIcon />
          {contractSendButtonText}
        </SendContractButton>
        {contractSentAt && (
          <ContractSentText>
            {format(texts.contractSentAt, {
              sentAt: moment(contractSentAt).format(DateFormat.DATE_FORMAT_TIME_MINUTES),
            })}
          </ContractSentText>
        )}
      </>
    );
  };

  return (
    <Container>
      <Header>
        <UserInfoSection userData={user} />
      </Header>
      <ContractEditor />
      {!isReadOnly && (
        <Footer>
          {renderFooterContent()}
        </Footer>
      )}
    </Container>
  );
};

ReonboardingContractModalContent.propTypes = {
  user: PropTypes.object.isRequired,
  coachId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default compose(
  observer,
)(ReonboardingContractModalContent);
