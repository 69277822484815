import React from 'react';
import PropTypes from 'prop-types';

import AnalyticNumberCard from '../../AnalyticNumberCard';
import CoachSubscriptionsAnalytics from '../../../../../Model/analytics/CoachSubscriptionAnalytics';
import CoachLeadAnalytics from '../../../../../Model/analytics/CoachLeadAnalytics';
import { formatNumber } from '../../../../../../utils/formatters';
import CoachAvgClientLifespanAnalytics from '../../../../../Model/analytics/CoachAvgClientLifespanAnalytics';
import texts from './texts.json';

const CancellationAnalyticsCard = ({
  subscriptionAnalytics,
  leadAnalytics,
  lifespanAnalytics,
}) => (
  <AnalyticNumberCard
    title={`${leadAnalytics?.total
      ? formatNumber(
        (subscriptionAnalytics?.newSubscriptions / (leadAnalytics?.total - leadAnalytics?.unqualified)) * 100,
        { maximumFractionDigits: 2 },
      )
      : 100}%`}
    subTitle={texts.conversionRate}
    bottomStats={[
      {
        title: `${formatNumber(lifespanAnalytics?.avgLifespan, { maximumFractionDigits: 0 })} Days`,
        subTitle: texts.clientLifespan,
      },
    ]}
  />
);

CancellationAnalyticsCard.propTypes = {
  subscriptionAnalytics: PropTypes.instanceOf(CoachSubscriptionsAnalytics).isRequired,
  leadAnalytics: PropTypes.instanceOf(CoachLeadAnalytics).isRequired,
  lifespanAnalytics: PropTypes.instanceOf(CoachAvgClientLifespanAnalytics).isRequired,
};

export default CancellationAnalyticsCard;
