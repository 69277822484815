import styled from 'styled-components';
import {
  FormHelperText,
  Select,
  TextField,
} from '@mui/material';
import colors from '../../styles/colors';

const StyledTextField = styled(TextField)`
  box-sizing: content-box;
  width: 100%;
  position: relative;

  ${(props) => !!props.$simple && `
    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
      -webkit-appearance: none; 
      margin: 0; 
    }
  `}

  &::after {
    content: attr(title);
    position: absolute;
    top: 12px;
    right: 10px;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: ${colors.base.secondary3};
    text-transform: lowercase;
  }

  .MuiInputBase-root {
    height: 40px;
    background-color: ${colors.base.alpha};

    .MuiOutlinedInput-notchedOutline {
      border-color: ${colors.shades.secondary7};
      border-radius: 5px;
    }

    .MuiInputBase-input {
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      color: ${colors.shades.secondary8};
    }

    &.Mui-disabled {
      background-color: ${colors.shades.secondary5};

      .MuiInputBase-input {
        -webkit-text-fill-color: initial;
      }
    }
  }
`;

const StyledTextFieldLabel = styled(FormHelperText)`
  color: ${colors.base.secondary2};
  font-weight: 400;
  font-size: 8px;
  line-height: 9px;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  padding: 4px;
`;
const StyledTextFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${(props) => (props.$withMargin ? '20px' : '0px')};

  ${StyledTextField} {
    min-width: 80px;
  }
`;

const StyledSelect = styled(Select)`
  box-sizing: unset;
  width: fit-content;
  position: relative;

  &.MuiInputBase-root {
    height: 37px;
    background-color: ${colors.base.alpha};

    .MuiOutlinedInput-notchedOutline {
      border-color: ${colors.shades.secondary7};
      border-radius: 5px;
    }

    .MuiInputBase-input {
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      color: ${colors.shades.secondary8};
    }

    &.Mui-disabled {
      background-color: ${colors.shades.secondary5};

      .MuiInputBase-input {
        -webkit-text-fill-color: initial;
      }
    }
  }
`;

const StyledInlineSelect = styled(StyledSelect)`
  margin: 0 6px 0 3px;
`;

export {
  StyledTextField,
  StyledTextFieldContainer,
  StyledTextFieldLabel,
  StyledSelect,
  StyledInlineSelect,
};
