import { MealPlanAssignOption } from './utils';

const texts = {
  mealPlan: 'MEAL PLAN',
  allPlans: 'All plans',
  button: {
    [MealPlanAssignOption.NEW_MEAL_PLAN]: 'Assign New Meal Plan',
    [MealPlanAssignOption.USE_PREVIOUS_MEAL_PLAN]: 'Use Previous Meal Plan',
  },
  filterBy: 'FILTER BY:',
  selectPlan: 'SELECT A PLAN:',
  name: 'NAME:',
  namePlaceholder: 'Meal plan name',
  note: 'NOTE:',
  notePlaceholder: 'Personalized meal plan note',
  macros: 'MACROS:',
  protein: '<strong>{percentage}%</strong> Protein',
  carbs: '<strong>{percentage}%</strong> Carbs',
  fat: '<strong>{percentage}%</strong> Fat',
  dietaryCompliance: 'DIETARY COMPLIANCE:',
  allergens: 'ALLERGENS:',
  noAllergens: 'None',
  moreItems: '+{amount} More',
  updateDate: 'Updated: {date}',
  addRecipes: 'Add Recipes',
  updateMealTimes: 'Update Meal Times',
  loadingMealPlan: 'Please wait while we load the meal plan...',
  regeneratePlan: 'Re-generate Plan',
};

export default texts;
