import styled from 'styled-components';
import { Box } from '@mui/material';
import colors from '../../../../../styles/colors';

const FeedContainer = styled.div`
  overflow-y: auto;
  width: 100%;
  padding: 0 12px;
  background-color: ${colors.base.gamma};
`;

const StyledLoaderContainer = styled(Box)`
  display: flex;
  justify-content: center;
  margin: 10px;
`;

export {
  FeedContainer,
  StyledLoaderContainer,
};
