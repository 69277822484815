import styled from 'styled-components';
import { DataGrid } from '@mui/x-data-grid';

import colors from '../../../styles/colors';

const StyledDataGrid = styled(DataGrid)`
  border: 1px solid ${colors.shades.secondary7};
  color: ${colors.shades.secondary8};
  font-size: 12px;
  
  .MuiDataGrid-columnSeparator {
    display: none;
  }

  .MuiDataGrid-columnsContainer {
    border-bottom: 1px solid ${colors.shades.secondary7};

    .MuiDataGrid-columnHeader {
      padding: 0;

      .MuiDataGrid-columnHeaderTitleContainer {
        padding: 0 10px;

        .MuiDataGrid-columnHeaderTitle {
          font-weight: 600;
        }
      }
    }
  }
  .MuiDataGrid-row {
    &:hover {
      background: ${colors.shades.secondary10};
    }
    .MuiDataGrid-cell {
      border-bottom: 1px solid ${colors.shades.secondary7};
    }
  }
  .MuiDataGrid-footerContainer {
    background: ${colors.shades.secondary10};
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .allergensHeader {
    color: ${colors.shades.danger1};
  }
`;

export default StyledDataGrid;
