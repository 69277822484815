import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as ReorderRoundedIcon } from '../../../../../../../../assets/icons/v2/drag.svg';
import { ReactComponent as TrashRoundedIcon } from '../../../../../../../../assets/icons/v2/trash.svg';
import { StyledTextField } from '../../../../../../../../components/Inputs';
import { MealPlanType } from '../../../../../../../Model/MealPlan';

import { ItemTypes } from '../../utils';
import {
  StyledDraggable,
  StyledReorderRoundedIcon,
  StyledIconButton,
  TextFieldWrapper,
  NumberFieldWrapper,
} from './styles';
import texts from './texts.json';

const DraggableRow = memo(({
  caloricSplit,
  recipesAmount,
  name,
  index,
  onChange,
  onRemove,
  isModalView,
  templateType,
}) => (
  <StyledDraggable $isModalView={isModalView}>
    <StyledReorderRoundedIcon className={ItemTypes.DRAGGABLE}>
      <ReorderRoundedIcon />
    </StyledReorderRoundedIcon>

    <TextFieldWrapper>
      <StyledTextField
        value={name}
        onChange={({ target }) => onChange(index, 'name', target.value)}
        placeholder={texts.namePlaceHolder}
      />
    </TextFieldWrapper>

    <NumberFieldWrapper>
      <StyledTextField
        value={caloricSplit}
        onChange={({ target }) => onChange(index, 'caloricSplit', Number(target.value))}
        type="number"
        title={texts.percentage}
        placeholder={texts.namePlaceHolder}
      />
    </NumberFieldWrapper>

    {(!isModalView && templateType === MealPlanType.LIVE) && (
      <NumberFieldWrapper>
        <StyledTextField
          value={recipesAmount}
          onChange={({ target }) => onChange(index, 'recipesAmount', Number(target.value))}
          type="number"
          placeholder={texts.recipesAmount}
        />
      </NumberFieldWrapper>
    )}

    <StyledIconButton onClick={() => onRemove(index)}>
      <TrashRoundedIcon />
    </StyledIconButton>
  </StyledDraggable>
));

DraggableRow.propTypes = {
  index: PropTypes.number.isRequired,
  name: PropTypes.string,
  caloricSplit: PropTypes.number,
  recipesAmount: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  templateType: PropTypes.string.isRequired,
  isModalView: PropTypes.bool,
};

DraggableRow.defaultProps = {
  caloricSplit: 0,
  recipesAmount: 0,
  name: '',
  isModalView: false,
};

export default DraggableRow;
