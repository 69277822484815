import styled, { css } from 'styled-components';

import { LoadingButton } from '../../../../../components/Button';

import colors from '../../../../../styles/colors';

const disabledStyles = css`
  opacity: unset;
  color: ${colors.base.alpha};
  background: ${colors.addAlpha(colors.shades.gamma10, 0.6)};
  &:hover {
    opacity: unset;
    background: ${colors.addAlpha(colors.shades.gamma10, 0.6)};
  }
`;

const SubmitButton = styled(LoadingButton)`
  width: fit-content;
  column-gap: 5px;
  background: ${colors.shades.gamma10};
  border-radius: 5px;
  text-transform: none;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: ${colors.base.alpha};

  > path {
    stroke: ${colors.base.alpha};
  }

  &:disabled {
    ${disabledStyles};
  }

  ${({ isLoading }) => isLoading && css`
    ${disabledStyles};
  `};

  ${({ isLoading, disabled }) => !(isLoading || disabled) && css`
    &:hover {
      opacity: unset;
      background: ${colors.addAlpha(colors.shades.gamma10, 0.6)};
      cursor: pointer;
    }
  `};
`;

export {
  SubmitButton,
};
