import React, {
  useCallback,
  useMemo,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import * as Sentry from '@sentry/browser';
import { CircularProgress } from '@mui/material';

import StatAccordion from '../../../components/Accordion';
import CustomizationContext from '../../context/CustomizationContext';
import { baseCheckInQuestionList } from '../../pages/Config/components/CheckInCustomization/utils';
import { DateFormat } from '../../../utils/date';
import ImageSlider from '../ImageSlider';

import CircumferenceStatTable from './CircumferenceStatTable';
import HabitStat from './HabitStat/HabitStat';
import CalorieIntakeChart from './CalorieIntakeChart';
import WorkoutStat from './WorkoutStat/WorkoutStat';

import {
  Container,
  DataContainer,
  ImageContainer,
  StyledContainer,
  StatContainer,
  WeightStatContainer,
  WeightStatTitle,
  WeightStatValue,
  StatLabel,
  Stat,
  DifferenceStatLabel,
  DifferenceStatValue,
  CheckInQuestionContainer,
  QuestionText,
  AnswerText,
  StyledTitle,
  WeightStat,
} from './styles';
import texts from './texts.json';

import {
  checkInColumns,
  measurement,
  getMeasurementDifference,
} from './utils';

const CheckInStats = ({
  activity,
  isNotification,
  title,
}) => {
  const { customCheckInDoc, isReady: isCustomizationContextReady } = useContext(CustomizationContext);

  // calculate difference of the value between current and last check-in
  // of given measurement type
  const getDifference = useCallback((measurementType, column) => {
    let differenceLabel = texts.emptyCell;
    let isPositive = false;

    if (activity.checkInData.current
      && activity.checkInData[column]
      && activity.checkInData.current[measurementType]?.value
      && activity.checkInData[column][measurementType]?.value) {
      const difference = getMeasurementDifference(
        activity.checkInData.current,
        activity.checkInData[column],
        measurementType,
      );
      isPositive = difference > 0;
      differenceLabel = `${isPositive ? texts.plus : ''}${difference % 1 ? difference.toFixed(2) : difference}
        ${activity.checkInData.current[measurementType].unit}`;
    }
    return {
      differenceLabel,
      isPositive,
    };
  }, [
    activity,
  ]);

  // render difference value for given measurement type with positive and negative
  // styled in green and blue respectively
  const renderDifferenceStat = useCallback((measurementType, column = checkInColumns[1]) => {
    const { isPositive, differenceLabel } = getDifference(measurementType, column);
    return (
      <DifferenceStatValue $isPositive={isPositive}>
        {differenceLabel}
      </DifferenceStatValue>
    );
  }, [
    getDifference,
  ]);

  const shouldShowQuestions = (activity.questions
    && Object.values(activity.questions).some((questionObject) => questionObject.value))
    || activity.checkInData?.current.win
    || activity.checkInData?.current.status
    || activity.checkInData?.current.comment;

  const checkInQuestionAnswers = useMemo(() => {
    if (activity.questions) {
      // sort the check-in answers according to the order in the
      // custom check-in question list set by coach in config page
      const checkInQuestionList = customCheckInDoc?.checkInQuestions || baseCheckInQuestionList;
      if (checkInQuestionList && Array.isArray(checkInQuestionList)) {
        return checkInQuestionList.reduce((acc, question) => {
          const questionObject = activity.questions[question];
          if (questionObject && questionObject.value) {
            acc.push(questionObject);
          }
          return acc;
        }, []);
      }
      Sentry.captureMessage('custom check-in question list is not an array', {
        user: activity.user,
      });
    }

    return [];
  }, [
    activity,
    customCheckInDoc,
  ]);

  if (!activity.checkInData) {
    return null;
  }

  const getDateText = (checkInData) => {
    let dateText = '';
    if (checkInData && checkInData.submittedAt) {
      dateText = ` - ${moment(checkInData.submittedAt.toDate()).format(DateFormat.TEXT_DATE_FORMAT)}`;
    }
    return dateText;
  };

  return (
    <Container>
      <StyledContainer>
        {title && <StyledTitle>{title}</StyledTitle>}
        {/* Weight comparison between start, last and latest check-in weights */}
        <StatContainer>
          <WeightStat $width="60%" showDivider>
            <WeightStatTitle>{texts.weightStat}</WeightStatTitle>
            <WeightStatContainer>
              {checkInColumns.map((column) => (
                <Stat key={`${activity.id}_${column}`}>
                  <WeightStatValue $current={column === checkInColumns[2]}>
                    {(activity.checkInData[column] && activity.checkInData[column].weight.value
                      && `${activity.checkInData[column].weight.value} ${activity.checkInData[column].weight.unit}`)
                      || texts.emptyCell}
                  </WeightStatValue>
                  <StatLabel>{texts[column]}</StatLabel>
                </Stat>
              ))}
            </WeightStatContainer>
          </WeightStat>
          <WeightStat $width="40%">
            <WeightStatTitle>{texts.weightDifferenceLabel}</WeightStatTitle>
            <WeightStatContainer>
              <Stat>
                {renderDifferenceStat(measurement.WEIGHT, checkInColumns[1])}
                <DifferenceStatLabel>{texts.differenceLast}</DifferenceStatLabel>
              </Stat>
              <Stat>
                {renderDifferenceStat(measurement.WEIGHT, checkInColumns[0])}
                <DifferenceStatLabel>{texts.differenceStart}</DifferenceStatLabel>
              </Stat>
            </WeightStatContainer>
          </WeightStat>
        </StatContainer>
        {/* Comparison of last and current check-in image uploads */}
        <ImageContainer>
          <ImageSlider
            imageList={activity.checkInData.first.attachments}
            resizedImageList={activity.checkInData.first.resizedAttachments}
            label={`${texts.initialCheckIn}${getDateText(activity.checkInData.first)}`}
          />
          {!isNotification && (
            <ImageSlider
              imageList={activity.checkInData.last?.attachments}
              resizedImageList={activity.checkInData.last?.resizedAttachments}
              label={`${texts.last}${getDateText(activity.checkInData.last)}`}
            />
          )}
          <ImageSlider
            imageList={activity.checkInData.current.attachments}
            resizedImageList={activity.checkInData.current.resizedAttachments}
            label={texts.current}
          />
        </ImageContainer>
        {/* Answers to Check-in Questions */}
        <DataContainer>
          {shouldShowQuestions && (
            <StatAccordion
              title={texts.checkInQuestions}
              defaultExpanded={!isNotification}
            >
              {isCustomizationContextReady ? checkInQuestionAnswers.map((questionObject) => (
                <CheckInQuestionContainer key={questionObject.id}>
                  <QuestionText>{`${questionObject.question}: `}</QuestionText>
                  <AnswerText>{questionObject.value}</AnswerText>
                </CheckInQuestionContainer>
              )) : <CircularProgress size={20} color="info" />}
              {activity.checkInData.current.win && (
                <CheckInQuestionContainer>
                  <QuestionText>{texts.winQuestion}</QuestionText>
                  <AnswerText>{activity.checkInData.current.win}</AnswerText>
                </CheckInQuestionContainer>
              )}
              {activity.checkInData.current.status && (
                <CheckInQuestionContainer>
                  <QuestionText>{texts.statusQuestion}</QuestionText>
                  <AnswerText>{activity.checkInData.current.status}</AnswerText>
                </CheckInQuestionContainer>
              )}
              {activity.checkInData.current.comment && (
                <CheckInQuestionContainer>
                  <QuestionText>{texts.commentQuestion}</QuestionText>
                  <AnswerText>{activity.checkInData.current.comment}</AnswerText>
                </CheckInQuestionContainer>
              )}
            </StatAccordion>
          )}
          {/* circumference measurement comparison accordion */}
          <StatAccordion
            title={texts.circumferenceStats}
            defaultExpanded={!isNotification}
          >
            <CircumferenceStatTable
              renderDifferenceStat={renderDifferenceStat}
              activity={activity}
            />
          </StatAccordion>
          {/* calorie intake since last check-in */}
          {!!activity.userMealsSubmitted && (
            <StatAccordion
              title={texts.calorieSummary}
              defaultExpanded={!isNotification}
            >
              <CalorieIntakeChart activity={activity} />
            </StatAccordion>
          )}
          {/* habit since last check-in */}
          <StatAccordion
            title={texts.habitSummary}
            defaultExpanded={!isNotification}
          >
            <HabitStat activity={activity} />
          </StatAccordion>
          {/* workout stats since last check-in */}
          {activity.workoutData && (
            <StatAccordion
              title={texts.workoutsSummary}
              defaultExpanded={!isNotification}
            >
              <WorkoutStat activity={activity} />
            </StatAccordion>
          )}
        </DataContainer>
      </StyledContainer>
    </Container>
  );
};

CheckInStats.propTypes = {
  activity: PropTypes.object,
  isNotification: PropTypes.bool,
  title: PropTypes.string,
};

CheckInStats.defaultProps = {
  activity: {},
  isNotification: false,
  title: '',
};

export default CheckInStats;
