import React, { useContext } from 'react';

import LoadingPage from '../../../components/LoadingPage';
import MealPlanContext from './MealPlanContext';
import MealPlanContextProvider from './MealPlanContextProvider';

const withMealPlanContextProvider = (Component) => (props) => (
  <MealPlanContextProvider>
    <Component {...props} />
  </MealPlanContextProvider>
);

const withMealPlanContextReady = (Component) => (props) => {
  const { isReady } = useContext(MealPlanContext);

  return isReady ? <Component {...props} /> : <LoadingPage />;
};

export {
  withMealPlanContextProvider,
  withMealPlanContextReady,
};
