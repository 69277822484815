import React from 'react';
import PropTypes from 'prop-types';

import {
  StyledSelect,
  StyledMenuItem,
  StyledTag,
  menuStyles,
} from './styles';

const SelectFilter = ({
  options,
  onSelect,
  optionTexts,
  optionIcons,
  optionTags,
  activeOption,
}) => {
  const handleChange = (event) => {
    onSelect(event.target.value);
  };

  return (
    <StyledSelect
      onChange={handleChange}
      value={activeOption}
      MenuProps={{
        sx: menuStyles,
      }}
    >
      {Object.values(options).map((option) => {
        const optionText = optionTexts[option];
        const IconComponent = optionIcons[option];
        const optionTagText = optionTags[option];
        return (
          <StyledMenuItem
            key={`filter-select-option-${option}`}
            value={option}
          >
            {!!IconComponent && <IconComponent />}
            {optionText}
            {!!optionTagText && (
              <StyledTag>
                {optionTagText}
              </StyledTag>
            )}
          </StyledMenuItem>
        );
      })}
    </StyledSelect>
  );
};

SelectFilter.propTypes = {
  options: PropTypes.object.isRequired,
  activeOption: PropTypes.string.isRequired,
  onSelect: PropTypes.func,
  optionTexts: PropTypes.object,
  optionIcons: PropTypes.object,
  optionTags: PropTypes.object,
};

SelectFilter.defaultProps = {
  onSelect: () => {},
  optionTexts: {},
  optionIcons: {},
  optionTags: {},
};

export default SelectFilter;
