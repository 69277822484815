import { MealPlanAssignmentStatus } from '../../../../../utils/mealPlan';

const FilterOptionValues = {
  ALL: 'ALL',
  NEEDS_ACTION: 'NEEDS_ACTION',
  NEEDS_REFRESH: 'NEEDS_REFRESH',
  NEW_ASSIGNMENTS: 'NEW_ASSIGNMENTS',
};

const filterChecks = {
  [FilterOptionValues.ALL]: () => true,
  [FilterOptionValues.NEEDS_ACTION]: ({ status }) => (
    status === MealPlanAssignmentStatus.NEEDS_REFRESH || status === MealPlanAssignmentStatus.PENDING
  ),
  [FilterOptionValues.NEEDS_REFRESH]: ({ status }) => MealPlanAssignmentStatus.NEEDS_REFRESH === status,
  [FilterOptionValues.NEW_ASSIGNMENTS]: ({ status, currentTarget }) => (
    MealPlanAssignmentStatus.PENDING === status && currentTarget !== '-'
  ),
};

export {
  filterChecks,
  FilterOptionValues,
};
