import styled from 'styled-components';
import { Chip } from '@mui/material';

import { ReactComponent as ChatIcon } from '../../../../assets/icons/menuIcons/chat.svg';
import colors from '../../../../styles/colors';
import { StyledArrowIcon as ArrowIcon } from '../../../pages/Chat/components/MessagingInput/styles';
import { APP_BAR_HEIGHT } from '../Header/styles';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: ${APP_BAR_HEIGHT}px;
  color: white;
  min-height: 30px;
  background-color: ${colors.base.secondary2};
  align-items: flex-end;
  justify-content: flex-end;
`;

const BottomBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-height: 64px;
  width: calc(50% - 286px);
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

const ChatContainer = styled.div`
  display: flex;
  flex: 1;
  position: absolute;
  top: 64px;
  right: 0;
  z-index: 999;
  width: calc(50% - 316px);
  height: calc(100vh - ${APP_BAR_HEIGHT * 2}px);
  border-left: 1px solid ${colors.shades.secondary4};
`;

const StyledUnreadCount = styled(Chip)`
  background-color: ${colors.shades.primary3};
  border: 1px solid ${colors.shades.primary4};
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  margin: 10px;
  &:hover {
    background-color: ${colors.shades.primary4};
    border: 1px solid ${colors.shades.primary4};
  }
`;

const StyledChatIcon = styled(ChatIcon)`
  margin: 10px;
  width: 20px;
  height: 20px;
  > path {
    stroke: ${colors.shades.primary4};
  }
`;

const StyledArrowIcon = styled(ArrowIcon)`
  > path {
    stroke: ${colors.base.primary};
  }
  margin-left: 5px;
`;

export {
  Container,
  StyledUnreadCount,
  StyledChatIcon,
  TitleContainer,
  BottomBar,
  ChatContainer,
  StyledArrowIcon,
};
