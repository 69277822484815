import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useContext,
} from 'react';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import format from 'string-template';
import ReactHtmlParser from 'react-html-parser';

import useAuthentication from '../../hooks/useAuthentication';
import useComponentMounted from '../../hooks/useComponentMounted';
import useNavigation from '../../hooks/useNavigation';
import useSessionStore from '../../hooks/useSessionStore';

import FirebaseContext from '../../context/FirebaseContext';

import LoadingPage from '../../components/LoadingPage';

import PageContainer from './components/PageContainer';
import LoginForm from './components/LoginForm';
import ForgotPassword from './components/ForgotPassword';

import {
  ErrorMessage,
  InfoMessage,
  Container,
  StyledSpinner,
  ContactSupport,
} from './styles';

import texts from './texts.json';

const Login = () => {
  const { authUser } = useSessionStore();
  const { firebase } = useContext(FirebaseContext);

  const [loginError, setLoginError] = useState(null);

  const {
    state: {
      sendAuthLink = false,
      userEmail = '',
      onLogin = '/home',
    } = {
      sendAuthLink: false,
      onLogin: '/home',
      userEmail: '',
    },
    navigateTo,
  } = useNavigation();

  const isComponentMountedRef = useComponentMounted();
  const { sendAuthenticationEmail } = useAuthentication();

  const [email, setEmail] = useState(userEmail);

  const [shouldSendAuthLink, setShouldSendAuthLink] = useState(email && sendAuthLink);
  const [authLinkSent, setAuthLinkSent] = useState(false);
  const redirectOnLogin = useMemo(() => authUser && !authUser.isAnonymous, [
    authUser,
  ]);

  const triggerAuthLinkSending = useCallback(() => {
    setShouldSendAuthLink(true);
  }, [
    setShouldSendAuthLink,
  ]);

  useEffect(() => {
    if (redirectOnLogin) {
      navigateTo(onLogin);
    }
  }, [
    navigateTo,
    onLogin,
    redirectOnLogin,
  ]);

  useEffect(() => {
    if (shouldSendAuthLink && !authLinkSent) {
      const performAuthLinkSending = async () => {
        await sendAuthenticationEmail(email, onLogin);
        if (isComponentMountedRef.current) {
          setAuthLinkSent(true);
        }
      };
      performAuthLinkSending();
    }
  }, [
    isComponentMountedRef,
    email,
    shouldSendAuthLink,
    sendAuthenticationEmail,
    onLogin,
    authLinkSent,
  ]);

  const onLoginSubmit = async (fields) => {
    try {
      await firebase.auth.signInWithEmailAndPassword(fields.email?.toLowerCase(), fields.password);
    } catch (error) {
      setLoginError(error);
    }
  };

  const onEmailChange = ({ target: { value } }) => {
    setEmail(value.toLowerCase());
  };

  if (redirectOnLogin) {
    return <LoadingPage />;
  }

  const renderPageContent = () => {
    if (shouldSendAuthLink && !authLinkSent) {
      return (
        <Container>
          <StyledSpinner name="crescent" />
        </Container>
      );
    }

    if (authLinkSent) {
      return (
        <InfoMessage>
          {format(texts.sentAuthLink, {
            email,
          })}
        </InfoMessage>
      );
    }

    return (
      <>
        <LoginForm
          onSubmit={onLoginSubmit}
          onEmailChange={onEmailChange}
          email={userEmail}
        />
        {!!loginError && <ErrorMessage>{loginError.message}</ErrorMessage>}
        <ForgotPassword
          email={email}
          sendAuthLink={triggerAuthLinkSending}
        />
        <ContactSupport>{ReactHtmlParser(texts.contactSupport)}</ContactSupport>
      </>
    );
  };

  return (
    <PageContainer formHeading={texts.login}>
      {renderPageContent()}
    </PageContainer>
  );
};

export default compose(
  observer,
)(Login);
