import styled from 'styled-components';
import Button from '../../../components/Button';
import colors from '../../../styles/colors';

const FileUploadContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  margin: 10px 0;
  align-items: center;
  column-gap: 10px;
  --padding-start: unset;
  background: ${colors.base.alpha};
  padding: 6px;
  border-radius: 60px;
  border: 1px solid ${colors.shades.secondary4};
  overflow-x: auto;
  overflow-y: hidden;
`;

const FileList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 4px;
`;

const FileItem = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 5px;
  align-items: center;
`;

const FileName = styled.div`
  max-width: 50%;
  white-space: nowrap;
  overflow: hidden;
  font-size: 14px;
  padding: 2px 5px;
  text-overflow: ellipsis;
  cursor: pointer;
`;

const StyledButton = styled(Button)`
  background-color: ${colors.shades.secondary4};
  color: ${colors.shades.secondary3};
  border: 1px solid ${colors.shades.secondary6};
  padding: 0 10px;
  height: 32px;
  flex-shrink: 0;
  width: fit-content;
  font-size: 13px;
  line-height: 30px;
  font-weight: 500;
  border-radius: 70px;
`;

const StyledIconButton = styled(Button)`
  background-color: transparent;
  color: ${colors.shades.secondary3};
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 14px;
  padding: 2px;
  cursor: pointer;
  &:hover {
    background-color: ${colors.shades.gamma1};
  }
`;

export {
  FileUploadContainer,
  FileList,
  FileItem,
  FileName,
  StyledButton,
  StyledIconButton,
};
