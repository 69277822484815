import styled from 'styled-components';

import Select from '../../../../../../components/Select';
import { StyledTextField } from '../../../../../../components/Inputs';
import colors from '../../../../../../styles/colors';

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 50px 40px 50px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const RestInput = styled(StyledTextField)`
  width: 150px;
  min-width: 150px;

  .MuiInputBase-root {
    height: 46px;

    .MuiOutlinedInput-notchedOutline {
      border-radius: 4px 0 0 4px;
      border-right: 0;
    }
  }
`;

const StyledSelect = styled(Select)`
  min-width: 100px;
  width: 100px;

  .MuiSelect-select {
    border-radius: 0 4px 4px 0;
    border-left: 0;
    background-color: ${colors.shades.secondary5};
    border-color: ${colors.shades.secondary7};
  }
`;

const ButtonContainer = styled.div`
  margin-left: 20px;
`;

export {
  ModalBody,
  InputContainer,
  RestInput,
  StyledSelect,
  ButtonContainer,
};
