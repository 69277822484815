import React, {
  useState,
  useCallback,
} from 'react';

import useComponentMounted from '../../../../../hooks/useComponentMounted';
import useCurrentLoggedInUser from '../../../../../hooks/useCurrentLoggedInUser';
import useSessionStore from '../../../../../hooks/useSessionStore';
import useToast from '../../../../hooks/useToast';

import MessagingBroadcastModal from '../MessagingBroadcastModal';
import {
  BroadcastMessageButton,
  StyledContainer,
  StyledBroadcastIcon,
} from './styles';
import text from './text.json';

const MessagingBroadcast = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { isCurrentLoggedInUserInPath } = useCurrentLoggedInUser();
  const { isCoachAssistant } = useSessionStore();
  const { showToast } = useToast();
  const isComponentMountedRef = useComponentMounted();

  const onBroadcastMessageButtonClick = () => {
    setIsModalOpen(true);
  };

  const onModalDismiss = () => {
    if (isComponentMountedRef.current) {
      setIsModalOpen(false);
    }
  };

  const showScheduledToast = useCallback(() => {
    showToast(text.broadcastMessageScheduled);
  }, [
    showToast,
  ]);

  const showErrorToast = useCallback((errorMessage = '') => {
    const toastText = errorMessage || text.broadcastMessageError;
    showToast(toastText, { error: true });
  }, [
    showToast,
  ]);

  return (
    <StyledContainer>
      <BroadcastMessageButton
        onClick={onBroadcastMessageButtonClick}
        disabled={!isCurrentLoggedInUserInPath && !isCoachAssistant}
      >
        <StyledBroadcastIcon />
        {text.broadcastMessageButtonLabel}
      </BroadcastMessageButton>
      <MessagingBroadcastModal
        isOpen={isModalOpen}
        onDidDismiss={onModalDismiss}
        showMessageScheduled={showScheduledToast}
        showMessageError={showErrorToast}
        setIsModalOpen={setIsModalOpen}
      />
    </StyledContainer>
  );
};

export default MessagingBroadcast;
