import styled from 'styled-components';
import {
  TextField,
  DialogContent,
} from '@mui/material';
import colors from '../../../../../../styles/colors';

const StyledTextField = styled(TextField)`
  color: ${colors.shades.gamma2};
`;

const StyledInfoLabel = styled.span`
  display: inline-block;
`;

const Label = styled.div`
  font-size: 14px;
  width: 100%;
`;

const LabelTitle = styled.span`
  display: inline-block;
  color: ${colors.shades.beta1};
  width: 100px;
`;

const LabelDescription = styled.span`
  color: ${colors.shades.gamma2};
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: ${colors.shades.alpha10};
`;

const Description = styled.div`
  font-size: 14px;
  color: ${colors.shades.gamma10};
`;

const StyledDialogContent = styled(DialogContent)`
  height: 240px;
  padding: 25px 50px 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export {
  StyledTextField,
  Label,
  LabelTitle,
  LabelDescription,
  StyledInfoLabel,
  Title,
  Description,
  StyledDialogContent,
};
