import React, {
  useState,
  useMemo,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { DateFormat } from '../../../utils/date';
import {
  MonthSelectWrapper,
  StyledAutoComplete,
  StyledTextField,
} from './styles';
import texts from './texts.json';

const MONTH_LIMIT = 12;

const MonthSelect = ({
  onChange,
}) => {
  const [selectedMonth, setSelectedMonth] = useState(null);

  // generate last 12 month options
  const monthOptions = useMemo(() => {
    const options = [];
    let proessingDate = moment();
    for (let i = 0; i < MONTH_LIMIT; i++) {
      options.push({
        id: proessingDate.format(DateFormat.YEAR_MONTH_FORMAT),
        label: proessingDate.format(DateFormat.YEAR_MONTH_DISPLAY_FORMAT),
      });
      proessingDate = proessingDate.subtract(1, 'month');
    }
    return options;
  }, []);

  const onMonthSelected = useCallback((event, selectedOption) => {
    setSelectedMonth(selectedOption);
    onChange(selectedOption);
  }, [
    onChange,
  ]);

  return (
    <MonthSelectWrapper>
      <StyledAutoComplete
        disablePortal
        options={monthOptions}
        renderInput={(params) => <StyledTextField {...params} label={texts.selectMonth} />}
        renderOption={(props, option) => <div {...props} key={option.id}>{option.label}</div>}
        value={selectedMonth}
        onChange={onMonthSelected}
      />
    </MonthSelectWrapper>
  );
};

MonthSelect.propTypes = {
  onChange: PropTypes.func,
};

MonthSelect.defaultProps = {
  onChange: () => { },
};

export default MonthSelect;
