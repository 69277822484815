import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
} from '@stripe/react-stripe-js';
import { compose } from 'recompose';
import { MobXProviderContext, observer } from 'mobx-react';
import * as Sentry from '@sentry/browser';
import moment from 'moment';

import { EventTypes } from '../../../utils/events';
import { anonId } from '../../../utils/onboarding';
import LoadingPage from '../../../components/LoadingPage';
import { withPageAnalytics } from '../../../components/withAnalytics';
import FirebaseContext from '../../../context/FirebaseContext';
import OnboardingFlowContext from '../../../context/OnboardingFlowContext';
import SubscriptionContext, {
  withSubscriptionContextProvider,
  withSubscriptionContextReady,
} from '../../../context/SubscriptionContext';
import PlanContext from '../../../context/PlanContext';
import CustomerIOContext from '../../../context/CustomerIOContext';
import useLogger from '../../../hooks/useLogger';
import useApplePay from '../../../hooks/useApplePay';
import useNavigation from '../../../hooks/useNavigation';
import StripeContext, {
  withStripeContextProvider,
  withStripeContextReady,
} from '../../../context/StripeContext';
import CoachAssignmentContext from '../../../context/CoachAssignmentContext';
import OnboardingFlowConfigContext from '../../../context/OnboardingFlowConfigContext';
import UserContractContext from '../../../context/UserContractContext';

import {
  createSubscription,
  submitNewPaymentMethod,
  authenticatePayment,
  cancelSubscription,
} from './stripe/SubscriptionRemoteRequestHandler';
import SubscriptionCard from './components/SubscriptionCard';
import SubscriptionUnavailable from './components/SubscriptionUnavailable';
import {
  StripeErrorType,
  SubscriptionControllerState,
  SubscriptionCreationState,
} from './SubscriptionMetadata';
import useSubscriptionLogicControllerStateReducer from './useSubscriptionLogicControllerStateReducer';
import {
  calculateInitialStates,
  findNextStateFromNonErrorResponse,
  findNextStateFromErrorResponse,
} from './SubscriptionNextStateCalculator';
import subscriptionSubmissionTypes from './subscriptionSubmissionTypes';

const SubscriptionLogicController = () => {
  const stripe = useStripe();
  const elements = useElements();
  const { firebase: { remote } } = useContext(FirebaseContext);
  const { track: customerIOTrack } = useContext(CustomerIOContext);
  const { logEvent } = useLogger();
  const { stripeAccountId } = useContext(StripeContext);
  const {
    externalCoach,
    leadId = '',
  } = useContext(OnboardingFlowConfigContext);

  const {
    firstName,
    lastName,
    email,
    phoneNumber,
    setters: onboardingSetters,
    createUser,
    startAtDate,
  } = useContext(OnboardingFlowContext);

  const { contractDoc } = useContext(UserContractContext);

  const [submittedForm, setSubmittedForm] = useState(null);
  const { sessionStore: { userId } } = useContext(MobXProviderContext);
  const { subscription } = useContext(SubscriptionContext);

  const {
    navigateTo,
    routes,
  } = useNavigation();

  const {
    plan: {
      planCode,
      totalPriceInCents,
      totalPayOnSubscriptionCreation,
      initialPaymentInCents,
      upfrontPaymentInCents,
      cancelAt,
      acceptUntil,
      id: planId,
      recurringBillingMonths,
      currency,
    },
    coupon: {
      code: coupon,
      percentOff,
    },
  } = useContext(PlanContext);

  const { currentCoach } = useContext(CoachAssignmentContext);

  const areSubscriptionsAllowed = useMemo(() => (
    (typeof acceptUntil === 'undefined') || moment().isBefore(acceptUntil)
  ), [
    acceptUntil,
  ]);

  const userDetail = useMemo(() => ({
    firstName,
    lastName,
    email,
    phoneNumber,
  }), [
    firstName,
    lastName,
    email,
    phoneNumber,
  ]);

  const [
    {
      subscriptionControllerState,
      stripeErrorState,
      subscriptionState,
      localSubscriptionDetail,
      processResponse,
      performHouseKeepingActions,
      latestResponse,
      completedInitialDataLoading,
      applePayPaymentMethodResult,
      socialProofData,
      accountCreationState,
      isOnboardingCompleted,
    },,
    setters,
  ] = useSubscriptionLogicControllerStateReducer();

  const reportSubscriptionCreation = useCallback((nextState) => {
    if (
      nextState === SubscriptionCreationState.ACTIVE_SUBSCRIPTION_EXISTS
      || nextState === SubscriptionCreationState.SUCCESSFUL_COMPLETION
    ) {
      const subscriptionPrice = totalPriceInCents / 100;

      const trackData = {
        name: EventTypes.STRIPE_SUBSCRIPTION_CREATED,
        userId,
        email,
        phoneNumber,
        userName: `${firstName}${lastName ? ` ${lastName}` : ''}`,
        planPrice: subscriptionPrice,
        planCode,
        recurringBillingMonths,
        coach: currentCoach.name,
      };
      const latestSubscription = latestResponse.subscription;

      const subscriptionAndPrice = {
        price: subscriptionPrice,
      };

      if (latestSubscription) {
        if (latestSubscription.id) {
          trackData.subscriptionId = latestSubscription.id;
          subscriptionAndPrice.subscriptionId = latestSubscription.id;
        }
        if (latestSubscription.status) {
          trackData.subscriptionStatus = latestSubscription.status;
        }
      }

      const latestInvoice = latestResponse.invoice;
      if (latestInvoice) {
        if (latestInvoice.amount_paid && latestInvoice.currency) {
          trackData.paymentDetail = {
            amountPaid: latestInvoice.amount_paid,
            currency: latestInvoice.currency,
          };
          subscriptionAndPrice.price = latestInvoice.amount_paid;
        }
      }

      /*
        We need to send this explicitly to those customer IO users that were created with the direct integration.
        (those representing users not created yet)
      */
      customerIOTrack(EventTypes.STRIPE_SUBSCRIPTION_CREATED, trackData);

      /*
        We report this event from here instead o server side as otherwise it doesn't gets correctly reported back to FB
        using the conversion API. This event will be reported to all third party integrations in a centralized way.
      */
      logEvent(EventTypes.STRIPE_SUBSCRIPTION_CREATED, trackData);
    }
  }, [
    logEvent,
    totalPriceInCents,
    customerIOTrack,
    userId,
    email,
    phoneNumber,
    firstName,
    lastName,
    latestResponse,
    planCode,
    recurringBillingMonths,
    currentCoach.name,
  ]);

  /**
   * Process responses from Housekeeping actions.
   *
   * @param {Object} response - Response from housekeeping action.
   */
  const processHouseKeepingResponse = useCallback((response) => {
    if (response.error) {
      const parsedResponse = {
        isError: true,
        error: response.error,
      };
      setters.setLatestResponse(parsedResponse);
      setters.setProcessResponse(true);
    } else {
      const parsedResponse = {
        isError: false,
      };
      setters.setLatestResponse(parsedResponse);
      setters.setProcessResponse(true);
    }
    setters.setSubscriptionControllerState(SubscriptionControllerState.HOUSE_KEEPING_ACTION_COMPLETED);
  }, [setters]);

  /**
   * Carries out initial state setup.
   * It loads any current subscriptions (apart from incomplete/incomplete-expired) and then
   * calculates initial states (flow-control, error and subscription-state) and then
   * breaks down the subscription, if exists into latest-invoice and payment-intent for the east of
   * processing.
   */
  useEffect(() => {
    const reconcileState = async () => {
      if (!completedInitialDataLoading && stripe) {
        const calculatedState = calculateInitialStates(subscription);
        const {
          controllerState,
          subscriptionState: subscriptionStateTmp,
          stripeErrorState: errorState,
          subscriptionBreakdown,
        } = calculatedState;
        setters.setSubscriptionControllerState(controllerState);
        setters.setStripeErrorState(errorState);
        setters.setSubscriptionState(subscriptionStateTmp);
        setters.setLocalSubscriptionDetail({
          ...localSubscriptionDetail,
          ...subscriptionBreakdown,
        });
        setters.setCompletedInitialDataLoading(true);
      }
    };
    reconcileState();
  }, [
    subscription,
    localSubscriptionDetail,
    setters,
    completedInitialDataLoading,
    stripe,
  ]);

  /**
   * Upon every state transition, we may have to execute house-keeping tasks. Currently the only
   * available one is for removing residual subscription data, before transitioning into certain subscription states.
   * The hook handles app state reconciliation after executing housekeeping tasks.
   */
  useEffect(() => {
    if (subscriptionControllerState === SubscriptionControllerState.HOUSE_KEEPING_ACTION_COMPLETED && processResponse) {
      setters.setProcessResponse(false);
      const reconcileStateAfterHouseKeepingActions = () => {
        const {
          nextState: nextSubscriptionState,
          error: currentError,
          cancelCurrentSubscription,
        } = localSubscriptionDetail;
        const isHouseKeepingActionFailed = latestResponse.isError;
        if (isHouseKeepingActionFailed && cancelCurrentSubscription) {
          setters.setSubscriptionState(SubscriptionCreationState.FAILURE_COMPLETION);
        } else {
          setters.setSubscriptionState(nextSubscriptionState);
        }
        setters.setStripeErrorState(currentError);
        setters.setLocalSubscriptionDetail({
          ...localSubscriptionDetail,
          nextState: null,
          error: null,
          cancelCurrentSubscription: null,
        });
      };
      reconcileStateAfterHouseKeepingActions();
    }
  }, [
    latestResponse,
    localSubscriptionDetail,
    setters,
    processResponse,
    subscriptionControllerState,
  ]);

  /**
   *  The hook handles responses from various subscription creation
   *  related actions such as external service requests/responses, user interactions etc...
   *  At it's core is a FSM that transitions states based on Stripe related errors and
   *  Subscription's latest-invoice/payment-intent status.
   *  Once done, it checks for any before-transition (housekeeping) actions and executes them as well.
   */
  useEffect(() => {
    if (subscriptionControllerState === SubscriptionControllerState.ACTION_COMPLETED && processResponse) {
      if (!latestResponse) {
        return;
      }
      setters.setProcessResponse(false);
      const reconcileLocalSubscriptionWithRemote = () => {
        let nextLocalSubscriptionDetail;
        if (latestResponse.isError) {
          nextLocalSubscriptionDetail = findNextStateFromErrorResponse(
            subscriptionState,
            latestResponse,
            localSubscriptionDetail,
          );
        } else {
          nextLocalSubscriptionDetail = findNextStateFromNonErrorResponse(
            subscriptionState,
            latestResponse,
            localSubscriptionDetail,
          );
        }

        const { nextState } = nextLocalSubscriptionDetail;
        reportSubscriptionCreation(nextState);

        if (nextLocalSubscriptionDetail.cancelCurrentSubscription) {
          setters.setSubscriptionControllerState(SubscriptionControllerState.HOUSE_KEEPING_ACTION_IN_PROGRESS);
          setters.setLocalSubscriptionDetail(nextLocalSubscriptionDetail);
          setters.setPerformHouseKeepingActions(true);
        } else {
          setters.setLocalSubscriptionDetail({
            ...nextLocalSubscriptionDetail,
            nextState: null,
            error: null,
            cancelCurrentSubscription: null,
          });
          setters.setStripeErrorState(nextLocalSubscriptionDetail.error);
          setters.setSubscriptionState(nextState);
        }
      };
      reconcileLocalSubscriptionWithRemote();
    }
  }, [
    reportSubscriptionCreation,
    latestResponse,
    localSubscriptionDetail,
    setters,
    subscriptionState,
    processResponse,
    subscriptionControllerState,
    email,
    phoneNumber,
    userId,
  ]);

  /**
   * The hook is responsible for carrying out before-transition (housekeeping) actions.
   * For now we have only one of them and therefore the logic is hard-coded.
   */
  useEffect(() => {
    if (
      performHouseKeepingActions
      && subscriptionControllerState === SubscriptionControllerState.HOUSE_KEEPING_ACTION_IN_PROGRESS) {
      setters.setPerformHouseKeepingActions(false);
      const executeHouseKeepingActions = () => {
        if (localSubscriptionDetail.cancelCurrentSubscription) {
          cancelSubscription({
            subscriptionId: localSubscriptionDetail.subscription.id,
            processResponse: processHouseKeepingResponse,
            remote,
          });
        }
      };
      executeHouseKeepingActions();
    }
  }, [
    localSubscriptionDetail,
    processHouseKeepingResponse,
    remote,
    setters,
    performHouseKeepingActions,
    subscriptionControllerState,
  ]);

  const completeOnboarding = useCallback(async () => {
    setters.setOnboardingCompleted(true);
  }, [setters]);

  useEffect(() => {
    const isSuccessfulState = [
      SubscriptionCreationState.SUCCESSFUL_COMPLETION,
      SubscriptionCreationState.ACTIVE_SUBSCRIPTION_EXISTS,
    ].includes(subscriptionState);

    if (isSuccessfulState) {
      completeOnboarding();
    }
  }, [
    onboardingSetters,
    subscriptionState,
    completeOnboarding,
  ]);

  /**
   * Check for an active apple pay method result submitted and check for subscription
   * creation state. If it's successful, then let's report back to the Apple Pay
   * interface.
   */
  useEffect(() => {
    if (applePayPaymentMethodResult) {
      switch (subscriptionState) {
        case SubscriptionCreationState.SUCCESSFUL_COMPLETION: {
          applePayPaymentMethodResult.complete('success');
          break;
        }
        case SubscriptionCreationState.FAILURE_COMPLETION:
        case SubscriptionCreationState.FAILURE_NETWORK: {
          applePayPaymentMethodResult.complete('fail');
          break;
        }
        default: {
          // Ignore any other state
          break;
        }
      }
    }
  }, [
    subscriptionState,
    applePayPaymentMethodResult,
  ]);

  /**
   * Process responses from normal subscription flow actions.
   *
   * @param {Object} response - Response from normal subscription flow actions.
   */
  const processSubscriptionFlowResponse = useCallback((response) => {
    if (response.error) {
      const parsedResponse = {
        isError: true,
        error: response.error.raw ? response.error.raw : response.error,
      };
      setters.setLatestResponse(parsedResponse);
      setters.setProcessResponse(true);
      Sentry.captureException(
        new Error('Error during subscription flow'),
        {
          extra: {
            error: response.error,
          },
        },
      );
    } else if (response.object === 'subscription') {
      const subscriptionResponse = response;
      const invoice = subscriptionResponse.latest_invoice;
      const paymentIntent = invoice ? invoice.payment_intent : null;
      const parsedResponse = {
        isError: subscriptionResponse.isError,
        createdTime: subscriptionResponse.createdTime,
        invoice,
        subscription: subscriptionResponse,
        paymentIntent,
      };
      setters.setLatestResponse(parsedResponse);
      setters.setProcessResponse(true);
    }
    setters.setSubscriptionControllerState(SubscriptionControllerState.ACTION_COMPLETED);
  }, [setters]);

  /**
   * Creates a new subscription making use of the provided paymentMethod
   * @param {Object} [paymentMethod] The payment method to use
   */
  const createNewSubscription = useCallback((paymentMethodId) => {
    const subscriptionData = {
      planCode,
      email,
      paymentMethodId,
      remote,
      processSubscriptionFlowResponse,
      startAtDate,
      cancelAt,
      coupon,
      contractId: contractDoc?.id,
      leadId,
    };
    createSubscription(subscriptionData);
    if (anonId) {
      // On subscription the Segment user resets and we
      // need to link it to the anonId again
      window.analytics.user().anonymousId(anonId);
    }
  }, [
    planCode,
    email,
    remote,
    processSubscriptionFlowResponse,
    startAtDate,
    cancelAt,
    coupon,
    contractDoc,
    leadId,
  ]);

  /**
   * Handles subscription creation action, initiated by the user.
   *
   * @param {Object} informationForAction - Contains data from user actions.
   * @return {Promise<void>}
   */
  const submitNewSubscriptionCreationRequest = useCallback(async (informationForAction) => {
    setters.setSubscriptionControllerState(SubscriptionControllerState.ACTION_IN_PROGRESS);
    const { error, paymentMethod } = await stripe.createPaymentMethod(informationForAction);
    if (error) {
      const response = {
        isError: true,
        error: {
          type: StripeErrorType.CARD_ERROR,
          message: 'Card details are invalid. Please check again and retry',
        },
      };
      processSubscriptionFlowResponse(response);
    } else {
      createNewSubscription(paymentMethod.id);
    }
  }, [
    setters,
    stripe,
    createNewSubscription,
    processSubscriptionFlowResponse,
  ]);

  /**
   * Handles retrying payment-intent with new card information.
   * @param {Object} informationForAction - Contains data from user actions.
   * @return {Promise<void>}
   */
  const submitNewPaymentMethodDetails = useCallback(async (informationForAction) => {
    setters.setSubscriptionControllerState(SubscriptionControllerState.ACTION_IN_PROGRESS);
    const { error, paymentMethod } = await stripe.createPaymentMethod(informationForAction);
    if (error) {
      const response = {
        isError: true,
        error: {
          type: StripeErrorType.CARD_ERROR,
          message: 'Card details are invalid. Please check again and retry.',
        },
      };
      processSubscriptionFlowResponse(response);
    } else {
      const paymentMethodId = paymentMethod.id;
      const latestInvoice = localSubscriptionDetail.invoice;
      submitNewPaymentMethod({
        email: userDetail.email,
        paymentMethodId,
        invoiceId: latestInvoice.id,
        remote,
        processSubscriptionFlowResponse,
        stripeAccountId,
        externalCoach,
      });
    }
  }, [
    setters,
    stripe,
    processSubscriptionFlowResponse,
    localSubscriptionDetail,
    userDetail.email,
    remote,
    stripeAccountId,
    externalCoach,
  ]);

  /**
   * Handles user authentication/confirmation of payment.
   * @param {Object} paymentConfirmationResponse - Contains details of payment authentication.
   * @return {Promise<void>}
   */
  const submitAuthenticationConfirmation = useCallback(async (paymentConfirmationResponse) => {
    setters.setSubscriptionControllerState(SubscriptionControllerState.ACTION_IN_PROGRESS);
    if (!paymentConfirmationResponse.isConfirmed) {
      const response = {
        isError: true,
        error: {
          type: StripeErrorType.INVALID_REQUEST_ERROR,
          message: 'You declined card authentication. Please choose another card if you wish to continue.',
        },
      };
      processSubscriptionFlowResponse(response);
    } else {
      const { paymentIntent } = localSubscriptionDetail;
      authenticatePayment({
        paymentIntent,
        invoiceId: localSubscriptionDetail.invoice.id,
        remote,
        processSubscriptionFlowResponse,
        stripe,
        stripeAccountId,
      });
    }
  }, [
    remote,
    stripe,
    setters,
    processSubscriptionFlowResponse,
    localSubscriptionDetail,
    stripeAccountId,
  ]);

  const handleSubscriptionEndResult = useCallback(() => {
    navigateTo(routes.HOME);
  }, [
    navigateTo,
    routes,
  ]);

  /**
   * Handles Subscription flow user actions based on the current Subscription state.
   *
   * @param {object} informationForAction - Contains data from user actions.
   * @return {Promise<void>}
   */
  const handleSubscriptionUserAction = useCallback(async (informationForAction) => {
    if (subscriptionState === SubscriptionCreationState.INITIAL) {
      await submitNewSubscriptionCreationRequest(informationForAction);
    } else if (subscriptionState === SubscriptionCreationState.WAITING_FOR_INITIAL_PAYMENT_AUTHORIZATION) {
      await submitAuthenticationConfirmation(informationForAction);
    } else if (subscriptionState === SubscriptionCreationState.WAITING_FOR_VALID_PAYMENT_METHOD) {
      await submitNewPaymentMethodDetails(informationForAction);
    } else {
      // eslint-disable-next-line no-console
      console.warn(`No action would be taken for the state ${subscriptionState}`);
    }
  }, [
    subscriptionState,
    submitAuthenticationConfirmation,
    submitNewPaymentMethodDetails,
    submitNewSubscriptionCreationRequest,
  ]);

  const onAccountFormSubmitted = useCallback(async (formData) => {
    let {
      firstName: submittedFirstName,
      lastName: submittedLastName,
    } = formData;

    const {
      email: submittedEmail,
      phoneNumber: submittedPhoneNumber,
      password: submittedPassword,
    } = formData;

    submittedFirstName = submittedFirstName.trim();
    submittedLastName = submittedLastName.trim();

    onboardingSetters.setFirstName(submittedFirstName);
    onboardingSetters.setLastName(submittedLastName);
    onboardingSetters.setPhoneNumber(submittedPhoneNumber);
    if (submittedEmail) {
      onboardingSetters.setEmail(submittedEmail);
    }
    if (!userId) {
      const result = await createUser({
        firstName: submittedFirstName,
        lastName: submittedLastName,
        email: submittedEmail,
        phoneNumber: submittedPhoneNumber,
        password: submittedPassword,
      });
      setters.setAccountCreationState(result);
    }
  }, [
    onboardingSetters,
    createUser,
    userId,
    setters,
  ]);

  const handleApplePayPaymentMethod = useCallback(async (paymentMethodResult) => {
    setters.setApplePayPaymentMethodResult(paymentMethodResult);

    setSubmittedForm({ type: subscriptionSubmissionTypes.APPLE_PAY });
  }, [
    setters,
  ]);

  const clearApplePayPaymentMethod = useCallback(() => {
    setters.setApplePayPaymentMethodResult(null);
  }, [
    setters,
  ]);

  /**
   * Subscribes a user using no payment method
   */
  const handleNoPaymentSubscriptionSubmission = useCallback(async () => {
    setters.setSubscriptionControllerState(SubscriptionControllerState.ACTION_IN_PROGRESS);
    setSubmittedForm({ type: subscriptionSubmissionTypes.NO_PAYMENT });
  }, [
    setters,
  ]);

  const processPaymentDetailSubmit = useCallback(async () => {
    const card = elements.getElement(CardNumberElement);
    handleSubscriptionUserAction({
      type: 'card',
      card,
      billing_details: {
        email,
        name: `${firstName} ${lastName}`,
        phone: phoneNumber,
      },
    });
  }, [
    handleSubscriptionUserAction,
    elements,
    email,
    firstName,
    lastName,
    phoneNumber,
  ]);

  const handleSubscriptionSubmissionWithCreditCard = useCallback(async () => {
    setters.setSubscriptionControllerState(SubscriptionControllerState.ACTION_IN_PROGRESS);
    setSubmittedForm({ type: subscriptionSubmissionTypes.CARD_PAYMENT });
  }, [
    setters,
  ]);

  useEffect(() => {
    if (submittedForm && userId) {
      const { type: submittedFormType } = submittedForm;
      switch (submittedFormType) {
        case subscriptionSubmissionTypes.NO_PAYMENT: {
          createNewSubscription();
          break;
        }
        case subscriptionSubmissionTypes.APPLE_PAY: {
          const { paymentMethod } = applePayPaymentMethodResult;
          createNewSubscription(paymentMethod.id);
          break;
        }
        case subscriptionSubmissionTypes.CARD_PAYMENT: {
          processPaymentDetailSubmit();
          clearApplePayPaymentMethod();
          break;
        }
        default:
          break;
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    userId,
    submittedForm,
  ]);

  const initialPayment = initialPaymentInCents || totalPayOnSubscriptionCreation;

  // Apply any coupon percentage discount if it exists.
  const discount = Math.round(initialPayment * (percentOff / 100));

  // Check if we have to charge an upfront amount (only for future start dates)
  const upfrontAmount = startAtDate ? upfrontPaymentInCents : 0;

  // Compute the amount we have to charge using applePay
  const amount = upfrontAmount || initialPayment - discount;

  const {
    isCheckingForApplePayReady,
    paymentRequest: applePayPaymentRequest,
  } = useApplePay({
    onPaymentMethodResult: handleApplePayPaymentMethod,
    amount,
    currency,
  });

  const subscriptionFormData = useMemo(() => ({
    isCheckingForApplePayReady,
    applePayPaymentRequest,
    subscriptionControllerState,
    subscriptionState,
    stripeErrorState,
    processSubscriptionFlowResponse,
    handleSubscriptionSubmissionWithCreditCard,
    currentUserId: userId,
    handleSubscriptionEndResult,
    userDetail,
    clearApplePayPaymentMethod,
    handleNoPaymentSubscriptionSubmission,
    socialProofData,
    onAccountFormSubmitted,
    accountCreationState,
    isOnboardingCompleted,
    submitAuthenticationConfirmation,
  }),
  [
    isCheckingForApplePayReady,
    applePayPaymentRequest,
    subscriptionControllerState,
    subscriptionState,
    stripeErrorState,
    processSubscriptionFlowResponse,
    handleSubscriptionSubmissionWithCreditCard,
    userId,
    handleSubscriptionEndResult,
    userDetail,
    clearApplePayPaymentMethod,
    handleNoPaymentSubscriptionSubmission,
    socialProofData,
    onAccountFormSubmitted,
    accountCreationState,
    isOnboardingCompleted,
    submitAuthenticationConfirmation,
  ]);

  if (!areSubscriptionsAllowed) {
    return (
      <SubscriptionUnavailable
        planData={{
          planId,
          planCode,
          externalCoach,
        }}
      />
    );
  }

  return (
    <>
      {!completedInitialDataLoading && (<LoadingPage />)}
      {completedInitialDataLoading && (
        <SubscriptionCard subscriptionFormData={subscriptionFormData} />)}
    </>
  );
};

const StripeEnabledSubscriptionController = (props) => {
  const { stripePromise } = useContext(StripeContext);
  return (
    <Elements stripe={stripePromise}>
      <SubscriptionLogicController {...props} />
    </Elements>
  );
};

export default compose(
  withStripeContextProvider,
  withStripeContextReady,
  withSubscriptionContextProvider,
  withSubscriptionContextReady,
  withPageAnalytics('subscriptionPayment'),
  observer,
)(StripeEnabledSubscriptionController);
