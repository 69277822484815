import React, { useContext } from 'react';
import LoadingPage from '../../../components/LoadingPage';
import CoachesListContext from './CoachesListContext';
import CoachesListContextProvider from './CoachesListContextProvider';

/**
 * Inserts CoachesListContextProvider.
 *
 * @param {Object} Component The component that needs the context provider.
 * @returns {Object} The component to render wrapped with the provider.
 */
const withCoachesListContextProvider = (Component) => (props) => (
  <CoachesListContextProvider>
    <Component {...props} />
  </CoachesListContextProvider>
);

const withCoachesListContextReady = (Component) => (props) => {
  const { isReady } = useContext(CoachesListContext);

  return isReady ? <Component {...props} /> : <LoadingPage />;
};

export {
  withCoachesListContextProvider,
  withCoachesListContextReady,
};
