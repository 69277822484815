import PayoutReconciliation from '../Model/PayoutReconciliation';
import { AssignmentRole } from '../../utils/internalAssignments';

const PayoutConfig = {
  [AssignmentRole.INSIDE_SALES]: {
    payoutFunctionRef: PayoutReconciliation.getPayoutReconciliationBySellerId,
    commissionNetAmount: 'insideSalesCommission',
    commissionNetAmountWithCurrency: 'insideSalesCommissionWithCurrency',
  },
  [AssignmentRole.CRX]: {
    payoutFunctionRef: PayoutReconciliation.getPayoutReconciliationByCRX,
    commissionNetAmount: 'crxCommission',
    commissionNetAmountWithCurrency: 'crxCommissionWithCurrency',
  },
};

export {
  PayoutConfig,
};
