import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import TimeMe from 'timeme.js';

import useLogger from './useLogger';

const IDLE_TIMEOUT = 30;
const SAVE_INTERVAL = 60;

const useTimeTracker = () => {
  const { logEvent } = useLogger();
  const location = useLocation();

  const saveTime = useCallback(() => {
    const timeSpentReports = TimeMe.getTimeOnAllPagesInSeconds();
    // on path change & time elasped, stop last timer & clear recorded time to avoid sending duplicates
    TimeMe.stopAllTimers();
    TimeMe.resetAllRecordedPageTimes();
    // start timer with current path
    TimeMe.initialize({ currentPageName: location.pathname, idleTimeoutInSeconds: IDLE_TIMEOUT });
    if (timeSpentReports.length > 0) {
      const { pageName: pagePath, timeOnPage } = timeSpentReports[0];
      // ignore short visits caused by redirections
      if (timeOnPage < 10) return;
      // ['', 'is', 'xxxxxxx', 'sales']
      const [, viewAs, viewAsUser, feature] = pagePath.split('/');
      logEvent('timeSpentOnPage', {
        pagePath,
        timeOnPage,
        viewAs, // 'is', 'c', 's', ...
        viewAsUser, // user id of view as user
        feature, // 'feed', 'chat', 'manageClients', ...
      });
    }
  }, [
    logEvent,
    location.pathname,
  ]);

  useEffect(() => {
    // save time every minute to avoid losing data on page close
    TimeMe.callAfterTimeElapsedInSeconds(SAVE_INTERVAL, saveTime);
  }, [saveTime]);

  useEffect(() => {
    // save time on path change
    saveTime();
  }, [saveTime, location.pathname]);
};

export default useTimeTracker;
