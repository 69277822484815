const getMetabaseIframeUrl = async (remote, dashboardId) => {
  const endpoint = `analytics/getMetabaseIframeUrl?${(new URLSearchParams({ dashboardId }).toString())}`;
  const response = await remote(endpoint, null, { method: 'GET' });
  const { iframeUrl } = await response.json();
  return iframeUrl;
};

export {
  getMetabaseIframeUrl,
};
