import React, { useState } from 'react';
import PropTypes from 'prop-types';
import format from 'string-template';

import useComponentMounted from '../../../hooks/useComponentMounted';
import useToast from '../../hooks/useToast';
import User, { UserRoleFlag } from '../../../Model/User';
import UserClaims from '../../../Model/UserClaims';
import DialogRoundedModal from '../../../components/DialogRoundedModal';
import { StyledTextField } from '../../../components/Inputs';
import { SaveButton, CancelButton } from '../../../components/Button/ActionButtons';

import {
  ButtonContainer,
  InputContainer,
  StyledTextLabel,
} from './styles';
import texts from './texts.json';

const AddUserModal = ({
  isOpen,
  setShowUserAddingModal,
  setUserCollection,
  role,
  setIsLoading,
}) => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  const { showToast } = useToast();
  const isComponentMountedRef = useComponentMounted();

  const onCloseModal = () => {
    setEmail('');
    setEmailError('');
    setShowUserAddingModal(false);
  };

  const onAddNewUser = async () => {
    setIsLoading(true);
    const userCol = await User.getUserByEmail(email);
    // Check if the user is not found.
    if (!userCol.hasDocs) {
      setEmailError(texts.userNotFound);
      setIsLoading(false);
      return;
    }
    // Check if multiple users are found.
    if (userCol.docs.length > 1) {
      setEmailError((texts.multipleUsersFound));
      setIsLoading(false);
      return;
    }
    const userDoc = userCol.docs[0];
    // Check if the user is already assigned to the role, using the role flag value.
    if (userDoc[UserRoleFlag[role]]) {
      setEmailError(format(texts.userAlreadyExists, { role }));
      setIsLoading(false);
      return;
    }
    try {
      await userDoc.updateFields({ [UserRoleFlag[role]]: true });
      const userClaims = await UserClaims.getUserClaims(userDoc.id);
      if (!userClaims.exists || !userClaims.isAdmin) {
        await userClaims.updateFields({ admin: true });
      }
      const users = await User.getUsersByRole(role);
      if (isComponentMountedRef.current) {
        setUserCollection(users);
      }
      showToast(format(texts.successAddingUser, { role }));
    } catch (error) {
      showToast(format(texts.errorAddingUser, { role }), { error: true });
    } finally {
      if (isComponentMountedRef.current) {
        setIsLoading(false);
        onCloseModal();
      }
    }
  };

  return (
    <>
      <DialogRoundedModal
        open={isOpen}
        onClose={onCloseModal}
        title={texts.addNewUser}
        fullWidth
        maxWidth="sm"
        actionButtons={(
          <ButtonContainer>
            <SaveButton
              variant="contained"
              onClick={onAddNewUser}
              disabled={!email}
            >
              {texts.addUser}
            </SaveButton>
            <CancelButton onClick={onCloseModal}>{texts.cancel}</CancelButton>
          </ButtonContainer>
        )}
      >
        <InputContainer>
          <StyledTextLabel>{texts.emailInputLabel}</StyledTextLabel>
          <StyledTextField
            autoFocus
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder={texts.emailPlaceholder}
            error={!!emailError}
            helperText={!!emailError && emailError}
          />
        </InputContainer>
      </DialogRoundedModal>
    </>
  );
};

AddUserModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setShowUserAddingModal: PropTypes.func.isRequired,
  setUserCollection: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired,
  setIsLoading: PropTypes.func.isRequired,
};

export default AddUserModal;
