import styled, { css } from 'styled-components';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from '@stripe/react-stripe-js';

import { fontFamily } from '../../../../../styles/text';
import colors from '../../../../../styles/colors';
import { DirectionRow } from '../../../../../styles/grid';
import { mixins } from '../../../../../styles/breakpoints';
import SubmitButton from '../SubmitButton';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid ${colors.shades.gamma4};
  border-radius: 5px;
`;

const ErrorMessage = styled.div`
  font-size: 14px;
  color: ${colors.base.danger};
  margin-bottom: 10px;
`;

const stripeFieldStyles = css`
  width: ${(props) => (props.width ? props.width : '100%')};
  font-size: 16px;
  padding: 8px 0;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  flex: ${({ small, medium, large }) => {
    if (small) return '3';
    if (medium) return '6';
    if (large) return '8';
    return '0 1 auto';
  }};

  &.StripeElement {
    border-bottom-width: 1px !important;
    border-bottom: solid;
  }
  &.StripeElement--empty  {
    border-bottom-color: ${colors.shades.gamma3};
  }
  &.StripeElement--focus {
    border-bottom-color: ${colors.base.secondary2};
  }
  &.StripeElement--invalid {
    border-bottom-color: ${colors.shades.danger3};
  }
  &.StripeElement--complete {
    border-bottom-color: ${colors.base.secondary2};
  }
`;

const StripeCardNumberElement = styled(CardNumberElement)`
  ${stripeFieldStyles}
`;

const StripeCardExpiryElement = styled(CardExpiryElement)`
  ${stripeFieldStyles}
`;

const StripeCardCvcElement = styled(CardCvcElement)`
  ${stripeFieldStyles}
`;

/**
 * Note : Each Div element has a margin of 16px to the right, except for the last div child.
 */
const InputFieldGroup = styled(DirectionRow)`
  width: 100%;
  background: ${colors.base.alpha};
  margin-bottom: 10px;
  flex-direction: column;
  row-gap: 8px;
  justify-content: 'flex-start';
  ${mixins.tabletMedia(css`
    flex-direction: row;
    > div {
      margin-right: 16px;
    }
    > div:first-child {
      justify-content: left;
    }
    > div:last-child {
      margin-right: 0;
      justify-content: right;
    }
  `)};
`;

const StyledSubmitButton = styled(SubmitButton)`
  margin: 20px 0;
`;

const Header = styled.div`
  padding: 20px;
  border-bottom: 1px solid ${colors.shades.gamma4};
  font-family: ${fontFamily.sansSerif};
  color: ${colors.base.secondary2};
`;

const CheckoutContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

export {
  Container,
  Header,
  CheckoutContent,
  ErrorMessage,
  StripeCardNumberElement,
  StripeCardExpiryElement,
  StripeCardCvcElement,
  InputFieldGroup,
  StyledSubmitButton,
};
