import styled from 'styled-components';

import { Button } from '@mui/material';

import colors from '../../../../styles/colors';

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 0 8px;
  align-items: center;
  column-gap: 5px;
`;

const ActionButton = styled(Button)`
  border-radius: 5px;
  text-transform: none;
  background: ${colors.base.secondary3};
  border: 1px solid ${colors.shades.secondary6};
  color: ${colors.shades.gamma10};
  height: fit-content;
  padding: 10px 14px;
  &:hover {
    background: ${colors.base.secondary3};
  }
  &.MuiButton-text {
    line-height: 16px;
  }
`;

export {
  Container,
  ActionButton,
};
