import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
  ListItem,
  ListItemIcon,
} from '@mui/material';
import {
  Reorder,
  Delete,
  VisibilityOffOutlined,
  VisibilityOutlined,
  Edit,
} from '@mui/icons-material';

import QuestionInputModal from '../QuestionInputModal';
import texts from '../../texts.json';
import {
  StyledDraggable,
  StyledListItemText,
} from './styles';

const CheckInItem = ({
  index,
  question,
  prompt,
  handleDelete,
  isDefault,
  handleEdit,
  handleHide,
  isHidden,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleEditClick = useCallback((editedQuestion, editedPrompt) => {
    if (editedQuestion && editedPrompt) {
      setIsModalOpen(false);
      handleEdit(index, editedQuestion, editedPrompt);
    }
  }, [
    index,
    handleEdit,
  ]);

  return (
    <StyledDraggable>
      <ListItem disabled={isHidden}>
        <ListItemIcon className="drag-handle">
          <Reorder />
        </ListItemIcon>
        <StyledListItemText
          $isHidden={isHidden}
          primary={question}
          secondary={prompt}
        />
        <>
          <ListItemIcon onClick={() => setIsModalOpen(true)}>
            <Edit />
          </ListItemIcon>
          <ListItemIcon onClick={() => handleHide(index)}>
            {isHidden ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
          </ListItemIcon>
          {!isDefault && (
            <ListItemIcon onClick={() => handleDelete(index)}>
              <Delete />
            </ListItemIcon>
          )}
        </>
      </ListItem>
      <QuestionInputModal
        isOpen={isModalOpen}
        dialogTexts={{
          title: texts.dialogTitle,
          content: texts.dialogContent,
        }}
        onCancel={() => setIsModalOpen(false)}
        onConfirm={handleEditClick}
        question={question}
        prompt={prompt}
      />
    </StyledDraggable>
  );
};

CheckInItem.propTypes = {
  index: PropTypes.number.isRequired,
  handleDelete: PropTypes.func.isRequired,
  isDefault: PropTypes.bool.isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleHide: PropTypes.func.isRequired,
  isHidden: PropTypes.bool.isRequired,
  question: PropTypes.string.isRequired,
  prompt: PropTypes.string.isRequired,
};

export default CheckInItem;
