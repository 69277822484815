import styled from 'styled-components';
import { IonModal } from '@ionic/react';

const ModalContainer = styled(IonModal)`
  .ion-page {
    height: 100%;
  }
`;

export {
  ModalContainer,
};
