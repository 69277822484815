import React, {
  useState,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import {
  InputLabel,
  MenuItem,
} from '@mui/material';

import {
  Diet,
  dietMacroRanges,
  dietTexts,
} from '../../../utils/meals';
import DialogRoundedModal from '../../../../components/DialogRoundedModal';
import { SaveButton, CancelButton } from '../../../../components/Button/ActionButtons';
import {
  StyledTextField,
  StyledTextFieldLabel,
} from '../../../../components/Inputs';

import {
  ContentSection,
  Subtitle,
  RowContainer,
  FilterLabel,
  ReStyledTextFieldContainer,
  ReStyledSelect,
  StyledMealPlateIcon,
} from './styles';
import texts from './texts.json';

const defaultRange = { from: 50, to: 50 };
const MIN_INPUT_VALUE = 0;
const MAX_INPUT_VALUE = 100;

const MacroFiltersModal = ({
  open,
  onClose,
  onConfirm,
}) => {
  const [proteinRange, setProteinRange] = useState(defaultRange);
  const [carbsRange, setCarbsRange] = useState(defaultRange);
  const [fatRange, setFatRange] = useState(defaultRange);

  const [selectedDiet, setSelectedDiet] = useState('');

  const handleFieldChange = useCallback((event, field, setter) => {
    const { value } = event.target;
    let newValue = value;
    if (value < MIN_INPUT_VALUE) {
      newValue = MIN_INPUT_VALUE;
    }
    if (value > MAX_INPUT_VALUE) {
      newValue = MAX_INPUT_VALUE;
    }

    setter((prev) => ({ ...prev, [field]: newValue }));
    setSelectedDiet('');
  }, []);

  const handleDietChange = useCallback((event) => {
    const selectedValue = event.target.value;
    if (!selectedValue) {
      setSelectedDiet('');
      setProteinRange(defaultRange);
      setCarbsRange(defaultRange);
      setFatRange(defaultRange);
      return;
    }
    setSelectedDiet(selectedValue);
    const {
      proteinRange: selectedProteinRange,
      carbsRange: selectedCarbsRange,
      fatRange: selectedFatRange,
    } = dietMacroRanges[selectedValue];
    setProteinRange(selectedProteinRange);
    setCarbsRange(selectedCarbsRange);
    setFatRange(selectedFatRange);
  }, []);

  const onFilterAccept = useCallback(() => {
    const returnValue = {
      proteinRange,
      carbsRange,
      fatRange,
      dietName: dietTexts[selectedDiet] || selectedDiet,
    };
    onConfirm(returnValue);
    onClose();
  }, [
    proteinRange,
    carbsRange,
    fatRange,
    onConfirm,
    selectedDiet,
    onClose,
  ]);

  const actionButtons = (
    <>
      <SaveButton onClick={onFilterAccept}>
        {texts.filterRecipes}
      </SaveButton>
      <CancelButton onClick={onClose}>
        {texts.cancel}
      </CancelButton>
    </>
  );

  return (
    <DialogRoundedModal
      open={open}
      onClose={onClose}
      title={texts.title}
      description={texts.description}
      actionButtons={actionButtons}
      IconComponent={<StyledMealPlateIcon />}
    >
      <ContentSection>
        <Subtitle>{texts.diet}</Subtitle>
        <RowContainer size="small">
          <InputLabel id="select-diet-plan">{texts.dietPlan}</InputLabel>
          <ReStyledSelect
            labelId="select-diet-plan"
            value={selectedDiet}
            onChange={handleDietChange}
            label={texts.dietPlan}
          >
            <MenuItem value=""><em>{texts.dietPlanNone}</em></MenuItem>
            {Object.keys(Diet).map((diet) => (
              <MenuItem key={diet} value={Diet[diet]}>{dietTexts[diet]}</MenuItem>
            ))}
          </ReStyledSelect>
        </RowContainer>
      </ContentSection>
      <ContentSection>
        <Subtitle>{texts.macroRange}</Subtitle>

        <RowContainer>
          <FilterLabel />

          <ReStyledTextFieldContainer>
            <StyledTextFieldLabel>{texts.from}</StyledTextFieldLabel>
          </ReStyledTextFieldContainer>
          <ReStyledTextFieldContainer>
            <StyledTextFieldLabel>{texts.to}</StyledTextFieldLabel>
          </ReStyledTextFieldContainer>
        </RowContainer>

        <RowContainer>
          <FilterLabel>{texts.protein}</FilterLabel>

          <ReStyledTextFieldContainer>
            <StyledTextField
              value={proteinRange.from}
              onChange={(event) => handleFieldChange(event, 'from', setProteinRange)}
              type="number"
              variant="outlined"
              title="%"
            />
          </ReStyledTextFieldContainer>
          <ReStyledTextFieldContainer>
            <StyledTextField
              value={proteinRange.to}
              onChange={(event) => handleFieldChange(event, 'to', setProteinRange)}
              type="number"
              variant="outlined"
              title="%"
            />
          </ReStyledTextFieldContainer>
        </RowContainer>

        <RowContainer>
          <FilterLabel>{texts.carbs}</FilterLabel>

          <ReStyledTextFieldContainer>
            <StyledTextField
              value={carbsRange.from}
              onChange={(event) => handleFieldChange(event, 'from', setCarbsRange)}
              type="number"
              title="%"
            />
          </ReStyledTextFieldContainer>
          <ReStyledTextFieldContainer>
            <StyledTextField
              value={carbsRange.to}
              onChange={(event) => handleFieldChange(event, 'to', setCarbsRange)}
              type="number"
              variant="outlined"
              title="%"
            />
          </ReStyledTextFieldContainer>
        </RowContainer>
        <RowContainer>
          <FilterLabel>{texts.fat}</FilterLabel>

          <ReStyledTextFieldContainer>
            <StyledTextField
              value={fatRange.from}
              onChange={(event) => handleFieldChange(event, 'from', setFatRange)}
              type="number"
              title="%"
            />
          </ReStyledTextFieldContainer>
          <ReStyledTextFieldContainer>
            <StyledTextField
              value={fatRange.to}
              onChange={(event) => handleFieldChange(event, 'to', setFatRange)}
              type="number"
              variant="outlined"
              title="%"
            />
          </ReStyledTextFieldContainer>
        </RowContainer>
      </ContentSection>
    </DialogRoundedModal>
  );
};

MacroFiltersModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default MacroFiltersModal;
