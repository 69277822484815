import React, {
  useContext,
  useMemo,
  useCallback,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { ChannelList } from 'stream-chat-react';

import { channelConfiguration } from '../../util';
import MessagingChannelList from '../MessagingChannelList';
import MessagingChannelPreview from '../MessagingChannelPreview';
import MessagingChannelSearch from '../MessagingChannelSearch';
import ChatContext from '../../../../context/ChatContext';

import { ChannelBuckets } from '../../../../context/ChatContext/buckets';
import {
  EmptyListPlaceholder,
  MultipleChannelListContainer,
  StyledSegment,
  StyledSegmentButton,
  StyledSegmentLabel,
  StyledSortButton,
  StyledSortIcon,
} from './styles';
import texts from './texts';

const MultipleChannelList = ({
  className,
}) => {
  const { userId } = useContext(ChatContext);
  const [isRecentFirst, setIsRecentFirst] = useState(true);

  const {
    customActiveChannel,
    selectedBucket,
    onBucketSelected,
  } = useContext(ChatContext);

  const handleBucketChange = (e) => onBucketSelected(e.detail.value);

  const channelFilters = useMemo(() => ({
    filters: channelConfiguration.filters(userId, selectedBucket),
    options: channelConfiguration.options,
    sort: channelConfiguration.sort(isRecentFirst),
  }), [
    selectedBucket,
    userId,
    isRecentFirst,
  ]);

  const getSegmentButtons = useCallback(() => (
    Object.values(ChannelBuckets).map((bucket) => (
      <StyledSegmentButton key={bucket} value={bucket}>
        <StyledSegmentLabel>{texts.channelBuckets[bucket]}</StyledSegmentLabel>
      </StyledSegmentButton>
    ))
  ), []);

  return (
    <MultipleChannelListContainer className={className}>
      <div>
        <div className="messaging__channel-list">
          <div className="messaging__channel-list__header">
            <MessagingChannelSearch
              searchQueryParams={{
                channelFilters,
                userFilters: { filters: { id: '' } },
              }}
              searchForChannels
            />
            <StyledSegment
              onIonChange={handleBucketChange}
              value={selectedBucket}
              mode="ios"
            >
              {getSegmentButtons()}
            </StyledSegment>
            <StyledSortButton onClick={() => setIsRecentFirst((prev) => !prev)}>
              <StyledSortIcon />
              {isRecentFirst ? texts.sortByOldestFirst : texts.sortByRecentFirst}
            </StyledSortButton>
          </div>
        </div>
      </div>
      <div>
        <ChannelList
          customActiveChannel={customActiveChannel?.id}
          {...channelFilters}
          List={(props) => <MessagingChannelList {...props} />}
          Preview={(props) => <MessagingChannelPreview {...props} />}
          EmptyStateIndicator={() => <EmptyListPlaceholder>{texts.noChannelsPlaceholder}</EmptyListPlaceholder>}
        />
      </div>
    </MultipleChannelListContainer>
  );
};

MultipleChannelList.propTypes = {
  className: PropTypes.string,
};

MultipleChannelList.defaultProps = {
  className: '',
};

export default MultipleChannelList;
