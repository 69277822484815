import React from 'react';
import PropTypes from 'prop-types';
import format from 'string-template';
import ReactHtmlParser from 'react-html-parser';

import texts from './texts.json';
import {
  Container,
  Note,
  ChangeDescription,
  HIGHLIGHT_CLASSNAME,
} from './styles';

const wrapperPattern = `<span class="${HIGHLIGHT_CLASSNAME}">$&</span>`;

const CheckInDayConfirmContent = ({
  currentDay,
  newDay,
}) => (
  <Container>
    <ChangeDescription>
      {ReactHtmlParser(format(texts.changeDescription, {
        currentDay,
        newDay,
      }).replaceAll(currentDay, wrapperPattern)
        .replaceAll(newDay, wrapperPattern))}
    </ChangeDescription>
    <Note>{texts.changeNote}</Note>
  </Container>
);

CheckInDayConfirmContent.propTypes = {
  newDay: PropTypes.string.isRequired,
  currentDay: PropTypes.string.isRequired,
};

export default CheckInDayConfirmContent;
