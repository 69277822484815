import format from 'string-template';

import {
  AnalyticFrequency,
  AnalyticType,
  AnalyticUserType,
} from '../../../utils/analytics';
import Collections from '../../../utils/collections';
import MonthlyAnalytics from './MonthlyAnalytics';

class CoachLeadAnalytics extends MonthlyAnalytics {
  get total() {
    return this.data.total || 0;
  }

  get male() {
    return this.data.male || 0;
  }

  get female() {
    return this.data.female || 0;
  }

  get unqualified() {
    return this.data.unqualified || 0;
  }

  static getCollectionPath(coachId) {
    return format(Collections.ANALYTICS, {
      userType: AnalyticUserType.COACH,
      analyticType: AnalyticType.LEADS,
      frequency: AnalyticFrequency.MONTHLY,
      userId: coachId,
    });
  }
}

export default CoachLeadAnalytics;
