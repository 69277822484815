import { DisallowedGender } from '../../../../../Model/Coach';

const texts = {
  title: 'Automated Disqualification Settings',
  disallowedGenders: 'Disallowed Genders',
  financialDisqualification: 'Disqualify by Financial Commitment',
  automatedEmails: 'Automatic Disqualification Emails',
  save: 'Save Changes',
  enabled: 'Enabled',
  disabled: 'Disabled',
  saved: 'Automated disqualification settings have been saved successfully',
  disallowedGender: {
    [DisallowedGender.MALE]: 'Male',
    [DisallowedGender.FEMALE]: 'Female',
    [DisallowedGender.PREFER_NOT_TO_ANSWER]: 'Prefer not to answer',
    [DisallowedGender.OTHER]: 'Other',
  },
  emailTemplateRequired:
    'To enable Automatic Disqualification Emails, please ensure a disqualification email template is configured. '
    + 'This template is required to proceed. Once set up, you can enable the email feature',
};

export default texts;
