import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
} from 'react';
import { useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import { computed, autorun } from 'mobx';

import useComponentMounted from '../../../hooks/useComponentMounted';
import User from '../../../Model/User';
import ClientTag from '../../Model/Tag';

import { ClientStatus } from '../../utils/statusFilter';
import ManageClientContext, { initialValues } from './ManageClientContext';

const ManageClientContextProvider = ({
  children,
}) => {
  const { params: { userId } } = useRouteMatch();

  const [clientList, setClientList] = useState(initialValues.clientList);
  const [currentUserId, setCurrentUserId] = useState(userId);
  const [isReady, setIsReady] = useState(initialValues.isReady);
  const isComponentMountedRef = useComponentMounted();
  const [clientSortModel, setClientSortModel] = useState(initialValues.clientSortModel);
  const [selectedTab, setSelectedTab] = useState(initialValues.selectedTab);
  const [clientFilters, setClientFilters] = useState(initialValues.clientFilters);
  const [clientExcludingFilters, setClientExcludingFilters] = useState(initialValues.clientExcludingFilters);
  const [clientTagsFilters, setClientTagsFilters] = useState(initialValues.clientTagsFilters);
  const [baseClientTagsCollection, setBaseClientTagsCollection] = useState(initialValues.baseClientTagsCollection);
  const [customClientTagsCollection, setCustomClientTagsCollection] = useState(
    initialValues.customClientTagsCollection,
  );
  const [clientTagsDocs, setClientTagsDocs] = useState(initialValues.clientTagsDocs);
  const [allUsersLoaded, setAllUsersLoaded] = useState(initialValues.allUsersLoaded);
  const [loadingUsers, setLoadingUsers] = useState(initialValues.loadingUsers);
  const [clientTablePaginationModel, setClientPaginationModel] = useState();

  useEffect(() => {
    if (currentUserId !== userId) {
      setIsReady(false);
    }
  }, [
    currentUserId,
    userId,
  ]);

  useEffect(() => {
    const init = async () => {
      // load only active users
      setLoadingUsers(true);
      const usersByCoachCol = await User.getActiveUsersByCoach(userId);
      if (isComponentMountedRef.current) {
        setClientList(usersByCoachCol.docs);
        setCurrentUserId(userId);
        setLoadingUsers(false);
        setAllUsersLoaded(false);
        setIsReady(true);
      }
    };

    if (!isReady) {
      init();
    }
  }, [
    isReady,
    isComponentMountedRef,
    userId,
  ]);

  useEffect(() => {
    const getClientTags = async () => {
      const baseClientTagsCol = await ClientTag.getClientBaseTags();
      const customClientTagsCol = await ClientTag.getClientCustomTagsforCoach(userId);

      if (isComponentMountedRef.current) {
        setBaseClientTagsCollection(baseClientTagsCol);
        setCustomClientTagsCollection(customClientTagsCol);
      }
    };
    getClientTags();
  }, [
    isComponentMountedRef,
    userId,
  ]);

  useEffect(() => {
    const disposer = autorun(() => {
      const baseClientTagDocs = baseClientTagsCollection.hasDocs ? baseClientTagsCollection.docs.slice() : [];
      const customClientTagDocs = customClientTagsCollection.hasDocs ? customClientTagsCollection.docs.slice() : [];

      const clientTagDocs = [
        ...baseClientTagDocs,
        ...customClientTagDocs,
      ];

      const clientTagDocsExcludeRemoved = clientTagDocs.filter((tagDoc) => tagDoc.removed !== true);

      // Sort all tags lexicographically.
      clientTagDocsExcludeRemoved.sort((a, b) => a.tag.localeCompare(b.tag));

      if (isComponentMountedRef.current) {
        setClientTagsDocs(clientTagDocsExcludeRemoved);
      }
    });
    return disposer;
  }, [
    baseClientTagsCollection,
    customClientTagsCollection,
    isComponentMountedRef,
  ]);

  const loadAllUsers = useCallback(async () => {
    setLoadingUsers(true);
    const usersByCoachCol = await User.getUsersByCoach(userId);
    if (isComponentMountedRef.current) {
      setClientList(usersByCoachCol.docs);
      setAllUsersLoaded(true);
      setLoadingUsers(false);
    }
  }, [isComponentMountedRef, userId]);

  useEffect(() => {
    // if the client filters are not set to active, we need to load all users
    const shouldLoadAllUsers = (clientFilters.length === 1 && clientFilters[0] !== ClientStatus.ACTIVE)
      || clientFilters.length > 1;
    if (!allUsersLoaded && shouldLoadAllUsers) {
      loadAllUsers();
    }
  }, [
    allUsersLoaded,
    clientFilters,
    loadAllUsers,
  ]);

  // data required for the user table will be calculated at each update to the user/client list
  const clientDataList = useMemo(() => computed(() => clientList
    .map((client) => ({
      id: client.id,
      name: client.name,
      userAvatar: client.avatarUrl,
      clientSince: client.createdAt,
      lastCheckIn: client.lastCheckIn,
      isActive: client.isActive,
      breakEndDate: client.breakEndDate,
      lastWorkout: client.lastWorkoutCompletedAt,
      lastMessageAt: client.lastMessageAt,
      isDelinquent: !!client.flags.delinquent,
      serviceStartAt: client.serviceStartAt,
      subscriptionStatus: client.flags.subscriptionStatus,
      cancelAt: client.cancelAt,
      planCode: client.planId,
      productId: client.product,
      monthlyStartAt: client.monthlyStartAt,
      tags: client.tags,
      clientTags: client.customTags || [],
      commitmentEndingDate: client.commitmentEndingDate,
    }))), [
    clientList,
  ]).get();

  const contextValue = useMemo(() => ({
    clientDataList,
    isReady,
    clientSortModel,
    setClientSortModel,
    selectedTab,
    setSelectedTab,
    clientFilters,
    setClientFilters,
    clientExcludingFilters,
    setClientExcludingFilters,
    clientTagsDocs,
    clientTagsFilters,
    setClientTagsFilters,
    loadingUsers,
    clientTablePaginationModel,
    setClientPaginationModel,
  }), [
    clientDataList,
    isReady,
    clientSortModel,
    setClientSortModel,
    selectedTab,
    setSelectedTab,
    clientFilters,
    setClientFilters,
    clientExcludingFilters,
    setClientExcludingFilters,
    clientTagsDocs,
    clientTagsFilters,
    setClientTagsFilters,
    loadingUsers,
    clientTablePaginationModel,
    setClientPaginationModel,
  ]);

  return (
    <ManageClientContext.Provider value={contextValue}>
      {children}
    </ManageClientContext.Provider>
  );
};

ManageClientContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default compose(
  observer,
)(ManageClientContextProvider);
