import styled from 'styled-components';

import colors from '../../../styles/colors';

/*
Usage:
  <HeaderRow>
    <TitleContainer>
      <Title>{texts.title}</Title>
      <SubTitle>{texts.subTitle}</SubTitle>
    </TitleContainer>
  </HeaderRow>
  <ProductList />
*/

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin: 20px;
`;

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 5px 0px;
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 22px;
  line-height: 21px;
  margin-bottom: 5px;
`;

const SubTitle = styled.div`
  font-size: 15px;
  margin-bottom: 0;
  font-weight: 300;
  color: ${colors.shades.gamma7};
`;

export {
  HeaderRow,
  Title,
  TitleContainer,
  SubTitle,
};
