import React from 'react';
import PropTypes from 'prop-types';

import { fatToCalories } from '../../../../../utils/meals';

import { tagsByNutritionix } from './components/nutritionfactstable/utils/nutritionix';
import NutritionFactTable from './components/nutritionfactstable';
import { Container, NutritionFactTableContainer } from './styles';

const CheckNutriFacts = ({
  checkData,
  numberOfServings,
}) => {
  let calories = 0;
  let weight = 0;
  let fat = 0;
  const items = [];

  // go through all foods in response
  checkData.forEach((food) => {
    // add to total calories
    calories += food.nf_calories;
    // add to total weight
    weight += food.serving_weight_grams;
    // add to total fat
    fat += food.nf_total_fat;

    // go through all nutrients and check if we had a corresponding tag for displaying it in table.
    food.full_nutrients.forEach((nutrient) => {
      const tag = tagsByNutritionix.find((tbn) => tbn.attr_id === nutrient.attr_id);
      if (tag?.tableId) {
        const existingItem = items.find(({ nutritionItem }) => nutritionItem === tag.tableId);
        if (existingItem) {
          existingItem.amount += nutrient.value;
        } else {
          items.push({ amount: nutrient.value, nutritionItem: tag.tableId });
        }
      }
    });
  });
  // divide by servings and round up resulted values
  for (let i = 0; i < items.length; i++) {
    items[i].amount = Math.round(items[i].amount / numberOfServings);
  }
  calories = Math.round(calories / numberOfServings);
  weight = Math.round(weight / numberOfServings);
  fat = Math.round(fat / numberOfServings);

  const NutritionFactProps = {
    servingSizeInformation: {
      imperialUnits: 'items',
      fractionalSize: checkData.length,
      servingNumber: `${weight}g`,
    },
    nutritionItems: {
      items,
      calories,
      caloriesFromFat: fatToCalories(fat),
    },
  };

  return (
    <Container>
      <NutritionFactTableContainer>
        <NutritionFactTable {...NutritionFactProps} disableMicros />
      </NutritionFactTableContainer>
    </Container>
  );
};

CheckNutriFacts.propTypes = {
  checkData: PropTypes.object.isRequired,
  numberOfServings: PropTypes.number,
};

CheckNutriFacts.defaultProps = {
  numberOfServings: 1,
};

export default CheckNutriFacts;
