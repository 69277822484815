import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import * as Sentry from '@sentry/browser';

import { CURRENCIES } from '../../utils/currency';
import { getCurrencySymbol } from '../../utils/formatters';
import {
  ContractContent,
  Wrapper,
  HIGHLIGHT_CLASSNAME,
} from './styles';
import texts from './texts.json';

const ContractDetails = ({
  contractTerms,
  coachConditions,
  className,
  userId,
}) => {
  useEffect(() => {
    // We should never reach this situation, so we want to report it right away to Sentry.
    if (userId && (!contractTerms || !contractTerms.length)) {
      Sentry.captureException(new Error(texts.noContractTerms), {
        extra: {
          userId,
        },
      });
    }
  }, [
    contractTerms,
    userId,
  ]);

  const renderTerms = useCallback(() => {
    if (!contractTerms || !contractTerms.length) {
      return texts.noContractTerms;
    }

    const datePattern = /\b(?:\w+)\s\d{1,2},\s\d{4}\b/g;

    // Build the price pattern based on the currencies we support.
    const currenciesPattern = CURRENCIES.map((currency) => {
      const symbol = getCurrencySymbol(currency.code);

      // If the currency symbol includes the dollar sign, then we need to escape it for the regex to work.
      return symbol.replaceAll('$', '\\$');
    }).join('|');

    const pricePattern = new RegExp(`(${currenciesPattern})\\d+(\\.\\d{2})?\\b`, 'g');
    const monthPattern = /\d+\smonth\(s\)/g;
    const wrapperPattern = `<span class="${HIGHLIGHT_CLASSNAME}">$&</span>`;

    return contractTerms.map((paragraph, index) => {
      const highlightedText = paragraph.replaceAll(datePattern, wrapperPattern)
        .replaceAll(pricePattern, wrapperPattern)
        .replaceAll(monthPattern, wrapperPattern);
      return <p key={index.toString()}>{ReactHtmlParser(highlightedText)}</p>;
    });
  }, [contractTerms]);

  return (
    <Wrapper className={className}>
      <ContractContent>
        {renderTerms()}
      </ContractContent>
      {!!coachConditions && (
        <ContractContent>
          {/* Adding ReactHtmlParser in case the custom conditions have html tags like <p>, and <br /> */}
          {ReactHtmlParser(coachConditions)}
        </ContractContent>
      )}
    </Wrapper>
  );
};

ContractDetails.propTypes = {
  contractTerms: PropTypes.array.isRequired,
  coachConditions: PropTypes.string,
  className: PropTypes.string,
  userId: PropTypes.string,
};

ContractDetails.defaultProps = {
  className: '',
  coachConditions: '',
  userId: '',
};

export default ContractDetails;
