import React, { useContext } from 'react';

import { Chat, ChatContext } from '@fitmoola/system2-chat';

import FirebaseContext from '../../../../../context/FirebaseContext';
import ChatLoadingView from '../../components/ChatLoadingView';
import useSessionStore from '../../../../../hooks/useSessionStore';
import UserContext from '../../../../../context/UserContext';
import WebMediaRecorder from '../../WebMediaRecorder';

const ChatContainer = React.memo(() => {
  const {
    isReady,
  } = useContext(ChatContext);

  const {
    firebase,
  } = useContext(FirebaseContext);

  const {
    userId: chatUserId,
  } = useContext(UserContext);

  const sessionStore = useSessionStore();

  const webMediaRecorder = new WebMediaRecorder();

  if (!isReady) {
    return <ChatLoadingView />;
  }

  return (
    <Chat
      firebase={firebase}
      sessionStore={sessionStore}
      chatUserId={chatUserId}
      mediaRecorder={webMediaRecorder}
    />
  );
});

export default ChatContainer;
