import { ExerciseType } from '../../../../../Model/Exercise';

const texts = {
  moreTags: '+{amount} More',
  changeExercise: 'Change Exercise',
  exerciseType: {
    [ExerciseType.BASE]: 'Base',
    [ExerciseType.CUSTOM]: 'Custom',
    [ExerciseType.EDITED]: 'Edited',
  },
};

export default texts;
