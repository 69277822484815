import React, { useContext } from 'react';

import HeaderContext from '../../../context/HeaderContext';
import colors from '../../../styles/colors';
import {
  StyledHeader,
  HeaderLogo,
  StyledBackButton,
} from './styles';

const Header = () => {
  const { showBackButton, navigateBack } = useContext(HeaderContext);
  return (
    <StyledHeader>
      {showBackButton && <StyledBackButton color={colors.base.alpha} onClick={navigateBack} />}
      <HeaderLogo />
    </StyledHeader>
  );
};

export default Header;
