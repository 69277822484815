import styled from 'styled-components';

import {
  Avatar,
  Typography,
} from '@mui/material';

import colors from '../../../../../../styles/colors';

const avatarCircleSizePx = 60;
const marginPx = 30;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px;
  background-color: ${colors.base.alpha};
`;

const UserInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: ${marginPx}px;
  gap: 12px;
`;

const StyledAvatar = styled(Avatar)`
  height: ${avatarCircleSizePx}px;
  width: ${avatarCircleSizePx}px;
  position: relative;
`;

const UserInfoTitle = styled(Typography)`
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  margin: 0 10px;
  color: ${colors.base.secondary2};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 7px;
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  column-gap: 20px;
  margin-bottom: ${marginPx}px;
`;

export {
  Container,
  StyledAvatar,
  UserInfoContainer,
  UserInfoTitle,
  SectionContainer,
};
