import React from 'react';

const initialValues = {
  isReady: false,
  internalAssignmentsCol: { docs: [] },
};

const InternalAssignmentsContext = React.createContext(initialValues);

export default InternalAssignmentsContext;
export {
  initialValues,
};
