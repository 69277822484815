import React, {
  useState,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import {
  Dialog,
  DialogContent,
  Paper,
} from '@mui/material';

import Button from '../../../../../components/Button';
import useToast from '../../../../hooks/useToast';

import {
  StyledNotesInput,
  Title,
  StyledDialogActions,
} from './styles';
import texts from './texts.json';

/*
  TODO: We can make this modal a little more generic to be used in other places. Let's keep it like this for now, but
  tackle all Dialogs and Modals when taking care of the UI refactor.
*/
const ReasonModal = ({
  title,
  onSave,
  onClose,
  showModal,
}) => {
  const [reason, setReason] = useState('');

  const { showToast } = useToast();

  const handleSave = useCallback(async () => {
    await onSave({ reason });

    showToast(texts.saveSuccessful, {
      options: {
        position: 'top-right',
        closeOnClick: true,
        draggable: true,
      },
    });
    onClose();
  }, [
    reason,
    onSave,
    onClose,
    showToast,
  ]);

  const titleValue = title || texts.title;

  return (
    <Dialog
      open={showModal}
      PaperComponent={Paper}
    >
      <Title>{titleValue}</Title>
      <DialogContent>
        <StyledNotesInput
          variant="outlined"
          label={texts.label}
          multiline
          rows={4}
          value={reason}
          onChange={(evt) => setReason(evt.target.value)}
        />
      </DialogContent>
      <StyledDialogActions>
        <Button onClick={onClose}>
          {texts.close}
        </Button>
        <Button onClick={handleSave}>
          {texts.save}
        </Button>
      </StyledDialogActions>
    </Dialog>
  );
};

ReasonModal.propTypes = {
  title: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
};

ReasonModal.defaultProps = {
  title: '',
};

export default compose(
  observer,
)(ReasonModal);
