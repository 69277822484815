import styled from 'styled-components';
import { IonProgressBar } from '@ionic/react';
import colors from '../../../../styles/colors';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  background-color: ${colors.base.alpha};
`;

const HabitText = styled.span`
  font-size: 12px;
  line-height: 22px;
  margin-top: 2px;
`;

const StatText = styled.span`
  font-size: 12px;
  line-height: 22px;
  margin-top: 2px;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 6px 0;
  border-radius: 10px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledHabitProgress = styled(IonProgressBar)`
  width: 100%;
  height: 8px;
  border-radius: 25px;
  --background: ${colors.shades.gamma8};
  --progress-background: ${colors.shades.primary1};
`;

export {
  Container,
  HabitText,
  ItemContainer,
  TextContainer,
  StatText,
  StyledHabitProgress,
};
