import React from 'react';

const initialValues = {};

const LeadsContext = React.createContext(initialValues);

export default LeadsContext;
export {
  initialValues,
};
