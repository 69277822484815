import {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
} from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import StripeContext from '../context/StripeContext';
import useLogger from './useLogger';
import useComponentMounted from './useComponentMounted';

const defaultApplePayOpts = {
  country: 'US',
  currency: 'usd',
  requestPayerName: true,
  requestPayerPhone: true,
};

/*
  Apple Pay relies on Stripe being loaded to be able to generate a payment request (as the Apple Pay impl we have is
  actually stripe-based). We'll wait for Stripe to load, but if it doesn't, we should just move forward with no Apple
  Pay support. That's why we have the following "loading timeout" to indicate that that's the max amount of time we're
  willing to wait for Stripe to load.
*/
const STRIPE_LOADING_TIMEOUT = 30 * 1000;

/**
 * A hook for making use of Apple Pay.
 * This hook requires Stripe to be already loaded. Like under an Elements provider component:
 * https://stripe.com/docs/stripe-js/react#elements-provider
 *
 * @param {function} onPaymentMethodResult A method to be called once the payment method is generated.
 * @param {number} amount The amount to be charged
 */
const useApplePay = ({
  onPaymentMethodResult,
  amount,
  currency = defaultApplePayOpts.currency,
}) => {
  const stripe = useStripe();
  const loadingTimer = useRef(null);
  const isComponentMountedRef = useComponentMounted();
  const [isCheckingForApplePayReady, setCheckingForApplePayReady] = useState(false);
  const [paymentRequest, setPaymentRequest] = useState(null);
  const { logEvent } = useLogger();
  const {
    applePay: {
      enabled: isApplePayMethodEnabled,
      productName: applePayProductName,
    },
  } = useContext(StripeContext);

  const currencyCode = currency.toLowerCase();

  const applePayOpts = {
    ...defaultApplePayOpts,
    currency: currencyCode,
    total: {
      label: applePayProductName,
      amount,
    },
  };

  if (typeof applePayOpts.total.amount !== 'number') {
    throw new Error('Invalid opts provided for generating an Apple Pay Payment Request');
  }

  useEffect(() => {
    if (stripe) {
      const initPaymentRequest = async () => {
        const pr = stripe.paymentRequest(applePayOpts);
        // Check the availability of the Payment Request API.
        const result = await pr.canMakePayment();
        if (result && result.applePay && isComponentMountedRef.current) {
          setPaymentRequest(pr);
        }
        setCheckingForApplePayReady(true);
      };

      if (isApplePayMethodEnabled) {
        initPaymentRequest();
      } else {
        setCheckingForApplePayReady(true);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    stripe,
  ]);

  useEffect(() => {
    if (!isCheckingForApplePayReady) {
      loadingTimer.current = setTimeout(() => {
        if (isComponentMountedRef.current) {
          setCheckingForApplePayReady(true);
        }
      }, STRIPE_LOADING_TIMEOUT);
    } else if (loadingTimer.current) {
      clearTimeout(loadingTimer.current);
    }
  }, [
    isComponentMountedRef,
    isCheckingForApplePayReady,
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onPaymentRequestCancel = useCallback(() => logEvent('applePayCancelled'), []);

  useEffect(() => {
    if (paymentRequest) {
      paymentRequest.on('paymentmethod', onPaymentMethodResult);
      paymentRequest.on('cancel', onPaymentRequestCancel);
    }

    return () => {
      if (paymentRequest) {
        paymentRequest.off('paymentmethod', onPaymentMethodResult);
        paymentRequest.off('cancel', onPaymentRequestCancel);
      }
    };
  }, [
    onPaymentMethodResult,
    paymentRequest,
    onPaymentRequestCancel,
  ]);

  return {
    paymentRequest,
    isCheckingForApplePayReady,
  };
};

export default useApplePay;
