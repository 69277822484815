import React from 'react';
import PropTypes from 'prop-types';
import {
  BarChart as ReBarChart,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ReferenceLine,
  Bar,
} from 'recharts';

import colors from '../../../styles/colors';

const BarChart = ({
  data,
  keys,
  width,
  height,
  labelFormatter,
  toolTipFormatter,
}) => (
  <ReBarChart
    width={width}
    height={height}
    data={data}
    stackOffset="sign"
  >
    <XAxis dataKey="name" />
    <YAxis />
    <Tooltip
      labelFormatter={labelFormatter}
      formatter={toolTipFormatter}
    />
    <Legend />
    <ReferenceLine y={0} stroke={colors.base.beta} />
    {keys.map((key, index) => (
      <Bar
        key={key?.name || key}
        dataKey={key?.name || key}
        stackId="stack"
        fill={key?.color || colors.chartColors[index % colors.chartColors.length]}
      />
    ))}
  </ReBarChart>
);

BarChart.propTypes = {
  data: PropTypes.array.isRequired,
  keys: PropTypes.arrayOf(PropTypes.object).isRequired,
  labelFormatter: PropTypes.func,
  toolTipFormatter: PropTypes.func,
  width: PropTypes.number,
  height: PropTypes.number,
};

BarChart.defaultProps = {
  labelFormatter: null,
  toolTipFormatter: null,
  width: 500,
  height: 300,
};

export default BarChart;
