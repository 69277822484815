import styled from 'styled-components';
import colors from '../../../../styles/colors';

const FeaturesListWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 15px;
`;

const LabeledList = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 40px;
`;

const FeaturesTitle = styled.div`
  color: ${({ $color }) => $color || colors.base.beta};
  text-transform: uppercase;
  font-weight: 400;
  font-size: 12px;
  margin-bottom: 5px;
`;

const List = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const Feature = styled.li`
  font-size: 13px;

  &:before {
    content: "${({ $adornment }) => ($adornment ? `${$adornment} ` : '•')}";
  }
`;

export {
  FeaturesListWrapper,
  LabeledList,
  FeaturesTitle,
  List,
  Feature,
};
