import * as Yup from 'yup';

import { DurationType } from '../utils';
import { TimeUnit, convertSeconds } from '../../../../../utils/time';
import FormField from './formFields';
import texts from './texts';

const initialValues = (exercise) => ({
  [FormField.NOTE]: exercise.note || '',
  [FormField.SIDE]: exercise.side || '',
  [FormField.SET]: exercise.rounds || 1,
  [FormField.SET_TIME]: convertSeconds(exercise.duration, exercise.durationDisplayUnit) || '',
  [FormField.REST_TIME]: convertSeconds(exercise.restTime, exercise.restTimeDisplayUnit) || '',
  [FormField.REPETITION]: exercise.repetitions || '',
  [FormField.SET_TIME_UNIT]: exercise.durationDisplayUnit || TimeUnit.SECONDS,
  [FormField.REST_TIME_UNIT]: exercise.restTimeDisplayUnit || TimeUnit.SECONDS,
});

const validationSchema = (selectedDuration) => Yup.object().shape({
  [FormField.NOTE]: Yup.string().notRequired(),
  [FormField.SET_TIME_UNIT]: Yup.string().required(texts.validation.requiredField),
  [FormField.REST_TIME_UNIT]: Yup.string().required(texts.validation.requiredField),
  /*
  * Repetition field validation is done if selected duration type is 'REPETITIONS'
  * because repetition field appears only if selected duration type is 'REPETITIONS'
  */
  [FormField.REPETITION]: Yup.number()
    .test('repetition-validation', texts.validation.requiredField, (value) => {
      if (selectedDuration === DurationType.REPETITIONS) {
        return value !== undefined;
      }
      return true;
    })
    .test('repetition-positive-validation', texts.validation.positiveNumber, (value) => {
      if (selectedDuration === DurationType.REPETITIONS) {
        return value && value > 0;
      }
      return true;
    })
    .test('repetition-integer-validation', texts.validation.integerNumber, (value) => {
      if (selectedDuration === DurationType.REPETITIONS) {
        return Number.isInteger(value);
      }
      return true;
    }),
  /*
  * Set time field validation is done if selected duration type is 'TIMED'
  * because set time field appears only if selected duration type is 'TIMED'
  */
  [FormField.SET_TIME]: Yup.number()
    .test('set-time-validation', texts.validation.requiredField, (value) => {
      if (selectedDuration === DurationType.TIMED) {
        return value !== undefined;
      }
      return true;
    })
    .test('set-time-positive-validation', texts.validation.positiveNumber, (value) => {
      if (selectedDuration === DurationType.TIMED) {
        return value && value > 0;
      }
      return true;
    })
    .test('repetition-integer-validation', texts.validation.integerNumber, (value) => {
      if (selectedDuration === DurationType.TIMED) {
        return Number.isInteger(value);
      }
      return true;
    }),
  [FormField.REST_TIME]: Yup.number()
    .integer(texts.validation.integerNumber)
    .positive(texts.validation.positiveNumber)
    .notRequired(texts.validation.requiredField),
  [FormField.SET]: Yup.number()
    .integer(texts.validation.integerNumber)
    .positive(texts.validation.positiveNumber)
    .required(texts.validation.requiredField),
});

export {
  initialValues,
  validationSchema,
};
