import { Collection } from 'firestorter';

import Collections from '../utils/collections';
import { firestorePaths } from '../utils/paths';
import BaseDocument from './BaseDocument';

/**
 * Level of difficulty associated with a program.
 */
const DifficultyLevel = {
  BEGINNER: 'BEGINNER',
  INTERMEDIATE: 'INTERMEDIATE',
  ADVANCED: 'ADVANCED',
};

/**
 * Class representing a program.
 *
 * @class Program
 * @extends BaseDocument
 */
class Program extends BaseDocument {
  /**
   * Creates an instance of a program.
   * @param {string} id - The ID of the program document.
   * @param {Object} opts - Optional parameters for initializing the document.
   */
  constructor(id, opts) {
    super(`${Collections.PROGRAM}/${id}`, opts);
  }

  /**
   * Get name of the program.
   * @return {string}
   */
  get name() {
    return this.data.name;
  }

  /**
   * Get the array of workout references (ids) assigned in the program.
   * @return {Array}
   */
  get workouts() {
    return this.data.workouts || [];
  }

  /**
   * Get the creation timestamp of the program.
   * The value is stored as milliseconds since the Unix epoch.
   * @return {number}
   */
  get createdAt() {
    return this.data.createdAt;
  }

  /**
   * Get the equipment list associated with the program.
   * @return {Array}
   */
  get equipment() {
    return this.data.equipment || [];
  }

  /**
   * Get the difficutly level of the program.
   * @return {string}
   */
  get difficulty() {
    return this.data.difficulty;
  }

  /**
   * Get the archival status of the program.
   * @return {boolean}
   */
  get isArchived() {
    return !!this.data.isArchived;
  }

  /**
   * Archive the program.
   * @return {Promise<void>}
   */
  async archive() {
    return this.updateFields({
      isArchived: true,
      archivedAt: new Date(),
    });
  }

  /**
   * Unarchive the program.
   * @return {Promise<void>}
   */
  async unarchive() {
    return this.updateFields({
      isArchived: false,
    });
  }

  /**
   * Get a program by it's id.
   *
   * @param {string} programId - The program id.
   * @return {Promise<Program|void>} The program document if it exists, or else null.
   */
  static async getProgramById(programId) {
    const programDoc = new Program(programId);
    await programDoc.init();
    return programDoc.exists ? programDoc : null;
  }

  /**
   * Get the programs associated with a coach.
   *
   * @param {string} coachId - The coach id.
   * @param {boolean} fetchArchivedPrograms - Whether to fetch archived programs.
   * @return {Promise<Collection>} The collection of programs.
   */
  static async getProgramsByCoach(coachId, fetchArchivedPrograms) {
    const programCollection = new Collection(firestorePaths.PROGRAM, {
      query: (ref) => {
        let query = ref.where('coach', '==', coachId);
        if (!fetchArchivedPrograms) {
          query = query.where('isArchived', '==', false);
        }
        return query;
      },
      createDocument: ({ id }, options) => new Program(id, options),
    });
    await Program.initCollection(programCollection);
    return programCollection;
  }

  /**
   * Adds a program document to the firestore db.
   *
   * @param {object} data - Data of the new program.
   * @return {Promise<Program>} Resolves with the created program.
   */
  static async addDoc(data) {
    const programCollection = new Collection(Collections.PROGRAM);
    const doc = await programCollection.add(data);
    const programDoc = new Program(doc.id);
    await programDoc.init();
    return programDoc;
  }
}

export default Program;
export {
  DifficultyLevel,
};
