import moment from 'moment';
import momentDurationFormat from 'moment-duration-format';

momentDurationFormat(moment);

const TimeUnit = {
  SECONDS: 'SECONDS',
  MINUTES: 'MINUTES',
};

const SECONDS_PER_MINUTE = 60;

const TimeDurationFormat = {
  DEFAULT: 'mm’ss”',
  CLOCK_FORMAT: 'hh:mm:ss',
  LONG_FORMAT: 'd [days], h [hours], m [minutes]',
  SHORT_FORMAT: 'h[h] m[m] s[s]',
};

/**
 * This simple function adds zeros to the left, if needed. We prepend two zeros to the number that's passed as param
 * and then we take the last two digits.
 *
 * @example
 *   digits = 7;
 *   padded string = '007';
 *   result: '07';
 *
 * @param {number} digits The digits to add the zeros to.
 * @returns {string} The padded string
 */
const leftZeroPadding = (digits) => `00${digits}`.slice(-2);

/**
 * Parse a time value expressed in milliseconds and convert it to a time string in the following format: hh:mm:ss.
 * The hours portion will not be returned if the converted time is less or equal to 59:59.
 *
 * @param {number} timeInMillis The time in milliseconds
 * @returns {string} The converted time
 */
const parseTimeInMillis = (timeInMillis) => {
  const duration = moment.duration(timeInMillis);
  const seconds = duration.seconds();
  const minutes = duration.minutes();
  const hours = duration.hours();

  let timeString = `${leftZeroPadding(minutes)}:${leftZeroPadding(seconds)}`;

  if (hours) {
    timeString = `${leftZeroPadding(hours)}:${timeString}`;
  }

  return timeString;
};

const formatDuration = (duration, formatType = TimeDurationFormat.DEFAULT) => (
  moment.duration(duration, 'seconds').format(formatType)
);

/**
 * Converts a seconds time duration to a given unit type
 *
 * @param {number} timeInSeconds - Time duration in seconds
 * @param {string} unit - Time unit ('SECONDS' or 'MINUTES')
 * @returns {number} The time converted to the desired unit type
 */
const convertSeconds = (timeInSeconds = 0, unit = TimeUnit.SECONDS) => (
  unit === TimeUnit.MINUTES ? timeInSeconds / SECONDS_PER_MINUTE : timeInSeconds
);

/**
 * Converts a time value to seconds based on unit type
 * @param {number} time - Time duration in seconds
 * @param {string} unit - Time unit ('SECONDS' or 'MINUTES')
 * @returns {number} The time converted to seconds
 */
const getSecondsFrom = (time = 0, unit = TimeUnit.SECONDS) => (
  unit === TimeUnit.MINUTES ? time * SECONDS_PER_MINUTE : time
);

export {
  TimeUnit,
  leftZeroPadding,
  parseTimeInMillis,
  formatDuration,
  convertSeconds,
  TimeDurationFormat,
  SECONDS_PER_MINUTE,
  getSecondsFrom,
};
