import * as Yup from 'yup';

import { ExerciseType } from '../../../../Model/Exercise';

import fieldName from './formFields';
import texts from './texts';

const initialValues = {
  [fieldName.NAME]: '',
  [fieldName.DESCRIPTION]: '',
  [fieldName.TYPE]: ExerciseType.CUSTOM,
  [fieldName.VIDEO_URL]: '',
  [fieldName.VIDEO_PREVIEW_URL]: '',
  [fieldName.VIDEO_PREVIEW_THUMBNAIL]: '',
  [fieldName.TAGS]: [],
  [fieldName.CURRENT]: true,
};

const validationSchema = Yup.object().shape({
  [fieldName.NAME]: Yup.string().required(texts.validation.requiredField),
  [fieldName.DESCRIPTION]: Yup.string().required(texts.validation.requiredField),
});

export {
  initialValues,
  validationSchema,
};
