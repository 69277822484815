const fieldName = {
  COACH: 'coach',
  COACH_ID: 'coachId',
  NAME: 'formName',
  FIELDS: 'fields',
  HIDDEN_FIELDS: 'hiddenFields',
  END_SCREEN_CONTENT: 'endScreenContent',
};

const questionFieldName = {
  TYPE: 'type',
  ID: 'id',
  CUSTOM_CLASS: 'customClass',
  LABEL: 'label',
  PLACEHOLDER: 'placeholder',
  REQUIRED: 'required',
  FIELDS: 'fields',
  ALLOW_MULTIPLE: 'isMultiple',
  OPTIONS: 'options',
  VALUE: 'value',
};

const endScreenContentFieldName = {
  LINE_1: 'line1',
  LINE_2: 'line2',
};

export default fieldName;
export {
  questionFieldName,
  endScreenContentFieldName,
};
