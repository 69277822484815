import { RefreshInterval } from '../../../../../Model/MealPlanAssignment';

const texts = {
  header: 'Refresh Interval',
  headerText: 'When do you want to refresh a client meal plan?',
  save: 'Save',
  saved: 'Changes have been saved',
  intervals: {
    [RefreshInterval.WEEKLY]: 'Every Week',
    [RefreshInterval.BIWEEKLY]: 'Every 2 Weeks',
    [RefreshInterval.MONTHLY]: 'Every Month',
    [RefreshInterval.QUARTERLY]: 'Every 3 Months',
    [RefreshInterval.NEVER]: 'Never',
  },
  recommended: '- Recommended',
};

export default texts;
