import styled, { css } from 'styled-components';

import { Typography } from '@mui/material';
import { FieldArray } from 'formik';

import MultiSelectFilter from '../../../../../components/MultiSelectFilter';
import FormikInput from '../../../../../../components/v2/FormikInput';

import {
  StyledFormRow,
  Label,
} from '../../styles';

import colors from '../../../../../../styles/colors';

import configs from './configs';

const IngredientsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const StyledFieldArray = styled(FieldArray)`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  align-items: center;
  justify-content: center;
`;

const IngredientContainer = styled(StyledFormRow)`
  padding: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  border: 1px solid ${colors.shades.secondary7};

  &:first-of-type {
    margin-top: 10px;
  }
`;

const ButtonContainer = styled(StyledFormRow)`
  padding: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  border: 1px solid ${colors.shades.secondary7};
  margin-top: 10px;
  width: 60%;
  align-items: center;
`;

const FieldsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
  justify-content: center;
`;

const AllergensContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const AllergensSubContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  flex-wrap: wrap;
  align-items: center;
`;

const AllergenTagContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 10px;
  width: 70%;
`;

const IngredientHeader = styled(Typography)`
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: -0.01em;
  color: ${colors.shades.secondary8};
  margin-left: 5px;
`;
const StyledTypography = styled(Typography)`
  font-weight: 400;
`;

const AllergensLabel = styled(Label)`
  color: ${colors.shades.danger1};
`;

const StyledFilter = styled(MultiSelectFilter)`
  &.${configs.classNames.filter} {
    width: 25%;
    
    .MuiInputBase-root {
      height: 40px;
      .MuiOutlinedInput-notchedOutline {
        border-color: ${colors.shades.secondary7};
      }
      &.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: ${colors.shades.danger5};
      }
      &:hover .MuiOutlinedInput-notchedOutline {
        border-color: ${colors.shades.secondary6};
      }
    }
    .MuiSelect-select {
      box-sizing: border-box;
      background-color: transparent;
    }
  }
`;
const IngredientLine = styled(Typography)`
  font-weight: bold;
  font-size: 16px;
`;

const commonFormInputStyles = css`
  > p {
    font-size: 8px;
  }
  margin: 0;
`;

const SmallFormInput = styled(FormikInput)`
  width: 12%;
  ${commonFormInputStyles};
`;

const BigFormInput = styled(FormikInput)`
  width: 24%;
  ${commonFormInputStyles};
`;

export {
  IngredientsContainer,
  StyledFieldArray,
  IngredientContainer,
  ButtonContainer,
  IngredientHeader,
  StyledTypography,
  AllergensLabel,
  StyledFilter,
  SmallFormInput,
  BigFormInput,
  FieldsContainer,
  AllergensContainer,
  AllergensSubContainer,
  AllergenTagContainer,
  HeaderContainer,
  IngredientLine,
};
