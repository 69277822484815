import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { compose } from 'recompose';

import LoadingOverlay from '../LoadingOverlay/LoadingOverlay';
import ColoredHeader from '../ColoredHeader';
import PageContent from '../PageContent';
import Table from '../Table';
import DownloadCSVButton from '../DownloadCSVButton';
import {
  Container,
  Title,
  Description,
  TitleRow,
  FilterRow,
} from './styles';

const TableWithFilters = ({
  title,
  description,
  rows,
  columns,
  columnVisibilityModel,
  pageSize,
  filters,
  isLoading,
  csvHeaders,
  csvFileName,
}) => (
  <Container>
    <ColoredHeader>
      <TitleRow>
        <div>
          <Title>{title}</Title>
          {!!description && <Description>{description}</Description>}
        </div>
        <DownloadCSVButton
          disabled={!(csvHeaders && csvFileName)}
          rows={rows}
          csvHeaders={csvHeaders}
          fileName={csvFileName}
        />
      </TitleRow>
      <FilterRow>
        {filters}
      </FilterRow>
    </ColoredHeader>
    <PageContent>
      <Table
        rows={rows}
        columns={columns}
        columnVisibilityModel={columnVisibilityModel}
        pageSize={pageSize}
      />
    </PageContent>
    <LoadingOverlay isLoading={isLoading} />
  </Container>
);

TableWithFilters.propTypes = {
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  columnVisibilityModel: PropTypes.object,
  pageSize: PropTypes.number,
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  filters: PropTypes.array,
  isLoading: PropTypes.bool,
  csvHeaders: PropTypes.array,
  csvFileName: PropTypes.string,
};

TableWithFilters.defaultProps = {
  description: '',
  pageSize: 25,
  filters: [],
  isLoading: false,
  csvHeaders: [],
  csvFileName: '',
  columnVisibilityModel: {},
};

export default compose(
  observer,
)(TableWithFilters);
