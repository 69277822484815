import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import { ReactComponent as SearchRefractionIcon } from '../../../assets/icons/search-refraction-icon.svg';
import colors from '../../../styles/colors';

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 12px;
  gap: 16px;
`;

const StyledTextField = styled(TextField)`
  margin-right: auto;
  min-width: 250px;
  margin-left: 10px;
  
  .MuiInput-root {
    &::before,
    &::after {
      bottom: -2px;
      border-bottom: 1px solid ${colors.shades.secondary7};
    }
    &:hover::before,
    &:hover::after {
      border-bottom: 2px solid ${colors.shades.secondary7};
    }
    
    .MuiInput-input {
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0;
      padding: 11px 4px;
      
      &::placeholder {
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0;
      }
    }
  }
`;

const StyledSearchRefractionIcon = styled(SearchRefractionIcon)`
  margin: 4px;
`;

export {
  Container,
  StyledTextField,
  StyledSearchRefractionIcon,
};
