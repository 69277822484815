import styled from 'styled-components';
import { Button } from '@mui/material';

import { ReactComponent as BackIcon } from '../../../assets/icons/arrow-circle-left.svg';
import colors from '../../../styles/colors';

const StyledBackIcon = styled(BackIcon)`
  width: 24px;
  height: 24px;
  > path {
    stroke: ${colors.shades.gamma10};
  }
`;

const StyledBackButton = styled(Button)`
  color: ${colors.shades.gamma10};
  height: fit-content;
  text-transform: none;
  margin-left: auto;

  &:hover {
    background: unset;
  }
  &.MuiButton-text {
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
  }
`;

export {
  StyledBackIcon,
  StyledBackButton,
};
