import React from 'react';
import PropTypes from 'prop-types';
import format from 'string-template';

import {
  Header,
  Title,
  RowContainer,
  StyledContainer,
  StyledColumnHeader,
  StyledPopper,
  StyledTypography,
} from './styles';
import texts from './texts.json';

const PopperId = 'meal-times-popper';

const MealTimesPopper = ({
  anchorEl,
  mealTimesSummary,
}) => (
  <StyledPopper
    id={PopperId}
    open={!!anchorEl}
    anchorEl={anchorEl}
    placement="right-start"
  >
    <StyledContainer>
      <Header>
        <Title>
          {texts.title}
        </Title>
        <StyledTypography>
          {texts.description}
        </StyledTypography>
      </Header>
      <RowContainer>
        {Object.values(texts.columns).map((column) => (
          <StyledColumnHeader key={`popper-column-${column}`}>
            {column}
          </StyledColumnHeader>
        ))}
      </RowContainer>
      {mealTimesSummary.map(({
        name,
        caloricSplit,
        numberOfRecipes,
      }) => (
        <RowContainer key={`popper-row-${name}`}>
          <StyledTypography $bold>
            {name}
          </StyledTypography>
          <StyledTypography>
            {format(texts.caloricSplit, {
              caloricSplit,
            })}
          </StyledTypography>
          <StyledTypography>
            {format(texts.recipes, {
              meals: numberOfRecipes || 0,
            })}
          </StyledTypography>
        </RowContainer>
      ))}
    </StyledContainer>
  </StyledPopper>
);

MealTimesPopper.propTypes = {
  anchorEl: PropTypes.object,
  mealTimesSummary: PropTypes.array,
};

MealTimesPopper.defaultProps = {
  anchorEl: null,
  mealTimesSummary: [],
};

export { PopperId };
export default MealTimesPopper;
