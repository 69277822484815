import styled from 'styled-components';
import { Typography } from '@mui/material';

const Text = styled(Typography)`
  margin-bottom: 5px;
`;

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const TitleContainer = styled.div`
  margin-bottom: 60px;
`;

const FilterOptionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Title = styled(Text)`
  font-weight: bold;
  font-size: 24px;
`;

export {
  Container,
  FilterOptionsContainer,
  Title,
  TitleContainer,
};
