import styled, { css } from 'styled-components';

import { Button } from '@mui/material';

import { ReactComponent as CrossIcon } from '../../../../assets/icons/cross-contained-icon.svg';
import { fontFamily } from '../../../../styles/text';
import colors from '../../../../styles/colors';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const FiltersDetails = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 5px;
  background: ${colors.shades.primary7};
  border: 1px solid ${colors.shades.primary8};
  margin-left: -1px;
  margin-right: -1px;
  padding: 5px;
`;

const textStyles = css`
  font-family: ${fontFamily.sansSerif};
  font-size: 12px;
  align-self: center;
`;

const DetailsText = styled.div`
  ${textStyles};
  color: ${colors.shades.beta};
  > span {
    font-weight: bold;
  }
`;

const iconButtonStyles = css`
  min-width: 16px;
  width: 16px;
`;

const StyledButton = styled(Button)`
  ${iconButtonStyles}
  margin-left: 5px;
`;

const StyledTag = styled.div`
  display: flex;
  padding: 0 10px;
  color: ${colors.base.beta};
  background-color: ${colors.base.primary};
  border: 1px solid ${colors.shades.primary2};
  border-radius: 15px;
  text-transform: none;
  height: 30px;

  &:hover {
    background-color: ${colors.base.primary};
  }
`;

const StyledCrossIcon = styled(CrossIcon)`
  ${iconButtonStyles}
  transform: rotate(45deg);
  path {
    stroke: ${colors.base.beta};
  }
`;

export {
  Container,
  FiltersDetails,
  DetailsText,
  StyledButton,
  StyledTag,
  StyledCrossIcon,
};
