import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { compose } from 'recompose';

import ExternalCoachContext, { withExternalCoachContextReady } from '../../../../../context/ExternalCoachContext';
import {
  Card,
  Container,
  HeaderContainer,
  SubTitle,
  Title,
  TitleContainer,
} from '../../../../../../components/v2/Card';
import StripeConnect from './StripeConnect';
import StripeInfo from './StripeInfo';
import texts from './texts.json';

const StripeConfiguration = () => {
  const {
    externalCoachDoc: {
      stripeAccountId,
    },
  } = useContext(ExternalCoachContext);

  return (
    <Card>
      <HeaderContainer>
        <TitleContainer>
          <Title>{texts.title}</Title>
          <SubTitle>{texts.description}</SubTitle>
        </TitleContainer>
      </HeaderContainer>
      <Container>
        {stripeAccountId ? <StripeInfo stripeAccountId={stripeAccountId} /> : <StripeConnect />}
      </Container>
    </Card>
  );
};

export default compose(
  withExternalCoachContextReady,
  observer,
)(StripeConfiguration);
