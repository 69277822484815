import React, {
  useEffect,
  useState,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';

import DialogRoundedModal from '../../../../../../components/DialogRoundedModal';
import { SaveButton } from '../../../../../../components/Button/ActionButtons';
import { getMealTimesStats } from '../../../../../utils/meals';
import {
  ErrorTag,
  InfoTag,
  SuccessTag,
  TransparentTag,
} from '../../../../../../components/Tags';

import DraggableSortedList from './components/DraggableSortedList';

import {
  AnalysisContainer,
  AlertContainer,
  AlertTypography,
  AlertIcon,
  ContentWrapper,
  ErrorsContainer,
  StyledMealPlateIcon,
} from './styles';
import texts from './texts.json';

const EditMealTimesModal = ({
  isOpen,
  onClose,
  mealPlanMealTimes,
  setMealPlanMealTimes,
}) => {
  const [mealTimes, setMealTimes] = useState([]);

  /*
    Get total % of calories allocated.
    Also check for errors, if any meal time has 0% calories allocated, or a meal time is missing a name.
    Meal times cannot have duplicated names.
  */
  const {
    caloriesAllocated,
    noAllocationError,
    noNameError,
    duplicatedNamesError,
  } = useMemo(() => getMealTimesStats(mealTimes), [
    mealTimes,
  ]);

  const caloriesAllocatedError = caloriesAllocated !== 100;

  const hasErrors = useMemo(() => (
    caloriesAllocatedError || noAllocationError || noNameError || duplicatedNamesError
  ), [
    caloriesAllocatedError,
    noAllocationError,
    noNameError,
    duplicatedNamesError,
  ]);

  const handleSave = () => {
    setMealPlanMealTimes(mealTimes);
    onClose();
  };

  useEffect(() => {
    if (isOpen) {
      setMealTimes(mealPlanMealTimes.map(({ name, caloricSplit, meals }) => ({ name, caloricSplit, meals })));
    }
  }, [
    isOpen,
    mealPlanMealTimes,
  ]);

  const renderError = (errorText) => (
    <AlertContainer>
      <AlertIcon />
      <AlertTypography>{errorText}</AlertTypography>
    </AlertContainer>
  );

  const AnalysisTag = caloriesAllocatedError ? ErrorTag : SuccessTag;
  const actionButtons = (
    <>
      <SaveButton onClick={handleSave} disabled={hasErrors}>
        {texts.buttons.save}
      </SaveButton>
      <AnalysisContainer>
        <AnalysisTag>
          {`${caloriesAllocated}${texts.caloriesAllocated}`}
        </AnalysisTag>
        <TransparentTag>{texts.in}</TransparentTag>
        <InfoTag>{`${mealTimes.length} ${texts.mealCategories}`}</InfoTag>
      </AnalysisContainer>
    </>
  );

  return (
    <DialogRoundedModal
      open={isOpen}
      onClose={onClose}
      title={texts.title}
      description={texts.description}
      actionButtons={actionButtons}
      IconComponent={<StyledMealPlateIcon />}
    >
      <ContentWrapper>
        <DraggableSortedList mealTimes={mealTimes} setMealTimes={setMealTimes} isModalView />
      </ContentWrapper>
      {hasErrors && (
        <ErrorsContainer>
          {caloriesAllocatedError && renderError(texts.totalCaloriesAlert)}
          {noAllocationError && renderError(texts.noAllocationAlert)}
          {noNameError && renderError(texts.noNameAlert)}
          {(duplicatedNamesError && !noNameError) && renderError(texts.duplicatedNames)}
        </ErrorsContainer>
      )}
    </DialogRoundedModal>
  );
};

EditMealTimesModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  mealPlanMealTimes: PropTypes.array.isRequired,
  setMealPlanMealTimes: PropTypes.func.isRequired,
};

export default EditMealTimesModal;
