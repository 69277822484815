import {
  Alert,
  Autocomplete,
  TextField,
} from '@mui/material';
import styled from 'styled-components';

import colors from '../../../../styles/colors';

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const FilterRow = styled.div`
  display: flex;
  div:not(:last-child) {
    margin-right: 30px;
  }
  margin: 20px 20px;
  justify-content: space-between;
`;

const PageContent = styled.div`
  flex: 1;
  margin: 10px;
  z-index: 1;
`;

const StyledAlert = styled(Alert)`
  margin: 20px;
  width: fit-content;
`;

const AutoCompleteWrapper = styled.div`
  width: 300px;
`;

const StyledAutoComplete = styled(Autocomplete)`
  .MuiAutocomplete-inputRoot {
    height: 56px;
  }
`;

const StyledTextField = styled(TextField)`
  background: ${colors.base.alpha};
`;

export {
  Container,
  FilterRow,
  PageContent,
  StyledAlert,
  StyledAutoComplete,
  AutoCompleteWrapper,
  StyledTextField,
};
