import React, {
  useState,
  useEffect,
  useMemo,
  useContext,
} from 'react';
import { observer } from 'mobx-react';
import { compose } from 'recompose';

import CoachSelect from '../../../components/Leads/components/CoachSelect';
import InternalAssignmentsContext, {
  withInternalAssignmentsContextProvider,
  withInternalAssignmentsContextReady,
} from '../../../context/InternalAssignmentsContext';
import { AnalyticGroups } from '../../../../utils/analytics';
import { AssignmentRole } from '../../../../utils/internalAssignments';
import CoachesListContext from '../../../context/CoachesListContext';
import MonthSelect from '../../../components/MonthSelect';
import GroupSelect from '../components/GroupSelect';
import UserSelect from '../components/UserSelect';
import MonthlyAnalytics from '../components/MonthlyAnalytics';
import NMonthsAnalytics from '../components/NMonthsAnalytics';
import { StyledAlert } from '../components/AnalyticsGroup/styles';
import {
  Header,
  Container,
  FilterRow,
  PageContent,
} from './styles';
import texts from './texts.json';

const CoachAnalytics = () => {
  const [selectedGroup, setSelectedGroup] = useState(AnalyticGroups.COACH);
  const [selectedCoaches, setSelectedCoaches] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);

  const {
    internalAssignmentsCol: { docs: internalAssignments },
    crxUsers,
    isUsers,
    acUsers,
  } = useContext(InternalAssignmentsContext);
  const { coachesCollection } = useContext(CoachesListContext);

  const userList = useMemo(() => {
    if (selectedGroup === AnalyticGroups.CRX) {
      return crxUsers;
    }
    if (selectedGroup === AnalyticGroups.INSIDE_SALES) {
      return isUsers;
    }
    if (selectedGroup === AnalyticGroups.ASSISTANT_COACH) {
      return acUsers;
    }
    return [];
  }, [
    selectedGroup,
    crxUsers,
    isUsers,
    acUsers,
  ]);

  // set selected coaches(based on internal assignments) when selected user changes
  useEffect(() => {
    if (selectedGroup && selectedGroup !== AnalyticGroups.COACH && selectedUser) {
      let assignmentRole;
      switch (selectedGroup) {
        case AnalyticGroups.CRX:
          assignmentRole = AssignmentRole.CRX;
          break;
        case AnalyticGroups.INSIDE_SALES:
          assignmentRole = AssignmentRole.INSIDE_SALES;
          break;
        case AnalyticGroups.ASSISTANT_COACH:
          assignmentRole = AssignmentRole.ASSISTANT_COACH;
          break;
        default:
          break;
      }
      const coaches = internalAssignments.filter((assignment) => assignment.role === assignmentRole
        && assignment.userId === selectedUser.id
        // filter inactive coaches
        && coachesCollection.docs.find((coach) => coach.id === assignment.coach))
        .map((assignment) => (
          {
            id: assignment.coach,
            name: coachesCollection.docs.find((coach) => coach.id === assignment.coach)?.name,
          }
        ));
      setSelectedCoaches(coaches);
    }
  }, [
    selectedGroup,
    internalAssignments,
    crxUsers,
    isUsers,
    acUsers,
    selectedUser,
    coachesCollection,
  ]);

  useEffect(() => {
    setSelectedUser(null);
    setSelectedCoaches([]);
  }, [selectedGroup]);

  const errMsg = useMemo(() => {
    if (!selectedGroup) {
      return texts.errors.noGroupSelected;
    }
    if (selectedGroup === AnalyticGroups.COACH && !selectedCoaches.length) {
      return texts.errors.noCoachSelected;
    }
    if (selectedGroup !== AnalyticGroups.COACH && !selectedUser) {
      return texts.errors.noUserSelected;
    }
    if (!selectedMonth) {
      return texts.errors.noMonthSelected;
    }
    return '';
  }, [selectedGroup, selectedCoaches, selectedUser, selectedMonth]);

  return (
    <Container>
      <Header>
        <FilterRow>
          <GroupSelect onChange={setSelectedGroup} selectedGroup={selectedGroup} />
          {selectedGroup === AnalyticGroups.COACH && (
            <CoachSelect
              onChange={(coach) => setSelectedCoaches([{ ...coach, name: coach.label }])}
              loadNewCoachDataOnChange={false}
            />
          )}
          {!!selectedGroup && selectedGroup !== AnalyticGroups.COACH && (
            <UserSelect
              selectedUser={selectedUser}
              userList={userList}
              onChange={setSelectedUser}
            />
          )}
          <MonthSelect onChange={setSelectedMonth} />
        </FilterRow>
      </Header>
      <PageContent>

        {!!errMsg && (
          <StyledAlert severity="warning">
            {errMsg}
          </StyledAlert>
        )}
        <MonthlyAnalytics
          selectedCoaches={selectedCoaches}
          selectedMonth={selectedMonth}
          selectedGroup={selectedGroup}
          selectedUser={selectedUser}
        />
        {selectedGroup === AnalyticGroups.COACH && selectedCoaches.length > 0
          && <NMonthsAnalytics selectedCoach={selectedCoaches[0]} />}
      </PageContent>
    </Container>
  );
};

export default compose(
  withInternalAssignmentsContextProvider,
  withInternalAssignmentsContextReady,
  observer,
)(CoachAnalytics);
