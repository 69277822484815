import React from 'react';

import Sidebar from '../components/Sidebar';
import useSupportSideBarConfig from './useSupportSidebarConfig';

const SupportSidebar = () => {
  const { entries } = useSupportSideBarConfig();
  return (
    <Sidebar entries={entries} />
  );
};

export default SupportSidebar;
