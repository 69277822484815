import { Collection } from 'firestorter';
import BaseDocument from '../../Model/BaseDocument';
import Collections from '../../utils/collections';

const TagType = {
  CLIENT: 'client',
};

class Tag extends BaseDocument {
  constructor(id, opts) {
    super(`${Collections.TAG}/${id}`, opts);
  }

  get type() {
    return this.data.type;
  }

  get tag() {
    return this.data.tag;
  }

  get isS2tag() {
    return !!this.data.isS2tag;
  }

  get coach() {
    return this.data.coach || '';
  }

  get removed() {
    return !!this.data.removed;
  }

  static async addDoc(data) {
    const TagsCollection = new Collection(Collections.TAG);
    const { id } = await TagsCollection.add(data);
    return id;
  }

  static async getClientBaseTags() {
    const TagsCollection = new Collection(Collections.TAG, {
      createDocument: ({ id }, opts) => new Tag(id, opts),
      query: (ref) => ref
        .where('isS2tag', '==', true)
        .where('type', '==', TagType.CLIENT),
    });
    await TagsCollection.fetch();
    return TagsCollection;
  }

  static async getClientCustomTagsforCoach(coachId) {
    const TagsCollection = new Collection(Collections.TAG, {
      createDocument: ({ id }, opts) => new Tag(id, opts),
      query: (ref) => ref
        .where('coach', '==', coachId)
        .where('isS2tag', '==', false)
        .where('type', '==', TagType.CLIENT),
    });
    await TagsCollection.fetch();
    return TagsCollection;
  }

  async setRemoved(removed) {
    return this.updateFields({
      removed,
      removedAt: new Date(),
    });
  }
}

export default Tag;
export {
  TagType,
};
