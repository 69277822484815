import React from 'react';
import PropTypes, { string } from 'prop-types';

import { IconButton } from '@mui/material';
import { Container as DraggableContainer, Draggable } from 'react-smooth-dnd';

import { ReactComponent as DragIcon } from '../../../../../../assets/icons/v2/drag.svg';
import { fieldName } from '../../formFields';
import {
  Label,
  StyledTrashIcon,
  StyledAddIcon,
} from '../../styles';

import {
  Container,
  StyledInput,
  StyledFieldArray,
  InstructionsContainer,
  ButtonContainer,
  InstructionsHeader,
  RowWrapper,
} from './styles';
import texts from './texts';

const InstructionsForm = ({
  instructions,
}) => (
  <Container>
    <Label>{`${texts.title}:`}</Label>
    <StyledFieldArray
      name={fieldName.INSTRUCTIONS}
      render={({
        remove,
        push,
        move,
      }) => (
        <>
          <DraggableContainer
            dragHandleSelector=".drag-handle"
            lockAxis="y"
            onDrop={({ removedIndex, addedIndex }) => move(removedIndex, addedIndex)}
          >
            {instructions.map((_instruction, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Draggable key={index}>
                <InstructionsContainer>
                  <RowWrapper>
                    <IconButton className="drag-handle">
                      <DragIcon />
                    </IconButton>
                    <StyledInput
                      name={`${fieldName.INSTRUCTIONS}.${index}`}
                      multiline
                    />
                    <IconButton onClick={() => remove(index)}>
                      <StyledTrashIcon />
                    </IconButton>
                  </RowWrapper>
                </InstructionsContainer>
              </Draggable>
            ))}
          </DraggableContainer>
          <ButtonContainer>
            <IconButton onClick={() => push('')}>
              <StyledAddIcon />
            </IconButton>
            <InstructionsHeader>{texts.addInstruction}</InstructionsHeader>
          </ButtonContainer>
        </>
      )}
    />
  </Container>
);

InstructionsForm.propTypes = {
  instructions: PropTypes.arrayOf(string).isRequired,
};

export default InstructionsForm;
