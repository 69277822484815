import styled from 'styled-components';
import { Chip } from '@mui/material';

import colors from '../../../styles/colors';

const StyledChip = styled(Chip)`
  background: ${colors.base.primary};
  width: fit-content;
  margin: 5px;
  padding: 5px 10px;
`;

export default StyledChip;
