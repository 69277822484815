import { TagType } from '../../../../Model/Tag';

const getClientTagInitialValues = (coachId) => {
  const initialTag = {
    tag: '',
    type: TagType.CLIENT,
    coach: coachId,
    isS2tag: false,
  };
  return initialTag;
};

export {
  getClientTagInitialValues,
};
