import styled from 'styled-components';
import { Typography } from '@mui/material';

import Button from '../../../../../../../components/Button';
import { fontFamily } from '../../../../../../../styles/text';

const StatsWrapper = styled.div`
  margin-top: 30px;
`;

const Stat = styled.div`
  display: flex;
`;

const Text = styled(Typography)`
  font-family: ${fontFamily.sansSerif};
  font-size: 16px;
`;

const Label = styled(Text)`
  font-weight: 600;
  margin-right: 15px;
`;

const StyledButton = styled(Button)`
  width: 390px;
  height: 45px;
  margin-top: 30px;
  font-size: 17px;
`;

export {
  StatsWrapper,
  Stat,
  Label,
  Text,
  StyledButton,
};
