import styled from 'styled-components';
import { TableCell, TableContainer } from '@mui/material';

const MealsAchievement = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const StyledTableCell = styled(TableCell)`
  font-size: 12px;
  padding: 12px;
`;

const StyledTableContainer = styled(TableContainer)`
  margin: 0 8px;
`;

export {
  MealsAchievement,
  StyledTableCell,
  StyledTableContainer,
};
