import styled from 'styled-components';
import {
  IonSegmentButton,
  IonLabel,
  IonSegment,
} from '@ionic/react';

import { ReactComponent as SortIcon } from '../../../../../assets/icons/sort-icon.svg';
import Button from '../../../../../components/Button';
import colors from '../../../../../styles/colors';

const Profile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 0 0 auto;
`;

const AccountInfo = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 30px;
  color: ${colors.base.alpha};
  padding-left: 15px;
`;

const StyledSegment = styled(IonSegment)`
  margin: 15px 0 6px;
  height: 38px;
  border-radius: 5px;
  --background: ${colors.base.secondary3};
`;

const StyledSegmentButton = styled(IonSegmentButton)`
  margin: 4px;
  text-align: center;
  border-radius: 5px;
  --background: ${colors.base.secondary3};
  --background-checked: ${colors.base.secondary2};
  --indicator-color: ${colors.base.secondary2};
  --color-checked: ${colors.base.alpha};
  --color: ${colors.base.beta};
`;

const StyledSegmentLabel = styled(IonLabel)`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
`;

const EmptyListPlaceholder = styled.div`
  width: 100%;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 30px;
  color: ${colors.base.beta};
  text-align: center;
  padding: 20px;
`;

const MultipleChannelViewContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const MultipleChannelListContainer = styled.div`
  width: 30%
`;

const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledSortButton = styled(Button)`
  width: max-content;
  height: 28px;
  margin: 8px 0 4px;
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  padding: 8px 12px;
  border-radius: 30px;
  background-color: ${colors.base.primary};
  cursor: pointer;
`;

const StyledSortIcon = styled(SortIcon)`
  width: 12px;
  height: 12px;
  margin-right: 6px;
`;

export {
  Profile,
  AccountInfo,
  StyledSegment,
  EmptyListPlaceholder,
  MultipleChannelViewContainer,
  MultipleChannelListContainer,
  StyledContainer,
  StyledSegmentButton,
  StyledSegmentLabel,
  StyledSortButton,
  StyledSortIcon,
};
