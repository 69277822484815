import React, {
  useEffect,
  useState,
  useMemo,
  useContext,
} from 'react';
import PropTypes from 'prop-types';

import ExternalCoach from '../../Model/ExternalCoach';
import useComponentMounted from '../../hooks/useComponentMounted';

import OnboardingFlowConfigContext from '../OnboardingFlowConfigContext';
import ExternalCoachContext, { initialValues } from './ExternalCoachContext';

const ExternalCoachContextProvider = ({
  children,
}) => {
  const { externalCoach } = useContext(OnboardingFlowConfigContext);

  const [externalCoachDoc, setExternalCoachDoc] = useState(initialValues.externalCoachDoc);
  const [isReady, setIsReady] = useState(initialValues.isReady);
  const isComponentMountedRef = useComponentMounted();

  useEffect(() => {
    const init = async () => {
      const externalCoachDocument = new ExternalCoach(externalCoach);
      await externalCoachDocument.init();

      if (isComponentMountedRef.current) {
        setExternalCoachDoc(externalCoachDocument);
        setIsReady(true);
      }
    };

    if (!isReady) {
      init();
    }
  }, [
    isReady,
    isComponentMountedRef,
    externalCoach,
  ]);

  const contextValue = useMemo(() => ({
    isReady,
    externalCoachDoc,
  }), [
    isReady,
    externalCoachDoc,
  ]);

  return (
    <ExternalCoachContext.Provider value={contextValue}>
      {children}
    </ExternalCoachContext.Provider>
  );
};

ExternalCoachContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ExternalCoachContextProvider;
