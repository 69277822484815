import React from 'react';
import PropTypes from 'prop-types';
import {
  PieChart as RePieChart,
  Tooltip,
  Legend,
  Pie,
  Cell,
} from 'recharts';
import colors from '../../../../../styles/colors';

const PieChart = ({
  data,
}) => (
  <RePieChart
    width={500}
    height={300}
    stackOffset="sign"
  >
    <Pie
      data={data}
      innerRadius={50}
      outerRadius={80}
      dataKey="value"
    >
      {data.map((entry, index) => (
        <Cell key={`cell-${index.toString()}`} fill={colors.chartColors[index % colors.chartColors.length]} />
      ))}
    </Pie>
    <Legend />
    <Tooltip />
  </RePieChart>
);

PieChart.propTypes = {
  data: PropTypes.array.isRequired,
};

export default PieChart;
