import styled, { css } from 'styled-components';

import {
  Select,
  MenuItem,
} from '@mui/material';

import colors from '../../../../../styles/colors';

const menuStyles = {
  '.MuiMenu-list': {
    padding: 0,
  },
};

const selectStyles = css`
  box-sizing: border-box;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: ${colors.base.secondary2};
`;

const StyledSelect = styled(Select)`
  .MuiSelect-select {
    ${selectStyles};
    height: 40px;
    background: ${colors.base.alpha};
    border: 1px solid ${colors.shades.secondary6};
    padding: 12px 15px;
  }
  .MuiOutlinedInput-notchedOutline {
    border: unset;
  }
  min-width: 265px;
`;

const StyledMenuItem = styled(MenuItem)`
  ${selectStyles};
  padding: 10px 15px;
  background: ${colors.shades.secondary5};
  border-radius: unset;
`;

export {
  StyledSelect,
  StyledMenuItem,
  menuStyles,
};
