import React, {
  useMemo,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import GrowsurfContext from './GrowsurfContext';

const GROWSURF_AWAITING_LIMIT = 1000 * 10;

const GrowsurfContextProvider = ({
  children,
}) => {
  const [isGrowsurfLoadingReady, setIsGrowsurfLoadingReady] = useState(false);
  const [injectScriptTimer, setInjectScriptTimer] = useState(null);
  const [referrerId, setReferrerId] = useState(null);
  const [isReady, setIsReady] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);

  /*
    Check if Growsurf is loaded. If it's not, wait for its ready event
  */
  useEffect(() => {
    let shouldUpdate = true;

    const handleGrowSurfReady = () => {
      window.removeEventListener('grsfReady', handleGrowSurfReady, false);
      if (shouldUpdate) {
        setIsGrowsurfLoadingReady(true);
      }
    };

    if (!window.growsurf) {
      window.addEventListener('grsfReady', handleGrowSurfReady);
    } else {
      handleGrowSurfReady();
    }

    return () => {
      shouldUpdate = false;
    };
  }, []);

  /*
    Do not wait Growsurf script forever.
    Timeout if Growsurf doesn't load.
  */
  useEffect(() => {
    let shouldUpdate = true;
    let timer;

    if (!isGrowsurfLoadingReady) {
      const scriptInjectionTimer = setTimeout(() => {
        if (shouldUpdate) {
          setIsGrowsurfLoadingReady(true);
        }
      }, GROWSURF_AWAITING_LIMIT);
      setInjectScriptTimer(scriptInjectionTimer);
    } else if (injectScriptTimer) {
      clearTimeout(injectScriptTimer);
    }

    return () => {
      shouldUpdate = false;
      clearTimeout(timer);
    };
  /*
    We don't want to depend on injectScriptTimer as it's being set explicitly
    as part of this useEffect too.
  */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isGrowsurfLoadingReady,
  ]);

  /*
    Load referrerId once growsurf is ready
  */
  useEffect(() => {
    let shouldUpdate = true;
    const onGrowsurfLoadingReady = async () => {
      if (window.growsurf) {
        const referrerIdValue = window.growsurf.getReferrerId();
        if (referrerIdValue) {
          // Check that the referrer code matches an existing participant in the campaign
          const participant = await window.growsurf.getParticipantById(referrerIdValue);
          if (participant && shouldUpdate) {
            setReferrerId(referrerIdValue);
          }
        }
        setIsInitialized(true);
      }
      setIsReady(true);
    };

    if (isGrowsurfLoadingReady) {
      onGrowsurfLoadingReady();
    }
    return () => {
      shouldUpdate = false;
    };
  }, [
    isGrowsurfLoadingReady,
  ]);

  const contextValue = useMemo(() => ({
    isReady,
    referrerId,
    isInitialized,
  }), [
    isReady,
    referrerId,
    isInitialized,
  ]);

  return (
    <GrowsurfContext.Provider value={contextValue}>
      {children}
    </GrowsurfContext.Provider>
  );
};

GrowsurfContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default GrowsurfContextProvider;
