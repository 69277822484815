import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  RadioGroup,
  Radio,
} from '@mui/material';

import {
  Container,
  StyledOption,
  StyledNotSelectedIcon,
  StyledSelectedIcon,
} from './styles';

const RadioButtonGroup = ({
  options,
  onOptionChange,
  selectedOption,
  fullWidth,
  isRow,
  checkedIcon: CheckedIcon,
  icon: Icon,
  className,
  enableReselection,
  disabled,
  showStyledOptions,
}) => (
  <Container fullWidth={fullWidth} className={className}>
    <FormControl>
      <RadioGroup
        row={isRow}
        value={selectedOption}
        onChange={enableReselection ? null : (event) => onOptionChange(event.target.value)}
      >
        {options.map((option) => (
          <StyledOption
            key={option.value}
            value={option.value}
            control={showStyledOptions ? <Radio checkedIcon={<CheckedIcon />} icon={<Icon />} /> : <Radio />}
            label={option.label}
            $isSelected={option.value === selectedOption}
            $showStyledOptions={showStyledOptions}
            onClick={enableReselection ? () => onOptionChange(option.value) : null}
            disabled={disabled}
          />
        ))}
      </RadioGroup>
    </FormControl>
  </Container>
);

RadioButtonGroup.propTypes = {
  options: PropTypes.array.isRequired,
  selectedOption: PropTypes.string.isRequired,
  onOptionChange: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
  isRow: PropTypes.bool,
  checkedIcon: PropTypes.elementType,
  icon: PropTypes.elementType,
  className: PropTypes.string,
  enableReselection: PropTypes.bool,
  disabled: PropTypes.bool,
  showStyledOptions: PropTypes.bool,
};

RadioButtonGroup.defaultProps = {
  fullWidth: true,
  isRow: true,
  checkedIcon: StyledSelectedIcon,
  icon: StyledNotSelectedIcon,
  className: '',
  enableReselection: false,
  disabled: false,
  showStyledOptions: true,
};

export default RadioButtonGroup;
