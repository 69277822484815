import styled from 'styled-components';

const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ChartTitle = styled.div`
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  margin-left: 12px;
`;

const TablesWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 20px;
  row-gap: 40px;
  padding: 12px;
  margin-top: 34px;
  margin-bottom: 20px;
`;

export {
  ChartContainer,
  ChartTitle,
  TablesWrapper,
};
