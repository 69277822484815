import posthog from 'posthog-js';
import config from '../config';

const isAnalyticsAvailable = !!window.analytics;
const isIdentifyAvailable = isAnalyticsAvailable && typeof window.analytics.identify === 'function';

const {
  posthog: { apiHost, apiKey },
} = config;

posthog.init(apiKey, { api_host: apiHost });

// Standard traits as defined: https://segment.com/docs/connections/spec/identify/#traits
const traits = {
  EMAIL: 'email',
  GENDER: 'gender',
};

const userTraits = {};

const addTrait = (trait, value) => {
  if (isIdentifyAvailable) {
    window.analytics.identify({
      [trait]: value,
    });
    posthog.setPersonProperties({
      [trait]: value,
    });
    /*
      We keep track of user traits separately so that we report them as extra properties on events sending too.
      This is to overcome the limitation of Segment not sending the user traits to FB Pixel unless the app itself
      gets refreshed. So we basically use both ways of reporting traits.
    */
    userTraits[trait] = value;
  }
};

export {
  traits,
  addTrait,
  userTraits,
};
