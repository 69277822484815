const Features = {
  CHAT: 'CHAT',
  WORKOUT_VIDEO_FEEDBACK: 'WORKOUT_VIDEO_FEEDBACK',
  CHECK_IN_NOTIFICATION: 'CHECK_IN_NOTIFICATION',
  NUTRITION_PLAN: 'NUTRITION_PLAN',
};

export {
  Features,
};
