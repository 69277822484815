import styled from 'styled-components';
import { DataGrid } from '@mui/x-data-grid';

const Container = styled.div`
  display: flex;
  width: 100%;
  min-height: 500px;
  height: 70vh;
`;

const DataGridContainer = styled.div`
  flex-basis: 100%;
`;

const StyledDataGrid = styled(DataGrid)`
  font-size: 12px;

  & .MuiDataGrid-columnSeparator {
    display: none;
  }

  & .MuiDataGrid-columnHeader {
    padding: 0;

    .MuiDataGrid-columnHeaderTitleContainer {
      padding: 0 10px;

      & .MuiDataGrid-columnHeaderTitle {
        font-weight: 600;
        padding: 0 8px;
      }
    }
  }

  & .MuiDataGrid-row {
    padding-left: 10px;
  }

  & .action-column {
    padding-left: 0;
  }
`;

export {
  Container,
  DataGridContainer,
  StyledDataGrid,
};
