import Collections from '../utils/collections';

import BaseDocument from './BaseDocument';

class UserClaims extends BaseDocument {
  constructor(id, opts) {
    super(`${Collections.USER_CLAIMS}/${id}`, opts);
  }

  get isAdmin() {
    return !!this.data.admin;
  }

  /**
 * Get userClaims by userId
 * @param {string} userId - The user id
 * @returns {object} - userClaims doc
 */
  static async getUserClaims(userId) {
    const userClaims = new UserClaims(userId);
    await userClaims.init();

    return userClaims;
  }
}

export default UserClaims;
