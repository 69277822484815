import styled from 'styled-components';

const Wrapper = styled.div`
  margin: 20px 24px;
  height: 85vh;
  .MuiInputBase-multiline {
    box-sizing: content-box;
  }
  .MuiDataGrid-root {
    max-height: calc(100% - 76px);
  }
  .smooth-dnd-container.vertical, .smooth-dnd-draggable-wrapper {
    overflow: unset;
  }
`;

export {
  Wrapper,
};
