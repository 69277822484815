const NavigationRouteType = {
  ADMIN: 'ADMIN',
  SUPPORT: 'SUPPORT',
  COACH: 'COACH',
  INSIDE_SALES: 'INSIDE_SALES',
  ASSISTANT_COACH: 'ASSISTANT_COACH',
};

export {
  NavigationRouteType,
};
