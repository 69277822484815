import React, {
  useEffect,
  useState,
  useMemo,
} from 'react';
import {
  FormControlLabel,
  RadioGroup,
} from '@mui/material';
import PropTypes from 'prop-types';

import { ReactComponent as WarningIcon } from '../../../../../../../../assets/icons/v2/exclamation-red.svg';
import {
  StyledTextField,
  StyledTextFieldContainer,
  StyledTextFieldLabel,
} from '../../../../../../../../components/Inputs';

import {
  AssignContainer,
  AssignTitle,
  MacrosContainer,
  OptionsContainer,
  StyledRadio,
  WarningContainer,
  AllocationContainer,
} from '../../styles';
import texts from './texts.json';

const radioBreakdownOptions = {
  MACRO: 'MACRO',
  CUSTOM: 'CUSTOM',
};

const AssignByCalories = ({
  updateNutritionGoals,
  clientNutritionGoals,
  mealPlanMacroAverages,
  currentMacroAverages,
}) => {
  // If no meal plan macros are available, allow only custom breakdowns
  const filteredBreakdownOptions = useMemo(() => (
    mealPlanMacroAverages?.percentages ? {
      ...radioBreakdownOptions,
    } : {
      CUSTOM: radioBreakdownOptions.CUSTOM,
    }
  ), [
    mealPlanMacroAverages,
  ]);

  const [selectedBreakdownOption, setSelectedBreakdownOption] = useState(
    filteredBreakdownOptions?.[radioBreakdownOptions.MACRO] || radioBreakdownOptions.CUSTOM,
  );

  const macroInputDisabled = selectedBreakdownOption === radioBreakdownOptions.MACRO;

  // When the selected option changes we will update the calories and macro percentages to match current values
  useEffect(() => {
    let updatedPercentages = {};
    // If the MACRO option is selected we will update the modal to contain recommended nutrients
    if (selectedBreakdownOption === radioBreakdownOptions.MACRO) {
      updatedPercentages = mealPlanMacroAverages?.percentages;
      // Else we will set the current values (either edited manual values and is now resetting, or else current goals)
    } else if (selectedBreakdownOption === radioBreakdownOptions.CUSTOM) {
      updatedPercentages = currentMacroAverages?.percentages;
    }
    updateNutritionGoals('protein', updatedPercentages.protein);
    updateNutritionGoals('carbs', updatedPercentages.carbs);
    updateNutritionGoals('fat', updatedPercentages.fat);
  }, [
    selectedBreakdownOption,
    updateNutritionGoals,
    mealPlanMacroAverages,
    currentMacroAverages,
  ]);

  const caloriesAllocation = useMemo(() => (
    clientNutritionGoals.protein + clientNutritionGoals.fat + clientNutritionGoals.carbs
  ), [clientNutritionGoals]);

  const allocationError = useMemo(() => (
    selectedBreakdownOption === radioBreakdownOptions.CUSTOM && caloriesAllocation !== 100
  ), [
    selectedBreakdownOption,
    caloriesAllocation,
  ]);

  return (
    <AssignContainer>
      <WarningContainer>
        <WarningIcon />
        {texts.macrosManualSetWarning}
      </WarningContainer>
      <AssignTitle>{texts.title}</AssignTitle>
      <StyledTextField
        type="number"
        value={clientNutritionGoals.calories}
        onChange={(evt) => updateNutritionGoals('calories', evt.target.value)}
        title={texts.kcal}
        $simple
      />
      <OptionsContainer>
        <RadioGroup
          value={selectedBreakdownOption}
          onChange={(evt) => setSelectedBreakdownOption(evt.target.value)}
        >
          {Object.keys(filteredBreakdownOptions).map((type) => (
            <FormControlLabel
              key={type}
              value={type}
              control={<StyledRadio />}
              label={texts.radioBreakdownOptions[type]}
            />
          ))}
        </RadioGroup>
      </OptionsContainer>
      <MacrosContainer>
        <StyledTextFieldContainer>
          <StyledTextFieldLabel>{texts.protein}</StyledTextFieldLabel>
          <StyledTextField
            disabled={macroInputDisabled}
            value={clientNutritionGoals?.protein}
            onChange={(evt) => updateNutritionGoals('protein', evt.target.value)}
            type="number"
            title="%"
            $simple
          />
        </StyledTextFieldContainer>
        <StyledTextFieldContainer>
          <StyledTextFieldLabel>{texts.carb}</StyledTextFieldLabel>
          <StyledTextField
            disabled={macroInputDisabled}
            value={clientNutritionGoals?.carbs}
            onChange={(evt) => updateNutritionGoals('carbs', evt.target.value)}
            type="number"
            title="%"
            $simple
          />
        </StyledTextFieldContainer>
        <StyledTextFieldContainer>
          <StyledTextFieldLabel>{texts.fat}</StyledTextFieldLabel>
          <StyledTextField
            disabled={macroInputDisabled}
            value={clientNutritionGoals?.fat}
            onChange={(evt) => updateNutritionGoals('fat', evt.target.value)}
            type="number"
            title="%"
            $simple
          />
        </StyledTextFieldContainer>
      </MacrosContainer>
      <AllocationContainer $isError={allocationError}>
        {`${caloriesAllocation}% ${texts.macrosAllocated}`}
      </AllocationContainer>
    </AssignContainer>
  );
};

AssignByCalories.propTypes = {
  updateNutritionGoals: PropTypes.func.isRequired,
  clientNutritionGoals: PropTypes.object.isRequired,
  mealPlanMacroAverages: PropTypes.object.isRequired,
  currentMacroAverages: PropTypes.object.isRequired,
};

export default AssignByCalories;
