import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { compose } from 'recompose';

import { AssignmentRole } from '../../../../utils/internalAssignments';
import { withPayoutReconciliationContextProvider } from '../../../context/PayoutReconciliationContext';
import { PayoutConfig } from '../../../utils/payoutReconciliation';
import Commissions from '../../../components/Commissions';

const CommissionsPage = () => {
  const {
    params: {
      userId: insideSalesId,
    },
  } = useRouteMatch();

  return (
    <Commissions
      userId={insideSalesId}
      payoutsConfig={PayoutConfig[AssignmentRole.INSIDE_SALES]}
    />
  );
};

export default compose(
  withPayoutReconciliationContextProvider,
)(CommissionsPage);
