import React, { useContext } from 'react';

import LoadingPage from '../../../components/LoadingPage';
import ExerciseTagContext from './ExerciseTagContext';
import ExerciseTagContextProvider from './ExerciseTagContextProvider';

const withExerciseTagContextProvider = (Component) => (props) => (
  <ExerciseTagContextProvider>
    <Component {...props} />
  </ExerciseTagContextProvider>
);

const withExerciseTagContextReady = (Component) => (props) => {
  const { isReady } = useContext(ExerciseTagContext);

  return isReady ? <Component {...props} /> : <LoadingPage />;
};

export {
  withExerciseTagContextProvider,
  withExerciseTagContextReady,
};
