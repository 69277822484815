import styled from 'styled-components';

import Chip from '../../../../../../../components/v2/Chip';
import colors from '../../../../../../../styles/colors';
import RoundedButton from '../../../../../../../components/v2/RoundedButton';
import { ReactComponent as ArchiveIcon } from '../../../../../../../assets/icons/v2/archive.svg';

const StyledChip = styled(Chip)`
  background-color: ${colors.shades.success3};
  color: ${colors.base.alpha};
  padding: unset;
  margin-left: 10px;
  height: 24px;

`;

const TitleRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledButton = styled(RoundedButton)`
  background-color: ${colors.shades.beta2};
  color: ${colors.base.alpha};
  height: 40px;
  min-width: 160px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  > * {
    margin-left: 10px;
  }
`;

const StyledArchiveIcon = styled(ArchiveIcon)`
  width: 16px;
`;

const ArchivedChip = styled(Chip)`
  background-color: ${colors.shades.gamma1};
  padding: unset;
  margin-left: 10px;
  height: 24px;
  font-weight: 400;
`;

export {
  StyledChip,
  TitleRow,
  StyledButton,
  ButtonContainer,
  StyledArchiveIcon,
  ArchivedChip,
};
