import React, { useContext } from 'react';

import QuickChatView from '../../components/QuickChatView';
import ChatContext from '../../../../context/ChatContext';
import ChatLoadingView from '../../components/ChatLoadingView';

import { Container } from './styles';

const QuickChatContainer = React.forwardRef((props, ref) => {
  const {
    isChatReady,
  } = useContext(ChatContext);

  return (
    <Container {...props} ref={ref}>
      {isChatReady ? <QuickChatView /> : <ChatLoadingView />}
    </Container>
  );
});

export default QuickChatContainer;
