import { TableCell, tableCellClasses, TableRow } from '@mui/material';
import styled from 'styled-components';

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const FilterRow = styled.div`
  display: flex;
  div:not(:last-child) {
    margin-right: 30px;
  }
  margin: 10px 0px;
`;

const PageContent = styled.div`
  flex: 1;
  margin: 0 30px 30px;
  z-index: 1;
`;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const TotalTableRow = styled(TableRow)`
  background-color: #a9a6a6;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  position: relative;
  &:after {
    content: "";
    width: 100%;
    height: 50px;
    position: absolute;
    top: 100%;
    left: 0;
  }
`;

export {
  Container,
  FilterRow,
  PageContent,
  StyledTableCell,
  StyledTableRow,
  TotalTableRow,
  Header,
};
