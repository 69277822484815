import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as CancelIcon } from '../../../assets/icons/v2/cross-cancel.svg';

import PrimaryButton from './PrimaryButton';

const CancelButton = ({
  onClick,
  children,
}) => (
  <PrimaryButton
    onClick={onClick}
    icon={<CancelIcon />}
    variant="dimmed"
  >
    {children}
  </PrimaryButton>
);

CancelButton.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
};

CancelButton.defaultProps = {
  children: [],
  onClick: null,
};

export default CancelButton;
