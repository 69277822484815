import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { observer } from 'mobx-react';

import Lead from '../../../../Model/Lead';
import PostPaymentFormAnswer from '../../../../Model/PostPaymentFormAnswer';
import FormAnswers from '../FormAnswers';

import texts from './texts.json';
import {
  Container,
} from './styles';

const FormResponseContainer = ({
  postPaymentFormDoc,
  leadDoc,
}) => (
  <Container>
    {!!leadDoc && (
      <FormAnswers
        formDoc={leadDoc}
        buttonText={texts.leadForm}
      />
    )}
    {!!postPaymentFormDoc && (
      <FormAnswers
        formDoc={postPaymentFormDoc}
        buttonText={texts.postPaymentForm}
      />
    )}
  </Container>
);

FormResponseContainer.propTypes = {
  postPaymentFormDoc: PropTypes.instanceOf(PostPaymentFormAnswer),
  leadDoc: PropTypes.instanceOf(Lead),
};

FormResponseContainer.defaultProps = {
  postPaymentFormDoc: null,
  leadDoc: null,
};

export default compose(
  observer,
)(FormResponseContainer);
