import moment from 'moment';
import format from 'string-template';

import { DateFormat } from '../../../utils/date';
import { getCurrencySymbol } from '../../../utils/formatters';
import texts from './termClauses.json';

const FEBRUARY_DAYS = 28;
const LAST_DAY = 31;

const ContractType = {
  CANCEL_AT_PERIOD_END: 'cancelAtPeriodEnd',
  WITH_REMAINING_PAYMENTS: 'withRemainingPayments',
  WITHOUT_REMAINING_PAYMENTS: 'withoutRemainingPayments',
  SEQUENTIAL: 'sequential',
  ADVANCE_PAYMENT: 'advancePayment',
};

const generateTerms = (contract) => {
  // Return an empty array if there is no contract to generate the terms for.
  if (!contract) {
    return [];
  }

  const {
    startDate,
    initialPayment,
    initialTerm,
    minSubscriptionMonths,
    monthlyPayment,
    currency,
    cancelAtPeriodEnd,
    recurringBillingMonths = 1,
    upfrontPayment = 0,
    version,
  } = contract;

  // Calculate the payment day copy
  const startDay = moment(startDate).date();
  let paymentDay;
  switch (startDay) {
    case LAST_DAY:
      paymentDay = texts.lastDay;
      break;
    default:
      paymentDay = startDate?.isValid() ? moment(startDate.toISOString()).format('Do') : '';
      break;
  }

  const exception = (startDay !== LAST_DAY && startDay > FEBRUARY_DAYS) ? texts.exception : '';

  // Calculate the remaining payments
  const remainingCommitment = (minSubscriptionMonths - initialTerm) / initialTerm;

  // Calculate the payment cycle
  const paymentCycle = recurringBillingMonths === 1
    ? texts.eachMonth
    : format(texts.everyXMonths, { recurringBillingMonths });

  // Subscription cycle after the commitment finished
  const subscriptionCycle = recurringBillingMonths === 1
    ? texts.monthly
    : format(texts.everyXMonths, { recurringBillingMonths });

  // Calculate the initial payment
  const upfrontPaymentValue = parseFloat(upfrontPayment);
  const initialPaymentValue = parseFloat(initialPayment);
  const computedInitialPaymentValue = upfrontPaymentValue > 0
    ? initialPaymentValue - upfrontPaymentValue
    : initialPaymentValue;

  const templateData = {
    startDate: startDate?.isValid() ? startDate.format(DateFormat.CONTRACT_DATE_FORMAT) : '',
    advancePaymentDate: moment().format(DateFormat.CONTRACT_DATE_FORMAT),
    currency: getCurrencySymbol(currency),
    initialPayment: computedInitialPaymentValue.toFixed(2),
    initialTerm,
    monthlyPayment,
    minSubscriptionMonths,
    remainingCommitment,
    nextPaymentDate: startDate?.isValid()
      ? startDate?.clone().add(initialTerm, 'months').format(DateFormat.CONTRACT_DATE_FORMAT) : '',
    paymentDay,
    exception,
    paymentCycle,
    subscriptionCycle,
    endOfCommitmentDate: startDate?.isValid()
      ? startDate.clone().add(minSubscriptionMonths, 'months').format(DateFormat.CONTRACT_DATE_FORMAT) : '',
    upfrontPayment,
  };

  let clausesKey;
  if (version === 1) {
    if (cancelAtPeriodEnd) {
      clausesKey = ContractType.CANCEL_AT_PERIOD_END;
    } else if (Number(initialTerm) === Number(minSubscriptionMonths)) {
      clausesKey = ContractType.WITHOUT_REMAINING_PAYMENTS;
    } else {
      clausesKey = ContractType.WITH_REMAINING_PAYMENTS;
    }
  } else if (version === 2) {
    /*
      If we collect an upfront payment, we should use the advance payment clauses. For all other options, we should
      use the sequential clauses, which have a PIF period and a monthly payment period.
    */
    clausesKey = upfrontPaymentValue > 0 ? ContractType.ADVANCE_PAYMENT : ContractType.SEQUENTIAL;
  }

  // Get the version key to get the clauses copy.
  const versionKey = `version${version}`;

  return texts.contractClauses[versionKey][clausesKey].map((clause) => format(clause, templateData));
};

export {
  generateTerms,
};
