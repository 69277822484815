import React from 'react';

const customizationContextInitialState = {
  customHabitDoc: null,
  appCustomizationDoc: null,
  setCustomHabitDoc: () => {},
  customCheckInDoc: null,
  setCustomCheckInDoc: () => {},
};

const CustomizationContext = React.createContext(customizationContextInitialState);

export default CustomizationContext;
export {
  customizationContextInitialState,
};
