import React, { useContext } from 'react';
import format from 'string-template';

import {
  HeaderRow,
  TitleContainer,
  SubTitle,
  Title,
} from '../../../../components/v2/Header';
import UserContext from '../../../../context/UserContext';

import CoachTable from './components/CoachTable';
import {
  Container,
} from './styles';
import texts from './texts.json';

const CoachingDashboard = () => {
  const {
    userDoc: {
      firstName: ACName,
    },
  } = useContext(UserContext);

  return (
    <Container>
      <HeaderRow>
        <TitleContainer>
          <Title>{format(texts.title, { name: ACName })}</Title>
          <SubTitle>{texts.subTitle}</SubTitle>
        </TitleContainer>
      </HeaderRow>
      <CoachTable />
    </Container>
  );
};

export default CoachingDashboard;
