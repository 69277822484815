import styled from 'styled-components';

import Button from '../../../components/Button';
import colors from '../../../styles/colors';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'fit-content')};
`;

const StyledButton = styled(Button)`
  min-height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'fit-content')};
  padding: 0px 20px;
  border-radius: 0;
  font-size: 14px;
  color: ${({ isSelected }) => (isSelected ? colors.base.alpha : colors.base.beta)};
  background-color: ${({ isSelected }) => (isSelected ? colors.base.secondary2 : colors.shades.gamma3)};
  border: 1px solid ${colors.shades.gamma9};
  cursor: pointer;

  &:first-child {
    border-radius: 5px 0 0 5px;
    border-right-width: 0;
  }

  &:last-child {
    border-radius: 0 5px 5px 0;
    border-left-width: 0;
  }
`;

const CountBadge = styled.div`
  display: grid;
  place-items: center;
  height: 34px;
  min-height: 34px;
  width: 34px;
  min-width: 34px;
  border-radius: 50%;
  color: ${colors.base.beta};
  background-color: ${colors.base.primary};
  margin-right: 10px;
`;

export {
  Container,
  StyledButton,
  CountBadge,
};
