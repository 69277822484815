import styled from 'styled-components';
import colors from '../../../../../styles/colors';

const ChannelSearchWrapper = styled.div`
  .str-chat__channel-search {
    padding: 0;
    background-color: ${colors.base.support};
    flex-direction: column;

    &-container {
      position: relative;
      width: 100%;
      height: 100%;
      z-index: initial;
      padding: 0;
      top: initial;
    }
  }
`;

const SearchStateContainer = styled.div`
  width: 100%;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 30px;
  color: ${colors.base.beta};
  background: ${colors.base.alpha};
  text-align: center;
  padding: 20px;
`;

export {
  ChannelSearchWrapper,
  SearchStateContainer,
};
