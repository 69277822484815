import React, {
  useCallback,
  useEffect,
  useState,
  useContext,
} from 'react';
import PropTypes from 'prop-types';

import {
  Card,
  Container,
  HeaderContainer,
  Title,
  TitleContainer,
} from '../../../../../components/v2/Card';
import { StyledTextFieldContainer } from '../../../../../components/Inputs';
import { DisallowedGender, ISConfigOption } from '../../../../../Model/Coach';
import useComponentMounted from '../../../../../hooks/useComponentMounted';
import LoggedInUserContext from '../../../../../context/LoggedInUserContext';
import InputLabel from '../../../../../components/v2/InputLabel';
import { Row } from '../../../../../components/v2/Grid';
import SaveButton from '../../../../../components/Button/ActionButtons/SaveButton';
import { isInsideSalesAdmin } from '../../../../../utils/postHog';
import RadioButtonGroup from '../../../../components/RadioButtonGroup';
import LoadingOverlay from '../../../../components/LoadingOverlay';
import useToast from '../../../../hooks/useToast';
import LabelCheckbox from '../../../../components/LabelCheckbox';
import { SettingOption } from '../../utils';
import texts from './texts';

const DisqualificationSettings = ({ coachDoc }) => {
  const [disallowedGenders, setDisallowedGenders] = useState([]);
  const [isAutomatedEmailsEnabled, setIsAutomatedEmailsEnabled] = useState(SettingOption.DISABLED);
  const [
    isFinancialCommitmentDisqualificationEnabled,
    setIsFinancialCommitmentDisqualificationEnabled,
  ] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const { showToast } = useToast();
  const isComponentMountedRef = useComponentMounted();
  const { userDoc } = useContext(LoggedInUserContext);

  const isReadOnly = !isInsideSalesAdmin();

  const disallowedGenderOptions = Object.values(DisallowedGender).map((value) => ({
    value: texts.disallowedGender[value],
    label: texts.disallowedGender[value],
  }));

  useEffect(() => {
    setDisallowedGenders(coachDoc?.disallowedGenders || []);
    setIsFinancialCommitmentDisqualificationEnabled(!!coachDoc?.shouldDisqualifyByFinancialCommitment);
    setIsAutomatedEmailsEnabled(!!coachDoc?.isAutomaticEmailEnabled);
  }, [coachDoc]);

  const onSave = useCallback(async () => {
    // Check if email template is configured
    if (isAutomatedEmailsEnabled && (
      !coachDoc.contractLink
      || !coachDoc.disqualificationMessageSubject
      || !coachDoc.disqualificationMessageContent)) {
      showToast(texts.emailTemplateRequired, { error: true });
      return;
    }
    setIsSaving(true);
    await coachDoc.set({
      leadDisqualificationConfig: {
        [ISConfigOption.DISALLOWED_GENDERS]: disallowedGenders,
        [ISConfigOption.FINANCIAL_COMMITMENT_DISQUALIFICATION_ENABLED]: isFinancialCommitmentDisqualificationEnabled,
      },
      leadEmailConfig: {
        [ISConfigOption.IS_AUTOMATIC_DISQUALIFICATION_EMAIL_ENABLED]: isAutomatedEmailsEnabled,
      },
      lastUpdatedByUserName: userDoc.name,
    }, { merge: true });
    showToast(texts.saved, { type: 'success' });
    if (isComponentMountedRef.current) {
      setIsSaving(false);
    }
  }, [
    disallowedGenders,
    isFinancialCommitmentDisqualificationEnabled,
    isAutomatedEmailsEnabled,
    coachDoc,
    showToast,
    isComponentMountedRef,
    userDoc,
  ]);

  return (
    <Card>
      <HeaderContainer>
        <TitleContainer>
          <Title>{texts.title}</Title>
        </TitleContainer>
      </HeaderContainer>
      <Container>
        <StyledTextFieldContainer $withMargin>
          <InputLabel>{texts.disallowedGenders}</InputLabel>
          <Row $withoutMargin>
            {disallowedGenderOptions.map((option) => (
              <LabelCheckbox
                key={option.value}
                description={option.label}
                isChecked={disallowedGenders.includes(option.value)}
                onChange={(isChecked) => setDisallowedGenders((prevState) => (
                  isChecked
                    ? [...prevState, option.value]
                    : prevState.filter((value) => value !== option.value)
                ))}
                disabled={isReadOnly}
              />
            ))}
          </Row>
        </StyledTextFieldContainer>
        <StyledTextFieldContainer $withMargin>
          <InputLabel>{texts.financialDisqualification}</InputLabel>
          <RadioButtonGroup
            options={[
              { label: texts.enabled, value: SettingOption.ENABLED },
              { label: texts.disabled, value: SettingOption.DISABLED },
            ]}
            selectedOption={isFinancialCommitmentDisqualificationEnabled
              ? SettingOption.ENABLED
              : SettingOption.DISABLED}
            onOptionChange={(val) => setIsFinancialCommitmentDisqualificationEnabled(val === SettingOption.ENABLED)}
            disabled={isReadOnly}
          />
        </StyledTextFieldContainer>
        <StyledTextFieldContainer $withMargin>
          <InputLabel>{texts.automatedEmails}</InputLabel>
          <RadioButtonGroup
            options={[
              { label: texts.enabled, value: SettingOption.ENABLED },
              { label: texts.disabled, value: SettingOption.DISABLED },
            ]}
            selectedOption={isAutomatedEmailsEnabled ? SettingOption.ENABLED : SettingOption.DISABLED}
            onOptionChange={(val) => setIsAutomatedEmailsEnabled(val === SettingOption.ENABLED)}
            disabled={isReadOnly}
          />
        </StyledTextFieldContainer>
        <SaveButton
          onClick={onSave}
          disabled={isReadOnly}
          $fitToContent
        >
          {texts.save}
        </SaveButton>
      </Container>
      <LoadingOverlay isLoading={!coachDoc || isSaving} />
    </Card>
  );
};

DisqualificationSettings.propTypes = {
  coachDoc: PropTypes.object,
};

DisqualificationSettings.defaultProps = {
  coachDoc: null,
};
export default DisqualificationSettings;
