import styled from 'styled-components';
import { Autocomplete, TextField } from '@mui/material';

import colors from '../../../styles/colors';

const StyledAutoComplete = styled(Autocomplete)`
  min-width: 250px;
  .MuiAutocomplete-inputRoot {
    height: 56px;
    overflow: hidden;
  }
`;

const StyledTextField = styled(TextField)`
  background: ${colors.base.alpha};
`;

const PermissionsErrorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export {
  StyledAutoComplete,
  StyledTextField,
  PermissionsErrorContainer,
};
