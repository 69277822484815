import { endScreenContentFieldName as fieldName } from '../../formFields';

const texts = {
  endScreen: 'End Screen Content',
  labels: {
    [fieldName.LINE_1]: 'Line 1',
    [fieldName.LINE_2]: 'Line 2',
  },
};

export default texts;
