import { Alert, Card } from '@mui/material';
import styled from 'styled-components';

const Container = styled(Card)`
  margin: 20px;
`;

const StyledAlert = styled(Alert)`
  margin: 20px;
  width: fit-content;
`;

const Title = styled.div`
  font-size: 1.5rem;
  margin-bottom: 0.35em;
`;

export {
  Container,
  StyledAlert,
  Title,
};
