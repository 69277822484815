import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

import {
  Container,
  MainAnalytics,
  MainAnalyticsContainer,
} from './styles';

const AnalyticsTimeCard = ({
  title,
  subTitle,
}) => (
  <Container elevation={2}>
    <MainAnalyticsContainer>
      <MainAnalytics>
        <Typography variant="h6">
          {title}
        </Typography>
        <Typography variant="subtitle1">
          {subTitle}
        </Typography>
      </MainAnalytics>
    </MainAnalyticsContainer>
  </Container>
);

AnalyticsTimeCard.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  subTitle: PropTypes.string.isRequired,
};

export default AnalyticsTimeCard;
