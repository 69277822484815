import React from 'react';
import {
  IonItem,
  IonList,
} from '@ionic/react';
import PropTypes from 'prop-types';
import { ChannelBuckets, ChannelActions } from '../../../../context/ChatContext/buckets';
import {
  StyledArchiveIcon,
  StyledUnarchiveIcon,
  StyledHideIcon,
  StyledShowIcon,
} from './styles';
import texts from './texts';

const ChannelPreviewPopover = React.forwardRef(({
  selectedBucket,
  onArchive,
  onUnarchive,
  onHide,
  onShow,
}, ref) => (
  <IonList ref={ref}>
    {
      selectedBucket === ChannelBuckets.ACTIVE
      && (
        <IonItem button detail={false} onClick={onArchive}>
          <StyledArchiveIcon />
          {texts.actionsPopover[ChannelActions.ARCHIVE]}
        </IonItem>
      )
    }
    {
      selectedBucket === ChannelBuckets.ARCHIVED
      && (
        <IonItem button detail={false} onClick={onUnarchive}>
          <StyledUnarchiveIcon />
          {texts.actionsPopover[ChannelActions.UNARCHIVE]}
        </IonItem>
      )
    }
    {
      (selectedBucket === ChannelBuckets.ACTIVE || selectedBucket === ChannelBuckets.ARCHIVED)
      && (
        <IonItem button lines="none" detail={false} onClick={onHide}>
          <StyledHideIcon />
          {texts.actionsPopover[ChannelActions.HIDE]}
        </IonItem>
      )
    }
    {
      selectedBucket === ChannelBuckets.ALL
      && (
        <IonItem button lines="none" detail={false} onClick={onShow}>
          <StyledShowIcon />
          {texts.actionsPopover[ChannelActions.SHOW]}
        </IonItem>
      )
    }
  </IonList>
));

ChannelPreviewPopover.propTypes = {
  selectedBucket: PropTypes.string.isRequired,
  onArchive: PropTypes.func.isRequired,
  onUnarchive: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  onShow: PropTypes.func.isRequired,
};

export default ChannelPreviewPopover;
