import React, {
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import { DataGrid } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import format from 'string-template';
import { DeleteOutline, EmailOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';

import FirebaseContext from '../../../../../context/FirebaseContext';
import CoachOnboarding from '../../../../Model/CoachOnboarding';
import ConfirmDialog from '../../../../components/ConfirmDialog';
import useToast from '../../../../hooks/useToast';

import {
  ActionsContainer,
  DataGridContainer,
} from './styles';
import texts from './texts.json';

const OnboardingTable = ({
  rows,
}) => {
  const [leadToDelete, setLeadToDelete] = useState();
  const { showToast } = useToast();
  const { firebase } = useContext(FirebaseContext);

  const onResendCoachEmail = useCallback(async (coachEmail) => {
    await firebase.remote('coachAccountCreation/onboardingemail', {
      coachOnboardingId: rows.find(({ email }) => email === coachEmail).id,
    });
    showToast(format(texts.emailSentMessage, { email: coachEmail }));
  }, [
    firebase,
    rows,
    showToast,
  ]);

  // The actions cell is rendered differently, based on the status of the row.
  const renderActionCell = useCallback(
    ({
      row: {
        id,
        isOnboarded,
      } = {},
    }) => {
      const onRemove = () => setLeadToDelete(id);
      const onEmail = () => onResendCoachEmail(id);
      return (
        <ActionsContainer>
          <IconButton disabled={isOnboarded} onClick={onRemove}>
            <DeleteOutline />
          </IconButton>
          <IconButton disabled={isOnboarded} onClick={onEmail}>
            <EmailOutlined />
          </IconButton>
        </ActionsContainer>
      );
    },
    [onResendCoachEmail],
  );

  const columns = useMemo(() => [
    {
      field: 'name',
      headerName: texts.headers.name,
      flex: 15,
    },
    {
      field: 'email',
      headerName: texts.headers.email,
      flex: 20,
    },
    {
      field: 'stripeAccountId',
      headerName: texts.headers.stripeAccountId,
      type: 'boolean',
      flex: 15,
    },
    {
      field: 'avatarUrl',
      headerName: texts.headers.avatarUrl,
      type: 'boolean',
      flex: 10,
    },
    {
      field: 'isOnboarded',
      headerName: texts.headers.isOnboarded,
      type: 'boolean',
      flex: 10,
    },
    {
      field: 'status',
      headerName: texts.headers.status,
      flex: 15,
    },
    {
      field: 'coach',
      headerName: texts.headers.coach,
      flex: 20,
    },
    {
      field: 'createdAt',
      headerName: texts.headers.dateCreated,
      type: 'dateTime',
      flex: 10,
    },
    {
      field: 'actions',
      headerName: texts.headers.actions,
      flex: 10,
      renderCell: renderActionCell,
    },
  ], [renderActionCell]);

  const preparedRows = rows.map((row) => ({
    id: row.email,
    name: row.name,
    email: row.email,
    isOnboarded: row.isOnboarded,
    status: row.status,
    coach: row.coach,
    stripeAccountId: row.coachHasStripeAccountId,
    avatarUrl: row.coachHasAvatarUrl,
    createdAt: row.createdAt,
  }));

  const handleLeadDelete = useCallback(async () => {
    await CoachOnboarding.removeCoachOnboardingDoc(rows.find(({ email }) => email === leadToDelete).id);
    showToast(format(texts.leadDeleteMessage, { leadName: leadToDelete }));
    setLeadToDelete(null);
  }, [
    leadToDelete,
    rows,
    showToast,
  ]);

  return (
    <DataGridContainer>
      <DataGrid
        rows={preparedRows}
        columns={columns}
        pageSize={25}
        rowsPerPageOptions={[25]}
        initialState={{
          sorting: {
            sortModel: [{ field: 'createdAt', sort: 'desc' }],
          },
        }}
      />
      <ConfirmDialog
        isOpen={!!leadToDelete}
        onConfirm={handleLeadDelete}
        onCancel={() => setLeadToDelete(null)}
        dialogTexts={{
          title: texts.deleteConfirm,
          content: leadToDelete || '',
        }}
      />
    </DataGridContainer>
  );
};

OnboardingTable.propTypes = {
  rows: PropTypes.array.isRequired,
};

export default compose(
  observer,
)(OnboardingTable);
