import React from 'react';
import {
  Route,
  Redirect,
  Switch,
  useRouteMatch,
} from 'react-router-dom';

import CoachToolsRoute from '../ToolsRoute';
import routes from './routes';
import { getTabPath, WorkoutsManagerTab } from './workoutsManagerPages';

const WorkoutsManagerRoutes = () => {
  const { path, url } = useRouteMatch();

  return (
    <Switch>
      {routes.map(({
        pageId,
        workoutsManagerPath,
        component,
      }) => (
        <CoachToolsRoute
          key={pageId}
          pageId={pageId}
          path={`${path}/${workoutsManagerPath}`}
          component={component}
        />
      ))}

      <Route exact path={`${path}`}>
        <Redirect to={{ pathname: `${url}/${getTabPath(WorkoutsManagerTab.PROGRAMS)}` }} />
      </Route>
    </Switch>
  );
};

export default WorkoutsManagerRoutes;
