import React, {
  useCallback,
  useEffect,
  useState,
  useContext,
} from 'react';
import PropTypes from 'prop-types';

import {
  Card,
  Container,
  HeaderContainer,
  Title,
  TitleContainer,
} from '../../../../../components/v2/Card';
import { StyledTextField, StyledTextFieldContainer } from '../../../../../components/Inputs';
import SaveButton from '../../../../../components/Button/ActionButtons/SaveButton';
import { ISConfigOption } from '../../../../../Model/Coach';
import useComponentMounted from '../../../../../hooks/useComponentMounted';
import LoggedInUserContext from '../../../../../context/LoggedInUserContext';
import { isInsideSalesAdmin } from '../../../../../utils/postHog';
import MsgTemplate, { MsgTemplateName } from '../../../../../Model/MsgTemplate';
import RadioButtonGroup from '../../../../components/RadioButtonGroup';
import InputLabel from '../../../../../components/v2/InputLabel';
import LoadingOverlay from '../../../../components/LoadingOverlay';
import useToast from '../../../../hooks/useToast';
import { SettingOption } from '../../utils';
import texts from './texts.json';

const AutomatedMessages = ({ coachDoc }) => {
  const [calendlyLink, setCalendlyLink] = useState('');
  const [isAutomatedMsgsEnabled, setIsAutomatedMsgsEnabled] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const { showToast } = useToast();
  const isComponentMountedRef = useComponentMounted();
  const { userDoc } = useContext(LoggedInUserContext);
  const isReadOnly = !isInsideSalesAdmin();

  useEffect(() => {
    setCalendlyLink(coachDoc?.calendlyLink || '');
    setIsAutomatedMsgsEnabled(!!coachDoc?.isAutomaticMsgEnabled);
  }, [coachDoc]);

  const onSave = useCallback(async () => {
    if (isAutomatedMsgsEnabled === SettingOption.ENABLED) {
      if (!calendlyLink) {
        showToast(texts.calendlyLinkRequired, { error: true });
        return;
      }
      if (!calendlyLink.startsWith('https://calendly.com/')) {
        showToast(texts.calendlyLinkInvalid, { error: true });
        return;
      }
    }
    setIsSaving(true);
    // if automated msgs are enabled, we need to check if we have a 1st msg template
    if (isAutomatedMsgsEnabled) {
      const msgTemplates = await MsgTemplate.getTemplatesByCoach(coachDoc.id, MsgTemplateName.FIRST_MESSAGE);
      const firstMsgTemplateDoc = msgTemplates.docs.find((doc) => doc.current);
      if (!firstMsgTemplateDoc || !firstMsgTemplateDoc.isEnabled) {
        showToast(texts.firstMsgTemplateRequired, { error: true });
        if (isComponentMountedRef.current) {
          setIsSaving(false);
        }
        return;
      }
    }
    await coachDoc.set({
      [ISConfigOption.IS_AUTOMATIC_MSG_ENABLED]: isAutomatedMsgsEnabled,
      [ISConfigOption.CALENDLY_LINK]: calendlyLink,
      lastUpdatedByUserName: userDoc.name,
    }, { merge: true });
    showToast(texts.saved, { type: 'success' });
    if (isComponentMountedRef.current) {
      setIsSaving(false);
    }
  }, [
    calendlyLink,
    coachDoc,
    isAutomatedMsgsEnabled,
    isComponentMountedRef,
    showToast,
    userDoc,
  ]);

  return (
    <Card>
      <HeaderContainer>
        <TitleContainer>
          <Title>{texts.title}</Title>
        </TitleContainer>
      </HeaderContainer>
      <Container>
        <StyledTextFieldContainer $withMargin>
          <InputLabel>{texts.calendlyLink}</InputLabel>
          <StyledTextField
            placeholder={texts.calendlyLinkPlaceholder}
            value={calendlyLink || ''}
            onChange={(e) => setCalendlyLink(e.target.value)}
            disabled={isReadOnly}
          />
        </StyledTextFieldContainer>
        <StyledTextFieldContainer $withMargin>
          <InputLabel>{texts.automatedMsgs}</InputLabel>
          <RadioButtonGroup
            options={[
              {
                label: texts.enabled,
                value: SettingOption.ENABLED,
              },
              {
                label: texts.disabled,
                value: SettingOption.DISABLED,
              },
            ]}
            selectedOption={isAutomatedMsgsEnabled ? SettingOption.ENABLED : SettingOption.DISABLED}
            onOptionChange={(val) => setIsAutomatedMsgsEnabled(val === SettingOption.ENABLED)}
            disabled={isReadOnly}
          />
        </StyledTextFieldContainer>
        <SaveButton
          onClick={onSave}
          disabled={isReadOnly}
          $fitToContent
        >
          {texts.save}
        </SaveButton>
      </Container>
      <LoadingOverlay isLoading={!coachDoc || isSaving} />
    </Card>
  );
};

AutomatedMessages.propTypes = {
  coachDoc: PropTypes.object,
};

AutomatedMessages.defaultProps = {
  coachDoc: null,
};

export default AutomatedMessages;
