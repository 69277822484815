import { Collection } from 'firestorter';
import { firestorePaths } from '../utils/paths';
import BaseDocument from './BaseDocument';

const CoachReminderStatus = {
  CREATED: 'CREATED',
};
class CoachReminderNotification extends BaseDocument {
  get content() {
    return this.data.content;
  }

  get sendAt() {
    return this.data.sendAt;
  }

  async updateContentAndDate(content, sendAt) {
    await this.updateFields({
      content,
      sendAt,
    });
  }

  async delete() {
    await this.ref.delete();
  }

  static async create(reminderData) {
    const messageRequestCollection = new Collection(firestorePaths.COACH_REMINDER_NOTIFICATION);

    await messageRequestCollection.add({
      ...reminderData,
      status: CoachReminderStatus.CREATED,
      createdAt: new Date(),
    });
  }

  static async getScheduledReminders(coachId) {
    const queryResult = new Collection(firestorePaths.COACH_REMINDER_NOTIFICATION, {
      createDocument: (src, opts) => new CoachReminderNotification(src, opts),
      query: (ref) => ref
        .where('coach', '==', coachId)
        .where('status', '==', CoachReminderStatus.CREATED)
        .orderBy('sendAt', 'asc'),
    });
    await queryResult.fetch();
    return queryResult.docs;
  }
}

export default CoachReminderNotification;
