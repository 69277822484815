import React from 'react';

import Leads from '../../components/Leads';
import texts from './texts.json';

const LeadsPageContainer = () => (
  <Leads
    title={texts.title}
    description={texts.description}
  />
);

export default LeadsPageContainer;
