import React, { useContext } from 'react';

import LoadingPage from '../../../components/LoadingPage';
import WorkoutContext from './WorkoutContext';
import WorkoutContextProvider from './WorkoutContextProvider';

const withWorkoutContextProvider = (Component) => (props) => (
  <WorkoutContextProvider>
    <Component {...props} />
  </WorkoutContextProvider>
);

const withWorkoutContextReady = (Component) => (props) => {
  const { isReady } = useContext(WorkoutContext);

  return isReady ? <Component {...props} /> : <LoadingPage />;
};

export {
  withWorkoutContextProvider,
  withWorkoutContextReady,
};
