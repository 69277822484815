import styled from 'styled-components';
import { Typography } from '@mui/material';

import colors from '../../../../styles/colors';

import { LoadingButton } from '../../../../components/Button';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding: 20px;
`;

const WorkoutItem = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  align-items : center;
`;

const WorkoutItemTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Tag = styled.div`
  width: 100px;
  min-width: 100px;
  text-align: center;
  padding: 7px;
  font-weight: 500;
  font-size: 12px;
  border-radius: 10px;
  background: ${colors.shades.secondary7};
  border: 1px solid ${colors.shades.secondary4};
`;

const WorkoutText = styled(Typography)`
  font-size: 14px;
`;

const WorkoutTextComment = styled(Typography)`
  font-size: 13px;
`;

const LoadButton = styled(LoadingButton)`
  width: fit-content;
  margin-top: 10px;
  padding: 5px 12px;
  background: ${colors.shades.secondary4};
  border: 1px solid ${colors.shades.secondary6};
  color: ${colors.base.secondary2};
  border-radius: 73px;
  font-weight: 600;
  font-size: 12px;
  text-transform: none;

  &:hover {
    background-color: ${colors.shades.secondary6};
  }
`;

export {
  Container,
  WorkoutItem,
  WorkoutItemTextContainer,
  Tag,
  WorkoutText,
  WorkoutTextComment,
  LoadButton,
};
