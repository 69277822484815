import styled from 'styled-components';
import {
  Paper, Select,
} from '@mui/material';

const StyledPaper = styled(Paper)`
  padding: 16px;
  max-width: 700px;
`;

const Container = styled.div`
  margin: 10px 0;
`;

const StyledSelect = styled(Select)`
  margin: 0px 10px;
  height: 25px;
`;

export {
  StyledPaper,
  Container,
  StyledSelect,
};
