import styled from 'styled-components';
import { InsertEmoticonOutlined } from '@mui/icons-material';
import { Popper } from '@mui/material';
import colors from '../../../../../styles/colors';

const StyledEmojiIcon = styled(InsertEmoticonOutlined)`
  height: 24px;
  width: 24px;
  cursor: pointer;
  margin: 2px 4px;
  > path {
    fill: ${colors.base.secondary2};
  }
`;

const StyledPopper = styled(Popper)`
  z-index: 9999;
`;

export {
  StyledEmojiIcon,
  StyledPopper,
};
