import styled from 'styled-components';

import colors from '../../../styles/colors';
import Button from '../../Button';

const RoundedButton = styled(Button)`
  margin: 10px 0px;
  background-color: ${colors.shades.primary1};
  border-radius: 73px;
  width: fit-content;
  font-size: 15px;
  line-height: 18px;
  padding: 16px;
  font-weight: 400;
`;

export default RoundedButton;
