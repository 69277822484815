import styled from 'styled-components';

import colors from '../../../../../styles/colors';
import Input from '../../Input';

const Container = styled.div`
  margin: 0 auto;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 40px;
  background-color: ${colors.shades.gamma5};
  row-gap: 20px;
`;

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  width: 100%;
  column-gap: 20px;
`;

const FormField = styled.div`
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -0.01em;
`;

const StyledInput = styled(Input)`
  .MuiInputBase-root {
    padding-left: 14px;
    padding-right: 14px;
  }
`;

export {
  Container,
  HeaderContainer,
  HeaderRow,
  FormField,
  Title,
  StyledInput,
};
