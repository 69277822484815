import React, {
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { compose } from 'recompose';

import ManageClientContext, { withManageClientContextReady } from '../../context/ManageClientContext';

import UserTable from './UserTable';
import { Container } from './styles';

const UserTableContainer = ({
  className,
  selectedClients,
  setSelectedClients,
}) => {
  const {
    clientDataList,
    clientSortModel,
    setClientSortModel,
    clientFilters,
    setClientFilters,
    clientExcludingFilters,
    setClientExcludingFilters,
    clientTagsDocs,
    clientTagsFilters,
    setClientTagsFilters,
    loadingUsers,
    clientTablePaginationModel,
    setClientPaginationModel,
  } = useContext(ManageClientContext);

  return (
    <Container className={className}>
      <UserTable
        rows={clientDataList.slice()}
        clientSortModel={clientSortModel}
        setClientSortModel={setClientSortModel}
        clientStatusFilters={clientFilters}
        setClientStatusFilters={setClientFilters}
        clientExcludingFilters={clientExcludingFilters}
        setClientExcludingFilters={setClientExcludingFilters}
        selectedClients={selectedClients}
        setSelectedClients={setSelectedClients}
        clientTagsDocs={clientTagsDocs}
        clientTagsFilters={clientTagsFilters}
        setClientTagsFilters={setClientTagsFilters}
        loadingUsers={loadingUsers}
        clientTablePaginationModel={clientTablePaginationModel}
        setClientPaginationModel={setClientPaginationModel}
      />
    </Container>
  );
};

UserTableContainer.propTypes = {
  className: PropTypes.string,
  selectedClients: PropTypes.array.isRequired,
  setSelectedClients: PropTypes.func.isRequired,
};

UserTableContainer.defaultProps = {
  className: '',
};

export default compose(
  withManageClientContextReady,
  observer,
)(UserTableContainer);
