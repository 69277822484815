import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import { Channel } from 'stream-chat-react';
import * as Sentry from '@sentry/browser';

import useComponentMounted from '../../../../../hooks/useComponentMounted';
import ChatContext from '../../../../context/ChatContext';
import { ChannelBuckets } from '../../../../context/ChatContext/buckets';
import { channelConfiguration } from '../../util';

import ChatLoadingView from '../ChatLoadingView';
import ChatFeedView from '../ChatFeedView';
import { QuickChatFeedContainer } from '../ChatFeedContainer';
import CustomTriggerProvider from '../CustomTriggerProvider';

import texts from './texts.json';
import { ErrorMessageContainer } from './styles';

const SingleChannelView = () => {
  const {
    chatClient,
    userId,
    onMarkChannelAsRead,
    quickChatUser,
    isChatEnabledForUser,
    setChatModalOpen,
    isChatModalOpen,
  } = useContext(ChatContext);
  const [isLoadingContent, setLoadingContent] = useState(true);
  const [channel, setChannel] = useState(null);
  const [isChatEnabled, setIsChatEnabled] = useState(false);
  const isComponentMountedRef = useComponentMounted();

  useEffect(() => {
    const findChannel = async () => {
      setChannel(null);
      const featureEnabled = await isChatEnabledForUser(quickChatUser);
      setIsChatEnabled(featureEnabled);

      if (!featureEnabled) {
        setLoadingContent(false);
        return;
      }

      const channels = await chatClient.queryChannels(
        {
          ...channelConfiguration.filters(quickChatUser, ChannelBuckets.ALL),
          members: [quickChatUser, userId],
        },
        channelConfiguration.sort,
        channelConfiguration.options,
      );

      if (channels && channels.length > 0 && isComponentMountedRef.current) {
        const selectedChannel = channels[0];
        setChannel(selectedChannel);
        setChatModalOpen(false);
      }
      if (isComponentMountedRef.current) {
        setLoadingContent(false);
      }
    };
    if (isLoadingContent) {
      findChannel();
    }
  }, [
    isLoadingContent,
    quickChatUser,
    chatClient,
    isComponentMountedRef,
    userId,
    isChatEnabledForUser,
    setChatModalOpen,
    isChatModalOpen,
  ]);

  useEffect(() => {
    setLoadingContent(true);
  }, [quickChatUser]);

  if (isLoadingContent) {
    return <ChatLoadingView />;
  }

  if (!quickChatUser) {
    return null;
  }

  if (!isChatEnabled) {
    return <ErrorMessageContainer>{texts.chatNotEnabled}</ErrorMessageContainer>;
  }

  if (!channel) {
    Sentry.captureException(
      `The user ${quickChatUser} doesn't have a channel created for in-app messaging`,
      {
        extra: {
          quickChatUser,
          userId,
        },
      },
    );
    return <ErrorMessageContainer>{texts.noChatChannel}</ErrorMessageContainer>;
  }

  return (
    <QuickChatFeedContainer>
      <Channel
        maxNumberOfFiles={channelConfiguration.maxFileUploadLimit}
        multipleUploads
        channel={channel}
        doMarkReadRequest={onMarkChannelAsRead}
        TriggerProvider={CustomTriggerProvider}
      >
        <ChatFeedView />
      </Channel>
    </QuickChatFeedContainer>
  );
};

export default SingleChannelView;
