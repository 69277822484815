import { Collection } from 'firestorter';
import format from 'string-template';

import { firestorePaths, pathPlaceholder } from '../utils/paths';
import { getRequiredEquipment } from '../utils/workout';
import WorkoutDefinition from './WorkoutDefinition';
import BaseDocument from './BaseDocument';

/**
 * Visibility type of workouts
 * - **PUBLIC:** Workout that was visible to all coaches
 * - **PRIVATE:** The Workout, its' visible only to the coach, who owns the workout.
 */
const VisibilityType = {
  PRIVATE: 'PRIVATE',
  PUBLIC: 'PUBLIC',
};

/**
 * Class representing a workout.
 *
 * @class Workout
 * @extends BaseDocument
 */
class Workout extends BaseDocument {
  async init(initOpts) {
    await super.init(initOpts);
    this.workoutDefinition = new WorkoutDefinition(this.data);
  }

  /**
   * Gets the name.
   * @returns {string}
   */
  get name() {
    return this.data.name;
  }

  /**
   * Gets the estimated duration in seconds.
   * @returns {number}
   */
  get estimatedDuration() {
    return this.data.estimatedDuration;
  }

  /**
   * Gets the estimated duration in minutes.
   * @returns {number|void} Estimated duration in minutes or null if not available.
   */
  get estimatedDurationInMinutes() {
    return this.estimatedDuration
      ? Math.ceil(this.estimatedDuration / 60)
      : null;
  }

  /**
   * Gets the activities in the workout.
   * @returns {Array}
   */
  get activities() {
    return this.data.activities;
  }

  /**
   * Checks if the workout is archived.
   * @returns {boolean}
   */
  get isArchived() {
    return !!this.data.isArchived;
  }

  /**
   * Gets the creation timestamp. The value is stored as milliseconds since the Unix epoch.
   * @returns {number}
   */
  get createdAt() {
    return this.data.createdAt;
  }

  /**
   * Gets the visibility type. It will be one of {@link VisibilityType}.
   * @returns {string}
   */
  get visibility() {
    return this.data.visibility;
  }

  /**
   * Gets the required equipment as an object with the equipment id as key and the equipment tag as the value.
   * @returns {Object}
   */
  get requiredEquipment() {
    return getRequiredEquipment(this.activities);
  }

  /**
   * Gets the equipment names/tags as an array.
   * @returns {Array}
   */
  get equipmentList() {
    return Object.values(this.requiredEquipment);
  }

  /**
   * Adds a new workout.
   *
   * @param {Object} data - The data for the new workout.
   * @returns {Promise<string>} The id of the newly added workout.
   */
  static async addWorkout(data) {
    const newWorkout = new Collection(firestorePaths.WORKOUT);
    const { id } = await newWorkout.add(data);
    return id;
  }

  /**
   * Get workouts by coach.
   *
   * @param {string} coachId - The id of the coach.
   * @param {boolean} [fetchArchivedWorkouts=false] - Whether to fetch archived workouts or not.
   * @returns {Promise<Collection>} The collection of workouts of the coach.
   */
  static async getWorkoutsByCoach(coachId, fetchArchivedWorkouts = false) {
    const workoutCollection = new Collection(firestorePaths.WORKOUT,
      {
        query: (ref) => {
          let query = ref
            .where('coach', '==', coachId);
          if (!fetchArchivedWorkouts) {
            query = query.where('isArchived', '==', false);
          }
          return query;
        },
        createDocument: (source, options) => new Workout(source, options),
      });
    await Workout.initCollection(workoutCollection);
    return workoutCollection;
  }

  /**
   * Get all the public workouts.
   *
   * @returns {Promise<Collection>} The collection of public workouts.
   */
  static async getPublicWorkouts() {
    const workoutCollection = new Collection(firestorePaths.WORKOUT,
      {
        query: (ref) => ref
          .where('visibility', '==', VisibilityType.PUBLIC),
        createDocument: (source, options) => new Workout(source, options),
      });
    await Workout.initCollection(workoutCollection);
    return workoutCollection;
  }

  /**
   * Gets a workout by id.
   *
   * @param {string} workoutID - The id of the workout.
   * @returns {Promise<Workout>} The workout document.
   */
  static async getWorkoutById(workoutID) {
    const workoutDoc = new Workout(format(firestorePaths.WORKOUT_DOC, {
      [pathPlaceholder.WORKOUT_ID]: workoutID,
    }));

    await workoutDoc.init();

    return workoutDoc;
  }
}

export default Workout;
export {
  VisibilityType,
};
