import styled from 'styled-components';
import {
  FormControl,
  FormHelperText,
} from '@mui/material';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
`;

const StyledFormControl = styled(FormControl)`
  width: 200px;
`;

const StyledFormHelperText = styled(FormHelperText)`
  margin-top: 0;
  font-size: 13px;
  line-height: 15px;
  color: unset;
`;

export {
  Container,
  StyledFormControl,
  StyledFormHelperText,
};
