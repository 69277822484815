import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { MajorNutritionItems } from './NutritionItem';

const THead = () => {
  const SmallRow = styled.th`
    font-size: 0.7rem;
  `;
  return (
    <thead>
      <tr>
        <SmallRow colSpan={3}>Amount Per Serving</SmallRow>
      </tr>
    </thead>
  );
};

const Calories = ({ calories, caloriesFromFat }) => {
  const ThickRowTh = styled.th`
    border-top-width: 5px;
  `;

  return (
    <tr>
      <ThickRowTh colSpan={2}>
        <b>Calories</b>
        {' '}
        { calories }
      </ThickRowTh>

      <td>
        {caloriesFromFat && `Calories from Fat ${caloriesFromFat}`}
      </td>
    </tr>
  );
};

Calories.propTypes = {
  calories: PropTypes.number.isRequired,
  caloriesFromFat: PropTypes.number,
};
Calories.defaultProps = {
  caloriesFromFat: null,
};

const NutritionMajor = (props) => {
  const ThickRowTd = styled.td`
    border-top-width: 5px;
  `;

  const NTable = styled.table`
    width: 100%;
    border-collapse: collapse;
    td:last-child {
      text-align: right;
    }
    th, td {
      font-weight: normal;
      text-align: left;
      padding: 0.25rem 0;
      border-top: 1px solid black;
      white-space: nowrap;
    }
    thead tr th {
        border: 0;
      }
  `;

  const { items } = props;

  return (
    <NTable>
      <THead />
      <tbody>
        <Calories {...props} />
        <tr>
          <ThickRowTd colSpan={3}>
            <b>% Daily Value*</b>
          </ThickRowTd>
        </tr>
        {MajorNutritionItems(items)}
      </tbody>
    </NTable>
  );
};

NutritionMajor.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  calories: PropTypes.number.isRequired,
  caloriesFromFat: PropTypes.number,
};

NutritionMajor.defaultProps = {
  caloriesFromFat: null,
};

export default NutritionMajor;
