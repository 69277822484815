import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import moment from 'moment';
import { useRouteMatch } from 'react-router-dom';

import { getClientPagePath } from '../../../utils/routes';
import ChatButton from '../ChatButton';
import Avatar from '../../../components/Avatar';
import ClientQuickInfoButton from '../ClientQuickInfoButton';
import AvatarSizeType from '../../../components/Avatar/AvatarSizes';

import {
  Container,
  ActionButtonContainer,
  StyledLink,
  TextContainer,
  SubTitle,
  StyledChatButtonContainer,
  StyledNotesButtonContainer,
} from './styles';

const UserProfileCard = ({
  clientId,
  avatarSize,
  dataRow,
  showButtonsOnHover,
  subTitle,
  className,
}) => {
  const NEW_USER_PERIOD_OF_DAYS = 7;

  const {
    params: {
      userId: coachId,
    },
  } = useRouteMatch();

  const showNewBadge = useMemo(() => (
    moment().diff(moment(dataRow.serviceStartAt), 'days') <= NEW_USER_PERIOD_OF_DAYS
  ), [dataRow.serviceStartAt]);

  return (
    <Container className={className}>
      <Avatar
        image={dataRow?.userAvatar}
        name={dataRow.userName}
        avatarSize={avatarSize}
        showNewBadge={showNewBadge}
      />
      <TextContainer>
        <StyledLink to={getClientPagePath(coachId, clientId)}>
          {dataRow.userName}
        </StyledLink>
        {!!subTitle && (
          <SubTitle>{subTitle}</SubTitle>
        )}
      </TextContainer>
      <ActionButtonContainer $showOnHover={showButtonsOnHover}>
        <StyledChatButtonContainer>
          <ChatButton
            userId={clientId}
            popperPlacement="right-start"
            iconVariant="secondary"
            iconButton
          />
        </StyledChatButtonContainer>
        <StyledNotesButtonContainer>
          <ClientQuickInfoButton userId={clientId} iconButton />
        </StyledNotesButtonContainer>
      </ActionButtonContainer>
    </Container>
  );
};

UserProfileCard.propTypes = {
  clientId: PropTypes.string.isRequired,
  avatarSize: PropTypes.oneOf(AvatarSizeType),
  dataRow: PropTypes.object.isRequired,
  showButtonsOnHover: PropTypes.bool,
  subTitle: PropTypes.string,
  className: PropTypes.string,
};

UserProfileCard.defaultProps = {
  avatarSize: AvatarSizeType.MEDIUM,
  showButtonsOnHover: false,
  subTitle: '',
  className: '',
};

export default compose(
  observer,
)(UserProfileCard);
