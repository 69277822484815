import React from 'react';
import PropTypes from 'prop-types';
import {
  CardContent,
  Grid,
} from '@mui/material';

import {
  Container,
  StyledAlert,
  Title,
} from './styles';

const AnalyticsGroup = ({
  title,
  children,
  error,
}) => (
  <Container variant="outlined">
    <CardContent>
      <Title>
        {title}
      </Title>
      {!error && (
        <Grid container spacing={2}>
          {children}
        </Grid>
      )}
    </CardContent>
    {!!error && (
      <StyledAlert severity="warning">
        {error}
      </StyledAlert>
    )}
  </Container>
);

AnalyticsGroup.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  error: PropTypes.string,
};

AnalyticsGroup.defaultProps = {
  children: null,
  error: null,
  title: '',
};

export default AnalyticsGroup;
