import styled from 'styled-components';
import { Box } from '@mui/material';

import colors from '../../../styles/colors';

const Container = styled(Box)`
  max-width: 935px;
  background-color: ${colors.base.gamma};
`;

export {
  Container,
};
