import styled from 'styled-components';

import FormikInput from '../../../../../components/v2/FormikInput';
import { ReactComponent as SpacingVerticalLine } from '../../../../../assets/icons/v2/distribute-spacing-vertical .svg';

const StyledSpacingVerticalLine = styled(SpacingVerticalLine)`
  width: 24px;
  height: 24px;
`;

const FormContainer = styled.div`
  padding: 24px 50px 10px 50px;
`;

const ButtonContainer = styled.div`
  margin: 50px 10px 25px;
`;

const FormFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding: 10px;
  flex-grow: 1;
`;

const StyledFormikInput = styled(FormikInput)`
  .MuiInputBase-multiline {
    box-sizing: content-box;
  }
`;

export {
  StyledSpacingVerticalLine,
  FormContainer,
  ButtonContainer,
  FormFieldsContainer,
  StyledFormikInput,
};
