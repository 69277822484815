import React, {
  useState,
  useCallback,
  useMemo,
  useContext,
} from 'react';
import moment from 'moment';
import format from 'string-template';
import { PropTypes } from 'prop-types';
import { Tooltip } from '@mui/material';

import LoggedInUserContext from '../../../../../context/LoggedInUserContext';
import useComponentMounted from '../../../../../hooks/useComponentMounted';
import Modal from '../../../../../components/v2/Modal';
import {
  PrimaryButton,
  CancelButton,
} from '../../../../../components/Button/ActionButtons';
import { CoachingActivity } from '../../../../../utils/log';
import useLogger from '../../../../../hooks/useLogger';
import useToast from '../../../../hooks/useToast';
import CoachActivity from '../../../../Model/CoachActivity';
// These are the titles of the activity feed sidebar menu
import sidebarTitles from '../../../../context/FeedContext/config/texts';

import { ClaimButton } from './styles';
import texts from './texts.json';

const CLAIM_TIMEOUT_MINS = 30;

const ClaimActivity = ({
  activity,
}) => {
  const {
    userDoc: {
      name: authUserName,
    },
    userId: authUserId,
  } = useContext(LoggedInUserContext);

  const { showToast } = useToast();
  const isComponentMountedRef = useComponentMounted();
  const { logCoachingActivity } = useLogger();

  const [showModal, setShowModal] = useState(false);

  const {
    claimedAt,
    claimedBy,
    claimedByUserName,
  } = activity;

  const isClaimedByUser = useMemo(() => claimedBy === authUserId, [
    claimedBy,
    authUserId,
  ]);

  const claimedTime = useMemo(() => (
    claimedAt ? moment.utc(claimedAt.toDate()).local().fromNow() : null
  ), [claimedAt]);

  const claimActivity = useCallback(async () => {
    try {
      await activity.claimActivity(authUserId, authUserName);
      showToast(texts.claimSuccessful);

      const [activityId] = activity.id;
      logCoachingActivity(CoachingActivity.CLAIMED_ACTIVITY, {
        activityId,
        activityType: activity.type,
        clientId: activity.user,
      });
      if (isComponentMountedRef.current) {
        setShowModal(false);
      }
    } catch (error) {
      showToast(format(texts.claimFailed, { error }), { error: true });
    }
  }, [
    isComponentMountedRef,
    activity,
    authUserId,
    authUserName,
    showToast,
    logCoachingActivity,
  ]);

  const unclaimActivity = useCallback(async () => {
    try {
      await activity.unclaimActivity();
      showToast(texts.unclaimSuccessful);

      const [activityId] = activity.id;
      logCoachingActivity(CoachingActivity.UNCLAIMED_ACTIVITY, {
        activityId,
        activityType: activity.type,
        clientId: activity.user,
      });
      if (isComponentMountedRef.current) {
        setShowModal(false);
      }
    } catch (error) {
      showToast(format(texts.unclaimFailed, { error }), { error: true });
    }
  }, [
    isComponentMountedRef,
    activity,
    showToast,
    logCoachingActivity,
  ]);

  const onClaimClick = useCallback(async () => {
    // This activity has already been claimed by the logged in user
    if (!!claimedBy && !!claimedAt && isClaimedByUser) {
      showToast(texts.claimWarning.selfClaimed);
      return;
    }
    const claimedActivity = await CoachActivity.getUserClaimedActivity(authUserId);
    // Don't allow to claim an item if the user already has a claimed one
    if (claimedActivity) {
      showToast(
        format(texts.claimWarning.claimedActivitiesExist, { tab: sidebarTitles[claimedActivity.type] }),
        { error: true },
      );
      return;
    }
    // If the activity was freshly claimed (within 30 minutes), we won't allow it be reclaimed
    if (!!claimedBy && !!claimedAt && moment().diff(moment(claimedAt.toDate()), 'minutes') <= CLAIM_TIMEOUT_MINS) {
      showToast(texts.claimWarning.freshlyClaimed, { warning: true });
      return;
    }
    // Allow reclaiming after 30 minutes has passed after the previous claim, but only after showing a warning
    if (!!claimedBy && !!claimedAt && moment().diff(moment(claimedAt.toDate()), 'minutes') > CLAIM_TIMEOUT_MINS) {
      setShowModal(true);
      return;
    }

    await claimActivity();
  }, [
    authUserId,
    claimedAt,
    claimedBy,
    isClaimedByUser,
    claimActivity,
    showToast,
  ]);

  return (
    <>
      <Tooltip title={isClaimedByUser ? texts.unclaimTooltip : texts.claimTooltip} placement="left-start" followCursor>
        <div>
          <ClaimButton onClick={isClaimedByUser ? unclaimActivity : onClaimClick}>
            {isClaimedByUser ? texts.UNCLAIM : texts.CLAIM}
          </ClaimButton>
        </div>
      </Tooltip>
      <Modal
        title={texts.claimModalTitle}
        open={showModal}
        onClose={() => setShowModal(false)}
        actionButtons={(
          <>
            <PrimaryButton
              onClick={claimActivity}
            >
              {texts.modalButton.OK}
            </PrimaryButton>
            <CancelButton onClick={() => setShowModal(false)}>{texts.modalButton.CANCEL}</CancelButton>
          </>
        )}
      >
        {format(texts.claimWarning.alreadyClaimed, { user: claimedByUserName, time: claimedTime })}
      </Modal>
    </>
  );
};

ClaimActivity.propTypes = {
  activity: PropTypes.object.isRequired,
};

export default ClaimActivity;
