import React, {
  useEffect,
  useState,
  useRef,
  useContext,
} from 'react';
import PropTypes from 'prop-types';

import useComponentMounted from '../../hooks/useComponentMounted';
import FirebaseContext from '../../context/FirebaseContext';
import { getMetabaseIframeUrl } from './utils';
import { StyledIframe } from './styles';

const MetabaseDashboardView = ({
  dashboardId,
  fullscreenButtonRef,
}) => {
  const [iframeUrl, setIframeUrl] = useState('');

  const isComponentMountedRef = useComponentMounted();
  const iframeRef = useRef();

  const { firebase: { remote } } = useContext(FirebaseContext);

  useEffect(() => {
    const loadIframeUrl = async () => {
      const url = await getMetabaseIframeUrl(remote, dashboardId);
      if (isComponentMountedRef.current) {
        setIframeUrl(url);
      }
    };
    loadIframeUrl();
  }, [dashboardId, isComponentMountedRef, remote]);

  const toggleFullscreen = () => {
    const iframe = iframeRef.current;
    if (!document.fullscreenElement) {
      if (iframe.requestFullscreen) {
        iframe.requestFullscreen();
      } else if (iframe.webkitRequestFullscreen) {
        iframe.webkitRequestFullscreen();
      } else if (iframe.mozRequestFullScreen) {
        iframe.mozRequestFullScreen();
      } else if (iframe.msRequestFullscreen) {
        iframe.msRequestFullscreen();
      }
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  };

  useEffect(() => {
    if (fullscreenButtonRef?.current) {
      const currenctRef = fullscreenButtonRef.current;
      currenctRef.onclick = toggleFullscreen;
    }
  }, [fullscreenButtonRef]);

  return (
    <StyledIframe
      title="Metabase Dashboard"
      src={iframeUrl}
      ref={iframeRef}
    />
  );
};

MetabaseDashboardView.propTypes = {
  dashboardId: PropTypes.number.isRequired,
  fullscreenButtonRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
};

MetabaseDashboardView.defaultProps = {
  fullscreenButtonRef: null,
};

export default MetabaseDashboardView;
