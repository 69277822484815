import styled, { css } from 'styled-components';
import { Radio, Typography } from '@mui/material';

import colors from '../../../../../../styles/colors';
import { ReactComponent as MealPlateIcon } from '../../../../../../assets/icons/v2/modal-meal-plate.svg';

const AssignContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  max-height: 320px;
  padding: 10px 50px;
  overflow-y: auto;
`;
const WarningContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 7px;
  margin: 8px 0 15px 0;
  font-size: 10px;
  color: ${colors.base.danger};
`;
const AssignTitle = styled(Typography)`
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  color: ${colors.shades.secondary8};
  ${({ $withTopMargin }) => $withTopMargin && css`
    margin-top: 45px;
  `}
`;
const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 15px 0;

  .MuiTypography-root {
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    color: ${colors.shades.secondary8};
  }

  .MuiButtonBase-root {
    padding: 3px 9px;
  }
`;
const StyledRadio = styled(Radio)`
  color: ${colors.shades.secondary8};

  &.Mui-checked {
    color: ${colors.shades.secondary8};
  }
`;
const MacrosContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  column-gap: 14px;
`;

// TODO: Use tags component
const AllocationContainer = styled.div`
  margin-top: 10px;
  border-radius: 45px;
  padding: 7px 12px;
  width: fit-content;
  font-size: 10px;
  font-weight: 400;
  letter-spacing: -0.1px;
  color: ${colors.base.beta};
  background: ${({ $isError }) => ($isError ? colors.shades.danger2 : colors.shades.success5)};
  border: 1px solid ${({ $isError }) => ($isError ? colors.shades.danger5 : colors.shades.success4)};
`;

const StyledMealPlateIcon = styled(MealPlateIcon)`
  width: 27px;
  height: 29px;
  margin-bottom: 9px;
`;

export {
  MacrosContainer,
  StyledRadio,
  OptionsContainer,
  AssignTitle,
  WarningContainer,
  AssignContainer,
  AllocationContainer,
  StyledMealPlateIcon,
};
