import config from '../../config';

const sendMessage = async (msgProvider, contactNo, message, file, fileUrl, msgEndpoint, leadId) => {
  let errMessage = '';
  let successMessage = '';
  const formdata = new FormData();
  formdata.append('provider', msgProvider);
  formdata.append('message', message);
  formdata.append('phoneNumber', contactNo);
  if (leadId) {
    formdata.append('leadId', leadId);
  }
  if (file) {
    formdata.append('file', file);
  } else if (fileUrl) {
    formdata.append('fileUrl', fileUrl);
  }
  try {
    const response = await fetch(msgEndpoint?.endpoint || config.messageServer.url, {
      method: 'POST',
      body: formdata,
    });
    const respMsg = await response.text();
    if (response.ok) {
      successMessage = respMsg;
    } else {
      errMessage = respMsg;
    }
  } catch (e) {
    errMessage = e.message === 'Failed to fetch' ? 'Message server is not running' : e.message;
  }
  return { errMessage, successMessage };
};

export {
  sendMessage,
};
