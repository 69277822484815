import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  InputLabel,
} from '@mui/material';

import { ReactComponent as ChevronDownIcon } from '../../../assets/icons/chevron-down.svg';
import {
  Container,
  StyledFormControl,
  StyledFormHelperText,
} from './styles';

import texts from './texts.json';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const statesSelectMenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      textTransform: 'capitalize',
    },
  },
};

const Filter = ({
  description,
  onOptionsSelected,
  onValuesSelected,
  options,
  initialOptions,
  initialValues,
  className,
  inputLabel,
}) => {
  const [selectedOptions, setSelectedOptions] = useState(() => {
    if (initialOptions && initialOptions.length > 0) {
      return initialOptions;
    }
    if (initialValues && initialValues.length > 0) {
      return initialValues.reduce((resultOptions, currentValue) => {
        const currentOption = options.find((option) => option.value === currentValue);
        if (currentOption) {
          return [...resultOptions, currentOption];
        }
        return resultOptions;
      }, []);
    }
    return [];
  });

  const optionAllRef = useRef();

  if (!optionAllRef.current) {
    optionAllRef.current = {
      value: 'OPTION_ALL',
      label: texts.selectAll,
    };
  }

  const handleChange = useCallback(
    (event) => {
      const {
        target: { value },
      } = event;

      // Remove the 'All' option
      const filteredValue = value.filter((item) => item.value !== optionAllRef.current.value);
      let newSelectedOptions;

      if (filteredValue.length === value.length) {
        // One of the states has triggered the events
        newSelectedOptions = filteredValue;
      } else if (filteredValue.length === options.length) {
        // It means that the All button has triggered the event
        newSelectedOptions = [];
      } else {
        newSelectedOptions = options;
      }

      setSelectedOptions(newSelectedOptions);
    },
    [options],
  );

  const renderValue = (selected) => {
    if (selected.length === options.length) {
      return optionAllRef.current.label;
    }

    return selected.map(({ label }) => label).join(', ');
  };

  useEffect(() => {
    onOptionsSelected(selectedOptions);
    onValuesSelected(selectedOptions.map((item) => item.value));
  }, [
    selectedOptions,
    onOptionsSelected,
    onValuesSelected,
  ]);

  return (
    <Container>
      {!!description && <StyledFormHelperText>{description}</StyledFormHelperText>}
      <StyledFormControl>
        {!!inputLabel && <InputLabel>{inputLabel}</InputLabel>}
        <Select
          className={className}
          multiple
          value={selectedOptions}
          onChange={handleChange}
          IconComponent={ChevronDownIcon}
          input={<OutlinedInput label={inputLabel} />}
          renderValue={renderValue}
          MenuProps={statesSelectMenuProps}
        >
          <MenuItem value={optionAllRef.current}>
            <Checkbox
              checked={options.length === selectedOptions.length}
              indeterminate={selectedOptions.length < options.length && selectedOptions.length > 0}
            />
            <ListItemText primary={optionAllRef.current.label} />
          </MenuItem>
          {options.map((option) => (
            <MenuItem key={option.value} value={option}>
              <Checkbox checked={!!selectedOptions.find((item) => item.value === option.value)} />
              <ListItemText primary={option.label} />
            </MenuItem>
          ))}
        </Select>
      </StyledFormControl>
    </Container>
  );
};

Filter.propTypes = {
  description: PropTypes.string,
  onOptionsSelected: PropTypes.func,
  onValuesSelected: PropTypes.func,
  options: PropTypes.array.isRequired,
  initialOptions: PropTypes.array,
  initialValues: PropTypes.array,
  className: PropTypes.string,
  inputLabel: PropTypes.string,
};

Filter.defaultProps = {
  className: '',
  description: '',
  inputLabel: '',
  initialOptions: [],
  initialValues: [],
  onOptionsSelected: () => {},
  onValuesSelected: () => {},
};

export default Filter;
