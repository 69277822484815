import styled from 'styled-components';

import { Typography } from '@mui/material';
import { FieldArray } from 'formik';

import { StyledFormRow } from '../../styles';

import colors from '../../../../../../styles/colors';
import FormikInput from '../../../../../../components/v2/FormikInput';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledInput = styled(FormikInput)`
  flex-grow: 1;
  margin: 0;
  .MuiInputBase-root {
    height: fit-content;
  }
`;

const StyledFieldArray = styled(FieldArray)`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  align-items: center;
  justify-content: center;
`;

const InstructionsContainer = styled(StyledFormRow)`
  padding: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  border: 1px solid ${colors.shades.secondary7};
  margin-top: 10px;
`;

const ButtonContainer = styled(StyledFormRow)`
  padding: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  border: 1px solid ${colors.shades.secondary7};
  margin-top: 10px;
  width: 60%;
  align-items: center;
`;

const InstructionsHeader = styled(Typography)`
  width: 100%;
`;

const RowWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
  justify-content: center;
`;

export {
  Container,
  StyledInput,
  StyledFieldArray,
  InstructionsContainer,
  ButtonContainer,
  InstructionsHeader,
  RowWrapper,
};
