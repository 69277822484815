import styled from 'styled-components';

const containerStyle = {
  display: 'flex',
  flexDirection: 'column',
  padding: '10px',
  rowGap: '20px',
};

const ActionButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 0;
  width: 100%;

  button {
    width: 100%;
    border-radius: 0;
  }
`;

export {
  containerStyle,
  ActionButtonContainer,
};
