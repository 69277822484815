import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/browser';

import useSessionStore from '../../hooks/useSessionStore';
import {
  calculateRedirectPathIfAny,
  removeRedirectFromSearch,
} from './redirect';

const HomeRoute = () => {
  const {
    isCoach,
    isAdmin,
    isCoachAssistant,
    isSupport,
    isInsideSales,
    isSDR,
    userId,
  } = useSessionStore();

  const location = useLocation();
  const { search: prevSearch, state } = location;

  let homePath = `/u/${userId}/home`;
  let redirectPath;

  try {
    redirectPath = calculateRedirectPathIfAny(userId);
  } catch (error) {
    Sentry.captureException(error);
  }

  const search = removeRedirectFromSearch(prevSearch);

  if (redirectPath) {
    homePath = redirectPath;
  } else if (isInsideSales || isSDR) {
    homePath = `/is/${userId}`;
  } else if (isCoachAssistant) {
    homePath = `/ac/${userId}`;
  } else if (isSupport) {
    homePath = `/s/${userId}`;
  } else if (isAdmin) {
    homePath = `/a/${userId}`;
  } else if (isCoach) {
    homePath = `/c/${userId}`;
  }

  return <Redirect to={{ pathname: homePath, search, state }} />;
};

export default HomeRoute;
