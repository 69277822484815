import React, {
  useState,
  useContext,
} from 'react';
import format from 'string-template';

import ExerciseTag from '../../../Model/ExerciseTag';
import useToast from '../../../hooks/useToast';
import UserContext from '../../../../context/UserContext';
import { PrimaryButton } from '../../../../components/Button/ActionButtons';
import { ReactComponent as CreateIcon } from '../../../../assets/icons/v2/creation-plus-circle.svg';
import {
  HeaderRow,
  TitleContainer,
  Title,
} from '../../../../components/v2/Header';
import useComponentMounted from '../../../../hooks/useComponentMounted';

import { TableTabContainer } from '../styles';
import TagsModal from './components/TagsModal';
import ExerciseTagsTable from './components/ExerciseTagsTable';

import texts from './texts.json';

const Tags = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTag, setSelectedTag] = useState(null);

  const { userId: coachId } = useContext(UserContext);

  const { showToast } = useToast();

  const isComponentMountedRef = useComponentMounted();

  const handleTagEdit = (tag) => {
    setSelectedTag(tag);
    setIsModalOpen(true);
  };

  const resetModal = () => {
    setIsModalOpen(false);
    setSelectedTag(null);
  };

  const onTagSave = async ({ tagName, tagCategory }) => {
    try {
      if (selectedTag) {
        // Update selected tag
        await selectedTag.updateTag(tagName, tagCategory);
      } else {
        // Create new tag
        await ExerciseTag.addCustomTag(tagName, tagCategory, coachId);
      }
      if (isComponentMountedRef.current) {
        showToast(selectedTag ? texts.updateTagSuccess : texts.createdNewTag);
        resetModal();
      }
    } catch (error) {
      showToast(format(texts.errorCreatingNewTag, { message: error.message }), { error: true });
    }
  };

  return (
    <TableTabContainer>
      <HeaderRow>
        <TitleContainer>
          <Title>{texts.title}</Title>
        </TitleContainer>
        <PrimaryButton
          onClick={() => setIsModalOpen(true)}
          icon={<CreateIcon />}
        >
          {texts.newTag}
        </PrimaryButton>
      </HeaderRow>
      <ExerciseTagsTable
        onEdit={handleTagEdit}
      />
      {isModalOpen && (
        <TagsModal
          open={isModalOpen}
          onClose={resetModal}
          onSave={onTagSave}
          selectedTag={selectedTag}
        />
      )}
    </TableTabContainer>
  );
};

export default Tags;
