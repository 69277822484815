import React, {
  useEffect,
  useState,
} from 'react';
import { useRouteMatch } from 'react-router-dom';

import { getQueryVariable } from '../../../utils/queryParams';
import LoadingPage from '../../../components/LoadingPage';
import useComponentMounted from '../../../hooks/useComponentMounted';

import Recipe from '../../Model/Recipe';
import RecipeEditor from '../MealsPlanner/RecipeEditor/RecipeEditor';

import { Container } from './styles';

const RecipesDetailsContainer = () => {
  const [isReady, setIsReady] = useState(false);
  const [recipeDoc, setRecipeDoc] = useState(null);

  const { params: { recipeId: recipeIdParam } } = useRouteMatch();

  const recipeIdQueryParam = getQueryVariable('recipeId');

  // The recipe id can be passed via the URL as a path param or query param
  const recipeId = recipeIdParam || recipeIdQueryParam;

  const isComponentMountedRef = useComponentMounted();

  useEffect(() => {
    const init = async () => {
      if (recipeId) {
        const recipe = new Recipe(recipeId);
        await recipe.init();

        if (isComponentMountedRef.current) {
          setRecipeDoc(recipe);
        }
      }

      setIsReady(true);
    };

    if (!isReady) {
      init();
    }
  }, [
    isReady,
    recipeId,
    isComponentMountedRef,
  ]);

  return (
    <Container>
      {
        isReady
          ? <RecipeEditor recipeDoc={recipeDoc} />
          : <LoadingPage />
      }
    </Container>
  );
};

export default RecipesDetailsContainer;
