import React from 'react';

const initialValues = {
  title: '',
  isDrawerOpen: false,
  toggleDrawer: () => {},
};

const LayoutContext = React.createContext(initialValues);

export default LayoutContext;
export {
  initialValues,
};
