import { WeeklyWeightGoal } from '../../../../Model/UserNutritionProfile';
import { ActivityLevel } from '../../../../Model/UserActivityProfile';
import { MeasurementUnit } from '../../../../../utils/measurement';

const texts = {
  headers: {
    physicalAttributes: 'PHYSICAL ATTRIBUTES',
    height: 'HEIGHT',
    currentWeight: 'CURRENT WEIGHT',
    goalWeight: 'GOAL WEIGHT',
    gender: 'GENDER',
    age: 'AGE',
    activityLevel: 'ACTIVITY LEVEL',
    clientGoal: 'CLIENT GOAL',
  },
  setGoal: 'Set {userFirstName}\'s goal',
  weightGoal: {
    [WeeklyWeightGoal.GAIN_WEIGHT]: 'Gain Weight',
    [WeeklyWeightGoal.LOSE_LITTLE_WEIGHT]: 'Lose Little Weight',
    [WeeklyWeightGoal.LOSE_MORE_WEIGHT]: 'Lose More Weight',
    [WeeklyWeightGoal.MAINTAIN_WEIGHT]: 'Maintain Weight',
  },
  activityLevel: {
    [ActivityLevel.ACTIVE]: 'Active',
    [ActivityLevel.MODERATE]: 'Moderate',
    [ActivityLevel.SEDENTARY]: 'Sedentary',
  },
  saveGoal: 'Save Goal',
  lastCheckin: 'Last check-in',
  thisCheckin: 'This check-in',
  startedOn: 'Started on',
  heightValues: {
    [MeasurementUnit.METRIC]: '{height} cm',
    [MeasurementUnit.IMPERIAL]: '{ft}\'{in}"',
  },
  weightValues: {
    [MeasurementUnit.METRIC]: '{weight} kg',
    [MeasurementUnit.IMPERIAL]: '{weight} lbs',
  },
  emptyCell: '-',
  loading: 'Loading...',
  noData: 'No data found for this user',
};

export default texts;
