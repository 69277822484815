import React, {
  useEffect,
  useState,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import format from 'string-template';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Paper,
} from '@mui/material';

import useToast from '../../../../hooks/useToast';
import Lead from '../../../../Model/Lead';

import { StyledNotesInput, Title } from './styles';
import texts from './texts.json';

const NotesModal = ({
  lead,
  onClose,
  showModal,
}) => {
  const {
    name,
    notes: prevNotes,
  } = lead;

  const [notes, setNotes] = useState(prevNotes);

  const { showToast } = useToast();

  useEffect(() => {
    setNotes(prevNotes);
  }, [
    prevNotes,
  ]);

  const handleNotesSave = useCallback(() => {
    lead.setNotes(notes || '');
    showToast(texts.notesSaveSuccess, {
      options: {
        position: 'top-right',
        closeOnClick: true,
        draggable: true,
      },
    });
    onClose();
  }, [
    lead,
    notes,
    onClose,
    showToast,
  ]);

  return (
    <Dialog
      open={showModal}
      PaperComponent={Paper}
    >
      <Title>
        {
          format(texts.title, {
            name,
          })
        }
      </Title>
      <DialogContent>
        <StyledNotesInput
          variant="outlined"
          label={texts.notes}
          multiline
          rows={4}
          value={notes}
          onChange={(evt) => setNotes(evt.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {texts.close}
        </Button>
        <Button onClick={handleNotesSave}>
          {texts.saveNotes}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

NotesModal.propTypes = {
  lead: PropTypes.instanceOf(Lead).isRequired,
  onClose: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
};

export default compose(
  observer,
)(NotesModal);
