import { Collection } from 'firestorter';
import { firestorePaths } from '../utils/paths';
import BaseDocument from './BaseDocument';
import User from './User';
import Coach from './Coach';

/**
 * Class representing a CoachAssignment.
 *
 * @class CoachAssignment
 * @extends BaseDocument
 */
class CoachAssignment extends BaseDocument {
  constructor(src, opts) {
    super(src, opts);
    if (this.data.user) {
      this.userDoc = new User(() => `user/${this.data.user}`);
    }
    if (this.data.coach) {
      this.coachDoc = new Coach(() => `coach/${this.data.coach}`);
    }
  }

  /**
   * Get the user id of user associated with this coach assignment.
   * @return {string}
   */
  get user() {
    return this.data.user;
  }

  /**
   * Get the coach id.
   * return {string}
   */
  get coach() {
    return this.data.coach;
  }

  /**
   * Get the coach assignment document for a user.

   * @param {string} userId - The user id.
   * @returns {Promise<CoachAssignment|Object>} The coach assignment document if it exists, or else empty object.
   */
  static async getByUserId(userId) {
    const coachAssignmentCollection = new Collection(firestorePaths.COACH_ASSIGNMENT_COLLECTION,
      {
        query: (ref) => ref
          .where('user', '==', userId)
          .where('current', '==', true)
          .limit(1),
        createDocument: (source, options) => new CoachAssignment(source, options),
      });
    await CoachAssignment.initCollection(coachAssignmentCollection);

    if (coachAssignmentCollection.hasDocs) {
      const coachAssignment = coachAssignmentCollection.docs[0];
      await coachAssignment.init(false);
      return coachAssignment;
    }
    return {};
  }

  /**
   * Assigns a user to a coach.

   * @param {string} userId - The user id.
   * @param {string} coachId - The coach id.
   * @returns {Promise<void>}
   */
  static async assignCoach(userId, coachId) {
    const currentCoachAssignment = CoachAssignment.getByUserId(userId);

    if (currentCoachAssignment.id) {
      if (currentCoachAssignment.user === userId && currentCoachAssignment.coach === coachId) {
        // It's already the current coach assignment, nothing to do here
        return;
      }
    }

    const coachAssignmentCollection = new Collection(firestorePaths.COACH_ASSIGNMENT_COLLECTION);

    await coachAssignmentCollection.add({
      coach: coachId,
      user: userId,
      createdAt: (new Date()),
      current: true,
    });
  }

  /**
   * Fetches the coach and user documents associated when the coach assignment is initialized.

   * @param {boolean} [fetch=false] - Whether to fetch the coach and user documents.
   * @returns {Promise<void>}
   */
  async init(fetch = false) {
    if (fetch) {
      await this.fetch();
    }
    await this.ready();
    this.userDoc = new User(() => `user/${this.data.user}`);
    await this.userDoc.fetch();
    await this.coachDoc.fetch();
  }
}

export default CoachAssignment;
