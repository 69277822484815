import styled, { css } from 'styled-components';

import { ReactComponent as AddIcon } from '../../../../../../../assets/icons/v2/add.svg';
import { ReactComponent as TrashIcon } from '../../../../../../../assets/icons/v2/trash.svg';
import { ReactComponent as CopyIcon } from '../../../../../../../assets/icons/v2/duplicate.svg';
import { ReactComponent as UpdateIcon } from '../../../../../../../assets/icons/v2/edit-blue.svg';

const actionButtonStyle = css`
  width: 20px;
  height: 20px; 
`;

const StyledAddIcon = styled(AddIcon)`
  ${actionButtonStyle}
`;

const StyledTrashIcon = styled(TrashIcon)`
  ${actionButtonStyle}
`;

const StyledCopyIcon = styled(CopyIcon)`
  ${actionButtonStyle}
`;

const StyledUpdateIcon = styled(UpdateIcon)`
  ${actionButtonStyle}
`;

const ActionButtonContainer = styled.div`
  margin-left: auto;
`;

export {
  StyledAddIcon,
  StyledTrashIcon,
  StyledCopyIcon,
  StyledUpdateIcon,
  ActionButtonContainer,
};
