import { createTheme } from '@mui/material/styles';
import colors from '../../styles/colors';

const theme = createTheme({
  palette: {
    primary: {
      main: colors.base.primary,
    },
  },
  drawer: {
    width: 240,
  },
});

export default theme;
