import styled from 'styled-components';
import {
  Autocomplete,
  FormHelperText,
  Checkbox,
} from '@mui/material';

import colors from '../../../../styles/colors';

const DialogBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 40px;
  gap: 20px;
`;

const StyledAutocomplete = styled(Autocomplete)`
  background-color: ${colors.base.alpha}
`;

const StyledOption = styled.div`
  border: 1px solid ${colors.shades.secondary7};
`;

const ColumnContainer = styled.div`
  flex-direction: column;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

const CheckBoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 0;
  margin: -5px 0 10px -2px;
  
  .MuiCheckbox-root {
    padding-right: 5px;
  }
`;

const StyledCheckBox = styled(Checkbox)`
  padding: 0;
`;

const StyledHelperText = styled(FormHelperText)`
  margin-top: 0;
  font-size: 13px;
  line-height: 14px;
  font-weight: 450;
  color: unset;
`;

export {
  DialogBody,
  StyledAutocomplete,
  StyledOption,
  RowContainer,
  ColumnContainer,
  CheckBoxContainer,
  StyledHelperText,
  StyledCheckBox,
};
