import React from 'react';

import HeaderlessLayout from './HeaderlessLayout';
import Layout from './Layout';

/**
 * High Order Component (HOC) that renders the given component with the
 * default application Layout.
 *
 * @param {boolean} [hasHeader=true] boolean that indicates if the header must be shown
 * @param {Object} Component React Component to place as page content.
 */
const withLayout = ({ hasHeader } = { hasHeader: true }) => (Component) => (props) => {
  if (hasHeader) {
    return (
      <Layout>
        <Component {...props} />
      </Layout>
    );
  }
  return (
    <HeaderlessLayout>
      <Component {...props} />
    </HeaderlessLayout>
  );
};

export default withLayout;
