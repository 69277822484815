import React, {
  useEffect,
  useState,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';

import useComponentMounted from '../../../../hooks/useComponentMounted';
import Product from '../../../../Model/Product';
import Select from '../../../../components/Select';
import texts from './texts.json';

const PackageSelector = ({
  product,
  selectedPackageId,
  onChange,
  isReadOnly,
}) => {
  const [packagesCollection, setPackagesCollection] = useState({ docs: [] });

  const isComponentMountedRef = useComponentMounted();

  useEffect(() => {
    if (product) {
      // Get the packages for the product
      const fetchPackages = async () => {
        const packages = await product.getPackages();
        if (isComponentMountedRef.current) {
          setPackagesCollection(packages);
        }
      };

      fetchPackages();
    }
  }, [
    product,
    selectedPackageId,
    isComponentMountedRef,
  ]);

  const packageOptions = useMemo(() => {
    const packagesList = packagesCollection.docs.slice().sort((a, b) => a.name.localeCompare(b.name));

    return packagesList
      // If the package is archived, we only show it if it's the selected one
      .filter((pkg) => (!pkg.isArchived || pkg.id === selectedPackageId))
      .map((packageItem) => ({
        value: packageItem.id,
        label: packageItem.name,
      }));
  }, [packagesCollection, selectedPackageId]);

  return (
    <Select
      value={packageOptions.length ? selectedPackageId : ''}
      onChange={(e) => onChange(packagesCollection.docs.find((packageItem) => packageItem.id === e.target.value))}
      options={packageOptions}
      noOptionsText={texts.noPackagesText}
      disabled={isReadOnly}
    />
  );
};

PackageSelector.propTypes = {
  product: PropTypes.instanceOf(Product),
  selectedPackageId: PropTypes.string,
  onChange: PropTypes.func,
  isReadOnly: PropTypes.bool,
};

PackageSelector.defaultProps = {
  product: null,
  selectedPackageId: '',
  onChange: () => { },
  isReadOnly: false,
};

export default PackageSelector;
