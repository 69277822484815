import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';

import colors from '../../../styles/colors';

const ActionButtonContainer = styled.div`
  display: ${({ $showOnHover }) => ($showOnHover ? 'none' : 'flex')};
  flex-direction: row;
  gap: 5px;
`;

const StyledLink = styled(Link)`
  color: ${colors.shades.secondary8};
  font-size: 16px;
  font-weight: 700;
  text-wrap: nowrap;
  width: 100%;
  overflow: hidden;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  .user-name {
    text-decoration: none;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 8px;
  width: 100%;
  padding: 0;

  &:hover > ${ActionButtonContainer} {
    display: flex;
  }
`;

const SubTitle = styled(Typography)`
  width: 100%;
  font-size: 10px;
  line-height: 12px;
  font-weight: 400;
  color: ${colors.shades.secondary3};
`;

const StyledChatButtonContainer = styled.div`
  button {
    background-color: ${colors.shades.primary7};
    border: 0.5px solid ${colors.shades.primary3};
    svg {
      > path {
        stroke: ${colors.shades.secondary8};
        fill: ${colors.base.primary};
      }
    }
    &:hover {
      border: 0.5px solid ${colors.shades.secondary4};
    }
  }
`;

const StyledNotesButtonContainer = styled.div`
  button {
    border: 0.5px solid ${colors.shades.secondary4};
    background-color: ${colors.shades.secondary5};
  }
`;

export {
  Container,
  ActionButtonContainer,
  StyledLink,
  TextContainer,
  SubTitle,
  StyledChatButtonContainer,
  StyledNotesButtonContainer,
};
