import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import posthog from 'posthog-js';

import config from '../../config';
import FirebaseContext from '../../context/FirebaseContext';
import User from '../../Model/User';
import UserConfig from '../../Model/UserConfig';
import useSessionStore from '../../hooks/useSessionStore';
import { getQueryVariable } from '../../utils/queryParams';

const {
  posthog: { apiHost, apiKey },
} = config;

posthog.init(apiKey, { api_host: apiHost });

const Auth = ({
  children,
}) => {
  const { firebase } = useContext(FirebaseContext);
  const sessionStore = useSessionStore();
  const [isSessionInitialized, setIsSessionInitialized] = useState(false);

  /*
    Initialize the sessionStore authUser only once, the first time the render is called.
    This simulates a constructor-like behaviour.
  */
  if (!isSessionInitialized) {
    sessionStore.setAuthUser(
      JSON.parse(localStorage.getItem('authUser')),
      JSON.parse(localStorage.getItem('userClaims')),
      {
        isSupport: JSON.parse(localStorage.getItem('isSupport')),
        isCoachAssistant: JSON.parse(localStorage.getItem('isCoachAssistant')),
        isInsideSales: JSON.parse(localStorage.getItem('isInsideSales')),
        isSDR: JSON.parse(localStorage.getItem('isSDR')),
        isOnCall: JSON.parse(localStorage.getItem('isOnCall')),
      },
    );
    setIsSessionInitialized(true);
  }

  const onAuthUserChanged = async (authUser) => {
    /*
      firebase.auth.onAuthStateChanged is called right after we create a new anonymous user but anonymous user might
      be deleted if we detect, during email association, that that email is already used by another anonymous user.
      There isn't other way to determine this as
      https://firebase.google.com/docs/reference/js/firebase.auth.Auth#fetchsigninmethodsforemail won't return anything
      when the e-mail provided is associated to another anonymous user.
      So, we make sure that we do this session initialization only when the provided user does have an email associated
      (even if it's anonymous)
    */
    if (authUser && authUser.email) {
      const { claims } = await authUser.getIdTokenResult();
      localStorage.setItem('authUser', JSON.stringify(authUser));
      localStorage.setItem('userClaims', JSON.stringify({ ...claims }));

      let userDoc;
      try {
        userDoc = new User(`/user/${authUser.uid}`);
        await userDoc.init();
        await userDoc.updateCreatedAt(authUser.metadata.creationTime);
      } catch (error) {
        Sentry.captureException(error);
      }

      const isSupport = (userDoc && userDoc.data.isSupport) || getQueryVariable('support') === 'true';
      localStorage.setItem('isSupport', isSupport);

      const isCoachAssistant = (userDoc && userDoc.isCoachAssistant) || getQueryVariable('isCoachAssistant') === 'true';
      localStorage.setItem('isCoachAssistant', isCoachAssistant);

      const isInsideSales = (userDoc && userDoc.isInsideSales) || getQueryVariable('isInsideSales') === 'true';
      localStorage.setItem('isInsideSales', isInsideSales);

      const isSDR = (userDoc && userDoc.isSDR) || getQueryVariable('isSDR') === 'true';
      localStorage.setItem('isSDR', isSDR);

      const isOnCall = (userDoc && userDoc.isOnCall) || getQueryVariable('isOnCall') === 'true';
      localStorage.setItem('isOnCall', isOnCall);

      sessionStore.setAuthUser(
        authUser,
        claims,
        {
          isSupport,
          isCoachAssistant,
          isInsideSales,
          isSDR,
          isOnCall,
        },
      );

      const {
        displayName,
        email,
        emailVerified,
      } = authUser;

      const userProps = {
        displayName,
        email,
        emailVerified,
      };
      window.analytics.identify(authUser.uid, userProps);
      posthog.identify(authUser.uid, userProps);

      const userConfigDoc = new UserConfig(`/config/${authUser.uid}`);
      await userConfigDoc.updateFields({
        emailVerified: authUser.emailVerified,
        lastVisited: new Date(),
      });
    } else {
      localStorage.removeItem('authUser');
      localStorage.removeItem('userClaims');
      localStorage.removeItem('isSupport');
      localStorage.removeItem('isCoachAssistant');
      localStorage.removeItem('isInsideSales');
      localStorage.removeItem('isSDR');
      localStorage.removeItem('isOnCall');
      sessionStore.setAuthUser(null, null, {});

      // Log out from segment as well
      if (window.analytics) {
        window.analytics.reset();
      }

      // Log out from Posthog
      posthog.reset();

      // Log out from Growsurf
      if (window.growsurf) {
        window.growsurf.logout();
      }
    }
  };

  useEffect(() => {
    const listener = firebase.auth.onAuthStateChanged(onAuthUserChanged);
    sessionStore.onAuthUserChanged(onAuthUserChanged);

    return () => {
      listener();
      sessionStore.removeListeners();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <>{children}</>;
};

Auth.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Auth;
