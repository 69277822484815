/* global _cio:readonly */
import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';

import CustomerIOContext from './CustomerIOContext';

const trackingTypes = {
  TRACK: 'track',
  PAGE: 'page',
};

const CustomerIOContextProvider = ({
  children,
}) => {
  const [email, setEmail] = useState(null);
  const [isReady, setIsReady] = useState(false);
  const [eventsQueue] = useState([]);

  useEffect(() => {
    if (email) {
      _cio.identify({
        id: email,
        email,
        onboardingUser: true,
      });
      setIsReady(true);
    }
  }, [
    email,
  ]);

  useEffect(() => {
    if (isReady) {
      while (eventsQueue.length > 0) {
        const evtToReport = eventsQueue.shift();
        const {
          event: {
            eventName,
            props,
          },
          trackingType,
        } = evtToReport;

        switch (trackingType) {
          case trackingTypes.PAGE:
            _cio.page(eventName, props);
            break;
          case trackingTypes.TRACK:
          default:
            _cio.track(eventName, props);
            break;
        }
      }
    }
  }, [
    isReady,
    eventsQueue,
  ]);

  const track = useCallback((eventName, props) => {
    if (!isReady) {
      eventsQueue.push({
        trackingType: trackingTypes.TRACK,
        event: {
          eventName,
          props,
        },
      });
      return;
    }
    _cio.track(eventName, props);
  }, [
    eventsQueue,
    isReady,
  ]);

  const page = useCallback((eventName, props) => {
    if (!isReady) {
      eventsQueue.push({
        trackingType: trackingTypes.PAGE,
        event: {
          eventName,
          props,
        },
      });
      return;
    }
    _cio.page(eventName, props);
  }, [
    eventsQueue,
    isReady,
  ]);

  const contextValue = useMemo(() => ({
    isReady,
    email,
    setEmail,
    track,
    page,
  }), [
    isReady,
    email,
    setEmail,
    track,
    page,
  ]);

  return (
    <CustomerIOContext.Provider value={contextValue}>
      {children}
    </CustomerIOContext.Provider>
  );
};

CustomerIOContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CustomerIOContextProvider;
