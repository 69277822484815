import React from 'react';
import NavigationContextProvider from './NavigationContextProvider';

// eslint-disable-next-line react/prop-types
const withNavigationContext = (Component) => ({ routeType, ...rest }) => (
  <NavigationContextProvider routeType={routeType}>
    <Component {...rest} />
  </NavigationContextProvider>
);

export {
  withNavigationContext,
};
