import styled from 'styled-components';
import { Box } from '@mui/material';
import colors from '../../../../../styles/colors';

const StyledLoaderContainer = styled(Box)`
  display: flex;
  justify-content: center;
  margin: 10px;
`;

const FeedActivityContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background-color: ${colors.base.alpha};
  margin: 10px 0;
  min-width: 500px;
`;

export {
  StyledLoaderContainer,
  FeedActivityContainer,
};
