import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import {
  Alert,
  Backdrop,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from '@mui/material';
import { IonButton } from '@ionic/react';
import format from 'string-template';
import * as Sentry from '@sentry/browser';

import UserContext from '../../../../../context/UserContext';
import { StyledNotesInput } from '../OnboardingLinkModal/styles';
import FileSelectButton from '../../../../../components/FileSelectButton/FileSelectButton';
import { ContactProvider, LocalContactProvider } from '../../../../Model/Lead';
import { sendMessage } from '../../../../utils/message';
import MsgTemplate from '../../../../../Model/MsgTemplate';
import { FileCategory } from '../../../../../utils/file';
import {
  StyledButton,
  StyledImg,
  StyledStack,
  StyledVideo,
} from './styles';
import texts from './texts.json';

const MsgModal = ({
  onClose,
  open,
  contactNo,
  provider,
  msgTemplate,
  msgParams,
  onMsgSent,
  readOnly,
  msgEndpoints,
  sendAsUserId,
  leadId,
}) => {
  const { userDoc } = useContext(UserContext);
  const [msgProvider, setMsgProvider] = useState(() => {
    if (provider) {
      return provider;
    }
    // If the user is an SDR, the default provider is ANY.
    if (userDoc.isSDR) {
      return ContactProvider.ANY;
    }
    return ContactProvider.iMessage;
  });
  const [contact, setContact] = useState(contactNo);
  const [fileAttachment, setFileAttachment] = useState(null);
  const [message, setMessage] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [sendingMsg, setSendingMsg] = useState(false);
  const [sendAs, setSendAs] = useState(sendAsUserId);

  const fileUrl = useMemo(() => msgTemplate?.attachmentUrl, [msgTemplate]);
  const fileCategory = useMemo(() => msgTemplate?.attachmentCategory, [msgTemplate]);

  const defaultSendAsEndpoints = msgEndpoints.filter((me) => me.isDefault);
  const numberOfSendAsDefaults = defaultSendAsEndpoints.length;
  // validate if contact number starts with a "+" sign,
  // followed by a minimum of 7 and a maximum of 15 digits
  const isValidContactNo = useMemo(() => /^\+\d{7,16}$/.test(contact), [contact]);

  useEffect(() => {
    if (msgTemplate) {
      setMessage(format(msgTemplate.msgBody, msgParams));
    }
  }, [msgParams, msgTemplate]);

  useEffect(() => {
    if (!readOnly && !sendAs && userDoc.isSDR && numberOfSendAsDefaults > 0) {
      setSendAs(defaultSendAsEndpoints[0].userId);
    }
  }, [
    readOnly,
    sendAs,
    userDoc.isSDR,
    defaultSendAsEndpoints,
    numberOfSendAsDefaults,
  ]);

  const clearAlerts = useCallback(() => {
    setErrMsg('');
    setSuccessMsg('');
  }, []);

  const sendMsg = useCallback(async () => {
    setSendingMsg(true);
    clearAlerts();
    if (!contact || !message || !msgProvider) {
      if (!contact) {
        setErrMsg(texts.errMsgs.noContact);
      } else if (!message) {
        setErrMsg(texts.errMsgs.noMsg);
      } else {
        setErrMsg(texts.errMsgs.noProvider);
      }
      setSendingMsg(false);
      return;
    }
    let msgEndpoint = null;
    if (sendAs) {
      msgEndpoint = msgEndpoints.find((p) => p.userId === sendAs);
    }
    const { errMessage, successMessage } = await sendMessage(
      msgProvider, contact, message, fileAttachment, msgTemplate?.attachmentUrl, msgEndpoint, leadId,
    );
    setErrMsg(errMessage);
    setSuccessMsg(successMessage);
    setSendingMsg(false);
    if (successMessage) {
      onMsgSent({
        msgProvider,
        contactNo: contact,
        messageBody: message,
        msgTemplate,
        ...(msgEndpoint && {
          sendAs: msgEndpoint.userId,
          sendAsUserName: msgEndpoint.userName,
        }),
      });
    }
  }, [
    contact,
    fileAttachment,
    message,
    msgProvider,
    clearAlerts,
    msgTemplate,
    onMsgSent,
    msgEndpoints,
    sendAs,
    leadId,
  ]);

  const onFilesSelected = useCallback((event) => {
    const { files } = event.target;
    setFileAttachment(files[0]);
  }, []);

  const providers = sendAs ? ContactProvider : LocalContactProvider;

  // If it is an SDR user, there must be one (and only one) endpoint marked as isDefault.
  // Otherwise, this error must be logged and shown.
  if (userDoc.isSDR && (numberOfSendAsDefaults > 1 || numberOfSendAsDefaults === 0)) {
    Sentry.captureMessage(numberOfSendAsDefaults > 1 ? texts.moreThanOneDefault : texts.noDefault);

    return (
      <Dialog
        open={open}
        PaperComponent={Paper}
      >
        <DialogTitle>
          {format(
            readOnly ? texts.sentMsg : texts.sendMsg,
            {
              msgName: msgTemplate?.name || texts.msg,
            },
          )}
        </DialogTitle>
        <DialogContent>
          <Alert severity="warning">
            {numberOfSendAsDefaults > 1 ? texts.moreThanOneDefault : texts.noDefault}
          </Alert>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog
      open={open}
      PaperComponent={Paper}
    >
      <DialogTitle>
        {format(
          readOnly ? texts.sentMsg : texts.sendMsg,
          {
            msgName: msgTemplate?.name || texts.msg,
          },
        )}
      </DialogTitle>
      <DialogContent>
        <StyledStack spacing={2} component="form">
          {(successMsg || errMsg) && (
            <Alert onClose={() => clearAlerts()} severity={errMsg ? 'error' : 'success'}>
              {successMsg || errMsg}
            </Alert>
          )}
          {!!msgEndpoints?.length && (
            <FormControl fullWidth>
              <InputLabel id="sendAs">{texts.sendAs}</InputLabel>
              <Select
                labelId="sendAs"
                id="sendAs"
                value={sendAs}
                label="sendAs"
                onChange={(e) => setSendAs(e.target.value)}
                disabled={readOnly}
              >
                {(!userDoc.isSDR && !readOnly) && <MenuItem value="">{texts.asMyself}</MenuItem>}
                {msgEndpoints.map((p) => (
                  <MenuItem value={p.userId} key={p.userId}>{p.userName}</MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <FormControl fullWidth>
            <InputLabel id="provider">{texts.provider}</InputLabel>
            <Select
              labelId="provider"
              id="provider"
              value={msgProvider}
              label="provider"
              onChange={(e) => setMsgProvider(e.target.value)}
              disabled={readOnly}
            >
              {Object.values(providers).map((p) => (
                <MenuItem value={p} key={p}>{p}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <TextField
              id="contact"
              label={texts.contactNo}
              variant="outlined"
              value={contact}
              onChange={(e) => setContact(e.target.value)}
              disabled={readOnly}
            />
            {!isValidContactNo && (
              <FormHelperText error>
                {texts.errMsgs.invalidContact}
              </FormHelperText>
            )}
          </FormControl>
          <StyledNotesInput
            variant="outlined"
            label={texts.message}
            multiline
            rows={4}
            value={message}
            onChange={(evt) => setMessage(evt.target.value)}
            disabled={readOnly}
          />
          {!fileUrl && !readOnly && (
            <FileSelectButton
              multiple={false}
              ButtonComponent={IonButton}
              onSelect={onFilesSelected}
            >
              {texts.selectAttachment}
            </FileSelectButton>
          )}
          {fileAttachment && (
            <span>
              {fileAttachment.name}
            </span>
          )}
          {!!fileUrl && fileCategory === FileCategory.IMAGE && (
            <StyledImg src={fileUrl} alt="media" />
          )}
          {!!fileUrl && fileCategory === FileCategory.VIDEO && (
            <StyledVideo controls>
              <source src={fileUrl} />
            </StyledVideo>
          )}
        </StyledStack>
      </DialogContent>
      <DialogActions>
        <StyledButton onClick={onClose}>Close</StyledButton>
        {!readOnly && <StyledButton onClick={sendMsg} disabled={sendingMsg}>Send</StyledButton>}
      </DialogActions>
      <Backdrop open={sendingMsg}>
        <CircularProgress />
      </Backdrop>
    </Dialog>
  );
};

MsgModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  contactNo: PropTypes.string.isRequired,
  provider: PropTypes.string,
  msgTemplate: PropTypes.instanceOf(MsgTemplate),
  msgParams: PropTypes.object,
  onMsgSent: PropTypes.func,
  readOnly: PropTypes.bool,
  msgEndpoints: PropTypes.array,
  sendAsUserId: PropTypes.string,
  leadId: PropTypes.string,
};

MsgModal.defaultProps = {
  msgTemplate: null,
  msgParams: {},
  provider: null,
  onMsgSent: () => { },
  readOnly: false,
  msgEndpoints: [],
  sendAsUserId: null,
  leadId: null,
};

export default compose(
  observer,
)(MsgModal);
