import React, {
  useEffect,
  useCallback,
  useState,
} from 'react';
import * as Sentry from '@sentry/browser';
import { Formik, Form } from 'formik';

import { getQueryVariable } from '../../utils/queryParams';

import LoadingPage from '../../components/LoadingPage';
import FormikInput from '../../components/FormikInput';
import Button from '../../components/Button';

import useAuthentication, {
  SIGN_IN_LINK_EMAIL_ACCOUNT_KEY,
} from '../../hooks/useAuthentication';
import useNavigation from '../../hooks/useNavigation';

import {
  Container,
  StyledCard,
  Description,
} from './styles';

import { validationSchema, initialValues } from './validation';

import texts from './texts.json';

const AuthLinkValidator = () => {
  const { navigateTo, routes } = useNavigation();

  const {
    isSignInWithEmailLink,
    signInWithEmailLink,
  } = useAuthentication();

  const destination = getQueryVariable('destination') || routes.HOME;
  const storedEmail = window.localStorage.getItem(SIGN_IN_LINK_EMAIL_ACCOUNT_KEY);
  const shouldRedirectToHome = !isSignInWithEmailLink();

  const [userEmail, setUserEmail] = useState(storedEmail);

  const onSubmit = useCallback((data) => {
    setUserEmail(data.email);
  }, [
    setUserEmail,
  ]);

  useEffect(() => {
    if (shouldRedirectToHome) {
      navigateTo(routes.HOME);
    }
  }, [
    navigateTo,
    routes,
    shouldRedirectToHome,
  ]);

  useEffect(() => {
    const signIn = async (email) => {
      try {
        await signInWithEmailLink(email);
        const destinationURL = new URL(`${window.location.origin}${destination}`);
        window.location.href = destinationURL.href;
      } catch (err) {
        Sentry.captureException(err);
        navigateTo(routes.LOGIN);
      }
    };
    if (userEmail) {
      signIn(userEmail);
    }
  }, [
    navigateTo,
    routes,
    userEmail,
    destination,
    signInWithEmailLink,
  ]);

  if (userEmail || shouldRedirectToHome) {
    return <LoadingPage />;
  }

  return (
    <Container>
      <StyledCard>
        <Description>
          {texts.pleaseProvideEmail}
        </Description>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({
            errors, touched, isSubmitting,
          }) => (
            <Form>
              <FormikInput
                name="email"
                type="email"
                placeholder="Email"
                error={errors.email}
                touched={touched.email}
              />
              <Button disabled={isSubmitting} type="submit">{texts.confirm}</Button>
            </Form>
          )}
        </Formik>
      </StyledCard>
    </Container>
  );
};

export default AuthLinkValidator;
