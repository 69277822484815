import React, {
  useCallback,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';

import { ErrorType } from '../../utils/error';
import useNavigation from '../../hooks/useNavigation';

import {
  Container,
  BackgroundText,
  StyledErrorImage,
  Title,
  StyledButton,
  StyledBackIcon,
  ImageContainer,
} from './styles';

import texts from './texts.json';

const Error = ({
  errorType,
}) => {
  const {
    navigateTo,
    routes,
    search,
    state,
    pathname,
  } = useNavigation();

  const handleNavigation = useCallback(() => {
    navigateTo(routes.HOME, {
      search,
      state,
      replace: true,
    });
  }, [
    navigateTo,
    routes,
    search,
    state,
  ]);

  useEffect(() => {
    // Notify page not found errors on Sentry
    if (errorType === ErrorType.PAGE_NOT_FOUND) {
      Sentry.captureMessage('Page not found', {
        extra: {
          pathname,
          search,
          state: JSON.stringify(state),
        },
      });
    }
  }, [
    errorType,
    pathname,
    search,
    state,
  ]);

  return (
    <Container>
      <ImageContainer>
        {errorType === ErrorType.PAGE_NOT_FOUND && (
          <BackgroundText>{texts.pageNotFoundCode}</BackgroundText>
        )}
        <StyledErrorImage />
      </ImageContainer>
      <Title>{texts.title}</Title>
      <StyledButton
        onClick={handleNavigation}
        startIcon={<StyledBackIcon />}
      >
        {texts.goHome}
      </StyledButton>
    </Container>
  );
};

Error.propTypes = {
  errorType: PropTypes.string,
};

Error.defaultProps = {
  errorType: ErrorType.OTHER,
};

export default Error;
