import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import format from 'string-template';
import { observer } from 'mobx-react';
import { compose } from 'recompose';

import useComponentMounted from '../../../../hooks/useComponentMounted';
import useStorage from '../../../../hooks/useStorage';
import LoggedInUserContext from '../../../../context/LoggedInUserContext';
import {
  CardsContainer,
  Card,
  Container,
} from '../../../../components/v2/Card';
import { StyledTextFieldContainer } from '../../../../components/Inputs';
import InputLabel from '../../../../components/v2/InputLabel';
import {
  SaveButton,
  PrimaryButton,
} from '../../../../components/Button/ActionButtons';
import AppCustomTheme from '../../../../Model/AppCustomTheme';
import { isCRXAdmin } from '../../../../utils/postHog';
import config from '../../../../config';
import AppCustomization from '../../../Model/AppCustomization';
import useToast from '../../../hooks/useToast';
import LoadingOverlay from '../../../components/LoadingOverlay';
import AutoComplete from '../../../components/AutoComplete';
import ConfirmDialog from '../../../components/ConfirmDialog';
import MobileViewModal from '../../ClientInfo/components/MobileView';
import {
  HelperText,
  UpdatedSettings,
  UpdatedSettingItem,
  StyledLoadingPage,
} from '../styles';

import WebIconConfiguration from './WebIconConfiguration';
import { uploadImage } from './utils';
import texts from './texts.json';

const MobileApp = ({
  coach: {
    id: coachId,
    label: coachName,
  },
}) => {
  const isComponentMountedRef = useComponentMounted();
  const { showToast } = useToast();
  const { uploadAttachment } = useStorage();
  const { userDoc } = useContext(LoggedInUserContext);

  const [appThemesCollection, setAppThemesCollection] = useState({ docs: [] });
  const [coachAppCustomization, setCoachAppCustomization] = useState(null);
  const [isThemesReady, setIsThemesReady] = useState(false);
  const [isCustomizationDocReady, setIsCustomizationDocReady] = useState(false);
  const [coachAppTheme, setCoachAppTheme] = useState(null);
  const [imageToUpload, setImageToUpload] = useState(null);
  const [coachAppIconUrl, setCoachAppIconUrl] = useState('');
  const [showErrors, setShowErrors] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [showApp, setShowApp] = useState(false);

  const mobileUrl = useMemo(() => (
    `${config.mobileAppURL}/u/${coachId}`
  ), [
    coachId,
  ]);

  const isReadOnly = !isCRXAdmin();

  // Reset the component state when the coach is changed
  useEffect(() => {
    const resetState = () => {
      setIsCustomizationDocReady(false);
      setCoachAppCustomization(null);
      setCoachAppTheme(null);
      setImageToUpload(null);
      setCoachAppIconUrl('');
      setShowErrors(false);
    };

    if (coachId) {
      resetState();
    }
  }, [coachId]);

  useEffect(() => {
    const init = async () => {
      const themesCol = await AppCustomTheme.getAllThemes();
      if (isComponentMountedRef.current) {
        setAppThemesCollection(themesCol);
        setIsThemesReady(true);
      }
    };
    init();
  }, [isComponentMountedRef]);

  useEffect(() => {
    const getCoachCustomization = async () => {
      setIsCustomizationDocReady(false);
      const appCustomization = await AppCustomization.getAppCustomizationByCoach(coachId);
      if (isComponentMountedRef.current) {
        setCoachAppCustomization(appCustomization);
        setIsCustomizationDocReady(true);
      }
    };
    if (coachId) {
      getCoachCustomization();
    }
  }, [
    isComponentMountedRef,
    coachId,
  ]);

  useEffect(() => {
    if (coachAppCustomization?.baseTheme) {
      const coachTheme = appThemesCollection.docs.find((theme) => theme.id === coachAppCustomization.baseTheme);
      if (coachTheme) {
        setCoachAppTheme({ id: coachTheme.id, label: coachTheme.name });
      }
    }
  }, [
    appThemesCollection.docs,
    coachAppCustomization,
  ]);

  const appThemeOptions = useMemo(() => (
    appThemesCollection.docs.map((doc) => ({ id: doc.id, label: doc.name }))
  ), [appThemesCollection.docs]);

  const isThemeChanged = !!coachAppCustomization
    && coachAppCustomization.baseTheme !== coachAppTheme?.id;

  const errors = useMemo(() => {
    const errorTexts = [];

    if (!isThemeChanged && !imageToUpload) {
      errorTexts.push(texts.errors.noChange);
    } else {
      if (!coachAppTheme?.id) {
        errorTexts.push(texts.errors.themeRequired);
      }
      if (!coachAppIconUrl) {
        errorTexts.push(texts.errors.iconRequired);
      }
    }

    return errorTexts;
  }, [
    isThemeChanged,
    imageToUpload,
    coachAppTheme,
    coachAppIconUrl,
  ]);

  const dialogContent = useMemo(() => ({
    appTheme: isThemeChanged
      ? format(texts.appThemeChange, { theme: coachAppTheme?.label })
      : '',
    appIcon: imageToUpload ? format(texts.appIconChange) : '',
  }), [
    isThemeChanged,
    imageToUpload,
    coachAppTheme,
  ]);

  // Hide any shown errors whenever the settings are changed
  useEffect(() => {
    setShowErrors(false);
  }, [
    coachAppTheme,
    coachAppIconUrl,
  ]);

  const handleSaveClick = useCallback(() => {
    if (errors.length > 0) {
      setShowErrors(true);
      return;
    }
    setShowModal(true);
  }, [
    errors,
  ]);

  const handleSave = useCallback(async () => {
    try {
      setShowModal(false);
      setIsProcessing(true);
      if (imageToUpload) {
        const fileRef = await uploadImage(
          coachAppIconUrl,
          uploadAttachment,
          imageToUpload,
          coachId,
        );
        await coachAppCustomization.updateFields({
          webAppIconRef: fileRef,
          lastUpdatedByUserName: userDoc.name,
        });
        setImageToUpload(null);
      }
      if (isThemeChanged) {
        await coachAppCustomization.setBaseTheme(coachAppTheme.id, userDoc.name);
      }
      showToast(texts.saveSuccessful);
    } catch (error) {
      showToast(format(texts.saveFailed, { error }), { error: true });
    }
    setIsProcessing(false);
  }, [
    uploadAttachment,
    coachAppCustomization,
    coachAppTheme,
    coachAppIconUrl,
    imageToUpload,
    isThemeChanged,
    showToast,
    coachId,
    userDoc,
  ]);

  if (!isThemesReady || !isCustomizationDocReady) {
    return <StyledLoadingPage />;
  }

  return (
    <CardsContainer $fullWidth>
      <Card>
        <Container>
          <StyledTextFieldContainer $withMargin>
            <InputLabel>{texts.appTheme}</InputLabel>
            <AutoComplete
              initialValue={coachAppTheme?.id}
              options={appThemeOptions}
              onChange={(theme) => setCoachAppTheme(theme || {})}
              disabled={isReadOnly}
            />
          </StyledTextFieldContainer>
          <StyledTextFieldContainer $withMargin>
            <InputLabel>{texts.webIcon}</InputLabel>
            <WebIconConfiguration
              webAppIconRef={coachAppCustomization?.webAppIconRef}
              imageUrl={coachAppIconUrl}
              setImagePreview={setCoachAppIconUrl}
              fileToUpload={imageToUpload}
              setFileToUpload={setImageToUpload}
              isReadOnly={isReadOnly}
            />
          </StyledTextFieldContainer>
          {showErrors && (errors.map((error) => <HelperText key={error} error>{error}</HelperText>))}
          <SaveButton
            onClick={handleSaveClick}
            $fitToContent
            disabled={isReadOnly}
          >
            {texts.save}
          </SaveButton>
        </Container>
        <PrimaryButton
          onClick={() => setShowApp(true)}
          variant="link"
          $fitToContent
        >
          {texts.appPreview}
        </PrimaryButton>
      </Card>
      <MobileViewModal
        showModal={showApp}
        onClose={() => setShowApp(false)}
        mobileUrl={mobileUrl}
      />
      <ConfirmDialog
        isOpen={showModal}
        onConfirm={handleSave}
        onCancel={() => setShowModal(false)}
        dialogTexts={{
          title: format(texts.confirmTitle, { coach: coachName }),
          content: (
            <UpdatedSettings>
              {!!dialogContent.appTheme && <UpdatedSettingItem>{dialogContent.appTheme}</UpdatedSettingItem>}
              {!!dialogContent.appIcon && <UpdatedSettingItem>{dialogContent.appIcon}</UpdatedSettingItem>}
            </UpdatedSettings>
          ),
        }}
      />
      <LoadingOverlay isLoading={isProcessing} />
    </CardsContainer>
  );
};

MobileApp.propTypes = {
  coach: PropTypes.object.isRequired,
};

export default compose(
  observer,
)(MobileApp);
