import styled, { css } from 'styled-components';
import colors from '../../../../../styles/colors';

import { ReactComponent as PopoverIcon } from '../../assets/icon/popover-icon.svg';

const channelContentHeight = 40;

const ChannelPreviewContainer = styled.button`
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  padding: 10px;
  align-items: stretch;
  border: none;
  border-bottom: 0px solid ${colors.shades.secondary5};
  background-color: ${colors.base.alpha};

  ${({ isActive }) => isActive && css`
    background-color: ${colors.shades.secondary5};
    border-bottom: 1px solid ${colors.shades.secondary4};
  `}
  ${({ isAdmin }) => isAdmin && css`
    background-color: ${colors.base.primary};
  `}
`;

const ChannelDescription = styled.div`
  display: flex;
  flex-direction: row;
  flex-basis: 100%;
  justify-content: space-evenly;
  overflow: hidden;
  margin-left: 10px;
  min-height: ${channelContentHeight}px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  align-items: flex-start;
  justify-content: space-between;
  overflow: hidden;
  flex-grow: 1;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: flex-end;
  align-items: center;
  width: 30%;
`;

const ChannelName = styled.span`
  font-size: 16px;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  text-align: left;
  font-weight: 500;
  line-height: 18px;
`;

const LastMessageContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: space-between;

  ${({ transparent }) => transparent && css`
    color: transparent;
  `}
`;

const LastMessageText = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 10px;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  text-align: left;
`;

const LastMessageDate = styled.span`
  flex-shrink: 0;
  font-size: 10px;
  margin-right: 8px;
  color: ${({ isActive }) => (isActive ? colors.shades.gamma6 : colors.shades.gamma10)};
`;

const IconContainer = styled.div`
  display: flex;
  cursor: pointer;
`;

const UnreadBadge = styled.span`
  border-radius: 999px;
  background-color: ${colors.shades.primary3};
  border: 1px solid ${colors.shades.primary4};
  height: 18px;
  width: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
`;

const UnreadText = styled.span`
  font-size: 10px;
  font-weight: bold;
  color: ${colors.base.beta};
`;

const StyledChannelPopoverIcon = styled(PopoverIcon)`
  height: 28px;
  width: 20px;
  padding: 5px 0;
  > path {
    stroke: ${({ isAdmin }) => (isAdmin ? colors.shades.gamma10 : colors.shades.gamma6)};
  }
`;

export {
  ChannelPreviewContainer,
  ChannelDescription,
  TextContainer,
  InfoContainer,
  ChannelName,
  LastMessageContainer,
  LastMessageText,
  LastMessageDate,
  IconContainer,
  UnreadBadge,
  UnreadText,
  StyledChannelPopoverIcon,
};
