import { getQueryVariable } from './queryParams';

const OnboardingType = {
  SYSTEM2: 'SYSTEM2',
  EXTERNAL: 'EXTERNAL',
};

const externalCoach = getQueryVariable('externalCoach');

// User's anon id
const anonId = getQueryVariable('anonid');

const planCode = getQueryVariable('plan');

const leadId = getQueryVariable('leadId');

const contractId = getQueryVariable('contractId');

export {
  OnboardingType,
  externalCoach,
  anonId,
  planCode,
  leadId,
  contractId,
};
