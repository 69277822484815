import React from 'react';
import PropTypes from 'prop-types';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

const ShowAdornment = ({
  onClick,
  show,
}) => (
  <InputAdornment position="end">
    <IconButton
      onClick={onClick}
      edge="end"
    >
      {show ? <VisibilityOffIcon /> : <VisibilityIcon />}
    </IconButton>
  </InputAdornment>
);

ShowAdornment.propTypes = {
  onClick: PropTypes.func.isRequired,
  show: PropTypes.bool,
};

ShowAdornment.defaultProps = {
  show: false,
};

export default ShowAdornment;
