import React from 'react';
import PropTypes from 'prop-types';
import {
  CardContent,
} from '@mui/material';
import { Container, Title } from './styles';

const ChartContainer = ({
  title,
  children,
}) => (
  <Container>
    <CardContent>
      <Title>
        {title}
      </Title>
      {children}
    </CardContent>
  </Container>
);

ChartContainer.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export default ChartContainer;
