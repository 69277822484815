import React, {
  useState,
  useCallback,
} from 'react';

import useComponentMounted from '../../../../hooks/useComponentMounted';
import useToast from '../../../hooks/useToast';

import CoachReminderModal from '../CoachReminderModal';
import {
  CoachReminderButton,
  StyledContainer,
  StyledSpecialRemindersIcon,
} from './styles';
import text from './text.json';

const CoachReminder = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { showToast } = useToast();
  const isComponentMountedRef = useComponentMounted();

  const onSetReminderButtonClicked = () => {
    setIsModalOpen(true);
  };

  const onModalDismiss = () => {
    if (isComponentMountedRef.current) {
      setIsModalOpen(false);
    }
  };

  const showScheduledToast = useCallback(() => {
    showToast(text.reminderScheduled);
  }, [
    showToast,
  ]);

  const showErrorToast = useCallback((errorMessage = '') => {
    const toastText = errorMessage || text.reminderScheduleError;
    showToast(toastText, { error: true });
  }, [
    showToast,
  ]);

  return (
    <StyledContainer>
      <CoachReminderButton
        onClick={onSetReminderButtonClicked}
      >
        <StyledSpecialRemindersIcon />
        {text.reminder}
      </CoachReminderButton>
      <CoachReminderModal
        isOpen={isModalOpen}
        onDidDismiss={onModalDismiss}
        showReminderScheduled={showScheduledToast}
        showReminderError={showErrorToast}
      />
    </StyledContainer>
  );
};

export default CoachReminder;
