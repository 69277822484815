import styled from 'styled-components';
import colors from '../../../../../styles/colors';

const Container = styled.div`
  .str-chat__message-data, .str-chat__message-simple-data {
    display: none !important;
  }
  .str-chat__audio__content{
    width: 200px;
  }
`;

const TranscriptionText = styled.div`
  width: 280px;
  margin-top: 10px;
  padding: 0 10px;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  border-left: ${(props) => (props.$borderLeft ? `3px solid ${colors.shades.secondary6};` : 'none;')};
  border-right: ${(props) => (props.$borderRight ? `3px solid ${colors.shades.secondary6};` : 'none;')};
  margin-right: ${(props) => (props.$rightMargin ? '50px' : '0px')};
  margin-left: ${(props) => (props.$leftMargin ? '50px' : '0px')};
`;

const TranscriptionWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: ${(props) => (props.$alignRight ? 'flex-end' : 'flex-start')};
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 10px;
  justify-content: ${(props) => (props.$alignRight ? 'flex-end' : 'flex-start')}
`;

const LoaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  .MuiCircularProgress-svg{
    color: ${colors.base.secondary2};
  }
`;

export {
  Container,
  TranscriptionText,
  TranscriptionWrapper,
  ButtonContainer,
  LoaderContainer,
};
