import styled from 'styled-components';
import {
  List,
  ListItemText,
} from '@mui/material';

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

const StyledList = styled(List)`
  flex: 1;
  overflow: auto;
`;

const ViewAsRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledListItemText = styled(ListItemText)`
  text-transform: capitalize;
`;

export {
  Container,
  StyledList,
  ViewAsRow,
  StyledListItemText,
};
