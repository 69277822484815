import styled, { css } from 'styled-components';
import { IonSpinner } from '@ionic/react';

import colors from '../../styles/colors';
import { button } from '../../styles/text';

import variants from './variants';

const buttonVariant = ({ variant }) => {
  let backgroundColor;
  let color;

  switch (variant) {
    case variants.PRIMARY:
      backgroundColor = colors.base.primary;
      color = colors.base.secondary2;
      break;
    case variants.SECONDARY:
      backgroundColor = colors.base.secondary2;
      color = colors.base.alpha;
      break;
    case variants.DARK:
      backgroundColor = colors.shades.secondary3;
      color = colors.base.primary;
      break;
    case variants.LINK:
      backgroundColor = 'transparent';
      color = colors.base.secondary2;
      break;
    default:
      // primary variant by default
      backgroundColor = colors.base.primary;
      color = colors.base.secondary2;
  }

  return css`
    background-color: ${backgroundColor};
    color: ${color};
  `;
};

const buttonSize = ({ $full }) => ($full ? css`width: 100%;` : '');

const buttonStyles = css`
  ${button}
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  border: 0;
  ${(props) => buttonVariant(props)}
  ${(props) => buttonSize(props)}
  -webkit-tap-highlight-color: transparent;
  &:hover {
    opacity: 0.8;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    background: ${colors.base.support};
    color: ${colors.base.beta};
    opacity: 0.6;
  }
`;

const StyledButton = styled.button`
  ${buttonStyles}
`;

const Spinner = styled(IonSpinner)`
  margin-left: 10px;
`;

export default StyledButton;
export {
  buttonStyles,
  Spinner,
};
