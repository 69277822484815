import { useState, useMemo, useCallback } from 'react';
import { useIonToast } from '@ionic/react';
import MediaRecorderEncoder from 'opus-media-recorder';
import * as Sentry from '@sentry/browser';
// eslint-disable-next-line import/no-webpack-loader-syntax,import/no-unresolved,import/extensions
import EncoderWorker from 'worker-loader!opus-media-recorder/encoderWorker.js';

const MediaRecorderState = {
  NOT_INITIALIZED: 'notInitialized',
  RECORDING: 'recording',
  ERROR: 'error',
  INACTIVE: 'inactive',
};

const ErrorMessage = {
  DEFAULT: 'There was some problem in recording your voice message',
};

const useMediaRecorder = (onDataAvailable) => {
  const [state, setState] = useState(MediaRecorderState.NOT_INITIALIZED);

  const [webRecorder, setWebRecorder] = useState();

  const [present] = useIonToast();

  const showToast = useCallback((message) => {
    present({
      message,
      duration: 2000,
      color: 'warning',
    });
  }, [present]);

  const startRecording = useCallback(async () => {
    const encoderWorkerFactory = () => new EncoderWorker();

    const workerOptions = {
      encoderWorkerFactory,
      OggOpusEncoderWasmPath: `${process.env.PUBLIC_URL}/opus-media-recorder/OggOpusEncoder.wasm`,
      WebMOpusEncoderWasmPath: `${process.env.PUBLIC_URL}/opus-media-recorder/WebMOpusEncoder.wasm`,
    };
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    const options = { mimeType: 'audio/wave' };
    const recorder = new MediaRecorderEncoder(stream, options, workerOptions);
    recorder.addEventListener('dataavailable', (audioData) => {
      onDataAvailable(audioData);
    });
    recorder.addEventListener('error', (error) => {
      setState(MediaRecorderState.ERROR);
      showToast(ErrorMessage.DEFAULT);
      const description = 'Error while using the web MediaRecorder API in in-app-chat';
      Sentry.captureException(error, {
        extra: {
          description,
        },
      });
    });
    recorder.start();
    setWebRecorder(recorder);
    setState(MediaRecorderState.RECORDING);
  }, [
    onDataAvailable,
    showToast,
  ]);

  const stopRecording = useCallback(async () => {
    webRecorder.stop();
    setState(MediaRecorderState.INACTIVE);
  }, [
    webRecorder,
  ]);

  return useMemo(() => ({
    isRecording: state === MediaRecorderState.RECORDING,
    startRecording,
    stopRecording,
  }), [
    state,
    startRecording,
    stopRecording,
  ]);
};

export default useMediaRecorder;
