import React, { useCallback, useContext } from 'react';
import {
  useChannelStateContext,
  useChannelActionContext,
  MessageInput,
  Window,
  MessageList,
} from 'stream-chat-react';
import { logChatPromiseExecution } from 'stream-chat';

import MessagingChannelHeader from '../MessagingChannelHeader';
import MessagingInput from '../MessagingInput';
import MessagingThread from '../MessagingThread';
import ChatContext from '../../../../context/ChatContext';
import CustomMessage from '../CustomMessage';
import { FeedViewContainer } from './styles';

const ChatFeedView = () => {
  const { error } = useChannelStateContext();
  const { onChatError, isMultiChannelView } = useContext(ChatContext);

  if (error) {
    onChatError();
  }

  const {
    sendMessage,
  } = useChannelActionContext();

  const overrideSubmitHandler = useCallback((message) => {
    const sendMessagePromise = sendMessage(message);
    logChatPromiseExecution(sendMessagePromise, 'send message');
  }, [
    sendMessage,
  ]);

  return (
    <FeedViewContainer>
      <Window>
        <MessagingChannelHeader />
        <MessageList onlySenderCanEdit Message={CustomMessage} />
        <MessageInput
          focus={!isMultiChannelView}
          Input={MessagingInput}
          overrideSubmitHandler={overrideSubmitHandler}
        />
      </Window>
      <MessagingThread />
    </FeedViewContainer>
  );
};

export default ChatFeedView;
