import React, {
  useCallback,
  useEffect,
  useState,
  useContext,
} from 'react';
import PropTypes from 'prop-types';

import {
  Card,
  Container,
  HeaderContainer,
  Title,
  TitleContainer,
} from '../../../../../components/v2/Card';
import { StyledTextField, StyledTextFieldContainer } from '../../../../../components/Inputs';
import SaveButton from '../../../../../components/Button/ActionButtons/SaveButton';
import { ISConfigOption } from '../../../../../Model/Coach';
import useComponentMounted from '../../../../../hooks/useComponentMounted';
import LoggedInUserContext from '../../../../../context/LoggedInUserContext';
import InputLabel from '../../../../../components/v2/InputLabel';
import { isInsideSalesAdmin } from '../../../../../utils/postHog';
import LoadingOverlay from '../../../../components/LoadingOverlay';
import useToast from '../../../../hooks/useToast';
import MsgBody, { MsgPlaceholder } from '../../../../components/Templates/components/TemplateView/MsgBody';
import texts from './texts.json';

const DisqualificationEmailTemplate = ({ coachDoc }) => {
  const [contractLink, setContractLink] = useState('');
  const [emailSubject, setEmailSubject] = useState('');
  const [emailBody, setEmailBody] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  const { showToast } = useToast();
  const isComponentMountedRef = useComponentMounted();
  const { userDoc } = useContext(LoggedInUserContext);

  const isReadOnly = !isInsideSalesAdmin();

  useEffect(() => {
    setContractLink(coachDoc?.contractLink || '');
    setEmailSubject(coachDoc?.disqualificationMessageSubject || '');
    setEmailBody(coachDoc?.disqualificationMessageContent || '');
  }, [coachDoc]);

  const onSave = useCallback(async () => {
    if (!contractLink) {
      showToast(texts.contractLinkRequired, { error: true });
      return;
    }
    if (!emailSubject) {
      showToast(texts.emailSubjectRequired, { error: true });
      return;
    }
    if (!emailBody) {
      showToast(texts.emailBodyRequired, { error: true });
      return;
    }
    if (!contractLink.startsWith('https://')) {
      showToast(texts.contractLinkInvalid, { error: true });
      return;
    }
    setIsSaving(true);
    await coachDoc.set({
      leadEmailConfig: {
        [ISConfigOption.CONTRACT_LINK]: contractLink,
        [ISConfigOption.SUBJECT]: emailSubject,
        [ISConfigOption.MESSAGE]: emailBody,
      },
      lastUpdatedByUserName: userDoc.name,
    }, { merge: true });
    showToast(texts.saved, { type: 'success' });
    if (isComponentMountedRef.current) {
      setIsSaving(false);
    }
  }, [
    coachDoc,
    contractLink,
    emailBody,
    emailSubject,
    isComponentMountedRef,
    showToast,
    userDoc,
  ]);

  return (
    <Card>
      <HeaderContainer>
        <TitleContainer>
          <Title>{texts.title}</Title>
        </TitleContainer>
      </HeaderContainer>
      <Container>
        <StyledTextFieldContainer $withMargin>
          <InputLabel>{texts.contractLink}</InputLabel>
          <StyledTextField
            placeholder={texts.contractLinkPlaceholder}
            value={contractLink}
            onChange={(e) => setContractLink(e.target.value)}
            disabled={isReadOnly}
          />
        </StyledTextFieldContainer>
        <StyledTextFieldContainer $withMargin>
          <InputLabel>{texts.emailSubject}</InputLabel>
          <StyledTextField
            placeholder={texts.emailSubjectPlaceholder}
            value={emailSubject}
            onChange={(e) => setEmailSubject(e.target.value)}
            disabled={isReadOnly}
          />
        </StyledTextFieldContainer>
        <StyledTextFieldContainer $withMargin>
          <MsgBody
            msgBody={emailBody}
            onMsgBodyChange={setEmailBody}
            placeHolders={[MsgPlaceholder.LEAD_NAME, MsgPlaceholder.PURCHASE_LINK]}
            isReadOnly={isReadOnly}
          />
        </StyledTextFieldContainer>
        <SaveButton
          disabled={isReadOnly}
          onClick={onSave}
          $fitToContent
        >
          {texts.save}
        </SaveButton>
      </Container>
      <LoadingOverlay isLoading={!coachDoc || isSaving} />
    </Card>
  );
};

DisqualificationEmailTemplate.propTypes = {
  coachDoc: PropTypes.object,
};

DisqualificationEmailTemplate.defaultProps = {
  coachDoc: null,
};

export default DisqualificationEmailTemplate;
