import styled from 'styled-components';

import { ReactComponent as BaseCrossIcon } from '../../../assets/icons/cross-button-icon.svg';

import colors from '../../../styles/colors';

const CenterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CloseContainer = styled(CenterContainer)`
  position: absolute;
  z-index: 20002;
  top: 12px;
  right: 14px;
  width: 48px;
  height: 48px;
  opacity: ${({ show }) => (show ? 1 : 0)};
  transition: opacity 1s;
`;

const Circle = styled(CenterContainer)`
  width: 24px;
  height: 24px;
  background-color: ${colors.base.beta};
  border-radius: 50%;
  box-shadow: 0 1px 12px rgba(0, 0, 0, 0.1);
`;

const CrossIcon = styled(BaseCrossIcon)`
  path {
    fill: ${colors.base.alpha};
  }
  width: 20px;
  height: 20px;
`;

export {
  CloseContainer,
  Circle,
  CrossIcon,
};
