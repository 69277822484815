import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const NutritionFactTableContainer = styled.div`
  width: 100%;
  
  > section {
    width: 100%;
    float: none;
    margin: 0;
    box-sizing: border-box;
    
    h1 {
      font-family: "Arial Black", Verdana, monospace;
      letter-spacing: -1px;
    }
  }
`;

export {
  Container,
  NutritionFactTableContainer,
};
