import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { getCurrencySymbol } from '../../../../utils/formatters';
import { CURRENCIES } from '../../../../utils/currency';
import { StyledSelect } from './styles';

const Variant = {
  FULL: 'FULL',
  SYMBOL_ONLY: 'SYMBOL_ONLY',
};

const CurrencySelector = ({
  supportedCurrencies,
  className,
  onChange,
  value,
  variant,
}) => {
  const currencyOptions = useMemo(() => CURRENCIES
    .filter(({ code }) => supportedCurrencies.includes(code))
    .map((currency) => ({
      value: currency.code,
      label: `${currency.name} (${currency.code})`,
    })), [supportedCurrencies]);

  return (
    <StyledSelect
      className={className}
      options={currencyOptions}
      onChange={onChange}
      value={value}
      renderValue={variant === Variant.SYMBOL_ONLY ? (val) => getCurrencySymbol(val) : undefined}
    />
  );
};

CurrencySelector.propTypes = {
  supportedCurrencies: PropTypes.array,
  className: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  variant: PropTypes.oneOf(Object.values(Variant)),
};

CurrencySelector.defaultProps = {
  supportedCurrencies: [],
  className: '',
  onChange: () => {},
  value: '',
  variant: Variant.FULL,
};

export default CurrencySelector;
export { Variant };
