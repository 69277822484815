import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { observer } from 'mobx-react';

import InfoItem from './InfoItem';

import {
  Container,
  InfoContainer,
  InfoWrapper,
  Name,
} from './styles';
import texts from './texts.json';

const EMPTY_CELL = '-';

const UserInfoSection = ({ userData }) => {
  const {
    name,
    email,
    country,
    phoneNumber,
    ageRange,
    gender,
    age,
    address,
  } = userData;

  return (
    <Container>
      <InfoContainer>
        <InfoWrapper>
          <Name>{name}</Name>
        </InfoWrapper>
        <InfoWrapper>
          <InfoItem label={texts.gender}>
            {gender || EMPTY_CELL}
          </InfoItem>
          <InfoItem label={texts.age}>
            {ageRange || age || EMPTY_CELL}
          </InfoItem>
          <InfoItem label={texts.location}>
            {country || address || EMPTY_CELL}
          </InfoItem>
          <InfoItem label={texts.email}>
            {email || EMPTY_CELL}
          </InfoItem>
          <InfoItem label={texts.phone}>
            {phoneNumber || EMPTY_CELL}
          </InfoItem>
        </InfoWrapper>
      </InfoContainer>
    </Container>
  );
};

UserInfoSection.propTypes = {
  userData: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    country: PropTypes.string,
    phoneNumber: PropTypes.string,
    ageRange: PropTypes.string,
    gender: PropTypes.string,
    age: PropTypes.string,
    address: PropTypes.string,
  }),
};

UserInfoSection.defaultProps = {
  userData: {},
};

export default compose(
  observer,
)(UserInfoSection);
