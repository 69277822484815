import styled from 'styled-components';

import Input from '../Input';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledInput = styled(Input)`
  width: 100px;

  .MuiInputAdornment-positionEnd {
    min-width: unset;
  }
`;

export {
  Container,
  StyledInput,
};
