import React from 'react';

import FeedContextProvider from './FeedContextProvider';

const withFeedContextProvider = (Component) => (props) => (
  <FeedContextProvider>
    <Component {...props} />
  </FeedContextProvider>
);

export default withFeedContextProvider;
