import React from 'react';

import StyledDataGrid from './styles';

const GenericDataGrid = (props) => (
  <StyledDataGrid
    {...props}
  />
);

export default GenericDataGrid;
