import React from 'react';
import PropTypes from 'prop-types';

import useAppLoaded from '../../../hooks/useAppLoaded';

import {
  CloseContainer,
  Circle,
  CrossIcon,
} from './styles';

const ExitButton = ({
  onClick,
}) => {
  const { isAppLoaded } = useAppLoaded();

  return (
    <CloseContainer
      show={isAppLoaded}
      onClick={onClick}
    >
      <Circle>
        <CrossIcon />
      </Circle>
    </CloseContainer>
  );
};

ExitButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default ExitButton;
