import React, {
  memo,
  useCallback,
} from 'react';
import { Container as DraggableContainer } from 'react-smooth-dnd';
import PropTypes from 'prop-types';

import { MealPlanType } from '../../../../../../../Model/MealPlan';
import DraggableRow from '../DraggableRow';
import CreationRow from '../CreationRow';

import { ItemTypes } from '../../utils';
import {
  StyledContainer,
  LabelsRow,
  FirstLabel,
  SecondLabel,
  ThirdLabel,
} from './styles';
import texts from './texts.json';

const DraggableSortedList = memo((
  {
    mealTimes,
    setMealTimes,
    isModalView,
    templateType,
  },
) => {
  const moveMealTime = useCallback(
    (removedIndex, addedIndex) => {
      const mealTime = mealTimes[removedIndex];
      setMealTimes(() => {
        const updatedMealTimes = [...mealTimes];
        updatedMealTimes.splice(removedIndex, 1);
        updatedMealTimes.splice(addedIndex, 0, mealTime);
        return updatedMealTimes;
      });
    },
    [
      mealTimes,
      setMealTimes,
    ],
  );

  const onChangeField = useCallback(
    (index, field, value) => {
      setMealTimes(() => {
        const updatedMealTimes = [...mealTimes];
        updatedMealTimes[index][field] = value;
        return updatedMealTimes;
      });
    },
    [
      mealTimes,
      setMealTimes,
    ],
  );

  const onCreateMealTime = useCallback(() => {
    setMealTimes(() => {
      const updatedMealTimes = [...mealTimes];
      updatedMealTimes.push({
        caloricSplit: 0,
        name: '',
        meals: [],
      });
      return updatedMealTimes;
    });
  }, [
    mealTimes,
    setMealTimes,
  ]);

  const onRemoveMealTime = useCallback(
    (index) => {
      setMealTimes(() => {
        const updatedMealTimes = [...mealTimes];
        updatedMealTimes.splice(index, 1);
        return updatedMealTimes;
      });
    },
    [
      mealTimes,
      setMealTimes,
    ],
  );

  const isLive = templateType === MealPlanType.LIVE;

  return (
    <DraggableContainer
      dragHandleSelector={`.${ItemTypes.DRAGGABLE}`}
      lockAxis="y"
      onDrop={({ removedIndex, addedIndex }) => moveMealTime(removedIndex, addedIndex)}
      render={(setRender) => (
        <>
          {!isModalView && (
            <LabelsRow>
              <FirstLabel>{texts.mealTime}</FirstLabel>
              <SecondLabel>{texts.caloriesSplit}</SecondLabel>
              {isLive && <ThirdLabel>{texts.noOfRecipes}</ThirdLabel>}
            </LabelsRow>
          )}
          <StyledContainer ref={setRender} $isModalView={isModalView}>
            {mealTimes.map(({ caloricSplit, name, recipesAmount }, idx) => (
              <DraggableRow
                // eslint-disable-next-line react/no-array-index-key
                key={`${idx}--row`}
                index={idx}
                caloricSplit={caloricSplit}
                recipesAmount={recipesAmount}
                name={name}
                onChange={onChangeField}
                onRemove={onRemoveMealTime}
                isModalView={isModalView}
                templateType={templateType}
              />
            ))}
            <CreationRow
              onClick={onCreateMealTime}
              isModalView={isModalView}
            />
          </StyledContainer>
        </>
      )}
    />
  );
});

DraggableSortedList.propTypes = {
  mealTimes: PropTypes.array.isRequired,
  setMealTimes: PropTypes.func.isRequired,
  templateType: PropTypes.string.isRequired,
  isModalView: PropTypes.bool,
};
DraggableSortedList.defaultProps = {
  isModalView: false,
};

export default DraggableSortedList;
