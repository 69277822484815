import { Autocomplete } from '@mui/material';
import styled from 'styled-components';

import colors from '../../../../../styles/colors';

const StyledFeedFilter = styled(Autocomplete)`
  .MuiOutlinedInput-root {
    background-color: white;
    .MuiAutocomplete-input {
      padding: 5px 14px;
    }
    .MuiOutlinedInput-notchedOutline {
      border-color: ${colors.base.secondary2};
    }
  }

  .MuiInputLabel-outlined {
    color: ${colors.base.secondary2};
  }
`;

export {
  StyledFeedFilter,
};
