import PropTypes from 'prop-types';

import { getFileType } from './files';

const getProcessedAttachmentData = ({
  fileName,
  contentType,
  pathRef,
  fileSize,
}) => {
  const fileType = getFileType(contentType); // image, video, audio, etc...
  return {
    type: fileType,
    title: fileName,
    assetPath: pathRef,
    fileSize,
    mimeType: contentType,
  };
};

const AttachmentType = {
  AUDIO: 'audio',
  FILE: 'file',
  IMAGE: 'image',
  VIDEO: 'video',
};

const attachmentShape = PropTypes.shape({
  type: PropTypes.oneOf(Object.values(AttachmentType)),
  fileSize: PropTypes.number,
  assetPath: PropTypes.string,
  title: PropTypes.string,
  mimeType: PropTypes.string,
});

const attachmentPreviewPropTypes = {
  attachmentData: attachmentShape,
  assetLocalUrl: PropTypes.string.isRequired,
};

export {
  getProcessedAttachmentData,
  AttachmentType,
  attachmentShape,
  attachmentPreviewPropTypes,
};
