import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import useLogger from './useLogger';

const usePageLoad = () => {
  const { logPage } = useLogger();
  const location = useLocation();

  useEffect(() => {
    logPage(location.pathname.split('/')[1]);
  }, [
    logPage,
    location.pathname,
  ]);
};

export default usePageLoad;
