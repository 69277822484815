import React from 'react';

import { Title } from '../styles';

import DefaultMealPlan from './components/DefaultMealPlan';
import MealPlanIntroductionConfig from './components/MealPlanIntroductionConfig';
import CalorieRange from './components/CalorieRange';
import NutritionInfoToggles from './components/NutritionInfoToggles';
import RefreshInterval from './components/RefreshInterval';

import {
  Container,
  ContentContainer,
  SettingsHeaderContainer,
} from './styles';
import texts from './texts.json';

const Settings = () => (
  <Container>
    <SettingsHeaderContainer>
      <Title>{texts.title}</Title>
    </SettingsHeaderContainer>
    <ContentContainer>
      <MealPlanIntroductionConfig />
      <DefaultMealPlan />
      <CalorieRange />
      <NutritionInfoToggles />
      <RefreshInterval />
    </ContentContainer>
  </Container>
);

export default Settings;
