import React, {
  useMemo,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import format from 'string-template';
import ReactHtmlParser from 'react-html-parser';

import QuickSearchToolbar from '../../QuickSearchToolbar';

import {
  Container,
  FiltersDetails,
  DetailsText,
  StyledButton,
  StyledTag,
  StyledCrossIcon,
} from './styles';
import texts from './texts.json';

const RecipesTableToolbar = ({
  macroFilters,
  mealFilters,
  restrictionFilters,
  onClearMacroFilters,
  onClearMealFilters,
  onClearRestrictionFilters,
  ...toolbarProps
}) => {
  const { mealOptions = {}, mealOptionValues = {} } = mealFilters;
  const { restrictionOptions = {}, restrictionOptionValues = {} } = restrictionFilters;

  const macroFilterText = useMemo(() => {
    if (macroFilters) {
      const {
        proteinRange: {
          from: proteinFrom,
          to: proteinTo,
        },
        carbsRange: {
          from: carbsFrom,
          to: carbsTo,
        },
        fatRange: {
          from: fatFrom,
          to: fatTo,
        },
        dietName,
      } = macroFilters;

      const macrosDetailsText = dietName || format(texts.macrosDetails, {
        proteinFrom,
        proteinTo,
        carbsFrom,
        carbsTo,
        fatFrom,
        fatTo,
      });

      return ReactHtmlParser(format(texts.searchResults, { detail: macrosDetailsText }));
    }
    return '';
  }, [
    macroFilters,
  ]);

  const mealFilterText = useMemo(() => {
    if (mealOptions.length < mealOptionValues.length) {
      const meals = mealOptions.map((meal) => {
        const matchingOption = mealOptionValues.find((option) => option.value === meal);
        return matchingOption ? matchingOption.label : '';
      }).join(', ');

      return ReactHtmlParser(format(texts.mealTimeResults, { detail: meals }));
    }
    return '';
  }, [
    mealOptions,
    mealOptionValues,
  ]);

  const restrictionFilterText = useMemo(() => {
    if (restrictionOptions.length > 0) {
      const firstOptions = restrictionOptions.slice(0, 2);

      let restrictions = firstOptions.map((rst) => {
        const matchingOption = restrictionOptionValues.find((option) => option.value === rst);
        return matchingOption ? matchingOption.label : '';
      }).join(', ');

      if (restrictionOptions.length > 2) {
        restrictions = `${restrictions} (+${restrictionOptions.length - 2} more)`;
      }

      return ReactHtmlParser(format(texts.restrictionResults, { detail: restrictions }));
    }
    return '';
  }, [
    restrictionOptions,
    restrictionOptionValues,
  ]);

  const handleClearAllFilters = useCallback(() => {
    onClearMacroFilters();
    onClearMealFilters();
    onClearRestrictionFilters();
  }, [
    onClearMacroFilters,
    onClearMealFilters,
    onClearRestrictionFilters,
  ]);

  return (
    <Container>
      <QuickSearchToolbar {...toolbarProps} />
      {(macroFilters || mealOptionValues.length > mealOptions.length > 0 || restrictionOptions.length > 0) && (
        <FiltersDetails>
          {mealOptionValues.length > mealOptions.length > 0 && (
            <StyledTag>
              <DetailsText>{mealFilterText}</DetailsText>
              <StyledButton>
                <StyledCrossIcon onClick={onClearMealFilters} />
              </StyledButton>
            </StyledTag>
          )}
          {restrictionOptions.length > 0 && (
            <StyledTag>
              <DetailsText>{restrictionFilterText}</DetailsText>
              <StyledButton>
                <StyledCrossIcon onClick={onClearRestrictionFilters} />
              </StyledButton>
            </StyledTag>
          )}
          {macroFilters && (
            <StyledTag>
              <DetailsText>{macroFilterText}</DetailsText>
              <StyledButton>
                <StyledCrossIcon onClick={onClearMacroFilters} />
              </StyledButton>
            </StyledTag>
          )}
          <StyledTag>
            <DetailsText>{texts.clearFilters}</DetailsText>
            <StyledButton>
              <StyledCrossIcon onClick={handleClearAllFilters} />
            </StyledButton>
          </StyledTag>
        </FiltersDetails>
      )}
    </Container>
  );
};

RecipesTableToolbar.propTypes = {
  macroFilters: PropTypes.object,
  mealFilters: PropTypes.object,
  restrictionFilters: PropTypes.object,
  onClearMacroFilters: PropTypes.func,
  onClearMealFilters: PropTypes.func,
  onClearRestrictionFilters: PropTypes.func,
};

RecipesTableToolbar.defaultProps = {
  macroFilters: null,
  mealFilters: null,
  restrictionFilters: null,
  onClearMacroFilters: () => {},
  onClearMealFilters: () => {},
  onClearRestrictionFilters: () => {},
};

export default RecipesTableToolbar;
