import styled, { css } from 'styled-components';

import { LoadingButton } from '../../../../../components/Button';
import { ReactComponent as CreditCardIcon } from '../../../../../assets/icons/creditCardIcon.svg';
import { ReactComponent as PaypalLogoIcon } from '../../../../../assets/icons/paypalLogo.svg';
import colors from '../../../../../styles/colors';

import ApplePayButton from '../ApplePayButton';

const StyledOnboardingCard = styled.div`
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 20px;
  margin-top: 10px;
  width: 100%;
`;

const checkoutActionsStyles = css`
  &:hover {
    opacity: 1;
    ${(isLoading) => !isLoading && css`
      filter: contrast(120%);
    `}
  }
  cursor: pointer;
  border-radius: 5px;
`;

const StyledButton = styled(LoadingButton)`
  ${checkoutActionsStyles}
  font-size: 16px;
  background: ${colors.base.primary};
  width: 100%;
  height: 100%;
  border: 1px solid ${colors.shades.primary2};
  ${({ disabled }) => disabled && css`
    border: unset;
  `}
`;

const StyledApplePayButton = styled(ApplePayButton)`
  ${checkoutActionsStyles}
  height: auto;
  width: 100%;
`;

const CreditCard = styled(CreditCardIcon)`
  width: 23px;
  margin-left: 7px;
`;

export {
  StyledOnboardingCard,
  StyledButton,
  CreditCard,
  StyledApplePayButton,
  PaypalLogoIcon,
};
