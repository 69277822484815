import React, {
  useState,
  useCallback,
  useContext,
} from 'react';

import MealPlanContext from '../../../../../context/MealPlanContext';
import useToast from '../../../../../hooks/useToast';
import RadioButtonGroup from '../../../../../components/RadioButtonGroup';
import {
  TitledActionContainer,
  TitledActionContainerHeader,
} from '../../../styles';

import {
  ToggleOption,
  ToggleSetting,
} from './utils';
import {
  StyledTitledActionContainerSection,
  SettingContainer,
  StyledTypography,
} from './styles';
import texts from './texts';

const toggleOptions = (setting) => Object.values(ToggleOption).map((option) => ({
  label: texts.button[setting][option],
  value: option,
}));

const NutritionInfoToggles = () => {
  const {
    mealPlanConfig,
    saveMealPlanConfig,
  } = useContext(MealPlanContext);

  const [toggleSettings, setToggleSettings] = useState({
    [ToggleSetting.MACROS]: mealPlanConfig[ToggleSetting.MACROS] ? ToggleOption.DISABLE : ToggleOption.ENABLE,
    [ToggleSetting.RECIPE]: mealPlanConfig[ToggleSetting.RECIPE] ? ToggleOption.DISABLE : ToggleOption.ENABLE,
  });

  const { showToast } = useToast();

  const handleToggle = useCallback(async (setting, value) => {
    setToggleSettings((prevState) => ({
      ...prevState,
      [setting]: value,
    }));

    saveMealPlanConfig({ [setting]: value === ToggleOption.DISABLE });
    showToast(texts.saved);
  }, [
    saveMealPlanConfig,
    showToast,
  ]);

  return (
    <TitledActionContainer>
      <TitledActionContainerHeader>{texts.header}</TitledActionContainerHeader>
      <StyledTitledActionContainerSection>
        {Object.values(ToggleSetting).map((setting, index) => (
          <SettingContainer key={setting} $top={index === 0}>
            <StyledTypography>{texts.description[setting]}</StyledTypography>
            <RadioButtonGroup
              options={toggleOptions(setting)}
              selectedOption={toggleSettings[setting]}
              onOptionChange={(value) => handleToggle(setting, value)}
            />
          </SettingContainer>
        ))}
      </StyledTitledActionContainerSection>
    </TitledActionContainer>
  );
};

export default NutritionInfoToggles;
