import { SalesStage, FinalStatusSalesStages } from '../../Model/Lead';

const SalesStagesOptions = {
  NEW_LEADS: 'NEW_LEADS',
  INVALID_CONTACT: 'INVALID_CONTACT',
  FIRST_MESSAGE: 'FIRST_MESSAGE',
  SECOND_MESSAGE: 'SECOND_MESSAGE',
  THIRD_MESSAGE: 'THIRD_MESSAGE',
  IN_DIALOGUE: 'IN_DIALOGUE',
  CALENDLY_SCHEDULED: 'CALENDLY_SCHEDULED',
  DUPLICATE: 'DUPLICATE',
  FINAL_STATUS: 'FINAL_STATUS',
  ALL: 'ALL',
};

const salesStagesFilterChecks = {
  [SalesStagesOptions.ALL]: () => true,
  [SalesStagesOptions.NEW_LEADS]: ({ salesStage }) => SalesStage.NEW_LEAD === salesStage,
  [SalesStagesOptions.INVALID_CONTACT]: ({ salesStage }) => SalesStage.INVALID_CONTACT === salesStage,
  [SalesStagesOptions.FIRST_MESSAGE]: ({ salesStage }) => SalesStage.FIRST_MESSAGE === salesStage,
  [SalesStagesOptions.SECOND_MESSAGE]: ({ salesStage }) => SalesStage.SECOND_MESSAGE === salesStage,
  [SalesStagesOptions.THIRD_MESSAGE]: ({ salesStage }) => SalesStage.THIRD_MESSAGE === salesStage,
  [SalesStagesOptions.IN_DIALOGUE]: ({ salesStage }) => SalesStage.IN_DIALOGUE === salesStage,
  [SalesStagesOptions.DUPLICATE]: ({ salesStage }) => SalesStage.DUPLICATE === salesStage,
  [SalesStagesOptions.CALENDLY_SCHEDULED]: ({ salesStage }) => SalesStage.CALENDLY_SCHEDULED === salesStage,
  [SalesStagesOptions.FINAL_STATUS]: ({ salesStage }) => FinalStatusSalesStages.includes(salesStage),
};

export {
  SalesStagesOptions,
  salesStagesFilterChecks,
  FinalStatusSalesStages,
};
