import { formatCurrencyCents } from '../../../utils/formatters';

class CoachRevenueData {
  constructor(data) {
    this.data = data;
  }

  get payments() {
    return this.data.payments || 0;
  }

  get currency() {
    return this.data.currency;
  }

  // Revenue value in cents
  get revenue() {
    return this.data.revenue || 0;
  }

  // Revenue value in units of currency, with currency symbol ($1, €1, etc)`
  get revenueWithCurrency() {
    return formatCurrencyCents(this.revenue, this.currency, { minimumFractionDigits: 2 });
  }

  // Revenue amount in units of the configured currency (1 Dollar, 1 Euro, etc)
  get revenueAmt() {
    return this.revenue / 100 || 0;
  }

  get avgPayment() {
    return formatCurrencyCents(this.payments ? this.revenue / this.payments : 0,
      this.currency, { minimumFractionDigits: 2 });
  }

  get netRevenueAmtInCents() {
    return this.data.netRevenue || 0;
  }

  get netRevenue() {
    return formatCurrencyCents(this.netRevenueAmtInCents, this.currency, { minimumFractionDigits: 2 });
  }

  get applicationFee() {
    return this.data.applicationFee || 0;
  }

  get applicationFeeWithCurrency() {
    return formatCurrencyCents(this.applicationFee, this.currency, { minimumFractionDigits: 2 });
  }

  get stripeFee() {
    return formatCurrencyCents(this.data.stripeFee || 0, this.currency, { minimumFractionDigits: 2 });
  }
}

export default CoachRevenueData;
