import { Collection, getFirebase } from 'firestorter';
import Collections from '../../utils/collections';
import BaseDocument from '../../Model/BaseDocument';
import { getBySchema } from '../utils/mealPlan';
import { getMacroNutrientsFromCalories } from '../utils/meals';

class UserNutritionMacroGoals extends BaseDocument {
  constructor(id, opts) {
    super(`${Collections.USER_NUTRITION_MACRO_GOALS}/${id}`, opts);
  }

  get history() {
    return this.data.history || [];
  }

  get bmr() {
    return this.data.bmr || 0;
  }

  get tdee() {
    return this.data.tdee || 0;
  }

  get carbs() {
    return this.data.carbs || 0;
  }

  get fat() {
    return this.data.fat || 0;
  }

  get protein() {
    return this.data.protein || 0;
  }

  get totalCarbsCalories() {
    return this.data.totalCarbsCalories || 0;
  }

  get totalProteinCalories() {
    return this.data.totalProteinCalories || 0;
  }

  get totalFatCalories() {
    return this.data.totalFatCalories || 0;
  }

  get macroAverages() {
    return this.data.macroAverages || {};
  }

  get totalDailyCalories() {
    const totalCaloriesValue = this.data.totalDailyCalories
      || (this.totalCarbsCalories + this.totalProteinCalories + this.totalFatCalories);

    return Math.round(totalCaloriesValue);
  }

  get totalDailyCaloriesUpdatedAt() {
    return this.data.totalDailyCaloriesUpdatedAt;
  }

  get calculatedDailyCalories() {
    return Math.round(this.data.calculatedDailyCalories || 0);
  }

  get proteinPercentage() {
    return this.data.proteinPercentage || 0;
  }

  get carbsPercentage() {
    return this.data.carbsPercentage || 0;
  }

  get fatPercentage() {
    return this.data.fatPercentage || 0;
  }

  get updatedMacroAveragesAt() {
    return this.data.updatedMacroAveragesAt;
  }

  resetMacroGoals(mealPlanId, mealPlanName) {
    this.saveHistory(mealPlanId, mealPlanName);
    return this.updateFields({
      macroAverages: getFirebase().firestore.FieldValue.delete(),
      totalDailyCalories: this.calculatedDailyCalories,
      updatedMacroAveragesAt: new Date(),
    });
  }

  resetMacrosDistribution() {
    return this.updateFields({
      proteinPercentage: getFirebase().firestore.FieldValue.delete(),
      carbsPercentage: getFirebase().firestore.FieldValue.delete(),
      fatPercentage: getFirebase().firestore.FieldValue.delete(),
    });
  }

  saveTotalDailyCalories(caloriesValue) {
    return this.updateFields({
      totalDailyCalories: caloriesValue,
      totalDailyCaloriesUpdatedAt: new Date(),
    });
  }

  updateMacroGoals(fields) {
    return this.updateFields({
      ...fields,
      macroAverages: fields?.macroAverages || getFirebase().firestore.FieldValue.delete(),
    });
  }

  getCurrentGoals(mealPlanId = '', mealPlanName = '', mealPlanMacroAverages) {
    const macroAveragesSchema = {
      percentages: {
        protein: null,
        carbs: null,
        fat: null,
      },
      macros: {
        protein: null,
        carbs: null,
        fat: null,
      },
    };

    const currentGoals = {
      mealPlanId,
      mealPlanName,
      totalDailyCalories: this.totalDailyCalories || 0,
      calculatedDailyCalories: this.calculatedDailyCalories || 0,
      updatedMacroAveragesAt: this.updatedMacroAveragesAt || null,
    };

    // Get macros using meal plan percentages and target calories
    const currentMacroAverages = {
      percentages: mealPlanMacroAverages?.percentages || {},
      macros: (this.totalDailyCalories && mealPlanMacroAverages?.percentages)
        ? getMacroNutrientsFromCalories(this.totalDailyCalories, mealPlanMacroAverages.percentages)
        : mealPlanMacroAverages?.macros || {},
    };

    if (Object.keys(this.macroAverages).length === 0) {
      currentGoals.isRecommended = true;
      currentGoals.macroAverages = getBySchema(macroAveragesSchema, currentMacroAverages);
    } else {
      // Save custom macros as macroAverages
      currentGoals.macroAverages = getBySchema(macroAveragesSchema, this.macroAverages);
      // If a custom goal is set, save meal plan macros as previousMacroAverages
      currentGoals.previousMacroAverages = getBySchema(macroAveragesSchema, currentMacroAverages);
    }
    return currentGoals;
  }

  saveHistory = (mealPlanId, mealPlanName, mealPlanMacroAverages = {}) => (
    this.updateFields({
      history: [...this.history, this.getCurrentGoals(mealPlanId, mealPlanName, mealPlanMacroAverages)],
    })
  )

  static async getById(id) {
    const macroGoalsDoc = new UserNutritionMacroGoals(id);
    await macroGoalsDoc.init();
    return macroGoalsDoc.exists ? macroGoalsDoc : null;
  }

  static async getMacroGoalsByCoach(coachId) {
    const collection = new Collection(Collections.USER_NUTRITION_MACRO_GOALS, {
      createDocument: ({ id }, opts) => new UserNutritionMacroGoals(id, opts),
      query: (ref) => ref.where('coach', '==', coachId),
    });
    await collection.fetch();
    return collection;
  }
}

export default UserNutritionMacroGoals;
