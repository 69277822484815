import React, {
  useState,
  useCallback,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';

import texts from './texts.json';
import {
  StyledPaper,
  Container,
} from './styles';

const QuestionInputModal = ({
  isOpen,
  onCancel,
  dialogTexts,
  onConfirm,
  question,
  prompt,
}) => {
  const [questionText, setQuestionText] = useState(question);
  const [promptText, setPromptText] = useState(prompt);

  useEffect(() => {
    setQuestionText(question);
    setPromptText(prompt);
  }, [
    question,
    prompt,
  ]);

  const handleConfirm = useCallback(async () => {
    await onConfirm(questionText, promptText);
    if (!question && !prompt) {
      setPromptText('');
      setQuestionText('');
    }
  }, [
    questionText,
    promptText,
    onConfirm,
    question,
    prompt,
  ]);

  return (
    <Dialog
      open={isOpen}
      PaperComponent={StyledPaper}
    >
      <DialogTitle>
        {dialogTexts.title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {dialogTexts.content}
        </DialogContentText>
        <Container>
          <TextField
            label={texts.question}
            variant="outlined"
            onChange={(event) => setQuestionText(event.target.value)}
            value={questionText}
            fullWidth
            required
            multiline
            maxRows={1}
            minRows={2}
            placeholder={texts.questionPlaceholder}
            error={!questionText}
            helperText={!questionText ? texts.required : ''}
          />
        </Container>
        <Container>
          <TextField
            label={texts.prompt}
            variant="outlined"
            onChange={(event) => setPromptText(event.target.value)}
            value={promptText}
            fullWidth
            required
            minRows={1}
            maxRows={4}
            multiline
            placeholder={texts.promptPlaceholder}
            error={!promptText}
            helperText={!promptText ? texts.required : ''}
          />
        </Container>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleConfirm}
          color="success"
          disabled={!questionText || !promptText}
        >
          {dialogTexts.confirm || texts.confirm}
        </Button>
        <Button
          onClick={onCancel}
          color="warning"
        >
          {dialogTexts.cancel || texts.cancel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

QuestionInputModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  dialogTexts: PropTypes.shape({
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    confirm: PropTypes.string,
    cancel: PropTypes.string,
  }).isRequired,
  onConfirm: PropTypes.func.isRequired,
  question: PropTypes.string,
  prompt: PropTypes.string,
};

QuestionInputModal.defaultProps = {
  question: '',
  prompt: '',
};

export default QuestionInputModal;
