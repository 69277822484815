import moment from 'moment';
import { StripeSubscriptionState } from '../../pages/Onboarding/Subscription/SubscriptionMetadata';

const NEW_CLIENTS_FOR_N_DAYS = 7; // Counts as a new client for N days after service is started.

const ClientStatus = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  ON_BREAK: 'ON_BREAK',
  DELINQUENT: 'DELINQUENT',
  CANCELED: 'CANCELED',
  PAST_DUE: 'PAST_DUE',
  FUTURE_START: 'FUTURE_START',
  INCOMPLETE: 'INCOMPLETE',
  FUTURE_CANCELLATION: 'FUTURE_CANCELLATION',
  MONTHLY_START: 'MONTHLY_START',
};

const ClientExcludingFilters = {
  NEW_CLIENT: 'NEW_CLIENT',
};

const clientStatusLabel = {
  [ClientStatus.ACTIVE]: 'Active',
  [ClientStatus.INACTIVE]: 'Inactive',
  [ClientStatus.ON_BREAK]: 'On a break',
  [ClientStatus.DELINQUENT]: 'Payment failed',
  [ClientStatus.CANCELED]: 'Canceled',
  [ClientStatus.PAST_DUE]: 'Past due',
  [ClientStatus.FUTURE_START]: 'Future start',
  [ClientStatus.INCOMPLETE]: 'Incomplete subscription',
  [ClientStatus.FUTURE_CANCELLATION]: 'Future cancellation',
  [ClientStatus.MONTHLY_START]: 'Monthly start',
};

const filterChecks = {
  [ClientStatus.ACTIVE]: (user) => !!user.isActive,
  [ClientStatus.INACTIVE]: (user) => !user.isActive,
  [ClientStatus.ON_BREAK]: (user) => moment(user.breakEndDate).isAfter(moment()),
  [ClientStatus.DELINQUENT]: (user) => !!user.isDelinquent,
  [ClientStatus.CANCELED]: (user) => user.subscriptionStatus?.toLowerCase() === StripeSubscriptionState.CANCELED,
  [ClientStatus.PAST_DUE]: (user) => user.subscriptionStatus?.toLowerCase() === StripeSubscriptionState.PAST_DUE,
  [ClientStatus.FUTURE_START]: (user) => user.serviceStartAt
    && moment(user.serviceStartAt).isAfter(moment()),
  [ClientStatus.INCOMPLETE]: (user) => [StripeSubscriptionState.INCOMPLETE, StripeSubscriptionState.INCOMPLETE_EXPIRED]
    .includes(user.subscriptionStatus?.toLowerCase()),
  [ClientStatus.MONTHLY_START]: (user) => !!user.monthlyStartAt
    && user.subscriptionStatus?.toLowerCase() !== StripeSubscriptionState.CANCELED
    && moment.unix(user.monthlyStartAt).isAfter(moment())
    && moment.unix(user.monthlyStartAt).isBefore(moment().add(1, 'month')),
  [ClientStatus.FUTURE_CANCELLATION]: (user) => !!user.cancelAt
    && user.subscriptionStatus?.toLowerCase() !== StripeSubscriptionState.CANCELED
    && moment.unix(user.cancelAt).isBefore(moment().add(1, 'month')),
};

const filterExcludingChecks = {
  [ClientExcludingFilters.NEW_CLIENT]: (user) => !!user.serviceStartAt
    && moment(user.serviceStartAt).isBetween(moment().subtract(NEW_CLIENTS_FOR_N_DAYS, 'd'), moment()),
};

export {
  clientStatusLabel,
  ClientStatus,
  ClientExcludingFilters,
  filterChecks,
  filterExcludingChecks,
  NEW_CLIENTS_FOR_N_DAYS,
};
