import React, {
  useMemo,
  useState,
  useCallback,
  useEffect,
} from 'react';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import { useRouteMatch } from 'react-router-dom';

import useNavigation from '../../../hooks/useNavigation';
import {
  WorkoutsManagerTab,
  workoutsManagerPagesConfig,
  getTabPath,
} from '../../routes/workoutsManager/workoutsManagerPages';
import {
  Container,
  Header,
  StyledTabs,
} from './styles';
import { WorkoutsManagerTabsConfig } from './utils';

const WorkoutsManagerDashboard = () => {
  const { navigateTo } = useNavigation();
  const match = useRouteMatch();

  const [selectedTab, setSelectedTab] = useState(WorkoutsManagerTab.PROGRAMS);

  const navigateToTab = useCallback((tab, options = {}) => (
    navigateTo(getTabPath(tab), options)
  ), [navigateTo]);

  const onSelectedTab = useCallback((tabSelected) => {
    navigateToTab(tabSelected, { clearSearch: true });
  }, [
    navigateToTab,
  ]);

  useEffect(() => {
    // Get the last part of the pathname to detect if it is a valid workouts manager tab.
    const currentWorkoutsManagerTab = match.path.split('/').pop();

    // Get the config object from the path we extracted from the url
    const tabConfig = Object.values(workoutsManagerPagesConfig)
      .find(({ path }) => path === currentWorkoutsManagerTab);

    if (tabConfig) {
      setSelectedTab(tabConfig.id);
    }
  }, [match]);

  const tabContent = useMemo(() => {
    const { component: TabContent } = WorkoutsManagerTabsConfig[selectedTab];
    return (
      <TabContent />
    );
  }, [selectedTab]);

  return (
    <Container>
      <Header>
        <StyledTabs
          tabsConfig={WorkoutsManagerTabsConfig}
          selectedTab={selectedTab}
          onSelectTab={onSelectedTab}
        />
      </Header>
      {tabContent}
    </Container>
  );
};

export default compose(
  observer,
)(WorkoutsManagerDashboard);
