import { Collection } from 'firestorter';

import Collections from '../utils/collections';
import BaseDocument from './BaseDocument';

/**
 * class representing the notifications view data of a coach
 *
 * @class CoachNotificationView
 * @extends BaseDocument
 */
class CoachNotificationView extends BaseDocument {
  constructor(id, opts) {
    super(`${Collections.COACH_NOTIFICATION_VIEW}/${id}`, opts);
  }

  /**
   * Get the coach name
   * @return {string}
   */
  get name() {
    return this.data.name;
  }

  /**
   * Get coach's new leads count
   * @return {number}
   */
  get leads() {
    return this.data.leads;
  }

  /**
   * Get coach's unread message count
   * @return {number}
   */
  get chats() {
    return this.data.chats;
  }

  /**
   * Get coach's unread check-in count
   * @return {number}
   */
  get checkins() {
    return this.data.checkins;
  }

  /**
   * Get coach's count of clients needing workout assignments
   * @return {number}
   */
  get workouts() {
    return this.data.workouts;
  }

  /**
   * Get coach's count of clients needing meal plan assignments
   * @return {number}
   */
  get mealPlans() {
    return this.data.mealPlans;
  }

  /**
   * Get coach's count on video feedback requests
   * @return {number}
   */
  get videoFeedback() {
    return this.data.videoFeedback;
  }

  /**
   * Get coach's reminders count
   * @return {number}
   */
  get reminders() {
    return this.data.reminders;
  }

  /**
   * Get all external and active coach notification views.
   * @return {Promise<Collection>} Collection of view documents.
   */
  static async getAllExternalCoachNotificationViews() {
    const coachNotificationViewsCollection = new Collection(Collections.COACH_NOTIFICATION_VIEW, {
      createDocument: ({ id }, opts) => new CoachNotificationView(id, { ...opts, disableObserverRefCount: true }),
      query: (query) => query
        .where('isS2Coach', '==', false)
        .where('isActive', '==', true),
    });
    await coachNotificationViewsCollection.fetch();
    return coachNotificationViewsCollection;
  }
}

export default CoachNotificationView;
