import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import format from 'string-template';
import { DateFormat } from '../../../../utils/date';
import useToolsNavigation from '../../../hooks/useToolsNavigation';
import useUserDoc from '../../../hooks/useUserDoc';
import Plan from '../../../../Model/Plan';
import Subscription, { subscriptionStatus as activeSubscriptionStatus } from '../../../../Model/Subscription';
import UserContract from '../../../../Model/UserContract';
import { getUserStatusWithDate } from '../../../utils/userStatus';
import useComponentMounted from '../../../../hooks/useComponentMounted';
import { getUserSubscriptionPrice } from '../../../utils/userContract';
import { getCheckInDay } from '../../../utils/checkIn';
import {
  NameContainer,
  Container,
  TitleContainer,
  StyledLink,
  Title,
  StyledButton,
  StyledChatIcon,
  StyledAvatar,
  StyledChip,
  InfoContainer,
} from './styles';
import texts from './texts.json';

const UserInfoSection = ({
  clientId,
  handleChatButtonClick,
}) => {
  const [plan, setPlan] = useState();
  const [subscriptionData, setSubscriptionData] = useState();
  const [contract, setContract] = useState();
  const { navigateToClientPage } = useToolsNavigation();
  const {
    userDoc: clientDoc,
  } = useUserDoc(clientId);
  const isComponentMountedRef = useComponentMounted();

  useEffect(() => {
    const loadPlan = async () => {
      if (clientDoc?.planId) {
        const clientPlan = await Plan.getPlanById(clientDoc?.planId);
        if (isComponentMountedRef.current) {
          setPlan(clientPlan);
        }
      }
      const clientContract = await UserContract.getContractByUserId(clientId);
      if (isComponentMountedRef.current) {
        setContract(clientContract);
      }
    };
    loadPlan();
  }, [
    clientDoc,
    clientId,
    isComponentMountedRef,
  ]);

  useEffect(() => {
    const subscriptionPlan = async () => {
      const subscription = await Subscription.getCurrentSubscription(clientId);
      if (isComponentMountedRef) {
        setSubscriptionData(subscription.subscriptionData);
      }
    };
    subscriptionPlan();
  }, [
    clientId,
    isComponentMountedRef,
  ]);

  const clientStatus = useMemo(() => getUserStatusWithDate(clientDoc), [clientDoc]);

  const price = useMemo(() => getUserSubscriptionPrice(contract, plan), [contract, plan]);

  return (
    <Container>
      <StyledAvatar image={clientDoc?.avatarUrl} name={clientDoc?.name} />
      <NameContainer>
        <TitleContainer>
          <StyledLink onClick={() => navigateToClientPage(clientId)}>
            <Title>{clientDoc?.name}</Title>
          </StyledLink>
          {handleChatButtonClick && (
            <StyledButton onClick={handleChatButtonClick}>
              <StyledChatIcon />
              Chat
            </StyledButton>
          )}
        </TitleContainer>
        <InfoContainer>
          {!!plan && (
            <StyledChip
              label={`${plan?.planTitle || plan?.planCode || texts.emptyText} ${price}`}
              color="info"
            />
          )}
          {!!clientStatus && (
            <StyledChip label={clientStatus} color="info" />
          )}
          <StyledChip
            label={format(texts.nextBilling, {
              nextBilling: (!!clientDoc?.subscriptionStatus
                && Object.keys(activeSubscriptionStatus).includes(clientDoc.subscriptionStatus)
                && moment.unix(subscriptionData?.current_period_end)
                  .format(DateFormat.MONTH_NAME_DATE_FORMAT))
                || texts.emptyText,
            })}
            color="info"
          />

          <StyledChip
            label={format(texts.checkInDay, {
              checkInDay: getCheckInDay(clientDoc?.checkInDay),
            })}
            color="info"
          />
        </InfoContainer>
      </NameContainer>
    </Container>
  );
};

UserInfoSection.propTypes = {
  handleChatButtonClick: PropTypes.func,
  clientId: PropTypes.string.isRequired,
};

UserInfoSection.defaultProps = {
  handleChatButtonClick: undefined,
};

export default compose(
  observer,
)(UserInfoSection);
