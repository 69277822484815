import styled from 'styled-components';

import { WarningTag } from '../../../../../components/Tags';
import CollapsibleSelect from '../../../../../components/v2/CollapsibleSelect';

const TagList = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 5px;
`;

const ExerciseName = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const Thumbnail = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 3px;
`;

const StyledCollapsibleSelect = styled(CollapsibleSelect)`
  width:  ${(props) => (props.$isWorkoutDesignerView ? '200px' : '250px')};

  @media (max-width: 1240px) {
    width: 250px;
  }
`;

export {
  TagList,
  WarningTag as ExerciseTag,
  ExerciseName,
  Thumbnail,
  StyledCollapsibleSelect,
};
