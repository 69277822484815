import React, {
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import { MenuItem } from '@mui/material';

import ExternalCoachContext, { withExternalCoachContextReady } from '../../../../../context/ExternalCoachContext';
import {
  Card,
  Container,
  HeaderContainer,
  SubTitle,
  Title,
  TitleContainer,
} from '../../../../../../components/v2/Card';
import Select from '../../../../../../components/v2/Select';
import { CURRENCIES } from '../../../../../../utils/currency';
import Chip from '../../../../../../components/v2/Chip';
import Button from '../../../../../../components/v2/Button';
import { CoachingActivity } from '../../../../../../utils/log';
import useLogger from '../../../../../../hooks/useLogger';
import LoadingOverlay from '../../../../../components/LoadingOverlay';
import useToast from '../../../../../hooks/useToast';
import InputLabel from '../../../../../../components/v2/InputLabel';
import texts from './texts.json';
import {
  ChipContainer,
  InputContainer,
  StyledSaveIcon,
} from './styles';

const CurrencyConfiguration = () => {
  const { externalCoachDoc } = useContext(ExternalCoachContext);
  const [defaultCurrency, setDefaultCurrency] = React.useState(externalCoachDoc.defaultCurrency);
  const [supportedCurrencies, setSupportedCurrencies] = React.useState(externalCoachDoc.supportedCurrencies);
  const [loading, setLoading] = useState(false);

  const { showToast } = useToast();
  const { logCoachingActivity } = useLogger();

  const renderCurrencyOptions = useCallback((currencyList) => currencyList.map((currency) => (
    <MenuItem key={currency.code} value={currency.code}>{`${currency.name} (${currency.code})`}</MenuItem>
  )), []);

  const currencyOptions = useMemo(() => renderCurrencyOptions(CURRENCIES), [renderCurrencyOptions]);

  // show only supported currencies for default currency selection
  const defaultCurrencyOptions = useMemo(() => renderCurrencyOptions(
    CURRENCIES.filter((currency) => supportedCurrencies.includes(currency.code)),
  ), [supportedCurrencies, renderCurrencyOptions]);

  const onSave = async () => {
    if (!supportedCurrencies.length) {
      showToast(texts.supportedCurrenciesRequired, { error: true });
      return;
    }
    if (!defaultCurrency) {
      showToast(texts.defaultCurrencyRequired, { error: true });
      return;
    }
    setLoading(true);
    await externalCoachDoc.update({
      defaultCurrency,
      supportedCurrencies,
    });
    logCoachingActivity(CoachingActivity.UPDATED_CURRENCY_SETTINGS);
    showToast(texts.successMsg);
    setLoading(false);
  };

  return (
    <Card>
      <HeaderContainer>
        <TitleContainer>
          <Title>{texts.title}</Title>
          <SubTitle>{texts.description}</SubTitle>
        </TitleContainer>
      </HeaderContainer>
      <Container>
        <InputContainer>
          <InputLabel>{texts.supportedCurrencies}</InputLabel>
          <Select
            multiple
            value={supportedCurrencies}
            onChange={(e) => setSupportedCurrencies(e.target.value)}
          >
            {currencyOptions}
          </Select>
          <ChipContainer>
            {supportedCurrencies.map((currency) => (
              <Chip
                key={currency}
                label={currency}
                onDelete={() => setSupportedCurrencies(supportedCurrencies.filter((c) => c !== currency))}
              />
            ))}
          </ChipContainer>
        </InputContainer>
        <InputContainer>
          <InputLabel>{texts.defaultCurrency}</InputLabel>
          <Select
            value={defaultCurrency}
            onChange={(e) => setDefaultCurrency(e.target.value)}
          >
            {defaultCurrencyOptions}
          </Select>
        </InputContainer>
        <Button onClick={onSave}>
          <StyledSaveIcon />
          {texts.save}
        </Button>
      </Container>
      <LoadingOverlay isLoading={loading} />
    </Card>
  );
};

export default compose(
  withExternalCoachContextReady,
  observer,
)(CurrencyConfiguration);
