import { useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

const routes = {
  HOME: '/home',
  LOGIN: '/login',
  ONBOARDING: '/onboarding',
  ERROR: '/error',
};

const routePlaceholder = {
  QUESTION_ID: 'questionId',
};

routes.ONBOARDING_QUIZ = `${routes.ONBOARDING}/quiz`;
routes.ONBOARDING_QUIZ_QUESTION = `${routes.ONBOARDING_QUIZ}/{${routePlaceholder.QUESTION_ID}}`;

const useNavigation = () => {
  const location = useLocation();
  const { search, state, pathname } = location;
  const history = useHistory();

  const navigateTo = useCallback((newPathname, {
    search: newSearch,
    state: newState,
    replace,
    clearSearch,
  } = {
    search: null,
    state: {},
    replace: false,
    clearSearch: false,
  }) => {
    /*
      Always propagate state and search during navigation.
    */
    const options = {
      pathname: newPathname,
      state: {
        ...state,
        ...newState,
      },
      search: clearSearch ? null : (newSearch || search),
    };
    if (replace) {
      history.replace(options);
    } else {
      history.push(options);
    }
  /*
    We don't want navigateTo to be re-calculated upon state change as that ends up generating
    cyclic deps loop.
  */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearSearchParams = useCallback(() => {
    history.replace({
      pathname,
      search: '', // Clear the search params
      state,
    });
  }, [history, pathname, state]);

  return {
    navigateTo,
    state,
    search,
    location,
    routes,
    routePlaceholder,
    pathname,
    clearSearchParams,
  };
};

export default useNavigation;
