import styled from 'styled-components';

import {
  Typography,
} from '@mui/material';

const UserName = styled(Typography)`
  margin-right: 10px;
`;

export {
  UserName,
};
