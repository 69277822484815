import styled from 'styled-components';

import Avatar from '../../../../../components/Avatar/Avatar';

const channelContentHeight = 36;

const StyledAvatar = styled(Avatar)`
  height: ${channelContentHeight}px;
  width: ${channelContentHeight}px;
  min-height: ${channelContentHeight}px;
  min-width: ${channelContentHeight}px;
  border-radius: 50%;
`;

export {
  StyledAvatar,
};
