import React, {
  useCallback,
  useContext,
  useState,
  useMemo,
  useEffect,
} from 'react';
import format from 'string-template';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import * as Sentry from '@sentry/browser';
import { forEachSeries } from 'p-iteration';
import { useRouteMatch } from 'react-router-dom';

import CopyAllIcon from '@mui/icons-material/CopyAll';
import SaveIcon from '@mui/icons-material/Save';

import { pathPlaceholder, storagePaths } from '../../../../../utils/firebasePaths';
import useStorage, { StorageProcessState } from '../../../../../hooks/useStorage';
import useComponentMounted from '../../../../../hooks/useComponentMounted';
import useLogger from '../../../../../hooks/useLogger';
import { CoachingActivity } from '../../../../../utils/log';
import CustomizationContext, { withCustomizationContextReady } from '../../../../context/CustomizationContext';
import useToast from '../../../../hooks/useToast';
import { WELCOME_MESSAGE_PLACEHOLDER } from '../../../../utils/appCustomization';
import AttachmentsContainer from '../../../../components/AttachmentsContainer';
import LoadingOverlay from '../../../../components/LoadingOverlay';
import { MAX_FILE_UPLOAD_SIZE, MAX_FILE_UPLOAD_SIZE_MB } from '../../../Chat/util/fileUpload';

import {
  Wrapper,
  Title,
  Description,
} from '../../styles';
import {
  PlaceholderButton,
  PlaceholdersContainer,
  PlaceholderTip,
} from '../../../../components/PlaceHolder/styles';
import {
  ContentWrapper,
  InputContainer,
  StyledTextField,
  StyledButton,
} from './styles';

import texts from './texts.json';

const MAX_CHARACTERS_COUNT = 5000;

const WelcomeMessageConfig = () => {
  const {
    appCustomizationDoc,
  } = useContext(CustomizationContext);

  const {
    welcomeMessage: customMessage,
  } = appCustomizationDoc;
  const { params: { userId: coachId } } = useRouteMatch();

  const {
    uploadAttachments,
    deleteAttachments,
    getBlobUrl,
  } = useStorage();
  const isComponentMountedRef = useComponentMounted();

  const [welcomeMessage, setWelcomeMessage] = useState(customMessage);
  const [characterCount, setCharacterCount] = useState(customMessage.length);
  const [fileAttachments, setFileAttachments] = useState([]); // Array of Files
  const [isUploading, setIsUploading] = useState(false);
  const [isReady, setIsReady] = useState(false);

  const { showToast } = useToast();
  const { logCoachingActivity } = useLogger();

  useEffect(() => {
    const init = async () => {
      const files = [];
      await forEachSeries(appCustomizationDoc.welcomeMessageAttachments, async (attachment) => {
        const dataUrl = await getBlobUrl(attachment.url);
        files.push({
          ...attachment,
          dataUrl,
          isUploaded: true,
        });
      });
      if (isComponentMountedRef.current) {
        setFileAttachments(files);
        setIsReady(true);
      }
    };

    if (!isReady) {
      init();
    }
  }, [
    getBlobUrl,
    appCustomizationDoc.welcomeMessageAttachments,
    fileAttachments,
    isReady,
    isComponentMountedRef,
  ]);

  const uploadMessageAttachments = useCallback(async () => {
    // adding already uploaded files to the array
    const attachmentsRefs = fileAttachments.reduce((arr, current) => {
      if (current.isUploaded) {
        arr.push({
          name: current.name,
          url: current.url,
        });
      }
      return arr;
    }, []);

    // filtering files that are not uploaded yet
    const filesToUpload = fileAttachments.filter((file) => !file.isUploaded);
    const storagePath = format(storagePaths.COACH_WELCOME_MESSAGE_ATTACHMENTS, {
      [pathPlaceholder.COACH_ID]: coachId,
    });

    setIsUploading(true);
    try {
      const result = await uploadAttachments(filesToUpload, storagePath);

      let storageTaskFailed = false;
      const successfulUploads = [];
      result.forEach((res, index) => {
        if (res.state === StorageProcessState.SUCCESS) {
          successfulUploads.push({
            name: filesToUpload[index].name,
            url: res.fileRef,
          });
        } else {
          storageTaskFailed = true;
        }
      });
      if (storageTaskFailed) {
        // If some upload fails, we should stop the saving process
        // and delete the files that were uploaded (if there is any)
        if (attachmentsRefs.length) {
          const filesToDelete = successfulUploads.map(({ url }) => ({ storagePath: url }));
          await deleteAttachments(filesToDelete);
        }
      }
      attachmentsRefs.push(...successfulUploads);
      if (isComponentMountedRef.current) {
        setIsUploading(false);
      }
      return { attachmentsRefs, storageTaskFailed };
    } catch (error) {
      if (isComponentMountedRef.current) {
        setIsUploading(false);
      }
      Sentry.captureException(error, {
        extra: {
          coachId,
        },
      });
      // Returning this to handle error messages from the caller function
      return { storageTaskFailed: true };
    }
  }, [
    fileAttachments,
    coachId,
    uploadAttachments,
    deleteAttachments,
    isComponentMountedRef,
  ]);

  const getAttachmentRefs = useCallback(async () => {
    let attachments = [];
    let error = false;
    if (fileAttachments.length) {
      const { storageTaskFailed, attachmentsRefs } = await uploadMessageAttachments();
      if (storageTaskFailed) {
        error = true;
      }
      attachments = attachmentsRefs;
    }
    return {
      attachments,
      error,
    };
  }, [
    fileAttachments,
    uploadMessageAttachments,
  ]);

  const handleSave = useCallback(async () => {
    const { attachments, error } = await getAttachmentRefs();
    if (error) {
      showToast(texts.uploadError, { error: true });
      return;
    }
    await appCustomizationDoc.setWelcomeMessage(welcomeMessage);
    await appCustomizationDoc.setWelcomeMessageAttachments(attachments);
    logCoachingActivity(CoachingActivity.UPDATED_WELCOME_MESSAGE);
    setIsReady(false);
    showToast(texts.messageSaved);
  }, [
    appCustomizationDoc,
    welcomeMessage,
    showToast,
    getAttachmentRefs,
    logCoachingActivity,
  ]);

  const onFilesSelected = useCallback((event) => {
    const { files } = event.target;
    if (files.length) {
      const filesArray = [...files];
      const validFiles = filesArray.every((file) => file.size < MAX_FILE_UPLOAD_SIZE);
      if (validFiles) {
        const formattedFiles = filesArray.map((file) => {
          const lastDotIndex = file.name.lastIndexOf('.');
          return {
            dataUrl: URL.createObjectURL(file),
            format: file.name.slice(lastDotIndex + 1),
            fileName: file.name.slice(0, lastDotIndex),
            fileType: file.type,
            name: file.name,
            isUploaded: false,
          };
        });
        setFileAttachments((prev) => [...prev, ...formattedFiles]);
      } else {
        showToast(format(texts.fileSizeError, {
          maxFileSize: MAX_FILE_UPLOAD_SIZE_MB, // Bytes to MB
        }), { error: true });
      }
    }
  }, [
    showToast,
  ]);

  const removeFileByIndex = useCallback((index) => {
    const resultAttachments = [...fileAttachments];
    resultAttachments.splice(index, 1);
    setFileAttachments(resultAttachments);
  }, [
    fileAttachments,
  ]);

  const copyPlaceholderValue = useCallback(async (placeholderValue) => {
    await navigator.clipboard.writeText(`{${placeholderValue}}`);
    showToast(texts.placeholderCopied);
  }, [
    showToast,
  ]);

  const handleTextChange = useCallback((event) => {
    const newValue = event.target.value;
    setWelcomeMessage(newValue);
    setCharacterCount(newValue.length);
  }, []);

  const isError = useMemo(() => characterCount > MAX_CHARACTERS_COUNT, [characterCount]);

  return (
    <Wrapper>
      <Title>{texts.title}</Title>
      <Description>{texts.description}</Description>
      <ContentWrapper>
        <InputContainer>
          <StyledTextField
            value={welcomeMessage}
            onChange={handleTextChange}
            placeholder={texts.defaultMessage}
            error={isError}
            helperText={format(texts.characterCount, {
              count: characterCount,
              maxCount: MAX_CHARACTERS_COUNT,
            })}
            multiline
            maxRows={18}
          />
          <AttachmentsContainer
            fileAttachments={fileAttachments}
            onFileRemoved={removeFileByIndex}
            onFilesSelected={onFilesSelected}
            isUploading={isUploading}
          />
          <StyledButton
            variant="contained"
            onClick={handleSave}
            disabled={isError}
            startIcon={<SaveIcon />}
          >
            {texts.saveButton}
          </StyledButton>
        </InputContainer>
        <PlaceholdersContainer>
          <PlaceholderTip>{texts.placeholderTitle}</PlaceholderTip>
          {Object.values(WELCOME_MESSAGE_PLACEHOLDER).map((placeholderValue) => {
            const displayText = `{${placeholderValue}}`;
            return (
              <PlaceholderButton
                key={placeholderValue}
                startIcon={<CopyAllIcon />}
                onClick={() => copyPlaceholderValue(placeholderValue)}
              >
                {displayText}
              </PlaceholderButton>
            );
          })}
          <PlaceholderTip>{texts.placeholderDescription}</PlaceholderTip>
        </PlaceholdersContainer>
      </ContentWrapper>
      <LoadingOverlay isLoading={!isReady} />
    </Wrapper>
  );
};

export default compose(
  withCustomizationContextReady,
  observer,
)(WelcomeMessageConfig);
