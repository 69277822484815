import styled from 'styled-components';
import Box from '@mui/material/Box';

import colors from '../../../../styles/colors';

const MealAssignmentsContainer = styled(Box)`
  flex: 1;
  margin: 10px 30px 30px 30px;
  z-index: 1;
  background-color: ${colors.base.alpha};
`;

const MealAssignmentsTableContainer = styled.div`
  height: calc(100% - 80px);
`;

const TitleIconsContainer = styled.div`
  display: flex;
  gap: 7px;
`;

export {
  MealAssignmentsContainer,
  MealAssignmentsTableContainer,
  TitleIconsContainer,
};
