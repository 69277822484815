import React, {
  useMemo,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import {
  UserSelectWrapper,
  StyledAutoComplete,
  StyledTextField,
} from './styles';
import texts from './texts.json';

const UserSelect = ({
  selectedUser,
  inputLabel,
  inputPlaceHolder,
  onChange,
  userList,
}) => {
  const userOptions = useMemo(() => userList.map((user) => ({
    id: user.userId,
    label: user.userName,
  })), [userList]);

  const onUserSelected = useCallback((event, selectedOption) => {
    onChange(selectedOption);
  }, [
    onChange,
  ]);

  return (
    <UserSelectWrapper>
      <StyledAutoComplete
        disablePortal
        options={userOptions}
        renderInput={(params) => (
          <StyledTextField
            {...params}
            label={inputLabel}
            placeholder={inputPlaceHolder}
          />
        )}
        renderOption={(props, option) => <div {...props} key={option.id}>{option.label}</div>}
        value={selectedUser}
        onChange={onUserSelected}
      />
    </UserSelectWrapper>
  );
};

UserSelect.propTypes = {
  selectedUser: PropTypes.object,
  inputLabel: PropTypes.string,
  inputPlaceHolder: PropTypes.string,
  onChange: PropTypes.func,
  userList: PropTypes.array.isRequired,
};

UserSelect.defaultProps = {
  inputLabel: texts.selectUser,
  inputPlaceHolder: '',
  selectedUser: null,
  onChange: () => { },
};

export default UserSelect;
