import styled from 'styled-components';

const StyledPageContent = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 30px;
  display: flex;
  flex-direction: column;
`;

export {
  StyledPageContent,
};
