import { ActivityLevel } from '../Model/UserActivityProfile';

import { WeeklyWeightGoal } from '../Model/UserNutritionProfile';

const BiologicalSex = {
  MALE: 'MALE',
  FEMALE: 'FEMALE',
};

const BMRFactor = {
  WEIGHT: 10,
  HEIGHT: 6.25,
  AGE: 5,
};

const BiologicalSexFactor = {
  [BiologicalSex.MALE]: 5,
  [BiologicalSex.FEMALE]: -161,
};

const ActivityFactor = {
  [ActivityLevel.SEDENTARY]: 1.2,
  [ActivityLevel.MODERATE]: 1.375,
  [ActivityLevel.ACTIVE]: 1.55,
};

const CaloricGoalFactor = {
  [WeeklyWeightGoal.LOSE_MORE_WEIGHT]: -500,
  [WeeklyWeightGoal.LOSE_LITTLE_WEIGHT]: -250,
  [WeeklyWeightGoal.MAINTAIN_WEIGHT]: 0,
  [WeeklyWeightGoal.GAIN_WEIGHT]: 250,
};

/**
 * Compute the BMR from the given params.
 * @param {String} biologicalSex The biological sex determines which formula to use.
 * @param {Number} weight Weight value in Kg.
 * @param {Number} height Height value in cm.
 * @param {Number} age The age of the person in years.
 *
 * The formulas we use are:
 *   - Men: (10 × weight in kg) + (6.25 × height in cm) - (5 × age in years) + 5
 *   - Women: (10 × weight in kg) + (6.25 × height in cm) - (5 × age in years) - 161
 * @returns {Number}
 */
const calculateBMR = (biologicalSex, weight, height, age) => {
  const sexFactor = BiologicalSexFactor[biologicalSex];

  const bmr = BMRFactor.WEIGHT * weight + BMRFactor.HEIGHT * height - BMRFactor.AGE * age + sexFactor;

  return Math.round(bmr);
};

/**
 * Calculate the TDEE based on the BMR and the current activiity level of the person.
 * @param {Number} BMR The bmr value.
 * @param {String} activityType The activity type of the person (SEDENTARY, MODERATE, ACTIVE).
 * @returns {number}
 */
const calculateTDEE = (BMR, activityType) => {
  const factor = ActivityFactor[activityType];
  return Math.round(BMR * factor);
};

/**
 * Calculate the caloric goal for the user. This will add or substract calories based on the goal.
 * @param {Number} tdee The TDEE value.
 * @param {String} weightGoal The weight goal determines how much we add or substract to compute the final caloric goal.
 * @returns {Number}
 */
const calculateCaloricGoal = (tdee, weightGoal) => (
  Math.round(tdee + CaloricGoalFactor[weightGoal] || 0)
);

export {
  calculateBMR,
  calculateTDEE,
  calculateCaloricGoal,
};
