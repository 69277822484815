import React, {
  useRef,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import {
  debounce,
  IconButton,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

import {
  Container,
  StyledTextField,
  StyledSearchRefractionIcon,
} from './styles';

import texts from './texts.json';

const SEARCH_TIMEOUT = 300;

const QuickSearchToolbar = ({
  value,
  onChange,
  clearSearch,
  autoFocus,
  placeholder,
  filterTools,
  processTools,
  className,
}) => {
  const inputRef = useRef(null);

  const handleClearSearch = () => {
    clearSearch();
    inputRef.current.value = '';
  };

  const handleSearch = useMemo(() => debounce(onChange, SEARCH_TIMEOUT), [onChange]);

  return (
    <Container className={className}>
      <StyledTextField
        inputRef={inputRef}
        variant="standard"
        onChange={handleSearch}
        placeholder={placeholder || texts.placeholder}
        autoFocus={autoFocus}
        InputProps={{
          startAdornment: <StyledSearchRefractionIcon />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: value ? 'visible' : 'hidden' }}
              onClick={handleClearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
      />
      {processTools.map(({ id, Component, props }) => (
        <Component key={id} {...props} />
      ))}
      {filterTools.map(({ id, Component, props }) => (
        <Component key={id} {...props} />
      ))}
    </Container>
  );
};

QuickSearchToolbar.propTypes = {
  autoFocus: PropTypes.bool,
  clearSearch: PropTypes.func.isRequired,
  filterTools: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      Component: PropTypes.elementType,
      props: PropTypes.object,
    }),
  ),
  processTools: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      Component: PropTypes.elementType,
      props: PropTypes.object,
    }),
  ),
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
};

QuickSearchToolbar.defaultProps = {
  autoFocus: false,
  filterTools: [],
  placeholder: '',
  className: '',
  processTools: [],
};

export default QuickSearchToolbar;
