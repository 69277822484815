import styled from 'styled-components';

import { IconButton } from '@mui/material';

import colors from '../../../../../styles/colors';

import { ColorCode } from './utils';

const ColorMapping = {
  [ColorCode.GREEN]: colors.shades.success6,
  [ColorCode.YELLOW]: colors.shades.primary2,
  [ColorCode.RED]: colors.shades.danger3,
  [ColorCode.BLACK]: colors.base.beta,
};

const Container = styled.div`
  position: relative;
  display: grid;
  padding: 8px;
  grid-template-columns: 36px 260px repeat(7, 1fr) 30px;
  column-gap: 4px;
  align-items: center;
  border-bottom: 1px solid ${colors.shades.secondary5}
`;

const StyledIconButton = styled(IconButton)`
  color: ${colors.shades.gamma11};
`;

const MealImage = styled.img`
  width: 48px;
  height: 40px;
  object-fit: cover;
  border-radius: 4px;
`;

const MealCell = styled.div`
  font-size: 11px;
  line-height: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${(props) => (props.$color ? ColorMapping[props.$color] : colors.base.beta)};
`;

const MealName = styled.div`
  margin-left: 8px;
`;

export {
  Container,
  MealImage,
  MealName,
  MealCell,
  StyledIconButton,
};
