import React, {
  useState,
  useEffect,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { compose } from 'recompose';

import useComponentMounted from '../../hooks/useComponentMounted';
import { leadId, contractId } from '../../utils/onboarding';
import UserContract from '../../Model/UserContract';
import { ContractStatus } from '../../CoachTools/utils/userContract';
import UserContractContext from './UserContractContext';

const UserContractContextProvider = ({
  children,
}) => {
  const [isReady, setIsReady] = useState(false);
  const [contractDoc, setContractDoc] = useState(null);

  const isComponentMountedRef = useComponentMounted();

  useEffect(() => {
    const loadContract = async () => {
      let contractDocument;

      if (contractId) {
        const document = new UserContract(contractId);
        await document.init();

        /*
          Reusable contracts are using the same status as the user contract. PENDING means the contract is not signed
          yet, so we can use it for generating USER contracts from this one.
        */
        if (document.exists && document.status === ContractStatus.PENDING) {
          contractDocument = document;
        }
      } else if (leadId) {
        const contractCollection = await UserContract.getContractsByLeadId(leadId);

        /*
          We are looking for the first contract that is not signed yet. If there is no such contract, we are looking for
          the first contract that is in progress. If there is no such contract, we are looking for the first contract
          that failed to show the user with respective pages.
        */
        let document = contractCollection.docs.find((doc) => doc.status === ContractStatus.PENDING);

        if (!document) {
          document = contractCollection.docs.find((doc) => doc.status === ContractStatus.IN_PROGRESS);
        }

        if (document && document.exists) {
          contractDocument = document;
        }
      }

      if (isComponentMountedRef) {
        setContractDoc(contractDocument);
        setIsReady(true);
      }
    };

    if (!isReady) {
      loadContract();
    }
  }, [
    isReady,
    isComponentMountedRef,
  ]);

  const context = useMemo(() => ({
    isReady,
    leadId,
    contractDoc,
  }), [
    isReady,
    contractDoc,
  ]);

  return (
    <UserContractContext.Provider value={context}>
      {children}
    </UserContractContext.Provider>
  );
};

UserContractContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default compose(
  observer,
)(UserContractContextProvider);
