import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
} from '@mui/material';

import {
  Container,
  StyledFormHelperText,
} from './styles';

const LabelCheckbox = ({
  description,
  isChecked,
  onChange,
  className,
  disabled,
}) => {
  const handleChange = useCallback(
    (event) => {
      const {
        target: { checked },
      } = event;

      onChange(checked);
    },
    [onChange],
  );

  return (
    <Container className={className}>
      <Checkbox disabled={disabled} onChange={handleChange} checked={isChecked} />
      {!!description && <StyledFormHelperText>{description}</StyledFormHelperText>}
    </Container>
  );
};

LabelCheckbox.propTypes = {
  description: PropTypes.string,
  isChecked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

LabelCheckbox.defaultProps = {
  className: '',
  description: '',
  disabled: false,
};

export default LabelCheckbox;
