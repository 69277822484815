import { Badge } from '@mui/material';
import styled from 'styled-components';

import colors from '../../../styles/colors';

const StyledBadge = styled(Badge)`
  .MuiBadge-badge {
    background-color: ${colors.base.secondary3};
    color: ${colors.base.beta};
  }
`;

export {
  StyledBadge,
};
