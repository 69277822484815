import styled from 'styled-components';

import InputLabel from '../../../components/v2/InputLabel';

const InputContainer = styled.div`
  display: flex;
  padding: 20px 50px;
  gap: 10px;
  align-items: center;
`;

const StyledTextLabel = styled(InputLabel)`
  font-size: 12px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  width: 100%;
`;

export {
  InputContainer,
  StyledTextLabel,
  ButtonContainer,
};
