import React from 'react';
import PropTypes from 'prop-types';

import PieChart from '../PieChart';
import ChartContainer from '../ChartContainer';
import texts from './texts.json';

const UserCompositionChart = ({
  data,
}) => (
  <ChartContainer title={texts.userComposition}>
    <PieChart data={data} />
  </ChartContainer>
);
UserCompositionChart.propTypes = {
  data: PropTypes.array.isRequired,
};

export default UserCompositionChart;
