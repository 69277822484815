import { ToggleButtonGroup } from '@mui/material';
import styled from 'styled-components';

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const FilterRow = styled.div`
  display: flex;
  div:not(:last-child) {
    margin-right: 30px;
  }
  margin: 10px 0px;
`;

const PageContent = styled.div`
  flex: 1;
  margin: 0 30px 30px;
  z-index: 1;
`;

const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  margin-left: auto;
`;

export {
  Container,
  FilterRow,
  PageContent,
  StyledToggleButtonGroup,
};
