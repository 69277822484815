import styled from 'styled-components';

import colors from '../../../../../../../styles/colors';

const HIGHLIGHT_CLASSNAME = 'highlight';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ChangeDescription = styled.p`
  span.${HIGHLIGHT_CLASSNAME} {
    font-weight: bold;
  }
`;

const Note = styled.div`
  display: flex;
  color: ${colors.base.danger};
  font-size: 16px;
`;

export {
  Container,
  Note,
  ChangeDescription,
  HIGHLIGHT_CLASSNAME,
};
