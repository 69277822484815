import styled from 'styled-components';

import {
  TextField,
  Button,
} from '@mui/material';

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 65%;
`;

const StyledTextField = styled(TextField)`
  width: 100%;
`;

const StyledButton = styled(Button)`
  margin: 10px 0;
  width: 250px;
  text-transform: none;
`;

export {
  ContentWrapper,
  InputContainer,
  StyledTextField,
  StyledButton,
};
