import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';

import { ReactComponent as UserIcon } from '../../../../../assets/icons/userIcon.svg';
import GenericErrorMessage from '../../../../../components/GenericErrorMessage';
import useComponentMounted from '../../../../../hooks/useComponentMounted';
import useNavigation from '../../../../../hooks/useNavigation';
import FormikInput, { FormikPhoneInput } from '../../../../../components/FormikInput';

import SubmitButton from '../SubmitButton';

import {
  Container,
  SignUpHeader,
  SignUpContent,
  ErrorContainer,
  StyledLinkButton,
} from './styles';

import { getInitialValues, validationSchema } from './validation';
import texts from './texts.json';

const AccountCreation = ({
  email,
  firstName,
  lastName,
  phoneNumber,
  onSubmit,
  state,
}) => {
  const {
    isError,
    errorMsg,
    route,
    routeSearch,
    routeState,
  } = state;

  const isComponentMountedRef = useComponentMounted();
  const { navigateTo } = useNavigation();

  const handleSubmit = useCallback(async (values, actions) => {
    await onSubmit(values);
    if (isComponentMountedRef.current) {
      actions.setSubmitting(false);
    }
  }, [
    onSubmit,
    isComponentMountedRef,
  ]);

  const handleNavigate = useCallback(() => {
    navigateTo(route, { search: routeSearch, state: routeState });
  }, [
    navigateTo,
    route,
    routeState,
    routeSearch,
  ]);

  return (
    <Formik
      initialValues={getInitialValues({
        email,
        firstName,
        lastName,
        phoneNumber,
      })}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ errors, touched, isSubmitting }) => (
        <Form>
          <Container>
            <SignUpHeader>{texts.signUp}</SignUpHeader>
            <SignUpContent>
              <FormikInput
                name="firstName"
                autoComplete="fname"
                placeholder={texts.placeholder.firstName}
                error={errors.name}
                touched={touched.name}
              />
              <FormikInput
                name="lastName"
                autoComplete="lname"
                placeholder={texts.placeholder.lastName}
                error={errors.name}
                touched={touched.name}
              />
              <FormikInput
                name="email"
                autoComplete="email"
                placeholder={texts.placeholder.email}
                error={errors.email}
                touched={touched.email}
              />
              <FormikPhoneInput
                name="phoneNumber"
                autoComplete="tel"
                placeholder={texts.placeholder.phoneNumber}
                error={errors.phoneNumber}
                touched={touched.phoneNumber}
              />
              <FormikInput
                name="password"
                placeholder={texts.placeholder.password}
                error={errors.password}
                touched={touched.password}
                type="password"
              />
              <SubmitButton
                startIcon={<UserIcon />}
                isLoading={isSubmitting}
                type="submit"
              >
                {texts.createAccount}
              </SubmitButton>
              { isError && (
                <ErrorContainer>
                  <GenericErrorMessage>{errorMsg}</GenericErrorMessage>
                  { route && (
                    <StyledLinkButton onClick={handleNavigate}>{texts.loginWithEmail}</StyledLinkButton>
                  )}
                </ErrorContainer>
              )}
            </SignUpContent>
          </Container>
        </Form>
      )}
    </Formik>
  );
};

AccountCreation.propTypes = {
  email: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  phoneNumber: PropTypes.string,
  onSubmit: PropTypes.func,
  state: PropTypes.object,
};

AccountCreation.defaultProps = {
  email: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  onSubmit: () => {},
  state: {},
};

export default AccountCreation;
