import QuestionType, { HiddenFieldType } from './questionType';
import {
  initialEmailFieldValues,
  initialHiddenFieldValues,
  initialNameFieldValues,
  initialOptionsFieldValues,
  initialPhoneFieldValues,
  initialTextFieldValues,
} from './validation';

const InitialQuestions = {
  [QuestionType.TEXT]: { ...initialTextFieldValues },
  [QuestionType.EMAIL]: { ...initialEmailFieldValues },
  [QuestionType.NAME]: { ...initialNameFieldValues },
  [QuestionType.OPTIONS]: { ...initialOptionsFieldValues },
  [QuestionType.PHONE]: { ...initialPhoneFieldValues },
  [HiddenFieldType.TEXT]: { ...initialHiddenFieldValues },
};

export default InitialQuestions;
