import {
  Box,
  Button,
  Typography,
  TextField,
} from '@mui/material';
import styled from 'styled-components';
import colors from '../../../../../styles/colors';
import { sansSerifHeadlineFontStyles } from '../../../../../styles/text';

const Container = styled(Box)`
  display: flex;
  flex-direction: row;
`;

const LeadInfoSection = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.base.secondary3};
  padding: 50px 50px 20px;
`;

const ModalContent = styled.div`
  padding-left: 50px;
  padding-top: 12px;
`;

const Title = styled.div`
  ${sansSerifHeadlineFontStyles}
  font-size: 36px;
  font-weight: 400;
`;

const TitleSection = styled.div`
  display: flex;
  flex-direction: row;
  place-content: space-between;
  margin-bottom: 15px;
`;

const ContactInfoSection = styled.div`
  display: flex;
  flex-direction: row;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
`;

const ContactButton = styled(Button)`
  color: ${colors.base.alpha};
  :hover {
    background-color: ${colors.base.secondary};
  }
  border-radius: 84px;
  height: 30px;
  font-weight: 400;
  font-size: 16px;
  text-transform: none;
  text-wrap: nowrap;
  min-width: unset;

  /* TODO: We have to standardize all buttons and colors, to avoid having hardcoded values */
  background-color: ${({ provider }) => {
    switch (provider) {
      case 'msg':
        return '#0FB904';
      case 'email':
        return '#FF7E06';
      case 'chat':
        return '#299CFC';
      case 'sentToReschedule':
        return '#4C03C2';
      case 'rescheduledManually':
        return '#A106FF';
      case 'noShow':
        return colors.shades.danger1;
      default:
        return colors.shades.alpha1;
    }
  }};
`;

const QuestionsContainer = styled.li`
  margin: 0 0 15px;
  font-size: 18px;
`;

const Question = styled.div`
  font-size: 1em;
  margin: 0 0 5px;
  font-weight: 600;
`;

const Answer = styled.p`
  font-size: 0.8em;
  margin: 0;
  white-space: pre-wrap;
`;

const MainSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 65%;
`;

const HistorySection = styled.div`
  display: flex;
  flex-direction: column;
  width: 35%;
  background-color: ${colors.shades.gamma5};
`;

const HistoryTitle = styled(Typography)`
  font-size: 24px;
  line-height: 28px;
  font-weight: 700;
  margin: 64px 0 36px 40px;
`;

const HistoryNotesContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const StyledButton = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${colors.base.beta};
  background-color: ${colors.base.primary};
  border: 1px solid ${colors.shades.primary1};
  text-transform: none;
  padding: 9px 15px;

  .MuiButton-startIcon {
    color: ${colors.base.alpha};
  }

  &:hover {
    background: ${colors.base.primary};
  }
`;

const StyledNoteActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: auto;
  padding: 20px 40px;
`;

const StyledTextField = styled(TextField)`
  margin-bottom: 10px;

  .MuiInputBase-multiline {
    padding: 0;
  }

  .MuiInputBase-input {
    border-radius: inherit;
    background-color: ${colors.base.alpha};
    padding: 4px;
  }
`;

const CallLogButton = styled(Button)`
  height: 30px;
  width: 130px;
  margin: 64px 10px 36px 40px;
  text-transform: none;
`;

export {
  Container,
  LeadInfoSection,
  MainSection,
  HistorySection,
  HistoryTitle,
  HistoryNotesContainer,
  ModalContent,
  Title,
  QuestionsContainer,
  Question,
  Answer,
  TitleSection,
  ContactInfoSection,
  ContactButton,
  StyledButton,
  StyledNoteActionContainer,
  StyledTextField,
  CallLogButton,
  ButtonsContainer,
};
