import React from 'react';

import Sidebar from '../components/Sidebar';
import useAdminSideBarConfig from './useAdminSidebarConfig';

const AdminSidebar = () => {
  const { entries } = useAdminSideBarConfig();
  return (
    <Sidebar entries={entries} />
  );
};

export default AdminSidebar;
