import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import useLogger from '../../hooks/useLogger';

import StyledButton from './styles';
import variants from './variants';

const Button = ({
  trackingProps,
  onClick,
  children,
  className,
  enableTracking,
  full,
  component: CustomButtonComponent,
  ...props
}) => {
  const { logEvent } = useLogger();
  const buttonEl = useRef(null);
  const onButtonClick = (event) => {
    const evtProps = trackingProps || {};
    if (!evtProps.button
      && buttonEl.current
      && buttonEl.current.textContent.length > 0) {
      evtProps.button = buttonEl.current.textContent;
    }
    if (enableTracking && evtProps.button) {
      logEvent('buttonClicked', evtProps);
    }
    if (onClick) {
      onClick(event);
    }
  };

  const ButtonComponent = CustomButtonComponent || StyledButton;
  return (
    <ButtonComponent
      ref={buttonEl}
      className={className}
      onClick={onButtonClick}
      $full={full}
      {...props}
    >
      {children}
    </ButtonComponent>
  );
};

Button.propTypes = {
  variant: PropTypes.oneOf(Object.values(variants)),
  full: PropTypes.bool,
  trackingProps: PropTypes.object,
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
  enableTracking: PropTypes.bool,
  component: PropTypes.elementType,
  startIcon: PropTypes.element,
};

Button.defaultProps = {
  variant: variants.PRIMARY,
  full: true,
  trackingProps: null,
  children: [],
  className: '',
  onClick: null,
  enableTracking: true,
  component: null,
  startIcon: undefined,
};

export default Button;
