import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';

import { validationSchema, initialValues } from './validation';
import { FormInput, LoginButton } from './styles';

const LoginForm = ({ onSubmit, onEmailChange, email }) => (
  <Formik
    initialValues={{ ...initialValues, email }}
    validationSchema={validationSchema}
    onSubmit={onSubmit}
  >
    {({
      errors, touched, isSubmitting, handleChange,
    }) => (
      <Form>
        <FormInput
          name="email"
          type="email"
          placeholder="Email"
          onChange={(e) => { onEmailChange(e); handleChange(e); }}
          error={errors.email}
          touched={touched.email}
        />
        <FormInput
          name="password"
          placeholder="Password"
          error={errors.password}
          touched={touched.password}
          type="password"
        />
        <LoginButton disabled={isSubmitting} type="submit">Log In</LoginButton>
      </Form>
    )}
  </Formik>
);

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onEmailChange: PropTypes.func.isRequired,
  email: PropTypes.string,
};

LoginForm.defaultProps = {
  email: '',
};

export default LoginForm;
