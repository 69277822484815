/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useCallback } from 'react';
import ExternalPage from '../ExternalPage';

import {
  Text,
  Link,
  Modal,
  Container,
  StyledButton,
} from './styles';

const TermsAndConditions = () => {
  const [showModal, setShowModal] = useState(false);
  const [externalPage, setExternalPage] = useState();

  const openExternalPageModal = useCallback((newExternalPage) => {
    setExternalPage(newExternalPage);
    setShowModal(true);
  }, []);

  const onTermsOfServiceClicked = useCallback(() => {
    openExternalPageModal('/tos.html');
  }, [
    openExternalPageModal,
  ]);

  const onPrivacyPolicyClicked = useCallback(() => {
    openExternalPageModal('/privacy.html');
  }, [
    openExternalPageModal,
  ]);

  const onDismiss = useCallback(() => {
    setShowModal(false);
  }, []);

  return (
    <>
      {
        !!externalPage && (
        <Modal
          isOpen={showModal}
          onDidDismiss={onDismiss}
        >
          <Container>
            <ExternalPage
              src={externalPage}
            />
            <StyledButton onClick={onDismiss}>
              Close
            </StyledButton>
          </Container>
        </Modal>
        )
      }
      <Text>
        I agree to the&nbsp;
        <Link type="button" onClick={onTermsOfServiceClicked}>Terms of Service</Link>
        &nbsp;and&nbsp;
        <Link type="button" onClick={onPrivacyPolicyClicked}>Privacy Policy.</Link>
      </Text>
    </>
  );
};

export default TermsAndConditions;
