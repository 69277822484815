import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  FormHelperText,
} from '@mui/material';

import { TagCategory } from '../../../../../Model/ExerciseTag';
import Modal from '../../../../../../components/v2/Modal';
import InputLabel from '../../../../../../components/v2/InputLabel';
import { StyledTextField } from '../../../../../../components/Inputs';
import { ReactComponent as CreateIcon } from '../../../../../../assets/icons/v2/creation-plus-circle.svg';
import { ReactComponent as SaveIcon } from '../../../../../../assets/icons/v2/save-alt.svg';
import { PrimaryButton, CancelButton } from '../../../../../../components/Button/ActionButtons';

import {
  InputContainer,
  StyledSelect,
} from './styles';
import texts from './texts';

const TagsModal = ({
  open,
  onClose,
  onSave,
  selectedTag,
}) => {
  const [tagName, setTagName] = useState(selectedTag?.tag || '');
  const [tagCategory, setTagCategory] = useState(selectedTag?.category || '');
  // Initialize errors as false so we don't show errors when opening the modal
  const [nameError, setNameError] = useState(false);
  const [categoryError, setCategoryError] = useState(false);

  const categoryOptions = Object.entries(TagCategory)
    .reduce((acc, [key, value]) => {
      if (value !== TagCategory.PLATFORM) {
        acc.push({
          value,
          label: texts.tagCategoryOption[value],
          key,
        });
      }
      return acc;
    }, []);

  const handleNameChange = (event) => {
    const { value } = event.target;
    setTagName(value);
    setNameError(!value.trim());
  };

  const handleCategoryChange = (event) => {
    const { value } = event.target;
    setTagCategory(value);
    setCategoryError(!value);
  };

  const handleSave = () => {
    const newNameError = !tagName.trim();
    const newCategoryError = !tagCategory;
    setNameError(newNameError);
    setCategoryError(newCategoryError);

    if (!newNameError && !newCategoryError) {
      onSave({ tagName, tagCategory });
    }
  };

  const isEditing = !!selectedTag;

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={isEditing ? texts.editTag : texts.createNewTag}
      actionButtons={(
        <>
          <PrimaryButton
            onClick={handleSave}
            icon={isEditing ? <SaveIcon /> : <CreateIcon />}
            disabled={nameError || categoryError}
          >
            {isEditing ? texts.saveChanges : texts.addNewTag}
          </PrimaryButton>
          <CancelButton onClick={onClose}>{texts.cancel}</CancelButton>
        </>
      )}
    >
      <InputContainer>
        <InputLabel>{texts.tagName}</InputLabel>
        <StyledTextField
          value={tagName}
          onChange={handleNameChange}
          error={nameError}
          helperText={nameError && texts.nameRequired}
        />
      </InputContainer>
      <InputContainer>
        <FormControl
          error={categoryError}
        >
          <InputLabel>{texts.tagCategory}</InputLabel>
          <StyledSelect
            options={categoryOptions}
            onChange={handleCategoryChange}
            value={tagCategory}
          />
          {!!categoryError && <FormHelperText>{texts.categoryRequired}</FormHelperText>}
        </FormControl>
      </InputContainer>
    </Modal>
  );
};

TagsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  selectedTag: PropTypes.object,
};

TagsModal.defaultProps = {
  onClose: () => {},
  onSave: () => {},
  selectedTag: null,
};

export default TagsModal;
