import React from 'react';
import { ChannelBuckets } from './buckets';
import ChatState from './states';

const chatContextInitialState = {
  isMultiChannelView: false,
  isChatModalOpen: false,
  isChatReady: false,
  setChatModalOpen: () => {},
  chatClient: null,
  totalUnreadCount: 0,
  setTotalUnreadCount: () => {},
  onChatRefresh: () => {},
  onChatError: () => {},
  onChannelSelected: () => {},
  onBucketSelected: () => {},
  chatState: ChatState.CHAT_NOT_INITIALIZED,
  initialChatMenuOpen: false,
  setInitialChatMenuOpen: () => {},
  disconnectUser: () => {},
  openChatModal: () => {},
  userId: null,
  readOnlyMode: false,
  onMarkChannelAsRead: () => {},
  customActiveChannel: null,
  isSearching: false,
  searchQuery: '',
  shouldCheckForGreetingMessage: false,
  showGreetingMessageIfAny: () => {},
  selectedBucket: ChannelBuckets.ACTIVE,
  sendBroadcastMessage: () => {},
  scheduleBroadcastMessage: () => {},
  getScheduledMessages: () => {},
  onQuickChatUserSelected: () => {},
  quickChatUser: '',
  queryUserForActiveness: () => {},
  isChatEnabledForUser: () => {},
  onMarkChannelAsUnread: () => {},
  isChatListOpen: false,
  setChatListOpen: () => {},
  sendMessage: () => {},
  isSmartResponseOpen: false,
  setIsSmartResponseOpen: () => {},
};

const ChatContext = React.createContext(chatContextInitialState);

export default ChatContext;
