import React, { useContext } from 'react';

import LoadingPage from '../../components/LoadingPage';

import CoachAssignmentContextProvider from './CoachAssignmentContextProvider';
import CoachAssignmentContext from './CoachAssignmentContext';

const withCoachAssignmentContextProvider = (Component) => (props) => (
  <CoachAssignmentContextProvider>
    <Component {...props} />
  </CoachAssignmentContextProvider>
);

const withCoachAssignmentContextReady = (Component) => (props) => {
  const { isReady } = useContext(CoachAssignmentContext);
  return isReady ? <Component {...props} /> : <LoadingPage />;
};

export {
  withCoachAssignmentContextProvider,
  withCoachAssignmentContextReady,
};
