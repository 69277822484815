import { Collection } from 'firestorter';
import moment from 'moment';

import BaseDocument from '../../../Model/BaseDocument';
import { DateFormat } from '../../../utils/date';

class MonthlyAnalytics extends BaseDocument {
  constructor(collectionPath, id, opts) {
    super(`${collectionPath}/${id}`, opts);
  }

  static async getLastNMonthsAnalytics(collectionPath, noOfMonths, Document) {
    const analyticCollection = new Collection(collectionPath, {
      createDocument: (src, opts) => new Document(collectionPath, src.id, opts),
      query: (ref) => ref
        .where('yearMonth', '>=', moment().subtract(noOfMonths, 'months').format(DateFormat.YEAR_MONTH_FORMAT))
        .where('yearMonth', '<=', moment().format(DateFormat.YEAR_MONTH_FORMAT))
        .orderBy('yearMonth'),
    });
    await analyticCollection.fetch();
    const collectionDocs = analyticCollection.docs;

    const analytics = [];
    // we might not have analytics for all months, so we need to fill in the blanks
    for (let i = noOfMonths; i >= 0; i--) {
      const fromYearMonth = moment().subtract(i, 'months').format(DateFormat.YEAR_MONTH_FORMAT);
      if (collectionDocs[0]?.id === fromYearMonth) {
        analytics.push(collectionDocs.shift());
      } else {
        analytics.push(new Document(collectionPath, fromYearMonth));
      }
    }
    return analytics;
  }
}

export default MonthlyAnalytics;
