import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  padding: 20px;
  max-width: 40%;
  min-width: 350px;
`;

export {
  Container,
};
