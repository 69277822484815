import styled from 'styled-components';

import ContractDetails from '../../../../components/ContractDetails';
import CopyField from '../../../../components/CopyField';

const DetailsPanel = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledCopyField = styled(CopyField)`
  width: 100%;
`;

const StyledContractDetails = styled(ContractDetails)`
  padding: 0;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

export {
  DetailsPanel,
  StyledCopyField,
  StyledContractDetails,
  Title,
};
