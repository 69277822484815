import styled, { css } from 'styled-components';

import { Modal } from '@mui/material';

import colors from '../../../styles/colors';

const typographyStyles = css`
  font-style: normal;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 14px;
  text-align: initial;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  row-gap: 25px;
`;

const ContentContainer = styled.div`
  width: 100%;
  column-gap: 20px;
`;

const BucketHeader = styled.div`
  display: grid;
  grid-template-columns: 260px repeat(7, 1fr) 30px;
  column-gap: 4px;
  background: ${colors.base.secondary3};
  align-items: center;
  padding: 8px 10px 8px 48px;
`;

const BucketContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const BucketTitle = styled.div`
  ${typographyStyles};
  font-size: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 4px;
`;

const BucketHeaderCell = styled.div`
  ${typographyStyles};
  font-size: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 4px;
`;

const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalContentWrapper = styled.div`
  outline: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const NoMealPlaceholder = styled.div`
  display: flex;
  text-transform: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: fit-content;
  margin-top: 20px;
`;

const AddButtonContainer = styled.div`
  display: flex;
  text-transform: none;
  flex-direction: row;
  column-gap: 8px;
  justify-content: center;
  align-items: center;
  height: 80px;
`;
const PlaceholderLabel = styled.div`
  color: ${colors.shades.secondary8};
  font-size: 11px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0;
  text-align: center;
`;

export {
  BucketContainer,
  BucketHeader,
  BucketHeaderCell,
  BucketTitle,
  Container,
  ContentContainer,
  ModalContentWrapper,
  StyledModal,
  NoMealPlaceholder,
  PlaceholderLabel,
  AddButtonContainer,
};
