import styled from 'styled-components';
import {
  Box,
  Typography,
  Popper,
} from '@mui/material';
import colors from '../../../../../../styles/colors';

const StyledContainer = styled(Box)`
  width: 350px;
  background-color: ${colors.base.alpha};
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  border-radius: 4px;
  box-shadow: 0px 4px 4px ${colors.shades.gamma3};
  border: 1px solid ${colors.shades.gamma1};
`;

const Title = styled(Typography)`
  font-size: 18px;
  line-height: 21px;
  font-weight: 500;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 24px 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${colors.shades.gamma1};
`;

const RowContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 8px;
  padding: 8px 20px;
  border-bottom: 1px solid ${colors.shades.gamma5};
`;

const StyledColumnHeader = styled(Typography)`
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  color: ${colors.shades.gamma3};
`;

const StyledTypography = styled(Typography)`
  font-size: 14px;
  line-height: 16px;
  font-weight: ${({ $bold }) => ($bold ? 500 : 400)};
  text-overflow: ellipsis;
  overflow: hidden;
`;

const StyledPopper = styled(Popper)`
  z-index: 1;  
`;

export {
  Header,
  Title,
  RowContainer,
  StyledContainer,
  StyledColumnHeader,
  StyledPopper,
  StyledTypography,
};
