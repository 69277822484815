import React from 'react';

import ViewAsDescription from '../../components/ViewAsDescription';
import {
  Container,
} from './styles';

const SupportDashboard = () => (
  <Container>
    <ViewAsDescription />
  </Container>
);

export default SupportDashboard;
