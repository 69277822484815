const texts = {
  toolbarPlaceholder: 'Search for coach',
  emptyCell: '-',
  headers: {
    formId: 'Form ID',
    formName: 'Form Name',
    coach: 'Coach Name',
    results: 'Results',
    partials: 'Partials',
    action: 'Action',
  },
};

export default texts;
