import styled from 'styled-components';
import { IonSpinner } from '@ionic/react';

import { ReactComponent as TrashIcon } from '../../../../assets/icons/v2/trash.svg';
import { ReactComponent as AddIcon } from '../../../../assets/icons/v2/add.svg';
import { ReactComponent as BackIcon } from '../../../../assets/icons/arrow-circle-left.svg';

import colors from '../../../../styles/colors';
import FormikInput from '../../../../components/v2/FormikInput';

const Container = styled.div`
  width: 100%;
  min-width: 1000px;
  height: 100%;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  margin-top: 0;
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: stretch;
  align-items: flex-start;
  padding: 20px;
  margin-top: 0;
  > :last-child {
    min-width: 332px;
  }
`;

const Title = styled.div`
  font-size: 24px;
  text-align: left;
  color: ${colors.shades.secondary8};
`;

const StyledBackIcon = styled(BackIcon)`
  margin-right: 5px;
`;

const StyledTrashIcon = styled(TrashIcon)`
  width: 12px;
  height: 12px;
`;

const StyledAddIcon = styled(AddIcon)`
  width: 17px;
  height: 17px;
`;

const StyledFormRow = styled.div`
  padding-left: 10px;
  background-color: ${colors.shades.secondary10};
`;

const FormImageContainer = styled.div`
  width: 20%;
  padding: 10px 20px;
`;

const FormSectionContainer = styled.div`
  width: 80%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
`;

const ShortFormSection = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  width: 60%;
  min-width: 450px;
`;

const PrepSection = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 20px;

  > div {
    min-width: 30%;
  }
`;

const StyledFormInput = styled(FormikInput)`
  margin: 0;
  > p {
    font-size: 8px;
  }
  .MuiInputBase-root {
    height: fit-content;
  }
`;

const Label = styled.p`
  color: ${colors.shades.primary1};
  margin: 0 0 5px 0;
  padding-left: 3px;
  text-transform: uppercase;
  font-size: 8px;
  font-weight: 500;
  line-height: 9px;
  letter-spacing: 0.07em;
  text-align: left;
`;

const StyledFormRowScale = styled(StyledFormRow)`
  border-radius: 5px;
  border: 1px solid ${colors.shades.secondary7};
`;

const CheckDescription = styled.div`
  color: ${colors.shades.gamma2};
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0;
  text-align: left;
`;

const StyledSpinner = styled(IonSpinner)`
  width: 50px;
  height: 50px;
  margin: 10px auto;
`;

export {
  Container,
  HeaderContainer,
  ContentContainer,
  CheckDescription,
  StyledSpinner,
  Title,
  StyledBackIcon,
  StyledTrashIcon,
  StyledAddIcon,
  StyledFormRow,
  StyledFormRowScale,
  FormImageContainer,
  FormSectionContainer,
  ShortFormSection,
  PrepSection,
  StyledFormInput,
  Label,
};
