import styled from 'styled-components';

import SubscribeButton from '../SubscribeButton';

const StyledButton = styled(SubscribeButton)`
  /*
    Some of the styling below was obtained from:
    https://developer.apple.com/documentation/apple_pay_on_the_web/displaying_apple_pay_buttons

    They are applied for this intended button:
    - Dark background
    - White text and Logo
  */
  --apple-pay-scale: 1; /* (height / 32) */
  display: inline-flex;
  justify-content: center;
  background-color: black;
  color: white;
  & > .logo {
    background-image: -webkit-named-image(apple-pay-logo-white);
    background-color: black;
    width: calc(62px * var(--apple-pay-scale));
    min-height: 32px;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 0 50%;
    margin-left: calc(5px * var(--apple-pay-scale));
    border: none;
  }
  & > .text {
    font-family: -apple-system;
    font-size: calc(1em * var(--apple-pay-scale));
    font-weight: normal;
    align-self: center;
    margin-right: calc(2px * var(--apple-pay-scale));
  }
`;

export {
  StyledButton,
};
