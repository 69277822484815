import { menuController } from '@ionic/core';

const CHAT_MENU_ID = 'chat-side-menu';

const toggleChatMenu = async () => {
  await menuController.enable(true, CHAT_MENU_ID);
  await menuController.toggle(CHAT_MENU_ID);
};

const openChatMenu = async () => {
  await menuController.enable(true, CHAT_MENU_ID);
  await menuController.open(CHAT_MENU_ID);
};

export {
  openChatMenu,
  toggleChatMenu,
  CHAT_MENU_ID,
};
