import { Collection } from 'firestorter';
import { forEachSeries } from 'p-iteration';

import Lead from '../CoachTools/Model/Lead';
import Collections from '../utils/collections';
import { DEFAULT_CURRENCY } from '../utils/currency';
import BaseDocument from './BaseDocument';
import Product from './Product';

class ExternalCoach extends BaseDocument {
  constructor(id, opts) {
    super(`${Collections.EXTERNAL_COACH}/${id}`, opts);
  }

  get stripeAccountId() {
    return this.data.stripeAccountId;
  }

  get applePay() {
    return this.data.applePay;
  }

  get clientInitialPreferencesForm() {
    return this.data.clientInitialPreferencesForm;
  }

  get defaultCurrency() {
    return this.data.defaultCurrency || DEFAULT_CURRENCY.code;
  }

  get supportedCurrencies() {
    return this.data.supportedCurrencies || [DEFAULT_CURRENCY.code];
  }

  get baseFee() {
    return this.data.baseFee;
  }

  get postPaymentFormLink() {
    return this.data.postPaymentFormLink;
  }

  async getProducts(returnCollection = false) {
    const coachProductsCollection = new Collection(Collections.PRODUCT, {
      query: (ref) => ref.where('coach', '==', this.id),
      createDocument: (source, options) => new Product(source.id, options),
    });

    await coachProductsCollection.fetch();
    if (returnCollection) {
      return coachProductsCollection;
    }
    return coachProductsCollection.hasDocs ? coachProductsCollection.docs.filter((doc) => !!doc.name) : [];
  }

  async getAllPlans() {
    const plans = [];
    const products = await this.getProducts();

    await forEachSeries(products, async (productDoc) => {
      const productPlans = await productDoc.getPlans();
      plans.push(...productPlans.docs);
    });

    // Filter the plans by their plan code instead of using the ids, which can seem a little random.
    const sortedPlans = [...plans].sort((a, b) => (a.planCode > b.planCode ? -1 : 1));

    return sortedPlans;
  }

  async getLeads(fromDate) {
    const leadsCollection = new Collection(Collections.LEADS, {
      createDocument: ({ id }, opts) => new Lead(id, { ...opts, disableObserverRefCount: true }),
      query: (ref) => {
        let query = ref.where('coach', '==', this.id);
        if (fromDate) {
          query = query.where('submitDate', '>=', fromDate);
        }
        return query.orderBy('submitDate', 'desc');
      },
    });
    await leadsCollection.fetch();
    return leadsCollection;
  }
}

export default ExternalCoach;
