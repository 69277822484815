import { Collection } from 'firestorter';
import BaseDocument from '../../Model/BaseDocument';
import Collections from '../../utils/collections';

class LeadMsg extends BaseDocument {
  get body() {
    // TODO: Handle media messages
    if (this.hasMedia) {
      return 'Media message';
    }
    if (this.data.body) {
      return this.data.body;
    }
    return '';
  }

  get contactNo() {
    return this.data.contactNo;
  }

  get from() {
    return this.data.from;
  }

  get to() {
    return this.data.to;
  }

  get hasMedia() {
    return this.data.hasMedia;
  }

  get isFromMe() {
    return this.data.isFromMe;
  }

  get provider() {
    return this.data.provider;
  }

  get sentAs() {
    return this.data.sentAs;
  }

  get sentAsUsername() {
    return this.data.sentAsUsername;
  }

  get sentAt() {
    return this.data.sentAt.toDate();
  }

  static async getLeadMsgs(contactNo) {
    const leadMsgsCollection = new Collection(Collections.LEAD_MSGS, {
      createDocument: (src, opts) => new LeadMsg(src, opts),
      query: (ref) => ref
        .where('contactNo', '==', contactNo),
    });
    await leadMsgsCollection.fetch();
    return leadMsgsCollection;
  }

  static async getLeadEmails(email) {
    const leadMsgsCollection = new Collection(Collections.LEAD_MSGS, {
      createDocument: (src, opts) => new LeadMsg(src, opts),
      query: (ref) => ref
        .where('contactEmail', '==', email),
    });
    await leadMsgsCollection.fetch();
    return leadMsgsCollection;
  }
}

export default LeadMsg;
