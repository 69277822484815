import { getFirestore } from 'firestorter';
import moment from 'moment';

import CollectionName from '../utils/collections';
import { DateFormat } from '../utils/date';
import BaseDocument from './BaseDocument';

/**
 * The standard code for a plan.
 */
const STANDARD_PLAN_CODE = 'STANDARD_PLAN';

/**
 * Class representing a plan.
 *
 * @class Plan
 * @extends BaseDocument
 */
class Plan extends BaseDocument {
  /**
   * Get the price in cents.
   * @return {number}
   */
  get totalPriceInCents() {
    return this.data.totalPriceInCents;
  }

  /**
   * Get the number of trial days.
   * @return {number}
   */
  get trialPeriodDays() {
    return this.data.trialPeriodDays;
  }

  /**
   * Get the number of trial days for referred users.
   * @return {number}
   */
  get trialPeriodReferredUsers() {
    return this.data.trialPeriodReferredUsers;
  }

  /**
   * Get the plan code.
   * @return {string}
   */
  get planCode() {
    return this.data.planCode;
  }

  /**
   * Get the associated product.
   * @return {string}
   */
  get productRef() {
    return this.ref.parent.parent;
  }

  /**
   * Get the price set on subscription creation.
   * @return {number}
   */
  get totalPayOnSubscriptionCreation() {
    return this.trialPeriodDays === 0 ? this.totalPriceInCents : 0;
  }

  /**
   * Get the status of the plan (free or not).
   * @return {bool}
   */
  get isFree() {
    return this.totalPriceInCents === 0;
  }

  /**
   * Get the recurring billing months.
   * @return {number}
   */
  get recurringBillingMonths() {
    return (typeof this.data.recurringBillingMonths === 'number') ? this.data.recurringBillingMonths : 1;
  }

  /**
   * Get the minimun months of the subscription.
   * @return {number}
   */
  get minSubscriptionMonths() {
    return this.data.minSubscriptionMonths;
  }

  /**
   * Get user's cancel at date in unix timestamp.
   * @return {number}
   */
  get cancelAt() {
    return this.data.cancelAt && moment(this.data.cancelAt, DateFormat.DATE_FORMAT_TIME_MINUTES);
  }

  /**
   * Get user's accept until date in unix timestamp.
   * @return {number}
   */
  get acceptUntil() {
    return this.data.acceptUntil && moment(this.data.acceptUntil, DateFormat.DATE_FORMAT_TIME_MINUTES);
  }

  /**
   * Get the coach offer text.
   * @return {string}
   */
  get offeringText() {
    return this.data.offeringText;
  }

  /**
   * Get the plan title.
   * @return {string}
   */
  get planTitle() {
    return this.data.planTitle;
  }

  /**
   * Get the currency in which the price is represented.
   * @return {string}
   */
  get currency() {
    return this.data.currency;
  }

  /**
   * Get the refundable status of the plan.
   * @return {bool}
   */
  get refundable() {
    return (typeof this.data.refundable === 'boolean') ? this.data.refundable : true;
  }

  /**
   * Get the status of cancellation at period end.
   * @return {bool}
   */
  get cancelAtPeriodEnd() {
    return !!this.data.cancelAtPeriodEnd;
  }

  /**
   * Get the fee/amount.
   * @return {number}
   */
  get fee() {
    return this.data.fee;
  }

  /**
   * Get the pricing status.
   * @return {bool}
   */
  get isS2Pricing() {
    return this.data.isS2Pricing;
  }

  /**
   * Returns a plan by a given plan code.
   * @param {string} planCode - The code of the plan to be retrieved.
   * @return {Promise<Plan>} A promise that resolves to Plan.
   */
  static async getPlan(planCode = STANDARD_PLAN_CODE) {
    const db = getFirestore();

    const priceQuerySnapshot = await db.collectionGroup(CollectionName.PRICE)
      .where('planCode', '==', planCode)
      .limit(1)
      .get();

    if (!priceQuerySnapshot.empty) {
      const planDoc = priceQuerySnapshot.docs[0];
      const plan = new Plan(planDoc.ref);
      await plan.init();
      return plan;
    }

    return null;
  }

  /**
   * Returns a plan by a given plan id.
   * @param {string} planId - The id of the plan to be retrieved.
   * @return {Promise<Plan>} A promise that resolves to a Plan.
   */
  static async getPlanById(planId) {
    if (!planId) {
      return null;
    }
    const db = getFirestore();

    const planQuerySnapshot = await db.collectionGroup(CollectionName.PRICE)
      .where('priceId', '==', planId)
      .limit(1)
      .get();

    if (!planQuerySnapshot.empty) {
      const planDoc = planQuerySnapshot.docs[0];
      const plan = new Plan(planDoc.ref);
      await plan.init();
      return plan;
    }

    return null;
  }
}

export default Plan;
