import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

import config from '../config';
import callRemote from '../utils/remote';

class Firebase {
  constructor() {
    app.initializeApp(config.firebase);
    this.auth = app.auth();
    this.storage = app.storage();
    this.firestore = app.firestore();
    this.app = app;
  }

  signOut = () => this.auth.signOut();

  remote = async (path, body = null, request = {}) => {
    // TODO: If this call happens on page-load, auth may not have
    // the currentUser's id token ready yet.  Should handle that case
    // by waiting for the currentUser to be populated with the
    // logged-in user.
    const idToken = await this.auth.currentUser.getIdToken();
    return callRemote(idToken, path, body, request);
  };
}
export default Firebase;
