import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import {
  Route,
  Redirect,
  useLocation,
} from 'react-router-dom';

import useSessionStore from '../../hooks/useSessionStore';

/**
 * A wrapper for <Route> that redirects to the login page if the user is not authenticated, is not an inside sales
 * person, or if the userId in the path doesn't match the one for the user currently logged in.
 */
const InsideSalesRoute = ({
  component: Component,
  children,
  ...rest
}) => {
  const {
    userId,
    isInsideSales,
    isAdmin,
  } = useSessionStore();
  const location = useLocation();
  const { search, state } = location;

  return (
    <Route
      {...rest}
      render={(props) => {
        if ((isInsideSales && location.pathname.includes(userId)) || isAdmin) {
          return Component ? <Component {...props} /> : children;
        }
        return (
          <Redirect to={{
            pathname: '/login',
            search,
            state,
          }}
          />
        );
      }}
    />
  );
};

InsideSalesRoute.propTypes = {
  component: PropTypes.elementType,
  children: PropTypes.node,
};

InsideSalesRoute.defaultProps = {
  children: null,
  component: null,
};

export default compose(
  observer,
)(InsideSalesRoute);
