import React, {
  useContext,
  useMemo,
  useState,
} from 'react';
import { useRouteMatch } from 'react-router-dom';
import { CloseOutlined } from '@mui/icons-material';

import config from '../../../../../config';
import FeedContext from '../../../../context/FeedContext';
import Portal from '../../../../components/Portal';

import {
  Error,
  StyledButton,
} from './styles';
import texts from './texts.json';

const MobileView = () => {
  const {
    selectedActivity,
    setSelectedActionBarType,
  } = useContext(FeedContext);
  const {
    params: {
      userId: coachId,
    },
  } = useRouteMatch();
  const [isReady, setIsReady] = useState(false);

  const mobileUrl = useMemo(() => {
    if (selectedActivity) {
    /*
     * We need to break the URL into multiple lines to avoid white spaces introduced by line breaks
     * and to adhere to the linting rule
     */
      return `${config.mobileAppURL}/u/${selectedActivity.user}`
        + `/workoutAssignment/${selectedActivity.workoutAssignmentId}`
        + `/details?coachId=${coachId}`;
    }
    return null;
  }, [
    selectedActivity,
    coachId,
  ]);

  return mobileUrl
    ? (
      <>
        {isReady && (
          <StyledButton onClick={() => setSelectedActionBarType(null)}>
            <CloseOutlined />
          </StyledButton>
        )}
        <Portal
          url={mobileUrl}
          onIframeLoaded={() => setIsReady(true)}
        />
      </>
    )
    : <Error>{texts.noActivitySelected}</Error>;
};

export default MobileView;
