import React, { useContext } from 'react';

import LoadingPage from '../../../components/LoadingPage';
import OnboardingCoachNotificationsContext from './OnboardingCoachNotificationsContext';
import OnboardingCoachNotificationsContextProvider from './OnboardingCoachNotificationsContextProvider';

const withOnboardingCoachNotificationsContextProvider = (Component) => (props) => (
  <OnboardingCoachNotificationsContextProvider>
    <Component {...props} />
  </OnboardingCoachNotificationsContextProvider>
);

const withOnboardingCoachNotificationsContextReady = (Component) => (props) => {
  const { isReady } = useContext(OnboardingCoachNotificationsContext);
  return isReady ? <Component {...props} /> : <LoadingPage />;
};

export {
  withOnboardingCoachNotificationsContextProvider,
  withOnboardingCoachNotificationsContextReady,
};
