import React, {
  useState,
  useMemo,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  MenuItem,
} from '@mui/material';
import format from 'string-template';

import { DateFormat } from '../../../../../../utils/date';
import Button from '../../../../../../components/Button';
import texts from './texts.json';
import {
  StyledPaper,
  Container,
  StyledSelect,
} from './styles';

const DEFAULT_PAUSE_PERIOD_MONTHS = 3;
const MAX_PAUSE_MONTHS = 12;

const SubscriptionPauseModal = ({
  isOpen,
  onCancel,
  subscription,
  pauseClientSubscription,
  userDoc,
}) => {
  const [selectedPeriod, setSelectedPeriod] = useState(DEFAULT_PAUSE_PERIOD_MONTHS);
  const [pausingReason, setPausingReason] = useState('');

  const breakEndDate = useMemo(() => moment().add(selectedPeriod, 'months'), [selectedPeriod]);

  const handleConfirm = useCallback(async () => pauseClientSubscription(
    pausingReason,
    breakEndDate.utc().startOf('day'),
    selectedPeriod,
  ), [
    breakEndDate,
    pauseClientSubscription,
    pausingReason,
    selectedPeriod,
  ]);

  const voidInvoiceDates = useMemo(() => {
    const invoiceDate = moment.unix(subscription.current_period_end);
    const dates = [];
    while (invoiceDate.isBefore(breakEndDate)) {
      dates.push(invoiceDate.clone());
      invoiceDate.add(1, 'month');
    }
    return dates;
  }, [
    subscription,
    breakEndDate,
  ]);

  return (
    <Dialog
      open={isOpen}
      PaperComponent={StyledPaper}
    >
      <DialogTitle>
        {texts.title}
      </DialogTitle>
      <DialogContent>
        <span>
          {texts.suspendPrefix}
          <StyledSelect
            value={selectedPeriod}
            onChange={(e) => setSelectedPeriod(e.target.value)}
          >
            {/* month select from 1 to max pause months */}
            {Array.from({ length: MAX_PAUSE_MONTHS }, (_, i) => i + 1)
              .map((num) => <MenuItem value={num} key={num}>{num}</MenuItem>)}
          </StyledSelect>
          {texts.suspendSuffix}
        </span>
        <ul>
          {voidInvoiceDates.map((date) => (
            <li key={date}>
              {date.format(DateFormat.CONTRACT_DATE_FORMAT)}
            </li>
          ))}
        </ul>
        {texts.noFeedWarning}
        <Container>
          <TextField
            label={texts.reason}
            variant="outlined"
            onChange={(event) => setPausingReason(event.target.value)}
            value={pausingReason}
            fullWidth
            required
            error={!pausingReason}
            helperText={!pausingReason ? texts.required : ''}
          />
        </Container>
        <DialogContentText>
          {format(texts.confirmInfo, { userName: userDoc.name })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleConfirm}
          color="success"
          disabled={!pausingReason}
        >
          {texts.confirm}
        </Button>
        <Button
          onClick={onCancel}
          color="warning"
        >
          {texts.cancel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

SubscriptionPauseModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  subscription: PropTypes.object.isRequired,
  pauseClientSubscription: PropTypes.func.isRequired,
  userDoc: PropTypes.object.isRequired,
};

export default SubscriptionPauseModal;
