import { Collection, Document } from 'firestorter';

class StripeCustomerDocument extends Document {
  static async getStripeCustomerByUserId(userId) {
    const collection = new Collection('/integrations/stripe/customers/',
      {
        query: (ref) => ref
          .where('user', '==', userId)
          .limit(1),
        createDocument: (source, options) => new StripeCustomerDocument(source, options),
      });
    await this.init(collection);
    if (collection.hasDocs) {
      return collection.docs[0];
    }
    return {};
  }

  static async init(collection) {
    // eslint-disable-next-line no-param-reassign
    collection.mode = 'off';
    if (!collection.isLoading && !collection.isActive) {
      await collection.fetch();
    }
    await collection.ready();
    // eslint-disable-next-line no-param-reassign
    collection.mode = 'auto';
  }

  getCustomer() {
    return this.data.stripeCustomer;
  }
}

export default StripeCustomerDocument;
