import React from 'react';

import { UserRole } from '../../../Model/User';
import UserManagement from '../../components/UserManagement';

import texts from './texts.json';

const ISUsers = () => (
  <UserManagement
    role={UserRole.IS}
    title={texts.iSUsers}
  />
);

export default ISUsers;
