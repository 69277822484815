import styled from 'styled-components';
import { Button } from '@mui/material';

import colors from '../../../styles/colors';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 30px;
`;

const HeaderContainer = styled.div`
  display: flex;
  padding: 20px 0px;
  justify-content: space-between;
`;

const Title = styled.div`
  font-size: 24px;
  line-height: 28px;
  align-self: center;
  text-align: left;
`;

const StyledCreationButton = styled(Button)`
  color: ${colors.base.beta};
  background-color: ${colors.shades.primary4};
  border: 1px solid ${colors.shades.primary2};
  padding: 10px 20px;
  border-radius: 5px;
  text-transform: none;

  &:hover {
    background-color: ${colors.shades.primary2};
  }
`;

export {
  Container,
  HeaderContainer,
  Title,
  StyledCreationButton,
};
