import React from 'react';
import PropTypes from 'prop-types';

import {
  HeaderlessContainer,
  ScrollableArea,
} from './styles';

const HeaderlessLayout = ({
  children,
}) => (
  <HeaderlessContainer>
    <ScrollableArea>
      {children}
    </ScrollableArea>
  </HeaderlessContainer>
);

HeaderlessLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default HeaderlessLayout;
