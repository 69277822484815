import fieldName from './formFields';

const texts = {
  title: {
    add: 'Add Circuit',
    edit: 'Edit Circuit',
  },
  button: {
    add: 'Add Circuit',
    update: 'Update Circuit',
  },
  validation: {
    requiredField: 'Required',
    positiveNumber: 'Only positive numbers',
    integerNumber: 'Only integer numbers',
  },
  fieldLabel: {
    [fieldName.NAME]: 'Name',
    [fieldName.DESCRIPTION]: 'Description',
    [fieldName.SET]: 'Set',
  },
};

export default texts;
