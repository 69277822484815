export const ImperialUnits = {
  Teaspoon: 'tsp',
  Tablespoon: 'tbsp',
  Ounce: 'ounce',
  Cup: 'cup',
  Custom: '',
};

export const MetricUnits = {
  None: '',
  Gram: 'g',
  Milligram: 'mg',
  Microgram: 'mcg',
  Kilogram: 'kg',
  Liter: 'l',
  Milliliter: 'ml',

  IU: 'IU',
  /**
   * Microgram of retinol activity equivalents
   */
  MicrogramRAE: 'mcg RAE',
  /**
   * Micrograms of dietary folate equivalents
   */
  MicrogramDFE: 'mcg DFE',
  /**
   * milligrams of niacin equivalents
   */
  MilligramNE: 'mg NE',
};

export class ServingSize {
  metricUnit = MetricUnits.None;

  metricSize = 0;

  customName = '';

  get exactImperial() {
    const components = this.fractionalSize.split('/');
    if (components.length > 1) {
      if (parseInt(components[1], 10) === 0) {
        throw new Error('Divide by zero in ServingSize');
      }
      return parseInt(components[0], 10) / parseInt(components[1], 10);
    }
    return parseInt(components[0], 10);
  }

  constructor(item) {
    Object.assign(this, item);
  }

  get imperialUnitsRepr() {
    if (this.imperialUnits === ImperialUnits.Custom) {
      return this.customName;
    }
    return this.imperialUnits;
  }

  toString = () => {
    let servingstmt = `Serving Size ${this.fractionalSize} ${this.imperialUnitsRepr}`;

    if (this.metricUnit === MetricUnits.None || this.metricSize === 0) {
      return servingstmt;
    }

    servingstmt += ` (about ${this.metricSize}${this.metricUnit})`;

    return servingstmt;
  };
}
