import styled from 'styled-components';

import { Typography } from '@mui/material';

import LabelCheckbox from '../../components/LabelCheckbox';

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Title = styled(Typography)`
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 5px;
`;

const StyledLabelCheckbox = styled(LabelCheckbox)`
  margin-left: auto;
`;

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 30px;
  }
  & > *:last-child {
    margin-left: unset;
  }
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 8px;
`;

const StyledContractName = styled.div`
  text-decoration: underline;
  cursor: pointer;
`;

export {
  Container,
  Title,
  HeaderRow,
  ActionButtonsContainer,
  StyledLabelCheckbox,
  StyledContractName,
};
