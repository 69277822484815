import styled from 'styled-components';
import { AddCircleOutline as AddIcon } from '@mui/icons-material';

import RoundedButton from '../../../../../../../components/v2/RoundedButton';
import colors from '../../../../../../../styles/colors';
import { ButtonIconStyle } from '../../../../../../../components/v2/Button';

const StyledButton = styled(RoundedButton)`
  background-color: ${colors.shades.beta2};
  color: ${colors.base.alpha};
`;

const FeatureContainer = styled.div`
  display: flex;
  flex-direction: column;
  
  .MuiFormControlLabel-label {
    font-size: 14px;
  }
`;

const StyledAddIcon = styled(AddIcon)`
  ${ButtonIconStyle}
  color: ${colors.shades.primary1};
`;

export {
  StyledButton,
  FeatureContainer,
  StyledAddIcon,
};
