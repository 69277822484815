import React from 'react';
import PropTypes from 'prop-types';

import {
  StyledBackButton,
  StyledBackIcon,
} from './styles';

const BackButton = ({
  label,
  onClick,
}) => (
  <StyledBackButton
    startIcon={<StyledBackIcon />}
    onClick={onClick}
  >
    {label}
  </StyledBackButton>
);

BackButton.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

BackButton.defaultProps = {
  label: '',
};

export default BackButton;
