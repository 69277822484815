const ColorCode = {
  GREEN: 'GREEN',
  YELLOW: 'YELLOW',
  RED: 'RED',
  BLACK: 'BLACK',
};

const ColorCodeBound = {
  [ColorCode.GREEN]: [0, 10],
  [ColorCode.YELLOW]: [11, 25],
  [ColorCode.RED]: [26, 100],
};

const getColorBasedOnDeviation = (recipeValue, targetValue) => {
  // If no target value is found we will default to black
  if (!targetValue) {
    return ColorCode.BLACK;
  }

  const deviationPercentage = Math.abs(((recipeValue - targetValue) / recipeValue) * 100);
  if (deviationPercentage <= ColorCodeBound[ColorCode.GREEN][1]) {
    return ColorCode.GREEN;
  }
  if (deviationPercentage >= ColorCodeBound[ColorCode.YELLOW][0]
    && deviationPercentage <= ColorCodeBound[ColorCode.YELLOW][1]) {
    return ColorCode.YELLOW;
  }
  return ColorCode.RED;
};

export {
  ColorCode,
  getColorBasedOnDeviation,
};
