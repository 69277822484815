import React from 'react';
import styled from 'styled-components';

import {
  NutritionLookUp,
} from '../utils/NutritionItemData';

const FormatPercentage = (float) => `${Math.round(100 * float)}%`;

/**
 * Renders the children of a major nutrient item
 * @param item Child nutrient
 */
const ChildRow = (item, idx) => {
  const { amount, nutritionItem } = item;
  const detail = NutritionLookUp[nutritionItem];
  const displayName = `${detail.name} ${amount}${detail.units}`;
  const showPercentage = detail.dailyValue !== -1;
  const key = `child-nest-${nutritionItem}-${idx}`;

  const LineItem = styled.td`
    width: 1rem;
    border-top: 0;
  `;

  return (
    <tr>
      <LineItem key={key} />
      <th>{displayName}</th>
      <LineItem>
        {showPercentage && (
          <b>{FormatPercentage(amount / detail.dailyValue)}</b>
        )}
      </LineItem>
    </tr>
  );
};

const ParentRow = (item, idx, end) => {
  const { amount, nutritionItem } = item;

  const detail = NutritionLookUp[nutritionItem];
  const key = `${idx}-${detail.name}`;
  const showPercentage = detail.dailyValue !== -1;

  return (
    <tr style={end ? { borderBottom: '10px solid black' } : {}}>
      <th colSpan={2} key={key}>
        <b>{detail.name}</b>
        {' '}
        { amount }
        { detail.units }
      </th>
      <td>
        {showPercentage && (
          <b>{FormatPercentage(amount / detail.dailyValue)}</b>
        )}
      </td>
    </tr>
  );
};

export const MajorNutritionItems = (items) => {
  const majorOnly = items.filter((val) => (!NutritionLookUp[val.nutritionItem].minorItem));
  const parents = majorOnly.filter((val) => !NutritionLookUp[val.nutritionItem].parent);

  const nutrients = [];
  parents.forEach((val, idx) => {
    nutrients.push(ParentRow(val, idx, idx === parents.length - 1));

    majorOnly
      .filter((x) => val.nutritionItem === NutritionLookUp[x.nutritionItem].parent)
      .forEach((val1, idx1) => {
        if (val1.amount > 0) {
          nutrients.push(ChildRow(val1, idx1));
        }
      });
  });
  return nutrients;
};

export const MinorNutritionItems = (items) => {
  const minorOnly = items.filter((val) => !!NutritionLookUp[val.nutritionItem].minorItem);

  const nutrients = [];
  for (let i = 0; i < minorOnly.length; i += 2) {
    const itm1 = minorOnly[i];
    const lookUp1 = NutritionLookUp[itm1.nutritionItem];
    const percent1 = FormatPercentage(
      itm1.fractionOverride ?? itm1.amount / lookUp1.dailyValue,
    );

    const itm2 = minorOnly[i + 1];
    let lookUp2 = null;
    let percent2 = '';
    if (itm2) {
      lookUp2 = NutritionLookUp[itm2.nutritionItem];
      percent2 = FormatPercentage(
        itm2.fractionOverride ?? itm2.amount / lookUp2.dailyValue,
      );
    }

    const end = i >= minorOnly.length - 2;
    const displayName = `${lookUp2?.name} ${percent2}`;

    nutrients.push(
      <tr style={end ? { borderBottom: '1px solid black' } : {}}>
        <td colSpan={2}>
          {lookUp1.name}
          {' '}
          {percent1}
        </td>
        {itm2 ? <td>{displayName}</td> : <td />}
      </tr>,
    );
  }

  return nutrients;
};
