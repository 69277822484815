import React, {
  useState,
  useCallback,
  useMemo,
} from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';

import {
  IconButton,
  TextField,
  Button,
  Tooltip,
} from '@mui/material';
import {
  DatePicker,
  TimePicker,
} from '@mui/lab';

import {
  DateFormat,
  isToday,
  combineDateAndTime,
} from '../../../../../../utils/date';
import CoachReminderNotification from '../../../../../../Model/CoachReminderNotification';

import {
  ItemContainer,
  MessageDate,
  MessageText,
  ActionsContainer,
  InfoContainer,
  EditContainer,
  StyledTextField,
  InfoText,
} from './styles';
import texts from './texts.json';

const ItemStatus = {
  INITIAL: 'INITIAL',
  EDITING: 'EDITING',
  DELETING: 'DELETING',
};

const ScheduledReminderItem = ({
  reminderNotificationDoc,
}) => {
  const {
    content,
    sendAt,
  } = reminderNotificationDoc;

  const [sendDate, setSendDate] = useState(moment(sendAt).local());
  const [sendTime, setSendTime] = useState(moment(sendAt).local());
  const [reminderText, setReminderText] = useState(content);
  const [itemStatus, setItemStatus] = useState(ItemStatus.INITIAL);

  const onSave = useCallback(async () => {
    // Convert to UTC after adding date and time
    const newSendAt = combineDateAndTime(sendDate, sendTime).utc().valueOf();
    await reminderNotificationDoc.updateContentAndDate(reminderText, newSendAt);
    setSendTime(moment(newSendAt).local());
    setSendDate(moment(newSendAt).local());
    setItemStatus(ItemStatus.INITIAL);
  }, [
    reminderNotificationDoc,
    reminderText,
    sendDate,
    sendTime,
  ]);

  const onDelete = useCallback(async () => {
    await reminderNotificationDoc.delete();
    setItemStatus(ItemStatus.INITIAL);
  }, [
    reminderNotificationDoc,
  ]);

  // If the selected date is today, then the user cannot select a time in the past
  const minTime = useMemo(() => (
    isToday(sendDate) ? moment().local() : null
  ), [
    sendDate,
  ]);

  const renderItemContent = useCallback(() => {
    switch (itemStatus) {
      case ItemStatus.EDITING:
        return (
          <EditContainer>
            <TextField
              value={reminderText}
              fullWidth
              multiline
              maxRows={4}
              variant="standard"
              onChange={(e) => setReminderText(e.target.value)}
            />
            <DatePicker
              label={texts.selectDate}
              value={sendDate}
              onChange={(newValue) => setSendDate(newValue)}
              minDate={moment().local()}
              maxDate={moment().local().add(3, 'months')}
              renderInput={(params) => (
                <StyledTextField {...params} />
              )}
            />
            <TimePicker
              label={texts.selectTime}
              value={sendTime}
              onChange={(newValue) => setSendTime(newValue)}
              minTime={minTime}
              minutesStep={5}
              renderInput={(params) => (
                <StyledTextField {...params} />
              )}
            />
          </EditContainer>
        );
      case ItemStatus.DELETING:
        return (
          <InfoContainer>
            <InfoText>{texts.deleteConfirm}</InfoText>
          </InfoContainer>
        );
      case ItemStatus.INITIAL:
      default:
        return (
          <InfoContainer>
            <MessageDate>{moment(sendAt).format(DateFormat.LOCAL_DATE_AND_TIME)}</MessageDate>
            <MessageText>{content}</MessageText>
          </InfoContainer>
        );
    }
  }, [
    itemStatus,
    content,
    reminderText,
    minTime,
    sendAt,
    sendDate,
    sendTime,
  ]);

  const renderActionButtons = useCallback(() => {
    switch (itemStatus) {
      case ItemStatus.EDITING:
        return (
          <ActionsContainer>
            <Button
              onClick={onSave}
              color="success"
            >
              {texts.button.save}
            </Button>
          </ActionsContainer>
        );
      case ItemStatus.DELETING:
        return (
          <ActionsContainer>
            <Button
              onClick={() => setItemStatus(ItemStatus.INITIAL)}
              color="info"
            >
              {texts.button.cancel}
            </Button>
            <Button
              onClick={onDelete}
              color="warning"
            >
              {texts.button.delete}
            </Button>
          </ActionsContainer>
        );
      case ItemStatus.INITIAL:
      default:
        return (
          <ActionsContainer>
            <Tooltip title={texts.tooltip.delete}>
              <IconButton
                onClick={() => setItemStatus(ItemStatus.DELETING)}
              >
                <ClearIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={texts.tooltip.edit}>
              <IconButton
                onClick={() => setItemStatus(ItemStatus.EDITING)}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          </ActionsContainer>
        );
    }
  }, [
    itemStatus,
    onSave,
    onDelete,
  ]);

  return (
    <ItemContainer>
      {renderItemContent()}
      {renderActionButtons()}
    </ItemContainer>
  );
};

ScheduledReminderItem.propTypes = {
  reminderNotificationDoc: PropTypes.instanceOf(CoachReminderNotification).isRequired,
};

export default observer(ScheduledReminderItem);
