import styled from 'styled-components';
import { Select } from '@mui/material';

import colors from '../../../styles/colors';

const StyledSelect = styled(Select)`
  width: 250px;
  height: 100%;
  border-radius: 5px;
  background-color: ${colors.shades.alpha1};
  font-size: 14px;
  line-height: 16px;
  text-align: initial;

  & .MuiSelect-select {
    min-height: unset;
  }

  & fieldset {
    border-color: ${colors.shades.gamma5};
  }

  & svg {
    right: 16px;
  }

  &:hover .MuiOutlinedInput-notchedOutline {
    border-color: ${colors.shades.gamma8};
  }
`;

export default StyledSelect;
