import React, {
  useMemo,
  useState,
  useEffect,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { compose } from 'recompose';
import { observer } from 'mobx-react';

import useComponentMounted from '../../../hooks/useComponentMounted';
import { getQueryVariable } from '../../../utils/queryParams';
import {
  DaysForRefreshInterval,
  DEFAULT_MEAL_REFRESH_DAYS,
  MealPlanAssignmentStatus,
} from '../../utils/mealPlan';
import MealPlanAssignment, { RefreshInterval } from '../../Model/MealPlanAssignment';
import User from '../../../Model/User';
import UserNutritionProfile from '../../Model/UserNutritionProfile';
import UserNutritionMacroGoals from '../../Model/UserNutritionMacroGoals';
import UserActivityProfile from '../../Model/UserActivityProfile';
import WeeklyCheckin from '../../Model/WeeklyCheckin';
import ExternalCoachContext from '../ExternalCoachContext';

import MealPlanAssignmentContext, { initialValues } from './MealPlanAssignmentContext';

const MealPlanAssignmentContextProvider = ({
  children,
}) => {
  // Get refresh user id, redirect to meal plan dashboard if not present
  const userId = getQueryVariable('user');

  const isComponentMountedRef = useComponentMounted();

  const [isReady, setIsReady] = useState(initialValues.isReady);
  const [isInitialized, setIsInitialized] = useState(initialValues.isInitialized);

  // User documents states
  const [weeklyCheckinDocs, setWeeklyCheckinDocs] = useState(initialValues.weeklyCheckinDocs);
  const [mealPlanAssignmentDoc, setMealPlanAssignmentDoc] = useState(initialValues.mealPlanAssignmentDoc);
  const [userDoc, setUserDoc] = useState(initialValues.userDoc);
  const [nutritionProfileDoc, setNutritionProfileDoc] = useState(initialValues.nutritionProfileDoc);
  const [macroGoalsDoc, setMacroGoalsDoc] = useState(initialValues.macroGoalsDoc);
  const [activityProfileDoc, setActivityProfileDoc] = useState(initialValues.activityProfileDoc);

  // Assignment and refresh flow states
  const [nutritionalGoalsAction, setNutritionalGoalsAction] = useState(initialValues.nutritionalGoalsAction);
  const [mealPlanAction, setMealPlanAction] = useState(initialValues.mealPlanAction);
  const [nutritionalGoals, setNutritionalGoals] = useState(initialValues.nutritionalGoals);
  const [newMealPlanData, setNewMealPlanData] = useState(initialValues.newMealPlanData);
  const [selectedWeightGoal, setSelectedWeightGoal] = useState(initialValues.selectedWeightGoal);
  const [assignmentNote, setAssignmentNote] = useState(initialValues.assignmentNote);

  const {
    coachDoc: {
      mealPlanConfig: {
        refreshInterval: mealPlanRefreshInterval,
      } = {},
    } = {},
  } = useContext(ExternalCoachContext) || {};

  useEffect(() => {
    const init = async () => {
      const mealPlanAssignment = await MealPlanAssignment.getUserMealPlanAssignment(userId);
      if (mealPlanAssignment) {
        if (mealPlanRefreshInterval === RefreshInterval.NEVER) {
          mealPlanAssignment.status = MealPlanAssignmentStatus.MANUALLY_REFRESHABLE;
        } else {
          mealPlanAssignment.status = moment.utc()
            .isAfter(moment.utc(mealPlanAssignment.lastUpdated.toDate())
              .add(DaysForRefreshInterval[mealPlanRefreshInterval] || DEFAULT_MEAL_REFRESH_DAYS, 'days'))
            ? MealPlanAssignmentStatus.NEEDS_REFRESH
            : MealPlanAssignmentStatus.ACTIVE;
        }
      }

      // Get latest 2 checkin docs
      const weeklyCheckinCol = await WeeklyCheckin.getByUser(userId);
      const latestWeeklyCheckinDocs = weeklyCheckinCol.hasDocs ? weeklyCheckinCol.docs.slice(0, 2) : [];

      const [
        user,
        userNutritionProfile,
        userMacroGoals,
        userActivityProfile,
      ] = await Promise.all([
        User.getById(userId),
        UserNutritionProfile.getById(userId),
        UserNutritionMacroGoals.getById(userId),
        UserActivityProfile.getById(userId),
      ]);

      if (isComponentMountedRef.current) {
        setMealPlanAssignmentDoc(mealPlanAssignment);
        setUserDoc(user);
        setNutritionProfileDoc(userNutritionProfile);
        setMacroGoalsDoc(userMacroGoals);
        setActivityProfileDoc(userActivityProfile);
        setWeeklyCheckinDocs(latestWeeklyCheckinDocs.reverse());
        setIsReady(true);
      }
    };

    if (!isReady) {
      init();
    }
  }, [
    isReady,
    isComponentMountedRef,
    userId,
    mealPlanRefreshInterval,
  ]);

  // Some states initial values use some of the fetched documents, this useEffect handles that
  useEffect(() => {
    if (isReady && !isInitialized) {
      setSelectedWeightGoal(nutritionProfileDoc?.weeklyWeightGoal);
      setAssignmentNote(mealPlanAssignmentDoc?.customMealPlanIntroMessage || '');
      setIsInitialized(true);
    }
  }, [
    isReady,
    isInitialized,
    nutritionProfileDoc,
    mealPlanAssignmentDoc,
  ]);

  const contextValue = useMemo(() => ({
    isReady: isReady && isInitialized,
    mealPlanAssignmentDoc,
    userDoc,
    nutritionProfileDoc,
    macroGoalsDoc,
    weeklyCheckinDocs,
    activityProfileDoc,
    nutritionalGoalsAction,
    setNutritionalGoalsAction,
    mealPlanAction,
    setMealPlanAction,
    nutritionalGoals,
    setNutritionalGoals,
    newMealPlanData,
    setNewMealPlanData,
    selectedWeightGoal,
    setSelectedWeightGoal,
    assignmentNote,
    setAssignmentNote,
  }), [
    isReady,
    isInitialized,
    mealPlanAssignmentDoc,
    userDoc,
    nutritionProfileDoc,
    macroGoalsDoc,
    weeklyCheckinDocs,
    activityProfileDoc,
    nutritionalGoalsAction,
    setNutritionalGoalsAction,
    mealPlanAction,
    setMealPlanAction,
    nutritionalGoals,
    setNutritionalGoals,
    newMealPlanData,
    setNewMealPlanData,
    selectedWeightGoal,
    setSelectedWeightGoal,
    assignmentNote,
    setAssignmentNote,
  ]);

  return (
    <MealPlanAssignmentContext.Provider value={contextValue}>
      {children}
    </MealPlanAssignmentContext.Provider>
  );
};

MealPlanAssignmentContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default compose(
  observer,
)(MealPlanAssignmentContextProvider);
