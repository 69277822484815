const defaultMealTimes = [
  {
    caloricSplit: 30,
    meals: [],
    name: 'BREAKFAST',
  },
  {
    caloricSplit: 30,
    meals: [],
    name: 'LUNCH',
  },
  {
    caloricSplit: 30,
    meals: [],
    name: 'DINNER',
  },
  {
    caloricSplit: 10,
    meals: [],
    name: 'SNACK',
  },
];

const defaultLiveMealTimes = [
  {
    caloricSplit: 30,
    name: 'BREAKFAST',
    recipesAmount: 5,
  },
  {
    caloricSplit: 30,
    name: 'LUNCH',
    recipesAmount: 5,
  },
  {
    caloricSplit: 30,
    name: 'DINNER',
    recipesAmount: 5,
  },
  {
    caloricSplit: 10,
    name: 'SNACK',
    recipesAmount: 5,
  },
];

const initialMealPlanValue = {
  name: '',
  mealTimes: defaultMealTimes,
};
const initialLiveMealPlanValue = {
  name: '',
  mealTimes: defaultLiveMealTimes,
};

export { initialMealPlanValue, initialLiveMealPlanValue };
