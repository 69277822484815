import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import format from 'string-template';

import CoachRevenueAnalytics from '../../../../../Model/analytics/CoachRevenueAnalytics';
import { formatNumber } from '../../../../../../utils/formatters';
import { DateFormat } from '../../../../../../utils/date';
import BarChart from '../../../../../components/BarChart';
import ChartContainer from '../../ChartContainer';
import texts from './texts.json';

const RevenueChart = ({
  revenueAnalytics,
  currency,
}) => (
  <ChartContainer title={format(texts.revenue, { currency: currency.toUpperCase() })}>
    <BarChart
      data={revenueAnalytics.map((subAnalytic) => ({
        name: subAnalytic.id,
        Revenue: subAnalytic.revenues.find((rev) => rev.currency === currency)?.revenueAmt || 0,
      }))}
      keys={[{ name: 'Revenue' }]}
      toolTipFormatter={(value) => formatNumber(value, { minimumFractionDigits: 2 })}
      labelFormatter={(yearMonth) => moment(yearMonth, DateFormat.YEAR_MONTH_FORMAT)
        .format(DateFormat.YEAR_MONTH_SHORT_DISPLAY_FORMAT)}
    />
  </ChartContainer>
);
RevenueChart.propTypes = {
  revenueAnalytics: PropTypes.arrayOf(PropTypes.instanceOf(CoachRevenueAnalytics)).isRequired,
  currency: PropTypes.string.isRequired,
};

export default RevenueChart;
