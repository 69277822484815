import { Collection } from 'firestorter';

import { firestorePaths } from '../utils/paths';

import { workoutAssignmentStatuses } from './WorkoutAssignment';
import BaseDocument from './BaseDocument';

/**
 * Class representing a GameplaySession
 *
 * The GameplaySession model is used to represent a single gameplay session
 * for a given user's workout assignment. It contains information about the
 * user, workout assignment, start time, end time, and status of the gameplay
 * session.
 *
 * @class GameplaySession
 * @extends BaseDocument
 */
class GameplaySession extends BaseDocument {
  /**
   * Forcefully finishes a GameplaySession by setting the status as partially completed
   */
  forceFinish() {
    return this.updateFields({
      status: workoutAssignmentStatuses.PARTIALLY_COMPLETED,
      endTime: Date.now(),
    });
  }

  /**
   * Fetches the latest gameplay session for a given user's workout assignment
   *
   * @param {string} user - User id
   * @param {string} assignmentId - Workout assignment id
   * @returns {Promise<Collection>} - collection of gameplay sessions
   */
  static async getLatestGameplaySessionCollection(user, assignmentId) {
    const collection = new Collection(firestorePaths.GAMEPLAY_SESSION, {
      createDocument: (src, opts) => new GameplaySession(src, opts),
      query: (ref) => ref.where('user', '==', user)
        .where('workoutAssignment', '==', assignmentId)
        .orderBy('startTime', 'desc')
        .limit(1),
    });
    await collection.fetch();
    return collection;
  }
}

export default GameplaySession;
