import {
  TextField,
  Typography,
  Button,
  List,
} from '@mui/material';
import styled from 'styled-components';

const Text = styled(Typography)`
  margin-bottom: 5px;
`;

const Container = styled.div`
  padding: 16px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const EmptyFeed = styled.div`
  width: 100%;
  height: 100%;
`;

const Title = styled(Text)`
  font-weight: bold !important;
  font-size: 24px !important;
`;

const Description = styled(Text)`
  font-size: 16px !important;
  margin-bottom: 15px !important;
`;

const InputContainer = styled.div`
  width: 100%;
  display: flex;
  margin: 20px 0;
`;

const StyledHabitInput = styled(TextField)`
  width: 100%;
  min-width: 500px;
  box-sizing: initial;
`;

const StyledButton = styled(Button)`
  margin-left: 10px;
  width: 100%;
`;

const StyledList = styled(List)`
  height: auto;
  overflow-y: scroll;
`;

export {
  Container,
  EmptyFeed,
  Title,
  Description,
  InputContainer,
  StyledHabitInput,
  StyledButton,
  StyledList,
};
