import moment from 'moment';

import { DateFormat } from '../../utils/date';
import { formatAmoutInCents, formatCurrencyCents } from '../../utils/formatters';
import { DEFAULT_CURRENCY } from '../../utils/currency';

import { generateTerms } from './userContractTerms';

// This is the default version we have for new contracts.
const CONTRACT_VERSION = 2;

const ContractProperties = {
  START_DATE: 'startDate',
  INITIAL_PAYMENT: 'initialPayment',
  MONTHLY_PAYMENT: 'monthlyPayment',
  INITIAL_TERM: 'initialTerm',
  MIN_SUBSCRIPTION_MONTHS: 'minSubscriptionMonths',
  PRICE_ID: 'priceId',
  MONTHLY_PRICE_ID: 'monthlyPriceId',
  CURRENCY: 'currency',
  CANCEL_AT_PERIOD_END: 'cancelAtPeriodEnd',
  RECURRING_BILLING_MONTHS: 'recurringBillingMonths',
  PACKAGE_ID: 'packageId',
  PACKAGE_NAME: 'packageName',
  VERSION: 'version',
  TYPE: 'type',
  NAME: 'name',
  SELLER_ID: 'sellerId',
  BASE_FEE: 'baseFee',
  AC_FEE: 'assistantCoachFee',
  IS_FEE: 'insideSalesFee',
  UPFRONT_PAYMENT: 'upfrontPayment',
};

const FeeType = {
  PERCENTAGE: 'PERCENTAGE',
  FIXED: 'FIXED',
};

const ContractType = {
  BASE: 'BASE',
  FROM_BASE: 'FROM_BASE',
  USER: 'USER',
};

const FeeConfig = {
  INSIDE_SALES: {
    value: 15,
    type: FeeType.PERCENTAGE,
  },
  ASSISTANT_COACH: {
    value: 15,
    type: FeeType.PERCENTAGE,
  },
};

const ContractStatus = {
  PENDING: 'PENDING',
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  ON_BREAK: 'ON_BREAK',
  DELINQUENT: 'DELINQUENT',
  CANCELED: 'CANCELED',
  PAST_DUE: 'PAST_DUE',
  INCOMPLETE: 'INCOMPLETE',
  FUTURE_CANCELLATION: 'FUTURE_CANCELLATION',
  TRIALING: 'TRIALING',
  IN_PROGRESS: 'IN_PROGRESS',
  FAILED: 'FAILED',
  INCOMPLETE_EXPIRED: 'INCOMPLETE_EXPIRED',
};

const ContractMinimumValue = {
  MIN_SUBSCRIPTION_MONTHS: 1,
  INITIAL_TERM: 1,
  FEE_PERCENTAGE: 0,
};

const ContractMaxValue = {
  INITIAL_TERM: 12,
  FEE_PERCENTAGE: 100,
};

const DefaultContract = {
  [ContractProperties.START_DATE]: moment(),
  [ContractProperties.INITIAL_PAYMENT]: '',
  [ContractProperties.MONTHLY_PAYMENT]: '',
  [ContractProperties.PRICE_ID]: '',
  [ContractProperties.MONTHLY_PRICE_ID]: '',
  [ContractProperties.INITIAL_TERM]: ContractMinimumValue.INITIAL_TERM,
  [ContractProperties.MIN_SUBSCRIPTION_MONTHS]: '',
  [ContractProperties.CURRENCY]: DEFAULT_CURRENCY.code,
  [ContractProperties.CANCEL_AT_PERIOD_END]: false,
  [ContractProperties.RECURRING_BILLING_MONTHS]: 1,
  [ContractProperties.PACKAGE_ID]: '',
  [ContractProperties.PACKAGE_NAME]: '',
  [ContractProperties.VERSION]: CONTRACT_VERSION,
  [ContractProperties.NAME]: '',
  [ContractProperties.UPFRONT_PAYMENT]: 0,
};

const getContractObj = (contractData) => {
  const {
    startDate: stringDate,
    initialPaymentInCents,
    totalPriceInCents,
    initialTerm,
    minSubscriptionMonths,
    currency,
    priceId = '',
    monthlyPriceId = '',
    cancelAtPeriodEnd,
    recurringBillingMonths,
    coachConditions,
    packageId = '',
    packageName = '',
    upfrontPaymentInCents = '',
    version = CONTRACT_VERSION,
  } = contractData;

  return {
    startDate: moment(stringDate),
    initialPayment: formatAmoutInCents(initialPaymentInCents, { minimumFractionDigits: 2 }),
    monthlyPayment: formatAmoutInCents(totalPriceInCents, { minimumFractionDigits: 2 }),
    upfrontPayment: formatAmoutInCents(upfrontPaymentInCents, { minimumFractionDigits: 2 }),
    initialTerm,
    minSubscriptionMonths,
    currency,
    priceId,
    monthlyPriceId,
    cancelAtPeriodEnd,
    recurringBillingMonths,
    coachConditions,
    packageId,
    packageName,
    version,
  };
};

const getContractDocContent = ({
  lead,
  user,
  contract,
  product,
  coachDoc,
  sellerId,
}) => {
  // Get the coach data
  const {
    id: coach,
    name: coachName,
    termsAndConditionsText,
  } = coachDoc;

  // Get the lead data
  const {
    id: leadId,
    name = '',
    firstName: leadFirstName = '',
    lastName: leadLastName = '',
    email: leadEmail,
    country = '',
  } = lead || {};

  const {
    firstName: userDocFirstName,
    lastName: userDocLastName,
    email: userDocEmail,
    id: userId,
  } = user || {};

  const userFirstName = userDocFirstName || leadFirstName;
  const userLastName = userDocLastName || leadLastName;
  const userEmail = userDocEmail || leadEmail;

  const userNameString = userFirstName ? `${userFirstName} ${userLastName}` : name;
  const userName = userNameString.trim();

  // Get the product data
  const {
    id: productId,
    name: productName,
  } = product;

  // Get the contract-specific data
  const {
    startDate: startDateValue,
    initialPayment,
    monthlyPayment,
    initialTerm,
    minSubscriptionMonths,
    currency,
    priceId = '',
    monthlyPriceId = '',
    cancelAtPeriodEnd,
    recurringBillingMonths,
    packageId = '',
    packageName = '',
    version,
    baseFee,
    assistantCoachFee,
    insideSalesFee,
    name: contractName,
    sellerId: contractSellerId,
    upfrontPayment = 0,
  } = contract;

  // Get the contract terms so that we can save them in the contract document.
  const terms = generateTerms(contract);

  // Build the price object
  const price = {
    priceId,
    monthlyPriceId,
    totalPriceInCents: parseFloat(monthlyPayment) * 100,
    currency,
    minSubscriptionMonths,
    initialTerm,
    initialPaymentInCents: parseFloat(initialPayment) * 100,
    cancelAtPeriodEnd,
    recurringBillingMonths,
    upfrontPaymentInCents: parseFloat(upfrontPayment) * 100,
  };

  // Build the fees object
  const fees = {
    baseFee: {
      value: baseFee,
      type: FeeType.PERCENTAGE,
    },
    ...(insideSalesFee ? {
      insideSalesFee: {
        value: insideSalesFee,
        type: FeeType.PERCENTAGE,
      },
    } : {}),
    ...(assistantCoachFee ? {
      assistantCoachFee: {
        value: assistantCoachFee,
        type: FeeType.PERCENTAGE,
      },
    } : {}),
  };

  const startDate = startDateValue.clone().format(DateFormat.DEFAULT_DATE_FORMAT);

  // Use sellerId specified in the contract object, use the one passed as param otherwise.
  const sellerIdValue = contractSellerId || sellerId;

  const docContent = {
    coach,
    coachName,
    sellerId: sellerIdValue,
    leadId: leadId || '',
    userFirstName,
    userLastName,
    userName,
    userEmail: userEmail || '',
    userId: userId || '',
    productId,
    price,
    fees,
    startDate,
    status: ContractStatus.PENDING,
    coachConditions: termsAndConditionsText,
    country,
    packageId,
    packageName,
    version,
    terms,
    name: contractName,
    productName,
  };

  return docContent;
};

const getBaseContractDocContent = (contract, product, coachDoc, sellerDoc) => {
  let contractDocContent = getContractDocContent({}, contract, product, coachDoc, sellerDoc.id);

  // Add the specific properties for base contracts.
  contractDocContent = {
    ...contractDocContent,
    type: ContractType.BASE,
    status: ContractStatus.ACTIVE,
  };

  return contractDocContent;
};

const getRemainingPaymentCount = (contract, user, subscription) => {
  const minSubscriptionMonths = contract?.minSubscriptionMonths
    || ContractMinimumValue.MIN_SUBSCRIPTION_MONTHS;
  const initialTerm = contract?.initialTerm || ContractMinimumValue.INITIAL_TERM;
  const commitmentEndDate = user.serviceStartAt
    ? moment(user.serviceStartAt).add(minSubscriptionMonths, 'months')
    : moment.unix(subscription.created).add(minSubscriptionMonths, 'months');
  const remainingMonths = commitmentEndDate.diff(moment(), 'months');

  if (remainingMonths > 0) {
    // check if the user is still in initial term, if so return the remaining months excluding the initial term
    if (remainingMonths > minSubscriptionMonths - initialTerm) {
      return minSubscriptionMonths - initialTerm;
    }
    return remainingMonths;
  }
  return 0;
};

/**
 * Get the average monthly payment for the initial term of a contract.
 * @param {object} paymentData
 * @param {number} paymentData.initialPayment - Initial payment (in units of currency, not cents. For example: 75).
 * @param {number} paymentData.initialTerm - Initial term in months.
 * @returns {number} Average monthly payment in cents.
 */
const getAverageMonthlyPayment = (paymentData = {}) => {
  const {
    initialPayment,
    initialTerm = 1,
  } = paymentData;

  // Ensure we have the correct types before doing the calculations
  const initialPaymentValue = parseFloat(initialPayment || 0) * 100;
  const initialTermValue = parseInt(initialTerm, 10);

  return initialPaymentValue / initialTermValue;
};

/**
 * Get a formatted average monthly payment for the initial term of a contract.
 * @param {object} paymentData
 * @param {number} paymentData.initialPayment - Initial payment (in units of currency, not cents. For example, 75).
 * @param {number} paymentData.initialTerm - Initial term in months.
 * @param {string} paymentData.currency - Currency code.
 * @returns {string} Formatted average monthly payment.
 */
const getFormattedAverageMonthlyPayment = (paymentData = {}) => {
  const averageMonthlyPayment = getAverageMonthlyPayment(paymentData);
  return formatCurrencyCents(averageMonthlyPayment, paymentData.currency, { minimumFractionDigits: 2 });
};

const getUserSubscriptionPrice = (contract, plan) => {
  if (contract) {
    return formatCurrencyCents(contract.totalPriceInCents, contract.currency);
  }
  if (plan) {
    return formatCurrencyCents(plan.totalPriceInCents, plan.currency);
  }
  return '';
};

export {
  ContractProperties,
  ContractMinimumValue,
  ContractMaxValue,
  ContractStatus,
  ContractType,
  FeeType,
  FeeConfig,
  DefaultContract,
  getContractObj,
  getContractDocContent,
  getBaseContractDocContent,
  getRemainingPaymentCount,
  getAverageMonthlyPayment,
  getFormattedAverageMonthlyPayment,
  getUserSubscriptionPrice,
};
