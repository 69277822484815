import pages from '../../config/pages';
import Forms from '../../pages/Forms';
import FormBuilder from '../../pages/FormInfo';

const routes = [
  {
    pageId: pages.FORMS,
    path: ':formId',
    component: FormBuilder,
  },
  {
    pageId: pages.FORMS,
    path: '',
    component: Forms,
  },
];

export default routes;
