import React, {
  useContext,
  useMemo,
} from 'react';
import moment from 'moment';
import { PropTypes } from 'prop-types';
import copy from 'clipboard-copy';
import { CheckCircleOutline, RestoreFromTrash } from '@mui/icons-material';

import useSessionStore from '../../../../../hooks/useSessionStore';
import useUserDoc from '../../../../hooks/useUserDoc';
import feedType, { feedOrderingActivityTypes } from '../../../../context/FeedContext/config/feedTypes';
import FeedContext from '../../../../context/FeedContext';
import useFeed from '../../../../hooks/useFeed';

import ClaimButton from './ClaimActivity';
import {
  HeaderContainer,
  ActionButtonsContainer,
  RestoreButton,
  MarkAsReadButton,
  StyledUserProfileCard,
} from './styles';
import texts from './texts.json';

const ActivityHeader = ({
  activity,
}) => {
  const { onMarkAsRead, onRestore } = useContext(FeedContext);
  const { readOnlyMode } = useFeed();
  const { isCoachAssistant } = useSessionStore();

  const {
    time,
    type,
    user: userId,
    userName,
    userAvatar,
    createdAt,
  } = activity;
  const {
    userDoc: clientDoc,
  } = useUserDoc(userId);

  const activityTime = useMemo(() => (
    moment.utc(time?.toDate()).local().fromNow()
  ), [
    time,
  ]);

  const initialTimeLabel = useMemo(() => {
    if (!moment(time?.toDate()).isSame(moment(createdAt?.toDate()))) {
      return `(${texts.initialTimeLabel} ${moment(createdAt?.toDate()).local().fromNow()})`;
    }
    return '';
  }, [
    time,
    createdAt,
  ]);

  const activityTimeText = `${activityTime} ${initialTimeLabel}`;

  return (
    <HeaderContainer onClick={() => copy(activity.id)}>
      <StyledUserProfileCard
        clientId={userId}
        dataRow={{
          userName,
          userAvatar,
          serviceStartAt: clientDoc?.serviceStartAt,
        }}
        subTitle={activityTimeText}
        className="user-name"
      />
      <ActionButtonsContainer>
        {isCoachAssistant && feedOrderingActivityTypes.includes(type) && (
          <ClaimButton activity={activity} />
        )}
        {type === feedType.ARCHIVED ? (
          <RestoreButton
            startIcon={<RestoreFromTrash />}
            disabled={readOnlyMode}
            onClick={() => onRestore(activity)}
          >
            {texts.RESTORE}
          </RestoreButton>
        ) : (
          <MarkAsReadButton
            startIcon={<CheckCircleOutline />}
            disabled={readOnlyMode}
            onClick={() => onMarkAsRead(activity)}
          >
            {texts.MARK_AS_READ}
          </MarkAsReadButton>
        )}
      </ActionButtonsContainer>
    </HeaderContainer>
  );
};

ActivityHeader.propTypes = {
  activity: PropTypes.object.isRequired,
};

export default ActivityHeader;
