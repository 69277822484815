import texts from './texts';

const getColumns = ({
  dragHandleRenderCell,
  exerciseNameRenderCell,
  tagsRenderCell,
  equipmentsRenderCell,
  actionsRenderCell,
  isWorkoutDesignerView,
  isExerciseChangeView,
}) => {
  const columns = [];

  if (isWorkoutDesignerView) {
    columns.push(
      {
        field: 'dragHandle',
        headerName: '',
        width: 50,
        sortable: false,
        filterable: false,
        renderCell: dragHandleRenderCell,
      },
    );
  }

  columns.push(
    {
      field: 'name',
      type: 'string',
      headerName: texts.columns.name,
      flex: 6,
      minWidth: 170,
      quickSearch: true,
      renderCell: exerciseNameRenderCell,
    },
    {
      field: 'type',
      type: 'string',
      headerName: texts.columns.type,
      flex: 4,
      width: 100,
    },
  );

  if (!isWorkoutDesignerView && !isExerciseChangeView) {
    columns.push(
      {
        field: 'description',
        type: 'string',
        headerName: texts.columns.description,
        flex: 16,
        valueGetter: ({ row }) => row.description || texts.emptyText,
      },
      {
        field: 'tags',
        type: 'string',
        headerName: texts.columns.tags,
        width: 220,
        renderCell: tagsRenderCell,
      },
    );
  }

  if (isWorkoutDesignerView || isExerciseChangeView) {
    columns.push({
      field: 'equipments',
      type: 'string',
      headerName: texts.columns.equipments,
      width: 140,
      renderCell: equipmentsRenderCell,
    });
  }

  columns.push({
    field: 'actions',
    headerName: (!isWorkoutDesignerView && !isExerciseChangeView) ? texts.columns.actions : '',
    sortable: false,
    filterable: false,
    renderCell: actionsRenderCell,
    width: isWorkoutDesignerView ? 100 : 210,
    ...(isWorkoutDesignerView && { align: 'right', headerAlign: 'right' }),
  });

  return columns;
};

export default getColumns;
