import styled from 'styled-components';
import colors from '../../../../../../styles/colors';

import { ReactComponent as Icon } from '../../../assets/icon/magnifying-glass.svg';

const SearchInputContainer = styled.div`
  width: 100%;
  background-color: ${colors.base.secondary2};
  position: relative;

  .str-chat__channel-search-input {
    border: none;
    border-radius: 5px;
    font-size: 14px;
    width: 100%;
    outline: none;
    padding: 5px 20px 5px 30px;
    height: 38px;
    background-color: ${colors.base.alpha};
  }
`;

const SearchIcon = styled(Icon)`
  position: absolute;
  height: 40%;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);

  path {
    stroke:${colors.base.secondary2};
  }
`;

export {
  SearchInputContainer,
  SearchIcon,
};
