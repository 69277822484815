import styled, { css } from 'styled-components';
import colors from '../../../../../styles/colors';
import { ReactComponent as SendIcon } from '../../assets/icon/send-icon.svg';
import { ReactComponent as AttachmentIcon } from '../../assets/icon/attachment.svg';
import { ReactComponent as MicIcon } from '../../assets/icon/mic-icon.svg';
import { ReactComponent as StopIcon } from '../../assets/icon/stop-icon.svg';
import { ReactComponent as ArrowIcon } from '../../../../../assets/icons/chevron-down.svg';
import { ReactComponent as S2Logo } from '../../../../../assets/s2-logo.svg';
import { LoadingButton } from '../../../../../components/Button';
import { ReactComponent as closeIcon } from '../../../../../assets/icons/v2/cross-cancel.svg';

const MessagingInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.base.alpha};
`;

const StyledSendIcon = styled(SendIcon)`
  height: 30px;
  width: 30px;
`;

const StyledAttachmentIcon = styled(AttachmentIcon)`
  display: flex;
  height: 22px;
  width: 20px;
  > path {
    fill: ${colors.base.alpha};
  }
`;

const StyledMicIcon = styled(MicIcon)`
  display: flex;
  height: 20px;
  width: 20px;
  color: ${colors.base.secondary2};
  margin-left: 2px;
`;

const StyledStopIcon = styled(StopIcon)`
  display: flex;
  height: 20px;
  width: 20px;
  color: ${colors.shades.danger1};
  margin-left: 2px;
`;

const TriggerSuggestion = styled(LoadingButton)`
  font-size: 1em;
  margin: 0.5em;
  width: calc(100% - 1em);
  align-content: center;
  padding: 0.5em 1em;
  color: ${colors.base.secondary2};
  background-color: ${colors.base.alpha};
  border: 2px solid ${colors.base.secondary2};
  border-radius: 10px;
`;

const RecordingView = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 40px;
  padding-right: 12px;
  font-size: 14px;
  color: ${colors.base.secondary2};
`;

const RecordingLabel = styled.div`
  display: flex;
  margin-left: 10px;
  flex-direction: row;
  align-items: center;
  flex: 1;
`;

const SmartResponseContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.shades.secondary6};
  overflow-y: auto;
  ${({ $isLoading }) => $isLoading && css`
    align-self: center;
    height: 100%;
    justify-content: center;
    padding: 30px 0;
  `}
`;

const SmartResponse = styled.div`
  display: flex;
  margin: 5px 15px;
  border: 2px solid ${colors.base.alpha};
  background-color: ${colors.base.alpha};
  padding: 4px 10px;
  border-radius: 10px;
  font-size: 14px;
  flex: 1;
  cursor: pointer;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.shades.secondary6};
  max-height: 300px;
`;

const Title = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${colors.shades.secondary6};
  padding: 6px 15px;
  font-weight: 500;
  font-size: 14px;
  align-items: center;
  color: ${colors.base.secondary2};
  .MuiSvgIcon-root {
    color: ${colors.base.secondary2};
  }
`;

const StyledArrowIcon = styled(ArrowIcon)`
  width: 20px;
  height: 20px;
  > path {
    stroke: ${colors.base.secondary2};
  }
  transform: ${({ $isOpen }) => ($isOpen ? 'rotate(0deg)' : 'rotate(180deg)')};
  transition: 0.5s;
  cursor: pointer;
  margin: 8px 8px 8px auto;
`;

const StyledS2Icon = styled(S2Logo)`
  width: 12px;
  height: 12px;
  margin-right: 10px;
`;

const ReplyMessageContainer = styled.div`
  padding: 5px 10px 10px 10px;
  width: 100%;
  background-color: ${colors.shades.primary1};
`;

const ReplyMessageContent = styled.div`
  display: flex;
  flex-direction: row;
`;

const ReplyMessage = styled.span`
  font-weight: 500;
  font-size: 14px;
  max-width: 95%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  align-items: center;
`;

const StyledCloseIcon = styled(closeIcon)`
  width: 24px;
  height: 24px;
  > path {
    stroke: ${colors.base.secondary2};
  }
  cursor: pointer;
  margin: -12px 10px 0px auto;
`;

const ReplyDiscription = styled.span`
  color: ${colors.base.alpha};
  font-size: 12px;
  font-weight: 500;
`;

export {
  MessagingInputContainer,
  StyledSendIcon,
  StyledAttachmentIcon,
  StyledMicIcon,
  StyledStopIcon,
  TriggerSuggestion,
  RecordingView,
  RecordingLabel,
  SmartResponseContainer,
  SmartResponse,
  Container,
  Title,
  StyledArrowIcon,
  StyledS2Icon,
  ReplyMessageContainer,
  ReplyMessageContent,
  ReplyMessage,
  StyledCloseIcon,
  ReplyDiscription,
};
