import useSetterWithReducer from '../../service/hook/useSetterWithReducer';
import { getQueryVariable } from '../../utils/queryParams';

const initialState = {
  userId: '',
  firstName: '',
  lastName: '',
  email: getQueryVariable('email') || '',
  userHasPassword: false,
  phoneNumber: '',
  submittedForm: '',
  recommendedCoachId: '',
  gender: '',
  setFlowCurrentPage: () => {},
  shouldInitializeUser: false,
  startAtDate: null,
};

const useOnboardingFlowStateReducer = () => useSetterWithReducer(initialState);

export default useOnboardingFlowStateReducer;
export {
  initialState,
};
