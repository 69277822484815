import styled from 'styled-components';

import { ReactComponent as MealPlateIcon } from '../../../../../../assets/icons/v2/modal-meal-plate.svg';

const ModalContent = styled.div`
  position: relative;
  padding: 44px 50px;
  overflow: auto;
  height: calc(100vh - 400px);
`;

const StyledMealPlateIcon = styled(MealPlateIcon)`
  width: 27px;
  height: 29px;
  margin-bottom: 9px;
`;

export {
  ModalContent,
  StyledMealPlateIcon,
};
