import React, {
  useCallback,
  useState,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';

import GenericErrorMessage from '../../../../../components/GenericErrorMessage';

import { ReactComponent as CreditCardIcon } from '../../../../../assets/icons/creditCardCheckIcon.svg';

import {
  Container,
  Header,
  CheckoutContent,
  ErrorMessage,
  StripeCardNumberElement,
  StripeCardExpiryElement,
  StripeCardCvcElement,
  InputFieldGroup,
  StyledSubmitButton,
} from './styles';

import texts from './texts.json';

const PaymentForm = ({
  isLoading,
  errorMessage,
  onSubmit,
}) => {
  // Stripe field errors
  const [fieldErrors, setFieldErrors] = useState({
    cardNumber: '',
    cardExpiry: '',
    cardCvc: '',
  });

  const handleStripeFieldChange = useCallback((event) => {
    const { error, elementType } = event;
    if (error) {
      setFieldErrors((prevErrors) => ({ ...prevErrors, [elementType]: error.message }));
    } else {
      setFieldErrors((prevErrors) => ({ ...prevErrors, [elementType]: '' }));
    }
  }, []);

  const areFieldErrors = useMemo(() => (
    Object.values(fieldErrors).some((field) => !!field)
  ), [fieldErrors]);

  return (
    <Container>
      <Header>{texts.header}</Header>
      <CheckoutContent>
        <InputFieldGroup>
          <StripeCardNumberElement
            medium
            options={{
              showIcon: true,
            }}
            onChange={handleStripeFieldChange}
          />
          <StripeCardExpiryElement small onChange={handleStripeFieldChange} />
          <StripeCardCvcElement small onChange={handleStripeFieldChange} />
        </InputFieldGroup>
        {fieldErrors.cardNumber && <ErrorMessage>{fieldErrors.cardNumber}</ErrorMessage>}
        {fieldErrors.cardExpiry && <ErrorMessage>{fieldErrors.cardExpiry}</ErrorMessage>}
        {fieldErrors.cardCvc && <ErrorMessage>{fieldErrors.cardCvc}</ErrorMessage>}
        <StyledSubmitButton
          startIcon={<CreditCardIcon />}
          isLoading={isLoading}
          disabled={areFieldErrors}
          onClick={onSubmit}
        >
          {texts.pay}
        </StyledSubmitButton>
        {
          errorMessage && <GenericErrorMessage>{errorMessage}</GenericErrorMessage>
        }
      </CheckoutContent>
    </Container>
  );
};

PaymentForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
};

PaymentForm.defaultProps = {
  errorMessage: null,
};

export default PaymentForm;
