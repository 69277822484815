import { Typography } from '@mui/material';
import styled from 'styled-components';

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 40px
`;

const InfoLabel = styled(Typography)`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  color: ${({ $color }) => $color};
`;

const InfoContent = styled.div`
  width: 100%;
  font-size: 14px;
  color: ${({ $color }) => $color};
`;

export {
  InfoWrapper,
  InfoLabel,
  InfoContent,
};
