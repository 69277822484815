const addItem = (arr, item, index) => {
  arr.splice(index, 0, item);
  return arr;
};

const removeItem = (arr, index) => {
  arr.splice(index, 1);
  return arr;
};

const moveItem = (arr, fromIndex, toIndex) => {
  arr.splice(toIndex, 0, arr.splice(fromIndex, 1)[0]);
  return arr;
};

const replaceItem = (arr, index, items) => {
  arr.splice(index, 1);
  arr.splice(index, 0, ...items);
  return arr;
};

export {
  addItem,
  removeItem,
  moveItem,
  replaceItem,
};
