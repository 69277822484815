import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  TextField,
} from '@mui/material';
import {
  Reorder,
  Delete,
  VisibilityOffOutlined,
  VisibilityOutlined,
  Edit,
  Done,
} from '@mui/icons-material';

import {
  StyledDraggable,
  StyledListItemText,
} from './styles';

const HabitItem = ({
  index,
  habitText,
  handleDelete,
  isDefault,
  handleEdit,
  handleHide,
  isHidden,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedText, setEditedText] = useState(habitText);

  const handleEditClick = useCallback(() => {
    if (isEditing) {
      setIsEditing(false);
      handleEdit(index, editedText);
    } else {
      setIsEditing(true);
    }
  }, [
    isEditing,
    index,
    editedText,
    handleEdit,
  ]);

  return (
    <StyledDraggable>
      <ListItem disabled={isHidden}>
        <ListItemIcon className="drag-handle">
          <Reorder />
        </ListItemIcon>
        <StyledListItemText
          $isHidden={isHidden}
          $isEditing={isEditing}
          primary={isEditing ? (
            <TextField
              onChange={(e) => setEditedText(e.target.value)}
              value={editedText}
            />
          ) : habitText}
        />
        <ListItemSecondaryAction>
          <ListItemIcon onClick={() => handleEditClick(index)}>
            {isEditing ? <Done /> : <Edit />}
          </ListItemIcon>
          <ListItemIcon onClick={() => handleHide(index)}>
            {isHidden ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
          </ListItemIcon>
          {!isDefault && (
            <ListItemIcon onClick={() => handleDelete(index)}>
              <Delete />
            </ListItemIcon>
          )}
        </ListItemSecondaryAction>
      </ListItem>
    </StyledDraggable>
  );
};

HabitItem.propTypes = {
  index: PropTypes.number.isRequired,
  habitText: PropTypes.string.isRequired,
  handleDelete: PropTypes.func.isRequired,
  isDefault: PropTypes.bool.isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleHide: PropTypes.func.isRequired,
  isHidden: PropTypes.bool.isRequired,
};

export default HabitItem;
