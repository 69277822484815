import styled from 'styled-components';
import { Typography } from '@mui/material';

import colors from '../../../styles/colors';
import Tabs from '../../components/Tabs';
import ColoredHeader from '../../components/ColoredHeader';

const Title = styled(Typography)`
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 30px;
`;

const NoCoachText = styled(Typography)`
  margin: 20px;
`;

const StyledHeader = styled(ColoredHeader)`
  &:after {
    height: 42px;
  }
`;

const StyledTabs = styled(Tabs)`
  flex: 1;
  border-color: ${colors.base.primary};

  & .Mui-selected {
    background-color: ${colors.base.primary};
  }
`;

export {
  Title,
  NoCoachText,
  StyledHeader,
  StyledTabs,
};
