import styled from 'styled-components';

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const FilterRow = styled.div`
  display: flex;
  div:not(:last-child) {
    margin-right: 30px;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  position: relative;
  &:after {
    content: "";
    width: 100%;
    height: 50px;
    position: absolute;
    top: 100%;
    left: 0;
  }
`;

const PageContent = styled.div`
  flex: 1;
  margin: 0 10px 30px;
  z-index: 1;
`;

export {
  Container,
  FilterRow,
  Header,
  PageContent,
};
