import styled from 'styled-components';
import { LinkButton } from '../Button';
import BackArrowIcon from '../Icons/BackArrowIcon';

const StyledBackArrowIcon = styled(BackArrowIcon)``;

const BackButtonContainer = styled(LinkButton)`
  width: 32px;
  height: 32px;
  border: none;
  outline: none;
  padding: 0;
  display: flex;
  align-items: center;

  ${StyledBackArrowIcon} {
    width: 25px;
    height: 18px;
  }
`;

export {
  BackButtonContainer,
  StyledBackArrowIcon,
};
