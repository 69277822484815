import React, {
  useState,
  useCallback,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { compose } from 'recompose';
import { observer } from 'mobx-react';

import { DateFormat } from '../../../../../../utils/date';
import DialogModal from '../../../../../../components/DialogRoundedModal';
import { ReactComponent as WarningIcon } from '../../../../../../assets/icons/v2/alert-triangle.svg';
import useToast from '../../../../../hooks/useToast';
import {
  SaveButton,
  CancelButton,
} from '../../../../../../components/Button/ActionButtons';
import {
  voidStripeInvoice,
} from '../../../../../../pages/Onboarding/Subscription/stripe/SubscriptionRemoteRequestHandler';
import FirebaseContext from '../../../../../../context/FirebaseContext';

import texts from './texts.json';
import {
  StyledDialogContent,
  StyledTextField,
  Label,
  LabelTitle,
  LabelDescription,
} from './styles';

const VoidInvoiceModal = ({
  isOpen,
  invoice,
  onClose,
  onInvoiceVoided,
  stripeAccountId,
}) => {
  const [reason, setReason] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { firebase: { remote } } = useContext(FirebaseContext);
  const { showToast } = useToast();

  const handleConfirm = useCallback(async () => {
    if (!reason) {
      showToast(texts.emptyReason, { error: true });
    } else {
      setIsLoading(true);
      try {
        await voidStripeInvoice({
          invoiceId: invoice.id,
          reason,
          stripeAccountId,
          remote,
        });
        showToast(texts.successMessage, { success: true });
        onInvoiceVoided();
      } catch (error) {
        showToast(error.message || error, { error: true });
      } finally {
        setIsLoading(false);
      }
    }
  }, [
    reason,
    showToast,
    remote,
    invoice,
    stripeAccountId,
    onInvoiceVoided,
  ]);

  const periodStart = moment.unix(invoice.period_start).format(DateFormat.DATE_FORMAT_COMMA_TH);
  const periodEnd = moment.unix(invoice.period_end).format(DateFormat.DATE_FORMAT_COMMA_TH);

  return (
    <DialogModal
      open={isOpen}
      title={texts.title}
      description={texts.description}
      IconComponent={<WarningIcon />}
      onClose={onClose}
      actionButtons={(
        <>
          <SaveButton
            onClick={handleConfirm}
            icon={null}
            disabled={isLoading}
          >
            {texts.confirm}
          </SaveButton>
          <CancelButton
            onClick={onClose}
            color="warning"
            icon={null}
            disabled={isLoading}
          >
            {texts.cancel}
          </CancelButton>
        </>
      )}
    >
      <StyledDialogContent>
        <Label>
          <LabelTitle>{texts.labels.invoiceTo}</LabelTitle>
          <LabelDescription>
            {invoice.customer_name}
          </LabelDescription>
        </Label>
        <Label>
          <LabelTitle>{texts.labels.invoiceNumber}</LabelTitle>
          <LabelDescription>
            {invoice.number}
          </LabelDescription>
        </Label>
        <Label>
          <LabelTitle>{texts.labels.paymentDate}</LabelTitle>
          <LabelDescription>
            {moment.unix(invoice.next_payment_attempt).format(DateFormat.DATE_FORMAT_COMMA_TH)}
          </LabelDescription>
        </Label>
        <Label>
          <LabelTitle>{texts.labels.period}</LabelTitle>
          <LabelDescription>
            {`${periodStart} - ${periodEnd}`}
          </LabelDescription>
        </Label>
        <StyledTextField
          multiline
          rows={3}
          fullWidth
          variant="outlined"
          placeholder={texts.voidReasonPlaceholder}
          onChange={(e) => setReason(e.target.value)}
          disabled={isLoading}
        />
      </StyledDialogContent>
    </DialogModal>
  );
};

VoidInvoiceModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  invoice: PropTypes.object.isRequired,
  stripeAccountId: PropTypes.string.isRequired,
  onInvoiceVoided: PropTypes.func.isRequired,
};

export default compose(
  observer,
)(VoidInvoiceModal);
