import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import format from 'string-template';

import { combineDateAndTime } from '../../../../../utils/date';
import Lead, { LeadHistoryType } from '../../../../Model/Lead';
import DateTimeModal from '../DateTimeModal';
import texts from './texts.json';

const CallLogModal = ({
  lead,
  onClose,
  showModal,
  userId,
  userName,
  logRescheduledCall,
}) => {
  const onSave = useCallback(async ({
    date,
    time,
    duration,
  }) => {
    const callDateTime = combineDateAndTime(date, time).toDate();
    await lead.addItemToHistory({
      // we are seting created at time to the call time so it will show correctly in history
      // saving real created at time in value object in case we need it
      createdAt: !logRescheduledCall ? callDateTime : new Date(),
      value: {
        callTime: callDateTime,
        createdAt: new Date(),
        callDuration: !logRescheduledCall ? duration : null,
      },
      activityType: logRescheduledCall ? LeadHistoryType.RESCHEDULED_MANUALLY : LeadHistoryType.CALL,
      createdBy: userId,
      createdByUserName: userName,
    });
  }, [
    lead,
    userId,
    userName,
    logRescheduledCall,
  ]);

  const titleTemplate = logRescheduledCall ? texts.rescheduledTitle : texts.callLogTitle;

  return (
    <DateTimeModal
      title={format(titleTemplate, { name: lead.name })}
      onSave={onSave}
      onClose={onClose}
      open={showModal}
      enableDuration={!logRescheduledCall}
      showOnlyPastDates={!logRescheduledCall}
    />
  );
};

CallLogModal.propTypes = {
  lead: PropTypes.instanceOf(Lead).isRequired,
  onClose: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  userId: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  logRescheduledCall: PropTypes.bool,
};

CallLogModal.defaultProps = {
  logRescheduledCall: false,
};

export default CallLogModal;
