import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import UserContract from '../../../../../Model/UserContract';
import ContractModalContext from '../../context/ContractModalContext';
import ContractEditor from '../../ContractEditor';
import ContractModalFooter from '../../ContractModalFooter';

import ReusableContractModalHeader from './ReusableContractModalHeader';
import { Container } from './styles';

const ReusableContractModalContent = ({
  selectedContract,
}) => {
  const {
    isReadOnly,
    loadReusableContractFromDoc,
    setCoachId,
  } = useContext(ContractModalContext);

  useEffect(() => {
    if (selectedContract?.coach) {
      setCoachId(selectedContract.coach);
    }
  }, [
    selectedContract,
    setCoachId,
  ]);

  useEffect(() => {
    if (selectedContract?.id) {
      loadReusableContractFromDoc(selectedContract);
    }
  }, [
    selectedContract,
    loadReusableContractFromDoc,
  ]);

  return (
    <Container>
      <ReusableContractModalHeader />
      <ContractEditor showFeesConfigurator />
      {!isReadOnly && (
        <ContractModalFooter />
      )}
    </Container>
  );
};

ReusableContractModalContent.propTypes = {
  selectedContract: PropTypes.instanceOf(UserContract),
};
ReusableContractModalContent.defaultProps = {
  selectedContract: null,
};

export default ReusableContractModalContent;
