import React from 'react';
import PropTypes from 'prop-types';

import Arrow, { ArrowTypes } from './components/Arrow';

import { StyledSlider } from './styles';

const DEFAULT_SLIDER_SETTINGS = {
  infinite: true,
  dots: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <Arrow type={ArrowTypes.RIGHT} />,
  prevArrow: <Arrow type={ArrowTypes.LEFT} />,
};

const Slider = React.forwardRef(({
  children,
  className,
  settings,
}, ref) => {
  const sliderSettings = {
    ...DEFAULT_SLIDER_SETTINGS,
    ...settings,
  };

  return (
    <StyledSlider
      {...sliderSettings}
      ref={ref}
      className={className}
    >
      {children}
    </StyledSlider>
  );
});

Slider.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  settings: PropTypes.object,
};

Slider.defaultProps = {
  className: '',
  settings: DEFAULT_SLIDER_SETTINGS,
};

export default Slider;
