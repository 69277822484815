import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useRouteMatch } from 'react-router-dom';
import { compose } from 'recompose';
import { observer } from 'mobx-react';

import useSessionStore from '../../../hooks/useSessionStore';
import StripeCustomerDocument from '../../../Model/StripeCustomerDocument';
import CollectionName from '../../../utils/collections';
import { openFirestoreDocument } from '../../../utils/support';
import { openStripeCustomer } from '../../utils/stripe';
import useUserDoc from '../../hooks/useUserDoc';
import useComponentMounted from '../../../hooks/useComponentMounted';
import Lead from '../../Model/Lead';
import PostPaymentFormAnswer from '../../Model/PostPaymentFormAnswer';
import useToolsNavigation from '../../hooks/useToolsNavigation';
import useToast from '../../hooks/useToast';
import LoadingPage from '../../../components/LoadingPage';
import BackButton from '../../components/BackButton';
import ExternalCoachContext from '../../context/ExternalCoachContext';
import ManageClientContext from '../../context/ManageClientContext';
import MealPlanContext, { withMealPlanContextReady } from '../../context/MealPlanContext';
import config from '../../../config';
import UserInfoSection from './components/UserInfoSection';
import FormResponseContainer from './components/FormResponseContainer';

import {
  ActionButtonContainer,
  TabHeaderRow,
  StyledOnCallActions,
  HeaderContainer,
  UserInfoContainer,
  StyledTabs,
  StyledButton,
} from './styles';
import texts from './texts.json';
import { clientInfoTabsConfig } from './utils';
import MobileViewModal from './components/MobileView';

const ClientInfoView = () => {
  const { isOnCallUser } = useSessionStore();
  const { showToast } = useToast();
  const {
    params: {
      clientId,
      userId: coachId,
    },
  } = useRouteMatch();
  const { navigateBack, navigateToClientMealPlanPage } = useToolsNavigation();
  const {
    externalCoachDoc: {
      stripeAccountId,
    },
  } = useContext(ExternalCoachContext);
  const { selectedTab, setSelectedTab } = useContext(ManageClientContext);
  const { mealPlanAssignments } = useContext(MealPlanContext);

  // We need the user doc of this specific client
  // TODO: we need to define a new coach context to give coach information
  // without doing that on the user context
  const {
    isReady: isClientDocReady,
    userDoc: clientDoc,
  } = useUserDoc(clientId);

  const [postPaymentFormDoc, setPostPaymentFormDoc] = useState();
  const [leadDoc, setLeadDoc] = useState();
  const [isReady, setIsReady] = useState(false);
  const [showMobileViewModal, setShowMobileViewModal] = useState(false);
  const isComponentMountedRef = useComponentMounted();

  const isMealPlanAvailable = useMemo(() => (
    !!mealPlanAssignments?.find((assignment) => assignment.id === clientId)
  ), [
    clientId,
    mealPlanAssignments,
  ]);
  const mobileUrl = useMemo(() => `${config.mobileAppURL}/u/${clientId}/home?coachId=${coachId}`,
    [clientId, coachId]);

  useEffect(() => {
    const init = async () => {
      const {
        id: userId,
        email,
      } = clientDoc;
      const userLeadDoc = await Lead.getLeadByEmail(email);
      const userPostPaymentFormDoc = await PostPaymentFormAnswer.getFormDataByUser(userId);

      if (isComponentMountedRef.current) {
        if (userLeadDoc) {
          setLeadDoc(userLeadDoc);
        }
        if (userPostPaymentFormDoc) {
          setPostPaymentFormDoc(userPostPaymentFormDoc);
        }
        setIsReady(true);
      }
    };
    if (isClientDocReady && !isReady) {
      init();
    }
  }, [
    clientDoc,
    isComponentMountedRef,
    isClientDocReady,
    isReady,
  ]);

  const tabContent = useMemo(() => {
    const { component: TabContent, props } = clientInfoTabsConfig[selectedTab];
    return (
      <TabContent
        user={clientId}
        userDoc={clientDoc}
        {...props}
      />
    );
  }, [
    selectedTab,
    clientDoc,
    clientId,
  ]);

  const handleStripeCustomerClick = useCallback(async () => {
    const userStripeDoc = clientDoc
      ? await StripeCustomerDocument.getStripeCustomerByUserId(clientDoc.id)
      : null;
    if (!!stripeAccountId && !!userStripeDoc) {
      openStripeCustomer(stripeAccountId, userStripeDoc.id);
    } else {
      showToast(texts.noStripeCustomer, { type: 'error' });
    }
  }, [
    stripeAccountId,
    clientDoc,
    showToast,
  ]);

  if (!isReady) {
    return <LoadingPage />;
  }

  return (
    <>
      <HeaderContainer>
        <UserInfoContainer>
          <UserInfoSection userDoc={clientDoc} />
          <ActionButtonContainer>
            <BackButton
              label={texts.goBack}
              onClick={navigateBack}
            />

            <FormResponseContainer
              postPaymentFormDoc={postPaymentFormDoc}
              leadDoc={leadDoc}
            />
            {isMealPlanAvailable && (
              <>
                <StyledButton
                  onClick={() => navigateToClientMealPlanPage(clientId)}
                >
                  {texts.mealPlan}
                </StyledButton>
              </>
            )}
            <StyledButton
              onClick={() => setShowMobileViewModal(true)}
            >
              {texts.clientView}
            </StyledButton>
          </ActionButtonContainer>
        </UserInfoContainer>
        <TabHeaderRow>
          <StyledTabs
            tabsConfig={clientInfoTabsConfig}
            selectedTab={selectedTab}
            onSelectTab={setSelectedTab}
          />
          {isOnCallUser && (
            <StyledOnCallActions
              actions={[
                {
                  onClick: () => openFirestoreDocument(`${CollectionName.USER}/${clientId}`),
                  disabled: !clientId,
                  label: texts.support.userDoc,
                },
                {
                  onClick: handleStripeCustomerClick,
                  disabled: !stripeAccountId,
                  label: texts.support.stripeCustomer,
                },
              ]}
            />
          )}
        </TabHeaderRow>
      </HeaderContainer>
      {tabContent}
      <MobileViewModal
        showModal={showMobileViewModal}
        onClose={() => setShowMobileViewModal(false)}
        mobileUrl={mobileUrl}
      />
    </>
  );
};

export default compose(
  withMealPlanContextReady,
  observer,
)(ClientInfoView);
