import React from 'react';
import PropTypes from 'prop-types';

import { ContractType } from '../../../../utils/userContract';
import UserContract from '../../../../../Model/UserContract';
import BaseContractModal from '../../BaseContractModal';

import ReusableContractModalContent from './ReusableContractModalContent';

const ReusableContractModal = ({
  selectedContract,
  showModal,
  onClose,
}) => (
  <BaseContractModal
    showModal={showModal}
    onClose={onClose}
    contractType={ContractType.BASE}
  >
    <ReusableContractModalContent
      selectedContract={selectedContract}
    />
  </BaseContractModal>
);

ReusableContractModal.propTypes = {
  selectedContract: PropTypes.instanceOf(UserContract),
  showModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
ReusableContractModal.defaultProps = {
  selectedContract: null,
};

export default ReusableContractModal;
