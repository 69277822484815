import styled from 'styled-components';
import { Divider } from '@mui/material';

import colors from '../../../../styles/colors';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 15px;
  margin-right: 10px;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  flex: 1;
  background-color: ${colors.base.alpha};
`;

const TitleText = styled.div`
  font-size: 12px;
  line-height: 14px;
`;

const UnitLabel = styled.div`
  font-size: 8px;
  line-height: 10px;
  font-weight: 500;
  margin-top: 2px;
`;

const ValueText = styled.div`
  font-size: 24px;
  line-height: 26px;
  font-weight: 800;
  margin-top: 2px;
`;

const MacroValueText = styled.div`
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  margin-top: 2px;
`;

const MacroLabelText = styled.div`
  font-size: 10px;
  line-height: 12px;
  font-weight: 400;
  color: ${colors.shades.gamma2};
  margin-top: 2px;
`;

const MacroTitleText = styled.div`
  font-size: 12px;
  line-height: 14px;
  margin: 5px 10px;
`;

const AverageStatContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 500;
  width: 100%;
  padding: 10px;
  margin: 0 10px;
  justify-content: space-between;
`;

const StatContainer = styled.div`
  display: flex;
  flex-direction: row;
  font-weight: 500;
  width: 100%;
  padding: 10px 0;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.shades.gamma5};
`;

const MacroStatContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 500;
  width: 100%;
  padding: 10px 0px;
  justify-content: space-between;
`;

const MacroStat = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const Stat = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 10px;
  margin: 2px 10px;
`;

const DailyStat = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 10px;
  margin: 2px 10px;
`;

const DailyPopupContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 25px;
  background-color: ${colors.base.secondary3};
  border-radius: 10px;
  color: ${colors.base.secondary2};
`;

const StyledDivider = styled(Divider)`
  margin: 10px 0;
`;

export {
  Container,
  TitleText,
  UnitLabel,
  ValueText,
  StatContainer,
  Stat,
  AverageStatContainer,
  MacroStatContainer,
  MacroStat,
  MacroLabelText,
  MacroValueText,
  MacroTitleText,
  DailyPopupContainer,
  StyledDivider,
  DailyStat,
};
