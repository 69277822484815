const texts = {
  noProfile: "Client doesn't have a nutrition profile yet",
  noTags: 'None selected',
  title: 'Nutrition Settings',
  restrictions: 'Client dietary restrictions',
  filterDescription: 'Dietary restriction options',
  save: 'Save',
  modalTitle: 'Save nutrition settings',
  modalContent: 'This will not affect the current meal plan assignment of the client (if any)',
  success: 'Nutrition settings updated successfully for the user',
  fail: 'Error updating nutrition settings: {error}',
};

export default texts;
