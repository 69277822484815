import React, {
  useState,
  useMemo,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

const HeaderContext = React.createContext(null);

const HeaderContextProvider = ({ children }) => {
  const history = useHistory();

  const defaultNavigateBack = useCallback(
    () => history.goBack(),
    [history],
  );

  const [navigateBack, setNavigateBack] = useState(() => defaultNavigateBack);
  const [showBackButton, setShowBackButton] = useState(false);

  const context = useMemo(() => ({
    navigateBack,
    setNavigateBack,
    showBackButton,
    setShowBackButton,
    defaultNavigateBack,
  }), [
    navigateBack,
    setNavigateBack,
    showBackButton,
    setShowBackButton,
    defaultNavigateBack,
  ]);

  return (
    <HeaderContext.Provider value={context}>
      {children}
    </HeaderContext.Provider>
  );
};

HeaderContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default HeaderContextProvider;
export {
  HeaderContext,
};
