import React from 'react';
import PropTypes from 'prop-types';

import {
  Title,
  Description,
  ActionsContainer,
  RoundedModal,
  ModalContentWrapper,
  HeaderContainer,
  ActionButton,
  CancelButton,
} from './styles';
import texts from './texts.json';

const ConfirmationModal = ({
  open,
  onClose,
  title,
  description,
  onConfirm,
}) => (
  <RoundedModal
    open={open}
    onClose={onClose}
  >
    <ModalContentWrapper>
      <HeaderContainer>
        <Title>{title}</Title>
        {!!description && <Description>{description}</Description>}
      </HeaderContainer>
      <ActionsContainer>
        <ActionButton onClick={onConfirm}>{texts.yes}</ActionButton>
        <CancelButton onClick={onClose}>{texts.no}</CancelButton>
      </ActionsContainer>
    </ModalContentWrapper>
  </RoundedModal>
);

ConfirmationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};
ConfirmationModal.defaultProps = {
  description: '',
};
export default ConfirmationModal;
