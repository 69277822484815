import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  row-gap: 10px;
`;

const SettingsHeaderContainer = styled.div`
  padding: 40px 0 0 20px;
`;

export {
  Container,
  ContentContainer,
  SettingsHeaderContainer,
};
