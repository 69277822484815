import styled from 'styled-components';

import Button from '../../../../components/Button';
import FormikInput from '../../../../components/FormikInput';
import colors from '../../../../styles/colors';

const FormInput = styled(FormikInput)`
  font-style: normal;
  font-size: 16px;
  color: ${colors.base.beta};

  input {
    background: ${colors.shades.gamma5} !important;
    border-radius: 0;
    border: none;
    outline: none;
    box-shadow: unset;
    width: 100%;
    line-height: 148%;
    padding: 20px;

    &:placeholder-shown {
      border-bottom: 0 !important;
    }

    &:focus {
      border-bottom: 0 !important;
    }

    .is-invalid:focus {
      border-bottom: 0 !important;
    }
  }
`;

const LoginButton = styled(Button)`
  letter-spacing: 0.16rem;
  line-height: 119%;
`;

export {
  FormInput,
  LoginButton,
};
