import WorkoutContext from './WorkoutContext';
import {
  withWorkoutContextProvider,
  withWorkoutContextReady,
} from './WithWorkoutContext';

export default WorkoutContext;
export {
  withWorkoutContextProvider,
  withWorkoutContextReady,
};
