import React from 'react';

const initialValues = {
  isReady: false,
  baseTagsCollection: { docs: [] },
  coachTagsCollection: { docs: [] },
  tags: [],
};

const ExerciseTagContext = React.createContext(initialValues);

export default ExerciseTagContext;
export {
  initialValues,
};
