import styled from 'styled-components';
import colors from '../../../../../../../styles/colors';

const FieldSet = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 40px 20px;
  color: ${colors.base.beta};
`;

export {
  FieldSet,
};
