const SettingOption = {
  ENABLED: 'ENABLED',
  DISABLED: 'DISABLED',
};

const getPublicChannelsList = async (remote) => {
  const response = await remote('getAllSlackChannels');
  if (!response.ok) {
    return [];
  }
  const { channels } = await response.json();
  return channels;
};

const joinSlackChannel = async (remote, channelId) => {
  const response = await remote('joinSlackChannel', { channelId });
  if (!response.ok) {
    const { error } = await response.json();
    return error;
  }
  return null;
};

export {
  SettingOption,
  getPublicChannelsList,
  joinSlackChannel,
};
