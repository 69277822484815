import {
  Button,
  Stack,
  TextField,
  Typography,
  Table,
  TableCell,
} from '@mui/material';
import styled from 'styled-components';

import colors from '../../../../../styles/colors';

import { LinkButton } from '../../../../../components/Button';

const TemplateContainer = styled(Stack)`
  display: flex;
  flex-direction: column;
  padding: 40px;
  height: fit-content;
`;

const Title = styled(Typography)`
  font-weight: bold;
  font-size: 24px;
`;

const StyledButton = styled(Button)`
  width: 200px;
`;

const StyledImage = styled.img`
  width: 200px;
`;

const StyledVideo = styled.video`
  width: 200px;
`;

const StyledTextField = styled(TextField)`
  width: 500px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

const HistoryContainer = styled.div`
  margin-top: 100px !important;
`;

const StyledTable = styled(Table)`
  margin-top: 10px;
  thead {
    background-color: ${colors.shades.gamma2};
    th {
      padding: 10px;
    }
  }
  tbody tr:nth-child(even) {
    background-color: ${colors.shades.gamma1};
  }
`;

const StyledLink = styled.a`
  color: ${colors.base.beta}
`;

const StyledLinkButton = styled(LinkButton)`
  width: max-content;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
  padding: 0;
`;

const StyledCell = styled(TableCell)`
  width: 100px;
`;

export {
  TemplateContainer,
  Title,
  StyledButton,
  StyledImage,
  StyledVideo,
  StyledTextField,
  HistoryContainer,
  StyledTable,
  StyledLink,
  StyledLinkButton,
  StyledCell,
  TextContainer,
};
