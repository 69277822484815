import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import format from 'string-template';
import { v4 as uuidv4 } from 'uuid';

import { ReactComponent as ReloadIcon } from '../../../../assets/icons/v2/reload.svg';
import { TimeDurationFormat, formatDuration } from '../../../../utils/time';
import useComponentMounted from '../../../../hooks/useComponentMounted';
import { DateFormat } from '../../../../utils/date';
import WorkoutFeedView from '../../../Model/WorkoutFeedView';
import {
  Container,
  WorkoutItem,
  WorkoutItemTextContainer,
  Tag,
  WorkoutText,
  WorkoutTextComment,
  LoadButton,
} from './styles';
import texts from './texts';

const ACTIVITIES_PER_PAGE = 10;

const WorkoutHistory = ({ clientId }) => {
  const [workoutsCollection, setWorkoutsCollection] = useState({});
  const [limit, setLimit] = useState(ACTIVITIES_PER_PAGE);
  const isComponentMountedRef = useComponentMounted();

  const canLoadMore = useMemo(() => workoutsCollection?.docs?.length === limit, [limit, workoutsCollection]);

  useEffect(() => {
    const loadworkoutHistoryCollection = async () => {
      const workoutHistoryCollection = await WorkoutFeedView.getCompletedWorkoutHistoryByUser(
        clientId,
        limit,
      );
      if (isComponentMountedRef.current) {
        setWorkoutsCollection(workoutHistoryCollection);
      }
    };
    loadworkoutHistoryCollection();
  }, [
    clientId,
    isComponentMountedRef,
    limit,
  ]);

  const handleLoadMore = useCallback(() => {
    setLimit((prevLimit) => prevLimit + ACTIVITIES_PER_PAGE);
  }, []);

  return (
    <Container>
      {workoutsCollection.hasDocs
        ? (workoutsCollection.docs.map((historyItem) => (
          <WorkoutItem key={`${historyItem.title}-${uuidv4()}`}>
            <Tag>{moment(historyItem.startDate).format(DateFormat.DATE_FORMAT_COMMA)}</Tag>
            <WorkoutItemTextContainer>
              <WorkoutText>
                {format(texts.completedWorkoutText, { type: texts.types[historyItem.type], title: historyItem.title })}
                {historyItem.duration
                  ? format(
                    texts.duration,
                    { duration: formatDuration(historyItem.duration, TimeDurationFormat.CLOCK_FORMAT) },
                  )
                  : null}
              </WorkoutText>
              {!!historyItem.comment
              && <WorkoutTextComment>{format(texts.comment, { comment: historyItem.comment })}</WorkoutTextComment>}
            </WorkoutItemTextContainer>
          </WorkoutItem>
        )))
        : texts.emptyText}
      {canLoadMore
      && (
        <LoadButton
          onClick={handleLoadMore}
          startIcon={<ReloadIcon />}
        >
          {texts.loadButton}
        </LoadButton>
      )}
    </Container>
  );
};

WorkoutHistory.propTypes = {
  clientId: PropTypes.string.isRequired,
};

export default WorkoutHistory;
