import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: start;

  > * {
    margin-right: 10px;
  }
`;

export {
  Wrapper,
};
