import { ChatSetting } from '../../../../../../../Model/Coach';

const texts = {
  chat: 'Chat Settings',
  chatDescription: 'Customize your chat functionality',
  chatSetting: {
    [ChatSetting.ENABLE_WORKOUT_NOTIFICATIONS]: 'Cloudbot Workout notifications',
    [ChatSetting.ENABLE_SUMMARY_NOTIFICATIONS]: 'Cloudbot Summary notifications',
    [ChatSetting.ENABLE_FEEDBACK_NOTIFICATIONS]: 'Cloudbot Feedback notifications',
  },
  enabled: 'Enabled',
  disabled: 'Disabled',
  chatSettingSaveSuccessful: 'Successfully saved the chat settings',
  chatSettingSaveFailed: 'Error changing the chatbot settings',
};

export default texts;
