import React, {
  useEffect,
  useMemo,
  useState,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { compose } from 'recompose';

import useComponentMounted from '../../../hooks/useComponentMounted';
import UserContext from '../../../context/UserContext';
import AppCustomization from '../../Model/AppCustomization';

import CustomizationContext from './CustomizationContext';

const CustomizationContextProvider = ({
  children,
}) => {
  const [customHabitDoc, setCustomHabitDoc] = useState();
  const [customCheckInDoc, setCustomCheckInDoc] = useState();
  const [appCustomizationDoc, setAppCustomizationDoc] = useState([]);
  const [isReady, setIsReady] = useState(false);

  const {
    userDoc: {
      id: userId,
    },
  } = useContext(UserContext);

  const isComponentMountedRef = useComponentMounted();

  useEffect(() => {
    const init = async () => {
      if (userId) {
        const appCustomization = await AppCustomization.getAppCustomizationByCoach(userId);
        let habitDoc = null;
        let checkInDoc = null;
        if (appCustomization) {
          habitDoc = await appCustomization.getBaseHabitDoc();
          checkInDoc = await appCustomization.getBaseCheckinDoc();
        }

        if (isComponentMountedRef.current) {
          setAppCustomizationDoc(appCustomization);
          setCustomHabitDoc(habitDoc);
          setCustomCheckInDoc(checkInDoc);
          setIsReady(true);
        }
      }
    };
    if (!isReady) {
      init();
    }
  }, [
    userId,
    isComponentMountedRef,
    appCustomizationDoc,
    isReady,
  ]);

  const context = useMemo(() => ({
    customHabitDoc,
    appCustomizationDoc,
    setCustomHabitDoc,
    customCheckInDoc,
    setCustomCheckInDoc,
    isReady,
  }), [
    customHabitDoc,
    appCustomizationDoc,
    setCustomHabitDoc,
    customCheckInDoc,
    setCustomCheckInDoc,
    isReady,
  ]);

  return (
    <CustomizationContext.Provider value={context}>
      {children}
    </CustomizationContext.Provider>
  );
};

CustomizationContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default compose(
  observer,
)(CustomizationContextProvider);
