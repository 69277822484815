import { ToggleButtonGroup } from '@mui/material';
import styled from 'styled-components';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  margin-left: auto;
`;

export {
  StyledToggleButtonGroup,
};
