import React, {
  useCallback,
  useEffect,
  useMemo,
  useContext,
} from 'react';
import { PropTypes } from 'prop-types';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import * as Sentry from '@sentry/browser';
import moment from 'moment';
import 'react-toastify/dist/ReactToastify.css';

// Hooks
import useAuthentication, {
  SignInMethod,
  AuthErrorCode,
} from '../../hooks/useAuthentication';
import useNavigation from '../../hooks/useNavigation';
import useSessionStore from '../../hooks/useSessionStore';

// Models
import User from '../../Model/User';

// Utils
import { firestorePaths } from '../../utils/paths';
import { anonId } from '../../utils/onboarding';
import { getQueryVariable } from '../../utils/queryParams';

// Contexts
import CustomerIOContext from '../CustomerIOContext';
import UserContractContext from '../UserContractContext';

import useOnboardingFlowStateReducer, { initialState } from './useOnboardingFlowStateReducer';
import { StyledToastContainer } from './styles';
import texts from './texts.json';

const FUTURE_DATE_PARAM_FORMAT = 'YYYY-MM-DD';

const OnboardingFlowContext = React.createContext(initialState);

const Provider = ({
  children,
}) => {
  const [
    state,,
    setters,
  ] = useOnboardingFlowStateReducer();
  const sessionStore = useSessionStore();
  const { userId: currentUserId } = sessionStore;

  const {
    getSignInMethod,
    createAnonymousAccountWithEmail,
    createUserWithEmailAndPassword,
  } = useAuthentication(sessionStore);

  const { setEmail: setEmailForCustomerIO } = useContext(CustomerIOContext);
  const { contractDoc } = useContext(UserContractContext);

  const {
    search,
    routes,
    pathname,
  } = useNavigation();

  const {
    email,
  } = state;

  // Set startAtDate state from a contract or query param
  useEffect(() => {
    // Check if we have a startDate in a contract.
    let { startDate } = contractDoc || {};

    // If we don't have a startDate, check the query params.
    if (!startDate) {
      startDate = getQueryVariable('startAt');
    }

    if (startDate) {
      const futureDate = moment(startDate, FUTURE_DATE_PARAM_FORMAT, true);

      if (futureDate.isValid()) {
        // Check if the date is in the future
        const todayStartOfDayDate = moment().startOf('day');
        if (futureDate.diff(todayStartOfDayDate) > 0) {
          // It is a valid future date!
          setters.setStartAtDate(futureDate);
        }
      }
    }
  }, [
    setters,
    contractDoc,
  ]);

  const userDoc = useMemo(() => {
    if (currentUserId) {
      return new User(() => `${firestorePaths.USER}/${currentUserId}`);
    }
    return null;
  }, [
    currentUserId,
  ]);

  const userDocHasData = !!userDoc && userDoc.hasData;

  useEffect(() => {
    if (anonId) {
      window.analytics.user().anonymousId(anonId);
    }
  }, []);

  useEffect(() => {
    if (userDoc && !userDocHasData) {
      userDoc.init({
        fetch: false,
      });
    }
  }, [
    userDoc,
    userDocHasData,
  ]);

  useEffect(() => {
    if (email) {
      setEmailForCustomerIO(email);
    }
  }, [
    email,
    setEmailForCustomerIO,
  ]);

  // Update onboarding states with user doc data
  useEffect(() => {
    if (contractDoc) {
      setters.setEmail(contractDoc.userEmail || '');
      setters.setFirstName(contractDoc.userFirstName || contractDoc.userName || '');
      setters.setLastName(contractDoc.userLastName || '');
      setters.setPhoneNumber(contractDoc.phoneNumber || '');
    }
    if (userDocHasData) {
      setters.setEmail(userDoc.email || '');
      setters.setFirstName(userDoc.firstName || '');
      setters.setLastName(userDoc.lastName || '');
      setters.setPhoneNumber(userDoc.phoneNumber || '');
    }
  }, [
    userDocHasData,
    setters,
    userDoc,
    contractDoc,
  ]);

  /**
   * This function creates an user on firebase auth and firestore.
   * It returns an object that indicates if the operation was succesful or not,
   * with some extra data to handle errors.
   */
  const createUser = useCallback(async ({
    firstName: submittedFirstName,
    lastName: submittedLastName,
    email: submittedEmail,
    phoneNumber: submittedPhoneNumber,
    password: submittedPassword,
  }) => {
    const emailForRegistration = (submittedEmail || email)?.toLowerCase();
    const signInMethod = await getSignInMethod(emailForRegistration);

    const commonErrorFields = {
      isError: true,
      errorMsg: texts.emailInUse,
      route: routes.LOGIN,
      routeSearch: search,
    };
    const commonRouteStateFields = {
      userEmail: emailForRegistration,
      onLogin: pathname,
    };

    switch (signInMethod) {
      case SignInMethod.NO_ACCOUNT: {
        try {
          const user = submittedPassword
            ? await createUserWithEmailAndPassword(emailForRegistration, submittedPassword)
            : await createAnonymousAccountWithEmail(emailForRegistration);
          await User.createUser(user.uid, {
            firstName: submittedFirstName,
            lastName: submittedLastName,
            email: submittedEmail,
            phoneNumber: submittedPhoneNumber,
          });
          sessionStore.changeUser(user);
          return { isError: false };
        } catch (err) {
          if (err.code === AuthErrorCode.EMAIL_ALREADY_IN_USE) {
            return {
              ...commonErrorFields,
              routeState: {
                ...commonRouteStateFields,
                sendAuthLink: true,
              },
            };
          }
          Sentry.captureException(err);
          return {
            isError: true,
            errorMsg: texts.accountCreateError,
          };
        }
      }
      case SignInMethod.EMAIL_LINK:
        return {
          ...commonErrorFields,
          routeState: {
            ...commonRouteStateFields,
            sendAuthLink: true,
          },
        };
      case SignInMethod.EMAIL_PASSWORD:
      default:
        return {
          ...commonErrorFields,
          routeState: {
            ...commonRouteStateFields,
          },
        };
    }
  }, [
    routes,
    createAnonymousAccountWithEmail,
    createUserWithEmailAndPassword,
    getSignInMethod,
    email,
    sessionStore,
    pathname,
    search,
  ]);

  const contextValue = useMemo(() => ({
    setters,
    ...state,
    createUser,
  }),
  [
    state,
    setters,
    createUser,
  ]);

  return (
    <OnboardingFlowContext.Provider value={contextValue}>
      <>
        <StyledToastContainer />
        {children}
      </>
    </OnboardingFlowContext.Provider>
  );
};

Provider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default OnboardingFlowContext;
export const OnBoardingFlowContextProvider = compose(
  observer,
)(Provider);
