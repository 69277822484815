import styled from 'styled-components';
import {
  BarChart,
  AddToHomeScreen,
  QueryStats,
  HistoryToggleOff,
  AutoGraph,
} from '@mui/icons-material';
import colors from '../../../styles/colors';
import { iconStyles } from '../ClientInfo/styles';

const Container = styled.div`
  padding: 30px;
  background-color: ${colors.shades.gamma5};
  position: relative;

  &:after {
    content: "";
    width: 100%;
    height: 38px;
    background-color: ${colors.shades.gamma5};
    position: absolute;
    top: 100%;
    left: 0;
  }
`;

const Title = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: ${colors.shades.beta2};
  padding: 0 30px;
`;

const CoachAnalyticsIcon = styled(BarChart)`
  ${iconStyles};
`;

const AppAnalyticsIcon = styled(AddToHomeScreen)`
  ${iconStyles};
`;

const CompanyAnalyticsIcon = styled(QueryStats)`
  ${iconStyles};
`;

const TimeTrackingIcon = styled(HistoryToggleOff)`
  ${iconStyles};
`;

const MetabaseIcon = styled(AutoGraph)`
  ${iconStyles};
`;

export {
  Container,
  Title,
  CoachAnalyticsIcon,
  AppAnalyticsIcon,
  CompanyAnalyticsIcon,
  TimeTrackingIcon,
  MetabaseIcon,
};
