import styled from 'styled-components';

const StyledIframe = styled.iframe`
    border: none;
    background: white;
    width: 100%;
    height: 100%;
`;

export {
  StyledIframe,
};
