import styled from 'styled-components';

import colors from '../../styles/colors';

const ErrorMessage = styled.p`
  padding: 0 25px;
  color: ${colors.base.danger};
`;

export { ErrorMessage };
