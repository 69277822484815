import React from 'react';
import PropTypes from 'prop-types';
import { FastField } from 'formik';

import InputLabel from '../InputLabel';
import { StyledTextField } from '../../Inputs';

import {
  Container,
} from './styles';

const FormikInput = ({
  name,
  label,
  className,
  ...props
}) => (
  <FastField name={name}>
    {({ field, meta }) => {
      const isError = !!(meta.touched && meta.error);

      return (
        <Container className={className}>
          {!!label && <InputLabel>{label}</InputLabel>}
          <StyledTextField
            {...field}
            {...props}
            error={isError}
            helperText={isError ? meta.error : ''}
          />
        </Container>
      );
    }}
  </FastField>
);

FormikInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
};

FormikInput.defaultProps = {
  label: '',
  className: '',
};

export default FormikInput;
