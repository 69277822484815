import {
  NotificationType,
  DisableType,
} from '../utils';

const texts = {
  selectADate: 'Select a Date',
  disableNotificationTitle: 'Disable {type} Notifications',
  notificationType: {
    [NotificationType.WORKOUT]: 'Workout',
    [NotificationType.MEAL_PLAN]: 'Meal Plan',
  },
  disableType: {
    [DisableType.RESUME]: 'Resume Notifications on',
    [DisableType.INDEFINITELY]: 'Indefinitely',
  },
  button: {
    save: 'Save Changes',
    cancel: 'Cancel',
  },

};

export default texts;
