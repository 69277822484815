import React, {
  useMemo,
  useState,
  useContext,
} from 'react';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import PropTypes from 'prop-types';
import format from 'string-template';
import { Divider } from '@mui/material';
import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';

import { ReactComponent as CalendarIcon } from '../../../../assets/icons/calendar-icon.svg';
import { DateFormat } from '../../../../utils/date';
import { DEFAULT_CURRENCY } from '../../../../utils/currency';
import { formatCurrency } from '../../../../utils/formatters';
import {
  ContractMinimumValue,
  ContractMaxValue,
  ContractProperties,
} from '../../../utils/userContract';
import RadioButtonGroup from '../../RadioButtonGroup';
import { ContractPriceType } from '../utils/ContractTypes';
import ContractModalContext from '../context/ContractModalContext';
import PriceSelector, { CURRENCY_KEY } from '../PriceSelector';
import Input from '../Input';
import {
  Section,
  SectionTitle,
} from '../Section';
import PackagesSelector from '../PackagesSelector';
import FeeInput from '../FeeInput';

import {
  FormField,
  SidePanel,
  sidePanelStyle,
  StyledProductSelector,
  ConfigRow,
  RowItem,
  InitialTermText,
  CancellationDate,
  StyledAdornment,
  InitialPaymentContainer,
  CommitmentContainer,
  MonthlyPriceContainer,
  StartDateContainer,
  ProductPackageContainer,
  CheckoutPaymentWrapper,
  SubSection,
  StyledAveragePayment,
  StyledDatePickerInput,
  PackagesContainer,
  Container,
  Toolbar,
  PriceTypeButton,
  TotalFeesContainer,
  BillingOptionsContainer,
  BillingOptionsRadioGroup,
  BillingOptionsPriceSelector,
  BillingOptionsInfo,
  ContractLabelContainer,
  ContractLabel,
  ContractValue,
} from './styles';
import texts from './texts.json';

const priceTypeButtons = [
  {
    label: texts.pricing.standard,
    type: ContractPriceType.STANDARD,
  },
  {
    label: texts.pricing.custom,
    type: ContractPriceType.CUSTOM,
  },
];

const BillingOption = {
  BILL_ON_START_DATE: 'BILL_ON_START_DATE',
  BILL_NOW: 'BILL_NOW',
};

const billingOptionsConfig = [
  {
    label: texts.billingOptions.optionLabels.billOnStartDate,
    value: BillingOption.BILL_ON_START_DATE,
  }, {
    label: texts.billingOptions.optionLabels.billNow,
    value: BillingOption.BILL_NOW,
  },
];

const ContractConfigurator = ({
  contract,
  contractDoc,
  onChange,
  products,
  onProductChange,
  selectedProduct,
  onPackageChange,
  supportedCurrencies,
  isReadOnly,
  showFeesConfigurator,
}) => {
  const [selectedPriceType, setSelectedPriceType] = useState(() => {
    if (!contractDoc) {
      return ContractPriceType.STANDARD;
    }

    return contractDoc.priceId ? ContractPriceType.STANDARD : ContractPriceType.CUSTOM;
  });

  const [billingOption, setBillingOption] = useState(() => (
    parseFloat(contract.upfrontPayment) > 0 ? BillingOption.BILL_NOW : BillingOption.BILL_ON_START_DATE
  ));

  const {
    resetContract,
    coachId,
  } = useContext(ContractModalContext);

  const onFieldChange = (event) => {
    const {
      target: {
        name,
        value,
      },
    } = event;

    if (value !== contract[name]) {
      onChange(name, value);
    }
  };

  const onPriceFieldChange = (event) => {
    const {
      target: {
        name,
        value,
      },
    } = event;
    let changedValue = value;
    /*
     * The price field change is triggered by the input change as well as the currency selection.
     * We only want to run through formatting if the change is the price input.
     */
    if (name !== CURRENCY_KEY) {
      changedValue = Number(value.toString().match(/^\d+(?:\.\d{0,2})?/)).toString();
    }
    onChange(name, changedValue);
  };

  const minSubscriptionMonthsLowerLimit = Math.max(
    ContractMinimumValue.MIN_SUBSCRIPTION_MONTHS,
    contract.recurringBillingMonths,
  );

  const commitmentErrorMessage = useMemo(() => {
    // Is it less than MIN_SUBSCRIPTION_MONTHS allowed?
    if (contract.minSubscriptionMonths < ContractMinimumValue.MIN_SUBSCRIPTION_MONTHS) {
      return format(texts.errors.minSubscriptionMonths, ContractMinimumValue.MIN_SUBSCRIPTION_MONTHS);
    }

    // If the commitment is not multiple of the billing cycle, then we should error out.
    if (contract.minSubscriptionMonths % contract.recurringBillingMonths !== 0) {
      const multiples = [1, 2, 3].map((item) => item * contract.recurringBillingMonths).join(', ');
      return format(texts.errors.minSubscriptionMonthsMultiple, { multiples });
    }

    // If the commitment is lower than the initial term, then we should error out.
    if (contract.minSubscriptionMonths < contract.initialTerm) {
      return format(texts.errors.minSubscriptionMonthsInitialTerm, contract.initialTerm);
    }

    return '';
  }, [contract]);

  // Compute the cancellation date based on the startDate and commitment.
  const cancellationDate = useMemo(() => {
    const startingDate = contract.startDate?.clone() || moment();

    return startingDate
      .add(contract.minSubscriptionMonths, 'months')
      .format(DateFormat.SHORT_MONTH_DAY_COMMA_YEAR_FORMAT);
  }, [
    contract.startDate,
    contract.minSubscriptionMonths,
  ]);

  const renderPriceTypeButtons = () => (
    priceTypeButtons.map((buttonConfig) => {
      const isSelected = selectedPriceType === buttonConfig.type;
      return (
        <PriceTypeButton
          key={buttonConfig.type}
          onClick={() => {
            if (!isSelected) {
              resetContract();
              setSelectedPriceType(buttonConfig.type);
            }
          }}
          $selected={isSelected}
          disabled={isReadOnly}
        >
          {buttonConfig.label}
        </PriceTypeButton>
      );
    })
  );

  const enableCustomPricing = selectedPriceType === ContractPriceType.CUSTOM;

  /**
   * We should disable the editing of the contract if:
   *  - The contract is read only (the client has already subscribed)
   *  - The coach is using standard pricing. In this case, the coach can select packages instead of individual prices.
   */
  const isEditingDisabled = isReadOnly || !enableCustomPricing;

  const totalFeeCollected = (contract[ContractProperties.BASE_FEE] || 0)
    + (contract[ContractProperties.IS_FEE] || 0)
    + (contract[ContractProperties.AC_FEE] || 0);

  let upfrontPaymentErrorMessage = '';
  const upfrontPaymentError = parseFloat(contract.upfrontPayment) > parseFloat(contract.initialPayment);
  if (upfrontPaymentError) {
    const msg = format(texts.upfrontPaymentError, {
      amount: formatCurrency(contract.initialPayment, contract.currency),
    });
    upfrontPaymentErrorMessage = ReactHtmlParser(msg);
  }

  const onBillingOptionChange = (option) => {
    setBillingOption(option);
    if (option === BillingOption.BILL_ON_START_DATE) {
      /*
        We have to reset the upfront payment to 0 if the user selects to bill on start date, to avoid splitting the
        subscription in the backend
      */
      onFieldChange({ target: { name: ContractProperties.UPFRONT_PAYMENT, value: 0 } });
    }
  };

  return (
    <Container>
      <Toolbar>
        {renderPriceTypeButtons()}
      </Toolbar>
      <SidePanel
        sx={sidePanelStyle}
      >
        <ProductPackageContainer>
          <SubSection>
            <SectionTitle>{texts.selectProduct}</SectionTitle>
            <StyledProductSelector
              selectedProduct={selectedProduct}
              onChange={onProductChange}
              isReadOnly={isReadOnly || !coachId}
              // show only products that are not archived or used in the contract
              products={products.filter((product) => (!product.isArchived || product.id === selectedProduct?.id))}
            />
          </SubSection>
          {/*
            We should only render the packages selector if there is a product selected and the pricing mode is standard.
          */}
          {!!selectedProduct && !enableCustomPricing && (
            <SubSection>
              <SectionTitle>{texts.selectPackage}</SectionTitle>
              <PackagesContainer>
                <PackagesSelector
                  product={selectedProduct}
                  selectedPackageId={contract.packageId}
                  onChange={onPackageChange}
                  isReadOnly={isReadOnly}
                />
              </PackagesContainer>
            </SubSection>
          )}
        </ProductPackageContainer>
        <Section>
          <SectionTitle>{texts.dueOnCheckout}</SectionTitle>
          <ConfigRow>
            <CheckoutPaymentWrapper>
              <InitialPaymentContainer>
                <RowItem>
                  <PriceSelector
                    name={ContractProperties.INITIAL_PAYMENT}
                    contract={contract}
                    currency={contract.currency}
                    value={contract.initialPayment}
                    fieldText={texts.initialPayment}
                    isReadOnly={isEditingDisabled}
                    onChange={onPriceFieldChange}
                    supportedCurrencies={supportedCurrencies}
                  />
                </RowItem>
                <InitialTermText>{texts.for}</InitialTermText>
                <RowItem>
                  <FormField>
                    <Input
                      size="small"
                      name={ContractProperties.INITIAL_TERM}
                      label={texts.initialTerm}
                      value={contract.initialTerm}
                      onChange={onFieldChange}
                      type="number"
                      disabled={isEditingDisabled}
                      error={contract.initialTerm <= 0}
                      helperText={contract.initialTerm <= 0
                        ? texts.errors.positiveNumber : ''}
                      InputProps={{
                        inputProps: {
                          min: ContractMinimumValue.INITIAL_TERM,
                          max: ContractMaxValue.INITIAL_TERM,
                        },
                        endAdornment: (
                          <StyledAdornment position="end">
                            {contract.initialTerm === 1 ? texts.month : texts.months}
                          </StyledAdornment>
                        ),
                      }}
                      id="initial-term"
                      fullWidth
                    />
                  </FormField>
                </RowItem>
              </InitialPaymentContainer>
              <StyledAveragePayment contract={contract} />
            </CheckoutPaymentWrapper>
            <Divider orientation="vertical" flexItem />
            <CommitmentContainer>
              <FormField>
                <Input
                  size="small"
                  name={ContractProperties.MIN_SUBSCRIPTION_MONTHS}
                  label={texts.commitment}
                  value={contract.minSubscriptionMonths}
                  onChange={onFieldChange}
                  type="number"
                  disabled={isReadOnly || !!contract.cancelAtPeriodEnd}
                  error={!!commitmentErrorMessage}
                  helperText={commitmentErrorMessage}
                  InputProps={{
                    inputProps: {
                      min: minSubscriptionMonthsLowerLimit,
                      placeholder: contract.minSubscriptionMonthsPlaceholder,
                    },
                    endAdornment: (
                      <StyledAdornment position="end">
                        {contract.minSubscriptionMonths === 1 ? texts.month : texts.months}
                      </StyledAdornment>
                    ),
                  }}
                  fullWidth
                  id="commitment"
                />
              </FormField>
            </CommitmentContainer>
            <CancellationDate>
              <span>{ReactHtmlParser(format(texts.cancelDate, { date: cancellationDate }))}</span>
            </CancellationDate>
          </ConfigRow>
        </Section>
        <Section>
          <SectionTitle>{texts.monthlySubscription}</SectionTitle>
          <MonthlyPriceContainer>
            <PriceSelector
              name={ContractProperties.MONTHLY_PAYMENT}
              currency={contract.currency}
              value={contract.monthlyPayment}
              fieldText={texts.monthlyPayment}
              /*
                If the initial term is 1 (meaning a monthly subscription), then we should not allow the user to change
                the monthly payment. This is because the monthly payment is the same as the initial payment.
                For longer initialTerms, the monthly payment will be different from the initial payment, so we enable
                the field.
              */
              isReadOnly={isEditingDisabled}
              onChange={onPriceFieldChange}
              supportedCurrencies={supportedCurrencies}
            />
          </MonthlyPriceContainer>
        </Section>
        <Section>
          <SectionTitle>{texts.startDate}</SectionTitle>
          <StartDateContainer>
            <FormField>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  disablePast
                  name={ContractProperties.START_DATE}
                  inputFormat={DateFormat.DATE_FORMAT_SLASH}
                  value={contract.startDate}
                  disabled={isReadOnly}
                  onChange={(dt) => onFieldChange({ target: { name: 'startDate', value: dt } })}
                  components={{
                    OpenPickerIcon: CalendarIcon,
                  }}
                  renderInput={(params) => (
                    <StyledDatePickerInput
                      size="small"
                      {...params}
                      error={!isReadOnly && !contract.startDate?.isValid()}
                      helperText={contract.startDate?.isValid() ? texts.startDateHelper
                        : texts.errors.invalidDate}
                    />
                  )}
                />
              </LocalizationProvider>
            </FormField>
          </StartDateContainer>
        </Section>
        {contract.startDate?.isValid() && contract.startDate?.isAfter(moment()) && (
          <Section>
            <SectionTitle>{texts.billingOptions.title}</SectionTitle>
            <BillingOptionsContainer>
              <BillingOptionsRadioGroup>
                <RadioButtonGroup
                  options={billingOptionsConfig}
                  selectedOption={billingOption}
                  onOptionChange={onBillingOptionChange}
                />
              </BillingOptionsRadioGroup>
              <BillingOptionsPriceSelector
                name={ContractProperties.UPFRONT_PAYMENT}
                currency={contract.currency}
                value={contract.upfrontPayment}
                fieldText={texts.upfrontPayment}
                isReadOnly={billingOption === BillingOption.BILL_ON_START_DATE || isReadOnly}
                onChange={onPriceFieldChange}
                supportedCurrencies={supportedCurrencies}
                errorMessage={upfrontPaymentErrorMessage}
                isCurrencyReadOnly
              />
              <BillingOptionsInfo>
                <ContractLabelContainer>
                  <ContractLabel>{`${texts.billingOptions.infoLabels.billedImmediately}: `}</ContractLabel>
                  <ContractValue>{formatCurrency(contract.upfrontPayment, contract.currency)}</ContractValue>
                </ContractLabelContainer>
                <ContractLabelContainer>
                  <ContractLabel>{`${texts.billingOptions.infoLabels.billedOnStartDate}: `}</ContractLabel>
                  <ContractValue>
                    {formatCurrency(contract.initialPayment - contract.upfrontPayment, contract.currency)}
                    {` (${contract.startDate.format(DateFormat.DATE_FORMAT_COMMA)})`}
                  </ContractValue>
                </ContractLabelContainer>
              </BillingOptionsInfo>
            </BillingOptionsContainer>
          </Section>
        )}
        {showFeesConfigurator && (
          <Section>
            <SectionTitle>{texts.collectedFees}</SectionTitle>
            <ConfigRow>
              {typeof contract[ContractProperties.BASE_FEE] !== 'undefined' && (
                <FeeInput
                  label={format(texts.override, { feeType: texts.baseFee })}
                  value={contract[ContractProperties.BASE_FEE]}
                  name={ContractProperties.BASE_FEE}
                  onChange={onFieldChange}
                />
              )}
              {typeof contract[ContractProperties.IS_FEE] !== 'undefined' && (
                <FeeInput
                  label={format(texts.override, { feeType: texts.isFee })}
                  value={contract[ContractProperties.IS_FEE]}
                  name={ContractProperties.IS_FEE}
                  onChange={onFieldChange}
                />
              )}
              {typeof contract[ContractProperties.AC_FEE] !== 'undefined' && (
                <FeeInput
                  label={format(texts.override, { feeType: texts.acFee })}
                  value={contract[ContractProperties.AC_FEE]}
                  name={ContractProperties.AC_FEE}
                  onChange={onFieldChange}
                />
              )}
            </ConfigRow>
            <TotalFeesContainer>
              {ReactHtmlParser(format(texts.totalFeeCollected, { totalFeeCollected }))}
            </TotalFeesContainer>
          </Section>
        )}
      </SidePanel>
    </Container>
  );
};

ContractConfigurator.propTypes = {
  contract: PropTypes.object.isRequired,
  contractDoc: PropTypes.object,
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
  onProductChange: PropTypes.func,
  selectedProduct: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool,
  onPackageChange: PropTypes.func.isRequired,
  supportedCurrencies: PropTypes.arrayOf(PropTypes.string),
  showFeesConfigurator: PropTypes.bool,
};

ContractConfigurator.defaultProps = {
  contractDoc: null,
  isReadOnly: false,
  onProductChange: () => {},
  selectedProduct: null,
  supportedCurrencies: [DEFAULT_CURRENCY.code],
  showFeesConfigurator: false,
};

export default ContractConfigurator;
