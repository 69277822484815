import styled from 'styled-components';

import colors from '../../../../styles/colors';

const Section = styled.section`
  background-color: ${colors.base.gamma};
  border-bottom: 1px solid ${colors.base.alpha};
  padding: 20px 40px 25px;
`;

const SectionTitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 15px;
`;

export {
  Section,
  SectionTitle,
};
