import React, {
  useCallback,
  useContext,
  useState,
} from 'react';
import format from 'string-template';
import { observer } from 'mobx-react';
import { compose } from 'recompose';

import { ReactComponent as CopyIcon } from '../../../../../../assets/icons/v2/duplicate.svg';
import { SaveButton } from '../../../../../../components/Button/ActionButtons';

import ExternalCoachContext from '../../../../../context/ExternalCoachContext';
import useToast from '../../../../../hooks/useToast';
import { MEAL_PLAN_INTRODUCTION_PLACEHOLDER } from '../../../../../utils/appCustomization';

import {
  PlaceholderButton,
  PlaceholdersContainer,
  PlaceholderTip,
} from '../../../../../components/PlaceHolder/styles';
import EditItem from '../../../components/EditItem';
import {
  TitledActionContainer,
  TitledActionContainerFooter,
  TitledActionContainerHeader,
  TitledActionContainerSection,
} from '../../../styles';

import texts from './texts.json';
import { MEAL_PLAN_MESSAGE_MAX_CHARACTERS_COUNT } from './utils';
import {
  InputContainer,
  StyledTextField,
} from './styles';

const MealPlanIntroductionConfig = () => {
  const minRows = 3;
  const maxRows = 18;

  const { coachDoc } = useContext(ExternalCoachContext);

  const {
    mealPlanConfig: {
      mealPlanIntroMessage = '',
    },
  } = coachDoc;

  const [introMessage, setIntroMessage] = useState(mealPlanIntroMessage);
  const [characterCount, setCharacterCount] = useState(mealPlanIntroMessage.length);

  const { showToast } = useToast();

  const handleSave = useCallback(async () => {
    await coachDoc.setMealPlanIntroMessage(introMessage);
    showToast(texts.messageSaved);
  }, [
    coachDoc,
    introMessage,
    showToast,
  ]);

  const copyPlaceholderValue = useCallback(async (placeholderValue) => {
    await navigator.clipboard.writeText(`{${placeholderValue}}`);
    showToast(texts.placeholderCopied);
  }, [
    showToast,
  ]);

  const handleTextChange = useCallback((event) => {
    const newValue = event.target.value;
    setIntroMessage(newValue);
    setCharacterCount(newValue.length);
  }, []);

  const isError = characterCount > MEAL_PLAN_MESSAGE_MAX_CHARACTERS_COUNT;

  return (
    <TitledActionContainer>
      <TitledActionContainerHeader>{texts.header}</TitledActionContainerHeader>
      <TitledActionContainerSection>
        <EditItem label={texts.label}>
          <InputContainer>
            <StyledTextField
              value={introMessage}
              onChange={handleTextChange}
              placeholder={texts.defaultMessage}
              error={isError}
              helperText={!isError ? null : format(texts.characterCount, {
                count: characterCount,
                maxCount: MEAL_PLAN_MESSAGE_MAX_CHARACTERS_COUNT,
              })}
              multiline
              minRows={minRows}
              maxRows={maxRows}
            />
          </InputContainer>
        </EditItem>
        <EditItem>
          <PlaceholdersContainer>
            <PlaceholderTip>{texts.placeholderTitle}</PlaceholderTip>
            {Object.values(MEAL_PLAN_INTRODUCTION_PLACEHOLDER).map((placeholderValue) => {
              const displayText = `{${placeholderValue}}`;
              return (
                <PlaceholderButton
                  key={placeholderValue}
                  startIcon={<CopyIcon />}
                  onClick={() => copyPlaceholderValue(placeholderValue)}
                >
                  {displayText}
                </PlaceholderButton>
              );
            })}
            <PlaceholderTip>{texts.placeholderDescription}</PlaceholderTip>
          </PlaceholdersContainer>
        </EditItem>
      </TitledActionContainerSection>
      <TitledActionContainerFooter>
        <SaveButton
          onClick={handleSave}
          disabled={isError || introMessage === mealPlanIntroMessage}
        >
          {texts.save}
        </SaveButton>
      </TitledActionContainerFooter>
    </TitledActionContainer>
  );
};

export default compose(
  observer,
)(MealPlanIntroductionConfig);
