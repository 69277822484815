import React, { useCallback, useEffect, useState } from 'react';

import useInAppBrowser from '../../hooks/useInAppBrowser';

import ExitButton from './ExitButton';

const withCloseInAppBrowserButton = (Component) => (props) => {
  const {
    isAvailable: isInAppBrowserAvailable,
    close: closeInAppBrowser,
  } = useInAppBrowser();

  const [showExitButton, setShowExitButton] = useState(false);

  useEffect(() => {
    if (isInAppBrowserAvailable) {
      setShowExitButton(true);
    }
  }, [
    isInAppBrowserAvailable,
  ]);

  const onExitButtonClicked = useCallback(async () => {
    closeInAppBrowser();
  }, [
    closeInAppBrowser,
  ]);

  return (
    <>
      <Component {...props} />
      { showExitButton && <ExitButton onClick={onExitButtonClicked} /> }
    </>
  );
};

export default withCloseInAppBrowserButton;
