const ActionType = {
  UPDATE: 'UPDATE',
  DUPLICATE: 'DUPLICATE',
  ARCHIVE: 'ARCHIVE',
  UNARCHIVE: 'UNARCHIVE',
  CREATE: 'CREATE',
};

const canPerformAction = (actionType, program) => (
  !!actionType && !!program
);

const isEditAction = (actionType, program) => (
  canPerformAction(actionType, program) && actionType === ActionType.UPDATE
);

const isCreateAction = (actionType) => (
  actionType === ActionType.CREATE
);

const isEditorView = (actionType, program) => (
  isEditAction(actionType, program) || isCreateAction(actionType)
);

export {
  ActionType,
  isEditAction,
  isEditorView,
};
