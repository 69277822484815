import { Collection } from 'firestorter';

import BaseDocument from '../../Model/BaseDocument';
import Collections from '../../utils/collections';

/**
 * Category of an exercise tag
 */
const TagCategory = {
  EXERCISE_TYPE: 'EXERCISE_TYPE',
  EQUIPMENT: 'EQUIPMENT',
  BODY_REGION_BASIC: 'BODY_REGION_BASIC',
  BODY_REGION_ADVANCED: 'BODY_REGION_ADVANCED',
  GENERIC: 'GENERIC',
  PLATFORM: 'PLATFORM',
};

/**
 * Type of an exercise tag
 */
const TagType = {
  BASE: 'BASE',
  CUSTOM: 'CUSTOM',
};

/**
 * Class representing an Exercise Tag.
 *
 * @class ExerciseTag
 * @extends BaseDocument
 */
class ExerciseTag extends BaseDocument {
  constructor(id, opts) {
    super(`${Collections.EXERCISE_TAG}/${id}`, opts);
  }

  /**
   * Get the type of the exercise tag.
   * @returns {string}
   */
  get type() {
    return this.data.type;
  }

  /**
   * Get the exercise tag name.
   * @returns {string}
   */
  get tag() {
    return this.data.tag;
  }

  /**
   * Get the category of the exercise tag.
   * @returns {string}
   */
  get category() {
    return this.data.category;
  }

  /**
   * Get the coach id which the exercise tag belongs to.
   * @returns {string}
   */
  get coach() {
    return this.data.coach;
  }

  /**
   * Updates an exercise tag
   *
   * @param {string} tag tag name
   * @param {string} category tag category
   * @returns {Promise} Promise that gets resolved when the update operation is completed
   * @throws {Error} if tag is not a CUSTOM tag
   */
  async updateTag(tag, category) {
    if (this.type === TagType.CUSTOM) {
      return this.updateFields({
        tag,
        category,
      });
    }
    throw new Error('Cannot update BASE tag');
  }

  /**
   * Creates a new exercise tag document in firestore, with a CUSTOM type.
   *
   * @param {string} name - name of the new exercise tag
   * @param {string} category - category of the new exercise tag
   * @param {string} coach - coach id for the new exercise tag
   * @returns {Promise<string>} The id of the created firestore document
   */
  static async addCustomTag(name, category, coach) {
    const exercisesTagCollection = new Collection(Collections.EXERCISE_TAG);
    const { id } = await exercisesTagCollection.add({
      tag: name,
      category,
      coach,
      type: TagType.CUSTOM,
    });
    return id;
  }

  /**
   * Gets the active base set of exercise tags that were added by the system.
   *
   * @returns {Promise<Collection>} The collection of exercise tags from firestore
   */
  static async getBaseTags() {
    const tagsCollection = new Collection(Collections.EXERCISE_TAG, {
      createDocument: ({ id }, opts) => new ExerciseTag(id, { ...opts, disableObserverRefCount: true }),
      query: (ref) => ref
        .where('type', '==', TagType.BASE),
    });
    await tagsCollection.fetch();
    return tagsCollection;
  }

  /**
   * Gets exercise tags created by a particular coach.
   *
   * @param {string} coachId - Id of the coach
   * @returns {Promise<Collection>} The collection of exercise tags from firestore
   */
  static async getCoachTags(coachId) {
    const tagsCollection = new Collection(Collections.EXERCISE_TAG, {
      createDocument: ({ id }, opts) => new ExerciseTag(id, { ...opts, disableObserverRefCount: true }),
      query: (ref) => ref
        .where('coach', '==', coachId)
        .where('type', '==', TagType.CUSTOM),
    });
    await tagsCollection.fetch();
    return tagsCollection;
  }
}

export default ExerciseTag;
export {
  TagCategory,
  TagType,
};
