import React, { useEffect, useState } from 'react';
import * as Sentry from '@sentry/browser';

import useComponentMount from '../../hooks/useComponentMounted';
import useAuthentication from '../../hooks/useAuthentication';
import useInAppBrowser from '../../hooks/useInAppBrowser';

import LoadingPage from '../LoadingPage';

const withRedirectToApp = (Component) => (props) => {
  const { isComponentMounted } = useComponentMount();
  const { sendCustomTokenToApp, signOut } = useAuthentication();
  const { isAvailable, close } = useInAppBrowser();
  const [isReady, setReady] = useState(!isAvailable);

  useEffect(() => {
    const sendToken = async () => {
      try {
        await sendCustomTokenToApp();
        await signOut();
        close();
      } catch (err) {
        Sentry.captureException(err);
        if (isComponentMounted.current) {
          setReady(true);
        }
      }
    };

    if (!isReady) {
      sendToken();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isReady,
  ]);

  if (!isReady) {
    return <LoadingPage />;
  }

  return <Component {...props} />;
};

export default withRedirectToApp;
