import React, {
  useState,
} from 'react';
import PropTypes from 'prop-types';

import ClientTaggingModal from './components/ClientTaggingModal';

import {
  StyledButton,
  StyledAddIcon,
} from './styles';

const ManageClientTags = ({
  selectedClientList,
  assignedTags,
  buttonLabel,
}) => {
  const [showTagsModal, setShowTagsModal] = useState(false);

  return (
    <>
      <StyledButton onClick={() => setShowTagsModal(true)}>
        <StyledAddIcon />
        {buttonLabel}
      </StyledButton>
      {showTagsModal && (
        <ClientTaggingModal
          selectedClients={selectedClientList}
          showModal={showTagsModal}
          onClose={() => setShowTagsModal(false)}
          assignedTagsList={assignedTags}
        />
      )}
    </>
  );
};

ManageClientTags.propTypes = {
  selectedClientList: PropTypes.array.isRequired,
  assignedTags: PropTypes.array,
  buttonLabel: PropTypes.string.isRequired,
};

ManageClientTags.defaultProps = {
  assignedTags: [],
};

export default ManageClientTags;
