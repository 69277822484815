import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';

import { ReactComponent as AddCircleRoundedIcon } from '../../../../../../../../assets/icons/v2/add.svg';

import {
  StyledContainer,
  StyledTypography,
} from './styles';
import texts from './texts.json';

const CreationRow = memo(({ onClick, isModalView }) => (
  <StyledContainer onClick={onClick} $isModalView={isModalView}>
    <IconButton>
      <AddCircleRoundedIcon />
    </IconButton>
    <StyledTypography>
      {texts.creation}
    </StyledTypography>
  </StyledContainer>
));

CreationRow.propTypes = {
  onClick: PropTypes.func.isRequired,
  isModalView: PropTypes.bool,
};

CreationRow.defaultProps = {
  isModalView: false,
};
export default CreationRow;
