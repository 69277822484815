import React, {
  useCallback,
  useState,
} from 'react';
import UserTable from '../../components/UserTable';
import {
  Container,
  Title,
  Header,
  TableContainer,
  Description,
  ContentRow,
} from './styles';
import texts from './texts.json';

const ManageClientsDashboard = () => {
  const [selectedClients, setSelectedClients] = useState([]);

  const handleClientListUpdate = useCallback((clientId) => {
    setSelectedClients((prevSelectedClients) => {
      if (prevSelectedClients.includes(clientId)) {
        return prevSelectedClients.filter((id) => id !== clientId);
      }

      return [...prevSelectedClients, clientId];
    });
  }, [setSelectedClients]);

  return (
    <Container>
      <Header>
        <Title>{texts.manageClientsTitle}</Title>
        <ContentRow>
          <Description>{texts.manageClientsDescription}</Description>
        </ContentRow>
      </Header>
      <TableContainer>
        <UserTable setSelectedClients={handleClientListUpdate} selectedClients={selectedClients} />
      </TableContainer>
    </Container>
  );
};

export default ManageClientsDashboard;
