import styled from 'styled-components';
import {
  Box,
  Typography,
} from '@mui/material';
import colors from '../../../styles/colors';

const Text = styled(Typography)`
  margin-bottom: 5px;
`;
const Container = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  background-color: ${colors.shades.gamma5};
  position: relative;

  &:after {
    content: "";
    width: 100%;
    height: 50px;
    background-color: ${colors.shades.gamma5};
    position: absolute;
    top: 100%;
    left: 0;
  }
`;

const Title = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: ${colors.shades.beta2};
  margin-bottom: 30px;
`;

const Description = styled(Text)`
  font-size: 16px;
  margin-bottom: 15px;
`;

const TableContainer = styled(Box)`
  flex: 1;
  margin: 0 30px 30px;
  z-index: 1;
  background-color: ${colors.base.alpha};
  border-radius: 5px;
  border: 1px solid ${colors.shades.gamma5};
`;

const ContentRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export {
  Container,
  Header,
  Title,
  TableContainer,
  Description,
  ContentRow,
};
