import React, {
  useEffect,
  useState,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { useRouteMatch } from 'react-router-dom';
import { compose } from 'recompose';

import User from '../../Model/User';
import UserConfig from '../../Model/UserConfig';
import UserExposures from '../../Model/UserExposures';
import UserVariants from '../../Model/UserVariants';
import UserContext from './UserContext';

const UserContextProvider = ({
  children,
}) => {
  const { params: { userId } } = useRouteMatch();

  const [userDoc, setUserDoc] = useState(null);
  const [userConfigDoc, setUserConfigDoc] = useState(null);
  const [userExposuresDoc, setUserExposures] = useState(null);
  const [userVariantsDoc, setUserVariantsDoc] = useState(null);

  /*
    The readiness of the UserContext depends on the actual user docs being
    ready and also any extra initialization logic that might be needed that
    ensured that consumer of UserContext would consume a userDoc that has all
    the data they need to operate with the document, even if it includes adding
    initialization data on it.
  */
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    let shouldUpdate = true;

    const init = async () => {
      const userDocument = new User(`/user/${userId}`);
      const userConfigDocument = new UserConfig(`/config/${userId}`);
      const userExposuresDocument = new UserExposures(`/exposures/${userId}`);
      const userVariantsDocument = new UserVariants(`/variants/${userId}`);

      await Promise.all([
        userDocument.init(),
        userConfigDocument.init(),
        userExposuresDocument.init(),
        userVariantsDocument.init(),
      ]);

      if (shouldUpdate) {
        setUserDoc(userDocument);
        setUserConfigDoc(userConfigDocument);
        setUserExposures(userExposuresDocument);
        setUserVariantsDoc(userVariantsDocument);
        setIsReady(true);
      }
    };
    init();
    return () => {
      /* This cleanup function is used to prevent updating the state
      when the component is unmounted */
      shouldUpdate = false;
    };
  }, [
    userId,
  ]);

  const context = useMemo(() => ({
    userId,
    userDoc,
    userConfigDoc,
    userExposuresDoc,
    userVariantsDoc,
    isReady,
  }), [
    userId,
    userDoc,
    userConfigDoc,
    userExposuresDoc,
    userVariantsDoc,
    isReady,
  ]);

  return (
    <UserContext.Provider value={context}>
      {children}
    </UserContext.Provider>
  );
};

UserContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default compose(
  observer,
)(UserContextProvider);
