import { useEffect } from 'react';

import useLogger from './useLogger';

const useLogEventOnMount = (eventName) => {
  const { logEvent } = useLogger();
  useEffect(() => {
    logEvent(eventName);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useLogEventOnMount;
