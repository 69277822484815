import styled from 'styled-components';
import {
  IonHeader,
  IonLabel,
  IonTextarea,
} from '@ionic/react';
import { CloseOutlined } from '@mui/icons-material';
import { TextField, Tabs } from '@mui/material';

import Button from '../../../../components/Button';
import Modal from '../../../../components/Modal';
import { fontFamily } from '../../../../styles/text';
import colors from '../../../../styles/colors';

const Container = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

const TitleContainer = styled.div`
  font-family: ${fontFamily.serif};
  color: ${colors.base.alpha};
  flex: 1;
  font-weight: bold;
  font-size: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  padding: 0 15px;
`;

const StyledHeader = styled(IonHeader)`
  box-shadow: 0 7px 9px rgba(0, 0, 0, 0.03), 0 1px 0 rgba(0, 0, 0, 0.03);
  transform: matrix(1, 0, 0, 1, 0, 0);
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 60px;
  justify-self: flex-start;
  background: ${colors.base.secondary2};
  width: 100%;
`;

const ScheduleReminderButton = styled(Button)`
  flex: 1;
  overflow: auto;
  margin-top: 10px;
`;

const StyledListContainer = styled.div`
  max-height: 230px;
  overflow-y: scroll
`;

const StyledLabel = styled(IonLabel)`
  font-weight: bold;
`;

const CloseButton = styled(CloseOutlined)`
  height: 25px;
  margin-right: 20px;
`;

const ModalContent = styled.div`
  padding: 20px;
  max-height: 70vh;
  overflow-y: auto;
`;

/*
  This modal is using the IonModal component. Ion and MUI modals have different
  z-index values. This styled component changes the Ion modal z-index
  to use the MUI z-index for modals (1300) so we can use MUI components inside the modal
  https://mui.com/material-ui/customization/z-index/
*/
const StyledModal = styled(Modal)`
  z-index: 1300 !important;
`;

const StyledTextField = styled(TextField)`
  margin: 20px 0;
  width: 100%;
`;

const StyledTabs = styled(Tabs)`
  width: 100%;
  margin-bottom: 10px;
`;

const StyledTextarea = styled(IonTextarea)`
  max-height: 110px;
  --padding-start: 14px;
  border: 1px solid;
  border-radius: 5px;
`;

export {
  Container,
  StyledListContainer,
  ScheduleReminderButton,
  StyledLabel,
  CloseButton,
  StyledHeader,
  TitleContainer,
  ModalContent,
  StyledModal,
  StyledTextField,
  StyledTabs,
  StyledTextarea,
};
