import styled from 'styled-components';
import { Typography } from '@mui/material';
import colors from '../../../../../../styles/colors';

import { TitledActionContainerSection } from '../../../styles';

const StyledTitledActionContainerSection = styled(TitledActionContainerSection)`
  flex-direction: column;
  row-gap: 30px;
`;

const SettingContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 15px;
  ${({ $top }) => !$top && (`
    border-top: 1px solid ${colors.shades.secondary7};
    padding-top: 20px;
  `)};
`;

const StyledTypography = styled(Typography)`
  font-size: 11px;
  font-weight: 700;
  color: ${colors.shades.secondary3};
`;

export {
  StyledTitledActionContainerSection,
  SettingContainer,
  StyledTypography,
};
