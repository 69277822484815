import format from 'string-template';
import {
  AnalyticFrequency,
  AnalyticType,
  AnalyticUserType,
} from '../../../utils/analytics';
import Collections from '../../../utils/collections';
import MonthlyAnalytics from './MonthlyAnalytics';
import CoachRevenueData from './CoachRevenueData';

class CoachRevenueAnalytics extends MonthlyAnalytics {
  get revenues() {
    // for new analytics, we have revenues as an array. But for old analytics, we have data in the root object
    return Object.values(this.data.revenues || { data: this.data }).map((revenue) => new CoachRevenueData(revenue));
  }

  static getCollectionPath(coachId) {
    return format(Collections.ANALYTICS, {
      userType: AnalyticUserType.COACH,
      analyticType: AnalyticType.REVENUE,
      frequency: AnalyticFrequency.MONTHLY,
      userId: coachId,
    });
  }
}

export default CoachRevenueAnalytics;
