import React, {
  useState, useEffect, useContext, useMemo,
} from 'react';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import {
  Alert,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
} from '@mui/material';
import moment from 'moment';

import useComponentMounted from '../../../../hooks/useComponentMounted';
import { DateFormat } from '../../../../utils/date';
import AnalyticsGroup from '../components/AnalyticsGroup';
import MonthSelect from '../../../components/MonthSelect';
import CoachesListContext from '../../../context/CoachesListContext';
import CoachLeadAnalytics from '../../../Model/analytics/CoachLeadAnalytics';
import CoachSubscriptionsAnalytics from '../../../Model/analytics/CoachSubscriptionAnalytics';
import CoachRevenueAnalytics from '../../../Model/analytics/CoachRevenueAnalytics';
import LoadingOverlay from '../../../components/LoadingOverlay';
import CoachActiveUsersAnalytics from '../../../Model/analytics/CoachActiveUsersAnalytics';
import { formatCurrencyCents, formatNumber } from '../../../../utils/formatters';
import ExchangeRates from '../../../Model/ExchangeRates';
import DownloadCSVButton from '../../../components/DownloadCSVButton';
import {
  Container,
  FilterRow,
  Header,
  PageContent,
  StyledTableCell,
  StyledTableRow,
  TotalTableRow,
} from './styles';
import texts from './texts.json';

const CSV_HEADERS = [
  texts.columnNames.coachName,
  texts.columnNames.totalLeads,
  texts.columnNames.totalRevenue,
  texts.columnNames.s2Fee,
  texts.columnNames.newSubscriptions,
  texts.columnNames.cancellations,
  texts.columnNames.churnRate,
  texts.columnNames.activeUsers,
  texts.columnNames.avgLifespan,
];

const TOTAL = 'total';

const CompanyAnalytics = () => {
  const [selectedMonth, setSelectedMonth] = useState();
  const [leads, setLeads] = useState(new Map());
  const [revenue, setRevenue] = useState(new Map());
  const [subscriptions, setSubscriptions] = useState(new Map());
  const [activeUsers, setActiveUsers] = useState(new Map());
  const [churnRates, setChurnRates] = useState(new Map());
  const [lifespans, setLifespans] = useState(new Map());
  const [isLoading, setIsLoading] = useState(false);
  const [exchangeRates, setExchangeRates] = useState(null);
  const { coachesCollection: { docs: coachList } } = useContext(CoachesListContext);
  const isComponentMountedRef = useComponentMounted();

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      const coachLeads = new Map();
      const coachRevenue = new Map();
      const coachSubscriptions = new Map();
      const coachActiveUsers = new Map();
      const churnRate = new Map();
      const coachLifespan = new Map();
      const lastDayOfMonth = moment(selectedMonth.id, DateFormat.YEAR_MONTH_FORMAT)
        .endOf('month');
      // if the selected month is current month, use yesterday's date as we don't have data for today
      const lastDayOfMonthTxt = lastDayOfMonth.isSame(moment(), 'month')
        ? moment().subtract(1, 'days').format(DateFormat.DEFAULT_DATE_FORMAT)
        : lastDayOfMonth.format(DateFormat.DEFAULT_DATE_FORMAT);
      let startingClientCountTotal = 0;
      let totalCancellations = 0;
      const promises = coachList.map(async (coach) => {
        const coachLeadAnalytics = new CoachLeadAnalytics(
          CoachLeadAnalytics.getCollectionPath(coach.id),
          selectedMonth.id,
        );
        await coachLeadAnalytics.fetch();
        coachLeads.set(coach.id, coachLeadAnalytics);

        const coachRevenueAnalytics = new CoachRevenueAnalytics(
          CoachRevenueAnalytics.getCollectionPath(coach.id),
          selectedMonth.id,
        );
        await coachRevenueAnalytics.fetch();
        coachRevenue.set(coach.id, coachRevenueAnalytics);

        const coachSubscriptionsAnalytics = new CoachSubscriptionsAnalytics(
          CoachSubscriptionsAnalytics.getCollectionPath(coach.id),
          selectedMonth.id,
        );
        await coachSubscriptionsAnalytics.fetch();
        coachSubscriptions.set(coach.id, coachSubscriptionsAnalytics);

        const coachActiveUsersAnalytics = new CoachActiveUsersAnalytics(coach.id, lastDayOfMonthTxt);
        await coachActiveUsersAnalytics.fetch();
        coachActiveUsers.set(coach.id, coachActiveUsersAnalytics);

        // calculate churn rate and avg lifespan only if the selected month is not current month
        // as we don't have data for current month
        if (!moment().isSame(selectedMonth.id, 'day')) {
          const firstDayOfMonth = moment(selectedMonth.id, DateFormat.YEAR_MONTH_FORMAT)
            .startOf('month');
          const firstDayOfMonthTxt = firstDayOfMonth.format(DateFormat.DEFAULT_DATE_FORMAT);
          // get active users at the start of the month
          const coachActiveUsersAnalyticsStart = new CoachActiveUsersAnalytics(coach.id, firstDayOfMonthTxt);
          await coachActiveUsersAnalyticsStart.fetch();
          const { activeUsers: activeUsersInStart } = coachActiveUsersAnalyticsStart;
          // get cancellations for the month
          const cancelations = coachSubscriptionsAnalytics.cancellations;
          startingClientCountTotal += activeUsersInStart;
          totalCancellations += cancelations;
          // Churn rate = (Number of customers lost during the period) / (Starting customer count)
          churnRate.set(coach.id, activeUsersInStart > 0
            ? formatNumber(cancelations / activeUsersInStart, { style: 'percent', minimumFractionDigits: 2 })
            : 'N/A');
          coachLifespan.set(coach.id, cancelations > 0
            ? formatNumber(activeUsersInStart / cancelations, { minimumFractionDigits: 2 })
            : 'N/A');
        } else {
          churnRate.set(coach.id, 'N/A');
          coachLifespan.set(coach.id, 'N/A');
        }
      });
      await Promise.all(promises);
      churnRate.set(TOTAL, startingClientCountTotal > 0
        ? formatNumber(totalCancellations / startingClientCountTotal, { style: 'percent', minimumFractionDigits: 2 })
        : 'N/A');
      coachLifespan.set(TOTAL, totalCancellations > 0
        ? formatNumber(startingClientCountTotal / totalCancellations, { minimumFractionDigits: 2 })
        : 'N/A');

      const exRates = await ExchangeRates.getExchangeRatesByMonth(selectedMonth.id);

      if (isComponentMountedRef.current) {
        setExchangeRates(exRates);
        setLeads(coachLeads);
        setRevenue(coachRevenue);
        setSubscriptions(coachSubscriptions);
        setActiveUsers(coachActiveUsers);
        setChurnRates(churnRate);
        setLifespans(coachLifespan);
        setIsLoading(false);
      }
    };
    if (selectedMonth && isComponentMountedRef.current) {
      loadData();
    }
  }, [
    coachList,
    isComponentMountedRef,
    selectedMonth,
  ]);

  // sort coaches by revenue
  const sortedCoachList = useMemo(() => {
    if (coachList.length > 0) {
      return coachList.sort((a, b) => {
        const aRevenue = Math.max(...revenue.get(a.id)?.revenues.map((rev) => rev.revenueAmt) || [0]);
        const bRevenue = Math.max(...revenue.get(b.id)?.revenues.map((rev) => rev.revenueAmt) || [0]);
        if (aRevenue > bRevenue) {
          return -1;
        }
        if (aRevenue < bRevenue) {
          return 1;
        }
        return 0;
      });
    }
    return coachList;
  }, [coachList, revenue]);

  const totalCounts = useMemo(() => {
    const totalLeads = Array.from(leads.values()).reduce((acc, lead) => acc + lead.total, 0);
    const { totalRevenue, totalS2Fee } = Array.from(revenue.values())
      .reduce((acc, rev) => {
        rev.revenues.forEach((r) => {
          if (r.currency) {
            if (!acc.totalRevenue[r.currency]) {
              acc.totalRevenue[r.currency] = 0;
              acc.totalS2Fee[r.currency] = 0;
            }
            acc.totalRevenue[r.currency] += r.revenue;
            acc.totalS2Fee[r.currency] += r.applicationFee;
          }
        });
        return acc;
      }, { totalS2Fee: {}, totalRevenue: {} });
    const totalNewSubscriptions = Array.from(subscriptions.values())
      .reduce((acc, sub) => acc + sub.newSubscriptions, 0);
    const totalCancellations = Array.from(subscriptions.values())
      .reduce((acc, sub) => acc + sub.cancellations, 0);
    const totalActiveUsers = Array.from(activeUsers.values())
      .reduce((acc, sub) => acc + sub.activeUsers, 0);
    return {
      totalLeads,
      totalRevenue,
      totalS2Fee,
      totalNewSubscriptions,
      totalCancellations,
      totalActiveUsers,
    };
  }, [
    activeUsers,
    leads,
    revenue,
    subscriptions,
  ]);

  const tableData = useMemo(() => (
    sortedCoachList.map((coach) => ({
      [texts.columnNames.coachName]: coach.name,
      [texts.columnNames.totalLeads]: leads.get(coach.id)?.total,
      [texts.columnNames.totalRevenue]: formatCurrencyCents(revenue.get(coach.id)?.revenues?.reduce(
        (acc, revenueItem) => acc + exchangeRates?.getUsdAmount(revenueItem.revenue, revenueItem.currency), 0,
      ), 'USD', { minimumFractionDigits: 2 }),
      [texts.columnNames.s2Fee]: formatCurrencyCents(revenue.get(coach.id)?.revenues?.reduce(
        (acc, revenueItem) => acc + exchangeRates?.getUsdAmount(revenueItem.applicationFee, revenueItem.currency), 0,
      ), 'USD', { minimumFractionDigits: 2 }),
      [texts.columnNames.newSubscriptions]: subscriptions.get(coach.id)?.newSubscriptions,
      [texts.columnNames.activeUsers]: activeUsers.get(coach.id)?.activeUsers,
      [texts.columnNames.cancellations]: subscriptions.get(coach.id)?.cancellations,
      [texts.columnNames.churnRate]: churnRates.get(coach.id),
      [texts.columnNames.avgLifespan]: lifespans.get(coach.id),
      id: coach.id,
      totalRevenueTooltip: revenue.get(coach.id)?.revenues?.map((revenueItem) => revenueItem.revenueWithCurrency)
        .join(' | '),
      s2FeeTooltip: revenue.get(coach.id)?.revenues?.map(
        (revenueItem) => revenueItem.applicationFeeWithCurrency,
      ).join(' | '),
    }))
  ), [
    activeUsers,
    churnRates,
    exchangeRates,
    leads,
    lifespans,
    revenue,
    sortedCoachList,
    subscriptions,
  ]);

  const totalRow = useMemo(() => (
    {
      [texts.columnNames.coachName]: texts.total,
      [texts.columnNames.totalLeads]: totalCounts.totalLeads,
      totalRevenueTooltip: `${Object.entries(totalCounts.totalRevenue)
        .map(
          ([currency, amount]) => formatCurrencyCents(amount, currency, { minimumFractionDigits: 2 }),
        ).join(' | ')}`,
      [texts.columnNames.totalRevenue]: formatCurrencyCents(
        Object.entries(totalCounts.totalRevenue)
          .reduce((acc, [currency, amount]) => acc
            + exchangeRates?.getUsdAmount(amount, currency), 0),
        'USD', { minimumFractionDigits: 2 },
      ),
      totalS2FeeTooltip: `${Object.entries(totalCounts.totalS2Fee)
        .map(
          ([currency, amount]) => formatCurrencyCents(amount, currency, { minimumFractionDigits: 2 }),
        ).join(' | ')}  `,
      [texts.columnNames.s2Fee]: formatCurrencyCents(Object.entries(totalCounts.totalS2Fee)
        .reduce((acc, [currency, amount]) => acc
        + exchangeRates?.getUsdAmount(amount, currency), 0), 'USD', { minimumFractionDigits: 2 }),
      [texts.columnNames.newSubscriptions]: totalCounts.totalNewSubscriptions,
      [texts.columnNames.activeUsers]: totalCounts.totalActiveUsers,
      [texts.columnNames.cancellations]: totalCounts.totalCancellations,
      [texts.columnNames.churnRate]: churnRates.get(TOTAL),
      [texts.columnNames.avgLifespan]: lifespans.get(TOTAL),
    }
  ), [
    churnRates,
    exchangeRates,
    lifespans,
    totalCounts,
  ]);

  return (
    <Container>
      <Header>
        <FilterRow>
          <MonthSelect onChange={setSelectedMonth} />
          {!!selectedMonth && (
            <>
              <Alert severity="warning">
                {texts.conversionWarning}
              </Alert>

              <DownloadCSVButton
                csvHeaders={CSV_HEADERS}
                rows={[totalRow, ...tableData]}
                fileName={`Company Analytics - ${selectedMonth.id}`}
                disabled={!tableData.length && !totalRow}
              />
            </>
          )}
        </FilterRow>
      </Header>
      <PageContent>
        {selectedMonth && (
          <AnalyticsGroup>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>{texts.columnNames.coachName}</StyledTableCell>
                    <StyledTableCell>{texts.columnNames.totalLeads}</StyledTableCell>
                    <StyledTableCell>{texts.columnNames.totalRevenue}</StyledTableCell>
                    <StyledTableCell>{texts.columnNames.s2Fee}</StyledTableCell>
                    <StyledTableCell>{texts.columnNames.newSubscriptions}</StyledTableCell>
                    <StyledTableCell>{texts.columnNames.activeUsers}</StyledTableCell>
                    <StyledTableCell>{texts.columnNames.cancellations}</StyledTableCell>
                    <StyledTableCell>{texts.columnNames.churnRate}</StyledTableCell>
                    <StyledTableCell>{texts.columnNames.avgLifespan}</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TotalTableRow>
                    <TableCell variant="head">{totalRow[texts.columnNames.coachName]}</TableCell>
                    <TableCell variant="head">{totalRow[texts.columnNames.totalLeads]}</TableCell>
                    {/* show tooltip of revenue currency breakdown */}
                    <Tooltip
                      followCursor
                      title={totalRow.totalRevenueTooltip}
                    >
                      <TableCell variant="head">{totalRow[texts.columnNames.totalRevenue]}</TableCell>
                    </Tooltip>
                    <Tooltip
                      followCursor
                      title={totalRow.totalS2FeeTooltip}
                    >
                      <TableCell variant="head">{totalRow[texts.columnNames.s2Fee]}</TableCell>
                    </Tooltip>
                    <TableCell variant="head">{totalRow[texts.columnNames.newSubscriptions]}</TableCell>
                    <TableCell variant="head">{totalRow[texts.columnNames.activeUsers]}</TableCell>
                    <TableCell variant="head">{totalRow[texts.columnNames.cancellations]}</TableCell>
                    <TableCell variant="head">{totalRow[texts.columnNames.churnRate]}</TableCell>
                    <TableCell variant="head">{totalRow[texts.columnNames.avgLifespan]}</TableCell>
                  </TotalTableRow>
                  {tableData.map((coach) => (
                    <StyledTableRow key={coach.id}>
                      <TableCell>{coach[texts.columnNames.coachName]}</TableCell>
                      <TableCell>{coach[texts.columnNames.totalLeads]}</TableCell>
                      <Tooltip
                        followCursor
                        title={coach.totalRevenueTooltip}
                      >
                        <TableCell>{coach[texts.columnNames.totalRevenue]}</TableCell>
                      </Tooltip>
                      <Tooltip
                        followCursor
                        title={coach.s2FeeTooltip}
                      >
                        <TableCell>{coach[texts.columnNames.s2Fee]}</TableCell>
                      </Tooltip>
                      <TableCell>{coach[texts.columnNames.newSubscriptions]}</TableCell>
                      <TableCell>{coach[texts.columnNames.activeUsers]}</TableCell>
                      <TableCell>{coach[texts.columnNames.cancellations]}</TableCell>
                      <TableCell>{coach[texts.columnNames.churnRate]}</TableCell>
                      <TableCell>{coach[texts.columnNames.avgLifespan]}</TableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </AnalyticsGroup>
        )}
      </PageContent>
      <LoadingOverlay isLoading={isLoading} />
    </Container>
  );
};

export default compose(
  observer,
)(CompanyAnalytics);
