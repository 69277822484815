import styled from 'styled-components';
import { Box } from '@mui/material';

import Tabs from '../../components/Tabs';
import { StyledTab as Tab } from '../../components/Tabs/styles';

import colors from '../../../styles/colors';

const Container = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${colors.shades.gamma5};
  position: relative;
`;

const StyledTabs = styled(Tabs)`
  border-bottom: unset;
  min-height: unset;

  ${Tab} {
    border-radius: unset;
    min-width: unset;
    padding: 20px 5px;
    column-gap: 5px;
    background-color: ${colors.shades.gamma5};

    &:not(:first-child) {
      margin-left: 15px;
    }

    &.Mui-selected {
      color: unset;
      background-color: unset;
      box-shadow: inset 0 -3px 0 ${colors.shades.secondary3};
      &::before {
        content: none;
      }
    }
  }
`;

const TableTabContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  margin: 10px 30px;
  height: calc(100% - 85px);
  z-index: 1;
  background-color: ${colors.base.alpha};
`;

export {
  Container,
  Header,
  StyledTabs,
  TableTabContainer,
};
