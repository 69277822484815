import styled from 'styled-components';

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 50px 25px 50px;
`;

const DateInputContainer = styled.div`
  margin-top: 20px;
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
`;

export {
  ModalContainer,
  DateInputContainer,
  ButtonContainer,
};
