import styled from 'styled-components';

import Select from '../../../../components/Select';

const StyledSelect = styled(Select)`
  min-width: unset;

  .MuiSelect-select {
    border: none;
  }
`;

export {
  StyledSelect,
};
