import React, {
  useEffect,
  useState,
  useMemo,
  useContext,
  useCallback,
} from 'react';
import { useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { observer } from 'mobx-react';

import { isCoachRoute } from '../../../utils/routes';
import useComponentMounted from '../../../hooks/useComponentMounted';
import LoggedInUserContext from '../../../context/LoggedInUserContext';
import ExternalCoach from '../../../Model/ExternalCoach';
import ExternalCoachPrivate from '../../../Model/ExternalCoachPrivate';
import Coach from '../../../Model/Coach';
import { firestorePaths } from '../../../utils/firebasePaths';
import ExternalCoachContext, { initialValues } from './ExternalCoachContext';

const ExternalCoachContextProvider = ({
  children,
}) => {
  const {
    path,
    params: {
      userId,
    },
  } = useRouteMatch();
  const isCoachUrl = isCoachRoute(path);

  const [externalCoachDoc, setExternalCoachDoc] = useState(initialValues.externalCoachDoc);
  const [externalCoachPrivateDoc, setExternalCoachPrivateDoc] = useState(initialValues.externalCoachPrivateDoc);
  const [coachDoc, setCoachDoc] = useState(initialValues.coachDoc);
  const [products, setProducts] = useState(initialValues.products);
  const [plans, setPlans] = useState(initialValues.plans);
  const [isReady, setIsReady] = useState(initialValues.isReady);
  const [isProductsReady, setIsProductsReady] = useState(initialValues.isProductsReady);
  const [currentCoachIds, setCurrentCoachIds] = useState(isCoachUrl ? [userId] : []);
  const [coachId, setCoachId] = useState(isCoachUrl ? userId : null);
  const isComponentMountedRef = useComponentMounted();

  const {
    userDoc: {
      isInsideSales,
    },
  } = useContext(LoggedInUserContext);

  // If the userId changes, then it means we are loading a new coach from the url
  useEffect(() => {
    if (userId) {
      setCoachId(userId);
    }
  }, [userId]);

  useEffect(() => {
    const init = async (id) => {
      const externalCoach = new ExternalCoach(id);
      const externalCoachPrivate = new ExternalCoachPrivate(id);
      const coach = new Coach(`${firestorePaths.COACH}/${id}`);

      await Promise.all([
        externalCoach.init(),
        externalCoachPrivate.init(),
        coach.init(),
      ]);

      if (isComponentMountedRef.current) {
        setExternalCoachDoc(externalCoach);
        setExternalCoachPrivateDoc(externalCoachPrivate);
        setCoachDoc(coach);
        setIsReady(true);
        setCurrentCoachIds([id]);
      }
      // we need to load the products and plans for the coach separately
      // because we don't need to wait for products and plans most of the time
      const externalCoachPlans = await externalCoach.getAllPlans();
      const externalCoachProducts = await externalCoach.getProducts();
      if (isComponentMountedRef.current) {
        setPlans(externalCoachPlans);
        setProducts(externalCoachProducts);
        setIsProductsReady(true);
      }
    };

    if (!isReady) {
      if (isCoachUrl) {
        // Regular coaches are loading this context. We only load the data for the current coach.
        init(coachId);
      } else if (currentCoachIds?.length === 1 && currentCoachIds[0] !== coachId) {
        // We are loading a single coach using the api. We need to use the id saved in the context state.
        init(currentCoachIds[0]);
      } else {
        // If we don't need to load a specific coach, then we can set the context as ready.
        setIsReady(true);
      }
    }
  }, [
    coachId,
    currentCoachIds,
    isCoachUrl,
    isComponentMountedRef,
    isInsideSales,
    isReady,
  ]);

  const resetContext = useCallback(() => {
    setExternalCoachDoc(initialValues.externalCoachDoc);
    setExternalCoachPrivateDoc(initialValues.externalCoachPrivateDoc);
    setCoachDoc(initialValues.coachDoc);
    setProducts(initialValues.products);
    setPlans(initialValues.plans);
    setIsReady(initialValues.isReady);
    setCurrentCoachIds(isCoachUrl ? [userId] : []);
    setCoachId(isCoachUrl ? userId : null);
  }, [
    isCoachUrl,
    userId,
  ]);

  /*
    Allow to load coach data by providing a coach id. This will override whatever this context had stored previously.
    If no coachId is provided, then we reload the data driven by the userId in the URL.
  */
  const loadNewCoachData = useCallback((coachIds) => {
    resetContext();
    setCurrentCoachIds(coachIds ?? [userId]);
  }, [
    userId,
    resetContext,
  ]);
  const loadCoachProducts = useCallback(async () => {
    const externalCoach = new ExternalCoach(coachId);
    const externalCoachProducts = await externalCoach.getProducts();
    if (isComponentMountedRef.current) {
      setProducts(externalCoachProducts);
    }
  }, [coachId, isComponentMountedRef]);

  const contextValue = useMemo(() => ({
    externalCoachDoc,
    externalCoachPrivateDoc,
    coachDoc,
    plans,
    isReady,
    isProductsReady,
    products,
    coachIds: currentCoachIds,
    loadNewCoachData,
    loadCoachProducts,
  }), [
    externalCoachDoc,
    externalCoachPrivateDoc,
    coachDoc,
    plans,
    isReady,
    isProductsReady,
    products,
    currentCoachIds,
    loadNewCoachData,
    loadCoachProducts,
  ]);

  return (
    <ExternalCoachContext.Provider value={contextValue}>
      {children}
    </ExternalCoachContext.Provider>
  );
};

ExternalCoachContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default compose(
  observer,
)(ExternalCoachContextProvider);
