import styled from 'styled-components';

import Select from '../../../../../../components/Select';
import colors from '../../../../../../styles/colors';

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
`;

const StyledSelect = styled(Select)`
  width: 100%;
  .MuiSelect-select {
    border-color: ${colors.shades.secondary7};
    color: ${colors.shades.secondary8};
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
  }
`;

export {
  InputContainer,
  StyledSelect,
};
