import styled from 'styled-components';

import { ReactComponent as ArchiveIcon } from '../../../../../../assets/icons/v2/archive.svg';
import colors from '../../../../../../styles/colors';

const ActiveStatus = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid ${({ color }) => (color || colors.shades.success1)};
  background-color: ${({ fill }) => (fill || colors.shades.success1)};
`;

const InactiveStatus = styled(ActiveStatus)`
  border-color: ${({ color }) => (color || colors.shades.danger1)};
  background-color: ${({ fill }) => (fill || colors.shades.danger1)};
`;

const ActionButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 10px 0;
  column-gap: 5px;
`;

const StyledArchiveIcon = styled(ArchiveIcon)`
  width: 16px;
`;

const TagList = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 3px;
`;

const NoMealPlansMessage = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MealTimesCell = styled.div`
  display: flex;
  width: 100%;
  text-decoration: underline;
  cursor: help;
`;

export {
  ActiveStatus,
  InactiveStatus,
  ActionButtonContainer,
  StyledArchiveIcon,
  TagList,
  NoMealPlansMessage,
  MealTimesCell,
};
