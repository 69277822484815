import React from 'react';
import PropTypes from 'prop-types';

import { Route } from 'react-router-dom';

import ActiveRoute from './ActiveRoute';

const ToolsRoute = ({
  path,
  component: RenderComponent,
  pageId,
  props,
}) => {
  const onRender = () => (
    <ActiveRoute pageId={pageId}>
      <RenderComponent {...props} />
    </ActiveRoute>
  );

  return (
    <Route
      path={path}
      render={onRender}
    />
  );
};

ToolsRoute.propTypes = {
  path: PropTypes.string.isRequired,
  component: PropTypes.elementType.isRequired,
  pageId: PropTypes.string.isRequired,
  props: PropTypes.object,
};

ToolsRoute.defaultProps = {
  props: {},
};

export default ToolsRoute;
