import React, {
  useContext,
  useMemo,
} from 'react';
import { observer } from 'mobx-react';
import { compose } from 'recompose';

import { ContractProperties } from '../../../../utils/userContract';
import CoachesListContext, {
  withCoachesListContextReady,
} from '../../../../context/CoachesListContext';
import InternalAssignmentsContext, {
  withInternalAssignmentsContextProvider,
  withInternalAssignmentsContextReady,
} from '../../../../context/InternalAssignmentsContext';
import UserSelect from '../../../../pages/Analytics/components/UserSelect';
import CoachSelect from '../../../Leads/components/CoachSelect';
import ContractModalContext from '../../context/ContractModalContext';
import { InputLabel } from '../../Input';

import {
  HeaderContainer,
  HeaderRow,
  FormField,
  Title,
  StyledInput,
} from './styles';
import texts from './texts.json';

const ReusableContractModalHeader = () => {
  const {
    coachId,
    coachDoc: coachDocFromContext,
    setCoachId,
    contract,
    onContractFieldChange,
  } = useContext(ContractModalContext);

  const {
    coachesCollection,
  } = useContext(CoachesListContext);

  const { isUsers } = useContext(InternalAssignmentsContext);

  const sellerOptions = useMemo(() => {
    // We should add the selected coach as a seller option
    if (coachId) {
      const coachDoc = coachesCollection.docs.find(({ id }) => id === coachId);
      if (coachDoc) {
        const { id, name } = coachDoc;
        return [{ userName: name, userId: id }, ...isUsers];
      }
    }
    return isUsers;
  }, [
    coachesCollection,
    isUsers,
    coachId,
  ]);

  const selectedSeller = useMemo(() => {
    if (contract.sellerId) {
      const seller = sellerOptions.find(({ userId }) => userId === contract.sellerId);
      return {
        id: seller.userId,
        label: seller.userName,
      };
    }
    return null;
  }, [
    contract,
    sellerOptions,
  ]);

  const handleCoachChange = (value) => {
    setCoachId(value?.id || '');
    /*
      Unset selected seller id when coach changes, this is because the selected
      coach is shown as a seller option.
    */
    onContractFieldChange(ContractProperties.SELLER_ID, '');
  };

  return (
    <HeaderContainer>
      <HeaderRow>
        <Title>{texts.reusableContractCreation}</Title>
      </HeaderRow>
      <HeaderRow>
        <FormField>
          <StyledInput
            size="small"
            name={ContractProperties.NAME}
            label={texts.contractName}
            value={contract.name}
            onChange={(e) => onContractFieldChange(ContractProperties.NAME, e.target.value)}
            fullWidth
          />
        </FormField>
        <FormField>
          <InputLabel>{texts.selectCoach}</InputLabel>
          <CoachSelect
            preselectedCoach={
              coachDocFromContext?.id && { id: coachDocFromContext.id, label: coachDocFromContext.data.name }
            }
            onChange={handleCoachChange}
            loadNewCoachDataOnChange={false}
            hideLabel
          />
        </FormField>
        <FormField>
          <InputLabel>{texts.assignSeller}</InputLabel>
          <UserSelect
            selectedUser={selectedSeller}
            userList={sellerOptions}
            onChange={(user) => onContractFieldChange(ContractProperties.SELLER_ID, user?.id || '')}
            inputLabel=""
          />
        </FormField>
      </HeaderRow>
    </HeaderContainer>
  );
};

ReusableContractModalHeader.propTypes = {};

export default compose(
  withInternalAssignmentsContextProvider,
  withInternalAssignmentsContextReady,
  withCoachesListContextReady,
  observer,
)(ReusableContractModalHeader);
