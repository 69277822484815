import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import format from 'string-template';
import moment from 'moment';
import { observer } from 'mobx-react';
import { compose } from 'recompose';

import { DateFormat } from '../../utils/date';
import CollectionName from '../../utils/collections';
import { openFirestoreDocument } from '../../utils/support';
import UserContract from '../../Model/UserContract';
import useComponentMounted from '../../hooks/useComponentMounted';
import useSessionStore from '../../hooks/useSessionStore';
import ContractDetails from '../ContractDetails';
import Accordion from '../Accordion';

import {
  Container,
  ContractInfoKey,
  ContractInfoLine,
  ContractInfoValue,
  StyledOnCallActions,
} from './styles';
import texts from './texts.json';

const MONTHLY_SUBSCRIPTION = 1;

const ContractHistory = ({
  userId,
}) => {
  const [isReady, setIsReady] = useState(false);
  const [contracts, setContracts] = useState({});

  const isComponentMountedRef = useComponentMounted();
  const { isOnCallUser } = useSessionStore();

  useEffect(() => {
    const fetchContracts = async () => {
      const contractsCollection = await UserContract.getContractsByUserId(userId);

      if (isComponentMountedRef.current) {
        setContracts(contractsCollection);
        setIsReady(true);
      }
    };

    if (!isReady && userId) {
      fetchContracts();
    }
  }, [
    userId,
    isReady,
    isComponentMountedRef,
  ]);

  const renderContractInfo = (contract) => {
    /*
      We have to determine the actual startDate to use. If the contract has a subscriptionCreatedAt property, it means
      the subscription already started. Otherwise, we use the startDate property, which will point to the start date
      of future startDates.
    */
    const processedStartDate = contract.subscriptionCreatedAt || contract.startDate;

    /*
      The info lines are extra lines we render at the top of the contract accordion. We show multiple properties like
      the startDate, the initial payment and the monthly payment.
    */
    const infoLines = [
      {
        key: texts.info.since.key,
        value: moment(processedStartDate).format(DateFormat.TEXT_DATE_FORMAT),
      },
      {
        key: texts.info.initialPayment.key,
        value: format(texts.info.initialPayment.value, {
          amount: contract.initialPaymentInCentsStr,
          extra: contract.initialTerm > MONTHLY_SUBSCRIPTION ? format(texts.pif, { months: contract.initialTerm }) : '',
        }),
      },
      {
        key: texts.info.monthlyPayment.key,
        value: format(texts.info.monthlyPayment.value, {
          amount: contract.totalPriceInCentsStr,
          extra: format(texts.monthlyStart, {
            date: moment(processedStartDate).add(contract.initialTerm, 'months').format(DateFormat.TEXT_DATE_FORMAT),
          }),
        }),
      },
    ];

    return (
      <>
        {infoLines.map((line) => (
          <ContractInfoLine key={line.key}>
            <ContractInfoKey>{`${line.key}:`}</ContractInfoKey>
            <ContractInfoValue>{line.value}</ContractInfoValue>
          </ContractInfoLine>
        ))}
      </>
    );
  };

  // Let's sort the contracts by creation date, so we can show the most recent contracts first.
  const sortedContracts = contracts.docs?.slice().sort((a, b) => b.createdAt - a.createdAt) || [];

  return (
    <Container>
      {sortedContracts.map((contract) => {
        const title = contract.current
          ? format(texts.currentContract, { status: contract.status.toUpperCase() })
          : texts.expiredContract;

        return (
          <Accordion
            key={contract.id}
            title={title}
            defaultExpanded={contract.current}
          >
            {renderContractInfo(contract)}
            <ContractDetails
              contractTerms={contract.terms}
              coachConditions={contract.coachConditions}
              userId={contract.id}
            />
            {isOnCallUser && (
              <StyledOnCallActions
                actions={[
                  {
                    onClick: () => openFirestoreDocument(`${CollectionName.USER_CONTRACT}/${contract.id}`),
                    disabled: !contract.id,
                    label: texts.support.contractDoc,
                  },
                ]}
              />
            )}
          </Accordion>
        );
      })}
    </Container>
  );
};

ContractHistory.propTypes = {
  userId: PropTypes.string,
};

ContractHistory.defaultProps = {
  userId: '',
};

export default compose(
  observer,
)(ContractHistory);
