import * as Yup from 'yup';

import fieldName from './formFields';
import texts from './texts';

const initialValues = (circuitData) => ({
  [fieldName.NAME]: circuitData?.name || '',
  [fieldName.DESCRIPTION]: circuitData?.note || '',
  [fieldName.SET]: circuitData?.rounds || '',
});

const validationSchema = Yup.object().shape({
  [fieldName.NAME]: Yup.string().required(texts.validation.requiredField),
  [fieldName.SET]: Yup.number()
    .integer(texts.validation.integerNumber)
    .positive(texts.validation.positiveNumber)
    .required(texts.validation.requiredField),
});

export {
  initialValues,
  validationSchema,
};
