import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  column-gap: 5px;
`;

export {
  Container,
};
