import styled from 'styled-components';

const Container = styled.div`
  flex: 1;
  padding: 16px;
`;

export {
  Container,
};
