import React, {
  useMemo,
} from 'react';
import PropTypes from 'prop-types';

import { LeadState, FinalStatusSalesStages } from '../../../../Model/Lead';
import statesTexts from '../../states.json';
import salesStageTexts from '../../salesStages.json';
import texts from './texts.json';
import { StyledFilter } from './styles';

const StateFilter = ({
  onSelect,
  initialStates,
  isISView,
}) => {
  const leadOptionValues = useMemo(() => (
    Object
      .values(LeadState)
      .map((state) => ({
        value: state,
        label: statesTexts[state],
      }))
  ), []);

  const salesStageOptionValues = useMemo(() => (
    FinalStatusSalesStages.map((state) => ({
      value: state,
      label: salesStageTexts[state],
    }))
  ), []);

  return (
    <StyledFilter
      title={texts.title}
      options={isISView ? salesStageOptionValues : leadOptionValues}
      initialValues={initialStates}
      onValuesSelected={onSelect}
    />
  );
};

StateFilter.propTypes = {
  onSelect: PropTypes.func.isRequired,
  initialStates: PropTypes.array.isRequired,
  isISView: PropTypes.bool,
};

StateFilter.defaultProps = {
  isISView: false,
};

export default StateFilter;
