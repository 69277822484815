import styled from 'styled-components';

const ActivityFeedContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  height: 100%;
`;

export {
  ActivityFeedContainer,
};
