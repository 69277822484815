import {
  useCallback,
  useMemo,
} from 'react';

import useNavigation from '../../hooks/useNavigation';
import {
  MealPlannerType,
  getTabPath,
} from '../routes/mealPlanner/mealPlannerPages';
import pages, { pagesConfig } from '../config/pages';

const useMealPlanNavigation = () => {
  const { navigateTo } = useNavigation();

  // As this is the default tab, its convenient to have a navigate function for it
  const navigateToAssignments = useCallback((options = {}) => (
    navigateTo(getTabPath(MealPlannerType.CLIENT_MEAL_ASSIGNMENTS), options)
  ), [
    navigateTo,
  ]);

  const navigateToRefreshPage = useCallback((refreshUser, absolute = false, options = {}) => {
    // Append the 'mealPlanner' prefix to the URL if we want the absolute path
    const prefix = absolute ? `${pagesConfig[pages.MEAL_PLANNER].path}/` : '';
    navigateTo(`${prefix}${getTabPath(MealPlannerType.MEAL_PLAN_REFRESH)}`, {
      ...options,
      search: `user=${refreshUser}`,
    });
  }, [
    navigateTo,
  ]);

  const navigateToClientAssignmentPage = useCallback((assignmentUser, options = {}) => (
    navigateTo(getTabPath(MealPlannerType.CLIENT_MEAL_ASSIGNMENT), {
      ...options,
      search: `user=${assignmentUser}`,
    })
  ), [
    navigateTo,
  ]);

  const navigateToNewAssignmentPage = useCallback((assignmentUser, absolute = false, options = {}) => {
    // Append the 'mealPlanner' prefix to the URL if we want the absolute path
    const prefix = absolute ? `${pagesConfig[pages.MEAL_PLANNER].path}/` : '';
    navigateTo(`${prefix}${getTabPath(MealPlannerType.MEAL_PLAN_ASSIGNMENT)}`, {
      ...options,
      search: `user=${assignmentUser}`,
    });
  }, [
    navigateTo,
  ]);

  const navigateToTab = useCallback((tab, options = {}) => (
    navigateTo(getTabPath(tab), options)
  ), [
    navigateTo,
  ]);

  return useMemo(() => ({
    navigateToAssignments,
    navigateToClientAssignmentPage,
    navigateToTab,
    navigateToRefreshPage,
    navigateToNewAssignmentPage,
  }), [
    navigateToAssignments,
    navigateToClientAssignmentPage,
    navigateToTab,
    navigateToRefreshPage,
    navigateToNewAssignmentPage,
  ]);
};

export default useMealPlanNavigation;
