import React from 'react';
import { quickAccessTabs } from '../../CoachTools/components/ClientQuickInfoModal/utils';

const initialValues = {
  isOpen: false,
  selectedUserId: null,
  openQAP: () => {},
  closeQAP: () => {},
  selectedTab: quickAccessTabs.NOTES,
};

const QAPContext = React.createContext(initialValues);

export default QAPContext;
export {
  initialValues,
};
