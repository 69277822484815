import texts from './texts.json';

const getColumnsConfig = (payoutsConfig) => [
  {
    field: 'coach',
    quickSearch: true,
    headerName: texts.headers.coach,
    flex: 20,
  },
  {
    field: 'coachName',
    quickSearch: true,
    headerName: texts.headers.coachName,
    flex: 20,
  },
  {
    field: 'user',
    quickSearch: true,
    headerName: texts.headers.user,
    flex: 20,

  },
  {
    field: 'userName',
    quickSearch: true,
    headerName: texts.headers.userName,
    flex: 20,
  },
  {
    field: 'startDate',
    headerName: texts.headers.startDate,
    flex: 15,
  },
  {
    field: 'contractId',
    quickSearch: true,
    headerName: texts.headers.contractId,
    flex: 20,
  },
  {
    field: 'payoutDate',
    headerName: texts.headers.payoutDate,
    flex: 15,
  },
  {
    field: 'reportingCategory',
    headerName: texts.headers.reportingCategory,
    flex: 15,
  },
  {
    field: 'connectedAccountDirectChargeId',
    headerName: texts.headers.chargeId,
    flex: 15,
  },
  {
    field: 'netAmountWithCurrency',
    headerName: texts.headers.netAmount,
    flex: 15,
  },
  {
    field: 'feesConfigurationString',
    headerName: texts.headers.feesConfiguration,
    flex: 20,
  },
  {
    field: 'totalCommissionableFeePercentage',
    headerName: texts.headers.totalCommissionableFeePercentage,
    flex: 15,
  },
  {
    field: 'commissionableFeeAmount',
    headerName: texts.headers.commissionableFeeAmount,
    flex: 15,
  },
  {
    field: payoutsConfig.commissionNetAmount,
    headerName: texts.headers.commission,
  },
  {
    field: payoutsConfig.commissionNetAmountWithCurrency,
    headerName: texts.headers.commission,
    flex: 15,
  },
  {
    field: 'recurringSubscription',
    headerName: texts.headers.recurringSubscription,
    flex: 15,
  },
  {
    field: 'feesConfigurationObject',
    headerName: texts.headers.feesConfigurationObject,
  },
];

export { getColumnsConfig };
