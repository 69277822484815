import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
} from '@mui/material';

import { validationSchema } from './validation';
import {
  StyledModal,
  Container,
  Title,
  StyledTextField,
} from './styles';
import texts from './texts.json';

const NewCoachModal = ({
  onAddNewCoach,
  ...modalProps
}) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({});

  const onSave = useCallback(() => {
    try {
      validationSchema.validateSync({ name, email }, { abortEarly: false });
      setErrors({});
      onAddNewCoach(email, name);
    } catch (err) {
      const errorMessages = err.inner.reduce((messages, errorObj) => ({
        ...messages,
        [errorObj.path]: errorObj.message,
      }), {});
      setErrors(errorMessages);
    }
  }, [
    name,
    email,
    onAddNewCoach,
  ]);

  return (
    <StyledModal {...modalProps}>
      <Container>
        <Title>{texts.title}</Title>
        <StyledTextField
          label={texts.name}
          variant="outlined"
          onChange={(e) => setName(e.target.value)}
          error={!!errors.name}
          helperText={errors.name}
        />
        <StyledTextField
          label={texts.email}
          variant="outlined"
          onChange={(e) => setEmail(e.target.value?.toLowerCase())}
          error={!!errors.email}
          helperText={errors.email}
        />
        <Button
          onClick={onSave}
          variant="contained"
        >
          {texts.save}
        </Button>
      </Container>
    </StyledModal>
  );
};

NewCoachModal.propTypes = {
  onAddNewCoach: PropTypes.func.isRequired,
};

export default NewCoachModal;
