import React, {
  useState,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

import ShowAdornment from '../../CoachTools/components/ShowAdornment';
import CopyButton from '../CopyButton';

const ButtonPlacement = {
  START: 'start',
  END: 'end',
};

const CopyField = ({
  id,
  label,
  value,
  valuePrefix,
  isSensitive,
  className,
  placement,
}) => {
  const [showSensitiveInfo, setShowSensitiveInfo] = useState(!isSensitive);

  const toggleShowSensitive = useCallback(() => {
    setShowSensitiveInfo((currentShowSensitiveInfo) => !currentShowSensitiveInfo);
  }, []);

  const getAdornment = () => (
    <>
      {isSensitive && (
        <ShowAdornment
          show={showSensitiveInfo}
          onClick={toggleShowSensitive}
        />
      )}
      <CopyButton value={value} valuePrefix={valuePrefix} />
    </>
  );

  return (
    <FormControl className={className}>
      <InputLabel
        htmlFor={id}
      >
        {label}
      </InputLabel>
      <OutlinedInput
        disabled
        id={id}
        type={showSensitiveInfo ? 'text' : 'password'}
        value={value}
        label={label}
        startAdornment={placement === ButtonPlacement.START ? getAdornment() : null}
        endAdornment={placement === ButtonPlacement.END ? getAdornment() : null}
      />
    </FormControl>
  );
};

CopyField.propTypes = {
  isSensitive: PropTypes.bool,
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  valuePrefix: PropTypes.string,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  placement: PropTypes.oneOf(Object.values(ButtonPlacement)),
};

CopyField.defaultProps = {
  isSensitive: false,
  valuePrefix: '',
  className: '',
  placement: ButtonPlacement.END,
};

export default CopyField;
export {
  ButtonPlacement,
};
