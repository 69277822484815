import { Collection } from 'firestorter';
import Collections from '../../utils/collections';
import BaseDocument from '../../Model/BaseDocument';

class WeeklyCheckin extends BaseDocument {
  constructor(id, opts) {
    super(`${Collections.WEEKLY_CHECKIN}/${id}`, opts);
  }

  get checkInData() {
    return this.data.checkInData || {};
  }

  get habitData() {
    return this.data.habitData || {};
  }

  get questions() {
    return this.data.questions || {};
  }

  get updated() {
    return this.data.updated?.toDate();
  }

  get submittedAt() {
    return this.data.submittedAt?.toDate();
  }

  get user() {
    return this.data.user;
  }

  get mealsSummary() {
    return this.data.mealsSummary || {};
  }

  get workoutData() {
    return this.data.workoutData || [];
  }

  get userMealsSubmitted() {
    return this.data.userMealsSubmitted;
  }

  static async getByUser(userId) {
    const weeklyCheckinCollection = new Collection(Collections.WEEKLY_CHECKIN, {
      createDocument: (src, opts) => new WeeklyCheckin(src.id, opts),
      query: (ref) => ref
        .where('user', '==', userId)
        .orderBy('submittedAt', 'desc'),
    });
    await weeklyCheckinCollection.fetch();
    return weeklyCheckinCollection;
  }
}

export default WeeklyCheckin;
