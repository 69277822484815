import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import NutritionHeader from './body/NutritionHeader';
import NutritionMajor from './body/NutritionBody';
import NutritionFacts from './body/NutritionFacts';

export { ImperialUnits, MetricUnits } from './utils/ServingSize';
export { ServingSize } from './utils/ServingSize';

const Section = styled.section`
  width: 100%;
  border: 1px solid black;
  margin: 20px;
  padding: 0.5rem;
  font-size: small;
  line-height: 1.4;
`;

export default function NutritionFactTable(props) {
  const {
    nutritionItems,
    servingSizeInformation,
    disableMicros,
  } = props;
  return (
    <Section>
      <NutritionHeader servingSize={servingSizeInformation} />
      <NutritionMajor {...nutritionItems} />
      {!disableMicros && <NutritionFacts {...nutritionItems} />}
      <p>
        * Percent Daily Values are based on a 2,000 calorie diet. Your daily
        values may be higher or lower depending on your calorie needs:
      </p>
    </Section>
  );
}

NutritionFactTable.propTypes = {
  nutritionItems: PropTypes.object.isRequired,
  servingSizeInformation: PropTypes.object.isRequired,
  disableMicros: PropTypes.bool,
};

NutritionFactTable.defaultProps = {
  disableMicros: false,
};
