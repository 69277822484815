import Collection from '../utils/collections';
import BaseDocument from './BaseDocument';

class ExternalCoachPrivate extends BaseDocument {
  constructor(id, opts) {
    super(`${Collection.EXTERNAL_COACH_PRIVATE}/${id}`, opts);
  }

  get leadCaptureFormResultsSheet() {
    return this.data.leadCaptureFormResultsSheet;
  }

  get onboardingEmailTemplate() {
    return this.data.onboardingEmailTemplate;
  }
}

export default ExternalCoachPrivate;
