import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import { autorun } from 'mobx';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';

import useComponentMounted from '../../../hooks/useComponentMounted';
import { ActivityType } from '../../Model/CoachActivity';
import Workout from '../../../Model/Workout';
import WorkoutContext, { initialValues } from './WorkoutContext';

/**
 * @namespace WorkoutContextProvider
 *
 * @description Provides a context for managing and accessing workouts data within the application.
 *
 * **Data Accessible from this Context:**
 * - **workoutCollection**: Workouts of coach.
 * - **isReady**: Whether context data is ready or not.
 */
const WorkoutContextProvider = ({ children }) => {
  const {
    params: { userId: coachId },
  } = useRouteMatch();
  const isComponentMountedRef = useComponentMounted();

  const [coachWorkoutCollection, setCoachWorkoutCollection] = useState(initialValues.coachWorkoutCollection);
  const [publicWorkoutsCollection, setPublicWorkoutsCollection] = useState(initialValues.publicWorkoutCollection);
  const [workouts, setWorkouts] = useState(initialValues.workouts);
  const [isReady, setIsReady] = useState(initialValues.isReady);
  const [showArchivedWorkouts, setShowArchivedWorkouts] = useState(initialValues.showArchivedWorkouts);
  const [isLoading, setIsLoading] = useState(initialValues.isLoading);
  const [isArchivalTickChangedOnce, setIsArchivalTickChangedOnce] = useState(false);

  useEffect(() => {
    const loadWorkouts = async () => {
      const coachWorkoutsCol = await Workout.getWorkoutsByCoach(coachId);
      const publicWorkoutsCol = await Workout.getPublicWorkouts();

      if (isComponentMountedRef.current) {
        setCoachWorkoutCollection(coachWorkoutsCol);
        setPublicWorkoutsCollection(publicWorkoutsCol);
      }
    };
    if (coachId) {
      loadWorkouts();
    }
  }, [
    coachId,
    isComponentMountedRef,
  ]);

  useEffect(() => {
    setIsLoading(true);
    const disposer = autorun(() => {
      const coachWorkoutDocs = coachWorkoutCollection.hasDocs ? coachWorkoutCollection.docs.slice() : [];
      const publicWorkoutDocs = publicWorkoutsCollection.hasDocs ? publicWorkoutsCollection.docs.slice() : [];
      const filteredCoachWorkouts = showArchivedWorkouts ? coachWorkoutDocs
        : coachWorkoutDocs.filter((workout) => !workout.isArchived);

      const workoutDocs = [
        ...filteredCoachWorkouts,
        ...publicWorkoutDocs,
      ];

      // Sort the workouts by created date
      workoutDocs.sort((first, second) => second.createdAt - first.createdAt);

      if (isComponentMountedRef.current) {
        setWorkouts(workoutDocs);
        setIsLoading(false);
        setIsReady(true);
      }
    });

    return disposer;
  }, [
    coachWorkoutCollection,
    publicWorkoutsCollection,
    isComponentMountedRef,
    showArchivedWorkouts,
  ]);

  const setWorkoutsBasedOnArchiveFlag = useCallback(async (fetchArchivedWorkouts = false) => {
    setIsLoading(true);
    setShowArchivedWorkouts(fetchArchivedWorkouts);
    if (!isArchivalTickChangedOnce) {
      setIsArchivalTickChangedOnce(true);
      const coachWorkoutsCol = await Workout.getWorkoutsByCoach(coachId, fetchArchivedWorkouts);
      if (isComponentMountedRef.current) {
        setCoachWorkoutCollection(coachWorkoutsCol);
      }
    }
    setIsLoading(false);
  }, [
    coachId,
    isComponentMountedRef,
    isArchivalTickChangedOnce,
  ]);

  /**
   * Filter workouts to exclude those that are not of type "CIRCUIT" and have empty sub-activities.
   * @param {Array} workouts - The array of workouts to filter.
   * @returns {Array} - The filtered array of workouts.
   */
  const filteredWorkouts = useMemo(() => workouts?.filter(({ activities }) => !!activities?.length
    && !activities?.some(
      ({ type, activities: subActivities }) => type === ActivityType.CIRCUIT && !subActivities?.length,
    )), [workouts]);

  const contextValue = useMemo(() => ({
    workouts,
    filteredWorkouts,
    isReady,
    showArchivedWorkouts,
    setShowArchivedWorkouts,
    isLoading,
    setWorkoutsBasedOnArchiveFlag,
  }),
  [
    workouts,
    filteredWorkouts,
    isReady,
    showArchivedWorkouts,
    setShowArchivedWorkouts,
    isLoading,
    setWorkoutsBasedOnArchiveFlag,
  ]);

  return (
    <WorkoutContext.Provider value={contextValue}>
      {children}
    </WorkoutContext.Provider>
  );
};

WorkoutContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default compose(
  observer,
)(WorkoutContextProvider);
