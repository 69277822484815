import React from 'react';
import PropTypes from 'prop-types';

import {
  Container,
  EmptyViewContainer,
} from './styles';

const EmptyView = ({
  content,
}) => (
  <Container>
    <EmptyViewContainer>
      {content}
    </EmptyViewContainer>
  </Container>
);

EmptyView.propTypes = {
  content: PropTypes.string.isRequired,
};

export default EmptyView;
