import styled from 'styled-components';
import {
  Typography,
} from '@mui/material';
import InfoRounded from '@mui/icons-material/InfoRounded';

import colors from '../../../../../../styles/colors';
import { ReactComponent as MealPlateIcon } from '../../../../../../assets/icons/v2/modal-meal-plate.svg';

const AnalysisContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background: ${colors.base.alpha};
  border: 1px solid ${colors.shades.secondary7};
  border-radius: 21px;
  height: 42px;
  box-sizing: border-box;
`;

const AlertContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 32px;
  border: 1px solid ${colors.base.danger};
  background-color: ${colors.shades.danger1};
  border-radius: 4px;
  margin-top: 2px;
`;

const AlertTypography = styled(Typography)`
  font-size: 10px;
  line-height: 12px;
  font-weight: 500;
  margin-left: 8px;
  color: ${colors.base.alpha};
`;

const AlertIcon = styled(InfoRounded)`
  color: ${colors.base.alpha};
  width: 14px;
  height: 14px;
`;

const ContentWrapper = styled.div`
  padding: 22px 49px 0 51px;
`;

const ErrorsContainer = styled.div`
  margin: 20px 50px 0 50px;
`;

const StyledMealPlateIcon = styled(MealPlateIcon)`
  width: 27px;
  height: 29px;
  margin-bottom: 9px;
`;

export {
  AnalysisContainer,
  AlertContainer,
  AlertTypography,
  AlertIcon,
  ContentWrapper,
  ErrorsContainer,
  StyledMealPlateIcon,
};
