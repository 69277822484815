import React from 'react';
import PropTypes, { object } from 'prop-types';

import QuestionType from '../../questionType';
import fieldName from '../../formFields';
import FieldArray from '../FieldArray';

import QuestionElement from './questionElement';
import {
  Container,
  HeaderContainer,
  Title,
} from './styles';
import texts from './texts.json';

const FormQuestions = ({ questions }) => (
  <Container>
    <HeaderContainer>
      <Title>{texts.questions}</Title>
    </HeaderContainer>
    <FieldArray
      questions={questions}
      fieldName={fieldName.FIELDS}
      addOptions={QuestionType}
      elementArray={QuestionElement}
    />
  </Container>
);

FormQuestions.propTypes = {
  questions: PropTypes.arrayOf(object),
};

FormQuestions.defaultProps = {
  questions: [],
};

export default FormQuestions;
