import { Collection } from 'firestorter';
import { firestorePaths } from '../utils/paths';
import BaseDocument from './BaseDocument';

/**
 * Status of the subscription
 */
const subscriptionStatus = {
  ACTIVE: 'ACTIVE',
  TRIALING: 'TRIALING',
  PAST_DUE: 'PAST_DUE',
};

/**
 * Class representing a Subscription associated to a User.
 *
 * @class Subscription
 * @extends BaseDocument
 */
class Subscription extends BaseDocument {
  constructor(id, opts) {
    super(`${firestorePaths.STRIPE_SUBSCRIPTIONS}/${id}`, opts);
  }

  /**
   * Get all of the subscription data as an object.
   * @return {Object}
   */
  get subscriptionData() {
    return this.data.stripeSubscription;
  }

  /**
   * Get the associated stripe account ID.
   * @return {string}
   */
  get stripeAccountId() {
    return this.data.stripeAccountId;
  }

  /**
   * Returns the current subscription by a given user ID.
   * @param {string} userId - The ID of the user.
   *  @return {Promise<Subscription|void>} A promise that resolves to a Subscription, or null if it doesn't exist.
   */
  static async getCurrentSubscription(userId) {
    const subscriptionsCollection = new Collection(firestorePaths.STRIPE_SUBSCRIPTIONS,
      {
        query: (ref) => ref
          .where('user', '==', userId)
          .where('current', '==', true),
      });
    await Subscription.initCollection(subscriptionsCollection);

    const doc = subscriptionsCollection.hasDocs ? new Subscription(subscriptionsCollection.docs[0].id) : null;

    if (doc) {
      await doc.init();
    }

    return doc;
  }
}

export default Subscription;

export {
  subscriptionStatus,
};
