import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import { WorkoutFeedViewType } from '../utils';

import {
  Container,
  ItemContainer,
  StyledTitle,
  WorkoutItem,
  WorkoutName,
  WorkoutStatusContainer,
  CompletedIcon,
  IncompleteIcon,
  Summary,
} from './styles';
import texts from './texts.json';

const WorkoutDayPanel = ({
  workoutList,
  dayName,
}) => {
  const { title, summaryText } = useMemo(() => {
    let summary = '';
    let titleText = dayName.toUpperCase();
    const assignedWorkoutCount = workoutList[WorkoutFeedViewType.COACH_ASSIGNED_WORKOUT].length;
    const customWorkoutCount = workoutList[WorkoutFeedViewType.CUSTOM_WORKOUT].length;
    if (assignedWorkoutCount) {
      const completedCount = workoutList[WorkoutFeedViewType.COACH_ASSIGNED_WORKOUT]
        .filter((workout) => !!workout.isCompleted)
        .length;
      summary = `•${summary}${completedCount}/${assignedWorkoutCount} ${texts.workouts}\n`;
    }
    if (customWorkoutCount) {
      summary = `${summary}•${customWorkoutCount} ${texts.customActivities}`;
    }
    // if no workouts are done, show rest day
    if (!customWorkoutCount && !assignedWorkoutCount) {
      titleText = `${titleText} - ${texts.restDay}`;
    }
    return {
      title: titleText,
      summaryText: summary,
    };
  }, [
    workoutList,
    dayName,
  ]);

  const hasWorkouts = useMemo(() => (
    workoutList[WorkoutFeedViewType.COACH_ASSIGNED_WORKOUT].length
    || workoutList[WorkoutFeedViewType.CUSTOM_WORKOUT].length
  ), [
    workoutList,
  ]);

  return (
    <Container>
      <ItemContainer>
        <StyledTitle $hasWorkouts={hasWorkouts}>{title}</StyledTitle>
        <Summary>{summaryText}</Summary>
      </ItemContainer>
      {!!workoutList[WorkoutFeedViewType.COACH_ASSIGNED_WORKOUT].length && (
        <ItemContainer>
          <StyledTitle $hasWorkouts={hasWorkouts}>{texts.workouts}</StyledTitle>
          {workoutList[WorkoutFeedViewType.COACH_ASSIGNED_WORKOUT].map((workout) => (
            <WorkoutItem key={`${workout.id}-${uuidv4()}`}>
              <WorkoutStatusContainer>
                {workout.isCompleted ? <CompletedIcon /> : <IncompleteIcon />}
              </WorkoutStatusContainer>
              <WorkoutName>{workout.title}</WorkoutName>
            </WorkoutItem>
          ))}
        </ItemContainer>
      )}
      {!!workoutList[WorkoutFeedViewType.CUSTOM_WORKOUT].length && (
        <ItemContainer>
          <StyledTitle $hasWorkouts={hasWorkouts}>{texts.customActivities}</StyledTitle>
          {workoutList[WorkoutFeedViewType.CUSTOM_WORKOUT].map((workout) => (
            <WorkoutItem key={`${workout.id}-${uuidv4()}`}>
              <WorkoutStatusContainer>
                {workout.isCompleted ? <CompletedIcon /> : <IncompleteIcon />}
              </WorkoutStatusContainer>
              <WorkoutName>{workout.title}</WorkoutName>
            </WorkoutItem>
          ))}
        </ItemContainer>
      )}
    </Container>
  );
};

WorkoutDayPanel.propTypes = {
  workoutList: PropTypes.object.isRequired,
  dayName: PropTypes.string.isRequired,
};

export default WorkoutDayPanel;
