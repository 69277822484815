import React from 'react';
import PropTypes from 'prop-types';
import format from 'string-template';

import { formatCurrencyCents } from '../../../../utils/formatters';
import { getAverageMonthlyPayment, getFormattedAverageMonthlyPayment } from '../../../utils/userContract';
import { CurrencyMinimumAvgWarning } from '../../../../utils/currency';
import {
  Container,
  Label,
  Value,
  Warning,
} from './styles';
import texts from './texts.json';

const AveragePayment = ({
  contract,
  className,
}) => {
  const averageAmount = getAverageMonthlyPayment(contract);
  const warningAmount = CurrencyMinimumAvgWarning[contract.currency];

  return (
    <Container className={className}>
      <Label>{texts.label}</Label>
      <Value>{format(texts.averageInfo, { amount: getFormattedAverageMonthlyPayment(contract) })}</Value>
      {averageAmount < warningAmount && (
        <Warning>{format(texts.warning, { amount: formatCurrencyCents(warningAmount, contract.currency) })}</Warning>
      )}
    </Container>
  );
};

AveragePayment.propTypes = {
  contract: PropTypes.object,
  className: PropTypes.string,
};

AveragePayment.defaultProps = {
  contract: {},
  className: '',
};

export default AveragePayment;
