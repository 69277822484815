import React, {
  useState,
  useMemo,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import { StyledTextField } from '../../../../../../../components/Inputs';
import Button from '../../../../../../../components/v2/Button';
import RadioButtonGroup from '../../../../../../components/RadioButtonGroup';
import CoachConfig, { LeadConfigOptions } from '../../../../../../Model/CoachConfig';
import useToast from '../../../../../../hooks/useToast';

import { SettingOption } from '../utils';
import {
  SettingContainer as Container,
  HeaderContainer,
  SettingsActionContainer,
  TitleContainer,
  Title,
  SubTitle,
  ActionLabel,
  StyledSaveIcon,
} from '../styles';

import texts from './texts.json';

const LeadSubmissionSettings = ({ coachConfig }) => {
  const { showToast } = useToast();

  const [isEmailNotificationsEnabled, seIsEmailNotificationsEnabled] = useState(
    coachConfig.isLeadSubmissionEmailEnabled,
  );
  const [emailAddress, setEmailAddress] = useState(coachConfig.leadSubmissionEmail);

  const isEmailError = useMemo(() => isEmailNotificationsEnabled && !emailAddress, [
    isEmailNotificationsEnabled,
    emailAddress,
  ]);

  const handleSave = useCallback(async () => {
    try {
      await coachConfig.updateLeadConfig({
        [LeadConfigOptions.IS_LEAD_SUBMISSION_EMAIL_ENABLED]: isEmailNotificationsEnabled,
        [LeadConfigOptions.LEAD_SUBMISSION_EMAIL]: emailAddress,
      });
      showToast(texts.messageSettingSaveSuccessful);
    } catch (error) {
      showToast(texts.messageSettingSaveFailed, { error: true });
    }
  }, [
    isEmailNotificationsEnabled,
    emailAddress,
    coachConfig,
    showToast,
  ]);

  return (
    <Container>
      <HeaderContainer>
        <TitleContainer>
          <Title>{texts.leadSubmissions}</Title>
          <SubTitle>{texts.leadSubmissionsDescription}</SubTitle>
        </TitleContainer>
      </HeaderContainer>
      <SettingsActionContainer>
        <ActionLabel>{texts.emailNotifications}</ActionLabel>
        <RadioButtonGroup
          options={[
            {
              label: texts.enabled,
              value: SettingOption.ENABLED,
            },
            {
              label: texts.disabled,
              value: SettingOption.DISABLED,
            },
          ]}
          selectedOption={isEmailNotificationsEnabled ? SettingOption.ENABLED : SettingOption.DISABLED}
          onOptionChange={(value) => seIsEmailNotificationsEnabled(value === SettingOption.ENABLED)}
        />
      </SettingsActionContainer>
      <SettingsActionContainer>
        <ActionLabel>{texts.emailAddress}</ActionLabel>
        <StyledTextField
          value={emailAddress}
          onChange={(event) => setEmailAddress(event.target.value)}
          placeholder={texts.emailPlaceholderText}
          error={isEmailError}
          helperText={isEmailError && texts.required}
        />
      </SettingsActionContainer>
      <Button
        onClick={handleSave}
        disabled={isEmailError}
      >
        <StyledSaveIcon />
        {texts.save}
      </Button>
    </Container>
  );
};

LeadSubmissionSettings.propTypes = {
  coachConfig: PropTypes.instanceOf(CoachConfig).isRequired,
};

export default LeadSubmissionSettings;
