import styled from 'styled-components';
import { Typography } from '@mui/material';

const Text = styled(Typography)`
  margin-bottom: 5px;
`;

const Title = styled(Text)`
  font-weight: bold;
  font-size: 24px;
`;

const Description = styled.div`
  font-size: 16px;
  margin-bottom: 15px;
`;

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 30px;
  }
`;

const FilterRow = styled.div`
  display: flex;
  div:not(:last-child) {
    margin-right: 30px;
  }
`;

export {
  Container,
  Title,
  Description,
  TitleRow,
  FilterRow,
};
