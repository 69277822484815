const ExerciseTagCategory = {
  EXERCISE_TYPE: 'EXERCISE_TYPE',
  EQUIPMENT: 'EQUIPMENT',
  BODY_REGION_BASIC: 'BODY_REGION_BASIC',
  BODY_REGION_ADVANCED: 'BODY_REGION_ADVANCED',
};

const WorkoutAssignmentCode = {
  NO_ACTIVITIES_IN_WORKOUT: 'This workout doesn\'t contain any activities',
  NO_ACTIVITIES_IN_CIRCUIT: 'One of the circuits in the workout doesn\'t contain any activities',
  ONLY_REST_ACTIVITIES: 'This workout only has REST activities',
  ONLY_REST_ACTIVITIES_IN_CIRCUIT: 'One of the circuits in the workout only has REST activities',
  VALID_WORKOUT: 'Valid workout',
};

/* TODO: Add Activity model and types.
* This will be necessary for Workouts 2.0.
*/

const COMPARE_REST = 'REST';

const getRequiredEquipment = (activities = []) => {
  let equipment = {};

  activities.forEach((activity) => {
    if (activity.activities) {
      const circuitEquipment = getRequiredEquipment(activity.activities);
      equipment = {
        ...equipment,
        ...circuitEquipment,
      };
    } else {
      const { tags = [] } = activity;
      const equipmentTags = tags.filter((tag) => tag.category === ExerciseTagCategory.EQUIPMENT);
      equipmentTags.forEach(({ id, tag }) => {
        equipment[id] = tag;
      });
    }
  });

  return equipment;
};

const validateActivities = (activitiesArr, isRootArr) => {
  if (activitiesArr.activities && activitiesArr.activities.length > 0) {
    let onlyRest = true;
    let innerCircuitCode = '';

    // Function to verify if workout has valid activities array
    const validArray = activitiesArr.activities.every((act) => {
      if (onlyRest && act.type && act.type !== COMPARE_REST) {
        onlyRest = false;
      }

      if (act.activities) {
        innerCircuitCode = validateActivities(act, false);
        if (innerCircuitCode !== true) {
          return false;
        }
      }
      return true;
    });

    if (isRootArr) {
      if (onlyRest) {
        return WorkoutAssignmentCode.ONLY_REST_ACTIVITIES; // Only REST activities in root array
      }
      if (innerCircuitCode !== true) {
        return innerCircuitCode; // No activities or only REST activities in nested array
      }
      return WorkoutAssignmentCode.VALID_WORKOUT; // Workout is valid
    }

    if (!isRootArr && onlyRest) {
      return WorkoutAssignmentCode.ONLY_REST_ACTIVITIES_IN_CIRCUIT;
    }

    return validArray;
  }
  if (!isRootArr && activitiesArr.activities && activitiesArr.activities.length === 0) {
    return WorkoutAssignmentCode.NO_ACTIVITIES_IN_CIRCUIT; // No activities in nested array
  }
  return WorkoutAssignmentCode.NO_ACTIVITIES_IN_WORKOUT; // No activities in root array
};

export {
  getRequiredEquipment,
  WorkoutAssignmentCode,
  validateActivities,
};
