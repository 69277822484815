import styled from 'styled-components';

import { ReactComponent as BellCrossedIcon } from '../../../../../assets/onboarding/bell-crossed.svg';
import { sansSerifHeadlineFontStyles } from '../../../../../styles/text';

const NoSubscriptionAllowIcon = styled(BellCrossedIcon)`
  width: 30px;
  height: 30px;
  margin-right: 15px;
`;

const Title = styled.span`
  ${sansSerifHeadlineFontStyles}
  font-size: 24px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export {
  Title,
  NoSubscriptionAllowIcon,
  Container,
};
