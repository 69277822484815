import React from 'react';
import LayoutContextProvider from './LayoutContextProvider';

const withLayoutContextProvider = (Component) => (props) => (
  <LayoutContextProvider>
    <Component {...props} />
  </LayoutContextProvider>
);

export {
  withLayoutContextProvider,
};
