import fieldName from './formFields';

const texts = {
  editExerciseTitle: 'Edit {exerciseType} exercise',
  newExerciseTitle: 'New Exercise',
  addExercise: 'Add New Exercise',
  editExercise: 'Edit Exercise',
  duplicateNameError: 'An exercise with the name {exerciseName} already exists',
  vimeoUploadError: 'Error in uploading video to Vimeo',
  videoUploadGuidelinesTitle: 'Video Upload Guidelines:',
  videoUploadGuidelines: [
    'File size: Less than 50mb',
    'Aspect ratio: 16:9',
    'Video orientation: Portrait',
    'Length: 20 to 30 seconds',
    'Video format: mp4, No audio track',
  ],
  exerciseUpdated: 'The exercise {exerciseName} has been saved successfully',
  uploading: 'Please wait until the exercise is uploaded...',
  createdNewTag: 'New tag created succesfully',
  errorCreatingNewTag: 'Error creating new tag: {message}',
  validation: {
    requiredField: 'Required',
  },
  addNewButton: 'Add New Exercise',
  submitButton: 'Save Exercise',
  cancelButton: 'Cancel',
  revert: 'Revert to Original',
  createTag: 'Create new tag',
  labels: {
    video: 'Exercise Video',
    tags: 'Tags',
  },
  field: {
    [fieldName.NAME]: {
      label: 'Exercise Name',
    },
    [fieldName.DESCRIPTION]: {
      label: 'Exercise Description',
    },
  },
};

export default texts;
