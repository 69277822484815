import styled from 'styled-components';
import { AddCircleOutline as AddIcon } from '@mui/icons-material';

import RoundedButton from '../../../components/v2/RoundedButton';
import { ButtonIconStyle } from '../../../components/v2/Button';
import colors from '../../../styles/colors';

const StyledButton = styled(RoundedButton)`
  background-color: ${colors.shades.beta2};
  color: ${colors.base.alpha};
  cursor: pointer;
`;

const StyledAddIcon = styled(AddIcon)`
  ${ButtonIconStyle}
  color: ${colors.shades.primary1};
`;

export {
  StyledButton,
  StyledAddIcon,
};
