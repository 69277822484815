import QuestionType from '../../questionType';

import InputQuestion from './components/InputQuestion';
import NameQuestion from './components/NameQuestion';
import OptionsQuestion from './components/OptionsQuestion';

const QuestionElement = {
  [QuestionType.TEXT]: InputQuestion,
  [QuestionType.OPTIONS]: OptionsQuestion,
  [QuestionType.EMAIL]: InputQuestion,
  [QuestionType.PHONE]: InputQuestion,
  [QuestionType.NAME]: NameQuestion,
};

export default QuestionElement;
