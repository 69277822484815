import styled from 'styled-components';
import SlickSlider from 'react-slick';

const StyledSlider = styled(SlickSlider)`
  .slick-dots li,
  .slick-dots li button,
  .slick-dots li button:before {
    width: 10px;
    height: 10px;
  }

  .slick-dots li button:before {
    line-height: 10px;
  }
`;

export {
  StyledSlider,
};
