import React, {
  useEffect,
  useState,
  useMemo,
  useContext,
} from 'react';
import { useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { observer } from 'mobx-react';

import useComponentMounted from '../../../hooks/useComponentMounted';
import ExternalCoachContext from '../ExternalCoachContext/ExternalCoachContext';
import CoachMissingSettingsModal from '../../components/CoachMissingSettingsModal/CoachMissingSettingsModal';
import pages, { pagesConfig } from '../../config/pages';
import {
  ConfigurationType,
  configurationPagesConfig,
} from '../../config/configurationPages';
import OnboardingCoachNotificationsContext, { initialValues } from './OnboardingCoachNotificationsContext';

import texts from './texts.json';

const OnboardingCoachNotificationsContextProvider = ({
  children,
}) => {
  const { params: { userId } } = useRouteMatch();

  const [missingSettingsActions, setMissingSettingsActions] = useState([]);
  const [isReady, setIsReady] = useState(initialValues.isReady);
  const [currentUserId, setCurrentUserId] = useState(userId);

  const isComponentMountedRef = useComponentMounted();

  const {
    externalCoachDoc: {
      stripeAccountId,
    },
    coachDoc: {
      avatar,
    },
  } = useContext(ExternalCoachContext);

  useEffect(() => {
    if (currentUserId !== userId) {
      setIsReady(false);
    }
  }, [
    currentUserId,
    userId,
  ]);

  useEffect(() => {
    const init = () => {
      if (isComponentMountedRef.current) {
        const missingSettings = [];
        if (!stripeAccountId) {
          missingSettings.push({
            path: `${pagesConfig[pages.CONFIG].path}/${configurationPagesConfig[ConfigurationType.PAYMENT].path}`,
            label: texts.missingStripeAccountId,
            buttonText: texts.missingStripeAccountIdButton,
          });
        }

        if (!avatar) {
          missingSettings.push({
            path: `${pagesConfig[pages.CONFIG].path}/${configurationPagesConfig[ConfigurationType.CHECKOUT_PAGE].path}`,
            label: texts.missingAvatarUrl,
            buttonText: texts.missingAvatarUrlButton,
          });
        }

        setMissingSettingsActions(missingSettings);
        setIsReady(true);
        setCurrentUserId(userId);
      }
    };

    if (!isReady) {
      init();
    }
  }, [
    userId,
    currentUserId,
    isComponentMountedRef,
    avatar,
    stripeAccountId,
    isReady,
  ]);

  const contextValue = useMemo(() => ({
    isReady,
    missingSettingsActions,
  }), [
    isReady,
    missingSettingsActions,
  ]);

  return (
    <OnboardingCoachNotificationsContext.Provider value={contextValue}>
      {!!missingSettingsActions.length && (
        <CoachMissingSettingsModal
          missingSettings={missingSettingsActions}
          isOpen={!!missingSettingsActions.length}
          onClose={() => setMissingSettingsActions([])}
        />
      )}
      {children}
    </OnboardingCoachNotificationsContext.Provider>
  );
};

OnboardingCoachNotificationsContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default compose(
  observer,
)(OnboardingCoachNotificationsContextProvider);
