import React, { useContext } from 'react';
import {
  Drawer,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@mui/material';

import FeedContext from '../../../../context/FeedContext';
import FeedSearch from '../FeedSearch';

import useFeedListConfig from './useFeedListConfig';
import {
  drawerStyle,
  FeedListFooter,
  StyledBadge,
  StyledList,
  FeedListContainer,
  FilterContainer,
  SortContainer,
  StyledSortIcon,
} from './styles';
import texts from './texts.json';

const FeedList = () => {
  const {
    selectedFeedType,
    setIsOldestToNewest,
    isOldestToNewest,
  } = useContext(FeedContext);
  const { feedListEntries, footerFeedListEntries } = useFeedListConfig();

  return (
    <FeedListContainer>
      <FilterContainer>
        <FeedSearch />
        <SortContainer onClick={() => setIsOldestToNewest((prev) => !prev)}>
          <Tooltip
            title={isOldestToNewest ? texts.newestToOldest : texts.oldestToNewest}
            placement="top"
            arrow
          >
            <StyledSortIcon />
          </Tooltip>
        </SortContainer>
      </FilterContainer>
      <Drawer
        variant="permanent"
        open
        sx={drawerStyle}
      >
        <StyledList>
          {feedListEntries.map(({
            title,
            icon: IconComponent,
            onClick,
            feedId,
            unreadCount,
          }) => (
            <React.Fragment key={feedId}>
              <ListItem
                button
                onClick={onClick}
                selected={selectedFeedType === feedId}
              >
                <ListItemIcon>
                  <IconComponent />
                </ListItemIcon>
                <ListItemText primary={title} />
                <StyledBadge
                  badgeContent={unreadCount}
                  showZero
                  max={999}
                />
              </ListItem>
            </React.Fragment>
          ))}
        </StyledList>
        <FeedListFooter>
          {footerFeedListEntries.map(({
            title,
            icon: IconComponent,
            onClick,
            feedId,
          }) => (
            <ListItem
              key={feedId}
              button
              onClick={onClick}
              selected={selectedFeedType === feedId}
            >
              <ListItemIcon>
                <IconComponent />
              </ListItemIcon>
              <ListItemText primary={title} />
            </ListItem>
          ))}
        </FeedListFooter>
      </Drawer>
    </FeedListContainer>
  );
};

export default FeedList;
