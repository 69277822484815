import React, {
  useEffect,
  useState,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { compose } from 'recompose';

import useComponentMounted from '../../../hooks/useComponentMounted';
import InternalAssignment from '../../../Model/InternalAssignment';
import { AssignmentRole } from '../../../utils/internalAssignments';
import InternalAssignmentsContext, { initialValues } from './InternalAssignmentsContext';

const InternalAssignmentsContextProvider = ({
  children,
}) => {
  const [isReady, setIsReady] = useState(initialValues.isReady);
  const [internalAssignmentsCol, setInternalAssignmentsCol] = useState(initialValues.internalAssignmentsCol);
  const [crxUsers, setCrxUsers] = useState([]);
  const [isUsers, setIsUsers] = useState([]);
  const [acUsers, setAcUsers] = useState([]);
  const [crxAssignments, setCrxAssignments] = useState([]);
  const [isAssignments, setIsAssignments] = useState([]);
  const [acAssignments, setAcAssignments] = useState([]);

  const isComponentMountedRef = useComponentMounted();

  useEffect(() => {
    const init = async () => {
      const assignmentCollection = await InternalAssignment.getAllCurrentAssignments();
      const crxUserMap = new Map();
      const isUsersMap = new Map();
      const acUsersMap = new Map();
      const crxAssignmentList = [];
      const isAssignmentList = [];
      const acAssignmentList = [];
      assignmentCollection.docs.forEach((assignment) => {
        switch (assignment.role) {
          case AssignmentRole.CRX:
            crxUserMap.set(assignment.userId, assignment);
            crxAssignmentList.push(assignment);
            break;
          case AssignmentRole.INSIDE_SALES:
            isUsersMap.set(assignment.userId, assignment);
            isAssignmentList.push(assignment);
            break;
          case AssignmentRole.ASSISTANT_COACH:
            acUsersMap.set(assignment.userId, assignment);
            acAssignmentList.push(assignment);
            break;
          default:
            break;
        }
      });
      if (isComponentMountedRef.current) {
        setInternalAssignmentsCol(assignmentCollection);
        setCrxUsers(Array.from(crxUserMap.values()));
        setIsUsers(Array.from(isUsersMap.values()));
        setAcUsers(Array.from(acUsersMap.values()));
        setCrxAssignments(crxAssignmentList);
        setIsAssignments(isAssignmentList);
        setAcAssignments(acAssignmentList);
        setIsReady(true);
      }
    };

    if (!isReady) {
      init();
    }
  }, [
    isComponentMountedRef,
    isReady,
  ]);

  const context = useMemo(() => ({
    internalAssignmentsCol,
    crxUsers,
    isUsers,
    acUsers,
    crxAssignments,
    isAssignments,
    acAssignments,
    isReady,
  }), [
    internalAssignmentsCol,
    crxUsers,
    isUsers,
    acUsers,
    crxAssignments,
    isAssignments,
    acAssignments,
    isReady,
  ]);

  return (
    <InternalAssignmentsContext.Provider value={context}>
      {children}
    </InternalAssignmentsContext.Provider>
  );
};

InternalAssignmentsContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default compose(
  observer,
)(InternalAssignmentsContextProvider);
