import React, { useContext } from 'react';

import { ReactComponent as ContractIcon } from '../../../../assets/icons/contract-icon.svg';

import ContractModalContext from '../context/ContractModalContext';
import { ContractState } from '../utils/ContractTypes';

import {
  Footer,
  GenerateContractButton,
  EditButton,
  ViewTermsButton,
} from './styles';
import texts from './texts.json';

const ContractModalFooter = () => {
  const {
    contractDoc,
    contractState,
    isSaving,
    isValidContract,
    generateContract,
    editContract,
    viewTerms,
  } = useContext(ContractModalContext);

  const getContractCTAText = () => {
    if (isSaving) {
      return texts.saving;
    }

    return contractDoc ? texts.updateTerms : texts.createLink;
  };

  const renderFooterContent = () => {
    if (contractState === ContractState.CONFIGURATION) {
      return (
        <>
          <GenerateContractButton
            onClick={generateContract}
            disabled={isSaving || !isValidContract}
          >
            <ContractIcon />
            {getContractCTAText()}
          </GenerateContractButton>
          {!!contractDoc && (
            <ViewTermsButton onClick={viewTerms}>
              {texts.viewTerms}
            </ViewTermsButton>
          )}
        </>
      );
    }

    return (
      <EditButton onClick={editContract}>
        {texts.editTerms}
      </EditButton>
    );
  };

  return (
    <Footer>
      {renderFooterContent()}
    </Footer>
  );
};

export default ContractModalFooter;
