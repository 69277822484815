import pages from '../../config/pages';
import MealsPlanner from '../../pages/MealsPlanner';
import {
  MealPlannerType,
  getTabPath,
} from './mealPlannerPages';

const routes = Object.values(MealPlannerType).map((type) => ({
  pageId: pages.MEAL_PLANNER,
  mealPlanPath: getTabPath(type),
  component: MealsPlanner,
}));

export default routes;
