import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import useLogger from '../../../../../hooks/useLogger';

import {
  Container,
  Title,
  NoSubscriptionAllowIcon,
} from './styles';
import texts from './texts.json';

const SubscriptionUnavailable = ({
  planData,
}) => {
  const { logEvent } = useLogger();

  useEffect(() => {
    logEvent('subscriptionUnavailableShown', planData);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <NoSubscriptionAllowIcon />
      <Title>{texts.noNewAvailable}</Title>
    </Container>
  );
};

SubscriptionUnavailable.propTypes = {
  planData: PropTypes.object.isRequired,
};

export default SubscriptionUnavailable;
