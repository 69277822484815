import styled from 'styled-components';
import { Typography } from '@mui/material';
import { Form } from 'formik';

import FormikInput from '../../../components/v2/FormikInput';
import Button, { LinkButton } from '../../../components/Button';

import colors from '../../../styles/colors';

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  margin-top: 20px;
`;

const Title = styled.div`
  font-size: 24px;
  text-align: left;
`;

const StyledBackButton = styled(LinkButton)`
  width: 10%;
  cursor: pointer;
  border-radius: 73px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  background: ${colors.base.secondary3};
  border: 1px solid ${colors.shades.secondary4};
  color: ${colors.base.secondary2};
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
`;

const FormSectionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledFormInput = styled(FormikInput)`
  margin: 30px 20px 20px 20px;
  width: 50%;
`;

const StyledTopRow = styled.div`
  padding: 0 20px 20px 20px;
  border-bottom: 1px solid ${colors.shades.gamma8};
`;

const StyledBottomRow = styled.div`
  padding: 0 20px;
  border-top: 1px solid ${colors.shades.gamma8};
`;

const StyledFormButton = styled(Button)`
  cursor: pointer;
  font-weight: 400;
  font-size: 15px;
  line-height: 14px;
  width: 120px;
  margin: 15px 0;  
  background-color: ${colors.shades.primary4};
  box-shadow: none;
  border-radius: 5px;
  padding: 15px;
`;

const StyledTypography = styled(Typography)`
  font-weight: bold;
  font-size: 18px;
`;

const CoachContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export {
  Container,
  HeaderContainer,
  Title,
  StyledBackButton,
  StyledForm,
  FormSectionContainer,
  StyledFormInput,
  StyledTopRow,
  StyledBottomRow,
  StyledFormButton,
  StyledTypography,
  CoachContainer,
};
