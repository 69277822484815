import styled from 'styled-components';
import { List } from '@mui/material';

const drawerStyle = {
  display: { xs: 'none', sm: 'block' },
  '& .MuiDrawer-paper': {
    boxSizing: 'border-box',
    position: 'unset',
    width: 280,
  },
};

const StyledList = styled(List)`
  flex: 1;
`;

export {
  drawerStyle,
  StyledList,
};
