import { env, Environment } from '../utils/environment';

import devConfig from './development.json';
import prodConfig from './production.json';

const configMap = {
  [Environment.DEVELOPMENT]: devConfig,
  [Environment.PRODUCTION]: prodConfig,
};

export default configMap[env];
