import { Card } from '@mui/material';
import styled from 'styled-components';

const Container = styled(Card)`
  min-width: 540px;
  margin: 20px;
`;

const Title = styled.div`
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 0.35em
`;

export {
  Container,
  Title,
};
