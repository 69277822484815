import styled from 'styled-components';
import { Typography, IconButton } from '@mui/material';
import colors from '../../../../../styles/colors';
import OnCallActions from '../../../../../components/OnCallActions';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: scroll;
  height: auto;
  margin: 20px 20%;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 20px 38px 0 38px;
  justify-content: space-between;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const NavigationContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const DateText = styled(Typography)`
  font-size: 14px;
  font-weight: bold;
`;

const UpdatedText = styled(Typography)`
  font-size: 12px;
`;

const StyledText = styled(Typography)`
  font-size: 12px;
  color: ${colors.shades.primary1};
  padding: 20px;
  font-weight: bold;
`;

const StyledButton = styled(IconButton)`
  border-radius: 50%;
  border: 1px solid;
  color: ${colors.shades.gamma2};
  margin: 10px;
  width: 30px;
  height: 30px;
`;

const StyledOnCallActions = styled(OnCallActions)`
  padding-left: 35px;
`;

export {
  Container,
  HeaderContainer,
  TextContainer,
  DateText,
  UpdatedText,
  NavigationContainer,
  StyledText,
  StyledButton,
  StyledOnCallActions,
};
