import { Collection } from 'firestorter';
import { firestorePaths } from '../utils/paths';
import BaseDocument from './BaseDocument';

const BroadcastMessageRequestStatus = {
  CREATED: 'CREATED',
};
class BroadcastMessageRequest extends BaseDocument {
  get message() {
    return this.data.message;
  }

  get recipients() {
    return this.data.recipients;
  }

  get sendAt() {
    return this.data.sendAt;
  }

  get attachmentsRefs() {
    return this.data.attachmentsRefs || [];
  }

  async updateMessageAndDate(message, sendAt) {
    await this.updateFields({
      message,
      sendAt,
    });
  }

  async delete() {
    await this.ref.delete();
  }

  static async create(messageData) {
    const messageRequestCollection = new Collection(firestorePaths.BROADCAST_MESSAGE_REQUEST);

    await messageRequestCollection.add({
      ...messageData,
      status: BroadcastMessageRequestStatus.CREATED,
      createdAt: new Date(),
    });
  }

  static async getScheduledMessages(coachId) {
    const queryResult = new Collection(firestorePaths.BROADCAST_MESSAGE_REQUEST, {
      createDocument: (src, opts) => new BroadcastMessageRequest(src, opts),
      query: (ref) => ref
        .where('coach', '==', coachId)
        .where('status', '==', BroadcastMessageRequestStatus.CREATED)
        .orderBy('sendAt', 'asc'),
    });
    await queryResult.fetch();
    return queryResult.docs;
  }
}

export default BroadcastMessageRequest;
