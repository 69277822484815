import React from 'react';

const initialValues = {
  isReady: false,
  isInitialized: false,
  mealPlanAssignmentDoc: null,
  nutritionProfileDoc: null,
  activityProfileDoc: null,
  macroGoalsDoc: null,
  userDoc: null,
  weeklyCheckinDocs: null,
  nutritionalGoalsAction: '',
  mealPlanAction: '',
  nutritionalGoals: {},
  newMealPlanData: {},
  selectedWeightGoal: '',
  assignmentNote: '',
};

const MealPlanAssignmentContext = React.createContext(initialValues);

export default MealPlanAssignmentContext;
export { initialValues };
