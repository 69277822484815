import styled from 'styled-components';
import {
  Typography,
  Modal,
} from '@mui/material';

import { ReactComponent as AnnouncementIcon } from '../../../assets/icons/announcement.svg';
import { ReactComponent as CrossIcon } from '../../../assets/icons/cross-rounded-icon.svg';
import Button from '../../../components/Button';
import colors from '../../../styles/colors';

const StyledModal = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContentWrapper = styled.div`
  outline: 0;
  height: fit-content;
  width: 500px;
  background-color: ${colors.base.alpha};
  border-radius: 8px;
  border: 1px solid ${colors.shades.gamma3};
  position: relative;
`;

const AnnouncementSection = styled.div`
  padding: 40px 36px 18px;
`;

const SettingsActionsSection = styled.div`
  padding: 40px 36px;
`;

const Announcement = styled(AnnouncementIcon)`
  width: 20px;
  height: 20px;
  margin-bottom: 30px;
`;

const Cross = styled(CrossIcon)`
  width: 10px;
  height: 10px;
`;

const CrossButton = styled.div`
  width: 34px;
  height: 34px;
  background: ${colors.base.secondary2};
  display: grid;
  position: absolute;
  place-items: center;
  top: -16px;
  right: -16px;
  border: 1px solid ${colors.base.beta};
  border-radius: 50%;
  cursor: pointer;
`;

const Title = styled(Typography)`
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
`;

const SubTitle = styled(Typography)`
  font-size: 14px;
  line-height: 16px;
  margin-top: 8px;
`;

const StyledList = styled.ul`
  line-height: 18px;
  margin-block: unset;
  margin-inline: unset;
  padding-inline: unset;
`;

const StyledListElement = styled.li`
  margin-bottom: 20px;

  &::marker {
    color: ${colors.shades.gamma2};
  }
`;

const StyledButton = styled(Button)`
  height: 40px;
  border: 1px solid ${colors.shades.primary2};
  border-radius: 5px;
  margin-top: 8px;
  cursor: pointer;
`;

const StyledTypography = styled(Typography)`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: unset;
`;

export {
  StyledModal,
  ModalContentWrapper,
  AnnouncementSection,
  SettingsActionsSection,
  StyledList,
  StyledListElement,
  Announcement,
  CrossButton,
  StyledButton,
  Cross,
  Title,
  SubTitle,
  StyledTypography,
};
