import styled from 'styled-components';
import { Box } from '@mui/material';
import { ToastContainer } from 'react-toastify';

const Container = styled(Box)`
  display: flex;
  height: 100vh;
  overflow: hidden;
`;

const MainBox = styled(Box)`
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const ContentBox = styled(Box)`
  flex: 1;
  width: 100%;
  overflow-y: auto;
`;

const StyledToastContainer = styled(ToastContainer).attrs({
  className: 'toast-container',
})`
  min-width: 35%;
`;

export {
  Container,
  MainBox,
  ContentBox,
  StyledToastContainer,
};
