import React from 'react';
import { PropTypes } from 'prop-types';

import variants from '../../../../components/Card/variants';
import Button from '../../../../components/Button/Button';
import OnboardingCard from '../../../../components/OnboardingCard';

import {
  CardGrid,
  StyledActionFailed,
  NotificationCardTitle,
} from './styles';
import texts from './texts.json';

const FailureNotificationCard = ({ message, onConfirmingFinalResult }) => (
  <CardGrid>
    <OnboardingCard variant={variants.PRIMARY}>
      <NotificationCardTitle>{message}</NotificationCardTitle>
      <StyledActionFailed />
      <Button onClick={() => onConfirmingFinalResult(false)}>{texts.back}</Button>
    </OnboardingCard>
  </CardGrid>
);

FailureNotificationCard.propTypes = {
  message: PropTypes.string.isRequired,
  onConfirmingFinalResult: PropTypes.func.isRequired,
};

export default FailureNotificationCard;
