import React from 'react';
import PropTypes from 'prop-types';

import HistoryEntry from './HistoryEntry';

const NutritionHistory = ({ history }) => (
  history.length > 0 && history.map((historyItem, index) => (
    <HistoryEntry
      key={historyItem.updatedMacroAveragesAt}
      historyItem={historyItem}
      current={index === 0}
    />
  ))
);

NutritionHistory.propTypes = {
  history: PropTypes.array,
};

NutritionHistory.defaultProps = {
  history: [],
};

export default NutritionHistory;
