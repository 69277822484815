import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { LoadingButton } from '../../../components/Button';
import FormikInput, { FormikPhoneInput } from '../../../components/FormikInput';
import { getQueryVariable } from '../../../utils/queryParams';

import TermsAndConditions from '../../../components/TermsAndConditions';
import { validationSchema, initialValues } from './validation';
import texts from './texts.json';

// Update the initial email if provided via query param
const initialEmail = getQueryVariable('email');
if (initialEmail) {
  initialValues.email = initialEmail;
}

const SignUpForm = ({
  onSubmit,
  isProcessing,
}) => (
  <Formik
    initialValues={initialValues}
    validationSchema={validationSchema}
    onSubmit={onSubmit}
  >
    {({
      errors,
      touched,
      isSubmitting,
      values,
    }) => (
      <Form>
        <FormikInput
          name="email"
          type="email"
          placeholder="Email"
          error={errors.email}
          touched={touched.email}
        />
        <FormikInput
          name="password"
          placeholder="Password"
          error={errors.password}
          touched={touched.password}
          type="password"
        />
        <FormikInput
          name="passwordConfirmation"
          placeholder="Confirm Password"
          error={errors.passwordConfirmation}
          touched={touched.passwordConfirmation}
          type="password"
        />
        <FormikInput
          name="firstName"
          placeholder="First Name"
          error={errors.firstName}
          touched={touched.firstName}
        />
        <FormikInput
          name="lastName"
          placeholder="Last Name"
          error={errors.lastName}
          touched={touched.lastName}
        />
        <FormikPhoneInput name="phoneNumber" />
        <FormikInput
          name="acceptTerms"
          type="checkbox"
          error={errors.acceptTerms}
          touched={touched.acceptTerms}
          labelContent={<TermsAndConditions />}
        />
        <LoadingButton
          isLoading={isSubmitting || isProcessing}
          disabled={isSubmitting || isProcessing || !values.acceptTerms}
          type="submit"
        >
          {texts.signUp}
        </LoadingButton>
      </Form>
    )}
  </Formik>
);

SignUpForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isProcessing: PropTypes.bool,
};

SignUpForm.defaultProps = {
  isProcessing: false,
};

export default SignUpForm;
