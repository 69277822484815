import React from 'react';
import PropTypes from 'prop-types';
import format from 'string-template';
import { IconButton } from '@mui/material';
import { Draggable } from 'react-smooth-dnd';

import { ReactComponent as DragIcon } from '../../../../../../../assets/icons/v2/drag.svg';
import { TimeUnit, convertSeconds } from '../../../../../../../utils/time';
import { ActivityTypes } from '../../../../../../../Model/Activity';
import { InfoChip } from '../../styles';
import Note from '../Note';
import ActionButtons from '../ActionButtons';
import {
  Container,
  ThumbnailWrapper,
  Thumbnail,
  StyledAvatar,
  DetailsContainer,
  ChipContainer,
  RestWarning,
  ActivityWrapper,
} from './styles';
import texts from './texts.json';

const Activity = ({
  activity,
  rounds,
  tag,
  showTopConnector,
  showRestWarning,
  showBottomConnector,
  onRemove,
  onDuplicate,
  onClickEdit,
}) => {
  const {
    name,
    videoPreviewThumbnail,
    restTime,
    restTimeDisplayUnit,
    goal,
    side,
    note,
    type: activityType,
    duration,
    durationDisplayUnit,
  } = activity;

  if (activityType === ActivityTypes.REST) {
    return (
      <Draggable key={tag}>
        <ActivityWrapper>
          <Container $isRest>
            <IconButton className="drag-handle">
              <DragIcon />
            </IconButton>
            <StyledAvatar>
              {tag}
            </StyledAvatar>
            {format(
              durationDisplayUnit === TimeUnit.MINUTES
                ? texts.minuteRest
                : texts.secondRest,
              {
                restTime: convertSeconds(duration, durationDisplayUnit),
                s: convertSeconds(duration, durationDisplayUnit) > 1 ? 's' : '',
              },
            )}
            <ActionButtons
              onRemove={onRemove}
              onDuplicate={onDuplicate}
              onUpdate={onClickEdit}
            />
          </Container>
        </ActivityWrapper>
      </Draggable>
    );
  }

  const renderActivityRequirements = () => {
    // We display only the goal by default.
    let requirement = goal;
    // If there is any side information, then we add this to the requirement text.
    if (side) {
      const sideText = texts.sideLabel[side];
      requirement = `${requirement} - ${sideText}`;
    }
    return <InfoChip label={requirement} />;
  };

  return (
    <Draggable key={tag}>
      <ActivityWrapper>
        <Container $showRestWarning={showRestWarning}>
          <IconButton className="drag-handle">
            <DragIcon />
          </IconButton>
          <StyledAvatar
            $showTopConnector={showTopConnector}
            $showBottomConnector={showBottomConnector}
          >
            {tag}
          </StyledAvatar>
          {!!videoPreviewThumbnail && (
            <ThumbnailWrapper>
              <Thumbnail src={videoPreviewThumbnail} />
            </ThumbnailWrapper>
          )}
          <DetailsContainer>
            {name}
            <ChipContainer>
              {rounds !== 1 && <InfoChip label={format(texts.rounds, { rounds })} />}
              {renderActivityRequirements()}
              {!!restTime && (
                <InfoChip
                  $isRest
                  label={format(
                    restTimeDisplayUnit === TimeUnit.MINUTES
                      ? texts.minuteRest
                      : texts.secondRest,
                    {
                      restTime: convertSeconds(restTime, restTimeDisplayUnit),
                      s: convertSeconds(restTime, restTimeDisplayUnit) > 1 ? 's' : '',
                    },
                  )}
                />
              )}
            </ChipContainer>
            {!!note && <Note text={note} />}
          </DetailsContainer>
          <ActionButtons
            onRemove={onRemove}
            onDuplicate={onDuplicate}
            onUpdate={onClickEdit}
          />
        </Container>
        {showRestWarning && <RestWarning>{texts.restWarning}</RestWarning>}
      </ActivityWrapper>
    </Draggable>
  );
};

Activity.propTypes = {
  activity: PropTypes.object.isRequired,
  rounds: PropTypes.number,
  tag: PropTypes.string.isRequired,
  showTopConnector: PropTypes.bool,
  showBottomConnector: PropTypes.bool,
  showRestWarning: PropTypes.bool,
  onRemove: PropTypes.func.isRequired,
  onDuplicate: PropTypes.func.isRequired,
  onClickEdit: PropTypes.func.isRequired,
};

Activity.defaultProps = {
  rounds: 1,
  showTopConnector: false,
  showBottomConnector: false,
  showRestWarning: false,
};

export default Activity;
