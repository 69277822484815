import React, {
  useContext,
  useCallback,
  useState,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import format from 'string-template';
import { Tooltip } from '@mui/material';

import { ReactComponent as ReviewIcon } from '../../../../../../assets/icons/v2/arrow-right.svg';
import { ReactComponent as DuplicateIcon } from '../../../../../../assets/icons/v2/duplicate.svg';
import { PrimaryButton } from '../../../../../../components/Button/ActionButtons';
import { InfoTag } from '../../../../../../components/Tags';
import { ReactComponent as CreateIcon } from '../../../../../../assets/icons/v2/creation-plus-circle.svg';
import {
  HeaderRow,
  Title,
  TitleContainer,
} from '../../../../../../components/v2/Header';
import useQuickSearch from '../../../../../../hooks/useQuickSearch';
import useComponentMounted from '../../../../../../hooks/useComponentMounted';
import { CoachingActivity } from '../../../../../../utils/log';
import useLogger from '../../../../../../hooks/useLogger';
import useToast from '../../../../../hooks/useToast';
import Program, { DifficultyLevel } from '../../../../../../Model/Program';
import GenericDataGrid from '../../../../../components/GenericDataGrid';
import LabelCheckbox from '../../../../../components/LabelCheckbox';
import MultiSelectFilter from '../../../../../components/MultiSelectFilter';
import LoadingOverlay from '../../../../../components/LoadingOverlay';
import ManageProgramContext, {
  withManageProgramContextReady,
} from '../../../../../context/ManageProgramContext';
import QuickSearchToolbar from '../../../../../components/QuickSearchToolbar';
import {
  ActionType,
} from '../../utils';
import ConfirmDialog from '../../../../../components/ConfirmDialog';

import getColumns from './columns';
import {
  ChipContainer,
  StyledArchiveIcon,
  ActionButtonContainer,
} from './styles';
import texts from './texts.json';

const VISIBLE_TAGS_COUNT = 1;

const difficultyOptions = Object.values(DifficultyLevel).map((category) => ({
  value: category,
  label: category,
}));

const ProgramsTable = ({ handleActionClick }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [actionPerformed, setActionPerformed] = useState(null);
  const [selectedDifficulty, setSelectedDifficulty] = useState([]);
  const [selectedEquipments, setSelectedEquipments] = useState([]);
  const {
    coachPrograms,
    showArchivedCoachPrograms,
    refetchCoachPrograms,
    equipmentList,
  } = useContext(ManageProgramContext);

  const isComponentMountedRef = useComponentMounted();

  const { showToast } = useToast();
  const { logCoachingActivity } = useLogger();

  const renderEquipmentCell = useCallback(
    ({ row: { equipment } = {} }) => {
      if (equipment && equipment.length > 0) {
        const firstEquipmentItem = equipment.slice(0, VISIBLE_TAGS_COUNT);
        const remainingEquipment = equipment.slice(VISIBLE_TAGS_COUNT, equipment.length);
        const tooltipText = remainingEquipment.join(', ');
        return (
          <ChipContainer>
            {firstEquipmentItem.map((firstEquipment) => (
              <InfoTag key={firstEquipment}>{firstEquipment}</InfoTag>
            ))}
            {!!remainingEquipment.length && (
              <Tooltip title={tooltipText} placement="top" arrow>
                <InfoTag>{format(texts.moreEquipment, { amount: remainingEquipment.length })}</InfoTag>
              </Tooltip>
            )}
          </ChipContainer>
        );
      }
      return (
        <>
          {texts.emptyCell}
        </>
      );
    }, [],
  );

  const renderActionCell = useCallback(
    ({ row }) => {
      const onActionClick = (action) => {
        setSelectedProgram(row);
        setActionPerformed(action);
      };

      return (
        <ActionButtonContainer>
          <PrimaryButton
            onClick={() => onActionClick(row.isArchived ? ActionType.UNARCHIVE : ActionType.ARCHIVE)}
            icon={<StyledArchiveIcon />}
            variant="info"
            size="medium"
          >
            {row.isArchived ? texts.unarchive : texts.archive}
          </PrimaryButton>
          <PrimaryButton
            onClick={() => onActionClick(ActionType.DUPLICATE)}
            endIcon={<DuplicateIcon />}
            variant="info"
            size="medium"
          >
            {texts.duplicate}
          </PrimaryButton>
          <PrimaryButton
            onClick={() => handleActionClick(row, ActionType.UPDATE)}
            endIcon={<ReviewIcon />}
            variant="info"
            size="medium"
          >
            {texts.edit}
          </PrimaryButton>
        </ActionButtonContainer>
      );
    },
    [
      handleActionClick,
    ],
  );

  const onActionPerformed = useCallback(async () => {
    setIsLoading(true);
    if (actionPerformed === ActionType.ARCHIVE) {
      await selectedProgram.archive();
      logCoachingActivity(CoachingActivity.ARCHIVED_PROGRAM, { programId: selectedProgram.id });
    } else if (actionPerformed === ActionType.UNARCHIVE) {
      await selectedProgram.unarchive();
      logCoachingActivity(CoachingActivity.UNARCHIVED_PROGRAM, { programId: selectedProgram.id });
    } else if (actionPerformed === ActionType.DUPLICATE) {
      const {
        name,
        coach,
        difficulty,
        equipment = [],
        workouts = [],
      } = selectedProgram.data;
      const duplicateName = `${name} ${texts.copyAppendText}`;
      const newProgramData = {
        name: duplicateName,
        coach,
        equipment,
        workouts,
        createdAt: Date.now(),
        isArchived: false,
        ...difficulty && { difficulty },
      };

      await Program.addDoc(newProgramData);
    }
    if (isComponentMountedRef.current) {
      showToast(
        format(texts.actionSuccessMsg, {
          action: actionPerformed.toLowerCase(),
          program: selectedProgram.name,
        }),
      );
      setIsLoading(false);
      setSelectedProgram(null);
    }
  }, [
    showToast,
    selectedProgram,
    actionPerformed,
    isComponentMountedRef,
    logCoachingActivity,
  ]);

  const columns = getColumns({
    actionsRenderCell: renderActionCell,
    equipmentRenderCell: renderEquipmentCell,
  });

  const {
    filteredRows: quickSearchRows,
    toolbarProps,
  } = useQuickSearch(coachPrograms, columns);

  const filteredRows = useMemo(() => {
    let filteredPrograms = quickSearchRows;
    if (selectedDifficulty.length > 0) {
      filteredPrograms = filteredPrograms.filter((program) => selectedDifficulty.includes(program.difficulty));
    }
    if (selectedEquipments.length > 0) {
      filteredPrograms = filteredPrograms.filter(
        (program) => program.equipment.some((equipment) => selectedEquipments.includes(equipment)),
      );
    }
    return filteredPrograms;
  }, [
    quickSearchRows,
    selectedDifficulty,
    selectedEquipments,
  ]);

  const onCloseConfirmModal = () => {
    setActionPerformed(null);
    setSelectedProgram(null);
  };

  return (
    <>
      <HeaderRow>
        <TitleContainer>
          <Title>{texts.title}</Title>
        </TitleContainer>
        <PrimaryButton
          onClick={() => handleActionClick(null, ActionType.CREATE)}
          icon={<CreateIcon />}
        >
          {texts.createProgram}
        </PrimaryButton>
      </HeaderRow>
      <GenericDataGrid
        rows={filteredRows}
        columns={columns}
        components={{
          Toolbar: QuickSearchToolbar,
        }}
        componentsProps={{
          toolbar: {
            ...toolbarProps,
            placeholder: texts.searchPlaceholder,
            filterTools: [
              {
                Component: LabelCheckbox,
                id: 'archive-filter',
                props: {
                  isChecked: showArchivedCoachPrograms,
                  description: texts.archivedCheckbox,
                  onChange: (showArchived) => refetchCoachPrograms(showArchived),
                },
              },
              {
                Component: MultiSelectFilter,
                id: 'difficulty-filter',
                props: {
                  description: texts.filter.difficulty,
                  options: difficultyOptions,
                  initialValues: selectedDifficulty,
                  onValuesSelected: setSelectedDifficulty,
                  disableValueRender: true,
                  hideAllOption: true,
                },
              },
              {
                Component: MultiSelectFilter,
                id: 'equipment-filter',
                props: {
                  description: texts.filter.equipment,
                  options: equipmentList,
                  initialValues: selectedEquipments,
                  onValuesSelected: setSelectedEquipments,
                  disableValueRender: true,
                  hideAllOption: true,
                },
              },
            ],
          },
        }}
      />
      <LoadingOverlay isLoading={isLoading} />
      <ConfirmDialog
        isOpen={!!selectedProgram}
        onConfirm={onActionPerformed}
        onCancel={onCloseConfirmModal}
        dialogTexts={{
          title: '',
          content: format(texts.actionConfirm, selectedProgram
            ? {
              program: selectedProgram.name,
              action: actionPerformed.toLowerCase(),
            }
            : ''),
        }}
      />
    </>
  );
};

ProgramsTable.propTypes = {
  handleActionClick: PropTypes.func.isRequired,
};

export default compose(
  withManageProgramContextReady,
  observer,
)(ProgramsTable);
