import React from 'react';
import { Typography } from '@mui/material';

import { Wrapper } from './styles';
import texts from './texts.json';

const NoDefaultMealPlanView = () => (
  <Wrapper>
    <Typography>{texts.description}</Typography>
  </Wrapper>
);

export default NoDefaultMealPlanView;
