import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';

import useNavigation from '../../../hooks/useNavigation';
import { configurationPagesConfig } from '../../config/configurationPages';
import useToolsNavigation from '../../hooks/useToolsNavigation';
import ConfigurationMenuContext from './ConfigurationMenuContext';

const ConfigurationMenuContextProvider = ({
  children,
}) => {
  const {
    params: {
      userId: coachId,
    },
  } = useRouteMatch();
  const [selectedConfiguration, setSelectedConfiguration] = useState('');

  const { navigateToConfigPage } = useToolsNavigation();

  const {
    location: {
      pathname,
    },
  } = useNavigation();

  useEffect(() => {
    // Get the last part of the pathname to detect if it is a valid config page.
    const currentConfigPage = pathname.split('/').reverse()[0];

    // Get the config object from the path we extracted from the url
    const [configPage] = Object.values(configurationPagesConfig).filter(({ path }) => path === currentConfigPage);

    if (configPage) {
      setSelectedConfiguration(configPage.id);
    }
  }, [pathname]);

  /**
   * Navigate to a config page by providing the config page id.
   */
  const navigateToConfig = useCallback((configId) => {
    const configPath = configurationPagesConfig[configId].path;

    navigateToConfigPage(coachId, configPath);
  }, [
    coachId,
    navigateToConfigPage,
  ]);

  const context = useMemo(() => ({
    selectedConfiguration,
    navigateToConfig,
  }), [
    selectedConfiguration,
    navigateToConfig,
  ]);

  return (
    <ConfigurationMenuContext.Provider value={context}>
      {children}
    </ConfigurationMenuContext.Provider>
  );
};

ConfigurationMenuContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ConfigurationMenuContextProvider;
