import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as NavigateIcon } from '../../assets/icons/navigate.svg';
import { PrimaryButton } from '../Button/ActionButtons';
import InputLabel from '../v2/InputLabel';

import {
  Container,
  ButtonsContainer,
} from './styles';
import texts from './texts.json';

const OnCallActions = ({
  actions,
  className,
}) => (
  <Container className={className}>
    <InputLabel>{texts.title}</InputLabel>
    <ButtonsContainer>
      {actions.map(({
        onClick,
        disabled,
        label,
      }) => (
        <PrimaryButton
          key={label}
          icon={<NavigateIcon />}
          onClick={onClick}
          disabled={disabled}
          size="small"
          variant="light"
        >
          {label}
        </PrimaryButton>
      ))}
    </ButtonsContainer>
  </Container>
);

OnCallActions.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string,
};

OnCallActions.defaultProps = {
  actions: [],
  className: '',
};

export default OnCallActions;
