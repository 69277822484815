import React from 'react';
import PropTypes from 'prop-types';
import { InputAdornment } from '@mui/material';

import TextField from '../TextField';
import { getCurrencySymbol } from '../../../utils/formatters';
import texts from './texts.json';

const PriceInput = ({
  currency,
  value,
  ...props
}) => (
  <TextField
    {...props}
    value={value}
    type="number"
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          {getCurrencySymbol(currency)}
        </InputAdornment>
      ),
    }}
    error={value <= 0}
    helperText={value <= 0
      ? texts.errors.positiveNumber : ''}
  />
);

PriceInput.propTypes = {
  currency: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default PriceInput;
