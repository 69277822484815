import React, {
  useCallback,
  useContext,
} from 'react';
import format from 'string-template';
import { Redirect } from 'react-router-dom';
import { Button } from '@mui/material';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import * as Sentry from '@sentry/browser';

import { ReactComponent as RefreshIcon } from '../../../../../../assets/icons/v2/creation-refresh.svg';
import { ReactComponent as CheckIcon } from '../../../../../../assets/icons/v2/check-circle.svg';
import { ReactComponent as PeopleIcon } from '../../../../../../assets/icons/v2/people.svg';
import { getQueryVariable } from '../../../../../../utils/queryParams';
import { PrimaryButton } from '../../../../../../components/Button/ActionButtons';
import { MealPlannerType, getTabPath } from '../../../../../routes/mealPlanner/mealPlannerPages';
import useMealPlanNavigation from '../../../../../hooks/useMealPlanNavigation';
import useToolsNavigation from '../../../../../hooks/useToolsNavigation';
import useToast from '../../../../../hooks/useToast';
import { withMealPlanContextReady } from '../../../../../context/MealPlanContext';
import MealPlanAssignmentContext, {
  withMealPlanAssignmentContextProvider,
  withMealPlanAssignmentContextReady,
} from '../../../../../context/MealPlanAssignmentContext';
import ChatButton from '../../../../../components/ChatButton';
import ClientQuickInfoButton from '../../../../../components/ClientQuickInfoButton';
import UserPhysicalAttributes from '../../../components/UserPhysicalAttributes';
import UserNutritionalGoals from '../../../components/UserNutritionalGoals';
import UserMealPlan from '../../../components/UserMealPlan';
import { TitleIconsContainer } from '../../styles';
import {
  UserInfoContainer,
  Container,
  StyledAvatar,
  UserInfoTitle,
  SectionContainer,
} from '../MealPlanRefresh/styles';

import {
  ButtonContainer,
} from './styles';
import texts from './texts.json';

const MealPlanAssigment = () => {
  const user = getQueryVariable('user');

  const {
    mealPlanAssignmentDoc = {},
    userDoc = {},
    macroGoalsDoc = {},
  } = useContext(MealPlanAssignmentContext);

  const {
    navigateToNewAssignmentPage,
    navigateToRefreshPage,
  } = useMealPlanNavigation();

  const { navigateToClientPage } = useToolsNavigation();

  const { showToast } = useToast();

  const {
    id: userId,
    name: userName,
    avatarUrl: userAvatar,
  } = userDoc;

  const isMealPlanAssignable = () => ((
    !mealPlanAssignmentDoc?.status && !!macroGoalsDoc?.totalDailyCalories
  ));

  const renderAvatar = useCallback(() => {
    if (userAvatar) {
      return <StyledAvatar src={userAvatar} />;
    }
    return <StyledAvatar>{userName?.substring(0, 1)}</StyledAvatar>;
  }, [
    userAvatar,
    userName,
  ]);

  const saveMacroGoals = useCallback(async (value) => {
    try {
      // Save previous goals
      await macroGoalsDoc?.saveHistory(
        mealPlanAssignmentDoc?.mealPlanId,
        mealPlanAssignmentDoc?.name,
        mealPlanAssignmentDoc?.macroAverages,
      );
      // Update nutritional goals
      await macroGoalsDoc?.updateMacroGoals(value);
      showToast(texts.nutritionalGoalsUpdated);
    } catch (error) {
      Sentry.captureException(error);
      showToast(texts.nutritionalGoalsUpdateFailed, { error: true });
    }
  }, [
    macroGoalsDoc,
    mealPlanAssignmentDoc,
    showToast,
  ]);

  if (!user) {
    return <Redirect to={getTabPath(MealPlannerType.CLIENT_MEAL_ASSIGNMENTS)} />;
  }

  return (
    <Container>
      <UserInfoContainer>
        {renderAvatar()}
        <UserInfoTitle>
          {format(texts.title, { userName })}
          <TitleIconsContainer>
            <ChatButton
              userId={userId}
              popperPlacement="right-start"
              iconVariant="secondary"
              smallButton
            />
            <ClientQuickInfoButton userId={userId} />
          </TitleIconsContainer>
        </UserInfoTitle>
        <ButtonContainer>
          {!!isMealPlanAssignable() && (
            <PrimaryButton
              component={Button}
              icon={<CheckIcon />}
              onClick={() => navigateToNewAssignmentPage(user)}
            >
              {texts.assignMealPlan}
            </PrimaryButton>
          )}
          {!!mealPlanAssignmentDoc && (
            <PrimaryButton
              component={Button}
              icon={<RefreshIcon />}
              onClick={() => navigateToRefreshPage(user)}
            >
              {texts.refreshMealPlan}
            </PrimaryButton>
          )}
          <PrimaryButton
            variant="light"
            component={Button}
            icon={<PeopleIcon />}
            onClick={() => navigateToClientPage(user)}
          >
            {texts.viewClientProfile}
          </PrimaryButton>
        </ButtonContainer>
      </UserInfoContainer>
      <SectionContainer>
        <UserPhysicalAttributes />
      </SectionContainer>
      <SectionContainer>
        <UserNutritionalGoals
          isOnlyManuallyEditable
          onSelectNutritionalGoals={saveMacroGoals}
        />
      </SectionContainer>
      <SectionContainer>
        <UserMealPlan
          isEditable={false}
          scaleMeals={!!macroGoalsDoc?.totalDailyCalories}
          totalDailyCalories={macroGoalsDoc?.totalDailyCalories}
          macrosPercentages={
            macroGoalsDoc?.macroAverages?.percentages
            || mealPlanAssignmentDoc?.macroAverages?.percentages
          }
        />
      </SectionContainer>
    </Container>
  );
};

export default compose(
  withMealPlanContextReady,
  withMealPlanAssignmentContextProvider,
  withMealPlanAssignmentContextReady,
  observer,
)(MealPlanAssigment);
