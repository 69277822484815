import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { IconButton } from '@mui/material';

import { StyledTrashIcon } from '../../styles';

import {
  Container,
  ImageContainer,
  ButtonContainer,
  StyledPictureIcon,
  StyledFileSelectButton,
  RecipeImage,
} from './styles';

import texts from './texts';

const ImageSelect = ({
  image,
  onImageSelect,
  onImageRemove,
}) => {
  const onFileSelect = useCallback((event) => {
    const { files } = event.target;
    if (files.length) {
      const [file] = files;
      onImageSelect(file);
    }
  }, [
    onImageSelect,
  ]);

  const onFileRemove = useCallback(() => {
    onImageRemove();
  }, [
    onImageRemove,
  ]);

  return (
    <Container>
      {image && (
        <ButtonContainer>
          <IconButton onClick={onFileRemove}>
            <StyledTrashIcon />
          </IconButton>
        </ButtonContainer>
      )}
      <ImageContainer>
        <RecipeImage src={image} alt="" />
      </ImageContainer>
      <StyledFileSelectButton
        onSelect={onFileSelect}
        multiple={false}
        extraInputProps={{ accept: 'image/*' }}
        extraButtonProps={{ variant: 'contained' }}
      >
        <StyledPictureIcon />
        {texts.uploadImage}
      </StyledFileSelectButton>
    </Container>
  );
};

ImageSelect.propTypes = {
  image: PropTypes.string.isRequired,
  onImageSelect: PropTypes.func.isRequired,
  onImageRemove: PropTypes.func.isRequired,
};

export default ImageSelect;
