import React from 'react';
import PropTypes from 'prop-types';

import colors from '../../../../styles/colors';
import {
  FeaturesListWrapper,
  LabeledList,
  FeaturesTitle,
  List,
  Feature,
} from './styles';
import texts from './texts';

const featuresListConfig = {
  enabled: {
    title: texts.enabledFeatures,
    titleColor: colors.shades.success3,
    itemAdornment: '+',
  },
  disabled: {
    title: texts.disabledFeatures,
    titleColor: colors.base.danger,
    itemAdornment: '-',
  },
};

const FeaturesList = ({
  productDoc,
}) => {
  const featuresConfig = productDoc.getFeaturesConfig();

  const renderFeatureList = (config, features) => {
    if (!features.length) {
      return null;
    }

    const {
      title,
      titleColor,
      itemAdornment,
    } = config;

    return (
      <LabeledList>
        <FeaturesTitle $color={titleColor}>{title}</FeaturesTitle>
        <List>
          {features.map((featureId) => (
            <Feature
              key={featureId}
              $adornment={itemAdornment}
            >
              {texts[featureId] || featureId}
            </Feature>
          ))}
        </List>
      </LabeledList>
    );
  };

  return (
    <FeaturesListWrapper>
      {renderFeatureList(featuresListConfig.enabled, featuresConfig.enabled)}
      {renderFeatureList(featuresListConfig.disabled, featuresConfig.disabled)}
    </FeaturesListWrapper>
  );
};

FeaturesList.propTypes = {
  productDoc: PropTypes.object.isRequired,
};

export default FeaturesList;
