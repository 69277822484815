import styled from 'styled-components';
import { FormHelperText } from '@mui/material';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 0;
  margin-right: 15px;
  
  .MuiCheckbox-root {
    padding-right: 5px;
  }
`;

const StyledFormHelperText = styled(FormHelperText)`
  margin-top: 0;
  font-size: 12px;
  line-height: 14px;
  color: unset;
`;

export {
  Container,
  StyledFormHelperText,
};
