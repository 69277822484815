import React, { useContext } from 'react';

import LoadingPage from '../../components/LoadingPage';
import WebCustomizationContext from './WebCustomizationContext';
import WebCustomizationContextProvider from './WebCustomizationContextProvider';

const withWebCustomizationContextProvider = (Component) => (props) => (
  <WebCustomizationContextProvider>
    <Component {...props} />
  </WebCustomizationContextProvider>
);

const withWebCustomizationContextReady = (Component) => (props) => {
  const { isReady } = useContext(WebCustomizationContext);
  return isReady ? <Component {...props} /> : <LoadingPage />;
};

export {
  withWebCustomizationContextProvider,
  withWebCustomizationContextReady,
};
