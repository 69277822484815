import styled from 'styled-components';
import { FormControlLabel } from '@mui/material';

import { ReactComponent as SelectedIcon } from '../../../assets/icons/selected-icon.svg';
import { ReactComponent as NotSelectedIcon } from '../../../assets/icons/not-selected-icon.svg';

import colors from '../../../styles/colors';

const iconStyle = `
  width: 20px;
  height: 20px;
`;

const StyledSelectedIcon = styled(SelectedIcon)`
  ${iconStyle}
`;

const StyledNotSelectedIcon = styled(NotSelectedIcon)`
  ${iconStyle}
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'fit-content')};
`;

const StyledOption = styled(FormControlLabel)`
  ${(props) => props.$showStyledOptions && `
    border-radius: 72px;
    background-color: ${props.$isSelected ? colors.base.secondary2 : colors.shades.gamma5};
    border: 1px solid ${props.$isSelected ? colors.base.beta : colors.base.gamma3};
    &:hover {
      background-color: ${props.$isSelected ? colors.shades.secondary8 : colors.shades.gamma1};
    }
    color: ${props.$isSelected ? colors.base.alpha : colors.base.beta};
  `};
  margin-left: 0px;
  border-radius: 72px;
  align-items: center;
  .MuiFormControlLabel-root {
    display: flex;
    flex-direction: row;
  }
  .MuiFormControlLabel-label {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    justify-content: center;
    padding: 0 10px 0 0;
  }
  .MuiFormControlLabel-label.Mui-disabled {
    color: ${colors.shades.gamma2};
  }
`;

export {
  Container,
  StyledOption,
  StyledNotSelectedIcon,
  StyledSelectedIcon,
};
