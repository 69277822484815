import styled from 'styled-components';

import LoadingPage from '../../../../../components/LoadingPage';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 100px;
  min-width: 100px;
`;

const StyledLoadingPage = styled(LoadingPage)`
  height: 100%;
`;

export {
  Container,
  StyledLoadingPage,
};
