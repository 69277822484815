import styled from 'styled-components';

import { body1 } from '../../styles/text';

import Button, { LinkButton } from '../Button';
import ModalComponent from '../Modal';

const Text = styled.span`
  ${body1}
  font-size: 12px;
  line-height: 18px;
`;

const Link = styled(LinkButton)`
  font-size: 12px;
  display: inline-block;
  text-transform: none;
  padding: 0;
  letter-spacing: normal;
  text-decoration: underline;
`;

const Modal = styled(ModalComponent)`
  .modal-wrapper {
    --height: 90%;
    --width: 90%;
  }
`;

const Container = styled.div`
  padding: 8px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  height: 100%;
`;

const StyledButton = styled(Button)`
  margin-top: 16px;
  max-width: 300px;
`;

export {
  Text,
  Link,
  Modal,
  Container,
  StyledButton,
};
