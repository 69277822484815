import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';

import LoadingPage from '../../components/LoadingPage';

import OnboardingFlowConfigContextProvider from './OnboardingFlowConfigContextProvider';
import OnboardingFlowConfigContext from './OnboardingFlowConfigContext';

/**
 * Inserts OnboardingFlowConfigContextProvider in the react tree
 * before adding the given component.
 *
 * @param {Object} Component The component that needs the context provider.
 * @returns {Object} The component to render wrapped with the provider.
 */
const withOnboardingFlowConfigContextProvider = (Component) => (props) => (
  <OnboardingFlowConfigContextProvider>
    <Component {...props} />
  </OnboardingFlowConfigContextProvider>
);

/**
 * Renders the given component when the Onboarding Flow Config Context is ready
 * if not, it will render the LoadingPage component.
 * Also checks if the user is already onboarded. If that's the case, it redirects
 * the user to the home page, out of the onboarding flow.
 * If the needed url params are not found it redirects the user to the error page.
 *
 * @param {Object} Component The component to render.
 * @param {Object} Component The rendered component.
 * @returns {Object} The component to render or the LoadingPage component.
 */
const withOnboardingFlowConfigContextReady = (Component) => (props) => {
  const {
    isReady,
    isOnboarded,
    paramsAvailable,
  } = useContext(OnboardingFlowConfigContext);

  if (!isReady) {
    return <LoadingPage />;
  }

  if (isOnboarded) {
    return <Redirect to={{ pathname: '/home' }} />;
  }

  if (!paramsAvailable) {
    return <Redirect to={{ pathname: '/error' }} />;
  }

  return <Component {...props} />;
};

export {
  withOnboardingFlowConfigContextReady,
  withOnboardingFlowConfigContextProvider,
};
