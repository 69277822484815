const QUESTIONS_SEPARATOR = '###';
const NOTES_KEY = 'notes';

const getFormAnswersFromData = (formData) => {
  let data = [];

  const {
    responses, // This property is included in legacy documents.
    answers,
    notes,
  } = formData;

  // Use the answers array if available (it takes precedence over the responses array).
  if (answers) {
    data = answers.map(({ title, value }) => {
      const answer = Array.isArray(value) ? value.join(', ') : value;

      return {
        question: title,
        answer,
      };
    });
  } else if (responses) {
    const parsedResponses = responses
      .split(QUESTIONS_SEPARATOR)
      // Remove hidden fields
      .filter((response) => response.length > 0 && !/hidden field/.test(response));

    data = parsedResponses.map((response) => {
      const regex = /\s*(.*?[?:])\s*(.*)\s*/m;
      const [, question, answer] = response.match(regex);
      return ({
        question,
        answer,
      });
    });
  }

  // if the document includes notes, then we add them at the end of the list.
  if (notes) {
    data = [
      ...data,
      {
        question: NOTES_KEY,
        answer: notes,
      },
    ];
  }

  return data;
};

export {
  getFormAnswersFromData,
};
