import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@mui/material';

import { ReactComponent as EditIcon } from '../../../assets/icons/v2/edit.svg';

import { StyledAddEditButton } from './styles';

const EditButton = ({
  onClick,
  children,
  disabled,
}) => (
  <StyledAddEditButton
    onClick={onClick}
    startIcon={<EditIcon />}
    component={Button}
    disabled={disabled}
  >
    {children}
  </StyledAddEditButton>
);

EditButton.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

EditButton.defaultProps = {
  children: [],
  onClick: null,
  disabled: false,
};

export default EditButton;
