const Environment = {
  DEVELOPMENT: 'DEVELOPMENT',
  PRODUCTION: 'PRODUCTION',
};

const env = window.system2.isDev ? Environment.DEVELOPMENT : Environment.PRODUCTION;

const isDev = env === Environment.DEVELOPMENT;

const isTestRun = !!window.system2.isTestRun;

export {
  Environment,
  env,
  isDev,
  isTestRun,
};
