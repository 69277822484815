import styled from 'styled-components';

const HEADER_MARGIN = 48;

const StyledIframe = styled.iframe`
  width: 100%;
  height: ${({ $height }) => ($height ? `${$height + HEADER_MARGIN}px` : '100%')};
  border-style: none;
  display: ${({ $hidden }) => ($hidden ? 'none' : 'block')}
`;

export {
  StyledIframe,
};
