import styled from 'styled-components';

import Button from '../../../../components/Button';

const StyledButton = styled(Button)`
  height: 30px;
  width: unset;
`;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 20px;
`;

export {
  StyledButton,
  ButtonRow,
};
