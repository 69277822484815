import { Collection } from 'firestorter';
import { DateFormat, timeStampToStr } from '../utils/date';
import { getFeeAmountStr } from '../utils/fee';

import { ContractType, ContractStatus } from '../CoachTools/utils/userContract';
import { firestorePaths } from '../utils/firebasePaths';
import { formatCurrencyCents } from '../utils/formatters';
import { generateTerms } from '../CoachTools/utils/userContractTerms';
import BaseDocument from './BaseDocument';

// All contracts that don't have a version set are considered to be version 1.
const CONTRACT_VERSION_UNSET = 1;

class UserContract extends BaseDocument {
  constructor(id, opts) {
    super(`${firestorePaths.USER_CONTRACT}/${id}`, opts);
  }

  get type() {
    return this.data.type || ContractType.USER;
  }

  get usageCount() {
    return this.data.usageCount || 0;
  }

  get name() {
    return this.data.name || '';
  }

  get version() {
    return this.data.version || CONTRACT_VERSION_UNSET;
  }

  get current() {
    const currentFlag = this.data.current;

    // Backwards-compatibility: If the contract does not have a current flag, we consider it to be a current contract.
    return typeof currentFlag === 'boolean' ? currentFlag : true;
  }

  get leadId() {
    return this.data.leadId;
  }

  get coach() {
    return this.data.coach;
  }

  get coachName() {
    return this.data.coachName || '';
  }

  get insideSalesId() {
    return this.data.insideSalesId;
  }

  get productId() {
    return this.data.productId;
  }

  get productName() {
    return this.data.productName || '';
  }

  get price() {
    return this.data.price || {};
  }

  get priceId() {
    return this.price.priceId || '';
  }

  get monthlyPriceId() {
    return this.price.monthlyPriceId || '';
  }

  get totalPriceInCents() {
    return this.price.totalPriceInCents;
  }

  get totalPriceInCentsStr() {
    return formatCurrencyCents(this.totalPriceInCents, this.currency);
  }

  get currency() {
    return this.price.currency;
  }

  get minSubscriptionMonths() {
    return this.price.minSubscriptionMonths;
  }

  get recurringBillingMonths() {
    return this.price.recurringBillingMonths || 1;
  }

  get initialPaymentInCents() {
    return this.price.initialPaymentInCents;
  }

  get initialPaymentInCentsStr() {
    return formatCurrencyCents(this.initialPaymentInCents, this.currency);
  }

  get initialTerm() {
    return this.price.initialTerm;
  }

  get cancelAtPeriodEnd() {
    return !!this.price.cancelAtPeriodEnd;
  }

  get upfrontPaymentInCents() {
    return this.price.upfrontPaymentInCents || 0;
  }

  get startDate() {
    return this.data.startDate;
  }

  get userEmail() {
    return this.data.userEmail;
  }

  get userFirstName() {
    return this.data.userFirstName || '';
  }

  get userLastName() {
    return this.data.userLastName || '';
  }

  get userName() {
    return this.data.userName;
  }

  get userId() {
    return this.data.userId;
  }

  get fees() {
    return this.data.fees || {};
  }

  get baseFee() {
    return this.fees.baseFee || 0;
  }

  get baseFeeStr() {
    return getFeeAmountStr(this.totalPriceInCents, this.currency, this.baseFee);
  }

  get insideSalesFee() {
    return this.fees.insideSalesFee || 0;
  }

  get insideSalesFeeStr() {
    return getFeeAmountStr(this.totalPriceInCents, this.currency, this.insideSalesFee);
  }

  get assistantCoachFee() {
    return this.fees.assistantCoachFee || 0;
  }

  get assistantCoachFeeStr() {
    return getFeeAmountStr(this.totalPriceInCents, this.currency, this.assistantCoachFee);
  }

  get status() {
    return this.data.status;
  }

  get createdAt() {
    return this.data.createdAt;
  }

  get createdAtStr() {
    return timeStampToStr(this.createdAt, DateFormat.DATE_FORMAT_SLASH_WITH_TIME_FORMAT);
  }

  get updatedAt() {
    return this.data.updatedAt;
  }

  get updatedAtStr() {
    return timeStampToStr(this.updatedAt, DateFormat.DATE_FORMAT_SLASH_WITH_TIME_FORMAT);
  }

  get coachConditions() {
    return this.data.coachConditions;
  }

  get terms() {
    return this.data.terms || [];
  }

  get packageId() {
    return this.data.packageId || '';
  }

  get packageName() {
    return this.data.packageName || '';
  }

  get sellerId() {
    return this.data.sellerId || '';
  }

  async updateStatus(status) {
    await this.set({ status }, { merge: true });
  }

  async updateContractTermsFromContractData(updatedContract) {
    return this.updateFields({
      startDate: updatedContract.startDate.format(DateFormat.DEFAULT_DATE_FORMAT),
      updatedAt: new Date(),
      terms: generateTerms(updatedContract),
    });
  }

  /**
   * Get user contracts that meet the given conditions.
   * If a condition value is undefined, it will not added as a condition
   *
   * @param {array} conditions - The array of conditions, each condition has property, comparator & value properties.
   * property - The database field name.
   * comparator - What comparison to perform ['==','in','<=','!=','>=',...].
   * value - The value to set for the database field.
   * @returns {Promise<Collection>} The collection of userContracts.
   */
  static async getContractsBy(conditions) {
    const contractsCollection = new Collection(() => firestorePaths.USER_CONTRACT, {
      createDocument: ({ id }, opts) => new UserContract(id, opts),
      query: (ref) => {
        let query = ref;
        conditions.forEach(({ property, comparator, value }) => {
          if (value !== undefined) {
            query = query.where(property, comparator, value);
          }
        });
        return query;
      },
    });
    await contractsCollection.fetch();
    return contractsCollection;
  }

  static getContractsByCoach(coach) {
    return UserContract.getContractsBy([{
      property: 'coach',
      comparator: '==',
      value: coach,
    }]);
  }

  static getContractsBySellerId(sellerId) {
    return UserContract.getContractsBy([{
      property: 'sellerId',
      comparator: '==',
      value: sellerId,
    }]);
  }

  static async getSingleContractBy(property, value) {
    const contractsCollection = await UserContract.getContractsBy([{
      property,
      comparator: '==',
      value,
    }]);
    if (contractsCollection.hasDocs) {
      return contractsCollection.docs[0];
    }

    return null;
  }

  static async getContractsBySellerIdAndStatuses(sellerId, statuses, fromDate) {
    const query = [];

    if (sellerId) {
      query.push({
        property: 'sellerId',
        comparator: '==',
        value: sellerId,
      });
    }
    if (statuses) {
      query.push({
        property: 'status',
        comparator: 'in',
        value: statuses,
      });
    }
    if (fromDate) {
      query.push({
        property: 'startDate',
        comparator: '>=',
        value: fromDate,
      });
    }

    return UserContract.getContractsBy(query);
  }

  /*
    TODO: We cannot use getContractsBy because we have lost the flexibility that regular firebase queries offer (We'd
    have to also implement extra props like orderBy, limit, etc). We have to come up with a better solution.
  */
  static async getContractByLeadId(leadId) {
    const contractsCollection = new Collection(() => firestorePaths.USER_CONTRACT, {
      query: (ref) => ref
        .where('leadId', '==', leadId)
        .orderBy('createdAt', 'desc')
        .limit(1),
    });
    await contractsCollection.fetch();

    let doc = null;
    if (contractsCollection.hasDocs) {
      doc = new UserContract(contractsCollection.docs[0].id);
      await doc.init();
    }

    return doc;
  }

  static getContractsByLeadId(leadId) {
    return UserContract.getContractsBy([{
      property: 'leadId',
      comparator: '==',
      value: leadId,
    }]);
  }

  static async getContractByUserId(userId) {
    return UserContract.getSingleContractBy('userId', userId);
  }

  static async getContractsByUserId(userId) {
    return UserContract.getContractsBy([{
      property: 'userId',
      comparator: '==',
      value: userId,
    }]);
  }

  static async getReonboardingContractsCollectionForUser(userId) {
    const contractsCollection = new Collection(() => firestorePaths.USER_CONTRACT, {
      createDocument: ({ id }, opts) => new UserContract(id, opts),
      query: (ref) => ref
        .where('userId', '==', userId)
        .where('status', 'in', [ContractStatus.IN_PROGRESS, ContractStatus.PENDING]),
    });
    await contractsCollection.fetch();
    return contractsCollection;
  }

  static async getBaseContracts(fromDate = '') {
    const contractsCollection = new Collection(() => firestorePaths.USER_CONTRACT, {
      createDocument: ({ id }, opts) => new UserContract(id, opts),
      query: (ref) => {
        let query = ref.where('type', '==', ContractType.BASE);
        if (fromDate) {
          query = query.where('createdAt', '>=', new Date(fromDate));
        }
        return query;
      },
    });
    await contractsCollection.fetch();
    return contractsCollection;
  }

  static async getBaseContractByPriceId(priceId) {
    const contractsCollection = new Collection(() => firestorePaths.USER_CONTRACT, {
      createDocument: ({ id }, opts) => new UserContract(id, opts),
      query: (ref) => ref
        .where('price.priceId', '==', priceId)
        .where('type', '==', ContractType.BASE),
    });
    await contractsCollection.fetch();

    if (contractsCollection.hasDocs) {
      return contractsCollection.docs[0];
    }

    return null;
  }

  static addDoc = async (data) => {
    const userContractCollection = new Collection(firestorePaths.USER_CONTRACT);
    const newContractDoc = await userContractCollection.add(data);
    const userContractDoc = new UserContract(newContractDoc.id);
    await userContractDoc.init();
    return userContractDoc;
  };

  static createBaseContract = (data) => {
    const docData = {
      ...data,
      type: ContractType.BASE,
    };

    return UserContract.addDoc(docData);
  };
}

export default UserContract;
