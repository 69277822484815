import styled from 'styled-components';

import colors from '../../../../../styles/colors';

const Container = styled.div`
  background-color: ${colors.base.alpha};
  border: 1px solid ${colors.shades.gamma3};
  width: 80vh;
`;

export {
  Container,
};
