import React from 'react';
import PropTypes from 'prop-types';
import { ToggleButton } from '@mui/material';
import format from 'string-template';

import texts from './texts.json';
import { StyledToggleButtonGroup } from './styles';
import { ALL, DaysOptions } from './utils';

const DateFilter = ({
  selectedPeriod,
  onChange,
}) => (
  <StyledToggleButtonGroup
    value={selectedPeriod}
    exclusive
    // update the selected period only if the user selected a period.
    // This is to avoid the deselection of the selected period when the user clicks on the selected period.
    onChange={(e, period) => { if (period !== null) onChange(period); }}
  >
    {DaysOptions.map((days) => (
      <ToggleButton value={days} key={days}>
        {days === ALL ? texts.all : format(texts.days, { days })}
      </ToggleButton>
    ))}
  </StyledToggleButtonGroup>
);

DateFilter.propTypes = {
  selectedPeriod: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};
export default DateFilter;
