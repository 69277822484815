import styled from 'styled-components';
import { SaveOutlined as SaveIcon } from '@mui/icons-material';

import { ButtonIconStyle } from '../../../../../../components/v2/Button';

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const ChipContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledSaveIcon = styled(SaveIcon)`
  ${ButtonIconStyle}
`;

export {
  InputContainer,
  ChipContainer,
  StyledSaveIcon,
};
