import { fieldName } from '../../formFields';

const texts = {
  title: 'Macros per Serving',
  totalCalories: '{totalCalories} calories',
  servingText: 'per serving',
  macrosUnit: 'g',
  field: {
    [fieldName.PROTEIN]: {
      label: 'Protein',
    },
    [fieldName.FAT]: {
      label: 'Fat',
    },
    [fieldName.CARBS]: {
      label: 'Carbs',
    },
  },
};

export default texts;
