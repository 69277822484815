import React from 'react';

const CustomerIOContext = React.createContext({
  isReady: false,
  email: null,
  setEmail: () => {},
  track: () => {},
  page: () => {},
});

export default CustomerIOContext;
