import React, {
  useState,
} from 'react';

import {
  TableTabContainer,
} from '../styles';

import ProgramsTable from './components/ProgramsTable';
import ProgramEditor from './components/ProgramEditor';
import {
  isEditAction,
  isEditorView,
} from './utils';

const Programs = () => {
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [actionPerformed, setActionPerformed] = useState(null);

  const handleActionClick = (program, action) => {
    setSelectedProgram(program);
    setActionPerformed(action);
  };

  return (
    <TableTabContainer>
      {isEditorView(actionPerformed, selectedProgram)
        ? (
          <ProgramEditor
            programDoc={selectedProgram}
            isEditView={isEditAction(actionPerformed, selectedProgram)}
            onClose={() => handleActionClick(null, null)}
          />
        )
        : <ProgramsTable handleActionClick={handleActionClick} />}
    </TableTabContainer>
  );
};

export default Programs;
