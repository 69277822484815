const CollectionName = {
  WORKOUT: 'workout',
  WORKOUT_ASSIGNMENT: 'workoutAssignment',
  GAMEPLAY_SESSION: 'gameplaySession',
  USER_DEVICE: 'userDevice',
  USER_ACTIVITY_HISTORY: 'userActivityHistory',
  COACH: 'coach',
  TRACKED_ACTIVITY_SUMMARY: 'trackedActivitySummary',
  WORKOUT_FEED_VIEW: 'workoutFeedView',
  EXERCISE: 'exercise',
  EXERCISE_TAG: 'exerciseTag',
  STREAK: 'streak',
  TRACKED_ACTIVITY: 'trackedActivity',
  REFERRAL_CODE: 'referralCode',
  PRODUCT: 'product',
  APP_CUSTOMIZATION: 'appCustomization',
  COACH_ASSIGNMENT: 'coachAssignment',
  INTEGRATIONS: 'integrations',
  USER: 'user',
  CONFIG: 'config',
  CLIENT_SMS: 'clientSMS',
  TAG: 'tag',
  PRICE: 'price',
  EXTERNAL_COACH: 'externalCoach',
  EXTERNAL_COACH_PRIVATE: 'externalCoachPrivate',
  MEAL_PLAN: 'mealPlan',
  MEAL_PLAN_VIEW: 'mealPlanView',
  MEAL_PLAN_ASSIGNMENT: 'mealPlanAssignment',
  CLIENT_MEAL_ASSIGNMENT_VIEW: 'clientMealAssignmentView',
  MESSAGE_SUGGESTION: 'messageSuggestion',
  LEADS: 'leads',
  LEAD_HISTORY: 'leadHistory',
  LEAD_MSGS: 'leadMsgs',
  FORMS: 'forms',
  BROADCAST_MESSAGE: 'broadcastMessage',
  BROADCAST_MESSAGE_REQUEST: 'broadcastMessageRequest',
  NUTRITION_PLAN: 'nutritionPlan',
  NUTRITION_PLAN_REQUEST: 'nutritionPlanRequest',
  SUGGESTIC_RECIPE: 'integrations/suggestic/recipe',
  COACH_ONBOARDING: 'coachOnboarding',
  USER_ACTIVITY_PROFILE: 'userActivityProfile',
  USER_NUTRITION_PROFILE: 'userNutritionProfile',
  USER_NUTRITION_LOG: 'userNutritionLog',
  USER_NUTRITION_MACRO_GOALS: 'userNutritionMacroGoals',
  COUPON: 'coupon',
  HABIT_LOG: 'habitLog',
  APP_CUSTOM_HABIT: 'appCustomHabits',
  RECIPE: 'recipe',
  INGREDIENT: 'ingredient',
  USER_CONTRACT: 'userContract',
  POST_PAYMENT_FORM_ANSWER: 'postPaymentFormAnswer',
  WEEKLY_CHECKIN: 'weeklyCheckin',
  ANALYTICS: 'analytics/{userType}/{analyticType}/{frequency}/{userId}',
  MSG_TEMPLATES: 'isConfigs/msgTemplates',
  EMAIL_TEMPLATES: 'isConfigs/emailTemplates',
  COACH_REMINDER_NOTIFICATION: 'coachReminderNotification',
  APP_CUSTOM_CHECK_IN: 'appCustomCheckIn',
  APP_CUSTOM_THEME: 'appCustomTheme',
  INTERNAL_ASSIGNMENT: 'internalAssignment',
  EXCHANGE_RATES: 'exchangeRates',
  COACH_ACTIVITY: 'coachActivity',
  METABASE_DASHBOARD: 'metabaseDashboard',
  CHECK_IN_FEEDBACK: 'checkInFeedback',
  MSG_ENDPOINT: 'msgEndpoint',
  DRAFT_MESSAGE: 'draftMessage',
  PROGRAM: 'program',
  COACH_CONFIG: 'coachConfig',
  COACH_NOTIFICATION_VIEW: 'coachNotificationView',
  CHECK_IN: 'checkIn',
  CHAT_CHANNEL: 'chatChannel',
  USER_CLAIMS: 'userClaims',
};

export default CollectionName;
