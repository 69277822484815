import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  justify-content: center;
`;

export const DirectionRow = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.direction ? props.direction : 'row')};
`;
