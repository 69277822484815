import Fraction from 'fraction.js';

// Parses the ingredient fields to a readable string
const getIngredientLine = ({
  amount,
  unit,
  secondAmount,
  secondUnit,
  name,
  preparationComment,
}) => {
  // amount is always required
  let result = `${new Fraction(amount.trim()).toString()}`;

  // unit is optional
  if (unit) {
    result = `${result} ${unit}`;
  }

  // secondAmount and secondUnit are both optional
  if (secondAmount && secondUnit) {
    result = `${result} (${secondAmount} ${secondUnit})`;
  } else if (secondAmount) {
    result = `${result} (${secondAmount})`;
  } else if (secondUnit) {
    result = `${result} (${secondUnit})`;
  }

  // name is always required
  result = `${result} ${name}`;

  // preparationComment is optional
  if (preparationComment) {
    result = `${result}, ${preparationComment}`;
  }

  return result;
};

export {
  getIngredientLine,
};
