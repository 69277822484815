import format from 'string-template';

import { getQueryVariable } from '../../utils/queryParams';

const RedirectMapping = {
  support_recipe_details: {
    pathname: '/s/{userId}/recipes/{recipeId}',
    requiredParams: [
      {
        name: 'id',
        placeholder: 'recipeId',
      },
    ],
  },
  support_nutrition_plan_details: {
    pathname: '/s/{userId}/nutritionPlans/{nutritionPlanId}',
    requiredParams: [
      {
        name: 'id',
        placeholder: 'nutritionPlanId',
      },
    ],
  },
  support_nutrition_plans: {
    pathname: '/s/{userId}/nutritionPlans',
  },
};

const queryParamsForRedirectType = (type) => {
  const redirectType = type || getQueryVariable('redirect');
  if (redirectType) {
    const redirectConfig = RedirectMapping[redirectType];
    if (redirectConfig) {
      const {
        requiredParams = [],
      } = redirectConfig;
      const result = requiredParams.map(({ name }) => name);
      return [
        ...result,
        'redirect',
      ];
    }
    return ['redirect'];
  }
  return [];
};

const removeRedirectFromSearch = (search) => {
  const params = queryParamsForRedirectType();

  if (params.length > 0) {
    const queryParams = new URLSearchParams(search);

    params.forEach((param) => {
      if (queryParams.has(param)) {
        queryParams.delete(param);
      }
    });

    return queryParams.toString();
  }

  return search;
};

const calculateRedirectPathIfAny = (userId) => {
  const redirectOption = getQueryVariable('redirect');

  if (redirectOption) {
    const redirectConfig = RedirectMapping[redirectOption];

    if (!redirectConfig) {
      throw new Error(`There's no valid configuration for the redirect type "${redirectOption}"`);
    }

    const {
      pathname,
      requiredParams = [],
    } = redirectConfig;

    const placeholdersConfig = requiredParams.reduce((acc, { name, placeholder }) => {
      const paramValue = getQueryVariable(name);
      if (!paramValue) {
        throw new Error(
          `There's no valid value for the required query param "${name}" and redirect type "${redirectOption}"`,
        );
      }
      return {
        ...acc,
        [placeholder]: paramValue,
      };
    }, {});

    return format(pathname, {
      ...placeholdersConfig,
      userId,
    });
  }

  return null;
};

export {
  RedirectMapping,
  calculateRedirectPathIfAny,
  queryParamsForRedirectType,
  removeRedirectFromSearch,
};
