import React from 'react';
import PropTypes from 'prop-types';

import CoachSubscriptionsAnalytics from '../../../../../Model/analytics/CoachSubscriptionAnalytics';
import BarChart from '../../../../../components/BarChart';
import ChartContainer from '../../ChartContainer';
import texts from './texts.json';

const SubscriptionsChart = ({
  subscriptionAnalytics,
}) => (
  <ChartContainer title={texts.newSubscriptions}>
    <BarChart
      data={subscriptionAnalytics
        .map((subAnalytic) => ({
          name: subAnalytic.id,
          'New Subscriptions': subAnalytic.newSubscriptions,
          Cancellations: -subAnalytic.cancellations,
        }))}
      keys={[{ name: 'New Subscriptions' }, { name: 'Cancellations' }]}
      toolTipFormatter={(value) => Math.abs(value)}
    />
  </ChartContainer>
);
SubscriptionsChart.propTypes = {
  subscriptionAnalytics: PropTypes.arrayOf(PropTypes.instanceOf(CoachSubscriptionsAnalytics)).isRequired,
};

export default SubscriptionsChart;
