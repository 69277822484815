import styled from 'styled-components';
import { TextField } from '@mui/material';

import colors from '../../../styles/colors';

const StyledTextField = styled(TextField)`
  width: 100%;
  border-radius: 5px;
  font-size: 14px;
  line-height: 16px;
  text-align: initial;
  margin-bottom: 20px;

  .MuiInputBase-root {
    background-color: ${colors.shades.alpha1};
  }

  input {
    height: unset;
  }

`;

export default StyledTextField;
