import React, {
  useMemo,
  useContext,
  useCallback,
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { DataGrid } from '@mui/x-data-grid';
import { CircularProgress, Tooltip } from '@mui/material';
import format from 'string-template';

import { InfoTag } from '../../../components/Tags';
import ExternalCoachContext from '../../context/ExternalCoachContext';
import {
  ClientStatus,
  ClientExcludingFilters,
  clientStatusLabel,
  filterChecks,
  NEW_CLIENTS_FOR_N_DAYS,
  filterExcludingChecks,
} from '../../utils/statusFilter';
import { getUserStatus } from '../../utils/userStatus';
import useQuickSearch from '../../../hooks/useQuickSearch';
import LabelCheckbox from '../LabelCheckbox';
import Filter from '../Filter';
import QuickSearchToolbar from '../QuickSearchToolbar';
import UserProfileCard from '../UserProfileCard';
import ManageClientTags from '../ManageClientTags';

import {
  DataGridContainer,
  CellContainer,
  StyledCheckBox,
  ChipContainer,
} from './styles';
import texts from './texts.json';

const STATUS_TOOLTIP_SEPARATOR = '#';

const UserTable = ({
  rows,
  clientSortModel,
  setClientSortModel,
  clientStatusFilters,
  setClientStatusFilters,
  clientExcludingFilters,
  setClientExcludingFilters,
  setSelectedClients,
  selectedClients,
  clientTagsDocs,
  clientTagsFilters,
  setClientTagsFilters,
  loadingUsers,
  clientTablePaginationModel,
  setClientPaginationModel,
}) => {
  const { products, isProductsReady } = useContext(ExternalCoachContext);
  const [onlyNewClients, setOnlyNewClients] = useState(false);

  useEffect(() => {
    const filters = clientExcludingFilters.filter((csf) => csf !== ClientExcludingFilters.NEW_CLIENT);
    if (onlyNewClients) {
      filters.push(ClientExcludingFilters.NEW_CLIENT);
    }
    // As we're setting here one and only one value, comparison of length is enough to decide if changes were made.
    if (filters.length !== clientExcludingFilters.length) {
      setClientExcludingFilters(filters);
    }
  }, [
    onlyNewClients,
    clientExcludingFilters,
    setClientExcludingFilters,
  ]);

  const productNames = products.reduce((codes, {
    id: productId,
    name,
  }) => ({
    ...codes,
    [productId]: name,
  }), {});

  const renderTags = useCallback(({ row: { clientTags } = {} }) => {
    if (clientTags && clientTags.length > 0) {
      const firstTag = clientTags.slice(0, 1);
      const remainingTags = clientTags.slice(1, clientTags.length);
      const tooltipText = remainingTags.map((rst) => rst).join(', ');
      return (
        <CellContainer>
          <ChipContainer>
            {firstTag.map((restrictionTag) => (
              <InfoTag key={restrictionTag}>{restrictionTag}</InfoTag>
            ))}
            {!!remainingTags.length && (
              <Tooltip title={tooltipText} placement="top" arrow>
                <InfoTag>{format(texts.moreTags, { amount: remainingTags.length })}</InfoTag>
              </Tooltip>
            )}
          </ChipContainer>
        </CellContainer>
      );
    }
    return (
      <CellContainer>
        {texts.emptyCell}
      </CellContainer>
    );
  }, []);

  const columns = useMemo(() => [
    {
      field: 'selectUsers',
      headerName: '',
      flex: 1,
      renderCell: (row) => (
        <StyledCheckBox
          size="small"
          checked={selectedClients.includes(row.id)}
          onChange={() => setSelectedClients(row.id)}
        />
      ),
    },
    {
      field: 'name',
      quickSearch: true,
      headerName: texts.headers.name,
      minWidth: 275,
      flex: 10,
      renderCell: ({ row }) => (
        <CellContainer>
          <UserProfileCard
            key={row.id}
            clientId={row.id}
            avatarSize="small"
            dataRow={{
              ...row,
              userName: row.name,
            }}
            showButtonsOnHover
          />
        </CellContainer>
      ),
    },
    {
      field: 'status',
      quickSearch: true,
      headerName: texts.headers.status,
      flex: 10,
      valueGetter: ({ row }) => {
        const {
          status,
          associatedDateString,
        } = getUserStatus(row);

        return `${status}${STATUS_TOOLTIP_SEPARATOR}${associatedDateString || ''}`;
      },
      renderCell: ({ value }) => {
        const [status, tooltipText] = value.split(STATUS_TOOLTIP_SEPARATOR);
        if (tooltipText) {
          return (
            <Tooltip
              title={tooltipText}
              placement="top"
              arrow
            >
              <CellContainer>{status}</CellContainer>
            </Tooltip>
          );
        }

        return <CellContainer>{status}</CellContainer>;
      },
    },
    {
      field: 'serviceStartAt',
      headerName: texts.headers.startDate,
      flex: 10,
      renderCell: ({ row: { serviceStartAt } }) => (
        <CellContainer title={serviceStartAt}>
          {`${serviceStartAt ? moment(serviceStartAt).fromNow() : texts.emptyCell}`}
        </CellContainer>
      ),
    },
    {
      field: 'lastCheckIn',
      headerName: texts.headers.lastCheckIn,
      flex: 10,
      renderCell: ({ row: { lastCheckIn } }) => (
        <CellContainer title={lastCheckIn}>
          {`${lastCheckIn && !moment(lastCheckIn).isSame(moment(new Date(0)))
            ? moment(lastCheckIn).fromNow()
            : texts.emptyCell}`}
        </CellContainer>
      ),
    },
    {
      field: 'lastWorkout',
      headerName: texts.headers.lastWorkout,
      flex: 10,
      renderCell: ({ row: { lastWorkout } }) => (
        <CellContainer title={lastWorkout}>
          {`${lastWorkout ? moment(lastWorkout).fromNow() : texts.emptyCell}`}
        </CellContainer>
      ),
    },
    {
      field: 'lastMessageAt',
      headerName: texts.headers.lastChatMessageAt,
      flex: 10,
      renderCell: ({ row: { lastMessageAt } }) => (
        <CellContainer title={lastMessageAt}>
          {`${lastMessageAt ? moment(lastMessageAt).fromNow() : texts.emptyCell}`}
        </CellContainer>
      ),
    },
    {
      field: 'productName',
      headerName: texts.headers.product,
      flex: 10,
      valueGetter: ({ row: { productId } }) => productNames[productId] || texts.emptyCell,
      renderCell: ({ row: { productId } }) => (
        <CellContainer title={productId}>
          {isProductsReady ? productNames[productId] || texts.emptyCell : <CircularProgress size={20} />}
        </CellContainer>
      ),
    },
    {
      field: 'clientTags',
      headerName: texts.headers.tags,
      flex: 10,
      minWidth: 75,
      sortable: false,
      renderCell: ({ row }) => (
        renderTags({ row })
      ),
    },
    {
      field: 'commitmentEndingDate',
      headerName: texts.headers.commitmentEnding,
      flex: 10,
      sortingOrder: ['desc', 'asc', null],
      renderCell: ({ row }) => (
        <CellContainer title={row.commitmentEndingDate}>
          {`${row.commitmentEndingDate ? moment(row.commitmentEndingDate).fromNow() : texts.emptyCell}`}
        </CellContainer>
      ),
    },
  ], [
    selectedClients,
    setSelectedClients,
    renderTags,
    isProductsReady,
    productNames,
  ]);

  const {
    filteredRows: quickSearchRows,
    toolbarProps,
  } = useQuickSearch(rows, columns);

  const filteredRows = useMemo(() => (
    quickSearchRows
      .filter((user) => clientStatusFilters.some((filter) => filterChecks[filter](user)))
      .filter((user) => !clientExcludingFilters.length
        || clientExcludingFilters.some((filter) => filterExcludingChecks[filter](user)))
      .filter((user) => clientTagsFilters.length === 0
        || user.clientTags.some((tag) => clientTagsFilters.includes(tag)))
  ), [
    quickSearchRows,
    clientStatusFilters,
    clientExcludingFilters,
    clientTagsFilters,
  ]);

  const clientStatusOptions = useMemo(() => (
    Object.values(ClientStatus).map((status) => ({
      value: status,
      label: clientStatusLabel[status],
    }))
  ), []);

  const tagsOptions = useMemo(() => (
    clientTagsDocs.map((doc) => ({
      value: doc.tag,
      label: doc.tag,
    }))
  ), [clientTagsDocs]);

  return (
    <DataGridContainer>
      <DataGrid
        loading={loadingUsers}
        rows={filteredRows}
        columns={columns}
        rowHeight={50}
        autoPageSize
        pagination
        disableSelectionOnClick
        sortModel={clientSortModel}
        onSortModelChange={(newSortModel) => setClientSortModel(newSortModel)}
        paginationModel={clientTablePaginationModel}
        onPaginationModelChange={setClientPaginationModel}
        components={{
          Toolbar: QuickSearchToolbar,
        }}
        componentsProps={{
          toolbar: {
            ...toolbarProps,
            placeholder: texts.searchPlaceholder,
            processTools: [{
              Component: ManageClientTags,
              id: 'manage-client-tags',
              props: {
                selectedClientList: selectedClients,
                buttonLabel: texts.tagClients,
              },
            }],
            filterTools: [{
              Component: Filter,
              id: 'tag-filter-toolbar',
              props: {
                inputLabel: texts.clientTags,
                options: tagsOptions,
                initialValues: clientTagsFilters,
                onValuesSelected: setClientTagsFilters,
              },
            },
            {
              Component: LabelCheckbox,
              id: 'new-client-filter-toolbar',
              props: {
                description: format(texts.filterNewClients, { days: NEW_CLIENTS_FOR_N_DAYS }),
                isChecked: onlyNewClients,
                onChange: setOnlyNewClients,
              },
            },
            {
              Component: Filter,
              id: 'filter-toolbar',
              props: {
                options: clientStatusOptions,
                initialValues: clientStatusFilters,
                title: texts.clientStatus,
                onValuesSelected: setClientStatusFilters,
              },
            }],
          },
        }}
      />
    </DataGridContainer>
  );
};

UserTable.propTypes = {
  rows: PropTypes.array.isRequired,
  clientSortModel: PropTypes.array.isRequired,
  setClientSortModel: PropTypes.func.isRequired,
  clientStatusFilters: PropTypes.array.isRequired,
  setClientStatusFilters: PropTypes.func.isRequired,
  clientExcludingFilters: PropTypes.array.isRequired,
  setClientExcludingFilters: PropTypes.func.isRequired,
  selectedClients: PropTypes.array.isRequired,
  setSelectedClients: PropTypes.func.isRequired,
  clientTagsDocs: PropTypes.array.isRequired,
  clientTagsFilters: PropTypes.array.isRequired,
  setClientTagsFilters: PropTypes.func.isRequired,
  loadingUsers: PropTypes.bool.isRequired,
  clientTablePaginationModel: PropTypes.object,
  setClientPaginationModel: PropTypes.func.isRequired,
};

UserTable.defaultProps = {
  // If set the default value as null or {}, gives an error. That's why set as undefined.
  clientTablePaginationModel: undefined,
};

export default UserTable;
