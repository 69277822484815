import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Menu, MenuItem } from '@mui/material';
import format from 'string-template';
import { AddButton } from '../../../../../../../components/Button/ActionButtons';
import texts from './texts.json';
import { PlaceFirst } from './styles';

const AddRecipeAction = ({
  data,
  addRecipe,
  mealTimes,
  selectedBucketId,
  variant,
  showSelectionCount,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <PlaceFirst>
        <AddButton
          variant={variant}
          onClick={selectedBucketId ? () => addRecipe(selectedBucketId, data) : handleMenuOpen}
        >
          {showSelectionCount
            ? format(texts.addSelectedRecipes, { plural: data.length > 1 ? 's' : '' })
            : texts.addRecipe}
        </AddButton>
        <Menu
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={handleMenuClose}
        >
          {
          !!mealTimes
          && mealTimes.map((mealTime) => (
            <MenuItem key={mealTime.name} onClick={() => addRecipe(mealTime.name, data)}>{mealTime.name}</MenuItem>
          ))
        }
        </Menu>
      </PlaceFirst>
      {showSelectionCount && (
      <PlaceFirst>
        <p>
          {format(texts.selectedRecipes, { count: data.length, plural: data.length > 1 ? 's' : '' })}
        </p>
      </PlaceFirst>
      )}
    </>
  );
};

AddRecipeAction.propTypes = {
  data: PropTypes.oneOfType(
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object,
  ),
  addRecipe: PropTypes.func.isRequired,
  mealTimes: PropTypes.array.isRequired,
  selectedBucketId: PropTypes.string,
  variant: PropTypes.string,
  showSelectionCount: PropTypes.bool,
};
AddRecipeAction.defaultProps = {
  selectedBucketId: null,
  variant: 'muted',
  data: {},
  showSelectionCount: false,
};
export default AddRecipeAction;
