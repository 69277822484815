import { TextField } from '@mui/material';
import React, { useContext } from 'react';

import FeedContext from '../../../../context/FeedContext';

import { StyledFeedFilter } from './styles';
import texts from './texts.json';

const FeedSearch = () => {
  const {
    setSearchField,
    uniqueClientNames,
    searchField,
  } = useContext(FeedContext);

  return (
    <StyledFeedFilter
      disablePortal
      options={uniqueClientNames}
      limitTags={5}
      fullWidth
      value={searchField}
      onChange={(evt) => setSearchField(evt.target.textContent || null)}
      renderInput={(params) => <TextField {...params} label={texts.fieldLabel} />}
    />
  );
};

export default FeedSearch;
