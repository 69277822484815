import React from 'react';

import LeadsContextProvider from './LeadsContextProvider';

const withLeadsContextProvider = (Component) => (props) => (
  <LeadsContextProvider>
    <Component {...props} />
  </LeadsContextProvider>
);

export {
  withLeadsContextProvider,
};
