import React from 'react';
import PropTypes from 'prop-types';

import {
  StyledHeader,
} from './styles';

const ColoredHeader = ({
  children,
  className,
}) => (
  <StyledHeader className={className}>{children}</StyledHeader>
);

ColoredHeader.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

ColoredHeader.defaultProps = {
  className: '',
};

export default ColoredHeader;
