import styled from 'styled-components';

import { subtitle1 } from '../../styles/text';
import Card from '../../components/Card';

const Container = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledCard = styled(Card)`
  max-width: 320px;
  box-sizing: border-box;
`;

const Description = styled.div`
  ${subtitle1}
`;

export {
  Container,
  StyledCard,
  Description,
};
