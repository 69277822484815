import { Avatar } from '@mui/material';
import styled, { css } from 'styled-components';

import colors from '../../../../../../../styles/colors';

const Container = styled.div`
  padding: 10px;
  border-radius: 5px;
  background-color:  ${(props) => (props.$isRest ? colors.shades.danger2 : colors.shades.secondary10)};
  border-bottom: ${({ $showRestWarning }) => ($showRestWarning ? `1px solid ${colors.shades.secondary7}` : 'none')};
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ThumbnailWrapper = styled.div`
  width: 60px;
  height: 60px;
  margin-right: 8px;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border: solid 1px ${colors.shades.gamma1};
  border-radius: 6px;
`;

const Thumbnail = styled.img`
  max-width: 140%;
`;

const connectorStyles = css`
  content: '';
  border-right: 1px solid ${colors.shades.beta2};
  height: 90px;
  position: absolute;
`;

const topConnector = css`
  &:before {
    bottom: 0;
    ${connectorStyles}
  }
`;

const bottomConnector = css`
  &:after {
    top: 0;
    ${connectorStyles}
  }
`;

const StyledAvatar = styled(Avatar)`
  background-color: ${colors.shades.beta2};
  overflow: unset;
  ${(props) => (props.$showTopConnector ? topConnector : null)}
  ${(props) => (props.$showBottomConnector ? bottomConnector : null)}
`;

const ChipContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  ${({ spaceBetween }) => spaceBetween && 'justify-content: space-between;'};
`;

const RestWarning = styled.div`
  color: red;
  padding-left: 10px;
  margin-top: 10px;
`;

const ActivityWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid ${colors.shades.secondary7};
`;

export {
  Container,
  ThumbnailWrapper,
  Thumbnail,
  StyledAvatar,
  DetailsContainer,
  ChipContainer,
  RestWarning,
  ActivityWrapper,
};
