import React from 'react';
import PropTypes from 'prop-types';

import NotificationSettings from './NotificationSettings';
import NutritionSettings from './NutritionSettings';
import {
  Container,
} from './styles';

const ClientSettings = ({ clientId }) => (
  <Container>
    <NotificationSettings clientId={clientId} />
    <NutritionSettings clientId={clientId} />
  </Container>
);

ClientSettings.propTypes = {
  clientId: PropTypes.string.isRequired,
};

export default ClientSettings;
