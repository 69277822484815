import React, {
  useContext,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import * as Sentry from '@sentry/browser';

import useComponentMounted from '../../hooks/useComponentMounted';
import useNavigation from '../../hooks/useNavigation';
import useSessionStore from '../../hooks/useSessionStore';

import CoachAssignment from '../../Model/CoachAssignment';
import Coach from '../../Model/Coach';

import OnboardingFlowConfigContext from '../OnboardingFlowConfigContext';
import CoachAssignmentContext from './CoachAssignmentContext';

/**
 * Context provider in charge of getting current coach assigned to the logged in user.
 */
const CoachAssignmentContextProvider = ({
  children,
}) => {
  const { userId } = useSessionStore();
  const {
    externalCoach,
  } = useContext(OnboardingFlowConfigContext);
  const [isReady, setIsReady] = useState(false);
  const [currentCoach, setCurrentCoach] = useState(null);
  const isComponentMountedRef = useComponentMounted();
  const {
    navigateTo,
    routes,
  } = useNavigation();

  const assignCoach = useCallback(async (coach) => {
    if (userId && coach) {
      await CoachAssignment.assignCoach(userId, coach.id);
    }
    setCurrentCoach(coach);
  }, [
    userId,
  ]);

  useEffect(() => {
    const init = async () => {
      let coach;

      if (externalCoach) {
        coach = await Coach.getCoachById(externalCoach);
      }

      if (!coach) {
        Sentry.captureException(new Error('Invalid external coach id provided for external onboarding', {
          extra: {
            externalCoachId: externalCoach,
          },
        }));
        navigateTo(routes.ERROR);
      }

      if (isComponentMountedRef.current) {
        setCurrentCoach(coach);
        setIsReady(true);
      }
    };

    if (!isReady) {
      init();
    }
  }, [
    userId,
    isReady,
    isComponentMountedRef,
    navigateTo,
    routes.ERROR,
    externalCoach,
  ]);

  const contextValue = useMemo(() => ({
    isReady,
    currentCoach,
    assignCoach,
  }), [
    isReady,
    currentCoach,
    assignCoach,
  ]);

  return (
    <CoachAssignmentContext.Provider value={contextValue}>
      {children}
    </CoachAssignmentContext.Provider>
  );
};

CoachAssignmentContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default compose(
  observer,
)(CoachAssignmentContextProvider);
