import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

import {
  BottomAnalytic,
  BottomAnalyticsContainer,
  Container, MainAnalytic, MainAnalyticContainer,
} from './styles';

const AnalyticNumberCard = ({
  title,
  subTitle,
  bottomStats,
}) => (
  <Container elevation={2}>
    <MainAnalyticContainer>
      <MainAnalytic>
        <Typography variant="h4">
          {title}
        </Typography>
        <Typography variant="subtitle1">
          {subTitle}
        </Typography>
      </MainAnalytic>
    </MainAnalyticContainer>
    {!!bottomStats && (
      <BottomAnalyticsContainer>
        {bottomStats.map((bottomStat, index) => (
          <BottomAnalytic key={index.toString()}>
            <div>
              <Typography variant="subtitle2" sx={{ mb: -1 }} textAlign="center">
                {bottomStat.title}
              </Typography>
              <Typography variant="caption" color="textSecondary">
                {bottomStat.subTitle}
              </Typography>
            </div>
          </BottomAnalytic>
        ))}
      </BottomAnalyticsContainer>
    )}
  </Container>
);

AnalyticNumberCard.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  subTitle: PropTypes.string.isRequired,
  bottomStats: PropTypes.array,
};

AnalyticNumberCard.defaultProps = {
  bottomStats: null,
};

export default AnalyticNumberCard;
