import BaseDocument from './BaseDocument';

class UserConfig extends BaseDocument {
  get onboardingSkipped() {
    return !!this.data.onboardingSkipped;
  }

  skipOnboarding = () => (
    this.updateFields({
      onboardingSkipped: true,
    })
  );

  setCampaignId = (campaignId) => (
    this.updateFields({
      campaignId,
    })
  );
}

export default UserConfig;
