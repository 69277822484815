import { Collection } from 'firestorter';

import { firestorePaths } from '../../utils/paths';

import BaseDocument from '../../Model/BaseDocument';
import Collections from '../../utils/collections';

class Ingredient extends BaseDocument {
  get allergenTags() {
    return this.data.allergenTags || [];
  }

  static async getByHandle(handle) {
    const ingredientCollection = new Collection(firestorePaths.INGREDIENT, {
      query: (ref) => ref
        .where('handle', '==', handle)
        .limit(1),
    });
    await ingredientCollection.fetch();

    if (ingredientCollection.docs.length === 1) {
      const ingredientDoc = new Ingredient(ingredientCollection.docs[0].ref);
      await ingredientDoc.init();
      return ingredientDoc;
    }
    return null;
  }

  static async addDoc(data) {
    const ingredientCollection = new Collection(Collections.INGREDIENT);
    await ingredientCollection.add(data);
  }
}

export default Ingredient;
