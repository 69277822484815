import React, { useState } from 'react';
import copy from 'clipboard-copy';
import PropTypes from 'prop-types';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import texts from './texts.json';

const CopyButton = ({
  value,
  valuePrefix,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const onTooltipClosed = () => {
    setShowTooltip(false);
  };

  const onCopyClicked = () => {
    copy(`${valuePrefix}${value}`);
    setShowTooltip(true);
  };

  return (
    <Tooltip
      open={showTooltip}
      title={texts.copied}
      leaveDelay={1500}
      onClose={onTooltipClosed}
    >
      <IconButton
        disabled={!value}
        onClick={onCopyClicked}
      >
        <ContentCopyIcon />
      </IconButton>
    </Tooltip>
  );
};

CopyButton.propTypes = {
  value: PropTypes.string.isRequired,
  valuePrefix: PropTypes.string,
};

CopyButton.defaultProps = {
  valuePrefix: '',
};

export default CopyButton;
