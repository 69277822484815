import React, {
  useContext,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  IconButton,
  Tooltip,
} from '@mui/material';
import { useTheme } from 'styled-components';

import NavigationContext from '../../../context/NavigationContext';
import LayoutContext from '../../context';

import {
  DrawerHeader,
  StyledDrawer,
  ListItemContainer,
  StyledChevronLeftIcon,
  StyledChevronRightIcon,
} from './styles';

const Sidebar = ({
  entries,
}) => {
  const {
    activePage,
  } = useContext(NavigationContext);

  const {
    isDrawerOpen,
    toggleDrawer,
  } = useContext(LayoutContext);

  const theme = useTheme();

  const getListItems = useCallback(() => (
    entries.map(({
      title,
      icon,
      onClick,
      showDividerAfter,
      pageId,
    }) => {
      const listItemComponent = (
        <ListItemContainer key={pageId}>
          <ListItem
            button
            onClick={onClick}
            selected={activePage === pageId}
          >
            <ListItemIcon>
              {icon}
            </ListItemIcon>
            <ListItemText primary={title} />
          </ListItem>
          {showDividerAfter && <Divider />}
        </ListItemContainer>
      );

      if (isDrawerOpen) {
        return listItemComponent;
      }

      return (
        <Tooltip
          title={title}
          key={pageId}
          placement="right"
        >
          {listItemComponent}
        </Tooltip>
      );
    })
  ), [
    activePage,
    isDrawerOpen,
    entries,
  ]);

  return (
    <StyledDrawer
      open={isDrawerOpen}
      variant="permanent"
    >
      <DrawerHeader>
        <IconButton onClick={toggleDrawer}>
          {theme.direction === 'rtl' ? <StyledChevronRightIcon /> : <StyledChevronLeftIcon />}
        </IconButton>
      </DrawerHeader>
      <List>{getListItems()}</List>
    </StyledDrawer>
  );
};

Sidebar.propTypes = {
  entries: PropTypes.array.isRequired,
};

export default Sidebar;
