import styled from 'styled-components';
import {
  Box,
} from '@mui/material';

import colors from '../../../../../../../styles/colors';

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${colors.base.alpha};
  padding: 12px 40px;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const NoteCredit = styled.div`
  font-size: 14px;
  line-height: 15px;
  color: ${colors.shades.gamma9};
`;

const StrongTypography = styled.strong`
  font-weight: 600;
`;

const StyledTypography = styled.div`
  font-size: 16px;
  line-height: 18px;
  color: ${({ isDate }) => (isDate ? colors.shades.gamma3 : colors.shades.gamma10)};
  flex-shrink: 0;
`;

const Separator = styled.div`
  margin: 0 8px;
`;

export {
  Container,
  ColumnContainer,
  RowContainer,
  NoteCredit,
  Separator,
  StyledTypography,
  StrongTypography,
};
