import { Collection } from 'firestorter';
import CollectionName from '../../utils/collections';
import BaseDocument from '../../Model/BaseDocument';

class AppCustomHabit extends BaseDocument {
  constructor(id, opts) {
    super(`${CollectionName.APP_CUSTOM_HABIT}/${id}`, opts);
  }

  get habits() {
    return this.data.habits || [];
  }

  get habitsDefinition() {
    return this.data.habitsDefinition;
  }

  setHabits(habits) {
    this.updateFields({
      habits,
      updatedAt: new Date(),
    });
  }

  setHabitsDefinition(habitsDefinition) {
    this.updateFields({
      habitsDefinition,
      updatedAt: new Date(),
    });
  }

  static addDoc = async (data) => {
    const customHabitCollection = new Collection(CollectionName.APP_CUSTOM_HABIT);
    const customHabitDoc = await customHabitCollection.add(data);
    const appCustomHabitDoc = new AppCustomHabit(customHabitDoc.id);
    await appCustomHabitDoc.init();
    return appCustomHabitDoc;
  };

  static async getById(id) {
    const appCustomHabitDoc = new AppCustomHabit(id);
    await appCustomHabitDoc.init();
    return appCustomHabitDoc.exists ? appCustomHabitDoc : null;
  }
}

export default AppCustomHabit;
