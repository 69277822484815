import styled, { css } from 'styled-components';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import colors from '../../../styles/colors';

const StyledTabs = styled(Tabs)`
  padding: 0 30px;
  margin-bottom: 0;
  border-bottom: 16px solid ${colors.base.secondary3};

  .MuiTabs-indicator {
    display: none;
  }
`;

const StyledTab = styled(Tab)`
  color: ${colors.base.beta};
  background-color: ${colors.shades.gamma1};
  border-radius: 5px 5px 0 0;
  min-width: 202px;
  min-height: 42px;
  text-transform: none;
  flex-direction: row;
  align-items: center;

  ${({ $hidden }) => $hidden && css`
    /* Material UI throws an error if we use display: none, lets use visibility: hidden */
    visibility: hidden;
    height: 0;
    width: 0;
    padding: 0;
    margin: 0;
  `}

  &:not(:first-child) {
    margin-left: 8px;
  }

  & > *:first-child {
    margin-bottom: 0;
  }

  &.Mui-selected {
    color: ${colors.base.beta};
    background-color: ${colors.base.secondary3};

    &::before {
      content: '';
    }
  }
`;

export {
  StyledTabs,
  StyledTab,
};
