import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import moment from 'moment';
import { Alert, TextField } from '@mui/material';
import DatePicker from '@mui/lab/DatePicker';
import * as Sentry from '@sentry/browser';

import {
  HeaderRow,
  SubTitle,
  Title,
  TitleContainer,
} from '../../../../../../../components/v2/Header';
import { DateFormat } from '../../../../../../../utils/date';
import CopyButton from '../../../../../../../components/CopyButton';
import ExternalCoachContext, { withExternalCoachContextReady } from '../../../../../../context/ExternalCoachContext';
import { getOnboardingLink } from '../../../../../../utils/onboardingLink';
import useComponentMounted from '../../../../../../../hooks/useComponentMounted';

import { FieldSet } from './styles';
import texts from './texts.json';

const FreeProductSignUpLink = () => {
  const { externalCoachDoc, products } = useContext(ExternalCoachContext);
  const isComponentMounted = useComponentMounted();
  const [startDate, setStartDate] = useState(moment());
  const [freeProductLink, setFreeProductLink] = useState();

  useEffect(() => {
    const init = async () => {
      const startAt = startDate.isAfter(moment(), 'day')
        ? startDate.format(DateFormat.DEFAULT_DATE_FORMAT) : undefined;
      const freeProductsPlan = await products.find((product) => !!product.isDefault)?.getPlans();

      const freeProductsPlanId = freeProductsPlan?.docs.length > 0 ? freeProductsPlan?.docs[0].data.planCode : '';
      if (freeProductsPlanId) {
        const linkData = {
          coachId: externalCoachDoc?.id,
          plan: freeProductsPlanId,
          startAt,
        };

        if (isComponentMounted.current) {
          setFreeProductLink(getOnboardingLink(linkData));
        }
      } else {
        Sentry.captureMessage(`There's no Free Product PlanCode for the external coach - "${externalCoachDoc?.id}"`);
      }
    };
    init();
  }, [
    externalCoachDoc,
    products,
    startDate,
    isComponentMounted,
  ]);

  if (!freeProductLink) {
    return (
      <Alert severity="warning">
        {texts.noFreeProduct}
      </Alert>
    );
  }

  return (
    <>
      <HeaderRow>
        <TitleContainer>
          <Title>{texts.title}</Title>
          <SubTitle>{texts.subTitle}</SubTitle>
        </TitleContainer>
      </HeaderRow>

      <FieldSet>
        <DatePicker
          label={texts.selectStartDate}
          value={startDate}
          onChange={(newValue) => setStartDate(newValue)}
          minDate={moment()}
          maxDate={moment().add(3, 'months')}
          renderInput={(params) => (
            <TextField {...params} />
          )}
        />

        <CopyButton value={freeProductLink}>{texts.copyLink}</CopyButton>
      </FieldSet>
    </>
  );
};

export default compose(
  withExternalCoachContextReady,
  observer,
)(FreeProductSignUpLink);
