import { Collection } from 'firestorter';

import { firestorePaths } from '../utils/paths';
import BaseDocument from './BaseDocument';

class MsgEndpoint extends BaseDocument {
  get endpoint() {
    return this.data.endpoint;
  }

  get userId() {
    return this.data.userId;
  }

  get userName() {
    return this.data.userName;
  }

  get isDefault() {
    return this.data.isDefault;
  }

  static async getAllEndpoints() {
    const msgEndpointCollection = new Collection(firestorePaths.MSG_ENDPOINT, {
      createDocument: (source, options) => new MsgEndpoint(source, options),
    });
    await MsgEndpoint.initCollection(msgEndpointCollection);
    return msgEndpointCollection;
  }
}

export default MsgEndpoint;
