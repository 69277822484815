import { ingredientField } from '../../formFields';

const texts = {
  addIngredient: 'Add Ingredient',
  allergensTitle: 'Allergens',
  exampleImageAlt: 'Ingredient example',
  title: 'Ingredients',
  field: {
    [ingredientField.AMOUNT]: {
      label: 'Amount',
    },
    [ingredientField.UNIT]: {
      label: 'Unit',
    },
    [ingredientField.NAME]: {
      label: 'Ingredient',
    },
    [ingredientField.PREPARATION_COMMENT]: {
      label: 'Prep Comment',
    },
    [ingredientField.ALLERGEN_TAGS]: {
      label: 'Allergens',
    },
  },
};

export default texts;
