import styled from 'styled-components';
import { IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import Popper from '@mui/material/Popper';
import { ResizableBox } from 'react-resizable';

import { ReactComponent as ExerciseEditorIcon } from '../../../assets/icons/menuIcons/exercise-editor.svg';
import { ReactComponent as WorkoutLibraryIcon } from '../../../assets/icons/menuIcons/workout-library.svg';
import { ReactComponent as MealPlannerIcon } from '../../../assets/icons/menuIcons/meal-planner.svg';
import { ReactComponent as AssignmentIcon } from '../../../assets/icons/v2/article-check.svg';
import { ReactComponent as Settings } from '../../../assets/icons/settings-gear-icon.svg';

import colors from '../../../styles/colors';
import { StyledTabs } from '../../pages/MealsPlanner/styles';

const Header = styled.div`
  display: flex;
  flex-direction: row;
  padding: 30px 40px 20px;
  background-color: ${colors.shades.secondary5};
  position: relative;
  cursor: move;
`;

const Tabcontent = styled.div`
  background-color: ${colors.shades.secondary5} !important; 
  min-height: 300px;
  padding: 20px;
`;

const NotesIcon = styled(AssignmentIcon)`
  width: 20px;
  height: 20px;
  > path {
    stroke: ${colors.base.secondary2};
    fill: ${colors.base.secondary3};
  }
`;

const WorkoutIcon = styled(ExerciseEditorIcon)`
  width: 20px;
  height: 20px;
  > path {
    stroke: ${colors.base.secondary2};
    fill: ${colors.base.secondary3};
  }
`;

const WorkoutHistoryIcon = styled(WorkoutLibraryIcon)`
  width: 20px;
  height: 20px;
  > path {
    stroke: ${colors.base.secondary2};
    fill: ${colors.base.secondary3};
  }
`;

const MealPlanIcon = styled(MealPlannerIcon)`
  width: 20px;
  height: 20px;
  > path {
    stroke: ${colors.base.secondary2};
    fill: ${colors.base.secondary3};
  }
  > line {
    stroke: ${colors.base.secondary2};
  }
`;

const SettingsIcon = styled(Settings)`
  width: 20px;
  height: 20px;
  > path {
    stroke: ${colors.base.secondary2};
  } 
`;

const Tabs = styled(StyledTabs)`
  button {
    background-color: unset !important;
  }
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  top: ${({ $fullScreen }) => ($fullScreen ? '30px' : 0)};
  right: ${({ $fullScreen }) => ($fullScreen ? '30px' : 0)};
  background: ${colors.shades.beta2};
  transform: translate(100%, -100%);
  z-index: 100;

  &:hover{
    background: ${colors.shades.beta1};
  }
`;

const StyledCloseIcon = styled(CloseIcon)`
  color: ${colors.base.alpha};
`;

const TabContainer = styled.div`
  background: ${colors.base.alpha};
`;

const Handle = styled('div')`
  width: 10px;
  height: 10px;
  background-color: lightgray;
  border: 1px solid gray;
  position: fixed;
  bottom: -5px;
  right: -5px;
  cursor: se-resize;
`;

const StyledPopper = styled(Popper)`
  border: 1px solid ${colors.shades.gamma3};
  margin: 5% 6%;

  @media screen and (max-width: 768px) {
    margin: 2% 4%;
  }

  @media screen and (max-width: 640px) {
    margin: 12% 5%;
  }
`;

const StyledResizableBox = styled(ResizableBox)`
  overflow-y: scroll;
  background: ${colors.shades.secondary5};

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${colors.shades.gamma3};
    border-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    background: ${colors.shades.gamma4};
  }
`;

export {
  Header,
  NotesIcon,
  WorkoutIcon,
  WorkoutHistoryIcon,
  MealPlanIcon,
  Tabs as StyledTabs,
  Tabcontent,
  SettingsIcon,
  CloseButton,
  StyledCloseIcon,
  TabContainer,
  Handle,
  StyledPopper,
  StyledResizableBox,
};
