import styled from 'styled-components';
import colors from '../../../styles/colors';
import Filter from '../Filter';

const StyledFilter = styled(Filter)`
  background: ${colors.base.alpha};
  text-transform: capitalize;

  .MuiMenu-list {
    text-transform: capitalize;
  }
`;
export {
  StyledFilter,
};
