import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  ArrowBack,
  ArrowForward,
} from '@mui/icons-material';

import useSessionStore from '../../../../../hooks/useSessionStore';
import useComponentMounted from '../../../../../hooks/useComponentMounted';
import { DateFormat } from '../../../../../utils/date';
import { openFirestoreDocument } from '../../../../../utils/support';
import CollectionName from '../../../../../utils/collections';
import LoadingPage from '../../../../../components/LoadingPage';
import WeeklyCheckin from '../../../../Model/WeeklyCheckin';
import CheckInStats from '../../../../components/CheckInStats';
import EmptyView from '../EmptyView';

import {
  Container,
  DateText,
  HeaderContainer,
  NavigationContainer,
  TextContainer,
  UpdatedText,
  StyledText,
  StyledButton,
  StyledOnCallActions,
} from './styles';
import texts from './texts.json';

const CheckInDetailsSlider = ({ user }) => {
  const { isOnCallUser } = useSessionStore();

  const [weeklyCheckinDocs, setWeeklyCheckinDocs] = useState([]);
  const [selectedCheckinDocIndex, setSelectedCheckinDocIndex] = useState();
  const [isReady, setIsReady] = useState(false);
  const isComponentMountedRef = useComponentMounted();

  useEffect(() => {
    const init = async () => {
      const weeklyCheckinCollection = await WeeklyCheckin.getByUser(user);
      const docs = weeklyCheckinCollection.hasDocs ? weeklyCheckinCollection.docs.slice() : [];
      if (isComponentMountedRef.current) {
        setWeeklyCheckinDocs(docs.reverse());
        if (docs.length > 0) {
          setSelectedCheckinDocIndex(docs.length - 1);
        }
        setIsReady(true);
      }
    };
    if (!isReady) {
      init();
    }
  }, [
    user,
    isReady,
    isComponentMountedRef,
  ]);

  const onNavigationClick = useCallback((forward = true) => {
    if (forward) {
      setSelectedCheckinDocIndex((prevIndex) => {
        if (prevIndex !== (weeklyCheckinDocs.length - 1)) {
          return prevIndex + 1;
        }
        return prevIndex;
      });
    } else {
      setSelectedCheckinDocIndex((prevIndex) => {
        if (prevIndex !== 0) {
          return prevIndex - 1;
        }
        return prevIndex;
      });
    }
  }, [
    weeklyCheckinDocs,
  ]);

  if (!isReady) {
    return <LoadingPage />;
  }

  if (weeklyCheckinDocs.length === 0) {
    return <EmptyView text={texts.emptyText} />;
  }

  return (
    <Container>
      <HeaderContainer>
        <TextContainer>
          <DateText>
            {`${weeklyCheckinDocs[selectedCheckinDocIndex]
              && moment(weeklyCheckinDocs[selectedCheckinDocIndex].updated)
                .format(DateFormat.MONTH_NAME_DATE_FORMAT)}`}
          </DateText>
          <UpdatedText>
            {`Updated ${weeklyCheckinDocs[selectedCheckinDocIndex]
              && moment(weeklyCheckinDocs[selectedCheckinDocIndex].updated)
                .format(DateFormat.DATE_FORMAT_TIME_MINUTES)}`}
          </UpdatedText>
        </TextContainer>
        <NavigationContainer>
          <StyledText>{`Week ${selectedCheckinDocIndex + 1} of ${weeklyCheckinDocs.length}`}</StyledText>
          <StyledButton onClick={() => onNavigationClick(false)}>
            <ArrowBack />
          </StyledButton>
          <StyledButton onClick={() => onNavigationClick()}>
            <ArrowForward />
          </StyledButton>
        </NavigationContainer>
      </HeaderContainer>
      {isOnCallUser && (
        <StyledOnCallActions
          actions={[
            {
              onClick: () => {
                openFirestoreDocument(`${CollectionName.CHECK_IN}/${weeklyCheckinDocs[selectedCheckinDocIndex].id}`);
              },
              disabled: !weeklyCheckinDocs[selectedCheckinDocIndex],
              label: texts.support.checkin,
            },
          ]}
        />
      )}
      <CheckInStats activity={weeklyCheckinDocs[selectedCheckinDocIndex]} />
    </Container>
  );
};

CheckInDetailsSlider.propTypes = {
  user: PropTypes.string.isRequired,
};

export default CheckInDetailsSlider;
