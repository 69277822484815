import React from 'react';
import CustomerIOContextProvider from './CustomerIOContextProvider';

const withCustomerIOContextProvider = (Component) => (props) => (
  <CustomerIOContextProvider>
    <Component {...props} />
  </CustomerIOContextProvider>
);

export {
  withCustomerIOContextProvider,
};
