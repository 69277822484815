import styled from 'styled-components';

import colors from '../../../styles/colors';
import Button from '../../Button';

const StyledButton = styled(Button)`
  margin: 10px 0px;
  background-color: ${colors.shades.secondary4};
  border-radius: 5px;
  width: fit-content;
  color: ${colors.base.secondary2};
  font-size: 15px;
  line-height: 18px;
  padding: 16px;
  font-weight: 600;
  text-transform: none;
  border: 1px solid ${colors.shades.secondary4};
  cursor: pointer;
  &:hover {
    background-color: ${colors.shades.secondary5};
    border: 1px solid ${colors.shades.secondary4};
  }
`;

const ButtonIconStyle = `
  width: 20px;
  height: 20px;
  margin-right: 10px;
`;

export default StyledButton;
export { ButtonIconStyle };
