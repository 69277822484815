import styled from 'styled-components';

import { Chip } from '@mui/material';

import { ReactComponent as ChatIcon } from '../../../../assets/icons/chat-icon.svg';
import Button from '../../../../components/Button';
import colors from '../../../../styles/colors';
import Avatar from '../../../../components/Avatar';

const chatIconPx = 30;
const avatarPx = 40;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 8px;
`;

const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const StyledChatIcon = styled(ChatIcon)`
  width: 20px;
  height: 20px;
  > path {
    stroke: ${colors.base.secondary2};
    fill: ${colors.base.primary};
  }
  margin-right: 5px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  .MuiIconButton-root {
    padding: 2px 10px;
    svg > path {
      stroke: ${colors.base.primary};
      fill: ${colors.shades.warning1};
    }
  }
`;

const StyledLink = styled.a`
  cursor: pointer;
  max-width: 100%;
`;

const Title = styled.div`
  font-size: 22px;
  font-weight: 400;
  line-height: 25px;
  max-width: 100%;
`;

const StyledButton = styled(Button)`
  height: ${chatIconPx}px;
  padding: 8px;
  margin-left: 10px;
  cursor: pointer;
  &:hover {
    background-color: ${colors.shades.gamma1};
  }
  font-size: 14px;
  width: auto;
  border-radius: 36px;
  background-color: ${colors.shades.warning1};
`;

const StyledAvatar = styled(Avatar)`
  width: ${avatarPx}px;
  height: ${avatarPx}px;
  min-width: 60px;
  min-height: 60px;
`;

const StyledChip = styled(Chip)`
  background-color: ${colors.base.secondary3};
  color: ${colors.base.beta};
  margin-right: 8px;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
`;

export {
  NameContainer,
  Container,
  TitleContainer,
  StyledLink,
  Title,
  StyledButton,
  StyledChatIcon,
  StyledAvatar,
  StyledChip,
  InfoContainer,
};
