import React from 'react';
import PropTypes from 'prop-types';

import { HeaderContextProvider } from '../../context/HeaderContext';
import Header from './Header';
import { Container, ScrollableArea } from './styles';

const Layout = ({
  children,
}) => (
  <HeaderContextProvider>
    <Header />
    <Container>
      <ScrollableArea>
        {children}
      </ScrollableArea>
    </Container>
  </HeaderContextProvider>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
