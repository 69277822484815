import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';

import Activity from '../../../../../Model/Activity';
import { ReactComponent as CreateIcon } from '../../../../../assets/icons/v2/creation-plus-circle.svg';
import { PrimaryButton } from '../../../../../components/Button/ActionButtons';
import DialogRoundedModal from '../../../../../components/DialogRoundedModal';

import { EditorActionType } from '../WorkoutEditor/utils';
import {
  initialValues as startValues,
  validationSchema,
} from './validation';
import fieldName from './formFields';
import {
  StyledSpacingVerticalLine,
  FormContainer,
  ButtonContainer,
  StyledFormikInput,
} from './styles';
import texts from './texts';

const AddCircuitModal = ({
  showModal,
  onClose,
  onAddCircuit,
  circuitData,
}) => {
  const onAddUpdateCircuit = (values) => {
    const newActivity = new Activity({
      type: EditorActionType.CIRCUIT,
      name: values[fieldName.NAME],
      rounds: values[fieldName.SET],
      activities: circuitData?.activities || [],
      note: values[fieldName.DESCRIPTION],
    });
    onAddCircuit([newActivity]);
  };

  return (
    <DialogRoundedModal
      title={circuitData ? texts.title.edit : texts.title.add}
      IconComponent={<StyledSpacingVerticalLine />}
      open={showModal}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
    >
      <FormContainer>
        <Formik
          initialValues={startValues(circuitData)}
          validationSchema={validationSchema}
          onSubmit={onAddUpdateCircuit}
          enableReinitialize
        >
          {({ isSubmitting, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <StyledFormikInput
                name={fieldName.NAME}
                label={`${texts.fieldLabel[fieldName.NAME]}:`}
              />
              <StyledFormikInput
                name={fieldName.DESCRIPTION}
                label={`${texts.fieldLabel[fieldName.DESCRIPTION]}:`}
                multiline
                rows={3}
              />
              <StyledFormikInput
                name={fieldName.SET}
                label={`${texts.fieldLabel[fieldName.SET]}:`}
                type="number"
              />
              <ButtonContainer>
                <PrimaryButton
                  icon={<CreateIcon />}
                  disabled={isSubmitting}
                  type="submit"
                >
                  {circuitData ? texts.button.update : texts.button.add}
                </PrimaryButton>
              </ButtonContainer>
            </form>
          )}
        </Formik>
      </FormContainer>
    </DialogRoundedModal>
  );
};

AddCircuitModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAddCircuit: PropTypes.func.isRequired,
  circuitData: PropTypes.object,
};

AddCircuitModal.defaultProps = {
  circuitData: {},
};

export default AddCircuitModal;
