import React, { useContext, useMemo } from 'react';

import FeedContext from '../../../../context/FeedContext';
import { actionBarType } from '../../../../context/FeedContext/config/actionBarTypes';

import MobileView from './MobileView';
import CheckInFeedback from './CheckInFeedback';
import {
  ActionBarContainer,
  StyledChatView,
} from './styles';

const FeedActionBar = () => {
  const {
    selectedActionBarType,
  } = useContext(FeedContext);

  const renderActionBar = useMemo(() => {
    switch (selectedActionBarType) {
      case actionBarType.CHAT:
        return <StyledChatView />;
      case actionBarType.MOBILE_VIEW:
        return <MobileView />;
      case actionBarType.CHECK_IN_FEEDBACK:
        return <CheckInFeedback />;
      default:
        return null;
    }
  }, [
    selectedActionBarType,
  ]);

  return (
    <ActionBarContainer>
      {renderActionBar}
    </ActionBarContainer>
  );
};

export default FeedActionBar;
