import React, {
  useState,
  useEffect,
  useMemo,
  useContext,
} from 'react';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';

import { getQueryVariable } from '../../utils/queryParams';
import Coupon from '../../Model/Coupon';
import Plan from '../../Model/Plan';
import Product from '../../Model/Product';
import useNavigation from '../../hooks/useNavigation';
import UserContractContext from '../UserContractContext';
import OnboardingFlowConfigContext from '../OnboardingFlowConfigContext';
import PlanContext from './PlanContext';

const PlanContextProvider = ({
  children,
}) => {
  const [isReady, setIsReady] = useState(false);
  const [plan, setPlan] = useState({});
  const [coupon, setCoupon] = useState({
    percentOff: 0,
  });
  const [postPaymentForm, setPostPaymentForm] = useState();
  const {
    externalCoach,
    planCode,
  } = useContext(OnboardingFlowConfigContext);
  const {
    contractDoc,
  } = useContext(UserContractContext);

  const {
    navigateTo,
    routes,
  } = useNavigation();

  useEffect(() => {
    let shouldUpdate = true;

    const init = async () => {
      let selectedPlan;
      let couponObj;

      const couponParam = getQueryVariable('coupon');

      if (contractDoc) {
        const {
          price,
        } = contractDoc;

        selectedPlan = price;
      } else if (planCode) {
        selectedPlan = await Plan.getPlan(planCode);

        if (!selectedPlan) {
          Sentry.captureException(new Error('No plan found for provided plan param', {
            extra: {
              planCode,
            },
          }));
          navigateTo(routes.ERROR);
        }
      } else {
        Sentry.captureException(new Error('No plan parameter provided for external onboarding'));
        navigateTo(routes.ERROR);
      }

      let productPostPaymentFormValue;

      if (selectedPlan || !!contractDoc) {
        // If we have a contract, then use the product id provided. Otherwise, extract it from the plan's parent doc.
        const productId = contractDoc?.exists ? contractDoc.productId : selectedPlan.productRef.id;

        // Check that the plan corresponds to the external coach
        const product = new Product(productId);
        await product.init();
        const {
          coach,
          postPaymentForm: productPostPaymentForm,
        } = product;

        productPostPaymentFormValue = productPostPaymentForm;

        const isPlanOfExternalCoach = coach === externalCoach;

        if (!isPlanOfExternalCoach) {
          Sentry.captureException(new Error('Plan provided for external onboarding does not belong to external coach'),
            {
              extra: {
                externalCoach,
                planCode,
              },
            });
        }
      }

      if (selectedPlan && couponParam) {
        couponObj = await Coupon.getCouponByCoachAndCode(externalCoach, couponParam);
        if (!couponObj) {
          Sentry.captureException(new Error('No such coupon'), {
            extra: {
              externalCoach,
              couponParam,
            },
          });
        }
        if (couponObj && couponObj.expires && couponObj.expires.isBefore()) {
          Sentry.captureException(new Error('Coupon is expired'), {
            extra: {
              externalCoach,
              couponParam,
            },
          });
          couponObj = null;
        }
        if (couponObj && couponObj.planCodes && !couponObj.planCodes.includes(selectedPlan.planCode)) {
          Sentry.captureException(new Error('Coupon is not allowed with plan'), {
            extra: {
              externalCoach,
              couponParam,
              planCode: selectedPlan.planCode,
            },
          });
          couponObj = null;
        }
      }

      if (shouldUpdate) {
        if (selectedPlan) {
          setPlan(selectedPlan);
        }

        if (couponObj) {
          setCoupon(couponObj);
        }

        if (productPostPaymentFormValue) {
          setPostPaymentForm(productPostPaymentFormValue);
        }
        setIsReady(true);
      }
    };

    if (!isReady) {
      init();
    }

    return () => {
      shouldUpdate = false;
    };
  }, [
    isReady,
    navigateTo,
    routes.ERROR,
    externalCoach,
    planCode,
    contractDoc,
  ]);

  const contextValue = useMemo(() => ({
    isReady,
    plan,
    coupon,
    postPaymentForm,
  }), [
    isReady,
    plan,
    coupon,
    postPaymentForm,
  ]);

  return (
    <PlanContext.Provider value={contextValue}>
      {children}
    </PlanContext.Provider>
  );
};

PlanContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default compose(
  observer,
)(PlanContextProvider);
