import styled, { css } from 'styled-components';

import {
  ReactComponent as CheckInIcon,
} from '../../../../assets/icons/menuIcons/feedMenuIcons/check-in.svg';
import {
  ReactComponent as NeedNewWorkoutIcon,
} from '../../../../assets/icons/menuIcons/feedMenuIcons/need-new-workouts.svg';
import {
  ReactComponent as NeedNewMealPlanIcon,
} from '../../../../assets/icons/menuIcons/feedMenuIcons/meal-planner.svg';
import {
  ReactComponent as DailySummaryIcon,
} from '../../../../assets/icons/menuIcons/feedMenuIcons/daily-summary.svg';
import {
  ReactComponent as SpecialRemindersIcon,
} from '../../../../assets/icons/menuIcons/feedMenuIcons/special-reminders.svg';
import {
  ReactComponent as ClientStatusIcon,
} from '../../../../assets/icons/menuIcons/feedMenuIcons/client-status.svg';
import {
  ReactComponent as ArchivedIcon,
} from '../../../../assets/icons/menuIcons/feedMenuIcons/deleted.svg';
import {
  ReactComponent as VideoFeedbackIcon,
} from '../../../../assets/icons/menuIcons/feedMenuIcons/video-feedback.svg';
import colors from '../../../../styles/colors';

const iconStyle = css`
  height: 20px;
  width: 20px;
`;

const StyledCheckInIcon = styled(CheckInIcon)`
  ${iconStyle};
`;

const StyledNeedNewWorkoutIcon = styled(NeedNewWorkoutIcon)`
  ${iconStyle};
`;

const StyledNeedMealPlanIcon = styled(NeedNewMealPlanIcon)`
  ${iconStyle};
`;

const StyledDailySummaryIcon = styled(DailySummaryIcon)`
  ${iconStyle};
`;

const StyledSpecialRemindersIcon = styled(SpecialRemindersIcon)`
  ${iconStyle};
`;

const StyledClientStatusIcon = styled(ClientStatusIcon)`
  ${iconStyle};
`;

const StyledArchivedIcon = styled(ArchivedIcon)`
  ${iconStyle};
`;

const StyledVideoFeedbackIcon = styled(VideoFeedbackIcon)`
  > path {
    fill: ${colors.shades.primary3};
  }
  ${iconStyle};
`;

export {
  StyledCheckInIcon,
  StyledClientStatusIcon,
  StyledDailySummaryIcon,
  StyledNeedNewWorkoutIcon,
  StyledNeedMealPlanIcon,
  StyledSpecialRemindersIcon,
  StyledArchivedIcon,
  StyledVideoFeedbackIcon,
};
