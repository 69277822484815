import styled from 'styled-components';
import { AddCircleOutline as AddIcon } from '@mui/icons-material';
import RoundedButton from '../../../../../components/v2/RoundedButton';
import { ButtonIconStyle } from '../../../../../components/v2/Button';
import colors from '../../../../../styles/colors';

const StyledButton = styled(RoundedButton)`
  min-width: 200px;
  max-height: 50px;
`;

const StyledAddIcon = styled(AddIcon)`
  ${ButtonIconStyle}
  color: ${colors.base.beta};
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  width: 100%;
`;

export {
  StyledButton,
  StyledAddIcon,
  ButtonContainer,
};
