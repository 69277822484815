import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { downloadCsv } from '../../../../utils/csv';
import { AnalyticType } from '../../../../../utils/analytics';
import { ButtonContainer, StyledDownloadButton } from './styles';

const CsvHeaders = {
  coachName: 'Coach Name',
  newLeads: 'New Leads',
  newMaleLeads: 'New Male Leads',
  newFemaleLeads: 'New Female Leads',
  newSubscriptions: 'New Subscriptions',
  revenue: 'Revenue',
  avgPayment: 'Avg Payment',
  payments: 'Payments',
  revenueFromNewSubscriptions: 'Revenue from New Subscriptions',
  avgPaymentFromNewSubscriptions: 'Avg Payment from New Subscriptions',
  s2Fee: 'S2 Fee',
};

const CsvBaseHeaders = [
  CsvHeaders.coachName,
  CsvHeaders.newLeads,
  CsvHeaders.newMaleLeads,
  CsvHeaders.newFemaleLeads,
  CsvHeaders.newSubscriptions,
];

const CSVDownloadButton = ({
  analyticsData,
  filters,
}) => {
  const downloadCsvData = useCallback(() => {
    let headers = [...CsvBaseHeaders];
    const data = [];
    // one row for each coach
    analyticsData.forEach(({ coachAnalytics, coach, currencies }) => {
      const coachName = coach?.name;
      const { total: newLeads, male: newMaleLeads, female: newFemaleLeads } = coachAnalytics.get(AnalyticType.LEADS);
      const { newSubscriptions } = coachAnalytics.get(AnalyticType.SUBSCRIPTIONS) || {};
      const currencyData = {};
      // columns for each currency
      currencies.forEach((currency) => {
        if (!currency) return;
        const currencyUpper = currency?.toUpperCase();
        const {
          revenueWithCurrency,
          avgPayment,
          payments,
          applicationFeeWithCurrency,
        } = coachAnalytics.get(AnalyticType.REVENUE).revenues.find(
          (rev) => rev.currency === currency,
        );
        const {
          newSubscriptions: currencyNewSubs,
          revenue,
          avgNewSubPayment,
        } = coachAnalytics.get(AnalyticType.SUBSCRIPTIONS).subscriptions.find(
          (sub) => sub.currency === currency,
        ) || {};
        // add headers if not already added
        if (headers.indexOf(`${CsvHeaders.revenue} (${currencyUpper})`) === -1) {
          headers = [
            ...headers,
            `${CsvHeaders.revenue} (${currencyUpper})`,
            `${CsvHeaders.avgPayment} (${currencyUpper})`,
            `${CsvHeaders.payments} (${currencyUpper})`,
            `${CsvHeaders.newSubscriptions} (${currencyUpper})`,
            `${CsvHeaders.revenueFromNewSubscriptions} (${currencyUpper})`,
            `${CsvHeaders.avgPaymentFromNewSubscriptions} (${currencyUpper})`,
            `${CsvHeaders.s2Fee} (${currencyUpper})`,
          ];
        }
        currencyData[`${CsvHeaders.revenue} (${currencyUpper})`] = revenueWithCurrency;
        currencyData[`${CsvHeaders.avgPayment} (${currencyUpper})`] = avgPayment;
        currencyData[`${CsvHeaders.payments} (${currencyUpper})`] = payments;
        currencyData[`${CsvHeaders.newSubscriptions} (${currencyUpper})`] = currencyNewSubs;
        currencyData[`${CsvHeaders.revenueFromNewSubscriptions} (${currencyUpper})`] = revenue;
        currencyData[`${CsvHeaders.avgPaymentFromNewSubscriptions} (${currencyUpper})`] = avgNewSubPayment;
        currencyData[`${CsvHeaders.s2Fee} (${currencyUpper})`] = applicationFeeWithCurrency;
      });
      data.push({
        [CsvHeaders.coachName]: coachName,
        [CsvHeaders.newLeads]: newLeads,
        [CsvHeaders.newMaleLeads]: newMaleLeads,
        [CsvHeaders.newFemaleLeads]: newFemaleLeads,
        [CsvHeaders.newSubscriptions]: newSubscriptions,
        ...currencyData,
      });
    });
    const title = `Coach Analytics for - ${Object.keys(filters).map((key) => `${key}:${filters[key]}`).join('_')}`;
    downloadCsv(headers, data, title);
  }, [analyticsData, filters]);

  return (
    <ButtonContainer>
      <StyledDownloadButton
        onClick={downloadCsvData}
      />
    </ButtonContainer>
  );
};

CSVDownloadButton.propTypes = {
  analyticsData: PropTypes.array.isRequired,
  filters: PropTypes.object.isRequired,
};

export default CSVDownloadButton;
