import { ActivityTypes } from '../../../../Model/Activity';
import config from '../../../../config';

const ActionType = {
  UPDATE: 'UPDATE',
  CREATE: 'CREATE',
  ARCHIVE: 'ARCHIVE',
  UNARCHIVE: 'UNARCHIVE',
  DUPLICATE: 'DUPLICATE',
  UPDATE_WORKOUT_ASSIGNMENT: 'UPDATE_WORKOUT_ASSIGNMENT',
};

const DurationType = {
  TIMED: 'TIMED',
  REPETITIONS: 'REPETITIONS',
};

const SideType = {
  RIGHT_SIDE: 'RIGHT_SIDE',
  LEFT_SIDE: 'LEFT_SIDE',
  ALTERNATE_SIDES: 'ALTERNATE_SIDES',
  LEFT_THEN_RIGHT_SIDE: 'LEFT_THEN_RIGHT_SIDE',
};
/**
 * Checks if the activity is a circuit
 * Circuits are activities with a type of CIRCUIT and a name
 * @param {Activity} activity
 * @returns {boolean}
 */
const isCircuit = (activity) => activity.type === ActivityTypes.CIRCUIT && !!activity.name;

/**
 * Returns the unwrapped activity
 * @param {Activity} activity
 * @returns {Activity}
 */
const getActivity = (activity) => {
  if (!isCircuit(activity) && activity.type === ActivityTypes.CIRCUIT) {
    return activity.activities[0];
  }
  return activity;
};

const getWorkoutEditorMobileUrl = (
  coachId = '',
  workoutId = '',
  editable = true,
  isWorkoutAssignment = false,
) => {
  let path = 'coach';
  const coachIdString = coachId ? `coachId=${coachId}` : '';

  if (workoutId) {
    path = `${path}/${isWorkoutAssignment ? 'wa' : 'w'}/${workoutId}?edit=${editable}&${coachIdString}`;
  } else {
    path = `${path}/w?${coachIdString}`;
  }

  const previewUrl = `${config.mobileAppURL}/${path}`;
  return previewUrl;
};

export {
  ActionType,
  DurationType,
  SideType,
  isCircuit,
  getActivity,
  getWorkoutEditorMobileUrl,
};
