import React from 'react';
import PropTypes from 'prop-types';

import {
  Container,
  ActionButton,
  HelpText,
} from './styles';

const HelpToggle = ({
  helpText,
  actionText,
  toggleForms,
}) => (
  <Container>
    <HelpText>{helpText}</HelpText>
    <ActionButton onClick={toggleForms}>
      {actionText}
    </ActionButton>
  </Container>
);

HelpToggle.propTypes = {
  helpText: PropTypes.string.isRequired,
  actionText: PropTypes.string.isRequired,
  toggleForms: PropTypes.func.isRequired,
};

export default HelpToggle;
