import styled, { css } from 'styled-components';
import {
  Button,
  Typography,
} from '@mui/material';
import { WarningAmberRounded } from '@mui/icons-material';

import Modal from '../../../../../../components/Modal';
import colors from '../../../../../../styles/colors';

const StyledModal = styled(Modal)`
  --width: 370px;
  --height: 170px;

  & .ion-page {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    box-sizing: border-box;
    padding: 28px 32px;
  }
`;

const Title = styled(Typography)`
  font-size: 14px;
  line-height: 16px;
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 16px;
`;

const buttonStyles = css`
  height: 38px;
  font-size: 14px;
  line-height: 16px;
  border-radius: 5px;
  text-transform: none;
  padding: 10px 20px;
`;

const CancelButton = styled(Button)`
  ${buttonStyles};
  background: ${colors.shades.gamma10};
  border: 1px solid ${colors.shades.gamma5};
  color: ${colors.base.alpha};
  &:hover {
    background: ${colors.shades.gamma10};
  }
`;

const ActionButton = styled(Button)`
  ${buttonStyles};
  background: ${colors.base.primary};
  border: 1px solid ${colors.shades.primary1};
  color: ${colors.base.beta};
  &:hover {
    background: ${colors.base.primary};
  }
`;

const WarningIcon = styled(WarningAmberRounded)`
  color: ${colors.base.secondary2};
`;

export {
  ActionsContainer,
  Title,
  StyledModal,
  ActionButton,
  CancelButton,
  WarningIcon,
};
