import React from 'react';
import { clientInfoTabs } from '../../pages/ClientInfo/utils';
import { ClientStatus } from '../../utils/statusFilter';

const initialValues = {
  clientList: [],
  clientDataList: [],
  isReady: false,
  clientSortModel: [
    {
      field: 'name',
      sort: 'asc',
    },
  ],
  setClientSortModel: () => { },
  selectedTab: clientInfoTabs.CHECKIN_ACTIVITY,
  setSelectedTab: () => { },
  clientFilters: [ClientStatus.ACTIVE],
  setClientFilters: () => { },
  clientExcludingFilters: [],
  setClientExcludingFilters: () => {},
  clientTagsDocs: [],
  clientTagsFilters: [],
  setClientTagsFilters: () => {},
  baseClientTagsCollection: { docs: [] },
  customClientTagsCollection: { docs: [] },
  allUsersLoaded: false,
  loadingUsers: false,
  setClientPaginationModel: () => { },
};

const ManageClientContext = React.createContext(initialValues);

export default ManageClientContext;
export {
  initialValues,
};
