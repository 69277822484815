import React from 'react';
import PropTypes from 'prop-types';
import {
  FastField,
  useFormikContext,
} from 'formik';
import {
  Checkbox,
  FormControlLabel,
} from '@mui/material';

import fieldName, { questionFieldName } from '../../../../formFields';
import externalText from '../../../../texts';
import {
  Container,
  SingleRowContainer,
  ShortFormInput,
  LongFormInput,
} from '../styles';

import {
  StyledTypography,
  ParentContainer,
} from './styles';
import texts from './texts.json';

const NameQuestion = ({ index }) => {
  const { setFieldValue } = useFormikContext();

  const renderSingleInput = (innerIndex) => {
    const singleInputFieldBaseName = `${fieldName.FIELDS}.${index}.${fieldName.FIELDS}.${innerIndex}`;
    return (
      <ParentContainer>
        <SingleRowContainer>
          <ShortFormInput
            name={`${singleInputFieldBaseName}.${questionFieldName.CUSTOM_CLASS}`}
            label={externalText.labels[questionFieldName.CUSTOM_CLASS]}
          />
          <ShortFormInput
            name={`${singleInputFieldBaseName}.${questionFieldName.ID}`}
            label={externalText.labels[questionFieldName.ID]}
          />
          {/* We only have the required flag for the last name field */}
          {innerIndex === 1
          && (
            <FastField name={`${singleInputFieldBaseName}.${questionFieldName.REQUIRED}`}>
              {({ field }) => (
                <FormControlLabel
                  key={`${singleInputFieldBaseName}-${index}-${questionFieldName.REQUIRED}`}
                  control={<Checkbox checked={field.value} />}
                  label={externalText.labels[questionFieldName.REQUIRED]}
                  name={questionFieldName.REQUIRED}
                  onChange={(event) => {
                    setFieldValue(`${singleInputFieldBaseName}.${questionFieldName.REQUIRED}`, event.target.checked);
                  }}
                />
              )}
            </FastField>
          )}
        </SingleRowContainer>
        <LongFormInput
          name={`${singleInputFieldBaseName}.${questionFieldName.LABEL}`}
          label={externalText.labels[questionFieldName.LABEL]}
        />
        <LongFormInput
          name={`${singleInputFieldBaseName}.${questionFieldName.PLACEHOLDER}`}
          label={externalText.labels[questionFieldName.PLACEHOLDER]}
        />
      </ParentContainer>
    );
  };

  return (
    <Container>
      <StyledTypography>{texts.firstName}</StyledTypography>
      {renderSingleInput(0)}
      <StyledTypography>{texts.lastName}</StyledTypography>
      {renderSingleInput(1)}
    </Container>
  );
};

NameQuestion.propTypes = {
  index: PropTypes.number.isRequired,
};

export default NameQuestion;
