import styled from 'styled-components';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import colors from '../../../../../../../../styles/colors';

const StyledSwitcher = styled(Tabs)`
  background-color: ${colors.base.alpha};
  border-bottom: 1px solid ${colors.shades.secondary7};
  padding-left: 50px;

  .MuiTabs-indicator {
    background-color: ${colors.shades.secondary8};
    height: 3px;
  }

  .MuiTab-root {
    min-height: 48px;
    padding: 20px 2px;
    
    svg {
      background-color: ${colors.shades.secondary7};
      border-radius: 4px;
    }
    
    &:first-child {
      margin-right: 40px;
    }
  }
`;

const StyledOption = styled(Tab)`
  text-transform: none;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);

  &:hover {
    color: ${colors.shades.secondary1};
  }
  &.Mui-selected {
    color: ${colors.shades.secondary8};
    font-weight: 400;
  }
  &.Mui-focusVisible {
    background-color: #d1eaff;
  }
`;

export {
  StyledSwitcher,
  StyledOption,
};
