import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../styles/colors';

const BackArrowIcon = ({ color, className }) => (
  <svg
    viewBox="0 0 25 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M23.1333 9.112L1.72301 9.112M1.72301 9.112L9.13426 17M1.72301 9.112L9.13426 1.224"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

BackArrowIcon.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

BackArrowIcon.defaultProps = {
  color: colors.base.beta,
  className: '',
};

export default BackArrowIcon;
