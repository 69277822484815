import { Collection } from 'firestorter';

import BaseDocument from '../../Model/BaseDocument';
import Collections from '../../utils/collections';

const MeasurementUnit = {
  METRIC: 'meters/kg',
  IMPERIAL: 'pounds/feet',
};
class PostPaymentFormAnswer extends BaseDocument {
  constructor(id, opts) {
    super(`${Collections.POST_PAYMENT_FORM_ANSWER}/${id}`, opts);
  }

  get unit() {
    return this.data.unit;
  }

  get activityLevel() {
    return this.data.activityLevel;
  }

  get currentWorkoutDays() {
    return this.data.currentWorkoutDays;
  }

  get daysAvailableToWorkout() {
    return this.data.daysAvailableToWorkout;
  }

  get weightsExperience() {
    return this.data.weightsExperience;
  }

  get dietRestrictions() {
    return this.data.dietRestrictions;
  }

  get biologicalSex() {
    return this.data.biologicalSex;
  }

  get answers() {
    return this.data.answers;
  }

  get createdAt() {
    return this.data.createdAt;
  }

  get submissionId() {
    return this.data.submissionId;
  }

  get birthdate() {
    return this.data.birthdate;
  }

  get currentWeight() {
    return this.unit === MeasurementUnit.METRIC
      ? this.data.currentWeightKgs
      : this.data.currentWeightLbs;
  }

  get height() {
    return this.unit === MeasurementUnit.METRIC
      ? this.data.heightCm
      : { ft: this.data.heightFt, in: this.data.heightIn };
  }

  get weeklyWeightGoal() {
    return this.unit === MeasurementUnit.METRIC
      ? this.data.weeklyWeightGoalKgs
      : this.data.weeklyWeightGoalLbs;
  }

  get user() {
    return this.data.user;
  }

  get name() {
    return this.data.name;
  }

  get email() {
    return this.data.email;
  }

  static async getFormDataByUser(userId) {
    const postPaymentFormAnswerCollection = new Collection(Collections.POST_PAYMENT_FORM_ANSWER, {
      createDocument: (src, opts) => new PostPaymentFormAnswer(src, opts),
      query: (ref) => ref
        .where('user', '==', userId)
        .limit(1),
    });
    await postPaymentFormAnswerCollection.fetch();
    return postPaymentFormAnswerCollection.hasDocs ? postPaymentFormAnswerCollection.docs[0] : null;
  }
}

export default PostPaymentFormAnswer;
