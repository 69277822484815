import styled from 'styled-components';

const ActivityContentContainer = styled.div`
  display: flex;
  font-size: 14px;
  line-height: 16px;
  padding: 8px 20px;
  margin: 4px 0 16px;
  white-space: pre-wrap;
`;

export {
  ActivityContentContainer,
};
