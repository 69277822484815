import styled from 'styled-components';
import {
  Box,
  Paper,
} from '@mui/material';

const Container = styled(Paper)`
  height: 180px;
  min-width: 160px;
  width: 180px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1em;
  margin: 25px 15px;
`;

const MainAnalyticContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MainAnalytic = styled(Box)`
  text-align: center;
  margin-top: 1px;
`;

const BottomAnalyticsContainer = styled(Box)`
  display: flex;
  justify-content: space-around;
  width: 100%;
`;

const BottomAnalytic = styled(Box)`
  display: flex;
  align-items: center;
`;

export {
  Container,
  MainAnalyticContainer,
  MainAnalytic,
  BottomAnalyticsContainer,
  BottomAnalytic,
};
