import React from 'react';
import PropTypes from 'prop-types';

import AnalyticNumberCard from '../../AnalyticNumberCard';
import CoachSubscriptionsAnalytics from '../../../../../Model/analytics/CoachSubscriptionAnalytics';
import CoachLeadAnalytics from '../../../../../Model/analytics/CoachLeadAnalytics';
import texts from './texts.json';

const CancellationAnalyticsCard = ({
  subscriptionAnalytics,
  leadAnalytics,
}) => (
  <AnalyticNumberCard
    title={subscriptionAnalytics?.cancellations}
    subTitle={texts.cancellations}
    bottomStats={[
      {
        title: leadAnalytics?.unqualified,
        subTitle: texts.unqualifiedLeads,
      },
    ]}
  />
);

CancellationAnalyticsCard.propTypes = {
  subscriptionAnalytics: PropTypes.instanceOf(CoachSubscriptionsAnalytics).isRequired,
  leadAnalytics: PropTypes.instanceOf(CoachLeadAnalytics).isRequired,
};

export default CancellationAnalyticsCard;
