import styled, { css } from 'styled-components';

import { mixins } from './breakpoints';
import colors from './colors';

const headlineFontSyles = css`
  font-family: 'Recoleta';
  font-style: normal;
  font-weight: bold;
  text-transform: uppercase;
`;

const sansSerifHeadlineFontStyles = css`
  font-family: 'Neue Montreal';
  font-style: normal;
`;

const fontFamily = {
  serif: 'Recoleta',
  sansSerif: 'Neue Montreal',
};

const headlineStyles = css`
  ${headlineFontSyles}
  color: ${colors.base.secondary2};
`;

// Headline styles

const headline1 = css`
  ${headlineStyles}
  font-size: 40px;
  line-height: 61px;
`;

const headline2 = css`
  ${headlineStyles}
  font-size: 28px;
  line-height: 38px;
  font-feature-settings: 'liga' off;
`;

const headline3 = css`
  ${headlineStyles}
  font-size: 22px;
  line-height: 161.95%;
  font-feature-settings: 'liga' off;
`;

// Subtitle styles
const sutbtitleStyles = css`
  font-style: normal;
  font-size: 18px;
  line-height: 27px;
  color: ${colors.base.secondary2};
`;

const subtitle1 = css`
  ${sutbtitleStyles}
  font-weight: normal;
`;

const subtitle2 = css`
  ${sutbtitleStyles}
  font-weight: bold;
`;

// Overline styles

const overline = css`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 3px;
  text-transform: uppercase;
  font-feature-settings: 'liga' off;
  color: ${colors.base.secondary2};
  opacity: 0.6;
`;

// Body styles

const bodyStyles = css`
  font-style: normal;
  font-size: 16px;
  line-height: 22px;
  color: ${colors.base.secondary2};
`;

const body1 = css`
  ${bodyStyles}
  font-weight: normal;
`;

const body2 = css`
  ${bodyStyles}
  font-weight: bold;
`;

// Caption styles

const captionStyles = css`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: ${colors.base.secondary2};
`;

const caption1 = css`
  ${captionStyles}
  font-weight: normal;
`;

const caption2 = css`
  ${captionStyles}
  font-weight: bold;
`;

// Button styles
const button = css`
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  ${mixins.tabletMedia(css`
    font-size: 21px;
  `)}
  line-height: 25px;
  font-feature-settings: 'liga' off;
  user-select: none;
`;

const StyledTextBolder = styled.span`
  font-weight: 600;
`;

export {
  headline1,
  headline2,
  headline3,
  subtitle1,
  subtitle2,
  overline,
  body1,
  body2,
  caption1,
  caption2,
  button,
  fontFamily,
  headlineStyles,
  headlineFontSyles,
  sansSerifHeadlineFontStyles,
  sutbtitleStyles,
  StyledTextBolder,
};
