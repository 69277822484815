import styled, { css } from 'styled-components';
import Typography from '@mui/material/Typography';

import { ReactComponent as PeopleIcon } from '../../../../../../assets/icons/people-icon.svg';
import { ReactComponent as ActionIcon } from '../../../../../../assets/icons/v2/article-check.svg';
import { ReactComponent as CurvedArrowIcon } from '../../../../../../assets/icons/v2/curved-arrow.svg';

import UserInfo from '../../../../../components/UserInfo';
import colors from '../../../../../../styles/colors';

const CaloricTargetContainerCell = styled.div`
  font-size: inherit;
  line-height: 14px;
  display: flex;
  flex-direction: column;
  row-gap: 2px;
`;

const commonCaloriesTextStyles = css`
  font-size: 10px;
  line-height: 11px;
  font-weight: 400;
`;

const CrossedCaloriesText = styled.div`
  ${commonCaloriesTextStyles};
  text-decoration: line-through;
  color: ${colors.shades.gamma3};
  text-decoration-line: line-through;
`;

const StyledCaloricTargetDateTypography = styled(Typography)`
  ${commonCaloriesTextStyles};
  color: ${colors.shades.primary1};
`;

const cellStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  column-gap: 5px;
`;

const ActionCell = styled.div`
  ${cellStyles};
  justify-content: end;
`;

const DateContainer = styled.div`
  height: 100%;
  line-height: 20px;
  text-align: start;
  width: 100%;
  display: flex;
  align-items: center;
  ${({ expired }) => !!expired && css`
    color: ${colors.shades.danger3};
  `};
`;

const RestrictionsList = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 5px;
`;

const RestrictionItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 9px;
  letter-spacing: -0.01em;
  color: ${colors.base.alpha};
  background: ${colors.shades.danger1};
  border: 1px solid ${colors.base.danger};
  border-radius: 45px;
`;

const NoMealPlansMessage = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledUserInfo = styled(UserInfo)`
  width: 100%;
`;

const iconStyles = css`
  height: 20px;
  width: 20px;
`;

const StyledPeopleIcon = styled(PeopleIcon)`
  ${iconStyles};
`;

const StyledActionIcon = styled(ActionIcon)`
  ${iconStyles};
`;

const StyledCurvedArrowIcon = styled(CurvedArrowIcon)`
  ${iconStyles};
`;

export {
  CaloricTargetContainerCell,
  StyledCaloricTargetDateTypography,
  DateContainer,
  NoMealPlansMessage,
  ActionCell,
  RestrictionsList,
  StyledUserInfo,
  CrossedCaloriesText,
  RestrictionItem,
  StyledPeopleIcon,
  StyledActionIcon,
  StyledCurvedArrowIcon,
};
