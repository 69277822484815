import React, {
  useContext,
  useEffect,
} from 'react';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';

import DialogModal from '../../../components/DialogModal';
import LoadingOverlay from '../LoadingOverlay';
import ContractModalContext, { initialValues } from './context/ContractModalContext';
import { withContractModalContextProvider } from './context/withContractModalContext';

import {
  Container,
} from './styles';

const BaseContractModal = ({
  showModal,
  onClose,
  contractType,
  children,
}) => {
  const {
    isLoading,
    setContractType,
  } = useContext(ContractModalContext);

  useEffect(() => {
    setContractType(contractType);
  }, [
    contractType,
    setContractType,
  ]);

  return (
    <DialogModal
      open={showModal}
      onClose={onClose}
      maxWidth="lg"
    >
      <Container>
        {children}
        <LoadingOverlay isLoading={isLoading} />
      </Container>
    </DialogModal>
  );
};

BaseContractModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  contractType: PropTypes.string,
};

BaseContractModal.defaultProps = {
  contractType: initialValues.contractType,
};

export default compose(
  observer,
  withContractModalContextProvider,
)(BaseContractModal);
