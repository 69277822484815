import styled, { css } from 'styled-components';

import { Button } from '@mui/material';

import { ReactComponent as ErrorImage } from '../../assets/errorPage.svg';
import { ReactComponent as BackIcon } from '../../assets/icons/arrow-circle-left.svg';

import { mixins } from '../../styles/breakpoints';
import { sansSerifHeadlineFontStyles } from '../../styles/text';
import colors from '../../styles/colors';

const backgroundZindex = 1;
const mainZindex = 10;

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 20px;
`;

const ImageContainer = styled.div`
  position: relative;
`;

const BackgroundText = styled.p`
  ${sansSerifHeadlineFontStyles};
  position: absolute;
  left: -50%;
  top: 0;
  margin: auto;
  font-weight: 800;
  font-size: 540px;
  line-height: 540px;
  z-index: ${backgroundZindex};
  color: ${colors.base.gamma};
  text-align: center;

  ${mixins.upToTabletMedia(css`
    font-size: 200px;
    line-height: 200px;
    left: -20%;
  `)};
`;

const StyledErrorImage = styled(ErrorImage)`
  position: relative;
  height: 440px;
  width: auto;
  z-index: ${mainZindex};
  padding: 10px;

  ${mixins.upToTabletMedia(css`
    height: 220px;
  `)};
`;

const Title = styled.div`
  ${sansSerifHeadlineFontStyles};
  padding: 20px 10px;
  font-weight: 800;
  font-size: 26px;
  line-height: 32px;
  z-index: ${mainZindex};
  color: ${colors.base.secondary2};
  text-align: center;
`;

const StyledBackIcon = styled(BackIcon)`
  width: 20px;
  height: 20px;
  > path {
    stroke: ${colors.base.alpha};
  }
`;

const StyledButton = styled(Button)`
  background: ${colors.shades.gamma10};
  border: 1px solid ${colors.shades.gamma7};
  color: ${colors.base.alpha};
  border-radius: 5px;
  height: fit-content;
  text-transform: none;
  padding: 10px 20px;
  z-index: ${mainZindex};

  &:hover {
    background: ${colors.shades.gamma10};
    opacity: 0.8;
  }
  &.MuiButton-text {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
  }
`;

export {
  Container,
  BackgroundText,
  StyledErrorImage,
  Title,
  StyledButton,
  StyledBackIcon,
  ImageContainer,
};
