import styled from 'styled-components';
import { Typography } from '@mui/material';

const ConfigContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  height: 100%;
`;

// Common styles used in the config section.
const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
`;

const Text = styled(Typography)`
  margin-bottom: 5px;
`;

const Title = styled(Text)`
  font-weight: bold !important;
  font-size: 24px !important;
`;

const Description = styled(Text)`
  font-size: 16px !important;
  margin-bottom: 15px !important;
`;

export {
  ConfigContainer,
  Wrapper,
  Text,
  Title,
  Description,
};
