import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { compose } from 'recompose';

import TextEditor from '../../../../components/TextEditor';
import useUserDoc from '../../../hooks/useUserDoc';
import LoadingOverlay from '../../LoadingOverlay';
import useToast from '../../../hooks/useToast';
import useComponentMounted from '../../../../hooks/useComponentMounted';
import { ButtonRow, StyledButton } from './styles';
import texts from './texts.json';

const Notes = ({
  clientId,
}) => {
  const [notes, setNotes] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const { showToast } = useToast();
  const isComponentMountedRef = useComponentMounted();

  const {
    isReady: isClientDocReady,
    userDoc: clientDoc,
  } = useUserDoc(clientId);

  useEffect(() => {
    if (clientDoc?.coachNotes) {
      setNotes(clientDoc.coachNotes);
    }
  }, [clientDoc]);

  const onSave = useCallback(async () => {
    setIsSaving(true);
    await clientDoc.updateCoachNotes(notes);
    showToast(texts.savedSuccessfully, { type: 'success' });
    if (isComponentMountedRef.current) {
      setIsSaving(false);
    }
  }, [
    clientDoc,
    notes,
    showToast,
    isComponentMountedRef,
  ]);

  return (
    <>
      <TextEditor value={notes} onChange={setNotes} />
      <ButtonRow>
        <StyledButton onClick={onSave}>Save</StyledButton>
      </ButtonRow>
      <LoadingOverlay isLoading={!isClientDocReady || isSaving} />
    </>
  );
};

Notes.propTypes = {
  clientId: PropTypes.string.isRequired,
};

export default compose(
  observer,
)(Notes);
