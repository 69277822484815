import React from 'react';
import feedType from './config/feedTypes';
import FeedState from './states';

const feedContextInitialState = {
  onFeedSelected: () => {},
  selectedFeedType: feedType.CHECK_IN,
  feedClient: null,
  totalUnreadCount: { total: 0 },
  feedState: FeedState.FEED_NOT_INITIALIZED,
  onFeedError: () => {},
  onFeedRefresh: () => {},
  isFeedReady: false,
  onMarkAsRead: () => {},
  onRestore: () => {},
  feedActivityList: [],
  actionError: false,
  setActionError: () => {},
  activityInProgress: [],
  uniqueClientNames: [],
  setSearchField: () => {},
  searchField: null,
  setIsOldestToNewest: () => {},
  isOldestToNewest: false,
  selectedActionBarType: null,
  setSelectedActionBarType: () => {},
  selectedActivity: null,
  setSelectedActivity: () => {},
  archivedActivityList: [],
  setIsSmartFeedbackSelected: () => {},
  isSmartFeedbackSelected: false,
};

const FeedContext = React.createContext(feedContextInitialState);

export default FeedContext;
