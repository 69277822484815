import React from 'react';
import PropTypes from 'prop-types';
import format from 'string-template';
import { IconButton } from '@mui/material';
import { Container as DraggableContainer, Draggable } from 'react-smooth-dnd';

import { ReactComponent as DragIcon } from '../../../../../../../assets/icons/v2/drag.svg';

import { SectionContainer, SectionHeaderContainer, SectionTitle } from '../../../../../../../components/v2/Section';
import { InfoChip } from '../../styles';
import ActivityComp from '../Activity';
import Activity, { ActivityTypes } from '../../../../../../../Model/Activity';
import { EditorActionType } from '../../utils';
import ActionButtons from '../ActionButtons';
import texts from './texts.json';
import {
  CircuitNote,
  RestWarning,
} from './styles';

const Circuit = ({
  activity,
  circuitTag,
  showRestWarning,
  circuitIndex,
  onDropActivity,
  onAddActivity,
  onClickEdit,
  onRemoveActivity,
  onDuplicateActivity,
}) => {
  const {
    name,
    rounds,
    activities: circuitActivities,
    note,
  } = activity;

  const showRounds = rounds > 1;
  const showHeader = !!name && rounds > 0;

  const circuitType = name ? texts.circuitType.circuit : texts.circuitType.activity;
  const RestWarningText = format(texts.restWarningText, { circuitType });

  return (
    <Draggable key={circuitTag}>
      <SectionContainer>
        {showHeader && (
          <SectionHeaderContainer>
            <IconButton className="drag-handle">
              <DragIcon />
            </IconButton>
            <SectionTitle>{name}</SectionTitle>
            {showRounds && <InfoChip label={format(texts.rounds, { rounds })} />}
            <ActionButtons
              onAdd={() => onAddActivity(circuitIndex)}
              onRemove={() => onRemoveActivity(null, circuitIndex)}
              onDuplicate={() => onDuplicateActivity(null, circuitIndex)}
              onUpdate={() => onClickEdit(ActivityTypes.CIRCUIT, circuitIndex)}
            />
          </SectionHeaderContainer>
        )}
        {note && <CircuitNote>{note}</CircuitNote>}
        <DraggableContainer
          dragHandleSelector=".drag-handle"
          groupName="activities"
          lockAxis="y"
          /*
           * Appends the dragged "ghost" object to the document body.
           * By default the draggable object is rendered on the parent container. But since we are nesting the draggable
           * containers here the ghost will be lost when the parent is transforming it's position due to the drag effect
           * of the ghost. Appending the ghost to the document body will make the ghost immune to these transformations.
           */
          getGhostParent={() => document.body}
          onDrop={({ removedIndex, addedIndex, payload }) => onDropActivity(
            removedIndex, addedIndex, payload, circuitActivities, true, circuitIndex,
          )}
          getChildPayload={(index) => circuitActivities[index]}
        >
          {circuitActivities.map(
            (cActivity, index) => {
              /*
                The tag is a combination of a letter and a number. eg: A1, A2, etc. If the circuit holds only
                one activity, then use the letter only
              */
              const tag = circuitActivities.length === 1 ? `${circuitTag}` : `${circuitTag}${index + 1}`;

              // Show the top connector for every activity except the first one.
              const showTopConnector = index !== 0;

              // Show the bottom connector for every activity except the last one.
              const showBottomConnector = index < circuitActivities.length - 1;

              return (
                <ActivityComp
                  activity={cActivity}
                  tag={tag}
                  showTopConnector={showTopConnector}
                  showBottomConnector={showBottomConnector}
                  key={tag}
                  onRemove={() => onRemoveActivity(index, circuitIndex)}
                  onDuplicate={() => onDuplicateActivity(index, circuitIndex)}
                  onClickEdit={() => onClickEdit(
                    EditorActionType.ACTIVITY,
                    activity.name ? index : circuitIndex,
                    activity.name ? circuitIndex : null,
                  )}
                />
              );
            },
          )}
        </DraggableContainer>
        {showRestWarning && (
          <RestWarning>{RestWarningText}</RestWarning>
        )}
      </SectionContainer>
    </Draggable>
  );
};

Circuit.propTypes = {
  activity: PropTypes.instanceOf(Activity).isRequired,
  circuitTag: PropTypes.string.isRequired,
  showRestWarning: PropTypes.bool,
  circuitIndex: PropTypes.number.isRequired,
  onDropActivity: PropTypes.func.isRequired,
  onAddActivity: PropTypes.func.isRequired,
  onClickEdit: PropTypes.func.isRequired,
  onRemoveActivity: PropTypes.func.isRequired,
  onDuplicateActivity: PropTypes.func.isRequired,
};

Circuit.defaultProps = {
  showRestWarning: false,
};

export default Circuit;
