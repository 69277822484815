import fieldName, {
  endScreenContentFieldName,
  questionFieldName,
} from './formFields';
import QuestionType, { HiddenFieldType } from './questionType';
import defaultFields from './defaultFields';

const initialValues = {
  [fieldName.COACH]: '',
  [fieldName.COACH_ID]: '',
  [fieldName.NAME]: '',
  [fieldName.FIELDS]: defaultFields[fieldName.FIELDS],
  [fieldName.HIDDEN_FIELDS]: defaultFields[fieldName.HIDDEN_FIELDS],
  [fieldName.END_SCREEN_CONTENT]: {
    [endScreenContentFieldName.LINE_1]: '',
    [endScreenContentFieldName.LINE_2]: '',
  },
};

const commonInputFieldValues = {
  [questionFieldName.ID]: '',
  [questionFieldName.CUSTOM_CLASS]: '',
  [questionFieldName.LABEL]: '',
  [questionFieldName.REQUIRED]: false,
};

const initialTextFieldValues = {
  ...commonInputFieldValues,
  [questionFieldName.TYPE]: QuestionType.TEXT,
  [questionFieldName.PLACEHOLDER]: '',
};

const initialEmailFieldValues = {
  ...commonInputFieldValues,
  [questionFieldName.TYPE]: QuestionType.EMAIL,
  [questionFieldName.PLACEHOLDER]: '',
};

const initialOptionsFieldValues = {
  ...commonInputFieldValues,
  [questionFieldName.TYPE]: QuestionType.OPTIONS,
  [questionFieldName.OPTIONS]: '',
  [questionFieldName.ALLOW_MULTIPLE]: false,
};

const initialPhoneFieldValues = {
  ...commonInputFieldValues,
  [questionFieldName.TYPE]: QuestionType.PHONE,
};

const initialNameFieldValues = {
  [questionFieldName.TYPE]: QuestionType.NAME,
  [questionFieldName.FIELDS]: [
    {
      ...(({ [questionFieldName.REQUIRED]: _, ...restOfValues }) => restOfValues)(commonInputFieldValues),
      [questionFieldName.TYPE]: QuestionType.TEXT,
      [questionFieldName.PLACEHOLDER]: '',
    },
    {
      ...commonInputFieldValues,
      [questionFieldName.TYPE]: QuestionType.TEXT,
      [questionFieldName.PLACEHOLDER]: '',
    },
  ],
};

const initialHiddenFieldValues = {
  [questionFieldName.TYPE]: HiddenFieldType.TEXT,
  [questionFieldName.ID]: '',
};

export {
  initialValues,
  initialTextFieldValues,
  initialEmailFieldValues,
  initialOptionsFieldValues,
  initialPhoneFieldValues,
  initialNameFieldValues,
  initialHiddenFieldValues,
};
