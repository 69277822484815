import {
  StripeErrorType,
  NonErrorResponseState,
  SubscriptionControllerState,
  SubscriptionNextStateOnError,
  SubscriptionNextStateOnResponse,
  SubscriptionCreationState,
  StripeSubscriptionState,
  StripePaymentIntentState,
} from './SubscriptionMetadata';

const calculateIncompleteSubscriptionState = (subscription) => {
  const nextStateTemplate = {
    controllerState: SubscriptionControllerState.READY,
    stripeErrorState: {
      type: StripeErrorType.NO_ERROR,
      message: null,
    },
  };
  const latestInvoice = subscription.latest_invoice;
  const paymentIntent = latestInvoice ? latestInvoice.payment_intent : null;
  if (latestInvoice && paymentIntent) {
    const paymentIntentStatus = paymentIntent.status;

    if (paymentIntentStatus === StripePaymentIntentState.REQUIRES_PAYMENT_METHOD) {
      return {
        ...nextStateTemplate,
        subscriptionState: SubscriptionCreationState.WAITING_FOR_VALID_PAYMENT_METHOD,
      };
    }
    if (paymentIntentStatus === StripePaymentIntentState.REQUIRES_ACTION) {
      return {
        ...nextStateTemplate,
        subscriptionState: SubscriptionCreationState.WAITING_FOR_INITIAL_PAYMENT_AUTHORIZATION,
      };
    }
  }

  return {
    ...nextStateTemplate,
    subscriptionState: SubscriptionCreationState.INITIAL, // Subscription creation starts from scratch..
  };
};
/**
 * Returns the initial state of the
 * Subscription-Controller based on the existing current Stripe Subscription.
 *
 * @param {Object} subscription - Current subscription of the user, if exists.
 * @return {Object} - Returns the initial state of the Subscription-Controller
 */
export const calculateInitialStates = (subscription) => {
  const nextStateTemplate = {
    controllerState: SubscriptionControllerState.READY,
    stripeErrorState: {
      type: StripeErrorType.NO_ERROR,
    },
  };
  if (!subscription) {
    return {
      ...nextStateTemplate,
      subscriptionState: SubscriptionCreationState.INITIAL,
    };
  }
  const invoice = subscription.latest_invoice ? subscription.latest_invoice : null;
  const paymentIntent = invoice ? invoice.payment_intent : null;
  const subscriptionBreakdown = {
    currentSubscription: subscription,
    invoice,
    paymentIntent,
  };
  const currentStatus = subscription.status;
  if (currentStatus === StripeSubscriptionState.ACTIVE
    || currentStatus === StripeSubscriptionState.TRIALING) {
    return {
      ...nextStateTemplate,
      controllerState: SubscriptionControllerState.VALID_SUBSCRIPTION_EXISTS,
      subscriptionState: SubscriptionCreationState.SUCCESSFUL_COMPLETION,
      subscriptionBreakdown,
    };
  }
  if (currentStatus === StripeSubscriptionState.INCOMPLETE
    || currentStatus === StripeSubscriptionState.INCOMPLETE_EXPIRED) {
    return {
      ...calculateIncompleteSubscriptionState(subscription),
      subscriptionBreakdown,
    };
  }

  return {
    ...nextStateTemplate,
    subscriptionState: SubscriptionCreationState.INITIAL,
  };
};

/**
 * Indicates what a non-error response, resulted from an action, requires next.
 * This is used for calculating the next state of the subscription flow.
 * @param response
 * @return {string}
 */
function findNonErrorResponseState(response) {
  let responseState = NonErrorResponseState.UNKNOWN;
  if (response.subscription) {
    if (response.subscription.status === StripeSubscriptionState.ACTIVE
      || response.subscription.status === StripeSubscriptionState.TRIALING) {
      responseState = NonErrorResponseState.SUCCESSFUL;
    } else if (response.paymentIntent) {
      const paymentIntentStatus = response.paymentIntent.status;
      if (paymentIntentStatus === StripePaymentIntentState.REQUIRES_PAYMENT_METHOD) {
        responseState = NonErrorResponseState.REQUIRES_PAYMENT_METHOD;
      } else if (paymentIntentStatus === StripePaymentIntentState.REQUIRES_ACTION) {
        responseState = NonErrorResponseState.REQUIRES_ACTION;
      } else {
        responseState = NonErrorResponseState.UNKNOWN;
      }
    }
  }
  return responseState;
}

/**
 * Calculates the next state, including subscription, invoice, paymentIntent of the subscription flow
 * based on a non-erroneous response, resulted from an action.
 * @param {SubscriptionCreationState} currentState - Current state of the subscription creation.
 * @param response - The response received from API calls.
 * @return {Object} - Next state of the Subscription flow.
 */
export const findNextStateFromNonErrorResponse = (currentState, response) => {
  const actionResponse = findNonErrorResponseState(response);
  const subscriptionDetails = {
    invoice: response.invoice,
    subscription: response.subscription,
    paymentIntent: response.paymentIntent,
  };
  const nextStateResponse = {
    ...subscriptionDetails,
    nextState: SubscriptionCreationState.FAILURE_COMPLETION,
    cancelCurrentSubscription: true,
    error: {
      type: StripeErrorType.NO_ERROR,
      message: null,
    },
  };
  const nextStateDetail = SubscriptionNextStateOnResponse[currentState];
  if (nextStateDetail) {
    const { next, cancelCurrentSubscription } = nextStateDetail[actionResponse];
    return {
      ...nextStateResponse,
      nextState: next,
      cancelCurrentSubscription,
    };
  }
  return nextStateResponse;
};

/**
 * Calculates the next state, including subscription, invoice, paymentIntent of the subscription flow
 * based on an erroneous response, resulted from an action.
 * @param currentState -
 * @param response
 * @param localSubscriptionDetail
 * @return {Object} - Next state of the Subscription flow.
 */
export const findNextStateFromErrorResponse = (currentState, response, localSubscriptionDetail) => {
  const { error } = response;
  let nextStateDetail = {
    next: SubscriptionCreationState.FAILURE_COMPLETION,
    cancelCurrentSubscription: true,
  };
  let errorType = StripeErrorType.UNKNOWN_ERROR;
  const errorResponseNextState = SubscriptionNextStateOnError[currentState];
  if (errorResponseNextState) {
    const errorTypeEntry = Object
      .entries(StripeErrorType)
      .find(([, value]) => value === error.type);
    errorType = errorTypeEntry ? errorTypeEntry[1] : StripeErrorType.UNKNOWN_ERROR;
    const { code: stateByCode, ...nextStateByErrorType } = errorResponseNextState[errorType];
    if (stateByCode
      && error.code
      && stateByCode[error.code]) {
      nextStateDetail = stateByCode[error.code];
    } else {
      nextStateDetail = nextStateByErrorType;
    }
  }
  return {
    ...localSubscriptionDetail,
    nextState: nextStateDetail.next,
    cancelCurrentSubscription: nextStateDetail.cancelCurrentSubscription,
    error: {
      type: errorType,
      message: error.message ? error.message : errorType,
    },
  };
};
