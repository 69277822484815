import React, {
  useState,
  useCallback,
  useContext,
  useRef,
} from 'react';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import {
  IconButton,
  Menu,
  MenuItem,
  Avatar,
} from '@mui/material';

import {
  AccountCircle as AccountCircleIcon,
} from '@mui/icons-material';

import UserContext from '../../../../context/UserContext';
import FirebaseContext from '../../../../context/FirebaseContext';

import { UserName } from './styles';

import texts from './texts.json';

const Account = () => {
  const { userDoc } = useContext(UserContext);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const accountButtonRef = useRef(null);
  const { firebase } = useContext(FirebaseContext);

  const toggleMenuVisibility = useCallback(() => {
    setMenuOpen((menuOpen) => !menuOpen);
  }, []);

  const onLogout = useCallback(async () => {
    setMenuOpen(false);
    await firebase.signOut();
  }, [
    firebase,
  ]);

  return (
    <>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-account"
        aria-haspopup="true"
        ref={accountButtonRef}
        color="inherit"
        onClick={toggleMenuVisibility}
      >
        <UserName>{userDoc.firstName}</UserName>
        {userDoc.avatarUrl ? <Avatar src={userDoc.avatarUrl} /> : <AccountCircleIcon />}
      </IconButton>

      <Menu
        id="menu-account"
        anchorEl={accountButtonRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isMenuOpen}
        onClose={toggleMenuVisibility}
      >
        <MenuItem onClick={onLogout}>{texts.logout}</MenuItem>
      </Menu>
    </>
  );
};

export default compose(
  observer,
)(Account);
