import React from 'react';
import styled, { css } from 'styled-components';
import { IonSpinner } from '@ionic/react';

import colors from '../../styles/colors';
import { body1, body2 } from '../../styles/text';
import Button from '../../components/Button';

const IntermediateText = styled.div`
  ${body2}
  font-size: 12px;
  font-feature-settings: 'liga' off;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.16em;
  padding: 34px 0 29px 0;
`;

const SuccessMessage = styled.p`
  font-size: 14px;
  color: ${colors.shades.success2};
`;

const ErrorMessage = styled.p`
  padding: 0 25px;
  color: ${colors.base.danger};
`;

const helpTextBaseCss = css`
  ${body1}
  font-feature-settings: 'liga' off;
  text-align: center;
  letter-spacing: 0.16em;

  @media (min-width: 375px) {
    font-size: 14px;
  }
`;

const HelpText = styled.div`
  ${helpTextBaseCss}
`;

const LinkButton = (props) => <Button {...props} variant="link" />;

const ActionButton = styled(LinkButton)`
  ${helpTextBaseCss}
  width: unset;
  height: unset;
  padding: 0 5px;
  font-weight: normal;
  text-transform: none;
  text-decoration: underline;
  cursor: pointer;
`;

const InfoMessage = styled.div`
  font-size: 18px;
  border: 5px solid ${colors.base.secondary2};
  text-align: center;
  padding: 5px;
  margin: 10px;
  color: ${colors.base.secondary2};
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 150px;
`;

const StyledSpinner = styled(IonSpinner)`
  width: 80px;
  height: 80px;
`;

const ContactSupport = styled.div`
  ${body1};
  margin-top: 30px;
  font-size: 12px;
  font-feature-settings: 'liga' off;
  text-align: center;
  letter-spacing: 0.16em;
  color: ${colors.base.secondary2};
  padding: 0 10px;
  a {
    color: ${colors.base.secondary2};
    text-transform: none;
    text-decoration: underline;
  }
`;

export {
  IntermediateText,
  ErrorMessage,
  SuccessMessage,
  HelpText,
  ActionButton,
  InfoMessage,
  Container,
  StyledSpinner,
  ContactSupport,
};
