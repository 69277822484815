import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import { Delete, Reorder } from '@mui/icons-material';

import { questionFieldName as fieldName } from '../../formFields';
import texts from '../../texts';

import {
  RowWrapper,
  StyledTypography,
  FieldsContainer,
  ButtonsContainer,
  DefaultFieldIndicator,
  StyledInfoIcon,
} from './styles';
import componentTexts from './texts.json';

const QuestionStep = ({
  question,
  removeRow,
  draggable,
  Component,
  extraProps,
}) => (
  <RowWrapper>
    <FieldsContainer>
      <StyledTypography>
        {`${texts.questionTypeLabel[question[fieldName.TYPE]]}`}
      </StyledTypography>
      <ButtonsContainer>
        <IconButton onClick={removeRow}>
          <Delete />
        </IconButton>
        {draggable && (
          <IconButton className="drag-handle">
            <Reorder />
          </IconButton>
        )}
      </ButtonsContainer>
    </FieldsContainer>
    {question.isDefault && (
      <DefaultFieldIndicator>
        <StyledInfoIcon />
        {componentTexts.default}
      </DefaultFieldIndicator>
    )}
    {Component && <Component {...extraProps} />}
  </RowWrapper>
);

QuestionStep.propTypes = {
  question: PropTypes.object.isRequired,
  removeRow: PropTypes.func.isRequired,
  draggable: PropTypes.bool,
  Component: PropTypes.elementType,
  extraProps: PropTypes.object,
};

QuestionStep.defaultProps = {
  draggable: true,
  extraProps: {},
  Component: null,
};

export default QuestionStep;
