import uuid from 'uuid/v4';
import {
  isIOS as isIOSDevice,
  isIOS13,
  isAndroid,
} from 'react-device-detect';

/*
  We need to check both iOS and iOS13 because on iPad Pro, for example, isIOS returns false
  while iOS13 returns true (when iOS13 is loaded). The same happens with isMobileSafari and
  isSafari.
*/
const isIOS = isIOSDevice || isIOS13;

/**
 * Gets the device id from local storage, and if it doesn't
 * exist, generate one and store into local storage
 */
const getDeviceId = () => {
  let deviceId = window.localStorage.getItem('deviceId');
  if (!deviceId) {
    deviceId = uuid();
    window.localStorage.setItem('deviceId', deviceId);
  }
  return deviceId;
};

export {
  getDeviceId,
  isIOS,
  isAndroid,
};
