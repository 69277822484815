import React from 'react';
import { OnBoardingFlowContextProvider } from './OnboardingFlowContext';

const withOnboardingFlowContext = (Component) => (props) => (
  <OnBoardingFlowContextProvider>
    <Component {...props} />
  </OnBoardingFlowContextProvider>
);

export default withOnboardingFlowContext;
