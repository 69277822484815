const actionBarType = {
  CHAT: 'CHAT',
  MOBILE_VIEW: 'MOBILE_VIEW',
  WORKOUT_ASSIGNMENT: 'WORKOUT_ASSIGNMENT',
  CHECK_IN_FEEDBACK: 'CHECK_IN_FEEDBACK',
};

export {
  actionBarType,
};
