import React, {
  useMemo,
  useState,
} from 'react';

import { TableTabContainer } from '../styles';
import { ActionType } from './utils';
import WorkoutEditor from './WorkoutEditor';
import WorkoutsTable from './WorkoutsTable';

const Workouts = () => {
  const [selectedWorkout, setSelectedWorkout] = useState(null);
  const [actionPerformed, setActionPerformed] = useState(null);

  const handleActionClick = (workout, action) => {
    setActionPerformed(action);
    setSelectedWorkout(workout);
  };

  const showEditorView = useMemo(
    () => [ActionType.CREATE, ActionType.UPDATE].includes(actionPerformed),
    [actionPerformed],
  );

  return (
    <TableTabContainer>
      {showEditorView
        ? (
          <WorkoutEditor
            workout={selectedWorkout}
            action={actionPerformed}
            onSave={() => handleActionClick(null, null)}
            onClose={() => handleActionClick(null, null)}
          />
        )
        : (
          <WorkoutsTable
            handleActionClick={handleActionClick}
            selectedWorkout={selectedWorkout}
            actionPerformed={actionPerformed}
          />
        )}
    </TableTabContainer>
  );
};

export default Workouts;
