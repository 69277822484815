import styled, { css } from 'styled-components';

import Button from '../../../../../components/Button';
import { ReactComponent as BroadcastIcon } from '../../../../../assets/icons/broadcast-icon.svg';
import colors from '../../../../../styles/colors';

const StyledBroadcastIcon = styled(BroadcastIcon)`
  margin: 0 10px 0 0;
  width: 14px;
  height: 14px;
  > path {
    stroke: ${colors.base.secondary2};
  }
`;

const StyledContainer = styled.div`
  align-self: center;
  margin-left: auto;
`;

const buttonStyle = css`
  background-color: ${colors.shades.secondary4};
  border-radius: 5px;
  color: ${colors.base.secondary2};
  font-size: 12px;
  padding: 2px 10px;
  margin: 16px 16px;
  font-weight: 500;
  width: fit-content;
  text-transform: none;
  border: 1px solid ${colors.shades.secondary4};
  &:hover {
    background-color: ${colors.shades.secondary5};
    border: 1px solid ${colors.shades.secondary4};
  }
`;

const BroadcastMessageButton = styled(Button)`
  ${buttonStyle};
  margin-left: auto;
  cursor: pointer;
`;

export {
  BroadcastMessageButton,
  StyledContainer,
  StyledBroadcastIcon,
};
