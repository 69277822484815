import styled from 'styled-components';
import { Description as DescriptionIcon } from '@mui/icons-material';

import colors from '../../../styles/colors';
import Avatar from '../../../components/Avatar';

const avatarPx = 40;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 8px;
`;

const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const StyledFileIcon = styled(DescriptionIcon)`
  width: 20px;
  height: 20px;
  > path {
    stroke: ${colors.base.secondary2};
    fill: ${colors.base.secondary3};
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: calc(90% - 30px);
  .MuiIconButton-root {
    padding: 2px 10px;
    svg > path {
      stroke: ${colors.base.primary};
      fill: ${colors.shades.warning1};
    }
  }
`;

const StyledLink = styled.a`
  cursor: pointer;
  max-width: 100%;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Time = styled.div`
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
`;

const StyledAvatar = styled(Avatar)`
  width: ${avatarPx}px;
  height: ${avatarPx}px;
`;

export {
  NameContainer,
  Container,
  TitleContainer,
  StyledLink,
  Title,
  Time,
  StyledAvatar,
  StyledFileIcon,
};
