import format from 'string-template';

import {
  AnalyticFrequency,
  AnalyticType,
  AnalyticUserType,
} from '../../../utils/analytics';
import Collections from '../../../utils/collections';
import MonthlyAnalytics from './MonthlyAnalytics';

class CoachAvgClientLifespanAnalytics extends MonthlyAnalytics {
  get avgLifespan() {
    return this.data.avgLifespan;
  }

  static getCollectionPath(coachId) {
    return format(Collections.ANALYTICS, {
      userType: AnalyticUserType.COACH,
      analyticType: AnalyticType.USER_LIFESPAN,
      frequency: AnalyticFrequency.MONTHLY,
      userId: coachId,
    });
  }
}

export default CoachAvgClientLifespanAnalytics;
