import React, { useContext } from 'react';
import LoadingPage from '../../../components/LoadingPage';

import ManageClientContextProvider from './ManageClientContextProvider';
import ManageClientContext from './ManageClientContext';

const withManageClientContextProvider = (Component) => (props) => (
  <ManageClientContextProvider>
    <Component {...props} />
  </ManageClientContextProvider>
);

const withManageClientContextReady = (Component) => (props) => {
  const { isReady } = useContext(ManageClientContext);
  return isReady ? <Component {...props} /> : <LoadingPage />;
};

export {
  withManageClientContextProvider,
  withManageClientContextReady,
};
