import React from 'react';

import CoachConfigurations from '../CoachConfigurations';
import { AssignmentRole } from '../../../utils/internalAssignments';
import {
  tabs,
  tabsConfig,
} from './utils';

const CRXCoachConfigurations = () => (
  <CoachConfigurations
    defaultTab={tabs.MOBILE_APP}
    tabsConfig={tabsConfig}
    role={AssignmentRole.CRX}
  />
);

export default CRXCoachConfigurations;
