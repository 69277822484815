import React from 'react';
import PropTypes from 'prop-types';

import ToolsLayout from '../ToolsLayout';
import SupportSidebar from './SupportSidebar';

const SupportToolsLayout = ({
  children,
}) => (
  <ToolsLayout sidebarComponent={<SupportSidebar />}>
    {children}
  </ToolsLayout>
);

SupportToolsLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SupportToolsLayout;
