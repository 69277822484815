import { RecipeTag } from '../../../../utils/meals';

const texts = {
  view: 'View',
  delete: 'Delete',
  prepTime: '{preparationTime} mins',
  macroNutrient: '{nutrient}: {amount}g ({percentage}%)',
  caloriesUnit: 'kcal',
  recipeImageAlt: 'Recipe image',
  empty: '-',
  meal: {
    [RecipeTag.BREAKFAST]: 'Breakfast',
    [RecipeTag.LUNCH]: 'Lunch',
    [RecipeTag.DINNER]: 'Dinner',
    [RecipeTag.SNACK]: 'Snack',
  },
};

export default texts;
