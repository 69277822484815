import { Typography } from '@mui/material';
import styled from 'styled-components';

import colors from '../../../../../styles/colors';

const EditWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: flex-start;
  margin-right: 20px;

  &:last-child {
    margin-right: 0;
  }
`;

const EditLabel = styled(Typography)`
  color: ${colors.shades.primary1};
  font-weight: 500;
  font-size: 11px;
  line-height: 9px;
  margin: ${(props) => (props.$noMargins ? '0' : '0 14px 10px 4px')};
  letter-spacing: 0.07em;
  text-transform: uppercase;
`;

const EditSubLabel = styled(Typography)`
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: ${colors.shades.gamma10};
`;

const EditContent = styled.div`
  width: 100%;
  text-transform: none;
`;

export {
  EditWrapper,
  EditLabel,
  EditSubLabel,
  EditContent,
};
