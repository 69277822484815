import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@mui/material';

import { ReactComponent as AddIcon } from '../../../assets/icons/v2/add-yellow.svg';
import { ReactComponent as AddMutedIcon } from '../../../assets/icons/v2/add.svg';

import { StyledAddEditButton } from './styles';

const AddButton = ({
  onClick,
  children,
  disabled,
  variant,
}) => (
  <StyledAddEditButton
    onClick={onClick}
    startIcon={variant === 'muted' ? <AddMutedIcon /> : <AddIcon />}
    component={Button}
    disabled={disabled}
    $variant={variant}
  >
    {children}
  </StyledAddEditButton>
);

AddButton.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  variant: PropTypes.oneOf(['normal', 'muted', 'square']),
};

AddButton.defaultProps = {
  children: [],
  onClick: () => {},
  disabled: false,
  variant: 'normal',
};

export default AddButton;
