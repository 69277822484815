import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { DateFormat } from '../../../../../../utils/date';
import CoachActiveUsersAnalytics from '../../../../../Model/analytics/CoachActiveUsersAnalytics';
import ChartContainer from '../../ChartContainer';
import LineChart from '../../LineChart';
import texts from './texts.json';

const ActiveUsersChart = ({
  activeUsersAnalytics,
}) => (
  <ChartContainer title={texts.activeUsers}>
    <LineChart
      data={activeUsersAnalytics
        .map((usrAnalytic) => ({
          name: moment(usrAnalytic.id, DateFormat.DEFAULT_DATE_FORMAT)
            .format(DateFormat.DATE_MONTH_SHORT_DISPLAY_FORMAT),
          'Active Users': usrAnalytic.activeUsers,
        }))}
      keys={['Active Users']}
      toolTipFormatter={(value) => Math.abs(value)}
    />
  </ChartContainer>
);
ActiveUsersChart.propTypes = {
  activeUsersAnalytics: PropTypes.arrayOf(PropTypes.instanceOf(CoachActiveUsersAnalytics)).isRequired,
};

export default ActiveUsersChart;
