import styled, { css } from 'styled-components';

import {
  Select,
  MenuItem,
} from '@mui/material';

import colors from '../../../../../../styles/colors';

import { WarningTag } from '../../../../../../components/Tags';

const menuStyles = {
  '& .MuiMenu-list': {
    padding: 0,
  },
};

const commonStyles = css`
  box-sizing: border-box;
  background: ${colors.shades.primary7};
  display: flex;
  align-items: center;
  column-gap: 6px;
  font-size: 12px;
  line-height: 14px;
`;

const StyledSelect = styled(Select)`
  & .MuiSelect-select {
    ${commonStyles};
    border: 1px solid ${colors.shades.primary3};
    border-radius: 5px;
    height: 40px;
  }
  min-width: 265px;

  .MuiOutlinedInput-notchedOutline {
    border-color: ${colors.shades.primary3};
  }
  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${colors.shades.primary4};
  }
  &:hover .MuiOutlinedInput-notchedOutline {
    border-color: ${colors.shades.primary2};
  }
`;

const StyledMenuItem = styled(MenuItem)`
  ${commonStyles};
  display: flex;
`;

const StyledTag = styled(WarningTag)`
  background: ${colors.shades.primary3};
  border-color: ${colors.shades.primary4};
  margin-left: auto;
`;

export {
  StyledSelect,
  StyledMenuItem,
  StyledTag,
  menuStyles,
};
