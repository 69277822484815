import React, {
  useState,
  useEffect,
  useContext,
} from 'react';
import { observer } from 'mobx-react';
import { compose } from 'recompose';

import HelpToggle from '../../components/HelpToggle';
import FirebaseContext from '../../context/FirebaseContext';
import User from '../../Model/User';
import PageContainer from '../Login/components/PageContainer';
import useNavigation from '../../hooks/useNavigation';
import useSessionStore from '../../hooks/useSessionStore';
import useComponentMounted from '../../hooks/useComponentMounted';

import SignupForm from './SignupForm';
import { ErrorMessage } from './styles';
import texts from './texts.json';

const InternalSignup = () => {
  const { authUser } = useSessionStore();
  const { firebase } = useContext(FirebaseContext);
  const [signupError, setSignupError] = useState(null);
  const [userRegistered, setUserRegistered] = useState(false);
  const [isUserSaved, setIsUserSaved] = useState(false);
  const isComponentMountedRef = useComponentMounted();

  const { navigateTo, routes } = useNavigation();

  const goToLogin = () => {
    navigateTo(routes.LOGIN);
  };

  const onSubmit = async ({
    email,
    password,
    firstName,
    phoneNumber,
  }) => {
    try {
      setUserRegistered(true);

      // Get the list of sign-in methods associated with the email
      const signInMethods = await firebase.auth.fetchSignInMethodsForEmail(email);

      // If sign-in methods available, then email is already exist.
      if (signInMethods.length > 0 && isComponentMountedRef.current) {
        setUserRegistered(false);
        setSignupError(texts.emailExist);
        return;
      }

      const { user } = await firebase
        .auth
        .createUserWithEmailAndPassword(email.toLowerCase(), password);
      // Update the first name in the firebase auth user and in the user doc
      await user.updateProfile({ displayName: firstName });

      const newUserDoc = new User(`/user/${user.uid}`);
      await newUserDoc.set({
        firstName,
        email: email.toLowerCase(),
        phoneNumber,
        isOnboarded: true,
      }, {
        merge: true,
      });

      if (isComponentMountedRef.current) {
        setIsUserSaved(true);
      }
    } catch (error) {
      if (isComponentMountedRef.current) {
        setUserRegistered(false);
        setSignupError(error.message);
      }
    }
  };

  useEffect(() => {
    if (authUser && isUserSaved) {
      navigateTo(routes.HOME);
    }
  }, [
    navigateTo,
    routes,
    userRegistered,
    authUser,
    isUserSaved,
  ]);

  return (
    <PageContainer formHeading={texts.internalSignUp}>
      <SignupForm
        onSubmit={onSubmit}
        isProcessing={userRegistered}
      />
      {!!signupError && <ErrorMessage>{signupError}</ErrorMessage>}
      <HelpToggle
        toggleForms={goToLogin}
        helpText={texts.accountExits}
        actionText={texts.login}
      />
    </PageContainer>
  );
};

export default compose(
  observer,
)(InternalSignup);
