import styled, { css } from 'styled-components';
import { Typography } from '@mui/material';
import { Form } from 'formik';

import colors from '../../../../../styles/colors';
import InputLabel from '../../../../../components/v2/InputLabel';
import {
  SectionCompartment,
} from '../../../../../components/v2/Section';
import FormikInput from '../../../../../components/v2/FormikInput';

const guidelineTextStyles = css`
  color: ${colors.shades.gamma10};
  font-size: 12px;
`;

const listboxStyles = {
  maxHeight: '150px',
};

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const StyledForm = styled(Form)`
  display: flex;
  width: 100%;
  flex-direction: column;
  border: 1px solid ${colors.shades.secondary7};
  border-radius: 5px;
`;

const StyledSectionCompartment = styled(SectionCompartment)`
  flex-direction: row;
  width: fit-content;
`;

const FormVideoContainer = styled.div`
  width: 250px;
  padding: 10px 20px;
`;

const FormFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding: 10px;
  flex-grow: 1;
`;

const Label = styled(InputLabel)`
  padding-left: 10px;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 5px;
`;

const TagsSection = styled.div`
  margin: 10px;
  display: flex;
  flex-direction: column;
`;

const TagContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 10px;
  flex-wrap: wrap;
  gap: 10px;
`;

const ButtonContainer = styled.div`
  width: 180px;
  padding: 20px;
  display: flex;
`;

const GuideLineContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

const GuidelineTitle = styled(Typography)`
  font-weight: 500;
  ${guidelineTextStyles};
`;

const GuidelineItem = styled.li`
  ${guidelineTextStyles};
`;

const StyledList = styled.ul`
  padding-left: 15px;
  margin-top: 5px;
`;

const StyledFormikInput = styled(FormikInput)`
  .MuiInputBase-multiline {
    height: fit-content;
  }
`;

export {
  listboxStyles,
  Container,
  StyledForm,
  StyledSectionCompartment,
  FormVideoContainer,
  FormFieldsContainer,
  TagsSection,
  TagContainer,
  Label,
  ButtonContainer,
  GuideLineContainer,
  GuidelineTitle,
  GuidelineItem,
  StyledList,
  StyledFormikInput,
};
