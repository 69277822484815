import * as Yup from 'yup';
import { isValidPhoneNumber } from '../../../../../utils/validators';

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, 'Password must be at least 6 characters')
    .required('Password is required'),
  firstName: Yup.string()
    .required('First Name is required'),
  lastName: Yup.string()
    .required('Last Name is required'),
  email: Yup.string()
    .email('Enter a valid Email address')
    .required('Email is required'),
  phoneNumber: Yup.string()
    .required('Phone Number is required')
    .test('isPhoneNumber', 'Enter a valid Phone Number', isValidPhoneNumber),
});

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  password: '',
};

const getInitialValues = (updatedInitialValues) => {
  const newInitialValues = { ...initialValues };
  Object.keys(newInitialValues).forEach((key) => {
    if (updatedInitialValues[key]) {
      newInitialValues[key] = updatedInitialValues[key];
    }
  });
  return newInitialValues;
};

export {
  validationSchema,
  getInitialValues,
};
