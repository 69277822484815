import React, { useContext, useState } from 'react';
import { Checkbox, FormControlLabel, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';

import Modal from '../../../../../../../components/v2/Modal';
import InputLabel from '../../../../../../../components/v2/InputLabel';
import TextField from '../../../../../../../components/v2/TextField';
import { CoachingActivity } from '../../../../../../../utils/log';
import useLogger from '../../../../../../../hooks/useLogger';
import LoadingOverlay from '../../../../../../components/LoadingOverlay';
import FirebaseContext from '../../../../../../../context/FirebaseContext';
import ExternalCoachContext from '../../../../../../context/ExternalCoachContext';
import useToast from '../../../../../../hooks/useToast';
import { Features } from '../../../../../../utils/product';
import { FeatureContainer, StyledButton, StyledAddIcon } from './styles';
import texts from './texts.json';

const AddProductModal = ({
  open,
  onClose,
}) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [isVideoFeedBackEnabled, setIsVideoFeedBackEnabled] = useState(true);
  const [isChatEnabled, setIsChatEnabled] = useState(true);
  const [isCheckInFeedBackEnabled, setIsCheckInFeedBackEnabled] = useState(true);
  const [isMealPlanEnabled, setIsMealPlanEnabled] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const { externalCoachDoc, products } = useContext(ExternalCoachContext);
  const { firebase: { remote } } = useContext(FirebaseContext);
  const { showToast } = useToast();
  const { logCoachingActivity } = useLogger();

  const onAddProduct = async () => {
    let errorMsg;
    if (!name) {
      errorMsg = texts.errors.noName;
    } else if (!description) {
      errorMsg = texts.errors.noDescription;
    } else {
      const productExists = products.find((product) => product.name.toLowerCase() === name.toLowerCase());
      if (productExists) {
        errorMsg = texts.errors.productExists;
      }
    }
    if (errorMsg) {
      showToast(errorMsg, { error: true });
      return;
    }
    try {
      setIsSaving(true);
      const apiResponse = await remote('createProduct', {
        coachId: externalCoachDoc?.id,
        name,
        description,
        enabledFeatures: [
          ...(isVideoFeedBackEnabled ? [Features.WORKOUT_VIDEO_FEEDBACK] : []),
          ...(isMealPlanEnabled ? [Features.NUTRITION_PLAN] : []),
          ...(isChatEnabled ? [Features.CHAT] : []),
          ...(isCheckInFeedBackEnabled ? [Features.CHECK_IN_NOTIFICATION] : []),
        ],
      });
      const { error } = await apiResponse.json();
      if (error) {
        showToast(error, { error: true });
        setIsSaving(false);
        return;
      }
      logCoachingActivity(CoachingActivity.CREATED_PRODUCT);
      showToast(texts.success, { success: true });
      setIsSaving(false);
      onClose();
    } catch (error) {
      setIsSaving(false);
      showToast(error.message, { error: true });
    }
  };

  return (
    <Modal
      open={open}
      title={texts.title}
      onClose={onClose}
      actionButtons={(
        <StyledButton onClick={onAddProduct}>
          <StyledAddIcon />
          {texts.addProduct}
        </StyledButton>
      )}
    >
      <InputLabel>{texts.fieldNames.productName}</InputLabel>
      <TextField onChange={(e) => setName(e.target.value)} value={name} />
      <InputLabel>{texts.fieldNames.productDescription}</InputLabel>
      <TextField multiline minRows={3} onChange={(e) => setDescription(e.target.value)} value={description} />
      <InputLabel>{texts.fieldNames.features}</InputLabel>
      <FeatureContainer>
        <Tooltip title={texts.tooltips.workoutFeedback} placement="right">
          <FormControlLabel
            control={(
              <Checkbox
                checked={isVideoFeedBackEnabled}
                onChange={(e) => setIsVideoFeedBackEnabled(e.target.checked)}
              />
          )}
            label={texts.workoutFeedback}
          />
        </Tooltip>
        <Tooltip title={texts.tooltips.mealPlan} placement="right">
          <FormControlLabel
            control={(
              <Checkbox
                checked={isMealPlanEnabled}
                onChange={(e) => setIsMealPlanEnabled(e.target.checked)}
              />
          )}
            label={texts.mealPlan}
          />
        </Tooltip>
        <Tooltip title={texts.tooltips.checkInFeedback} placement="right">
          <FormControlLabel
            control={(
              <Checkbox
                checked={isCheckInFeedBackEnabled}
                onChange={(e) => setIsCheckInFeedBackEnabled(e.target.checked)}
              />
          )}
            label={texts.checkInFeedback}
          />
        </Tooltip>
        <Tooltip title={texts.tooltips.chat} placement="right">
          <FormControlLabel
            control={(
              <Checkbox
                checked={isChatEnabled}
                onChange={(e) => setIsChatEnabled(e.target.checked)}
              />
          )}
            label={texts.chat}
          />
        </Tooltip>
      </FeatureContainer>
      <LoadingOverlay isLoading={isSaving} />
    </Modal>
  );
};

AddProductModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AddProductModal;
