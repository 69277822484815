import React from 'react';

const initialValues = {
  isLoadingDocs: false,
  payoutReconciliationCollection: {
    docs: [],
  },
};

const PayoutReconciliationContext = React.createContext(initialValues);

export default PayoutReconciliationContext;
export {
  initialValues,
};
