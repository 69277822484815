import styled, { css } from 'styled-components';

import colors from '../../../../../styles/colors';
import Button from '../../../../../components/Button';
import { ReactComponent as ProgramIcon } from '../../../../../assets/icons/menuIcons/exercise-editor.svg';
import { ReactComponent as WorkoutIcon } from '../../../../../assets/icons/menuIcons/workout-library.svg';
import { ReactComponent as ClockIcon } from '../../../../../assets/icons/v2/clock.svg';
import { ReactComponent as CopyIcon } from '../../../../../assets/icons/copy.svg';
import {
  ReactComponent as ReminderIcon,
} from '../../../../../assets/icons/menuIcons/feedMenuIcons/special-reminders.svg';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 2px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2px;
`;

const StyledButton = styled(Button)`
  width: 25px;
  padding: 5px;
  background: ${colors.shades.secondary8};
  border: 1px solid ${colors.shades.secondary6};
  color: ${colors.base.alpha};
  border-radius: 50%;
  cursor: pointer;
  path {
    stroke: ${colors.base.alpha};
  }
  &:hover {
    background-color: ${colors.shades.secondary6};
    path {
      stroke: ${colors.shades.secondary8};
      fill: ${colors.shades.secondary6};
    }
  }
`;

const DayContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
`;

const actionIconStyles = css`
  height: 12px;
  width: 12px;
`;

const StyledProgramIcon = styled(ProgramIcon)`
  ${actionIconStyles};
`;
const StyledWorkoutIcon = styled(WorkoutIcon)`
  ${actionIconStyles};
`;
const StyledCopyIcon = styled(CopyIcon)`
  ${actionIconStyles};
`;
const StyledReminderIcon = styled(ReminderIcon)`
  ${actionIconStyles};
`;

const StyledClockIcon = styled(ClockIcon)`
  ${actionIconStyles};
  path {
    stroke: ${colors.shades.secondary8};
    fill: ${colors.shades.secondary6};
  }
`;

export {
  Container,
  ButtonContainer,
  StyledButton,
  DayContainer,
  StyledProgramIcon,
  StyledWorkoutIcon,
  StyledCopyIcon,
  StyledClockIcon,
  StyledReminderIcon,
};
