import styled, { css } from 'styled-components';
import {
  subtitle2,
  fontFamily,
} from '../../../../../styles/text';
import colors from '../../../../../styles/colors';
import { mixins } from '../../../../../styles/breakpoints';

const CoachMessage = styled.div`
  text-align: center;
  font-weight: normal;
  font-size: 16px;
  line-height: 19.2px;
  margin-bottom: 24px;
`;

const Container = styled.div`
  ${subtitle2}
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${colors.base.beta};
  padding: 0 24px;
  background-color: transparent;
  width: auto;
  ${mixins.upToTabletMedia(css`
    margin: 8px;
  `)}

  ${CoachMessage} {
    margin: 15px 0;
  }
`;

const ExplainerContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  background: ${colors.base.primary};
  border: 1px solid ${colors.shades.primary2};
  border-radius: 5px;
`;

const BillNote = styled.div`
  font-family: ${fontFamily.sansSerif};
  font-style: normal;
  color: ${colors.shades.gamma7};
`;

const PricingNote = styled(BillNote)`
  text-transform: unset;
  font-size: 14px;
`;

const BillTitle = styled.div`
  font-family: ${fontFamily.sansSerif};
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  color: ${colors.base.secondary2};
`;

const StyledList = styled.ul`
  margin: 10px 0;
  padding-inline-start: 20px;
`;

const StyledListItem = styled.li`
  ::marker {
    color: ${colors.shades.gamma7};
    font-size: 10px;
  }
`;

export {
  Container,
  CoachMessage,
  ExplainerContainer,
  BillNote,
  BillTitle,
  PricingNote,
  StyledList,
  StyledListItem,
};
