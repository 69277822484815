import Collections from '../../utils/collections';
import BaseDocument from '../../Model/BaseDocument';

class DraftMessage extends BaseDocument {
  constructor(id, opts) {
    super(`${Collections.DRAFT_MESSAGE}/${id}`, opts);
  }

  get message() {
    return this.data.message || '';
  }

  get lastUpdated() {
    return this.data.lastUpdated?.toDate();
  }

  static async getById(id) {
    const draftMessageDoc = new DraftMessage(id);
    await draftMessageDoc.init();
    return draftMessageDoc.exists ? draftMessageDoc : null;
  }
}

export default DraftMessage;
