import React, {
  useState,
  useMemo,
  useContext,
} from 'react';
import { compose } from 'recompose';

import { SaveButton } from '../../../../../../components/Button/ActionButtons';
import Select from '../../../../../../components/Select';
import { getValidMealPlanOptions } from '../../../../../utils/mealPlan';
import EditItem from '../../../components/EditItem';
import { DietaryRestrictionText } from '../../../../../Model/UserNutritionProfile';
import { RestrictionAllergens } from '../../../../../utils/meals';
import MealPlanContext, { withMealPlanContextReady } from '../../../../../context/MealPlanContext';
import useToast from '../../../../../hooks/useToast';
import MealPlanSelect from '../../../components/MealPlanSelect/MealPlanSelect';
import {
  TitledActionContainer,
  TitledActionContainerFooter,
  TitledActionContainerHeader,
  TitledActionContainerSection,
} from '../../../styles';

import texts from './texts.json';

const DefaultMealPlan = () => {
  const {
    mealPlanViewsCollection: {
      docs: mealPlanViews = [],
    },
    saveDefaultMealPlan,
    defaultMealPlan,
  } = useContext(MealPlanContext);

  const { showToast } = useToast();

  const [currentMealPlan, setCurrentMealPlan] = useState(defaultMealPlan);
  const [selectedAllergenFilter, setSelectedAllergenFilter] = useState('');

  const onFilterChange = (event) => {
    setSelectedAllergenFilter(event.target.value);
    setCurrentMealPlan('');
  };

  const filteredMealPlans = useMemo(
    () => {
      // Get valid, non archived, meal plans
      const validMealPlans = getValidMealPlanOptions(mealPlanViews);
      if (!selectedAllergenFilter) {
        return validMealPlans;
      }

      // Apply filter based on selected allergen
      const filteredPlans = !selectedAllergenFilter
        ? validMealPlans
        : validMealPlans.filter((mealPlan) => {
          const { allergenTags } = mealPlan;
          return !allergenTags.some((tag) => RestrictionAllergens[selectedAllergenFilter].includes(tag));
        });

      // Return only the id and name, renamed to value and label
      return filteredPlans.map(({ id, name }) => ({ value: id, label: name }));
    },
    [
      mealPlanViews,
      selectedAllergenFilter,
    ],
  );

  const handleSave = () => {
    saveDefaultMealPlan(currentMealPlan);
    showToast(texts.saved);
  };

  const allergensFilterOptions = {
    '': texts.allPlans,
    ...DietaryRestrictionText,
  };

  const filterSelectOptions = Object.entries(allergensFilterOptions).map(([key, value]) => ({
    value: key,
    label: value,
  }));

  return (
    <TitledActionContainer>
      <TitledActionContainerHeader>{texts.header}</TitledActionContainerHeader>
      <TitledActionContainerSection>
        <EditItem label={texts.filter}>
          <Select
            options={filterSelectOptions}
            value={selectedAllergenFilter}
            displayEmpty
            onChange={onFilterChange}
          />
        </EditItem>
        <EditItem label={texts.select}>
          <MealPlanSelect
            value={currentMealPlan}
            mealPlanOptions={filteredMealPlans}
            onChange={(event) => setCurrentMealPlan(event.target.value)}
          />
        </EditItem>
      </TitledActionContainerSection>
      <TitledActionContainerFooter>
        <SaveButton
          onClick={handleSave}
          disabled={!currentMealPlan || currentMealPlan === defaultMealPlan}
        >
          {texts.save}
        </SaveButton>
      </TitledActionContainerFooter>
    </TitledActionContainer>
  );
};

export default compose(
  withMealPlanContextReady,
)(DefaultMealPlan);
