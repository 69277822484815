import React, {
  useEffect,
  useMemo,
  useState,
  useContext,
} from 'react';
import { loadStripe } from '@stripe/stripe-js';
import * as Sentry from '@sentry/browser';
import PropTypes from 'prop-types';
import format from 'string-template';

import { firestorePaths, pathPlaceholder } from '../../utils/paths';
import config from '../../config';
import Coach from '../../Model/Coach';
import useNavigation from '../../hooks/useNavigation';
import useComponentMounted from '../../hooks/useComponentMounted';
import ExternalCoachContext from '../ExternalCoachContext';

import StripeContext from './StripeContext';
import texts from './texts.json';

const StripeContextProvider = ({
  children,
}) => {
  const isComponentMountedRef = useComponentMounted();
  const { routes, navigateTo } = useNavigation();
  const [isReady, setIsReady] = useState(false);
  const [stripePromise, setStripePromise] = useState(false);
  const [stripeAccountId, setStripeAccountId] = useState();
  const [applePay, setApplePay] = useState({
    enabled: false,
  });
  const { externalCoachDoc } = useContext(ExternalCoachContext);

  useEffect(() => {
    const init = async () => {
      let connectedStripeAccount;

      const applePayConfig = {
        enabled: true,
        productName: texts.planName,
      };

      if (externalCoachDoc) {
        const {
          stripeAccountId: externalCoachStripeAccountId,
          applePay: {
            enabled: applePayEnabled,
            productName: applePayProductName,
          } = {},
          id: externalCoachId,
        } = externalCoachDoc;

        applePayConfig.enabled = !!applePayEnabled;
        applePayConfig.productName = applePayProductName;

        if (applePayConfig.enabled && !applePayConfig.productName) {
          const coachDoc = new Coach(format(firestorePaths.COACH_DOC, {
            [pathPlaceholder.COACH_ID]: externalCoachId,
          }));

          await coachDoc.init();

          if (coachDoc.exists) {
            const { name: coachName } = coachDoc;
            if (coachName) {
              applePayConfig.productName = format(texts.externalPlan, {
                coachName,
              });
            } else {
              Sentry.captureException(new Error('External coach has no name set up'), {
                extra: {
                  user: externalCoachId,
                },
              });
              applePayConfig.productName = texts.externalPlanDefault;
            }
          } else {
            Sentry.captureException(new Error('Coach doc for external coach does not exists'), {
              extra: {
                user: externalCoachId,
              },
            });
            applePayConfig.productName = texts.externalPlanDefault;
          }
        }

        if (!externalCoachStripeAccountId) {
          Sentry.captureException(new Error('Unable to get stripe account id of external coach', {
            extra: {
              externalCoachId,
            },
          }));
          navigateTo(routes.ERROR);
          return;
        }
        connectedStripeAccount = externalCoachStripeAccountId;
      }

      const loadStripePromise = loadStripe(config.stripe.publicKey, {
        stripeAccount: connectedStripeAccount,
      });

      if (isComponentMountedRef.current) {
        setApplePay(applePayConfig);
        setStripePromise(loadStripePromise);
        setIsReady(true);
        setStripeAccountId(connectedStripeAccount);
      }
    };

    init();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const context = useMemo(() => ({
    isReady,
    stripePromise,
    stripeAccountId,
    applePay,
  }), [
    isReady,
    stripePromise,
    stripeAccountId,
    applePay,
  ]);

  return (
    <StripeContext.Provider value={context}>
      {children}
    </StripeContext.Provider>
  );
};

StripeContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default StripeContextProvider;
