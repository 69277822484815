import BaseDocument from '../../Model/BaseDocument';
import Collections from '../../utils/collections';

class ExchangeRates extends BaseDocument {
  /**
   * @param {string} yearMonth - YYYY-MM
  */
  constructor(yearMonth, opts) {
    super(`${Collections.EXCHANGE_RATES}/${yearMonth}`, opts);
  }

  /**
   * @param {string} currency - USD, EUR, etc.
   * @returns {number} exchange rate
  */
  getExchangeRate = (currency) => this.data[currency];

  /**
   * @param {number} amount - amount in currency
   * @param {string} currency - USD, EUR, etc.
   * @returns {number} amount in USD
  */
  getUsdAmount = (amount, currency) => {
    if (!currency || currency === 'USD') return amount;
    const rate = this.getExchangeRate(currency.toUpperCase());
    return amount / rate;
  }

  /**
   * @param {string} yearMonth - YYYY-MM
   * @returns {Promise<ExchangeRates>}
  */
  static getExchangeRatesByMonth = async (yearMonth) => {
    const exchangeRates = new ExchangeRates(yearMonth);
    await exchangeRates.fetch();
    return exchangeRates;
  }
}

export default ExchangeRates;
