import React, {
  useState,
  useCallback,
  useMemo,
  useContext,
  useRef,
} from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import format from 'string-template';

import useComponentMounted from '../../../../../hooks/useComponentMounted';
import {
  HeaderRow,
  TitleContainer,
  Title,
} from '../../../../../components/v2/Header';
import {
  SectionContainer,
  SectionHeaderContainer,
  SectionTitle,
  SectionFooterContainer,
} from '../../../../../components/v2/Section';
import {
  SaveButton,
  CancelButton,
} from '../../../../../components/Button/ActionButtons';
import ConfirmDialog from '../../../../components/ConfirmDialog';
import DialogRoundedModal from '../../../../../components/DialogRoundedModal';
import Workout, { VisibilityType } from '../../../../../Model/Workout';
import WorkoutAssignment from '../../../../../Model/WorkoutAssignment';
import UserContext from '../../../../../context/UserContext';
import useLogger from '../../../../../hooks/useLogger';
import { CoachingActivity } from '../../../../../utils/log';
import useToast from '../../../../hooks/useToast';
import ExercisesTable from '../../Exercises/ExercisesTable';
import LoadingOverlay from '../../../../components/LoadingOverlay';
import { ActionType } from '../utils';
import Activity from '../../../../../Model/Activity';
import {
  initialValues as startValues,
  validationSchema,
} from './validation';
import fieldName from './formFields';
import {
  COMPONENT_REARRANGE_SCREEN_WIDTH,
  assignmentsUpdateOptions,
  AssignmentUpdateType,
  estimateDuration,
} from './utils';

import {
  FLASH_CLASSNAME,
  FLASH_SECONDS,
  Container,
  StyledForm,
  StyledSectionCompartment,
  ContentContainer,
  ExercisesTableContainer,
  StyledFormikInput,
  StyledRadioButtonGroup,
} from './styles';
import texts from './texts';
import Activities from './Activities';

const WorkoutEditor = ({
  workout,
  workoutAssignment,
  action,
  onSave,
  onClose,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showExercisesTable, setShowExercisesTable] = useState(false);
  const [selectedExercise, setSelectedExercise] = useState(null);
  const [preparedActivities, setPreparedActivities] = useState(
    workout?.activities?.map((activityItem) => new Activity(activityItem))
    || workoutAssignment?.workoutContent?.activities?.map((activityItem) => new Activity(activityItem))
    || [],
  );
  const [showActivityModal, setShowActivityModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [formikData, setFormikData] = useState({});
  const [assignmentsToUpdate, setAssignmentsToUpdate] = useState([]);
  const [showAssignmentUpdateModal, setShowAssignmentUpdateModal] = useState(false);
  const [selectedAssignmentUpdateOption, setSelectedAssignmentUpdateOption] = useState(
    AssignmentUpdateType.SELECTED_ASSIGNMENT,
  );
  const tableRef = useRef(null);

  const { showToast } = useToast();
  const isComponentMountedRef = useComponentMounted();
  const { userDoc: { id: coachId } } = useContext(UserContext);
  const { logCoachingActivity } = useLogger();

  const docData = workout?.data || workoutAssignment?.workoutContent || {};

  const onClickAddActivity = useCallback(() => {
    const isSmallScreen = window.innerWidth <= COMPONENT_REARRANGE_SCREEN_WIDTH;

    if (showExercisesTable && tableRef.current) {
      // Scroll to the table if the screen size is below column wise width
      if (isSmallScreen) {
        tableRef.current.scrollIntoView({ behavior: 'smooth' });
      } else {
        // If the table is beside the designer, add the flash animation
        tableRef.current.classList.add(FLASH_CLASSNAME);
        // Remove the flash after the animation duration
        setTimeout(() => {
          tableRef.current.classList.remove(FLASH_CLASSNAME);
        }, FLASH_SECONDS * 1000);
      }
    } else {
      setShowExercisesTable(true);
      // Scroll to the table after a small delay to account for delay in opening table
      setTimeout(() => {
        if (isSmallScreen && tableRef.current) {
          tableRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      }, 1000);
    }
  }, [
    showExercisesTable,
    tableRef,
  ]);

  const handleSubmit = useCallback(async (values, actions) => {
    setIsLoading(true);
    if (action === ActionType.UPDATE) {
      await workout.updateFields({
        [fieldName.NAME]: values[fieldName.NAME],
        [fieldName.NOTE]: values[fieldName.NOTE] || '',
        activities: preparedActivities.map((activity) => activity.jsonActivity),
        estimatedDuration: estimateDuration(preparedActivities),
      });
      showToast(format(texts.workoutUpdated, {
        workoutName: values[fieldName.NAME],
      }));
      logCoachingActivity(CoachingActivity.UPDATED_WORKOUT, { workoutId: workout.id });
    } else if (action === ActionType.CREATE) {
      const workoutId = await Workout.addWorkout(
        {
          ...values,
          coach: coachId,
          createdAt: Date.now(),
          activities: preparedActivities.map((activity) => activity.jsonActivity),
          visibility: VisibilityType.PRIVATE,
          estimatedDuration: estimateDuration(preparedActivities),
          isArchived: false,
        },
      );
      showToast(format(texts.workoutCreated, { workoutName: values[fieldName.NAME] }));
      logCoachingActivity(CoachingActivity.CREATED_WORKOUT, { workoutId });
    } else if (action === ActionType.UPDATE_WORKOUT_ASSIGNMENT) {
      const updatedWorkoutContent = {
        [fieldName.NAME]: values[fieldName.NAME],
        [fieldName.NOTE]: values[fieldName.NOTE] || '',
        activities: preparedActivities.map((activity) => activity.jsonActivity),
        estimatedDuration: estimateDuration(preparedActivities),
      };

      if (selectedAssignmentUpdateOption === AssignmentUpdateType.SELECTED_ASSIGNMENT) {
        await workoutAssignment.updateFields({
          workoutContent: updatedWorkoutContent,
        });
        showToast(format(texts.workoutAssignmentUpdated, { workoutName: values.name }));
      } else {
        await Promise.all(assignmentsToUpdate.map(async (assignment) => {
          await assignment.updateFields({
            workoutContent: updatedWorkoutContent,
          });
        }));
        showToast(format(texts.workoutAssignmentsUpdated, {
          workoutName: values.name,
          count: assignmentsToUpdate.length,
        }));
      }
      logCoachingActivity(CoachingActivity.UPDATED_ASSIGNED_WORKOUT, {
        workoutId: workoutAssignment.workout,
        clientId: workoutAssignment.user,
      });
    }

    if (isComponentMountedRef.current) {
      setIsLoading(false);
      actions.setSubmitting(false);
      setFormikData({});
      setShowConfirmationModal(false);
      setShowAssignmentUpdateModal(false);
      setSelectedAssignmentUpdateOption(AssignmentUpdateType.SELECTED_ASSIGNMENT);
      setAssignmentsToUpdate([]);
      onClose();
      onSave();
    }
  }, [
    action,
    showToast,
    isComponentMountedRef,
    workout,
    coachId,
    onSave,
    preparedActivities,
    workoutAssignment,
    logCoachingActivity,
    onClose,
    assignmentsToUpdate,
    selectedAssignmentUpdateOption,
  ]);

  const onSubmitClick = useCallback(async (values, actions) => {
    setFormikData({ values, actions });

    if (workout) {
      setShowConfirmationModal(true);
    } else if (workoutAssignment) {
      setIsLoading(true);
      const userAssignments = await WorkoutAssignment.getUserUnStartedWorkoutAssignmentsByWorkoutId(
        workoutAssignment.user,
        workoutAssignment.workout,
      );
      if (isComponentMountedRef.current) {
        setAssignmentsToUpdate(userAssignments.docs);
        setIsLoading(false);
        setShowAssignmentUpdateModal(true);
      }
    } else {
      handleSubmit(values, actions);
    }
  }, [
    workout,
    workoutAssignment,
    isComponentMountedRef,
    handleSubmit,
  ]);

  const sectionTitle = useMemo(() => {
    if (action === ActionType.UPDATE) {
      return texts.editWorkout;
    }
    if (action === ActionType.CREATE) {
      return texts.addWorkout;
    }
    return texts.editWorkoutAssignment;
  }, [action]);

  const buttonTitle = useMemo(() => {
    if (action === ActionType.UPDATE) {
      return texts.submitButton.saveWorkout;
    }
    if (action === ActionType.CREATE) {
      return texts.saveNewButton;
    }
    return texts.submitButton.saveWorkoutAssignment;
  }, [action]);

  const onExerciseAddClick = (exercise) => {
    setSelectedExercise(exercise);
    setShowActivityModal(true);
  };

  return (
    <Container>
      <HeaderRow>
        <TitleContainer>
          <Title>
            {texts.title}
          </Title>
        </TitleContainer>
      </HeaderRow>
      <ContentContainer>
        <Formik
          initialValues={docData || startValues}
          validationSchema={validationSchema}
          onSubmit={onSubmitClick}
          enableReinitialize
        >
          {({ isSubmitting }) => (
            <StyledForm className="workout-editor-form">
              <SectionContainer>
                <SectionHeaderContainer>
                  <SectionTitle>{sectionTitle}</SectionTitle>
                </SectionHeaderContainer>
                <StyledSectionCompartment>
                  <StyledFormikInput
                    name={fieldName.NAME}
                    label={`${texts.field[fieldName.NAME].label}:`}
                  />
                  <StyledFormikInput
                    name={fieldName.NOTE}
                    label={`${texts.field[fieldName.NOTE].label}:`}
                    multiline
                    rows={3}
                  />
                </StyledSectionCompartment>
                <StyledSectionCompartment>
                  <Activities
                    activities={preparedActivities}
                    onActivitiesChange={setPreparedActivities}
                    onClickAddActivity={onClickAddActivity}
                    selectedExercise={selectedExercise}
                    showActivityModal={showActivityModal}
                    setShowActivityModal={setShowActivityModal}
                    setSelectedExercise={setSelectedExercise}
                  />
                </StyledSectionCompartment>
                <SectionFooterContainer>
                  <SaveButton
                    disabled={isSubmitting}
                    type="submit"
                  >
                    {buttonTitle}
                  </SaveButton>
                  <CancelButton onClick={onClose}>
                    {texts.cancelButton}
                  </CancelButton>
                </SectionFooterContainer>
              </SectionContainer>
            </StyledForm>
          )}
        </Formik>
        {showExercisesTable && (
          <ExercisesTableContainer ref={tableRef}>
            <ExercisesTable
              isWorkoutDesignerView
              handleActionClick={onExerciseAddClick}
            />
          </ExercisesTableContainer>
        )}
      </ContentContainer>
      <LoadingOverlay
        isLoading={isLoading}
        loadingText={texts.uploading}
      />
      {showConfirmationModal && (
        <ConfirmDialog
          isOpen={showConfirmationModal}
          onConfirm={() => handleSubmit(formikData.values, formikData.actions)}
          onCancel={() => setShowConfirmationModal(false)}
          dialogTexts={{
            title: texts.actionConfirmMessage,
          }}
        />
      )}
      {showAssignmentUpdateModal && (
        <DialogRoundedModal
          title={texts.assignmentUpdateModalTitle}
          actionButtons={(
            <>
              <SaveButton onClick={() => handleSubmit(formikData.values, formikData.actions)}>
                {texts.submitButton.updateWorkoutAssignments}
              </SaveButton>
              <CancelButton
                onClick={() => setShowAssignmentUpdateModal(false)}
              >
                {texts.cancelButton}
              </CancelButton>
            </>
          )}
          open={showAssignmentUpdateModal}
          onClose={() => setShowAssignmentUpdateModal(false)}
        >
          <StyledRadioButtonGroup
            options={assignmentsUpdateOptions(
              workoutAssignment.workoutContent.name,
              assignmentsToUpdate.length,
            )}
            onOptionChange={(type) => setSelectedAssignmentUpdateOption(type)}
            selectedOption={selectedAssignmentUpdateOption}
          />
        </DialogRoundedModal>
      )}
    </Container>
  );
};

WorkoutEditor.propTypes = {
  workout: PropTypes.instanceOf(Workout),
  workoutAssignment: PropTypes.instanceOf(WorkoutAssignment),
  action: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

WorkoutEditor.defaultProps = {
  workout: null,
  workoutAssignment: null,
  onSave: () => { },
  onClose: () => { },
};

export default WorkoutEditor;
