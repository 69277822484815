import styled from 'styled-components';

import { FormControlLabel } from '@mui/material';

import { StyledFormRow } from '../../styles';

import colors from '../../../../../../styles/colors';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
`;

const TagContainer = styled(StyledFormRow)`
  padding: 5px 20px;
  border-radius: 5px;
  display: flex;
  border: 1px solid ${colors.shades.secondary7};
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  width: 20%;
  min-width: fit-content;
  .MuiCheckbox-root {
    padding: 2px 6px 2px 2px;
    &:not(.Mui-checked) {
      color: ${colors.shades.secondary7};
    }
  }
  .MuiFormControlLabel-label {
    font-size: 9px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0;
    text-align: left;
    color: ${colors.shades.secondary8};
  }
`;

export {
  Container,
  TagContainer,
  StyledFormControlLabel,
};
