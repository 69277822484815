import React, {
  useState,
  useEffect,
  useMemo,
  useContext,
} from 'react';
import { autorun } from 'mobx';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import isEqual from 'lodash.isequal';
import moment from 'moment';

import useComponentMounted from '../../../hooks/useComponentMounted';
import Lead, { LeadState } from '../../Model/Lead';
import ExternalCoachContext from '../ExternalCoachContext';
import { ALL, DaysOptions } from '../../components/DateFilter/utils';
import { DateFormat } from '../../../utils/date';
import LeadsContext from './LeadsContext';

const LeadsContextProvider = ({
  children,
}) => {
  const [leadsCollection, setLeadsCollection] = useState(null);
  const [leadsForMultipleCoaches, setLeadsForMultipleCoaches] = useState(null);
  const [loadingLeads, setLoadingLeads] = useState(false);
  const [leads, setLeads] = useState([]);
  const [filteredLeads, setFilteredLeads] = useState([]);
  const [newLeadsCount, setNewLeadsCount] = useState(0);
  const [coachIdsList, setCoachIdsList] = useState(null);
  const [selectedPeriod, setSelectedPeriod] = useState(DaysOptions[0]);
  const [loadedPeriod, setLoadedPeriod] = useState();

  const isComponentMountedRef = useComponentMounted();

  const {
    externalCoachDoc,
    isReady: externalCoachReady,
    coachIds,
  } = useContext(ExternalCoachContext);

  // Load the leads collection.
  useEffect(() => {
    const load = async () => {
      setLoadingLeads(true);
      const loadLeadsFrom = selectedPeriod === ALL ? null
        : moment().subtract(selectedPeriod, 'days').format(DateFormat.DEFAULT_DATE_FORMAT);

      if (externalCoachDoc) {
        const newLeads = await externalCoachDoc.getLeads(loadLeadsFrom);
        if (isComponentMountedRef.current) {
          setLeadsCollection(newLeads);
          // We need to reset the leads for the current role if we loaded leads for a single coach.
          setLeadsForMultipleCoaches(null);
        }
      } else if (coachIds?.length) {
        const newLeadsCollections = await Lead.getAllLeadsByCoachList(coachIds, loadLeadsFrom);

        if (isComponentMountedRef.current) {
          // Reset the leads collection, which needs to be re-populated with the contents of the leads for the role.
          setLeadsCollection(null);
          setLeadsForMultipleCoaches(newLeadsCollections);
        }
      } else {
        setLeadsCollection({});
      }

      if (isComponentMountedRef.current) {
        setCoachIdsList(coachIds);
        setLoadedPeriod(selectedPeriod);
      }
    };

    const newCoachesLoaded = !isEqual(coachIds, coachIdsList);
    const selectedPeriodChanged = selectedPeriod !== loadedPeriod;

    if (externalCoachReady && (newCoachesLoaded || selectedPeriodChanged) && !loadingLeads) {
      load();
    }
  }, [
    externalCoachReady,
    externalCoachDoc,
    leadsCollection,
    leadsForMultipleCoaches,
    loadingLeads,
    coachIds,
    coachIdsList,
    isComponentMountedRef,
    selectedPeriod,
    loadedPeriod,
  ]);

  useEffect(() => (
    autorun(() => {
      // We need to make the newLeads variable an observable so that we can update the tables dynamically.
      if (leadsForMultipleCoaches) {
        const newLeads = leadsForMultipleCoaches.reduce((combinedLeadsCollection, currentCollection) => {
          const { docs } = currentCollection;

          // Build a pseudo collection with all the leads from all the collections fetched.
          return {
            docs: [
              ...combinedLeadsCollection.docs,
              ...docs,
            ],
          };
        }, { docs: [] });

        // We need to update the state from within the autorun, in case the leads change after they were loaded.
        if (isComponentMountedRef.current) {
          setLeadsCollection(newLeads);
        }
      }
    })
  ), [
    leadsForMultipleCoaches,
    isComponentMountedRef,
  ]);

  // This useEffect will be executed after we have loaded a leadsCollection succesfully.
  useEffect(() => {
    if (leadsCollection) {
      /*
        We need an autorun here, just in case a new lead is added to the DB while the dashboard is already loaded.
        It will also execute if any of the leads change, for example, if a row is marked as removed.
        We will also keep count of the amount of leads in the NEW state, to display it in the sidebar.
      */
      const disposer = autorun(() => {
        let newLeadsCounter = 0;
        const { docs = [] } = leadsCollection;

        const filteredDocs = docs.filter(({ removed, state }) => {
          if (state === LeadState.NEW && !removed) {
            newLeadsCounter += 1;
          }
          return !removed;
        });

        setNewLeadsCount(newLeadsCounter);
        setLeads(filteredDocs);
        setLoadingLeads(false);
      });

      return disposer;
    }
    return null;
  }, [leadsCollection]);

  const value = useMemo(() => ({
    leadsCollection,
    leads,
    loadingLeads,
    filteredLeads,
    setFilteredLeads,
    newLeadsCount,
    setSelectedPeriod,
    selectedPeriod,
  }), [
    leadsCollection,
    leads,
    loadingLeads,
    filteredLeads,
    setFilteredLeads,
    newLeadsCount,
    setSelectedPeriod,
    selectedPeriod,
  ]);

  return (
    <LeadsContext.Provider value={value}>
      {children}
    </LeadsContext.Provider>
  );
};

LeadsContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default compose(
  observer,
)(LeadsContextProvider);
