import styled from 'styled-components';

import { ReactComponent as ArrowIcon } from '../../../assets/icons/arrow.svg';

const Action = styled.div`
  z-index: 2;
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  &::before {
    content: none;
  }
`;

const Next = styled(Action)`
  right: 0;
`;

const Previous = styled(Action)`
  left: 0;
`;

const RightArrow = ArrowIcon;

const LeftArrow = styled(ArrowIcon)`
  transform: rotate(180deg);
`;

export {
  Next,
  Previous,
  RightArrow,
  LeftArrow,
};
