import styled from 'styled-components';

import colors from '../../../../../styles/colors';
import OnCallActions from '../../../../../components/OnCallActions';

const Container = styled.div`
  display: flex;
  height: auto;
  margin: 20px;
`;

const StyledOnCallActions = styled(OnCallActions)`
  padding-bottom: 20px;
`;

const StyledContractDetails = styled.div`
  border-left: 1px solid ${colors.shades.gamma1};
  margin-left: 30px;
  padding-left: 30px;
  width: 600px;
  min-width: 600px;
`;

export {
  Container,
  StyledOnCallActions,
  StyledContractDetails,
};
