import React from 'react';

import {
  Container,
  StyledVisibilityIcon,
} from './styles';
import texts from './texts.json';

const ViewAsDescription = () => (
  <Container>
    <span>{texts.clickOn}</span>
    <StyledVisibilityIcon />
    <span>{texts.viewAs}</span>
  </Container>
);

export default ViewAsDescription;
