import styled from 'styled-components';

import colors from '../../styles/colors';

const GenericErrorMessage = styled.div`
  max-width: fit-content;
  margin-left: auto;
  margin-right: auto;
  color: ${colors.base.danger};
`;

export default GenericErrorMessage;
