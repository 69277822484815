import { RecipeTag } from '../../../../../utils/meals';

const texts = {
  title: 'Suitable For',
  [RecipeTag.BREAKFAST]: 'Breakfast',
  [RecipeTag.LUNCH]: 'Lunch',
  [RecipeTag.SNACK]: 'Snack',
  [RecipeTag.DINNER]: 'Dinner',
};

export default texts;
