import feedType from './feedTypes';
import {
  StyledCheckInIcon,
  StyledClientStatusIcon,
  StyledDailySummaryIcon,
  StyledNeedNewWorkoutIcon,
  StyledNeedMealPlanIcon,
  StyledSpecialRemindersIcon,
  StyledArchivedIcon,
  StyledVideoFeedbackIcon,
} from './styles';
import texts from './texts';

const feedTypeConfig = {
  [feedType.NEED_NEW_WORKOUTS]: {
    title: texts[feedType.NEED_NEW_WORKOUTS],
    icon: StyledNeedNewWorkoutIcon,
  },
  [feedType.NEED_NEW_MEAL_PLAN]: {
    title: texts[feedType.NEED_NEW_MEAL_PLAN],
    icon: StyledNeedMealPlanIcon,
  },
  [feedType.DAILY_SUMMARY]: {
    title: texts[feedType.DAILY_SUMMARY],
    icon: StyledDailySummaryIcon,
  },
  [feedType.ARCHIVED]: {
    title: texts[feedType.ARCHIVED],
    icon: StyledArchivedIcon,
  },
  [feedType.CHECK_IN]: {
    title: texts[feedType.CHECK_IN],
    icon: StyledCheckInIcon,
  },
  [feedType.CLIENT_STATUS]: {
    title: texts[feedType.CLIENT_STATUS],
    icon: StyledClientStatusIcon,
  },
  [feedType.SPECIAL]: {
    title: texts[feedType.SPECIAL],
    icon: StyledSpecialRemindersIcon,
  },
  [feedType.WORKOUT_FEEDBACK_REQUEST]: {
    title: texts.WORKOUT_FEEDBACK_REQUEST,
    icon: StyledVideoFeedbackIcon,
  },
};

export default feedTypeConfig;
