import styled, { css } from 'styled-components';
import { Typography } from '@mui/material';

import colors from '../../../styles/colors';
import transitionsConfig from './transitionsConfig';

const transitionsProperty = css`
  transition: all ${transitionsConfig.delay}ms ease-in-out;
`;

const Overlay = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${colors.addAlpha(colors.base.beta, 0.3)};
  border-radius: 0;
  /* This z-index value is necessary for rendering this component on top of the side menu and the header. */
  z-index: 9900;

  &.${transitionsConfig.classNames.overlay}-enter,
  &.${transitionsConfig.classNames.overlay}-appear {
    opacity: 0;
  }

  &.${transitionsConfig.classNames.overlay}-appear-active,
  &.${transitionsConfig.classNames.overlay}-enter-active {
    opacity: 1;
    ${transitionsProperty}
  }

  &.${transitionsConfig.classNames.overlay}-exit {
    opacity: 1;
  }

  &.${transitionsConfig.classNames.overlay}-exit-active {
    opacity: 0;
    ${transitionsProperty}
  }
`;

const StyledTypography = styled(Typography)`
  color: ${colors.base.beta};
  background-color: ${colors.shades.gamma8};
  padding: 6px 8px;
  opacity: 0.8;
  font-size: 11px;
  text-align: center;
  font-weight: bold;
  border-radius: 3px;
`;

export {
  Overlay,
  StyledTypography,
};
