import React, {
  useEffect,
  useMemo,
  useState,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import useComponentMounted from '../../hooks/useComponentMounted';
import { getWebCustomizationByCoach } from '../../Model/WebCustomization';

import OnboardingFlowConfigContext from '../OnboardingFlowConfigContext';
import WebCustomizationContext from './WebCustomizationContext';

const WebCustomizationProvider = ({
  children,
}) => {
  const [isReady, setIsReady] = useState(false);
  const [webCustomizationDoc, setWebCustomizationDoc] = useState(null);
  const isComponentMountedRef = useComponentMounted();

  const { externalCoach } = useContext(OnboardingFlowConfigContext);

  useEffect(() => {
    const init = async () => {
      const webCustomization = await getWebCustomizationByCoach(externalCoach);

      if (isComponentMountedRef.current) {
        setWebCustomizationDoc(webCustomization);
        setIsReady(true);
      }
    };

    init();
  }, [
    isComponentMountedRef,
    externalCoach,
  ]);

  const contextValue = useMemo(() => ({
    isReady,
    webCustomizationDoc,
  }), [
    isReady,
    webCustomizationDoc,
  ]);

  return (
    <WebCustomizationContext.Provider value={contextValue}>
      {children}
    </WebCustomizationContext.Provider>
  );
};

WebCustomizationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default observer(WebCustomizationProvider);
