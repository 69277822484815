import React from 'react';
import { MessageSimple, useMessageContext } from 'stream-chat-react';
import VoiceMessage from '../VoiceMessage';

const CustomMessage = () => {
  const { message } = useMessageContext();
  const isVoiceMessage = message.isVoiceMessage
    || (message.attachments && message.attachments.some((attachment) => attachment.type === 'audio'));

  return isVoiceMessage ? (
    <VoiceMessage message={message} />
  ) : (
    <MessageSimple message={message} />
  );
};

export default CustomMessage;
