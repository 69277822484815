import styled, { keyframes } from 'styled-components';
import { Form } from 'formik';
import { Chip } from '@mui/material';

import colors from '../../../../../styles/colors';
import {
  SectionCompartment,
} from '../../../../../components/v2/Section';
import FormikInput from '../../../../../components/v2/FormikInput';
import RadioButtonGroup from '../../../../components/RadioButtonGroup';

import { COMPONENT_REARRANGE_SCREEN_WIDTH } from './utils';

const FLASH_SECONDS = 2;
const FLASH_CLASSNAME = 'flash';

// Keyframes for the flash animation
const flashShadow = keyframes`
  0% {
    box-shadow: inset 0 0 10px 2px rgba(0, 0, 0, 0.3);
  }
  100% {
    box-shadow: inset 0 0 0 0 rgba(0, 0, 0, 0);
  }
`;

const Container = styled.div`
  width: 100%;
  height: 100%;

  @media (max-width: 1240px) {
    height: fit-content;
  }
`;

const StyledForm = styled(Form)`
  display: flex;
  width: 50%;
  min-width: 700px;
  flex: 0 0 auto;
  flex-direction: column;
  border: 1px solid ${colors.shades.secondary7};
  border-radius: 5px;

  @media (max-width: 1240px) {
    width: 100%;
  }
`;

const StyledSectionCompartment = styled(SectionCompartment)`
  width: unset;
  row-gap: 5px;
  padding: 10px;
  flex-grow: 1;
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: stretch;
  height: 100%;
  position: relative;
  max-height: calc(100% - 76px);
  overflow-y: auto;
  align-items: flex-start;
  padding-bottom: 20px;
  gap: 20px;
  margin-top: 0;

  @media (max-width: 1240px) {
    flex-direction: column;
    height: fit-content;
    align-items: center;
  }
`;

const InfoChip = styled(Chip)`
  background-color: ${({ $isRest }) => ($isRest ? colors.shades.secondary9 : colors.shades.primary3)};
  height: 25px;
  margin-right: 15px;
  width: fit-content;
`;

const ExercisesTableContainer = styled.div`
  flex: 1;
  width: 100%;
  min-width: 220px;
  max-height: 100%;
  position: sticky;
  top: 0;
  right: 0;
  overflow-y: auto;

  .MuiDataGrid-root {
    min-width: 500px;
  }

  @media (max-width: ${COMPONENT_REARRANGE_SCREEN_WIDTH}px) {
    position: static;
    width: 100%;
    max-height: 500px;
  }

  &.${FLASH_CLASSNAME} {
    animation: ${flashShadow} ${FLASH_SECONDS}s ease-in-out;
  }
`;

const StyledFormikInput = styled(FormikInput)`
  .MuiInputBase-root {
    height: fit-content;
  }
`;

const StyledRadioButtonGroup = styled(RadioButtonGroup)`
  margin: 10px 45px;
  display: flex;
  width: auto;
  .MuiFormControlLabel-root {
    margin-top: 10px;
  }
`;

export {
  FLASH_SECONDS,
  FLASH_CLASSNAME,
  Container,
  StyledForm,
  StyledSectionCompartment,
  ContentContainer,
  InfoChip,
  ExercisesTableContainer,
  StyledFormikInput,
  StyledRadioButtonGroup,
};
