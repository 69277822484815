import React from 'react';
import { Badge } from '@mui/material';
import PropTypes from 'prop-types';

import { StyledAvatar } from './styles';

const MessagingChannelProfilePic = ({
  image,
  isOnline,
}) => (
  <Badge
    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    overlap="circular"
    color={isOnline ? 'success' : 'error'}
    variant="dot"
  >
    <StyledAvatar image={image} />
  </Badge>
);

MessagingChannelProfilePic.propTypes = {
  image: PropTypes.string,
  isOnline: PropTypes.bool,
};

MessagingChannelProfilePic.defaultProps = {
  image: '',
  isOnline: false,
};

export default MessagingChannelProfilePic;
