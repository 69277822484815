import React, { useContext } from 'react';
import { compose } from 'recompose';
import { observer } from 'mobx-react';

import FirebaseContext from '../../context/FirebaseContext';
import { withUserContextReady } from '../../context/UserContext';

import { withPageAnalytics } from '../../components/withAnalytics';
import withRedirectToApp from '../../components/withRedirectToApp';
import DownloadApp from '../../components/DownloadApp';
import { variants } from '../../components/Button';
import { withUserOnboarded } from '../../components/withUserOnboarded';

import {
  Container,
  SignoutButton,
  Body,
} from './styles';

import texts from './texts.json';

const Home = () => {
  const { firebase } = useContext(FirebaseContext);

  const signOut = async () => {
    await firebase.signOut();
  };

  return (
    <Container>
      <Body>
        <DownloadApp />
      </Body>
      <SignoutButton
        variant={variants.DARK}
        onClick={signOut}
      >
        {texts.signOut}
      </SignoutButton>
    </Container>
  );
};

export default compose(
  withPageAnalytics('homePage'),
  withRedirectToApp,
  withUserContextReady,
  withUserOnboarded,
  observer,
)(Home);
