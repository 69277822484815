export const StripeSubscriptionState = {
  TRIALING: 'trialing',
  ACTIVE: 'active',
  INCOMPLETE: 'incomplete',
  INCOMPLETE_EXPIRED: 'incomplete_expired',
  PAST_DUE: 'past_due',
  CANCELED: 'canceled',
  UNPAID: 'unpaid',
};

export const StripePaymentIntentState = {
  SUCCEEDED: 'succeeded',
  REQUIRES_ACTION: 'requires_action',
  REQUIRES_PAYMENT_METHOD: 'requires_payment_method',
};

export const StripeErrorType = {
  NO_ERROR: 'no_error',
  API_CONNECTION_ERROR: 'api_connection_error',
  API_ERROR: 'api_error',
  AUTHENTICATION_ERROR: 'authentication_error',
  CARD_ERROR: 'card_error',
  IDEMPOTENCY_ERROR: 'idempotency_error',
  INVALID_REQUEST_ERROR: 'invalid_request_error',
  RATE_LIMIT_ERROR: 'rate_limit_error',
  VALIDATION_ERROR: 'validation_error',
  PAYMENT_METHOD_CREATION_FAILED: 'payment_method_creation_failed',
  UNKNOWN_ERROR: 'unknown_error',
};

export const NonErrorResponseState = {
  SUCCESSFUL: 'successful',
  REQUIRES_PAYMENT_METHOD: 'requires_payment_method',
  REQUIRES_ACTION: 'requires_action',
  UNKNOWN: 'unknown',
};

export const SubscriptionCreationState = {
  INITIAL: 'initial',
  WAITING_FOR_VALID_PAYMENT_METHOD: 'waiting_for_valid_payment_method',
  WAITING_FOR_INITIAL_PAYMENT_AUTHORIZATION: 'waiting_for_initial_payment_authorization',
  SUCCESSFUL_COMPLETION: 'successful_completion',
  FAILURE_NETWORK: 'failure_network',
  FAILURE_COMPLETION: 'failure_completion',
  ACTIVE_SUBSCRIPTION_EXISTS: 'active_subscription_exists',
};

export const SubscriptionControllerState = {
  INITIAL_LOADING: {
    value: 'initial_loading',
    isBusy: true,
  },
  VALID_SUBSCRIPTION_EXISTS: {
    value: 'valid_subscription_exists',
    isBusy: false,
  },
  READY: {
    value: 'ready',
    isBusy: false,
  },
  ACTION_IN_PROGRESS: {
    value: 'action_in_progress',
    isBusy: true,
  },
  PROCESSING_RESPONSE: {
    value: 'processing_response',
    isBusy: true,
  },
  ACTION_COMPLETED: {
    value: 'action_completed',
    isBusy: false,
  },
  HOUSE_KEEPING_ACTION_IN_PROGRESS: {
    value: 'house_keeping_action_in_progress',
    isBusy: true,
  },
  HOUSE_KEEPING_ACTION_COMPLETED: {
    value: 'house_keeping_action_completed',
    isBusy: false,
  },
  RESPONSE_PROCESSED: {
    value: 'response_processed',
    isBusy: true,
  },
};

export const SubscriptionNextStateOnResponse = {
  [SubscriptionCreationState.INITIAL]: {
    [NonErrorResponseState.SUCCESSFUL]: {
      next: SubscriptionCreationState.SUCCESSFUL_COMPLETION,
      cancelCurrentSubscription: false,
    },
    [NonErrorResponseState.REQUIRES_ACTION]: {
      next: SubscriptionCreationState.WAITING_FOR_INITIAL_PAYMENT_AUTHORIZATION,
      cancelCurrentSubscription: false,
    },
    [NonErrorResponseState.REQUIRES_PAYMENT_METHOD]: {
      next: SubscriptionCreationState.WAITING_FOR_VALID_PAYMENT_METHOD,
      cancelCurrentSubscription: false,
    },
    [NonErrorResponseState.UNKNOWN]: {
      next: SubscriptionCreationState.FAILURE_COMPLETION,
      cancelCurrentSubscription: true,
    },
  },
  [SubscriptionCreationState.WAITING_FOR_VALID_PAYMENT_METHOD]: {
    [NonErrorResponseState.SUCCESSFUL]: {
      next: SubscriptionCreationState.SUCCESSFUL_COMPLETION,
      cancelCurrentSubscription: false,
    },
    [NonErrorResponseState.REQUIRES_ACTION]: {
      next: SubscriptionCreationState.WAITING_FOR_INITIAL_PAYMENT_AUTHORIZATION,
      cancelCurrentSubscription: false,
    },
    [NonErrorResponseState.REQUIRES_PAYMENT_METHOD]: {
      next: SubscriptionCreationState.WAITING_FOR_VALID_PAYMENT_METHOD,
      cancelCurrentSubscription: false,
    },
    [NonErrorResponseState.UNKNOWN]: {
      next: SubscriptionCreationState.FAILURE_COMPLETION,
      cancelCurrentSubscription: true,
    },
  },
  [SubscriptionCreationState.WAITING_FOR_INITIAL_PAYMENT_AUTHORIZATION]: {
    [NonErrorResponseState.SUCCESSFUL]: {
      next: SubscriptionCreationState.SUCCESSFUL_COMPLETION,
      cancelCurrentSubscription: false,
    },
    [NonErrorResponseState.REQUIRES_ACTION]: {
      next: SubscriptionCreationState.INITIAL,
      cancelCurrentSubscription: true,
    },
    [NonErrorResponseState.REQUIRES_PAYMENT_METHOD]: {
      next: SubscriptionCreationState.WAITING_FOR_VALID_PAYMENT_METHOD,
      cancelCurrentSubscription: false,
    },
    [NonErrorResponseState.UNKNOWN]: {
      next: SubscriptionCreationState.FAILURE_COMPLETION,
      cancelCurrentSubscription: true,
    },
  },
};

const SubscriptionCustomErrorCode = {
  ACTIVE_SUBSCRIPTION_EXISTS: {
    code: 'active_subscription_exists',
    message: 'Active subscription already exists.',
  },
  SUBSCRIPTION_PERSISTENCE_FAILED: {
    code: 'subscription_persistence_failed',
    message: 'Subscription creation failed',
  },
  SUBSCRIPTION_DB_QUERY_FAILED: {
    code: 'subscription_db_query_failed',
    message: 'Failed querying the DB for subscription',
  },
  PRODUCT_DETAIL_DB_QUERY_FAILED: {
    code: 'product_detail_db_query_failed',
    message: 'Failed querying the DB for product details',
  },
  PRODUCT_DETAIL_NOT_FOUND: {
    code: 'product_detail_not_found',
    message: 'Product details were not found',
  },
  SUBSCRIPTION_NOT_AVAILABLE: {
    code: 'subscription_not_available',
    message: 'Stripe couldn\'t find the subscription',
  },
  VALIDATION_FAILED: {
    code: 'validation_failed',
    message: 'Subscription request validation failed',
  },
  INVOICE_NOT_AVAILABLE: {
    code: 'invoice_not_available',
    message: 'Invoice could not be found.',
  },
  INVOICE_PAYMENT_FAILED: {
    code: 'invoice_payment_failed',
    message: 'Invoice payment failed abruptly',
  },
  CUSTOMER_CREATION_FAILED: {
    code: 'customer_creation_failed',
    message: 'Customer creation failed abruptly.',
  },
  UNKNOWN_FAILURE: {
    code: 'unknown_failure_occurred',
    message: 'Faced and unknown failure',
  },
  DEFAULT: {
    code: 'default',
    message: 'Faced and unknown failure',
  },
};

export const SubscriptionNextStateOnError = {
  [SubscriptionCreationState.INITIAL]: {
    [StripeErrorType.API_CONNECTION_ERROR]: {
      next: SubscriptionCreationState.FAILURE_NETWORK,
      cancelCurrentSubscription: false,
    },
    [StripeErrorType.API_ERROR]: {
      code: {
        [SubscriptionCustomErrorCode.ACTIVE_SUBSCRIPTION_EXISTS.code]: {
          next: SubscriptionCreationState.ACTIVE_SUBSCRIPTION_EXISTS,
          cancelCurrentSubscription: false,
        },
      },
      next: SubscriptionCreationState.FAILURE_COMPLETION,
      cancelCurrentSubscription: false,
    },
    [StripeErrorType.AUTHENTICATION_ERROR]: {
      next: SubscriptionCreationState.FAILURE_COMPLETION,
      cancelCurrentSubscription: false,
    },
    [StripeErrorType.CARD_ERROR]: {
      next: SubscriptionCreationState.INITIAL,
      cancelCurrentSubscription: false,
    },
    [StripeErrorType.IDEMPOTENCY_ERROR]: {
      next: SubscriptionCreationState.INITIAL,
      cancelCurrentSubscription: false,
    },
    [StripeErrorType.INVALID_REQUEST_ERROR]: {
      next: SubscriptionCreationState.INITIAL,
      cancelCurrentSubscription: false,
    },
    [StripeErrorType.VALIDATION_ERROR]: {
      next: SubscriptionCreationState.INITIAL,
      cancelCurrentSubscription: false,
    },
    [StripeErrorType.RATE_LIMIT_ERROR]: {
      next: SubscriptionCreationState.FAILURE_COMPLETION,
      cancelCurrentSubscription: false,
    },
    [StripeErrorType.UNKNOWN_ERROR]: {
      next: SubscriptionCreationState.FAILURE_COMPLETION,
      cancelCurrentSubscription: false,
    },
  },
  [SubscriptionCreationState.WAITING_FOR_VALID_PAYMENT_METHOD]: {
    [StripeErrorType.API_CONNECTION_ERROR]: {
      next: SubscriptionCreationState.FAILURE_NETWORK,
      cancelCurrentSubscription: true,
    },
    [StripeErrorType.API_ERROR]: {
      code: {
        [SubscriptionCustomErrorCode.ACTIVE_SUBSCRIPTION_EXISTS.code]: {
          next: SubscriptionCreationState.SUCCESSFUL_COMPLETION,
          message: SubscriptionCreationState.ACTIVE_SUBSCRIPTION_EXISTS.message,
          cancelCurrentSubscription: false,
        },
      },
      next: SubscriptionCreationState.FAILURE_COMPLETION,
      cancelCurrentSubscription: true,
    },
    [StripeErrorType.AUTHENTICATION_ERROR]: {
      next: SubscriptionCreationState.FAILURE_COMPLETION,
      cancelCurrentSubscription: true,
    },
    [StripeErrorType.CARD_ERROR]: {
      next: SubscriptionCreationState.WAITING_FOR_VALID_PAYMENT_METHOD,
      cancelCurrentSubscription: false,
    },
    [StripeErrorType.IDEMPOTENCY_ERROR]: {
      next: SubscriptionCreationState.INITIAL,
      cancelCurrentSubscription: true,
    },
    [StripeErrorType.INVALID_REQUEST_ERROR]: {
      next: SubscriptionCreationState.WAITING_FOR_VALID_PAYMENT_METHOD,
      cancelCurrentSubscription: false,
    },
    [StripeErrorType.VALIDATION_ERROR]: {
      next: SubscriptionCreationState.WAITING_FOR_VALID_PAYMENT_METHOD,
      cancelCurrentSubscription: false,
    },
    [StripeErrorType.RATE_LIMIT_ERROR]: {
      next: SubscriptionCreationState.FAILURE_COMPLETION,
      cancelCurrentSubscription: true,
    },
    [StripeErrorType.UNKNOWN_ERROR]: {
      next: SubscriptionCreationState.FAILURE_COMPLETION,
      cancelCurrentSubscription: true,
    },
  },
  [SubscriptionCreationState.WAITING_FOR_INITIAL_PAYMENT_AUTHORIZATION]: {
    [StripeErrorType.API_CONNECTION_ERROR]: {
      next: SubscriptionCreationState.FAILURE_NETWORK,
      cancelCurrentSubscription: true,
    },
    [StripeErrorType.API_ERROR]: {
      code: {
        [SubscriptionCustomErrorCode.ACTIVE_SUBSCRIPTION_EXISTS.code]: {
          next: SubscriptionCreationState.SUCCESSFUL_COMPLETION,
          message: SubscriptionCreationState.ACTIVE_SUBSCRIPTION_EXISTS.message,
          cancelCurrentSubscription: false,
        },
      },
      next: SubscriptionCreationState.FAILURE_COMPLETION,
      cancelCurrentSubscription: true,
    },
    [StripeErrorType.AUTHENTICATION_ERROR]: {
      next: SubscriptionCreationState.FAILURE_COMPLETION,
      cancelCurrentSubscription: true,
    },
    [StripeErrorType.CARD_ERROR]: {
      next: SubscriptionCreationState.INITIAL,
      cancelCurrentSubscription: true,
    },
    [StripeErrorType.IDEMPOTENCY_ERROR]: {
      next: SubscriptionCreationState.INITIAL,
      cancelCurrentSubscription: true,
    },
    [StripeErrorType.INVALID_REQUEST_ERROR]: {
      next: SubscriptionCreationState.INITIAL,
      cancelCurrentSubscription: true,
    },
    [StripeErrorType.VALIDATION_ERROR]: {
      next: SubscriptionCreationState.INITIAL,
      cancelCurrentSubscription: true,
    },
    [StripeErrorType.RATE_LIMIT_ERROR]: {
      next: SubscriptionCreationState.FAILURE_COMPLETION,
      cancelCurrentSubscription: true,
    },
    [StripeErrorType.UNKNOWN_ERROR]: {
      next: SubscriptionCreationState.FAILURE_COMPLETION,
      cancelCurrentSubscription: true,
    },
  },
};
