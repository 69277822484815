import React, {
  useState,
  useEffect,
  useMemo,
} from 'react';
import { autorun } from 'mobx';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';

import UserContract from '../../../Model/UserContract';
import useComponentMounted from '../../../hooks/useComponentMounted';
import UserContractsContext, { initialValues } from './UserContractsContext';

const UserContractsContextProvider = ({
  children,
}) => {
  const [userContractsCollection, setUserContractsCollection] = useState(null);
  const [loadingContracts, setLoadingContracts] = useState(false);
  const [userContracts, setUserContracts] = useState([]);
  const [isReady, setIsReady] = useState(initialValues.isReady);

  const isComponentMountedRef = useComponentMounted();

  const { params: { userId: insideSalesId } } = useRouteMatch();

  // Load the contracts collection.
  useEffect(() => {
    const init = async () => {
      setLoadingContracts(true);
      const contracts = await UserContract.getContractsBySellerId(insideSalesId);
      if (isComponentMountedRef.current) {
        setLoadingContracts(false);
        setUserContractsCollection(contracts);
        setIsReady(true);
      }
    };

    if (insideSalesId) {
      init();
    }
  }, [
    isComponentMountedRef,
    insideSalesId,
  ]);

  // This useEffect will be executed after we have loaded a contractsCollection succesfully.
  useEffect(() => {
    if (userContractsCollection) {
      /*
        We need an autorun here, just in case a new contract is added to the DB while the dashboard is already loaded.
        It will also execute if any of the contracts change.
      */
      const disposer = autorun(() => {
        const { docs } = userContractsCollection;
        setUserContracts(docs);
      });

      return disposer;
    }
    return null;
  }, [userContractsCollection]);

  const value = useMemo(() => ({
    userContractsCollection,
    userContracts,
    loadingContracts,
    isReady,
  }), [
    userContractsCollection,
    userContracts,
    loadingContracts,
    isReady,
  ]);

  return (
    <UserContractsContext.Provider value={value}>
      {children}
    </UserContractsContext.Provider>
  );
};

UserContractsContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UserContractsContextProvider;
