const COACH = '/c/';

const isCoachRoute = (routePath) => routePath.startsWith(COACH);
const getClientPagePath = (coachId, clientId) => `${COACH}${coachId}/manageClients/${clientId}`;
const getClientMealPlanPagePath = (coachId) => `${COACH}${coachId}/mealPlanner/assignment`;

export {
  isCoachRoute,
  getClientPagePath,
  getClientMealPlanPagePath,
};
