import React from 'react';
import PropTypes from 'prop-types';

import { StyledEditor } from './styles';

const TextEditor = ({
  value,
  onChange,
}) => (
  <StyledEditor
    value={value}
    onChange={onChange}
    modules={{
      toolbar: [
        [{ header: [1, 2, 3, 4, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ indent: '-1' }, { indent: '+1' }],
        [{ align: [] }],
        ['link', 'image'],
      ],
    }}
  />
);

TextEditor.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default TextEditor;
