import styled, { css } from 'styled-components';

import { fontFamily } from '../../../../../styles/text';
import colors from '../../../../../styles/colors';
import Button, { LinkButton } from '../../../../../components/Button';

import { ReactComponent as CloseButtonIcon } from '../../assets/icon/close-thread-icon.svg';
import { ReactComponent as BackIcon } from '../../assets/icon/back-icon.svg';

const headerTextStyles = css`
  font-family: ${fontFamily.serif} !important;
  color: ${colors.base.alpha};
  font-weight: bold;
  font-size: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
`;

const MultipleChannelNameContainer = styled.div`
  ${headerTextStyles};
  padding: 0 15px;
  flex: 1;
  display: flex;
  gap: 10px;
  flex-direction: row;
  align-items: center;
`;

const SingleChannelNameContainer = styled(MultipleChannelNameContainer)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SingleChannelTitle = styled.div`
  ${headerTextStyles};
  flex-grow: 1;
  align-items: center;
  justify-items: center;
`;

const ChatListBurgerContainer = styled.div`
  position: relative;
  width: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin-left: 25px;
`;

const CloseButtonComponent = styled(CloseButtonIcon)`
  height: 25px;
  margin-right: 20px;
  cursor: pointer;
`;

const StyledLink = styled.a`
  cursor: pointer;
`;

const StyledButton = styled(Button)`
  background-color: ${colors.shades.secondary4};
  border-radius: 50px;
  color: ${colors.base.secondary2};
  font-size: 13px;
  padding: 2px 16px;
  margin: 16px 16px;
  font-weight: 400;
  width: fit-content;
  text-transform: none;
  border: 1px solid ${colors.shades.secondary4};
  &:hover {
    background-color: ${colors.shades.secondary5};
    border: 1px solid ${colors.shades.secondary4};
  }
`;

const StyledBackIcon = styled(BackIcon)`
  cursor: pointer;
`;

const BackButton = styled(LinkButton)`
  width: 32px;
  height: 32px;
  border: none;
  outline: none;
  padding: 0;
  display: flex;
  align-items: center;

  ${StyledBackIcon} {
    width: 25px;
    height: 18px;
  }
`;

export {
  MultipleChannelNameContainer,
  SingleChannelNameContainer,
  ChatListBurgerContainer,
  CloseButtonComponent,
  SingleChannelTitle,
  StyledLink,
  StyledButton,
  BackButton,
  StyledBackIcon,
};
