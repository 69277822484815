import styled, { css } from 'styled-components';

import { LinkButton } from '../Button';
import { body1 } from '../../styles/text';

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;

const helpTextBaseCss = css`
  ${body1}
  font-feature-settings: 'liga' off;
  text-align: center;
  letter-spacing: 0.16em;
`;

const HelpText = styled.div`
  ${helpTextBaseCss}
`;

const ActionButton = styled(LinkButton)`
  ${helpTextBaseCss}
  display: flex;
  width: unset;
  height: unset;
  padding: 0 5px;
  font-weight: bold;
  text-transform: none;
  text-decoration: underline;
  cursor: pointer;
`;

export {
  Container,
  HelpText,
  ActionButton,
};
