import styled from 'styled-components';
import { IonProgressBar } from '@ionic/react';
import colors from '../../../../styles/colors';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const DataContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const WorkoutDetails = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 5px;
`;

const StyledTitle = styled.span`
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  color: ${colors.shades.secondary3};
`;

const CountLabel = styled.span`
  font-size: 50px;
  font-weight: 500;
  line-height: 62px;
  color: ${colors.shades.secondary3};
`;

const Description = styled.span`
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: ${colors.shades.gamma10};
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 20px;
  width: calc(50% - 8px);
  align-items: left;
  border-radius: 5px;
  background-color: ${colors.base.alpha};
`;

const StyledWorkoutProgress = styled(IonProgressBar)`
  width: 100%;
  height: 8px;
  border-radius: 25px;
  margin: 5px 0;
  --background: ${colors.shades.gamma8};
  --progress-background: ${colors.shades.primary1};
`;

export {
  Container,
  ItemContainer,
  StyledTitle,
  CountLabel,
  Description,
  StyledWorkoutProgress,
  DataContainer,
  WorkoutDetails,
};
