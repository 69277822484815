import mime from 'mime-types';

const FileType = {
  IMAGE: 'image',
  VIDEO: 'video',
  AUDIO: 'audio',
  PDF: 'pdf',
  FILE: 'file',
};

// Gets the mime type and then returns the first part of it (audio, video, application, etc...)
const getFileType = (type) => {
  const mimeType = mime.contentType(type);
  if (mimeType) {
    const processedType = mimeType.split('/')[0];
    return Object.values(FileType).includes(processedType) ? processedType : FileType.FILE;
  }

  return FileType.FILE;
  // The mime-types package returns false when the input is not valid
};

export {
  FileType,
  getFileType,
};
