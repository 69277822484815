import React, { useContext } from 'react';

import LoadingPage from '../../../components/LoadingPage';
import ExerciseContext from './ExerciseContext';
import ExerciseContextProvider from './ExerciseContextProvider';

const withExerciseContextProvider = (Component) => (props) => (
  <ExerciseContextProvider>
    <Component {...props} />
  </ExerciseContextProvider>
);

const withExerciseContextReady = (Component) => (props) => {
  const { isReady } = useContext(ExerciseContext);

  return isReady ? <Component {...props} /> : <LoadingPage />;
};

export {
  withExerciseContextProvider,
  withExerciseContextReady,
};
