import styled from 'styled-components';
import {
  Box,
  Button,
  Typography,
} from '@mui/material';

import colors from '../../../../styles/colors';

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 40px 20px;
  background-color: ${colors.shades.gamma5};
`;

const StyledDropdownHeading = styled(Typography)`
  font-weight: bold;
  font-size: 14px;
`;

const StyledButton = styled(Button)`
  min-width: 120px;
  margin: 10px;
  align-self: flex-end;
`;

export {
  Container,
  Header,
  StyledDropdownHeading,
  StyledButton,
};
