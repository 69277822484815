import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@mui/material';

import {
  StyledFormControl,
  StyledFilterIcon,
  StyledInputLabel,
  StyledSelect,
  StyledMenuItem,
  menuStyles,
} from './styles';

import texts from './texts.json';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const statesSelectMenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      textTransform: 'capitalize',
    },
  },
};

const allOption = {
  value: texts.selectAllValue,
  label: texts.selectAllLabel,
};

const MultiSelectFilter = ({
  description,
  filterIcon,
  options,
  onOptionsSelected,
  onValuesSelected,
  initialOptions,
  initialValues,
  className,
  disableValueRender,
  setResetFunction,
  hideAllOption,
}) => {
  const [initialOptionsState] = useState(() => {
    if (initialOptions && initialOptions.length > 0) {
      return initialOptions;
    }
    if (initialValues && initialValues.length > 0) {
      return initialValues.reduce((resultOptions, currentValue) => {
        const currentOption = options.find((option) => option.value === currentValue);
        if (currentOption) {
          return [...resultOptions, currentOption];
        }
        return resultOptions;
      }, []);
    }
    return [];
  });

  const [selectedOptions, setSelectedOptions] = useState(initialOptionsState);

  const handleChange = useCallback(
    (event) => {
      const {
        target: { value },
      } = event;

      // Remove the 'All' option
      const filteredValue = value.filter((item) => item.value !== allOption.value);
      let newSelectedOptions;

      if (filteredValue.length === value.length) {
        // One of the states has triggered the events
        newSelectedOptions = filteredValue;
      } else if (filteredValue.length === options.length) {
        // It means that the All button has triggered the event
        newSelectedOptions = [];
      } else {
        newSelectedOptions = options;
      }

      setSelectedOptions(newSelectedOptions);
    },
    [options],
  );

  const renderValue = (selected) => {
    if (disableValueRender) {
      return '';
    }

    if (selected.length === options.length) {
      return allOption.label;
    }

    return selected.map(({ label }) => label).join(', ');
  };

  useEffect(() => {
    onOptionsSelected(selectedOptions);
    onValuesSelected(selectedOptions.map((item) => item.value));
  }, [
    selectedOptions,
    onOptionsSelected,
    onValuesSelected,
  ]);

  const resetFunction = useCallback(() => {
    setSelectedOptions(initialOptionsState);
  }, [initialOptionsState]);

  useEffect(() => {
    setResetFunction(() => resetFunction);

    return () => {
      setResetFunction(null);
    };
  }, [setResetFunction, resetFunction]);

  return (
    <StyledFormControl className={className}>
      <StyledInputLabel shrink={!disableValueRender && selectedOptions.length > 0}>
        {!!filterIcon && filterIcon}
        {description}
      </StyledInputLabel>
      <StyledSelect
        multiple
        displayEmpty
        value={selectedOptions}
        onChange={handleChange}
        renderValue={renderValue}
        MenuProps={{
          ...statesSelectMenuProps,
          sx: menuStyles,
        }}
      >
        {!hideAllOption && (
          <StyledMenuItem value={allOption}>
            <Checkbox
              checked={options.length === selectedOptions.length}
              indeterminate={selectedOptions.length < options.length && selectedOptions.length > 0}
            />
            {allOption.label}
          </StyledMenuItem>
        )}
        {options.map((option) => (
          <StyledMenuItem key={option.value} value={option}>
            <Checkbox checked={!!selectedOptions.find((item) => item.value === option.value)} />
            {option.label}
          </StyledMenuItem>
        ))}
      </StyledSelect>
    </StyledFormControl>
  );
};

MultiSelectFilter.propTypes = {
  description: PropTypes.string,
  filterIcon: PropTypes.element,
  options: PropTypes.array.isRequired,
  onOptionsSelected: PropTypes.func,
  onValuesSelected: PropTypes.func,
  initialOptions: PropTypes.array,
  initialValues: PropTypes.array,
  className: PropTypes.string,
  disableValueRender: PropTypes.bool,
  setResetFunction: PropTypes.func,
  hideAllOption: PropTypes.bool,
};

MultiSelectFilter.defaultProps = {
  description: '',
  filterIcon: <StyledFilterIcon />,
  initialOptions: [],
  initialValues: [],
  onOptionsSelected: () => {},
  onValuesSelected: () => {},
  className: '',
  disableValueRender: false,
  setResetFunction: () => () => {},
  hideAllOption: false,
};

export default MultiSelectFilter;
