import React, {
  useState,
  useMemo,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';

import { useChatContext, MessageSimple } from 'stream-chat-react';
import MicIcon from '@mui/icons-material/Mic';
import { CircularProgress } from '@mui/material';
import useComponentMounted from '../../../../../hooks/useComponentMounted';
import FirebaseContext from '../../../../../context/FirebaseContext';
import ChatContext from '../../../../context/ChatContext';
import { PrimaryButton } from '../../../../../components/Button/ActionButtons';
import {
  TranscriptionText,
  Container,
  TranscriptionWrapper,
  ButtonContainer,
  LoaderContainer,
} from './styles';
import texts from './texts.json';

const STATUS_CODE_OF_SUCCESSFUL_RESPONSE = 200;

const VoiceMessage = ({ message }) => {
  const { params: { userId: coachId } } = useRouteMatch();
  const { chatClient: streamChatClient } = useContext(ChatContext);
  const [transcription, setTranscription] = useState(message.transcription || '');
  const [showTranscription, setShowTranscription] = useState(false);
  const [isLoadingTranscription, setIsLoadingTranscription] = useState(false);
  const { client } = useChatContext();
  const { firebase: { remote } } = useContext(FirebaseContext);

  const audioUrl = useMemo(
    () => message.attachments
      .filter((attachment) => attachment.type === 'audio')
      .map((audioAttachment) => audioAttachment.asset_url),
    [message.attachments],
  );

  const updateMessageWithTranscription = async (messageToBeUpdated, transcriptionText) => {
    const updatedMessage = {
      ...messageToBeUpdated,
      transcription: transcriptionText,
    };
    await streamChatClient.updateMessage(updatedMessage);
  };

  const isOwnMessage = client?.user.id === message?.user?.id;
  const isComponentMountedRef = useComponentMounted();

  const loadTranscription = async () => {
    setShowTranscription((prev) => !prev);

    if (!transcription) {
      setIsLoadingTranscription(true);
      try {
        const response = await remote('getVoiceTranscription', {
          audioUrl: audioUrl[0],
          coachId,
        });
        const data = await response.json();
        if (isComponentMountedRef.current) {
          if (response.status === STATUS_CODE_OF_SUCCESSFUL_RESPONSE) {
            const transcriptionText = data?.results?.map((result) => result.alternatives[0].transcript).join('\n')
            || texts.noTranscription;
            setTranscription(transcriptionText);
            updateMessageWithTranscription(message, transcriptionText);
          } else {
            setTranscription(texts.transcriptionError);
          }
        }
      } catch (err) {
        if (isComponentMountedRef) {
          setTranscription(texts.transcriptionError);
        }
      } finally {
        if (isComponentMountedRef) {
          setIsLoadingTranscription(false);
        }
      }
    }
  };

  return (
    <Container>
      <MessageSimple {...message} />
      <TranscriptionWrapper $alignRight={isOwnMessage}>
        <TranscriptionText
          $borderRight={isOwnMessage}
          $rightMargin={isOwnMessage}
          $borderLeft={!isOwnMessage}
          $leftMargin={!isOwnMessage}
        >
          {showTranscription && transcription}
          {isLoadingTranscription && (
            <LoaderContainer>
              <CircularProgress size={20} />
            </LoaderContainer>
          )}
          <ButtonContainer $alignRight={isOwnMessage}>
            <PrimaryButton
              icon={<MicIcon />}
              variant="info"
              size="small"
              onClick={loadTranscription}
              disabled={isLoadingTranscription}
            >
              { showTranscription ? texts.collapseView : texts.voiceTranscript }
            </PrimaryButton>
          </ButtonContainer>
        </TranscriptionText>
      </TranscriptionWrapper>
    </Container>
  );
};

VoiceMessage.propTypes = {
  message: PropTypes.object.isRequired,
};

export default VoiceMessage;
