import React, {
  useMemo,
} from 'react';
import format from 'string-template';
import { PropTypes } from 'prop-types';

import { InfoTag } from '../../../../../components/Tags';
import { feedOrderTypes } from '../../../../../utils/feed';

import {
  Container,
} from './styles';
import texts from './texts';

const ActivityStatus = ({
  activity,
}) => {
  const {
    orderLabelType,
    lastInteractionByUserName,
    claimedByUserName,
  } = activity;

  const activityLabel = useMemo(() => {
    if (orderLabelType === feedOrderTypes.ASSIGNED) {
      return format(texts.feedLabel[orderLabelType], { user: lastInteractionByUserName });
    }
    if (orderLabelType === feedOrderTypes.CLAIMED) {
      return format(texts.feedLabel[orderLabelType], { user: claimedByUserName });
    }
    return texts.feedLabel[orderLabelType];
  }, [
    lastInteractionByUserName,
    claimedByUserName,
    orderLabelType,
  ]);

  /*
   * This attribute is only added to feed items when the logged in user is an AC and to the feed buckets that have
   * been sorted. So we can safely use this to check if we need to render the label or not
   */
  if (!orderLabelType) {
    return null;
  }

  return (
    <Container>
      <InfoTag>
        {activityLabel}
      </InfoTag>
    </Container>
  );
};

ActivityStatus.propTypes = {
  activity: PropTypes.object.isRequired,
};

export default ActivityStatus;
