import React, {
  useMemo,
  useCallback,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import format from 'string-template';
import moment from 'moment';

import { DateFormat } from '../../../../../utils/date';
import { capitalizeFirstLetter } from '../../../../../utils/string';
import {
  MeasurementUnit,
  getFtInFromCentimeters,
  getCentimetersFromFtIn,
  getKgFromLbs,
  getLbsFromKg,
} from '../../../../../utils/measurement';
import MealPlanAssignmentContext from '../../../../context/MealPlanAssignmentContext';
import { WeeklyWeightGoal } from '../../../../Model/UserNutritionProfile';

import {
  StyledTableContainer,
  StyledTable,
  StyledTableHead,
  StyledTableRow,
  StyledTableCell,
  StyledTableBody,
  StyledHeaderCell,
  FooterContainer,
  DateContainer,
  EmptyRow,
} from '../styles';
import {
  StyledSelect,
  StyledMenuItem,
  menuStyles,
} from './styles';
import texts from './texts';

const UserPhysicalAttributes = ({
  onSelectWeightGoal,
}) => {
  const {
    isReady,
    userDoc: {
      firstName: userFirstName,
      birthdate: userBirthdate,
      serviceStartAt: userStartDate,
    } = {},
    nutritionProfileDoc = {},
    activityProfileDoc = {},
    weeklyCheckinDocs = [],
    selectedWeightGoal: weightGoal,
  } = useContext(MealPlanAssignmentContext);

  const heights = useMemo(() => {
    const { height, heightMeasurementUnit } = nutritionProfileDoc || {};
    const heightsTexts = {
      primaryText: texts.emptyCell,
      secondaryText: null,
    };

    if (!height) {
      return heightsTexts;
    }

    if (heightMeasurementUnit === MeasurementUnit.METRIC) {
      heightsTexts.primaryText = format(texts.heightValues[heightMeasurementUnit], { height });

      const { ft, inches } = getFtInFromCentimeters(height);
      heightsTexts.secondaryText = format(texts.heightValues[MeasurementUnit.IMPERIAL], { ft, in: inches });
    } else {
      heightsTexts.primaryText = format(
        texts.heightValues[heightMeasurementUnit],
        { ft: height?.ft, in: height?.in },
      );
      const cm = getCentimetersFromFtIn({ ft: height?.ft, inches: height?.in }, true);
      heightsTexts.secondaryText = format(texts.heightValues[MeasurementUnit.METRIC], { height: cm });
    }

    return heightsTexts;
  }, [
    nutritionProfileDoc,
  ]);

  const getWeightValues = useCallback((currentWeight, weightMeasurementUnit) => {
    const weights = {
      primaryText: format(texts.weightValues[weightMeasurementUnit], { weight: currentWeight }),
      secondaryText: weightMeasurementUnit === MeasurementUnit.METRIC
        ? format(texts.weightValues[MeasurementUnit.IMPERIAL], { weight: getLbsFromKg(currentWeight, true) })
        : format(texts.weightValues[MeasurementUnit.METRIC], { weight: getKgFromLbs(currentWeight, true) }),
    };
    return weights;
  }, []);

  const longTermWeightGoals = useMemo(() => {
    const { longTermWeightGoal, longTermWeightGoalMeasurementUnit } = nutritionProfileDoc || {};
    const weightGoalText = {
      primaryText: texts.emptyCell,
      secondaryText: null,
    };

    if (!longTermWeightGoal) {
      return weightGoalText;
    }
    return getWeightValues(longTermWeightGoal, longTermWeightGoalMeasurementUnit);
  }, [
    nutritionProfileDoc,
    getWeightValues,
  ]);

  const renderTableHead = useCallback(() => (
    <StyledTableHead>
      <StyledTableRow $header>
        <StyledHeaderCell $highlight>{texts.headers.physicalAttributes}</StyledHeaderCell>
        <StyledHeaderCell>{texts.headers.gender}</StyledHeaderCell>
        <StyledHeaderCell>{texts.headers.age}</StyledHeaderCell>
        <StyledHeaderCell>{texts.headers.height}</StyledHeaderCell>
        <StyledHeaderCell>{texts.headers.currentWeight}</StyledHeaderCell>
        <StyledHeaderCell>{texts.headers.goalWeight}</StyledHeaderCell>
        <StyledHeaderCell>{texts.headers.clientGoal}</StyledHeaderCell>
        <StyledHeaderCell>{texts.headers.activityLevel}</StyledHeaderCell>
      </StyledTableRow>
    </StyledTableHead>
  ), []);

  const renderTable = useCallback(() => {
    if (!isReady) {
      return (
        <>
          <StyledTable>
            {renderTableHead()}
          </StyledTable>
          <EmptyRow>{texts.loading}</EmptyRow>
        </>
      );
    }

    const weeklyWeightGoal = {
      primaryText: nutritionProfileDoc?.weeklyWeightGoal
        ? texts.weightGoal[nutritionProfileDoc.weeklyWeightGoal]
        : texts.emptyCell,
      seondaryText: nutritionProfileDoc?.lastUpdated
        ? moment(nutritionProfileDoc.lastUpdated).format(DateFormat.DATE_FORMAT_COMMA)
        : null,
    };

    const activityLevel = {
      primaryText: activityProfileDoc?.activityLevel
        ? texts.activityLevel[activityProfileDoc.activityLevel]
        : texts.emptyCell,
      secondaryText: activityProfileDoc?.lastUpdated
        ? moment(activityProfileDoc.lastUpdated).format(DateFormat.DATE_FORMAT_COMMA)
        : null,
    };

    // If there are no checkins for this user, use nutrition profile data
    if (!weeklyCheckinDocs.length && nutritionProfileDoc) {
      const { currentWeight, weightMeasurementUnit } = nutritionProfileDoc;
      const weights = getWeightValues(currentWeight, weightMeasurementUnit);
      const dateText = userStartDate
        ? moment(userStartDate).format(DateFormat.MONTH_NAME_DATE_FORMAT)
        : texts.emptyCell;
      const age = {
        primaryText: moment().diff(moment(userBirthdate), 'years') || texts.emptyCell,
        secondaryText: moment(userBirthdate).format(DateFormat.DATE_FORMAT_COMMA) || null,
      };

      return (
        <StyledTable>
          {renderTableHead()}
          <StyledTableBody>
            <StyledTableRow>
              <StyledTableCell $flex>
                {texts.startedOn}
                <DateContainer>{dateText}</DateContainer>
              </StyledTableCell>
              <StyledTableCell>
                {nutritionProfileDoc?.biologicalSex
                  ? capitalizeFirstLetter(nutritionProfileDoc.biologicalSex)
                  : texts.emptyCell}
              </StyledTableCell>
              <StyledTableCell>
                <span>{age.secondaryText}</span>
                {age.primaryText}
              </StyledTableCell>
              <StyledTableCell>
                <span>{heights.secondaryText}</span>
                {heights.primaryText}
              </StyledTableCell>
              <StyledTableCell>
                <span>{weights.secondaryText}</span>
                {weights.primaryText}
              </StyledTableCell>
              <StyledTableCell>
                <span>{longTermWeightGoals.secondaryText}</span>
                {longTermWeightGoals.primaryText}
              </StyledTableCell>
              <StyledTableCell>
                <span>{weeklyWeightGoal.seondaryText}</span>
                {weeklyWeightGoal.primaryText}
              </StyledTableCell>
              <StyledTableCell>
                <span>{activityLevel.secondaryText}</span>
                {activityLevel.primaryText}
              </StyledTableCell>
            </StyledTableRow>
          </StyledTableBody>
        </StyledTable>
      );
    }

    if (weeklyCheckinDocs.length) {
      // Render table with checkin data
      return (
        <StyledTable>
          {renderTableHead()}
          <StyledTableBody>
            {weeklyCheckinDocs.map((checkinDoc, index) => {
              const {
                checkInData: {
                  current: {
                    weight: {
                      value: weightValue,
                      unit: weightUnit,
                    } = {},
                  } = {},
                } = {},
                submittedAt,
              } = checkinDoc;
              const multipleWeeklyCheckins = weeklyCheckinDocs.length > 1;
              const isSecondary = index === 0 && multipleWeeklyCheckins;

              const checkinDateText = moment(submittedAt).format(DateFormat.MONTH_NAME_DATE_FORMAT);

              const weights = {
                primaryText: texts.emptyCell,
                secondaryText: null,
              };

              if (weightValue && weightUnit) {
                const processedWeights = weightUnit === 'kg'
                  ? getWeightValues(weightValue, MeasurementUnit.METRIC)
                  : getWeightValues(weightValue, MeasurementUnit.IMPERIAL);
                weights.primaryText = processedWeights.primaryText;
                weights.secondaryText = processedWeights.primaryText;
              }

              // Only show the secondary age if primary age is a valid value
              const ageValue = moment(submittedAt).diff(moment(userBirthdate), 'years');
              const age = {
                primaryText: ageValue || texts.emptyCell,
                secondaryText: ageValue > 0
                  ? moment(new Date(userBirthdate)).format(DateFormat.DATE_FORMAT_COMMA) || null
                  : null,
              };

              return (
                <StyledTableRow key={checkinDoc.id}>
                  <StyledTableCell $secondary={isSecondary} $flex>
                    {isSecondary ? texts.lastCheckin : texts.thisCheckin}
                    <DateContainer $secondary={isSecondary}>{checkinDateText}</DateContainer>
                  </StyledTableCell>
                  <StyledTableCell $secondary={isSecondary}>
                    {nutritionProfileDoc?.biologicalSex
                      ? capitalizeFirstLetter(nutritionProfileDoc.biologicalSex)
                      : texts.emptyCell}
                  </StyledTableCell>
                  <StyledTableCell $secondary={isSecondary}>
                    {!multipleWeeklyCheckins && <span>{age.secondaryText}</span>}
                    {age.primaryText}
                  </StyledTableCell>
                  <StyledTableCell $secondary={isSecondary}>
                    {!multipleWeeklyCheckins && <span>{heights.secondaryText}</span>}
                    {heights.primaryText}
                  </StyledTableCell>
                  <StyledTableCell $secondary={isSecondary}>
                    {!multipleWeeklyCheckins && <span>{weights.secondaryText}</span>}
                    {weights.primaryText}
                  </StyledTableCell>
                  <StyledTableCell $secondary={isSecondary}>
                    {!multipleWeeklyCheckins && <span>{longTermWeightGoals.secondaryText}</span>}
                    {longTermWeightGoals.primaryText}
                  </StyledTableCell>
                  <StyledTableCell $secondary={isSecondary}>
                    {!multipleWeeklyCheckins && <span>{weeklyWeightGoal.seondaryText}</span>}
                    {weeklyWeightGoal.primaryText}
                  </StyledTableCell>
                  <StyledTableCell $secondary={isSecondary}>
                    {!multipleWeeklyCheckins && <span>{activityLevel.secondaryText}</span>}
                    {activityLevel.primaryText}
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </StyledTableBody>
        </StyledTable>
      );
    }

    return (
      <>
        <StyledTable>
          {renderTableHead()}
        </StyledTable>
        <EmptyRow>{texts.noData}</EmptyRow>
      </>
    );
  }, [
    heights,
    isReady,
    userBirthdate,
    userStartDate,
    weeklyCheckinDocs,
    nutritionProfileDoc,
    activityProfileDoc,
    renderTableHead,
    longTermWeightGoals,
    getWeightValues,
  ]);

  return (
    <StyledTableContainer>
      {renderTable()}
      <FooterContainer>
        {!!onSelectWeightGoal && (
        <>
          {format(texts.setGoal, { userFirstName })}
          <StyledSelect
            value={weightGoal}
            onChange={(e) => onSelectWeightGoal(e.target.value)}
            MenuProps={{
              sx: menuStyles,
            }}
          >
            {Object.values(WeeklyWeightGoal).map((weeklyWeightGoal) => {
              const optionText = texts.weightGoal[weeklyWeightGoal];
              return (
                <StyledMenuItem
                  key={`goal-select-option-${weeklyWeightGoal}`}
                  value={weeklyWeightGoal}
                >
                  {optionText}
                </StyledMenuItem>
              );
            })}
          </StyledSelect>
        </>
        )}
      </FooterContainer>
    </StyledTableContainer>
  );
};

UserPhysicalAttributes.propTypes = {
  onSelectWeightGoal: PropTypes.func,
};

UserPhysicalAttributes.defaultProps = {
  onSelectWeightGoal: null,
};

export default UserPhysicalAttributes;
