import React, {
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import CoachSelect from '../../components/Leads/components/CoachSelect';

import {
  StyledHeader,
  Title,
  NoCoachText,
  StyledTabs,
} from './styles';
import texts from './texts.json';

const CoachConfigurations = ({
  defaultTab,
  tabsConfig,
  role,
}) => {
  const [selectedTab, setSelectedTab] = useState(defaultTab);
  const [selectedCoach, setSelectedCoach] = useState({});

  const tabContent = useMemo(() => {
    const { component: TabContent, props } = tabsConfig[selectedTab];

    if (!selectedCoach.id) {
      return (
        <NoCoachText>{texts.noCoachSelected}</NoCoachText>
      );
    }

    return (
      <TabContent
        coach={selectedCoach}
        {...props}
      />
    );
  }, [
    tabsConfig,
    selectedTab,
    selectedCoach,
  ]);

  return (
    <>
      <StyledHeader>
        <Title>{texts.title}</Title>
        <CoachSelect role={role} onChange={(coach) => setSelectedCoach(coach || {})} />
      </StyledHeader>
      <StyledTabs
        tabsConfig={tabsConfig}
        selectedTab={selectedTab}
        onSelectTab={setSelectedTab}
      />
      {tabContent}
    </>
  );
};

CoachConfigurations.propTypes = {
  defaultTab: PropTypes.string.isRequired,
  tabsConfig: PropTypes.object.isRequired,
  role: PropTypes.string,
};

CoachConfigurations.defaultProps = {
  role: '',
};

export default CoachConfigurations;
