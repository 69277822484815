import React from 'react';

import Sidebar from '../components/Sidebar';
import useSideBarConfig from './useSidebarConfig';

const CoachSidebar = () => {
  const { sideBarEntries } = useSideBarConfig();
  return (
    <Sidebar entries={sideBarEntries} />
  );
};

export default CoachSidebar;
