import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import ChatContext from '../../../../context/ChatContext';
import {
  CloseButtonComponent,
  StyledContentContainer,
  StyledLabel,
  StyledButton,
} from './styles';

const ChatNotificationView = ({ notificationMessage }) => {
  const { setChatModalOpen, onChatRefresh } = useContext(ChatContext);
  return (
    <>
      <CloseButtonComponent onClick={() => setChatModalOpen(false)} />
      <StyledContentContainer>
        <StyledLabel>{notificationMessage}</StyledLabel>
        <StyledButton onClick={onChatRefresh}>Refresh</StyledButton>
      </StyledContentContainer>
    </>
  );
};

ChatNotificationView.propTypes = {
  notificationMessage: PropTypes.string.isRequired,
};

export default ChatNotificationView;
