import styled from 'styled-components';

import {
  LinkButton,
} from '../../../../../components/Button';

import { fontFamily } from '../../../../../styles/text';
import colors from '../../../../../styles/colors';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid ${colors.shades.gamma4};
  border-radius: 5px;
`;

const SignUpHeader = styled.div`
  padding: 20px;
  border-bottom: 1px solid ${colors.shades.gamma4};
  font-family: ${fontFamily.sansSerif};
  color: ${colors.base.secondary2};
`;

const SignUpContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const ErrorContainer = styled.div`
  margin-top: 20px;
`;

const StyledLinkButton = styled(LinkButton)`
  margin-top: 10px;
  text-decoration: underline;
  opacity: 0.4;
  display: block;
  font-size: 16px;
  cursor: pointer;
  margin: auto;
`;

export {
  Container,
  SignUpHeader,
  SignUpContent,
  ErrorContainer,
  StyledLinkButton,
};
