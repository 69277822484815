const QuestionType = {
  TEXT: 'text',
  OPTIONS: 'multi-select',
  EMAIL: 'email',
  PHONE: 'phone',
  NAME: 'name',
};

const HiddenFieldType = {
  TEXT: 'hidden',
};

export default QuestionType;
export { HiddenFieldType };
