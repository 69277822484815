import React from 'react';

import ContractModalContextProvider from './ContractModalContextProvider';

const withContractModalContextProvider = (Component) => (props) => (
  <ContractModalContextProvider>
    <Component {...props} />
  </ContractModalContextProvider>
);

export {
  withContractModalContextProvider,
};
