import { Collection } from 'firestorter';
import { firestorePaths } from '../utils/paths';
import BaseDocument from './BaseDocument';

class WebCustomization extends BaseDocument {
  get texts() {
    return this.data.texts || {};
  }
}

const getWebCustomizationByCoach = async (coach) => {
  const collection = new Collection(firestorePaths.WEB_CUSTOMIZATION, {
    query: (ref) => ref.where('coach', '==', coach)
      .limit(1),
  });
  await collection.fetch();
  if (collection.docs.length === 1) {
    const webCustomization = new WebCustomization(collection.docs[0].ref);
    await webCustomization.init();
    return webCustomization;
  }
  return null;
};

export default WebCustomization;
export {
  getWebCustomizationByCoach,
};
