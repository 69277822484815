import React, {
  useMemo,
  useState,
  useCallback,
  useEffect,
} from 'react';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import { useRouteMatch } from 'react-router-dom';

import { mealPlannerPagesConfig, MealPlannerType } from '../../routes/mealPlanner/mealPlannerPages';
import useMealPlanNavigation from '../../hooks/useMealPlanNavigation';
import {
  Container,
  Header,
  StyledTabs,
} from './styles';
import {
  getMealPlannerTabsConfig,
} from './utils';

const MealPlannerDashboard = () => {
  const [selectedTab, setSelectedTab] = useState(MealPlannerType.CLIENT_MEAL_ASSIGNMENTS);

  const mealPlannerTabsConfig = useMemo(() => getMealPlannerTabsConfig(), []);

  const {
    navigateToTab,
  } = useMealPlanNavigation();

  const match = useRouteMatch();

  useEffect(() => {
    // Get the last part of the pathname to detect if it is a valid meal planner page.
    const currentMealPlannerPage = match.path.split('/').reverse()[0];

    // Get the config object from the path we extracted from the url
    const [tabConfig] = Object.values(mealPlannerPagesConfig).filter(({ path }) => path === currentMealPlannerPage);

    if (tabConfig) {
      setSelectedTab(tabConfig.id);
    }
  }, [match]);

  const onSelectedTab = useCallback((tabSelected) => {
    navigateToTab(tabSelected, { clearSearch: true });
  }, [
    navigateToTab,
  ]);

  const tabContent = useMemo(() => {
    const { component: TabContent } = mealPlannerTabsConfig[selectedTab];
    return (
      <TabContent />
    );
  }, [
    selectedTab,
    mealPlannerTabsConfig,
  ]);

  return (
    <Container>
      <Header>
        <StyledTabs
          tabsConfig={mealPlannerTabsConfig}
          selectedTab={selectedTab}
          onSelectTab={onSelectedTab}
        />
      </Header>
      {tabContent}
    </Container>
  );
};

export default compose(
  observer,
)(MealPlannerDashboard);
