import { RefreshInterval } from '../Model/MealPlanAssignment';

/*
  A meal plan Assignment can have one of the following states:

  ACTIVE: Active meal plan assignment.
  PENDING: New meal plan assignment pending (user's first assignment).
  NEEDS_REFRESH: The assignment is ready to be refreshed, based on the refresh interval setting.
  MANUALLY_REFRESHABLE: The assignment can be refreshed manually. Used when refresh interval is set to NEVER.
  USER_ON_BREAK: The assignment is refreshable/assignable, But the user is on break and doesn't require any action.
*/
const MealPlanAssignmentStatus = {
  ACTIVE: 'ACTIVE',
  PENDING: 'PENDING',
  NEEDS_REFRESH: 'NEEDS_REFRESH',
  MANUALLY_REFRESHABLE: 'MANUALLY_REFRESHABLE',
  USER_ON_BREAK: 'USER_ON_BREAK',
};

const DEFAULT_MEAL_REFRESH_DAYS = 30;

const DaysForRefreshInterval = {
  [RefreshInterval.WEEKLY]: 7,
  [RefreshInterval.BIWEEKLY]: 14,
  [RefreshInterval.MONTHLY]: 30,
  [RefreshInterval.QUARTERLY]: 90,
  [RefreshInterval.NEVER]: 0,
};

// Filter archived meal plans and order alphabetically
const getValidMealPlanOptions = (mealPlanViews) => (
  mealPlanViews
    .filter((mealPlanViewDoc) => !mealPlanViewDoc.isArchived)
    .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
);

// Gets data from haystack if it is present (recursively)
// Schema should be an object with seeked attributes set with null value
const getBySchema = (schema, haystack = {}) => {
  const result = {};
  Object.keys(schema).forEach((k) => {
    if (!haystack[k]) return;
    result[k] = schema[k] ? getBySchema(schema[k], haystack[k]) : haystack[k];
  });
  return result;
};

// Adds a recipe to a meal times array, returns the new array (non-destructive operation)
const addRecipeToMealTimes = (mealTimes, bucketId, recipe) => (
  mealTimes.map((mealTime) => {
    const result = { ...mealTime };
    if (result.name === bucketId) {
      if (!result.meals) {
        result.meals = [];
      }
      const mealExists = result.meals.find((meal) => meal.recipe.id === recipe.id);
      if (!mealExists) {
        result.meals.push({ recipe: { ...recipe.data, id: recipe.id }, servings: recipe.servings });
      }
    }
    return result;
  })
);

// Adds multiple recipes to a bucket in a meal times array, returns the new array (non-destructive operation)
const bulkAddRecipesToMealTimes = (mealTimes, bucketId, recipes) => (
  mealTimes.map((mealTime) => {
    const result = { ...mealTime };
    if (result.name === bucketId) {
      if (!result.meals) {
        result.meals = [];
      }
      // Add all recipes to bucket
      recipes.forEach((recipe) => {
        const mealExists = result.meals.find((meal) => meal.recipe.id === recipe.id);
        if (!mealExists) {
          result.meals.push({ recipe: { ...recipe.data, id: recipe.id }, servings: recipe.servings });
        }
      });
    }
    return result;
  })
);

// Removes a recipe from a meal times array, returns the new array (non-destructive operation)
const removeRecipeFromMealTimes = (mealTimes, recipeId, bucketIndex) => (
  mealTimes.map((mealTime, index) => {
    const result = { ...mealTime };
    if (index === bucketIndex) {
      const meals = result.meals.filter((meal) => meal.recipe.id !== recipeId);
      result.meals = meals;
    }
    return result;
  })
);

// Moves a recipe inside a meal times bucket, returns the new meal times array (non-destructive)
const moveRecipeInBucket = (mealTimes, removedIndex, addedIndex, bucketIndex) => (
  mealTimes.map((mealTime, index) => {
    if (index !== bucketIndex) {
      return mealTime;
    }

    const updatedMeals = [...mealTime.meals];
    const [movedMeal] = updatedMeals.splice(removedIndex, 1);
    updatedMeals.splice(addedIndex, 0, movedMeal);

    return {
      ...mealTime,
      meals: updatedMeals,
    };
  })
);

// Moves a recipe inside a meal plan (between buckets), returns the new meal times array (non-destructive)
const moveRecipeInMealPlan = (
  mealTimes,
  removedIndex,
  addedIndex,
  sourceBucketIndex,
  destinationBucketIndex,
  meal,
) => {
  const newMealTimes = mealTimes.map((mealTime) => ({
    ...mealTime,
    meals: [...mealTime.meals],
  }));

  // Remove the meal from the source bucket
  if (removedIndex !== null) {
    newMealTimes[sourceBucketIndex].meals.splice(removedIndex, 1);
  }

  // Add the meal to the destination bucket
  if (addedIndex !== null) {
    newMealTimes[destinationBucketIndex].meals.splice(addedIndex, 0, meal);
  }

  return newMealTimes;
};

export {
  MealPlanAssignmentStatus,
  DaysForRefreshInterval,
  DEFAULT_MEAL_REFRESH_DAYS,
  getValidMealPlanOptions,
  getBySchema,
  addRecipeToMealTimes,
  bulkAddRecipesToMealTimes,
  removeRecipeFromMealTimes,
  moveRecipeInBucket,
  moveRecipeInMealPlan,
};
