import { ENutritionItem } from './NutritionItemData';

export const tagsByNutritionix = [
  {
    attr_id: 301,
    usda_tag: 'CA',
    name: 'Calcium, Ca',
    unit: 'mg',
    tableId: ENutritionItem.Calcium,
  },
  {
    attr_id: 205,
    usda_tag: 'CHOCDF',
    name: 'Carbohydrate, by difference',
    unit: 'g',
    tableId: ENutritionItem.TotalCarb,
  },
  {
    attr_id: 601,
    usda_tag: 'CHOLE',
    name: 'Cholesterol',
    unit: 'mg',
    tableId: ENutritionItem.Cholesterol,
  },
  {
    attr_id: 208,
    usda_tag: 'ENERC_KCAL',
    name: 'Energy',
    unit: 'kcal',
  },
  {
    attr_id: 606,
    usda_tag: 'FASAT',
    name: 'Fatty acids, total saturated',
    unit: 'g',
    tableId: ENutritionItem.SaturatedFat,
  },
  {
    attr_id: 204,
    usda_tag: 'FAT',
    name: 'Total lipid (fat)',
    unit: 'g',
    tableId: ENutritionItem.Fat,
  },
  {
    attr_id: 605,
    usda_tag: 'FATRN',
    name: 'Fatty acids, total trans',
    unit: 'g',
    tableId: ENutritionItem.TransFat,
  },
  {
    attr_id: 303,
    usda_tag: 'FE',
    name: 'Iron, Fe',
    unit: 'mg',
  },
  {
    attr_id: 291,
    usda_tag: 'FIBTG',
    name: 'Fiber, total dietary',
    unit: 'g',
    tableId: ENutritionItem.DietaryFiber,
  },
  {
    attr_id: 306,
    usda_tag: 'K',
    name: 'Potassium, K',
    unit: 'mg',
  },
  {
    attr_id: 307,
    usda_tag: 'NA',
    name: 'Sodium, Na',
    unit: 'mg',
    tableId: ENutritionItem.Sodium,
  },
  {
    attr_id: 203,
    usda_tag: 'PROCNT',
    name: 'Protein',
    unit: 'g',
    tableId: ENutritionItem.Protein,
  },
  {
    attr_id: 269,
    usda_tag: 'SUGAR',
    name: 'Sugars, total',
    unit: 'g',
    tableId: ENutritionItem.Sugar,
  },
  {
    attr_id: 539,
    usda_tag: 'SUGAR_ADD',
    name: 'Sugars, added',
    unit: 'g',
    tableId: ENutritionItem.AddedSugar,
  },
  {
    attr_id: 324,
    usda_tag: 'VITD',
    name: 'Vitamin D',
    unit: 'IU',
    tableId: ENutritionItem.VitaminD,
  },
  {
    attr_id: 299,
    usda_tag: 'SUGAR_ALC',
    name: 'Sugar Alcohol',
    unit: 'g',
  },
  {
    attr_id: 1001,
    usda_tag: '#N/A',
    name: 'Erythritol',
    unit: 'g',
  },
  {
    attr_id: 1006,
    usda_tag: '#N/A',
    name: 'Allulose',
    unit: 'g',
  },
  {
    attr_id: 1002,
    usda_tag: '#N/A',
    name: 'Glycerin',
    unit: 'g',
  },
  {
    attr_id: 290,
    usda_tag: 'XYL',
    name: 'Xylitol',
    unit: 'g',
  },
  {
    attr_id: 261,
    usda_tag: 'SORB',
    name: 'Sorbitol',
    unit: 'g',
  },
  {
    attr_id: 260,
    usda_tag: 'MANOL',
    name: 'Mannitol',
    unit: 'g',
  },
  {
    attr_id: 1003,
    usda_tag: '#N/A',
    name: 'Maltitol',
    unit: 'g',
  },
  {
    attr_id: 1004,
    usda_tag: '#N/A',
    name: 'Isomalt',
    unit: 'g',
  },
  {
    attr_id: 1005,
    usda_tag: '#N/A',
    name: 'Lactitol',
    unit: 'g',
  },
  {
    attr_id: 513,
    usda_tag: 'ALA_G',
    name: 'Alanine',
    unit: 'g',
  },
  {
    attr_id: 221,
    usda_tag: 'ALC',
    name: 'Alcohol, ethyl',
    unit: 'g',
  },
  {
    attr_id: 511,
    usda_tag: 'ARG_G',
    name: 'Arginine',
    unit: 'g',
  },
  {
    attr_id: 207,
    usda_tag: 'ASH',
    name: 'Ash',
    unit: 'g',
  },
  {
    attr_id: 514,
    usda_tag: 'ASP_G',
    name: 'Aspartic acid',
    unit: 'g',
  },
  {
    attr_id: 454,
    usda_tag: 'BETN',
    name: 'Betaine',
    unit: 'mg',
  },
  {
    attr_id: 262,
    usda_tag: 'CAFFN',
    name: 'Caffeine',
    unit: 'mg',
  },
  {
    attr_id: 639,
    usda_tag: 'CAMD5',
    name: 'Campesterol',
    unit: 'mg',
  },
  {
    attr_id: 322,
    usda_tag: 'CARTA',
    name: 'Carotene, alpha',
    unit: 'Âµg',
  },
  {
    attr_id: 321,
    usda_tag: 'CARTB',
    name: 'Carotene, beta',
    unit: 'Âµg',
  },
  {
    attr_id: 326,
    usda_tag: 'CHOCAL',
    name: 'Vitamin D3 (cholecalciferol)',
    unit: 'Âµg',
  },
  {
    attr_id: 421,
    usda_tag: 'CHOLN',
    name: 'Choline, total',
    unit: 'mg',
    tableId: ENutritionItem.Choline,
  },
  {
    attr_id: 334,
    usda_tag: 'CRYPX',
    name: 'Cryptoxanthin, beta',
    unit: 'Âµg',
  },
  {
    attr_id: 312,
    usda_tag: 'CU',
    name: 'Copper, Cu',
    unit: 'mg',
    tableId: ENutritionItem.Copper,
  },
  {
    attr_id: 507,
    usda_tag: 'CYS_G',
    name: 'Cystine',
    unit: 'g',
  },
  {
    attr_id: 268,
    usda_tag: 'ENERC_KJ',
    name: 'Energy',
    unit: 'kJ',
  },
  {
    attr_id: 325,
    usda_tag: 'ERGCAL',
    name: 'Vitamin D2 (ergocalciferol)',
    unit: 'Âµg',
  },
  {
    attr_id: 610,
    usda_tag: 'F10D0',
    name: '10:00',
    unit: 'g',
  },
  {
    attr_id: 611,
    usda_tag: 'F12D0',
    name: '12:00',
    unit: 'g',
  },
  {
    attr_id: 696,
    usda_tag: 'F13D0',
    name: '13:00',
    unit: 'g',
  },
  {
    attr_id: 612,
    usda_tag: 'F14D0',
    name: '14:00',
    unit: 'g',
  },
  {
    attr_id: 625,
    usda_tag: 'F14D1',
    name: '14:01',
    unit: 'g',
  },
  {
    attr_id: 652,
    usda_tag: 'F15D0',
    name: '15:00',
    unit: 'g',
  },
  {
    attr_id: 697,
    usda_tag: 'F15D1',
    name: '15:01',
    unit: 'g',
  },
  {
    attr_id: 613,
    usda_tag: 'F16D0',
    name: '16:00',
    unit: 'g',
  },
  {
    attr_id: 626,
    usda_tag: 'F16D1',
    name: '16:1 undifferentiated',
    unit: 'g',
  },
  {
    attr_id: 673,
    usda_tag: 'F16D1C',
    name: '16:1 c',
    unit: 'g',
  },
  {
    attr_id: 662,
    usda_tag: 'F16D1T',
    name: '16:1 t',
    unit: 'g',
  },
  {
    attr_id: 653,
    usda_tag: 'F17D0',
    name: '17:00',
    unit: 'g',
  },
  {
    attr_id: 687,
    usda_tag: 'F17D1',
    name: '17:01',
    unit: 'g',
  },
  {
    attr_id: 614,
    usda_tag: 'F18D0',
    name: '18:00',
    unit: 'g',
  },
  {
    attr_id: 617,
    usda_tag: 'F18D1',
    name: '18:1 undifferentiated',
    unit: 'g',
  },
  {
    attr_id: 674,
    usda_tag: 'F18D1C',
    name: '18:1 c',
    unit: 'g',
  },
  {
    attr_id: 663,
    usda_tag: 'F18D1T',
    name: '18:1 t',
    unit: 'g',
  },
  {
    attr_id: 859,
    usda_tag: 'F18D1TN7',
    name: '18:1-11t (18:1t n-7)',
    unit: 'g',
  },
  {
    attr_id: 618,
    usda_tag: 'F18D2',
    name: '18:2 undifferentiated',
    unit: 'g',
  },
  {
    attr_id: 670,
    usda_tag: 'F18D2CLA',
    name: '18:2 CLAs',
    unit: 'g',
  },
  {
    attr_id: 675,
    usda_tag: 'F18D2CN6',
    name: '18:2 n-6 c,c',
    unit: 'g',
  },
  {
    attr_id: 669,
    usda_tag: 'F18D2TT',
    name: '18:2 t,t',
    unit: 'g',
  },
  {
    attr_id: 619,
    usda_tag: 'F18D3',
    name: '18:3 undifferentiated',
    unit: 'g',
  },
  {
    attr_id: 851,
    usda_tag: 'F18D3CN3',
    name: '18:3 n-3 c,c,c (ALA)',
    unit: 'g',
  },
  {
    attr_id: 685,
    usda_tag: 'F18D3CN6',
    name: '18:3 n-6 c,c,c',
    unit: 'g',
  },
  {
    attr_id: 627,
    usda_tag: 'F18D4',
    name: '18:04',
    unit: 'g',
  },
  {
    attr_id: 615,
    usda_tag: 'F20D0',
    name: '20:00',
    unit: 'g',
  },
  {
    attr_id: 628,
    usda_tag: 'F20D1',
    name: '20:01',
    unit: 'g',
  },
  {
    attr_id: 672,
    usda_tag: 'F20D2CN6',
    name: '20:2 n-6 c,c',
    unit: 'g',
  },
  {
    attr_id: 689,
    usda_tag: 'F20D3',
    name: '20:3 undifferentiated',
    unit: 'g',
  },
  {
    attr_id: 852,
    usda_tag: 'F20D3N3',
    name: '20:3 n-3',
    unit: 'g',
  },
  {
    attr_id: 853,
    usda_tag: 'F20D3N6',
    name: '20:3 n-6',
    unit: 'g',
  },
  {
    attr_id: 620,
    usda_tag: 'F20D4',
    name: '20:4 undifferentiated',
    unit: 'g',
  },
  {
    attr_id: 855,
    usda_tag: 'F20D4N6',
    name: '20:4 n-6',
    unit: 'g',
  },
  {
    attr_id: 629,
    usda_tag: 'F20D5',
    name: '20:5 n-3 (EPA)',
    unit: 'g',
  },
  {
    attr_id: 857,
    usda_tag: 'F21D5',
    name: '21:05',
    unit: 'g',
  },
  {
    attr_id: 624,
    usda_tag: 'F22D0',
    name: '22:00',
    unit: 'g',
  },
  {
    attr_id: 630,
    usda_tag: 'F22D1',
    name: '22:1 undifferentiated',
    unit: 'g',
  },
  {
    attr_id: 858,
    usda_tag: 'F22D4',
    name: '22:04',
    unit: 'g',
  },
  {
    attr_id: 631,
    usda_tag: 'F22D5',
    name: '22:5 n-3 (DPA)',
    unit: 'g',
  },
  {
    attr_id: 621,
    usda_tag: 'F22D6',
    name: '22:6 n-3 (DHA)',
    unit: 'g',
  },
  {
    attr_id: 654,
    usda_tag: 'F24D0',
    name: '24:00:00',
    unit: 'g',
  },
  {
    attr_id: 671,
    usda_tag: 'F24D1C',
    name: '24:1 c',
    unit: 'g',
  },
  {
    attr_id: 607,
    usda_tag: 'F4D0',
    name: '4:00',
    unit: 'g',
  },
  {
    attr_id: 608,
    usda_tag: 'F6D0',
    name: '6:00',
    unit: 'g',
  },
  {
    attr_id: 609,
    usda_tag: 'F8D0',
    name: '8:00',
    unit: 'g',
  },
  {
    attr_id: 645,
    usda_tag: 'FAMS',
    name: 'Fatty acids, total monounsaturated',
    unit: 'g',
    tableId: ENutritionItem.FatMonounsaturated,
  },
  {
    attr_id: 646,
    usda_tag: 'FAPU',
    name: 'Fatty acids, total polyunsaturated',
    unit: 'g',
    tableId: ENutritionItem.FatPolyunsaturated,
  },
  {
    attr_id: 693,
    usda_tag: 'FATRNM',
    name: 'Fatty acids, total trans-monoenoic',
    unit: 'g',
    tableId: ENutritionItem.FatTransMonoenoic,
  },
  {
    attr_id: 695,
    usda_tag: 'FATRNP',
    name: 'Fatty acids, total trans-polyenoic',
    unit: 'g',
    tableId: ENutritionItem.FatTransPolyenoic,
  },
  {
    attr_id: 313,
    usda_tag: 'FLD',
    name: 'Fluoride, F',
    unit: 'Âµg',
  },
  {
    attr_id: 417,
    usda_tag: 'FOL',
    name: 'Folate, total',
    unit: 'Âµg',
  },
  {
    attr_id: 431,
    usda_tag: 'FOLAC',
    name: 'Folic acid',
    unit: 'Âµg',
    tableId: ENutritionItem.FolicAcid,
  },
  {
    attr_id: 435,
    usda_tag: 'FOLDFE',
    name: 'Folate, DFE',
    unit: 'Âµg',
  },
  {
    attr_id: 432,
    usda_tag: 'FOLFD',
    name: 'Folate, food',
    unit: 'Âµg',
  },
  {
    attr_id: 212,
    usda_tag: 'FRUS',
    name: 'Fructose',
    unit: 'g',
  },
  {
    attr_id: 287,
    usda_tag: 'GALS',
    name: 'Galactose',
    unit: 'g',
  },
  {
    attr_id: 515,
    usda_tag: 'GLU_G',
    name: 'Glutamic acid',
    unit: 'g',
  },
  {
    attr_id: 211,
    usda_tag: 'GLUS',
    name: 'Glucose (dextrose)',
    unit: 'g',
  },
  {
    attr_id: 516,
    usda_tag: 'GLY_G',
    name: 'Glycine',
    unit: 'g',
  },
  {
    attr_id: 512,
    usda_tag: 'HISTN_G',
    name: 'Histidine',
    unit: 'g',
  },
  {
    attr_id: 521,
    usda_tag: 'HYP',
    name: 'Hydroxyproline',
    unit: 'g',
  },
  {
    attr_id: 503,
    usda_tag: 'ILE_G',
    name: 'Isoleucine',
    unit: 'g',
  },
  {
    attr_id: 213,
    usda_tag: 'LACS',
    name: 'Lactose',
    unit: 'g',
  },
  {
    attr_id: 504,
    usda_tag: 'LEU_G',
    name: 'Leucine',
    unit: 'g',
  },
  {
    attr_id: 338,
    usda_tag: 'LUT+ZEA',
    name: 'Lutein + zeaxanthin',
    unit: 'Âµg',
  },
  {
    attr_id: 337,
    usda_tag: 'LYCPN',
    name: 'Lycopene',
    unit: 'Âµg',
  },
  {
    attr_id: 505,
    usda_tag: 'LYS_G',
    name: 'Lysine',
    unit: 'g',
  },
  {
    attr_id: 214,
    usda_tag: 'MALS',
    name: 'Maltose',
    unit: 'g',
  },
  {
    attr_id: 506,
    usda_tag: 'MET_G',
    name: 'Methionine',
    unit: 'g',
  },
  {
    attr_id: 304,
    usda_tag: 'MG',
    name: 'Magnesium, Mg',
    unit: 'mg',
    tableId: ENutritionItem.Magnesium,
  },
  {
    attr_id: 428,
    usda_tag: 'MK4',
    name: 'Menaquinone-4',
    unit: 'Âµg',
  },
  {
    attr_id: 315,
    usda_tag: 'MN',
    name: 'Manganese, Mn',
    unit: 'mg',
    tableId: ENutritionItem.Manganese,
  },
  {
    attr_id: 406,
    usda_tag: 'NIA',
    name: 'Niacin',
    unit: 'mg',
    tableId: ENutritionItem.Niacin,
  },
  {
    attr_id: 573,
    usda_tag: 'NULL',
    name: 'Vitamin E, added',
    unit: 'mg',
  },
  {
    attr_id: 578,
    usda_tag: 'NULL',
    name: 'Vitamin B-12, added',
    unit: 'Âµg',
  },
  {
    attr_id: 257,
    usda_tag: 'NULL',
    name: 'Adjusted Protein',
    unit: 'g',
  },
  {
    attr_id: 664,
    usda_tag: 'NULL',
    name: '22:1 t',
    unit: 'g',
  },
  {
    attr_id: 676,
    usda_tag: 'NULL',
    name: '22:1 c',
    unit: 'g',
  },
  {
    attr_id: 856,
    usda_tag: 'NULL',
    name: '18:3i',
    unit: 'g',
  },
  {
    attr_id: 665,
    usda_tag: 'NULL',
    name: '18:2 t not further defined',
    unit: 'g',
  },
  {
    attr_id: 666,
    usda_tag: 'NULL',
    name: '18:2 i',
    unit: 'g',
  },
  {
    attr_id: 305,
    usda_tag: 'P',
    name: 'Phosphorus, P',
    unit: 'mg',
    tableId: ENutritionItem.Phosphorus,
  },
  {
    attr_id: 410,
    usda_tag: 'PANTAC',
    name: 'Pantothenic acid',
    unit: 'mg',
    tableId: ENutritionItem.PantothenicAcid,
  },
  {
    attr_id: 508,
    usda_tag: 'PHE_G',
    name: 'Phenylalanine',
    unit: 'g',
  },
  {
    attr_id: 636,
    usda_tag: 'PHYSTR',
    name: 'Phytosterols',
    unit: 'mg',
  },
  {
    attr_id: 517,
    usda_tag: 'PRO_G',
    name: 'Proline',
    unit: 'g',
  },
  {
    attr_id: 319,
    usda_tag: 'RETOL',
    name: 'Retinol',
    unit: 'Âµg',
  },
  {
    attr_id: 405,
    usda_tag: 'RIBF',
    name: 'Riboflavin',
    unit: 'mg',
    tableId: ENutritionItem.Riboflavin,
  },
  {
    attr_id: 317,
    usda_tag: 'SE',
    name: 'Selenium, Se',
    unit: 'Âµg',
    tableId: ENutritionItem.Selenium,
  },
  {
    attr_id: 518,
    usda_tag: 'SER_G',
    name: 'Serine',
    unit: 'g',
  },
  {
    attr_id: 641,
    usda_tag: 'SITSTR',
    name: 'Beta-sitosterol',
    unit: 'mg',
  },
  {
    attr_id: 209,
    usda_tag: 'STARCH',
    name: 'Starch',
    unit: 'g',
  },
  {
    attr_id: 638,
    usda_tag: 'STID7',
    name: 'Stigmasterol',
    unit: 'mg',
  },
  {
    attr_id: 210,
    usda_tag: 'SUCS',
    name: 'Sucrose',
    unit: 'g',
  },
  {
    attr_id: 263,
    usda_tag: 'THEBRN',
    name: 'Theobromine',
    unit: 'mg',
  },
  {
    attr_id: 404,
    usda_tag: 'THIA',
    name: 'Thiamin',
    unit: 'mg',
    tableId: ENutritionItem.Thiamin,
  },
  {
    attr_id: 502,
    usda_tag: 'THR_G',
    name: 'Threonine',
    unit: 'g',
  },
  {
    attr_id: 323,
    usda_tag: 'TOCPHA',
    name: 'Vitamin E (alpha-tocopherol)',
    unit: 'mg',
    tableId: ENutritionItem.VitaminE,
  },
  {
    attr_id: 341,
    usda_tag: 'TOCPHB',
    name: 'Tocopherol, beta',
    unit: 'mg',
  },
  {
    attr_id: 343,
    usda_tag: 'TOCPHD',
    name: 'Tocopherol, delta',
    unit: 'mg',
  },
  {
    attr_id: 342,
    usda_tag: 'TOCPHG',
    name: 'Tocopherol, gamma',
    unit: 'mg',
  },
  {
    attr_id: 501,
    usda_tag: 'TRP_G',
    name: 'Tryptophan',
    unit: 'g',
  },
  {
    attr_id: 509,
    usda_tag: 'TYR_G',
    name: 'Tyrosine',
    unit: 'g',
  },
  {
    attr_id: 510,
    usda_tag: 'VAL_G',
    name: 'Valine',
    unit: 'g',
  },
  {
    attr_id: 318,
    usda_tag: 'VITA_IU',
    name: 'Vitamin A, IU',
    unit: 'IU',
    tableId: ENutritionItem.VitaminA,
  },
  {
    attr_id: 320,
    usda_tag: 'VITA_RAE',
    name: 'Vitamin A, RAE',
    unit: 'Âµg',
  },
  {
    attr_id: 418,
    usda_tag: 'VITB12',
    name: 'Vitamin B-12',
    unit: 'Âµg',
    tableId: ENutritionItem.VitaminB12,
  },
  {
    attr_id: 415,
    usda_tag: 'VITB6A',
    name: 'Vitamin B-6',
    unit: 'mg',
    tableId: ENutritionItem.VitaminB6,
  },
  {
    attr_id: 401,
    usda_tag: 'VITC',
    name: 'Vitamin C, total ascorbic acid',
    unit: 'mg',
    tableId: ENutritionItem.VitaminC,
  },
  {
    attr_id: 328,
    usda_tag: 'VITD',
    name: 'Vitamin D (D2 + D3)',
    unit: 'Âµg',
  },
  {
    attr_id: 430,
    usda_tag: 'VITK1',
    name: 'Vitamin K (phylloquinone)',
    unit: 'Âµg',
    tableId: ENutritionItem.VitaminK,
  },
  {
    attr_id: 429,
    usda_tag: 'VITK1D',
    name: 'Dihydrophylloquinone',
    unit: 'Âµg',
  },
  {
    attr_id: 255,
    usda_tag: 'WATER',
    name: 'Water',
    unit: 'g',
  },
  {
    attr_id: 309,
    usda_tag: 'ZN',
    name: 'Zinc, Zn',
    unit: 'mg',
    tableId: ENutritionItem.Zinc,
  },
  {
    attr_id: 344,
    usda_tag: 'TOCTRA',
    name: 'Tocotrienol, alpha',
    unit: 'mg',
  },
  {
    attr_id: 345,
    usda_tag: 'TOCTRB',
    name: 'Tocotrienol, beta',
    unit: 'mg',
  },
  {
    attr_id: 346,
    usda_tag: 'TOCTRG',
    name: 'Tocotrienol, gamma',
    unit: 'mg',
  },
  {
    attr_id: 347,
    usda_tag: 'TOCTRD',
    name: 'Tocotrienol,delta',
    unit: 'mg',
  },
];
