import styled, { css } from 'styled-components';

import colors from '../../styles/colors';

const tagsContainerStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 4px 10px;
  white-space: nowrap;
  box-sizing: border-box;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: -0.01em;
  text-align: center;
  border: 1px solid transparent;
  border-radius: 11px;
  text-transform: none;

  ${({ $small }) => $small && css`
    font-size: 8px;
    font-weight: 500;
    line-height: 9px;
    letter-spacing: -0.01em;
  `};
`;

const LightTag = styled.span`
  ${tagsContainerStyles};
  background: ${colors.shades.gamma12};
  color: ${colors.shades.secondary8};
  border-color: ${colors.shades.gamma13};
`;

const SuccessTag = styled.span`
  ${tagsContainerStyles};
  background: ${colors.shades.success5};
  color: ${colors.shades.secondary8};
  border-color: ${colors.shades.success4};
`;

const StyledDarkTag = styled.span`
  ${tagsContainerStyles};
  background: ${colors.shades.secondary8};
  color: ${colors.base.primary};
  border-color: ${colors.base.secondary2};
`;

const ErrorTag = styled.span`
  ${tagsContainerStyles};
  background: ${colors.shades.danger2};
  color: ${colors.shades.danger3};
  border-color: ${colors.shades.danger5};
`;

const WarningTag = styled.span`
  ${tagsContainerStyles};
  background: ${colors.shades.primary5};
  color: ${colors.shades.secondary8};
  border-color: ${colors.shades.primary1};
`;

const InfoTag = styled.span`
  ${tagsContainerStyles};
  background: ${colors.shades.primary7};
  color: ${colors.shades.secondary8};
  border-color: ${colors.shades.primary3};
`;

const NoticeTag = styled.span`
  ${tagsContainerStyles};
  background: ${colors.shades.primary8};
  color: ${colors.shades.secondary8};
  border-color: ${colors.shades.warning3};
`;

const OkTag = styled.span`
  ${tagsContainerStyles};
  background: ${colors.shades.success6};
  color: ${colors.base.alpha};
  border-color: ${colors.shades.success7};
`;

const AttentionTag = styled.span`
  ${tagsContainerStyles};
  background: ${colors.shades.danger1};
  color: ${colors.base.alpha};
  border-color: ${colors.base.danger};
  font-weight: 500;
`;

const TransparentTag = styled.span`
  ${tagsContainerStyles};
  color: ${colors.shades.secondary8};
  padding: 4px 5px;
`;

const DisabledTag = styled.span`
  ${tagsContainerStyles};
  background: ${colors.shades.gamma8};
  color: ${colors.shades.secondary8};
  border-color: ${colors.shades.gamma9};
  font-weight: 500;
`;

const BaseTag = styled.span`
  ${tagsContainerStyles};
`;

export {
  InfoTag,
  SuccessTag,
  ErrorTag,
  OkTag,
  StyledDarkTag,
  AttentionTag,
  TransparentTag,
  NoticeTag,
  WarningTag,
  BaseTag,
  LightTag,
  DisabledTag,
};
