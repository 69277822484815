import React, { useContext } from 'react';
import LoadingPage from '../../../components/LoadingPage';
import UserContractsContext from './UserContractsContext';
import UserContractsContextProvider from './UserContractsContextProvider';

/**
 * Inserts UserContractsContextProvider.
 *
 * @param {Object} Component The component that needs the context provider.
 * @returns {Object} The component to render wrapped with the provider.
 */
const withUserContractsContextProvider = (Component) => (props) => (
  <UserContractsContextProvider>
    <Component {...props} />
  </UserContractsContextProvider>
);

const withUserContractsContextReady = (Component) => (props) => {
  const { isReady } = useContext(UserContractsContext);

  return isReady ? <Component {...props} /> : <LoadingPage />;
};

export {
  withUserContractsContextProvider,
  withUserContractsContextReady,
};
