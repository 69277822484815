import React from 'react';

import MsgEndpointContextProvider from './MsgEndpointContextProvider';

const withMsgEndpointContextProvider = (Component) => (props) => (
  <MsgEndpointContextProvider>
    <Component {...props} />
  </MsgEndpointContextProvider>
);

export {
  withMsgEndpointContextProvider,
};
