import React, { useContext } from 'react';

import NavigationContext, { NavigationRouteType } from '../context/NavigationContext';

import CoachToolsLayout from './CoachToolsLayout';
import AdminToolsLayout from './AdminToolsLayout';
import SupportToolsLayout from './SupportToolsLayout';
import InsideSalesToolsLayout from './InsideSalesToolsLayout';
import AssistantCoachToolsLayout from './AssistantCoachToolsLayout';

const withToolsLayout = (Component) => (props) => {
  const { routeType } = useContext(NavigationContext);
  let Layout;
  if (routeType === NavigationRouteType.ADMIN) {
    Layout = AdminToolsLayout;
  } else if (routeType === NavigationRouteType.INSIDE_SALES) {
    Layout = InsideSalesToolsLayout;
  } else if (routeType === NavigationRouteType.SUPPORT) {
    Layout = SupportToolsLayout;
  } else if (routeType === NavigationRouteType.ASSISTANT_COACH) {
    Layout = AssistantCoachToolsLayout;
  } else {
    Layout = CoachToolsLayout;
  }
  return (
    <Layout>
      <Component {...props} />
    </Layout>
  );
};

export {
  withToolsLayout,
};
