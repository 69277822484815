import React from 'react';
import PropTypes, { object } from 'prop-types';

import { HiddenFieldType } from '../../questionType';
import fieldName from '../../formFields';
import FieldArray from '../FieldArray';

import HiddenFieldElement from './hiddenFieldElement';
import {
  Container,
  HeaderContainer,
  Title,
} from './styles';
import texts from './texts.json';

const HiddenFields = ({ hiddenFields }) => (
  <Container>
    <HeaderContainer>
      <Title>{texts.hiddenFields}</Title>
    </HeaderContainer>
    <FieldArray
      questions={hiddenFields}
      fieldName={fieldName.HIDDEN_FIELDS}
      addOptions={HiddenFieldType}
      elementArray={HiddenFieldElement}
      draggable={false}
    />
  </Container>
);

HiddenFields.propTypes = {
  hiddenFields: PropTypes.arrayOf(object),
};

HiddenFields.defaultProps = {
  hiddenFields: [],
};

export default HiddenFields;
