import React, {
  useState,
} from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';
import { DateFormat } from '../../../../../utils/date';
import useToast from '../../../../hooks/useToast';
import CoachReminderModal from '../../../../layout/components/CoachReminderModal';

import {
  StyledButton,
  Container,
  ButtonContainer,
  DayContainer,
  StyledProgramIcon,
  StyledWorkoutIcon,
  StyledCopyIcon,
  StyledClockIcon,
} from './styles';
import texts from './texts.json';

const CalendarDayView = ({
  dayData,
  selectedDate: hoverDate,
  onWorkoutAssignmentClick,
  onProgramAssignmentClick,
  onWorkoutPasteClick,
  showPasteButton,
}) => {
  const [isReminderModalOpen, setIsReminderModalOpen] = useState(false);

  const { showToast } = useToast();

  const selectedDateString = moment(dayData.date).format(DateFormat.DEFAULT_DATE_FORMAT);

  return (
    <Container>
      {moment(hoverDate).isSame(dayData.date) && (
        <ButtonContainer>
          <StyledButton onClick={() => onWorkoutAssignmentClick(selectedDateString)}>
            <Tooltip title={texts.addWorkout} arrow>
              <StyledWorkoutIcon />
            </Tooltip>
          </StyledButton>
          <StyledButton onClick={() => onProgramAssignmentClick(selectedDateString)}>
            <Tooltip title={texts.addProgram}>
              <StyledProgramIcon />
            </Tooltip>
          </StyledButton>
          {showPasteButton && (
            <StyledButton onClick={() => {
              onWorkoutPasteClick(selectedDateString);
            }}
            >
              <Tooltip title={texts.pasteWorkout} arrow>
                <StyledCopyIcon />
              </Tooltip>
            </StyledButton>
          )}
          <StyledButton onClick={() => setIsReminderModalOpen(true)}>
            <Tooltip title={texts.addReminder} arrow>
              <StyledClockIcon />
            </Tooltip>
          </StyledButton>
        </ButtonContainer>
      )}
      <CoachReminderModal
        isOpen={isReminderModalOpen}
        onDidDismiss={() => setIsReminderModalOpen(false)}
        showReminderScheduled={() => showToast(texts.reminderScheduled)}
        showReminderError={(errorMessage = '') => showToast(errorMessage
          || (texts.reminderScheduleError, { error: true }))}
        selectedSendDate={selectedDateString}
      />
      <DayContainer>
        {dayData.dayNumberText}
      </DayContainer>
    </Container>
  );
};

CalendarDayView.propTypes = {
  dayData: PropTypes.object.isRequired,
  selectedDate: PropTypes.object,
  onWorkoutAssignmentClick: PropTypes.func.isRequired,
  onProgramAssignmentClick: PropTypes.func.isRequired,
  showPasteButton: PropTypes.bool.isRequired,
  onWorkoutPasteClick: PropTypes.func.isRequired,
};

CalendarDayView.defaultProps = {
  selectedDate: null,
};

export default CalendarDayView;
