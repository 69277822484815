import styled from 'styled-components';
import { Button } from '@mui/material';

import colors from '../../../../../styles/colors';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 10px;
`;

const StyledAddButton = styled(Button)`
  width: auto;
  margin: 15px 0;
  padding: 5px 10px;
  text-transform: none;
  background-color: ${colors.shades.gamma4};
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
  color: ${colors.shades.beta2};
  &:hover {
    background-color: ${colors.shades.gamma4};
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
  }
`;

export {
  Container,
  StyledAddButton,
};
