import styled, { css } from 'styled-components';
import {
  Autocomplete,
  TextField,
} from '@mui/material';

import colors from '../../../../../styles/colors';

const CoachSelectWrapper = styled.div`
  width: 300px;
`;

const StyledAutoComplete = styled(Autocomplete)`
  .MuiAutocomplete-inputRoot {
    height: 56px;
    overflow: hidden;
  }
`;

const StyledTextField = styled(TextField)`
  background: ${colors.base.alpha};
  border-radius: 4px;
`;

const StyledOption = styled.div`
  ${({ $isDivider }) => $isDivider && css`
    border-bottom: 2px solid ${colors.shades.primary1};
    margin-bottom: 1rem;
  `}
`;

const CheckboxContainer = styled.div`
  width: 100%;
  padding: 10px 17px;
`;

export {
  CoachSelectWrapper,
  StyledAutoComplete,
  StyledTextField,
  StyledOption,
  CheckboxContainer,
};
