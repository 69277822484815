import React, {
  useState,
} from 'react';
import { compose } from 'recompose';

import { ReactComponent as CreateIcon } from '../../../../assets/icons/v2/creation-plus-circle.svg';
import { PrimaryButton } from '../../../../components/Button/ActionButtons';
import { withMealPlanContextReady } from '../../../context/MealPlanContext';
import { HeaderContainer, Title } from '../styles';

import MealPlansTable from './components/MealPlansTable';
import ManageMealTemplate from './components/ManageMealTemplate';
import {
  MealAssignmentsTableContainer,
  MealAssignmentsContainer,
} from './styles';
import texts from './texts.json';

const ManageMealPlans = () => {
  const [selectedMealPlan, setSelectedMealPlan] = useState(null);

  const [showCreatePage, setShowCreatePage] = useState(false);

  const handleCreateClick = () => {
    setShowCreatePage((prevShowCreatePage) => !prevShowCreatePage);
  };

  if (showCreatePage || selectedMealPlan) {
    return (
      <ManageMealTemplate
        selectedMealPlan={selectedMealPlan}
        onClose={() => {
          setShowCreatePage(false);
          setSelectedMealPlan(null);
        }}
      />
    );
  }

  return (
    <MealAssignmentsContainer>
      <HeaderContainer>
        <Title>{texts.manageMealPlansTitle}</Title>
        <PrimaryButton
          onClick={handleCreateClick}
          icon={<CreateIcon />}
        >
          {texts.createMealPlan}
        </PrimaryButton>
      </HeaderContainer>
      <MealAssignmentsTableContainer>
        <MealPlansTable onSelectMealPlan={setSelectedMealPlan} />
      </MealAssignmentsTableContainer>
    </MealAssignmentsContainer>
  );
};

export default compose(
  withMealPlanContextReady,
)(ManageMealPlans);
