import React, {
  useCallback,
  useState,
} from 'react';
import format from 'string-template';

import { isIOS, isAndroid } from '../../utils/device';
import { ReactComponent as S2Logo } from '../../assets/s2-logo.svg';
import config from '../../config';

import Button, { variants } from '../Button';

import texts from './texts.json';

import {
  Container,
  Message,
  ButtonsContainer,
} from './styles';

const storePlatformConfig = {
  IOS: {
    storeName: 'App Store',
    storeURL: config.storeURLs.ios,
  },
  ANDROID: {
    storeName: 'Play Store',
    storeURL: config.storeURLs.android,
  },
};

const DownloadApp = () => {
  const [storesConfig] = useState(() => {
    if (isIOS) {
      return [storePlatformConfig.IOS];
    }
    if (isAndroid) {
      return [storePlatformConfig.ANDROID];
    }
    // It's web, show both: iOS and Android download buttons.
    return Object.values(storePlatformConfig);
  });

  const renderDownloadButtons = useCallback(() => (
    storesConfig.map((storeConfig) => {
      const {
        storeURL,
        storeName,
      } = storeConfig;
      return (
        <Button
          variant={variants.SECONDARY}
          key={storeName}
          onClick={() => window.open(storeURL, '_blank')}
        >
          {format(texts.buttonText, { storeName })}
        </Button>
      );
    })
  ), [
    storesConfig,
  ]);

  return (
    <Container>
      <S2Logo />
      <Message>{texts.message}</Message>
      <ButtonsContainer>
        {renderDownloadButtons()}
      </ButtonsContainer>
    </Container>
  );
};

export default DownloadApp;
