import React, {
  useState,
  useMemo,
  useEffect,
  useContext,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import { pagesConfig } from '../../config/pages';

import NavigationContext from '../../context/NavigationContext';

import LayoutContext, { initialValues } from './LayoutContext';

const LayoutContextProvider = ({ children }) => {
  const [title, setTitle] = useState(initialValues.title);
  const [isDrawerOpen, setDrawerOpen] = useState(initialValues.isDrawerOpen);
  const { activePage } = useContext(NavigationContext);

  useEffect(() => {
    if (activePage) {
      setTitle(pagesConfig[activePage].title || '');
    }
  }, [activePage]);

  const toggleDrawer = useCallback(() => {
    setDrawerOpen((isOpen) => !isOpen);
  }, [
    setDrawerOpen,
  ]);

  const contextValue = useMemo(() => ({
    title,
    isDrawerOpen,
    toggleDrawer,
  }), [
    title,
    isDrawerOpen,
    toggleDrawer,
  ]);

  return (
    <LayoutContext.Provider value={contextValue}>
      {children}
    </LayoutContext.Provider>
  );
};

LayoutContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LayoutContextProvider;
