import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from '@mui/material';

import { openDashboardInNewTab } from '../../../../../../utils/stripe';
import { Wrapper } from '../../../../styles';

import {
  StatsWrapper,
  Stat,
  Label,
  Text,
  StyledButton,
} from './styles';
import texts from './texts.json';

const StripeInfo = ({
  stripeAccountId,
}) => (
  <Wrapper>
    <Alert variant="outlined" severity="success">
      {texts.message}
    </Alert>
    <StatsWrapper>
      <Stat>
        <Label>{texts.accountId}</Label>
        <Text>{stripeAccountId}</Text>
      </Stat>
    </StatsWrapper>
    <StyledButton onClick={() => openDashboardInNewTab(stripeAccountId)}>{texts.openStripeDashboard}</StyledButton>
  </Wrapper>
);

StripeInfo.propTypes = {
  stripeAccountId: PropTypes.string.isRequired,
};

export default StripeInfo;
