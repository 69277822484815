import React, { useContext } from 'react';

import LoadingPage from '../../components/LoadingPage';

import GrowsurfContextProvider from './GrowsurfContextProvider';
import GrowsurfContext from './GrowsurfContext';

const withGrowsurfContext = (Component) => (props) => (
  <GrowsurfContextProvider>
    <Component {...props} />
  </GrowsurfContextProvider>
);

const withGrowsurfContextReady = (Component) => (props) => {
  const { isReady } = useContext(GrowsurfContext);
  return isReady ? <Component {...props} /> : <LoadingPage />;
};

export {
  withGrowsurfContext,
  withGrowsurfContextReady,
};
