import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../styles/colors';
import {
  BackButtonContainer,
  StyledBackArrowIcon,
} from './styles';

const BackButton = ({
  color,
  ...buttonProps
}) => (
  <BackButtonContainer {...buttonProps}>
    <StyledBackArrowIcon color={color} />
  </BackButtonContainer>
);

BackButton.propTypes = {
  color: PropTypes.string,
};

BackButton.defaultProps = {
  color: colors.base.beta,
};

export default BackButton;
