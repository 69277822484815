import React from 'react';
import Sales from '../../../components/Sales/Sales';
import { UserContractsContextProvider } from '../../../context/UserContractsContext';

const InsideSales = () => (
  <UserContractsContextProvider>
    <Sales />
  </UserContractsContextProvider>
);

export default InsideSales;
