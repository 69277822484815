/**
 * Colors that are part of the main color palette should be defined in "base".
 * If we have variations of the base colors, ligther or darker versions, those should
 * go under "shades".
 */
const base = {
  // main brand colors
  primary: '#F9D74D', // yellow
  secondary: '#3A7CFD', // blue
  secondary2: '#14222F', // dark blue
  secondary3: '#C7D6E8', // sky
  alpha: '#FFFFFF', // white
  beta: '#000000', // black
  gamma: '#F7F7F7', // grey
  success: '#BBFB92', // green
  support: '#F4F4F4', // grey
  danger: '#EA5B63', // red
  warning: '#BD795A', // orange
};

const shades = {
  primary1: '#D6AC6D', // sand for progress bar
  primary2: '#E7BE2B',
  primary3: '#FCE8A1',
  primary4: '#FCDC6A',
  primary5: '#E5BF85',
  primary6: '#CFA464',
  primary7: '#FDF1C7',
  primary8: '#FFE4BB',
  secondary1: '#234A98', // blue dark 40%
  secondary2: '#4E88FD', // blue - white with opacity 0.1
  secondary3: '#0B1C4F', // dark blue
  secondary4: '#C7D6E8', // sky
  secondary5: '#EFF7FF', // light blue
  secondary6: '#BAC9DC', // dark sky
  secondary7: '#DDECFF', // borders
  secondary8: '#0D3355', // border dark
  secondary9: '#FECCCC', // pinkish
  secondary10: '#F8FBFF', // even lighter blue
  alpha1: '#FCFCFC', // light gray
  gamma1: '#E2E2E2', // grey - black with opacity 0.1
  gamma2: '#999999', // grey - black with opacity 0.4
  gamma3: '#C7C7C7', // grey border
  gamma4: '#ECF1F7', // grey box-shadow
  gamma5: '#EDEEE9', // grey background in input
  gamma6: '#8FA3BC', // gray subscription price
  gamma7: '#8A8A8A', // gray sticky faded out section
  gamma8: '#D1D1D1', // gray separation line
  gamma9: '#bdbdbd', // gray MUI placeholder
  gamma10: '#687078', // gray text
  gamma11: '#888888', // gray shade
  gamma12: '#e5eaef', // tag grey
  gamma13: '#d8dfe9', // tag border grey
  danger1: '#EC6B74', // danger - white with opacity 0.1
  danger2: '#FDEFEF', // danger with opacity 0.1
  danger3: '#EB1C26', // danger with a more bright red
  danger4: '#FFD2D2', // danger with light red
  danger5: '#FF5A5F', // danger - a lighter red color
  beta1: '#4B4B4B', // black variation
  beta2: '#191919', // black - white with opacity 0.1
  success1: '#86CC47', // ligth green
  success2: '#4DB1C1', // green - for success message
  success3: '#00AE07',
  success4: '#4fd44c', // success tag border
  success5: '#5de15c', // success tag bg
  success6: '#10A643', // ok tag bg
  success7: '#017E2B', // ok tag border
  warning1: '#FFF8DE', // orange - very light
  warning2: '#FFDD64', // orange - light
  warning3: '#FFA763', // orange - dark
};

/**
 * This function adds an alpha value (transparency) to a given hexadecimal color
 * @param {string} hexColor hexadecimal 6 digit color (#FFFFFF)
 * @param {number} alpha alpha value, between 0 and 1
 * @returns rgba color
 */
const addAlpha = (hexColor, alpha) => {
  const radix = 16;
  const [, red, green, blue] = hexColor.match(/#([\da-f]{2})([\da-f]{2})([\da-f]{2})/i);
  return `rgba(${parseInt(red, radix)},${parseInt(green, radix)},${parseInt(blue, radix)},${alpha})`;
};

const chartColors = [
  '#8884d8',
  '#82ca9d',
  '#ffc658',
  '#4176DF',
  '#93BD31',
  '#7E20AD',
  '#66D1D4',
  '#FD765D',
  '#A071FF',
  '#F7A0EF',
  '#12659B',
  '#CF6C17',
  '#259982',
  '#FAC400',
  '#D6064B',
  '#2A75DF',
];

const colors = {
  base,
  shades,
  addAlpha,
  chartColors,
};

export default colors;
