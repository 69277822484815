import {
  useMemo,
  useCallback,
} from 'react';

import { toast } from 'react-toastify';

const toastDefaultOptions = {
  position: 'top-right',
  autoClose: 5000,
};

const useToast = () => {
  const showToast = useCallback((toastText, {
    error = false,
    warning = false,
    options = toastDefaultOptions,
  } = {}) => {
    if (error) {
      toast.error(toastText, options);
    } else if (warning) {
      toast.warning(toastText, options);
    } else {
      toast.success(toastText, options);
    }
  }, []);

  return useMemo(() => ({
    showToast,
  }), [
    showToast,
  ]);
};

export default useToast;
