import React, { useContext } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { withChat } from '@fitmoola/system2-chat';

import { withFeedContextProvider } from '../../context/FeedContext';
import { withChatContextProvider } from '../../context/ChatContext';
import { withOnboardingCoachNotificationsContextProvider } from '../../context/OnboardingCoachNotificationsContext';
import { withCustomizationContextProvider } from '../../context/CustomizationContext';
import { withManageClientContextProvider } from '../../context/ManageClientContext';
import { withMealPlanContextProvider } from '../../context/MealPlanContext';
import { withExerciseContextProvider } from '../../context/ExerciseContext';
import { withExerciseTagContextProvider } from '../../context/ExerciseTagContext';
import {
  withExternalCoachContextProvider,
  withExternalCoachContextReady,
} from '../../context/ExternalCoachContext';
import { withLeadsContextProvider } from '../../context/LeadsContext';
import { withManageProgramContextProvider } from '../../context/ManageProgramContext';
import { withWorkoutContextProvider } from '../../context/WorkoutContext';
import withChatProps from '../../pages/Chat/withChatProps';
import QAPContext, { withQAPContextProvider } from '../../../context/QAPContext';
import ToolsLayout from '../ToolsLayout';
import Chatbar from '../components/Chatbar';
import ClientQuickInfoModal from '../../components/ClientQuickInfoModal';
import CoachSidebar from './CoachSidebar';

const CoachToolsLayout = ({
  children,
}) => {
  const { isOpen: showQAP } = useContext(QAPContext);
  return (
    <ToolsLayout
      sidebarComponent={<CoachSidebar />}
      bottomBarComponent={<Chatbar />}
    >
      {children}
      {showQAP && <ClientQuickInfoModal />}
    </ToolsLayout>
  );
};

CoachToolsLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

// Don't put any ready context providers here unless you want them to be ready for all coach tools pages!
export default compose(
  withFeedContextProvider,
  // Order of feed context, chat context and external coach context is important here!
  withExternalCoachContextProvider,
  withExternalCoachContextReady,
  withChatContextProvider,
  withCustomizationContextProvider,
  withMealPlanContextProvider,
  withManageClientContextProvider,
  withOnboardingCoachNotificationsContextProvider,
  withLeadsContextProvider,
  withManageProgramContextProvider,
  withWorkoutContextProvider,
  withExerciseContextProvider,
  withExerciseTagContextProvider,
  withQAPContextProvider,
  withChatProps,
  withChat,
)(CoachToolsLayout);
