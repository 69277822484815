import React from 'react';
import PropTypes from 'prop-types';

import AnalyticNumberCard from '../../AnalyticNumberCard';
import CoachRevenueData from '../../../../../Model/analytics/CoachRevenueData';
import texts from './texts.json';

const TakeHomeAnalyticsCard = ({
  revenueAnalytics,
}) => (
  <AnalyticNumberCard
    title={revenueAnalytics?.netRevenue}
    subTitle={texts.takeHome}
    bottomStats={[
      {
        title: revenueAnalytics?.applicationFeeWithCurrency,
        subTitle: texts.applicationFee,
      },
      {
        title: revenueAnalytics?.stripeFee,
        subTitle: texts.stripeFee,
      },
    ]}
  />
);

TakeHomeAnalyticsCard.propTypes = {
  revenueAnalytics: PropTypes.instanceOf(CoachRevenueData).isRequired,
};

export default TakeHomeAnalyticsCard;
