import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import PhoneInput from 'react-phone-number-input/input';

import Container, { ErrorMessage } from '../styles';

const FormikPhoneInput = ({
  name,
  placeholder,
  className,
  isSmall,
}) => {
  const { 0: field, 2: { setValue } } = useField(name);

  const onPhoneValueChange = useCallback((value = '') => {
    setValue(value);
  }, [setValue]);

  return (
    <Container isSmall={isSmall} className={className}>
      <PhoneInput
        {...field}
        defaultCountry="US"
        placeholder={placeholder}
        onChange={onPhoneValueChange}
      />
      <ErrorMessage name={name} component="div" />
    </Container>
  );
};

FormikPhoneInput.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  isSmall: PropTypes.bool,
};

FormikPhoneInput.defaultProps = {
  placeholder: 'Phone number',
  className: 'FormikPhoneInput',
  isSmall: false,
};

export default FormikPhoneInput;
