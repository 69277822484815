import { Button } from '@mui/material';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 30px;
  height: 85vh;
`;

const StyledButton = styled(Button)`
  width: 180px;
`;

export {
  Container,
  StyledButton,
};
