import styled, { css } from 'styled-components';
import Button from '../../../../../components/Button';
import UserProfileCard from '../../../../components/UserProfileCard';
import colors from '../../../../../styles/colors';

const BUTTON_CONTAINER_WIDTH = 220;

const buttonStyles = css`
  margin: 10px 0px;
  background-color: ${colors.shades.secondary4};
  border-radius: 36px;
  color: ${colors.base.secondary2};
  font-size: 14px;
  padding: 8px 16px;
  font-weight: 400;
  text-transform: none;
  border: 1px solid ${colors.shades.secondary4};
  &:hover {
    background-color: ${colors.shades.secondary5};
    border: 1px solid ${colors.shades.secondary4};
    cursor: pointer;
  }
`;

const MarkAsReadButton = styled(Button)`
  ${buttonStyles};
`;

const RestoreButton = MarkAsReadButton;

const ClaimButton = styled(Button)`
  ${buttonStyles};
  background-color: ${colors.shades.primary4};
  border: 1px solid ${colors.shades.secondary4};
  &:hover {
    background-color: ${colors.shades.primary3};
  }
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  justify-content: right;
  white-space: nowrap;
  column-gap: 10px;
  width: ${BUTTON_CONTAINER_WIDTH}px;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  column-gap: 8px;
  justify-content: space-between;
`;

const StyledUserProfileCard = styled(UserProfileCard)`
  width: calc(100% - ${BUTTON_CONTAINER_WIDTH}px);
  max-width: fit-content;
  a {
    text-decoration: none;
  }
`;

export {
  MarkAsReadButton,
  ActionButtonsContainer,
  RestoreButton,
  ClaimButton,
  HeaderContainer,
  StyledUserProfileCard,
};
