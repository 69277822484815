import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { IonSpinner } from '@ionic/react';
import { debounce } from 'throttle-debounce';

import {
  LoadingWrapper,
  MessageComponentWrapper,
} from './styles';

const MAX_TIME_WAITING_NEXT_LOADING_PAGE = 2000;

const triggerS2Loaded = debounce(MAX_TIME_WAITING_NEXT_LOADING_PAGE, () => {
  if (!window.system2.isLoading) {
    const s2AppLoadedEvent = new CustomEvent('s2AppLoaded');
    window.dispatchEvent(s2AppLoadedEvent);
  }
});

const LoadingPage = ({ className, messageComponent }) => {
  useEffect(() => {
    if (!window.system2.isLoading) {
      window.system2.isLoading = true;
    }
    return () => {
      window.system2.isLoading = false;
      triggerS2Loaded();
    };
  });

  return (
    <LoadingWrapper className={className}>
      <IonSpinner name="crescent" />
      {!!messageComponent
      && (
      <MessageComponentWrapper>
        {messageComponent}
      </MessageComponentWrapper>
      )}
    </LoadingWrapper>
  );
};

LoadingPage.propTypes = {
  className: PropTypes.string,
  messageComponent: PropTypes.node,
};

LoadingPage.defaultProps = {
  className: '',
  messageComponent: null,
};

export default LoadingPage;
