const AnalyticType = {
  LEADS: 'leads',
  REVENUE: 'revenue',
  SUBSCRIPTIONS: 'subscriptions',
  ACTIVE_USERS: 'activeUsers',
  USER_LIFESPAN: 'userLifespan',
};

const AnalyticFrequency = {
  DAILY: 'daily',
  MONTHLY: 'monthly',
};

const AnalyticUserType = {
  COACH: 'coach',
};

const AnalyticGroups = {
  COACH: 'Coach',
  CRX: 'CRX',
  INSIDE_SALES: 'Inside Sales',
  ASSISTANT_COACH: 'Assistant Coach',
};

const RemoteFunctions = {
  GET_TIME_SPENT_BY_USER: 'getTimeSpentByUser',
  GET_TIME_SPENT_BY_COACH: 'getTimeSpentByCoach',
  GET_FEATURE_TIME_SPENT: 'getFeatureTimeSpent',
};

const getTimeSpentData = async (remote, funcName, params) => {
  const endpoint = `analytics/${funcName}?${(new URLSearchParams(params).toString())}`;
  const response = await remote(endpoint, null, { method: 'GET' });
  return response.json();
};

export {
  AnalyticType,
  AnalyticFrequency,
  AnalyticUserType,
  AnalyticGroups,
  RemoteFunctions,
  getTimeSpentData,
};
