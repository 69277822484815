import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import format from 'string-template';
import moment from 'moment';

import { IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

import { DateFormat } from '../../../utils/date';
import UserContract from '../../../Model/UserContract';
import useComponentMounted from '../../../hooks/useComponentMounted';
import Table from '../../../components/v2/Table';
import CopyButton from '../../../components/CopyButton';
import { PrimaryButton } from '../../../components/Button/ActionButtons';
import ColoredHeader from '../../components/ColoredHeader';
import PageContent from '../../components/PageContent';
import DateFilter from '../../components/DateFilter';
import { ALL, DaysOptions } from '../../components/DateFilter/utils';
import CoachSelect from '../../components/Leads/components/CoachSelect';
import ConfirmDialog from '../../components/ConfirmDialog';
import ReusableContractModal from '../../components/ContractModal/Modals/ReusableContractModal';
import { getOnboardingLinkFromContract } from '../../utils/onboardingLink';
import { ContractStatus } from '../../utils/userContract';
import useToast from '../../hooks/useToast';

import {
  Container,
  Title,
  HeaderRow,
  ActionButtonsContainer,
  StyledLabelCheckbox,
  StyledContractName,
} from './styles';
import texts from './texts.json';

const ReusableContracts = () => {
  const [reusableContractsCollection, setReusableContractsCollection] = useState({ docs: [] });
  const [selectedDatePeriod, setSelectedDatePeriod] = useState(DaysOptions[0]);
  const [selectedCoach, setSelectedCoach] = useState('');
  const [contractToCancel, setContractToCancel] = useState(null);
  const [showCanceled, setShowCanceled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedContract, setSelectedContract] = useState();
  const [showContractModal, setShowContractModal] = useState(false);

  const isComponentMountedRef = useComponentMounted();

  const { showToast } = useToast();

  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      const loadContractsFromDate = selectedDatePeriod === ALL
        ? null
        : moment().subtract(selectedDatePeriod, 'days').format(DateFormat.DEFAULT_DATE_FORMAT);
      const contractsCollection = await UserContract.getBaseContracts(loadContractsFromDate);
      if (isComponentMountedRef.current) {
        setReusableContractsCollection(contractsCollection);
        setIsLoading(false);
      }
    };
    init();
  }, [
    isComponentMountedRef,
    selectedDatePeriod,
  ]);

  const filterContractsFunction = useCallback((contractDoc) => {
    const coachMatch = !selectedCoach || contractDoc.coach === selectedCoach;
    const statusMatch = (showCanceled && contractDoc.status === ContractStatus.CANCELED)
      || (!showCanceled && contractDoc.status !== ContractStatus.CANCELED);
    return coachMatch && statusMatch;
  }, [
    showCanceled,
    selectedCoach,
  ]);

  const handleContractCancel = useCallback(async () => {
    if (contractToCancel) {
      try {
        await contractToCancel.updateStatus(ContractStatus.CANCELED);
        setContractToCancel(null);
        showToast(texts.canceledSuccessfully);
      } catch (error) {
        const errorMessage = format(texts.cancelationFailed, { error: error.message });
        showToast(errorMessage, { error: true });
      }
    }
  }, [
    contractToCancel,
    showToast,
  ]);

  const onCloseContractModal = useCallback(() => {
    setShowContractModal(false);
    setSelectedContract(null);
  }, []);

  return (
    <Container>
      <ColoredHeader>
        <HeaderRow>
          <Title>{texts.reusableContracts}</Title>
          <PrimaryButton
            onClick={() => setShowContractModal(true)}
          >
            {texts.createReusableContract}
          </PrimaryButton>
        </HeaderRow>
        <HeaderRow>
          <CoachSelect
            onChange={(value) => setSelectedCoach(value?.id || '')}
            loadNewCoachDataOnChange={false}
          />
          <StyledLabelCheckbox
            description={texts.showCanceled}
            isChecked={showCanceled}
            onChange={() => setShowCanceled((prev) => !prev)}
          />
          <DateFilter
            selectedPeriod={selectedDatePeriod}
            onChange={setSelectedDatePeriod}
          />
        </HeaderRow>
      </ColoredHeader>
      <PageContent>
        <Table
          rows={reusableContractsCollection.docs.slice()}
          filterFunction={filterContractsFunction}
          loading={isLoading}
          columns={[
            {
              field: 'name',
              headerName: texts.headers.name,
              flex: 1,
              quickSearch: true,
              renderCell: ({ row }) => (
                <StyledContractName onClick={() => {
                  setSelectedContract(row);
                  setShowContractModal(true);
                }}
                >
                  {row.name}
                </StyledContractName>
              ),
            },
            {
              field: 'coachName',
              headerName: texts.headers.coachName,
              flex: 1,
              quickSearch: true,
            },
            {
              field: 'productName',
              headerName: texts.headers.productName,
              flex: 1,
              quickSearch: true,
            },
            {
              field: 'packageName',
              headerName: texts.headers.packageName,
              renderCell: ({ row: { packageName } }) => (
                packageName || texts.defaultCellValue
              ),
              flex: 1,
              quickSearch: true,
            },
            {
              field: 'initialPaymentAndTerm',
              headerName: texts.headers.initialPaymentAndTerm,
              renderCell: ({ row: { initialPaymentInCentsStr, initialTerm } }) => (
                format(texts.initialPaymentAndTerm, {
                  initialPaymentInCentsStr,
                  initialTerm,
                })
              ),
              flex: 1,
              disableColumnMenu: true,
            },
            {
              field: 'totalPriceInCentsStr',
              headerName: texts.headers.monthlySubscription,
              flex: 1,
            },
            {
              field: 'actions',
              headerName: texts.headers.contractAction,
              flex: 1,
              renderCell: ({ row }) => (
                <ActionButtonsContainer>
                  {row.status !== ContractStatus.CANCELED && (
                    <>
                      <CopyButton value={getOnboardingLinkFromContract(row.id)} />
                      <IconButton onClick={() => setContractToCancel(row)}>
                        <ClearIcon />
                      </IconButton>
                    </>
                  )}
                </ActionButtonsContainer>
              ),
            },
          ]}
        />
      </PageContent>
      <ConfirmDialog
        isOpen={!!contractToCancel}
        onConfirm={handleContractCancel}
        onCancel={() => setContractToCancel(null)}
        dialogTexts={{
          title: texts.deleteConfirm,
        }}
      />
      {!!showContractModal && (
        <ReusableContractModal
          showModal={showContractModal}
          selectedContract={selectedContract}
          onClose={onCloseContractModal}
        />
      )}
    </Container>
  );
};

export default compose(
  observer,
)(ReusableContracts);
