import styled from 'styled-components';
import {
  Box,
  Paper,
} from '@mui/material';

const Container = styled(Paper)`
  height: 120px;
  min-width: 220px;
  width: 180px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1em;
  margin: 25px 15px;
`;

const MainAnalyticsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MainAnalytics = styled(Box)`
  text-align: center;
  margin-top: 1px;
`;

export {
  Container,
  MainAnalyticsContainer,
  MainAnalytics,
};
