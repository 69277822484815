const feedOrderTypes = {
  SELF_CLAIMED: 'SELF_CLAIMED',
  SELF_ASSIGNED: 'SELF_ASSIGNED',
  UNASSIGNED: 'UNASSIGNED',
  ASSIGNED: 'ASSIGNED',
  CLAIMED: 'CLAIMED',
};

export {
  feedOrderTypes,
};
