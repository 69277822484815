import React from 'react';

import Sidebar from '../components/Sidebar';
import useInsideSalesSideBarConfig from './useInsideSalesSidebarConfig';

const InsideSalesSidebar = () => {
  const { entries } = useInsideSalesSideBarConfig();
  return (
    <Sidebar entries={entries} />
  );
};

export default InsideSalesSidebar;
