import React from 'react';
import PropTypes from 'prop-types';

import { formatDateForChannelPreview } from '../../util/timestampUtil';
import MessagingChannelProfilePic from '../MessagingChannelProfilePic';
import {
  ChannelDescription,
  ChannelName,
  ChannelPreviewContainer,
  IconContainer,
  InfoContainer,
  LastMessageContainer,
  LastMessageDate,
  LastMessageText,
  StyledChannelPopoverIcon,
  TextContainer,
  UnreadBadge,
  UnreadText,
} from './styles';

const ChannelPreview = ({
  onClick,
  image,
  lastMessage,
  unreadCount,
  name,
  isAdmin,
  isActive,
  shouldAllowActions,
  handleAction,
  isUnread,
  isOnline,
}) => (
  <ChannelPreviewContainer
    onClick={onClick}
    isActive={isActive}
    isAdmin={isAdmin}
  >
    <MessagingChannelProfilePic image={image} isOnline={isOnline} />
    <ChannelDescription>
      <TextContainer>
        <ChannelName>
          {name}
          {unreadCount > 0 && (
            <UnreadBadge>
              <UnreadText>{unreadCount}</UnreadText>
            </UnreadBadge>
          )}
          {isUnread && (
            <UnreadBadge />
          )}
        </ChannelName>
        <LastMessageContainer>
          <LastMessageText>
            {lastMessage.text}
          </LastMessageText>
        </LastMessageContainer>
      </TextContainer>
      <InfoContainer>
        {!!lastMessage.created_at && (
          <LastMessageDate isActive={isActive}>
            {formatDateForChannelPreview(lastMessage.created_at)}
          </LastMessageDate>
        )}
        {shouldAllowActions && (
          <IconContainer onClick={handleAction}>
            <StyledChannelPopoverIcon isAdmin={isAdmin} />
          </IconContainer>
        )}
      </InfoContainer>
    </ChannelDescription>
  </ChannelPreviewContainer>
);

ChannelPreview.propTypes = {
  onClick: PropTypes.func,
  image: PropTypes.string,
  lastMessage: PropTypes.shape({
    text: PropTypes.string,
    created_at: PropTypes.shape({}),
  }),
  unreadCount: PropTypes.number,
  name: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool,
  isActive: PropTypes.bool,
  shouldAllowActions: PropTypes.bool,
  handleAction: PropTypes.func,
  isUnread: PropTypes.bool,
  isOnline: PropTypes.bool,
};

ChannelPreview.defaultProps = {
  onClick: () => { },
  image: null,
  lastMessage: {},
  unreadCount: 0,
  isAdmin: false,
  isActive: false,
  shouldAllowActions: false,
  handleAction: () => { },
  isUnread: false,
  isOnline: false,
};

export default ChannelPreview;
