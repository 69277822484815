import styled from 'styled-components';

const StyledFeedContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 50%;
`;

export {
  StyledFeedContainer,
};
