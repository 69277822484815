import styled, { css } from 'styled-components';

import { mixins } from '../../styles/breakpoints';
import colors from '../../styles/colors';

import Card from '../Card';

const OnboardingCard = styled(Card)`
  display: flex;
  flex-direction: column;
  overflow: visible;
  min-height: fit-content;
  max-width: 450px;
  margin-top: 45px;
  box-shadow: none;
  width: 100%;
  ${mixins.tabletMedia(css`
    max-width: 400px;
  `)}
  background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : colors.base.alpha)};
  ${(props) => props.modal && 'z-index: 2;'};
`;

export default OnboardingCard;
