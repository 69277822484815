import React from 'react';
import PropTypes from 'prop-types';

import {
  Container,
  StyledEmptyView,
} from './styles';

const EmptyView = ({ text }) => (
  <Container>
    <StyledEmptyView>
      {text}
    </StyledEmptyView>
  </Container>
);

EmptyView.propTypes = {
  text: PropTypes.string,
};

EmptyView.defaultProps = {
  text: '',
};

export default EmptyView;
