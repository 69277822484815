import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import {
  withExternalCoachContextProvider,
} from '../../context/ExternalCoachContext';
import {
  withLeadsContextProvider,
} from '../../context/LeadsContext';
import ToolsLayout from '../ToolsLayout';
import InsideSalesSidebar from './InsideSalesSidebar';

const InsideSalesToolsLayout = ({
  children,
}) => (
  <ToolsLayout sidebarComponent={<InsideSalesSidebar />}>
    {children}
  </ToolsLayout>
);

InsideSalesToolsLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default compose(
  withExternalCoachContextProvider,
  withLeadsContextProvider,
)(InsideSalesToolsLayout);
