import styled, { css } from 'styled-components';
import {
  TextField,
} from '@mui/material';
import { IonSpinner } from '@ionic/react';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 100%;
  min-height: 50vh;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  column-gap: 8px;
`;

const baseTextCss = css`
  font-size: 14px;
  text-align: left;
`;

const MessageText = styled.div`
  ${baseTextCss};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;

const MessageDate = styled.div`
  ${baseTextCss};
  font-weight: bold;
`;

const ActionsContainer = styled.div`
  display: flex;
`;

const InfoContainer = styled.div`
  display: contents;
`;

const EditContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  width: 100%;
`;

const StyledTextField = styled(TextField)`
  margin: 10px 0;
`;

const InfoText = styled.div`
  ${baseTextCss};
  font-size: 18px;
  width: 100%;
  text-align: center;
`;

const StyledIonSpinner = styled(IonSpinner)`
  align-self: center;
  justify-self: center;
`;

export {
  Container,
  ItemContainer,
  MessageText,
  MessageDate,
  ActionsContainer,
  InfoContainer,
  EditContainer,
  StyledTextField,
  InfoText,
  StyledIonSpinner,
};
