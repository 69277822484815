import React, {
  useCallback,
  useState,
  useContext,
  useMemo,
} from 'react';
import { useRouteMatch } from 'react-router-dom';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import { forEachSeries } from 'p-iteration';
import format from 'string-template';
import PropTypes from 'prop-types';
import {
  FormGroup,
  Checkbox,
  FormControlLabel,
} from '@mui/material';

import { ReactComponent as FileHeartIcon } from '../../../../../assets/icons/file-heart-icon.svg';
import { ReactComponent as FileCheckIcon } from '../../../../../assets/icons/file-check-icon.svg';
import { ReactComponent as EditIcon } from '../../../../../assets/icons/edit_icon.svg';
import { SaveButton, CancelButton } from '../../../../../components/Button/ActionButtons';
import DialogRoundedModal from '../../../../../components/DialogRoundedModal';
import useComponentMounted from '../../../../../hooks/useComponentMounted';
import User from '../../../../../Model/User';
import ManageClientContext from '../../../../context/ManageClientContext';
import useToast from '../../../../hooks/useToast';
import ClientTag from '../../../../Model/Tag';
import ConfirmDialog from '../../../ConfirmDialog';
import LoadingOverlay from '../../../LoadingOverlay';
import TabPanel from '../../../TabPanel';

import { getClientTagInitialValues } from './utils';

import {
  ColumnContainer,
  RowContainer,
  StyledTabs,
  StyledTab,
  ModalContent,
  Container,
  Label,
  StyledInput,
  StyledUserIcon,
  OptionsContainer,
  StyledTextField,
  StyledCount,
  StyledDeleteOutlineOutlinedIcon,
  TagDataContainer,
  EditDeleteIconContainer,
} from './styles';

import texts from './texts.json';

const ClientTaggingModal = ({
  showModal,
  onClose,
  selectedClients,
  assignedTagsList,
}) => {
  const {
    params: {
      userId: coachId,
    },
  } = useRouteMatch();

  const {
    clientTagsDocs,
    clientDataList,
  } = useContext(ManageClientContext);

  const getClientList = useCallback((tagName) => {
    const clientList = clientDataList.filter((user) => user.clientTags.includes(tagName));
    return clientList.length;
  }, [
    clientDataList,
  ]);

  const clientTagsList = useMemo(() => (
    clientTagsDocs.map((doc) => ({
      id: doc.id,
      value: doc.tag,
      label: doc.tag,
      isS2tag: doc.isS2tag,
      clientCount: getClientList(doc.tag),
    }))
  ), [
    clientTagsDocs,
    getClientList,
  ]);

  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedTags, setSelectedTags] = useState(assignedTagsList);
  const [isLoading, setIsLoading] = useState(false);
  const [editableTag, setEditableTag] = useState(null);
  const [tagToDelete, setTagToDelete] = useState(null);

  const handleCheckboxChange = (event) => {
    const tagName = event.target.name;
    setSelectedTags((prevState) => {
      if (prevState.includes(tagName)) {
        return prevState.filter((tag) => tag !== tagName);
      }
      return [...prevState, tagName];
    });
  };

  const onTabChange = useCallback((_, value) => {
    setSelectedTab(value);
  }, []);

  const a11yProps = useCallback((index) => ({
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  }), []);

  const initialTagData = getClientTagInitialValues(coachId);

  const isComponentMountedRef = useComponentMounted();
  const [tagData, setTagData] = useState(initialTagData);

  const { showToast } = useToast();

  const setTagName = (event) => {
    setTagData((prevState) => ({
      ...prevState,
      tag: event.target.value,
    }));
  };

  const isValidTag = useCallback((tagName) => {
    const tagIsEmpty = tagName === '';
    const tagExists = clientTagsDocs.some((doc) => doc.tag.toLowerCase().trim() === tagName.toLowerCase().trim());

    if (tagIsEmpty) {
      showToast(texts.validation.required, { error: true });
      return false;
    }

    // Existing names are not allowed to be duplicated
    if (tagExists) {
      showToast(texts.validation.exists, { error: true });
      return false;
    }

    return true;
  }, [
    clientTagsDocs,
    showToast,
  ]);

  const createTag = useCallback(async () => {
    if (!isValidTag(tagData.tag)) {
      return;
    }

    setIsLoading(true);
    await ClientTag.addDoc(tagData);
    if (isComponentMountedRef.current) {
      showToast(texts.successfullyCreated);
      setIsLoading(false);
      onClose();
    }
  }, [
    tagData,
    showToast,
    isComponentMountedRef,
    onClose,
    isValidTag,
  ]);

  const assignTags = useCallback(async (selectedClientsList, selectedTagsList) => {
    await forEachSeries(selectedClientsList, async (userId) => {
      const doc = await User.getById(userId);
      const currentTagsList = doc.customTags;
      const updatedTagsList = Array.from(
        new Set(assignedTagsList.length > 0 ? selectedTagsList : currentTagsList.concat(selectedTagsList)),
      );
      await doc.ref.update({
        customTags: updatedTagsList,
      });
    });
  }, [
    assignedTagsList.length,
  ]);

  const updateTag = useCallback(async () => {
    const doc = clientTagsDocs.find((tagDoc) => tagDoc.id === editableTag.tagId);
    if (doc.tag !== editableTag.name && !isValidTag(editableTag.name)) {
      return;
    }
    setIsLoading(true);
    await doc.ref.update({
      tag: editableTag.name,
    });
    if (isComponentMountedRef.current) {
      setIsLoading(false);
      showToast(texts.tagUpdatedSuccessfully);
      setEditableTag(null);
    }
  }, [
    clientTagsDocs,
    isComponentMountedRef,
    isValidTag,
    showToast,
    editableTag,
  ]);

  const deleteTag = async () => {
    setIsLoading(true);
    const tagToDeleteDoc = clientTagsDocs.find((tagDoc) => tagDoc.id === tagToDelete.tagId);
    await tagToDeleteDoc.setRemoved(true);
    if (isComponentMountedRef.current) {
      showToast(texts.successfullyDeleted);
      setIsLoading(false);
      setTagToDelete(null);
    }
  };

  /**
   * Used to manage client tags.
   *
   * Depending on the selected tab, the execution functionality may differ:
   * 1. If we are in the assign tags tab,
   *    a. The function first checks if the coach has selected clients, and displays an error if there none selected.
   *    b. Then will execute the assign tags logic.
   * 2. If we are in the create tag tab, we will create a new tag.
   */
  const onHandleTags = async () => {
    if (selectedTab === 0) {
      setIsLoading(true);
      const isClientsSelected = selectedClients.length > 0;
      if (isClientsSelected) {
        await assignTags(selectedClients, selectedTags);
      }
      if (isComponentMountedRef.current) {
        setIsLoading(false);
        showToast(
          isClientsSelected ? texts.assignmentSuccessful : texts.emptySelection,
          { error: !isClientsSelected },
        );
      }
      onClose();
    } else {
      await createTag();
    }
  };

  const clientTagsOptions = () => clientTagsList.map(({
    id,
    value,
    label,
    isS2tag,
    clientCount,
  }) => (
    <OptionsContainer key={id}>
      { !!editableTag && editableTag.tagId === id ? (
        <StyledTextField
          value={editableTag.name}
          onChange={(e) => setEditableTag({ ...editableTag, name: e.target.value })}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              updateTag();
            }
          }}
        />
      ) : (
        <TagDataContainer>
          <FormControlLabel
            control={(
              <Checkbox
                checked={selectedTags.includes(value)}
                onChange={handleCheckboxChange}
                name={value}
              />
            )}
            label={label}
          />
          {clientCount > 0 && (
            <StyledCount>
              {clientCount === 1 ? `(${clientCount} Client)` : `(${clientCount} Clients)`}
            </StyledCount>
          )}
        </TagDataContainer>
      )}
      <EditDeleteIconContainer>
        { (!isS2tag && !editableTag) && (
        <EditIcon key={id} onClick={() => setEditableTag({ tagId: id, name: value })} />
        )}
        { (!isS2tag && !tagToDelete) && (
        <StyledDeleteOutlineOutlinedIcon
          onClick={() => setTagToDelete({ tagId: id, tag: label, assignedClientCount: clientCount })}
        />
        )}
      </EditDeleteIconContainer>
    </OptionsContainer>
  ));

  return (
    <>
      <DialogRoundedModal
        title={texts.title}
        IconComponent={<StyledUserIcon />}
        description={texts.description}
        fullWidth
        actionButtons={(
          <ColumnContainer>
            <RowContainer>
              <SaveButton
                disabled={isLoading
                  || (selectedTab === 0 && selectedTags.length === 0 && assignedTagsList.length === 0)}
                onClick={onHandleTags}
              >
                {texts.save}
              </SaveButton>
              <CancelButton onClick={onClose}>{texts.cancel}</CancelButton>
            </RowContainer>
          </ColumnContainer>
        )}
        open={showModal}
        onClose={onClose}
      >
        <StyledTabs value={selectedTab} onChange={onTabChange}>
          <StyledTab label={texts.selectTag} icon={<FileHeartIcon />} {...a11yProps(0)} />
          <StyledTab label={texts.createTag} icon={<FileCheckIcon />} {...a11yProps(1)} />
        </StyledTabs>
        <ModalContent>
          <TabPanel
            index={0}
            value={selectedTab}
          >
            <FormGroup>
              {clientTagsOptions()}
            </FormGroup>
          </TabPanel>
          <TabPanel
            index={1}
            value={selectedTab}
          >
            <Container>
              <Label>{`${texts.tagName}:`}</Label>
              <StyledInput
                variant="outlined"
                value={tagData.tag}
                onChange={setTagName}
              />
            </Container>
          </TabPanel>
        </ModalContent>
        <LoadingOverlay isLoading={isLoading} />
      </DialogRoundedModal>
      <ConfirmDialog
        isOpen={!!tagToDelete}
        onConfirm={deleteTag}
        onCancel={() => setTagToDelete(null)}
        dialogTexts={{
          title: '',
          content: format(texts.deleteConfirm, tagToDelete
            ? { tagName: tagToDelete.tag, clientCount: tagToDelete.assignedClientCount } : ''),
        }}
      />
    </>
  );
};

ClientTaggingModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedClients: PropTypes.array.isRequired,
  assignedTagsList: PropTypes.array,
};

ClientTaggingModal.defaultProps = {
  assignedTagsList: [],
};

export default compose(
  observer,
)(ClientTaggingModal);
