import React from 'react';

const initialValues = {
  isReady: false,
  coachesCollection: { docs: [] },
  coachesByRole: {},
};

const CoachesListContext = React.createContext(initialValues);

export default CoachesListContext;
export {
  initialValues,
};
