import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import { Route, Redirect, useLocation } from 'react-router-dom';

import useSessionStore from '../../hooks/useSessionStore';

/**
 * A wrapper for <Route> that redirects to the login
 * page if you're not yet authenticated or you're not an admin user.
 */
const AdminCoachRoute = ({
  component: Component,
  children,
  ...rest
}) => {
  const { isCoachOrAdmin } = useSessionStore();
  const location = useLocation();
  const { search, state } = location;
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isCoachOrAdmin) {
          return Component ? <Component {...props} /> : children;
        }

        // Redirect to the login page
        return (
          <Redirect to={{
            pathname: '/login',
            search,
            state,
          }}
          />
        );
      }}
    />
  );
};

AdminCoachRoute.propTypes = {
  component: PropTypes.elementType,
  children: PropTypes.node,
};

AdminCoachRoute.defaultProps = {
  children: null,
  component: null,
};

export default compose(
  observer,
)(AdminCoachRoute);
