const WELCOME_MESSAGE_PLACEHOLDER = {
  COACH_NAME: 'coachName',
  USER_NAME: 'userName',
};

const MEAL_PLAN_INTRODUCTION_PLACEHOLDER = {
  CLIENT_NAME: 'clientName',
};

export {
  MEAL_PLAN_INTRODUCTION_PLACEHOLDER,
  WELCOME_MESSAGE_PLACEHOLDER,
};
