import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import { Close as CloseIcon } from '@mui/icons-material';
import {
  Dialog,
  DialogContent,
  Paper,
} from '@mui/material';

import {
  StyledIconButton,
  StyledIframe,
  paperStyles,
} from './styles';

const MobileViewModal = ({
  onClose,
  showModal,
  mobileUrl,
}) => (
  <Dialog
    open={showModal}
    PaperComponent={Paper}
    onBackdropClick={onClose}
    PaperProps={{
      style: paperStyles,
    }}
  >
    <DialogContent>
      <StyledIconButton onClick={onClose}>
        <CloseIcon />
      </StyledIconButton>
      <StyledIframe
        url={mobileUrl}
      />
    </DialogContent>
  </Dialog>
);

MobileViewModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  mobileUrl: PropTypes.string.isRequired,
};

export default compose(
  observer,
)(MobileViewModal);
