import React, {
  useCallback,
  useState,
  useRef,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '@mui/material';

import defaultImage from '../../../assets/noImage.svg';

import {
  ImageSliderContainer,
  ImageCard,
  StyledImage,
  StyledSlider,
  Title,
  StyledChip,
} from './styles';

const ImageSlider = ({
  resizedImageList,
  imageList,
  title,
  label,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(false);
  const [height, setHeight] = useState(0);
  const divRef = useRef(null);

  // This is the most common aspect ratio in the check-in images
  // we have in db so far
  const ASPECT_RATIO = 0.75;

  const handleImageClick = (image) => {
    setIsOpen(true);
    setCurrentImage(image);
  };

  // This adjust the height of the div based on its width dynamically
  useEffect(() => {
    const updateHeight = () => {
      let divWidth;
      let divHeight;
      if (divRef.current) {
        ({ width: divWidth } = divRef.current.getBoundingClientRect());
        divHeight = divWidth / ASPECT_RATIO;
        setHeight(divHeight);
      }
    };

    updateHeight();
    window.addEventListener('resize', updateHeight);

    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, [
    divRef,
  ]);

  // TODO: add srcset to images to control the size of the images
  const getImageSlides = useCallback(() => {
    if (imageList.length === 0) {
      return <StyledImage src={defaultImage} />;
    }
    return imageList.map((imageUrl, index) => (
      <ImageCard key={imageUrl}>
        <StyledImage
          onClick={() => handleImageClick(imageUrl)}
          src={resizedImageList[index] || imageUrl}
          $height={height}
        />
        {label && <StyledChip variant="filled" label={label} />}
        <Dialog
          open={isOpen}
          onClose={() => setIsOpen(false)}
          onBackdropClick={() => setIsOpen(false)}
        >
          <StyledImage src={currentImage} />
        </Dialog>
      </ImageCard>
    ));
  }, [
    imageList,
    resizedImageList,
    isOpen,
    currentImage,
    label,
    height,
  ]);

  return (
    <ImageSliderContainer ref={divRef} $height={height}>
      {title && (
        <Title>{title}</Title>
      )}
      <StyledSlider>
        {getImageSlides()}
      </StyledSlider>
    </ImageSliderContainer>
  );
};

ImageSlider.propTypes = {
  imageList: PropTypes.arrayOf(PropTypes.string),
  resizedImageList: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
  label: PropTypes.string,
};

ImageSlider.defaultProps = {
  resizedImageList: [],
  imageList: [],
  title: '',
  label: '',
};

export default ImageSlider;
