import React, { useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
} from '@mui/material';
import { StyledButtonGroup, StyledPopper } from './styles';

const SplitButton = ({
  options,
  onClick,
  initialSelectedIndex,
}) => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(initialSelectedIndex);

  const onClickAway = useMemo(() => (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  }, [anchorRef]);

  return (
    <>
      <StyledButtonGroup
        variant="contained"
        ref={anchorRef}
      >
        <Button onClick={() => onClick(selectedIndex, options[selectedIndex].key)}>
          {options[selectedIndex]?.label}
        </Button>
        <Button onClick={() => setOpen((prevOpen) => !prevOpen)}>
          <ArrowDropDownIcon />
        </Button>
      </StyledButtonGroup>
      <StyledPopper
        open={open}
        anchorEl={anchorRef.current}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={onClickAway}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option.key}
                      selected={index === selectedIndex}
                      onClick={() => {
                        setSelectedIndex(index);
                        setOpen(false);
                      }}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </StyledPopper>
    </>
  );
};

SplitButton.propTypes = {
  options: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  initialSelectedIndex: PropTypes.number,
};

SplitButton.defaultProps = {
  initialSelectedIndex: 0,
};

export default SplitButton;
