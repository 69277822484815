/**
 * ContractPriceType enum for the contract modal.
 * We keep this here instead of the Price model until we standardize the new price model. For now, this only
 * drives the Contract modal buttons in the toolbar.
 */
const ContractPriceType = {
  STANDARD: 'STANDARD',
  CUSTOM: 'CUSTOM',
};

/**
 * The contarct generator has two states: CONFIGURATION and DETAILS. The configuration state is used for
 * setting all the parameters of the contract. The details state is used for displaying the contract details once
 * the contract has been generated.
 */
const ContractState = {
  CONFIGURATION: 'CONFIGURATION',
  DETAILS: 'DETAILS',
};

export {
  ContractPriceType,
  ContractState,
};
