import { feedOrderTypes } from '../../../../../utils/feed';

const texts = {
  feedLabel: {
    [feedOrderTypes.SELF_CLAIMED]: 'Claimed by you',
    [feedOrderTypes.SELF_ASSIGNED]: 'Last interacted by you',
    [feedOrderTypes.UNASSIGNED]: 'New user',
    [feedOrderTypes.ASSIGNED]: 'Last interacted by {user}',
    [feedOrderTypes.CLAIMED]: 'Claimed by {user}',
  },
};

export default texts;
