import styled from 'styled-components';
import { Grid } from '@mui/material';

const Container = styled(Grid)`
  padding: 20px;
`;

export {
  Container,
};
