import React, {
  useState,
  useEffect,
  useMemo,
  useContext,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import { autorun } from 'mobx';

import UserContext from '../../../context/UserContext';
import Program from '../../../Model/Program';
import useComponentMounted from '../../../hooks/useComponentMounted';
import ManageProgramContext, { initialValues } from './ManageProgramContext';

const ManageProgramContextProvider = ({
  children,
}) => {
  const [coachProgramsCollection, setCoachProgramsCollection] = useState(initialValues.coachProgramsCollection);
  const [coachPrograms, setCoachPrograms] = useState(initialValues.coachPrograms);
  const [showArchivedCoachPrograms, setShowArchivedCoachPrograms] = useState(initialValues.showArchivedCoachPrograms);
  const [equipmentList, setEquipmentList] = useState(initialValues.equipmentList);
  const [isReady, setIsReady] = useState(initialValues.isReady);

  const isComponentMountedRef = useComponentMounted();

  const { userId: coachId } = useContext(UserContext);

  // Load the program collection related to the coach.
  useEffect(() => {
    const init = async () => {
      const programsCol = await Program.getProgramsByCoach(coachId);
      if (isComponentMountedRef.current) {
        setCoachProgramsCollection(programsCol);
        setIsReady(true);
      }
    };

    if (coachId) {
      init();
    }
  }, [
    isComponentMountedRef,
    coachId,
  ]);

  useEffect(() => {
    const disposer = autorun(() => {
      const coachProgramsDocs = coachProgramsCollection.hasDocs ? coachProgramsCollection.docs.slice() : [];
      const uniqueEquipmentMap = new Map();
      if (coachProgramsCollection.hasDocs) {
        coachProgramsCollection.docs.forEach((program) => {
          if (program.equipment.length > 0) {
            program.equipment.forEach((equipment) => {
              if (!uniqueEquipmentMap.has(equipment)) {
                uniqueEquipmentMap.set(equipment, {
                  value: equipment,
                  label: equipment,
                });
              }
            });
          }
        });
      }
      const uniqueEquipmentList = Array.from(uniqueEquipmentMap.values());
      setEquipmentList(uniqueEquipmentList);
      setCoachPrograms(coachProgramsDocs);
    });
    return disposer;
  }, [
    coachProgramsCollection,
    isComponentMountedRef,
  ]);

  const refetchCoachPrograms = useCallback(async (fetchArchivedPrograms = false) => {
    setShowArchivedCoachPrograms(fetchArchivedPrograms);
    const programsCol = await Program.getProgramsByCoach(coachId, fetchArchivedPrograms);
    if (isComponentMountedRef.current) {
      setCoachProgramsCollection(programsCol);
    }
  }, [
    coachId,
    isComponentMountedRef,
  ]);

  const value = useMemo(() => ({
    coachProgramsCollection,
    isReady,
    coachPrograms,
    showArchivedCoachPrograms,
    refetchCoachPrograms,
    equipmentList,
  }), [
    coachProgramsCollection,
    isReady,
    coachPrograms,
    showArchivedCoachPrograms,
    refetchCoachPrograms,
    equipmentList,
  ]);

  return (
    <ManageProgramContext.Provider value={value}>
      {children}
    </ManageProgramContext.Provider>
  );
};

ManageProgramContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default compose(
  observer,
)(ManageProgramContextProvider);
