import React, {
  useContext,
  useEffect,
} from 'react';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';

import ContractModalContext from '../../context/ContractModalContext';
import UserInfoSection from '../../UserInfoSection/UserInfoSection';
import ContractModalFooter from '../../ContractModalFooter';

import ContractEditor from '../../ContractEditor';
import {
  Container,
  Header,
} from './styles';

const LeadContractModalContent = ({
  lead,
  coachId,
}) => {
  const {
    isReadOnly,
    setCoachId,
    loadContractByLead,
  } = useContext(ContractModalContext);

  useEffect(() => {
    if (coachId) {
      setCoachId(coachId);
    }
  }, [
    coachId,
    setCoachId,
  ]);

  useEffect(() => {
    if (lead?.id) {
      loadContractByLead(lead);
    }
  }, [
    lead,
    loadContractByLead,
  ]);

  return (
    <Container>
      <Header>
        <UserInfoSection userData={lead} />
      </Header>
      <ContractEditor />
      {!isReadOnly && (
        <ContractModalFooter />
      )}
    </Container>
  );
};

LeadContractModalContent.propTypes = {
  lead: PropTypes.object.isRequired,
  coachId: PropTypes.string.isRequired,
};

export default compose(
  observer,
)(LeadContractModalContent);
