import React, {
  useState,
  useMemo,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import useComponentMounted from '../../../hooks/useComponentMounted';
import PayoutReconciliationContext, { initialValues } from './PayoutReconciliationContext';

const PayoutReconciliationContextProvider = ({
  children,
}) => {
  const [isLoadingDocs, setIsLoadingDocs] = useState(initialValues.isLoadingDocs);
  const [payoutReconciliationCollection, setPayoutReconciliationCollection] = useState(
    initialValues.payoutReconciliationCollection,
  );

  const isComponentMountedRef = useComponentMounted();

  /**
   * Load payoutReconciliationCollection from server. This function calls the model API so the params are the same.
   * @param {function} payoutFunctionRef The function reference to call the model API
   * @param {Array<string>} userIds A list of user ids to get the payout reconciliation documents
   * @param {string} type The type of the user id (CRX, INSISE_SALES, etc)
   * @param {string} fromDate The initial date to get the payout reconciliation (DateFormat.DEFAULT_DATE_FORMAT)
   * @param {string} toDate The final date to get the payout reconciliation (DateFormat.DEFAULT_DATE_FORMAT)
   */
  const loadPayoutReconciliationData = useCallback(async (payoutFunctionRef, userIds, type, fromDate, toDate) => {
    setIsLoadingDocs(true);
    const payoutReconciliationCol = await payoutFunctionRef(userIds, type, fromDate, toDate);
    if (isComponentMountedRef.current) {
      setIsLoadingDocs(false);
      setPayoutReconciliationCollection(payoutReconciliationCol);
    }
  }, [isComponentMountedRef]);

  const clearData = useCallback(() => {
    setPayoutReconciliationCollection(initialValues.payoutReconciliationCollection);
  }, []);

  const value = useMemo(() => ({
    payoutReconciliationCollection,
    isLoadingDocs,
    loadPayoutReconciliationData,
    clearData,
  }), [
    payoutReconciliationCollection,
    isLoadingDocs,
    loadPayoutReconciliationData,
    clearData,
  ]);

  return (
    <PayoutReconciliationContext.Provider value={value}>
      {children}
    </PayoutReconciliationContext.Provider>
  );
};

PayoutReconciliationContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PayoutReconciliationContextProvider;
