import React, {
  useContext,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { ImageDropzone, FileUploadButton } from 'react-file-utils';
import {
  useChannelStateContext,
  useMessageInputContext,
  ChatAutoComplete,
  useChannelActionContext,
} from 'stream-chat-react';
import { CircularProgress, IconButton } from '@mui/material';
import { RefreshOutlined } from '@mui/icons-material';
import format from 'string-template';

import ChatContext from '../../../../context/ChatContext';
import TimeCounter from '../../../../../components/TimeCounter';

import DraftMessage from '../../../../Model/DraftMessage';
import useSessionStore from '../../../../../hooks/useSessionStore';
import useLogger from '../../../../../hooks/useLogger';
import useMediaRecorder from '../../hooks/useMediaRecorder';
import { MAX_FILE_UPLOAD_SIZE } from '../../util/fileUpload';
import EmojiInput from '../EmojiInput';
import UploadsPreview from './UploadsPreview';
import {
  MessagingInputContainer,
  StyledSendIcon,
  StyledAttachmentIcon,
  SmartResponseContainer,
  SmartResponse,
  StyledMicIcon,
  StyledStopIcon,
  RecordingView,
  RecordingLabel,
  Container,
  Title,
  StyledArrowIcon,
  StyledS2Icon,
  ReplyMessageContainer,
  ReplyMessage,
  StyledCloseIcon,
  ReplyDiscription,
  ReplyMessageContent,
} from './styles';
import './MessagingInput.css';
import texts from './texts.json';

const unsupportedAudioFormats = [
  'audio/x-m4a',
];

const MessagingInput = () => {
  const { logEvent } = useLogger();
  const { isCoachAssistant } = useSessionStore();

  const {
    acceptedFiles,
    maxNumberOfFiles,
    multipleUploads,
    channel,
    quotedMessage,
  } = useChannelStateContext();

  const {
    readOnlyMode,
    isMultiChannelView,
    isSmartResponseOpen,
    setIsSmartResponseOpen,
  } = useContext(ChatContext);

  const messageInputProps = useMessageInputContext();

  const { addNotification, setQuotedMessage } = useChannelActionContext();
  const [isInitialised, setIsInitialised] = useState(false);
  const [draftMessageDoc, setDraftMessageDoc] = useState();

  const {
    uploadNewFiles,
    numberOfUploads,
    handleSubmit,
    textareaRef,
    suggestionList,
    handleChange,
    isLoading,
    loadSuggestions,
  } = messageInputProps;

  const onAudioDataAvailable = useCallback((audioFile) => {
    const file = new File(
      [audioFile.data],
      `Voice-Recording-${new Date().toTimeString().split(' ')[0]}.${audioFile.data.type.split('/')[1]}`,
      {
        type: audioFile.data.type,
        lastModified: Date.now(),
      },
    );
    uploadNewFiles([file]);
  }, [uploadNewFiles]);

  const {
    isRecording,
    startRecording,
    stopRecording,
  } = useMediaRecorder(onAudioDataAvailable);

  useEffect(() => {
    const init = async () => {
      const userDraftMessageDoc = await DraftMessage.getById(channel.data.id);
      if (!userDraftMessageDoc) {
        const newDraftMessageDoc = new DraftMessage(channel.data.id);
        await newDraftMessageDoc.set({
          message: '',
        });
        setDraftMessageDoc(newDraftMessageDoc);
      } else {
        setDraftMessageDoc(userDraftMessageDoc);
      }
      const nativeTextAreaValueSetter = Object
        .getOwnPropertyDescriptor(window.HTMLTextAreaElement.prototype, 'value')
        .set;
      nativeTextAreaValueSetter.call(textareaRef.current, (userDraftMessageDoc?.message || ''));
      const event = new Event('input', { bubbles: true });
      textareaRef.current.dispatchEvent(event);
      textareaRef.current.focus();
    };

    init();
  }, [
    textareaRef,
    channel,
  ]);

  const onSubmit = useCallback((event) => {
    if (draftMessageDoc) {
      draftMessageDoc.ref.update({
        message: '',
      });
    }

    if (isMultiChannelView) {
      const sentSuggestion = suggestionList
        ?.find((suggestion) => textareaRef.current.value
          .toLowerCase()
          .trim()
          .includes(suggestion.message.toLowerCase().trim()));
      if (sentSuggestion) {
        logEvent('chatSuggestionSent', {
          suggestionId: sentSuggestion.id,
          suggestion: sentSuggestion.message,
          textSent: textareaRef.current.value,
        });
      }
    }
    textareaRef.current.style.height = 'inherit';
    loadSuggestions();
    handleSubmit(event);
  }, [
    logEvent,
    suggestionList,
    textareaRef,
    handleSubmit,
    isMultiChannelView,
    draftMessageDoc,
    loadSuggestions,
  ]);

  const changeChatInputValue = useCallback((editedTextValue, replace = true) => {
    const nativeTextAreaValueSetter = Object
      .getOwnPropertyDescriptor(window.HTMLTextAreaElement.prototype, 'value')
      .set;

    let editedText = editedTextValue;
    if (!replace) {
      editedText = `${textareaRef.current.value} ${editedTextValue}`;
    }
    nativeTextAreaValueSetter.call(textareaRef.current, editedText);
    const event = new Event('input', { bubbles: true });
    textareaRef.current.dispatchEvent(event);
    textareaRef.current.focus();
  }, [
    textareaRef,
  ]);

  const handleFileUpload = useCallback((fileList) => {
    // Stream supports any file type and a maximum size of 20MB
    const validFiles = Object.values(fileList).every((file) => file.size < MAX_FILE_UPLOAD_SIZE);
    if (validFiles) {
      const finalFileList = Object.values(fileList).map((file) => {
        if (unsupportedAudioFormats.includes(file.type)) {
          return new File([file], `${file.name}`, {
            type: 'video/mp4',
            lastModified: Date.now(),
          });
        }
        return file;
      });
      uploadNewFiles(finalFileList);
    } else {
      const errorMessage = format(texts.notification.message, {
        sizeLimit: MAX_FILE_UPLOAD_SIZE / 1000000, // Bytes to MB
      });
      addNotification(errorMessage, 'error');
    }
  }, [uploadNewFiles, addNotification]);

  // here we dynamically change the height of the text-area as the text grows until it reaches max-height
  const handleChangeOnInput = useCallback((event) => {
    if (event.type === 'change') {
      handleChange(event);
    }

    if (draftMessageDoc) {
      draftMessageDoc.ref.update({
        message: event.target.value,
      });
    }

    textareaRef.current.style.height = 'inherit';
    textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
  }, [
    textareaRef,
    handleChange,
    draftMessageDoc,
  ]);

  /* If voice message recording starts render the timer along with recording label else
   show the normal text input with attachment and voice record buttons */
  const renderMessageInputComponent = useCallback(() => {
    if (!isRecording) {
      return (
        <div className="messaging-input__input-wrapper">
          <ChatAutoComplete
            onChange={handleChangeOnInput}
            onPaste={handleChangeOnInput}
            handleSubmit={onSubmit}
            placeholder={texts.chatInputPlaceholder}
          />
          <FileUploadButton handleFiles={handleFileUpload}>
            <StyledAttachmentIcon />
          </FileUploadButton>
          <StyledMicIcon onClick={startRecording} />
          <EmojiInput onEmojiClick={(e) => changeChatInputValue(e.emoji, false)} />
        </div>
      );
    }

    return (
      <RecordingView>
        <RecordingLabel>
          {texts.recordingLabel}
          <TimeCounter run />
        </RecordingLabel>
        <StyledStopIcon onClick={stopRecording} />
      </RecordingView>
    );
  }, [
    isRecording,
    handleFileUpload,
    startRecording,
    stopRecording,
    onSubmit,
    handleChangeOnInput,
    changeChatInputValue,
  ]);

  useEffect(() => {
    if (!isInitialised) {
      loadSuggestions();
      setIsInitialised(true);
    }
  }, [
    loadSuggestions,
    isInitialised,
  ]);

  if (readOnlyMode) {
    return null;
  }

  return (
    <>
      {quotedMessage && (
        <ReplyMessageContainer>
          <ReplyDiscription>{texts.replyingTo}</ReplyDiscription>
          <ReplyMessageContent>
            <ReplyMessage>
              {quotedMessage.text}
            </ReplyMessage>
            <StyledCloseIcon onClick={() => setQuotedMessage()} />
          </ReplyMessageContent>
        </ReplyMessageContainer>
      )}
      <MessagingInputContainer>
        {isCoachAssistant && (
          <Container>
            <Title>
              <StyledS2Icon />
              {texts.smartResponses}
              {isSmartResponseOpen && (
                <IconButton onClick={() => loadSuggestions()} size="medium">
                  <RefreshOutlined fontSize="small" />
                </IconButton>
              )}
              <StyledArrowIcon
                $isOpen={isSmartResponseOpen}
                onClick={() => setIsSmartResponseOpen((prev) => !prev)}
              />
            </Title>
            {isSmartResponseOpen && (
              <SmartResponseContainer $isLoading={isLoading}>
                {!isLoading ? (
                  suggestionList.map((suggestion) => (
                    <SmartResponse
                      key={suggestion.id}
                      onClick={() => changeChatInputValue(suggestion.message)}
                    >
                      {suggestion.message}
                    </SmartResponse>
                  ))
                ) : <CircularProgress color="info" />}
              </SmartResponseContainer>
            )}
          </Container>
        )}
        <div className="str-chat__messaging-input">
          <ImageDropzone
            accept={acceptedFiles}
            handleFiles={handleFileUpload}
            multiple={multipleUploads}
            disabled={(maxNumberOfFiles !== undefined && numberOfUploads >= maxNumberOfFiles)}
          >
            <UploadsPreview {...messageInputProps} />
            {renderMessageInputComponent()}
          </ImageDropzone>
          <div
            className="messaging-input__button"
            role="presentation"
            aria-roledescription="button"
            onClick={onSubmit}
          >
            <StyledSendIcon />
          </div>
        </div>
      </MessagingInputContainer>
    </>
  );
};

export default MessagingInput;
