import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import useLogger from '../../../../../hooks/useLogger';
import { CustomSuggestionItemComponent } from './styles';

const CustomSuggestionItem = ({ entity }) => {
  const { logEvent } = useLogger();

  const onSuggestionItemClicked = useCallback(() => {
    logEvent('chatSuggestionItemClicked', {
      suggestionId: entity.id,
    });
  }, [entity.id, logEvent]);

  return (
    <CustomSuggestionItemComponent onClick={onSuggestionItemClicked}>
      {entity.name}
    </CustomSuggestionItemComponent>
  );
};

CustomSuggestionItem.propTypes = {
  entity: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
};

export default CustomSuggestionItem;
