import styled from 'styled-components';

import { ReactComponent as Picture } from '../../../../../../assets/icons/v2/picture.svg';
import FileSelectButton from '../../../../../../components/FileSelectButton';

import colors from '../../../../../../styles/colors';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  position: relative;
`;

const ButtonContainer = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledFileSelectButton = styled(FileSelectButton)`
  margin: 15px 0;
  text-transform: none;
  font-size: 10px;
  font-weight: 400;
  background-color: ${colors.base.secondary3};
  box-shadow: none;
  border-radius: 50px;
  
  &:hover {
    background-color: ${colors.shades.secondary4};
    box-shadow: none;
  }
`;

const StyledPictureIcon = styled(Picture)`
  margin-right: 5px;
`;

const ImageContainer = styled.div`
  background-color: ${colors.shades.secondary5};
  border: 1px solid ${colors.shades.secondary7};
  border-radius: 5px;
  width: 100%;
  aspect-ratio: 1/1;
  position: relative;
`;

const RecipeImage = styled.img`
  object-fit: cover;
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 5px;
  vertical-align: top;

  &[alt]:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background-color: ${colors.shades.secondary5};
    content: '';
  }
`;

export {
  Container,
  ImageContainer,
  ButtonContainer,
  StyledPictureIcon,
  StyledFileSelectButton,
  RecipeImage,
};
