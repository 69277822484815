import { ButtonGroup, Popper } from '@mui/material';
import styled from 'styled-components';

const StyledButtonGroup = styled(ButtonGroup)`
  height: 40px;
`;

const StyledPopper = styled(Popper)`
  z-index: 1;
`;

export {
  StyledButtonGroup,
  StyledPopper,
};
