import format from 'string-template';
import config from '../../config';

const StripeConfig = {
  CONNECT_URL: 'https://connect.stripe.com/oauth/authorize',
  DASHBOARD_URL: 'https://dashboard.stripe.com/{accountId}',
  CUSTOMER_URL: 'https://dashboard.stripe.com/{accountId}/customers/{customerId}',
  REDIRECT_URI: `${config.webAppURL}/stripe-cb`,
  CLIENT_ID: config.stripe.clientId,
};

const getStripeConnectLink = (userId) => {
  const stripeLink = new URL(StripeConfig.CONNECT_URL);

  // Params needed by the connect url.
  const paramsConfig = {
    redirect_uri: StripeConfig.REDIRECT_URI,
    client_id: StripeConfig.CLIENT_ID,
    response_type: 'code',
    scope: 'read_write',
    state: userId,
  };

  Object.entries(paramsConfig).forEach(([key, value]) => stripeLink.searchParams.append(key, value));

  return stripeLink.toString();
};

const openDashboardInNewTab = (accountId = '') => {
  const windowName = 'stripeDashboard';
  const windowFeatures = 'noreferrer=true,noopener=true';
  window.open(format(StripeConfig.DASHBOARD_URL, { accountId }), windowName, windowFeatures);
};

const openStripeCustomer = (accountId = '', customerId = '') => {
  const windowName = 'stripeDashboard';
  const windowFeatures = 'noreferrer=true,noopener=true';
  window.open(format(StripeConfig.CUSTOMER_URL, { accountId, customerId }), windowName, windowFeatures);
};

export {
  getStripeConnectLink,
  openDashboardInNewTab,
  openStripeCustomer,
};
