const UnitConversion = {
  POUNDS_PER_KILOGRAM: 2.20462,
  CENTIMETERS_PER_INCH: 2.54,
};

const WeightUnit = {
  POUNDS: 'POUNDS',
  KILOGRAMS: 'KILOGRAMS',
};

const LengthUnit = {
  INCHES: 'INCHES',
  CENTIMETERS: 'CENTIMETERS',
};

export {
  WeightUnit,
  LengthUnit,
  UnitConversion,
};
