import { Collection } from 'firestorter';
import chunk from 'lodash.chunk';

import BaseDocument from '../../Model/BaseDocument';
import { firestorePaths } from '../../utils/firebasePaths';
import { formatCurrency } from '../../utils/formatters';

const FIRESTORE_MAX_ARRAY_SIZE = 10;

const IdType = {
  CRX: 'CRX',
  SELLER: 'SELLER',
};

const QueryConfigByIdType = {
  [IdType.CRX]: {
    field: 'assignments.CRX',
    operator: 'array-contains-any',
  },
  [IdType.SELLER]: {
    field: 'sellerId',
    operator: 'in',
  },
};

class PayoutReconciliation extends BaseDocument {
  constructor(id, opts) {
    super(`${firestorePaths.STRIPE_PAYOUT_RECONCILIATION}/${id}`, opts);
  }

  get coach() {
    return this.data.coach;
  }

  get coachName() {
    return this.data.coachName;
  }

  get user() {
    return this.data.user;
  }

  get userName() {
    return this.data.userName;
  }

  get startDate() {
    return this.data.startDate || '';
  }

  get sellerId() {
    return this.data.sellerId;
  }

  get createdAt() {
    return this.data.createdAt;
  }

  get payoutDate() {
    return this.data.payoutDate;
  }

  get stripePayoutReconciliation() {
    return this.data.stripePayoutReconciliation;
  }

  get commissions() {
    return this.data.commissions || {};
  }

  get insideSalesCommission() {
    return this.commissions.insideSalesCommission || 0;
  }

  get insideSalesCommissionWithCurrency() {
    return formatCurrency(this.insideSalesCommission, this.currency, { minimumFractionDigits: 2 });
  }

  get crxCommission() {
    return this.commissions.crxCommission || 0;
  }

  get crxCommissionWithCurrency() {
    return formatCurrency(this.crxCommission, this.currency, { minimumFractionDigits: 2 });
  }

  get reportingCategory() {
    return this.stripePayoutReconciliation.reporting_category;
  }

  get netAmount() {
    return this.stripePayoutReconciliation.net;
  }

  get currency() {
    return this.stripePayoutReconciliation.currency;
  }

  get connectedAccountDirectChargeId() {
    return this.stripePayoutReconciliation.connected_account_direct_charge_id;
  }

  get netAmountWithCurrency() {
    return formatCurrency(this.netAmount, this.currency, { minimumFractionDigits: 2 });
  }

  get commissionsParams() {
    return this.data.commissionsParams || {};
  }

  get insideSalesFeeValue() {
    return this.commissionsParams.insideSalesFeeValue;
  }

  get totalFeesPercentage() {
    return this.commissionsParams.totalFeesPercentage;
  }

  get totalCommissionableFeePercentage() {
    return this.commissionsParams.totalCommissionableFeePercentage;
  }

  get commissionableFeeAmount() {
    return this.commissionsParams.commissionableFeeAmount;
  }

  get numberOfCRXs() {
    return this.commissionsParams.numberOfCRXs;
  }

  get feesConfiguration() {
    return this.data.feesConfiguration || {};
  }

  get feesConfigurationString() {
    return Object.entries(this.feesConfiguration)
      .map(([feeName, config]) => `${feeName}: ${config.value}%`)
      .join('\n');
  }

  get contractId() {
    return this.data.contractId;
  }

  get recurringSubscription() {
    return this.data.recurringSubscription;
  }

  /**
   * Get all payout reconciliation for a given seller
   * @param {string} sellerId The seller id
   * @param {string} fromDateString The initial date to get the payout reconciliation (DateFormat.DEFAULT_DATE_FORMAT)
   * @param {string} toDateString The final date to get the payout reconciliation (DateFormat.DEFAULT_DATE_FORMAT)
   * @returns {Collection}
   */
  static async getPayoutReconciliationBySellerId(sellerId, fromDateString, toDateString) {
    const collection = new Collection(firestorePaths.STRIPE_PAYOUT_RECONCILIATION, {
      createDocument: ({ id }, opts) => new PayoutReconciliation(id, opts),
      query: (ref) => ref
        .where('sellerId', '==', sellerId)
        .where('payoutDate', '>=', fromDateString)
        .where('payoutDate', '<=', toDateString),
    });
    await collection.fetch();
    return collection;
  }

  /**
   * Get all payout reconciliation for a given crx
   * @param {string} crxId The crx id
   * @param {string} fromDateString The initial date to get the payout reconciliation (DateFormat.DEFAULT_DATE_FORMAT)
   * @param {string} toDateString The final date to get the payout reconciliation (DateFormat.DEFAULT_DATE_FORMAT)
   * @returns {Collection}
   */
  static async getPayoutReconciliationByCRX(crxId, fromDateString, toDateString) {
    const collection = new Collection(firestorePaths.STRIPE_PAYOUT_RECONCILIATION, {
      createDocument: ({ id }, opts) => new PayoutReconciliation(id, opts),
      query: (ref) => ref
        .where('assignments.CRX', 'array-contains', crxId)
        .where('payoutDate', '>=', fromDateString)
        .where('payoutDate', '<=', toDateString),
    });
    await collection.fetch();
    return collection;
  }

  static async getPayoutReconciliationByIdList(idList, idType, fromDateString, toDateString) {
    const idListChunks = chunk(idList, FIRESTORE_MAX_ARRAY_SIZE);

    const {
      field,
      operator,
    } = QueryConfigByIdType[idType];

    const promises = idListChunks.map((idListChunk) => {
      const collection = new Collection(firestorePaths.STRIPE_PAYOUT_RECONCILIATION, {
        createDocument: ({ id }, opts) => new PayoutReconciliation(id, opts),
        query: (ref) => ref
          .where(field, operator, idListChunk)
          .where('payoutDate', '>=', fromDateString)
          .where('payoutDate', '<=', toDateString),
      });
      return collection.fetch();
    });

    const collections = await Promise.all(promises);

    return {
      docs: collections.map((collection) => collection.docs).flat(),
    };
  }

  static async getPayoutReconciliationByCRXList(crxList, fromDateString, toDateString) {
    return PayoutReconciliation.getPayoutReconciliationByIdList(
      crxList,
      IdType.CRX,
      fromDateString,
      toDateString,
    );
  }

  static async getPayoutReconciliationBySellerIdList(sellerIdList, fromDateString, toDateString) {
    return PayoutReconciliation.getPayoutReconciliationByIdList(
      sellerIdList,
      IdType.SELLER,
      fromDateString,
      toDateString,
    );
  }
}

export default PayoutReconciliation;
