import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { initFirestorter } from 'firestorter';

import Firebase from '../../firebase';

import FirebaseContext from './FirebaseContext';

const firebase = new Firebase();

const FirebaseContextProvider = ({ children }) => {
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (!isReady) {
      initFirestorter({ firebase: firebase.app });
      setIsReady(true);
    }
  }, [
    isReady,
  ]);

  const context = useMemo(() => ({
    isReady,
    firebase,
  }), [
    isReady,
  ]);

  return (
    <FirebaseContext.Provider value={context}>
      {children}
    </FirebaseContext.Provider>
  );
};

FirebaseContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FirebaseContextProvider;
