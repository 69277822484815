import React from 'react';
import PropTypes from 'prop-types';
import Cropper from 'react-cropper';

// eslint-disable-next-line import/no-extraneous-dependencies
import 'cropperjs/dist/cropper.css';

const ImageCrop = ({
  onCropperChange,
  aspectRatio,
  imageUrl,
}) => (
  <Cropper
    src={imageUrl}
    aspectRatio={aspectRatio}
    guides={false}
    onInitialized={onCropperChange}
    background={false}
  />
);

ImageCrop.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  onCropperChange: PropTypes.func.isRequired,
  aspectRatio: PropTypes.number,
};

ImageCrop.defaultProps = {
  aspectRatio: null,
};

export default ImageCrop;
