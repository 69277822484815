import React from 'react';
import PropTypes from 'prop-types';
import {
  InfoTag,
  SuccessTag,
  ErrorTag,
  OkTag,
  StyledDarkTag,
  AttentionTag,
  TransparentTag,
  NoticeTag,
  WarningTag,
  BaseTag,
  LightTag,
  DisabledTag,
} from './styles';

const DarkTag = ({ icon, children }) => {
  const Icon = icon;
  return (
    <StyledDarkTag>
      {!!icon && <Icon />}
      {children}
    </StyledDarkTag>
  );
};

DarkTag.propTypes = {
  icon: PropTypes.node,
  children: PropTypes.node.isRequired,
};
DarkTag.defaultProps = {
  icon: null,
};

export {
  InfoTag,
  SuccessTag,
  ErrorTag,
  OkTag,
  DarkTag,
  LightTag,
  AttentionTag,
  TransparentTag,
  NoticeTag,
  WarningTag,
  BaseTag,
  DisabledTag,
};
