import styled from 'styled-components';

import colors from '../../../../../styles/colors';

const ContactInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 15px;
`;

const ContactInfoIcon = styled.div`
  display: flex;
  color: ${colors.shades.primary1};
  margin: 7px 0;
  font-size: 14px;
`;

const ContactInfoContent = styled.div`
  width: 100%;
  font-size: 14px;
  margin-left: 5px;
  align-self: center;
`;

export {
  ContactInfoWrapper,
  ContactInfoIcon,
  ContactInfoContent,
};
