import { Collection } from 'firestorter';
import Collections from '../../utils/collections';
import BaseDocument from '../../Model/BaseDocument';

/**
 * Class representing a user nutrition log.
 * It is used to keep track of each user nutrition log on the app and keep track of their food intake.
 *
 * @class UserNutritionLog
 * @extends BaseDocument
 */
class UserNutritionLog extends BaseDocument {
  constructor(id, opts) {
    super(`${Collections.USER_NUTRITION_LOG}/${id}`, opts);
  }

  /**
   * User id associated to a log.
   * @return {string}
  */
  get user() {
    return this.data.user;
  }

  /**
   * Start time as a timestamp.
   * @return {number}
  */
  get startTime() {
    return this.data.startTime;
  }

  /**
   * End time as a timestamp.
   * @return {number}
  */
  get endTime() {
    return this.data.endTime;
  }

  /**
   * Array of meals in the log.
   * @return {Object[]}
  */
  get meals() {
    return this.data.meals || [];
  }

  /**
   * Object containing the total macros of the log.
   * @return {Object}
  */
  get macros() {
    return this.data.macros || {};
  }

  /**
   * Returns the collection of UserNutritionLogs by a given user id.

   * @param {string} user - The id of the user associated to the UserNutritionLog.
   * @return {Promise<Collection>} A promise that resolves to a collection of UserNutritionLogs.
   */
  static async getUserNutritionLogs(user) {
    const userNutritionLogsCollection = new Collection(Collections.USER_NUTRITION_LOG, {
      createDocument: (src, opts) => new UserNutritionLog(src.id, opts),
      query: (ref) => ref
        .where('user', '==', user)
        .orderBy('startTime', 'asc'),
    });
    await userNutritionLogsCollection.fetch();
    return userNutritionLogsCollection;
  }
}

export default UserNutritionLog;
