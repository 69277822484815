import pages, { pagesConfig } from '../../config/pages';

import useToolsNavigation from '../../hooks/useToolsNavigation';

const useSupportSideBarConfig = () => {
  const { navigateTo } = useToolsNavigation();

  const entries = [
    {
      pageId: pages.RECIPES,
      icon: pagesConfig[pages.RECIPES].icon,
      onClick: () => navigateTo(pagesConfig[pages.RECIPES].path),
      title: pagesConfig[pages.RECIPES].title,
    },
    {
      pageId: pages.RECIPE_EDITOR,
      icon: pagesConfig[pages.RECIPE_EDITOR].icon,
      onClick: () => navigateTo(pagesConfig[pages.RECIPE_EDITOR].path),
      title: pagesConfig[pages.RECIPE_EDITOR].title,
    },
    {
      pageId: pages.ANALYTICS,
      icon: pagesConfig[pages.ANALYTICS].icon,
      onClick: () => navigateTo(pagesConfig[pages.ANALYTICS].path),
      title: pagesConfig[pages.ANALYTICS].title,
    },
    {
      pageId: pages.REUSABLE_CONTRACTS,
      icon: pagesConfig[pages.REUSABLE_CONTRACTS].icon,
      onClick: () => navigateTo(pagesConfig[pages.REUSABLE_CONTRACTS].path),
      title: pagesConfig[pages.REUSABLE_CONTRACTS].title,
    },
  ];

  return {
    entries,
  };
};

export default useSupportSideBarConfig;
