import { Collection } from 'firestorter';

import { firestorePaths } from '../utils/paths';
import BaseDocument from './BaseDocument';

class MetabaseDashboard extends BaseDocument {
  get name() {
    return this.data.name;
  }

  get dashboardId() {
    return this.data.dashboardId;
  }

  static async getAllDashboards(isSupport) {
    const dashboardCollection = new Collection(firestorePaths.METABASE_DASHBOARD, {
      createDocument: (source, options) => new MetabaseDashboard(source, options),
      // show only dashboards with forAllAdmins flag is true if user is not support
      ...!isSupport && {
        query: (ref) => ref.where('forAllAdmins', '==', true),
      },
    });
    await MetabaseDashboard.initCollection(dashboardCollection);
    return dashboardCollection;
  }
}

export default MetabaseDashboard;
