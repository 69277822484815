import styled, { css } from 'styled-components';

import { ReactComponent as MarkedCompletedIcon } from '../../../../assets/icons/marked-complete.svg';
import { ReactComponent as MarkedIncompleteIcon } from '../../../../assets/icons/marked-incomplete.svg';
import colors from '../../../../styles/colors';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  &:nth-of-type(odd) {
    background-color: ${colors.base.alpha};
  }
  &:nth-of-type(even) {
    background-color: ${colors.addAlpha(colors.shades.gamma5, 0.3)};
  }
  justify-content: space-between;
`;

const StyledTitle = styled.span`
  font-size: 12px;
  font-weight: 600;
  line-height: 22px;
  color: ${({ $hasWorkouts }) => ($hasWorkouts ? colors.base.beta : colors.shades.gamma8)};
`;

const Summary = styled.span`
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
  white-space: pre-wrap;
`;

const WorkoutItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const WorkoutStatusContainer = styled.div`
  display: flex;
  margin-right: 10px;
`;

const WorkoutName = styled.div`
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 6px 12px;
  margin: 10px 8px;
  align-items: left;
  min-width: 30%;
`;

const iconStyle = css`
  height: 12px;
  width: 12px;
`;

const CompletedIcon = styled(MarkedCompletedIcon)`
  ${iconStyle};
`;

const IncompleteIcon = styled(MarkedIncompleteIcon)`
  ${iconStyle};
`;

export {
  Container,
  ItemContainer,
  StyledTitle,
  WorkoutItem,
  WorkoutStatusContainer,
  WorkoutName,
  CompletedIcon,
  IncompleteIcon,
  Summary,
};
