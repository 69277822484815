import React, {
  useState,
  useCallback,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import format from 'string-template';
import moment from 'moment';
import { DatePicker } from '@mui/lab';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

import { DateFormat } from '../../../../../../utils/date';

import texts from './texts.json';
import {
  StyledPaper,
  StyledDialogContentText,
  StyledTextField,
  StyledInfoLabel,
} from './styles';

const StartDateUpdateModal = ({
  isOpen,
  updateClientSubscription,
  onCancel,
  dialogTexts,
  currentStartDate,
}) => {
  const [startDate, setStartDate] = useState(currentStartDate);

  useEffect(() => {
    setStartDate(currentStartDate);
  }, [
    currentStartDate,
  ]);

  const handleConfirm = useCallback(async () => {
    await updateClientSubscription({
      trial_end: startDate.unix(),
      proration_behavior: 'none',
    });
  }, [
    updateClientSubscription,
    startDate,
  ]);

  return (
    <Dialog
      open={isOpen}
      PaperComponent={StyledPaper}
    >
      <DialogTitle>
        {dialogTexts.title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {format(texts.currentStartDateText, {
            date: currentStartDate.format(DateFormat.DATE_FORMAT_TIME_MINUTES),
          })}
        </DialogContentText>
        <DatePicker
          label={texts.newStartDate}
          value={startDate}
          onChange={(newValue) => setStartDate(newValue)}
          minDate={moment().local()}
          renderInput={(params) => (
            <StyledTextField {...params} />
          )}
        />
        <DialogContentText>
          {format(texts.newStartDateText, {
            date: startDate.local().format(DateFormat.DATE_FORMAT_TIME_MINUTES),
          })}
        </DialogContentText>
        <StyledInfoLabel>
          {texts.timezoneLabel}
        </StyledInfoLabel>
        <StyledDialogContentText>
          {dialogTexts.content}
        </StyledDialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleConfirm}
          color="success"
        >
          {dialogTexts.confirm || texts.confirm}
        </Button>
        <Button
          onClick={onCancel}
          color="warning"
        >
          {dialogTexts.cancel || texts.cancel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

StartDateUpdateModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  dialogTexts: PropTypes.shape({
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    confirm: PropTypes.string,
    cancel: PropTypes.string,
  }).isRequired,
  updateClientSubscription: PropTypes.func.isRequired,
  currentStartDate: PropTypes.object.isRequired,
};

export default compose(
  observer,
)(StartDateUpdateModal);
