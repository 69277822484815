import React, { useState } from 'react';
import { Alert } from '@mui/material';

import useSessionStore from '../../../../../../../hooks/useSessionStore';
import { getStripeConnectLink } from '../../../../../../utils/stripe';

import {
  Wrapper,
  StyledButton,
} from './styles';
import texts from './texts.json';

const StripeConnect = () => {
  const [isLoadingStripe, setIsLoadingStripe] = useState(false);
  const { userId } = useSessionStore();

  const onStripeClick = () => {
    setIsLoadingStripe(true);
    window.location.href = getStripeConnectLink(userId);
  };

  return (
    <>
      <Alert variant="outlined" severity="warning">
        {texts.message}
      </Alert>
      <Wrapper>
        <StyledButton
          onClick={onStripeClick}
          isLoading={isLoadingStripe}
        >
          {texts.cta}
        </StyledButton>
      </Wrapper>
    </>
  );
};

export default StripeConnect;
