import React, {
  useState,
  useRef,
  useContext,
} from 'react';
import {
  IconButton,
  Menu,
  MenuItem,
} from '@mui/material';
import { Visibility as EyeIcon } from '@mui/icons-material';

import useNavigation from '../../../../hooks/useNavigation';
import useComponentMounted from '../../../../hooks/useComponentMounted';
import NavigationContext from '../../../context/NavigationContext';
import CoachSelectorModal from '../CoachSelectorModal';
import text from './text.json';

const ViewAs = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const viewAsButtonRef = useRef(null);
  const { isAdminGroupRoute } = useContext(NavigationContext);

  const isComponentMountedRef = useComponentMounted();

  const {
    navigateTo,
    routes,
  } = useNavigation();

  // Return to the logged in user home route when exiting the "view as" feature
  const handleExitViewAs = () => {
    navigateTo(routes.HOME);
  };

  const onViewAsClick = () => {
    setIsMenuOpen(false);
    setIsModalOpen(true);
  };

  const onModalDismiss = () => {
    if (isComponentMountedRef.current) {
      setIsModalOpen(false);
    }
  };

  // Only render the Exit view as button, if the admin is already using the feature.
  const renderExitViewAsMenuItem = () => {
    if (isAdminGroupRoute) {
      return null;
    }
    return (
      <MenuItem onClick={handleExitViewAs}>{text.exitViewAsButton}</MenuItem>
    );
  };

  return (
    <>
      <IconButton
        onClick={() => setIsMenuOpen(true)}
        ref={viewAsButtonRef}
      >
        <EyeIcon />
      </IconButton>
      <Menu
        anchorEl={viewAsButtonRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
      >
        <MenuItem onClick={onViewAsClick}>{text.viewAsButton}</MenuItem>
        {renderExitViewAsMenuItem()}
      </Menu>
      <CoachSelectorModal
        isOpen={isModalOpen}
        onDidDismiss={onModalDismiss}
      />
    </>
  );
};

export default ViewAs;
