import styled from 'styled-components';
import {
  TextField,
} from '@mui/material';
import colors from '../../../../../../styles/colors';

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 65%;
`;

const StyledTextField = styled(TextField)`
  width: 100%;
  min-width: 500px;

  fieldset.MuiOutlinedInput-notchedOutline {
    border: 1px solid ${colors.shades.secondary4};
  }
  .MuiInputBase-input {
    font-size: 13px;
    line-height: 16px;
    color: ${colors.shades.gamma2};
  }
`;

export {
  InputContainer,
  StyledTextField,
};
