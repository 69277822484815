import styled from 'styled-components';
import { DataGrid } from '@mui/x-data-grid';
import colors from '../../../styles/colors';

const DataGridContainer = styled.div`
  flex-basis: 100%;
  min-height: 500px;
  min-width: 920px
`;

const StyledDataGrid = styled(DataGrid)`
  border: 1px solid ${colors.shades.secondary7};
  color: ${colors.shades.secondary8};
  background-color: ${colors.base.alpha};
`;

export {
  DataGridContainer,
  StyledDataGrid,
};
