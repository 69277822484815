import pages from '../../config/pages';
import RecipesList from '../../pages/MealsPlanner/RecipesList';
import RecipesEditor from '../../pages/RecipeInfo';

const routes = [
  {
    pageId: pages.RECIPES,
    path: ':recipeId',
    component: RecipesEditor,
  },
  {
    pageId: pages.RECIPES,
    path: '',
    component: RecipesList,
  },
];

export default routes;
