import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as ArticleCheckIcon } from '../../../../../../../../assets/icons/v2/article-check.svg';
import { ReactComponent as ArticleLoveIcon } from '../../../../../../../../assets/icons/v2/article-love.svg';
import { StyledSwitcher, StyledOption } from './styles';
import texts from './texts.json';

const Switcher = ({ isMacrosView, setIsMacrosView }) => {
  const optionIds = {
    macros: 'macros',
    calories: 'calories',
  };
  const [value, setValue] = useState(isMacrosView ? optionIds.macros : optionIds.calories);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setIsMacrosView(newValue === optionIds.macros);
  };

  return (
    <StyledSwitcher value={value} onChange={handleChange}>
      <StyledOption
        icon={<ArticleCheckIcon />}
        value={optionIds.calories}
        iconPosition="start"
        label={texts.byCalories}
      />
      <StyledOption
        icon={<ArticleLoveIcon />}
        value={optionIds.macros}
        iconPosition="start"
        label={texts.byMacros}
      />
    </StyledSwitcher>
  );
};

Switcher.propTypes = {
  isMacrosView: PropTypes.bool.isRequired,
  setIsMacrosView: PropTypes.func.isRequired,
};

export default Switcher;
