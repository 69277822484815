import { Typography } from '@mui/material';
import styled from 'styled-components';

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const InfoLabel = styled(Typography)`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-transform: capitalize;
  color: ${(props) => props.color};
`;

const InfoContent = styled.div`
  width: 100%;
  font-size: 14px;
  color: ${(props) => props.color};
`;

export {
  InfoWrapper,
  InfoLabel,
  InfoContent,
};
