import { formatSeconds } from '../../../../../utils/date';
import texts from './texts.json';

const getColumns = ({
  actionsRenderCell,
}) => (
  [
    {
      field: 'name',
      quickSearch: true,
      headerName: texts.columns.workoutName,
      flex: 30,
    },
    {
      field: 'estimatedDuration',
      headerName: texts.columns.duration,
      flex: 20,
      valueGetter: ({ row }) => (
        `${row.estimatedDuration ? formatSeconds(row.estimatedDuration)
          : texts.emptyCell
        }`
      ),
    },
    {
      field: 'actions',
      sortable: false,
      filterable: false,
      headerName: texts.columns.actions,
      flex: 20,
      renderCell: actionsRenderCell,
    },
  ]
);

export default getColumns;
