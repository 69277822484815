import React, {
  useContext,
} from 'react';
import { compose } from 'recompose';

import MealPlanContext, { withMealPlanContextReady } from '../../../context/MealPlanContext';
import NoDefaultMealPlanView from '../components/NoDefaultMealPlanView';
import { HeaderContainer, Title } from '../styles';

import MealPlanAssignmentsTable from './components/MealPlanAssignmentsTable';
import {
  MealAssignmentsTableContainer,
  MealAssignmentsContainer,
} from './styles';
import texts from './texts.json';

const ClientMealAssignments = () => {
  const { defaultMealPlan } = useContext(MealPlanContext);

  if (!defaultMealPlan) {
    return <NoDefaultMealPlanView />;
  }

  return (
    <MealAssignmentsContainer>
      <HeaderContainer>
        <Title>{texts.title}</Title>
      </HeaderContainer>
      <MealAssignmentsTableContainer>
        <MealPlanAssignmentsTable />
      </MealAssignmentsTableContainer>
    </MealAssignmentsContainer>
  );
};

export default compose(
  withMealPlanContextReady,
)(ClientMealAssignments);
