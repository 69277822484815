import React, { useContext } from 'react';

import LoadingPage from '../../components/LoadingPage';

import FirebaseContext from './FirebaseContext';

const withFirebaseContextReady = (Component) => (props) => {
  const { isReady } = useContext(FirebaseContext);
  return isReady ? <Component {...props} /> : <LoadingPage />;
};

export {
  withFirebaseContextReady,
};
