import styled from 'styled-components';

import colors from '../../../../styles/colors';
import CurrencySelector from '../CurrencySelector';
import Input from '../Input';

const FormField = styled.div`
  display: ${({ $hidden }) => ($hidden ? 'none' : 'flex')};
  flex-direction: column;
  width: 100%;
`;

const StyledCurrencySelector = styled(CurrencySelector)`
  .MuiInputBase-root {
    height: 56px;
    background-color: ${colors.base.alpha};

    fieldset {
      border: none;
    }

    &.Mui-disabled {
      background-color: ${colors.base.gamma};
    }
  }

  .MuiInputBase-input {
    line-height: 40px;
    padding-left: 14px;
  }
`;

const PriceOption = styled.div`
  display: flex;
`;

const StyledInput = styled(Input)`
  .MuiInputBase-root {
    padding-left: 0;
  }
`;

const StyledCurrencySymbol = styled.span`
  display: inline-block;
  padding-left: 14px;
`;

export {
  FormField,
  StyledCurrencySelector,
  PriceOption,
  StyledInput,
  StyledCurrencySymbol,
};
