import styled, { css } from 'styled-components';

import colors from '../../../../../styles/colors';
import Button from '../../../../../components/Button';

const Container = styled.div`
  margin: 0 auto;
`;

const Header = styled.div`
  padding: 30px 40px;
  background-color: ${colors.shades.gamma5};
  position: sticky;
  top: 0;
  z-index: 1;
`;

const ColumnsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 30px 40px;
  position: relative;
`;

const StyledButton = styled(Button)`
  border-radius: 5px;
  height: 55px;
  font-size: 14px;
  cursor: pointer;
`;

const disabledButtonStyles = css`
  cursor: not-allowed;
  background-color: ${colors.shades.gamma3};
  color: ${colors.base.beta};
`;

const SendContractButton = styled(StyledButton)`
  width: 250px;
  justify-content: space-evenly;

  &:disabled {
    ${disabledButtonStyles};
  }
`;

const EditButton = styled(StyledButton)`
  width: 120px;
  margin-right: 20px;
  background-color: ${colors.shades.gamma9};
  color: ${colors.base.beta};
`;

const ViewTermsButton = styled(StyledButton)`
  width: 150px;
  &:disabled {
    ${disabledButtonStyles};
  }
`;

const ContractSentText = styled.div`
  display: flex;
  align-items: center;
  padding: 15px;
  color: ${colors.shades.success3};
`;

export {
  Container,
  Header,
  ColumnsContainer,
  Footer,
  SendContractButton,
  EditButton,
  ViewTermsButton,
  ContractSentText,
};
