import React from 'react';
import PropTypes from 'prop-types';

import {
  ContactInfoWrapper,
  ContactInfoIcon,
  ContactInfoContent,
} from './styles';

const ContactInfoItem = ({
  icon,
  children,
}) => (
  <ContactInfoWrapper>
    <ContactInfoIcon>{icon}</ContactInfoIcon>
    <ContactInfoContent>{children}</ContactInfoContent>
  </ContactInfoWrapper>
);

ContactInfoItem.propTypes = {
  icon: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
};

export default ContactInfoItem;
