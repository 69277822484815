import React, {
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import format from 'string-template';
import { useFormikContext } from 'formik';

import {
  InfoTag,
  LightTag,
  TransparentTag,
} from '../../../../../../components/Tags';
import {
  carbsToCalories,
  proteinToCalories,
  fatToCalories,
} from '../../../../../utils/meals';
import { fieldName } from '../../formFields';
import { Label } from '../../styles';

import {
  Container,
  MacrosContainer,
  StyledFormInput,
  StyledLabel,
  RowWrapper,
  CaloriesContainer,
  MacrosWrapper,
  MacrosSubWrapper,
} from './styles';

import texts from './texts';

const MacrosForm = () => {
  const { values, setFieldValue } = useFormikContext();

  const {
    [fieldName.PROTEIN]: protein = 0,
    [fieldName.CARBS]: carbs = 0,
    [fieldName.FAT]: fat = 0,
  } = values;

  const totalCalories = useMemo(() => (
    proteinToCalories(protein) + carbsToCalories(carbs) + fatToCalories(fat)
  ), [
    protein,
    carbs,
    fat,
  ]);

  const calculatePercentage = useCallback((value) => (
    (totalCalories > 0) && (value > 0) ? Math.round((value / totalCalories) * 100) : 0
  ), [totalCalories]);

  // Set form totalCalories value
  useEffect(() => {
    setFieldValue(fieldName.TOTAL_CALORIES, totalCalories);
  }, [
    setFieldValue,
    totalCalories,
  ]);

  useEffect(() => {
    const proteinPerc = calculatePercentage(proteinToCalories(protein));
    const carbsPerc = calculatePercentage(carbsToCalories(carbs));
    const fatPerc = calculatePercentage(fatToCalories(fat));
    setFieldValue(fieldName.PROTEIN_PERCENTAGE, proteinPerc);
    setFieldValue(fieldName.CARBS_PERCENTAGE, carbsPerc);
    setFieldValue(fieldName.FAT_PERCENTAGE, fatPerc);
  }, [
    values,
    protein,
    carbs,
    fat,
    calculatePercentage,
    setFieldValue,
  ]);

  return (
    <Container>
      <Label>{`${texts.title} :`}</Label>
      <MacrosContainer>
        <RowWrapper>
          <MacrosWrapper>
            <StyledLabel>{`${texts.field[fieldName.PROTEIN].label}:`}</StyledLabel>
            <MacrosSubWrapper>
              <StyledFormInput
                name={fieldName.PROTEIN}
                type="number"
                title={texts.macrosUnit}
                $simple
              />
              <LightTag>{`${values[fieldName.PROTEIN_PERCENTAGE] || 0}%`}</LightTag>
            </MacrosSubWrapper>
          </MacrosWrapper>
          <MacrosWrapper>
            <StyledLabel>{`${texts.field[fieldName.FAT].label}:`}</StyledLabel>
            <MacrosSubWrapper>
              <StyledFormInput
                name={fieldName.FAT}
                type="number"
                title={texts.macrosUnit}
                $simple
              />
              <LightTag>{`${values[fieldName.FAT_PERCENTAGE] || 0}%`}</LightTag>
            </MacrosSubWrapper>
          </MacrosWrapper>
          <MacrosWrapper>
            <StyledLabel>{`${texts.field[fieldName.CARBS].label}:`}</StyledLabel>
            <MacrosSubWrapper>
              <StyledFormInput
                name={fieldName.CARBS}
                type="number"
                title={texts.macrosUnit}
                $simple
              />
              <LightTag>{`${values[fieldName.CARBS_PERCENTAGE] || 0}%`}</LightTag>
            </MacrosSubWrapper>
          </MacrosWrapper>
        </RowWrapper>
        <CaloriesContainer>
          <InfoTag>{format(texts.totalCalories, { totalCalories })}</InfoTag>
          <TransparentTag>{texts.servingText}</TransparentTag>
        </CaloriesContainer>
      </MacrosContainer>
    </Container>
  );
};

export default MacrosForm;
