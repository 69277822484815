import styled from 'styled-components';
import colors from '../../styles/colors';

const HIGHLIGHT_CLASSNAME = 'highlight';

const Wrapper = styled.div`
  padding: 0;
`;

const ContractContent = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 29px;

  span.${HIGHLIGHT_CLASSNAME} {
    background-color: ${colors.shades.gamma5};
    border: 1px solid ${colors.shades.gamma8};
    border-radius: 4px;
    font-weight: 600;
    display: inline-block;
    padding: 3px 6px;
    margin: 0;
    line-height: initial;
  }
`;

export {
  Wrapper,
  ContractContent,
  HIGHLIGHT_CLASSNAME,
};
