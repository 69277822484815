import { TagCategory } from '../../../../../Model/ExerciseTag';

const texts = {
  searchPlaceholder: 'Search by tag name',
  customTagsFilter: 'Show Custom Tags Only',
  filterByCategory: 'Filter Tags by Category',
  deleteSuccessful: 'Tag deleted successfully',
  deleteFailed: 'Failed to delete tag: {message}',
  deleteTag: 'Do you want to delete the tag: {name}?',
  tagCategory: {
    [TagCategory.EXERCISE_TYPE]: 'Exercise Type',
    [TagCategory.EQUIPMENT]: 'Equipment',
    [TagCategory.GENERIC]: 'Generic',
    [TagCategory.BODY_REGION_ADVANCED]: 'Body Region Advanced',
    [TagCategory.BODY_REGION_BASIC]: 'Body Region Basic',
    [TagCategory.PLATFORM]: 'Platform',
  },
  columns: {
    name: 'Tag Name',
    type: 'Type',
    category: 'Category',
    actions: 'Actions',
  },
  buttons: {
    delete: 'Delete',
    edit: 'Edit',
  },
};

export default texts;
