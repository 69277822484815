import React, {
  useMemo,
  useContext,
  useEffect,
  useState,
  useCallback,
} from 'react';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import moment from 'moment';
import format from 'string-template';
import PropTypes from 'prop-types';
import {
  FormControl,
  MenuItem,
  Select,
} from '@mui/material';

import {
  InfoOutlined as InfoOutlinedIcon,
  DeleteOutlineOutlined as DeleteOutlineOutlinedIcon,
} from '@mui/icons-material';
import LinkIcon from '@mui/icons-material/Link';
import NoteEditIcon from '@mui/icons-material/NoteAltOutlined';
import NoteAddIcon from '@mui/icons-material/NoteAddOutlined';
import IconButton from '@mui/material/IconButton';

import { DateFormat } from '../../../../../utils/date';
import {
  LeadState,
  SalesStage,
} from '../../../../Model/Lead';
import LeadsContext from '../../../../context/LeadsContext';
import QuickSearchToolbar from '../../../QuickSearchToolbar';
import ExternalCoachContext from '../../../../context/ExternalCoachContext';
import useQuickSearch from '../../../../../hooks/useQuickSearch';
import useToast from '../../../../hooks/useToast';
import ConfirmDialog from '../../../ConfirmDialog';

import statesTexts from '../../states.json';
import salesStageTexts from '../../salesStages.json';
import { SalesStagesOptions } from '../../utils';
import texts from './texts';

import { LeadsTableType } from './utils';

import {
  ActionsContainer,
  DataGridContainer,
  StyledDataGrid,
  LinkText,
  StyledTableAlert,
  TableMessageContainer,
} from './styles';

const leadStates = Object.values(LeadState);
const leadSalesStages = Object.values(SalesStage);

const LeadsTable = ({
  rows,
  selectLead,
  setShowQuestionsModal,
  setShowUnqualifiedModal,
  selectedLeadStates,
  selectedSalesStages,
  onOnboardingLinkClick,
  userId,
  userName,
  onNotesClick,
  type,
  coachesList,
  activeSalesStageFilter,
}) => {
  const [leadToDelete, setLeadToDelete] = useState(null);
  const { showToast } = useToast();
  const { setFilteredLeads } = useContext(LeadsContext);
  const { coachIds } = useContext(ExternalCoachContext);

  const handleLeadDelete = useCallback(() => {
    leadToDelete.setRemoved(true);
    showToast(format(texts.leadDeleteMessage, { leadName: leadToDelete.name }));
    setLeadToDelete(null);
  }, [leadToDelete, showToast]);

  const columns = useMemo(() => {
    if (type === LeadsTableType.LEADS) {
      return (
        [
          {
            field: 'onboarding',
            headerName: texts.headers.onboarding.text,
            description: texts.headers.onboarding.tooltip,
            width: 140,
            disableColumnMenu: true,
            hideSortIcons: true,
            disableReorder: true,
            renderCell: ({
              row,
            }) => {
              const handleOnboardingLinkClick = () => {
                selectLead(row);
                onOnboardingLinkClick();
              };

              return (
                <ActionsContainer>
                  <IconButton onClick={handleOnboardingLinkClick}>
                    <LinkIcon />
                  </IconButton>
                </ActionsContainer>
              );
            },
          },
          {
            field: 'state',
            headerName: texts.headers.state.text,
            flex: 15,
            width: '100%',
            valueGetter: ({ row }) => statesTexts[row.state],
            renderCell: ({ row }) => {
              const handleStateClick = (event) => {
                const selectedState = event.target.value;
                selectLead(row);
                row.setState(selectedState, { userId, userName });
              };

              return (
                <FormControl fullWidth>
                  <Select
                    variant="filled"
                    value={row.state}
                    label={texts.headers.state.text}
                    onChange={handleStateClick}
                  >
                    {leadStates.map((state) => <MenuItem key={state} value={state}>{statesTexts[state]}</MenuItem>)}
                  </Select>
                </FormControl>
              );
            },
          },
          {
            field: 'name',
            quickSearch: true,
            headerName: texts.headers.name.text,
            flex: 15,
            renderCell: ({ row }) => {
              const handleOnNotesClick = () => {
                selectLead(row);
                onNotesClick();
              };

              return (
                <>
                  <IconButton onClick={handleOnNotesClick}>
                    {row.notes ? <NoteEditIcon /> : <NoteAddIcon />}
                  </IconButton>
                  {row.name?.toString() || texts.defaultRowValues.name}
                </>
              );
            },
          },
          {
            field: 'email',
            quickSearch: true,
            headerName: texts.headers.email.text,
            flex: 20,
          },
          {
            field: 'gender',
            headerName: texts.headers.gender.text,
            flex: 10,
          },
          {
            field: 'submitDate',
            headerName: texts.headers.submitDate.text,
            flex: 20,
            valueGetter: ({ row }) => (
              moment(row.submitDate).format(DateFormat.DATE_FORMAT_SLASH_WITH_TIME_FORMAT)
            ),
          },
          {
            field: 'country',
            headerName: texts.headers.country.text,
            flex: 10,
            valueGetter: ({ row }) => (
              row.country || texts.defaultRowValues.country
            ),
          },
          {
            field: 'action',
            headerAlign: 'center',
            flex: 10,
            headerName: texts.headers.action.text,
            description: texts.headers.action.tooltip,
            disableColumnMenu: true,
            hideSortIcons: true,
            disableReorder: true,
            renderCell: ({
              row,
            }) => {
              const handleResponsesClick = () => {
                selectLead(row);
                setShowQuestionsModal(true);
              };

              return (
                <ActionsContainer>
                  <IconButton onClick={handleResponsesClick}>
                    <InfoOutlinedIcon />
                  </IconButton>
                </ActionsContainer>
              );
            },
          },
          {
            field: 'delete',
            headerAlign: 'center',
            flex: 10,
            headerName: texts.headers.delete.text,
            description: texts.headers.delete.tooltip,
            disableColumnMenu: true,
            hideSortIcons: true,
            disableReorder: true,
            renderCell: ({
              row,
            }) => (
              <ActionsContainer>
                <IconButton onClick={() => setLeadToDelete(row)}>
                  <DeleteOutlineOutlinedIcon />
                </IconButton>
              </ActionsContainer>
            ),
          },
        ]
      );
    }
    return (
      [
        {
          field: 'coach',
          quickSearch: true,
          headerName: texts.headers.coach.text,
          flex: 20,
          renderCell: ({ row }) => <div>{coachesList[row.coach] || row.coach}</div>,
        },
        {
          field: 'name',
          quickSearch: true,
          headerName: texts.headers.name.text,
          flex: 15,
          renderCell: ({ row }) => {
            const handleOnNotesClick = () => {
              selectLead(row);
              onNotesClick();
            };
            const handleResponsesClick = () => {
              selectLead(row);
              setShowQuestionsModal(true);
            };

            return (
              <>
                <IconButton onClick={handleOnNotesClick}>
                  {row.notes ? <NoteEditIcon /> : <NoteAddIcon />}
                </IconButton>
                <LinkText onClick={handleResponsesClick}>
                  {row.name?.toString() || texts.defaultRowValues.name}
                </LinkText>
              </>
            );
          },
        },
        {
          field: 'salesStage',
          headerName: texts.headers.salesStage.text,
          flex: 15,
          width: '100%',
          renderCell: ({ row }) => {
            const handleStageClick = (event) => {
              const selectedStage = event.target.value;
              selectLead(row);
              if (type === LeadsTableType.IS_LEADS && selectedStage === SalesStage.UNQUALIFIED) {
                setShowUnqualifiedModal(true);
              } else {
                row.setSalesStage(selectedStage, { userId, userName });
              }
            };

            return (
              <FormControl fullWidth>
                <Select
                  variant="filled"
                  value={row.salesStage}
                  label={texts.headers.salesStage.text}
                  onChange={handleStageClick}
                >
                  {leadSalesStages.map((stage) => (
                    <MenuItem key={stage} value={stage}>{salesStageTexts[stage]}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            );
          },
        },
        {
          field: 'onboarding',
          headerName: texts.headers.onboarding.text,
          description: texts.headers.onboarding.tooltip,
          width: 140,
          disableColumnMenu: true,
          hideSortIcons: true,
          disableReorder: true,
          renderCell: ({
            row,
          }) => {
            const handleOnboardingLinkClick = () => {
              selectLead(row);
              onOnboardingLinkClick(true);
            };

            return (
              <ActionsContainer>
                <IconButton onClick={handleOnboardingLinkClick}>
                  <LinkIcon />
                </IconButton>
              </ActionsContainer>
            );
          },
        },
        {
          field: 'email',
          quickSearch: true,
          headerName: texts.headers.email.text,
          flex: 20,
        },
        {
          field: 'gender',
          headerName: texts.headers.gender.text,
          flex: 10,
        },
        {
          field: 'country',
          headerName: texts.headers.country.text,
          flex: 10,
          valueGetter: ({ row }) => (
            row.country || texts.defaultRowValues.country
          ),
        },
        {
          field: 'submitDate',
          headerName: texts.headers.submitDate.text,
          flex: 20,
          valueGetter: ({ row }) => (
            moment(row.submitDate).format(DateFormat.DATE_FORMAT_SLASH_WITH_TIME_FORMAT)
          ),
        },
        {
          field: 'lastContactedAt',
          headerName: texts.headers.lastContact.text,
          flex: 15,
          valueFormatter: ({ value }) => (
            value ? moment(value.toDate()).fromNow() : texts.defaultRowValues.empty
          ),
        },
        {
          field: 'lastContactedBy',
          headerName: texts.headers.lastContactedBy.text,
          flex: 15,
          valueFormatter: ({ value }) => (
            value || texts.defaultRowValues.empty
          ),
        },
      ]
    );
  }, [
    type,
    selectLead,
    setShowQuestionsModal,
    setShowUnqualifiedModal,
    onOnboardingLinkClick,
    userId,
    userName,
    onNotesClick,
    coachesList,
  ]);

  // Get the first filtering stage which returns the rows that match the quick search input.
  const {
    filteredRows,
    toolbarProps,
  } = useQuickSearch(rows, columns);

  // We also need to filter by the states selected in the states dropdown.
  const finalFilteredRows = useMemo(() => (
    filteredRows.filter((row) => selectedLeadStates.includes(row.state) && selectedSalesStages.includes(row.salesStage))
  ), [
    filteredRows,
    selectedLeadStates,
    selectedSalesStages,
  ]);

  useEffect(() => {
    /*
      Every time the leads are filtered, we need to save them in the context, so that other features can also work with
      them. For example, the download button needs to download only the leads that are shown at any given moment.
    */
    setFilteredLeads(finalFilteredRows);
  }, [
    finalFilteredRows,
    setFilteredLeads,
  ]);

  return (
    <DataGridContainer>
      <StyledDataGrid
        rows={finalFilteredRows}
        columns={columns}
        pageSize={25}
        rowsPerPageOptions={[25]}
        disableSelectionOnClick
        components={{
          Toolbar: QuickSearchToolbar,
          NoRowsOverlay: () => (
            (coachIds?.length > 1 && activeSalesStageFilter === SalesStagesOptions.FINAL_STATUS)
              ? <StyledTableAlert severity="info">{texts.multipleCoachesFinalStatusMessage}</StyledTableAlert>
              : <TableMessageContainer>{texts.noRows}</TableMessageContainer>
          ),
        }}
        componentsProps={{
          toolbar: { ...toolbarProps, autoFocus: true },
        }}
      />
      <ConfirmDialog
        isOpen={!!leadToDelete}
        onConfirm={handleLeadDelete}
        onCancel={() => setLeadToDelete(null)}
        dialogTexts={{
          title: texts.deleteConfirm,
          content: leadToDelete?.name || '',
        }}
      />
    </DataGridContainer>
  );
};

LeadsTable.propTypes = {
  rows: PropTypes.array.isRequired,
  selectLead: PropTypes.func.isRequired,
  setShowQuestionsModal: PropTypes.func.isRequired,
  setShowUnqualifiedModal: PropTypes.func,
  selectedLeadStates: PropTypes.array.isRequired,
  selectedSalesStages: PropTypes.array.isRequired,
  onOnboardingLinkClick: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  onNotesClick: PropTypes.func.isRequired,
  type: PropTypes.string,
  coachesList: PropTypes.object,
  activeSalesStageFilter: PropTypes.string.isRequired,
};

LeadsTable.defaultProps = {
  type: LeadsTableType.LEADS,
  setShowUnqualifiedModal: null,
  coachesList: {},
};

export default compose(
  observer,
)(LeadsTable);
