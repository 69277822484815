import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

const TabPanel = ({
  children,
  value,
  index,
}) => (
  <Container
    role="tabpanel"
    hidden={value !== index}
  >
    {children}
  </Container>
);

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default TabPanel;
