import { ReactComponent as FileCheckIcon } from '../../../assets/icons/file-check-icon.svg';
import { ReactComponent as FileHeartIcon } from '../../../assets/icons/file-heart-icon.svg';
import { ReactComponent as FolderCheckIcon } from '../../../assets/icons/folder-check-icon.svg';
import { ReactComponent as SettingsGearIcon } from '../../../assets/icons/settings-gear-icon.svg';

import { MealPlannerType } from '../../routes/mealPlanner/mealPlannerPages';
import Recipes from './Recipes';
import Settings from './Settings';
import ClientMealAssignments from './ClientMealAssignments';
import MealPlanRefresh from './ClientMealAssignments/components/MealPlanRefresh';
import ManageMealPlans from './ManageMealPlans';
import MealPlanAssigment from './ClientMealAssignments/components/MealPlanAssigment/MealPlanAssigment';

import texts from './texts.json';

const getMealPlannerTabsConfig = () => ({
  [MealPlannerType.CLIENT_MEAL_ASSIGNMENT]: {
    component: MealPlanAssigment,
    hidden: true,
  },
  [MealPlannerType.CLIENT_MEAL_ASSIGNMENTS]: {
    label: texts.tabLabels[MealPlannerType.CLIENT_MEAL_ASSIGNMENTS],
    component: ClientMealAssignments,
    icon: FileCheckIcon,
  },
  [MealPlannerType.MANAGE_MEAL_PLANS]: {
    label: texts.tabLabels[MealPlannerType.MANAGE_MEAL_PLANS],
    component: ManageMealPlans,
    icon: FolderCheckIcon,
  },
  [MealPlannerType.RECIPES]: {
    label: texts.tabLabels[MealPlannerType.RECIPES],
    component: Recipes,
    icon: FileHeartIcon,
  },
  [MealPlannerType.SETTINGS]: {
    label: texts.tabLabels[MealPlannerType.SETTINGS],
    component: Settings,
    icon: SettingsGearIcon,
  },
  [MealPlannerType.MEAL_PLAN_REFRESH]: {
    hidden: true,
    component: MealPlanRefresh,
  },
  [MealPlannerType.MEAL_PLAN_ASSIGNMENT]: {
    hidden: true,
    component: MealPlanRefresh,
  },
});

export {
  getMealPlannerTabsConfig,
};
