import styled, { css } from 'styled-components';

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  TableBody,
} from '@mui/material';

import Button from '../../../../components/Button';
import colors from '../../../../styles/colors';

const StyledTableContainer = styled(TableContainer)`
  border: 1px solid ${colors.shades.secondary7};
  border-radius: 5px;
  width: 100%;
`;

const StyledTable = styled(Table)`
  background: ${colors.shades.secondary10};
`;

const StyledTableHead = styled(TableHead)`
  background: ${colors.shades.secondary10};
`;

const StyledTableRow = styled(TableRow)`
  background: ${colors.base.alpha};
  ${({ $header }) => !!$header && css`
    background: ${colors.shades.secondary10};
  `}
`;

const StyledHeaderCell = styled(TableCell)`
  font-weight: 500;
  font-size: 11px;
  line-height: 11px;
  letter-spacing: 0.07em;
  color: ${colors.shades.gamma2};
  border-color: ${colors.shades.secondary7};
  ${({ $highlight }) => !!$highlight && css`
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 1px;
    color: ${colors.shades.primary1};
  `}
`;

const StyledTableCell = styled(TableCell)`
  position: relative;
  text-align: left;
  font-weight: 400;
  font-size: 15px;
  line-height: 17px;
  width: auto;
  color: ${colors.base.secondary2};
  border-color: ${colors.shades.secondary7};
  ${({ $secondary }) => !!$secondary && css`
    color: ${colors.shades.gamma7};
  `}
  ${({ $flex }) => !!$flex && css`
    display: flex;
    align-items: center;
  `}
  min-width: 100px;

  > span {
    position: absolute;
    top: 6px;
    left: 16px;
    text-align: left;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    ${({ $strike }) => !!$strike && css`
      text-decoration-line: line-through;
    `}
    color: ${colors.shades.gamma7};
  }
`;

const StyledTableBody = styled(TableBody)``;

const FooterContainer = styled.div`
  background: ${colors.shades.secondary10};
  padding: 8px 15px;
  width: 100%;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: ${colors.base.secondary2};
  column-gap: 10px;
`;

const StyledButton = styled(Button)`
  padding: ${({ $light }) => ($light ? '5px 10px' : '9px 12px')};
  border-radius: 73px;
  font-weight: 400;
  font-size: ${({ $light }) => ($light ? '10px' : '13px')};
  letter-spacing: 0;
  line-height: ${({ $light }) => ($light ? '12px' : '14px')};
  text-transform: none;
  background: ${({ $light }) => ($light ? colors.base.secondary3 : colors.base.secondary2)};
  color: ${({ $light }) => ($light ? colors.shades.secondary8 : colors.base.alpha)};
  &:hover {
    background: ${({ $light }) => ($light ? colors.shades.secondary6 : colors.shades.secondary8)};
  }
  &:disabled {
    background: ${colors.shades.gamma10};
    color: ${colors.base.alpha};
  }
`;

const DateContainer = styled.div`
  margin-left: 10px;
  padding: 5px 8px;
  background: ${colors.shades.secondary10};
  border: 1px solid ${colors.shades.secondary7};
  border-radius: 4px;
  font-weight: 500;
  font-size: 11px;
  line-height: 12px;
  letter-spacing: -0.01em;
  color: ${colors.base.secondary2};
  ${({ $secondary }) => !!$secondary && css`
    color: ${colors.shades.gamma7};
  `}
`;

const EmptyRow = styled.div`
  width: 100%;
  background: ${colors.base.alpha};
  border-bottom: 1px solid ${colors.shades.secondary7};
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  padding: 12px;
  color: ${colors.base.secondary2};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export {
  StyledTableContainer,
  StyledTable,
  StyledTableHead,
  StyledTableRow,
  StyledTableCell,
  StyledTableBody,
  StyledHeaderCell,
  FooterContainer,
  StyledButton,
  DateContainer,
  EmptyRow,
};
