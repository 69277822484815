import styled from 'styled-components';
import { Autocomplete } from '@mui/material';

import colors from '../../../styles/colors';
import Button from '../../Button';

const StyledAutoComplete = styled(Autocomplete)`
  width: 200px;
`;

const StyledButton = styled(Button)`
  padding: 6px 0 6px 15px;
  font-size: 16px;
  justify-content: left;
  background: none;
  color: ${colors.shades.danger1};

  &:hover {
    cursor: pointer;
  }
`;

const StyledOption = styled.div`
  display: flex;
  flex-direction: row;
`;

export {
  StyledAutoComplete,
  StyledButton,
  StyledOption,
};
