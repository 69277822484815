import styled from 'styled-components';

import colors from '../../../../../../styles/colors';
import FormikInput from '../../../../../../components/v2/FormikInput';

import {
  StyledFormRow,
  Label,
} from '../../styles';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 0;
`;

const RowWrapper = styled.div`
  margin: 5px 0;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 15px;
`;

const MacrosContainer = styled(StyledFormRow)`
  padding: 5px 20px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  border: 1px solid ${colors.shades.secondary7};
`;

const StyledFormInput = styled(FormikInput)`
  margin: 0;
`;

const StyledLabel = styled(Label)`
  color: ${colors.shades.secondary3};
`;

const CaloriesContainer = styled.div`
  margin: 10px 0;
  display: flex;
  align-items: center;
`;

const MacrosWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100px;
  flex-grow: 1;
`;

const MacrosSubWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  column-gap: 5px;
  > :last-child {
    margin-top: 10px;
  }
`;

export {
  Container,
  RowWrapper,
  MacrosContainer,
  StyledFormInput,
  StyledLabel,
  CaloriesContainer,
  MacrosWrapper,
  MacrosSubWrapper,
};
