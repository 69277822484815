import React from 'react';
import PropTypes from 'prop-types';

import AnalyticNumberCard from '../../AnalyticNumberCard';
import CoachLeadAnalytics from '../../../../../Model/analytics/CoachLeadAnalytics';
import texts from './texts.json';

const LeadAnalyticsCard = ({
  leadAnalytics,
}) => (
  <AnalyticNumberCard
    title={leadAnalytics?.total}
    subTitle={texts.newLeads}
    bottomStats={[
      {
        title: leadAnalytics?.male,
        subTitle: texts.gender.male,
      },
      {
        title: leadAnalytics?.female,
        subTitle: texts.gender.female,
      },
    ]}
  />
);

LeadAnalyticsCard.propTypes = {
  leadAnalytics: PropTypes.instanceOf(CoachLeadAnalytics).isRequired,
};

export default LeadAnalyticsCard;
