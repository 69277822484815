import React from 'react';

const initialValues = {
  isReady: false,
  baseExercisesCollection: { docs: [] },
  coachExercisesCollection: { docs: [] },
  exercises: [],
  showArchivedExercises: false,
  isLoading: false,
};

const ExerciseContext = React.createContext(initialValues);

export default ExerciseContext;
export {
  initialValues,
};
