import { ReactComponent as ProgramsIcon } from '../../../assets/icons/program-icon.svg';
import { ReactComponent as WorkoutIcon } from '../../../assets/icons/workout-icon.svg';
import { ReactComponent as ExerciseIcon } from '../../../assets/icons/exercise-icon.svg';
import { ReactComponent as TagsIcon } from '../../../assets/icons/tag-icon.svg';

import { WorkoutsManagerTab } from '../../routes/workoutsManager/workoutsManagerPages';
import Programs from './Programs';
import Workouts from './Workouts';
import Exercises from './Exercises';
import Tags from './Tags';

import texts from './texts.json';

const WorkoutsManagerTabsConfig = {
  [WorkoutsManagerTab.PROGRAMS]: {
    label: texts.tabLabels[WorkoutsManagerTab.PROGRAMS],
    component: Programs,
    icon: ProgramsIcon,
  },
  [WorkoutsManagerTab.WORKOUTS]: {
    label: texts.tabLabels[WorkoutsManagerTab.WORKOUTS],
    component: Workouts,
    icon: WorkoutIcon,
  },
  [WorkoutsManagerTab.EXERCISES]: {
    label: texts.tabLabels[WorkoutsManagerTab.EXERCISES],
    component: Exercises,
    icon: ExerciseIcon,
  },
  [WorkoutsManagerTab.TAGS]: {
    label: texts.tabLabels[WorkoutsManagerTab.TAGS],
    component: Tags,
    icon: TagsIcon,
  },
};

export {
  WorkoutsManagerTabsConfig,
};
