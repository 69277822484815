import React from 'react';
import PropTypes from 'prop-types';

import {
  StyledTextField,
  TextFieldLabel,
} from './styles';

const Input = ({
  className,
  name,
  label,
  ...extraProps
}) => (
  <>
    <TextFieldLabel>{label}</TextFieldLabel>
    <StyledTextField
      className={className}
      name={name}
      {...extraProps}
    />
  </>
);

Input.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
};

Input.defaultProps = {
  className: '',
  name: '',
  label: '',
};

export default Input;
