import { formatCurrencyCents } from './formatters';

const FeeType = {
  FIXED: 'FIXED',
  PERCENTAGE: 'PERCENTAGE',
};

const getFeeAmountInCents = (totalPriceInCents, feeValue, feeType) => {
  switch (feeType) {
    case FeeType.FIXED:
      return feeValue;
    case FeeType.PERCENTAGE:
    default: // using FeeType.PERCENTAGE as default
      return totalPriceInCents * (feeValue / 100);
  }
};

const getFeeAmountStr = (totalPriceInCents, currency, fee) => {
  const { value: feeValue, type: feeType, currency: feeCurrency } = fee;
  const feeAmountInCents = getFeeAmountInCents(totalPriceInCents, feeValue, feeType);
  return formatCurrencyCents(feeAmountInCents, feeCurrency || currency);
};

export {
  FeeType,
  getFeeAmountInCents,
  getFeeAmountStr,
};
