import styled from 'styled-components';

import colors from '../../../../../../styles/colors';
import InputLabel from '../../../../../../components/v2/InputLabel';
import { ReactComponent as SaveIcon } from '../../../../../../assets/icons/v2/save.svg';

const Container = styled.div`
  width: 100%;
  padding: 0px 20px 20px 20px;
`;

const FormSection = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  width: 80%;
  min-width: 450px;
`;

const WorkoutDaysSection = styled(FormSection)`
  width: 100%;
`;

const Label = styled(InputLabel)`
  font-size: 12px;
  font-weight: 700;
  margin-left: 10px;
`;

const StyledFormRowScale = styled.div`
  padding-left: 10px;
  background-color: ${colors.shades.secondary10};
  border-radius: 5px;
  border: 1px solid ${colors.shades.secondary7};
`;

const EquipmentContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const FieldContent = styled.div`
  margin: 10px;
`;

const StyledSaveIcon = styled(SaveIcon)`
  width: 15px;
  height: 15px;
  > path {
    stroke: ${colors.base.primary};
    fill: ${colors.base.secondary2};
  }
`;

const ContainerContent = styled.div`
  display: grid;
  grid-template-columns: 5fr 3fr;
`;

export {
  FormSection,
  Container,
  Label,
  StyledFormRowScale,
  EquipmentContainer,
  FieldContent,
  StyledSaveIcon,
  WorkoutDaysSection,
  ContainerContent,
};
