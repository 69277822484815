import React, {
  useState,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { MobXProviderContext } from 'mobx-react';
import PropTypes from 'prop-types';

import Subscription from '../../Model/Subscription';

import useComponentMounted from '../../hooks/useComponentMounted';

import SubscriptionContext from './SubscriptionContext';

const SubscriptionContextProvider = ({
  children,
}) => {
  const isComponentMountedRef = useComponentMounted();
  const { sessionStore: { userId } } = useContext(MobXProviderContext);
  const [isReady, setIsReady] = useState(!userId);
  const [subscription, setSubscription] = useState(null);

  useEffect(() => {
    const init = async () => {
      const currentSubscription = await Subscription.getCurrentSubscription(userId);
      if (isComponentMountedRef.current) {
        setSubscription(currentSubscription ? currentSubscription.subscriptionData : null);
        setIsReady(true);
      }
    };

    if (!isReady) {
      init();
    }
  }, [
    isReady,
    isComponentMountedRef,
    userId,
  ]);

  const contextValue = useMemo(() => ({
    isReady,
    subscription,
  }), [
    isReady,
    subscription,
  ]);

  return (
    <SubscriptionContext.Provider value={contextValue}>
      {children}
    </SubscriptionContext.Provider>
  );
};

SubscriptionContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SubscriptionContextProvider;
