import React from 'react';
import PropTypes, { object } from 'prop-types';

import { Container as DraggableContainer, Draggable } from 'react-smooth-dnd';

import { IconButton } from '@mui/material';

import {
  initialIngredientValues,
} from '../../validation';

import {
  fieldName,
} from '../../formFields';
import { Label, StyledAddIcon } from '../../styles';

import IngredientsRow from './IngredientsRow';
import {
  IngredientsContainer,
  StyledFieldArray,
  ButtonContainer,
  IngredientHeader,
  IngredientLine,
} from './styles';

import texts from './texts';

const IngredientsForm = ({
  ingredients,
}) => (
  <IngredientsContainer>
    <Label>{`${texts.title}:`}</Label>
    <StyledFieldArray
      name={fieldName.INGREDIENTS}
      render={({
        remove,
        push,
        move,
      }) => (
        <>
          <DraggableContainer
            dragHandleSelector=".drag-handle"
            lockAxis="y"
            onDrop={({ removedIndex, addedIndex }) => move(removedIndex, addedIndex)}
          >
            {ingredients.map((ingredient, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Draggable key={index}>
                {ingredient.ingredientLine && (
                  <IngredientLine>{ingredient.ingredientLine}</IngredientLine>
                )}
                <IngredientsRow
                  index={index}
                  removeRow={() => remove(index)}
                />
              </Draggable>
            ))}
          </DraggableContainer>
          <ButtonContainer>
            <IconButton onClick={() => push({ ...initialIngredientValues })}>
              <StyledAddIcon />
            </IconButton>
            <IngredientHeader>{texts.addIngredient}</IngredientHeader>
          </ButtonContainer>
        </>
      )}
    />
  </IngredientsContainer>
);

IngredientsForm.propTypes = {
  ingredients: PropTypes.arrayOf(object).isRequired,
};

export default IngredientsForm;
