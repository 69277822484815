import React from 'react';
import PropTypes from 'prop-types';
import { useChatContext } from 'stream-chat-react';
import MessagingChannelPreview from '../../MessagingChannelPreview';

const CustomResultItem = ({
  result: channel,
}) => {
  const { setActiveChannel } = useChatContext();

  const {
    unreadCount,
  } = channel.state;

  return (
    <MessagingChannelPreview
      unread={unreadCount}
      channel={channel}
      setActiveChannel={setActiveChannel}
    />
  );
};

CustomResultItem.propTypes = {
  result: PropTypes.shape({}).isRequired,
};

export default CustomResultItem;
