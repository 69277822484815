import { Collection } from 'firestorter';

import Collections from '../utils/collections';
import { firestorePaths } from '../utils/paths';
import BaseDocument from './BaseDocument';

/**
 * Class representing a mobile app theme
 *
 * A custom theme defines colors to a set of predefined variables that applies to the mobile application.
 * These are assigned to coaches before launching the coach to customize the app's look and feel.
 *
 * @class AppCustomTheme
 * @extends BaseDocument
 */
class AppCustomTheme extends BaseDocument {
  constructor(id, opts) {
    super(`${Collections.APP_CUSTOM_THEME}/${id}`, opts);
  }

  /**
   * Name of the theme
   * @return {string}
   */
  get name() {
    return this.data.name;
  }

  /**
   * Object that holds all the colors of the theme for each variant value
   * @return {Object}
   */
  get colors() {
    return this.data.colors || {};
  }

  /**
   * Get an app theme by the ID
   *
   * @param {string} id - Theme id
   * @return {Promise<AppCustomTheme|void>} - App theme document if exists, null if not
   */
  static async getById(id) {
    const appCustomThemeDoc = new AppCustomTheme(id);
    await appCustomThemeDoc.init();
    return appCustomThemeDoc.exists ? appCustomThemeDoc : null;
  }

  /**
   * Get all the app themes
   *
   * @return {Promise<Collection>} - Collection of app themes
   */
  static async getAllThemes() {
    const collection = new Collection(firestorePaths.APP_CUSTOM_THEME, {
      createDocument: ({ id }, options) => new AppCustomTheme(id, options),
    });
    await collection.fetch();
    return collection;
  }
}

export default AppCustomTheme;
