import styled from 'styled-components';
import { Button } from '@mui/material';

import colors from '../../../../../styles/colors';

const StyledButton = styled(Button)`
  border-radius: 5px;
  margin-top: 10px;
  background: ${colors.base.primary};
  color: ${colors.base.beta};
  height: fit-content;
  padding: 10px 14px;
  &.MuiButton-text {
    font-size: 14px;
    line-height: 16px;
  }
  &:hover {
    background: ${colors.shades.primary1};
  }
`;

export {
  StyledButton,
};
