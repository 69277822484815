import { useContext } from 'react';

import FirebaseContext from '../context/FirebaseContext';
import useInAppBrowser from './useInAppBrowser';

const SIGN_IN_LINK_EMAIL_ACCOUNT_KEY = 'signInEmail';

/**
 * @typedef {string} AuthErrorCode
 */
const AuthErrorCode = {
  INVALID_EMAIL: 'auth/invalid-email',
  EMAIL_ALREADY_IN_USE: 'auth/email-already-in-use',
  REQUIRES_RECENT_LOGIN: 'auth/requires-recent-login',
};

/**
 * @typedef {string} SignInMethod
 */
const SignInMethod = {
  NO_ACCOUNT: 'noAccount',
  EMAIL_PASSWORD: 'password',
  EMAIL_LINK: 'link',
};

const useAuthentication = () => {
  const { firebase: { auth, remote } } = useContext(FirebaseContext);

  const { sendMessage } = useInAppBrowser();

  /**
   * Given an e-mail address it returns the sign in method for this e-mail.
   * @param {string} email The e-mail address to query about
   * @returns {Promise<SignInMethod>} A promise that would resolve to the preferred sign in method for the provided
   * email address.
   */
  const getSignInMethod = async (email) => {
    const signInMethods = await auth.fetchSignInMethodsForEmail(email);
    if (signInMethods.length === 0) {
      return SignInMethod.NO_ACCOUNT;
    }
    return signInMethods.includes(SignInMethod.EMAIL_PASSWORD)
      ? SignInMethod.EMAIL_PASSWORD
      : SignInMethod.EMAIL_LINK;
  };

  /**
   * Sends an authentication e-mail to the user to be able to access to the app at the
   * specificed destinationPath. It also stores an item in local storage with the given email to simplify the
   * re-authentication. (If that is not provided we need to ask the e-mail explicitly)
   * @param {string} email The email address we want the authorization e-mail to be sent to
   * @param {string} destinationPath The destination path we should forward the user to
   */
  const sendAuthenticationEmail = async (email, destinationPath) => {
    await auth.sendSignInLinkToEmail(email, {
      url: `${window.location.origin}/authLink?destination=${encodeURIComponent(destinationPath)}`,
      handleCodeInApp: true,
    });
    window.localStorage.setItem(SIGN_IN_LINK_EMAIL_ACCOUNT_KEY, email);
  };

  /**
   * Creates a new anonymous account and assigns the given e-mail address to it.
   *
   * @param {string} email The e-mail address to associate
   * @returns A promise that is resolved once the user gets created and e-mail is successfully associated.
   * The promise can be rejected with the following codes:
   * https://firebase.google.com/docs/reference/js/firebase.User#error-codes_13
   */
  const createAnonymousAccountWithEmail = async (email) => {
    const { user } = await auth.signInAnonymously();
    try {
      await user.updateEmail(email);
    } catch (err) {
      if (err.code === AuthErrorCode.EMAIL_ALREADY_IN_USE) {
        await user.delete();
        throw err;
      }
    }
    return user;
  };

  const createUserWithEmailAndPassword = async (email, password) => {
    const { user } = await auth.createUserWithEmailAndPassword(email, password);
    return user;
  };

  /**
   * Sends a password reset e-mail to the provided email address
   * @param {string} email The e-mail address to send the e-mail to
   */
  const sendPasswordResetEmail = async (email) => {
    await auth.sendPasswordResetEmail(email);
  };

  /**
   * Check if current URL corresponds to an auth link
   */
  const isSignInWithEmailLink = () => (
    auth.isSignInWithEmailLink(window.location.href)
  );

  /**
   * Signs the email address in using auth link
   * @param {string} email The email of the user
   */
  const signInWithEmailLink = (email) => (
    auth.signInWithEmailLink(email)
  );

  /**
   * Sends a post message back to the In-App browser that opened this
   * site with the authorization code for automatic sign in.
   */
  const sendCustomTokenToApp = async () => {
    const response = await remote('generateCustomToken');
    const { customToken } = await response.json();
    const message = {
      action: 'authenticate',
      customToken,
    };
    sendMessage(message);
  };

  /**
   * Sign out current user
   */
  const signOut = async () => {
    await auth.signOut();
  };

  return {
    getSignInMethod,
    sendAuthenticationEmail,
    createAnonymousAccountWithEmail,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    isSignInWithEmailLink,
    signInWithEmailLink,
    sendCustomTokenToApp,
    signOut,
  };
};

export default useAuthentication;

export {
  SignInMethod,
  AuthErrorCode,
  SIGN_IN_LINK_EMAIL_ACCOUNT_KEY,
};
