import { Button, Stack } from '@mui/material';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  height: 40px;
  font-size: 14px;
  text-align: initial;
`;

const StyledStack = styled(Stack)`
  margin-top: 20px;
`;

const StyledImg = styled.img`
  width: 400px;
`;

const StyledVideo = styled.video`
  width: 400px;
`;

export {
  StyledButton,
  StyledStack,
  StyledImg,
  StyledVideo,
};
