import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as ArrowIcon } from '../../assets/icons/chevron-down.svg';
import InputLabel from '../v2/InputLabel';
import {
  Container,
  Description,
  StyledSelect,
  StyledMenuItem,
  menuStyles,
} from './styles';
import texts from './texts.json';

const Select = ({
  className,
  description,
  displayEmpty,
  options,
  onChange,
  noOptionsText,
  label,
  optionParserFunction,
  ...otherProps
}) => {
  const parsedOptionItems = useMemo(() => {
    if (!options.length) {
      return <StyledMenuItem value="">{noOptionsText}</StyledMenuItem>;
    }

    // Use custom parser function if available
    if (optionParserFunction) {
      return optionParserFunction(options);
    }

    return options.map(({ value, label: optionLabel }) => (
      <StyledMenuItem key={value} value={value}>
        {optionLabel}
      </StyledMenuItem>
    ));
  }, [
    options,
    noOptionsText,
    optionParserFunction,
  ]);

  return (
    <Container>
      {!!description && <Description>{description}</Description>}
      {!!label && <InputLabel>{label}</InputLabel>}
      <StyledSelect
        className={className}
        onChange={onChange}
        displayEmpty={displayEmpty}
        MenuProps={{
          sx: menuStyles,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
        IconComponent={ArrowIcon}
        label={label}
        {...otherProps}
      >
        {parsedOptionItems}
      </StyledSelect>
    </Container>
  );
};

Select.propTypes = {
  className: PropTypes.string,
  description: PropTypes.string,
  displayEmpty: PropTypes.bool,
  options: PropTypes.array,
  onChange: PropTypes.func,
  noOptionsText: PropTypes.string,
  label: PropTypes.string,
  optionParserFunction: PropTypes.func,
};

Select.defaultProps = {
  className: '',
  description: '',
  displayEmpty: false,
  options: [],
  onChange: () => {},
  noOptionsText: texts.noOptionsText,
  label: '',
  optionParserFunction: null,
};

export default Select;
