import React from 'react';
import PropTypes from 'prop-types';

import {
  StyledModal,
  Title,
  ActionButton,
  CancelButton,
  WarningIcon,
  ActionsContainer,
} from './styles';

import texts from './texts.json';

const ArchiveModal = ({ isOpen, mealPlanDoc, onDidDismiss }) => {
  const archiveMealPlan = async () => {
    await mealPlanDoc.archive();
    onDidDismiss();
  };

  return (
    <StyledModal isOpen={isOpen} onDidDismiss={onDidDismiss}>
      <WarningIcon />
      <Title>{texts.title}</Title>
      <ActionsContainer>
        <ActionButton onClick={archiveMealPlan}>{texts.buttons.archive}</ActionButton>
        <CancelButton onClick={onDidDismiss}>{texts.buttons.cancel}</CancelButton>
      </ActionsContainer>
    </StyledModal>
  );
};

ArchiveModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  mealPlanDoc: PropTypes.object.isRequired,
  onDidDismiss: PropTypes.func.isRequired,
};

export default ArchiveModal;
