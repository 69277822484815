import styled from 'styled-components';
import { DataGrid } from '@mui/x-data-grid';

import colors from '../../../styles/colors';

const StyledDataGrid = styled(DataGrid)`
  background-color: ${colors.base.alpha};
  min-height: 350px;
  
  .MuiDataGrid-columnHeaderTitle {
    font-weight: 500;
    color: ${colors.shades.primary1};
    text-transform: uppercase;
    font-size: 12px;
  }
`;

export {
  StyledDataGrid,
};
