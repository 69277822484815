import { useMemo, useReducer } from 'react';

/**
 * A normal reducer used in useReducer.
 * Actions with the type 'set' would set the provided value to the given field.
 * @param {Object} state - Current state.
 * @param {Object} action - Action used in the reducer
 * @param {string} action.type - Type of the action
 * @param {string} action.fieldName - Name of the field to be updated
 * @param {Object} action.value - An object to be set as the new value
 * @return {*}
 */
function reducer(state, action) {
  switch (action.type) {
    case 'set':
      return {
        ...state,
        [action.fieldName]: action.value,
      };
    default:
      return state;
  }
}

/**
 * Returns a method that dispatches an Action with the type set to 'set'
 * and a fieldName and value to be updated with.
 * @param {string} fieldName - Name of the field to be updated
 * @param {function} dispatch - React reducer dispatch function
 * @return {Function}
 */
const setterBuilder = (fieldName, dispatch) => (value) => {
  dispatch({ type: 'set', fieldName, value });
};

/**
 * Generates a setter name for the given field name.
 * @param {string} fieldName - Field name
 * @return {string} - The generated setter method name.
 */
function createSetterName(fieldName) {
  let nameStartChar = fieldName.charAt(0);
  let slicePosition = 1;
  if (fieldName.startsWith('is')) {
    nameStartChar = fieldName.charAt(2);
    slicePosition = 3;
  }
  return `set${nameStartChar.toUpperCase()}${fieldName.slice(slicePosition)}`;
}

/**
 * A custom hook to be used when need to avoid unnecessary dependencies in a useEffect hook.
 * That way we could avoid implementing boilerplate logic just to avoid unnecessary re-renders.
 * The implementation generates setter for all the parameters given in the initial state.
 * For fields starting with 'is' {eg : isValid}, the generated setter would be without the 'is'
 * {eg : setValid}.
 * @param {Object} initialState - Initial state.
 * @return {Array} Returns an array with three elements:
 *                 1. The current state object.
 *                 2. A dispatch function with no return value.
 *                 3. An object with setter functions for each state field.
 *                  If no fields are provided, an empty object is returned.
 */
const useSetterWithReducer = (initialState = {}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const setters = useMemo(() => {
    const settersList = Object
      .keys(initialState)
      .reduce((setterMap, fieldName) => {
        const setter = setterBuilder(fieldName, dispatch);
        return {
          ...setterMap,
          [createSetterName(fieldName)]: setter,
        };
      }, {});
    return settersList;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return [state, dispatch, setters];
};

export default useSetterWithReducer;
