import styled from 'styled-components';
import { DataGrid } from '@mui/x-data-grid';
import { Typography, Alert } from '@mui/material';

import colors from '../../../../../styles/colors';

const DataGridContainer = styled.div`
  flex-basis: 100%;
  min-height: 500px;
  min-width: 920px;
  margin-bottom: 20px;
`;

const ActionsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TableActionsHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledDataGrid = styled(DataGrid)`
  border: 1px solid ${colors.shades.secondary7};
  color: ${colors.shades.secondary8};
  background-color: ${colors.base.alpha};
`;

const LinkText = styled(Typography)`
  cursor: pointer;
  text-decoration: underline;
  opacity: 1;
  &:hover {
    opacity: 0.8;
  }
`;

const StyledTableAlert = styled(Alert)`
  width: 600px;
  margin: auto;
`;

const TableMessageContainer = styled.div`
  margin: auto;
`;

export {
  DataGridContainer,
  ActionsContainer,
  TableActionsHeader,
  StyledDataGrid,
  LinkText,
  StyledTableAlert,
  TableMessageContainer,
};
