import React, { useContext, useEffect, useState } from 'react';
import { compose } from 'recompose';

import { SaveButton } from '../../../../../../components/Button/ActionButtons';
import MealPlanContext, { withMealPlanContextReady } from '../../../../../context/MealPlanContext';
import useToast from '../../../../../hooks/useToast';
import EditItem from '../../../components/EditItem';
import { StyledTextField } from '../../../../../../components/Inputs';
import {
  TitledActionContainer,
  TitledActionContainerFooter,
  TitledActionContainerHeader,
  TitledActionContainerSection,
} from '../../../styles';

import texts from './texts.json';

const CalorieRange = () => {
  const {
    mealPlanConfig,
    saveMealPlanConfig,
  } = useContext(MealPlanContext);
  const {
    minCaloriesThreshold,
    maxCaloriesThreshold,
  } = mealPlanConfig;

  const [calorieRange, setCalorieRange] = useState({
    ...(minCaloriesThreshold && { minCaloriesThreshold }),
    ...(maxCaloriesThreshold && { maxCaloriesThreshold }),
  });

  const [isSavable, setSavable] = useState(false);

  const { showToast } = useToast();

  useEffect(() => {
    const equal = (minCaloriesThreshold === calorieRange.minCaloriesThreshold
      && maxCaloriesThreshold === calorieRange.maxCaloriesThreshold);
    setSavable(!equal);
  }, [
    minCaloriesThreshold,
    maxCaloriesThreshold,
    calorieRange.minCaloriesThreshold,
    calorieRange.maxCaloriesThreshold,
  ]);

  const handleThresholdSave = () => {
    if (parseInt(calorieRange.minCaloriesThreshold, 10) >= parseInt(calorieRange.maxCaloriesThreshold, 10)) {
      showToast(texts.errors.minError, { error: true });
    } else {
      saveMealPlanConfig(calorieRange);
      showToast(texts.saved);
    }
  };

  const handleValueChange = (name) => (event) => {
    setCalorieRange({ ...calorieRange, [name]: event.target.value });
  };

  return (
    <TitledActionContainer>
      <TitledActionContainerHeader>{texts.header}</TitledActionContainerHeader>
      <TitledActionContainerSection>
        <EditItem label={texts.minCaloriesLabel}>
          <StyledTextField
            value={calorieRange.minCaloriesThreshold}
            onChange={handleValueChange('minCaloriesThreshold')}
            type="number"
            title={texts.calories}
          />
        </EditItem>
        <EditItem label={texts.maxCaloriesLabel}>
          <StyledTextField
            value={calorieRange.maxCaloriesThreshold}
            onChange={handleValueChange('maxCaloriesThreshold')}
            type="number"
            title={texts.calories}
          />
        </EditItem>
      </TitledActionContainerSection>
      <TitledActionContainerFooter>
        <SaveButton
          onClick={handleThresholdSave}
          disabled={!isSavable}
        >
          {texts.save}
        </SaveButton>
      </TitledActionContainerFooter>
    </TitledActionContainer>
  );
};

export default compose(
  withMealPlanContextReady,
)(CalorieRange);
