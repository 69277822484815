import { Collection } from 'firestorter';
import { firestorePaths } from '../utils/paths';
import BaseDocument from './BaseDocument';

class BroadcastMessage extends BaseDocument {
  get message() {
    return this.data.message;
  }

  get recipients() {
    return this.data.recipients;
  }

  get coach() {
    return this.data.coach;
  }

  static async create(messageData) {
    const broadcastMessageCollection = new Collection(firestorePaths.BROADCAST_MESSAGE);

    await broadcastMessageCollection.add({
      ...messageData,
      createdAt: new Date(),
    });
  }
}

export default BroadcastMessage;
