import styled from 'styled-components';
import {
  TextField,
} from '@mui/material';

import colors from '../../../../styles/colors';

const TextFieldLabel = styled.div`
  color: ${colors.shades.primary1};
  text-transform: uppercase;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
`;

const StyledTextField = styled(TextField)`
  background: ${colors.base.alpha};

  .Mui-disabled {
    cursor: not-allowed;
    background: ${colors.base.gamma};
    background-color: ${colors.base.gamma};
    color: ${colors.shades.gamma7};
    -webkit-text-fill-color: ${colors.shades.gamma7};

    .MuiInputBase-input {
      background: ${colors.base.gamma};
    }

    .MuiInputAdornment-positionEnd p{
      color: ${colors.shades.gamma7};
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: ${colors.shades.gamma5};
    }
  }

  .MuiInputBase-input {
    padding: 8.5px 0;
    font-size: 14px;
  }

  .MuiOutlinedInput-input {
    height: 40px;
    background-color: ${colors.base.alpha};
  }

  .MuiInputAdornment-positionEnd p,
  .MuiInputAdornment-positionStart p {
    font-size: 14px;
  }

  .MuiInputBase-adornedEnd {
    text-transform: uppercase;
    padding-left: 14px;
  }

  .MuiInputBase-adornedStart {
    padding-right: 14px;
  }

  .MuiInputAdornment-positionEnd {
    margin-left: 0;
    min-width: 68px;
  }

  .MuiFormHelperText-contained {
    position: absolute;
    top: 100%;
    margin-left: 0;
    width: max-content;
  }
`;

export {
  TextFieldLabel,
  StyledTextField,
};
