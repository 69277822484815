import React, { useContext, useEffect, useState } from 'react';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import { DoubleArrow } from '@mui/icons-material';

import useSessionStore from '../../hooks/useSessionStore';
import useToolsNavigation from '../../CoachTools/hooks/useToolsNavigation';
import FirebaseContext from '../../context/FirebaseContext';
import { withUserContextProvider, withUserContextReady } from '../../context/UserContext';

import StripeLogo from '../../assets/icons/stripe-logo.png';
import { ReactComponent as S2Logo } from '../../assets/s2-logo.svg';

import { withPageAnalytics } from '../../components/withAnalytics';
import { getQueryVariable } from '../../utils/queryParams';

import pages, { pagesConfig } from '../../CoachTools/config/pages';

import {
  Container,
  Body,
  StyledButton,
  StyledSpinner,
  ErrorMessage,
  IconsContainer,
  Icon,
} from './styles';

import texts from './texts.json';

const QueryParam = {
  STATE: 'state',
  AUTHORIZATION_CODE: 'code',
  ERROR_DESCRIPTION: 'error_description',
  ERROR: 'error',
};

const StripeCallback = () => {
  const [isReady, setIsReady] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { navigateToCoachTools } = useToolsNavigation();

  const { userId } = useSessionStore();
  const {
    firebase: {
      remote,
    },
  } = useContext(FirebaseContext);

  useEffect(() => {
    const connectAccount = async () => {
      const userIdFromState = getQueryVariable(QueryParam.STATE);
      const authorizationCode = getQueryVariable(QueryParam.AUTHORIZATION_CODE);
      const stripeError = getQueryVariable(QueryParam.ERROR);
      const stripeErrorDescription = getQueryVariable(QueryParam.ERROR_DESCRIPTION);

      if (stripeError) {
        setErrorMessage(`Error: ${stripeErrorDescription.replaceAll('+', ' ')}`);
      } else if (userIdFromState !== userId) {
        setErrorMessage(texts.userError);
      } else {
        try {
          const response = await remote('connectStripeAccount', { userId, authorizationCode });

          const { error } = await response.json();

          if (error) {
            setErrorMessage(error);
          } else {
            navigateToCoachTools(userId, pagesConfig[pages.CONFIG].path);
          }
        } catch (error) {
          setErrorMessage(error.toString());
        }
      }

      setIsReady(true);
    };

    if (!isReady && !!userId) {
      connectAccount();
    }
  }, [
    isReady,
    userId,
    remote,
    navigateToCoachTools,
  ]);

  const renderMessage = () => {
    if (errorMessage) {
      return (
        <>
          <ErrorMessage>{errorMessage}</ErrorMessage>
          <StyledButton onClick={() => navigateToCoachTools(userId, pagesConfig[pages.CONFIG].path)}>
            {texts.goBack}
          </StyledButton>
        </>
      );
    }

    return (
      <Body>{texts.connecting}</Body>
    );
  };

  return (
    <Container>
      <IconsContainer>
        <Icon src={StripeLogo} />
        <DoubleArrow width={60} />
        <S2Logo height={35} width={48} />
      </IconsContainer>
      {renderMessage()}
      {!isReady && (
        <StyledSpinner name="crescent" />
      )}
    </Container>
  );
};

export default compose(
  withPageAnalytics('stripeCallback'),
  withUserContextProvider,
  withUserContextReady,
  observer,
)(StripeCallback);
