import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import { Route, Redirect, useLocation } from 'react-router-dom';

import useSessionStore from '../../hooks/useSessionStore';

/**
 * A wrapper for <Route> that redirects to the signup
 * page if you're not yet authenticated.
 */
const PrivateRoute = ({
  component: Component,
  children,
  ...rest
}) => {
  const { authUser } = useSessionStore();
  const location = useLocation();
  const { search, state } = location;

  return (
    <Route
      {...rest}
      render={(props) => {
        if (authUser) {
          return Component ? <Component {...props} /> : children;
        }

        // Redirect to the default page
        return (
          <Redirect to={{
            pathname: '/login',
            search,
            state,
          }}
          />
        );
      }}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.elementType,
  children: PropTypes.node,
};

PrivateRoute.defaultProps = {
  children: null,
  component: null,
};

export default compose(
  observer,
)(PrivateRoute);
