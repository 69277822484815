import React from 'react';
import PropTypes from 'prop-types';

import {
  EditWrapper,
  EditLabel,
  EditContent,
  EditSubLabel,
} from './styles';

const EditItem = ({
  label,
  subLabel,
  children,
  noMarginsOnLabel,
}) => (
  <EditWrapper>
    <EditLabel $noMargins={noMarginsOnLabel}>{label}</EditLabel>
    <EditContent>{children}</EditContent>
    {!!subLabel && <EditSubLabel>{subLabel}</EditSubLabel>}
  </EditWrapper>
);

EditItem.propTypes = {
  label: PropTypes.string,
  noMarginsOnLabel: PropTypes.bool,
  subLabel: PropTypes.string,
  children: PropTypes.node.isRequired,
};

EditItem.defaultProps = {
  label: '',
  subLabel: '',
  noMarginsOnLabel: false,
};

export default EditItem;
