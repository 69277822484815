import * as Yup from 'yup';
import { isValidPhoneNumber } from '../../../utils/validators';

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('First Name is required'),
  lastName: Yup.string()
    .required('Last Name is required'),
  email: Yup.string()
    .email('Email is invalid')
    .required('Email is required'),
  password: Yup.string()
    .min(6, 'Password must be at least 6 characters')
    .required('Password is required'),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  phoneNumber: Yup.string()
    .required('Phone Number is required')
    .test('isPhoneNumber', 'Enter a valid phone number', isValidPhoneNumber),
});

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  passwordConfirmation: '',
  phoneNumber: '',
  acceptTerms: false,
};

export { validationSchema, initialValues };
