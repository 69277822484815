import React, {
  useState,
  useMemo,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import format from 'string-template';
import moment from 'moment';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Radio,
  RadioGroup,
  FormControlLabel,
  TextField,
} from '@mui/material';

import { DateFormat } from '../../../../../../utils/date';
import User from '../../../../../../Model/User';
import UserContract from '../../../../../../Model/UserContract';
import { ContractMinimumValue, getRemainingPaymentCount } from '../../../../../utils/userContract';

import texts from './texts.json';
import {
  StyledPaper,
  Container,
  OptionsContainer,
} from './styles';

const cancelType = {
  IMMEDIATELY: 'IMMEDIATELY',
  END_OF_CURRENT_PERIOD: 'END_OF_CURRENT_PERIOD',
  END_OF_COMMITMENT: 'END_OF_COMMITMENT',
};

const SubscriptionCancelDialog = ({
  isOpen,
  updateClientSubscription,
  cancelClientSubscription,
  onCancel,
  dialogTexts,
  subscription,
  userContractDoc,
  userDoc,
}) => {
  const [selectedCancelType, setSelectedCancelType] = useState(cancelType.IMMEDIATELY);
  const [cancellationReason, setCancellationReason] = useState('');

  const cancelDate = useMemo(() => {
    const monthsToAdd = userContractDoc?.minSubscriptionMonths || ContractMinimumValue.MIN_SUBSCRIPTION_MONTHS;
    return {
      [cancelType.IMMEDIATELY]: moment(),
      [cancelType.END_OF_CURRENT_PERIOD]: moment.unix(subscription.current_period_end),
      [cancelType.END_OF_COMMITMENT]: userDoc.serviceStartAt
        ? moment(userDoc.serviceStartAt).add(monthsToAdd, 'months')
        : moment.unix(subscription.created).add(monthsToAdd, 'months'),
    };
  }, [
    subscription,
    userContractDoc,
    userDoc,
  ]);

  const remainingPayments = useMemo(() => getRemainingPaymentCount(userContractDoc, userDoc, subscription),
    [
      subscription,
      userContractDoc,
      userDoc,
    ]);

  const handleConfirm = useCallback(async () => {
    switch (selectedCancelType) {
      case cancelType.IMMEDIATELY:
        await cancelClientSubscription(cancellationReason, selectedCancelType);
        break;
      case cancelType.END_OF_CURRENT_PERIOD:
        await updateClientSubscription({
          cancel_at_period_end: true,
        }, cancellationReason, selectedCancelType);
        break;
      case cancelType.END_OF_COMMITMENT:
        await updateClientSubscription({
          cancel_at: cancelDate[cancelType.END_OF_COMMITMENT].unix(),
        }, cancellationReason, selectedCancelType);
        break;

      default:
        break;
    }
  }, [
    cancelClientSubscription,
    updateClientSubscription,
    selectedCancelType,
    cancellationReason,
    cancelDate,
  ]);

  return (
    <Dialog
      open={isOpen}
      PaperComponent={StyledPaper}
    >
      <DialogTitle>
        {dialogTexts.title}
      </DialogTitle>
      <DialogContent>
        {texts.cancel}
        <OptionsContainer>
          <RadioGroup
            value={selectedCancelType}
            onChange={(evt) => setSelectedCancelType(evt.target.value)}
          >
            {Object.keys(cancelType).map((type) => cancelDate[type] >= cancelDate[cancelType.IMMEDIATELY]
              && (
                <FormControlLabel
                  key={type}
                  value={type}
                  control={<Radio />}
                  label={format(texts.cancelTypeLabel[type], {
                    date: cancelDate[type].format(DateFormat.MONTH_NAME_DATE_FORMAT),
                    remainingPayments: remainingPayments > 0 ? remainingPayments : 'No',
                  })}
                />
              ))}
          </RadioGroup>
        </OptionsContainer>
        <Container>
          <TextField
            label={texts.reason}
            variant="outlined"
            onChange={(event) => setCancellationReason(event.target.value)}
            value={cancellationReason}
            fullWidth
            required
            error={!cancellationReason}
            helperText={!cancellationReason ? texts.required : ''}
          />
        </Container>
        <DialogContentText>
          {dialogTexts.content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleConfirm}
          color="success"
          disabled={!cancellationReason}
        >
          {dialogTexts.confirm || texts.confirm}
        </Button>
        <Button
          onClick={onCancel}
          color="warning"
        >
          {dialogTexts.cancel || texts.cancel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

SubscriptionCancelDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  dialogTexts: PropTypes.shape({
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    confirm: PropTypes.string,
    cancel: PropTypes.string,
  }).isRequired,
  subscription: PropTypes.object.isRequired,
  cancelClientSubscription: PropTypes.func.isRequired,
  updateClientSubscription: PropTypes.func.isRequired,
  userDoc: PropTypes.instanceOf(User).isRequired,
  userContractDoc: PropTypes.instanceOf(UserContract),
};

SubscriptionCancelDialog.defaultProps = {
  userContractDoc: null,
};

export default SubscriptionCancelDialog;
