import { Stack, TextField } from '@mui/material';
import styled from 'styled-components';
import colors from '../../../../../../styles/colors';

const StyledTextField = styled(TextField)`
  width: 500px;  
  .MuiInputBase-root {
    background-color: ${colors.base.alpha};

    .MuiOutlinedInput-notchedOutline {
      border-color: ${colors.shades.secondary7};
      border-radius: 5px;
    }

    .MuiInputBase-input {
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      color: ${colors.shades.secondary8};
    }

    &.Mui-disabled {
      background-color: ${colors.shades.secondary5};

      .MuiInputBase-input {
        -webkit-text-fill-color: initial;
      }
    }
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

const InputContainer = styled(Stack)`
  display: flex;
  flex-direction: row;
`;

export {
  InputContainer,
  StyledTextField,
  TextContainer,
};
