import {
  Collection,
  getFirebase,
} from 'firestorter';
import Collections from '../../utils/collections';
import BaseDocument from '../../Model/BaseDocument';
import feedType, { mainFeedTypes } from '../context/FeedContext/config/feedTypes';

/**
 * Types that define activities based on the selected type when adding activities for the workout
 */
const ActivityType = {
  CIRCUIT: 'CIRCUIT',
};

const activityStatus = {
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED',
  REMOVED: 'REMOVED',
};

const activeActivityStatus = [
  activityStatus.IN_PROGRESS,
  activityStatus.COMPLETED,
];

class CoachActivity extends BaseDocument {
  constructor(id, opts) {
    super(`${Collections.COACH_ACTIVITY}/${id}`, opts);
  }

  get status() {
    return this.data.status;
  }

  get createdAt() {
    return this.data.createdAt?.toDate();
  }

  get time() {
    return this.data.time?.toDate();
  }

  get type() {
    return this.data.type;
  }

  get coach() {
    return this.data.coach;
  }

  get user() {
    return this.data.user;
  }

  get content() {
    return this.data.content;
  }

  get channelId() {
    return this.data.channelId;
  }

  get workoutAssignmentId() {
    return this.data.workoutAssignmentId;
  }

  get workoutVideoAvailable() {
    return this.data.workoutVideoAvailable;
  }

  get gameplaySessionId() {
    return this.data.gameplaySessionId;
  }

  get alertType() {
    return this.data.alertType;
  }

  get checkInData() {
    return this.data.checkInData || {};
  }

  get habitData() {
    return this.data.habitData || {};
  }

  get workoutData() {
    return this.data.workoutData || [];
  }

  get mealsSummary() {
    return this.data.mealsSummary || {};
  }

  get userName() {
    return this.data.userName;
  }

  get userAvatar() {
    return this.data.userAvatar;
  }

  get lastInteractionBy() {
    return this.data.lastInteractionBy;
  }

  get lastInteractionByUserName() {
    return this.data.lastInteractionByUserName;
  }

  get claimedBy() {
    return this.data.claimedBy;
  }

  get claimedByUserName() {
    return this.data.claimedByUserName;
  }

  get claimedAt() {
    return this.data.claimedAt?.toDate();
  }

  claimActivity = async (claimedBy, claimedByUserName) => {
    await this.updateFields({
      claimedBy,
      claimedByUserName,
      claimedAt: new Date(),
    });
  };

  unclaimActivity = async () => {
    await this.updateFields({
      claimedBy: getFirebase().firestore.FieldValue.delete(),
      claimedByUserName: getFirebase().firestore.FieldValue.delete(),
      claimedAt: getFirebase().firestore.FieldValue.delete(),
    });
  };

  static async getUnarchivedActivitiesForCoach(coach) {
    const activeActivitiesCollection = new Collection(Collections.COACH_ACTIVITY, {
      createDocument: ({ id }, opts) => new CoachActivity(id, { ...opts, disableObserverRefCount: true }),
      query: (ref) => ref
        .where('coach', '==', coach)
        .where('status', '!=', activityStatus.REMOVED)
        .where('type', 'in', mainFeedTypes)
        .orderBy('status', 'desc')
        .orderBy('time', 'desc'),
    });
    await activeActivitiesCollection.fetch();
    return activeActivitiesCollection;
  }

  static async getArchivedActivitiesForCoach(coach) {
    const activeActivitiesCollection = new Collection(Collections.COACH_ACTIVITY, {
      createDocument: ({ id }, opts) => new CoachActivity(id, { ...opts, disableObserverRefCount: true }),
      query: (ref) => ref
        .where('coach', '==', coach)
        .where('type', '==', feedType.ARCHIVED)
        .where('status', 'in', activeActivityStatus)
        .orderBy('type', 'desc')
        .orderBy('time', 'desc')
        .limit(50),
    });
    await activeActivitiesCollection.fetch();
    return activeActivitiesCollection;
  }

  static async getUserClaimedActivity(userId) {
    const activeActivitiesCollection = new Collection(Collections.COACH_ACTIVITY, {
      createDocument: ({ id }, opts) => new CoachActivity(id, { ...opts, disableObserverRefCount: true }),
      query: (ref) => ref
        .where('claimedBy', '==', userId)
        .where('status', '==', activityStatus.IN_PROGRESS)
        .limit(1),
    });
    await activeActivitiesCollection.fetch();
    return activeActivitiesCollection.hasDocs ? activeActivitiesCollection.docs[0] : null;
  }
}

export default CoachActivity;
export {
  ActivityType,
};
