import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';

import { ModalContainer } from './styles';

const Modal = ({ className, ...otherProps }) => (
  <ModalContainer
    cssClass={className}
    {...otherProps}
  />
);

Modal.propTypes = {
  className: PropTypes.string,
};

Modal.defaultProps = {
  className: '',
};

export default styled(Modal)``;
