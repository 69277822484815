import styled from 'styled-components';
import { FieldArray } from 'formik';

const StyledFieldArray = styled(FieldArray)`
  display: flex;
  flex-direction: column;
`;

export {
  StyledFieldArray,
};
