/**
 * Formats a number using the given opts
 * @param {number} number The number to format
 * @param {Object} [opts={}] The optional formatting config
 * @param {number} [opts.minimumFractionDigits=0] The min number of decimals
 * @param {number} [opts.maximumFractionDigits=2] The max number of decimals
 * @returns {string} The formatted number
 */
export const formatNumber = (number, {
  minimumFractionDigits = 0,
  maximumFractionDigits = 2,
  ...otherProps
}) => (
  new Intl.NumberFormat('en-US', {
    minimumFractionDigits,
    maximumFractionDigits,
    useGrouping: false,
    ...otherProps,
  })).format(number);

export const formatCurrency = (sum, currency = 'usd', opts) => formatNumber(sum, {
  style: 'currency',
  currency,
  ...opts,
});

export const formatCurrencyCents = (sum, currency = 'usd', opts) => formatCurrency(sum / 100, currency, opts);

export const formatAmoutInCents = (sum, opts) => formatNumber(sum / 100, {
  ...opts,
});

export const getCurrencySymbol = (currency = 'usd') => formatNumber(0, {
  style: 'currency',
  currency,
}).replace(/\d/g, '').trim();
