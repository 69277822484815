import styled from 'styled-components';
import { FormControl, Select } from '@mui/material';
import colors from '../../../../styles/colors';

const InputsContainer = styled.div`
  display: flex;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const PlanSelectionFormControl = styled(FormControl)`
  min-width: 350px;
  box-sizing: content-box;
`;

const StyledSelect = styled(Select)`
  background: white;
`;

const Title = styled.div`
  font-weight: 400;
  font-size: 13px;
`;

const Feature = styled.li`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-decoration:${(props) => (!props.enabled ? 'line-through' : '')};
  color:${(props) => (!props.enabled ? colors.shades.gamma10 : '')};
`;

export {
  Container,
  InputsContainer,
  PlanSelectionFormControl,
  StyledSelect,
  Title,
  Feature,
};
