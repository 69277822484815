import styled from 'styled-components';
import { Alert, AppBar, Toolbar } from '@mui/material';
import colors from '../../../../styles/colors';

const APP_BAR_HEIGHT = '64';
const APP_BAR_HEIGHT_WITH_WARNING = '48';

const StyledAppBar = styled(AppBar)(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: theme.drawer.width,
    width: `calc(100% - ${theme.drawer.width}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  maxHeight: `${APP_BAR_HEIGHT}px`,
}));

const StyledAlert = styled(Alert)`
  background: ${colors.shades.danger5};
  color: ${colors.base.alpha};
  min-height: 25px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    padding: 0;
  }
  svg {
    height: 17px;
    color: ${colors.base.alpha};
  }
`;

const StyledToolbar = styled(Toolbar)(({ $hasWarning }) => ({
  minHeight: $hasWarning ? `${APP_BAR_HEIGHT_WITH_WARNING}px` : `${APP_BAR_HEIGHT}px`,
}));

export {
  StyledAppBar,
  StyledAlert,
  StyledToolbar,
  APP_BAR_HEIGHT,
};
