import { Collection } from 'firestorter';

import BaseDocument from '../../Model/BaseDocument';
import Collections from '../../utils/collections';

class ClientMealAssignmentView extends BaseDocument {
  constructor(id, opts) {
    super(`${Collections.CLIENT_MEAL_ASSIGNMENT_VIEW}/${id}`, opts);
  }

  get userAvatar() {
    return this.data.userAvatar;
  }

  get userName() {
    return this.data.userName;
  }

  get isActive() {
    return !!this.data.isUserActive;
  }

  get breakEndDate() {
    return this.data.breakEndDate?.toDate();
  }

  get serviceStartAt() {
    return this.data.serviceStartAt?.toDate();
  }

  get assignmentName() {
    return this.data.assignmentName || '';
  }

  get productName() {
    return this.data.productName || '';
  }

  get assignmentId() {
    return this.data.assignmentId || '';
  }

  get coach() {
    return this.data.coach;
  }

  get lastUpdated() {
    return this.data.lastUpdated;
  }

  get type() {
    return this.data.type;
  }

  get calculatedDailyCalories() {
    return this.data.calculatedDailyCalories;
  }

  get totalDailyCalories() {
    return this.data.totalDailyCalories;
  }

  get updatedMacroAveragesAt() {
    return this.data.updatedMacroAveragesAt;
  }

  get dietaryRestrictions() {
    return this.data.dietaryRestrictions || [];
  }

  get assignmentMacroAverages() {
    return this.data.assignmentMacroAverages;
  }

  static async getClientMealAssignmentView(clientMealAssignmentViewId) {
    const clientMealAssignmentViewDoc = new ClientMealAssignmentView(clientMealAssignmentViewId);
    await clientMealAssignmentViewDoc.init();
    return clientMealAssignmentViewDoc;
  }

  static async getActiveClientMealAssignmentViewsByCoach(coach) {
    const clientMealAssignmentViewsCollection = new Collection(Collections.CLIENT_MEAL_ASSIGNMENT_VIEW, {
      createDocument: ({ id }, opts) => new ClientMealAssignmentView(id, opts),
      query: (ref) => ref
        .where('coach', '==', coach)
        .where('isUserActive', '==', true)
        .where('isMealPlanEnabled', '==', true),
    });
    await clientMealAssignmentViewsCollection.fetch();
    return clientMealAssignmentViewsCollection;
  }
}

export default ClientMealAssignmentView;
