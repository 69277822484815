import styled from 'styled-components';
import { Typography } from '@mui/material';

import colors from '../../../../styles/colors';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
`;

const SecondaryText = styled(Typography)`
  line-height: 12px;
  color: ${colors.shades.gamma2};
  font-size: 13px;
  margin-bottom: ${(props) => (props.$withMargin ? '10px' : '0')};
`;

export {
  Container,
  SecondaryText,
};
