import React, {
  useCallback,
  useMemo,
  useEffect,
  useState,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

import { IconButton } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

import { ReactComponent as DragIcon } from '../../../../../../assets/icons/v2/drag.svg';

import {
  fieldName,
  ingredientField,
} from '../../formFields';

import { AllergenTag, AllergenTagText } from '../../../../../utils/meals';
import { AttentionTag } from '../../../../../../components/Tags';
import { StyledTrashIcon } from '../../styles';

import {
  IngredientContainer,
  HeaderContainer,
  IngredientHeader,
  StyledTypography,
  AllergensLabel,
  StyledFilter,
  SmallFormInput,
  BigFormInput,
  FieldsContainer,
  AllergensContainer,
  AllergensSubContainer,
  AllergenTagContainer,
} from './styles';

import texts from './texts';

import configs from './configs';

const allergenValues = Object.values(AllergenTag).map((tag) => ({
  value: tag,
  label: AllergenTagText[tag],
}));

const IngredientsRow = ({
  index,
  removeRow,
}) => {
  const { values, setFieldValue } = useFormikContext();

  const [allergenOptions, setAllergenOptions] = useState(
    values[fieldName.INGREDIENTS][index][ingredientField.ALLERGEN_TAGS],
  );

  const allergenRef = useRef(null);

  const memoizedTags = useMemo(() => allergenOptions.map((option) => (
    <AttentionTag key={uuidv4()}>
      {AllergenTagText[AllergenTag[option]]}
    </AttentionTag>
  )), [allergenOptions]);

  const handleChange = useCallback((options) => {
    setAllergenOptions(options);
  }, []);

  const fieldBaseName = `${fieldName.INGREDIENTS}.${index}`;

  useEffect(() => {
    const prevAllergenOptions = allergenRef?.current;

    if (prevAllergenOptions && (allergenOptions.length !== prevAllergenOptions.length)) {
      // selected allergens have been changed for the ingredient
      const currentTags = values[fieldName.INGREDIENTS][index][ingredientField.ALLERGEN_TAGS];
      let updatedValues = null;

      if (allergenOptions.length > prevAllergenOptions.length) {
        // new allergens have been selected. add them to values
        const addedOptions = allergenOptions.filter((option) => !prevAllergenOptions.includes(option));
        updatedValues = [...currentTags, ...addedOptions];
      } else if (allergenOptions.length < prevAllergenOptions.length) {
        // allergens have been unselected. remove the changes from values
        const removedOptions = prevAllergenOptions.filter((option) => !allergenOptions.includes(option));
        updatedValues = currentTags.filter((item) => !removedOptions.includes(item));
      }

      setFieldValue(`${fieldBaseName}.${ingredientField.ALLERGEN_TAGS}`, updatedValues);
    }

    allergenRef.current = allergenOptions;
  }, [
    allergenOptions,
    index,
    values,
    setFieldValue,
    fieldBaseName,
  ]);

  return (
    <IngredientContainer>
      <HeaderContainer>
        <IconButton className="drag-handle">
          <DragIcon />
        </IconButton>
        <IngredientHeader>{texts.addIngredient}</IngredientHeader>
        <IconButton onClick={removeRow}>
          <StyledTrashIcon />
        </IconButton>
      </HeaderContainer>
      <FieldsContainer>
        <SmallFormInput
          name={`${fieldBaseName}.${ingredientField.AMOUNT}`}
          label={`${texts.field[ingredientField.AMOUNT].label}:`}
        />
        <SmallFormInput
          name={`${fieldBaseName}.${ingredientField.UNIT}`}
          label={`${texts.field[ingredientField.UNIT].label}:`}
        />
        <StyledTypography>(</StyledTypography>
        <SmallFormInput
          name={`${fieldBaseName}.${ingredientField.SECOND_AMOUNT}`}
          label={`${texts.field[ingredientField.AMOUNT].label}:`}
        />
        <SmallFormInput
          name={`${fieldBaseName}.${ingredientField.SECOND_UNIT}`}
          label={`${texts.field[ingredientField.UNIT].label}:`}
        />
        <StyledTypography>)</StyledTypography>
        <BigFormInput
          name={`${fieldBaseName}.${ingredientField.NAME}`}
          label={`${texts.field[ingredientField.NAME].label}:`}
        />
        <BigFormInput
          name={`${fieldBaseName}.${ingredientField.PREPARATION_COMMENT}`}
          label={`${texts.field[ingredientField.PREPARATION_COMMENT].label}:`}
        />
      </FieldsContainer>
      <AllergensContainer>
        <AllergensLabel>{`${texts.allergensTitle}:`}</AllergensLabel>
        <AllergensSubContainer>
          <StyledFilter
            options={allergenValues}
            initialValues={allergenOptions}
            onValuesSelected={handleChange}
            className={configs.classNames.filter}
            filterIcon={null}
            disableValueRender
          />
          <AllergenTagContainer>{memoizedTags}</AllergenTagContainer>
        </AllergensSubContainer>
      </AllergensContainer>
    </IngredientContainer>
  );
};

IngredientsRow.propTypes = {
  index: PropTypes.number.isRequired,
  removeRow: PropTypes.func.isRequired,
};

export default IngredientsRow;
