import pages, { pagesConfig } from '../../config/pages';

import useToolsNavigation from '../../hooks/useToolsNavigation';

const useAssistantCoachSideBarConfig = () => {
  const { navigateTo } = useToolsNavigation();

  const entries = [
    {
      pageId: pages.AC_DASHBOARD,
      icon: pagesConfig[pages.AC_DASHBOARD].icon,
      onClick: () => navigateTo(pagesConfig[pages.AC_DASHBOARD].path),
      title: pagesConfig[pages.AC_DASHBOARD].title,
    },
  ];

  return {
    entries,
  };
};

export default useAssistantCoachSideBarConfig;
