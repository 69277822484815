import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Alert } from '@mui/material';
import format from 'string-template';

import { CardsContainer } from '../../../../components/v2/Card';
import useComponentMounted from '../../../../hooks/useComponentMounted';
import useToast from '../../../hooks/useToast';
import LoadingOverlay from '../../../components/LoadingOverlay';
import ConfirmationModal from '../../../../components/v2/ConfirmationModal';
import { isInsideSalesAdmin } from '../../../../utils/postHog';
import { StyledButton } from './styles';
import texts from './texts.json';

const NonISCoachView = ({ coachDoc }) => {
  const [isSaving, setIsSaving] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const isComponentMountedRef = useComponentMounted();
  const { showToast } = useToast();
  const isReadOnly = !isInsideSalesAdmin();

  const onISEnable = async () => {
    setIsSaving(true);
    await coachDoc.updateFields({ isEnrolledInISProgram: true });
    if (isComponentMountedRef.current) {
      setIsSaving(false);
    }
    showToast(texts.enabled, { type: 'success' });
  };

  return (
    <CardsContainer $fullWidth>
      <Alert severity="warning">{texts.note}</Alert>
      <StyledButton onClick={() => setShowConfirmation(true)} disabled={isReadOnly}>
        {texts.enroll}
      </StyledButton>
      <LoadingOverlay isLoading={isSaving} />
      {showConfirmation && (
        <ConfirmationModal
          open
          onClose={() => setShowConfirmation(false)}
          onConfirm={onISEnable}
          title={format(texts.confirmationTitle, { coachName: coachDoc.name })}
          description={format(texts.confirmationMessage, { coachName: coachDoc.name })}
        />
      )}
    </CardsContainer>
  );
};

NonISCoachView.propTypes = {
  coachDoc: PropTypes.object,
};

NonISCoachView.defaultProps = {
  coachDoc: null,
};

export default NonISCoachView;
