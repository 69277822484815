import styled from 'styled-components';

import colors from '../../styles/colors';

const Container = styled.div`
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  position: absolute;
  max-width: unset;
  margin-top: 70px;
  overflow: hidden;
  background-color: ${colors.base.alpha};
`;

const HeaderlessContainer = styled(Container)`
  margin-top: 0;
`;

const ScrollableArea = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export {
  Container,
  HeaderlessContainer,
  ScrollableArea,
};
