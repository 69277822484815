import React from 'react';
import PropTypes from 'prop-types';

import ToolsLayout from '../ToolsLayout';
import AssistantCoachSidebar from './AssistantCoachSidebar';

const AssistantCoachToolsLayout = ({
  children,
}) => (
  <ToolsLayout sidebarComponent={<AssistantCoachSidebar />}>
    {children}
  </ToolsLayout>
);

AssistantCoachToolsLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AssistantCoachToolsLayout;
