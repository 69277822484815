import React from 'react';

import {
  ContractType,
  DefaultContract,
} from '../../../utils/userContract';
import { ContractState } from '../utils/ContractTypes';

const initialValues = {
  coachId: '',
  isLoadingData: false,
  isLoadingContract: false,
  coachDoc: null,
  externalCoachDoc: null,
  products: [],
  contract: { ...DefaultContract },
  previousContract: null,
  contractDoc: null,
  contractState: ContractState.CONFIGURATION,
  isSaving: false,
  lead: {},
  user: {},
  contractType: ContractType.USER,
  isReonboardingContract: false,
  latestContract: {},
};

const ContractModalContext = React.createContext(initialValues);

export default ContractModalContext;
export {
  initialValues,
};
