import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { compose } from 'recompose';

import Coach from '../../../../Model/Coach';
import useComponentMounted from '../../../../hooks/useComponentMounted';
import { CardsContainer } from '../../../../components/v2/Card';
import NonISCoachView from '../NonISCoachView';
import AutomatedMessages from './AutomatedMessages';
import DisqualificationSettings from './DisqualificationSettings';
import LeadSubmissionSettings from './LeadSubmissionSettings';

const LeadConfigurations = ({ coach: { id: coachId } }) => {
  const [coachDoc, setCoachDoc] = useState(null);
  const isComponentMountedRef = useComponentMounted();

  useEffect(() => {
    const init = async () => {
      setCoachDoc(null);
      if (coachId) {
        const coachDocument = await Coach.getCoachById(coachId);
        if (isComponentMountedRef.current) {
          setCoachDoc(coachDocument);
        }
      }
    };
    init();
  }, [coachId, isComponentMountedRef]);

  if (coachDoc && !coachDoc.isEnrolledInISProgram) {
    return <NonISCoachView coachDoc={coachDoc} />;
  }

  return (
    <CardsContainer $fullWidth>
      <AutomatedMessages coachDoc={coachDoc} />
      <DisqualificationSettings coachDoc={coachDoc} />
      <LeadSubmissionSettings coachId={coachDoc?.id} />
    </CardsContainer>
  );
};

LeadConfigurations.propTypes = {
  coach: PropTypes.object,
};

LeadConfigurations.defaultProps = {
  coach: {},
};

export default compose(
  observer,
)(LeadConfigurations);
