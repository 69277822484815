import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import { DatePicker } from '@mui/lab';
import moment from 'moment';
import format from 'string-template';

import User from '../../../../../Model/User';
import UserNutritionLog from '../../../../Model/UserNutritionLog';
import useComponentMounted from '../../../../../hooks/useComponentMounted';
import LoadingPage from '../../../../../components/LoadingPage';
import { DateFormat } from '../../../../../utils/date';
import NutritionalStats from './NutritionalStats';
import FoodSummary from './FoodSummary';

import {
  Container,
  DateFilterWrapper,
  FilterWrapper,
  StyledFormHelperText,
  FilterInformation,
  FilterTitle,
  FilterDescription,
  FilterDates,
  StyledTextField,
} from './styles';
import texts from './texts.json';

// TODO: Update with date range period selector(component).
const FromDateDifference = -7;

const FoodLog = ({ userDoc }) => {
  const [userNutritionLogsCollection, setUserNutritionLogsCollection] = useState();
  const [fromDate, setFromDate] = useState(moment().add(FromDateDifference, 'days'));
  const [toDate, setToDate] = useState(moment());
  const [isReady, setIsReady] = useState(false);
  const isComponentMountedRef = useComponentMounted();

  const dateRangeText = format(texts.nutritionStatsDescription, {
    fromDate: fromDate.format(DateFormat.DATE_MONTH_SHORT_DISPLAY_FORMAT),
    toDate: toDate.format(DateFormat.DATE_MONTH_SHORT_DISPLAY_FORMAT),
  });

  const filteredLogs = useMemo(() => {
    if (userNutritionLogsCollection) {
      return userNutritionLogsCollection.docs.filter(({ startTime }) => (
        moment(startTime.toDate()).isBetween(fromDate, toDate, 'day', '[]')
      ));
    }
    return [];
  }, [
    fromDate,
    toDate,
    userNutritionLogsCollection,
  ]);

  useEffect(() => {
    const init = async () => {
      const {
        id: userId,
      } = userDoc;
      const nutritionLogsCollection = await UserNutritionLog.getUserNutritionLogs(userId);

      if (isComponentMountedRef.current) {
        setUserNutritionLogsCollection(nutritionLogsCollection);
        setIsReady(true);
      }
    };
    if (!isReady) {
      init();
    }
  }, [
    userDoc,
    isReady,
    isComponentMountedRef,
  ]);

  if (!isReady) {
    return <LoadingPage />;
  }

  return (
    <Container>
      <FilterWrapper>
        <FilterInformation>
          <FilterTitle>
            {texts.nutritionStats}
          </FilterTitle>
          <FilterDescription>
            {dateRangeText}
          </FilterDescription>
        </FilterInformation>
        <FilterDates>
          <DateFilterWrapper>
            <DatePicker
              value={fromDate}
              inputFormat={DateFormat.DATE_YEAR_MONTH_SHORT_DISPLAY_FORMAT}
              onChange={(newFromDate) => setFromDate(newFromDate)}
              maxDate={toDate}
              renderInput={(params) => (
                <>
                  <StyledFormHelperText>{texts.date.from}</StyledFormHelperText>
                  <StyledTextField {...params} onKeyDown={(e) => e.preventDefault()} variant="outlined" />
                </>
              )}
            />
          </DateFilterWrapper>
          <DateFilterWrapper>
            <DatePicker
              value={toDate}
              inputFormat={DateFormat.DATE_YEAR_MONTH_SHORT_DISPLAY_FORMAT}
              onChange={(newToDate) => setToDate(newToDate)}
              minDate={fromDate}
              renderInput={(params) => (
                <>
                  <StyledFormHelperText>{texts.date.to}</StyledFormHelperText>
                  <StyledTextField {...params} onKeyDown={(e) => e.preventDefault()} variant="outlined" />
                </>
              )}
            />
          </DateFilterWrapper>
        </FilterDates>
      </FilterWrapper>
      <NutritionalStats
        user={userDoc.id}
        nutritionLogs={filteredLogs}
      />
      <FoodSummary
        nutritionLogs={filteredLogs}
        dateRange={dateRangeText}
      />
    </Container>
  );
};

FoodLog.propTypes = {
  userDoc: PropTypes.instanceOf(User).isRequired,
};

export default compose(
  observer,
)(FoodLog);
