import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as ReviewIcon } from '../../../../../assets/icons/v2/arrow-right.svg';
import { PrimaryButton } from '../../../../../components/Button/ActionButtons';

import {
  Container,
} from './styles';
import texts from './texts.json';

const EditAction = ({
  data,
  navigateToRecipe,
}) => (
  <Container>
    <PrimaryButton
      onClick={() => navigateToRecipe(data.id)}
      endIcon={<ReviewIcon />}
      variant="info"
      size="medium"
    >
      {texts.edit}
    </PrimaryButton>
  </Container>
);

EditAction.propTypes = {
  data: PropTypes.object.isRequired,
  navigateToRecipe: PropTypes.func.isRequired,
};

export default EditAction;
