import styled from 'styled-components';

import { IconButton } from '@mui/material';

import colors from '../../../../../../../styles/colors';
import Button from '../../../../../../../components/Button';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  margin: 10px 0;
  align-items: center;
  column-gap: 10px;
  background: ${colors.base.alpha};
  padding: 6px;
  border-radius: 60px;
  border: 1px solid ${colors.shades.secondary4};
  overflow-x: auto;
  overflow-y: hidden;
`;

const AudioRecorderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 5px;
  align-items: center;
`;

const AudioPlayerWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  justify-content: space-between;
  align-items: center;
`;

const StyledAudioPlayer = styled.audio`
  width: 100%;
  height: 32px;
`;

const StyledButton = styled(Button)`
  background-color: ${colors.shades.secondary4};
  color: ${colors.shades.secondary3};
  border: 1px solid ${colors.shades.secondary6};
  padding: 0 5px;
  height: 32px;
  flex-shrink: 0;
  width: fit-content;
  font-size: 13px;
  line-height: 30px;
  font-weight: 500;
  border-radius: 70px;
`;

const StyledIconButton = styled(IconButton)`
  height: 32px;
  aspect-ratio: 1;
  width: auto;
  color: ${colors.base.alpha};
  background-color: ${colors.base.secondary};
  &:hover {
    background-color: ${colors.shades.secondary2};
  }
`;

export {
  Container,
  AudioRecorderWrapper,
  StyledIconButton,
  StyledAudioPlayer,
  StyledButton,
  AudioPlayerWrapper,
};
