import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import format from 'string-template';
import moment from 'moment';
import { autorun } from 'mobx';
import {
  Tooltip,
} from '@mui/material';

import { ReactComponent as DuplicateIcon } from '../../../../../../assets/icons/v2/duplicate.svg';
import { ReactComponent as StarsIcon } from '../../../../../../assets/icons/v2/stars.svg';
import { ReactComponent as UpdateIcon } from '../../../../../../assets/icons/v2/arrow-right.svg';
import { PrimaryButton } from '../../../../../../components/Button/ActionButtons';
import { DateFormat } from '../../../../../../utils/date';
import useQuickSearch from '../../../../../../hooks/useQuickSearch';
import useToast from '../../../../../hooks/useToast';
import QuickSearchToolbar from '../../../../../components/QuickSearchToolbar';
import MealPlanContext, { withMealPlanContextReady } from '../../../../../context/MealPlanContext';
import MealPlan, { MealPlanType } from '../../../../../Model/MealPlan';
import { DietaryRestrictionText } from '../../../../../Model/UserNutritionProfile';
import { RestrictionAllergens } from '../../../../../utils/meals';
import LabelCheckbox from '../../../../../components/LabelCheckbox';
import MultiSelectFilter from '../../../../../components/MultiSelectFilter';
import LoadingOverlay from '../../../../../components/LoadingOverlay';
import GenericDataGrid from '../../../../../components/GenericDataGrid';
import ArchiveModal from '../ArchiveModal';
import MealTimesPopper, { PopperId } from '../MealTimesPopper';
import {
  AttentionTag,
  DarkTag,
  InfoTag,
} from '../../../../../../components/Tags';

import {
  ActiveStatus,
  InactiveStatus,
  ActionButtonContainer,
  StyledArchiveIcon,
  TagList,
  NoMealPlansMessage,
  MealTimesCell,
} from './styles';
import texts from './texts.json';
import getColumns from './columns';

const restrictionOptionValues = Object.entries(DietaryRestrictionText).map(([value, label]) => ({
  value,
  label,
}));

const MealPlansTable = ({ onSelectMealPlan }) => {
  const [tableRows, setTableRows] = useState([]);
  const [mealPlanToArchive, setMealPlanToArchive] = useState(null);
  const [restrictionOptions, setRestrictionOptions] = useState([]);
  const [excludedAllergens, setExcludedAllergens] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [popperMealTimesSummary, setPopperMealTimesSummary] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const {
    isLoading: isMealPlanContextLoading,
    mealPlanViewsCollection,
    refetchMealPlanViews,
    showArchivedMealPlans,
  } = useContext(MealPlanContext);

  const { showToast } = useToast();

  const handleArchivedCheckbox = useCallback((showArchived) => {
    refetchMealPlanViews(showArchived);
  }, [refetchMealPlanViews]);

  const handlePopperState = useCallback((event, mealTimesSummary) => {
    setPopperMealTimesSummary(mealTimesSummary);
    setAnchorEl((prevAnchor) => (prevAnchor ? null : event.target));
  }, []);

  useEffect(() => {
    const disposer = autorun(() => {
      const rows = [];

      mealPlanViewsCollection.docs.forEach(
        ({
          id,
          name,
          type,
          meals,
          numberOfMealTimes,
          mealTimesSummary,
          macroAverages,
          isArchived,
          allergenTags,
          lastUpdated,
        }) => {
          rows.push({
            id,
            name,
            numberOfMealTimes,
            mealTimesSummary,
            meals,
            isArchived,
            percentages: macroAverages?.percentages || {},
            allergenTags,
            lastUpdated: moment(lastUpdated.toDate()).format(DateFormat.DATE_FORMAT_COMMA),
            type,
          });
        },
      );

      setTableRows(rows);
    });

    return () => disposer();
  }, [mealPlanViewsCollection]);

  const renderMacroBreakdown = useCallback(
    ({ row: { type, percentages } = {} }) => {
      if (type === MealPlanType.LIVE) {
        return (<DarkTag icon={StarsIcon}>{texts.liveMealPlan}</DarkTag>);
      }
      return (
        <TagList>
          <InfoTag>{`${percentages.protein}% ${texts.protein}`}</InfoTag>
          <InfoTag>{`${percentages.carbs}% ${texts.carbs}`}</InfoTag>
          <InfoTag>{`${percentages.fat}% ${texts.fat}`}</InfoTag>
        </TagList>
      );
    },
    [],
  );

  const renderActionCell = useCallback(
    ({
      row: {
        id: mealPlanViewId,
        isArchived,
      } = {},
    }) => {
      const onDuplicateMealPlan = async () => {
        setIsLoading(true);
        const mealPlan = await MealPlan.getMealPlan(mealPlanViewId);
        MealPlan.addDoc(
          {
            ...mealPlan.data,
            name: `${mealPlan.data.name} (${texts.copy})`,
          },
        );
        setIsLoading(false);
        showToast(texts.duplicateSuccess, { type: 'success' });
      };

      const onClick = async ({ isArchiveClick = false }) => {
        setIsLoading(true);
        const mealPlan = await MealPlan.getMealPlan(mealPlanViewId);
        setIsLoading(false);
        if (isArchiveClick) {
          setMealPlanToArchive(mealPlan);
        } else {
          onSelectMealPlan(mealPlan);
        }
      };

      return (
        <ActionButtonContainer>
          {!isArchived
            && (
              <PrimaryButton
                onClick={() => onClick({ isArchiveClick: true })}
                icon={<StyledArchiveIcon />}
                variant="info"
                size="medium"
              >
                {texts.buttons.archive}
              </PrimaryButton>
            )}
          <PrimaryButton
            onClick={onDuplicateMealPlan}
            icon={<DuplicateIcon />}
            variant="info"
            size="medium"
          >
            {texts.buttons.duplicate}
          </PrimaryButton>
          <PrimaryButton
            onClick={onClick}
            icon={<UpdateIcon />}
            variant="info"
            size="medium"
          >
            {texts.buttons.update}
          </PrimaryButton>
        </ActionButtonContainer>
      );
    },
    [
      onSelectMealPlan,
      setIsLoading,
      showToast,
    ],
  );

  const renderStatusCell = useCallback(
    ({ row: { isArchived } = {} }) => (!isArchived ? <ActiveStatus /> : <InactiveStatus />),
    [],
  );

  const renderMealTimesCell = useCallback(
    ({ row: { numberOfMealTimes, mealTimesSummary } = {} }) => (
      <MealTimesCell
        aria-describedby={PopperId}
        onMouseOver={(event) => handlePopperState(event, mealTimesSummary)}
        onMouseOut={(event) => handlePopperState(event, mealTimesSummary)}
      >
        {numberOfMealTimes}
      </MealTimesCell>
    ),
    [handlePopperState],
  );

  // Render the restrictions cell of each row.
  const renderRestrictions = useCallback(({ row: { allergenTags } = {} }) => {
    let restrictionsCell = texts.noAllergens;

    // If we have restrictions, then we render them with different styles.
    if (allergenTags.length > 0) {
      const firstRestrictions = allergenTags.slice(0, 2);
      const remainingRestrictions = allergenTags.slice(2, allergenTags.length);
      const tooltipText = remainingRestrictions.map((rst) => rst).join(', ');

      restrictionsCell = (
        <TagList>
          {firstRestrictions.map((rst) => (
            <AttentionTag key={rst}>{rst}</AttentionTag>
          ))}
          {!!remainingRestrictions.length && (
            <Tooltip title={tooltipText} placement="top" arrow>
              <AttentionTag>{format(texts.moreAllergens, { amount: remainingRestrictions.length })}</AttentionTag>
            </Tooltip>
          )}
        </TagList>
      );
    }

    return restrictionsCell;
  }, []);

  const columns = getColumns({
    actionsRenderCell: renderActionCell,
    restrictionsRenderCell: renderRestrictions,
    statusRenderCell: renderStatusCell,
    macroBreakDownCell: renderMacroBreakdown,
    mealTimesCell: renderMealTimesCell,
  });

  const {
    filteredRows: quickSearchRows,
    toolbarProps,
  } = useQuickSearch(tableRows, columns);

  const filteredRows = useMemo(() => (
    quickSearchRows.filter((row) => {
      /*
        Show rows that don't include any of the allergens set by the restrictions
      */
      const containsAllergen = row.allergenTags.some((tag) => excludedAllergens.includes(tag));
      const shouldShowArchived = showArchivedMealPlans || !row.isArchived;
      return !containsAllergen && shouldShowArchived;
    })
  ), [
    showArchivedMealPlans,
    quickSearchRows,
    excludedAllergens,
  ]);

  const handleRestrictionFilter = useCallback((restrictions) => {
    setRestrictionOptions(restrictions);
    let allergensList = [];
    restrictions.forEach((tag) => {
      allergensList = [...allergensList, ...RestrictionAllergens[tag]];
    });
    setExcludedAllergens([...new Set(allergensList)]);
  }, []);

  if (tableRows.length === 0) {
    return <NoMealPlansMessage>{texts.noMealPlanAssignments}</NoMealPlansMessage>;
  }

  return (
    <>
      {!!mealPlanToArchive && (
        <ArchiveModal
          isOpen={!!mealPlanToArchive}
          onDidDismiss={() => setMealPlanToArchive(null)}
          mealPlanDoc={mealPlanToArchive}
        />
      )}
      <MealTimesPopper
        anchorEl={anchorEl}
        mealTimesSummary={popperMealTimesSummary}
      />
      <GenericDataGrid
        rows={filteredRows}
        columns={columns}
        components={{
          Toolbar: QuickSearchToolbar,
        }}
        componentsProps={{
          toolbar: {
            ...toolbarProps,
            placeholder: texts.searchPlaceholder,
            filterTools: [
              {
                Component: LabelCheckbox,
                id: 'archive-filter',
                props: {
                  isChecked: showArchivedMealPlans,
                  description: texts.archivedCheckbox,
                  onChange: handleArchivedCheckbox,
                },
              },
              {
                Component: MultiSelectFilter,
                id: 'restriction-filter',
                props: {
                  description: texts.restrictionFilter,
                  options: restrictionOptionValues,
                  onValuesSelected: handleRestrictionFilter,
                  initialValues: restrictionOptions,
                },
              },
            ],
          },
        }}
      />
      <LoadingOverlay isLoading={isLoading || isMealPlanContextLoading} />
    </>
  );
};

MealPlansTable.propTypes = {
  onSelectMealPlan: PropTypes.func.isRequired,
};

export default compose(
  withMealPlanContextReady,
  observer,
)(MealPlansTable);
