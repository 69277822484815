import React, { useContext } from 'react';
import LoadingPage from '../../components/LoadingPage';

import PlanContext from './PlanContext';
import PlanContextProvider from './PlanContextProvider';

const withPlanContextProvider = (Component) => (props) => (
  <PlanContextProvider>
    <Component {...props} />
  </PlanContextProvider>
);

const withPlanContextReady = (Component) => (props) => {
  const { isReady } = useContext(PlanContext);
  return isReady ? <Component {...props} /> : <LoadingPage />;
};

export {
  withPlanContextProvider,
  withPlanContextReady,
};
