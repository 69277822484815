import styled from 'styled-components';
import { Button } from '@mui/material';

const StyledButton = styled(Button)`
  width: 300px;
  height: 45px;
  margin: 20px 0;

  > svg {
    margin-right: 10px;
  }
`;

export {
  StyledButton,
};
