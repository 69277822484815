import React, {
  useEffect,
  useState,
} from 'react';
import { Tooltip, IconButton } from '@mui/material';
import format from 'string-template';
import PropTypes from 'prop-types';

import { TagCategory } from '../../../../../Model/ExerciseTag';
import Exercise from '../../../../../Model/Exercise';
import useComponentMounted from '../../../../../../hooks/useComponentMounted';
import { WarningTag as ExerciseTag } from '../../../../../../components/Tags';
import {
  ExerciseDetailsContainer,
  Thumbnail,
  TagList,
  DetailsContainer,
  TextContainer,
  Title,
  Subtitle,
  ActionContainer,
  StyledEditIcon,
} from './styles';
import texts from './texts';

const EQUIPMENTS_PER_ROW = 1;

const ExerciseDetail = ({
  exerciseData,
  isUpdate,
  setShowExerciseChangeModal,
  isExerciseChangeView,
}) => {
  const [exerciseDoc, setExerciseDoc] = useState(null);
  const isComponentMountedRef = useComponentMounted();

  useEffect(() => {
    const init = async () => {
      /**
       * When adding new activity we get exercise id from exercise doc (exercise.id),
       * but when editing existing activity we get exercise id from activity(exercise.exerciseId)
       */
      const exercise = await Exercise.getExerciseById(exerciseData.id || exerciseData.exerciseId);
      if (isComponentMountedRef.current) {
        setExerciseDoc(exercise);
      }
    };
    if (isUpdate) {
      init();
    }
  }, [
    exerciseData,
    isComponentMountedRef,
    isUpdate,
  ]);

  const renderEquipment = (tags) => {
    if (tags.length > 0) {
      const equipments = tags.filter(({ category }) => category === TagCategory.EQUIPMENT);
      if (equipments.length === 0) return null;

      const initialEquipment = equipments.slice(0, EQUIPMENTS_PER_ROW);
      const remainingEquipments = equipments.length > EQUIPMENTS_PER_ROW
        ? equipments.slice(EQUIPMENTS_PER_ROW, equipments.length) : [];
      const tooltipText = remainingEquipments.map(({ tag }) => tag).join(', ');

      return (
        <TagList>
          {initialEquipment.map(({ id, tag }) => (
            <ExerciseTag key={id}>{tag}</ExerciseTag>
          ))}
          {!!remainingEquipments.length && (
            <Tooltip title={tooltipText} placement="top" arrow>
              <ExerciseTag>{format(texts.moreTags, { amount: remainingEquipments.length })}</ExerciseTag>
            </Tooltip>
          )}
        </TagList>
      );
    }
    return null;
  };

  return (
    <ExerciseDetailsContainer>
      <DetailsContainer>
        {!!exerciseData.videoPreviewThumbnail && (
          <Thumbnail src={exerciseData.videoPreviewThumbnail} alt={exerciseData.name} />
        )}
        <TextContainer>
          <Title>{exerciseData.name}</Title>
          <Subtitle>
            {texts.exerciseType[isUpdate && !!exerciseDoc ? exerciseDoc.type : exerciseData.type]}
          </Subtitle>
        </TextContainer>
      </DetailsContainer>
      <ActionContainer>
        {renderEquipment(exerciseData.tags)}
        {!isExerciseChangeView && (
          <Tooltip title={texts.changeExercise}>
            <IconButton onClick={() => setShowExerciseChangeModal(true)}>
              <StyledEditIcon />
            </IconButton>
          </Tooltip>
        )}
      </ActionContainer>
    </ExerciseDetailsContainer>
  );
};

ExerciseDetail.propTypes = {
  exerciseData: PropTypes.object.isRequired,
  isUpdate: PropTypes.bool,
  setShowExerciseChangeModal: PropTypes.func,
  isExerciseChangeView: PropTypes.bool,
};

ExerciseDetail.defaultProps = {
  setShowExerciseChangeModal: () => {},
  isExerciseChangeView: false,
  isUpdate: false,
};

export default ExerciseDetail;
