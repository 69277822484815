import { Collection } from 'firestorter';
import Collections from '../../utils/collections';
import BaseDocument from '../../Model/BaseDocument';

/**
 * Activity levels of users.
 */
const ActivityLevel = {
  SEDENTARY: 'SEDENTARY',
  MODERATE: 'MODERATE',
  ACTIVE: 'ACTIVE',
};

/**
 * Class representing a user's activity profile.
 *
 * @class UserActivityProfile
 * @extends BaseDocument
 */
class UserActivityProfile extends BaseDocument {
  constructor(id, opts) {
    super(`${Collections.USER_ACTIVITY_PROFILE}/${id}`, opts);
  }

  /**
   * Get the user's level of workout activity within a week.
   * - **'NONE'** - 0 per week
   * - **'SOME'** - 1-3x per week
   * - **'OFTEN'** - 3+ per week
   * @returns {string}
   */
  get currentWorkoutDays() {
    return this.data.currentWorkoutDays || '';
  }

  /**
   * Get the activity level of the user.
   * @returns {string}
   */
  get activityLevel() {
    return this.data.activityLevel || '';
  }

  /**
   * Get the last updated timestamp.
   * The value is stored as milliseconds since the Unix epoch.
   * @returns {number}
   */
  get lastUpdated() {
    return this.data.lastUpdated;
  }

  /**
   * Get a user activity profile by the user's id.
   * @param {string} id - Id of the activity profile (which is the same as the user's id).
   * @returns {Promise<UserActivityProfile|void>} The activity profile document or null if it doesn't exist.
   */
  static async getById(id) {
    const activityProfileDoc = new UserActivityProfile(id);
    await activityProfileDoc.init();
    return activityProfileDoc.exists ? activityProfileDoc : null;
  }

  /**
   * Get all profiles of users for a single coach.
   * @param {string} coachId - Id of the coach.
   * @returns {Promise<Collection>} The collection of activity profiles associated with the coach.
   */
  static async getProfilesByCoach(coachId) {
    const collection = new Collection(Collections.USER_ACTIVITY_PROFILE, {
      createDocument: ({ id }, opts) => new UserActivityProfile(id, opts),
      query: (ref) => ref.where('coach', '==', coachId),
    });
    await collection.fetch();
    return collection;
  }
}

export default UserActivityProfile;
export {
  ActivityLevel,
};
