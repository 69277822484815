import {
  WeightUnit,
  LengthUnit,
  UnitConversion,
} from './units';

const measurement = {
  STOMACH: 'stomach',
  HIP: 'hip',
  UPPER_ARM: 'upperArm',
  WAIST: 'waist',
  CHEST: 'chest',
  THIGH: 'thigh',
  WEIGHT: 'weight',
  GLUTES: 'glutes',
};

const weightMeasurements = [
  measurement.WEIGHT,
];

const circumferenceMeasurements = [
  measurement.STOMACH,
  measurement.HIP,
  measurement.UPPER_ARM,
  measurement.WAIST,
  measurement.CHEST,
  measurement.THIGH,
  measurement.GLUTES,
];

const checkInColumns = [
  'first',
  'last',
  'current',
];

const accordion = {
  QUESTION: 'QUESTION',
  WEIGHT: 'WEIGHT',
  CIRCUMFERENCE: 'CIRCUMFERENCE',
  HABIT: 'HABIT',
  NUTRITIONAL: 'NUTRITIONAL',
  WORKOUT: 'WORKOUT',
};

const WorkoutFeedViewType = {
  COACH_ASSIGNED_WORKOUT: 'coach_assigned_workout',
  SELF_ASSIGNED_WORKOUT: 'self_assigned_workout',
  CUSTOM_WORKOUT: 'custom_workout',
  TRACKED_ACTIVITY_SUMMARY: 'tracked_activity_summary',
  TRACKED_ACTIVITY: 'tracked_activity',
  CHECK_IN: 'check_in',
};

const poundsToKilograms = (pounds) => (
  pounds / UnitConversion.POUNDS_PER_KILOGRAM
);

const kilogramsToPounds = (kilograms) => (
  kilograms * UnitConversion.POUNDS_PER_KILOGRAM
);

const centimetersToInches = (centimeters) => (
  centimeters / UnitConversion.CENTIMETERS_PER_INCH
);

const inchesToCentimeters = (inches) => (
  inches * UnitConversion.CENTIMETERS_PER_INCH
);

/**
 * Get difference between the current measurement and previous measurement, considering the measurements unit types.
 * @param {Object} currentData Data of current checkIn.
 * @param {Object} previousData Data of previous checkIn.
 * @param {String} measurementType Type of the body measurement (weight, stomach, hip, etc..).
 * @returns {Number}
 */
const getMeasurementDifference = (currentData, previousData, measurementType) => {
  const isWeightMeasurement = measurementType === measurement.WEIGHT;
  const currentUnitType = isWeightMeasurement ? currentData.weightUnitType : currentData.lengthUnitType;
  const previousUnitType = isWeightMeasurement ? previousData.weightUnitType : previousData.lengthUnitType;

  if (currentUnitType === previousUnitType) {
    return Number(currentData[measurementType].value) - Number(previousData[measurementType].value);
  }

  if (isWeightMeasurement) {
    return currentUnitType === WeightUnit.KILOGRAMS
      ? Number(currentData[measurementType].value) - poundsToKilograms(Number(previousData[measurementType].value))
      : Number(currentData[measurementType].value) - kilogramsToPounds(Number(previousData[measurementType].value));
  }

  return currentUnitType === LengthUnit.CENTIMETERS
    ? Number(currentData[measurementType].value) - inchesToCentimeters(Number(previousData[measurementType].value))
    : Number(currentData[measurementType].value) - centimetersToInches(Number(previousData[measurementType].value));
};

export {
  measurement,
  weightMeasurements,
  circumferenceMeasurements,
  checkInColumns,
  accordion,
  WorkoutFeedViewType,
  getMeasurementDifference,
};
