import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { IonSpinner } from '@ionic/react';
import { compose } from 'recompose';
import { CSSTransition } from 'react-transition-group';

import withPortal from '../../../components/withPortal';
import transitionsConfig from './transitionsConfig';
import {
  Overlay,
  StyledTypography,
} from './styles';

const LoadingOverlay = ({
  isLoading,
  loadingText,
}) => {
  const overlayRef = useRef(null);

  return (
    <CSSTransition
      timeout={transitionsConfig.delay}
      classNames={transitionsConfig.classNames.overlay}
      in={isLoading}
      unmountOnExit
      nodeRef={overlayRef}
    >
      <Overlay ref={overlayRef}>
        <IonSpinner name="crescent" />
        {!!loadingText && <StyledTypography>{loadingText}</StyledTypography>}
      </Overlay>
    </CSSTransition>
  );
};

LoadingOverlay.propTypes = {
  isLoading: PropTypes.bool,
  loadingText: PropTypes.string,
};

LoadingOverlay.defaultProps = {
  isLoading: false,
  loadingText: '',
};

export default compose(
  withPortal('loadingOverlay'),
)(LoadingOverlay);
