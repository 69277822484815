import styled from 'styled-components';

import colors from '../../../../../styles/colors';
import Button from '../../../../../components/Button';

const Container = styled.div`
  margin: 0 auto;
`;

const Header = styled.div`
  padding: 30px 40px 0;
  background-color: ${colors.shades.gamma5};
  position: sticky;
  top: 0;
  z-index: 1;
`;

const ColumnsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 30px 40px;
  position: relative;
`;

const StyledButton = styled(Button)`
  border-radius: 5px;
  height: 55px;
  font-size: 14px;
  cursor: pointer;
`;

const GenerateContractButton = styled(StyledButton)`
  width: 200px;
  justify-content: space-evenly;

  &:disabled {
    cursor: not-allowed;
    background-color: ${colors.shades.gamma3};
    color: ${colors.base.beta};
  }
`;

const EditButton = styled(StyledButton)`
  width: 120px;
  background-color: ${colors.shades.gamma9};
  color: ${colors.base.beta};
`;

const ViewTermsButton = styled(EditButton)`
  margin-left: 25px;
  width: 150px;
`;

export {
  Container,
  Header,
  ColumnsContainer,
  Footer,
  GenerateContractButton,
  EditButton,
  ViewTermsButton,
};
