const NotificationType = {
  WORKOUT: 'WORKOUT',
  MEAL_PLAN: 'MEAL_PLAN',
};

const DisableType = {
  RESUME: 'RESUME',
  INDEFINITELY: 'INDEFINITELY',
};

export {
  NotificationType,
  DisableType,
};
