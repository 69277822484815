import { Collection } from 'firestorter';
import CollectionName from '../../utils/collections';
import BaseDocument from '../../Model/BaseDocument';

class AppCustomCheckin extends BaseDocument {
  constructor(id, opts) {
    super(`${CollectionName.APP_CUSTOM_CHECK_IN}/${id}`, opts);
  }

  get checkInQuestions() {
    return this.data.checkInQuestions || [];
  }

  get checkInQuestionDefinition() {
    return this.data.checkInQuestionDefinition;
  }

  get checkInDay() {
    return this.data.checkInDay || 0;
  }

  get isImageUploadCompulsory() {
    return !!this.data.isImageUploadCompulsory;
  }

  get checkInDayUpdatedAt() {
    return this.data.checkInDayUpdatedAt;
  }

  get checkInDayUpdatedBy() {
    return this.data.checkInDayUpdatedBy;
  }

  setCheckinQuestions(checkInQuestions) {
    this.updateFields({
      checkInQuestions,
      updatedAt: new Date(),
    });
  }

  setCheckinQuestionDefinition(checkInQuestionDefinition) {
    this.updateFields({
      checkInQuestionDefinition,
      updatedAt: new Date(),
    });
  }

  setCheckInDay(checkInDay) {
    this.updateFields({
      checkInDay,
      checkInDayUpdatedAt: new Date(),
    });
  }

  setCheckInDayUpdatedBy(checkInDayUpdatedBy) {
    this.updateFields({
      checkInDayUpdatedBy,
    });
  }

  setImageUploadRequirement(required = false) {
    this.updateFields({
      isImageUploadCompulsory: required,
      imageUploadCompulsoryUpdatedAt: new Date(),
    });
  }

  static addDoc = async (data) => {
    const customCheckInCollection = new Collection(CollectionName.APP_CUSTOM_CHECK_IN);
    const customCheckInDoc = await customCheckInCollection.add(data);
    const appCustomCheckInDoc = new AppCustomCheckin(customCheckInDoc.id);
    await appCustomCheckInDoc.init();
    return appCustomCheckInDoc;
  };

  static async getById(id) {
    const appCustomCheckInDoc = new AppCustomCheckin(id);
    await appCustomCheckInDoc.init();
    return appCustomCheckInDoc.exists ? appCustomCheckInDoc : null;
  }
}

export default AppCustomCheckin;
