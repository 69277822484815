import React from 'react';
import PropTypes from 'prop-types';
import { InputAdornment } from '@mui/material';

import TextField from '../TextField';
import texts from './texts.json';

const MonthInput = ({
  value,
  ...props
}) => (
  <TextField
    {...props}
    value={value}
    type="number"
    InputProps={{
      endAdornment: <InputAdornment position="end">{texts.months}</InputAdornment>,
    }}
    error={value <= 0}
    helperText={value <= 0
      ? texts.errors.positiveNumber : ''}
  />
);

MonthInput.propTypes = {
  value: PropTypes.number.isRequired,
};

export default MonthInput;
