import React, {
  useContext,
  useMemo,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import useComponentMounted from '../../../../hooks/useComponentMounted';
import MealPlanContext, { withMealPlanContextReady } from '../../../context/MealPlanContext';
import UserNutritionMacroGoals from '../../../Model/UserNutritionMacroGoals';
import NutritionHistory from '../../NutritionHistory';

import { Container } from './styles';
import texts from './texts.json';

const NutritionalHistory = ({ clientId }) => {
  const {
    mealPlanAssignments,
  } = useContext(MealPlanContext);

  const [macroGoalsDoc, setMacroGoalsDoc] = useState({});

  const isComponentMountedRef = useComponentMounted();

  useEffect(() => {
    const init = async () => {
      const doc = await UserNutritionMacroGoals.getById(clientId) || {};
      if (isComponentMountedRef.current) {
        setMacroGoalsDoc(doc);
      }
    };
    init();
  }, [
    clientId,
    isComponentMountedRef,
  ]);

  const mealPlanAssignment = useMemo(() => mealPlanAssignments
    .find((assignment) => assignment.id === clientId) || {},
  [
    clientId,
    mealPlanAssignments,
  ]);

  const {
    assignmentId,
    assignmentName,
    assignmentMacroAverages,
  } = mealPlanAssignment;

  const { history = [] } = macroGoalsDoc;

  const currentNutritionalGoals = useMemo(() => {
    if (!!macroGoalsDoc && !!macroGoalsDoc.getCurrentGoals && !!assignmentId) {
      return macroGoalsDoc.getCurrentGoals(assignmentId, assignmentName, assignmentMacroAverages);
    }
    return {};
  }, [
    assignmentId,
    assignmentName,
    assignmentMacroAverages,
    macroGoalsDoc,
  ]);

  const fullHistory = useMemo(() => {
    const currentGoals = Object.keys(currentNutritionalGoals).length ? [currentNutritionalGoals] : [];
    return [...history, ...currentGoals].reverse();
  }, [
    history,
    currentNutritionalGoals,
  ]);

  return (
    <Container>
      {fullHistory.length > 0
        ? <NutritionHistory history={fullHistory} />
        : texts.emptyText}
    </Container>
  );
};

NutritionalHistory.propTypes = {
  clientId: PropTypes.string.isRequired,
};

export default compose(
  withMealPlanContextReady,
)(NutritionalHistory);
