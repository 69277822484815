import React from 'react';
import PropTypes from 'prop-types';

import feedType from '../../../../context/FeedContext/config/feedTypes';
import CheckInStats from '../../../../components/CheckInStats';

import { ActivityContentContainer } from './styles';

const ActivityContent = ({ activity }) => {
  if (activity.type === feedType.CHECK_IN) {
    return (
      <CheckInStats
        isNotification
        activity={activity}
        title={activity.content}
      />
    );
  }
  return (
    <ActivityContentContainer>{activity.content}</ActivityContentContainer>
  );
};

ActivityContent.propTypes = {
  activity: PropTypes.object,
};

ActivityContent.defaultProps = {
  activity: {},
};

export default ActivityContent;
