import React from 'react';
import PropTypes from 'prop-types';
import {
  Param,
  ParamTitle,
  ParamAfter,
  ParamBefore,
} from './styles';

const HistoryParam = ({
  title,
  kcal,
  grams,
  percent,
  beforeKcal,
  beforeGrams,
  beforePercent,
}) => (
  <Param>
    <ParamTitle>{title}</ParamTitle>
    {beforeKcal && (
      <ParamBefore>
        {`${beforeKcal}kcal`}
      </ParamBefore>
    )}
    {(beforeGrams && beforePercent) && (
      <ParamBefore>
        {`${beforeGrams}g (${beforePercent}%)`}
      </ParamBefore>
    )}
    {(grams && percent) && (
      <ParamAfter>
        {`${grams}g (${percent}%)`}
      </ParamAfter>
    )}
    {kcal && (
      <ParamAfter>
        {`${kcal}kcal`}
      </ParamAfter>
    )}
  </Param>
);

HistoryParam.propTypes = {
  title: PropTypes.string.isRequired,
  kcal: PropTypes.number,
  beforeKcal: PropTypes.number,
  grams: PropTypes.number,
  percent: PropTypes.number,
  beforeGrams: PropTypes.number,
  beforePercent: PropTypes.number,
};

HistoryParam.defaultProps = {
  beforeGrams: undefined,
  beforePercent: undefined,
  kcal: undefined,
  beforeKcal: undefined,
  grams: undefined,
  percent: undefined,
};

export default HistoryParam;
