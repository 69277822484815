import { Alert } from '@mui/material';
import styled from 'styled-components';

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const FilterRow = styled.div`
  display: flex;
  div:not(:last-child) {
    margin-right: 30px;
  }
  margin: 20px 20px;
`;

const PageContent = styled.div`
  flex: 1;
  margin: 10px;
  z-index: 1;
`;

const StyledAlert = styled(Alert)`
  margin: 20px;
  width: fit-content;
`;

export {
  Container,
  FilterRow,
  PageContent,
  StyledAlert,
};
