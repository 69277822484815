import styled from 'styled-components';
import colors from '../../../../../../../styles/colors';

import { SectionHeaderContainer } from '../../../../../../../components/v2/Section';

const RestWarning = styled.div`
  color: red;
  padding-left: 10px;
`;

const CircuitNote = styled.div`
  padding: 10px;
  font-size: 14px;
  color: ${colors.shades.secondary1};
  letter-spacing: 0.1em;
`;

const ActionButtonContainer = styled.div`
  display: flex;
  gap: 0;
`;

const SectionHeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

const StyledSectionHeaderContainer = styled(SectionHeaderContainer)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export {
  RestWarning,
  CircuitNote,
  ActionButtonContainer,
  SectionHeaderContent,
  StyledSectionHeaderContainer,
};
