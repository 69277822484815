import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';

const StyledToastContainer = styled(ToastContainer).attrs({
  className: 'toast-container',
})`
  min-width: 80%;
`;

export {
  StyledToastContainer,
};
