import styled from 'styled-components';
import { SaveOutlined as SaveIcon } from '@mui/icons-material';

import Select from '../../../../../../../components/Select';
import Button, { ButtonIconStyle } from '../../../../../../../components/v2/Button';

const StyledSaveIcon = styled(SaveIcon)`
  ${ButtonIconStyle}
`;

const StyledSelect = styled(Select)`
  width: 100px;
`;

const StyledButton = styled(Button)`
  margin-top: 30px;
`;

const HintLabel = styled.div`
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  margin-bottom: 5px;
  text-decoration: underline;
  cursor: pointer;
`;

export {
  StyledSaveIcon,
  StyledSelect,
  StyledButton,
  HintLabel,
};
