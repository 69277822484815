import React, {
  useState,
  useContext,
  useMemo,
} from 'react';
import { observer } from 'mobx-react';
import { compose } from 'recompose';

import useQuickSearch from '../../../hooks/useQuickSearch';
import TableWithFilters from '../TableWithFilters';
import { ContractStatus } from '../../utils/userContract';
import UserContractsContext, {
  withUserContractsContextProvider,
  withUserContractsContextReady,
} from '../../context/UserContractsContext';
import CoachesListContext, {
  withCoachesListContextProvider,
  withCoachesListContextReady,
} from '../../context/CoachesListContext';
import TableFilter from '../TableFilter';
import texts from './texts.json';
import { StyledFilter } from './styles';

const InitialContractStatus = [
  ContractStatus.PENDING,
  ContractStatus.ACTIVE,
  ContractStatus.TRIALING,
];

const Sales = () => {
  const [selectedContractStatus, setSelectedContractStatus] = useState(InitialContractStatus);
  const [selectedCoaches, setSelectedCoaches] = useState([]);

  const {
    userContracts,
    loadingContracts,
  } = useContext(UserContractsContext);
  const { coachesCollection: { docs: coachList } } = useContext(CoachesListContext);

  const columns = useMemo(() => [
    {
      field: 'userName',
      quickSearch: true,
      headerName: texts.headers.name.text,
      flex: 20,
    },
    {
      field: 'coachName',
      quickSearch: true,
      headerName: texts.headers.coachName.text,
      flex: 20,
    },
    {
      field: 'createdAtStr',
      headerName: texts.headers.linkCreated.text,
      flex: 15,
    },
    {
      field: 'startDate',
      headerName: texts.headers.startDate.text,
      flex: 15,
    },
    {
      field: 'totalPriceInCentsStr',
      headerName: texts.headers.price.text,
      flex: 10,
    },
    {
      field: 'status',
      headerName: texts.headers.status.text,
      flex: 10,
    },
  ], []);

  // Get the first filtering stage which returns the rows that match the quick search input.
  const {
    filteredRows,
  } = useQuickSearch(userContracts, columns);

  // We also need to filter by the states selected in the states dropdown and by the coach.
  const finalFilteredRows = useMemo(() => (
    filteredRows.filter((row) => (
      selectedContractStatus.includes(row.status)
      && selectedCoaches.includes(row.coach)
    ))
  ), [
    filteredRows,
    selectedCoaches,
    selectedContractStatus,
  ]);

  const filteredCoaches = useMemo(() => (
    coachList.filter((coach) => userContracts.some((contract) => contract.coach === coach.id))
  ), [
    coachList,
    userContracts,
  ]);

  const optionValues = useMemo(() => (
    Object
      .values(ContractStatus)
      .map((status) => ({
        value: status,
        label: status.replace('_', ' ').toLowerCase(),
      }))
  ), []);

  return (
    <TableWithFilters
      title={texts.contractTable.title}
      rows={finalFilteredRows}
      columns={columns}
      isLoading={loadingContracts}
      csvHeaders={columns.map(({ field }) => field)}
      csvFileName="contracts"
      filters={[
        <StyledFilter
          title={texts.statusFilterTitle}
          initialValues={InitialContractStatus}
          onValuesSelected={setSelectedContractStatus}
          options={optionValues}
          key="status"
        />,
        <TableFilter
          onSelect={setSelectedCoaches}
          options={filteredCoaches.map((coach) => ({ value: coach.id, label: coach.name }))}
          selectedCoaches={selectedCoaches}
          initialSelection={filteredCoaches.map((coach) => coach.id)}
          key="coach"
        />,
      ]}
    />
  );
};

export default compose(
  withCoachesListContextProvider,
  withCoachesListContextReady,
  withUserContractsContextProvider,
  withUserContractsContextReady,
  observer,
)(Sales);
