import styled from 'styled-components';
import { DataGrid } from '@mui/x-data-grid';
import Button from '../../../../../components/Button';

import colors from '../../../../../styles/colors';

const DataGridContainer = styled.div`
  flex-basis: 100%;
  min-height: 500px;
  min-width: 920px;
  margin-bottom: 20px;
`;

const StyledDataGrid = styled(DataGrid)`
  border: 1px solid ${colors.shades.secondary7};
  color: ${colors.shades.secondary8};
  background-color: ${colors.base.alpha};
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
`;

const StyledActionButton = styled(Button)`
  background-color: ${colors.base.primary};
  color: ${colors.base.beta};
  border: 1.5px solid ${colors.shades.primary2};
  font-size: 12px;
  font-weight: 600;
  padding: 2px 10px;
  min-width: fit-content;
  border-radius: 5px;
  cursor: pointer;

  :disabled {
    background-color: ${colors.base.primary};
    cursor: default;
  }
`;

const StyledUserName = styled.div`
  text-decoration: underline;
  cursor: pointer;
`;

export {
  DataGridContainer,
  StyledDataGrid,
  ActionButtonsContainer,
  StyledActionButton,
  StyledUserName,
};
