import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { MinorNutritionItems } from './NutritionItem';

const NutritionTable = styled.table`
  border-collapse: collapse;
  margin: 0 0 0.5rem 0;
  width: 100%;
  td,
  th {
    font-weight: normal;
    text-align: left;
    padding: 0.25rem 0;
    border-top: 1px solid black;
    white-space: nowrap;
  }

  thead tr th {
    border: 0;
  }
  td:last-child {
    text-align: left;
    ::before {
      font-weight: bold;
      margin: 0 0.25rem 0 0;
    }
  }
`;

const NutritionFacts = ({ items }) => (
  <NutritionTable>
    <tbody>{MinorNutritionItems(items)}</tbody>
  </NutritionTable>
);

NutritionFacts.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default NutritionFacts;
