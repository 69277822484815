import * as Yup from 'yup';

import fieldName from './formFields';
import texts from './texts';

const initialValues = {
  [fieldName.NAME]: '',
  [fieldName.NOTE]: '',
};

const validationSchema = Yup.object().shape({
  [fieldName.NAME]: Yup.string().required(texts.validation.requiredField),
});

export {
  initialValues,
  validationSchema,
};
