import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 0 16px;
`;

export {
  Container,
};
