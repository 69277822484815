import styled from 'styled-components';

import { LoadingButton } from '../../../../../../../components/Button';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

const StyledButton = styled(LoadingButton)`
  width: 270px;
  height: 50px;
  font-size: 18px;
`;

export {
  Wrapper,
  StyledButton,
};
