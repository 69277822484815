import React, {
  useState,
  useEffect,
  useMemo,
} from 'react';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';

import useComponentMounted from '../../../../../hooks/useComponentMounted';
import CoachLeadAnalytics from '../../../../Model/analytics/CoachLeadAnalytics';
import CoachSubscriptionsAnalytics from '../../../../Model/analytics/CoachSubscriptionAnalytics';
import CoachRevenueAnalytics from '../../../../Model/analytics/CoachRevenueAnalytics';
import CoachAvgClientLifespanAnalytics from '../../../../Model/analytics/CoachAvgClientLifespanAnalytics';
import CoachActiveUsersAnalytics from '../../../../Model/analytics/CoachActiveUsersAnalytics';
import AnalyticsGroup from '../AnalyticsGroup';
import SubscriptionsChart from '../AnalyticCharts/SubscriptionsChart/SubscriptionsChart';
import LeadsChart from '../AnalyticCharts/LeadsChart/LeadsChart';
import RevenueChart from '../AnalyticCharts/RevenueChart';
import ActiveUsersChart from '../AnalyticCharts/ActiveUsersChart';
import AvgClientLifespanChart from '../AnalyticCharts/AvgClientLifespanChart';
import texts from './texts.json';

const TWELVE_MONTHS = 12; // load last 12 months data

const NMonthsAnalytics = ({
  selectedCoach,
}) => {
  const [leadAnalytics, setLeadAnalytics] = useState([]);
  const [subscriptionAnalytics, setSubscriptionAnalytics] = useState([]);
  const [revenueAnalytics, setRevenueAnalytics] = useState([]);
  const [activeUsersAnalytics, setActiveUsersAnalytics] = useState([]);
  const [avgClientLifespanAnalytics, setAvgClientLifespanAnalytics] = useState([]);

  const isComponentMountedRef = useComponentMounted();

  useEffect(() => {
    if (selectedCoach) {
      const loadMonthlyAnalytics = async (Document, onSuccess) => {
        const analyticDocs = await Document.getLastNMonthsAnalytics(
          Document.getCollectionPath(selectedCoach.id),
          TWELVE_MONTHS,
          Document,
        );
        if (isComponentMountedRef.current) {
          onSuccess(analyticDocs);
        }
      };

      const loadActiveUsersAnalytics = async () => {
        const activeUsersAnalyticDocs = await CoachActiveUsersAnalytics.getLastNMonthsAnalytics(
          selectedCoach.id,
          TWELVE_MONTHS,
        );
        if (isComponentMountedRef.current) {
          setActiveUsersAnalytics(activeUsersAnalyticDocs);
        }
      };

      const loadLastNMonthsAnalytics = async () => {
        await Promise.all([
          loadMonthlyAnalytics(CoachLeadAnalytics, setLeadAnalytics),
          loadMonthlyAnalytics(CoachSubscriptionsAnalytics, setSubscriptionAnalytics),
          loadMonthlyAnalytics(CoachRevenueAnalytics, setRevenueAnalytics),
          loadMonthlyAnalytics(CoachAvgClientLifespanAnalytics, setAvgClientLifespanAnalytics),
          loadActiveUsersAnalytics(),
        ]);
      };
      loadLastNMonthsAnalytics();
    }
  }, [isComponentMountedRef, selectedCoach]);

  const coachCurrencies = useMemo(() => {
    const currencies = new Set();
    if (revenueAnalytics) {
      revenueAnalytics.forEach((monthlyAnalytics) => {
        monthlyAnalytics.revenues.forEach((revenueItem) => {
          if (revenueItem.currency && !currencies.has(revenueItem.currency)) {
            currencies.add(revenueItem.currency);
          }
        });
      });
    }
    return Array.from(currencies);
  }, [revenueAnalytics]);

  return (
    <AnalyticsGroup title={texts.last12Months}>
      <SubscriptionsChart subscriptionAnalytics={subscriptionAnalytics} />
      <LeadsChart leadAnalytics={leadAnalytics} />
      {coachCurrencies?.map((currency) => (
        <RevenueChart revenueAnalytics={revenueAnalytics} currency={currency} key={currency} />
      ))}
      <ActiveUsersChart activeUsersAnalytics={activeUsersAnalytics} />
      <AvgClientLifespanChart lifespanAnalytics={avgClientLifespanAnalytics} />
    </AnalyticsGroup>
  );
};

NMonthsAnalytics.propTypes = {
  selectedCoach: PropTypes.object.isRequired,
};

export default compose(
  observer,
)(NMonthsAnalytics);
