import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import useToast from '../../../../../hooks/useToast';
import {
  caloriesToCarbs,
  caloriesToFat,
  caloriesToProtein,
  carbsToCalories,
  fatToCalories,
  proteinToCalories,
} from '../../../../../utils/meals';

import DialogRoundedModal from '../../../../../../components/DialogRoundedModal';
import { SaveButton, CancelButton } from '../../../../../../components/Button/ActionButtons';

import Switcher from './components/Switcher';
import AssignByMacros from './components/AssignByMacros';
import AssignByCalories from './components/AssignByCalories';
import {
  StyledMealPlateIcon,
} from './styles';
import texts from './texts.json';

const NutritionGoalsEditorModal = ({
  isOpen,
  onClose,
  mealPlanMacroAverages,
  currentCalories,
  currentMacroAverages,
  userNutritionMacroGoalsDoc,
  mealPlanId,
  mealPlanName,
  onSave,
}) => {
  const [isMacrosView, setIsMacrosView] = useState(false);
  const [clientNutritionGoals, setClientNutritionGoals] = useState({
    calories: currentCalories || 0,
    protein: 0,
    carbs: 0,
    fat: 0,
  });

  const { showToast } = useToast();

  const updateNutritionGoals = useCallback((type, value) => {
    setClientNutritionGoals((prevClientNutritionGoals) => ({
      ...prevClientNutritionGoals,
      [type]: Number(value),
    }));
  }, []);

  const handleSave = useCallback(() => {
    const {
      calories: clientCalories,
      protein = 0,
      carbs = 0,
      fat = 0,
    } = clientNutritionGoals;

    if (!isMacrosView) {
      const totalMacros = protein + carbs + fat;
      if (totalMacros !== 100) {
        showToast(texts.reviewError, {
          error: texts.macrosPercentageError,
        });
        return;
      }
    }

    const docContent = {
      totalDailyCalories: clientCalories,
      macroAverages: {
        percentages: {
          protein: isMacrosView ? Math.round((proteinToCalories(protein) / clientCalories) * 100) : protein,
          carbs: isMacrosView ? Math.round((carbsToCalories(carbs) / clientCalories) * 100) : carbs,
          fat: isMacrosView ? Math.round((fatToCalories(fat) / clientCalories) * 100) : fat,
        },
        macros: {
          protein: isMacrosView ? protein : Math.round(caloriesToProtein(clientCalories * (protein / 100))),
          carbs: isMacrosView ? carbs : Math.round(caloriesToCarbs(clientCalories * (carbs / 100))),
          fat: isMacrosView ? fat : Math.round(caloriesToFat(clientCalories * (fat / 100))),
        },
      },
      updatedMacroAveragesAt: new Date(),
    };

    if (onSave) {
      // Use save function if available
      onSave(docContent);
    } else {
      userNutritionMacroGoalsDoc.saveHistory(mealPlanId, mealPlanName, mealPlanMacroAverages);
      userNutritionMacroGoalsDoc.updateFields(docContent);
      showToast(texts.updateSuccess);
    }
    onClose({ isSaved: true });
  }, [
    onSave,
    userNutritionMacroGoalsDoc,
    clientNutritionGoals,
    isMacrosView,
    mealPlanId,
    mealPlanMacroAverages,
    mealPlanName,
    onClose,
    showToast,
  ]);

  const assignView = () => {
    if (isMacrosView) {
      return (
        <AssignByMacros
          clientNutritionGoals={clientNutritionGoals}
          updateNutritionGoals={updateNutritionGoals}
          currentMacroAverages={currentMacroAverages}
        />
      );
    }
    return (
      <AssignByCalories
        clientNutritionGoals={clientNutritionGoals}
        updateNutritionGoals={updateNutritionGoals}
        mealPlanMacroAverages={mealPlanMacroAverages}
        currentMacroAverages={currentMacroAverages}
      />
    );
  };

  const actionButtons = (
    <>
      <SaveButton onClick={handleSave}>
        {texts.buttons.save}
      </SaveButton>
      <CancelButton onClick={onClose}>
        {texts.buttons.cancel}
      </CancelButton>
    </>
  );

  return (
    <DialogRoundedModal
      open={isOpen}
      onClose={() => onClose({ isSaved: false })}
      title={texts.title}
      description={texts.description}
      actionButtons={actionButtons}
      IconComponent={<StyledMealPlateIcon />}
    >
      <Switcher
        isMacrosView={isMacrosView}
        setIsMacrosView={setIsMacrosView}
      />
      {assignView()}
    </DialogRoundedModal>
  );
};

NutritionGoalsEditorModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  mealPlanId: PropTypes.string,
  mealPlanName: PropTypes.string,
  userNutritionMacroGoalsDoc: PropTypes.object,
  mealPlanMacroAverages: PropTypes.object,
  currentCalories: PropTypes.number,
  currentMacroAverages: PropTypes.object,
  onSave: PropTypes.func,
};

NutritionGoalsEditorModal.defaultProps = {
  userNutritionMacroGoalsDoc: {},
  mealPlanMacroAverages: {},
  currentCalories: 0,
  currentMacroAverages: {},
  onSave: null,
  mealPlanId: '',
  mealPlanName: '',
};

export default NutritionGoalsEditorModal;
