import styled from 'styled-components';

import {
  Button,
} from '@mui/material';
import colors from '../../../styles/colors';

const PlaceholdersContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 0.5rem;
`;

const PlaceholderTip = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  line-height: 11px;
  color: ${colors.shades.secondary4};
  margin: 5px 0;
  max-width: 115px;
  text-align: left;
`;

const PlaceholderButton = styled(Button)`
  justify-content: flex-start;
  color: ${colors.shades.secondary1};
  width: fit-content;
  font-size: 11px;
  line-height: 13px;
  text-transform: none;
  &:hover {
    background-color: unset;
  }
`;

export {
  PlaceholdersContainer,
  PlaceholderButton,
  PlaceholderTip,
};
