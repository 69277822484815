import styled from 'styled-components';

import Button from '../../components/Button';

const Container = styled.div`
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Body = styled.div`
  flex: 1;
`;

const SignoutButton = styled(Button)`
  width: 200px;
  margin: auto 0;
`;

export {
  Container,
  SignoutButton,
  Body,
};
