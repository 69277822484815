import { ChannelBuckets } from '../../../../context/ChatContext/buckets';

const texts = {
  noChannelsPlaceholder: 'All done here!',
  channelBuckets: {
    [`${ChannelBuckets.ACTIVE}`]: 'Active',
    [`${ChannelBuckets.ARCHIVED}`]: 'Archived',
    [`${ChannelBuckets.ALL}`]: 'All',
  },
  sortByRecentFirst: 'Sort By: Recent First',
  sortByOldestFirst: 'Sort By: Oldest First',
};

export default texts;
