import { Avatar, Badge } from '@mui/material';
import styled, { css } from 'styled-components';
import colors from '../../styles/colors';
import AvatarSizeType from './AvatarSizes';

const size = (avatarSize) => {
  let width;
  let height;

  switch (avatarSize) {
    case AvatarSizeType.SMALL:
      width = '36px';
      height = '36px';
      break;
    case AvatarSizeType.MEDIUM:
      width = '40px';
      height = '40px';
      break;
    case AvatarSizeType.LARGE:
      width = '60px';
      height = '60px';
      break;
    default:
      width = '40px';
      height = '40px';
  }
  return css`
    width: ${width};
    height: ${height};
  `;
};

const badgePosition = (avatarSize) => {
  let left;

  switch (avatarSize) {
    case AvatarSizeType.SMALL:
      left = '18px';
      break;
    case AvatarSizeType.MEDIUM:
      left = '20px';
      break;
    case AvatarSizeType.LARGE:
      left = '30px';
      break;
    default:
      left = '20px';
  }
  return css`
    left: ${left};
  `;
};

const AvatarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledImage = styled.img`
  ${(props) => size(props.$avatarSize)};
  border-radius: 50%;
  object-fit: cover;
`;

const StyledAvatar = styled(Avatar)`
  ${(props) => size(props.$avatarSize)};
  background-color: ${colors.shades.primary4};
`;

const StyledBadge = styled(Badge)`
  margin-bottom: ${(props) => props.$showNewBadge && '8px'};
  .MuiBadge-badge {
    height: 15px;
    font-size: 10px;
    padding-top: 2px;
    background-color: ${colors.base.danger};
    color: ${colors.base.alpha};
    ${(props) => badgePosition(props.$avatarSize)};
  }
`;

export {
  AvatarWrapper,
  StyledImage,
  StyledAvatar,
  StyledBadge,
};
