import {
  useEffect,
  useState,
  useMemo,
} from 'react';
import format from 'string-template';

import { firestorePaths, pathPlaceholder } from '../../utils/paths';
import User from '../../Model/User';
import useComponentMounted from '../../hooks/useComponentMounted';

const useUserDoc = (userId) => {
  const [userDoc, setUserDoc] = useState(null);

  const isComponentMountedRef = useComponentMounted();

  useEffect(() => {
    const initUserDoc = async () => {
      const newUserDoc = new User(format(firestorePaths.USER_DOC, {
        [pathPlaceholder.USER_ID]: userId,
      }));

      await newUserDoc.init();

      if (isComponentMountedRef.current) {
        setUserDoc(newUserDoc);
      }
    };

    if (!userDoc) {
      initUserDoc();
    }
  }, [
    userDoc,
    isComponentMountedRef,
    userId,
  ]);

  return useMemo(() => ({
    isReady: !!userDoc,
    userDoc,
  }), [
    userDoc,
  ]);
};

export default useUserDoc;
