import React from 'react';

import { UserRole } from '../../../Model/User';
import UserManagement from '../../components/UserManagement';

import texts from './texts.json';

const CRXUsers = () => (
  <UserManagement
    role={UserRole.CRX}
    title={texts.crxUsers}
  />
);

export default CRXUsers;
