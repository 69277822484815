import styled from 'styled-components';
import { Draggable } from 'react-smooth-dnd';
import { IconButton } from '@mui/material';

import colors from '../../../../../../../../styles/colors';

const StyledDraggable = styled(Draggable)`
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px;
  background-color: ${(props) => (props.$isModalView ? colors.shades.secondary7 : colors.shades.secondary5)};
  border: 1px solid ${colors.shades.secondary4};
  border-radius: 5px;
  flex-shrink: 0;
  gap: 2px;
`;

const StyledIconButton = styled(IconButton)`
  width: 40px;
`;
const StyledReorderRoundedIcon = styled(StyledIconButton)`
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
`;

const TextFieldWrapper = styled.div`
  width: 310px;
`;
const NumberFieldWrapper = styled.div`
  width: 80px;
`;

export {
  StyledDraggable,
  StyledReorderRoundedIcon,
  StyledIconButton,
  TextFieldWrapper,
  NumberFieldWrapper,
};
