import MediaRecorderEncoder from 'opus-media-recorder';
import * as Sentry from '@sentry/browser';
import { toast } from 'react-toastify';

// eslint-disable-next-line import/no-webpack-loader-syntax,import/no-unresolved,import/extensions
import EncoderWorker from 'worker-loader!opus-media-recorder/encoderWorker.js';

import { MediaRecorder, MediaRecorderState } from '@fitmoola/system2-chat';

import texts from './texts.json';

class WebMediaRecorder extends MediaRecorder {
  async checkForPermissions() {
    if (this.permissionsGranted) {
      return true;
    }

    try {
      const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });
      this.stream = audioStream;
      this.permissionsGranted = !!audioStream;
      return this.permissionsGranted;
    } catch (error) {
      Sentry.captureException(error, {
        extra: {
          description: texts.errorMessage.permissions,
        },
      });
      return false;
    }
  }

  async startRecording(onDataAvailable) {
    if (await this.checkForPermissions()) {
      await super.startRecording();

      const encoderWorkerFactory = () => new EncoderWorker();

      const workerOptions = {
        encoderWorkerFactory,
        OggOpusEncoderWasmPath: `${process.env.PUBLIC_URL}/opus-media-recorder/OggOpusEncoder.wasm`,
        WebMOpusEncoderWasmPath: `${process.env.PUBLIC_URL}/opus-media-recorder/WebMOpusEncoder.wasm`,
      };
      const options = { mimeType: 'audio/wave' };
      const recorder = new MediaRecorderEncoder(this.stream, options, workerOptions);

      recorder.addEventListener('dataavailable', (audioData) => {
        onDataAvailable(audioData);
      });

      recorder.addEventListener('error', (error) => {
        this.state = MediaRecorderState.ERROR;
        toast.error(texts.errorMessage.default);
        const description = texts.errorMessage.webMediaRecording;
        Sentry.captureException(error, {
          extra: {
            description,
          },
        });
      });
      recorder.start();

      this.recorder = recorder;
    }
  }

  stopRecording() {
    this.recorder.stop();
    this.state = MediaRecorderState.INACTIVE;
  }
}

export default WebMediaRecorder;
