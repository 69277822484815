import styled from 'styled-components';

const ButtonContainer = styled.div`
  display: flex;
  margin-left: auto;
  width: fit-content;
  column-gap: 5px;
`;

export {
  ButtonContainer,
};
