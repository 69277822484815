import React, {
  useContext,
  useState,
  useMemo,
  useCallback,
} from 'react';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import format from 'string-template';

import { PrimaryButton } from '../../../../../../components/Button/ActionButtons';
import useComponentMounted from '../../../../../../hooks/useComponentMounted';
import useQuickSearch from '../../../../../../hooks/useQuickSearch';
import useToast from '../../../../../hooks/useToast';
import ExerciseTagContext, { withExerciseTagContextReady } from '../../../../../context/ExerciseTagContext';
import QuickSearchToolbar from '../../../../../components/QuickSearchToolbar';
import GenericDataGrid from '../../../../../components/GenericDataGrid';
import LabelCheckbox from '../../../../../components/LabelCheckbox';
import MultiSelectFilter from '../../../../../components/MultiSelectFilter';
import ConfirmDialog from '../../../../../components/ConfirmDialog';
import { TagType, TagCategory } from '../../../../../Model/ExerciseTag';

import {
  ActionsContainer,
  StyledEditIcon,
  StyledDeleteIcon,
} from './styles';
import getColumns from './columns';
import texts from './texts';

const categoryOptions = Object.values(TagCategory).map((category) => ({
  value: category,
  label: texts.tagCategory[category],
}));

const ExerciseTagsTable = ({
  onEdit,
}) => {
  const [showOnlyCustomTags, setShowOnlyCustomTags] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedTagToDelete, setSelectedTagToDelete] = useState(null);

  const { tags } = useContext(ExerciseTagContext);

  const { showToast } = useToast();
  const isComponentMountedRef = useComponentMounted();

  const handleDeleteTag = useCallback(async () => {
    try {
      await selectedTagToDelete.delete();
      showToast(texts.deleteSuccessful);
    } catch (error) {
      showToast(format(texts.deleteFailed, { message: error.message }), { error: true });
    } finally {
      if (isComponentMountedRef.current) {
        setSelectedTagToDelete(null);
      }
    }
  }, [
    selectedTagToDelete,
    showToast,
    isComponentMountedRef,
  ]);

  const renderActionCell = useCallback(({ row }) => {
    const actionsEnabled = row.type === TagType.CUSTOM;

    return actionsEnabled && (
      <ActionsContainer>
        <PrimaryButton
          onClick={() => onEdit(row)}
          icon={<StyledEditIcon />}
          variant="info"
          size="medium"
        >
          {texts.buttons.edit}
        </PrimaryButton>
        <PrimaryButton
          onClick={() => setSelectedTagToDelete(row)}
          icon={<StyledDeleteIcon />}
          variant="info"
          size="medium"
        >
          {texts.buttons.delete}
        </PrimaryButton>
      </ActionsContainer>
    );
  }, [
    onEdit,
  ]);

  const columns = getColumns({
    actionsRenderCell: renderActionCell,
  });

  // Filter tags by category and type before quicksearch
  const filteredTags = useMemo(() => (
    tags.filter(({ type, category }) => {
      const categoryMatch = selectedCategories.length === 0 || selectedCategories.includes(category);
      const typeMatch = !showOnlyCustomTags || type === TagType.CUSTOM;
      return categoryMatch && typeMatch;
    })
  ), [
    selectedCategories,
    showOnlyCustomTags,
    tags,
  ]);

  // Get filtered rows for toolbar search value
  const {
    filteredRows,
    toolbarProps,
  } = useQuickSearch(filteredTags, columns);

  return (
    <>
      <GenericDataGrid
        rows={filteredRows}
        columns={columns}
        components={{
          Toolbar: QuickSearchToolbar,
        }}
        componentsProps={{
          toolbar: {
            ...toolbarProps,
            placeholder: texts.searchPlaceholder,
            filterTools: [
              {
                Component: LabelCheckbox,
                id: 'custom-tag-filter',
                props: {
                  isChecked: showOnlyCustomTags,
                  description: texts.customTagsFilter,
                  onChange: setShowOnlyCustomTags,
                },
              },
              {
                Component: MultiSelectFilter,
                id: 'category-tag-filter',
                props: {
                  description: texts.filterByCategory,
                  options: categoryOptions,
                  initialValues: selectedCategories,
                  onValuesSelected: setSelectedCategories,
                  disableValueRender: true,
                  hideAllOption: true,
                },
              },
            ],
          },
        }}
      />
      <ConfirmDialog
        isOpen={!!selectedTagToDelete}
        onConfirm={handleDeleteTag}
        onCancel={() => setSelectedTagToDelete(null)}
        dialogTexts={{
          title: format(texts.deleteTag, { name: selectedTagToDelete?.tag }),
        }}
      />
    </>
  );
};

ExerciseTagsTable.propTypes = {
  onEdit: PropTypes.func,
};

ExerciseTagsTable.defaultProps = {
  onEdit: () => {},
};

export default compose(
  withExerciseTagContextReady,
  observer,
)(ExerciseTagsTable);
