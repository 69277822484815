const ActionType = {
  UPDATE: 'UPDATE',
  CREATE: 'CREATE',
  ARCHIVE: 'ARCHIVE',
  UNARCHIVE: 'UNARCHIVE',
  ADD: 'ADD',
};

const canPerformAction = (actionType, exercise) => (
  !!actionType && !!exercise
);

const isEditAction = (actionType, exercise) => (
  canPerformAction(actionType, exercise) && actionType === ActionType.UPDATE
);

const isCreateAction = (actionType) => (
  actionType === ActionType.CREATE
);

const isEditorView = (actionType, exercise) => (
  isEditAction(actionType, exercise) || isCreateAction(actionType)
);

const isModalViewAction = (actionType) => (
  actionType === ActionType.ARCHIVE || actionType === ActionType.UNARCHIVE
);

export {
  ActionType,
  canPerformAction,
  isCreateAction,
  isEditAction,
  isEditorView,
  isModalViewAction,
};
