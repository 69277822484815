import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import { useRouteMatch } from 'react-router-dom';

import { AssignmentRole } from '../../../../../utils/internalAssignments';
import InternalAssignmentContext, {
  withInternalAssignmentsContextProvider,
  withInternalAssignmentsContextReady,
} from '../../../../context/InternalAssignmentsContext';
import CoachSelect from '../CoachSelect';
import UserSelect from '../../../../pages/Analytics/components/UserSelect';

import {
  Wrapper,
} from './styles';
import texts from './texts.json';

const InsideSalesSelectors = ({
  onCoachChange,
}) => {
  const { params: { userId } } = useRouteMatch();
  const { isUsers } = useContext(InternalAssignmentContext);
  const [selectedIS, setSelectedIS] = useState(() => {
    const agent = isUsers.find(({ userId: agentId }) => agentId === userId);
    if (agent) {
      return {
        id: agent.userId,
        label: agent.userName,
      };
    }
    return null;
  });

  return (
    <Wrapper>
      <UserSelect
        selectedUser={selectedIS}
        userList={isUsers}
        onChange={setSelectedIS}
        inputLabel={texts.inputLabel}
        inputPlaceHolder={texts.inputPlaceHolder}
      />
      <CoachSelect
        role={AssignmentRole.INSIDE_SALES}
        onChange={onCoachChange}
        selectedUserId={selectedIS?.id}
        enableMultipleSelection
      />
    </Wrapper>
  );
};

InsideSalesSelectors.propTypes = {
  onCoachChange: PropTypes.func,
};

InsideSalesSelectors.defaultProps = {
  onCoachChange: () => {},
};

export default compose(
  withInternalAssignmentsContextProvider,
  withInternalAssignmentsContextReady,
  observer,
)(InsideSalesSelectors);
