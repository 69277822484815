class WorkoutDefinition {
  constructor(workoutData) {
    const {
      name,
      note,
      activities,
    } = workoutData;
    this.name = name;

    if (note) {
      this.note = note;
    }

    this._activities = activities;
  }

  toJSON = () => {
    const jsonWorkoutDefinition = {
      name: this.name,
    };

    if (this.note) {
      jsonWorkoutDefinition.note = this.note;
    }

    jsonWorkoutDefinition.activities = JSON.parse(JSON.stringify(this._activities));

    return jsonWorkoutDefinition;
  };
}

export default WorkoutDefinition;
