import { Collection } from 'firestorter';
import format from 'string-template';

import { firestorePaths, pathPlaceholder } from '../utils/paths';
import BaseDocument from './BaseDocument';

const EmailTemplateName = {
  LEAD_WELCOME: 'Lead Welcome Email',
};

/**
 * Class representing a Email templates.
 * This model stores and manages the email templates associated with a coach or the default email templates.
 *
 * @class EmailTemplate
 * @extends BaseDocument
 */
class EmailTemplate extends BaseDocument {
  /**
   * Get the name
   * @return {string}
   */
  get name() {
    return this.data.name;
  }

  /**
   * Get the email body
   * @return {string}
   */
  get emailBody() {
    return this.data.emailBody;
  }

  /**
   * Get whether the template is a default template or not
   * @return {boolean}
   */
  get isDefault() {
    return this.data.isDefault;
  }

  /**
   * Get the subject
   * @return {string}
   */
  get subject() {
    return this.data.subject;
  }

  /**
   * Get whether template is enabled or not
   * @return {boolean}
   */
  get isEnabled() {
    return this.data.isEnabled ?? true;
  }

  /**
   * Gets the default email templates
   *
   * @param {string} [templateName] - The name of the template (optional)
   * @return {Promise<Collection>} - The collection of default email templates
   */
  static async getDefaultTemplates(templateName) {
    const emailTemplateCollection = new Collection(firestorePaths.DEFAULT_EMAIL_TEMPLATES, {
      createDocument: (source, options) => new EmailTemplate(source, options),
      ...(templateName ? {
        query: (ref) => ref.where('name', '==', templateName),
      } : {}),
    });
    await EmailTemplate.initCollection(emailTemplateCollection);
    return emailTemplateCollection;
  }

  /**
   * Gets the email templates by coach
   *
   * @param {string} coachId - The coach id
   * @param {string} [templateName] - The name of the template (optional)
   * @return {Promise<Collection>} - The collection of email templates
   */
  static async getTemplatesByCoach(coachId, templateName) {
    const emailTemplateCollection = new Collection(
      format(firestorePaths.COACH_EMAIL_TEMPLATES, { [pathPlaceholder.COACH_ID]: coachId }),
      {
        createDocument: (source, options) => new EmailTemplate(source, options),
        ...(templateName ? {
          query: (ref) => ref.where('name', '==', templateName),
        } : {}),
      },
    );
    await EmailTemplate.initCollection(emailTemplateCollection);
    return emailTemplateCollection;
  }

  /**
   * Adds a new email template for a coach
   *
   * @param {object} templateData - Data of new email template
   * @return {Promise<void>}
   */
  static async addNewTemplate(templateData) {
    const newCoachEmailTemplate = new Collection(format(firestorePaths.COACH_EMAIL_TEMPLATES, {
      [pathPlaceholder.COACH_ID]: templateData.coach,
    }));
    return newCoachEmailTemplate.add({
      ...templateData,
    });
  }
}

export default EmailTemplate;
export { EmailTemplateName };
