import styled from 'styled-components';
import { Badge, List } from '@mui/material';
import { ReactComponent as SortIcon } from '../../../../../assets/icons/sort-icon.svg';
import colors from '../../../../../styles/colors';
import { APP_BAR_HEIGHT } from '../../../../layout/components/Header/styles';

const drawerStyle = {
  display: { xs: 'none', sm: 'block' },
  '& .MuiDrawer-paper': {
    boxSizing: 'border-box',
    position: 'unset',
    width: 280,
    height: `calc(100vh - ${APP_BAR_HEIGHT * 3}px)`,
  },
};

const StyledBadge = styled(Badge)`
  margin-right: 10px;
  .MuiBadge-badge {
    background-color: ${colors.shades.primary3};
    border: 1px solid ${colors.shades.primary4};
  }
`;

const FeedListFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 90px;
  margin-bottom: 15px;
`;

const StyledList = styled(List)`
  flex: 1;
`;

const FeedListContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${colors.base.secondary3};
  padding: 15px 10px;
`;

const SortContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2px;
  cursor: pointer;
`;

const StyledSortIcon = styled(SortIcon)`
  width: 18px;
  height: 18px;
  margin-left: 5px;
`;

export {
  drawerStyle,
  StyledBadge,
  FeedListFooter,
  StyledList,
  FeedListContainer,
  FilterContainer,
  SortContainer,
  StyledSortIcon,
};
