import styled from 'styled-components';
import { ToggleButtonGroup } from '@mui/material';

import colors from '../../../styles/colors';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  margin-left: auto;
  background-color: ${colors.base.alpha};
`;

export {
  StyledToggleButtonGroup,
};
