import styled, { css } from 'styled-components';
import colors from '../../../../styles/colors';

const HistoryItem = styled.div`
  border-bottom: 1px solid ${colors.shades.secondary7};
  position: relative;
  padding: 0 0 18px 0;
  margin-bottom: 20px;

  &:last-child {
    border-bottom: none;
    padding: 0;
  }
`;

const DateRow = styled.div`
  display: flex;
  align-items: center;
`;
const Date = styled.div`
  color: ${colors.shades.primary1};
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
`;

const tagStyles = css`
  font-size: 10px;
  line-height: 9px;
  border-radius: 4px;
  padding: 5px 8px;
  margin-left: 10px;
  color: ${colors.shades.secondary3};
`;
const Current = styled.div`
  ${tagStyles};
  background: ${colors.shades.primary4};
  border: 1px solid ${colors.shades.primary1};
`;
const Origin = styled.div`
  ${tagStyles};
  background: ${colors.shades.secondary7};
  border: 1px solid ${colors.shades.secondary4};
`;

const MealPlanName = styled.span`
  display: inline-block;
  background: ${colors.shades.secondary4};
  border: 1px solid ${colors.shades.secondary6};
  border-radius: 2px;
  padding: 5px 9px;
  margin: 10px 0;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: ${colors.shades.secondary3};
  > span {
    font-weight: 700;
  }
`;

const Params = styled.div`
  display: flex;
`;

export {
  HistoryItem,
  MealPlanName,
  Params,
  DateRow,
  Date,
  Current,
  Origin,
};
