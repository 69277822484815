import {
  isInAppBrowserEnabled,
  cordovaInAppBrowserRef,
} from '../utils/inAppBrowser';

const useInAppBrowser = () => {
  const sendMessage = (message) => {
    if (isInAppBrowserEnabled) {
      if (typeof message !== 'object') {
        throw new Error('Invalid message for in-app browser communication. Message should be an object');
      }
      cordovaInAppBrowserRef.postMessage(JSON.stringify(message));
    }
  };

  const close = () => {
    sendMessage({
      action: 'close',
    });
  };

  return {
    isAvailable: isInAppBrowserEnabled,
    sendMessage,
    close,
  };
};

export default useInAppBrowser;
