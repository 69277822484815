/**
 * Supported currency codes.
 */
const CurrencyCode = {
  AUD: 'AUD',
  GBP: 'GBP',
  BRL: 'BRL',
  CAD: 'CAD',
  EUR: 'EUR',
  USD: 'USD',
};

/**
 * List of currencies supported by the app. Each time we add a new currency, we need to also add a minimum
 * average warning value in the CurrencyMinimumAvgWarning object.
 */
const CURRENCIES = [
  {
    name: 'Australian Dollar',
    code: CurrencyCode.AUD,
  },
  {
    name: 'British Pound Sterling',
    code: CurrencyCode.GBP,
  },
  {
    name: 'Brazilian Real',
    code: CurrencyCode.BRL,
  },
  {
    name: 'Canadian Dollar',
    code: CurrencyCode.CAD,
  },
  {
    name: 'Euro',
    code: CurrencyCode.EUR,
  },
  {
    name: 'US Dollar',
    code: CurrencyCode.USD,
  },
];

/**
 * Default currency. This is the currency that will be selected by default when no currency is selected or saved.
 */
const DEFAULT_CURRENCY = {
  name: 'US Dollar',
  code: CurrencyCode.USD,
};

/**
 * Currency minimum average warning. Contracts will show a warning if the average monthly amount is lower
 * than these values (in cents).
 */
const CurrencyMinimumAvgWarning = {
  [CurrencyCode.AUD]: 12000, // 120 AUD
  [CurrencyCode.GBP]: 6500, // 65 GBP
  [CurrencyCode.BRL]: 38000, // 380 BRL
  [CurrencyCode.CAD]: 10500, // 105 CAD
  [CurrencyCode.EUR]: 7500, // 75 EUR
  [CurrencyCode.USD]: 7500, // 75 USD
};

export {
  DEFAULT_CURRENCY,
  CURRENCIES,
  CurrencyMinimumAvgWarning,
};
