import React from 'react';
import PropTypes from 'prop-types';

import FormikInput from '../../../../../../../components/v2/FormikInput';
import fieldName, { questionFieldName } from '../../../../formFields';
import texts from '../../../../texts';
import {
  Container,
} from './styles';

const HiddenField = ({ index }) => {
  const fieldBaseName = `${fieldName.HIDDEN_FIELDS}.${index}`;

  return (
    <Container>
      <FormikInput
        name={`${fieldBaseName}.${questionFieldName.ID}`}
        label={texts.labels[questionFieldName.ID]}
      />
    </Container>
  );
};

HiddenField.propTypes = {
  index: PropTypes.number.isRequired,
};

export default HiddenField;
