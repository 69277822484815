import React from 'react';
import PropTypes from 'prop-types';

import {
  Container,
  StyledButton,
  CountBadge,
} from './styles';

const MAX_BADGE_COUNT = 99;

const FilterButtons = ({
  filterOptions,
  optionCounters,
  onSelectFilter,
  optionTexts,
  activeOption,
  showCounters,
  fullWidth,
  optionsWithoutBadges,
}) => (
  <Container fullWidth={fullWidth}>
    {Object.values(filterOptions).map((option) => {
      const optionCount = optionCounters[option];
      return (
        <StyledButton
          key={`button-filter-${option}`}
          onClick={() => onSelectFilter(option)}
          isSelected={activeOption === option}
          fullWidth={fullWidth}
        >
          {(showCounters && !!optionCount && !optionsWithoutBadges.includes(option))
            && (
              <CountBadge>
                {optionCount > MAX_BADGE_COUNT ? `${MAX_BADGE_COUNT}+` : optionCount}
              </CountBadge>
            )}
          {optionTexts[option]}
        </StyledButton>
      );
    })}
  </Container>
);

FilterButtons.propTypes = {
  filterOptions: PropTypes.object.isRequired,
  activeOption: PropTypes.string.isRequired,
  optionTexts: PropTypes.object,
  onSelectFilter: PropTypes.func,
  optionCounters: PropTypes.object,
  showCounters: PropTypes.bool,
  fullWidth: PropTypes.bool,
  optionsWithoutBadges: PropTypes.array,
};

FilterButtons.defaultProps = {
  optionTexts: {},
  onSelectFilter: () => { },
  optionCounters: {},
  showCounters: true,
  fullWidth: false,
  optionsWithoutBadges: [],
};

export default FilterButtons;
