import React, {
  useState,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import {
  Dialog,
  DialogActions,
  Paper,
  TextField,
} from '@mui/material';
import { TimePicker, DatePicker } from '@mui/lab';
import moment from 'moment';

import useToast from '../../../../hooks/useToast';
import { DateFormat } from '../../../../../utils/date';
import {
  StyledDialogContent,
  StyledInput,
  Title,
  StyledButton,
} from './styles';
import texts from './texts.json';

const DEFAULT_DURATION = 15;

const DateTimeModal = ({
  title,
  onSave,
  onClose,
  open,
  enableDuration,
  showOnlyPastDates,
}) => {
  const [date, setDate] = useState(moment());
  const [time, setTime] = useState(moment().startOf('hour'));
  const [duration, setDuration] = useState(DEFAULT_DURATION);
  const [isSaving, setIsSaving] = useState(false);

  const { showToast } = useToast();

  const handleSave = useCallback(async () => {
    setIsSaving(true);

    await onSave({
      date,
      time,
      duration: enableDuration && duration,
    });

    showToast(texts.callSaved, {
      options: {
        position: 'top-right',
        closeOnClick: true,
        draggable: true,
      },
    });
    setIsSaving(false);
    onClose();
  }, [
    date,
    duration,
    time,
    enableDuration,
    onClose,
    onSave,
    showToast,
  ]);

  return (
    <Dialog
      open={open}
      PaperComponent={Paper}
    >
      <Title>{title}</Title>
      <StyledDialogContent>
        <DatePicker
          label={texts.selectDate}
          value={date}
          onChange={setDate}
          maxDate={showOnlyPastDates ? moment() : null}
          inputFormat={DateFormat.DEFAULT_DATE_FORMAT}
          mask="____-__-__"
          renderInput={(params) => (
            <TextField
              {...params}
            />
          )}
        />
        <TimePicker
          label={texts.selectTime}
          value={time}
          onChange={setTime}
          maxTime={showOnlyPastDates ? moment() : null}
          renderInput={(params) => (
            <TextField
              {...params}
            />
          )}
        />
        {enableDuration && (
          <StyledInput
            variant="outlined"
            label={texts.duration}
            value={duration}
            onChange={(evt) => setDuration(evt.target.value)}
          />
        )}
      </StyledDialogContent>
      <DialogActions>
        <StyledButton onClick={onClose} style={{ height: 20 }}>
          {texts.close}
        </StyledButton>
        <StyledButton onClick={handleSave} disabled={isSaving}>
          {texts.save}
        </StyledButton>
      </DialogActions>
    </Dialog>
  );
};

DateTimeModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onSave: PropTypes.func,
  title: PropTypes.string,
  enableDuration: PropTypes.bool,
  showOnlyPastDates: PropTypes.bool,
};

DateTimeModal.defaultProps = {
  title: '',
  enableDuration: false,
  onSave: () => {},
  showOnlyPastDates: false,
};

export default compose(
  observer,
)(DateTimeModal);
