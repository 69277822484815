import React, { useContext } from 'react';

import LoadingPage from '../../../components/LoadingPage';

import CustomizationContext from './CustomizationContext';
import CustomizationContextProvider from './CustomizationContextProvider';

const withCustomizationContextProvider = (Component) => (props) => (
  <CustomizationContextProvider>
    <Component {...props} />
  </CustomizationContextProvider>
);

const withCustomizationContextReady = (Component) => (props) => {
  const { isReady } = useContext(CustomizationContext);
  return isReady ? <Component {...props} /> : <LoadingPage />;
};

export {
  withCustomizationContextProvider,
  withCustomizationContextReady,
};
