import styled from 'styled-components';
import Button from '../../../../components/Button';

const StyledButton = styled(Button)`
  width: fit-content;
  margin-top: 16px;
`;

export {
  StyledButton,
};
