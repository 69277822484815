import styled from 'styled-components';
import { IonSpinner } from '@ionic/react';

import Button from '../../components/Button';
import colors from '../../styles/colors';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: auto;
`;

const Body = styled.div`
  margin-bottom: 20px;
`;

const StyledButton = styled(Button)`
  width: 280px;
  font-size: 18px;
`;

const StyledSpinner = styled(IonSpinner)`
  width: 50px;
  height: 50px;
`;

const ErrorMessage = styled(Body)`
  color: ${colors.base.danger};
`;

const IconsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 20px;
  width: 200px;
`;

const Icon = styled.img`
  height: 60px;
  width: 60px;
`;

export {
  Container,
  StyledButton,
  Body,
  StyledSpinner,
  ErrorMessage,
  IconsContainer,
  Icon,
};
