import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import {
  Card,
  Container,
  HeaderContainer,
  Title,
  TitleContainer,
} from '../../../../../components/v2/Card';
import { StyledTextField, StyledTextFieldContainer } from '../../../../../components/Inputs';
import InputLabel from '../../../../../components/v2/InputLabel';
import SaveButton from '../../../../../components/Button/ActionButtons/SaveButton';
import { isInsideSalesAdmin } from '../../../../../utils/postHog';
import useComponentMounted from '../../../../../hooks/useComponentMounted';
import LoggedInUserContext from '../../../../../context/LoggedInUserContext';
import RadioButtonGroup from '../../../../components/RadioButtonGroup';
import LoadingOverlay from '../../../../components/LoadingOverlay';
import useToast from '../../../../hooks/useToast';
import CoachConfig, { LeadConfigOptions } from '../../../../Model/CoachConfig';
import { SettingOption } from '../../utils';
import texts from './texts.json';

const LeadSubmissionSettings = ({ coachId }) => {
  const [coachConfigDoc, setCoachConfigDoc] = useState(null);
  const [isTextBackEnabled, setIsTextBackEnabled] = useState(false);
  const [isEmailBackEnabled, setIsEmailBackEnabled] = useState(false);
  const [isWelcomeEmailEnabled, setIsWelcomeEmailEnabled] = useState(false);
  const [salesEmail, setSalesEmail] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  const { showToast } = useToast();
  const isComponentMountedRef = useComponentMounted();
  const { userDoc } = useContext(LoggedInUserContext);

  const isReadOnly = !isInsideSalesAdmin();

  const radioButtonOptions = [
    { label: texts.enabled, value: SettingOption.ENABLED },
    { label: texts.disabled, value: SettingOption.DISABLED },
  ];

  useEffect(() => {
    const init = async () => {
      setCoachConfigDoc(null);
      if (coachId) {
        const coachConfigDocument = await CoachConfig.getCoachConfigByCoachId(coachId);
        if (isComponentMountedRef.current) {
          setCoachConfigDoc(coachConfigDocument);
          setSalesEmail(coachConfigDocument?.coachEmailAddress || '');
          setIsTextBackEnabled(!!coachConfigDocument?.isTextBackEnabled);
          setIsEmailBackEnabled(!!coachConfigDocument?.isEmailResponseEnabled);
          setIsWelcomeEmailEnabled(!!coachConfigDocument?.isLeadWelcomeEmailEnabled);
        }
      }
    };
    init();
  }, [coachId, isComponentMountedRef]);

  const onSave = useCallback(async () => {
    if (isEmailBackEnabled) {
      if (!salesEmail) {
        showToast(texts.salesEmailRequired, { error: true });
        return;
      }
    }
    if (salesEmail && !salesEmail.includes('@coachserver.com')) {
      showToast(texts.salesEmailInvalid, { error: true });
      return;
    }
    setIsSaving(true);
    await coachConfigDoc.updateLeadConfig({
      [LeadConfigOptions.IS_TEXT_BACK_ENABLED]: isTextBackEnabled,
      [LeadConfigOptions.IS_EMAIL_RESPONSE_ENABLED]: isEmailBackEnabled,
      [LeadConfigOptions.EMAIL_ADDRESS]: salesEmail,
      [LeadConfigOptions.IS_WELCOME_EMAIL_ENABLED]: isWelcomeEmailEnabled,
    }, userDoc.name);
    showToast(texts.saved, { type: 'success' });
    if (isComponentMountedRef.current) {
      setIsSaving(false);
    }
  }, [
    coachConfigDoc,
    isTextBackEnabled,
    isEmailBackEnabled,
    salesEmail,
    isWelcomeEmailEnabled,
    showToast,
    isComponentMountedRef,
    userDoc,
  ]);

  return (
    <Card>
      <HeaderContainer>
        <TitleContainer>
          <Title>{texts.title}</Title>
        </TitleContainer>
      </HeaderContainer>
      <Container>
        <StyledTextFieldContainer $withMargin>
          <InputLabel>{texts.textBackOption}</InputLabel>
          <RadioButtonGroup
            options={radioButtonOptions}
            selectedOption={isTextBackEnabled ? SettingOption.ENABLED : SettingOption.DISABLED}
            onOptionChange={(val) => setIsTextBackEnabled(val === SettingOption.ENABLED)}
            disabled={isReadOnly}
          />
        </StyledTextFieldContainer>
        <StyledTextFieldContainer $withMargin>
          <InputLabel>{texts.salesEmailInput}</InputLabel>
          <StyledTextField
            placeholder={texts.salesEmailPlaceholder}
            value={salesEmail}
            onChange={(e) => setSalesEmail(e.target.value)}
            disabled={isReadOnly}
          />
        </StyledTextFieldContainer>
        <StyledTextFieldContainer $withMargin>
          <InputLabel>{texts.emailBackOption}</InputLabel>
          <RadioButtonGroup
            options={radioButtonOptions}
            selectedOption={isEmailBackEnabled ? SettingOption.ENABLED : SettingOption.DISABLED}
            onOptionChange={(val) => setIsEmailBackEnabled(val === SettingOption.ENABLED)}
            disabled={isReadOnly}
          />
        </StyledTextFieldContainer>
        <StyledTextFieldContainer $withMargin>
          <InputLabel>{texts.welcomeEmailOption}</InputLabel>
          <RadioButtonGroup
            options={radioButtonOptions}
            selectedOption={isWelcomeEmailEnabled ? SettingOption.ENABLED : SettingOption.DISABLED}
            onOptionChange={(val) => setIsWelcomeEmailEnabled(val === SettingOption.ENABLED)}
            disabled={isReadOnly}
          />
        </StyledTextFieldContainer>
        <SaveButton
          onClick={onSave}
          disabled={isReadOnly}
          $fitToContent
        >
          {texts.save}
        </SaveButton>
      </Container>
      <LoadingOverlay isLoading={!coachConfigDoc || isSaving} />
    </Card>
  );
};

LeadSubmissionSettings.propTypes = {
  coachId: PropTypes.string,
};

LeadSubmissionSettings.defaultProps = {
  coachId: '',
};
export default LeadSubmissionSettings;
