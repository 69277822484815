import { TagCategory } from '../../../../../Model/ExerciseTag';

const texts = {
  createNewTag: 'Create a New Tag',
  editTag: 'Edit Tag',
  tagName: 'Tag Name',
  tagCategory: 'Tag Category',
  cancel: 'Cancel',
  addNewTag: 'Add New Tag',
  saveChanges: 'Save Changes',
  tagCategoryOption: {
    [TagCategory.EXERCISE_TYPE]: 'Exercise Type',
    [TagCategory.EQUIPMENT]: 'Equipment',
    [TagCategory.GENERIC]: 'Generic',
    [TagCategory.BODY_REGION_ADVANCED]: 'Body Region Advanced',
    [TagCategory.BODY_REGION_BASIC]: 'Body Region Basic',
  },
  nameRequired: 'Tag name is required',
  categoryRequired: 'Tag category is required',
};

export default texts;
