import React from 'react';
import {
  Route,
  Redirect,
  Switch,
  useRouteMatch,
} from 'react-router-dom';
import { compose } from 'recompose';

import {
  withUserContextProvider,
  withUserContextReady,
} from '../../context/UserContext';

import { withNavigationContext } from '../context/NavigationContext';
import {
  withLoggedInUserContextProvider,
  withLoggedInUserContextReady,
} from '../../context/LoggedInUserContext';
import { withToolsLayout } from '../layout';
import useToolsNavigation from '../hooks/useToolsNavigation';

import ToolsRoute from './ToolsRoute';
import useRoutesConfig from './useRoutesConfig';

const ToolsRoutes = () => {
  const { path } = useRouteMatch();
  const { pathname } = useToolsNavigation();
  const routes = useRoutesConfig();

  const defaultRouteConfig = routes.find(({ defaultRoute }) => !!defaultRoute);

  return (
    <Switch>
      {
        routes.map(({
          pageId,
          path: routePath,
          component,
          props,
        }) => (
          <ToolsRoute
            key={pageId}
            pageId={pageId}
            path={`${path}/${routePath}`}
            component={component}
            props={props}
          />
        ))
      }
      {
        !!defaultRouteConfig && (
          <Route exact path={path}>
            <Redirect to={{ pathname: `${pathname}/${defaultRouteConfig.path}` }} />
          </Route>
        )
      }
    </Switch>
  );
};

export default compose(
  withUserContextProvider,
  withUserContextReady,
  withLoggedInUserContextProvider,
  withLoggedInUserContextReady,
  withNavigationContext,
  withToolsLayout,
)(ToolsRoutes);
