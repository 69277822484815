import React, {
  useState,
  useContext,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';

import {
  Tab,
} from '@mui/material';
import {
  DatePicker,
  TimePicker,
} from '@mui/lab';

import {
  isToday,
  combineDateAndTime,
} from '../../../../utils/date';
import UserContext from '../../../../context/UserContext';
import CoachReminderNotification from '../../../../Model/CoachReminderNotification';

import TabPanel from '../../../components/TabPanel';
import ScheduledMessagesList from './components/ScheduledReminders';

import {
  Container,
  ScheduleReminderButton,
  CloseButton,
  StyledHeader,
  TitleContainer,
  ModalContent,
  StyledModal,
  StyledTextField,
  StyledTabs,
  StyledTextarea,
} from './styles';

import text from './text.json';

const CoachReminderModal = ({
  isOpen,
  showReminderScheduled,
  onDidDismiss,
  showReminderError,
  selectedSendDate,
  ...modalProps
}) => {
  const [tabSelected, setTabSelected] = useState(0);
  // reminder notification values states
  const [reminderText, setReminderText] = useState(null);
  const [sendDate, setSendDate] = useState(moment().local());
  const [sendTime, setSendTime] = useState(moment().local());

  const { userId } = useContext(UserContext);

  const resetFields = useCallback(() => {
    setReminderText(null);
    setSendDate(moment().local());
    setSendTime(moment().local());
  }, []);

  useEffect(() => {
    if (selectedSendDate) {
      setSendDate(moment(selectedSendDate).local());
    }
  }, [selectedSendDate]);

  const handleReminderSchedule = useCallback(async () => {
    // Convert to UTC after adding date and time
    try {
      const sendAt = combineDateAndTime(sendDate, sendTime).utc().valueOf();
      await CoachReminderNotification.create({
        content: reminderText,
        coach: userId,
        sendAt,
      });
      resetFields();
      showReminderScheduled();
      setTabSelected(1);
    } catch (error) {
      Sentry.captureException(error, {
        extra: {
          userId,
        },
      });
      showReminderError();
    }
  }, [
    sendDate,
    sendTime,
    reminderText,
    resetFields,
    showReminderScheduled,
    showReminderError,
    userId,
  ]);

  // If the selected date is today, then the user cannot select a time in the past
  const minTime = useMemo(() => (
    isToday(sendDate) ? moment().local() : null
  ), [
    sendDate,
  ]);

  const onTabChange = useCallback((event, value) => {
    setTabSelected(value);
  }, []);

  const a11yProps = useCallback((index) => ({
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  }), []);

  return (
    <StyledModal
      isOpen={isOpen}
      onDidDismiss={onDidDismiss}
      showReminderScheduled={showReminderScheduled}
      {...modalProps}
    >
      <Container>
        <StyledHeader>
          <TitleContainer>{text.coachReminder}</TitleContainer>
          <CloseButton onClick={onDidDismiss} />
        </StyledHeader>
        <ModalContent>
          <StyledTabs value={tabSelected} onChange={onTabChange}>
            <Tab label={text.newReminder} {...a11yProps(0)} />
            <Tab label={text.scheduledReminders} {...a11yProps(1)} />
          </StyledTabs>
          <TabPanel
            index={0}
            value={tabSelected}
          >
            <DatePicker
              label={text.selectDate}
              value={sendDate}
              onChange={(newValue) => setSendDate(newValue)}
              minDate={moment().local()}
              renderInput={(params) => (
                <StyledTextField {...params} />
              )}
            />
            <TimePicker
              label={text.selectTime}
              value={sendTime}
              onChange={(newValue) => setSendTime(newValue)}
              minTime={minTime}
              minutesStep={5}
              renderInput={(params) => (
                <StyledTextField {...params} />
              )}
            />

            <StyledTextarea
              placeholder={text.reminderPlaceholder}
              value={reminderText}
              rows={5}
              onIonChange={(e) => setReminderText(e.detail.value)}
            />

            <ScheduleReminderButton
              onClick={handleReminderSchedule}
              disabled={!reminderText}
            >
              {text.scheduleReminder}
            </ScheduleReminderButton>

          </TabPanel>
          <TabPanel
            index={1}
            value={tabSelected}
          >
            <ScheduledMessagesList userId={userId} />
          </TabPanel>
        </ModalContent>
      </Container>
    </StyledModal>
  );
};

CoachReminderModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onDidDismiss: PropTypes.func.isRequired,
  showReminderScheduled: PropTypes.func.isRequired,
  showReminderError: PropTypes.func.isRequired,
  selectedSendDate: PropTypes.string,
};

CoachReminderModal.defaultProps = {
  selectedSendDate: null,
};

export default CoachReminderModal;
