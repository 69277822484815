import React from 'react';
import {
  Route,
  Redirect,
  Switch,
  useRouteMatch,
} from 'react-router-dom';
import { compose } from 'recompose';

import { withUserContextProvider } from '../context/UserContext';

import Home from '../pages/Home';

const UserRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/home`} component={Home} />
      <Route exact path={`${path}`}>
        <Redirect to={{ pathname: `${path}/home` }} />
      </Route>
    </Switch>
  );
};

export default compose(
  withUserContextProvider,
)(UserRoutes);
