import React from 'react';

import { NavigationRouteType } from './navigationRouteTypes';

const NavigationContext = React.createContext({
  activePage: null,
  setActivePage: () => {},
  routeType: NavigationRouteType.COACH,
  isAdminGroupRoute: false,
});

export default NavigationContext;
