import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import CoachAvgClientLifespanAnalytics from '../../../../../Model/analytics/CoachAvgClientLifespanAnalytics';
import BarChart from '../../../../../components/BarChart';
import { formatNumber } from '../../../../../../utils/formatters';
import { DateFormat } from '../../../../../../utils/date';
import ChartContainer from '../../ChartContainer';
import texts from './texts.json';

const AvgClientLifespanChart = ({
  lifespanAnalytics,
}) => (
  <ChartContainer title={texts.averageClientLifespan}>
    <BarChart
      data={lifespanAnalytics
        .map((lifespanAnalytic) => ({
          name: lifespanAnalytic.id,
          'Avg. Lifespan': lifespanAnalytic.avgLifespan,
        }))}
      keys={[{ name: 'Avg. Lifespan' }]}
      toolTipFormatter={(value) => `${formatNumber(value, { maximumFractionDigits: 0 })} days`}
      labelFormatter={(yearMonth) => moment(yearMonth, DateFormat.YEAR_MONTH_FORMAT)
        .format(DateFormat.YEAR_MONTH_SHORT_DISPLAY_FORMAT)}
    />
  </ChartContainer>
);

AvgClientLifespanChart.propTypes = {
  lifespanAnalytics: PropTypes.arrayOf(PropTypes.instanceOf(CoachAvgClientLifespanAnalytics)).isRequired,
};

export default AvgClientLifespanChart;
