import React from 'react';
import PropTypes from 'prop-types';

import AnalyticNumberCard from '../../AnalyticNumberCard';
import CoachRevenueData from '../../../../../Model/analytics/CoachRevenueData';
import texts from './texts.json';

const RevenueAnalyticsCard = ({
  revenueAnalytics,
}) => (
  <AnalyticNumberCard
    title={revenueAnalytics?.revenueWithCurrency}
    subTitle={texts.revenue}
    bottomStats={[
      {
        title: revenueAnalytics?.avgPayment,
        subTitle: texts.avgPay,
      },
      {
        title: revenueAnalytics?.payments,
        subTitle: texts.payments,
      },
    ]}
  />
);

RevenueAnalyticsCard.propTypes = {
  revenueAnalytics: PropTypes.instanceOf(CoachRevenueData).isRequired,
};

export default RevenueAnalyticsCard;
