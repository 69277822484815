const EventTypes = {
  COACH_SELECTED: 'coachSelected',
  EMAIL_COLLECTED: 'emailCollected',
  STRIPE_SUBSCRIPTION_CREATED: 'stripeSubscriptionCreated',
  SCHEDULE: 'onboardingAppointmentScheduled',
};

export {
  EventTypes,
};
