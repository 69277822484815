const FormField = {
  NAME: 'name',
  NOTE: 'note',
  SIDE: 'side',
  SET: 'set',
  DURATION: 'duration',
  SET_TIME: 'setTime',
  SET_TIME_UNIT: 'setTimeUnit',
  REST_TIME: 'restTime',
  REST_TIME_UNIT: 'restTimeUnit',
  REPETITION: 'repetition',
};

export default FormField;
