import styled from 'styled-components';
import DownloadCSVButton from '../../../../components/DownloadCSVButton';

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 0px;
`;

const StyledDownloadButton = styled(DownloadCSVButton)`
  width: 200px;
  height: 35px;
  top: -90px;
  margin-right: 20px;

  > svg {
    margin-right: 10px;
  }
`;

export {
  StyledDownloadButton,
  ButtonContainer,
};
