import styled from 'styled-components';

import Button from '../../../components/Button';
import colors from '../../../styles/colors';

const chatIconPx = 30;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 8px;
`;

const StyledButton = styled(Button)`
  background-color: transparent;
  color: ${colors.shades.secondary3};
  border-radius: 50%;
  width: ${chatIconPx}px;
  height: ${chatIconPx}px;
  padding: 8px;
  margin-left: 2px;
  cursor: pointer;
  &:hover {
    background-color: ${colors.shades.gamma1};
  }
`;

export {
  Container,
  StyledButton,
};
