import React from 'react';
import PropTypes from 'prop-types';
import {
  LineChart as ReLineChart,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ReferenceLine,
  Line,

} from 'recharts';
import colors from '../../../../../styles/colors';

const LineChart = ({
  data,
  keys,
  labelFormatter,
  toolTipFormatter,
}) => (
  <ReLineChart
    width={500}
    height={300}
    data={data}
    stackOffset="sign"
    margin={{
      top: 5,
      right: 30,
      left: 20,
      bottom: 5,
    }}
  >
    <XAxis dataKey="name" />
    <YAxis />
    <Tooltip
      labelFormatter={labelFormatter}
      formatter={toolTipFormatter}
    />
    <Legend />
    <ReferenceLine y={0} stroke="#000" />
    {keys.map((key, index) => (
      <Line
        key={key}
        dataKey={key}
        stroke={colors.chartColors[index % colors.chartColors.length]}
        dot={false}
        strokeWidth={2}
      />
    ))}
  </ReLineChart>
);

LineChart.propTypes = {
  data: PropTypes.array.isRequired,
  keys: PropTypes.arrayOf(PropTypes.string).isRequired,
  labelFormatter: PropTypes.func,
  toolTipFormatter: PropTypes.func,
};

LineChart.defaultProps = {
  labelFormatter: null,
  toolTipFormatter: null,
};

export default LineChart;
