import React, {
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Formik,
  FastField,
  Form,
} from 'formik';
import { Alert } from '@mui/material';
import format from 'string-template';

import { ReactComponent as CheckIcon } from '../../../../../../assets/icons/v2/check-circle.svg';
import { ReactComponent as CancelIcon } from '../../../../../../assets/icons/v2/cross-cancel.svg';
import UserContext from '../../../../../../context/UserContext';
import WorkoutContext from '../../../../../context/WorkoutContext';
import {
  HeaderRow,
  Title,
} from '../../../../../../components/v2/Header';
import FormikInput from '../../../../../../components/v2/FormikInput';
import { PrimaryButton } from '../../../../../../components/Button/ActionButtons';
import {
  SectionContainer,
  SectionHeaderContainer,
  SectionTitle,
  SectionCompartment,
  SectionFooterContainer,
} from '../../../../../../components/v2/Section';
import useComponentMounted from '../../../../../../hooks/useComponentMounted';
import Program, {
  DifficultyLevel,
} from '../../../../../../Model/Program';
import WorkoutEditorModal from '../../../../../components/WorkoutEditorModal';
import LoadingOverlay from '../../../../../components/LoadingOverlay';
import useToast from '../../../../../hooks/useToast';
import { InfoTag } from '../../../../../../components/Tags';
import RadioButtonGroup from '../../../../../components/RadioButtonGroup';
import {
  ActionType,
  getWorkoutEditorMobileUrl,
} from '../../../Workouts/utils';
import useLogger from '../../../../../../hooks/useLogger';
import { CoachingActivity } from '../../../../../../utils/log';
import {
  WorkoutDaysSelector,
  WorkoutPreview,
} from './components';
import {
  Container,
  FormSection,
  Label,
  StyledFormRowScale,
  EquipmentContainer,
  FieldContent,
  StyledSaveIcon,
  WorkoutDaysSection,
  ContainerContent,
} from './styles';
import { fieldName } from './formFields';
import {
  initialValues as startValues,
  validationSchema,
  initialWorkoutDayValues,
} from './validation';
import texts from './texts';

const difficultyLevelOptions = Object.values(DifficultyLevel).map((difficultyLevel) => ({
  label: texts.difficultyLevel[difficultyLevel],
  value: difficultyLevel,
}));

const ProgramEditor = ({
  programDoc,
  onClose,
  isEditView,
}) => {
  const { showToast } = useToast();
  const isComponentMountedRef = useComponentMounted();
  const { userId: coachId } = useContext(UserContext);

  const { filteredWorkouts } = useContext(WorkoutContext);

  const [isLoading, setIsLoading] = useState(false);
  const [mobileUrl, setMobileUrl] = useState('');
  const [selectedWorkout, setSelectedWorkout] = useState(null);
  const [previewKey, setPreviewKey] = useState(0);

  const { logCoachingActivity } = useLogger();

  const onWorkoutAssignmentClick = useCallback((workoutAssignment) => {
    setMobileUrl(getWorkoutEditorMobileUrl(coachId, workoutAssignment, false));
  }, [coachId]);

  const onEditWorkoutClick = useCallback((workoutId) => {
    const workout = filteredWorkouts.find(({ id }) => id === workoutId);
    setSelectedWorkout(workout);
  }, [filteredWorkouts]);

  const onSaveWorkout = useCallback(() => {
    setSelectedWorkout(null);
    // Force render the preview mobile view if it's open, to reflect any updates done on the workout
    if (mobileUrl) {
      setPreviewKey((prev) => prev + 1);
    }
  }, [mobileUrl]);

  /**
   * Initializes the workoutList with properly formatted workouts.
   *
   * If the workout reference is null, it is considered as the initial workout value (Rest & Recovery).
   * If it is not null, retrieves workout days values from workoutDetails.
   */
  const getFormattedWorkouts = useCallback((workoutDays) => {
    const assignedWorkouts = [];
    workoutDays.forEach((workoutDay) => {
      if (!workoutDay.workoutRef) {
        assignedWorkouts.push(initialWorkoutDayValues);
      } else {
        /**
         * Sometimes, if a workout is archived after being assigned,
         * that program may not be included within the 'workoutDetails' list.
         * In such cases, that workout is considered as the initial workout (Rest & Recovery).
         */
        const workoutDetails = filteredWorkouts.find(({ id }) => id === workoutDay.workoutRef);
        let assignedWorkout = {};
        if (!workoutDetails) {
          assignedWorkout = initialWorkoutDayValues;
        } else {
          assignedWorkout = {
            id: workoutDetails.id,
            label: workoutDetails.name,
            equipment: workoutDetails.equipmentList,
            workout: workoutDetails,
          };
        }
        assignedWorkouts.push(assignedWorkout);
      }
    });
    return assignedWorkouts;
  }, [
    filteredWorkouts,
  ]);

  const initialValues = useMemo(() => (
    programDoc
      ? {
        ...programDoc.data,
        [fieldName.WORKOUT_DAYS]: getFormattedWorkouts(programDoc.workouts),
        [fieldName.EQUIPMENT]: programDoc.equipment,
      }
      : startValues
  ), [
    programDoc,
    getFormattedWorkouts,
  ]);

  const handleSubmit = useCallback(async (values) => {
    setIsLoading(true);
    const workoutsList = Array.from(values[fieldName.WORKOUT_DAYS], (workout) => ({ workoutRef: workout.id }));
    const programData = {
      name: values[fieldName.NAME],
      workouts: workoutsList,
      ...(!!values[fieldName.DIFFICULTY] && { difficulty: values[fieldName.DIFFICULTY] }),
      equipment: values[fieldName.EQUIPMENT],
      ...(!programDoc
        ? {
          coach: coachId,
          isArchived: false,
          createdAt: new Date().getTime(),
        }
        : {
          updatedAt: new Date().getTime(),
        }),
    };
    if (programDoc) {
      await programDoc.updateFields(programData);
      logCoachingActivity(CoachingActivity.UPDATED_PROGRAM, { programId: programDoc.id });
    } else {
      const program = await Program.addDoc(programData);
      logCoachingActivity(CoachingActivity.CREATED_PROGRAM, { programId: program.id });
    }
    if (isComponentMountedRef.current) {
      showToast(texts.programSaved);
      setIsLoading(false);
      onClose();
    }
  }, [
    showToast,
    isComponentMountedRef,
    coachId,
    programDoc,
    onClose,
    logCoachingActivity,
  ]);

  return (
    <>
      {isEditView && (
        <Alert severity="warning">{format(texts.updateProgramAlert, { program: programDoc.name })}</Alert>
      )}
      <Container>
        <HeaderRow>
          <Title>{isEditView ? texts.editProgramTitle : texts.addNewProgramTitle}</Title>
        </HeaderRow>
        <ContainerContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({
              values,
              isSubmitting,
              setFieldValue,
            }) => (
              <Form>
                <SectionContainer>
                  <SectionHeaderContainer>
                    <SectionTitle>{isEditView ? texts.editProgramTitle : texts.addNewProgram}</SectionTitle>
                  </SectionHeaderContainer>
                  <SectionCompartment>
                    <FormSection>
                      <FormikInput
                        name={fieldName.NAME}
                        label={`${texts.field[fieldName.NAME]}:`}
                      />
                    </FormSection>
                    <WorkoutDaysSection>
                      <FieldContent>
                        <WorkoutDaysSelector
                          workoutDays={values[fieldName.WORKOUT_DAYS]}
                          onPreviewWorkout={onWorkoutAssignmentClick}
                          onEditWorkout={onEditWorkoutClick}
                          onClosePreview={() => setMobileUrl('')}
                        />
                      </FieldContent>
                    </WorkoutDaysSection>
                    <FormSection>
                      <FieldContent>
                        <Label>{`${texts.field[fieldName.DIFFICULTY]}:`}</Label>
                        <StyledFormRowScale>
                          <RadioButtonGroup
                            options={difficultyLevelOptions}
                            showStyledOptions={false}
                            selectedOption={values[fieldName.DIFFICULTY]}
                            onOptionChange={(value) => setFieldValue(fieldName.DIFFICULTY, value)}
                          />
                        </StyledFormRowScale>
                      </FieldContent>
                    </FormSection>
                    <FormSection>
                      <FastField name={fieldName.EQUIPMENT}>
                        {({ field }) => (
                          <FieldContent>
                            <Label>{`${texts.field[fieldName.EQUIPMENT]}:`}</Label>
                            {field.value.length > 0 ? (
                              <EquipmentContainer>
                                {field.value.map((equipment) => (
                                  <InfoTag>{equipment}</InfoTag>
                                ))}
                              </EquipmentContainer>
                            ) : (
                              <div>{texts.emptyValue}</div>
                            )}
                          </FieldContent>
                        )}
                      </FastField>
                    </FormSection>
                  </SectionCompartment>
                  <SectionFooterContainer>
                    <PrimaryButton
                      type="submit"
                      size="medium"
                      icon={isEditView ? <StyledSaveIcon /> : <CheckIcon />}
                      disabled={isSubmitting}
                    >
                      {isEditView ? texts.saveChanges : texts.addNewProgram}
                    </PrimaryButton>
                    <PrimaryButton
                      onClick={onClose}
                      icon={<CancelIcon />}
                      variant="info"
                      size="medium"
                      disabled={isSubmitting}
                    >
                      {texts.cancel}
                    </PrimaryButton>
                  </SectionFooterContainer>
                </SectionContainer>
              </Form>
            )}
          </Formik>
          {!!mobileUrl && (
            <WorkoutPreview key={previewKey} mobileUrl={mobileUrl} />
          )}
        </ContainerContent>
        {!!selectedWorkout && (
          <WorkoutEditorModal
            showModal={!!selectedWorkout}
            workout={selectedWorkout}
            onSave={onSaveWorkout}
            onClose={() => setSelectedWorkout(null)}
            editorAction={ActionType.UPDATE}
          />
        )}
        <LoadingOverlay isLoading={isLoading} />
      </Container>
    </>
  );
};

ProgramEditor.propTypes = {
  programDoc: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  isEditView: PropTypes.bool,
};

ProgramEditor.defaultProps = {
  programDoc: null,
  isEditView: false,
};

export default ProgramEditor;
