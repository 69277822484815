import styled, { css } from 'styled-components';

import { ReactComponent as DeleteIcon } from '../../../../../../assets/icons/v2/trash.svg';
import { ReactComponent as EditIcon } from '../../../../../../assets/icons/v2/edit-blue.svg';

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
`;

const iconStyles = css`
  width: 16px;
`;

const StyledEditIcon = styled(EditIcon)`
  ${iconStyles};
`;

const StyledDeleteIcon = styled(DeleteIcon)`
  ${iconStyles};
`;

export {
  ActionsContainer,
  StyledEditIcon,
  StyledDeleteIcon,
};
