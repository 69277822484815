import React, {
  useMemo,
  useContext,
  useCallback,
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { compose } from 'recompose';

import { getQueryVariable } from '../../../utils/queryParams';
import useQuickSearch from '../../../hooks/useQuickSearch';
import { DateFormat } from '../../../utils/date';
import { formatCurrency } from '../../../utils/formatters';
import PayoutReconciliationContext from '../../context/PayoutReconciliationContext';
import InternalAssignmentContext, {
  withInternalAssignmentsContextProvider,
  withInternalAssignmentsContextReady,
} from '../../context/InternalAssignmentsContext';
import PayoutReconciliation from '../../Model/PayoutReconciliation';
import MonthSelect from '../MonthSelect';
import TableWithFilters from '../TableWithFilters';
import TableFilter from '../TableFilter';
import texts from './texts.json';
import { getColumnsConfig } from './columns';
import { PayoutConfigByPreset, Preset, presetOptions } from './presets';
import {
  StyledAutoComplete,
  StyledTextField,
  PermissionsErrorContainer,
} from './styles';

const LEAD_THRESHOLD = 65000;
const LEAD_PERCENTAGE = 0.01;
const COMMISSION_CURRENCY = 'usd';

const Commissions = ({
  userId,
  title,
}) => {
  const showPage = useMemo(() => getQueryVariable('isSuperUser') === 'true', []);

  const [selectedPreset, setSelectedPreset] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [payoutsConfig, setPayoutsConfig] = useState(null);

  const { isUsers } = useContext(InternalAssignmentContext);
  const [selectedIS, setSelectedIS] = useState([]);
  const isUserOptions = useMemo(() => (
    isUsers.map(({ userId: value, userName: label }) => ({ value, label }))
  ), [isUsers]);

  const { crxUsers } = useContext(InternalAssignmentContext);
  const [selectedCRX, setSelectedCRX] = useState([]);
  const crxUserOptions = useMemo(() => (
    crxUsers.map(({ userId: value, userName: label }) => ({ value, label }))
  ), [crxUsers]);

  const {
    payoutReconciliationCollection,
    loadPayoutReconciliationData,
    clearData,
    isLoadingDocs,
  } = useContext(PayoutReconciliationContext);

  const onSelectedPresetChange = useCallback((_, selectedOption) => {
    setSelectedIS([]);
    setSelectedCRX([]);
    setSelectedPreset(selectedOption);
    setPayoutsConfig(selectedOption?.id ? PayoutConfigByPreset[selectedOption.id] : null);
    clearData();
  }, [
    clearData,
  ]);

  const onSelectCRXChange = useCallback((selectedOption) => {
    setSelectedCRX(selectedOption);
  }, []);

  const filtersArray = useMemo(() => {
    /*
      We have to render the filters based on the preset selected. For CRX and CRX_LEAD, we have to render the CRX
      filter. The IS filter will be rendered if the preset INSIDE_SALES or INSIDE_SALES_LEAD is sslected.
    */
    const filters = [];

    // The preset is always rendered
    filters.push(
      <StyledAutoComplete
        onChange={onSelectedPresetChange}
        options={presetOptions}
        key={texts.preset}
        renderInput={(params) => <StyledTextField {...params} label={texts.preset} />}
      />,
    );

    // Render the month selector
    filters.push(
      <MonthSelect
        onChange={setSelectedDate}
        key={texts.monthSelect}
      />,
    );

    // Render the correct filter based on the preset
    if (selectedPreset?.id === Preset.CRX || selectedPreset?.id === Preset.CRX_LEAD) {
      filters.push(
        <TableFilter
          onSelect={onSelectCRXChange}
          options={crxUserOptions}
          initialSelection={[]}
          key={texts.selectCRX}
          title={texts.selectCRX}
        />,
      );
    }

    if (selectedPreset?.id === Preset.INSIDE_SALES || selectedPreset?.id === Preset.INSIDE_SALES_LEAD) {
      filters.push(
        <TableFilter
          onSelect={setSelectedIS}
          options={isUserOptions}
          initialSelection={[]}
          key={texts.selectIS}
          title={texts.selectIS}
        />,
      );
    }

    return filters;
  }, [
    selectedPreset,
    onSelectCRXChange,
    onSelectedPresetChange,
    isUserOptions,
    crxUserOptions,
  ]);

  useEffect(() => {
    // Check if we have to fetch data.
    if (selectedPreset && selectedDate) {
      const { id: dateMonth } = selectedDate;
      const fromDate = moment.utc(dateMonth).startOf('month').format(DateFormat.DEFAULT_DATE_FORMAT);
      const toDate = moment.utc(dateMonth).endOf('month').format(DateFormat.DEFAULT_DATE_FORMAT);

      if (selectedCRX?.length) {
        loadPayoutReconciliationData(
          PayoutReconciliation.getPayoutReconciliationByCRXList,
          selectedCRX,
          fromDate,
          toDate,
        );
      } else if (selectedIS?.length) {
        loadPayoutReconciliationData(
          PayoutReconciliation.getPayoutReconciliationBySellerIdList,
          selectedIS,
          fromDate,
          toDate,
        );
      } else {
        clearData();
      }
    } else {
      clearData();
    }
  }, [
    selectedPreset,
    selectedDate,
    selectedCRX,
    selectedIS,
    loadPayoutReconciliationData,
    clearData,
    userId,
  ]);

  const columns = useMemo(() => (payoutsConfig ? getColumnsConfig(payoutsConfig) : []), [payoutsConfig]);

  // Get the first filtering stage which returns the rows that match the quick search input.
  const {
    filteredRows,
  } = useQuickSearch(payoutReconciliationCollection.docs, columns);

  const csvFileName = !!selectedPreset && !!selectedDate?.id
    ? `${texts.csvFileNamePrefix}${selectedPreset.id}_${selectedDate?.id}`
    : '';

  const renderCommissionAmount = () => {
    if (!selectedPreset || !selectedDate) {
      return null;
    }

    /*
      The leads commissions is calculated as follows:
      - Total commission = Base + IS + AC
      - Lead commission = Base + IS
      - Subtract 65k from the total commission (only for CRX's Leads)
      - Get 1% of that amount
    */
    let commissionAmount = 0;
    // Sum the commission amount
    if (selectedPreset.id === Preset.CRX_LEAD || selectedPreset.id === Preset.INSIDE_SALES_LEAD) {
      const baseCommissionAmount = filteredRows.reduce((acc, row) => {
        const {
          feesConfiguration: {
            baseFee,
            insideSalesFee = {
              value: 0,
            },
          },
          commissionableFeeAmount,
          totalCommissionableFeePercentage,
        } = row;

        // Lead fees sum
        const feePercentage = baseFee.value + insideSalesFee.value;

        let amount = 0;
        if (totalCommissionableFeePercentage) {
          // Get the commissionable amount for leads, based on the percentage calculated on leadFeePercetage.
          amount = (feePercentage * commissionableFeeAmount) / totalCommissionableFeePercentage;
        }

        return acc + amount;
      }, 0);

      let leadCommission = 0;
      if (selectedPreset.id === Preset.CRX_LEAD) {
        if (baseCommissionAmount > LEAD_THRESHOLD) {
          leadCommission = (baseCommissionAmount - LEAD_THRESHOLD);
        }
      } else {
        // INSIDE_SALES_LEAD will get a commission for the entirety of the base commission.
        leadCommission = baseCommissionAmount;
      }

      // Get 1% of the lead commission
      commissionAmount = leadCommission * LEAD_PERCENTAGE;
    } else {
      commissionAmount = filteredRows.reduce((acc, row) => {
        const amount = row[payoutsConfig.commissionNetAmount];
        return acc + amount;
      }, 0);
    }

    const formattedCommission = formatCurrency(commissionAmount, COMMISSION_CURRENCY, { minimumFractionDigits: 2 });

    return <div>{`${texts.commissionAmount} ${formattedCommission}`}</div>;
  };

  // Only superUsers can see this page
  if (!showPage) {
    return <PermissionsErrorContainer>{texts.permissionsError}</PermissionsErrorContainer>;
  }

  return (
    <TableWithFilters
      title={title}
      description={renderCommissionAmount()}
      rows={filteredRows}
      columns={columns}
      columnVisibilityModel={{
        // Hide columns
        coach: false,
        connectedAccountDirectChargeId: false,
        insideSalesCommission: false,
        crxCommission: false,
        feesConfigurationObject: false,
      }}
      isLoading={isLoadingDocs}
      csvHeaders={columns.map(({ field }) => field)}
      csvFileName={csvFileName}
      filters={filtersArray}
    />
  );
};

Commissions.propTypes = {
  userId: PropTypes.string.isRequired,
  title: PropTypes.string,
};

Commissions.defaultProps = {
  title: texts.title,
};

export default compose(
  withInternalAssignmentsContextProvider,
  withInternalAssignmentsContextReady,
)(Commissions);
