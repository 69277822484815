import React, { useContext } from 'react';
import format from 'string-template';
import PropTypes from 'prop-types';

import { ButtonPlacement } from '../../../../components/CopyField';
import { getOnboardingLinkFromLead } from '../../../utils/onboardingLink';
import ContractModalContext from '../context/ContractModalContext';
import { ContractState } from '../utils/ContractTypes';
import ContractConfigurator from '../ContractConfigurator';

import { Section } from '../Section';
import {
  DetailsPanel,
  StyledContractDetails,
  StyledCopyField,
  Title,
} from './styles';
import texts from './texts.json';

const ContractEditor = ({
  showFeesConfigurator,
}) => {
  const {
    coachDoc,
    externalCoachDoc,
    products,
    contract,
    contractState,
    selectedProduct,
    isReadOnly,
    contractTerms,
    onContractFieldChange,
    onPackageSelected,
    onProductChange,
    contractDoc,
    lead,
    isReonboardingContract,
  } = useContext(ContractModalContext);

  return (
    <>
      {contractState === ContractState.CONFIGURATION && !isReadOnly && (
        <ContractConfigurator
          products={products}
          selectedProduct={selectedProduct}
          onProductChange={onProductChange}
          contract={contract}
          contractDoc={contractDoc}
          onChange={onContractFieldChange}
          isReadOnly={isReadOnly}
          onPackageChange={onPackageSelected}
          supportedCurrencies={externalCoachDoc?.supportedCurrencies}
          showFeesConfigurator={showFeesConfigurator}
        />
      )}
      {(contractState === ContractState.DETAILS || isReadOnly) && (
        <DetailsPanel>
          <Section>
            <Title>{texts.detailsTitle}</Title>
            <StyledContractDetails
              contractTerms={contractTerms}
              coachConditions={contract.coachConditions}
            />
          </Section>
          {!!lead?.id && !isReadOnly && !isReonboardingContract && (
            <Section>
              <StyledCopyField
                id="copy-contract-link"
                label={texts.copyLink}
                value={getOnboardingLinkFromLead(lead.id)}
                valuePrefix={format(texts.linkPrefix, { leadName: lead?.name, coachName: coachDoc?.name })}
                placement={ButtonPlacement.START}
              />
            </Section>
          )}
        </DetailsPanel>
      )}
    </>
  );
};

ContractEditor.propTypes = {
  showFeesConfigurator: PropTypes.bool,
};

ContractEditor.defaultProps = {
  showFeesConfigurator: false,
};

export default ContractEditor;
