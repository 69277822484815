import React from 'react';
import PropTypes from 'prop-types';

import { StyledPageContent } from './styles';

const PageContent = ({
  children,
}) => (
  <StyledPageContent>{children}</StyledPageContent>
);

PageContent.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageContent;
