import styled from 'styled-components';
import { Draggable } from 'react-smooth-dnd';
import { ListItemText } from '@mui/material';

import colors from '../../../../../../../styles/colors';

const Container = styled.div`
  display: flex;
  width: 100%;
  text-align: center;
  padding: 20px;
`;

const StyledDraggable = styled(Draggable)`
  background: ${colors.base.gamma};
  border-radius: 5px;
  margin-bottom: 5px;
  border: ${colors.shades.gamma5} solid;
`;

const StyledListItemText = styled(ListItemText)`
  text-decoration: ${({ $isHidden, $isEditing }) => (($isHidden && !$isEditing) ? 'line-through' : 'none')};
`;

export {
  Container,
  StyledDraggable,
  StyledListItemText,
};
