const FileCategory = {
  IMAGE: 'image',
  VIDEO: 'video',
  OTHER: 'other',
};

const getFileExtension = (filename) => {
  const parts = filename.split('.');
  return parts[parts.length - 1];
};

const getFileName = (filename) => {
  const parts = filename.split('.');
  return parts.slice(0, parts.length - 1).join('.');
};

const getFileUrl = (file) => (file ? URL.createObjectURL(file) : null);

const isVideo = (file) => (file ? file.type.includes('video') : false);

const isImage = (file) => (file ? file.type.includes('image') : false);

const getFileCategory = (file) => {
  if (isVideo(file)) {
    return FileCategory.VIDEO;
  }
  if (isImage(file)) {
    return FileCategory.IMAGE;
  }
  return FileCategory.OTHER;
};

export {
  FileCategory,
  getFileExtension,
  getFileName,
  getFileUrl,
  isVideo,
  isImage,
  getFileCategory,
};
