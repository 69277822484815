import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  Tooltip,
  Menu,
  MenuItem,
} from '@mui/material';
import PopupState, {
  bindTrigger,
  bindMenu,
} from 'material-ui-popup-state';
import { MoreVert as MoreVertIcon } from '@mui/icons-material';

import {
  Container,
  Title,
  StyledIconButton,
  StyledCheckCircleOutline,
  StyledRadioButtonUnchecked,
} from './styles';
import texts from './texts.json';

const EventContent = ({
  workoutAssignment,
  onWorkoutAssignmentClick,
  editWorkoutAssignment,
  deleteWorkoutAssignment,
  copyWorkoutAssignment,
  onMarkAsDone,
}) => {
  const {
    startDate,
    isCompleted,
    workoutStartedDate,
  } = workoutAssignment;

  const isPastWorkout = moment(startDate.toDate()).isSameOrBefore(moment(), 'day');
  const isPendingCompletion = !!workoutStartedDate && !isCompleted;

  return (
    <Container>
      {isPastWorkout && (
        isCompleted ? <StyledCheckCircleOutline /> : <StyledRadioButtonUnchecked />
      )}
      <Tooltip title={workoutAssignment.name} arrow>
        <Title onClick={() => onWorkoutAssignmentClick(workoutAssignment)} className="drag">
          {workoutAssignment.name}
        </Title>
      </Tooltip>
      <PopupState variant="popover">
        {(popupState) => (
          <>
            <StyledIconButton {...bindTrigger(popupState)}>
              <MoreVertIcon />
            </StyledIconButton>
            <Menu {...bindMenu(popupState)}>
              <MenuItem onClick={() => editWorkoutAssignment(workoutAssignment, popupState.close)}>
                {texts.edit}
              </MenuItem>
              <MenuItem onClick={() => deleteWorkoutAssignment(workoutAssignment, popupState.close)}>
                {texts.delete}
              </MenuItem>
              <MenuItem
                onClick={() => copyWorkoutAssignment(
                  {
                    id: workoutAssignment.workout,
                    name: workoutAssignment.workoutContent.name,
                    data: workoutAssignment.workoutContent,
                  },
                  popupState.close,
                )}
                divider={isPendingCompletion}
              >
                {texts.copy}
              </MenuItem>
              {isPendingCompletion && (
                <MenuItem onClick={() => onMarkAsDone(workoutAssignment, popupState.close)}>
                  {texts.complete}
                </MenuItem>
              )}
            </Menu>
          </>
        )}
      </PopupState>
    </Container>
  );
};

EventContent.propTypes = {
  workoutAssignment: PropTypes.object.isRequired,
  onWorkoutAssignmentClick: PropTypes.func.isRequired,
  editWorkoutAssignment: PropTypes.func.isRequired,
  deleteWorkoutAssignment: PropTypes.func.isRequired,
  copyWorkoutAssignment: PropTypes.func.isRequired,
  onMarkAsDone: PropTypes.func.isRequired,
};

export default EventContent;
