import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as NoteIcon } from '../../../../../../../assets/icons/v2/notes.svg';
import { Container, IconContainer } from './styles';

const Note = ({ text }) => (
  <Container>
    <IconContainer>
      <NoteIcon />
    </IconContainer>
    {text}
  </Container>
);

Note.propTypes = {
  text: PropTypes.string.isRequired,
};

export default Note;
