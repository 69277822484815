import format from 'string-template';

import { storagePaths, pathPlaceholder } from '../../../../utils/firebasePaths';
import { StorageProcessState } from '../../../../hooks/useStorage';

const uploadImage = async (
  iconUrl,
  upload,
  image,
  coachId,
) => {
  const storagePath = format(storagePaths.APP_CUSTOM_ICONS, {
    [pathPlaceholder.COACH_ID]: coachId,
  });

  const extensionIndex = image.name.lastIndexOf('.');
  const imageFormat = image.name.slice(extensionIndex + 1);
  const fileName = image.name.slice(0, extensionIndex);
  const fileType = image.type;

  // Upload the image to firebase storage
  const { state, fileRef } = await upload(iconUrl, imageFormat, storagePath, fileName, fileType);

  if (state === StorageProcessState.FAILED) {
    throw new Error('There was an error in uploading the icon to storage');
  }

  return fileRef;
};

export {
  uploadImage,
};
