import config from '../../config';

/**
 * Returns the onboarding link for the given onboarding parameters
 * @param {object} onboardingParameters The onboarding parameters
 * @param {string} onboardingParameters.coachId id of the coach
 * @param {string} onboardingParameters.plan The coach plan
 * @param {string} [onboardingParameters.email] The user's email to onboard
 * @param {string} [onboardingParameters.anonid] Segment anonymous id
 * @param {string} [onboardingParameters.startAt] billing start date
 * @returns {string} onboarding link
 */
const getOnboardingLink = ({
  coachId,
  plan,
  email,
  anonid,
  startAt,
}) => {
  const newURL = new URL(`${config.webAppURL}/onboarding`);
  newURL.searchParams.set('externalCoach', coachId);
  newURL.searchParams.set('plan', plan);

  if (email) {
    newURL.searchParams.set('email', email);
  }

  if (anonid) {
    newURL.searchParams.set('anonid', anonid);
  }

  if (startAt) {
    newURL.searchParams.set('startAt', startAt);
  }

  return newURL.toString();
};

/**
 * Get an onboarding link that uses the leadId for identifying the correct contract.
 * @param {string} leadId
 * @returns {string}
 */
const getOnboardingLinkFromLead = (leadId) => {
  const onboardingLink = new URL(`${config.webAppURL}/onboarding`);

  // Add the lead id as a query param
  onboardingLink.searchParams.set('leadId', leadId);

  return onboardingLink.toString();
};

/**
 * Get an onboarding link that uses a contract ID. This allows for reusable contracts that can be applied on challenges,
 * direct urchase or promo campains. Since we are using a contract, we can control the fees that are associated.
 * @param {string} contractId
 * @returns {string}
 */
const getOnboardingLinkFromContract = (contractId) => {
  const onboardingLink = new URL(`${config.webAppURL}/onboarding`);

  // Add the lead id as a query param
  onboardingLink.searchParams.set('contractId', contractId);

  return onboardingLink.toString();
};

export {
  getOnboardingLink,
  getOnboardingLinkFromLead,
  getOnboardingLinkFromContract,
};
