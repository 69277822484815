import React, {
  memo,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as WarningIcon } from '../../../../../../../../assets/icons/v2/exclamation-red.svg';
import useRunOnInitialRender from '../../../../../../../../hooks/useRunOnInitialRender';
import {
  carbsToCalories,
  fatToCalories,
  proteinToCalories,
} from '../../../../../../../utils/meals';
import {
  StyledTextField,
  StyledTextFieldContainer,
  StyledTextFieldLabel,
} from '../../../../../../../../components/Inputs';
import {
  AssignContainer,
  AssignTitle,
  MacrosContainer,
  WarningContainer,
} from '../../styles';
import texts from './texts.json';

const AssignByMacros = memo(({
  updateNutritionGoals,
  clientNutritionGoals,
  currentMacroAverages,
}) => {
  // Update the macros with gram values from current goals on initial render
  useRunOnInitialRender(() => {
    const {
      macros: {
        protein = 0,
        carbs = 0,
        fat = 0,
      },
    } = currentMacroAverages || {};

    updateNutritionGoals('protein', protein);
    updateNutritionGoals('carbs', carbs);
    updateNutritionGoals('fat', fat);
  });

  useEffect(() => {
    const proteinCalories = proteinToCalories(clientNutritionGoals.protein);
    const carbsCalories = carbsToCalories(clientNutritionGoals.carbs);
    const fatCalories = fatToCalories(clientNutritionGoals.fat);
    const goalsUpdatedCalories = proteinCalories + carbsCalories + fatCalories;
    updateNutritionGoals('calories', goalsUpdatedCalories);
  }, [
    clientNutritionGoals.protein,
    clientNutritionGoals.carbs,
    clientNutritionGoals.fat,
    updateNutritionGoals,
  ]);

  return (
    <AssignContainer>
      <WarningContainer>
        <WarningIcon />
        {texts.macrosManualSetWarning}
      </WarningContainer>
      <AssignTitle>{texts.title}</AssignTitle>
      <MacrosContainer>
        <StyledTextFieldContainer>
          <StyledTextFieldLabel>{texts.protein}</StyledTextFieldLabel>
          <StyledTextField
            value={clientNutritionGoals?.protein}
            onChange={(evt) => updateNutritionGoals('protein', evt.target.value)}
            type="number"
            title="g"
            $simple
          />
        </StyledTextFieldContainer>
        <StyledTextFieldContainer>
          <StyledTextFieldLabel>{texts.carb}</StyledTextFieldLabel>
          <StyledTextField
            value={clientNutritionGoals?.carbs}
            onChange={(evt) => updateNutritionGoals('carbs', evt.target.value)}
            type="number"
            title="g"
            $simple
          />
        </StyledTextFieldContainer>
        <StyledTextFieldContainer>
          <StyledTextFieldLabel>{texts.fat}</StyledTextFieldLabel>
          <StyledTextField
            value={clientNutritionGoals?.fat}
            onChange={(evt) => updateNutritionGoals('fat', evt.target.value)}
            type="number"
            title="g"
            $simple
          />
        </StyledTextFieldContainer>
      </MacrosContainer>
      <AssignTitle $withTopMargin>{texts.subTitle}</AssignTitle>
      <StyledTextField
        type="number"
        value={clientNutritionGoals.calories}
        disabled
        title={texts.kcal}
        $simple
      />
    </AssignContainer>
  );
});

AssignByMacros.propTypes = {
  updateNutritionGoals: PropTypes.func.isRequired,
  clientNutritionGoals: PropTypes.object.isRequired,
  currentMacroAverages: PropTypes.object.isRequired,
};

export default AssignByMacros;
