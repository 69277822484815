import React from 'react';

import fieldName, { endScreenContentFieldName } from '../../formFields';
import {
  Container,
  HeaderContainer,
  Title,
  StyledFormInput,
} from './styles';
import texts from './texts';

const EndScreen = () => (
  <Container>
    <HeaderContainer>
      <Title>{texts.endScreen}</Title>
    </HeaderContainer>
    <StyledFormInput
      name={`${fieldName.END_SCREEN_CONTENT}.${endScreenContentFieldName.LINE_1}`}
      label={texts.labels[endScreenContentFieldName.LINE_1]}
    />
    <StyledFormInput
      name={`${fieldName.END_SCREEN_CONTENT}.${endScreenContentFieldName.LINE_2}`}
      label={texts.labels[endScreenContentFieldName.LINE_2]}
    />
  </Container>
);

export default EndScreen;
