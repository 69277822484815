import moment from 'moment';
import format from 'string-template';
import { DateFormat } from '../../../../../../utils/date';

import texts from './texts.json';

const getColumns = ({
  equipmentRenderCell,
  actionsRenderCell,
}) => (
  [
    {
      field: 'name',
      quickSearch: true,
      headerName: texts.columns.programName,
      flex: 1,
    },
    {
      field: 'createdAt',
      headerName: texts.columns.createdOn,
      flex: 1,
      type: 'datetime',
      valueGetter: ({ row }) => new Date(row.createdAt),
      renderCell: ({ row }) => (
        moment(row.createdAt).format(DateFormat.DATE_FORMAT_COMMA)
      ),
    },
    {
      field: 'difficulty',
      headerName: texts.columns.difficulty,
      flex: 1,
      valueGetter: ({ row }) => (
        row.difficulty || texts.emptyCell
      ),
    },
    {
      field: 'programDays',
      headerName: texts.columns.programDays,
      flex: 1,
      valueGetter: ({ row }) => (
        format(texts.daysCount, { count: row.workouts.length })
      ),
    },
    {
      field: 'equipment',
      headerName: texts.columns.equipment,
      sortable: false,
      flex: 1,
      renderCell: equipmentRenderCell,
    },
    {
      field: 'actions',
      headerName: texts.columns.actions,
      sortable: false,
      filterable: false,
      width: 300,
      renderCell: actionsRenderCell,
    },
  ]
);

export default getColumns;
