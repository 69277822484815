import BaseDocument from './BaseDocument';

class Package extends BaseDocument {
  get coachId() {
    return this.data.coachId;
  }

  get productId() {
    return this.data.productId;
  }

  get name() {
    return this.data.name;
  }

  get currency() {
    return this.data.currency;
  }

  get initialPaymentInCents() {
    return this.data.initialPaymentInCents;
  }

  get initialTerm() {
    return this.data.initialTerm;
  }

  get priceId() {
    return this.data.priceId;
  }

  get monthlyPaymentInCents() {
    return this.data.monthlyPaymentInCents;
  }

  get monthlyPriceId() {
    return this.data.monthlyPriceId;
  }

  get minSubscriptionMonths() {
    return this.data.minSubscriptionMonths;
  }

  get isArchived() {
    return !!this.data.isArchived;
  }

  archive() {
    return this.updateFields({
      isArchived: true,
      archivedAt: new Date(),
    });
  }

  unarchive() {
    return this.updateFields({
      isArchived: false,
    });
  }
}

export default Package;
