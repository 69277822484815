import styled from 'styled-components';

import { LoadingButton } from '../../../../../components/Button';

const SubscribeButton = styled(LoadingButton)`
  height: 60px;
  width: 100%;
  cursor: pointer;
`;

export default styled(SubscribeButton)``;
