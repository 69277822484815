import React, {
  useState,
  useEffect,
  useMemo,
  createRef,
} from 'react';
import { Button } from '@mui/material';

import MetabaseDashboardView from '../../../../components/MetabaseDashboardView';
import MetabaseDashboard from '../../../../Model/MetabaseDashboard';
import useComponentMounted from '../../../../hooks/useComponentMounted';
import useSessionStore from '../../../../hooks/useSessionStore';
import {
  AutoCompleteWrapper,
  Container,
  FilterRow,
  PageContent,
  StyledAlert,
  StyledAutoComplete,
  StyledTextField,
} from './styles';
import texts from './texts.json';

const MetabaseDashboardsView = () => {
  const [selectedDashboard, setSelectedDashboard] = useState(null);
  const [dashboards, setDashboards] = useState([]);
  const { isSupport } = useSessionStore();

  const isComponentMountedRef = useComponentMounted();
  const fullscreenButtonRef = createRef();

  useEffect(() => {
    const loadDashboards = async () => {
      const dashboardCollection = await MetabaseDashboard.getAllDashboards(isSupport);
      if (isComponentMountedRef.current) {
        setDashboards(dashboardCollection.docs);
      }
    };
    loadDashboards();
  }, [isComponentMountedRef, isSupport]);

  const dashboardOptions = useMemo(() => (
    dashboards.map((dashboard) => (
      {
        id: dashboard.dashboardId,
        label: dashboard.name,
      }
    ))
  ), [
    dashboards,
  ]);

  return (
    <Container>
      <PageContent>
        <FilterRow>
          <AutoCompleteWrapper>
            <StyledAutoComplete
              disablePortal
              options={dashboardOptions}
              renderInput={(params) => <StyledTextField {...params} label={texts.dashboard} />}
              renderOption={(props, option) => <div {...props} key={option.id}>{option.label}</div>}
              onChange={(event, selectedOption) => setSelectedDashboard(selectedOption?.id)}
            />
          </AutoCompleteWrapper>
          {!!selectedDashboard && <Button ref={fullscreenButtonRef}>View Fullscreen</Button>}
        </FilterRow>
        {!selectedDashboard && (
          <StyledAlert severity="warning">
            {texts.errors.noDashboardSelected}
          </StyledAlert>
        )}
        {!!selectedDashboard && (
          <MetabaseDashboardView
            dashboardId={selectedDashboard}
            fullscreenButtonRef={fullscreenButtonRef}
          />
        )}
      </PageContent>
    </Container>
  );
};

export default MetabaseDashboardsView;
