import { strict as assert } from 'assert';
import { fetch } from 'whatwg-fetch';
import config from '../config';

const isFirebaseDev = () => process.env.REACT_APP_FIREBASE_DEV === 'true';

/**
 * Gets the URL to use for a given firebase function based on the environment
 * it is actually running.
 *
 * If isFirebaseDev() then this returns the base for the local firebase
 * development server (emulator).
 */
export const getFirebaseFunctionURL = (path) => {
  const { projectId } = config.firebase;

  return isFirebaseDev()
    ? `http://localhost:5001/${projectId}/us-central1/${path}`
    : `https://us-central1-${projectId}.cloudfunctions.net/${path}`;
};

/**
 * Calls a path in Firebase functions
 */
const callRemote = async (idToken, path, body = null, request = {}) => {
  assert(path);
  if (!request.method) {
    request.method = 'POST';
  }
  if (body !== null) {
    request.body = JSON.stringify(body);
  }
  if (!request.headers) {
    request.headers = {};
  }
  request.headers['Content-Type'] = 'application/json';
  request.headers.Authorization = `Bearer ${idToken}`;

  const url = getFirebaseFunctionURL(path);
  return fetch(url, request);
};

export default callRemote;
