import styled from 'styled-components';
import {
  Autocomplete,
  TextField,
} from '@mui/material';

import colors from '../../../styles/colors';

const MonthSelectWrapper = styled.div`
  width: 300px;
`;

const StyledAutoComplete = styled(Autocomplete)`
  .MuiAutocomplete-inputRoot {
    height: 56px;
  }
`;

const StyledTextField = styled(TextField)`
  background: ${colors.base.alpha};
`;

export {
  MonthSelectWrapper,
  StyledAutoComplete,
  StyledTextField,
};
