import React, {
  useContext,
  useState,
  useEffect,
} from 'react';
import { Redirect } from 'react-router-dom';

import UserContext from '../../context/UserContext';
import FirebaseContext from '../../context/FirebaseContext';

import LoadingPage from '../LoadingPage';

/**
 * HOC that makes sure that the user is onboarded, if not, the user is
 * signed out and redirected to the home page
 */
const withUserOnboarded = (Component) => (props) => {
  const [isReady, setIsReady] = useState(false);
  const {
    userDoc,
  } = useContext(UserContext);
  const { firebase } = useContext(FirebaseContext);

  // Sign out not onboarded users
  useEffect(() => {
    const init = async () => {
      if (!userDoc.isOnboarded) {
        await firebase.signOut();
      }
      setIsReady(true);
    };
    if (!isReady) {
      init();
    }
  }, [
    isReady,
    userDoc,
    firebase,
  ]);

  if (!isReady) {
    return <LoadingPage />;
  }

  // Send not onboarded users back to the home page
  if (!userDoc.isOnboarded) {
    return <Redirect to={{ pathname: '/home' }} />;
  }

  return <Component {...props} />;
};

export {
  withUserOnboarded,
};
