import React, {
  useContext,
  useState,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import { Container as DraggableContainer } from 'react-smooth-dnd';
import uuid from 'uuid/v4';

import { removeSpaces } from '../../../../../utils/string';
import { CoachingActivity } from '../../../../../utils/log';
import useLogger from '../../../../../hooks/useLogger';
import EmptyView from '../../../../components/EmptyView';
import AppCustomHabit from '../../../../Model/AppCustomHabit';
import CustomizationContext, { withCustomizationContextReady } from '../../../../context/CustomizationContext';
import useToast from '../../../../hooks/useToast';
import HabitItem from './components/HabitItem';

import texts from './texts.json';
import {
  Container,
  Title,
  Description,
  InputContainer,
  StyledHabitInput,
  StyledButton,
  StyledList,
} from './styles';
import { baseHabitList, baseHabitsDefinition } from './utils';

const HabitCustomization = () => {
  const { customHabitDoc, appCustomizationDoc, setCustomHabitDoc } = useContext(CustomizationContext);
  const [newHabit, setNewHabit] = useState();
  const { showToast } = useToast();
  const [habitList, setHabitList] = useState([]);
  const [habitsDefinition, setHabitsDefinition] = useState({});
  const { logCoachingActivity } = useLogger();

  const customDocHabitList = customHabitDoc?.habits;
  const customDocHabitsDefinition = customHabitDoc?.habitsDefinition;

  useEffect(() => {
    if (customDocHabitList && customDocHabitsDefinition) {
      setHabitList(customDocHabitList);
      setHabitsDefinition(customDocHabitsDefinition);
    } else {
      setHabitList(baseHabitList);
      setHabitsDefinition(baseHabitsDefinition);
    }
  }, [
    customHabitDoc,
    customDocHabitList,
    customDocHabitsDefinition,
  ]);

  const defaultHabitList = useMemo(() => (
    habitList.filter((habit) => habitsDefinition && !!habitsDefinition[habit]?.isDefault)
  ), [
    habitList,
    habitsDefinition,
  ]);

  const customHabitList = useMemo(() => (
    habitList.filter((habit) => habitsDefinition && !habitsDefinition[habit]?.isDefault)
  ), [
    habitList,
    habitsDefinition,
  ]);

  const updateHabitDoc = useCallback(async (updatedHabitList, updatedHabitsDefinition) => {
    if (!customHabitDoc) {
      const newCustomHabitDoc = await AppCustomHabit.addDoc({
        habits: updatedHabitList,
        createdAt: new Date(),
      });
      setCustomHabitDoc(newCustomHabitDoc);
      if (updatedHabitsDefinition) {
        newCustomHabitDoc.setHabitsDefinition(updatedHabitsDefinition);
      }
      newCustomHabitDoc.setHabits(updatedHabitList);
      appCustomizationDoc.setBaseHabitDoc(newCustomHabitDoc.id);
    } else {
      if (updatedHabitsDefinition) {
        customHabitDoc.setHabitsDefinition(updatedHabitsDefinition);
      }
      customHabitDoc.setHabits(updatedHabitList);
    }
    logCoachingActivity(CoachingActivity.UPDATED_HABIT_SETTINGS);
  }, [
    appCustomizationDoc,
    customHabitDoc,
    setCustomHabitDoc,
    logCoachingActivity,
  ]);

  const addNewHabit = useCallback(() => {
    if (newHabit) {
      let newHabitList = null;
      let newHabitsDefinition = null;
      let newHabitId = uuid();
      let oldHabitObject = null;
      let isDuplicate = false;

      if (habitsDefinition) {
        Object.keys(habitsDefinition).forEach((habit) => {
          if (removeSpaces(habitsDefinition[habit].text) === removeSpaces(newHabit)) {
            oldHabitObject = habitsDefinition[habit];
            // bring back the deleted habit if there is any by using the old id
            newHabitId = oldHabitObject.id;

            if (habitsDefinition[habit].active) {
              isDuplicate = true;
            }
          }
        });

        if (isDuplicate) {
          showToast(texts.duplicateHabit, { warning: true });
          return;
        }
        newHabitList = [...habitList];
        if (!habitList.includes(newHabitId)) {
          newHabitList.push(newHabitId);
        }
      } else {
        newHabitList = [newHabitId];
      }

      // check new habit in the habit definition. if present change the active status
      if (habitsDefinition && oldHabitObject) {
        newHabitsDefinition = { ...habitsDefinition };
        newHabitsDefinition[oldHabitObject.id].active = true;
      } else {
        newHabitsDefinition = {
          ...habitsDefinition,
          [newHabitId]: {
            text: newHabit,
            id: newHabitId,
            active: true,
            isDefault: false,
          },
        };
      }

      updateHabitDoc(newHabitList, newHabitsDefinition);
      setNewHabit('');
    }
  }, [
    newHabit,
    updateHabitDoc,
    habitList,
    habitsDefinition,
    showToast,
  ]);

  // move position of array element after drag and drop
  const move = (array, from, to) => {
    array.splice(to, 0, array.splice(from, 1)[0]);
    return [...array];
  };

  const onDrop = useCallback(({ removedIndex, addedIndex }, isDefault = true) => {
    // we have list of defined default habits which will always be the starting elements in the
    // complete habit list. any custom habits added will be added after these default habits.
    // so if it is a custom habit we need calculate the index of that particular habit in complete
    // list as the component calculates two different lists for default and custom.
    const updatedRemovedIndex = isDefault ? removedIndex : (removedIndex + defaultHabitList.length);
    const updatedAddedIndex = isDefault ? addedIndex : (addedIndex + defaultHabitList.length);
    if (habitList) {
      const newArray = move([...habitList], updatedRemovedIndex, updatedAddedIndex);
      updateHabitDoc(newArray, habitsDefinition);
    }
  }, [
    updateHabitDoc,
    habitList,
    defaultHabitList,
    habitsDefinition,
  ]);

  const handleDelete = useCallback((removedIndex) => {
    if (habitList && habitsDefinition) {
      const newArray = [...habitList];
      const removedHabit = newArray.splice(removedIndex, 1)[0];

      const newDefinition = { ...habitsDefinition };
      delete newDefinition[removedHabit];
      updateHabitDoc(newArray, newDefinition);
    }
  }, [
    updateHabitDoc,
    habitList,
    habitsDefinition,
  ]);

  const handleEdit = useCallback((editedIndex, editedText) => {
    if (habitList && habitsDefinition) {
      const editedHabitId = habitList[editedIndex];
      const newDefinition = { ...habitsDefinition };
      newDefinition[editedHabitId].text = editedText;
      updateHabitDoc(habitList, newDefinition);
    }
  }, [
    updateHabitDoc,
    habitList,
    habitsDefinition,
  ]);

  const handleHide = useCallback((hiddenIndex) => {
    if (habitList && habitsDefinition) {
      const hiddenHabit = habitList[hiddenIndex];
      const newDefinition = { ...habitsDefinition };
      newDefinition[hiddenHabit].active = !newDefinition[hiddenHabit].active;
      updateHabitDoc(habitList, newDefinition);
    }
  }, [
    updateHabitDoc,
    habitList,
    habitsDefinition,
  ]);

  return (
    <Container>
      <Title>{texts.title}</Title>
      <Description>{texts.description}</Description>
      <InputContainer>
        <StyledHabitInput
          variant="outlined"
          label={texts.newHabit}
          value={newHabit}
          onChange={(evt) => setNewHabit(evt.target.value)}
        />
        <StyledButton
          variant="contained"
          onClick={addNewHabit}
        >
          {texts.addHabit}
        </StyledButton>
      </InputContainer>
      <Description>{texts.defaultHabits}</Description>
      <StyledList>
        <DraggableContainer dragHandleSelector=".drag-handle" lockAxis="y" onDrop={onDrop}>
          {defaultHabitList.map((habitId, index) => (
            <HabitItem
              index={index}
              handleDelete={handleDelete}
              habitText={habitsDefinition && habitsDefinition[habitId].text}
              key={habitId}
              isDefault
              handleEdit={handleEdit}
              handleHide={handleHide}
              isHidden={!(habitsDefinition && habitsDefinition[habitId].active)}
            />
          ))}
        </DraggableContainer>
      </StyledList>
      <Description>{texts.customHabits}</Description>
      <StyledList>
        <DraggableContainer dragHandleSelector=".drag-handle" lockAxis="y" onDrop={(data) => onDrop(data, false)}>
          {customHabitList.map((habitId, index) => (
            <HabitItem
              // we only update the main habit list which includes both default and custom habits
              index={index + defaultHabitList.length}
              handleDelete={handleDelete}
              habitText={habitsDefinition && habitsDefinition[habitId]?.text}
              key={habitId}
              isDefault={false}
              handleEdit={handleEdit}
              handleHide={handleHide}
              isHidden={!(habitsDefinition && habitsDefinition[habitId]?.active)}
            />
          ))}
          {(!customHabitList.length) && <EmptyView content={texts.noCustomHabits} />}
        </DraggableContainer>
      </StyledList>
    </Container>
  );
};

export default compose(
  withCustomizationContextReady,
  observer,
)(HabitCustomization);
