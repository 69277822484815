import React, {
  useEffect,
  useState,
  useCallback,
} from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import useComponentMounted from '../../../../../../hooks/useComponentMounted';
import CoachReminderNotification from '../../../../../../Model/CoachReminderNotification';

import ScheduledReminderItem from './ScheduledReminderItem';
import {
  Container,
  StyledIonSpinner,
  InfoText,
} from './styles';
import texts from './texts.json';

const ScheduledRemindersList = ({
  userId,
}) => {
  const [isReady, setIsReady] = useState(false);
  const [reminderList, setReminderList] = useState([]);

  const isComponentMountedRef = useComponentMounted();

  useEffect(() => {
    const init = async () => {
      const result = await CoachReminderNotification.getScheduledReminders(userId);
      if (isComponentMountedRef.current) {
        setReminderList(result);
        setIsReady(true);
      }
    };
    if (!isReady) {
      init();
    }
  }, [
    isReady,
    userId,
    isComponentMountedRef,
  ]);

  const renderContent = useCallback(() => {
    if (isReady) {
      if (reminderList.length) {
        return (
          reminderList.map((reminderDoc) => (
            <ScheduledReminderItem
              key={reminderDoc.id}
              reminderNotificationDoc={reminderDoc}
            />
          ))
        );
      }
      return (
        <InfoText>{texts.noReminders}</InfoText>
      );
    }
    return (
      <StyledIonSpinner name="crescent" />
    );
  }, [
    isReady,
    reminderList,
  ]);

  return (
    <Container>
      {renderContent()}
    </Container>
  );
};

ScheduledRemindersList.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default observer(ScheduledRemindersList);
