import styled from 'styled-components';
import { Typography } from '@mui/material';

import { ReactComponent as InfoIcon } from '../../../../../assets/icons/v2/exclamation.svg';
import colors from '../../../../../styles/colors';

const RowWrapper = styled.div`
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  padding: 10px;
  margin: 5px 0;
  background-color: ${colors.shades.gamma4};
`;

const FieldsContainer = styled.div`
  width: 100%;
  padding-left: 30px;
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
  justify-content: space-between;
`;

const ButtonsContainer = styled.div`
  display: flex;
`;

const StyledTypography = styled(Typography)`
  font-weight: bold;
`;

const DefaultFieldIndicator = styled.div`
  width: fit-content;
  font-size: 12px;
  border-radius: 10px;
  margin-left: 30px;
  display: flex;
  column-gap: 5px;
  justify-content: center;
  align-items: center;
`;

const StyledInfoIcon = styled(InfoIcon)`
  width: 15px;
  height: 15px;
`;

export {
  RowWrapper,
  StyledTypography,
  FieldsContainer,
  ButtonsContainer,
  DefaultFieldIndicator,
  StyledInfoIcon,
};
