import {
  observable,
  action,
  decorate,
  computed,
} from 'mobx';

class SessionStore {
  authUser = null;

  userClaims = null;

  isSupportUser = false;

  isCoachAssistantUser = false;

  isInsideSalesUser = false;

  isSDRUser = false;

  isOnCallUser = false;

  userChangeListeners = [];

  setAuthUser = (
    authUser,
    claims,
    flags = {
      isSupport: false,
      isCoachAssistant: false,
      isInsideSales: false,
      isSDR: false,
      isOnCall: false,
    },
  ) => {
    this.authUser = authUser;
    this.userClaims = claims;
    this.isSupportUser = !!flags.isSupport;
    this.isCoachAssistantUser = !!flags.isCoachAssistant;
    this.isInsideSalesUser = !!flags.isInsideSales;
    this.isSDRUser = !!flags.isSDR;
    this.isOnCallUser = !!flags.isOnCall;
  };

  /**
   * Informs to the listeners that the current user has been explicitly changed.
   * @param {object} authUser A https://firebase.google.com/docs/reference/js/firebase.User user object.
   */
  changeUser = (authUser) => {
    this.userChangeListeners.forEach((listener) => listener(authUser));
  }

  onAuthUserChanged = (callback) => {
    this.userChangeListeners.push(callback);
  }

  removeListeners = () => {
    this.userChangeListeners = [];
  }

  get userId() {
    return this.authUser && this.authUser.uid ? this.authUser.uid : '';
  }

  get isAdmin() {
    const { admin } = this.userClaims || {};
    return !!admin;
  }

  get isCoach() {
    const { coach } = this.userClaims || {};
    return !!coach;
  }

  get isCoachOrAdmin() {
    return this.isCoach || this.isAdmin;
  }

  get isSupport() {
    return this.isAdmin && this.isSupportUser;
  }

  get isCoachAssistant() {
    return this.isAdmin && this.isCoachAssistantUser;
  }

  get isInsideSales() {
    return this.isAdmin && this.isInsideSalesUser;
  }

  get isSDR() {
    return this.isAdmin && this.isSDRUser;
  }

  get isOnCall() {
    return this.isOnCallUser;
  }
}

decorate(SessionStore, {
  authUser: observable,
  userClaims: observable,
  isSupportUser: observable,
  isSupport: computed,
  isCoachAssistantUser: observable,
  isCoachAssistant: computed,
  isInsideSales: computed,
  isSDRUser: observable,
  isSDR: computed,
  isOnCallUser: observable,
  isOnCall: computed,
  setAuthUser: action,
  setIsSupport: action,
});

const sessionStore = new SessionStore();

export default sessionStore;
