import React from 'react';
import PropTypes from 'prop-types';
import { ToggleButton } from '@mui/material';

import { StyledToggleButtonGroup } from './styles';

const DaysOptions = [7, 30, 60, 90];

const TimePeriodToggles = ({
  selectedPeriod,
  onChange,
}) => (
  <StyledToggleButtonGroup
    value={selectedPeriod}
    exclusive
    onChange={(e, period) => onChange(period)}
  >
    {DaysOptions.map((days) => (
      <ToggleButton value={days} key={days}>
        {`${days}d`}
      </ToggleButton>
    ))}
  </StyledToggleButtonGroup>
);

TimePeriodToggles.propTypes = {
  selectedPeriod: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default TimePeriodToggles;
export {
  DaysOptions,
};
