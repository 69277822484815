import React, { useContext } from 'react';
import { ChatChannelListContext } from '@fitmoola/system2-chat';
import pages, { pagesConfig } from '../../config/pages';

import useToolsNavigation from '../../hooks/useToolsNavigation';

import LoadingBadge from '../../../components/LoadingBadge';
import LeadsContext from '../../context/LeadsContext';
import FeedContext from '../../context/FeedContext';
import MealPlanContext from '../../context/MealPlanContext';
import { MealPlanAssignmentStatus } from '../../utils/mealPlan';

import { StyledBadge } from './styles';

const useSideBarConfig = () => {
  const { navigateTo } = useToolsNavigation();

  const { totalUnreadCount: { total: feedTotalUnreadCount }, isFeedReady } = useContext(FeedContext);
  const { totalUnreadCount: chatTotalUnreadCount, isReady: isChatReady } = useContext(ChatChannelListContext);
  const { mealPlansStatusCount, isReady: isMealPlanReady } = useContext(MealPlanContext);
  const { newLeadsCount } = useContext(LeadsContext);
  // Display number of MP assignments that require action in the status count (NEEDS_REFRESH + PENDING)
  const mealPlanStatusHighlight = mealPlansStatusCount[MealPlanAssignmentStatus.NEEDS_REFRESH]
    + mealPlansStatusCount[MealPlanAssignmentStatus.PENDING];

  const commonBadgeProps = {
    max: 999,
  };

  const sideBarEntries = [{
    pageId: pages.ACTIVITY_FEED,
    icon: (
      <StyledBadge
        {...commonBadgeProps}
        badgeContent={isFeedReady ? feedTotalUnreadCount : <LoadingBadge />}
      >
        {pagesConfig[pages.ACTIVITY_FEED].icon}
      </StyledBadge>
    ),
    onClick: () => navigateTo(pagesConfig[pages.ACTIVITY_FEED].path),
    title: pagesConfig[pages.ACTIVITY_FEED].title,
  }, {
    pageId: pages.CHAT,
    icon: (
      <StyledBadge
        {...commonBadgeProps}
        badgeContent={isChatReady ? chatTotalUnreadCount : <LoadingBadge />}
      >
        {pagesConfig[pages.CHAT].icon}
      </StyledBadge>
    ),
    onClick: () => navigateTo(pagesConfig[pages.CHAT].path),
    title: pagesConfig[pages.CHAT].title,
  }, {
    pageId: pages.MEAL_PLANNER,
    icon: (
      <StyledBadge
        {...commonBadgeProps}
        badgeContent={isMealPlanReady ? mealPlanStatusHighlight : <LoadingBadge />}
      >
        {pagesConfig[pages.MEAL_PLANNER].icon}
      </StyledBadge>
    ),
    onClick: () => navigateTo(pagesConfig[pages.MEAL_PLANNER].path),
    title: pagesConfig[pages.MEAL_PLANNER].title,
  }, {
    pageId: pages.MANAGE_CLIENTS,
    icon: pagesConfig[pages.MANAGE_CLIENTS].icon,
    onClick: () => navigateTo(pagesConfig[pages.MANAGE_CLIENTS].path),
    title: pagesConfig[pages.MANAGE_CLIENTS].title,
  }, {
    pageId: pages.WORKOUTS_MANAGER,
    icon: pagesConfig[pages.WORKOUTS_MANAGER].icon,
    onClick: () => navigateTo(pagesConfig[pages.WORKOUTS_MANAGER].path),
    title: pagesConfig[pages.WORKOUTS_MANAGER].title,
  }, {
    pageId: pages.LEADS,
    icon: (
      <StyledBadge
        {...commonBadgeProps}
        badgeContent={newLeadsCount}
      >
        {pagesConfig[pages.LEADS].icon}
      </StyledBadge>
    ),
    onClick: () => navigateTo(pagesConfig[pages.LEADS].path),
    title: pagesConfig[pages.LEADS].title,
  }, {
    pageId: pages.CONFIG,
    icon: pagesConfig[pages.CONFIG].icon,
    onClick: () => navigateTo(pagesConfig[pages.CONFIG].path),
    title: pagesConfig[pages.CONFIG].title,
  }];

  return {
    sideBarEntries,
  };
};

export default useSideBarConfig;
