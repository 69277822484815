import React, { useContext } from 'react';

import LoadingPage from '../../../components/LoadingPage';

import MealPlanAssignmentContext from './MealPlanAssignmentContext';
import MealPlanAssignmentContextProvider from './MealPlanAssignmentContextProvider';

const withMealPlanAssignmentContextProvider = (Component) => (props) => (
  <MealPlanAssignmentContextProvider>
    <Component {...props} />
  </MealPlanAssignmentContextProvider>
);

const withMealPlanAssignmentContextReady = (Component) => (props) => {
  const { isReady } = useContext(MealPlanAssignmentContext);

  return isReady ? <Component {...props} /> : <LoadingPage />;
};

export {
  withMealPlanAssignmentContextProvider,
  withMealPlanAssignmentContextReady,
};
