/**
 * Converts a data URI to Blob.
 *
 * @param {string} dataURI
 * @return {Object} Blob object.
 */
const dataURItoBlob = (dataURI) => {
  // convert base64 to raw binary data held in a string
  const byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component. Example: dataURI = 'data:image/jpeg;base64,<base64_here>' -> result: image/jpeg
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to an ArrayBuffer
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const typedArray = new Uint8Array(arrayBuffer);

  for (let i = 0; i < byteString.length; i++) {
    typedArray[i] = byteString.charCodeAt(i);
  }

  const dataView = new DataView(arrayBuffer);

  return new Blob([dataView], { type: mimeString });
};

/**
 * Converts a data URI to a Blob URL.
 * NOTE: Always call URL.revokeObjectURL(blobURL); once it is not longer used. Otherwise a memory leak will happen.
 *
 * @param {string} dataURI
 * @return {string} Blob URL.
 */
const dataURItoBlobURL = (dataURI) => URL.createObjectURL(dataURItoBlob(dataURI));

/**
 * Downloads a file from firebase storage and returns a blob URL
 * @param storagePath
 * @param storage firebase storage instance
 * @returns {Promise<string>} Blob URL.
 */
const storageURLtoBlobURL = async (storagePath, storage) => {
  const downloadUrl = await storage
    .ref()
    .child(storagePath)
    .getDownloadURL();
  const fileData = await fetch(downloadUrl);
  const fileBlob = await fileData.blob();
  return URL.createObjectURL(fileBlob);
};

/**
 * Downloads a JSON file from firebase storage and returns the JSON content
 * @param storagePath
 * @param storage firebase storage instance
 * @returns {Promise<Object>} JSON object.
 */
const storageJSONURLtoJSONData = async (storagePath, storage) => {
  const downloadUrl = await storage
    .ref()
    .child(`${storagePath}.json`)
    .getDownloadURL();
  const fileData = await fetch(downloadUrl);
  const jsonData = await fileData.json();
  return jsonData;
};

/**
 * Converts a given File object to an ArrayBuffer.
 *
 * @param {File} file - The file object to be converted.
 * @returns {Promise<ArrayBuffer>} A promise that resolves tothe ArrayBuffer of the provided file.
 * @throws an error if reading the file fails.
 */
const fileToArrayBuffer = async (file) => (
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsArrayBuffer(file);
  })
);

/**
 * Converts an array of objects into a keyed JSON object.
 *
 * @param {Array} arrOfObjects - The array to be converted.
 * @param {string} objKey - The key to be used from each object as the key for the created key value pairs.
 * @returns {Object} A keyed JSON object
 * @example
 * const array = [{keyAttr: key1, valueAttr: value1}, {keyAttr: key2, valueAttr: value2}, ...];
 * const converted = arrOfObjectstoKeyedJSONObj(array, 'keyAttr');
 * converted -> {key1: {keyAttr: key1, valueAttr: value1}, key2: {keyAttr: key2, valueAttr: value2}, ...};
 */

const arrOfObjectstoKeyedJSONObj = (arrOfObjects, objKey) => (
  arrOfObjects.reduce((accumulator, obj) => {
    const key = obj[objKey];
    accumulator[key] = obj;
    return accumulator;
  }, {})
);

export {
  dataURItoBlob,
  dataURItoBlobURL,
  storageURLtoBlobURL,
  storageJSONURLtoJSONData,
  fileToArrayBuffer,
  arrOfObjectstoKeyedJSONObj,
};
