import { writeToString } from '@fast-csv/format';
import FileSaver from 'file-saver';

const downloadCsv = async (csvHeaders, rows, fileName) => {
  const csvRows = rows.map((row) => (
    csvHeaders.map((header) => row[header])
  ));

  const csvString = await writeToString(csvRows, { headers: csvHeaders });
  const csvFileType = 'text/csv;charset=utf-8;';
  const blob = new Blob([csvString], { type: csvFileType });

  const outputFileName = `${fileName}.csv`;

  FileSaver.saveAs(blob, outputFileName);
};

export {
  downloadCsv,
};
