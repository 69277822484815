import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'recompose';

import {
  withExternalCoachContextProvider,
} from '../../context/ExternalCoachContext';
import ToolsLayout from '../ToolsLayout';
import AdminSidebar from './AdminSidebar';

const AdminToolsLayout = ({
  children,
}) => (
  <ToolsLayout sidebarComponent={<AdminSidebar />}>
    {children}
  </ToolsLayout>
);

AdminToolsLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default compose(
  withExternalCoachContextProvider,
)(AdminToolsLayout);
