import React from 'react';

import ConfigurationMenuContextProvider from './ConfigurationMenuContextProvider';

const withConfigurationMenuContextProvider = (Component) => (props) => (
  <ConfigurationMenuContextProvider>
    <Component {...props} />
  </ConfigurationMenuContextProvider>
);

export default withConfigurationMenuContextProvider;
