import React, {
  useState,
  useMemo,
  useCallback,
  useContext,
} from 'react';
import {
  MessageInputContextProvider,
  useChannelStateContext,
  useChatContext,
  useMessageInputContext,
} from 'stream-chat-react';
import { useEmojiTrigger } from 'stream-chat-react/dist/components/MessageInput/hooks/useEmojiTrigger';
import { useUserTrigger } from 'stream-chat-react/dist/components/MessageInput/hooks/useUserTrigger';

import PropTypes from 'prop-types';

import FirebaseContext from '../../../../../context/FirebaseContext';
import CustomSuggestionItem from '../CustomSuggestionItem';
import useSessionStore from '../../../../../hooks/useSessionStore';

const CustomTriggerProvider = ({ children }) => {
  const currentContextValue = useMessageInputContext();
  const { client } = useChatContext();
  const { channel } = useChannelStateContext();
  const [suggestionList, setSuggestionList] = useState([]);
  const emojiTrigger = useEmojiTrigger(currentContextValue.emojiIndex);
  const { firebase: { remote } } = useContext(FirebaseContext);
  const [isLoading, setIsLoading] = useState(false);
  const { isCoachAssistant } = useSessionStore();
  const userTrigger = useUserTrigger({
    disableMentions: currentContextValue.disableMentions,
    mentionAllAppUsers: currentContextValue.mentionAllAppUsers,
    mentionQueryParams: currentContextValue.mentionQueryParams,
    onSelectUser: currentContextValue.onSelectUser,
  });

  const customTrigger = useMemo(() => ({
    component: CustomSuggestionItem,
    dataProvider: (query, _, onReady) => {
      const filteredSuggestions = suggestionList
        .filter((suggestion) => suggestion.message.toLowerCase().includes(query.toLowerCase()))
        .map((suggestion) => ({ name: suggestion.message, id: suggestion.id }));
      onReady(filteredSuggestions, query);
    },
    output: (entity) => ({
      caretPosition: 'next',
      key: entity.id,
      text: entity.name,
    }),
  }), [
    suggestionList,
  ]);

  const customTriggers = useMemo(() => ({
    '#': customTrigger,
    ':': emojiTrigger,
    '@': userTrigger,
  }), [
    customTrigger,
    emojiTrigger,
    userTrigger,
  ]);

  const retrieveAISuggestions = useCallback(async (channelMessages) => {
    const systemMessage = [
      {
        role: 'system',
        content: 'You are a fitness related coach who gives one-on-one coaching to clients.',
      },
    ];
    const messages = channelMessages
      .slice(-10)
      .reduce((prev, curr) => {
        if (curr.user.id === client.user.id) {
          prev.push({
            role: 'assistant',
            content: curr.text,
          });
        } else {
          prev.push({
            role: 'user',
            content: curr.text,
          });
        }
        return prev;
      }, systemMessage);
    const apiResponse = await remote('aiChatAssistant', {
      messages,
    });
    const responseToJson = await apiResponse.json();
    const { choices } = responseToJson;
    return choices;
  }, [
    remote,
    client.user.id,
  ]);

  const loadSuggestions = useCallback(async () => {
    let suggestions = [];

    setSuggestionList([]);
    setIsLoading(true);
    const conversationMessages = channel.state.messages;
    if (conversationMessages.length > 0 && isCoachAssistant) {
      const responseSuggestionList = await retrieveAISuggestions(conversationMessages);
      suggestions = responseSuggestionList.map((responseSuggestion) => ({
        id: String(responseSuggestion.index),
        message: responseSuggestion.message.content.replace('\n\n', ''),
      }));
    }

    setSuggestionList(suggestions);
    setIsLoading(false);
  }, [
    retrieveAISuggestions,
    channel.state.messages,
    isCoachAssistant,
  ]);

  const updatedContextValue = useMemo(() => ({
    ...currentContextValue,
    suggestionList,
    autocompleteTriggers: customTriggers,
    isLoading,
    loadSuggestions,
    grow: true,
    maxRows: 15,
  }), [
    currentContextValue,
    customTriggers,
    suggestionList,
    isLoading,
    loadSuggestions,
  ]);

  return (
    <MessageInputContextProvider value={updatedContextValue}>
      {children}
    </MessageInputContextProvider>
  );
};

CustomTriggerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CustomTriggerProvider;
