import React, { useCallback } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import { DateFormat } from '../../../../utils/date';
import {
  RepeatSettingContainer,
  StyledInputLabel,
  StyledInput,
  StyledHelperText,
  StyledDurationText,
  Container,
  SettingSection,
} from './styles';
import texts from './texts.json';

const AssignmentRepeatInput = ({
  helperText,
  value,
  onChange,
  startDate,
  endDate,
}) => {
  const changeInput = useCallback((event) => {
    const inputValue = parseInt(event.target.value, 10);
    if (inputValue > 0) {
      onChange(inputValue);
    }
  }, [onChange]);

  return (
    <Container>
      <SettingSection>
        <StyledInputLabel>{texts.repeat}</StyledInputLabel>
        <RepeatSettingContainer>
          <StyledInput
            value={value}
            onChange={changeInput}
            type="number"
            required
          />
          <StyledHelperText>{helperText}</StyledHelperText>
        </RepeatSettingContainer>
      </SettingSection>
      <SettingSection>
        <StyledInputLabel>{texts.duration}</StyledInputLabel>
        <RepeatSettingContainer>
          <StyledDurationText>
            {`${moment(startDate).format(DateFormat.SHORT_MONTH_DAY_COMMA_YEAR_FORMAT)} - ${endDate}`}
          </StyledDurationText>
        </RepeatSettingContainer>
      </SettingSection>
    </Container>
  );
};

AssignmentRepeatInput.propTypes = {
  helperText: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
};

export default AssignmentRepeatInput;
