const fieldName = {
  NAME: 'name',
  DESCRIPTION: 'description',
  TAGS: 'tags',
  PREPARATION_TIME: 'preparationTime',
  COOKING_TIME: 'cookingTime',
  SERVINGS: 'servings',
  INGREDIENTS: 'ingredients',
  INSTRUCTIONS: 'instructions',
  SCALE: 'isScalingDisabled',
  PROTEIN: 'protein',
  FAT: 'fat',
  CARBS: 'carbs',
  TOTAL_CALORIES: 'totalCalories',
  PROTEIN_PERCENTAGE: 'proteinPercentage',
  FAT_PERCENTAGE: 'fatPercentage',
  CARBS_PERCENTAGE: 'carbsPercentage',
  ALLERGEN_TAGS: 'allergenTags',
  CREATED_BY: 'createdBy',
  CREATED_AT: 'createdAt',
  UPDATED_BY: 'updatedBy',
  UPDATED_AT: 'updatedAt',
};

const ingredientField = {
  NAME: 'name',
  AMOUNT: 'amount',
  UNIT: 'unit',
  SECOND_AMOUNT: 'secondAmount',
  SECOND_UNIT: 'secondUnit',
  PREPARATION_COMMENT: 'preparationComment',
  ALLERGEN_TAGS: 'allergenTags',
};

export {
  fieldName,
  ingredientField,
};
