import styled from 'styled-components';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import colors from '../../styles/colors';

const StyledDialog = styled(Dialog)`
  .MuiDialog-paperScrollPaper {
    overflow: initial;
  }
`;

const StyledDialogTitle = styled(DialogTitle)`
  position: relative;
  padding: 0;
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  top: ${({ $fullScreen }) => ($fullScreen ? '30px' : 0)};
  right: ${({ $fullScreen }) => ($fullScreen ? '30px' : 0)};
  background: ${colors.shades.beta2};
  transform: translate(50%, -50%);
  z-index: 100;

  &:hover{
    background: ${colors.shades.beta1};
  }
`;

const StyledCloseIcon = styled(CloseIcon)`
  color: ${colors.base.alpha};
`;

const StyledDialogContent = styled(DialogContent)`
  padding: 0;
`;

export {
  StyledDialog,
  StyledDialogTitle,
  CloseButton,
  StyledCloseIcon,
  StyledDialogContent,
};
