import styled, { css } from 'styled-components';
import { IonSpinner } from '@ionic/react';

import colors from '../../../../../../../styles/colors';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  min-height: 50vh;
  padding: 0 4px;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  column-gap: 16px;
  padding: 15px 13px;
  border-radius: 5px;
  border: 1px solid ${colors.shades.secondary4};
  margin-bottom: 12px;
`;

const baseTextCss = css`
  font-size: 14px;
  text-align: left;
`;

const MessageText = styled.div`
  ${baseTextCss};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;

const MessageDate = styled.div`
  ${baseTextCss};
  flex-shrink: 0;
`;

const ActionsContainer = styled.div`
  display: flex;
`;

const InfoContainer = styled.div`
  display: contents;
`;

const EditContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  width: 100%;
`;

const InfoText = styled.div`
  ${baseTextCss};
  width: 100%;
`;

const StyledIonSpinner = styled(IonSpinner)`
  align-self: center;
  justify-self: center;
`;

export {
  Container,
  ItemContainer,
  MessageText,
  MessageDate,
  ActionsContainer,
  InfoContainer,
  EditContainer,
  InfoText,
  StyledIonSpinner,
};
