import React from 'react';
import PropTypes from 'prop-types';

import AnalyticNumberCard from '../../AnalyticNumberCard';
import CoachSubscriptionData from '../../../../../Model/analytics/CoachSubscriptionData';
import CoachSubscriptionsAnalytics from '../../../../../Model/analytics/CoachSubscriptionAnalytics';
import texts from './texts.json';

const SubscriptionAnalyticsCard = ({
  subscriptionAnalytics,
}) => (
  <AnalyticNumberCard
    title={subscriptionAnalytics?.newSubscriptions}
    subTitle={texts.newSubscriptions}
    bottomStats={subscriptionAnalytics?.revenue ? [
      {
        title: subscriptionAnalytics?.revenue,
        subTitle: texts.revenue,
      },
      {
        title: subscriptionAnalytics?.avgNewSubPayment,
        subTitle: texts.avgPay,
      },
    ] : []}
  />
);

SubscriptionAnalyticsCard.propTypes = {
  subscriptionAnalytics: PropTypes.oneOfType([
    PropTypes.instanceOf(CoachSubscriptionData),
    PropTypes.instanceOf(CoachSubscriptionsAnalytics),
  ]).isRequired,
};

export default SubscriptionAnalyticsCard;
