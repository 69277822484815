const MealPlannerType = {
  CLIENT_MEAL_ASSIGNMENT: 'CLIENT_MEAL_ASSIGNMENT',
  CLIENT_MEAL_ASSIGNMENTS: 'CLIENT_MEAL_ASSIGNMENTS',
  MEAL_PLAN_REFRESH: 'MEAL_PLAN_REFRESH',
  MEAL_PLAN_ASSIGNMENT: 'MEAL_PLAN_ASSIGNMENT',
  MANAGE_MEAL_PLANS: 'MANAGE_MEAL_PLANS',
  CREATE_MEAL_PLANS_TEMPLATE: 'CREATE_MEAL_PLANS_TEMPLATE',
  RECIPES: 'RECIPES',
  SETTINGS: 'SETTINGS',
};

const mealPlannerPagesConfig = {
  [MealPlannerType.CLIENT_MEAL_ASSIGNMENT]: {
    id: MealPlannerType.CLIENT_MEAL_ASSIGNMENT,
    path: 'assignment',
  },
  [MealPlannerType.CLIENT_MEAL_ASSIGNMENTS]: {
    id: MealPlannerType.CLIENT_MEAL_ASSIGNMENTS,
    path: 'assignments',
  },
  [MealPlannerType.MANAGE_MEAL_PLANS]: {
    id: MealPlannerType.MANAGE_MEAL_PLANS,
    path: 'manageMealPlans',
  },
  [MealPlannerType.CREATE_MEAL_PLANS_TEMPLATE]: {
    id: MealPlannerType.CREATE_MEAL_PLANS_TEMPLATE,
    path: 'createMealPlansTemplate',
  },
  [MealPlannerType.RECIPES]: {
    id: MealPlannerType.RECIPES,
    path: 'recipes',
  },
  [MealPlannerType.SETTINGS]: {
    id: MealPlannerType.SETTINGS,
    path: 'settings',
  },
  [MealPlannerType.MEAL_PLAN_REFRESH]: {
    id: MealPlannerType.MEAL_PLAN_REFRESH,
    path: 'mealPlanRefresh',
  },
  [MealPlannerType.MEAL_PLAN_ASSIGNMENT]: {
    id: MealPlannerType.MEAL_PLAN_ASSIGNMENT,
    path: 'mealPlanAssignment',
  },
};

const getTabPath = (tab) => mealPlannerPagesConfig[tab]?.path || '';

export {
  MealPlannerType,
  mealPlannerPagesConfig,
  getTabPath,
};
