import React, {
  useContext,
  useState,
  useEffect,
} from 'react';
import { observer } from 'mobx-react';
import { compose } from 'recompose';

import useComponentMounted from '../../../../../hooks/useComponentMounted';
import UserContext from '../../../../../context/UserContext';
import CoachConfig from '../../../../Model/CoachConfig';
import ExternalCoachContext, { withExternalCoachContextReady } from '../../../../context/ExternalCoachContext';
import ActivityFeedSettings from './components/ActivityFeedSettings';
import ChatSettings from './components/ChatSettings';
import InboundMsgSettings from './components/InboundMsgSettings';
import LeadSubmissionSettings from './components/LeadSubmissionSettings';
import {
  Container,
} from './styles';

const OtherSettings = () => {
  const {
    coachDoc: {
      isEnrolledInISProgram,
    } = {},
  } = useContext(ExternalCoachContext);
  const { userId: coachId } = useContext(UserContext);

  const isComponentMountedRef = useComponentMounted();

  const [coachConfig, setCoachConfig] = useState(null);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    const init = async () => {
      const coachConfigDoc = await CoachConfig.getCoachConfigByCoachId(coachId);
      if (isComponentMountedRef.current) {
        setCoachConfig(coachConfigDoc);
        setIsReady(true);
      }
    };
    if (coachId) {
      init();
    }
  }, [
    coachId,
    isComponentMountedRef,
  ]);

  return (
    <Container>
      <ActivityFeedSettings />
      <ChatSettings />
      {(isReady && !isEnrolledInISProgram) && <InboundMsgSettings coachConfig={coachConfig} />}
      {isReady && <LeadSubmissionSettings coachConfig={coachConfig} />}
    </Container>
  );
};

export default compose(
  withExternalCoachContextReady,
  observer,
)(OtherSettings);
