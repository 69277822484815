import User, { UserRoleFlag } from '../../Model/User';
import UserClaims from '../../Model/UserClaims';

/**
 * Remove user from the given role.
 *
 * @param {string} userId - Id of the user to remove from the role
 * @param {string} role - The role to remove
 * @return {Promise<void>}
 */
const removeUserFromRole = async (userId, role) => {
  const userDoc = await User.getById(userId);
  await userDoc.updateFields({
    [UserRoleFlag[role]]: false,
  });
  const hasOtherRoles = Object.entries(UserRoleFlag).some(([, flag]) => flag !== UserRoleFlag[role] && userDoc[flag]);

  if (!hasOtherRoles) {
    const userClaimDoc = await UserClaims.getUserClaims(userId);
    await userClaimDoc.updateFields({
      admin: false,
    });
  }
};

export {
  removeUserFromRole,
};
