import { TimeUnit } from '../../../../../../utils/time';

const texts = {
  title: {
    add: 'Add Rest',
    edit: 'Edit Rest',
  },
  labels: {
    rest: 'Rest:',
  },
  timeUnits: {
    [TimeUnit.SECONDS]: 'Seconds',
    [TimeUnit.MINUTES]: 'Minutes',
  },
  actions: {
    add: 'Add Rest',
    update: 'Update Rest',
  },
};

export default texts;
