import React from 'react';

import QAPContextProvider from './QAPContextProvider';

const withQAPContextProvider = (Component) => (props) => (
  <QAPContextProvider>
    <Component {...props} />
  </QAPContextProvider>
);

export {
  withQAPContextProvider,
};
