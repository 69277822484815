import { fieldName } from './formFields';

const texts = {
  verifyNutritionFactsTitle: 'Verify nutritional facts',
  verifyNutritionFactsDescription: 'Get a quick fact check on your recipe’s Nutritional breakdown per serving',
  checkNutritionFacts: 'Check Nutrition Facts',
  checkNutritionFactsOnSite: 'Go to Nutritionix site',
  recipesEditorTitle: 'Recipe Editor',
  back: 'View Recipes',
  addRecipe: 'Add New Recipe',
  editRecipe: 'Edit Recipe',
  recipeSaved: 'Recipe saved successfuly',
  recipeUpdated: 'Recipe updated successfuly',
  prepUnit: 'minutes',
  yes: 'Yes',
  no: 'No',
  validation: {
    requiredField: 'Required',
    positiveNumber: 'Only positive numbers',
    integerNumber: 'Only integer numbers',
    onlyNumbers: 'Only numbers or decimal numbers allowed',
    errorsFound: 'Some fields contain errors or are missing, please check.',
    noTags: 'Please select at least one tag',
  },
  errorOnNutritionixCall: 'Error happened on call to Nutritionix: "{error}"',
  errorNoIngredients: 'There are no ingredients to verify. Please add some before submitting request.',
  submitButton: 'Save Recipe',
  labels: {
    image: 'Recipe Image',
    tags: 'Suitable For',
    macros: 'Macros Per Serving',
    ingredients: 'Ingredients',
    instructions: 'Instructions',
  },
  field: {
    [fieldName.NAME]: {
      label: 'Recipe Name',
    },
    [fieldName.DESCRIPTION]: {
      label: 'Recipe Description',
    },
    [fieldName.SERVINGS]: {
      label: 'No. of Servings',
    },
    [fieldName.SCALE]: {
      label: 'Scale this recipe',
    },
    [fieldName.PREPARATION_TIME]: {
      label: 'Prep Time',
    },
    [fieldName.COOKING_TIME]: {
      label: 'Cook Time',
    },
  },
};

export default texts;
