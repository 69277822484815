import React from 'react';
import PayoutReconciliationContextProvider from './PayoutReconciliationContextProvider';

/**
 * Inserts PayoutReconciliationContext.
 *
 * @param {Object} Component The component that needs the context provider.
 * @returns {Object} The component to render wrapped with the provider.
 */
const withPayoutReconciliationContextProvider = (Component) => (props) => (
  <PayoutReconciliationContextProvider>
    <Component {...props} />
  </PayoutReconciliationContextProvider>
);

export {
  withPayoutReconciliationContextProvider,
};
