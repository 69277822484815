import styled from 'styled-components';

import colors from '../../../../../../styles/colors';

const SecondaryText = styled.span`
  margin-left: 5px;
  color: ${colors.shades.gamma3};
`;

export { SecondaryText };
