import React from 'react';
import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import PropTypes from 'prop-types';

import {
  TemplateListContainer,
  TemplateListContainerStyle,
} from './styles';

const TemplateList = ({
  selectedTemplateName,
  templates,
  onTemplateSelect,
}) => (
  <TemplateListContainer sx={TemplateListContainerStyle}>
    <List>
      {templates.map((template) => (
        <ListItem
          disablePadding
          divider
          selected={selectedTemplateName?.name === template.name}
          key={template.name}
        >
          <ListItemButton onClick={() => onTemplateSelect(template.name)}>
            <ListItemText primary={template.name} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  </TemplateListContainer>
);

TemplateList.propTypes = {
  selectedTemplateName: PropTypes.string,
  templates: PropTypes.arrayOf(PropTypes.object).isRequired,
  onTemplateSelect: PropTypes.func.isRequired,
};

TemplateList.defaultProps = {
  selectedTemplateName: '',
};

export default TemplateList;
