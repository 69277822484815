import format from 'string-template';

import {
  AnalyticFrequency,
  AnalyticType,
  AnalyticUserType,
} from '../../../utils/analytics';
import Collections from '../../../utils/collections';
import MonthlyAnalytics from './MonthlyAnalytics';
import CoachSubscriptionData from './CoachSubscriptionData';

class CoachSubscriptionsAnalytics extends MonthlyAnalytics {
  get newSubscriptions() {
    return this.data.newSubscriptions || 0;
  }

  get cancellations() {
    return this.data.cancellations || 0;
  }

  get activeSubscriptions() {
    return this.data.activeSubscriptions || 0;
  }

  // for new analytics, we have subscriptions as an array. But for old analytics, we have data in the root object
  get subscriptions() {
    return Object.values(this.data.subscriptions || { data: this.data })
      .map((subscriptionData) => new CoachSubscriptionData(subscriptionData));
  }

  static getCollectionPath(coachId) {
    return format(Collections.ANALYTICS, {
      userType: AnalyticUserType.COACH,
      analyticType: AnalyticType.SUBSCRIPTIONS,
      frequency: AnalyticFrequency.MONTHLY,
      userId: coachId,
    });
  }
}

export default CoachSubscriptionsAnalytics;
