import React, {
  useState,
} from 'react';
import {
  Checkbox,
} from '@mui/material';
import PropTypes from 'prop-types';

import {
  StyledTextField,
} from '../../Inputs';
import {
  StyledAutoComplete,
  StyledButton,
  StyledOption,
} from './styles';

const MultiSelectAutoComplete = ({
  tagOptions,
  initialValues,
  onChange,
  buttonLabel,
  onButtonClick,
  listboxStyle,
  className,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [selectedValues, setSelectedValues] = useState(initialValues);

  const buttonOption = { id: 'Button', label: buttonLabel, button: true };

  const handleChange = (_, newValues) => {
    // Remove button from selected options
    const filteredValues = newValues.filter((value) => !value.button);

    setSelectedValues(filteredValues);
    onChange(filteredValues);
  };

  return (
    <StyledAutoComplete
      options={tagOptions}
      inputValue={inputValue}
      value={selectedValues}
      className={className}
      onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
      isOptionEqualToValue={(option, value) => {
        // If the option is the button return true as it's not in the selectable options
        if (option.button) {
          return true;
        }
        return option.id === value.id;
      }}
      onChange={handleChange}
      renderInput={(params) => <StyledTextField {...params} variant="outlined" />}
      renderOption={(props, option, { selected }) => (
        option.button ? (
          <StyledButton
            onClick={onButtonClick}
            key="button"
          >
            {buttonLabel}
          </StyledButton>
        ) : (
          <StyledOption {...props} key={option.id}>
            <Checkbox checked={selected} />
            {option.label}
          </StyledOption>
        )
      )}
      filterOptions={(options, params) => {
        const filtered = options.filter((option) => (
          option.label.toLowerCase().includes(params.inputValue.toLowerCase())
        ));
        // Add button to the start of the list
        return buttonLabel ? [buttonOption, ...filtered] : filtered;
      }}
      getOptionDisabled={(option) => option.disabled}
      ListboxProps={{
        style: listboxStyle,
      }}
      renderTags={() => null}
      clearIcon={null}
      disableCloseOnSelect
      multiple
    />
  );
};

MultiSelectAutoComplete.propTypes = {
  tagOptions: PropTypes.arrayOf(PropTypes.object),
  initialValues: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
  buttonLabel: PropTypes.string,
  onButtonClick: PropTypes.func,
  listboxStyle: PropTypes.object,
  className: PropTypes.string,
};

MultiSelectAutoComplete.defaultProps = {
  tagOptions: [],
  initialValues: [],
  onChange: () => {},
  buttonLabel: '',
  onButtonClick: () => {},
  listboxStyle: {},
  className: '',
};

export default MultiSelectAutoComplete;
