import React from 'react';

const initialValues = {
  externalCoachDoc: null,
  externalCoachPrivateDoc: null,
  coachDoc: null,
  isReady: false,
  isProductsReady: false,
  plans: [],
  products: [],
};

const ExternalCoachContext = React.createContext(initialValues);

export default ExternalCoachContext;
export {
  initialValues,
};
