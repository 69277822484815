import styled from 'styled-components';
import { Typography } from '@mui/material';

import colors from '../../../../../../../../styles/colors';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 5px;
  padding: 5px;
  background-color: ${(props) => (props.$isModalView ? colors.shades.secondary7 : colors.shades.secondary5)};
  border: 1px solid ${colors.shades.secondary4};
  border-radius: 5px;
  cursor: pointer;
`;

const StyledTypography = styled(Typography)`
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0;
  text-align: left;
  color: ${colors.shades.secondary3};
`;

export {
  StyledContainer,
  StyledTypography,
};
