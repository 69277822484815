import styled, { css } from 'styled-components';

import { ReactComponent as ArchiveIcon } from '../../../../../assets/icons/v2/archive.svg';
import { ReactComponent as DuplicateIcon } from '../../../../../assets/icons/v2/duplicate.svg';
import { ReactComponent as ArrowIcon } from '../../../../../assets/icons/v2/arrow-right.svg';

const iconStyles = css`
  width: 16px;
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
`;

const StyledArchiveIcon = styled(ArchiveIcon)`
  ${iconStyles}
`;

const StyledDuplicateIcon = styled(DuplicateIcon)`
  ${iconStyles}
`;

const StyledArrowIcon = styled(ArrowIcon)`
  ${iconStyles}
`;

export {
  ActionButtonsContainer,
  StyledArchiveIcon,
  StyledDuplicateIcon,
  StyledArrowIcon,
};
