import styled from 'styled-components';
import colors from '../../../../../styles/colors';

const Param = styled.div`
  width: 25%;
`;
const ParamTitle = styled.div`
  font-weight: 500;
  font-size: 8px;
  line-height: 9px;
  display: flex;
  align-items: center;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  color: ${colors.shades.gamma10};
  margin-bottom: 5px;
`;
const ParamAfter = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: ${colors.shades.secondary3};
`;
const ParamBefore = styled.div`
  color: ${colors.shades.gamma7};
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  margin-bottom: 2px;
  text-decoration: line-through;
`;

export {
  Param,
  ParamTitle,
  ParamAfter,
  ParamBefore,
};
