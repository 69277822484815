import styled from 'styled-components';

import { ReactComponent as ArchiveIcon } from '../../../../../../assets/icons/v2/archive.svg';

const ChipContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  padding: 4px;
`;

const StyledArchiveIcon = styled(ArchiveIcon)`
  width: 16px;
`;

const ActionButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export {
  ChipContainer,
  StyledArchiveIcon,
  ActionButtonContainer,
};
