import React from 'react';
import PropTypes from 'prop-types';

import { StyledIFrame } from './styles';

const ExternalPage = ({
  src,
}) => (
  <StyledIFrame
    src={src}
    frameBorder="0"
    width="100%"
  />
);

ExternalPage.propTypes = {
  src: PropTypes.string.isRequired,
};

export default ExternalPage;
