import React from 'react';

const initialValues = {
  externalCoachDoc: null,
  isReady: false,
};

const ExternalCoachContext = React.createContext(initialValues);

export default ExternalCoachContext;
export {
  initialValues,
};
