import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Paper,
} from '@mui/material';

import texts from './texts.json';
import {
  Answer,
  Question,
  QuestionContainer,
  Title,
} from './styles';

const FormResponseModal = ({
  questionAnswers,
  onClose,
  showModal,
}) => (
  <Dialog
    open={showModal}
    PaperComponent={Paper}
    onBackdropClick={onClose}
  >
    <Title>
      {texts.title}
    </Title>
    <DialogContent>
      {questionAnswers && questionAnswers.map(({ question, answer }) => !!answer
        && (
          <QuestionContainer key={question}>
            <Question>{question}</Question>
            <Answer>{answer}</Answer>
          </QuestionContainer>
        ))}
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>
        {texts.close}
      </Button>
    </DialogActions>
  </Dialog>
);

FormResponseModal.propTypes = {
  questionAnswers: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
};

export default compose(
  observer,
)(FormResponseModal);
