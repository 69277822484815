import styled from 'styled-components';
import VisibilityIcon from '@mui/icons-material/Visibility';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
`;

const StyledVisibilityIcon = styled(VisibilityIcon)`
  height: 50px;
  width: 50px;
`;

export {
  Container,
  StyledVisibilityIcon,
};
