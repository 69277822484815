import React from 'react';
import PropTypes from 'prop-types';

import {
  StyledEngineProvider,
  ScopedCssBaseline,
  ThemeProvider,
} from '@mui/material';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterMoment from '@mui/lab/AdapterMoment';

import { compose } from 'recompose';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import 'react-toastify/dist/ReactToastify.css';

import { withCoachesListContextProvider } from '../context/CoachesListContext';
import { DrawerHeader } from './components/Sidebar';
import Header from './components/Header';

import { withLayoutContextProvider } from './context';

import {
  Container,
  MainBox,
  ContentBox,
  StyledToastContainer,
} from './styles';

import theme from './theme';

const ToolsLayout = ({
  children,
  sidebarComponent,
  bottomBarComponent,
}) => (
  <ScopedCssBaseline>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Container>
            <Header showMenuIcon={!!sidebarComponent} />
            {sidebarComponent}
            <MainBox
              component="main"
            >
              <DrawerHeader />
              <ContentBox>
                {children}
                <StyledToastContainer />
              </ContentBox>
              {bottomBarComponent}
            </MainBox>
          </Container>
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  </ScopedCssBaseline>
);

ToolsLayout.propTypes = {
  children: PropTypes.node.isRequired,
  sidebarComponent: PropTypes.element,
  bottomBarComponent: PropTypes.element,
};

ToolsLayout.defaultProps = {
  sidebarComponent: null,
  bottomBarComponent: null,
};

export default compose(
  withLayoutContextProvider,
  withCoachesListContextProvider,
)(ToolsLayout);
