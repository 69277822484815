import styled from 'styled-components';
import { Box } from '@mui/material';

const Container = styled(Box)`
  width: 100%;
  height: 100%;
`;

export {
  Container,
};
