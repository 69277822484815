import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import { autorun } from 'mobx';
import { useRouteMatch } from 'react-router-dom';

import useComponentMounted from '../../../hooks/useComponentMounted';
import Exercise from '../../Model/Exercise';
import ExerciseContext, { initialValues } from './ExerciseContext';

/**
 * @namespace ExerciseContextProvider
 *
 * @description Provides a context for managing and accessing exercise data for a coach.
 *
 * **Data Accessible from this Context:**
 * - **isReady**: The readiness of the context.
 * - **exercises**: The collection of base exercises and coach specific exercises.
 */
const ExerciseContextProvider = ({
  children,
}) => {
  const [isReady, setIsReady] = useState(initialValues.isReady);
  const [baseExercisesCollection, setBaseExercisesCollection] = useState(initialValues.baseExercisesCollection);
  const [coachExercisesCollection, setCoachExercisesCollection] = useState(initialValues.coachExercisesCollection);
  const [exercises, setExercises] = useState(initialValues.exercises);
  const [showArchivedExercises, setShowArchivedExercises] = useState(initialValues.showArchivedExercises);
  const [isArchivedExercisesLoaded, setIsArchivedExercisesLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(initialValues.isLoading);

  const isComponentMountedRef = useComponentMounted();

  const {
    params: {
      userId: coachId,
    },
  } = useRouteMatch();

  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      const baseExercisesCol = await Exercise.getBaseExercises();
      const coachExercisesCol = await Exercise.getExercisesforCoach(coachId, initialValues.showArchivedExercises);

      if (isComponentMountedRef.current) {
        setIsLoading(false);
        setBaseExercisesCollection(baseExercisesCol);
        setCoachExercisesCollection(coachExercisesCol);
      }
    };
    init();
  }, [
    isComponentMountedRef,
    coachId,
  ]);

  const getExercises = useCallback((onlyActiveExercises) => {
    const baseExercisesDocs = baseExercisesCollection.docs.slice();
    const coachExercisesDocs = coachExercisesCollection.docs.slice();
    const filteredCoachExercisesDocs = showArchivedExercises && !onlyActiveExercises ? coachExercisesDocs
      : coachExercisesDocs.filter((exercise) => exercise.current);

    // Remove base exercises if there are edited exercises created from them
    filteredCoachExercisesDocs.forEach((coachExercise) => {
      const { originalExercise } = coachExercise;
      if (originalExercise) {
        const originalExerciseIndex = baseExercisesDocs.findIndex(
          (baseExercise) => baseExercise.id === originalExercise,
        );
        baseExercisesDocs.splice(originalExerciseIndex, 1);
      }
    });

    const exerciseDocs = [
      ...baseExercisesDocs,
      ...filteredCoachExercisesDocs,
    ];

    return exerciseDocs.sort((a, b) => a.name.localeCompare(b.name));
  }, [
    baseExercisesCollection.docs,
    coachExercisesCollection.docs,
    showArchivedExercises,
  ]);

  const activeExercises = useMemo(() => {
    if (!showArchivedExercises) {
      return exercises;
    }
    return getExercises(true);
  }, [
    showArchivedExercises,
    getExercises,
    exercises,
  ]);

  useEffect(() => {
    const disposer = autorun(() => {
      const exerciseDocs = getExercises(false);
      if (isComponentMountedRef.current) {
        setExercises(exerciseDocs);
        setIsReady(true);
      }
    });

    return disposer;
  }, [
    isComponentMountedRef,
    getExercises,
  ]);

  const OnChangeCheckBox = useCallback(async (fetchArchivedExercises = false) => {
    setShowArchivedExercises(fetchArchivedExercises);
    if (!isArchivedExercisesLoaded) {
      setIsLoading(true);
      const coachExercisesCol = await Exercise.getExercisesforCoach(coachId, fetchArchivedExercises);
      if (isComponentMountedRef.current) {
        setIsLoading(false);
        setCoachExercisesCollection(coachExercisesCol);
        setIsArchivedExercisesLoaded(true);
      }
    }
  }, [
    coachId,
    isComponentMountedRef,
    isArchivedExercisesLoaded,
  ]);

  const contextValue = useMemo(() => ({
    isReady,
    exercises,
    showArchivedExercises,
    activeExercises,
    isLoading,
    OnChangeCheckBox,
  }), [
    isReady,
    exercises,
    activeExercises,
    showArchivedExercises,
    isLoading,
    OnChangeCheckBox,
  ]);

  return (
    <ExerciseContext.Provider value={contextValue}>
      {children}
    </ExerciseContext.Provider>
  );
};

ExerciseContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default compose(
  observer,
)(ExerciseContextProvider);
