import React, {
  useMemo,
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import { autorun } from 'mobx';
import { useRouteMatch } from 'react-router-dom';

import useComponentMounted from '../../../hooks/useComponentMounted';
import ExerciseTag from '../../Model/ExerciseTag';
import ExerciseTagContext, { initialValues } from './ExerciseTagContext';

/**
 * @namespace ExerciseTagContextProvider
 *
 * @description Provides a context for managing and accessing exercise tags data for a coach.
 *
 * **Data Accessible from this Context:**
 * - **isReady**: The readiness of the context.
 * - **tags**: The collection of base and coach specific exercise tags.
 */
const ExerciseTagContextProvider = ({
  children,
}) => {
  const [isReady, setIsReady] = useState(initialValues.isReady);
  const [baseTagsCollection, setBaseTagsCollection] = useState(initialValues.baseTagsCollection);
  const [coachTagsCollection, setCoachTagsCollection] = useState(initialValues.coachTagsCollection);
  const [tags, setTags] = useState(initialValues.tags);

  const isComponentMountedRef = useComponentMounted();

  const {
    params: {
      userId: coachId,
    },
  } = useRouteMatch();

  useEffect(() => {
    const init = async () => {
      const baseTagsCol = await ExerciseTag.getBaseTags();
      const coachTagsCol = await ExerciseTag.getCoachTags(coachId);

      if (isComponentMountedRef.current) {
        setBaseTagsCollection(baseTagsCol);
        setCoachTagsCollection(coachTagsCol);
      }
    };
    init();
  }, [
    isComponentMountedRef,
    coachId,
  ]);

  useEffect(() => {
    const disposer = autorun(() => {
      const baseTagsDocs = baseTagsCollection.docs.slice();
      const coachTagsDocs = coachTagsCollection.docs.slice();

      const tagDocs = [
        ...coachTagsDocs,
        ...baseTagsDocs,
      ];

      if (isComponentMountedRef.current) {
        setTags(tagDocs);
        setIsReady(true);
      }
    });

    return disposer;
  }, [
    baseTagsCollection.docs,
    coachTagsCollection.docs,
    isComponentMountedRef,
  ]);

  const contextValue = useMemo(() => ({
    isReady,
    tags,
  }), [
    isReady,
    tags,
  ]);

  return (
    <ExerciseTagContext.Provider value={contextValue}>
      {children}
    </ExerciseTagContext.Provider>
  );
};

ExerciseTagContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default compose(
  observer,
)(ExerciseTagContextProvider);
