import { WorkoutFeedViewType } from '../../CheckInStats/utils';

const texts = {
  emptyText: '-',
  completedWorkoutText: '{type} {title}: completed',
  duration: ' - {duration}',
  comment: '"{comment}"',
  loadButton: 'Load More',
  types: {
    [WorkoutFeedViewType.CHECK_IN]: 'Check in',
    [WorkoutFeedViewType.COACH_ASSIGNED_WORKOUT]: 'Workout',
    [WorkoutFeedViewType.SELF_ASSIGNED_WORKOUT]: 'Self assigned workout',
    [WorkoutFeedViewType.CUSTOM_WORKOUT]: 'Custom workout',
    [WorkoutFeedViewType.TRACKED_ACTIVITY]: 'Tracked activity',
    [WorkoutFeedViewType.TRACKED_ACTIVITY_SUMMARY]: 'Tracked activity summary',
  },
};

export default texts;
