import styled from 'styled-components';
import {
  TextField,
} from '@mui/material';

import Modal from '../../../../../components/Modal';
import { fontFamily } from '../../../../../styles/text';

const StyledModal = styled(Modal)`
  --height: inner-height;
  --min-height: 320px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 60px;
`;

const Title = styled.div`
  font-family: ${fontFamily.sansSerif};
  flex: 1;
  font-size: 24px;
  white-space: nowrap;
  text-align: center;
  margin: 20px;
`;

const StyledTextField = styled(TextField)`
  margin-bottom: 20px;
  width: 100%;
`;

export {
  StyledModal,
  Title,
  Container,
  StyledTextField,
};
