import { useContext, useEffect } from 'react';

import NavigationContext from '../context/NavigationContext';
import useSessionStore from '../../hooks/useSessionStore';

const ActiveRoute = ({ pageId, children }) => {
  const {
    setActivePage,
  } = useContext(NavigationContext);
  const {
    authUser: {
      uid: userId,
      displayName: name,
      email,
    },
  } = useSessionStore();

  useEffect(() => {
    setActivePage(pageId);
  }, [
    pageId,
    setActivePage,
  ]);

  useEffect(() => {
    window.pylon = {
      chat_settings: {
        app_id: window.system2.config.pylonAppId,
        email,
        name,
      },
    };
  }, [
    userId,
    email,
    name,
  ]);

  return children;
};

export default ActiveRoute;
