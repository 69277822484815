import React, { useContext } from 'react';
import { Chat } from 'stream-chat-react';
import 'stream-chat-react/dist/css/index.css';

import ChatContext from '../../../../context/ChatContext';
import '../../assets/css/Chat.css';
import ChatState from '../../../../context/ChatContext/states';

import SingleChannelView from '../SingleChannelView';
import ChatNotificationView from '../Notification';

import texts from './texts.json';

const QuickChatView = () => {
  const {
    chatClient,
    chatState,
  } = useContext(ChatContext);
  if (chatState === ChatState.CHAT_ERROR) {
    return <ChatNotificationView notificationMessage={texts.chatViewErrorMessage} />;
  }

  return (
    <Chat client={chatClient} theme="messaging light">
      <SingleChannelView />
    </Chat>
  );
};

export default QuickChatView;
