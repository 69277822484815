import React, {
  useState,
  useEffect,
  useContext,
} from 'react';
import { observer } from 'mobx-react';
import { compose } from 'recompose';

import HelpToggle from '../../components/HelpToggle';
import FirebaseContext from '../../context/FirebaseContext';
import PageContainer from '../Login/components/PageContainer';
import useNavigation from '../../hooks/useNavigation';
import useSessionStore from '../../hooks/useSessionStore';
import useComponentMounted from '../../hooks/useComponentMounted';
import useToolsNavigation from '../../CoachTools/hooks/useToolsNavigation';
import pages, { pagesConfig } from '../../CoachTools/config/pages';

import SignupForm from './SignupForm';
import { ErrorMessage } from './styles';
import texts from './texts.json';

const Signup = () => {
  const { authUser } = useSessionStore();
  const { firebase } = useContext(FirebaseContext);
  const [signupError, setSignupError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const isComponentMountedRef = useComponentMounted();

  const { navigateTo, routes } = useNavigation();
  const { navigateToCoachTools } = useToolsNavigation();

  const goToLogin = () => {
    navigateTo(routes.LOGIN);
  };

  const onSubmit = async ({
    email,
    password,
    firstName,
    lastName,
    phoneNumber,
  }) => {
    setIsProcessing(true);

    let anonymousUser = null;
    try {
      // Create an anonymous user account for calling the remote function.
      ({ user: anonymousUser } = await firebase.auth.signInAnonymously());

      const response = await firebase.remote('coachAccountCreation', {
        email: email?.toLowerCase(),
        password,
        firstName,
        lastName,
        phoneNumber,
      });

      // We don't need the anonymous user anymore.
      await anonymousUser.delete();

      // If there's an error, we need to show a message and stop processing anything else.
      const { error } = await response.json();
      if (error) {
        setSignupError(error);
        setIsProcessing(false);
        return;
      }

      // Let's log in with the provided user and password.
      await firebase.auth.signInWithEmailAndPassword(email, password);
    } catch (error) {
      if (isComponentMountedRef.current) {
        setIsProcessing(false);
        setSignupError(error.message);

        // Clean up the anonymous user if it was created.
        if (anonymousUser) {
          await anonymousUser.delete();
        }
      }
    }
  };

  useEffect(() => {
    if (authUser && !authUser.isAnonymous) {
      navigateToCoachTools(authUser.uid, pagesConfig[pages.CONFIG].path);
    }
  }, [
    navigateToCoachTools,
    authUser,
  ]);

  return (
    <PageContainer formHeading={texts.signUp}>
      <SignupForm
        onSubmit={onSubmit}
        isProcessing={isProcessing}
      />
      {!!signupError && <ErrorMessage>{signupError}</ErrorMessage>}
      <HelpToggle
        toggleForms={goToLogin}
        helpText={texts.accountExits}
        actionText={texts.login}
      />
    </PageContainer>
  );
};

export default compose(
  observer,
)(Signup);
