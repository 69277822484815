import styled from 'styled-components';

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 16px;
`;

const DataGridContainer = styled.div`
  width: calc(100% - 40px);
  min-height: 500px;
  min-width: 920px
`;

export {
  ActionsContainer,
  DataGridContainer,
};
