const texts = {
  title: 'Days',
  addNewDay: 'Add New Day',
  day: 'Day',
  noOptionText: 'No workout found...',
  estDuration: 'Est: {minutes} mins',
  equipment: 'Equipment: {equipment}',
  none: 'None',
  moreEquipment: 'More...',
  textFieldLabel: 'Select Workout',
  deleteConfirmMsg: 'Are you sure you want to remove the Day {workoutDate} workout?',
  viewWorkout: 'View',
  editWorkout: 'Edit',
};

export default texts;
