import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import * as Sentry from '@sentry/browser';

import useComponentMounted from '../../hooks/useComponentMounted';
import useSessionStore from '../../hooks/useSessionStore';

import User from '../../Model/User';

import { firestorePaths } from '../../utils/paths';
import {
  externalCoach as externalCoachQueryVariable,
  planCode as planCodeQueryVariable,
} from '../../utils/onboarding';
import UserContractContext from '../UserContractContext';
import OnboardingFlowConfigContext from './OnboardingFlowConfigContext';

const OnboardingFlowConfigContextProvider = ({
  children,
}) => {
  const [isReady, setIsReady] = useState(false);
  const [externalCoach, setExternalCoach] = useState(null);
  const [planCode, setPlanCode] = useState(null);
  const [isOnboarded, setIsOnboarded] = useState(false);

  const isComponentMountedRef = useComponentMounted();

  const { userId: currentUserId } = useSessionStore();

  const {
    leadId,
    contractDoc,
  } = useContext(UserContractContext);

  const getOnboardingData = useCallback(() => {
    const extCoach = externalCoachQueryVariable;
    const plan = planCodeQueryVariable;
    return {
      extCoach,
      plan,
    };
  }, []);

  useEffect(() => {
    const init = async () => {
      let extCoach;
      let plan;

      try {
        if (currentUserId) {
          const userDoc = new User(`${firestorePaths.USER}/${currentUserId}`);
          await userDoc.init();

          const {
            isOnboarded: isUserOnboarded,
          } = userDoc;

          if (isComponentMountedRef.current) {
            setIsOnboarded(isUserOnboarded);
          }
        }
        // Get onboarding data from query variables
        ({ extCoach, plan } = getOnboardingData());
      } catch (error) {
        Sentry.captureException(error, {
          extra: {
            currentUserId,
          },
        });
      }

      // Retrieve the coach id from the contract, if available.
      if (contractDoc) {
        ({
          coach: extCoach,
        } = contractDoc);
      }

      if (isComponentMountedRef.current) {
        setExternalCoach(extCoach);
        setPlanCode(plan);
        setIsReady(true);
      }
    };
    if (!isReady) {
      init();
    }
  }, [
    isReady,
    currentUserId,
    isComponentMountedRef,
    contractDoc,
    getOnboardingData,
  ]);

  const context = useMemo(() => ({
    isReady,
    externalCoach,
    planCode,
    isOnboarded,
    leadId,
    paramsAvailable: !!leadId || !!contractDoc || (!!externalCoach && !!planCode),
  }), [
    isReady,
    externalCoach,
    planCode,
    leadId,
    isOnboarded,
    contractDoc,
  ]);

  return (
    <OnboardingFlowConfigContext.Provider value={context}>
      {children}
    </OnboardingFlowConfigContext.Provider>
  );
};

OnboardingFlowConfigContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default compose(
  observer,
)(OnboardingFlowConfigContextProvider);
