import React, {
  useMemo,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import { AnalyticGroups } from '../../../../../utils/analytics';
import {
  GroupSelectWrapper,
  StyledAutoComplete,
  StyledTextField,
} from './styles';
import texts from './texts.json';

const GroupSelect = ({
  selectedGroup,
  onChange,
}) => {
  const groupOptions = useMemo(() => Object.values(AnalyticGroups).map((grp) => ({
    id: grp,
    label: grp,
  })), []);

  const onMonthSelected = useCallback((event, selectedOption) => {
    onChange(selectedOption?.id);
  }, [
    onChange,
  ]);

  return (
    <GroupSelectWrapper>
      <StyledAutoComplete
        disablePortal
        options={groupOptions}
        renderInput={(params) => <StyledTextField {...params} label={texts.selectGroup} />}
        value={selectedGroup}
        onChange={onMonthSelected}
      />
    </GroupSelectWrapper>
  );
};

GroupSelect.propTypes = {
  selectedGroup: PropTypes.string,
  onChange: PropTypes.func,
};

GroupSelect.defaultProps = {
  selectedGroup: '',
  onChange: () => { },
};

export default GroupSelect;
