import React from 'react';

const initialValues = {
  isReady: false,
  coachWorkoutCollection: {},
  publicWorkoutCollection: {},
  workouts: [],
  showArchivedWorkouts: false,
  isLoading: false,
};

const WorkoutContext = React.createContext(initialValues);

export default WorkoutContext;
export {
  initialValues,
};
