import {
  Typography,
  List,
} from '@mui/material';
import styled from 'styled-components';

import { ReactComponent as QuestionIcon } from '../../../../../assets/icons/question.svg';
import { ReactComponent as DownloadIcon } from '../../../../../assets/icons/download.svg';
import { ReactComponent as StarIcon } from '../../../../../assets/icons/star.svg';

import colors from '../../../../../styles/colors';
import Button from '../../../../../components/Button';
import RadioButtonGroup from '../../../../components/RadioButtonGroup';

const Text = styled(Typography)`
  margin-bottom: 5px;
`;

const Container = styled.div`
  padding: 16px;
  height: 100%;
  width: 60%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  background-color: ${colors.base.gamma};
`;

const ConfigContainer = styled.div`
  margin: 15px 0;
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background-color: ${colors.base.alpha};
`;

const EmptyFeed = styled.div`
  width: 100%;
  height: 100%;
`;

const Title = styled(Text)`
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 5px;
`;

const SubTitle = styled(Text)`
  font-size: 13px !important;
  margin-bottom: 0;
  font-weight: 300;
  color: ${colors.shades.gamma7};
`;

const Description = styled(Text)`
  font-size: 13px !important;
  margin-bottom: 0;
  font-weight: 500;
`;

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  margin: 20px 0;
`;

const StyledList = styled(List)`
  margin: 0 0 10px;
`;

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledButton = styled(Button)`
  margin: 10px 0px;
  background-color: ${colors.shades.secondary4};
  border-radius: 5px;
  width: fit-content;
  color: ${colors.base.secondary2};
  font-size: 15px;
  line-height: 18px;
  padding: 16px;
  font-weight: 600;
  text-transform: none;
  border: 1px solid ${colors.shades.secondary4};
  &:hover {
    background-color: ${colors.shades.secondary5};
    border: 1px solid ${colors.shades.secondary4};
  }
`;

const iconStyle = `
  width: 20px;
  height: 20px;
  margin-right: 10px;
`;

const StyledQuestionIcon = styled(QuestionIcon)`
  ${iconStyle}
`;

const StyledDownloadIcon = styled(DownloadIcon)`
  ${iconStyle}
`;

const CheckInDayLabel = styled(Text)`
  font-size: 13px !important;
  margin-bottom: 0;
  font-weight: 400;
  color: ${colors.shades.primary1};

  > span {
    font-weight: 500;
    color: ${colors.base.beta};
  }
`;

const CheckInDayText = styled(Text)`
  font-size: 13px !important;
  margin-bottom: 0;
  font-weight: 500;
  color: ${colors.base.beta};
`;

const CheckInDayContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  width: 100%;
  overflow-x: auto;
`;

const CheckInDay = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  margin-bottom: 10px;
  color: ${colors.base.beta};
  background-color: ${({ isSelected }) => (isSelected ? colors.shades.primary5 : colors.base.alpha)};
  border: 1px solid ${({ isSelected }) => (isSelected ? colors.shades.primary6 : colors.shades.gamma4)};
  align-items: center;
  padding: 14px;
  margin-right: 12px;
  font-weight: 400;
  flex: 1;
  font-size: 16px;
  line-height: 16px;
  justify-content: center;
  position: relative;
  cursor: pointer;
  &:hover {
    background-color: ${({ isSelected }) => (isSelected ? colors.shades.primary3 : colors.shades.gamma1)};
  }
`;

const ImageUploadOptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  overflow-x: auto;
`;

const ClientCount = styled.div`
  display: flex;
  flex-direction: column;
  color: ${colors.base.alpha};
  background-color: ${colors.shades.primary1};
  border-radius: 36px;
  padding: 2px 10px;
  font-size: 8px;
  font-weight: 500;
  margin: 5px 0;
  text-align: center;
`;

const StyledStarIcon = styled(StarIcon)`
  width: 10px;
  height: 10px;
  left: 5px;
  top: 5px;
  position: absolute;
`;

const StyledRadioButtonGroup = styled(RadioButtonGroup)`
  margin-bottom: 10px;
`;

export {
  Container,
  EmptyFeed,
  Title,
  Description,
  StyledButton,
  StyledList,
  TitleContainer,
  HeaderContainer,
  ConfigContainer,
  StyledQuestionIcon,
  SubTitle,
  StyledDownloadIcon,
  CheckInDayText,
  CheckInDayLabel,
  CheckInDayContainer,
  CheckInDay,
  ClientCount,
  StyledStarIcon,
  ImageUploadOptionsContainer,
  StyledRadioButtonGroup,
};
