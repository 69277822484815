import React, {
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { compose } from 'recompose';

import useQuickSearch from '../../../hooks/useQuickSearch';

import FormsTableToolbar from './FormsTableToolbar';
import {
  Container,
  DataGridContainer,
  StyledDataGrid,
} from './styles';

import texts from './texts';

const FormsTable = ({
  rows,
  ActionsCellComponent,
  extraActionsCellProps,
}) => {
  const columns = useMemo(() => [
    {
      field: 'coach',
      headerName: texts.headers.coach,
      quickSearch: true,
      flex: 6,
    },
    {
      field: 'formName',
      headerName: texts.headers.formName,
      flex: 10,
    },
    {
      field: 'id',
      headerName: texts.headers.formId,
      flex: 6,
    },
    {
      field: 'action',
      headerName: texts.headers.action,
      disableColumnMenu: true,
      hideSortIcons: true,
      disableReorder: true,
      flex: 15,
      minWidth: 300,
      cellClassName: 'action-column',
      renderCell: ({ row }) => (<ActionsCellComponent row={row} {...extraActionsCellProps} />),
    },
  ], [extraActionsCellProps]);

  const {
    filteredRows: quickSearchRows,
    toolbarProps,
  } = useQuickSearch(rows, columns);

  return (
    <Container>
      <DataGridContainer>
        <StyledDataGrid
          rows={quickSearchRows}
          columns={columns}
          rowHeight={60}
          pagination
          pageSize={20}
          rowsPerPageOptions={[20]}
          disableSelectionOnClick
          components={{
            Toolbar: FormsTableToolbar,
          }}
          componentsProps={{
            toolbar: {
              ...toolbarProps,
              placeholder: texts.toolbarPlaceholder,
            },
          }}
        />
      </DataGridContainer>
    </Container>
  );
};

FormsTable.propTypes = {
  rows: PropTypes.array.isRequired,
  ActionsCellComponent: PropTypes.elementType.isRequired,
  extraActionsCellProps: PropTypes.object,
};

FormsTable.defaultProps = {
  extraActionsCellProps: {},
};

export default compose(
  observer,
)(FormsTable);
