import React, {
  useEffect,
  useMemo,
  useState,
  useCallback,
} from 'react';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { DataGrid } from '@mui/x-data-grid';
import format from 'string-template';

import useQuickSearch from '../../../hooks/useQuickSearch';
import useComponentMounted from '../../../hooks/useComponentMounted';
import useToast from '../../hooks/useToast';
import User from '../../../Model/User';
import { removeUserFromRole } from '../../utils/user';
import QuickSearchToolbar from '../QuickSearchToolbar';
import { PrimaryButton } from '../../../components/Button/ActionButtons';
import ConfirmDialog from '../ConfirmDialog';
import LoadingOverlay from '../LoadingOverlay';
import {
  HeaderRow,
  TitleContainer,
  Title,
} from '../../../components/v2/Header';
import AddUserModal from '../UserAddModal';

import {
  Container,
  StyledButton,
} from './styles';

import texts from './texts.json';

const UserManagement = ({
  role,
  title,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [userCollection, setUserCollection] = useState({ docs: [] });
  const [showUserAddingModal, setShowUserAddingModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const isComponentMountedRef = useComponentMounted();
  const { showToast } = useToast();

  useEffect(() => {
    const initUsers = async () => {
      setIsLoading(true);
      const usersCol = await User.getUsersByRole(role);
      if (isComponentMountedRef.current) {
        setUserCollection(usersCol);
        setIsLoading(false);
      }
    };
    initUsers();
  }, [
    isComponentMountedRef,
    role,
  ]);

  const columns = useMemo(() => [
    {
      field: 'name',
      headerName: texts.headers.name,
      flex: 10,
      quickSearch: true,
    },
    {
      field: 'email',
      headerName: texts.headers.email,
      flex: 10,
      quickSearch: true,
    },
    {
      field: 'actions',
      headerName: texts.headers.actions,
      flex: 5,
      sortable: false,
      filterable: false,
      renderCell: ({ row }) => (
        <PrimaryButton
          onClick={() => setSelectedUser(row)}
          variant="info"
          size="medium"
        >
          {format(texts.removeFromRole, { role })}
        </PrimaryButton>
      ),
    },
  ], [role]);

  const handleRemoveUserFromRole = useCallback(async () => {
    setIsLoading(true);
    try {
      await removeUserFromRole(selectedUser.id, role);
      showToast(format(texts.removeSuccess, { role }));
      const usersCol = await User.getUsersByRole(role);
      if (isComponentMountedRef.current) {
        setUserCollection(usersCol);
      }
    } catch (error) {
      showToast(format(texts.removeError, { role }), { error: true });
    } finally {
      if (isComponentMountedRef.current) {
        setIsLoading(false);
        setSelectedUser(null);
      }
    }
  }, [
    selectedUser,
    isComponentMountedRef,
    showToast,
    role,
  ]);

  const {
    filteredRows: quickSearchRows,
    toolbarProps,
  } = useQuickSearch(userCollection.docs, columns);

  return (
    <Container>
      <HeaderRow>
        <TitleContainer>
          <Title>{title}</Title>
        </TitleContainer>
        <StyledButton
          variant="contained"
          onClick={() => setShowUserAddingModal(true)}
        >
          {texts.addNewUser}
        </StyledButton>
      </HeaderRow>
      <DataGrid
        rows={quickSearchRows}
        columns={columns}
        rowHeight={50}
        pagination
        autoPageSize
        components={{
          Toolbar: QuickSearchToolbar,
        }}
        componentsProps={{
          toolbar: {
            ...toolbarProps,
            placeholder: texts.searchPlaceholder,
          },
        }}
      />
      <AddUserModal
        isOpen={showUserAddingModal}
        setShowUserAddingModal={setShowUserAddingModal}
        setUserCollection={setUserCollection}
        role={role}
        setIsLoading={setIsLoading}
      />
      <ConfirmDialog
        isOpen={!!selectedUser}
        onCancel={() => setSelectedUser(null)}
        onConfirm={handleRemoveUserFromRole}
        dialogTexts={{
          title: format(texts.removeFromRoleConfirm, { userName: selectedUser?.name, role }),
        }}
      />
      <LoadingOverlay isLoading={isLoading} />
    </Container>
  );
};

UserManagement.propTypes = {
  role: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default compose(
  observer,
)(UserManagement);
