import React from 'react';
import PropTypes from 'prop-types';
import {
  Stat,
  MacroStatContainer,
  MacroStat,
  MacroLabelText,
  MacroValueText,
  MacroTitleText,
} from './styles';
import texts from './texts.json';

const MacroStats = ({
  macros,
  macrosPercentages,
  shouldShowTitle,
}) => (
  <MacroStatContainer>
    {shouldShowTitle && <MacroTitleText>{texts.macroBreakdown}</MacroTitleText>}
    {macros && macrosPercentages && (
      <MacroStat>
        <Stat>
          <MacroLabelText>{texts.protein}</MacroLabelText>
          <MacroValueText>
            {`${macros.protein?.toFixed(0)}g (${macrosPercentages.proteinPercentage}%)`}
          </MacroValueText>
        </Stat>
        <Stat>
          <MacroLabelText>{texts.carbs}</MacroLabelText>
          <MacroValueText>
            {`${macros.carbs?.toFixed(0)}g (${macrosPercentages.carbsPercentage}%)`}
          </MacroValueText>
        </Stat>
        <Stat>
          <MacroLabelText>{texts.fat}</MacroLabelText>
          <MacroValueText>
            {`${macros.fat?.toFixed(0)}g (${macrosPercentages.fatPercentage}%)`}
          </MacroValueText>
        </Stat>
      </MacroStat>
    )}
  </MacroStatContainer>
);

MacroStats.propTypes = {
  macros: PropTypes.object,
  macrosPercentages: PropTypes.object,
  shouldShowTitle: PropTypes.bool,
};

MacroStats.defaultProps = {
  macros: {},
  macrosPercentages: {},
  shouldShowTitle: false,
};

export default MacroStats;
