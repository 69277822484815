import {
  useCallback,
  useMemo,
  useContext,
} from 'react';
import { getFirestore } from 'firestorter';

import { firestorePaths } from '../../utils/paths';
import LoggedInUserContext from '../../context/LoggedInUserContext';
import useSessionStore from '../../hooks/useSessionStore';

const useUpdateInteraction = (clientId) => {
  const {
    userDoc: {
      name: authUserName,
    },
    userId: authUserId,
  } = useContext(LoggedInUserContext);
  const { isCoachAssistant: isLoggedInUserCoachAssistant } = useSessionStore();

  const updateLastInteraction = useCallback(async () => {
    if (isLoggedInUserCoachAssistant && clientId) {
      const db = getFirestore();
      const userDocRef = db.collection(firestorePaths.USER).doc(clientId);
      await userDocRef.update({
        lastInteractionBy: authUserId,
        lastInteractionByUserName: authUserName,
      });
    }
  }, [
    isLoggedInUserCoachAssistant,
    clientId,
    authUserId,
    authUserName,
  ]);

  return useMemo(() => ({
    updateLastInteraction,
  }), [
    updateLastInteraction,
  ]);
};

export default useUpdateInteraction;
