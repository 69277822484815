import styled from 'styled-components';
import { Typography } from '@mui/material';

import { ReactComponent as EditIcon } from '../../../../../../assets/icons/v2/edit-blue.svg';
import colors from '../../../../../../styles/colors';

const ExerciseDetailsContainer = styled.div`
  display: flex;
  border-radius: 4px;
  border: 1px solid ${colors.shades.secondary6};
  background-color: ${colors.base.alpha};
  height: 60px;
  margin-bottom: 20px;
  padding: 0 15px;
  align-items: center;
  justify-content: space-between;
`;

const Thumbnail = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 3px;
`;

const TagList = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 5px;
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled(Typography)`
  font-size: 14px;
`;

const Subtitle = styled(Typography)`
  font-size: 12px;
`;

const ActionContainer = styled.div`
  display: flex;
  gap:20px;
  align-items: center;
`;

const StyledEditIcon = styled(EditIcon)`
  width: 24px;
`;

export {
  ExerciseDetailsContainer,
  Thumbnail,
  TagList,
  DetailsContainer,
  TextContainer,
  Title,
  Subtitle,
  ActionContainer,
  StyledEditIcon,
};
