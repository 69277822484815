import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { compose } from 'recompose';

import useToolsNavigation from '../../hooks/useToolsNavigation';

import ClientQuickInfoButton from '../ClientQuickInfoButton';
import ChatButton from '../ChatButton';
import {
  NameContainer,
  Container,
  TitleContainer,
  StyledLink,
  Title,
  Time,
  StyledAvatar,
} from './styles';

const UserInfo = ({
  userId,
  userAvatar,
  userName,
  title,
  time,
  className,
  hideQuickAccessButton,
}) => {
  const { navigateToClientPage } = useToolsNavigation();

  return (
    <>
      <Container className={className}>
        <StyledAvatar image={userAvatar} name={userName} />
        <NameContainer>
          <TitleContainer>
            <StyledLink onClick={() => navigateToClientPage(userId)}>
              <Title>{title || userName}</Title>
            </StyledLink>
            <ChatButton
              userId={userId}
              popperPlacement="right-start"
              iconVariant="secondary"
              iconButton
            />
            {!hideQuickAccessButton && (
              <ClientQuickInfoButton userId={userId} />
            )}
          </TitleContainer>
          {time && <Time>{time}</Time>}
        </NameContainer>
      </Container>
    </>
  );
};

UserInfo.propTypes = {
  userAvatar: PropTypes.string,
  title: PropTypes.string,
  time: PropTypes.string,
  className: PropTypes.string,
  hideQuickAccessButton: PropTypes.bool,
  userName: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
};

UserInfo.defaultProps = {
  userAvatar: '',
  title: '',
  time: '',
  className: '',
  hideQuickAccessButton: false,
};

export default compose(
  observer,
)(UserInfo);
