import React from 'react';
import { ConfigurationType } from '../../config/configurationPages';

const configurationMenuContextInitialState = {
  onConfigurationSelected: () => {},
  selectedConfiguration: ConfigurationType.STRIPE,
};

const ConfigurationMenuContext = React.createContext(configurationMenuContextInitialState);

export default ConfigurationMenuContext;
export {
  configurationMenuContextInitialState,
};
