import { DietaryRestrictionText } from '../../Model/UserNutritionProfile';
import {
  RecipeTag,
} from '../../utils/meals';

const texts = {
  protein: 'Protein',
  carbs: 'Carbs',
  fat: 'Fat',
  macroNutrient: '{nutrient}: {amount}g ({percentage}%)',
  caloriesUnit: 'kcal',
  recipeImageAlt: 'Recipe image',
  prepTime: '{preparationTime} mins',
  cookTime: '{cookingTime} mins',
  ingredients: '{count} ingredients',
  noAllergens: '-',
  moreAllergens: '+{amount} More',
  toolbarPlaceholder: 'Search by recipe name',
  mealFilter: 'Meal time',
  restrictionFilter: 'Restrictions',
  dietMacroRange: 'Diet/Macro Range',
  archive: 'Archive',
  unarchive: 'Unarchive',
  showArchivedCheckbox: 'Show Archived Recipes',
  actionText: 'Are you sure you want to {action} recipe: {recipe}?',
  archiveWarning: `
    This will remove the recipe from all your active meal plans. Current meal plan assignments will be unaffected
  `,
  successfullyProcessed: 'Recipe {action}d successfully',
  buttons: {
    breakfast: '+ Breakfast',
    lunch: '+ Lunch',
    snack: '+ Snack',
    dinner: '+ Dinner',
  },
  headers: {
    name: 'Recipe Name',
    protein: 'Protein',
    carbs: 'Carbs',
    fat: 'Fat',
    prepTime: 'Prep Time',
    cookTime: 'Cook Time',
    tags: 'Suitable For',
    calories: 'Calories',
    allergens: 'Allergens',
    action: 'Add to',
  },
  meal: {
    [RecipeTag.BREAKFAST]: 'Breakfast',
    [RecipeTag.LUNCH]: 'Lunch',
    [RecipeTag.DINNER]: 'Dinner',
    [RecipeTag.SNACK]: 'Snack',
  },
};

export const allergensFilterOptions = {
  '': 'Filter by',
  ...DietaryRestrictionText,
};

export default texts;
