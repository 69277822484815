import { DialogTitle, TextField } from '@mui/material';
import styled from 'styled-components';
import { sansSerifHeadlineFontStyles } from '../../../../../styles/text';

const Title = styled(DialogTitle)`
  ${sansSerifHeadlineFontStyles}
  font-size: 24px;
  font-weight: bold;
`;

const StyledNotesInput = styled(TextField)`
  min-width: 350px;
  width: 100%;
  margin-top: 5px;
`;

export {
  Title,
  StyledNotesInput,
};
