import { formatCurrencyCents } from '../../../utils/formatters';

class CoachSubscriptionData {
  constructor(data) {
    this.data = data;
  }

  get currency() {
    return this.data.currency;
  }

  get revenue() {
    return formatCurrencyCents(this.data.revenue || 0, this.currency, { minimumFractionDigits: 2 });
  }

  get newSubscriptions() {
    return this.data.newSubscriptions || 0;
  }

  get activeSubscriptions() {
    return this.data.activeSubscriptions || 0;
  }

  get avgNewSubPayment() {
    return formatCurrencyCents(this.newSubscriptions ? this.data.revenue / this.newSubscriptions : 0,
      this.currency,
      { minimumFractionDigits: 2 });
  }
}

export default CoachSubscriptionData;
