import React from 'react';
import PropTypes from 'prop-types';

import config from '../../../../../config';

/**
 * This component encapsulates the pixel integration for ShareASale.
 * Integration Guideline: https://shareasale.atlassian.net/wiki/spaces/SAS/pages/388563231/Lead+Tracking+Setup
 */
const ShareASale = ({ userId }) => {
  const merchantId = config.shareASale?.merchantId;

  // We don't want to render this pixel in dev environment.
  if (!merchantId) {
    return null;
  }

  // According to the guideline we need to use as "tracking" whatever we define is our "customerID"
  const srcQueryParams = `tracking=${userId}&amount=0.00&merchantID=${merchantId}&transtype=lead`;
  const src = `https://www.shareasale.com/sale.cfm?${srcQueryParams}`;

  // As per the guideline, it's required to re-inject the script right after adding <img> element.
  return (
    <>
      <img
        src={src}
        width="1"
        height="1"
        alt="ShareASale"
      />
      <script
        src="https://www.dwin1.com/19038.js"
        type="text/javascript"
        defer="defer"
      />
    </>
  );
};

ShareASale.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default ShareASale;
