import React, {
  useState,
  useContext,
} from 'react';
import { compose } from 'recompose';

import { SaveButton } from '../../../../../../components/Button/ActionButtons';
import Select from '../../../../../../components/Select';
import MealPlanContext, { withMealPlanContextReady } from '../../../../../context/MealPlanContext';
import { RefreshInterval as IntervalOptions } from '../../../../../Model/MealPlanAssignment';
import useToast from '../../../../../hooks/useToast';
import EditItem from '../../../components/EditItem';
import {
  TitledActionContainer,
  TitledActionContainerFooter,
  TitledActionContainerHeader,
  TitledActionContainerSection,
} from '../../../styles';

import { SecondaryText } from './styles';
import texts from './texts';

const recommendedTextEl = (
  <>
    {texts.intervals[IntervalOptions.MONTHLY]}
    <SecondaryText>
      {texts.recommended}
    </SecondaryText>
  </>
);

const intervalOptions = Object.entries(IntervalOptions).map(([key, value]) => ({
  value: key,
  label: value === IntervalOptions.MONTHLY ? recommendedTextEl : texts.intervals[value],
}));

const RefreshInterval = () => {
  const {
    mealPlanConfig,
    saveMealPlanConfig,
  } = useContext(MealPlanContext);

  const [selectedInterval, setSelectedInterval] = useState(mealPlanConfig?.refreshInterval || IntervalOptions.MONTHLY);

  const { showToast } = useToast();

  const handleSave = () => {
    saveMealPlanConfig({ refreshInterval: selectedInterval });
    showToast(texts.saved);
  };

  return (
    <TitledActionContainer>
      <TitledActionContainerHeader>{texts.header}</TitledActionContainerHeader>
      <TitledActionContainerSection>
        <EditItem label={texts.headerText}>
          <Select
            options={intervalOptions}
            value={selectedInterval}
            displayEmpty
            onChange={(event) => setSelectedInterval(event.target.value)}
          />
        </EditItem>
      </TitledActionContainerSection>
      <TitledActionContainerFooter>
        <SaveButton
          onClick={handleSave}
          disabled={selectedInterval === mealPlanConfig?.refreshInterval}
        >
          {texts.save}
        </SaveButton>
      </TitledActionContainerFooter>
    </TitledActionContainer>
  );
};

// We need the meal plan context to be ready because changing the interval will affect the fetched assignments' statuses
export default compose(
  withMealPlanContextReady,
)(RefreshInterval);
