import styled from 'styled-components';

import colors from '../../../../styles/colors';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 12px;
`;

const Label = styled.div`
  color: ${colors.shades.primary1};
`;

const Value = styled.div`
  color: ${colors.base.beta};
  margin-left: 5px;
`;

const Warning = styled.div`
  color: ${colors.base.danger};
  margin-left: 5px;
  font-weight: 600;
`;

export {
  Container,
  Label,
  Value,
  Warning,
};
