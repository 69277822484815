import React from 'react';

import Leads from '../../../components/Leads';
import texts from './texts.json';

const ISLeads = () => (
  <Leads
    title={texts.leadsTitle}
    enableCoachSelect
    isISView
  />
);

export default ISLeads;
