const cordovaInAppBrowserRef = window.cordova_iab || (
  window.webkit
  && window.webkit.messageHandlers
  && window.webkit.messageHandlers.cordova_iab
);

const isInAppBrowserEnabled = !!cordovaInAppBrowserRef;

export {
  isInAppBrowserEnabled,
  cordovaInAppBrowserRef,
};
