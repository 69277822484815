import styled from 'styled-components';
import {
  Box,
} from '@mui/material';

import colors from '../../../../styles/colors';

const MealAssignmentsContainer = styled(Box)`
  flex: 1;
  margin: 10px 30px 30px 30px;
  z-index: 1;
  background-color: ${colors.base.alpha};
`;

const MealAssignmentsTableContainer = styled.div`
  height: calc(100% - 80px);
`;

const HeaderContainer = styled.div`
  display: flex;
  padding: 20px 0;
  justify-content: space-between;
`;

const Title = styled.div`
  font-size: 24px;
  line-height: 28px;
  align-self: center;
  text-align: left;
  color: ${colors.shades.secondary8};
`;

export {
  MealAssignmentsContainer,
  MealAssignmentsTableContainer,
  HeaderContainer,
  Title,
};
