import styled, { css } from 'styled-components';
import { Checkbox } from '@mui/material';

const Container = styled.div`
  display: flex;
  width: 100%;
  min-height: 540px;
  min-width: 920px;
  height: 70vh;
`;

const DataGridContainer = styled.div`
  flex-basis: 100%;
  .MuiDataGrid-row {
    align-items: center;
    .MuiDataGrid-cell {
      .MuiBadge-badge{
        white-space: nowrap;
      }
    }
  } 
`;

const StyledList = styled.ul`
  width: 100%;
  height: 100%;
  line-height: 18px;
  margin-block: unset;
  margin-inline: unset;
  padding-inline: unset;
  padding: 8px 20px;
  overflow-y: auto;
`;

const commonCellStyles = css`
  display: flex;
  height: 100%;
  width: 100%;
  padding: 8px;
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: break-word;
  line-height: 18px;
  text-transform: capitalize;
`;

const CellContainer = styled.div`
  ${commonCellStyles};
`;

const StyledImage = styled.img`
  width: 100%;
  height: auto;
  aspect-ratio: 1;
  object-fit: scale-down;
`;

const StyledCheckBox = styled(Checkbox)`
  padding: 5px;
`;

const ChipContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  padding: 4px;
`;

export {
  Container,
  DataGridContainer,
  StyledList,
  StyledImage,
  CellContainer,
  StyledCheckBox,
  ChipContainer,
};
