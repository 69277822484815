import styled, { css } from 'styled-components';
import { Typography } from '@mui/material';

import colors from '../../../styles/colors';

/*
Usage:
  <SectionContainer>
    <SectionHeaderContainer>
      <SectionTitle>{texts.title}</SectionTitle>
    </SectionHeaderContainer>
    <SectionCompartment>
      ...
    </SectionCompartment>
    <SectionCompartment>
      ...
    </SectionCompartment>
    <SectionFooterContainer>
      ...
    </SectionFooterContainer>
  </SectionContainer>
*/

const barStyles = css`
  display: flex;
  flex-direction: row;
  padding: 10px;
  background-color: ${colors.shades.secondary5};
`;

const SectionContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid ${colors.shades.secondary7};
  border-radius: 5px;
`;

const SectionHeaderContainer = styled.div`
  ${barStyles};
  border-radius: 5px 5px 0px 0px;
  border-bottom: 1px solid ${colors.shades.secondary7};
  align-items: center;
  gap: 5px;
`;

const SectionTitle = styled(Typography)`
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  color: ${colors.shades.secondary3};
  letter-spacing: 0.1em;
`;

const SectionCompartment = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-top: 1px solid ${colors.shades.secondary7};
  border-bottom: 1px solid ${colors.shades.secondary7};
`;

const SectionFooterContainer = styled.div`
  ${barStyles};
  border-radius: 0px 0px 5px 5px;
  column-gap: 10px;
  border-top: 1px solid ${colors.shades.secondary7};
  padding: 10px;
`;

export {
  SectionContainer,
  SectionHeaderContainer,
  SectionTitle,
  SectionCompartment,
  SectionFooterContainer,
};
