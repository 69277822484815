const WorkoutsManagerTab = {
  PROGRAMS: 'PROGRAMS',
  WORKOUTS: 'WORKOUTS',
  EXERCISES: 'EXERCISES',
  TAGS: 'TAGS',
};

const workoutsManagerPagesConfig = {
  [WorkoutsManagerTab.PROGRAMS]: {
    id: WorkoutsManagerTab.PROGRAMS,
    path: 'programs',
  },
  [WorkoutsManagerTab.WORKOUTS]: {
    id: WorkoutsManagerTab.WORKOUTS,
    path: 'workouts',
  },
  [WorkoutsManagerTab.EXERCISES]: {
    id: WorkoutsManagerTab.EXERCISES,
    path: 'exercises',
  },
  [WorkoutsManagerTab.TAGS]: {
    id: WorkoutsManagerTab.TAGS,
    path: 'tags',
  },
};

const getTabPath = (tab) => workoutsManagerPagesConfig[tab]?.path || '';

export {
  WorkoutsManagerTab,
  workoutsManagerPagesConfig,
  getTabPath,
};
