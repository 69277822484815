import styled from 'styled-components';
import { Typography } from '@mui/material';

import colors from '../../../../../../../styles/colors';

const ParentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 0px;
`;

const StyledTypography = styled(Typography)`
  font-weight: bold;
  font-size: 14px;
  padding: 10px;
  border-bottom: 1px solid ${colors.base.secondary3};
`;

export {
  StyledTypography,
  ParentContainer,
};
