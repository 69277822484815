import React, {
  useState,
  useContext,
  useMemo,
  useEffect,
} from 'react';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';

import InternalAssignmentsContext,
{
  withInternalAssignmentsContextProvider,
  withInternalAssignmentsContextReady,
} from '../../../../../context/InternalAssignmentsContext';
import FirebaseContext from '../../../../../../context/FirebaseContext';
import { DateFormat } from '../../../../../../utils/date';
import useComponentMounted from '../../../../../../hooks/useComponentMounted';
import { getTimeSpentData, RemoteFunctions } from '../../../../../../utils/analytics';
import { formatDuration, TimeDurationFormat } from '../../../../../../utils/time';
import LoadingOverlay from '../../../../../components/LoadingOverlay';
import CoachActiveUsersAnalytics from '../../../../../Model/analytics/CoachActiveUsersAnalytics';
import AnalyticsGroup from '../../../components/AnalyticsGroup';
import ChartContainer from '../../../components/ChartContainer';
import BarChart from '../../../../../components/BarChart';
import AnalyticsTimeCard from '../AnalyticsTimeCard';
import texts from './texts.json';

const CoachView = ({
  selectedPeriod,
  coachId,
}) => {
  const [timeSpent, setTimeSpent] = useState([]);
  const [featureTimeSpent, setFeatureTimeSpent] = useState({});
  const [activeUserCount, setActiveUserCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const { firebase: { remote } } = useContext(FirebaseContext);
  const isComponentMountedRef = useComponentMounted();

  const totalTimeSpent = useMemo(() => timeSpent
    .reduce((acc, item) => acc + item.timeSpent, 0), [timeSpent]);
  const coachTimeSpent = useMemo(() => timeSpent
    .find((item) => item.userId === coachId)?.timeSpent || 0, [coachId, timeSpent]);

  const {
    internalAssignmentsCol: { docs: internalAssignments },
  } = useContext(InternalAssignmentsContext);

  const coachAssignments = useMemo(() => internalAssignments
    .filter((assignment) => assignment.coach === coachId), [coachId, internalAssignments]);

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);

      const from = moment().utc().subtract(selectedPeriod, 'days').format(DateFormat.DATE_FORMAT_YYYYMMDD);
      const to = moment().utc().format(DateFormat.DATE_FORMAT_YYYYMMDD);
      const getTimeSpentByCoach = async () => getTimeSpentData(
        remote,
        RemoteFunctions.GET_TIME_SPENT_BY_COACH,
        {
          from,
          to,
          coachId,
        },
      );
      const getCoachFeatureTimeSpent = async () => getTimeSpentData(
        remote,
        RemoteFunctions.GET_FEATURE_TIME_SPENT,
        {
          from,
          to,
          userId: coachId,
        },
      );
      const getS2FeatureTimeSpent = async () => getTimeSpentData(
        remote,
        RemoteFunctions.GET_FEATURE_TIME_SPENT,
        {
          from,
          to,
          coachId,
          onlyS2Time: true,
        },
      );

      const timeSpentData = await getTimeSpentByCoach();
      const featureTimeSpentDataCoach = await getCoachFeatureTimeSpent();
      const featureTimeSpentDataS2 = await getS2FeatureTimeSpent();

      if (isComponentMountedRef.current) {
        setTimeSpent(timeSpentData);
        setFeatureTimeSpent({
          coach: featureTimeSpentDataCoach,
          s2: featureTimeSpentDataS2,
        });
        setIsLoading(false);
      }
    };

    loadData();
  }, [coachId, isComponentMountedRef, remote, selectedPeriod]);

  useEffect(() => {
    const loadActiveUserCount = async () => {
      // use yesterday's date as we don't have data for today
      const dateTxt = moment().subtract(1, 'days').format(DateFormat.DEFAULT_DATE_FORMAT);
      const coachActiveUsersAnalytics = new CoachActiveUsersAnalytics(coachId, dateTxt);
      await coachActiveUsersAnalytics.fetch();
      if (isComponentMountedRef.current) {
        setActiveUserCount(coachActiveUsersAnalytics.activeUsers);
      }
    };
    loadActiveUserCount();
  }, [coachId, isComponentMountedRef]);

  const timeSpentPerClient = useMemo(() => (
    formatDuration(
      activeUserCount ? totalTimeSpent / activeUserCount : 0,
      TimeDurationFormat.LONG_FORMAT,
    )), [activeUserCount, totalTimeSpent]);

  return (
    <>
      <AnalyticsGroup>
        <AnalyticsTimeCard
          subTitle={texts.cardHeaders.totalTimeSpent}
          title={formatDuration(totalTimeSpent, TimeDurationFormat.LONG_FORMAT)}
        />
        <AnalyticsTimeCard
          title={formatDuration(coachTimeSpent, TimeDurationFormat.LONG_FORMAT)}
          subTitle={texts.cardHeaders.timeSpentByCoach}
        />
        <AnalyticsTimeCard
          title={formatDuration(totalTimeSpent - coachTimeSpent, TimeDurationFormat.LONG_FORMAT)}
          subTitle={texts.cardHeaders.timeSpentByS2}
        />
        <AnalyticsTimeCard
          title={activeUserCount}
          subTitle={texts.cardHeaders.activeClients}
        />
        <AnalyticsTimeCard
          title={timeSpentPerClient}
          subTitle={texts.cardHeaders.timeSpentPerClient}
        />
      </AnalyticsGroup>
      <AnalyticsGroup>
        <ChartContainer title={texts.coachTimeSpent}>
          <BarChart
            data={featureTimeSpent?.coach?.map((item) => ({
              name: item.feature,
              'Time Spent': item.timeSpent,
            })) || []}
            keys={['Time Spent']}
            toolTipFormatter={(value) => formatDuration(value, TimeDurationFormat.LONG_FORMAT)}
          />
        </ChartContainer>
        <ChartContainer title={texts.s2TimeSpent}>
          <BarChart
            data={featureTimeSpent?.s2?.map((item) => ({
              name: item.feature,
              'Time Spent': item.timeSpent,
            })) || []}
            keys={['Time Spent']}
            toolTipFormatter={(value) => formatDuration(value, TimeDurationFormat.LONG_FORMAT)}
          />
        </ChartContainer>
      </AnalyticsGroup>
      <AnalyticsGroup>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell variant="head">{texts.tableHeaders.name}</TableCell>
              <TableCell variant="head">{texts.tableHeaders.role}</TableCell>
              <TableCell variant="head">{texts.tableHeaders.timeSpent}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {coachAssignments.map((assignment) => (
              <TableRow key={assignment.id}>
                <TableCell>{assignment.userName}</TableCell>
                <TableCell>{assignment.role}</TableCell>
                <TableCell>
                  {
                    formatDuration(
                      timeSpent.find((item) => item.userId === assignment.userId)?.timeSpent || 0,
                      TimeDurationFormat.LONG_FORMAT,
                    )
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </AnalyticsGroup>
      <LoadingOverlay isLoading={isLoading} />
    </>
  );
};

CoachView.propTypes = {
  coachId: PropTypes.string.isRequired,
  selectedPeriod: PropTypes.number.isRequired,
};

export default compose(
  withInternalAssignmentsContextProvider,
  withInternalAssignmentsContextReady,
  observer,
)(CoachView);
