import styled, { css } from 'styled-components';

import { ReactComponent as VideoIcon } from '../../../../../../assets/icons/video-review.svg';
import { ReactComponent as RevertIcon } from '../../../../../../assets/icons/v2/clock-rewind.svg';
import { ReactComponent as RemoveIcon } from '../../../../../../assets/icons/v2/trash.svg';
import Button from '../../../../../../components/Button';
import FileSelectButton from '../../../../../../components/FileSelectButton';

import colors from '../../../../../../styles/colors';

const buttonStyles = css`
  height: 30px;
  width: fit-content;
  border: 1px solid ${colors.base.secondary3};
  margin: 15px 0;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: bold;
  text-transform: none;
  cursor: pointer;
  background-color: ${colors.base.secondary3};
  box-shadow: none;
  border-radius: 50px;

  &:hover {
    background-color: ${colors.shades.secondary6};
    box-shadow: none;
  }
`;

const iconStyles = css`
  margin-right: 5px;
  width: 20px;
  height: 20px;
`;

const videoFrameStyles = css`
  object-fit: scale-down;
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 5px;
  vertical-align: top;

  &[alt]:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background-color: ${colors.shades.secondary5};
    content: '';
  }
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  position: relative;
`;

const IconButtonContainer = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  align-items: center;
  justify-content: center;
`;

const StyledFileSelectButton = styled(FileSelectButton)`
  ${buttonStyles};
`;

const ActionButton = styled(Button)`
  ${buttonStyles};
`;

const StyledVideoIcon = styled(VideoIcon)`
  ${iconStyles};
`;

const StyledRemoveIcon = styled(RemoveIcon)`
  ${iconStyles};
`;

const StyledRevertIcon = styled(RevertIcon)`
  ${iconStyles};
`;

const VideoContainer = styled.div`
  background-color: ${colors.shades.secondary5};
  border: 1px solid ${colors.shades.secondary7};
  border-radius: 5px;
  width: 100%;
  aspect-ratio: 1/1;
  position: relative;
`;

const RemoteExerciseVideo = styled.iframe`
  ${videoFrameStyles};
`;

const LocalExerciseVideo = styled.video`
  ${videoFrameStyles};
`;

export {
  Container,
  IconButtonContainer,
  VideoContainer,
  StyledVideoIcon,
  StyledRemoveIcon,
  StyledRevertIcon,
  ButtonsContainer,
  StyledFileSelectButton,
  ActionButton,
  RemoteExerciseVideo,
  LocalExerciseVideo,
};
