import React, {
  useState,
  useEffect,
} from 'react';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import { forEach, map } from 'p-iteration';
import PropTypes from 'prop-types';

import useComponentMounted from '../../../../../hooks/useComponentMounted';
import CoachLeadAnalytics from '../../../../Model/analytics/CoachLeadAnalytics';
import CoachSubscriptionsAnalytics from '../../../../Model/analytics/CoachSubscriptionAnalytics';
import CoachRevenueAnalytics from '../../../../Model/analytics/CoachRevenueAnalytics';
import CoachAvgClientLifespanAnalytics from '../../../../Model/analytics/CoachAvgClientLifespanAnalytics';
import { AnalyticType } from '../../../../../utils/analytics';
import LoadingOverlay from '../../../../components/LoadingOverlay';
import AnalyticsGroup from '../AnalyticsGroup';
import SubscriptionAnalyticsCard from '../AnalyticCards/SubscriptionAnalyticsCard';
import LeadAnalyticsCard from '../AnalyticCards/LeadAnalyticsCard';
import RevenueAnalyticsCard from '../AnalyticCards/RevenueAnalyticsCard';
import TakeHomeAnalyticsCard from '../AnalyticCards/TakeHomeAnalyticsCard';
import CancellationAnalyticsCard from '../AnalyticCards/CancellationAnalyticsCard';
import ConversionAnalyticsCard from '../AnalyticCards/ConversionAnalyticsCard';
import CSVDownloadButton from '../CSVDownloadButton';
import { Container } from './styles';

const AnalyticTypeDocs = {
  [AnalyticType.LEADS]: CoachLeadAnalytics,
  [AnalyticType.SUBSCRIPTIONS]: CoachSubscriptionsAnalytics,
  [AnalyticType.REVENUE]: CoachRevenueAnalytics,
  [AnalyticType.USER_LIFESPAN]: CoachAvgClientLifespanAnalytics,
};

const MonthlyAnalytics = ({
  selectedCoaches,
  selectedMonth,
  selectedGroup,
  selectedUser,
}) => {
  const [activeMonthAnalytics, setActiveMonthAnalytics] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const isComponentMountedRef = useComponentMounted();

  useEffect(() => {
    const loadSelectedMonthAnalytics = async () => {
      setIsLoading(true);
      const monthAnalytics = await map(selectedCoaches, async (coach) => {
        const coachAnalytics = new Map();
        await forEach(Object.keys(AnalyticTypeDocs), async (analyticType) => {
          const analyticDoc = new AnalyticTypeDocs[analyticType](
            AnalyticTypeDocs[analyticType].getCollectionPath(coach.id),
            selectedMonth.id,
          );
          await analyticDoc.fetch();
          coachAnalytics.set(analyticType, analyticDoc);
        });

        const currencies = new Set();
        coachAnalytics.get(AnalyticType.REVENUE).revenues.forEach((revenueItem) => {
          if (!currencies.has(revenueItem.currency)) {
            currencies.add(revenueItem.currency);
          }
        });
        return {
          coachAnalytics,
          coach,
          currencies: Array.from(currencies),
        };
      });
      if (isComponentMountedRef.current) {
        setActiveMonthAnalytics(monthAnalytics);
        setIsLoading(false);
      }
    };
    if (selectedMonth) {
      loadSelectedMonthAnalytics();
    }
  }, [isComponentMountedRef, selectedCoaches, selectedMonth]);

  return (
    <>
      {!!selectedMonth && !!activeMonthAnalytics && (
        <CSVDownloadButton
          analyticsData={activeMonthAnalytics}
          filters={{
            month: selectedMonth.id,
            group: selectedGroup,
            ...(selectedUser && { user: selectedUser.label }),
          }}
        />
      )}
      {!!selectedMonth && activeMonthAnalytics.map(({ coachAnalytics, coach, currencies }) => (
        <AnalyticsGroup title={coach.name} key={coach.id}>
          <Container container spacing={2}>
            <LeadAnalyticsCard leadAnalytics={coachAnalytics.get(AnalyticType.LEADS)} />
            {/* if coach has only one currency for selected month, show all analytics in one place */}
            {currencies.length === 1 && (
              <>
                <SubscriptionAnalyticsCard
                  subscriptionAnalytics={coachAnalytics.get(AnalyticType.SUBSCRIPTIONS)?.subscriptions[0]}
                />
                <RevenueAnalyticsCard revenueAnalytics={coachAnalytics.get(AnalyticType.REVENUE)?.revenues[0]} />
                <TakeHomeAnalyticsCard revenueAnalytics={coachAnalytics.get(AnalyticType.REVENUE)?.revenues[0]} />
              </>
            )}
            {/* if coach has more than one currency for the month, show total subscriptions with other analytics */}
            {currencies.length > 1 && (
              <SubscriptionAnalyticsCard subscriptionAnalytics={coachAnalytics.get(AnalyticType.SUBSCRIPTIONS)} />
            )}
            <CancellationAnalyticsCard
              subscriptionAnalytics={coachAnalytics.get(AnalyticType.SUBSCRIPTIONS)}
              leadAnalytics={coachAnalytics.get(AnalyticType.LEADS)}
            />
            <ConversionAnalyticsCard
              subscriptionAnalytics={coachAnalytics.get(AnalyticType.SUBSCRIPTIONS)}
              leadAnalytics={coachAnalytics.get(AnalyticType.LEADS)}
              lifespanAnalytics={coachAnalytics.get(AnalyticType.USER_LIFESPAN)}
            />
          </Container>
          {/* if coach has more than one currency for the month, show analytics for each currency */}
          {currencies.length > 1 && currencies?.map((currency) => (
            <AnalyticsGroup title={currency.toUpperCase()} key={currency}>
              <SubscriptionAnalyticsCard
                subscriptionAnalytics={coachAnalytics.get(AnalyticType.SUBSCRIPTIONS)?.subscriptions?.find(
                  (sub) => sub.currency === currency,
                )}
              />
              <RevenueAnalyticsCard
                revenueAnalytics={coachAnalytics.get(AnalyticType.REVENUE)?.revenues?.find(
                  (rev) => rev.currency === currency,
                )}
              />
              <TakeHomeAnalyticsCard
                revenueAnalytics={coachAnalytics.get(AnalyticType.REVENUE)?.revenues?.find(
                  (rev) => rev.currency === currency,
                )}
              />
            </AnalyticsGroup>
          ))}
        </AnalyticsGroup>
      ))}
      <LoadingOverlay isLoading={isLoading} />
    </>
  );
};

MonthlyAnalytics.propTypes = {
  selectedCoaches: PropTypes.array.isRequired,
  selectedMonth: PropTypes.object,
  selectedGroup: PropTypes.string,
  selectedUser: PropTypes.string,
};

MonthlyAnalytics.defaultProps = {
  selectedMonth: null,
  selectedGroup: '',
  selectedUser: '',
};

export default compose(
  observer,
)(MonthlyAnalytics);
