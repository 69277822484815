import styled from 'styled-components';

import RadioButtonGroup from '../../RadioButtonGroup';

const StyledRadioButtonGroup = styled(RadioButtonGroup)`
  margin: 10px 45px;
  display: flex;
  width: auto;
  .MuiFormControlLabel-root {
    margin-top: 10px;
  }
`;

export {
  StyledRadioButtonGroup,
};
