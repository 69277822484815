import { Collection } from 'firestorter';

import BaseDocument from '../../Model/BaseDocument';
import Collections from '../../utils/collections';

class MealPlanView extends BaseDocument {
  constructor(id, opts) {
    super(`${Collections.MEAL_PLAN_VIEW}/${id}`, opts);
  }

  get name() {
    return this.data.mealPlanName;
  }

  get mealTimesSummary() {
    return this.data.mealTimesSummary || [];
  }

  get macroAverages() {
    return this.data.macroAverages;
  }

  get allergenTags() {
    return this.data.allergenTags || [];
  }

  get isArchived() {
    return !!this.data.isArchived;
  }

  get lastUpdated() {
    return this.data.lastUpdated;
  }

  get numberOfAssignments() {
    return this.data.numberOfAssignments;
  }

  get numberOfMealTimes() {
    return this.data.numberOfMealTimes;
  }

  get meals() {
    return this.data.numberOfMeals;
  }

  get type() {
    return this.data.type;
  }

  static async getMealPlanView(mealPlanViewId) {
    const mealPlanViewDoc = new MealPlanView(mealPlanViewId);
    await mealPlanViewDoc.init();
    return mealPlanViewDoc.exists ? mealPlanViewDoc : null;
  }

  static async getMealPlanViewsByCoach(coach, fetchArchivedPlans) {
    const mealPlanViewsCollection = new Collection(Collections.MEAL_PLAN_VIEW, {
      createDocument: ({ id }, opts) => new MealPlanView(id, { ...opts, disableObserverCountRef: true }),
      query: (ref) => {
        let query = ref.where('coach', '==', coach);
        if (!fetchArchivedPlans) {
          query = query.where('isArchived', '==', false);
        }
        return query;
      },
    });
    await mealPlanViewsCollection.fetch();
    return mealPlanViewsCollection;
  }
}

export default MealPlanView;
