import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import {
  Route,
  Redirect,
  useLocation,
} from 'react-router-dom';

import useSessionStore from '../../hooks/useSessionStore';

/**
 * A wrapper for <Route> that redirects to the login page if you're not yet authenticated, you're not an support-admin,
 * or the user id of the path doesn't corresponds to the already logged in user.
 */
const AdminSupportRoute = ({
  component: Component,
  children,
  ...rest
}) => {
  const { userId, isSupport } = useSessionStore();
  const location = useLocation();
  const { search, state } = location;

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isSupport && location.pathname.includes(userId)) {
          return Component ? <Component {...props} /> : children;
        }
        return (
          <Redirect to={{
            pathname: '/login',
            search,
            state,
          }}
          />
        );
      }}
    />
  );
};

AdminSupportRoute.propTypes = {
  component: PropTypes.elementType,
  children: PropTypes.node,
};

AdminSupportRoute.defaultProps = {
  children: null,
  component: null,
};

export default compose(
  observer,
)(AdminSupportRoute);
