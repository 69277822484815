import React from 'react';
import useLogEventOnMount from '../../hooks/useLogEventOnMount';

const withPageAnalytics = (pageName) => (Component) => (props) => {
  useLogEventOnMount(`${pageName}Shown`);
  return (
    <Component {...props} />
  );
};

const withAnalytics = (eventName) => (Component) => (props) => {
  useLogEventOnMount(eventName);
  return (
    <Component {...props} />
  );
};

export {
  withAnalytics,
  withPageAnalytics,
};
