const baseHabitList = [
  'drinkHabit',
  'walkHabit',
  'readHabit',
  'writeHabit',
];

const baseHabitsDefinition = {
  drinkHabit: {
    text: 'Drink 1 gallon of water',
    id: 'drinkHabit',
    active: true,
    isDefault: true,
  },
  walkHabit: {
    text: 'Walk 8k-10k steps',
    id: 'walkHabit',
    active: true,
    isDefault: true,
  },
  readHabit: {
    text: 'Read 5 pages of a book',
    id: 'readHabit',
    active: true,
    isDefault: true,
  },
  writeHabit: {
    text: 'Write Morning Journal',
    id: 'writeHabit',
    active: true,
    isDefault: true,
  },
};

export {
  baseHabitList,
  baseHabitsDefinition,
};
