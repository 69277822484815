import * as Sentry from '@sentry/browser';
import { arrOfObjectstoKeyedJSONObj } from '../../../utils/dataProcessing';

/**
 * Retrieves the conversion rates for a specified template and coach from big-query through a firebase function.
 * It processes the response to return an object where each key corresponds to a template version
 * and its associated data object.
 *
 * @param {Function} remote - The function to call the remote service.
 * @param {string} coachId - The coach id whose template conversion rates are being requested.
 * @param {string} templateName - The name of the template for which conversion rates are being fetched.
 * @returns {Promise<Object>} Resolves to an object mapping template versions to it's conversion data.
 *                            If the request fails or no data is available, the promise resolves to an empty object.
 */
const getTemplateConversionRates = async (remote, coachId, templateName) => {
  const response = await remote('getTemplateConversionRates', {
    coachId,
    templateName,
  }, {
    method: 'POST',
  });

  if (!response.ok) {
    Sentry.captureException(
      new Error(`Error in retrieving conversion rates for template ${templateName} of coach ${coachId}`),
    );
    return {};
  }

  const { data: templateConversionRatesArr = [] } = await response.json();
  const templateConversionRates = arrOfObjectstoKeyedJSONObj(templateConversionRatesArr, 'templateVersion');

  return templateConversionRates;
};

export {
  getTemplateConversionRates,
};
