import {
  Typography,
  TextField,
} from '@mui/material';
import styled from 'styled-components';

import { ReactComponent as SaveIcon } from '../../../../../../assets/icons/v2/save.svg';
import colors from '../../../../../../styles/colors';

const Text = styled(Typography)`
  margin-bottom: 5px;
`;

const SettingContainer = styled.div`
  margin: 15px 0;
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background-color: ${colors.base.alpha};
`;

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  margin: 20px 0;
`;

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Title = styled(Text)`
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 5px;
`;

const SubTitle = styled(Text)`
  font-size: 13px;
  margin-bottom: 0;
  font-weight: 300;
  color: ${colors.shades.gamma7};
`;

const SettingsActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0;
`;

const ActionLabel = styled(Text)`
  font-size: 13px;
  margin-bottom: 10px;
  font-weight: 400;
  color: ${colors.shades.primary1};
`;

const StyledTextField = styled(TextField)`
  background: ${colors.base.alpha};
`;

const StyledSaveIcon = styled(SaveIcon)`
  margin-right: 5px;
`;

export {
  SettingContainer,
  HeaderContainer,
  TitleContainer,
  Title,
  SubTitle,
  SettingsActionContainer,
  ActionLabel,
  StyledTextField,
  StyledSaveIcon,
};
