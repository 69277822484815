import styled, { css } from 'styled-components';

import colors from '../../../../../styles/colors';
import { fontFamily } from '../../../../../styles/text';

const baseContainerStyles = css`
  .str-chat__message {
    font-family: ${fontFamily.sansSerif} !important;
  }

  .str-chat__message-text-inner {
    background-color: ${colors.shades.primary1} !important;
    border-radius: 5px !important;
  }

  .str-chat__li--bottom .str-chat__message-text-inner,
  .str-chat__li--single .str-chat__message-text-inner {
    :before {
      content: "";
      position: absolute;
      bottom: 1px;
      left: -6px;
      transform: rotate(135deg);
      border-style: solid;
      border-width: 7px 7px 7px 0;
      border-color: transparent ${colors.shades.primary1} transparent transparent;
    }
  }

  .str-chat__message--me
  .str-chat__message-text-inner {
    background-color: ${colors.shades.secondary6} !important;
    color: ${colors.base.beta};
  }

  .str-chat__li--bottom
  .str-chat__message--me
  .str-chat__message-text-inner,
  .str-chat__li--single
  .str-chat__message--me
  .str-chat__message-text-inner {
    :before {
      content: "";
      position: absolute;
      bottom: 1px;
      left: auto;
      right: -6px;
      transform: rotate(45deg);
      border-style: solid;
      border-width: 7px 7px 7px 0;
      border-color: transparent ${colors.shades.secondary6} transparent transparent;
    }
  }
`;

const ChatFeedContainer = styled.div`
  height: 100%;
  width: 70%;
  ${baseContainerStyles};
`;

const QuickChatFeedContainer = styled.div`
  .str-chat-channel .str-chat__container {
    height: 70vh;
    display: flex;
  }

  ${baseContainerStyles};
`;

export {
  ChatFeedContainer,
  QuickChatFeedContainer,
};
