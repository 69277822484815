import styled from 'styled-components';
import { Typography } from '@mui/material';

import colors from '../../../styles/colors';

/*
Usage:
  <CardContainer>
    <Card>
      <HeaderContainer>
        <TitleContainer>
          <Title>{texts.title}</Title>
          <SubTitle>{texts.description}</SubTitle>
        </TitleContainer>
      </HeaderContainer>
      <Container>
        Content here
      </Container>
    </Card>
    <Card>
      ...
    </Card>
  </CardContainer>
*/

const Text = styled(Typography)`
  margin-bottom: 5px;
`;

const CardsContainer = styled.div`
  padding: 16px;
  height: 100%;
  width: ${(props) => (props.$fullWidth ? '100%' : '60%')};
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  background-color: ${colors.base.gamma};
`;

const Card = styled.div`
  margin: 15px 0;
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background-color: ${colors.base.alpha};
`;

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  margin: 20px 0;
`;

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 5px;
`;

const SubTitle = styled(Text)`
  font-size: 13px !important;
  margin-bottom: 0;
  font-weight: 300;
  color: ${colors.shades.gamma7};
`;

const Description = styled(Text)`
  font-size: 13px !important;
  margin-bottom: 0;
  font-weight: 500;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  width: 100%;
  overflow-x: auto;
`;

export {
  CardsContainer,
  Card,
  Container,
  Title,
  Description,
  TitleContainer,
  HeaderContainer,
  SubTitle,
};
