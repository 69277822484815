import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import {
  Typography,
  IconButton,
} from '@mui/material';

import {
  Menu as MenuIcon,
} from '@mui/icons-material';

import useSessionStore from '../../../../hooks/useSessionStore';
import NavigationContext, { NavigationRouteType } from '../../../context/NavigationContext';
import LayoutContext from '../../context/LayoutContext';

import Account from '../Account';
import ViewAs from '../ViewAs';
import CoachReminder from '../CoachReminder';

import {
  StyledAppBar,
  StyledToolbar,
} from './styles';

const Header = ({
  showMenuIcon,
}) => {
  const {
    title,
    toggleDrawer,
    isDrawerOpen,
  } = useContext(LayoutContext);
  const { routeType } = useContext(NavigationContext);

  const { isAdmin } = useSessionStore();

  return (
    <StyledAppBar
      position="fixed"
      open={isDrawerOpen}
    >
      <StyledToolbar>
        {showMenuIcon && (
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer}
            sx={{
              mr: 2,
              ...(isDrawerOpen && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
        )}
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          {title}
        </Typography>
        {routeType === NavigationRouteType.COACH && <CoachReminder />}
        {isAdmin && <ViewAs />}
        <Account />
      </StyledToolbar>
    </StyledAppBar>
  );
};

Header.propTypes = {
  showMenuIcon: PropTypes.bool,
};

Header.defaultProps = {
  showMenuIcon: true,
};

export default Header;
