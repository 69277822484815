import {
  DialogTitle,
  TextField,
  DialogActions,
} from '@mui/material';
import styled from 'styled-components';

import { sansSerifHeadlineFontStyles } from '../../../../../styles/text';

const Title = styled(DialogTitle)`
  ${sansSerifHeadlineFontStyles}
  font-size: 24px;
  font-weight: bold;
`;

const StyledNotesInput = styled(TextField)`
  min-width: 350px;
  width: 100%;
  margin-top: 5px;
`;

const StyledDialogActions = styled(DialogActions)`
  height: 40px;

  button {
    height: 35px;
    width: 100px;
  }
`;

export {
  Title,
  StyledNotesInput,
  StyledDialogActions,
};
