import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import useLogger from '../../../../../hooks/useLogger';

import { StyledButton } from './styles';

const ApplePayButton = ({
  paymentRequest,
  logoOnly,
  className,
}) => {
  const { logEvent } = useLogger();
  const onClick = useCallback(() => {
    if (paymentRequest) {
      paymentRequest.show();
      logEvent('applePayShown');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    paymentRequest,
  ]);

  if (logoOnly) {
    return (
      <StyledButton className={className} onClick={onClick}>
        <span className="logo" />
      </StyledButton>
    );
  }

  return (
    <StyledButton onClick={onClick}>
      <span className="text">Checkout with</span>
      <span className="logo" />
    </StyledButton>
  );
};

ApplePayButton.propTypes = {
  paymentRequest: PropTypes.object,
  logoOnly: PropTypes.bool,
  className: PropTypes.string,
};

ApplePayButton.defaultProps = {
  paymentRequest: null,
  logoOnly: false,
  className: '',
};

export default ApplePayButton;
