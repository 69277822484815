import styled, { css } from 'styled-components';
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from '@mui/material';

import { ReactComponent as FilterIcon } from '../../../assets/icons/v2/filter.svg';
import colors from '../../../styles/colors';

const menuStyles = {
  '& .MuiMenu-list': {
    padding: 0,
  },
};

const commonStyles = css`
  box-sizing: border-box;
  background: ${colors.shades.primary7};
  align-items: center;
  column-gap: 6px;
  font-size: 12px;
  line-height:  14px;
`;

const StyledFormControl = styled(FormControl)`
  width: 265px;
`;

const StyledSelect = styled(Select)`
  
  & .MuiSelect-select {
    padding: 8px 14px 9px 14px;
    ${commonStyles};
    height: 40px;
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: ${colors.shades.primary3};
  }
  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${colors.shades.primary4};
  }
  &:hover .MuiOutlinedInput-notchedOutline {
    border-color: ${colors.shades.primary2};
  }
`;

const StyledMenuItem = styled(MenuItem)`
  ${commonStyles};
`;

const StyledInputLabel = styled(InputLabel)`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0;
  color: ${colors.shades.secondary8};
  transform: translate(15px, 11px);
  
  svg {
    margin-right: 7px;
  }

  &.Mui-focused {
    color: ${colors.shades.secondary8};
  }
`;

const StyledFilterIcon = styled(FilterIcon)`
  flex-shrink: 0
`;

export {
  menuStyles,
  StyledFormControl,
  StyledSelect,
  StyledMenuItem,
  StyledInputLabel,
  StyledFilterIcon,
};
