import { Collection } from 'firestorter';
import { firestorePaths } from '../utils/paths';
import BaseDocument from './BaseDocument';

class InternalAssignment extends BaseDocument {
  get userId() {
    return this.data.userId;
  }

  get userName() {
    return this.data.userName;
  }

  get coach() {
    return this.data.coach;
  }

  get role() {
    return this.data.role;
  }

  static async getAllCurrentAssignments(userId = null, role = null) {
    const internalAssignmentCollection = new Collection(firestorePaths.INTERNAL_ASSIGNMENT,
      {
        query: (ref) => {
          let query = ref.where('current', '==', true);
          if (userId) {
            query = query.where('userId', '==', userId);
          }
          if (role) {
            query = query.where('role', '==', role);
          }
          return query;
        },
        createDocument: (source, options) => new InternalAssignment(source, options),
      });
    await InternalAssignment.initCollection(internalAssignmentCollection);
    return internalAssignmentCollection;
  }

  static async getAllCurrentAssignmentsByUserId(userId) {
    return InternalAssignment.getAllCurrentAssignments(userId);
  }

  static async getAllCurrentAssignmentsByRole(role) {
    return InternalAssignment.getAllCurrentAssignments(null, role);
  }
}

export default InternalAssignment;
