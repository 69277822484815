import { Typography, Chip } from '@mui/material';
import styled from 'styled-components';

import Card from '../../../components/Card';
import Slider from '../../../components/Slider';
import colors from '../../../styles/colors';

const ImageSliderContainer = styled(Card)`
  width: 100%;
  max-width: 500px;
  aspect-ratio: 0.75;
  max-height: 100%;
  padding: 0;
  box-sizing: border-box;
  border-radius: 5px;
  margin: 4px 8px;
`;

const ImageCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledSlider = styled(Slider)`
  .slick-dots {
    bottom: 0;
    padding: 15px 0;
    li button:before {
      font-size: 12px;
    }
  }
  .slick-slide > div {
    padding: 0;
  }
  .slick-slider {
    height: 100%;
  }
`;

const StyledImage = styled.img`
  max-width: 100%;
  height: ${({ $height }) => ($height ? `${$height}px` : '100%')};
  width: 100%;
  object-fit: contain;
`;

const DateText = styled(Typography)`
  text-align: center;
  font-size: 12px;
`;

const Title = styled.div`
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  border-bottom: 1px solid ${colors.shades.gamma3};
  margin-bottom: 5px;
`;

const StyledChip = styled(Chip)`
  position: absolute;
  bottom: 20px;
  background-color: ${colors.addAlpha(colors.base.beta, 0.5)};
  color: ${colors.base.alpha};
  font-size: 10px;
  font-weight: 500;
  height: 24px;
  margin-left: 10px;
`;

export {
  ImageSliderContainer,
  ImageCard,
  StyledSlider,
  StyledImage,
  DateText,
  Title,
  StyledChip,
};
