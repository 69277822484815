import styled, { css } from 'styled-components';
import { ReactComponent as ArchiveIcon } from '../../assets/icon/archive-icon.svg';
import { ReactComponent as UnarchiveIcon } from '../../assets/icon/unarchive-icon.svg';
import { ReactComponent as HideIcon } from '../../assets/icon/hide-icon.svg';
import { ReactComponent as ShowIcon } from '../../assets/icon/show-icon.svg';
import colors from '../../../../../styles/colors';

const Container = styled.div`
  position: relative;
  height: 56px;
  margin: 8px 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 8px;
  background: ${colors.shades.gamma1};
  transition: background 0.1s ease-in-out;
  ${({ active }) => {
    if (active) {
      return css`
        box-shadow: 4px 4px 4px 1px rgb(0 0 0 / 20%), 0 3px 5px 3.05px rgb(0 0 0 / 19%);
      `;
    }
    return null;
  }}
`;

const AdminContainer = styled(Container)`
  background: ${colors.base.primary};
`;

const UnreadCountBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 50px;
  top: -10px;
  right: -4px;
  font-size: 10px;
  font-weight: 600;
  color: ${colors.base.alpha};
  padding: 6px;
  border-radius: 10%;
  background: rgb(0 119 204);
  box-sizing: border-box;
  box-shadow: 0 4px 2px 0 rgb(0 0 0 / 20%), 0 6px 5px -41.95px rgb(0 0 0 / 19%);
`;

const iconStyle = css`
  height: auto;
  width: 20px;
  padding-right: 5px;
`;

const StyledArchiveIcon = styled(ArchiveIcon)`
  ${iconStyle}
`;

const StyledUnarchiveIcon = styled(UnarchiveIcon)`
  ${iconStyle}
`;

const StyledHideIcon = styled(HideIcon)`
  ${iconStyle}
`;

const StyledShowIcon = styled(ShowIcon)`
  ${iconStyle}
`;

export {
  Container,
  AdminContainer,
  UnreadCountBadge,
  StyledArchiveIcon,
  StyledUnarchiveIcon,
  StyledHideIcon,
  StyledShowIcon,
};
