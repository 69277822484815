import React from 'react';

const initialValues = {
  isReady: false,
  coachPrograms: [],
  showArchivedCoachPrograms: false,
  coachProgramsCollection: {},
  equipmentList: [],
};

const ManageProgramContext = React.createContext(initialValues);

export default ManageProgramContext;
export {
  initialValues,
};
