import CoachAnalytics from './CoachAnalytics';
import texts from './texts.json';
import {
  AppAnalyticsIcon,
  CoachAnalyticsIcon,
  CompanyAnalyticsIcon,
  TimeTrackingIcon,
  MetabaseIcon,
} from './styles';
import AppAnalytics from './AppAnalytics';
import CompanyAnalytics from './CompanyAnalytics';
import TimeTrackingView from './TimeTrackingView';
import MetabaseDashboardsView from './MetabaseDashboardsView';

const analyticTabs = {
  COACH_ANALYTICS: 'COACH_ANALYTICS',
  APP_ANALYTICS: 'APP_ANALYTICS',
  COMPANY_ANALYTICS: 'COMPANY_ANALYTICS',
  TIME_TRACKING: 'TIME_TRACKING',
  METABASE_DASHBOARDS: 'METABASE_DASHBOARDS',
};

const getAnalyticTabsConfig = (isSupport) => ({
  [analyticTabs.COACH_ANALYTICS]: {
    label: texts.tabLabels[analyticTabs.COACH_ANALYTICS],
    icon: CoachAnalyticsIcon,
    component: CoachAnalytics,
  },
  [analyticTabs.APP_ANALYTICS]: {
    label: texts.tabLabels[analyticTabs.APP_ANALYTICS],
    icon: AppAnalyticsIcon,
    component: AppAnalytics,
  },
  [analyticTabs.COMPANY_ANALYTICS]: {
    label: texts.tabLabels[analyticTabs.COMPANY_ANALYTICS],
    icon: CompanyAnalyticsIcon,
    component: CompanyAnalytics,
  },
  [analyticTabs.METABASE_DASHBOARDS]: {
    label: texts.tabLabels[analyticTabs.METABASE_DASHBOARDS],
    icon: MetabaseIcon,
    component: MetabaseDashboardsView,
  },
  // show these analytics only for support
  ...(isSupport ? {
    [analyticTabs.TIME_TRACKING]: {
      label: texts.tabLabels[analyticTabs.TIME_TRACKING],
      icon: TimeTrackingIcon,
      component: TimeTrackingView,
    },
  } : {}),
});

export {
  analyticTabs,
  getAnalyticTabsConfig,
};
