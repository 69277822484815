const sanitizeForID = (formerString) => (
  formerString.replace(/[^\w]/g, '-')
);

const sanitizeForHandle = (formerString) => (
  formerString.replace(/[^a-z]/gi, '').toLowerCase()
);

const removeSpaces = (str) => str.toLowerCase().replaceAll(' ', '');

const capitalizeFirstLetter = (str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

export {
  sanitizeForID,
  sanitizeForHandle,
  removeSpaces,
  capitalizeFirstLetter,
};
