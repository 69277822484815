import Card from './Card';
import CardTitle from './CardTitle';
import CardDescription from './CardDescription';
import variants from './variants';

export default Card;
export {
  CardTitle,
  CardDescription,
  variants,
};
