import React, { useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import Modal from '../../../../../../../components/v2/Modal';
import InputLabel from '../../../../../../../components/v2/InputLabel';
import TextField from '../../../../../../../components/v2/TextField';
import FirebaseContext from '../../../../../../../context/FirebaseContext';
import Product from '../../../../../../../Model/Product';
import { Col, Row } from '../../../../../../../components/v2/Grid';
import PriceInput from '../../../../../../../components/v2/PriceInput';
import { CoachingActivity } from '../../../../../../../utils/log';
import useLogger from '../../../../../../../hooks/useLogger';
import ExternalCoachContext from '../../../../../../context/ExternalCoachContext';
import useToast from '../../../../../../hooks/useToast';
import LoadingOverlay from '../../../../../../components/LoadingOverlay';
import { DefaultPackage } from '../../../../../../utils/userPackage';
import { ContractMinimumValue, ContractProperties } from '../../../../../../utils/userContract';
import {
  StyledButton,
  StyledAddIcon,
  TermField,
  StyledCurrencySelector,
} from './styles';
import texts from './texts.json';

const NAME = 'name';

const AddPackageModal = ({
  open,
  onClose,
  product,
}) => {
  const { externalCoachDoc } = useContext(ExternalCoachContext);
  const [userPackage, setUserPackage] = useState({
    ...DefaultPackage,
    [ContractProperties.CURRENCY]: externalCoachDoc.defaultCurrency,
  });
  const [isSaving, setIsSaving] = useState(false);

  const { firebase: { remote } } = useContext(FirebaseContext);
  const { showToast } = useToast();
  const { logCoachingActivity } = useLogger();

  const isValidPackage = useMemo(() => userPackage[NAME]
    && userPackage[ContractProperties.CURRENCY]
    && userPackage[ContractProperties.INITIAL_PAYMENT] > 0
    && userPackage[ContractProperties.INITIAL_TERM] >= ContractMinimumValue.MIN_SUBSCRIPTION_MONTHS
    && userPackage[ContractProperties.MIN_SUBSCRIPTION_MONTHS] >= ContractMinimumValue.MIN_SUBSCRIPTION_MONTHS
    && userPackage[ContractProperties.MONTHLY_PAYMENT],
  [userPackage]);

  const onAddPackage = async () => {
    try {
      setIsSaving(true);
      const apiResponse = await remote('createPackage', {
        coachId: externalCoachDoc?.id,
        productId: product.id,
        ...userPackage,
      });
      const { error } = await apiResponse.json();
      if (error) {
        showToast(error, { error: true });
        setIsSaving(false);
        return;
      }
      logCoachingActivity(CoachingActivity.CREATED_PACKAGE);
      showToast(texts.success, { success: true });
      setIsSaving(false);
      onClose();
    } catch (error) {
      setIsSaving(false);
      showToast(error.message, { error: true });
    }
  };

  const onFieldChange = (field, value) => setUserPackage((currentPackage) => ({ ...currentPackage, [field]: value }));

  return (
    <Modal
      open={open}
      title={texts.title}
      onClose={onClose}
      actionButtons={(
        <StyledButton onClick={onAddPackage} disabled={!isValidPackage}>
          <StyledAddIcon />
          {texts.addPackage}
        </StyledButton>
      )}
    >
      <InputLabel>{texts.fieldNames.name}</InputLabel>
      <TextField
        onChange={(e) => onFieldChange(NAME, e.target.value)}
        value={userPackage[NAME]}
        error={!userPackage[NAME]}
        helperText={!userPackage[NAME]
          ? texts.errors.required : ''}
      />

      <InputLabel>{texts.fieldNames.currency}</InputLabel>
      <StyledCurrencySelector
        supportedCurrencies={externalCoachDoc.supportedCurrencies}
        value={userPackage[ContractProperties.CURRENCY]}
        onChange={(e) => onFieldChange(ContractProperties.CURRENCY, e.target.value)}
      />

      <Row spaceBetween fullWidth>
        <Col>
          <InputLabel>{texts.fieldNames.initialPayment}</InputLabel>
          <PriceInput
            value={userPackage[ContractProperties.INITIAL_PAYMENT]}
            currency={userPackage[ContractProperties.CURRENCY]}
            onChange={(e) => onFieldChange(ContractProperties.INITIAL_PAYMENT, e.target.value)}
          />
        </Col>
        <Col>
          <InputLabel>{texts.fieldNames.initialTerm}</InputLabel>
          <TermField
            onChange={(e) => onFieldChange(ContractProperties.INITIAL_TERM, parseInt(e.target.value, 10))}
            value={userPackage[ContractProperties.INITIAL_TERM]}
          />
        </Col>
      </Row>
      <InputLabel>{texts.fieldNames.minimumCommitment}</InputLabel>
      <TermField
        onChange={(e) => onFieldChange(ContractProperties.MIN_SUBSCRIPTION_MONTHS, parseInt(e.target.value, 10))}
        value={userPackage[ContractProperties.MIN_SUBSCRIPTION_MONTHS]}
      />

      <InputLabel>{texts.fieldNames.monthlyPayment}</InputLabel>
      <PriceInput
        value={userPackage[ContractProperties.MONTHLY_PAYMENT]}
        currency={userPackage[ContractProperties.CURRENCY]}
        onChange={(e) => onFieldChange(ContractProperties.MONTHLY_PAYMENT, e.target.value)}
      />
      <LoadingOverlay isLoading={isSaving} />
    </Modal>
  );
};

AddPackageModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  product: PropTypes.instanceOf(Product).isRequired,
};

export default AddPackageModal;
