import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import {
  Card,
  Container,
  HeaderContainer,
  Title,
  TitleContainer,
} from '../../../../../components/v2/Card';
import { StyledTextField, StyledTextFieldContainer } from '../../../../../components/Inputs';
import SaveButton from '../../../../../components/Button/ActionButtons/SaveButton';
import useComponentMounted from '../../../../../hooks/useComponentMounted';
import InputLabel from '../../../../../components/v2/InputLabel';
import { isInsideSalesAdmin } from '../../../../../utils/postHog';
import LoadingOverlay from '../../../../components/LoadingOverlay';
import useToast from '../../../../hooks/useToast';
import MsgBody, { MsgPlaceholder } from '../../../../components/Templates/components/TemplateView/MsgBody';
import EmailTemplate, { EmailTemplateName } from '../../../../../Model/EmailTemplate';
import RadioButtonGroup from '../../../../components/RadioButtonGroup';
import useSessionStore from '../../../../../hooks/useSessionStore';
import texts from './texts.json';

const TemplateOption = {
  USE_CUSTOM: 'USE_CUSTOM',
  USE_DEFAULT: 'USE_DEFAULT',
};

const LeadWelcomeEmailTemplate = ({ coachId }) => {
  const [emailSubject, setEmailSubject] = useState('');
  const [emailBody, setEmailBody] = useState('');
  const [isCustomTemplateEnabled, setIsCustomTemplateEnabled] = useState(false);
  const [defaultEmailTemplateDoc, setDefaultEmailTemplateDoc] = useState(null);
  const [coachEmailTemplateDoc, setCoachEmailTemplateDoc] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const { showToast } = useToast();
  const isComponentMountedRef = useComponentMounted();
  const { authUser } = useSessionStore();

  const isReadOnly = !isInsideSalesAdmin();

  const setDataFromTemplateDoc = useCallback((templateDoc) => {
    setEmailSubject(templateDoc.data.subject);
    setEmailBody(templateDoc.data.emailBody);
  }, []);

  useEffect(() => {
    const loadTemplates = async () => {
      setIsLoading(true);
      const coachEmailTemplateCol = await EmailTemplate.getTemplatesByCoach(coachId, EmailTemplateName.LEAD_WELCOME);
      const defaultEmailTemplateCol = await EmailTemplate.getDefaultTemplates(EmailTemplateName.LEAD_WELCOME);
      if (isComponentMountedRef.current) {
        if (coachEmailTemplateCol.hasDocs) {
          const { isEnabled } = coachEmailTemplateCol.docs[0].data;
          // if coach has a template and it's enabled, we use the coach template values
          if (isEnabled) {
            setDataFromTemplateDoc(coachEmailTemplateCol.docs[0]);
            setIsCustomTemplateEnabled(isEnabled);
          }
          setCoachEmailTemplateDoc(coachEmailTemplateCol.docs[0]);
        }
        if (defaultEmailTemplateCol.hasDocs) {
          if (!coachEmailTemplateCol.hasDocs || !coachEmailTemplateCol.docs[0].data.isEnabled) {
            // If coach don't have a template or it's disabled, we use the default template values
            setDataFromTemplateDoc(defaultEmailTemplateCol.docs[0]);
            setIsCustomTemplateEnabled(false);
          }
          setDefaultEmailTemplateDoc(defaultEmailTemplateCol.docs[0]);
        }
        setIsLoading(false);
      }
    };
    if (coachId) {
      loadTemplates();
    }
  }, [
    coachId,
    isComponentMountedRef,
    setDataFromTemplateDoc,
  ]);

  const onSave = useCallback(async () => {
    if (isCustomTemplateEnabled && (!emailSubject || !emailBody)) {
      showToast(!emailSubject ? texts.emailSubjectRequired : texts.emailBodyRequired, { error: true });
      return;
    }
    setIsLoading(true);
    const templateData = {
      subject: emailSubject,
      emailBody,
      isEnabled: isCustomTemplateEnabled,
    };
    if (isCustomTemplateEnabled) {
      if (!coachEmailTemplateDoc) {
        const coachTemplateDoc = await EmailTemplate.addNewTemplate({
          ...templateData,
          isDefault: false,
          createdAt: new Date(),
          coach: coachId,
          createdByUserName: authUser.displayName,
          createdByUser: authUser.uid,
          name: EmailTemplateName.LEAD_WELCOME,
        });
        if (isComponentMountedRef.current) {
          setCoachEmailTemplateDoc(coachTemplateDoc);
        }
      } else {
        await coachEmailTemplateDoc.update({
          ...templateData,
          updatedAt: new Date(),
          updatedByUser: authUser.uid,
          updatedByUserName: authUser.displayName,
        });
      }
    } else if (coachEmailTemplateDoc) {
      await coachEmailTemplateDoc.update({
        isEnabled: false,
        updatedAt: new Date(),
        updatedByUser: authUser.uid,
        updatedByUserName: authUser.displayName,
      });
    }
    showToast(texts.saved, { type: 'success' });
    if (isComponentMountedRef.current) {
      setIsLoading(false);
    }
  }, [
    authUser,
    coachEmailTemplateDoc,
    coachId, emailBody,
    emailSubject,
    isComponentMountedRef,
    setIsLoading,
    isCustomTemplateEnabled,
    showToast,
  ]);

  const onTemplateStateChange = useCallback((templateOption) => {
    setIsCustomTemplateEnabled(templateOption === TemplateOption.USE_CUSTOM);
    // If user selects custom template, set the custom template values
    if (templateOption === TemplateOption.USE_CUSTOM && coachEmailTemplateDoc) {
      setDataFromTemplateDoc(coachEmailTemplateDoc);
    }
    // If user selects default template, set the default template values
    if (templateOption === TemplateOption.USE_DEFAULT) {
      setDataFromTemplateDoc(defaultEmailTemplateDoc);
    }
  }, [
    coachEmailTemplateDoc,
    defaultEmailTemplateDoc,
    setDataFromTemplateDoc,
  ]);

  return (
    <Card>
      <HeaderContainer>
        <TitleContainer>
          <Title>{texts.title}</Title>
        </TitleContainer>
      </HeaderContainer>
      <Container>
        <StyledTextFieldContainer $withMargin>
          <InputLabel>{texts.automatedEmails}</InputLabel>
          <RadioButtonGroup
            options={[
              { label: texts.useDefault, value: TemplateOption.USE_DEFAULT },
              { label: texts.useCustom, value: TemplateOption.USE_CUSTOM },
            ]}
            selectedOption={isCustomTemplateEnabled ? TemplateOption.USE_CUSTOM : TemplateOption.USE_DEFAULT}
            onOptionChange={onTemplateStateChange}
            disabled={isReadOnly}
          />
        </StyledTextFieldContainer>
        <StyledTextFieldContainer $withMargin>
          <InputLabel>{texts.emailSubject}</InputLabel>
          <StyledTextField
            value={emailSubject}
            onChange={(e) => setEmailSubject(e.target.value)}
            disabled={!isCustomTemplateEnabled || isReadOnly}
          />
        </StyledTextFieldContainer>
        <StyledTextFieldContainer $withMargin>
          <MsgBody
            msgBody={emailBody}
            onMsgBodyChange={setEmailBody}
            placeHolders={[MsgPlaceholder.CLIENT_NAME, MsgPlaceholder.COACH_NAME, MsgPlaceholder.CONTACT_NUMBER]}
            isReadOnly={!isCustomTemplateEnabled || isReadOnly}
          />
        </StyledTextFieldContainer>
        <SaveButton
          onClick={onSave}
          disabled={isReadOnly}
          $fitToContent
        >
          {texts.save}
        </SaveButton>
      </Container>
      <LoadingOverlay isLoading={!coachId || isLoading} />
    </Card>
  );
};

LeadWelcomeEmailTemplate.propTypes = {
  coachId: PropTypes.string,
};

LeadWelcomeEmailTemplate.defaultProps = {
  coachId: null,
};

export default LeadWelcomeEmailTemplate;
