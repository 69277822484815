import posthog from 'posthog-js';

const FeatureFlag = {
  INSIDE_SALES_ADMIN: 'InsideSalesAdmin',
  CRX_ADMIN: 'CRXAdmin',
};

const isFeatureEnabled = (featureFlag) => posthog.isFeatureEnabled(featureFlag);

const isCRXAdmin = () => isFeatureEnabled(FeatureFlag.CRX_ADMIN);

const isInsideSalesAdmin = () => isFeatureEnabled(FeatureFlag.INSIDE_SALES_ADMIN);

export {
  isInsideSalesAdmin,
  isCRXAdmin,
};
