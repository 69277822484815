import React, {
  useCallback,
  useState,
} from 'react';
import EmojiPicker from 'emoji-picker-react';
import PropTypes from 'prop-types';

import {
  ClickAwayListener,
} from '@mui/material';

import {
  StyledEmojiIcon,
  StyledPopper,
} from './styles';

const EmojiInput = ({
  onEmojiClick,
}) => {
  const [popperAnchor, setPopperAnchor] = useState(null);
  const [isPopperOpen, setIsPopperOpen] = useState(false);

  const handleEmojiOpen = useCallback((event) => {
    setPopperAnchor(event.currentTarget);
    setIsPopperOpen(true);
  }, []);

  const handleEmojiClick = useCallback((event) => {
    onEmojiClick(event);
    setIsPopperOpen(true);
  }, [
    onEmojiClick,
  ]);

  return (
    <>
      <StyledEmojiIcon onClick={handleEmojiOpen} />
      {popperAnchor && (
        <StyledPopper
          open={isPopperOpen}
          anchorEl={popperAnchor}
          placement="top-end"
          modifiers={[
            {
              name: 'preventOverflow',
              enabled: true,
              options: {
                altAxis: true,
                altBoundary: true,
                tether: true,
                rootBoundary: 'viewport',
                padding: 8,
              },
            },
          ]}
        >
          <ClickAwayListener onClickAway={() => setIsPopperOpen(false)}>
            <div>
              <EmojiPicker
                onEmojiClick={handleEmojiClick}
              />
            </div>
          </ClickAwayListener>
        </StyledPopper>
      )}
    </>
  );
};

EmojiInput.propTypes = {
  onEmojiClick: PropTypes.func.isRequired,
};

export default EmojiInput;
