import React, { useContext } from 'react';
import { Alert, Snackbar } from '@mui/material';
import { compose } from 'recompose';
import { observer } from 'mobx-react';

import FeedContext from '../../../../context/FeedContext';
import ActivityFeed from '../Feed';
import {
  StyledFeedContainer,
} from './styles';
import text from './text.json';

const FeedContainer = () => {
  const { actionError, setActionError } = useContext(FeedContext);

  return (
    <StyledFeedContainer>
      <Snackbar
        open={actionError}
        autoHideDuration={3000}
        onClose={() => setActionError(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity="error">
          {text.ERROR_MESSAGE}
        </Alert>
      </Snackbar>
      <ActivityFeed />
    </StyledFeedContainer>
  );
};

export default compose(
  observer,
)(FeedContainer);
