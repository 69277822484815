import styled, { css } from 'styled-components';
import { Button } from '@mui/material';

import { ReactComponent as WorkoutIcon } from '../../../../../assets/icons/workout-icon.svg';
import { ReactComponent as MealIcon } from '../../../../../assets/icons/meal.svg';
import { ReactComponent as VideoReviewIcon } from '../../../../../assets/icons/video-review.svg';
import { ReactComponent as ChatIcon } from '../../../../../assets/icons/chat-icon.svg';
import { ReactComponent as SmartChatIcon } from '../../../../../assets/icons/smart-chat-icon.svg';
import colors from '../../../../../styles/colors';

const buttonStyle = css`
  background-color: ${colors.shades.secondary4};
  border-radius: 5px;
  color: ${colors.base.secondary2};
  font-size: 13px;
  padding: 10px 16px;
  margin: 16px 16px;
  font-weight: 500;
  width: fit-content;
  text-transform: none;
  border: 1px solid ${colors.shades.secondary4};
  &:hover {
    background-color: ${colors.shades.secondary5};
    border: 1px solid ${colors.shades.secondary4};
  }
`;
const ChatButtonContainer = styled.div`
  .MuiButton-root {
    ${buttonStyle};
  }
`;

const FeedActionButton = styled(Button)`
  ${buttonStyle};
`;

const iconStyles = css`
  width: 20px;
  height: 20px;
`;

const StyledWorkoutIcon = styled(WorkoutIcon)`
  ${iconStyles};
`;

const StyledMealIcon = styled(MealIcon)`
  ${iconStyles};
`;

const StyledVideoReviewIcon = styled(VideoReviewIcon)`
  ${iconStyles};
`;

const StyledChatIcon = styled(ChatIcon)`
  ${iconStyles};

  > path {
    stroke: ${colors.base.secondary2};
    fill: ${colors.base.secondary3};
  }
`;

const StyledSmartChatIcon = styled(SmartChatIcon)`
  ${iconStyles};

  > path {
    stroke: ${colors.base.secondary2};
    fill: ${colors.base.secondary3};
  }
`;

const CheckinActionContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export {
  ChatButtonContainer,
  FeedActionButton,
  StyledWorkoutIcon,
  StyledMealIcon,
  StyledVideoReviewIcon,
  StyledChatIcon,
  CheckinActionContainer,
  StyledSmartChatIcon,
};
