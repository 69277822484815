import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import { autorun } from 'mobx';
import * as Sentry from '@sentry/browser';

import useComponentMounted from '../../../hooks/useComponentMounted';
import FirebaseContext from '../../../context/FirebaseContext';

import useFeed from '../../hooks/useFeed';
import CoachActivity from '../../Model/CoachActivity';
import CoachConfig, {
  orderType,
} from '../../Model/CoachConfig';
import feedTypes from './config/feedTypes';
import FeedContext from './FeedContext';
import FeedState from './states';

const FeedContextProvider = ({
  children,
}) => {
  const [feedActivityList, setFeedActivityList] = useState([]);
  const [initialActivityList, setInitialActivityList] = useState([]);
  const [archivedActivityList, setArchivedActivityList] = useState([]);
  const [initialArchivedActivityList, setInitialArchivedActivityList] = useState([]);
  const [isArchivedActivitiesLoaded, setIsArchivedActivitiesLoaded] = useState(false);
  const [selectedFeedType, setSelectedFeedType] = useState(feedTypes.CHECK_IN);
  const [totalUnreadCount, setTotalUnreadCount] = useState({});
  const [feedState, setFeedState] = useState(FeedState.FEED_NOT_INITIALIZED);
  const { firebase: { remote } } = useContext(FirebaseContext);
  const [actionError, setActionError] = useState(false);
  const [activityInProgress, setActivityInProgress] = useState([]);
  const [searchField, setSearchField] = useState();
  const [isOldestToNewest, setIsOldestToNewest] = useState();
  const [selectedActionBarType, setSelectedActionBarType] = useState();
  const [selectedActivity, setSelectedActivity] = useState();
  const [isSmartFeedbackSelected, setIsSmartFeedbackSelected] = useState(false);
  const [coachConfig, setCoachConfig] = useState();

  const {
    userDocForFeed: {
      id: userId,
    },
  } = useFeed();

  const isComponentMountedRef = useComponentMounted();

  useEffect(() => {
    setFeedState(FeedState.FEED_NOT_INITIALIZED);
    setInitialActivityList([]);
    setSearchField(null);
  }, [
    userId,
  ]);

  useEffect(() => {
    const loadCoachConfig = async () => {
      const coachConfigDoc = await CoachConfig.getCoachConfigByCoachId(userId);
      if (isComponentMountedRef.current) {
        setIsOldestToNewest(!!((coachConfigDoc && coachConfigDoc.activityFeedDefaultOrderValue === orderType.ASC)));
        setCoachConfig(coachConfigDoc);
      }
    };
    loadCoachConfig();
  }, [
    isComponentMountedRef,
    userId,
  ]);

  /**
   * It will make the state of the feed to be erroneous on any error.
   * This will enable a refresh button so that the whole feed can be re-initialized.
   */
  const onFeedError = useCallback(() => {
    setFeedState(FeedState.FEED_ERROR);
  }, []);

  /**
   * It makes the feed to transition again to the NOT_INITIALIZED state so the whole
   * initialization occurs again
   */
  const onFeedRefresh = useCallback(() => {
    setFeedState(FeedState.FEED_NOT_INITIALIZED);
  }, []);

  // The feed is considered to be ready when it's in one of the final states, either initialized or in error state.
  const isFeedReady = useMemo(() => feedState === FeedState.FEED_INITIALIZED || feedState === FeedState.FEED_ERROR, [
    feedState,
  ]);

  /**
 * Whenever a new feedClient gets calculated we subscribe for event for
 * add new activity and deletion of activity from the feed.
 */
  useEffect(() => {
    const loadFeed = async () => {
      if (feedActivityList.length === 0 && !isFeedReady) {
        const activityList = await CoachActivity.getUnarchivedActivitiesForCoach(userId);
        const { docs } = activityList;
        if (isComponentMountedRef.current) {
          if (docs.length > 0 && docs[0].user) {
            setInitialActivityList(docs);
          }
          setFeedState(FeedState.FEED_INITIALIZED);
        }
      }
    };
    loadFeed();
  }, [
    userId,
    isComponentMountedRef,
    isFeedReady,
    feedActivityList,
  ]);

  /**
 * This will filter the activity list whenever a search keyword is
 * set, sorting added or initial activity list is updated.
 */
  useEffect(() => {
    const disposer = autorun(() => {
      const sortedActivityList = [...initialActivityList];
      const sortedArchivedActivityList = [...initialArchivedActivityList];
      if (isOldestToNewest) {
        sortedActivityList.reverse();
        sortedArchivedActivityList.reverse();
      }
      if (searchField) {
        const filteredActivityList = sortedActivityList
          .filter((activity) => activity.userName === searchField);
        const filteredArchivedActivityList = sortedArchivedActivityList
          .filter((activity) => activity.userName === searchField);
        setFeedActivityList(filteredActivityList);
        setArchivedActivityList(filteredArchivedActivityList);
      } else {
        setFeedActivityList(sortedActivityList);
        setArchivedActivityList(sortedArchivedActivityList);
      }
    });
    return disposer;
  }, [
    initialActivityList,
    searchField,
    isOldestToNewest,
    initialArchivedActivityList,
  ]);

  /**
   * This will calculate the unread count by type of activity and total
   * unread count whenever the activity feed list updates
   */
  useEffect(() => {
    const unreadCount = { total: 0 };
    const workoutAssignmentIds = [];
    const supportedFeedTypes = Object.values(feedTypes);
    const excludedFeedTypes = [feedTypes.DAILY_SUMMARY, feedTypes.CLIENT_STATUS, feedTypes.ARCHIVED];
    feedActivityList.forEach(({ type, workoutAssignmentId }) => {
      if (!supportedFeedTypes.includes(type) || excludedFeedTypes.includes(type)) {
        return;
      }
      if (!Object.keys(unreadCount).includes(type)) {
        unreadCount[type] = 0;
      }
      if (type === feedTypes.WORKOUT_COMPLETE && workoutAssignmentId) {
        if (!workoutAssignmentIds.includes(workoutAssignmentId)) {
          workoutAssignmentIds.push(workoutAssignmentId);
          unreadCount[type] += 1;
          unreadCount.total += 1;
        }
      } else {
        unreadCount[type] += 1;
        unreadCount.total += 1;
      }
    });

    if (unreadCount.total !== totalUnreadCount.total) {
      setTotalUnreadCount({ ...unreadCount });
    }
  }, [
    feedActivityList,
    totalUnreadCount,
  ]);

  // handles the functionality for changing between feed types
  const onFeedSelected = useCallback(async (type) => {
    if (isComponentMountedRef.current) {
      setSelectedFeedType(type);
      if (type === feedTypes.ARCHIVED && !isArchivedActivitiesLoaded) {
        setFeedState(FeedState.FEED_NOT_INITIALIZED);
        const activityList = await CoachActivity.getArchivedActivitiesForCoach(userId);
        const { docs } = activityList;
        if (isComponentMountedRef.current) {
          if (docs.length > 0 && docs[0].user) {
            setInitialArchivedActivityList(docs);
            setIsArchivedActivitiesLoaded(true);
          }
          setFeedState(FeedState.FEED_INITIALIZED);
        }
      }
    }
  }, [
    isComponentMountedRef,
    userId,
    isArchivedActivitiesLoaded,
  ]);

  const updateActivity = useCallback(async (activityIds, isArchiveAction) => {
    setActivityInProgress([...activityInProgress, activityIds]);
    const endpoint = `activityFeed/${isArchiveAction ? 'archive' : 'restore'}`;
    try {
      const response = await remote(endpoint, { activityIds });
      setActivityInProgress([...activityInProgress.filter((activityId) => activityId !== activityIds)]);
      if (!response.ok) {
        setActionError(true);
        return false;
      }
    } catch (err) {
      setActionError(true);
      Sentry.captureException(err, {
        extra: {
          message: 'Error while archiving activity',
          activityIds,
          coach: userId,
        },
      });
      return false;
    }
    return true;
  }, [
    userId,
    remote,
    activityInProgress,
  ]);

  // when coach mark activity as read we archive the activity in the feed.
  const onMarkAsRead = useCallback(async (activity) => {
    await updateActivity(activity.id, true);
  }, [
    updateActivity,
  ]);

  // when coach restore activity from the deleted activities.
  const onRestore = useCallback(async (activity) => {
    await updateActivity(activity.id, false);
  }, [
    updateActivity,
  ]);

  const context = useMemo(() => ({
    selectedFeedType,
    onFeedSelected,
    feedState,
    totalUnreadCount,
    onFeedError,
    onFeedRefresh,
    isFeedReady,
    onMarkAsRead,
    onRestore,
    feedActivityList,
    actionError,
    setActionError,
    activityInProgress,
    uniqueClientNames: [...new Set(initialActivityList.map((activity) => activity.userName))],
    setSearchField,
    searchField,
    setIsOldestToNewest,
    isOldestToNewest,
    selectedActionBarType,
    setSelectedActionBarType,
    selectedActivity,
    setSelectedActivity,
    archivedActivityList,
    isSmartFeedbackSelected,
    setIsSmartFeedbackSelected,
    coachConfig,
    setCoachConfig,
  }), [
    selectedFeedType,
    onFeedSelected,
    feedState,
    totalUnreadCount,
    onFeedError,
    onFeedRefresh,
    isFeedReady,
    onMarkAsRead,
    onRestore,
    feedActivityList,
    actionError,
    setActionError,
    activityInProgress,
    setSearchField,
    initialActivityList,
    searchField,
    setIsOldestToNewest,
    isOldestToNewest,
    selectedActionBarType,
    setSelectedActionBarType,
    selectedActivity,
    setSelectedActivity,
    archivedActivityList,
    isSmartFeedbackSelected,
    setIsSmartFeedbackSelected,
    coachConfig,
    setCoachConfig,
  ]);

  return (
    <FeedContext.Provider value={context}>
      {children}
    </FeedContext.Provider>
  );
};

FeedContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FeedContextProvider;
