import React from 'react';
import {
  Route,
  Redirect,
  Switch,
  useRouteMatch,
} from 'react-router-dom';

import useToolsNavigation from '../../hooks/useToolsNavigation';
import pages, { pagesConfig } from '../../config/pages';
import CoachToolsRoute from '../ToolsRoute';
import routes from './routes';

const FormsRoutes = () => {
  const { path } = useRouteMatch();
  const { pathname } = useToolsNavigation();

  return (
    <Switch>
      {routes.map(({
        pageId,
        path: routePath,
        component,
      }) => (
        <CoachToolsRoute
          key={pageId}
          pageId={pageId}
          path={`${path}/${routePath}`}
          component={component}
        />
      ))}

      <Route exact path={`${path}`}>
        <Redirect to={{ pathname: `${pathname}/${pagesConfig[pages.FORMS].path}` }} />
      </Route>
    </Switch>
  );
};

export default FormsRoutes;
