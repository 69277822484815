import styled from 'styled-components';
import {
  DialogContentText,
  Paper,
  TextField,
} from '@mui/material';

const StyledPaper = styled(Paper)`
  padding: 16px;
`;

const StyledTextField = styled(TextField)`
  margin: 20px 0;
  width: 100%;
`;

const StyledDialogContentText = styled(DialogContentText)`
  font-weight: bold;
  margin: 10px 0;
`;

const StyledInfoLabel = styled(DialogContentText)`
  margin: 10px 0;
`;

const StyledLabel = styled.div`
  font-size: 14px;
  margin: 20px 0;
  width: 100%;
`;

export {
  StyledPaper,
  StyledTextField,
  StyledLabel,
  StyledDialogContentText,
  StyledInfoLabel,
};
