import { WorkoutDeleteType } from './utils';

const texts = {
  title: 'Delete Workouts',
  delete: 'Delete',
  programDeleted: 'Program deleted successfully',
  workoutDeleted: 'Workout deleted successfully',
  workoutsDeleted: 'Workouts deleted successfully',
  workoutDeleteOptionsLabels: {
    [WorkoutDeleteType.WORKOUT]: 'Delete only this workout',
    [WorkoutDeleteType.MULTIPLE_WORKOUTS]: 'Delete this and all upcoming "{workoutName}" workouts',
    [WorkoutDeleteType.PROGRAM]: 'Delete this workout and all future workouts in this program ({count} upcoming)',
  },
  cancel: 'Cancel',
  programWorkoutsInProgress: `
    {count} workout assignments in the program were not deleted since they were already accessed by the client
  `,
  workoutsInProgress: `
    {count} "{workoutName}" workouts were not deleted since they were already accessed by the client
  `,
};

export default texts;
