import { Collection } from 'firestorter';

import Collections from '../../utils/collections';
import BaseDocument from '../../Model/BaseDocument';
import { workoutAssignmentStatuses } from '../../Model/WorkoutAssignment';

const WorkoutFeedViewType = {
  COACH_ASSIGNED_WORKOUT: 'coach_assigned_workout',
  SELF_ASSIGNED_WORKOUT: 'self_assigned_workout',
  CUSTOM_WORKOUT: 'custom_workout',
  TRACKED_ACTIVITY_SUMMARY: 'tracked_activity_summary',
  TRACKED_ACTIVITY: 'tracked_activity',
  CHECK_IN: 'check_in',
};

class WorkoutFeedView extends BaseDocument {
  constructor(id, opts) {
    super(`${Collections.WORKOUT_FEED_VIEW}/${id}`, opts);
  }

  get title() {
    return this.data.title;
  }

  get user() {
    return this.data.user;
  }

  get startDate() {
    return this.data.startDate?.toDate();
  }

  get endDate() {
    return this.data.endDate?.toDate();
  }

  get status() {
    return this.data.status;
  }

  get type() {
    return this.data.type;
  }

  get duration() {
    return this.data.duration;
  }

  get comment() {
    return this.data.comment;
  }

  static async getCompletedWorkoutHistoryByUser(
    userId,
    limit,
  ) {
    const workoutFeedViewCollection = new Collection(() => Collections.WORKOUT_FEED_VIEW, {
      createDocument: (src, opts) => new WorkoutFeedView(src.id, opts),
      query: (ref) => {
        let query = ref
          .where('user', '==', userId)
          .where('status', '==', workoutAssignmentStatuses.COMPLETED)
          .where('type', 'in', [
            WorkoutFeedViewType.COACH_ASSIGNED_WORKOUT,
            WorkoutFeedViewType.SELF_ASSIGNED_WORKOUT,
            WorkoutFeedViewType.CUSTOM_WORKOUT,
            WorkoutFeedViewType.TRACKED_ACTIVITY,
          ])
          .orderBy('startDate', 'desc');
        if (typeof limit === 'number' && limit > 0) {
          query = query.limit(limit);
        }
        return query;
      },
    });

    await workoutFeedViewCollection.fetch();
    return workoutFeedViewCollection;
  }
}

export default WorkoutFeedView;
