import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import { ReactComponent as RefreshIcon } from '../../../../assets/icons/v2/creation-refresh.svg';
import { PrimaryButton } from '../../../../components/Button/ActionButtons';
import Recipe, { RecipeState as State } from '../../../Model/Recipe';
import NavigationContext, { NavigationRouteType } from '../../../context/NavigationContext';
import MealPlanContext, { withMealPlanContextReady } from '../../../context/MealPlanContext';
import RecipesTable from '../../../components/RecipesTable';
import useToolsNavigation from '../../../hooks/useToolsNavigation';
import RecipeEditor from '../RecipeEditor';
import { HeaderContainer, Title } from '../styles';

import EditAction from './EditAction';
import {
  Container,
  TableContainer,
} from './styles';

import texts from './texts.json';

const RecipesList = ({ isMealPlannerView }) => {
  const [recipeState, setRecipeState] = useState(null);

  const { selectedRecipe, onSelectRecipe } = useContext(MealPlanContext);
  const { routeType } = useContext(NavigationContext);

  const { navigateToRecipe: navigateToRecipeFn } = useToolsNavigation();

  const recipeEditFn = async (recipeId) => {
    const recipe = new Recipe(recipeId);
    await recipe.init();
    onSelectRecipe(recipe);
    setRecipeState(State.EDIT);
  };

  const onNewActionClick = () => {
    onSelectRecipe(null);
    setRecipeState(State.NEW);
  };

  const onBackActionClick = () => {
    onSelectRecipe(null);
    setRecipeState(null);
  };

  const navigateToRecipe = isMealPlannerView ? recipeEditFn : navigateToRecipeFn;

  return (
    <Container>
      {selectedRecipe || (recipeState && recipeState === State.NEW)
        ? <RecipeEditor recipeDoc={selectedRecipe} onBackClick={onBackActionClick} hasBackButton />
        : (
          <TableContainer>
            <HeaderContainer>
              <Title>{texts.recipesTitle}</Title>
              <PrimaryButton
                onClick={onNewActionClick}
                icon={<RefreshIcon />}
              >
                {texts.createRecipe}
              </PrimaryButton>
            </HeaderContainer>
            <RecipesTable
              ActionsCellComponent={EditAction}
              extraActionsCellProps={{ navigateToRecipe }}
              actionCellWidth={10}
              isRecipeTabView={routeType !== NavigationRouteType.SUPPORT}
            />
          </TableContainer>
        )}
    </Container>
  );
};

RecipesList.propTypes = {
  isMealPlannerView: PropTypes.bool,
};

RecipesList.defaultProps = {
  isMealPlannerView: false,
};

export default compose(
  withMealPlanContextReady,
)(RecipesList);
