import styled from 'styled-components';
import {
  Paper,
} from '@mui/material';

const StyledPaper = styled(Paper)`
  padding: 16px;
`;

export {
  StyledPaper,
};
