import styled from 'styled-components';
import {
  Autocomplete,
  Box,
  TextField,
  InputAdornment,
} from '@mui/material';

import colors from '../../../../styles/colors';
import Button from '../../../../components/Button';
import ProductSelector from '../ProductSelector';
import PriceSelector from '../PriceSelector';
import { Section } from '../Section';
import AveragePayment from '../AveragePayment';
import Input from '../Input';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 500px;
`;

const SidePanel = styled(Box)`
  background-color: ${colors.base.gamma};
`;

const sidePanelStyle = {
  borderRight: `1px solid ${colors.shades.gamma1}`,
  minWidth: '350px',
  boxSizing: 'border-box',
};

const Toolbar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 40px;
  padding: 20px 40px 0;
  background-color: ${colors.shades.gamma5};
`;

/**
 * This button is re-styled here because this is the only place we'll use it. We'll even remove it as soon as the new
 * design system is ready.
 */
const PriceTypeButton = styled(Button)`
  border-radius: 5px 5px 0 0;
  margin-right: 10px;
  width: 150px;
  font-size: 14px;
  cursor: pointer;
  background-color: ${({ $selected }) => ($selected ? colors.base.secondary2 : colors.shades.gamma1)};
  color: ${({ $selected }) => ($selected ? colors.base.alpha : colors.base.beta)};

  &:disabled {
    cursor: default;
    background-color: ${({ $selected }) => (
    $selected
      ? colors.addAlpha(colors.base.secondary2, 50)
      : colors.addAlpha(colors.shades.gamma1, 50))};
    color: ${({ $selected }) => ($selected ? colors.base.alpha : colors.base.beta)};
  }
`;

const TextFieldLabel = styled.div`
  color: ${colors.shades.primary1};
  text-transform: uppercase;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
`;

const FormField = styled.div`
  display: ${({ $hidden }) => ($hidden ? 'none' : 'flex')};
  flex-direction: column;
  justify-content: center;
`;

const StyledTextField = styled(TextField)`
  background-color: ${colors.base.alpha};

  .MuiOutlinedInput-input {
    height: 40px;
    background-color: ${colors.base.alpha};
  }

  .MuiInputBase-adornedEnd {
    text-transform: uppercase;
  }

  .MuiInputAdornment-root {
    margin-left: 0;
    min-width: 68px;
  }

  .MuiFormHelperText-contained {
    position: absolute;
    top: 100%;
    margin-left: 0;
    width: max-content;
  }
`;

const StyledAdornment = styled(InputAdornment)`
  background-color: ${colors.base.alpha};
`;

const StyledAutoComplete = styled(Autocomplete)`
  .MuiAutocomplete-inputRoot {
    height: 56px;
  }
`;

const StyledProductSelector = styled(ProductSelector)`
  flex-basis: 50%;
`;

const ConfigRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const RowItem = styled.div`
  width: 130px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const CheckoutPaymentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const InitialPaymentContainer = styled(RowItem)`
  width: 350px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-right: 50px;
`;

const InitialTermText = styled(RowItem)`
  width: 40px;
  height: 76px;
  margin-top: 18px;
`;

const CommitmentContainer = styled(RowItem)`
  margin-left: 50px;
  padding-bottom: 27px;
`;

const CancellationDate = styled(RowItem)`
  width: 235px;
  height: 76px;
  margin: 18px 0 27px 18px;
  font-style: italic;
  font-size: 14px;

  #cancelDate {
    font-weight: 600;
  }
`;

const MonthlyPriceContainer = styled(RowItem)`
  width: 250px;
`;

const StartDateContainer = styled(RowItem)`
  width: 250px;
  height: 58px;
  padding-bottom: 25px;
`;

const ProductPackageContainer = styled(Section)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

const SubSection = styled.div`
  width: 350px;
  margin-right: 35px;
`;

const PackagesContainer = styled.div`
  height: 45px;
`;

const StyledAveragePayment = styled(AveragePayment)`
  margin-top: 12px;
`;

const StyledDatePickerInput = styled(Input)`
  .MuiInputBase-input {
    min-width: 85%;
  }
`;

const TotalFeesContainer = styled.div`
  margin-top: 25px;
  color: ${colors.base.beta};
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;

  > span {
    color: ${colors.shades.primary1};
  }
`;

const BillingOptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 80px;
  padding-bottom: 15px;
  align-items: center;
`;

const BillingOptionsRadioGroup = styled.div`
  min-width: 300px;
`;

const BillingOptionsPriceSelector = styled(PriceSelector)`
  width: 170px;
`;

const BillingOptionsInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 15px;
`;

const ContractLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 12px;
  line-height: 21px;
`;

const ContractLabel = styled.div`
  color: ${colors.shades.primary1};
`;

const ContractValue = styled.div`
  color: ${colors.base.beta};
  margin-left: 10px;
`;

export {
  TextFieldLabel,
  FormField,
  StyledTextField,
  StyledAutoComplete,
  SidePanel,
  sidePanelStyle,
  StyledProductSelector,
  ConfigRow,
  RowItem,
  InitialPaymentContainer,
  InitialTermText,
  CommitmentContainer,
  CancellationDate,
  StyledAdornment,
  MonthlyPriceContainer,
  StartDateContainer,
  ProductPackageContainer,
  SubSection,
  CheckoutPaymentWrapper,
  StyledAveragePayment,
  StyledDatePickerInput,
  PackagesContainer,
  Toolbar,
  PriceTypeButton,
  Container,
  TotalFeesContainer,
  BillingOptionsContainer,
  BillingOptionsRadioGroup,
  BillingOptionsPriceSelector,
  BillingOptionsInfo,
  ContractLabelContainer,
  ContractLabel,
  ContractValue,
};
