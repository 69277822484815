import React, {
  useCallback,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';

import { transformVideoUrl } from './utils';
import {
  Container,
  IconButtonContainer,
  VideoContainer,
  StyledVideoIcon,
  StyledRemoveIcon,
  StyledRevertIcon,
  ButtonsContainer,
  StyledFileSelectButton,
  ActionButton,
  RemoteExerciseVideo,
  LocalExerciseVideo,
} from './styles';
import texts from './texts.json';

const VideoHandler = ({
  videoUrl,
  onVideoChange,
  showRevertOption,
}) => {
  const [videoPreviewUrl, setVideoPreviewUrl] = useState(transformVideoUrl(videoUrl));
  const [isLocalVideo, setIsLocalVideo] = useState(false);

  const onFileSelect = useCallback((event) => {
    const { files } = event.target;
    if (files.length) {
      const [file] = files;
      setIsLocalVideo(true);
      setVideoPreviewUrl(URL.createObjectURL(file));
      onVideoChange(file);
    }
  }, [onVideoChange]);

  const onRemoveVideo = useCallback(() => {
    setVideoPreviewUrl(null);
    onVideoChange(null);
  }, [onVideoChange]);

  const onRevertVideo = useCallback(() => {
    setVideoPreviewUrl(transformVideoUrl(videoUrl));
    setIsLocalVideo(false);
  }, [videoUrl]);

  return (
    <Container>
      {!!videoPreviewUrl && (
        <IconButtonContainer>
          <IconButton onClick={onRemoveVideo}>
            <StyledRemoveIcon />
          </IconButton>
        </IconButtonContainer>
      )}
      <VideoContainer>
        {!!videoPreviewUrl
          && (isLocalVideo
            ? <LocalExerciseVideo src={videoPreviewUrl} controls />
            : <RemoteExerciseVideo src={videoPreviewUrl} />
          )}
      </VideoContainer>
      <ButtonsContainer>
        <StyledFileSelectButton
          onSelect={onFileSelect}
          multiple={false}
          extraInputProps={{ accept: 'video/*' }}
          extraButtonProps={{ variant: 'contained' }}
        >
          <StyledVideoIcon />
          {texts.uploadVideo}
        </StyledFileSelectButton>
        {showRevertOption && (
          <ActionButton
            onClick={onRevertVideo}
            disabled={!videoUrl}
            type="button"
          >
            <StyledRevertIcon />
            {texts.revert}
          </ActionButton>
        )}
      </ButtonsContainer>
    </Container>
  );
};

VideoHandler.propTypes = {
  videoUrl: PropTypes.string.isRequired,
  onVideoChange: PropTypes.func.isRequired,
  showRevertOption: PropTypes.bool,
};

VideoHandler.defaultProps = {
  showRevertOption: false,
};

export default VideoHandler;
