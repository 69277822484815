import styled from 'styled-components';

const MobileView = styled.div`
  height: 90%;
  width: calc(100vh * 0.5);
  border: none;
`;

export {
  MobileView,
};
