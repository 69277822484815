import React, {
  useEffect,
  useState,
} from 'react';
import { useRouteMatch } from 'react-router-dom';

import LoadingPage from '../../../components/LoadingPage';
import useComponentMounted from '../../../hooks/useComponentMounted';
import useToolsNavigation from '../../hooks/useToolsNavigation';
import Form from '../../Model/Form';
import FormBuilder from '../../components/FormBuilder';

import { Container } from './styles';

const FormInfo = () => {
  const [isReady, setIsReady] = useState(false);
  const [formDoc, setFormDoc] = useState(null);

  const { params: { formId } } = useRouteMatch();

  const { navigateBack } = useToolsNavigation();

  const isComponentMountedRef = useComponentMounted();

  useEffect(() => {
    const init = async () => {
      if (formId) {
        const form = new Form(formId);
        await form.init();

        if (isComponentMountedRef.current) {
          setFormDoc(form);
        }
      }

      setIsReady(true);
    };

    if (!isReady) {
      init();
    }
  }, [
    isReady,
    formId,
    isComponentMountedRef,
  ]);

  return (
    <Container>
      {
        isReady
          ? <FormBuilder formDoc={formDoc} onBackClick={navigateBack} />
          : <LoadingPage />
      }
    </Container>
  );
};

export default FormInfo;
