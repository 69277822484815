import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import format from 'string-template';
import moment from 'moment';
import { Accordion } from '@mui/material';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';

import { DateFormat } from '../../../../../../utils/date';

import {
  FilterDescription,
  FilterInformation,
  FilterTitle,
} from '../styles';
import {
  AccordionMacro,
  AccordionTitle,
  Container,
  NutritionLogMealContainer,
  NutritionLogMealMacro,
  NutritionLogMealMacroContainer,
  NutritionLogMealTitle,
  StyledAccordionDetails,
  StyledAccordionSummary,
} from './styles';
import texts from './texts.json';

const FoodSummary = ({
  nutritionLogs,
  dateRange,
}) => {
  const [expandedAccordionIndex, setExpandedAccordionIndex] = useState(null);

  return (
    <Container>
      <FilterInformation>
        <FilterTitle>
          {texts.mealsIntake}
        </FilterTitle>
        <FilterDescription>
          {dateRange}
        </FilterDescription>
      </FilterInformation>
      {nutritionLogs.map((nutritionLog, nutritionLogIndex) => (
        <Accordion
          expanded={expandedAccordionIndex === nutritionLogIndex}
          onChange={(_, isExpanded) => setExpandedAccordionIndex(isExpanded ? nutritionLogIndex : null)}
        >
          <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
            <AccordionTitle>
              {moment(nutritionLog.startTime.toDate()).format(DateFormat.DATE_FORMAT_WEEKDAY_MONTH_FORMAT)}
            </AccordionTitle>
            <AccordionMacro>
              {format(texts.nutritionLogMacroDescription, {
                protein: Math.round(nutritionLog.macros.protein),
                carbs: Math.round(nutritionLog.macros.carbs),
                fat: Math.round(nutritionLog.macros.fat),
                calories: Math.round(nutritionLog.macros.calories),
              })}
            </AccordionMacro>
          </StyledAccordionSummary>
          <StyledAccordionDetails>
            <ul>
              {nutritionLog.meals.map((meal) => (
                <NutritionLogMealContainer>
                  <NutritionLogMealTitle>
                    {meal.name}
                  </NutritionLogMealTitle>
                  <NutritionLogMealMacroContainer>
                    <NutritionLogMealMacro highlight>
                      {format(texts.nutritionLogMealCalories, {
                        calories: Math.round(meal.macros.kcal),
                      })}
                    </NutritionLogMealMacro>
                    <NutritionLogMealMacro>
                      {format(texts.nutritionLogMealMacroDescription, {
                        protein: Math.round(meal.macros.protein),
                        carbs: Math.round(meal.macros.total_carbs),
                        fat: Math.round(meal.macros.fat),
                      })}
                    </NutritionLogMealMacro>
                  </NutritionLogMealMacroContainer>
                </NutritionLogMealContainer>
              ))}
            </ul>
          </StyledAccordionDetails>
        </Accordion>
      ))}
    </Container>
  );
};

FoodSummary.propTypes = {
  nutritionLogs: PropTypes.array,
  dateRange: PropTypes.string,
};

FoodSummary.defaultProps = {
  nutritionLogs: [],
  dateRange: '',
};

export default compose(
  observer,
)(FoodSummary);
