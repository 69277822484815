const fieldName = {
  NAME: 'name',
  DESCRIPTION: 'description',
  TYPE: 'type',
  TAGS: 'tags',
  CREATED_BY: 'createdBy',
  CREATED_AT: 'createdAt',
  MODIFIED_AT: 'modifiedAt',
  ORIGINAL_EXERCISE: 'originalExercise',
  VIDEO_URL: 'videoUrl',
  VIDEO_PREVIEW_URL: 'videoPreviewUrl',
  VIDEO_PREVIEW_THUMBNAIL: 'videoPreviewThumbnail',
  CURRENT: 'current',
  TAG_IDS: 'tagIds',
};

export default fieldName;
