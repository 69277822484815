import { Collection } from 'firestorter';
import BaseDocument from '../../Model/BaseDocument';
import Collections from '../../utils/collections';

class LeadHistory extends BaseDocument {
  get leadId() {
    return this.data.leadId;
  }

  get createdAt() {
    return this.data.createdAt;
  }

  get createdBy() {
    return this.data.createdBy;
  }

  get createdByUserName() {
    return this.data.createdByUserName;
  }

  get activityType() {
    return this.data.activityType;
  }

  get value() {
    return this.data.value;
  }

  get reason() {
    return this.data.reason || '';
  }

  get coachId() {
    return this.data.coachId;
  }

  static async getLeadHistory(leadId) {
    const leadHistoryCollection = new Collection(Collections.LEAD_HISTORY, {
      createDocument: (src, opts) => new LeadHistory(src, opts),
      query: (ref) => ref
        .where('leadId', '==', leadId)
        .orderBy('createdAt', 'asc'),
    });
    await leadHistoryCollection.fetch();
    return leadHistoryCollection.docs;
  }

  static async addLeadHistoryItem(item) {
    const leadHistoryCollection = new Collection(Collections.LEAD_HISTORY);
    await leadHistoryCollection.add(item);
  }
}

export default LeadHistory;
