import styled, { css } from 'styled-components';

import Select, { MenuItem } from '../../../../../components/Select';
import { BaseTag } from '../../../../../components/Tags';

import colors from '../../../../../styles/colors';

const menuStyles = {
  '.MuiMenu-list': {
    padding: 0,
  },
};

const Container = styled.div`
  width: fit-content;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Description = styled.p`
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  margin-right: 14px;
`;

const selectStyles = css`
  box-sizing: border-box;
  height: 40px;
  border-radius: 5px;
  font-weight: 500;
  font-size: 13px;
  line-height: 14px;
  color: ${colors.base.secondary2};
`;

const BadgesContainer = styled.div`
  display: flex;
  column-gap: 5px;
  width: 100%;
  justify-content: flex-end;
  margin-left: 5px;
`;

const StyledSelect = styled(Select)`
  min-width: 265px;
  text-align: initial;
  & .MuiSelect-select {
    ${selectStyles};
    background: ${colors.base.alpha};
    border: 1px solid ${colors.shades.secondary6};
    padding: 15px;
    min-height: unset;
  }

  & fieldset {
    border: unset;
  }

  &:hover .MuiOutlinedInput-notchedOutline {
    border: unset;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  ${selectStyles};
  padding: 10px 15px;
  background: ${colors.shades.secondary5};
  border-radius: unset;
`;

const MealTimesTag = styled(BaseTag)`
  background: ${colors.shades.secondary7};
  border: 1px solid ${colors.shades.secondary4};
`;

const RecipesTag = styled(BaseTag)`
  background: ${colors.shades.danger2};
  border: 1px solid ${colors.shades.danger4};
`;

export {
  Container,
  Description,
  StyledSelect,
  menuStyles,
  StyledMenuItem,
  BadgesContainer,
  MealTimesTag,
  RecipesTag,
};
