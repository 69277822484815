import {
  useContext,
  useMemo,
  useState,
  useEffect,
} from 'react';
import { autorun } from 'mobx';

import UserContext from '../../context/UserContext';
import useCurrentLoggedInUser from '../../hooks/useCurrentLoggedInUser';
import useSessionStore from '../../hooks/useSessionStore';

const useFeed = () => {
  const { isCurrentLoggedInUserInPath } = useCurrentLoggedInUser();
  const { isCoachAssistant } = useSessionStore();

  const {
    userDoc: userInRouteDoc,
  } = useContext(UserContext);

  const [userDocForFeed, setUserDocForFeed] = useState(userInRouteDoc);

  useEffect(() => {
    autorun(() => {
      setUserDocForFeed(userInRouteDoc);
    });
  }, [
    userInRouteDoc,
  ]);

  return useMemo(() => ({
    userDocForFeed,
    readOnlyMode: !isCurrentLoggedInUserInPath && !isCoachAssistant,
  }), [
    userDocForFeed,
    isCurrentLoggedInUserInPath,
    isCoachAssistant,
  ]);
};

export default useFeed;
