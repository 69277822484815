import styled, { css } from 'styled-components';
import {
  TableCell,
  Typography,
  TableRow,
} from '@mui/material';

import colors from '../../../styles/colors';

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

const DataContainer = styled.div`
  margin: 10px;
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
`;

const StyledWeightCell = styled(TableCell)`
  font-size: 24px;
  font-weight: bold;
`;

const StyledTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: ${colors.base.alpha};
  }
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 10px;
  width: calc(100% - 20px);
`;

const StyledLink = styled.a`
  cursor: pointer;
`;

const StatContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${colors.base.gamma};
  border-radius: 5px;
  border: 1px solid ${colors.shades.gamma4};
  margin: 10px 6px;
`;

const WeightStat = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.base.gamma};
  padding: 16px;
  width: ${({ $width }) => ($width || '100%')};
  ${({ showDivider }) => (showDivider && css`
    border-right: 1px solid ${colors.shades.gamma5};
  `)}
`;

const WeightStatContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 30px;
`;

const Stat = styled.div`
  display: flex;
  flex-direction: column;
`;

const WeightStatTitle = styled(Typography)`
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  text-transform: uppercase;
  margin: 0 30px 5px;
  color: ${colors.shades.primary1};
`;

const WeightStatValue = styled(Typography)`
  font-size: 16px;
  font-weight: 800;
  line-height: 20px;
  color: ${(props) => (props.$current ? colors.base.beta : colors.shades.gamma10)};
`;

const StatLabel = styled(Typography)`
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  color: ${colors.shades.gamma10};
`;

const DifferenceStatValue = styled(Typography)`
  font-size: 16px;
  font-weight: 800;
  line-height: 20px;
  color: ${(props) => (props.$isPositive ? colors.shades.success3 : colors.shades.secondary2)};
`;

const DifferenceStatLabel = StatLabel;

const CheckInQuestionContainer = Stat;

const QuestionText = styled(Typography)`
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  color: ${colors.shades.gamma10};
`;

const AnswerText = styled(Typography)`
  background: ${colors.base.alpha};
  padding: 10px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  margin: 5px 0 14px;
`;

const StatValue = styled(Typography)`
  font-size: 12px;
  font-weight: 450;
  line-height: 15px;
  color: ${(props) => (props.$current ? colors.base.beta : colors.shades.gamma10)};
`;

const StyledStatCell = styled(TableCell)`
  padding: 8px;
  border-bottom: 1px solid ${colors.shades.gamma1};
  text-align: left;
`;

const StyledTitle = styled(Typography)`
  display: flex;
  font-size: 14px;
  line-height: 16px;
  padding: 8px 10px;
`;

export {
  Container,
  DataContainer,
  ImageContainer,
  StyledContainer,
  StyledLink,
  StyledWeightCell,
  StatContainer,
  Stat,
  WeightStatContainer,
  WeightStatTitle,
  WeightStatValue,
  StatLabel,
  DifferenceStatValue,
  DifferenceStatLabel,
  CheckInQuestionContainer,
  QuestionText,
  AnswerText,
  StatValue,
  StyledStatCell,
  StyledTableRow,
  StyledTitle,
  WeightStat,
};
