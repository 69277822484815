import React, { useMemo } from 'react';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import useQuickSearch from '../../../hooks/useQuickSearch';
import QuickSearchToolbar from '../../../CoachTools/components/QuickSearchToolbar';
import { StyledDataGrid } from './styles';

const ROWS_PER_PAGE = 25;

const Table = ({
  rows,
  columns,
  pageSize,
  enableSearch,
  loading,
  filterFunction,
}) => {
  // Get the first filtering stage which returns the rows that match the quick search input.
  const {
    filteredRows: quickSearchRows,
    toolbarProps,
  } = useQuickSearch(rows, columns);

  // Get the second filtering stage using custom filter function passed as prop
  const filteredRows = useMemo(() => {
    const rowsToFilter = enableSearch ? quickSearchRows : rows;
    return rowsToFilter.filter(filterFunction);
  }, [
    enableSearch,
    quickSearchRows,
    rows,
    filterFunction,
  ]);

  return (
    <StyledDataGrid
      rows={filteredRows}
      columns={columns}
      pageSize={pageSize}
      rowsPerPageOptions={[pageSize || ROWS_PER_PAGE]}
      disableSelectionOnClick
      components={{ Toolbar: enableSearch ? QuickSearchToolbar : null }}
      componentsProps={{
        toolbar: enableSearch ? { ...toolbarProps, autoFocus: true } : null,
      }}
      loading={loading}
    />
  );
};

Table.propTypes = {
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  pageSize: PropTypes.number,
  enableSearch: PropTypes.bool,
  loading: PropTypes.bool,
  filterFunction: PropTypes.func,
};

Table.defaultProps = {
  pageSize: 25,
  enableSearch: true,
  loading: false,
  filterFunction: () => true,
};

export default compose(
  observer,
)(Table);
