import React, { useContext } from 'react';
import LoadingPage from '../../components/LoadingPage';
import StripeContext from './StripeContext';
import StripeContextProvider from './StripeContextProvider';

const withStripeContextProvider = (Component) => (props) => (
  <StripeContextProvider>
    <Component {...props} />
  </StripeContextProvider>
);

const withStripeContextReady = (Component) => (props) => {
  const { isReady } = useContext(StripeContext);
  return isReady ? <Component {...props} /> : <LoadingPage />;
};

export {
  withStripeContextProvider,
  withStripeContextReady,
};
