import React from 'react';
import PropTypes from 'prop-types';

import { SectionContainer } from '../../../../../../../../components/v2/Section';
import Portal from '../../../../../../../components/Portal';

const WorkoutPreview = ({
  mobileUrl,
}) => (
  <SectionContainer>
    <Portal
      url={mobileUrl}
    />
  </SectionContainer>
);

WorkoutPreview.propTypes = {
  mobileUrl: PropTypes.string.isRequired,
};

export default WorkoutPreview;
