import React from 'react';
import {
  Switch,
  useRouteMatch,
  Route,
} from 'react-router-dom';
import { compose } from 'recompose';

import {
  withOnboardingFlowConfigContextProvider,
  withOnboardingFlowConfigContextReady,
} from '../../context/OnboardingFlowConfigContext';
import { withOnboardingFlowContext } from '../../context/OnboardingFlowContext';
import { withGrowsurfContext } from '../../context/GrowsurfContext';
import {
  withCoachAssignmentContextProvider,
  withCoachAssignmentContextReady,
} from '../../context/CoachAssignmentContext';
import {
  withWebCustomizationContextProvider,
  withWebCustomizationContextReady,
} from '../../context/WebCustomizationContext/withWebCustomizationContext';
import {
  withUserContractContextProvider,
  withUserContractContextReady,
} from '../../context/UserContractContext/withUserContractContext';
import { withCustomerIOContextProvider } from '../../context/CustomerIOContext';
import {
  withPlanContextProvider,
  withPlanContextReady,
} from '../../context/PlanContext';

import { withLayout } from '../../components/Layout';

import Subscription from '../../pages/Onboarding/Subscription';

import {
  withExternalCoachContextProvider,
  withExternalCoachContextReady,
} from '../../context/ExternalCoachContext';

const OnboardingRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`} component={Subscription} />
    </Switch>
  );
};

export default compose(
  withUserContractContextProvider,
  withUserContractContextReady,
  withOnboardingFlowConfigContextProvider,
  withOnboardingFlowConfigContextReady,
  withExternalCoachContextProvider,
  withExternalCoachContextReady,
  withLayout({ hasHeader: false }),
  withCustomerIOContextProvider,
  withGrowsurfContext,
  withCoachAssignmentContextProvider,
  withCoachAssignmentContextReady,
  withWebCustomizationContextProvider,
  withWebCustomizationContextReady,
  withOnboardingFlowContext,
  withPlanContextProvider,
  withPlanContextReady,
)(OnboardingRoutes);
