import styled from 'styled-components';
import { Typography } from '@mui/material';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 10px 0;
`;

const Title = styled(Typography)`
  font-size: 18px;
  font-weight: bold;
`;

export {
  Container,
  HeaderContainer,
  Title,
};
