import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import NavigationContext from './NavigationContext';
import { NavigationRouteType } from './navigationRouteTypes';

const NavigationContextProvider = ({
  routeType,
  children,
}) => {
  const [activePage, setActivePage] = useState(null);

  const contextValue = useMemo(() => ({
    activePage,
    setActivePage,
    routeType,
    isAdminGroupRoute: routeType === NavigationRouteType.ADMIN || routeType === NavigationRouteType.SUPPORT,
  }), [
    activePage,
    routeType,
  ]);

  return (
    <NavigationContext.Provider value={contextValue}>
      {children}
    </NavigationContext.Provider>
  );
};

NavigationContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  routeType: PropTypes.oneOf(Object.values(NavigationRouteType)),
};

NavigationContextProvider.defaultProps = {
  routeType: NavigationRouteType.COACH,
};

export default NavigationContextProvider;
