import styled from 'styled-components';
import { Autocomplete } from '@mui/material';

const Container = styled.div`
  width: fit-content;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Description = styled.p`
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  margin-right: 14px;
`;

const StyledAutoComplete = styled(Autocomplete)`
  width: 250px;
  height: 50px;

  .MuiFormControl-root {
    height: 100%;
  }

  .MuiInputBase-root {
    height: 100%;
  }
`;

export {
  Container,
  Description,
  StyledAutoComplete,
};
