const EventType = {
  COACHING_ACTIVITY: 'coachingActivity',
};

const CoachingActivity = {
  CHECKIN_FEEDBACK_SENT: 'checkinFeedbackSent',
  CREATED_PROGRAM: 'createdProgram',
  UPDATED_PROGRAM: 'updatedProgram',
  ARCHIVED_PROGRAM: 'archivedProgram',
  UNARCHIVED_PROGRAM: 'unarchivedProgram',
  CREATED_EXERCISE: 'createdExercise',
  UPDATED_EXERCISE: 'updatedExercise',
  ARCHIVED_EXERCISE: 'archivedExercise',
  UNARCHIVED_EXERCISE: 'unarchivedExercise',
  CREATED_WORKOUT: 'createdWorkout',
  UPDATED_WORKOUT: 'updatedWorkout',
  ARCHIVED_WORKOUT: 'archivedWorkout',
  UNARCHIVED_WORKOUT: 'unarchivedWorkout',
  ASSIGNED_WORKOUT: 'assignedWorkout',
  UPDATED_ASSIGNED_WORKOUT: 'updatedAssignedWorkout',
  DELETED_WORKOUT_ASSIGNMENT: 'deletedWorkoutAssignment',
  DELETED_WORKOUT_ASSIGNMENTS: 'deletedWorkoutAssignments',
  ASSIGNED_PROGRAM: 'assignedProgram',
  DELETED_PROGRAM_ASSIGNMENT: 'deletedProgramAssignment',
  WORKOUT_FEEDBACK_SENT: 'workoutFeedbackSent',
  CREATED_MEAL_PLAN: 'createdMealPlan',
  UPDATED_MEAL_PLAN: 'updatedMealPlan',
  ASSIGNED_MEAL_PLAN: 'assignedMealPlan',
  UPDATED_ASSIGNED_MEAL_PLAN: 'updatedAssignedMealPlan',
  CREATED_RECIPE: 'createdRecipe',
  UPDATED_RECIPE: 'updatedRecipe',
  CREATED_PRODUCT: 'createdProduct',
  CREATED_PACKAGE: 'createdPackage',
  UPDATED_CURRENCY_SETTINGS: 'updatedCurrencySettings',
  UPDATED_WELCOME_MESSAGE: 'updatedWelcomeMessage',
  UPDATED_HABIT_SETTINGS: 'updatedHabitSettings',
  UPDATED_CHECKIN_SETTINGS: 'updatedCheckinSettings',
  UPDATED_ACTIVITY_FEED_SETTINGS: 'updatedActivityFeedSettings',
  UPDATED_CHAT_SETTINGS: 'updatedChatSettings',
  UPDATED_INBOUND_MESSAGE_SETTINGS: 'updatedInboundMessageSettings',
  CLAIMED_ACTIVITY: 'claimedActivity',
  UNCLAIMED_ACTIVITY: 'unclaimedActivity',
  CANCELED_USER: 'canceledUser',
  PAUSED_USER: 'pausedUser',
};

export {
  EventType,
  CoachingActivity,
};
