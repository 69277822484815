import styled from 'styled-components';
import colors from '../../../../../../../styles/colors';

const Container = styled.div`
  margin-top: 10px;
  padding-top: 5px;
  border-top: 1px solid ${colors.shades.secondary7};
  font-size: 14px;
  display: flex;
  flex-direction: row;
  column-gap : 8px;
  align-items: center;
`;

const IconContainer = styled.div`
  height: 14px;
  width: 14px;
`;

export {
  Container,
  IconContainer,
};
