import React, {
  useState,
} from 'react';

import { TableTabContainer } from '../styles';
import {
  ActionType,
  isEditAction,
  isEditorView,
} from './utils';
import ExerciseEditor from './ExerciseEditor';
import ExercisesTable from './ExercisesTable';

const Exercises = () => {
  const [selectedExercise, setSelectedExercise] = useState(null);
  const [actionPerformed, setActionPerformed] = useState(null);

  const handleActionClick = (exercise, action) => {
    setSelectedExercise(exercise);
    setActionPerformed(action);
  };

  return (
    <TableTabContainer>
      {isEditorView(actionPerformed, selectedExercise)
        ? (
          <ExerciseEditor
            selectedExercise={selectedExercise}
            isEditView={isEditAction(actionPerformed, selectedExercise)}
            onClose={() => handleActionClick(null, null)}
          />
        )
        : (
          <ExercisesTable
            selectedExercise={selectedExercise}
            actionPerformed={actionPerformed}
            handleActionClick={handleActionClick}
          />
        )}
    </TableTabContainer>
  );
};

export default Exercises;
export {
  ActionType,
};
