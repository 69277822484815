import styled from 'styled-components';
import { Drawer, Typography } from '@mui/material';
import {
  ChevronRight as ChevronRightIcon,
  ChevronLeft as ChevronLeftIcon,
} from '@mui/icons-material';

import colors from '../../../../styles/colors';

const StyledTitle = styled(Typography)`
  margin-left: 10px;
`;

const openedMixin = (theme) => ({
  width: theme.drawer.width,
  background: colors.base.secondary2,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  color: colors.base.primary,
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  background: colors.base.secondary2,
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const StyledDrawer = styled(Drawer)(({ theme, open }) => ({
  width: theme.drawer.width,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const ListItemContainer = styled.div`
  margin: 5px;
  .MuiListItemIcon-root {
    color: ${colors.base.primary};
  }
`;

const StyledChevronRightIcon = styled(ChevronRightIcon)`
  color: ${colors.base.primary};
`;

const StyledChevronLeftIcon = styled(ChevronLeftIcon)`
  color: ${colors.base.primary};
`;

export {
  StyledDrawer,
  StyledTitle,
  DrawerHeader,
  ListItemContainer,
  StyledChevronLeftIcon,
  StyledChevronRightIcon,
};
