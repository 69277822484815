import useSetterWithReducer from '../../../service/hook/useSetterWithReducer';
import { SubscriptionControllerState, SubscriptionCreationState } from './SubscriptionMetadata';

const initialState = {
  subscriptionControllerState: SubscriptionControllerState.INITIAL_LOADING,
  stripeErrorState: {},
  subscriptionState: SubscriptionCreationState.INITIAL,
  localSubscriptionDetail: {},
  processResponse: false,
  performHouseKeepingActions: false,
  latestResponse: null,
  obsoleteDataRemoved: false,
  completedInitialDataLoading: false,
  applePayPaymentMethodResult: null,
  accountCreationState: {},
  isOnboardingCompleted: false,
};

const useSubscriptionLogicControllerStateReducer = () => useSetterWithReducer(initialState);
export default useSubscriptionLogicControllerStateReducer;
