import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import useComponentMounted from '../../../hooks/useComponentMounted';
import LoadingPage from '../../../components/LoadingPage';

import {
  StyledIframe,
} from './styles';

const MAX_LOADING_WAIT = 1000 * 20;

const Portal = ({
  url,
  className,
  onIframeLoaded,
}) => {
  const [isReady, setIsReady] = useState(false);
  const isComponentMountedRef = useComponentMounted();
  const [height, setHeight] = useState();

  const iframeRef = useRef(null);

  const onReady = useCallback(() => {
    if (isComponentMountedRef.current) {
      setIsReady(true);
      onIframeLoaded();
    }
  }, [
    isComponentMountedRef,
    onIframeLoaded,
  ]);

  useEffect(() => {
    setTimeout(onReady, MAX_LOADING_WAIT);
    iframeRef.current.addEventListener('load', onReady);
  }, [
    onReady,
  ]);

  useEffect(() => {
    const handler = (event) => {
      if (event.data && Object.keys(event.data).includes('height')) {
        setHeight(event.data.height);
      }
    };

    window.addEventListener('message', handler);

    return () => window.removeEventListener('message', handler);
  }, []);

  return (
    <>
      {!isReady && <LoadingPage className={className} />}
      <StyledIframe
        className={className}
        ref={iframeRef}
        src={url}
        $hidden={!isReady}
        $height={height}
      />
    </>
  );
};

Portal.propTypes = {
  url: PropTypes.string.isRequired,
  className: PropTypes.string,
  onIframeLoaded: PropTypes.func,
};

Portal.defaultProps = {
  className: '',
  onIframeLoaded: () => {},
};

export default Portal;
