import {
  useContext,
  useMemo,
  useCallback,
} from 'react';

import MealPlanAssignment from '../Model/MealPlanAssignment';
import MealPlanContext from '../context/MealPlanContext';
import {
  RecipeTag,
  RestrictionAllergens,
} from '../utils/meals';

// Used when calculating similarity scores between recipes, to add randomness to the results
const SIMILARITY_SCORE_RANDOMNESS_FACTOR = 10;
// Used when randomly adjusting macros targets
const TARGETS_ADJUSTMENT_PERCENTAGE = 15;

const DEFAULT_TARGET_PROTEIN = 40;
const DEFAULT_TARGET_CARBS = 40;
const DEFAULT_TARGET_FAT = 20;

// Function to adjust numbers by a certain percentage while maintaining their sum
const adjustNumbers = (num1, num2, num3, percentage) => {
  const sum = num1 + num2 + num3;

  // Calculate the amount to add or subtract from each number
  const delta = (sum * (percentage / 100)) / 3;

  // random number between -1 and 1
  const randomNumber = () => (Math.random() - 0.5) * 2;

  // Adjust each number randomly within the percentage range
  const newNum1 = Math.round(num1 + (delta * randomNumber()));
  const newNum2 = Math.round(num2 + (delta * randomNumber()));
  // Calculate the third number to ensure the sum remains constant
  const newNum3 = sum - newNum1 - newNum2;

  // Return the adjusted numbers
  return [newNum1, newNum2, newNum3];
};

const useMealPlanGeneration = () => {
  const { recipesDocs } = useContext(MealPlanContext);

  /**
   * Returns a sorted list of recipes that match the target macro nutrients.
   * The resulting list is ordered by score (matching recipes first).
   */
  const findMatchingRecipes = useCallback(({
    recipes,
    targetCarbs = DEFAULT_TARGET_CARBS,
    targetProtein = DEFAULT_TARGET_PROTEIN,
    targetFat = DEFAULT_TARGET_FAT,
  }) => {
    const similarityScore = (recipe) => {
      const carbDiff = Math.abs(recipe.carbsPercentage - targetCarbs);
      const proteinDiff = Math.abs(recipe.proteinPercentage - targetProtein);
      const fatDiff = Math.abs(recipe.fatPercentage - targetFat);
      // Add a bit of randomness to the sorting
      const randomWeight = Math.random() * SIMILARITY_SCORE_RANDOMNESS_FACTOR;
      return carbDiff + proteinDiff + fatDiff + randomWeight;
    };

    // Calculate similarity score for each recipe and sort by increasing score
    const sortedRecipes = [...recipes].sort((a, b) => similarityScore(a) - similarityScore(b));

    return sortedRecipes;
  }, []);

  /**
   * Generates a random set of recipes
   * @param {Object} options options object used in generation
   * @param {string} options.mealTimeName bucket/meal time name
   * @param {Number} options.limit number of recipes to generate
   * @param {Number} options.targetProtein target percentage of protein
   * @param {Number} options.targetCarbs target percentage of carbs
   * @param {Number} options.targetFat target percentage of fat
   * @param {Array} options.excludeRecipes array of recipe ids to be excluded during the generation
   * @returns {Array} generated recipes docs array
   */
  const generateRecipes = useCallback(({
    mealTimeName = '',
    limit = 5,
    targetCarbs,
    targetProtein,
    targetFat,
    excludeRecipes = [],
  }) => {
    const targets = {};

    if (!targetCarbs || !targetFat || !targetProtein) {
      // If all targets are not provided, randomly adjust targets based on default values
      [targets.targetProtein, targets.targetCarbs, targets.targetFat] = adjustNumbers(
        DEFAULT_TARGET_PROTEIN,
        DEFAULT_TARGET_CARBS,
        DEFAULT_TARGET_FAT,
        TARGETS_ADJUSTMENT_PERCENTAGE,
      );
    } else {
      targets.targetProtein = targetProtein;
      targets.targetCarbs = targetCarbs;
      targets.targetFat = targetFat;
    }

    // Filter recipes specified in the exludeRecipes array
    const includedRecipes = recipesDocs.filter(({ id }) => !excludeRecipes.includes(id));

    const sortedRecipes = findMatchingRecipes({
      recipes: includedRecipes,
      ...targets,
    });

    // If the meal time name matches one of the recipe tags, use recipes with the matching tag
    const mealTimeTag = Object.values(RecipeTag).find((tag) => mealTimeName.toLowerCase().includes(tag.toLowerCase()));
    const filteredRecipes = mealTimeTag
      ? sortedRecipes.filter(({ tags }) => tags.includes(mealTimeTag))
      : sortedRecipes;

    return filteredRecipes.slice(0, limit);
  }, [
    findMatchingRecipes,
    recipesDocs,
  ]);

  /**
   * Generates meal times using a meal plan template, macro nutrient targets and allergen restrictions.
   * @param {Object} options options object used in generation
   * @param {Object} options.mealPlanTemplate meal plan template used in the generation
   * @param {Number} options.targetProtein target percentage of protein
   * @param {Number} options.targetCarbs target percentage of carbs
   * @param {Number} options.targetFat target percentage of fat
   * @param {Array} options.restrictions array of dietary restriction
   * @param {string} options.userId userId to generate meal times to
   * @returns {Array} generated meal times array
   */
  const generateMealTimes = useCallback(async ({
    mealPlanTemplate,
    targetCarbs = DEFAULT_TARGET_CARBS,
    targetProtein = DEFAULT_TARGET_PROTEIN,
    targetFat = DEFAULT_TARGET_FAT,
    restrictions = [],
    userId = '',
  } = {}) => {
    // Get all allergen tags that are restricted in the meal plan
    const restrictedTags = restrictions.reduce((acc, restriction) => {
      const restrictionAllergens = RestrictionAllergens[restriction];
      return [...new Set([...acc, ...restrictionAllergens])];
    }, []);

    const {
      mealTimes: mealTimesTemplate,
      numberOfMealPlansToExclude,
    } = mealPlanTemplate;

    let recipesToExclude = [];

    // If user is provided, exlude meals from previous meal plans
    if (userId) {
      // Get all meal plans assigned to this user
      const assignmentsHistory = await MealPlanAssignment.getAssignmentsByUser(userId);
      // Array of recipe ids to exclude
      recipesToExclude = assignmentsHistory
        .toSorted((a, b) => (a.approvalDate > b.approvalDate ? -1 : 1))
        .slice(0, numberOfMealPlansToExclude)
        .reduce((acc, { recipes }) => [...new Set([...acc, ...recipes])], []);
    }

    // Filter recipes with restricted allergen tags and excluded recipes
    const validRecipes = recipesDocs.filter(({ allergenTags, id }) => (
      !recipesToExclude.includes(id) && allergenTags.every((tag) => !restrictedTags.includes(tag))
    ));

    // Get matching recipes
    let sortedRecipes = findMatchingRecipes({
      recipes: validRecipes,
      targetCarbs,
      targetProtein,
      targetFat,
    });

    const mealTimes = mealTimesTemplate.map((mealTime) => {
      const {
        caloricSplit,
        name,
        recipesAmount,
      } = mealTime;

      // If the meal time name matches one of the recipe tags, use recipes with the matching tag
      const mealTimeTag = Object.values(RecipeTag).find((tag) => name.toLowerCase().includes(tag.toLowerCase()));
      const filteredRecipes = mealTimeTag
        ? sortedRecipes.filter(({ tags }) => tags.some((tag) => mealTimeTag.includes(tag)))
        : sortedRecipes;

      const recipesUsed = [];
      const meals = filteredRecipes.slice(0, recipesAmount).map((recipe) => {
        recipesUsed.push(recipe.id);
        return {
          recipe: { ...recipe.data, id: recipe.id },
          servings: recipe.servings,
        };
      });

      // Remove used recipes from sorted recipes to avoid repeated recipes
      sortedRecipes = sortedRecipes.filter(({ id }) => !recipesUsed.includes(id));

      return {
        caloricSplit,
        name,
        meals,
      };
    });

    return mealTimes;
  }, [
    recipesDocs,
    findMatchingRecipes,
  ]);

  return useMemo(() => ({
    generateMealTimes,
    generateRecipes,
  }), [
    generateMealTimes,
    generateRecipes,
  ]);
};

export default useMealPlanGeneration;
