import { useEffect, useState } from 'react';
import useComponentMounted from './useComponentMounted';

/*
  Max time we are willing to wait for a first Loading Page to be rendered.
*/
const MAX_FIRST_LOADING_PAGE_EXPECTABLE_DELAY = 500;

const useAppLoaded = () => {
  const isComponentMountedRef = useComponentMounted();
  const [isAppLoaded, setIsAppLoaded] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      if (!window.system2.isLoading) {
        setIsAppLoaded(true);
      } else {
        window.addEventListener('s2AppLoaded', () => {
          if (isComponentMountedRef.current) {
            setIsAppLoaded(true);
          }
        });
      }
      // We wait a brief period of time to see if a loading page gets rendered
    }, MAX_FIRST_LOADING_PAGE_EXPECTABLE_DELAY);
  }, [
    isComponentMountedRef,
  ]);

  return {
    isAppLoaded,
  };
};

export default useAppLoaded;
