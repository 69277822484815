import { useEffect, useRef } from 'react';

const useRunOnInitialRender = (fn, extraChecks = true) => {
  const hasRun = useRef(false);

  useEffect(() => {
    if (!hasRun.current && !!extraChecks) {
      fn();
      hasRun.current = true;
    }
  }, [
    fn,
    extraChecks,
  ]);
};

export default useRunOnInitialRender;
