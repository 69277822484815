import React from 'react';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import useQuickSearch from '../../../hooks/useQuickSearch';
import QuickSearchToolbar from '../QuickSearchToolbar';
import {
  DataGridContainer,
  StyledDataGrid,
} from './styles';

const ROWS_PER_PAGE = 25;

const Table = ({
  rows,
  columns,
  columnVisibilityModel,
  pageSize,
}) => {
  // Get the first filtering stage which returns the rows that match the quick search input.
  const {
    filteredRows,
    toolbarProps,
  } = useQuickSearch(rows, columns);

  return (
    <DataGridContainer>
      <StyledDataGrid
        rows={filteredRows}
        columns={columns}
        columnVisibilityModel={columnVisibilityModel}
        pageSize={pageSize}
        rowsPerPageOptions={[ROWS_PER_PAGE]}
        disableSelectionOnClick
        components={{
          Toolbar: QuickSearchToolbar,
        }}
        componentsProps={{
          toolbar: { ...toolbarProps, autoFocus: true },
        }}
      />
    </DataGridContainer>
  );
};

Table.propTypes = {
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  columnVisibilityModel: PropTypes.object,
  pageSize: PropTypes.number,
};

Table.defaultProps = {
  pageSize: 25,
  columnVisibilityModel: {},
};

export default compose(
  observer,
)(Table);
