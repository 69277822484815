import React from 'react';

import QuickSearchToolbar from '../../QuickSearchToolbar';

import {
  Container,
} from './styles';

const FormsTableToolbar = ({
  ...toolbarProps
}) => (
  <Container>
    <QuickSearchToolbar {...toolbarProps} />
  </Container>
);

export default FormsTableToolbar;
