import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Tooltip } from '@mui/material';
import {
  ActionButtonContainer,
  StyledAddIcon,
  StyledTrashIcon,
  StyledCopyIcon,
  StyledUpdateIcon,
} from './styles';
import texts from './texts.json';

const ActionButtons = ({
  onAdd,
  onRemove,
  onDuplicate,
  onUpdate,
}) => (

  <ActionButtonContainer>
    {!!onAdd && (
      <Tooltip title={texts.add}>
        <IconButton onClick={onAdd}>
          <StyledAddIcon />
        </IconButton>
      </Tooltip>
    )}
    <Tooltip title={texts.update}>
      <IconButton onClick={onUpdate}>
        <StyledUpdateIcon />
      </IconButton>
    </Tooltip>
    <Tooltip title={texts.delete}>
      <IconButton onClick={onRemove}>
        <StyledTrashIcon />
      </IconButton>
    </Tooltip>
    <Tooltip title={texts.duplicate}>
      <IconButton onClick={onDuplicate}>
        <StyledCopyIcon />
      </IconButton>
    </Tooltip>
  </ActionButtonContainer>
);

ActionButtons.propTypes = {
  onAdd: PropTypes.func,
  onRemove: PropTypes.func.isRequired,
  onDuplicate: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

ActionButtons.defaultProps = {
  onAdd: null,
};

export default ActionButtons;
