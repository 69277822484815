import styled from 'styled-components';
import {
  Paper,
  FormControl,
  FormLabel,
} from '@mui/material';

const StyledPaper = styled(Paper)`
  padding: 16px;
  max-width: 900px;
`;

const StyledFormControl = styled(FormControl)`
  display: flex;
  flex-direction: row;
  margin: 0 0 20px;
  justify-content: space-between;
`;

const StyledFormLabel = styled(FormLabel)`
  margin: 8px 0;
`;

const Container = styled.div`
  margin: 10px 0;
`;

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export {
  StyledPaper,
  StyledFormControl,
  StyledFormLabel,
  Container,
  OptionsContainer,
};
