import React from 'react';
import PropTypes from 'prop-types';
import {
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material';

import {
  Title,
  StyledAccordion,
} from './styles';

const Accordion = ({
  title,
  defaultExpanded,
  children,
}) => (
  <StyledAccordion
    defaultExpanded={defaultExpanded}
  >
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Title>{title}</Title>
    </AccordionSummary>
    <AccordionDetails>
      {children}
    </AccordionDetails>
  </StyledAccordion>
);

Accordion.propTypes = {
  title: PropTypes.string.isRequired,
  defaultExpanded: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Accordion.defaultProps = {
  defaultExpanded: false,
};

export default Accordion;
