import styled, { css } from 'styled-components';
import { Popper } from '@mui/material';

import { ReactComponent as ChatIcon } from '../../../assets/icons/chat-icon.svg';
import Button from '../../../components/Button';
import colors from '../../../styles/colors';

import variants from './variants';

const chatIconPx = 30;

const iconVariant = (variant) => {
  switch (variant) {
    case variants.PRIMARY:
      return css`fill: ${colors.base.secondary3};`;
    case variants.SECONDARY:
      return css`fill: ${colors.base.primary};`;
    default:
      // primary variant by default
      return css`fill: ${colors.base.secondary3};`;
  }
};

const Container = styled.div`
  display: flex;
`;

const ChatButton = styled(Button)`
  border-radius: 5px;
  column-gap: 8px;
  background: ${colors.base.primary};
  color: ${colors.base.beta};
  height: fit-content;
  padding: 10px;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    background: ${colors.shades.primary6};
  }
`;

const ChatIconButton = styled(Button)`
  background-color: transparent;
  color: ${colors.shades.secondary3};
  border-radius: 50%;
  width: ${chatIconPx}px;
  height: ${chatIconPx}px;
  padding: 5px;
  margin-left: 5px;
  cursor: pointer;

  &:hover {
    background-color: ${colors.shades.gamma1};
  }
`;

const StyledPopper = styled(Popper)`
  z-index: 99;
`;

const StyledChatLightIcon = styled(ChatIcon)`
  width: 15px;
  height: 15px;
`;

const StyledChatIcon = styled(StyledChatLightIcon)`
  width: 15px;
  height: 15px;

  > path {
    stroke: ${colors.shades.secondary8};
    ${(props) => iconVariant(props?.variant)}
  }
`;

export {
  Container,
  ChatButton,
  ChatIconButton,
  StyledPopper,
  StyledChatIcon,
  StyledChatLightIcon,
};
