import React, {
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import format from 'string-template';

import { formatCurrencyCents } from '../../../../../utils/formatters';

import {
  Container,
  ExplainerContainer,
  BillNote,
  BillTitle,
  PricingNote,
  StyledList,
  StyledListItem,
} from './styles';

import texts from './texts.json';

const RecurringOption = {
  YEARLY: 12,
  MONTHLY: 1,
};

const PaymentExplainer = ({
  totalPriceInCents,
  recurringBillingMonths,
  totalPayToday,
  totalPayOnStartDate,
  refundablePlan,
  currency,
  cancelAtPeriodEnd,
  initialTerm,
}) => {
  /*
    There are multiple ways we can consider the first payment a discount:
    - If the monthly price (totalPrice property) is different from the first payment
    - If the initial term is greater than 1, meaning the first payment covers more months than the recurring billing.
  */
  const discount = useMemo(() => (
    totalPayToday !== totalPriceInCents || initialTerm > recurringBillingMonths
  ), [
    totalPayToday,
    totalPriceInCents,
    initialTerm,
    recurringBillingMonths,
  ]);

  const formattedPrice = useMemo(() => (
    formatCurrencyCents(totalPriceInCents, currency)
  ), [
    totalPriceInCents,
    currency,
  ]);

  const formattedDiscountPrice = useMemo(() => (
    formatCurrencyCents(totalPayToday, currency)
  ), [
    totalPayToday,
    currency,
  ]);

  const priceTexts = useMemo(() => {
    let note;
    let title;
    let renew;

    if (cancelAtPeriodEnd) {
      note = format(texts.totalPrice, { amount: formattedDiscountPrice });
      title = format(texts.nonRecurringBillTitle, { amount: formattedPrice });
      renew = format(texts.renewNonRecurring, { months: recurringBillingMonths });

      return { note, title, renew };
    }

    if (totalPayToday !== totalPayOnStartDate) {
      note = format(texts.firstPayment, { amount: formattedDiscountPrice });
      title = format(texts.upfrontPayment, {
        startDateAmount: formatCurrencyCents(totalPayOnStartDate, currency),
        amount: formattedPrice,
      });
      renew = texts.renewMonthly;
      const initialTermNote = format(texts.upfrontPaymentTerm, {
        initialTerm,
        unit: initialTerm > 1 ? texts.units.months : texts.units.month,
      });

      return {
        note,
        title,
        renew,
        initialTermNote,
      };
    }
    switch (recurringBillingMonths) {
      case RecurringOption.YEARLY: {
        note = discount
          ? format(texts.firstYear, { amount: formattedDiscountPrice })
          : format(texts.yearlyBillNote, { amount: formattedPrice });
        title = format(texts.yearlyBillTitle, { amount: formattedPrice });
        renew = texts.renewYearly;
        break;
      }

      case RecurringOption.MONTHLY: {
        note = discount
          ? format(texts.firstPayment, { amount: formattedDiscountPrice })
          : format(texts.monthlyBillNote, { amount: formattedPrice });
        title = format(texts.monthlyBillTitle, { amount: formattedPrice });
        renew = texts.renewMonthly;
        break;
      }

      default: {
        note = discount
          ? format(texts.firstPayment, { amount: formattedDiscountPrice })
          : format(texts.monthsBillNote, { amount: formattedPrice, months: recurringBillingMonths });
        title = format(texts.monthsBillTitle, { amount: formattedPrice, months: recurringBillingMonths });
        renew = format(texts.renewMonths, { months: recurringBillingMonths });
        break;
      }
    }

    const initialTermNote = format(texts.initialTerm, {
      initialTerm,
      unit: initialTerm > 1 ? texts.units.months : texts.units.month,
    });

    return {
      note,
      title,
      renew,
      initialTermNote,
    };
  }, [
    recurringBillingMonths,
    discount,
    formattedDiscountPrice,
    formattedPrice,
    cancelAtPeriodEnd,
    initialTerm,
    totalPayToday,
    totalPayOnStartDate,
    currency,
  ]);

  const pricingNotes = useMemo(() => {
    const notes = [];

    // Get how many months the initial payment covers.
    if (priceTexts.initialTermNote) {
      notes.push(priceTexts.initialTermNote);
    }

    // Add the renewal note.
    notes.push(priceTexts.renew);

    // Only add the non refundable note if the plan is not refundable.
    if (!refundablePlan) {
      notes.push(texts.nonRefundable);
    }
    return notes;
  }, [
    priceTexts,
    refundablePlan,
  ]);

  return (
    <Container>
      <ExplainerContainer>
        <BillNote>{priceTexts.note}</BillNote>
        <BillTitle>{priceTexts.title}</BillTitle>
        <StyledList>
          {pricingNotes.map((text) => (
            <StyledListItem key={`note-${text}`}>
              <PricingNote>{text}</PricingNote>
            </StyledListItem>
          ))}
        </StyledList>
      </ExplainerContainer>
    </Container>
  );
};

PaymentExplainer.propTypes = {
  totalPriceInCents: PropTypes.number,
  recurringBillingMonths: PropTypes.number,
  totalPayToday: PropTypes.number,
  totalPayOnStartDate: PropTypes.number,
  refundablePlan: PropTypes.bool,
  currency: PropTypes.string,
  cancelAtPeriodEnd: PropTypes.bool,
  initialTerm: PropTypes.number,
};

PaymentExplainer.defaultProps = {
  totalPriceInCents: 0,
  recurringBillingMonths: 1,
  totalPayToday: 0,
  totalPayOnStartDate: 0,
  refundablePlan: false,
  currency: 'usd',
  cancelAtPeriodEnd: false,
  initialTerm: 1,
};

export default PaymentExplainer;
