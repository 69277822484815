const VIDEO_DESTINATION_URL = 'https://api.vimeo.com/me/projects/5400740/videos';

const AcceptableVideoStatus = {
  AVAILABLE: 'available',
  TRANSCODE_STARTING: 'transcode_starting',
  TRANSCODING: 'transcoding',
  UPLOADING: 'uploading',
};

/**
 * Creates a video upload in Vimeo using the form upload method in Vimeo.
 *
 * @param {File} fileSize - The size of the video file to be uploaded.
 * @param {string} videoName - The name of the created video.
 * @param {string} coachId - The coach ID.
 * @param {firebase.remote.RemoteType} remote - The Firebase remote object for Firebase-related operations.
 * @returns {Promise<Object>} - Returns the response from the API with the video data.
 */
const fetchUploadLink = async (fileSize, videoName, coachId, remote) => {
  const response = await remote('generateVimeoUpload', {
    fileSize,
    videoName,
    coachId,
  });

  const {
    videoURI,
    uploadData: {
      upload_link: uploadLink,
      status,
    },
  } = await response.json();

  if (!response.ok) {
    throw new Error(`Received an erroneous response. Upload status is ${status}`);
  }

  if (!uploadLink) {
    throw new Error('Upload link has not been created');
  }

  return ({ videoURI, uploadLink });
};

/**
 * Uploads a video file to the provided Vimeo upload link.
 *
 * @param {string} uploadLink - The Vimeo upload link received after creating the video placeholder on Vimeo.
 * @param {File} file - The video file to be uploaded.
 * @returns {Promise<Response>} The response of the fetch request.
 */
const uploadVideo = async (uploadLink, file) => {
  // Vimeo upload link expects the data to be in the 'formdata' format and the key as 'file_data'
  const formData = new FormData();
  formData.append('file_data', file);

  fetch(uploadLink, {
    method: 'POST',
    mode: 'cors',
    headers: {
      Accept: 'application/vnd.vimeo.*+json;version=3.4',
    },
    body: formData,
  });
};

/**
 * Moves a video in Vimeo to a given destination folder.
 *
 * @param {File} videoId - The id of the video to be moved.
 * @param {string} coachId - The coach ID.
 * @param {firebase.remote.RemoteType} remote - The Firebase remote object for Firebase-related operations.
 * @returns {Promise<boolean>} Resolves with a boolean indicating whether the operation was successful.
 */
const moveVimeoVideo = async (videoId, coachId, remote) => {
  const response = await remote('moveVimeoVideo', {
    destinationUrl: VIDEO_DESTINATION_URL,
    videoId,
    coachId,
  });

  const { success } = await response.json();

  if (!response.ok) {
    throw new Error('Received an erroneous response');
  }

  if (!success) {
    throw new Error('Something went wrong with moving the video file');
  }

  return success;
};

/**
 * Gets the data of the uploaded video.
 *
 * @param {File} videoId - The id of the video.
 * @param {string} coachId - The coach ID.
 * @param {firebase.remote.RemoteType} remote - The Firebase remote object for Firebase-related operations.
 * @returns {Promise<Object>} - Returns the video data.
 */
const getUploadedVideoData = async (videoId, coachId, remote) => {
  const response = await remote('getUploadedVideoData', {
    videoId,
    coachId,
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error('Received an erroneous response');
  }

  // If the video doesn't have an acceptable status we will throw an error
  if (!Object.values(AcceptableVideoStatus).some((status) => status === data.status)) {
    throw new Error('Something went wrong with accessing the video');
  }

  return data;
};

/**
 * Polls Vimeo's API at regular intervals to check if a specified video is available and ready.
 *
 * This function periodically checks the status of a video on Vimeo. It will resolve once the
 * video's status becomes 'available'. The function uses a 5-second interval between checks.
 *
 * @param {string|number} videoId - The unique identifier of the video on Vimeo.
 * @param {string} coachId - The coach ID.
 * @param {firebase.remote.RemoteType} remote - The Firebase remote object for Firebase-related operations.
 * @returns {Promise<string>} Resolves with the video link and thumbnail if the status is AVAILABLE;
 *                            otherwise, rejects with an error.
 */
const waitForVideoReady = async (videoId, coachId, remote) => {
  let intervalId = null;
  let checkInProgress = false;

  const promise = new Promise((resolve, reject) => {
    intervalId = setInterval(async () => {
      // Return from the function if the previous API call is still in progress
      if (checkInProgress) {
        return;
      }

      try {
        checkInProgress = true;
        const {
          status,
          link: videoLink,
          pictures: {
            base_link: thumbnail,
          } = {},
        } = await getUploadedVideoData(videoId, coachId, remote);

        // If the video is available all is fine and we will resolve with video's accessible link and thumbnail
        if (status === AcceptableVideoStatus.AVAILABLE) {
          clearInterval(intervalId);
          resolve({ videoLink, thumbnail });
        }
        checkInProgress = false;
      } catch (error) {
        clearInterval(intervalId);
        reject(error);
      }
    }, 5000);
  });

  return promise;
};

/**
 * Uploads a video file to Vimeo.
 *
 * This function encompasses the entire process of uploading a video to Vimeo:
 * 1. Creates a video record on Vimeo, preparing for the actual file upload.
 * 2. Uploads the video file using the received upload link.
 * 3. Sends a request to the server to move the uploaded video and resolve once ready.
 *
 * @param {File} file - The video file to be uploaded.
 * @param {string} userFullName - The full name of the user, used in naming the video on Vimeo.
 * @param {string} coachId - The id of the coach.
 * @param {firebase.remote.RemoteType} remote - The Firebase remote object for Firebase-related operations.
 * @returns {Promise<void>} Resolves once all operations (uploading, moving, and waiting) are completed.
 */
const uploadVideoToVimeo = async (file, userFullName, coachId, remote) => {
  const videoName = `${userFullName.replaceAll(' ', '-')}_${file.name}`;
  let uploadedLink = '';
  let thumbnailImage = '';
  let errMsg = '';

  try {
    const {
      videoURI,
      uploadLink,
    } = await fetchUploadLink(file.size, videoName, coachId, remote);

    await uploadVideo(uploadLink, file);

    const videoId = videoURI.slice(videoURI.lastIndexOf('/') + 1);
    await moveVimeoVideo(videoId, coachId, remote);

    const {
      videoLink,
      thumbnail,
    } = await waitForVideoReady(videoId, coachId, remote);
    uploadedLink = videoLink;
    thumbnailImage = thumbnail;
  } catch (error) {
    errMsg = error.message;
  }

  return { uploadedLink, thumbnailImage, errMsg };
};

export { uploadVideoToVimeo };
