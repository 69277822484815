import { DialogContent, DialogTitle, TextField } from '@mui/material';
import styled from 'styled-components';
import { sansSerifHeadlineFontStyles } from '../../../../../styles/text';
import Button from '../../../../../components/Button/Button';

const Title = styled(DialogTitle)`
  ${sansSerifHeadlineFontStyles}
  font-size: 24px;
  font-weight: bold;
`;

const StyledInput = styled(TextField)`
  min-width: 350px;
  width: 100%;
`;

const StyledDialogContent = styled(DialogContent)`
  padding-top: 10px !important;
  display: flex;
  flex-direction: column;
  place-content: space-between;
  gap: 25px;
`;

const StyledButton = styled(Button)`
  height: 30px;
  width: unset;
`;

export {
  Title,
  StyledInput,
  StyledDialogContent,
  StyledButton,
};
