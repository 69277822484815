import React, { useContext } from 'react';

import LoadingPage from '../../components/LoadingPage';

import ExternalCoachContext from './ExternalCoachContext';
import ExternalCoachContextProvider from './ExternalCoachContextProvider';

const withExternalCoachContextProvider = (Component) => (props) => (
  <ExternalCoachContextProvider>
    <Component {...props} />
  </ExternalCoachContextProvider>
);

const withExternalCoachContextReady = (Component) => (props) => {
  const { isReady } = useContext(ExternalCoachContext);
  return isReady ? <Component {...props} /> : <LoadingPage />;
};

export {
  withExternalCoachContextProvider,
  withExternalCoachContextReady,
};
