import React, { useContext } from 'react';
import LoadingPage from '../../../components/LoadingPage';

import ExternalCoachContextProvider from './ExternalCoachContextProvider';
import ExternalCoachContext from './ExternalCoachContext';

const withExternalCoachContextProvider = (Component) => (props) => (
  <ExternalCoachContextProvider>
    <Component {...props} />
  </ExternalCoachContextProvider>
);

const withExternalCoachContextReady = (Component) => (props) => {
  const { isReady } = useContext(ExternalCoachContext);
  return isReady ? <Component {...props} /> : <LoadingPage />;
};

const withCoachProductsContextReady = (Component) => (props) => {
  const { isProductsReady } = useContext(ExternalCoachContext);
  return isProductsReady ? <Component {...props} /> : <LoadingPage />;
};

export {
  withExternalCoachContextProvider,
  withExternalCoachContextReady,
  withCoachProductsContextReady,
};
